import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  fetchMarginRequirements,
} from 'redux/marginSettings/actions'
import { ConfigStyle } from './styles'
import UpdateMarginRequirements from './UpdateMarginRequirements'

class MarginRequirements extends Component {
  componentDidMount() {
    this.props.fetchMarginRequirements()
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }


  render() {
    const { status, data, isSavingMarginRequirements } = this.props.marginRequirements
    return (
      <Fragment>
        <h3>Margin Requirements</h3>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' &&
          <>
            {status === 'done' && Object.entries(data).map(([key, value]) => <ConfigStyle key={key} >
              {this.removeCamelCasing(key)}: {value}
            </ConfigStyle>)}

            {status === 'done' && <UpdateMarginRequirements isSavingMarginRequirements={isSavingMarginRequirements} data={data} />}
          </>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  marginRequirements: state.marginSettings.marginRequirements,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMarginRequirements,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarginRequirements)
