import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MuiSwitcherWithTitle from 'components/atoms/MuiSwitcherWithTitle'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { TablePagination } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import { FlexboxColumn, Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'
import * as moment from 'moment'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  updateInternalTransfers,
  fetchPendingTransfers,
} from 'redux/pendingInternalTransfers/actions'
import { RenderCheckbox } from 'forms/fields'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import { Actions, Title, TitleContainer } from 'components/atoms/Table/styles'

import PendingInternalTransferRow from './row'

import { TransitionCell } from '../Styles'

class PendingInternalTransfers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      selected: {},
      highlightedId: '',
      selectedInternalTransfers: [],
      showBulkAction: false,
      bulkModalOpen: false,
      bulkSelectedActionType: '',
      page: 0,
      rowsPerPage: 50,
      isBulkProcessing: false,
    }
  }

  toggleSelectedRow = (transfer) => {
    this.setState((prev) => {
      const updatedSelectedTransfers = [...prev.selectedInternalTransfers]
      const indexOfExistingTransfer = updatedSelectedTransfers.findIndex(
        (currentSelectedTransfer) => currentSelectedTransfer.id === transfer.id,
      )
      if (indexOfExistingTransfer > -1) {
        updatedSelectedTransfers.splice(indexOfExistingTransfer, 1)
      } else {
        updatedSelectedTransfers.push(transfer)
      }
      return {
        selectedInternalTransfers: updatedSelectedTransfers,
      }
    })
  }

  setHighlightedId = (id) => {
    this.setState({ highlightedId: id })
  }

  toggleSelectAll = (currentInternalTransfersData) => {
    this.setState(({ selectedInternalTransfers }) => {
      if (
        selectedInternalTransfers.length === currentInternalTransfersData.length
      ) {
        return { selectedInternalTransfers: [] }
      }
      const currentInternalTransfersToAdd = currentInternalTransfersData
        .filter((transfer) => !transfer.isBatchTransfer)
        .map(
          ({
            id,
            fromAccountId,
            toAccountId,
            reason,
            currency,
            amount,
            internalComment,
          }) => ({
            id,
            fromAccountId,
            toAccountId,
            reasonId: reason.id,
            currencyCode: currency.shortName,
            amount,
            internalCommentId: internalComment.id,
          }),
        )

      return {
        selectedInternalTransfers: currentInternalTransfersToAdd,
      }
    })
  }

  toggleBulkActions = () => {
    this.setState({ showBulkAction: !this.state.showBulkAction })
  }

  openBulkTransfersModel = (action) => {
    this.setState({ bulkModalOpen: true, bulkSelectedActionType: action })
  }

  setRowsPerPage = (rowsPerPage) => {
    this.setState({
      rowsPerPage,
    })
  }

  setPage = (page) => {
    this.setState({
      page,
    })
  }

  handleProceedBulkInternalTransfersCheck = async () => {
    this.setState({ isBulkProcessing: true })
    const { selectedInternalTransfers, bulkSelectedActionType } = this.state
    await this.props.updateInternalTransfers(
      selectedInternalTransfers,
      bulkSelectedActionType,
    )
    this.setState({
      selectedInternalTransfers: [],
      bulkSelectedActionType: '',
      bulkModalOpen: false,
      isBulkProcessing: false,
    })
  }

  handleCloseModal = () => {
    this.setState({
      bulkModalOpen: false,
      bulkSelectedActionType: '',
      selectedInternalTransfers: [],
    })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage)
  }

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(parseInt(event.target.value, 10))
    this.setPage(0)
  }

  render() {
    const {
      name,
      pendingInfo: { status, data },
    } = this.props
    const {
      bulkModalOpen,
      showBulkAction,
      selectedInternalTransfers,
      page,
      rowsPerPage,
      bulkSelectedActionType,
      isBulkProcessing,
    } = this.state
    if (status === 'done' && data.length === 0) {
      return <Flexbox>No Pending Internal Transfers</Flexbox>
    }
    const internalTransfersToDisplay =
      data && data.slice(page * rowsPerPage, rowsPerPage * (page + 1))
    const tablePaginationCount = data.length
    const selectableInternalTransfers = internalTransfersToDisplay.filter(
      (t) => !t.isBatchTransfer,
    )
    return (
      <div>
        <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
          <TitleContainer>
            <Title>{name}</Title>
            <MuiSwitcherWithTitle
              checked={showBulkAction}
              text="Enable Bulk Actions"
              onChange={this.toggleBulkActions}
            />
          </TitleContainer>
          {showBulkAction && (
            <>
              <Actions justify="flex-end" margin="10px 0">
                <MuiButton
                  disabled={selectedInternalTransfers.length === 0}
                  onClick={() => {
                    this.openBulkTransfersModel('CREDIT')
                  }}
                  style={{ marginRight: '12px' }}
                >
                  Approve
                </MuiButton>
                <MuiButton
                  disabled={selectedInternalTransfers.length === 0}
                  onClick={() => {
                    this.openBulkTransfersModel('REJECT')
                  }}
                  style={{ marginRight: '12px' }}
                >
                  Reject
                </MuiButton>
              </Actions>
            </>
          )}
          <Table>
            <TableHead>
              <TableCell>Transfer ID</TableCell>
              <TableCell>From Account</TableCell>
              <TableCell>To Account</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Internal Comment</TableCell>
              <TableCell>Client Transaction History Comment</TableCell>
              {this.state.showBulkAction && (
                <TableCell>
                  <TransitionCell flex={0.7}>
                    <RenderCheckbox
                      onCheckFunction={() =>
                        this.toggleSelectAll(selectableInternalTransfers)
                      }
                      checked={
                        selectedInternalTransfers.length > 0 &&
                        selectedInternalTransfers.length ===
                          selectableInternalTransfers.length &&
                        data.length > 0
                      }
                      label={'Select All'}
                    />
                  </TransitionCell>
                </TableCell>
              )}
            </TableHead>
            <TableBody>
              {status === 'loading' && (
                <TableCell colSpan={10}>
                  {' '}
                  <LoadingBlock />
                </TableCell>
              )}
              {status === 'done' &&
                internalTransfersToDisplay.map((transfer) => {
                  transfer.createdAt = moment(transfer.createdAt).format(
                    'YYYY-MMM-DD HH:mm:ss',
                  )
                  return (
                    <PendingInternalTransferRow
                      key={transfer.id}
                      transfer={transfer}
                      showBulkAction={this.state.showBulkAction}
                      isSelected={
                        selectedInternalTransfers.findIndex(
                          (internalTransfer) =>
                            internalTransfer.id === transfer.id,
                        ) > -1
                      }
                      isHighlighted={this.state.highlightedId === transfer.id}
                      toggleSelected={this.toggleSelectedRow}
                      setHighlightedId={this.setHighlightedId}
                    />
                  )
                })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={tablePaginationCount}
            page={page}
            onChangePage={this.handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <ConfirmationDialog
            open={bulkModalOpen}
            title={'Internal Transfers'}
            message={`You are about to bulk ${bulkSelectedActionType} ${selectedInternalTransfers.length} items ?`}
            proceed={this.handleProceedBulkInternalTransfersCheck}
            handleClose={this.handleCloseModal}
            disabled={isBulkProcessing}
            button={{
              confirm: {
                text: `Proceed${isBulkProcessing ? 'ing' : ''}`,
              },
              decline: {
                text: 'Cancel',
              },
            }}
          />
        </FlexboxColumn>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  pendingInfo: state.pendingInternalTransfers,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPendingTransfers,
      updateInternalTransfers,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingInternalTransfers)
