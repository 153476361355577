import React, { Component, Fragment } from 'react'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  openForm = id => {
    this.props.toggleSelected(id)
    this.props.fetchIdentityNumberChangeDualAuthRawData(id)
  }

  closeForm = id => {
    this.props.toggleSelected(id)
  }

  handleSubmitSuccess = async () => {
    await this.props.removeSubmittedItem(this.props.identityNumberInfo.id)
    this.props.toggleSelected(this.props.identityNumberInfo.id)
  }

  render() {
    const {
      identityNumberInfo,
      isSelected,
      rowLoading,
      identityNumberDataById,
    } = this.props
    const {
      data: { accountId, reason },
      id,
      createdAt,
      active,
    } = identityNumberInfo
    const shading = isSelected ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => {
            this.openForm(id)
          }}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'10%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'40%'}>{createdAt}</TableCell>
          <TableCell width={'40%'}>{reason}</TableCell>
          <TableCell width={'10%'}>
            {active &&
              active.userActive && (
                <div className="tooltip">
                  <i style={{ color: '#B9BBBD' }} className="fas fa-eye" />
                  <span className="tooltiptext">{active.activePersonName}</span>
                </div>
              )}
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              actionType: identityNumberInfo.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'pending',
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            onCancel={this.closeForm}
            active={active}
            onSubmitSuccess={this.handleSubmitSuccess}
            rawData={identityNumberDataById}
            loading={rowLoading}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'ID_NUMBER_EVENT')
