import { createSelector } from 'reselect'

export const getAccounts = state => state.accounts.byId

export const getAccountDetails = createSelector(
  [getAccounts],
  accounts => accounts,
)

export const getAccountNameAndSurname = createSelector(
  [getAccountDetails],
  (accountDetails) => {
    const name = accountDetails?.info?.name;
    const surname = accountDetails?.info?.surname;
    return `${name} ${surname}`;
  }
);