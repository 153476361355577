import React, { Fragment, Component } from 'react'
import { hasAccountProfileSettingsCustomWithdrawalLimitChangeRole } from 'helpers/roleBasedAccess'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { Details } from 'components/atoms/Details'
import { ActionButton } from 'components/atoms/Table/styles'
import InitiateWithdrawalLimitForm from 'forms/InititateWithdrawalLimit/index'
import auth from 'services/Auth'

class InitiateWithdrawalLimit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      updateUserData: {},
      withdrawalLimitChangeProcessActive: false,
    }
  }

  toggleForm = async () => {
    this.setState({ formShown: !this.state.formShown })
  }

  render() {
    const { formShown, withdrawalLimitChangeProcessActive } = this.state
    const { accountId } = this.props
    const notAuthorized = !hasAccountProfileSettingsCustomWithdrawalLimitChangeRole()
    return (
      <Details>
        <Fragment>
          {!formShown && (
            <MuiButton
              disabled={notAuthorized || withdrawalLimitChangeProcessActive}
              style={{ whiteSpace: 'nowrap' }}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
              onClick={this.toggleForm}
            >
              {withdrawalLimitChangeProcessActive
                ? 'updating...'
                : 'Update Crypto Withdrawal Limit'}
            </MuiButton>
          )}
        </Fragment>
        {formShown && (
          <InitiateWithdrawalLimitForm
            initialValues={{
              accountId,
              comment: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
              type: 'CUSTOM_WITHDRAWAL_LIMIT',
            }}
            updateWithdrawalLimitProcessState={status =>
              this.setState({
                withdrawalLimitChangeProcessActive: status,
              })
            }
            form={'ChangeWithdrawalLimit'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Details>
    )
  }
}
export default InitiateWithdrawalLimit
