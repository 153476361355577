import React, { useMemo } from 'react'
import {
  AccountCorporate,
  CorporateNaturalEntity,
  CorporateRiskProfile,
  ICorporateRiskProfileEddReport,
  ICorporateRiskProfileEddReportInitialValues,
} from 'containers/Accounts/SingleAccount/Compliance/interfaces/riskProfile'
import { diffCheckBetweenObjects } from 'helpers/diffCheckBetweenObjects'
import { SingleAccountInfo } from 'interfaces/singleAccount'

interface AccountInfoDiffCheck {
  phoneNumber: string
  emailAddress: string
  fullName: string
}

export const useCorporateRiskProfileEddReport = (
  accountCorporate: AccountCorporate,
  accountCorporateNaturalEntities: CorporateNaturalEntity[],
  corporateInfo: CorporateRiskProfile,
  corporateEddReport: ICorporateRiskProfileEddReport,
  accountInfo: SingleAccountInfo,
) => {
  const {
    id: corporateEddReportId,
    createdAt: corporateEddReportCreatedAt,
    updatedAt: corporateEddReportUpdatedAt,
    ...restOfCorporateEddReportData
  } = corporateEddReport
  const registeredCorporateAddress =
    accountCorporate.corporateAddressDetails.find(
      (address) => address.addressType.toLowerCase() === 'registered',
    )
  const corporateDirectors = accountCorporateNaturalEntities.filter(
    (entity) => entity.roles?.includes('Director'),
  )

  const parsedDirectorsAndPepDirectors = corporateDirectors.reduce(
    (acc, director, index) => {
      if (director.isPep) {
        acc.pepDirectors = `${acc.pepDirectors}${director.firstName}${
          index < corporateDirectors.length - 1 ? ', ' : ''
        }`
      }
      acc.directors = `${acc.directors}${director.firstName}${
        index < corporateDirectors.length - 1 ? ', ' : ''
      }`
      return acc
    },
    { directors: '', pepDirectors: '' },
  )
  const accountOpenedDate =
    accountCorporate && accountCorporate.createdAt
      ? new Date(accountCorporate.createdAt).toISOString()
      : null
  const corporateEddReportInitialValues: ICorporateRiskProfileEddReportInitialValues =
    useMemo(
      () => ({
        accountId: corporateInfo.accountId,
        corporateId: corporateInfo.corporateId,
        name: corporateInfo.name,
        countryOfRegistration: corporateInfo.countryOfRegistration,
        documentsStatus: corporateInfo.documentsStatus,
        natureOfBusiness: corporateInfo.natureOfBusiness,
        purposeOfAccount: corporateInfo.purposeOfAccount,
        riskScore: corporateInfo.riskScore,
        sourceOfFunds: corporateInfo.sourceOfFunds,
        isCorporateAccount: true,
        accountOpenedDate:
          accountOpenedDate ||
          (corporateEddReport.accountOpenedDate &&
            new Date(corporateEddReport.accountOpenedDate).toISOString()) ||
          new Date().toISOString(),
        companyRegistrationNumber: accountCorporate.companyRegistrationNumber,
        registeredOfficeAddress: registeredCorporateAddress
          ? `Type: ${registeredCorporateAddress.addressType}, Street: ${registeredCorporateAddress.street}, Area: ${registeredCorporateAddress.area}, Country: ${registeredCorporateAddress.country}, Code: ${registeredCorporateAddress.code}`
          : '',
        phoneNumber: accountInfo.cellNumber,
        emailAddress: accountInfo.email,
        typeOfCorporate: accountCorporate.type,
        directors: parsedDirectorsAndPepDirectors.directors,
        pepDirectors: parsedDirectorsAndPepDirectors.pepDirectors,
        affiliated: restOfCorporateEddReportData.affiliated,
        criminalHistory: restOfCorporateEddReportData.criminalHistory,
        describedNatureOfBusinessEmploymentGeneratingFunds:
          restOfCorporateEddReportData.describedNatureOfBusinessEmploymentGeneratingFunds,
        estimatedAnnualRevenue:
          restOfCorporateEddReportData.estimatedAnnualRevenue,
        estimatedNetWorth: restOfCorporateEddReportData.estimatedNetWorth,
        highRiskCountry: restOfCorporateEddReportData.highRiskCountry,
        ipLogs: corporateInfo.ipLogs || restOfCorporateEddReportData.ipLogs,
        originOfFunds: restOfCorporateEddReportData.originOfFunds,
        preventionMeasures: restOfCorporateEddReportData.preventionMeasures,
        shareHolders: restOfCorporateEddReportData.shareHolders,
        totalCryptoReceives: restOfCorporateEddReportData.totalCryptoReceives,
        totalCryptoSends: restOfCorporateEddReportData.totalCryptoSends,
        totalFiatDeposits: restOfCorporateEddReportData.totalCryptoSends,
        totalFiatWithdrawals: restOfCorporateEddReportData.totalFiatWithdrawals,
        transactionHistoryInternal:
          restOfCorporateEddReportData.transactionHistoryInternal,
        transactionHistoryExternal:
          restOfCorporateEddReportData.transactionHistoryExternal,
        ultimateBeneficialOwner:
          restOfCorporateEddReportData.ultimateBeneficialOwner,
        website: restOfCorporateEddReportData.website,
        recommendation: restOfCorporateEddReportData.recommendation,
        sourceOfWealth: corporateInfo.sourceOfWealth,
      }),
      [corporateInfo, corporateEddReport],
    )

  // Make the data properties the same
  const accountInfoDataToCheckDiff: AccountInfoDiffCheck = {
    fullName: accountInfo.name
      ? `${accountInfo.name} ${accountInfo.surname}`
      : '',
    emailAddress: accountInfo.email || '',
    phoneNumber: accountInfo.cellNumber || '',
  }
  // Pull out data not needed to check against
  const {
    id,
    accountId,
    corporateId,
    riskScore,
    ...corporateRiskProfileEddReportDataToCheckDiff
  } = corporateEddReport
  const hasCorporateRiskProfileEddReportDiff = diffCheckBetweenObjects(
    corporateRiskProfileEddReportDataToCheckDiff,
    corporateInfo,
  )
  const hasAccountCorporateRiskProfileEddReportDiff = diffCheckBetweenObjects(
    corporateRiskProfileEddReportDataToCheckDiff,
    accountInfoDataToCheckDiff,
  )
  const hasCorporateTypeDiff =
    accountCorporate.type !== corporateEddReport.typeOfCorporate

  return {
    corporateEddReportInitialValues,
    hasCorporateRiskProfileEddReportDiff,
    hasCorporateTypeDiff,
    hasAccountCorporateRiskProfileEddReportDiff,
  }
}
