import React from 'react'
import { StylesProvider } from '@material-ui/core/styles'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { AuthContextProvider } from 'context/auth'

import App from './containers/App'
import store, { history } from './store'

import './index.css'

// This is required by redux-dev-tools to prevent it from throwing an error
// eslint-disable-next-line
import Symbol_observable from 'symbol-observable'
import authService from 'services/Auth'

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID || '',
  // TODO: If we want to add specific user context
  // context: {
  //   kind: 'user',
  //   key: 'user-key-123abc',
  //   name: 'Sandy Smith',
  //   email: 'sandy@example.com',
  // },
  options: {
    sendEvents: false,
  },
})(App)

const appRoot = document.getElementById('root')
render(
  <AuthContextProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <StylesProvider injectFirst>
          <LDProvider />
        </StylesProvider>
      </ConnectedRouter>
    </Provider>
  </AuthContextProvider>,
  appRoot,
)
