import { toast } from 'react-toastify'

const convertErrorData = errorData => {
  if (
    !errorData ||
    typeof errorData === 'string' ||
    errorData instanceof String
  ) {
    return errorData
  }
  return (errorData.error && errorData.error.message) || errorData.message
}

const ErrorToast = error => {
  if (typeof error === 'string') return toast(error, { type: toast.TYPE.ERROR, autoClose: 2000 })
  if (error.response) {
    const errorMsg = error.response
      ? `${error.response.status} || ${convertErrorData(error.response.data) ||
      error.response.statusText}`
      : 'Unknown Error'
    toast(errorMsg, { type: toast.TYPE.ERROR, autoClose: 2000 })
  } else if (error.message) {
    return toast(error.message, { type: toast.TYPE.ERROR, autoClose: 2000 })
  }
}

export default ErrorToast
