import React from 'react'
import PropTypes from 'prop-types'

function ParachuteDocument({
  type,
  url,
  identifier,
  isOpen,
  handleShowDialog,
}) {
  return (
    <div colSpan={5} style={{ position: 'relative' }}>
      <p>{type.split('_').join(' ')}</p>
      <img
        style={{
          maxHeight: '650px',
          maxWidth: '85%',
          opacity: isOpen !== identifier ? '1' : '0',
        }}
        onContextMenu={e =>
          e.preventDefault()
        }
        draggable={false}
        src={url}
        alt="Could not genarate image, file could be corrupt"
        onClick={() => handleShowDialog(identifier)}
      />
    </div>
  )
}

ParachuteDocument.propTypes = {
  type: PropTypes.string,
  isOpen: PropTypes.bool,
  identifier: PropTypes.string,
  handleShowDialog: PropTypes.func,
}

export default ParachuteDocument
