import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { JsonBlock } from 'components/atoms/Details'
import CircularSpinner from 'components/atoms/Spinners'
import SimpleModal from 'components/organisms/Modal'
import { Link } from 'components/organisms/AccountIdLink/clickableId'
import React, {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import {
  PEP_SCREENING_CHECK_STATUS,
  PepScreeningResult,
} from 'redux/pepScreening/interfaces'
import { PEPScreeningForm } from './PEPScreeningForm'

interface PEPScreeningRowProps extends PepScreeningResult {
  checkPEPScreeningResult: (
    pepScreeningId: number,
    checkStatus: PEP_SCREENING_CHECK_STATUS,
    comment: string,
  ) => Promise<void>
  toggleSelectedRow: Dispatch<SetStateAction<number | null>>
  selectedRow: boolean
}

const PEPScreeningRow: FC<PEPScreeningRowProps> = (pepScreeningResult) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const [modalData, setModalData] = useState<{
    open: boolean
    data: null | PepScreeningResult
  }>({
    open: false,
    data: null,
  })
  const closeModal = () =>
    setModalData({
      open: false,
      data: null,
    })
  const handleProceedPEPScreeningResultCheck = async (formValues: {
    comment: string
    checkStatus: PEP_SCREENING_CHECK_STATUS
  }) => {
    setIsUpdating(true)
    await pepScreeningResult.checkPEPScreeningResult(
      pepScreeningResult.id,
      formValues.checkStatus,
      formValues.comment,
    )
  }
  const handleCheckboxClick = () =>
    setModalData({
      open: true,
      data: pepScreeningResult,
    })
  const uncheckPEPScreeningResult = async () => {
    setIsUpdating(true)
    await pepScreeningResult.checkPEPScreeningResult(
      pepScreeningResult.id,
      '',
      '',
    )
  }
  useEffect(() => {
    return () => {
      setIsUpdating(false)
    }
  }, [])

  return (
    <Fragment>
      <TableRow
        selected={pepScreeningResult.selectedRow}
        onClick={() =>
          pepScreeningResult.toggleSelectedRow(pepScreeningResult.id)
        }
      >
        <TableCell>{pepScreeningResult.id}</TableCell>
        <TableCell>
          {' '}
          <Link id={pepScreeningResult.accountId} />
        </TableCell>
        <TableCell>{pepScreeningResult.fullName}</TableCell>
        <TableCell>{pepScreeningResult.checkStatus || 'un-checked'}</TableCell>
        {pepScreeningResult.checked && (
          <TableCell>{pepScreeningResult.comment}</TableCell>
        )}
        <TableCell>{pepScreeningResult.createdAt}</TableCell>
        <TableCell>{pepScreeningResult.updatedAt}</TableCell>
        <TableCell onClick={(e) => e.stopPropagation()}>
          {isUpdating ? (
            <CircularSpinner />
          ) : (
            <Checkbox
              checked={pepScreeningResult.checked || false}
              onChange={
                !pepScreeningResult.checked
                  ? handleCheckboxClick
                  : uncheckPEPScreeningResult
              }
            />
          )}
        </TableCell>
      </TableRow>
      {pepScreeningResult.selectedRow && (
        <TableRow className="no-hover">
          <TableCell colSpan={9}>
            <JsonBlock
              src={pepScreeningResult.matchData}
              label={'Match data'}
            />
          </TableCell>
        </TableRow>
      )}
      <SimpleModal
        open={modalData.open}
        proceed={() => {}}
        handleClose={closeModal}
        buttonsHidden
      >
        <PEPScreeningForm
          fullName={pepScreeningResult.fullName}
          onCancel={closeModal}
          onSubmit={handleProceedPEPScreeningResultCheck}
          form="sanctionsCheckForm"
        />
      </SimpleModal>
    </Fragment>
  )
}

export default PEPScreeningRow
