import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableHead } from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { match } from 'interfaces'
import { OpenOrder } from 'interfaces/openOrders'
import {
  cancelOpenOrderForAccount,
  fetchAccountOpenOrders,
} from 'redux/trading/actions'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import OpenOrderRow from './OpenOrderRow'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'
import { useAccountOpenOrders } from 'Hooks/AccountOpenOrders/useAccountOpenOrders'
import { TablePlaceholder } from 'components/atoms/Table/styles'
interface IAccountOpenOrders {
  fetchAccountOpenOrders: (accountId: string) => Promise<void>
  cancelOpenOrderForAccount: (orderData: {
    accountId: number | string
    orderId: string
    currencyPair: string
  }) => Promise<void>
  match: match<{ accountId: string }>
  openOrders: {
    loading: boolean
    data: OpenOrder[]
    isCancelingOrder: boolean
  }
}
const AccountOpenOrders: FC<IAccountOpenOrders> = (props) => {
  const {
    confirmationModalData,
    expandedItem,
    setConfirmationModalData,
    handleProceedCancelOrder,
    resetModal,
    setExpandedItem,
  } = useAccountOpenOrders(
    props.fetchAccountOpenOrders,
    props.cancelOpenOrderForAccount,
    props.match.params.accountId,
  )
  if (props.openOrders.loading)
    return <LoadingBlock message="Fetching account open orders..." />
  if (props.openOrders.data.length === 0)
    return <TablePlaceholder>No open orders found.</TablePlaceholder>
  return (
    <Table>
      <TableHead>
        <TableCell>Total</TableCell>
        <TableCell>Allow Margin</TableCell>
        <TableCell>Short Name</TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Side</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Original Quantity</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Updated At</TableCell>
      </TableHead>
      <TableBody>
        {props.openOrders.data.map((o) => (
          <OpenOrderRow
            key={o.id}
            expandedItem={expandedItem}
            setExpandedItem={setExpandedItem}
            setConfirmationModalData={setConfirmationModalData}
            openOrder={o}
          />
        ))}
      </TableBody>
      <ConfirmationDialog
        title="Cancel open order"
        message="Please confirm you want to cancel this open order."
        open={confirmationModalData.isOpen}
        disabled={props.openOrders.isCancelingOrder}
        proceed={handleProceedCancelOrder}
        handleClose={resetModal}
      />
    </Table>
  )
}
const actions = {
  fetchAccountOpenOrders,
  cancelOpenOrderForAccount,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    openOrders: state.trading.openOrders,
  })),
  actions,
  AccountOpenOrders,
)
