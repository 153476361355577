import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { fetchCorporateSanctionInformation } from 'redux/sanctionedCorporate/actions'
import { Spacer } from 'components/atoms/Generic/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { renderDropDownField, renderInput } from 'forms/fields'
import { FormTitle, InputGroup } from 'components/atoms/Form/index'
import {
  captureAccountCorporateInformation,
  updateAccountCorporateInformation,
} from 'redux/corporate/actions'
import { required } from 'forms/fieldValidators'
import ErrorToast from 'components/molecules/ErrorToast/index'

import {
  StyledCoproateFormSection,
  StyledCorporateFormContent,
  StyledCorporateFormWrapper,
} from './Styles'

import { CORPORATE_SELECT_OPTIONS, COUNTRY_OPTIONS } from '../const/Corporate'
import { MultipleSelect } from 'components/atoms/MultipleSelect'
import getAxios from 'helpers/axios'

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formHidden: true,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isEditingCorporate !== this.props.isEditingCorporate ||
      prevProps.corporateModalView !== this.props.corporateModalView
    ) {
      if (!this.props.pristine) {
        this.props.reset('corporateForm')
      }
      this.setState({ formHidden: false })
    }
  }

  submit = async () => {
    let removedType
    const values = this.props.formValues && this.props.formValues.values
    const corporateType = values.companyDocuments.corporate_type || ''
    const {
      otherNature,
      otherType,
      otherPurpose,
      corporateRegistrationNumber,
      countryOfRegistration,
      sourceOfWealth,
      sourceOfFunds,
      productSelection,
    } = values
    if (values.type === corporateType) {
      removedType = ''
    } else {
      removedType = corporateType
    }

    const corporateData = {
      accountId: this.props.accountId,
      name: values.name,
      type: values.type,
      purpose: values.purpose,
      natureOfBusiness: values.nature,
      otherNatureOfBusiness: otherNature,
      otherPurposeOfAccount: otherPurpose,
      otherTypeOfCorporate: otherType,
      corporateRegistrationNumber,
      removedType,
      countryOfRegistration,
      sourceOfFunds,
      sourceOfWealth,
      productSelection,
    }
    try {
      if (this.props.isEditingCorporate) {
        await this.props.updateAccountCorporateInformation(
          corporateData,
          this.props.accountId,
        )
      } else {
        await this.props.captureAccountCorporateInformation(corporateData)
      }
      await this.props.fetchCorporateSanctionInformation(this.props.accountId)
      this.props.closeEditingState()
    } catch (error) {
      ErrorToast(error)
    }
  }

  handleCorporateNameChange = (e) => {
    this.setState({ corporateNameValue: e.target.value })
  }

  render() {
    const values = this.props.formValues && this.props.formValues.values
    const companyDocuments = (values && values.companyDocuments) || {}
    const {
      pristine,
      invalid,
      formType,
      isUpdatingCorporate,
      corporateModalView,
      isEditingCorporate,
    } = this.props
    const renderActionButtonText = () => {
      let buttonActionTypeText = ''
      switch (formType) {
        case 'edit':
          buttonActionTypeText = 'Updat'
          break

        default:
          buttonActionTypeText = 'Captur'
          break
      }

      return `${buttonActionTypeText}${
        isUpdatingCorporate ? 'ing' : 'e'
      } Corporate
     Details`
    }

    const form = document.getElementById('corporateForm')
    const formButton = document.getElementById('formBtn')

    const formHeight = (form && form.clientHeight) || 0
    const formButtonHeight = (formButton && formButton.clientHeight) || 0

    const finalFormHeight = formHeight - formButtonHeight
    return (
      <StyledCorporateFormWrapper
        id="corporateForm"
        active={
          corporateModalView ||
          isEditingCorporate ||
          this.props.formType === 'create'
        }
        formHeight={finalFormHeight}
      >
        <>
          <FormTitle>Business Details</FormTitle>
          <StyledCorporateFormContent>
            <StyledCoproateFormSection>
              <Spacer margin="8px">
                <Field
                  margin={`0 ${BASE * 2}px 0 0`}
                  fullWidth
                  label="Corporate Name"
                  name={'name'}
                  component={renderInput}
                  validate={[required]}
                  disabled={corporateModalView}
                />
                <Spacer margin="8px 0px" />
                <Field
                  margin={`0 ${BASE * 2}px 0 0`}
                  fullWidth
                  label={"corporate's registration number"}
                  name={'corporateRegistrationNumber'}
                  component={renderInput}
                  disabled={corporateModalView}
                />
                <Spacer margin="8px 0px" />
                <Field
                  label={'Nature of business'}
                  name={'nature'}
                  width={'100%'}
                  margin={`0 ${BASE * 2}px 0 0`}
                  validate={[required]}
                  disabled={corporateModalView}
                  options={CORPORATE_SELECT_OPTIONS.NATURE.sort((a, b) => {
                    if (a.label === b.label) {
                      return 0
                    }
                    if (a.label === 'Other') {
                      return 1
                    }
                    if (b.label === 'Other') {
                      return -1
                    }
                    if (a.label < b.label) {
                      return -1
                    }
                    if (a.label > b.label) {
                      return 1
                    }
                    return 0
                  })}
                  component={renderDropDownField}
                  fullWidth
                  showNoneLabel={false}
                />
                <Spacer margin="8px 0px" />
                {values && values.nature === 'OTHER' && (
                  <Field
                    margin={`0 ${BASE * 2}px 0 0`}
                    fullWidth
                    label="Specify other nature"
                    name={'otherNature'}
                    component={renderInput}
                    validate={[required]}
                    disabled={corporateModalView}
                  />
                )}
                <Spacer margin="8px 0px" />
                <Field
                  label={'Source of wealth'}
                  name={'sourceOfWealth'}
                  fullWidth
                  margin={`0 ${BASE * 2}px 0 0`}
                  disabled={corporateModalView}
                  options={CORPORATE_SELECT_OPTIONS.SOURCE_OF_WEALTH}
                  component={renderDropDownField}
                  showNoneLabel={false}
                />
                <Field
                  label={'Product selection'}
                  name={'productSelection'}
                  fullWidth
                  margin={`8px ${BASE * 2}px 0 0`}
                  disabled={corporateModalView}
                  options={CORPORATE_SELECT_OPTIONS.PRODUCT_SELECTION}
                  component={MultipleSelect}
                  showNoneLabel={false}
                />
                <Spacer margin="8px 0px" />
              </Spacer>
            </StyledCoproateFormSection>
            <StyledCoproateFormSection>
              <Spacer margin="8px">
                <Field
                  label={'Type of corporate'}
                  name={'type'}
                  width={'100%'}
                  margin={`0 ${BASE * 2}px 0 0`}
                  disabled={
                    corporateModalView ||
                    Object.keys(companyDocuments).length > 5
                  }
                  validate={[required]}
                  options={CORPORATE_SELECT_OPTIONS.TYPE.sort((a, b) =>
                    a.label < b.label ? -1 : 1,
                  )}
                  component={renderDropDownField}
                  fullWidth
                  showNoneLabel={false}
                />
                <Spacer margin="8px 0px" />
                {values && values.type === 'OTHER' && (
                  <Field
                    margin={`0 ${BASE * 2}px 0 0`}
                    fullWidth
                    label="Specify other type"
                    name={'otherType'}
                    component={renderInput}
                    validate={[required]}
                    disabled={corporateModalView}
                  />
                )}
                <Spacer margin="8px 0px" />
                <Field
                  label={'Purpose of account'}
                  name={'purpose'}
                  width={'100%'}
                  margin={`0 ${BASE * 2}px 0 0`}
                  validate={[required]}
                  disabled={corporateModalView}
                  options={CORPORATE_SELECT_OPTIONS.PURPOSE}
                  component={renderDropDownField}
                  fullWidth
                  showNoneLabel={false}
                />

                <Spacer margin="8px 0px" />
                {values && values.purpose === 'OTHER' && (
                  <Field
                    margin={`0 ${BASE * 2}px 0 0`}
                    fullWidth
                    label="Specify other purpose"
                    name={'otherPurpose'}
                    component={renderInput}
                    validate={[required]}
                    disabled={corporateModalView}
                  />
                )}
                <Spacer margin="8px 0px" />
                <Field
                  label={'Country of registration'}
                  name={'countryOfRegistration'}
                  fullWidth
                  margin={`0 ${BASE * 2}px 0 0`}
                  validate={[required]}
                  disabled={corporateModalView}
                  options={COUNTRY_OPTIONS}
                  component={renderDropDownField}
                  showNoneLabel={false}
                />
                <Spacer margin="8px 0px" />
                <Field
                  label={'Source of funds'}
                  name={'sourceOfFunds'}
                  fullWidth
                  margin={`0 ${BASE * 2}px 0 0`}
                  disabled={corporateModalView}
                  options={CORPORATE_SELECT_OPTIONS.SOURCE_OF_FUNDS}
                  component={renderDropDownField}
                  showNoneLabel={false}
                />
              </Spacer>
            </StyledCoproateFormSection>
          </StyledCorporateFormContent>

          {((isEditingCorporate && !corporateModalView) ||
            formType === 'create') && (
            <Spacer margin="8px">
              <InputGroup justify={'flex-start'}>
                <MuiButton
                  id="formBtn"
                  onClick={this.submit}
                  disabled={
                    corporateModalView ||
                    pristine ||
                    invalid ||
                    isUpdatingCorporate
                  }
                  margin={`0 ${BASE * 2}px 0 0`}
                  variant="contained"
                  color="primary"
                >
                  {renderActionButtonText()}
                </MuiButton>
              </InputGroup>
            </Spacer>
          )}
        </>
      </StyledCorporateFormWrapper>
    )
  }
}
const CorporateAccountForm = reduxForm({
  form: 'corporateForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(Form)

const mapStateToProps = (state, ownProps) => ({
  formValues: state.form[ownProps.form],
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      captureAccountCorporateInformation,
      updateAccountCorporateInformation,
      fetchCorporateSanctionInformation,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateAccountForm)
