import { Table } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCsvDataTable = styled(Table)`
  border: 2px solid lightgrey;
`

export const StyledCsvAccountIdsData = styled.div`
  max-height: 200px;
  overflow: scroll;
`

export const StyledScheduledDateWrapper = styled.div`
margin-right: 12px;
`
export const StyledMobilePageInputWrapper = styled.div`
width: 400px;
`

export const StyledOptionalText = styled.p`
margin-bottom: 5px;
`
