import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import { required, isNumber, noSpacesAllowed } from 'forms/fieldValidators'
import {
  TransparentFormContainer,
  Button,
  InputGroup,
} from 'components/atoms/Form'

import { submit } from './submit'

import { renderDropdown, renderInput } from '../fields'

const FormSetAccountsConfirmations = props => {
  const { handleSubmit, submitting, invalid } = props
  return (
    <TransparentFormContainer width={'100%'} onSubmit={handleSubmit(submit)}>
      <InputGroup align="baseline">
        <Field
          label={'Currency (required)'}
          name={'currency'}
          margin={`0 ${BASE * 2}px 26px 0`}
          width={'20%'}
          component={renderDropdown}
          options={[
            { value: 'BTC', label: 'BTC' },
            { value: 'ETH', label: 'ETH' },
            { value: 'XRP', label: 'XRP' },
          ]}
          validate={[required]}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Below Threshold (required) '}
          name={'belowThresholdConfirmationCount'}
          width={'25%'}
          margin={`0 ${BASE * 2}px 26px 0`}
          validate={[required, isNumber, noSpacesAllowed]}
          component={renderInput}
          placeholder={'Below threshold'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Above Threshold (required)'}
          name={'aboveThresholdConfirmationCount'}
          width={'25%'}
          margin={`0 ${BASE * 2}px 26px 0`}
          validate={[required, isNumber, noSpacesAllowed]}
          component={renderInput}
          placeholder={'Above threshold'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Threshold (required)'}
          name={'threshold'}
          width={'25%'}
          margin={`0 ${BASE * 2}px 26px 0`}
          validate={[required, isNumber, noSpacesAllowed]}
          component={renderInput}
          placeholder={'Threshold'}
        />
        <Button type="submit" main="true" disabled={submitting || invalid}>
          Submit
        </Button>
      </InputGroup>
    </TransparentFormContainer>
  )
}

const ReduxFormSetAccountsConfirmations = reduxForm({
  form: 'SetConfirmationForm',
  validate: values => {
    const errors = {}
    if (
      !values.currency &&
      !values.belowThresholdConfirmationCount &&
      !values.aboveThresholdConfirmationCount &&
      !values.threshold
    ) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
})(FormSetAccountsConfirmations)

export default ReduxFormSetAccountsConfirmations
