import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchCompetitionList } from 'redux/displayList/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Button } from 'components/atoms/Form/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'


const GlobalUnsubscribe = ({
  isGloballyUnsubscribed,
  handleUpdateGlobalSubcriptions,
}) => {
  const [
    isUpdatingGlobalSubscription,
    setIsUpdatingGlobalSubscription,
  ] = useState(false)
  const [bulkModalOpen, setBulkModalOpen] = useState(false)
  const actionLoadingText = actionText =>
    isUpdatingGlobalSubscription
      ? `${actionText.replace('e', '')}ing`
      : actionText

  useEffect(
    () => () => {
      setIsUpdatingGlobalSubscription(false)
    },
    [],
  )

  const openModel = () => {
    setBulkModalOpen(true)
  }

  const handleCloseModal = () => {
    setBulkModalOpen(false)
    setIsUpdatingGlobalSubscription(false)
  }

  const handleProceed = async () => {
    setIsUpdatingGlobalSubscription(true)
    await handleUpdateGlobalSubcriptions(!isGloballyUnsubscribed)
    setBulkModalOpen(false)
  }

  return (
    <div>
      {
        <>
          <Button
            style={{ marginTop: '12px' }}
            disabled={isUpdatingGlobalSubscription}
            onClick={async () => {
              openModel()
            }}
            main="true"
            type="button"
          >
            {`Globally ${actionLoadingText(
              isGloballyUnsubscribed ? 'Subscribe' : 'Unsubscribe',
            )}`}
          </Button>
          <ConfirmationDialog
            title={'Global Subscription'}
            message={`Are you sure you want to Globally ${isGloballyUnsubscribed ? 'Subscribe' : 'Unsubscribe'
              }?`}
            open={bulkModalOpen}
            proceed={() => handleProceed()}
            handleClose={handleCloseModal}
            disabled={isUpdatingGlobalSubscription}
            button={{
              confirm: {
                text: 'Proceed',
              },
              decline: {
                text: 'Cancel',
              },
            }}
          />
        </>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  listData: state.listData,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompetitionList,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalUnsubscribe)
