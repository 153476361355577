import { FETCH_REVIEW_ERROR, FETCH_REVIEW_PROCESS, FETCH_REVIEW_SUCCESS } from './actions'

const initialState = {
  byId: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REVIEW_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'loading',
          },
        },
      }
    }

    case FETCH_REVIEW_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data,
          },
        },
      }
    }

    case FETCH_REVIEW_ERROR: {
      return {
        ...state,
        review: {
          status: 'done',
          data: [],
        },
      }
    }

    default:
      return state
  }
}
