import { MobileNotification } from 'interfaces/mobileNotifications'
import { MobileNotificationsActions } from './actions'

export interface MobileNotificationsInitialState {
  mobileCampaigns: MobileNotification[]
  fetchingMobileNotifications: 'idle' | 'pending' | 'done'
  sendingMobileNotifications: 'idle' | 'sending'
  marketingMessagingCampaignFormOpen: boolean
}

interface Action {
  type: MobileNotificationsActions | 'CHECK_CAMPAIGN_SUCCESS'
  payload:
    | MobileNotification[]
    | MobileNotification
    | boolean
    | { campaignId: number }
}

const initialState: MobileNotificationsInitialState = {
  mobileCampaigns: [],
  fetchingMobileNotifications: 'idle',
  sendingMobileNotifications: 'idle',
  marketingMessagingCampaignFormOpen: false,
}

export default (
  state = initialState,
  action: Action,
): MobileNotificationsInitialState => {
  switch (action.type) {
    case 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_START': {
      return {
        ...state,
        fetchingMobileNotifications: 'pending',
      }
    }
    case 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS': {
      return {
        ...state,
        mobileCampaigns: action.payload as MobileNotification[],
        fetchingMobileNotifications: 'done',
      }
    }
    case 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_ERROR': {
      return {
        ...state,
        fetchingMobileNotifications: 'idle',
      }
    }
    case 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_START': {
      return {
        ...state,
        sendingMobileNotifications: 'sending',
      }
    }
    case 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS': {
      return {
        ...state,
        mobileCampaigns: [
          action.payload as MobileNotification,
          ...state.mobileCampaigns,
        ],
        sendingMobileNotifications: 'idle',
      }
    }
    case 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR': {
      return {
        ...state,
        sendingMobileNotifications: 'idle',
      }
    }

    case 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_START': {
      return {
        ...state,
        sendingMobileNotifications: 'sending',
      }
    }

    case 'UPDATE_MOBILE_NOTIFICATION_CAMPAIGN': {
      const updatedMobileNotification = action.payload as MobileNotification
      const updatedMobileCampaigns = [...state.mobileCampaigns]
      const campaignIndexToUpdate = updatedMobileCampaigns.findIndex(
        (campaign) => campaign.id === updatedMobileNotification.id,
      )
      if (campaignIndexToUpdate > -1) {
        updatedMobileCampaigns[campaignIndexToUpdate] = {
          ...updatedMobileNotification,
        }
      }
      return {
        ...state,
        mobileCampaigns: updatedMobileCampaigns,
        sendingMobileNotifications: 'idle',
      }
    }

    case 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS': {
      const payload = action.payload as { campaignId: number }
      const updatedMobileCampaigns = [...state.mobileCampaigns]
      const campaignIndexToUpdate = updatedMobileCampaigns.findIndex(
        (campaign) => campaign.id === payload.campaignId,
      )
      if (campaignIndexToUpdate > -1) {
        const campaignToUpdate = updatedMobileCampaigns[campaignIndexToUpdate]
        updatedMobileCampaigns[campaignIndexToUpdate] = {
          ...campaignToUpdate,
          status: 'SCHEDULED',
        }
      }
      return {
        ...state,
        mobileCampaigns: updatedMobileCampaigns,
        sendingMobileNotifications: 'idle',
      }
    }
    case 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR': {
      return {
        ...state,
        sendingMobileNotifications: 'idle',
      }
    }

    case 'TOGGLE_MOBILE_MESSAGING_CAMPAIGN_FORM': {
      return {
        ...state,
        marketingMessagingCampaignFormOpen: action.payload as boolean,
      }
    }

    default:
      return state
  }
}
