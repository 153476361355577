export const MODIFIED_LIST = 'MODIFIED_LIST'
export const CLEAR_COMPETITION_LIST = 'CLEAR_COMPETITION_LIST'

export const updateCompetitionList = data => dispatch => {
  dispatch({
    type: MODIFIED_LIST,
    payload: {
      data,
    },
  })
}

export const clearCompetitionList = () => dispatch => {
  dispatch({
    type: CLEAR_COMPETITION_LIST,
  })
}
