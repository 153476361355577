import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { reset } from 'redux-form'
import { updateNotesForBlockchainReceives } from 'redux/unallocatedBlockchainReceives/actions'

export const submit = async (values, dispatch) => {
  const {
    receivesId, data, notes,
  } = values
  data.notes = notes
  try {
    const res = await getAxios().post('/unallocated/receives/notes', { receivesId, data })
    if (res.status === 200) {
      toast(`${res.data}`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      dispatch(updateNotesForBlockchainReceives(receivesId))
      dispatch(reset(`unallocatedReceivesNotesForm+${receivesId}`))
    } else {
      toast('Error occured while adding a comment', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
  } catch (e) {
    toast(`Failed to add a note,|| ${e}`, {
      type: toast.TYPE.ERROR,
      autoClose: 4000,
    })
  }
}
