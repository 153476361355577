import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { Flexbox } from 'components/layout'

import { required, maxLength256 } from 'forms/fieldValidators'
import RawData from 'components/atoms/RawDataDualAuth'
import {
  Details,
  DetailsBlockWithTitle,
  InfoLabel,
  JsonBlock,
} from 'components/atoms/Details'
import {
  BlueLinkButton,
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import Notes from 'components/atoms/Notes'

import { submitRecieves } from './submit'

import { renderDropdown, renderInput } from '../fields'

const renderAccountIdField = accountId => (
  <Field
    label={'Account ID (required)'}
    name={'accountId'}
    width={'50%'}
    margin={`0 ${BASE * 2}px 0 0`}
    value={accountId || ''}
    disabled={!!accountId}
    validate={[required]}
    component={renderInput}
  />
)

let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    showAddNote,
    showRawData,
    expandAddNote,
    expandRawData,
    comments,
    id,
  } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Credit Unallocated Receives</FormTitle>
      <InputGroup
        justify={'space-between'}
        align={'baseline'}
        direction={'row'}
      >
        <Field
          label={'Receives Status'}
          name={'status'}
          margin={`0 ${BASE * 2}px 0 0`}
          flex={'0.3'}
          width="50%"
          component={renderDropdown}
          options={[{ value: 'APPROVE', label: 'Approve' }]}
        />
        {renderAccountIdField(rawData.accountId)}
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required]}
          component={renderInput}
          justify={'space-around'}
          margin={`0 ${BASE * 2}px 0 0`}
          marginTop={26}
        />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          main="true"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
      <Details>
        <DetailsBlockWithTitle title="Notes">
          <Notes depositId={id} comments={comments} />
        </DetailsBlockWithTitle>
      </Details>
      <Flexbox
        direction={'column'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <Flexbox
          style={{ color: 'white' }}
          direction={'row'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showRawData}
          >
            {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
          </BlueLinkButton>
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showAddNote}
          >
            {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
          </BlueLinkButton>
        </Flexbox>
        {expandRawData && (
          <Fragment>
            <Flexbox
              marginBottom={'10'}
              marginTop={'10'}
              direction={'column'}
              align={'flex-start'}
              justify={'space-around'}
            >
              <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
              <RawData data={rawData} />
            </Flexbox>
          </Fragment>
        )}
      </Flexbox>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submitRecieves })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
