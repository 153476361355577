import LoadingBlock from 'components/molecules/LoadingBlock/index'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getAccountDetails } from 'redux/selectors/details'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { styled } from '@material-ui/core/styles'
import { TablePlaceholder, TitleContainer } from 'components/atoms/Table/styles'
import { getAccountBalances } from 'redux/selectors/balances'
import ExpandingItem from 'components/atoms/ExpandingItem/index'
import BigNumber from 'bignumber.js'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { handleCopyToClipBoard } from 'helpers/utils'
import { formatCurrency, formatZar } from 'helpers/currency'

class AllBalances extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      expandedRow: '',
    }
  }

  settingExpand = (panel) => {
    this.setState({ expanded: panel })
  }

  addTotalAmount = (balance) => {
    const total = balance.result.reduce((acc, currentBalanceResult) => {
      const { zarTotal } = currentBalanceResult
      const checkedCurrentValue = zarTotal
        ? parseFloat(
            typeof zarTotal === 'string'
              ? zarTotal.split(',').join('')
              : zarTotal,
          )
        : 0
      return acc + checkedCurrentValue
    }, 0)
    return `R${new BigNumber(total).toFormat(2)}`
  }

  toggleSelectedRow = (id) => {
    if (id !== this.state.expandedRow) {
      this.setState({ expandedRow: id })
    } else {
      this.setState({ expandedRow: '' })
    }
  }

  toggleRow = (id) => {
    this.toggleSelectedRow(id)
  }

  getTotalPortfolioValue = (balances) => {
    const totalBalance = balances.reduce((acc, currentBalance) => {
      const total = currentBalance.result.reduce((acc2, currBalanceResult) => {
        const { zarTotal } = currBalanceResult
        const checkedCurrentValue = zarTotal
          ? parseFloat(
              typeof zarTotal === 'string'
                ? zarTotal.split(',').join('')
                : zarTotal,
            )
          : 0
        return acc2 + checkedCurrentValue
      }, 0)
      acc = acc + total
      return acc
    }, 0)
    return `R${new BigNumber(totalBalance).toFormat(2)}`
  }

  addAllAssets = (a, b, c, d) => {
    return Number(a) + Number(b) + Number(c) + Number(d)
  }

  handleChange = (panel) => {
    this.settingExpand(panel)
  }

  copyToClipboard = (e, value) => {
    e.stopPropagation()
    handleCopyToClipBoard(value)
  }

  render() {
    const Container = styled(TableContainer)({
      maxHeight: 1400,
    })

    const {
      allBalances: { allBalancesStatus, allBalancesData },
      details: { status, id, primaryAccountSubAccounts },
    } = this.props

    return (
      <Fragment>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && allBalancesStatus === 'done' && (
          <>
            <TitleContainer>
              {`Total ZAR balance: ${this.getTotalPortfolioValue(
                allBalancesData,
              )}`}
            </TitleContainer>

            {allBalancesData
              .sort((a, b) => {
                return a.accountPublicId - b.accountPublicId
              })
              .map((sub, i) => {
                const t = primaryAccountSubAccounts.reduce(
                  (accumulator, subaccount) => {
                    if (subaccount.publicId === sub.accountPublicId)
                      accumulator = subaccount
                    return accumulator
                  },
                  '',
                )
                return (
                  <ExpandingItem
                    key={i}
                    title={`Total ZAR balance: ${this.addTotalAmount(sub)}`}
                    expanded={
                      this.state.expanded ===
                      `Total ZAR balance: ${this.addTotalAmount(sub)}-panel`
                    }
                    link={{
                      label:
                        sub.accountLabel === 'Primary'
                          ? 'Main'
                          : sub.accountLabel,
                      id: sub.accountLabel === 'Primary' ? id : t.accountId,
                    }}
                    onExpand={this.handleChange}
                    opensMultiple
                    expandedView={
                      <Paper>
                        <Container>
                          <Table
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                          >
                            <TableHead align="right">
                              <TableRow>
                                <TableCell>Currency</TableCell>
                                <TableCell>Assets</TableCell>
                                <TableCell>Debt</TableCell>
                                <TableCell>Equity</TableCell>
                                <TableCell>Available</TableCell>
                                <TableCell>Trade Reserved</TableCell>
                                <TableCell>Collateral Reserved</TableCell>
                                <TableCell>Lent</TableCell>
                                <TableCell>Total In Rands</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sub.accountLabel !== 'Primary' &&
                                sub.result.length == 0 && (
                                  <TableCell colSpan={7}>
                                    <TablePlaceholder>
                                      {'Subaccount has no balances'}
                                    </TablePlaceholder>
                                  </TableCell>
                                )}
                              {sub.accountLabel === 'Primary' &&
                                sub.result.length == 0 && (
                                  <TableCell colSpan={7}>
                                    <TablePlaceholder>
                                      {'Account has no balances'}
                                    </TablePlaceholder>
                                  </TableCell>
                                )}

                              {sub.result && sub.result.length > 0 && (
                                <>
                                  {sub.result.map((subacc, index) => {
                                    const { longName, shortName } =
                                      subacc.currency
                                    const {
                                      total,
                                      reserved,
                                      available,
                                      zarTotal,
                                      lendReserved,
                                      borrowCollateralReserved,
                                      borrowedAmount,
                                    } = subacc
                                    const assetsData = formatCurrency(
                                      shortName,
                                      this.addAllAssets(
                                        available,
                                        reserved,
                                        borrowCollateralReserved,
                                        lendReserved,
                                      ),
                                    )

                                    return (
                                      <Fragment key={index}>
                                        <TableRow
                                          onClick={() => this.toggleRow(index)}
                                        >
                                          <TableCell>{longName}</TableCell>
                                          <TableCell
                                            style={{ cursor: 'copy' }}
                                            onClick={(e) =>
                                              this.copyToClipboard(
                                                e,
                                                assetsData,
                                              )
                                            }
                                          >
                                            {assetsData}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(
                                              shortName,
                                              borrowedAmount,
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(shortName, total)}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(
                                              shortName,
                                              available,
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(
                                              shortName,
                                              reserved,
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(
                                              shortName,
                                              borrowCollateralReserved,
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {formatCurrency(
                                              shortName,
                                              lendReserved,
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {formatZar(zarTotal)}
                                          </TableCell>
                                        </TableRow>
                                        {this.state.expandedRow === index && (
                                          <TableCell colSpan={9}>
                                            <Details>
                                              <DetailsBlockWithTitle
                                                title={'Raw Data'}
                                              >
                                                <RawData
                                                  data={subacc}
                                                  title={'Raw Data'}
                                                />
                                              </DetailsBlockWithTitle>
                                            </Details>
                                          </TableCell>
                                        )}
                                      </Fragment>
                                    )
                                  })}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </Container>
                      </Paper>
                    }
                  />
                )
              })}
          </>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  balances: getAccountBalances(state),
  allBalances: state.balances.allBalances,
  details: getAccountDetails(state),
})

export default connect(mapStateToProps, null)(AllBalances)
