import React, { useMemo } from 'react'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
  SimpleFormError,
} from 'components/atoms/Form'
import { UploadingBlock } from 'components/molecules/LoadingBlock'
import { renderFileInput, renderDropdown } from 'forms/fields'

import { submitFilter } from './submit'

import { checkRole, ROLES } from '../../helpers/roleBasedAccess'

const FormUploadCsv = props => {
  const {
    handleSubmit,
    submitting,
    invalid,
    error,
    pristine,
    loading,
    config,
  } = props
  const hasFiatUploadRights = checkRole(ROLES.depositUploadRole)

  const sourceOptions = useMemo(
    () =>
      config.bankAccounts.reduce((arr, curr) => {
        if (!curr.csvEnabled) return arr
        return [
          ...arr,
          {
            value: curr.id,
            label: `${curr.name} - ${curr.accountNumber}`,
            country: curr.country,
          },
        ]
      }, []),
    [config.bankAccounts],
  )

  if (!hasFiatUploadRights) return null

  return (
    <FormContainer onSubmit={handleSubmit(submitFilter)}>
      <FormTitle>Upload Csv</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Deposit Source'}
          name={'source'}
          marginRight={BASE * 2}
          width={'50%'}
          component={renderDropdown}
          options={sourceOptions}
        />
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          name={'csv'}
          component={renderFileInput}
          accept={'.csv'}
          width={'50%'}
          marginRight={BASE * 2}
        />
      </InputGroup>
      {error && !pristine && <SimpleFormError message={error} />}
      <InputGroup justify={'flex-end'}>
        {!loading && (
          <Button type="submit" main="true" disabled={submitting || invalid}>
            Upload
          </Button>
        )}
        {loading && <UploadingBlock />}
      </InputGroup>
    </FormContainer>
  )
}

const validateFields = values => {
  const errors = {}
  if (!values.csv) {
    errors._error = 'Csv file required'
  }
  return errors
}

const ReduxFormUploadCsv = reduxForm({
  form: 'uploadCsv',
  validate: validateFields,
  initialValues: { source: null },
})(FormUploadCsv)

export default ReduxFormUploadCsv
