import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearDownloadStatus } from 'redux/fiat/actions'
import { Flexbox } from 'components/layout/index'
import { InfoBlock } from 'components/atoms/Details/index'
import { InputGroup } from 'components/atoms/Form/index'
import ExpandingItem from 'components/atoms/ExpandingItem/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'

class RenderResponseAfterDownload extends React.Component {
  render() {
    const {
      fiatCSVDownload,
      done,
      selectedWithdrawals: { data },
      clearDownloadStatus,
      name,
      selectedWithdrawalsTotalAmount,
      expanded,
      setExpand,
    } = this.props
    const { fileName, failedWithdraws } = fiatCSVDownload.data

    return (
      <ExpandingItem
        title={name}
        itemsAmount={data && data.length || 0}
        expanded={expanded}
        onExpand={setExpand}
        expandedView={(
          <Fragment>
            {fiatCSVDownload && (
              <Flexbox align={'left'} justify={'center'} direction={'row'}>
                <InfoBlock label={'File Name:'} value={fileName} />

                <InfoBlock
                  label={'Failed Withdraws: '}
                  value={failedWithdraws.length}
                />
                <InfoBlock
                  maxWidth={'50em'}
                  label={'Withdraws:'}
                  value={data.length}
                />
                <InfoBlock
                  maxWidth={'50em'}
                  label={'Total Value'}
                  value={'R' + selectedWithdrawalsTotalAmount.value}
                />
              </Flexbox>
            )}
            <InputGroup justify={'flex-end'}>
              <MuiButton
                onClick={() => {
                  done()
                  clearDownloadStatus()
                }}
              >
                Done
              </MuiButton>
            </InputGroup>
          </Fragment>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  fiatCSVDownload: state.fiat.download,
  pendingWithdraws: state.fiat.pendingWithdraws,
  selectedWithdrawals: state.fiat.selectedWithdrawals,
  selectedWithdrawalsTotalAmount: state.fiat.selectedWithdrawalsTotalAmount,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearDownloadStatus,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderResponseAfterDownload)
