import React, { Fragment, useEffect, useState } from 'react'
import { BASE } from 'theme/base-units'
import getAxios from 'helpers/axios'
import moment from 'moment'
import {
  ContentContainer,
  Spacer,
  StyledAnchor,
} from 'components/atoms/Generic/index'

import {
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import { Actions, ActionButton } from 'components/atoms/Table/styles'
import { Title } from 'components/atoms/Title/index'
import NoData from 'components/molecules/NoData/index'
import ErrorToast from 'components/molecules/ErrorToast/index'

import {
  Details,
  DetailsBlock,
} from './styles'

import config from '../../../../config'

interface VeriffAddress {
  fullAddress: string
}

interface VeriffPerson {
  firstName?: string,
  lastName?: string,
  idNumber?: string,
  dateOfBirth?: string,
  nationality?: string,
  gender?: string,
  addresses?: VeriffAddress[]
}

interface VeriffDocument {
  number?: string,
  type: string,
  country?: string,
  expiry?: string
}

interface VeriffInfo {
  sessionIdentifier: string,
  verificationOutcome: string,
  attemptStatus: string,
  reason?: string,
  person: VeriffPerson,
  document: VeriffDocument,
  decisionAt?: string,
  attemptCount: number
}

export enum VeriffKycType {
  IDENTITY = 'identity',
  ADDRESS = 'address'
}

const buildName = (person: VeriffPerson, kycType: VeriffKycType): string => {
  if (kycType === VeriffKycType.ADDRESS) return `${person.firstName}`
  return `${person.firstName} ${person.lastName}`
}

const renderVeriffInfo = (info: VeriffInfo, kycType: VeriffKycType) => {
  const {
    attemptStatus, verificationOutcome, decisionAt, attemptCount, person,
  } = info

  const name = person ? buildName(person, kycType) : null

  return (
    attemptStatus === 'APPROVED' && (
      <Fragment>
        { name && <InfoBlock label={'Name'} value={name || '--'} /> }
        <InfoBlock label={'Attempt Status'} value={attemptStatus || '--'} />
        <InfoBlock
          label={'Check Created'}
          value={moment(decisionAt).format('YYYY-MMM-DD HH:mm a') || '--'}
        />
        <InfoBlock label={'Check Count'} value={attemptCount || '--'} />
        <InfoBlock label={'Check Result'} value={verificationOutcome || '--'} />
        { person?.addresses?.map(a => <InfoBlock label={'Address'} value={a.fullAddress || '--'} />)}
      </Fragment>
    )
  )
}

const renderVeriffButton = (userInfo: VeriffInfo) => {
  const { sessionIdentifier } = userInfo
  const link = `${config.veriff.veriffExternalUrl}/verifications/${sessionIdentifier}`

  return (
    <StyledAnchor href={link} target="_blank">
      <ActionButton variant="contained" color="primary">View in Veriff</ActionButton>
    </StyledAnchor>
  )
}

interface IVeriffAccountInformationProps {
  accountId: number,
  kycType: VeriffKycType
}

const VeriffAccountInformation = ({ accountId, kycType }: IVeriffAccountInformationProps) => {
  const [userVeriffInformation, setUserVeriffInformation] = useState<VeriffInfo | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    let abortFlag = false

    const fetchUserVeriffInformation = async () => {
      setIsLoading(true)
      try {
        const response = await getAxios().get(`/veriff/account/${accountId}/${kycType}`)

        if (abortFlag) return

        const { status, data } = response

        if (status === 200) {
          setUserVeriffInformation(Object.keys(data).length > 0 ? data : null)
          setIsLoading(false)
        }
      } catch (e) {
        setError(`Error occurred when fetching Veriff ${kycType} info`)
        ErrorToast(e)
      }
      setIsLoading(false)
    }

    fetchUserVeriffInformation()

    return () => {
      abortFlag = true
    }
  }, [accountId])
  if (!userVeriffInformation) return null
  return (
    <ContentContainer bgcolor={'0px solid transparent'} style={{ marginBottom: `${BASE * 3}px` }}>
      <Title type="h3" text={`Veriff ${kycType} account information`} />
      <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
        <DetailsBlock flow={'row'}>
          {isLoading && <IconLoading color={'primary'}/>}
          {error && <InfoBlock label={'Fetching Error'} value={`${error}`} />}
          {!isLoading &&
            userVeriffInformation != null && (
            <Fragment>
              {renderVeriffInfo(userVeriffInformation, kycType)}
              <Actions justify={'space-between'}>
                {renderVeriffButton(userVeriffInformation)}
              </Actions>
            </Fragment>
          )}
          {userVeriffInformation == null &&
            !error &&
            !isLoading && (
            <NoData
              height={100}
              width={380}
              messageSpacing={1}
              hasMagnifier={false}
              headingType={'h4'}
              headingText={'Not submitted'}
              message={`This user has no Veriff ${kycType} information`}
            />
          )}
        </DetailsBlock>
        <Spacer margin="10px 0px" />
        {!isLoading &&
          userVeriffInformation != null && (
          <DetailsBlock>
            <JsonBlock
              overflow={'scroll'}
              src={userVeriffInformation}
              title={'Raw Data'}
            />
          </DetailsBlock>
        )}
      </Details>
    </ContentContainer>
  )
}

export default VeriffAccountInformation
