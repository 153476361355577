
import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = (props) => {
  const { expandItem } = props
  return (
    <TableHeader onClick={expandItem}>
      <TableCell width={'7%'}>Account Id</TableCell>
      <TableCell width={'7%'}>Currency</TableCell>
      <TableCell width={'20%'}>Amount</TableCell>
      <TableCell width={'25%'}>Action Type</TableCell>
      <TableCell width={'20%'}>Reason</TableCell>
      <TableCell width={'12%'}>Created At</TableCell>
      <TableCell width={'10%'}/>
    </TableHeader>
  )
}
