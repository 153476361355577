import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAccountsWithFullMarginInfo } from 'redux/margin/actions'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { styled } from '@material-ui/core/styles'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { JsonBlock } from 'components/atoms/Details/index'

class AccountsWithFullMarginInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedRow: '',
    }
  }

  componentDidMount() {
    this.props.fetchAccountsWithFullMarginInfo()
  }

  toggleSelectedRow = id => {
    if (id !== this.state.expandedRow) {
      this.setState({ expandedRow: id })
    } else {
      this.setState({ expandedRow: '' })
    }
  }

  leverageHealthTotal = (marginFraction, maintenanceMarginFraction) => {
    return `${Math.round(marginFraction / maintenanceMarginFraction) * 100}%`
  }

  render() {
    const Container = styled(TableContainer)({
      maxHeight: 1400,
    })

    const {
      accountsWithFullMarginInfo: { status, data },
    } = this.props.margin
    return (
      <Fragment>
        <h3>Margin Full Accounts Info</h3>
        <Paper >
          <Container>
            <Table aria-labelledby="tableTitle"
              aria-label="enhanced table">
              <TableHead align='right' >
                <TableRow>
                  <TableCell >Account Id</TableCell>
                  <TableCell >Reference Currency</TableCell>
                  <TableCell >Margin Fraction</TableCell>
                  <TableCell >Total Borrowed In Reference</TableCell>
                  <TableCell >Collateralised Balances In Reference Weighted</TableCell>
                  <TableCell >Account Leverage Health</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {status === 'loading' &&
                  <TableRow>
                    <TableCell colSpan={6}>
                      <LoadingBlock />
                    </TableCell>
                  </TableRow>
                }
                {status === 'done' && data.map((marginInfo, index) => {
                  const {
                    collateralisedBalancesInReferenceWeighted,
                    totalBorrowedPlusPositionsAtOpenInReference, marginFraction,
                    maintenanceMarginFraction,
                    referenceCurrency: { shortName },
                  } = marginInfo.marginFraction
                  return (
                    <Fragment key={index}>
                      <TableRow onClick={() => this.toggleSelectedRow(index)} >
                        <TableCell>{marginInfo.accountId}</TableCell>
                        <TableCell>{shortName}</TableCell>
                        <TableCell>{marginFraction}</TableCell>
                        <TableCell>{totalBorrowedPlusPositionsAtOpenInReference}</TableCell>
                        <TableCell>{collateralisedBalancesInReferenceWeighted}</TableCell>
                        <TableCell>{this.leverageHealthTotal(marginFraction, maintenanceMarginFraction)}</TableCell>
                      </TableRow>
                      {this.state.expandedRow === index && (<TableCell colSpan={5}>
                        <JsonBlock src={{ marginInfo }} title={'Raw Data'} />
                      </TableCell>)}
                    </Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </Container>
        </Paper>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  margin: state.margin,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountsWithFullMarginInfo,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsWithFullMarginInfo)
