import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { Flexbox } from 'components/layout'
import { Autocomplete } from '@mui/material'
import { TextField } from '@material-ui/core'
import getAxios from 'helpers/axios'
import _ from 'lodash'

import { maxLength256, required } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  InputGroup,
} from 'components/atoms/Form'
import ErrorToast from 'components/molecules/ErrorToast'

import { submit } from './submit'

import { renderInput, renderRadioGroup } from '../fields'

interface KeycloakUser {
  id: string
  createdTimestamp: number
  username: string
  enabled: boolean
  totp: boolean
  emailVerified: boolean
  firstName: string
  lastName: string
  email: string
  disableableCredentialTypes: any
  requiredActions: any
  notBefore: number
  access: {
    manageGroupMembership: boolean
    view: boolean
    mapRoles: boolean
    impersonate: boolean
    manage: boolean
  }
}

const ReduxFormAutoComplete = (props) => {
  const { options, getOptionLabel, onInputChange, label, isLoading, ...rest } =
    props
  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      filterOptions={(options) => options}
      loading={isLoading}
      getOptionLabel={getOptionLabel}
      onChange={(event, newValue) => props.input.onChange(newValue)}
      onInputChange={onInputChange}
      {...rest}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  )
}

let Form = (props) => {
  const { submitting, error, invalid, isMultiAuth, isInitiator } = props
  const { handleSubmit, onSubmit, selectedValue, cancel } = props
  const [foundAccounts, setFoundAccounts] = useState([] as string[])
  const [isSearchingForAccounts, setIsSearchingForAccounts] = useState(false)
  let actionOptions = [
    { value: 'APPROVED', label: 'Approve' },
    { value: 'DECLINED', label: 'Reject' },
  ]

  if (isInitiator) {
    actionOptions = [actionOptions[1]]
  }

  const handleSearchForAccounts = async (email: string) => {
    if (!email) return setIsSearchingForAccounts(false)
    const realm = 'internal'
    try {
      const { data }: { data: KeycloakUser[] } = await getAxios().get(
        `/account/keycloak/by-email/${email}`,
        {
          params: { realm },
        },
      )
      setFoundAccounts(data.map((keycloakUser) => keycloakUser.email))
    } catch (error) {
      ErrorToast(error)
    }
    setIsSearchingForAccounts(false)
  }

  const debouncedSearch = useMemo(
    () => _.debounce((q) => handleSearchForAccounts(q), 1000),
    [],
  )
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {/* @ts-ignore */}
      <InputGroup align={'center'} direction={'row'}>
        <Field
          label={'Action'}
          name={'action'}
          margin={`0 ${BASE * 2}px 8px 0`}
          width={'40%'}
          flex={0.4}
          validate={[required]}
          component={renderRadioGroup}
          options={actionOptions}
        />
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[required, maxLength256]}
          component={renderInput}
          marginBottom={26}
        />
        {!isMultiAuth && selectedValue !== 'DECLINED' && !isInitiator && (
          <Field
            label={'Add Reviewers'}
            name={'listOfReviewers'}
            fullWidth
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[maxLength256]}
            component={ReduxFormAutoComplete}
            marginBottom={26}
            multiple
            onInputChange={async (event, newInputValue) => {
              setFoundAccounts([])
              setIsSearchingForAccounts(true)
              debouncedSearch(newInputValue)
            }}
            options={foundAccounts}
            getOptionLabel={(option: KeycloakUser) => `${option}`}
            isLoading={isSearchingForAccounts}
          />
        )}
      </InputGroup>
      <Flexbox
        marginRight={BASE * 2}
        marginTop={BASE * 1.5}
        justify={'flex-end'}
        direction={'row'}
      >
        <Button
          margin={`0 ${BASE * 2}px 0 0`}
          type="submit"
          disabled={submitting || invalid}
        >
          Submit
        </Button>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm({
  form: 'risk_profiles_dual_auth_Form',
  onSubmit: submit,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Form)

const mapStateToProps = (state, ownProps) => {
  const riskAccountsDualAuthForm = state.form[ownProps.form]
  if (!riskAccountsDualAuthForm) {
    return {
      selectedValue: '',
    }
  }
  if (riskAccountsDualAuthForm.values) {
    const {
      values: { action },
    } = riskAccountsDualAuthForm
    return {
      selectedValue: action || '',
    }
  }
  return { selectedValue: '' }
}

export const RiskAccountsDualAuthForm = connect(mapStateToProps)(Form)
