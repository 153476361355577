import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  required,
  requiredDropdownValue,
  maxLength256,
} from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput } from '../fields'
import { submitCryptoWithdrawalReview } from './submit'
import ReviewView from 'components/organisms/ReviewView'
import RenderSendDetails from 'components/organisms/ReviewCryptoWithdrawals/SendsDetailsBlock'
import { BlueLinkButton } from 'components/atoms/Form/index'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { Flexbox } from 'components/layout/index'
import {
  Details,
  DetailsBlockWithTitle,
  InfoLabel,
} from 'components/atoms/Details/index'
import WithdrawalNotes from 'components/atoms/Notes/withdrawalNotes'

const refineRawData = data => {
  const {
    name,
    surname,
    sendAddress,
    confirmations,
    uniqueId,
    verified,
    placedUnderReviewAt,
    paymentReference,
  } = data
  return {
    name,
    surname,
    sendAddress,
    confirmations,
    uniqueId,
    verified,
    placedUnderReviewAt,
    paymentReference,
  }
}

let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    showRawData,
    toggleRawData,
    showActiveUser,
    removeActiveUser,
    withdrawalId,
    comments,
    expandAddNote,
    expandRawData,
    showAddNote,
  } = props
  const reviews = rawData.review
  const flags = reviews && reviews.flags
  const audits = reviews && reviews.audits

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <RenderSendDetails tx={rawData} />
      <FormTitle>Review Withdrawal</FormTitle>
      <InputGroup align={'flex-start'} direction={'row'}>
        <Field
          label={'Action'}
          name={'status'}
          margin={`0 ${BASE * 2}px 8px 0`}
          width={'20%'}
          flex={0.4}
          validate={[requiredDropdownValue]}
          component={renderDropdown}
          options={[
            { value: 'APPROVE', label: 'Approve' },
            { value: 'DECLINE', label: 'Reject' },
          ]}
        />
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'60%'}
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[required, maxLength256]}
          component={renderInput}
          marginBottom={26}
        />
        <div style={{ width: '286px', marginTop: BASE * 2 }}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button padding={'16px 41px'} onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </div>
      </InputGroup>
      <ReviewView flags={flags} audits={audits} />
      <Details>
        <DetailsBlockWithTitle title="Notes">
          <WithdrawalNotes withdrawalId={withdrawalId} comments={comments} />
        </DetailsBlockWithTitle>
      </Details>
      <Flexbox
        direction={'column'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <Flexbox
          style={{ color: 'white' }}
          direction={'row'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showRawData}
          >
            {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
          </BlueLinkButton>
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showAddNote}
          >
            {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
          </BlueLinkButton>
        </Flexbox>
        {expandRawData && (
          <Fragment>
            <Flexbox
              marginBottom={'10'}
              marginTop={'10'}
              direction={'column'}
              align={'flex-start'}
              justify={'space-around'}
            >
              <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
              <RawData data={rawData} />
            </Flexbox>
          </Fragment>
        )}
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submitCryptoWithdrawalReview })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
