import { Input } from 'components/atoms/Form/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import React, { Component } from 'react'
import { ActionButton } from 'components/atoms/Table/styles'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'

export default class UpdateLiqudationEngineSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      liquidationState: [],
      open: false,
      isUpdating: false,
    }
  }

  componentDidMount() {
    const { data } = this.props
    const applicationNewState =
      Object.keys(data).length > 0 &&
      Object.entries(data).map(([key, value]) => ({ key, value }))
    this.setState({ liquidationState: applicationNewState })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data.length !== data.length) {
      const applicationNewState =
        Object.keys(data).length > 0 &&
        Object.entries(data).map(([key, value]) => {
          data.configChanged = true
          return { key, value }
        })
      this.setState({ liquidationState: applicationNewState })
    }
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  updateCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.toUpperCase()
    if (
      string === 'liquidationRunnerIntervalMs' ||
      string === 'insuranceFundAccountId'
    ) {
      return fixedCamelCasing.split(' ').slice(0, -1).join('_')
    }
    return fixedCamelCasing.split(' ').join('_')
  }

  checkValue = (key, value) => {
    if (
      key === 'liquidationRunnerIntervalMs' ||
      key === 'insuranceFundAccountId' ||
      key === 'minMsBetweenLiquidationEvents'
    ) {
      return parseInt(value)
    }
    return value
  }

  updateRequirements = async () => {
    this.setState({ open: true })
  }

  saveApplication = async () => {
    this.setState({ isUpdating: true })
    await Promise.all(
      this.state.liquidationState.map(async (applicationChange) => {
        if (applicationChange.configChanged) {
          try {
            const key = this.updateCamelCasing(applicationChange.key)
            const value = this.checkValue(
              applicationChange.key,
              applicationChange.value,
            )

            await getAxios().put(
              '/admin/margin-settings/update-liquidation-engine-settings',
              { key, value },
            )
            this.props.fetchLiquidationSettings()
            toast('Info has been updated', {
              type: toast.TYPE.SUCCESS,
              autoClose: 1500,
            })
          } catch (error) {
            toast(`${error}`, {
              type: toast.TYPE.ERROR,
              autoClose: 3000,
            })
          }
        }
      }),
    )
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing =
      fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }

  onChange = (value, key) => {
    const newApplicationState = this.state.liquidationState.map((newState) => {
      if (newState.key === key) {
        newState.value = value
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ liquidationState: newApplicationState })
  }

  handleProceedConfirmation = async () => {
    await this.saveApplication()
    this.setState({ open: false, isUpdating: false })
  }

  render() {
    const { open, liquidationState } = this.state
    return (
      <>
        <ActionButton
          style={{ marginLeft: '90%', marginBottom: '20%', width: '8%' }}
          onClick={this.updateRequirements}
        >
          Update
        </ActionButton>
        <ConfirmationDialog
          title={'Margin requirements'}
          message={'Are you sure you want to procced?'}
          open={open}
          disabled={this.state.isUpdating}
          proceed={this.handleProceedConfirmation}
          handleClose={() => {
            this.closeDialog()
          }}
          button={{
            confirm: {
              text: 'Proceed',
            },
            decline: {
              text: 'Cancel',
            },
          }}
        >
          <>
            {liquidationState &&
              liquidationState.map((liquidationSettings) => (
                <>
                  <Input
                    label={this.removeCamelCasing(liquidationSettings.key)}
                    variant="outlined"
                    style={{ marginLeft: '8px', marginTop: '8px' }}
                    type={
                      liquidationSettings.key !== 'liquidationRunnerEnabled' &&
                      'number'
                    }
                    value={
                      liquidationSettings.key === 'liquidationRunnerEnabled'
                        ? liquidationSettings.value.toString()
                        : liquidationSettings.value
                    }
                    disabled={
                      liquidationSettings.key === 'buyOrderSpreadMultiplier' ||
                      liquidationSettings.key === 'sellOrderSpreadMultiplier'
                    }
                    inputProps={{
                      step: '.0.1',
                    }}
                    onChange={(event) =>
                      this.onChange(
                        event.target.value,
                        liquidationSettings.key,
                        liquidationSettings.value,
                      )
                    }
                  />
                </>
              ))}
          </>
        </ConfirmationDialog>
      </>
    )
  }
}
