import React, { Fragment, Component } from 'react'
import { ActionButton } from 'components/atoms/Table/styles'
import EnableVoiceOtpForm from 'forms/EnableVoiceOtp'
import { hasAccountEditorRole } from 'helpers/roleBasedAccess'

class RenderEnableVoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const { id } = this.props
    return (
      <Fragment  >
        <Fragment>
          {!formShown && <ActionButton onClick={this.toggleForm} disabled={!hasAccountEditorRole()} >Enable Voice OTP</ActionButton>}
        </Fragment>
        {formShown &&
        <EnableVoiceOtpForm initialValues={{ id, comment: '' }} form={`EnableVoiceOtp`} onSubmitSuccess={this.toggleForm} onCancel={this.toggleForm} />
        }
      </Fragment>
    )
  }
}
export default RenderEnableVoice
