import React, { useCallback } from 'react'
import { Route, Switch } from 'react-router-dom'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

import SingleAccount from './SingleAccount'
import AccountLists from './AccountLists'
import RemovedAccountsPage from './RemovedAccounts'

const AccountsView = () => {
  const accountPendingMultiAuthsFeatureActive = useFeatureFlagCheck(
    'accountPendingMultiAuths',
  )
  const memoizedSingleAccount = useCallback(
    (props) => (
      <SingleAccount
        {...props}
        accountPendingMultiAuthsFeatureActive={
          accountPendingMultiAuthsFeatureActive
        }
      />
    ),
    [accountPendingMultiAuthsFeatureActive],
  )

  return (
    <Switch>
      <Route
        path={'/accounts/byId/:accountId'}
        component={memoizedSingleAccount}
      />
      <Route
        path={'/accounts/sanctions/removed'}
        component={RemovedAccountsPage}
      />
      <Route path={'/accounts/*'} component={AccountLists} />
    </Switch>
  )
}
export default AccountsView
