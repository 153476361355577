import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'

import ExpandingItem from 'components/atoms/ExpandingItem'
import { fetchTwilioData } from 'redux/accounts/actions'
import { getAccountDetails } from 'redux/selectors/details'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  Title,
  TitleContainer,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading/index'

import DataRow from './row'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}))

const Twilio = props => {
  const classes = useStyles()
  const [highlightedSid, setHighlightedSid] = useState(null)
  const [expanded, setExpanded] = useState('')

  const {
    details: { info, accountId, sms, voice, isFetchingTwilio },
  } = props

  const setingExpand = panel => {
    setExpanded(panel)
  }

  const handleChange = panel => {
    setingExpand(panel)
  }

  const changeHighlightedSid = sid => {
    if (sid === highlightedSid) {
      setHighlightedSid(null)
    } else {
      setHighlightedSid(sid)
    }
  }

  useEffect(
    () => {
      const cellNumber = info && info.cellNumber

      if (cellNumber && accountId) props.fetchTwilioData(accountId, cellNumber)
    },
    [info],
  )

  const twilioArray = useMemo(
    () => [
      { type: 'SMS', data: sms || [] },
      { type: 'Voice calls', data: voice || [] },
    ],
    [sms, voice],
  )
  const convertUnixToDate = date => {
    const day = moment(date.seconds * 1000 || date)
    return day.format('YYYY-MMM-DD HH:mm:ss')
  }

  const mapData = list => {
    list.map(item => {
      if (item.type === 'SMS') {
        item.data.map(timeStamp => {
          if (timeStamp.dateCreated) {
            timeStamp.dateCreated = convertUnixToDate(timeStamp.dateCreated)
          }
          if (timeStamp.dateUpdated) {
            timeStamp.dateUpdated = convertUnixToDate(timeStamp.dateUpdated)
          }
          if (timeStamp.startTime) {
            timeStamp.startTime = convertUnixToDate(timeStamp.startTime)
          }
          if (timeStamp.endTime) {
            timeStamp.endTime = convertUnixToDate(timeStamp.endTime)
          }
          if (timeStamp.dateSent) {
            timeStamp.dateSent = convertUnixToDate(timeStamp.dateSent)
          }
          return timeStamp
        })
        return item
      }
      if (item.type === 'Voice calls') {
        item.data.map(timeStamp => {
          if (timeStamp.dateCreated) {
            timeStamp.dateCreated = convertUnixToDate(timeStamp.dateCreated)
          }
          if (timeStamp.dateUpdated) {
            timeStamp.dateUpdated = convertUnixToDate(timeStamp.dateUpdated)
          }
          if (timeStamp.startTime) {
            timeStamp.startTime = convertUnixToDate(timeStamp.startTime)
          }
          if (timeStamp.endTime) {
            timeStamp.endTime = convertUnixToDate(timeStamp.endTime)
          }
          if (timeStamp.dateSent) {
            timeStamp.dateSent = convertUnixToDate(timeStamp.dateSent)
          }
          return timeStamp
        })
        return item
      }
    })
    return list
  }

  const twillioModifiedArray = mapData(twilioArray)
  return (
    <>
      {isFetchingTwilio && (
        <TablePlaceholder>
          <IconLoading />
          <span> SMS Loading, please wait</span>
        </TablePlaceholder>
      )}
      {(!isFetchingTwilio &&
        twillioModifiedArray.map((twilioitem, i) => (
          <ExpandingItem
            title={twilioitem.type}
            expanded={expanded}
            onExpand={handleChange}
            key={i}
            expandedView={
              <>
                {twilioitem.data.length ? (
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="collapsible table"
                        >
                          <TableCell
                            width={`${
                              twilioitem.type === 'SMS' ? '15%' : '25%'
                            }`}
                          >
                            From Number
                          </TableCell>
                          <TableCell
                            width={`${
                              twilioitem.type === 'SMS' ? '15%' : '25%'
                            }`}
                          >
                            To Number
                          </TableCell>
                          <TableCell
                            width={`${
                              twilioitem.type === 'SMS' ? '15%' : '25%'
                            }`}
                          >
                            {twilioitem.type === 'SMS'
                              ? 'Delivery Status'
                              : 'Call Status'}
                          </TableCell>
                          <TableCell
                            width={`${
                              twilioitem.type === 'SMS' ? '15%' : '25%'
                            }`}
                          >
                            Date
                          </TableCell>
                          {twilioitem.type === 'SMS' && (
                            <>
                              <TableCell width={'15%'}>Error Code</TableCell>
                              <TableCell width={'15%'}>Error Message</TableCell>
                            </>
                          )}
                          <TableBody>
                            {twilioitem.data &&
                              twilioitem.data.map(item => (
                                <DataRow
                                  setHighlightedSid={changeHighlightedSid}
                                  isHighlighted={item.sid === highlightedSid}
                                  key={item.sid}
                                  item={item}
                                  type={twilioitem.type}
                                />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                ) : (
                  <TablePlaceholder>{`Account doesn't have any ${
                    twilioitem.type
                  }`}</TablePlaceholder>
                )}
              </>
            }
          />
        ))) ||
        ''}
    </>
  )
}

const mapStateToProps = state => ({
  twilioData: state.twilio.twilioData,
  dataLoading: state.twilio.dataLoading,
  details: getAccountDetails(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTwilioData,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Twilio)
