import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_HISTORY_LOGS_PROCESS = 'account/FETCH_HISTORY_LOGS_PROCESS'
export const FETCH_HISTORY_LOGS_SUCCESS = 'account/FETCH_HISTORY_LOGS_SUCCESS'
export const FETCH_HISTORY_LOGS_ERROR = 'account/FETCH_HISTORY_LOGS_ERROR'
export const RESET_HISTORY_LOGS = 'account/RESET_HISTORY_LOGS'

export const fetchHistoryLogs = (values) => async dispatch => {
  const { skip, limit } = values
  dispatch({
    type: FETCH_HISTORY_LOGS_PROCESS,
  })
  try {
    const res = await getAxios().post('/all/request', values)
    const { data } = res

    dispatch({
      type: FETCH_HISTORY_LOGS_SUCCESS,
      payload: {
        data,
        skip,
        limit,
        hasMoreRecords: data.length === limit,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_HISTORY_LOGS_ERROR,
      payload: { error },
    })
  }
}

export const clearHistoryLogs = () => dispatch => {
  dispatch({
    type: RESET_HISTORY_LOGS,
  })
}
