import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

import {
  KEYCLOAK_ACTIVITY_PROCESS,
  KEYCLOAK_ACTIVITY_SUCCESS,
  KEYCLOAK_ACTIVITY_ERROR,
  CLEAR_KEYCLOAK_ACTIVITY,
} from './index'
import { buildQuery } from 'helpers/utils'

export const fetchKeycloakActivity = (values) => async (dispatch) => {
  const query = buildQuery(values)
  dispatch({
    type: KEYCLOAK_ACTIVITY_PROCESS,
  })
  try {
    const res = await getAxios().get(`/account/keycloak/events/${query}`)
    const { data } = res
    dispatch({
      type: KEYCLOAK_ACTIVITY_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: KEYCLOAK_ACTIVITY_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const clearKeycloakActivity = () => async (dispatch) => {
  dispatch({
    type: CLEAR_KEYCLOAK_ACTIVITY,
  })
}
