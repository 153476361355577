import React, { useState, useEffect } from 'react'
import { TableBody } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import {
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import Row from './row'
import { Headers } from './headers'

const OtherTable = (props) => {
  const {
    formShown,
    expandItem,
    data,
    showRawData,
    expandRawData,
    addNoteFormShown,
    bulkActionsActive,
    title,
    handleSelectedBulkDepositClick,
    selectedBulkDeposits,
    clearSelectedDeposits,
    handleActionTypeChange,
    actionType,
    toggleSelectAll,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    tablePaginationCount,
    isAllDepositsSelected,
    handleSendIndividualZendeskEmail,
  } = props
  const { checkIfIsPending, toggleForm, toggleAddNotesForm } = props
  const [highlightedId, setHighlightedId] = useState('')
  const [isCellHidden, setIsCellHidden] = useState(true)
  const hasDeposits = data.length > 0

  useEffect(() => {
    if (bulkActionsActive) {
      return setIsCellHidden(false)
    }

    setTimeout(() => {
      setIsCellHidden(true)
    }, 200)
  }, [bulkActionsActive, isCellHidden])

  return (
    <>
      {!hasDeposits && (
        <NoTableResultsMessage messageType={NO_RESULT_MESSAGE.DEPOSITS.type} />
      )}
      {hasDeposits && (
        <>
          <Headers
            isCellHidden={isCellHidden}
            bulkActionsActive={bulkActionsActive}
            expandItem={expandItem}
            toggleSelectAll={toggleSelectAll}
            clearSelectedDeposits={clearSelectedDeposits}
            title={title}
            handleActionTypeChange={handleActionTypeChange}
            actionType={actionType}
            isAllDepositsSelected={isAllDepositsSelected}
            highlightedId={highlightedId}
          />
          <>
            {data.map((deposit) => {
              deposit.dateTime = moment(deposit.dateTime).format('YYYY-MMM-DD')
              deposit.insertedAt = moment(deposit.insertedAt).format(
                'YYYY-MMM-DD HH:mm:ss',
              )
              return (
                <Row
                  isCellHidden={isCellHidden}
                  selectedBulkDeposits={selectedBulkDeposits}
                  handleSelectedBulkDepositClick={
                    handleSelectedBulkDepositClick
                  }
                  title={title}
                  bulkActionsActive={bulkActionsActive}
                  key={deposit.depositId}
                  deposit={deposit}
                  depositId={deposit.depositId}
                  isPending={checkIfIsPending(deposit)}
                  toggleForm={() => toggleForm(deposit.depositId)}
                  formShown={formShown === deposit.depositId}
                  showRawData={showRawData}
                  toggleAddNotesForm={toggleAddNotesForm}
                  expandRawData={expandRawData}
                  addNoteFormShown={addNoteFormShown === deposit.depositId}
                  isHighlighted={highlightedId === deposit.depositId}
                  setHighlightedId={setHighlightedId}
                  actionType={actionType}
                  handleSendIndividualZendeskEmail={
                    handleSendIndividualZendeskEmail
                  }
                />
              )
            })}
          </>
        </>
      )}
      <TableRow style={{ backgroundColor: 'transparent' }} className="no-hover">
        <TableCell colSpan={12}>
          <TablePagination
            component="div"
            count={tablePaginationCount}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export default OtherTable
