import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.cellphoneChangeInfo.id)
  }

  render() {
    const {
      cellphoneChangeInfo,
      cellphoneChangeInfo: {
        id,
        accountId,
        reason,
        createdAt,
        active
      },
      showForm,
      isHighlighted,
    } = this.props
    const shading = isHighlighted ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isHighlighted}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'12%'}>{createdAt}</TableCell>
          <TableCell width={'20%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {showForm && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              actionType: cellphoneChangeInfo.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'pending'
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.toggleForm}
            rawData={cellphoneChangeInfo}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'CELL_PHONE_EVENT')
