import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Flexbox } from 'components/layout/index'
import { BASE } from 'theme/base-units'

import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
  FormError,
  RawDataInput,
} from 'components/atoms/Form'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth'
import { required, requiredDropdownValue } from 'forms/fieldValidators'
import LoadingBlock from 'components/molecules/LoadingBlock'

import { submitAccountIdNumberChangeDualAuthorisation } from './submit'

import { renderDropdown, renderInput } from '../fields'

let DualAuth = props => {
  const {
    submitting,
    error,
    invalid,
    rawData,
    loading: loadingRawData = false,
    pristine,
    showActiveUser,
    removeActiveUser,
    active,
    statusType,
    addComment,
  } = props
  const { handleSubmit, onCancel, onSubmit } = props
  const [userData, setUserData] = useState({})

  React.useEffect(() => {
    showActiveUser()
    return () => {
      removeActiveUser()
    }
  }, [])

  React.useEffect(
    () => {
      setUserData({
        ...rawData,
        active: {
          userActive: active.userActive,
          activePersonName: active.activePersonName,
        },
      })
    },
    [active.activePersonName, rawData],
  )
  if (loadingRawData) {
    return <LoadingBlock message="Fetching dual auth data..." />
  }
  return (
    <FormContainer
      onSubmit={handleSubmit((values, dispatch) =>
        onSubmit(
          values,
          dispatch,
          addComment,
          rawData.data && rawData.data.newIdentityNumber,
        ),
      )}
    >
      <FormTitle>Pending Dual Auth</FormTitle>
      <RawDataInput align="flex-start" direction="column">
        <Details>
          <DetailsBlockWithTitle title="Raw Data">
            <RawData data={userData} loading={loadingRawData} />
          </DetailsBlockWithTitle>
        </Details>
      </RawDataInput>
      {error && <FormError error={error} />}
      <InputGroup
        justify={'space-between'}
        align={'baseline'}
        direction={'row'}
      >
        <Field
          label="Pending Dual Auth"
          name="status"
          component={renderDropdown}
          options={[
            { value: 'approve', label: 'Approve' },
            { value: 'reject', label: 'Reject' },
          ]}
          validate={[requiredDropdownValue]}
          margin={`0 ${BASE * 2}px 0 0`}
          marginTop={26}
          fullWidth
        />
        {statusType === 'reject' && (
          <Field
            label={'Comment (required)'}
            name={'reason'}
            width={'100%'}
            validate={[required]}
            component={renderInput}
            justify={'space-around'}
            margin={`0 ${BASE * 2}px 0 0`}
            marginTop={26}
          />
        )}
        <Flexbox
          marginRight={BASE * 2}
          marginTop={BASE * 1.5}
          justify={'center'}
          direction={'row'}
        >
          <Button
            type="submit"
            disabled={submitting || invalid || pristine || loadingRawData}
            padding="9px 40px"
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button onClick={onCancel} padding="9px 40px" color="secondary">
            Cancel
          </Button>
        </Flexbox>
      </InputGroup>
    </FormContainer>
  )
}

DualAuth = reduxForm({
  onSubmit: submitAccountIdNumberChangeDualAuthorisation,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(DualAuth)

const mapStateToProps = (state, ownProps) => {
  const suspendedForm = state.form[ownProps.form]
  if (!suspendedForm) {
    return {
      statusType: '',
    }
  }
  if (suspendedForm.values) {
    const {
      values: { status, comment, selectedValue },
    } = suspendedForm
    return {
      statusType: status && status.value,
      selectedValue: selectedValue || '',
      comment: comment || '',
    }
  }
  return { statusType: '' }
}

const ReduxForm = connect(
  mapStateToProps,
  null,
)(DualAuth)

export default ReduxForm
