import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import { Dispatch } from 'redux'
import { ComplianceData } from 'containers/Accounts/SingleAccount/Comments/interfeces'

export type ActionTypes =
  | 'FETCH_COMPLIANCE_COMMENTS_PROCESS'
  | 'FETCH_COMPLIANCE_COMMENTS_SUCCESS'
  | 'FETCH_COMPLIANCE_COMMENTS_ERROR'
  |'UPDATE_COMPLIANCE_COMMENTS_SUCCESS'

export interface CommentsAction {
    type: ActionTypes
    payload?: {comments:ComplianceData}
  }
export interface UpdateFundingEventsAction {
    type: ActionTypes
    payload?: any,
  }

export const fetchComplianceComments = (
  id: string | number,
) => async (dispatch: Dispatch<CommentsAction>) => {
  dispatch({
    type: 'FETCH_COMPLIANCE_COMMENTS_PROCESS',
  })
  try {
    const res = await getAxios().get(`/compliance/comments/${id}`)
    const comments = res.data
    dispatch({
      type: 'FETCH_COMPLIANCE_COMMENTS_SUCCESS',
      payload: { comments },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_COMPLIANCE_COMMENTS_ERROR',
    })
  }
}

export const flagComplianceComment = (
  object: {
    id: number | null
    flag: boolean
  }
) => async (dispatch: Dispatch<UpdateFundingEventsAction>) => {
  try {
    const { data } = await getAxios().put(
      '/compliance/comments/flag',
      object,
    )
    dispatch({
      type: 'UPDATE_COMPLIANCE_COMMENTS_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
  }
}
