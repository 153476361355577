import React, { FC } from 'react'
import { connect } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import { Spacer, StyledAnchor, StyledTableRow } from 'components/atoms/Generic'
import { bindActionCreators } from 'redux'
import { fetchVeriffAccountSessionsBySourceId } from 'redux/veriffSessions/actions'
import { VeriffSessionsState } from 'redux/veriffSessions'
import { Table, TableCell, TableHead } from '@material-ui/core'
import { Flexbox } from 'components/layout'
import useVeriffSessions from 'Hooks/Kyc/useVeriffSessions'

import { ActionButton, TablePlaceholder } from 'components/atoms/Table/styles'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { Title } from 'components/atoms/Title'
import IconButton from 'components/atoms/IconButton'
import { Input } from 'components/atoms/Form/index'
import { Link } from 'components/organisms/AccountIdLink/clickableId'

import { StyledVeriffSessionsActionsWrapper } from './Styles'

interface VeriffSessionsProps {
  fetchVeriffAccountSessionsBySourceId: (
    sourceId: string,
  ) => () => Promise<void>
  fetchMoreVeriffSessionsData: (sourceId: string) => () => Promise<void>
  veriffSessions: VeriffSessionsState
}

const VeriffSessions: FC<VeriffSessionsProps> = (props) => {
  const { isFetchingAccountInfoBySessionId } = props.veriffSessions
  const {
    sourceId,
    setSourceId,
    generateLink,
    getAccountIdFromVeriffSessions,
  } = useVeriffSessions()

  return (
    <>
      <Title
        type="h3"
        text="Search For An Account By Veriff Session Identifier"
      />
      <StyledVeriffSessionsActionsWrapper>
        <Input
          style={{ width: '30%' }}
          placeholder="Veriff Session Identifier"
          variant="outlined"
          type="text"
          value={sourceId}
          onChange={(e) => setSourceId(e.target.value)}
        />
        <Spacer margin="0px 0px 0px 8px">
          <IconButton
            disabled={
              sourceId.trim().length === 0 ||
              isFetchingAccountInfoBySessionId === 'pending'
            }
            height={''}
            onClick={() => props.fetchVeriffAccountSessionsBySourceId(sourceId)}
            type={'button'}
            width={''}
            icon={<SearchIcon style={{ fontSize: 40 }} />}
          />
        </Spacer>
        {props.veriffSessions.data !== null &&
          isFetchingAccountInfoBySessionId === 'done' && (
            <Flexbox marginBottom={8} direction="column" align="center">
              <Title
                margin="0px"
                type="h5"
                text="Account ID Found For This Session Identifier"
              />
              <Title
                type="h3"
                text={`${getAccountIdFromVeriffSessions(
                  props.veriffSessions.data,
                )}`}
              />
              <Flexbox>
                <Spacer margin="0px 0px 0px 8px">
                  <StyledAnchor
                    href={generateLink(
                      props.veriffSessions.data.accountId,
                      'account',
                    )}
                    target="_blank"
                  >
                    <ActionButton variant="contained" color="primary">
                      View Account
                    </ActionButton>
                  </StyledAnchor>
                </Spacer>

                <Spacer margin="0px 0px 0px 8px">
                  <StyledAnchor
                    href={generateLink(
                      props.veriffSessions.data.sessionIdentifier,
                      'veriff',
                    )}
                    target="_blank"
                  >
                    <ActionButton variant="contained" color="primary">
                      View in Veriff
                    </ActionButton>
                  </StyledAnchor>
                </Spacer>
              </Flexbox>
            </Flexbox>
          )}
      </StyledVeriffSessionsActionsWrapper>
      {isFetchingAccountInfoBySessionId === 'pending' && <LoadingBlock />}
      {isFetchingAccountInfoBySessionId === 'done' &&
        props.veriffSessions.data === null && (
          <TablePlaceholder>
            No Veriff Sessions Found For Session Identifier
          </TablePlaceholder>
        )}
      {props.veriffSessions.data !== null && (
        <Table>
          <TableHead>
            <TableCell>Account ID</TableCell>
            <TableCell>Source ID</TableCell>
            <TableCell>Inserted At</TableCell>
          </TableHead>

          <StyledTableRow>
            <TableCell>
              {props.veriffSessions.data.accountId ? (
                <Link id={props.veriffSessions.data.accountId} />
              ) : (
                '--'
              )}
            </TableCell>
            <TableCell>{props.veriffSessions.data.sessionIdentifier}</TableCell>
            <TableCell>{props.veriffSessions.data.insertedAt}</TableCell>
          </StyledTableRow>
        </Table>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  veriffSessions: state.veriffSessions,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchVeriffAccountSessionsBySourceId,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(VeriffSessions)
