import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { toast } from 'react-toastify'

export const FETCH_COMPETITION_LIST_PROCESS = 'FETCH_COMPETITION_LIST_PROCESS'
export const FETCH_COMPETITION_LIST_SUCCESS = 'FETCH_COMPETITION_LIST_SUCCESS'
export const FETCH_COMPETITION_LIST_ERROR = 'FETCH_COMPETITION_LIST_ERROR'

export const FETCH_ALL_SENDGRID_EMAILS_FOR_USER_SUCCESS =
  'FETCH_ALL_SENDGRID_EMAILS_FOR_USER_SUCCESS'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const REMOVE_ITEM_ERROR = 'REMOVE_ITEM_ERROR'

export const fetchCompetitionList = email => async dispatch => {
  dispatch({
    type: FETCH_COMPETITION_LIST_PROCESS,
  })
  try {
    const res = await getAxios().get(
      `/sendgrid/lists/${email}`,
    )
    const { data } = res
    dispatch({
      type: FETCH_COMPETITION_LIST_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    // ErrorToast(error, {
    //   type: toast.TYPE.ERROR,
    //   autoClose: 2000,
    // })
    dispatch({
      type: FETCH_COMPETITION_LIST_ERROR,
      payload: error,
    })
  }
}

export const removeUserFromCompetitionList = id => async dispatch => {
  try {
    dispatch({
      type: REMOVE_ITEM,
      payload: id,
    })
  } catch (error) {
    dispatch({
      type: REMOVE_ITEM_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}
