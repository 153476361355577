import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { updateKycLevel } from 'redux/kyc/actions'
import { addUserActionComment } from 'redux/userComments/actions'

export const submitNewKYCLevel = async (values, dispatch) => {
  const { level, comment = '', id } = values
  let res = null
  try {
    res = await getAxios().put('/account/kyclevel/override', {
      newKycLevelId: level.value,
      comment,
      id,
    })
    if (res.status === 202) {
      await addUserActionComment(values)
      toast('Verification status update submitted.', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
    dispatch(updateKycLevel(res.data))
  } catch (e) {
    ErrorToast(e)
  }
  return res
}
