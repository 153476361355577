export const CORPORATE_SELECT_OPTIONS = {
  TYPE: [
    { value: 'PTY', label: 'Propriety Limited' },
    { value: 'TRUST', label: 'Trust' },
    { value: 'PARTNERSHIPS', label: 'Partnerships' },
    { value: 'CC', label: 'Closed corporation' },
    { value: 'STOKVEL', label: 'Stokvel' },
    { value: 'FOREIGN COMPANIES', label: 'Foreign Companies' },
    { value: 'NON PROFIT COMPANIES', label: 'Non Profit Companies' },
    { value: 'BODY CORPORATE', label: 'Body Corporate' },
    { value: 'CHURCH', label: 'Church' },
    { value: 'CLUB', label: 'Club' },
    { value: 'ESTATE LATE', label: 'Estate Late' },
    { value: 'PUBLICLY TRADE COMPANY', label: 'Publicly Trade Company' },
    { value: 'NON PROFIT ORGANISATION', label: 'Non Profit Organisation' },
    { value: 'INTERMEDIARY FSP', label: 'Intermediary (FSP)' },
    { value: 'INTERMEDIARY NON FSP', label: 'Intermediary (NON FSP)' },
    { value: 'SOLE PROPRIETORSHIP', label: 'Sole Proprietorship' },
    { value: 'OTHER', label: 'Other' },
  ],
  PURPOSE: [
    { value: 'GENERAL INVESTMENTS', label: 'General Investments' },
    { value: 'MARKET MAKER', label: 'Market Maker' },
    { value: 'CRYPTO TRADER', label: 'Crypto trader' },
    { value: 'BITCOIN BROKER', label: 'Bitcoin Broker' },
    { value: 'CRYPTO EXCHANGE', label: 'Crypto Exchange' },
    { value: 'ARBITRAGE TRADING', label: 'Arbitrage Trading' },
    {
      value: 'OTC TRADER AND MARKET MAKER',
      label: 'OTC trader and market maker',
    },
    { value: 'ASSET ALLOCATION', label: 'Asset Allocation' },
    { value: 'REMITTANCE', label: 'Remittance' },
    {
      value: 'HOLDING OF CASH RESERVES IN CRYPTO',
      label: 'Holding of Cash Reserves in Crypto',
    },
    {
      value: 'FIAT CONVERSION TO CRYPTO ONLY',
      label: 'Fiat Conversion to Crypto Only',
    },
    {
      value: 'CRYPTO CONVERSION TO FIAT ONLY',
      label: 'Crypto Conversion to Fiat Only',
    },
    { value: 'SAVINGS', label: 'Savings' },
    { value: 'OTHER', label: 'Other' },
  ],
  NATURE: [
    {
      value: 'AGRICULTURE, FORESTRY AND FISHING',
      label: 'Agriculture, forestry and fishing',
    },
    {
      value: 'MINING AND QUARRYING',
      label: 'Mining and quarrying',
    },
    { value: 'MANUFACTURING', label: 'Manufacturing' },
    {
      value: 'ELECTRICITY,GAS,STEAM AND AIR CONDITIONING SUPPLY',
      label: 'Electricity, gas, steam and air conditioning supply',
    },
    {
      value:
        'WATER SUPPLY,SEWERAGE,WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
      label:
        'Water supply, sewerage, waste management and remediation activities',
    },
    { value: 'CONSTRUCTION', label: 'Construction' },
    {
      value:
        'WHOLESALE AND RETAIL TRADE, REPAIR OF MOTOR VEHICLES AND MOTORCYCLES',
      label:
        'Wholesale and retail trade, repair of motor vehicles and motorcycles',
    },
    {
      value: 'TRANSPORTATION AND STORAGE',
      label: 'Transportation and storage',
    },
    {
      value: 'ACCOMMODATION AND FOOD SERVICE ACTIVITIES',
      label: 'Accommodation and food service activities',
    },
    {
      value: 'INFORMATION AND COMMUNICATION',
      label: 'Information and communication',
    },
    {
      value: 'FINANCIAL AND INSURANCE ACTIVITIES',
      label: 'Financial and insurance activities',
    },
    {
      value: 'REAL ESTATE ACTIVITIES',
      label: 'Real estate activities',
    },
    {
      value: 'PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES',
      label: 'Professional, scientific and technical activities',
    },
    {
      value: 'ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES',
      label: 'Administrative and support service activities',
    },
    {
      value: 'PUBLIC ADMINISTRATION AND DEFENSE, COMPULSORY SOCIAL SECURITY ',
      label: 'Public administration and defense, compulsory social security',
    },
    { value: 'EDUCATION', label: 'Education' },
    {
      value: 'HUMAN HEALTH AND SOCIAL WORK ACTIVITIES',
      label: 'Human health and social work activities',
    },
    {
      value: 'ARTS, ENTERTAINMENT AND RECREATION',
      label: 'Arts, entertainment and recreation',
    },
    {
      value: 'ACTIVITIES OF EXTRATERRITORIAL ORGANIZATIONS AND BODIES',
      label: 'Activities of extraterritorial organizations and bodies',
    },
    {
      value:
        'ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS, UNDIFFERENTIATED GOODS AND SERVICES PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
      label:
        'Activities of households as employers, undifferentiated goods and services producing activities of households for own use',
    },
    {
      value: 'CONSULTING SERVICES ',
      label: 'Consulting Services',
    },
    {
      value: 'CRYPTOCURRENCY ',
      label: 'Cryptocurrency',
    },
    {
      value: 'ORGANIZATIONS ',
      label: 'Organizations',
    },
    {
      value: 'TECHNOLOGY ',
      label: 'Technology',
    },
    { value: 'OTHER', label: 'Other' },
  ],
  SOURCE_OF_WEALTH: [
    {
      value: 'INHERITANCE OR GIFTS ',
      label: 'Inheritance or gifts',
    },
    {
      value: 'LOANS OR CREDIT ',
      label: 'Loans or credit',
    },
    {
      value: 'SALE OF PROPERTY OR ASSETS',
      label: 'Sale of property or assets',
    },
    {
      value: 'INSURANCE PAYOUTS ',
      label: 'Insurance payouts',
    },
    {
      value: 'BONUS ',
      label: 'Bonus',
    },
  ],

  SOURCE_OF_FUNDS: [
    {
      value: '3RD PARTY PAYMENTS (DIRECT)',
      label: '3rd Party payments (direct)',
    },
    {
      value: '3RD PARTY PAYMENTS (INDIRECT)',
      label: '3rd Party payments (indirect)',
    },
    {
      value: 'BUSINESS INCOME',
      label: 'Business income',
    },
    {
      value: 'CROWDFUNDING OR PEER-TO-PEER LENDING',
      label: 'Crowdfunding or peer-to-peer lending',
    },
    {
      value: 'CRYPTOCURRENCY TRANSACTIONS',
      label: 'Cryptocurrency transactions',
    },
    {
      value: 'GRANTS OR SUBSIDIES',
      label: 'Grants or subsidies',
    },
    {
      value: 'INHERITANCE OR GIFTS ',
      label: 'Inheritance or gifts',
    },
    {
      value: 'INSURANCE PAYOUTS',
      label: 'Insurance payouts',
    },
    {
      value: 'INVESTMENTS (e.g. stocks, bonds, mutual funds)',
      label: 'Investments (e.g. stocks, bonds, mutual funds)',
    },
    {
      value: 'LOANS OR CREDIT ',
      label: 'Loans or credit',
    },
    {
      value: 'SALARY OR WAGES ',
      label: 'Salary or wages',
    },
    {
      value: 'SALE OF PROPERTY OR ASSETS',
      label: 'Sale of property or assets',
    },
  ],
  PRODUCT_SELECTION: [
    'Simple buy/simple sell',
    'Exchange Buy/Sell',
    'Autobuy',
    'API',
    'VALR for business',
    'Governance feature (subaccounts)',
    'VALR Pay',
    'Futures',
    'Margin trading (borrowing)',
    'Staking',
    'OTC',
    'VALR for business - KRA',
  ],
}

export const CORPORATE_ADDRESS_TYPES = {
  OPERATIONAL: { value: 'Operational', label: 'Operational' },
  REGISTERED: { value: 'Registered', label: 'Registered' },
}

export const FILE_NAME_SPLITTER = ']}'

export const COUNTRY_OPTIONS = [
  {
    value: 'Afghanistan',
    label: 'Afghanistan',
  },
  {
    value: 'Åland Islands',
    label: 'Åland Islands',
  },
  {
    value: 'Albania',
    label: 'Albania',
  },
  {
    value: 'Algeria',
    label: 'Algeria',
  },
  {
    value: 'Andorra',
    label: 'Andorra',
  },
  {
    value: 'Angola',
    label: 'Angola',
  },
  {
    value: 'Anguilla',
    label: 'Anguilla',
  },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    value: 'Armenia',
    label: 'Armenia',
  },
  {
    value: 'Aruba',
    label: 'Aruba',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
  {
    value: 'Austria',
    label: 'Austria',
  },
  {
    value: 'Azerbaijan',
    label: 'Azerbaijan',
  },
  {
    value: 'Bahamas',
    label: 'Bahamas',
  },
  {
    value: 'Bahrain',
    label: 'Bahrain',
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
  },
  {
    value: 'Barbados',
    label: 'Barbados',
  },
  {
    value: 'Belarus',
    label: 'Belarus',
  },
  {
    value: 'Belgium',
    label: 'Belgium',
  },
  {
    value: 'Belize',
    label: 'Belize',
  },
  {
    value: 'Benin',
    label: 'Benin',
  },
  {
    value: 'Bermuda',
    label: 'Bermuda',
  },
  {
    value: 'Bhutan',
    label: 'Bhutan',
  },
  {
    value: 'Bolivia',
    label: 'Bolivia',
  },
  {
    value: 'Bosnia-Herzegovina',
    label: 'Bosnia-Herzegovina',
  },
  {
    value: 'Botswana',
    label: 'Botswana',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
  },
  {
    value: 'British Virgin Islands',
    label: 'British Virgin Islands',
  },
  {
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
  },
  {
    value: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    value: 'Burkina Faso',
    label: 'Burkina Faso',
  },
  {
    value: 'Burundi',
    label: 'Burundi',
  },
  {
    value: 'Cambodia',
    label: 'Cambodia',
  },
  {
    value: 'Cameroon',
    label: 'Cameroon',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Cape Verde',
    label: 'Cape Verde',
  },
  {
    value: 'Cayman Islands',
    label: 'Cayman Islands',
  },
  {
    value: 'Central African Rep',
    label: 'Central African Rep',
  },
  {
    value: 'Chad',
    label: 'Chad',
  },
  {
    value: 'Chile',
    label: 'Chile',
  },
  {
    value: 'China',
    label: 'China',
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
  },
  {
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: 'Colombia',
    label: 'Colombia',
  },
  {
    value: 'Comoros',
    label: 'Comoros',
  },
  {
    value: 'Congo (Brazzaville)',
    label: 'Congo (Brazzaville)',
  },
  {
    value: 'Cook Islands',
    label: 'Cook Islands',
  },
  {
    value: 'Costa Rica',
    label: 'Costa Rica',
  },
  {
    value: "Côte D'Ivoire",
    label: "Côte D'Ivoire",
  },
  {
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    value: 'Cuba',
    label: 'Cuba',
  },
  {
    value: 'Curacao',
    label: 'Curacao',
  },
  {
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    value: 'Denmark',
    label: 'Denmark',
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
  },
  {
    value: 'Dominica',
    label: 'Dominica',
  },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  {
    value: 'Ecuador',
    label: 'Ecuador',
  },
  {
    value: 'Egypt',
    label: 'Egypt',
  },
  {
    value: 'El Salvador',
    label: 'El Salvador',
  },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  {
    value: 'Eritrea',
    label: 'Eritrea',
  },
  {
    value: 'Estonia',
    label: 'Estonia',
  },
  {
    value: 'Eswatini',
    label: 'Eswatini',
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
  },
  {
    value: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'Faroe islands',
    label: 'Faroe islands',
  },
  {
    value: 'Fiji',
    label: 'Fiji',
  },
  {
    value: 'Finland',
    label: 'Finland',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'French Guiana',
    label: 'French Guiana',
  },
  {
    value: 'French Polynesia',
    label: 'French Polynesia',
  },
  {
    value: 'Gabon',
    label: 'Gabon',
  },
  {
    value: 'Gambia',
    label: 'Gambia',
  },
  {
    value: 'Gaza Strip',
    label: 'Gaza Strip',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Ghana',
    label: 'Ghana',
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    value: 'Greece',
    label: 'Greece',
  },
  {
    value: 'Greenland',
    label: 'Greenland',
  },
  {
    value: 'Grenada',
    label: 'Grenada',
  },
  {
    value: 'Guadeloupe',
    label: 'Guadeloupe',
  },
  {
    value: 'Guam',
    label: 'Guam',
  },
  {
    value: 'Guatemala',
    label: 'Guatemala',
  },
  {
    value: 'Guernsey',
    label: 'Guernsey',
  },
  {
    value: 'Guinea',
    label: 'Guinea',
  },
  {
    value: 'Guinea Bissau',
    label: 'Guinea Bissau',
  },
  {
    value: 'Guyana',
    label: 'Guyana',
  },
  {
    value: 'Haiti',
    label: 'Haiti',
  },
  {
    value: 'Honduras',
    label: 'Honduras',
  },
  {
    value: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    value: 'Hungary',
    label: 'Hungary',
  },
  {
    value: 'Iceland',
    label: 'Iceland',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
  },
  {
    value: 'Iraq',
    label: 'Iraq',
  },
  {
    value: 'Ireland',
    label: 'Ireland',
  },
  {
    value: 'Isle Of Man',
    label: 'Isle Of Man',
  },
  {
    value: 'Israel',
    label: 'Israel',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'Jamaica',
    label: 'Jamaica',
  },
  {
    value: 'Japan',
    label: 'Japan',
  },
  {
    value: 'Jersey',
    label: 'Jersey',
  },
  {
    value: 'Jordan',
    label: 'Jordan',
  },
  {
    value: 'Kazakhstan',
    label: 'Kazakhstan',
  },
  {
    value: 'Kenya',
    label: 'Kenya',
  },
  {
    value: 'Kiribati',
    label: 'Kiribati',
  },
  {
    value: 'Kosovo',
    label: 'Kosovo',
  },
  {
    value: 'Kuwait',
    label: 'Kuwait',
  },
  {
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
  },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  {
    value: 'Latvia',
    label: 'Latvia',
  },
  {
    value: 'Lebanon',
    label: 'Lebanon',
  },
  {
    value: 'Lesotho',
    label: 'Lesotho',
  },
  {
    value: 'Liberia',
    label: 'Liberia',
  },
  {
    value: 'Libya',
    label: 'Libya',
  },
  {
    value: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    value: 'Lithuania',
    label: 'Lithuania',
  },
  {
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    value: 'Macau',
    label: 'Macau',
  },
  {
    value: 'Macedonia',
    label: 'Macedonia',
  },
  {
    value: 'Madagascar',
    label: 'Madagascar',
  },
  {
    value: 'Malawi',
    label: 'Malawi',
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
  },
  {
    value: 'Maldives',
    label: 'Maldives',
  },
  {
    value: 'Mali',
    label: 'Mali',
  },
  {
    value: 'Malta',
    label: 'Malta',
  },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  {
    value: 'Martinique',
    label: 'Martinique',
  },
  {
    value: 'Mauritania',
    label: 'Mauritania',
  },
  {
    value: 'Mauritius',
    label: 'Mauritius',
  },
  {
    value: 'Mayotte',
    label: 'Mayotte',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'Micronesia',
    label: 'Micronesia',
  },
  {
    value: 'Moldova',
    label: 'Moldova',
  },
  {
    value: 'Monaco',
    label: 'Monaco',
  },
  {
    value: 'Mongolia',
    label: 'Mongolia',
  },
  {
    value: 'Montenegro',
    label: 'Montenegro',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'Morocco',
    label: 'Morocco',
  },
  {
    value: 'Mozambique',
    label: 'Mozambique',
  },
  {
    value: 'Myanmar',
    label: 'Myanmar',
  },
  {
    value: 'Namibia',
    label: 'Namibia',
  },
  {
    value: 'Nauru',
    label: 'Nauru',
  },
  {
    value: 'Nepal',
    label: 'Nepal',
  },
  {
    value: 'Netherlands',
    label: 'Netherlands',
  },
  {
    value: 'New Caledonia',
    label: 'New Caledonia',
  },
  {
    value: 'New Zealand',
    label: 'New Zealand',
  },
  {
    value: 'Nicaragua',
    label: 'Nicaragua',
  },
  {
    value: 'Niger',
    label: 'Niger',
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
  },
  {
    value: 'Niue',
    label: 'Niue',
  },
  {
    value: 'Norfolk Island',
    label: 'Norfolk Island',
  },
  {
    value: 'North Korea',
    label: 'North Korea',
  },
  {
    value: 'North Mariana Islands',
    label: 'North Mariana Islands',
  },
  {
    value: 'Norway',
    label: 'Norway',
  },
  {
    value: 'Oman',
    label: 'Oman',
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
  },
  {
    value: 'Palau',
    label: 'Palau',
  },
  {
    value: 'Panama',
    label: 'Panama',
  },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  {
    value: 'Paraguay',
    label: 'Paraguay',
  },
  {
    value: 'Peru',
    label: 'Peru',
  },
  {
    value: 'Philippines',
    label: 'Philippines',
  },
  {
    value: 'Pitcairn',
    label: 'Pitcairn',
  },
  {
    value: 'Poland',
    label: 'Poland',
  },
  {
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    value: 'Puerto Rico',
    label: 'Puerto Rico',
  },
  {
    value: 'Qatar',
    label: 'Qatar',
  },
  {
    value: 'Réunion',
    label: 'Réunion',
  },
  {
    value: 'Romania',
    label: 'Romania',
  },
  {
    value: 'Russia',
    label: 'Russia',
  },
  {
    value: 'Rwanda',
    label: 'Rwanda',
  },
  {
    value: 'Saint Berthélemy',
    label: 'Saint Berthélemy',
  },
  {
    value: 'Saint Kitts & Nevis',
    label: 'Saint Kitts & Nevis',
  },
  {
    value: 'Saint Martin (French part)',
    label: 'Saint Martin (French part)',
  },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
  },
  {
    value: 'Samoa',
    label: 'Samoa',
  },
  {
    value: 'San Marino',
    label: 'San Marino',
  },
  {
    value: 'Sao Tome & Prin.',
    label: 'Sao Tome & Prin.',
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    value: 'Senegal',
    label: 'Senegal',
  },
  {
    value: 'Serbia',
    label: 'Serbia',
  },
  {
    value: 'Seychelles',
    label: 'Seychelles',
  },
  {
    value: 'Sierra Leone',
    label: 'Sierra Leone',
  },
  {
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    value: 'Slovakia',
    label: 'Slovakia',
  },
  {
    value: 'Slovenia',
    label: 'Slovenia',
  },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
  },
  {
    value: 'Somalia',
    label: 'Somalia',
  },
  {
    value: 'South Africa',
    label: 'South Africa',
  },
  {
    value: 'South Korea',
    label: 'South Korea',
  },
  {
    value: 'South Sudan',
    label: 'South Sudan',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'Sri Lanka',
    label: 'Sri Lanka',
  },
  {
    value: 'St. Lucia',
    label: 'St. Lucia',
  },
  {
    value: 'St. Maarten',
    label: 'St. Maarten',
  },
  {
    value: 'St. Vincent & Gren',
    label: 'St. Vincent & Gren',
  },
  {
    value: 'Sudan',
    label: 'Sudan',
  },
  {
    value: 'Suriname',
    label: 'Suriname',
  },
  {
    value: 'Svalbard and Mayen',
    label: 'Svalbard and Mayen',
  },
  {
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    value: 'Syria',
    label: 'Syria',
  },
  {
    value: 'Taiwan',
    label: 'Taiwan',
  },
  {
    value: 'Tajikistan',
    label: 'Tajikistan',
  },
  {
    value: 'Tanzania',
    label: 'Tanzania',
  },
  {
    value: 'Thailand',
    label: 'Thailand',
  },
  {
    value: 'Timor-Leste',
    label: 'Timor-Leste',
  },
  {
    value: 'Togo',
    label: 'Togo',
  },
  {
    value: 'Tokelau',
    label: 'Tokelau',
  },
  {
    value: 'Tonga',
    label: 'Tonga',
  },
  {
    value: 'Trinidad & Tobago',
    label: 'Trinidad & Tobago',
  },
  {
    value: 'Tunisia',
    label: 'Tunisia',
  },
  {
    value: 'Turkey',
    label: 'Turkey',
  },
  {
    value: 'Turkmenistan',
    label: 'Turkmenistan',
  },
  {
    value: 'Turks & Caicos',
    label: 'Turks & Caicos',
  },
  {
    value: 'Tuvalu',
    label: 'Tuvalu',
  },
  {
    value: 'Uganda',
    label: 'Uganda',
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    value: 'United States',
    label: 'United States',
  },
  {
    value: 'United States Virgin Islands',
    label: 'United States Virgin Islands',
  },
  {
    value: 'Uruguay',
    label: 'Uruguay',
  },
  {
    value: 'Uzbekistan',
    label: 'Uzbekistan',
  },
  {
    value: 'Vanuatu',
    label: 'Vanuatu',
  },
  {
    value: 'Vatican City State (Holy See)',
    label: 'Vatican City State (Holy See)',
  },
  {
    value: 'Venezuela',
    label: 'Venezuela',
  },
  {
    value: 'Vietnam',
    label: 'Vietnam',
  },
  {
    value: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
  },
  {
    value: 'Western Sahara',
    label: 'Western Sahara',
  },
  {
    value: 'Yemen',
    label: 'Yemen',
  },
  {
    value: 'Zambia',
    label: 'Zambia',
  },
  {
    value: 'Zimbabwe',
    label: 'Zimbabwe',
  },
]
