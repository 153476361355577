import {
  FETCH_ACCOUNT_ACTIVATION_STATUS_PROCESS,
  FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS,
} from './actions'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_ACTIVATION_STATUS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          status: 'loading',
        },
      }
    }
    case FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS: {
      const { id, activated } = action.payload
      return {
        ...state,
        [id]: {
          status: 'done',
          activated,
        },
      }
    }
    default:
      return state
  }
}
