import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { isVerifiedAccount } from 'helpers/utils'
import { fetchCorporateSanctionInformation } from 'redux/sanctionedCorporate/actions'

import { getAccountWatchlistCheck } from 'redux/selectors/sanctionsWatchlist'
import { getAccountKYC } from 'redux/selectors/kyc'

import RiskProfiles from './RiskProfiles'
import WatchlistCheck from './RenderWatchlistCheck'

import SanctionedCorporateInformation from '../../SanctionedCorporateInformation/SanctionedCorporateInformation'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

interface ComplianceProps {
  acountId: string
  watchlistCheck: string
  kyc: {
    info: {
      description: string
    }
  }
  accountType: 'SUB_ACCOUNT' | 'PRIMARY'
  fetchCorporateSanctionInformation: (accountId: string) => void
  match: RouteComponentProps<{ accountId: string }>
}

const ComplianceInfo: FC<ComplianceProps> = (props) => {
  const { accountId } = props.match.params
  const flagOn = useFeatureFlagCheck('riskBasedApproach')
  useEffect(() => {
    props.fetchCorporateSanctionInformation(accountId)
  }, [accountId])

  const { kyc, watchlistCheck, accountType, ...rest } = props

  return (
    <>
      <div style={{ marginTop: '20px' }}>
        {flagOn && accountType === 'PRIMARY' && <RiskProfiles {...rest} />}
        <WatchlistCheck
          isAccountUnverified={isVerifiedAccount(kyc.info.description)}
          sanctionCheck={watchlistCheck}
          id={accountId}
        />
        <SanctionedCorporateInformation accountId={accountId} isDetailsPage />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  watchlistCheck: getAccountWatchlistCheck(state),
  accountType: state.accounts.byId.accountType,
  kyc: getAccountKYC(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCorporateSanctionInformation,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ComplianceInfo)
