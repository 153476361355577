import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import {
  fetchAccountRestrictions,
  clearAccountRestrictions,
} from 'redux/withdrawalRestrictions/actions'
import WithdrawalRestrictionsTable from './table'
import AddRestrictions from './AddRestrictions'
import { DetailsBlockWithTitle } from 'components/atoms/Details'
import { fetchCurrencies } from 'redux/currencies/actions'
import { WithdrawalRestrictionsProps } from './interface'

interface AccountRestricatedWithdrawalsProps {
  restrictedWithdrawals: {
    status: string
    data: WithdrawalRestrictionsProps
  }
  currencies: {
    status: string
    data: any
  }
  accountId: string
  match: RouteComponentProps<{ accountId: string }>
  fetchAccountRestrictions: (accountId: string) => void
  fetchCurrencies: () => void
  clearAccountRestrictions: () => void
}
const AccountWithdrawalRestrictions: FC<AccountRestricatedWithdrawalsProps> = (
  props,
) => {
  const { accountId } = props.match.params
  const { currencies } = props

  useEffect(() => {
    props.fetchAccountRestrictions(accountId)
    props.fetchCurrencies()
    return () => {
      props.clearAccountRestrictions()
    }
  }, [])

  const {
    restrictedWithdrawals: { status, data },
  } = props

  return (
    <>
      <DetailsBlockWithTitle title="Add Restrictions" flow={'column'}>
        <AddRestrictions currencies={currencies} accountId={accountId} />
      </DetailsBlockWithTitle>
      <DetailsBlockWithTitle title="View Restrictions" flow={'column'}>
        <WithdrawalRestrictionsTable
          withdrawalRestrictionsData={data}
          currencies={currencies}
          accountId={accountId}
          status={status}
        />
      </DetailsBlockWithTitle>
    </>
  )
}

const mapStateToProps = (state) => ({
  restrictedWithdrawals: state.withdrawalRestrictions,
  currencies: state.currencies,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountRestrictions,
      fetchCurrencies,
      clearAccountRestrictions,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountWithdrawalRestrictions)
