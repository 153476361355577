import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  checkoutPendingFiatWithdrawals,
  getTotalAmountOfWithdrawsSelected,
  reversePendingFiatWithdrawals,
} from 'redux/fiat/actions'
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableRowForTotal,
  selectButtonColors,
} from 'components/atoms/Table/styles'
import { Button, InputGroup } from 'components/atoms/Form/index'
import { renderPlural } from 'helpers/utils'
import { PreviewTableHeaders } from './table-headers'
import LoadingBlock from '../../molecules/LoadingBlock'
import { getSelectedBankAccount } from "../../../redux/fiat/selectors";

class PendingWithdrawalsPreviewer extends React.Component {
  constructor() {
    super()
    this.state = {
      bank: '',
      hasFastWithdrawals: false,
    }
  }

  componentDidMount() {
    const { selectedPendingFiatWithDrawals, pendingWithdraws } = this.props
    // TODO: Why was this being done
    // this.checkForAnyFastWithdrawals()
    this.props.getTotalAmountOfWithdrawsSelected(
      pendingWithdraws.data,
      selectedPendingFiatWithDrawals,
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pendingWithdraws.data !== this.props.pendingWithdraws.data) {
      this.props.clearSelectedState()
    }
  }

  // TODO: We should see what the purpose this was else remove it
  // checkForAnyFastWithdrawals = () => {
  //   // const { selectedPendingFiatWithDrawals, pendingWithdraws } = this.props
  //   // const currentList = selectedPendingFiatWithDrawals
  //   //   .map(withdrawalId => {
  //   //     return pendingWithdraws.data.findIndex(withdrawal => {
  //   //       if (withdrawal.withdrawalId === withdrawalId && withdrawal.isFast)
  //   //         return withdrawalId
  //   //     })
  //   //   })
  //   //   .filter(val => val !== -1)
  //   // var hasFastWithdrawals = currentList.length > 0
  //   // TODO: Find out what the purpose of this bank state is  when it is disabled
  //   // cause one thing says stb but yet the row data says fnb?
  //   // this.setState({ hasFastWithdrawals, bank: hasFastWithdrawals ? 'stb' : '' })
  // }

  // TODO: Why was this just updating the bank state ?
  // The showDropDown has no purpose currently of even being here so i think the best is to remove it completely
  handleSelect = e => {
    var bank = e.target.value
    this.setState({ bank })
  }

  // TODO: Find out what the purpose of this is  when it is disabled
  // cause one thing says stb but yet the row data says fnb?
  // showDropdown = () => {
  //   const options = [
  //     { value: 'fnb', label: 'FNB/RMB' },
  //     { value: 'stb', label: 'Standard Bank' },
  //   ]
  //   return (
  //     <Divider>
  //       <Select
  //         onChange={this.handleSelect}
  //         disabled={this.state.hasFastWithdrawals}
  //         value={this.state.bank}
  //       >
  //         {options.map(opt => {
  //           return (
  //             <option key={opt.value} value={opt.value}>
  //               {opt.label}
  //             </option>
  //           )
  //         })}
  //       </Select>
  //     </Divider>
  //   )
  // }

  renderList() {
    const { pendingWithdraws, selectedPendingFiatWithDrawals } = this.props
    const currentList = pendingWithdraws.data.filter(withdrawal => {
      return selectedPendingFiatWithDrawals.find(
        w => w.id === withdrawal.withdrawalId,
      )
    })
    return currentList.length > 0 && currentList || []
  }

  render() {
    const {
      selectedPendingFiatWithDrawals,
      checkoutWithdrawals,
      pendingWithdraws,
      cancel,
      selectedWithdrawalsTotalAmount,
      reversePendingFiatWithdrawalsByIds,
      showReverseFee,
      bankAccount,
    } = this.props

    const { status } = pendingWithdraws

    return (
      <div>
        <Table>
          <InputGroup justify={'flex-end'}>
            Selected Items: {selectedPendingFiatWithDrawals.length}
          </InputGroup>
          <PreviewTableHeaders showReverseFee={showReverseFee} />
          <TableBody>
            {status === 'loading' && <LoadingBlock />}
            {status === 'done' &&
              this.renderList().map(withdrawal => {
                const {
                  withdrawalId,
                  accountId,
                  accountHolder,
                  bank,
                  amount,
                  isFast,
                  currency,
                } = withdrawal
                const reverseFee = selectedPendingFiatWithDrawals.find(
                  w => w.id === withdrawalId,
                ).reverseFee
                const formattedWithdrawalId = withdrawalId
                  .match(/[^-]/g, '')
                  .slice(0, 14)
                  .join('')
                return (
                  <TableRow key={withdrawalId}>
                    <TableCell flex={1}>{formattedWithdrawalId}</TableCell>
                    <TableCell flex={0.8}>{accountId}</TableCell>
                    <TableCell flex={1}>{accountHolder}</TableCell>
                    <TableCell flex={0.8}>{bank.displayName}</TableCell>
                    <TableCell color={isFast ? 'green' : 'black'} flex={1}>
                      {isFast ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell flex={1} align={'right'}>
                      {amount}
                    </TableCell>
                    <TableCell flex={0.8} align={'right'}>
                      {currency.shortName}
                    </TableCell>
                    {showReverseFee &&
                      <TableCell flex={0.9} align={'right'}>
                        {JSON.stringify(reverseFee)}
                      </TableCell>
                    }
                  </TableRow>
                )
              })}
            {status === 'done' && (
              <TableRowForTotal>
                <TableCell bold={true} align={'right'}>
                  Total Amount{' '}
                  {selectedWithdrawalsTotalAmount &&
                    selectedWithdrawalsTotalAmount.value}
                </TableCell>
              </TableRowForTotal>
            )}
          </TableBody>
        </Table>
        {status === 'done' && (
          <InputGroup justify={'flex-end'}>
            {/* TODO: What is the point of this dropdown as it has no reason to be here */}
            {/* {this.showDropdown()} */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                cancel()
              }}
            >
              Cancel
            </Button>
            {bankAccount.canCheckout && !showReverseFee && (
              <Button
                style={{
                  marginLeft: '15px',
                  backgroundColor: selectButtonColors.GREEN,
                }}
                disabled={
                  selectedPendingFiatWithDrawals.length === 0 ||
                  pendingWithdraws.isReversingWithdrawals ||
                  pendingWithdraws.isCheckingOutWithdrawals
                }
                onClick={async () => {
                  await checkoutWithdrawals(
                    selectedPendingFiatWithDrawals.map(({ id }) => id),
                    bankAccount,
                  )
                  this.props.clearSelectedState()
                  this.props.cancel()
                }}
              >
                Process
              </Button>
            )}
            {showReverseFee &&
              <Button
                style={{ marginLeft: '15px' }}
                disabled={
                  selectedPendingFiatWithDrawals.length === 0 ||
                  pendingWithdraws.isReversingWithdrawals ||
                  pendingWithdraws.isCheckingOutWithdrawals
                }
                onClick={async () => {
                  // TODO: Find out what the purpose of this is ?
                  // selectedWithdrawals.bank = this.state.bank
                  await reversePendingFiatWithdrawalsByIds(
                    selectedPendingFiatWithDrawals,
                  )
                  this.props.clearSelectedState()
                  this.props.cancel()
                }}
              >
                {`Revers${pendingWithdraws.isReversingWithdrawals
                  ? `ing withdrawal${renderPlural(
                    selectedPendingFiatWithDrawals.length,
                  )}`
                  : 'e'
                  }`}
              </Button>

            }
          </InputGroup>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pendingWithdraws: state.fiat.pendingWithdraws,
  selectedWithdrawalsTotalAmount: state.fiat.selectedWithdrawalsTotalAmount,
  bankAccount: getSelectedBankAccount(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTotalAmountOfWithdrawsSelected,
      reversePendingFiatWithdrawalsByIds: reversePendingFiatWithdrawals,
      checkoutWithdrawals: checkoutPendingFiatWithdrawals,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingWithdrawalsPreviewer)
