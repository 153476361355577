import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { Button, FormBounces } from 'components/atoms/Form'

import { submitRemovedEmail } from './submit'

let Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onSubmit } = props
  return (
    <FormBounces onSubmit={handleSubmit(onSubmit)}>
      <Button type="submit" main="true" disabled={submitting || invalid}>
        Remove User From The Bounced List
      </Button>
    </FormBounces>
  )
}

Form = reduxForm({ form: 'bouncedList', onSubmit: submitRemovedEmail })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
