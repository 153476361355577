import React, { FC, useState } from 'react'

import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import { toast } from 'react-toastify'

import { TableCell, TableRow } from '@material-ui/core'

import IconButton from 'components/atoms/IconButton/index'
import BasicModal from 'components/organisms/Modal/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import WithdrawalRestrictionForm from 'forms/WithdrawalRestriction'
import { WithdrawalRestrictionsProps } from './interface'
import useWithdrawalRestrictions from './useWithdrawalRestrictions'

interface WithdrawalRestrictionProps {
  withdrawalRestriction: WithdrawalRestrictionsProps
  currencies: any
  accountId: string
}

const WithdrawalRestrictionRow: FC<WithdrawalRestrictionProps> = (props) => {
  const {
    closeModal,
    withdrawalRestriction: { amount, currencyShortName, createdAt },
    currencies,
    accountId,
    handleWithdrawalRestrictionUpdate,
    deleteWithdrawalRestriction,
    setRemoveWithdrawalRestrictionConfirmationDialog,
    handleProceedRemoveWithdrawalRestriction,
    showModal,
    removeWithdrawalRestrictionConfirmationDialog,
    withdrawalRestrictionsData,
  } = useWithdrawalRestrictions(props)

  return (
    <>
      <TableRow>
        <TableCell>{currencyShortName}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{new Date(createdAt).toISOString()}</TableCell>
        <>
          <IconButton
            onClick={() =>
              handleWithdrawalRestrictionUpdate(
                accountId,
                amount,
                currencyShortName,
              )
            }
            icon={<EditIcon />}
            height={''}
            type={''}
            width={''}
            disabled={false}
          />
          <IconButton
            onClick={() => deleteWithdrawalRestriction()}
            icon={<DeleteIcon />}
            height={''}
            type={''}
            width={''}
            disabled={false}
          />
        </>
        <BasicModal
          handleClose={closeModal}
          buttonsHidden
          open={showModal.open}
          title={showModal.title}
          message=""
        >
          {showModal.open && (
            <WithdrawalRestrictionForm
              initialValues={{
                accountId: withdrawalRestrictionsData.accountId,
                amount: withdrawalRestrictionsData.amount,
                currency: withdrawalRestrictionsData.currencyShortName,
              }}
              currencies={currencies}
              form={'add_restrictions'}
              isEditing
              onSubmitSuccess={closeModal}
              onCancel={closeModal}
            />
          )}
        </BasicModal>
        <ConfirmationDialog
          open={removeWithdrawalRestrictionConfirmationDialog.open}
          title="Remove restrictions"
          message={`Are you sure you want to delete this withdrawal restriction of ${amount} ${currencyShortName}?`}
          proceed={() => handleProceedRemoveWithdrawalRestriction()}
          handleClose={() =>
            setRemoveWithdrawalRestrictionConfirmationDialog({
              open: false,
            })
          }
        />
      </TableRow>
    </>
  )
}

export default WithdrawalRestrictionRow
