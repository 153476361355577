import { TablePagination } from '@material-ui/core'
import React, { Fragment, useState, useEffect, FC,useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchIndividualRiskBasedProfiles,
  fetchMoreRiskBasedProfiles,
  initializeIndividualRiskProfileReview,
} from 'redux/riskBasedProfiles/actions'
import {
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'
import { RiskBasedProfilesState } from 'redux/riskBasedProfiles'

import RiskAccountsProfilesTable from '../RiskAccountsProfilesTable'
import getAxios from 'helpers/axios'

interface IRiskAccountProfiles {
  fetchIndividualRiskBasedProfiles: (
    from: number,
    to: number,
    type: RecommendationStatusTypes,
    riskLevelToSearch: RiskLevelTypes,
  ) => Promise<void>
  fetchMoreRiskBasedProfiles: (
    from: number,
    to: number,
    type: RecommendationStatusTypes,
    riskLevelToSearch: RiskLevelTypes,
  ) => Promise<void>
  initializeIndividualRiskProfileReview: (id: number) => Promise<void>
  riskAccountProfiles: RiskBasedProfilesState
  type: RecommendationStatusTypes
  riskLevelToSearch: RiskLevelTypes
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
}

const IndividualRiskAccountProfiles: FC<IRiskAccountProfiles> = (props) => {
  const [expandRowId, setSelectedRow] = useState(-1)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [availableProfiles,setAvailableProfiles] = useState(0)

  const toggleSelectedRow = (id: number) =>
    setSelectedRow((prev) => (prev === id ? -1 : id))

  const {
    individualRiskBasedProfileManagement: {
      newRiskProfiles,
      pendingRiskProfiles,
      monitorRiskProfiles,
      blockedRiskProfiles,
      terminatedRiskProfiles,
    },
    status,
  } = props.riskAccountProfiles
  const riskProfileType = 'INDIVIDUAL'
  const showDataByType = (type: RecommendationStatusTypes, page: number) => {
    let data: RiskProfileWithDualAuthStatus[] = []
    switch (type) {
      case 'NEW':
        data = newRiskProfiles
        break
      case 'PENDING':
        data = pendingRiskProfiles
        break
      case 'MONITOR':
        data = monitorRiskProfiles
        break
      case 'BLOCK':
        data = blockedRiskProfiles
        break
      case 'TERMINATE':
        data = terminatedRiskProfiles
        break
      default:
        break
    }
    return data && data.slice(page * rowsPerPage, rowsPerPage * (page + 1))
  }

  const getAmountOfItems = (type: RecommendationStatusTypes) => {
    let data: RiskProfileWithDualAuthStatus[] = []
    switch (type) {
      case 'NEW':
        data = newRiskProfiles
        break
      case 'PENDING':
        data = pendingRiskProfiles
        break
      case 'MONITOR':
        data = monitorRiskProfiles
        break
      case 'BLOCK':
        data = blockedRiskProfiles
        break

      case 'TERMINATE':
        data = terminatedRiskProfiles
        break
      default:
        break
    }
    return data.length
  }

  const handleChangePage = async(_, newPage: number) => {
    const amountOfItemsOnPage = showDataByType(props.type, newPage).length
    if (rowsPerPage === amountOfItemsOnPage) return setPage(newPage)
    const previousItemsAmount = getAmountOfItems(props.type)
    setPage(newPage)
    props.fetchMoreRiskBasedProfiles(
      previousItemsAmount,
      rowsPerPage,
      props.type,
      props.riskLevelToSearch,
    )
    const { data } = await getAxios().get(
      `risk-based-approach/profiles/all?type=${props.type}&level=${props.riskLevelToSearch}&riskProfileType=${riskProfileType}`,
    )
    setAvailableProfiles(data)
  }

  const handleChangeRowsPerPage = async(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
    const { data } = await getAxios().get(
      `risk-based-approach/profiles/all?type=${props.type}&level=${props.riskLevelToSearch}&riskProfileType=${riskProfileType}`,
    )
    setAvailableProfiles(data)
    if (rowsPerPage < parseInt(e.target.value)) {
      props.fetchIndividualRiskBasedProfiles(
        0,
        parseInt(e.target.value),
        props.type,
        props.riskLevelToSearch,
      )
    }
  }

  const getAllProfiles = async (type:RecommendationStatusTypes,riskLevelToSearch:RiskLevelTypes,riskProfileType?: 'CORPORATE' | 'INDIVIDUAL')=>{
    const { data } = await getAxios().get(
      `risk-based-approach/profiles/all?type=${type}&level=${riskLevelToSearch}&riskProfileType=${riskProfileType}`,
    )
    setAvailableProfiles(data)
    }

  useEffect(() => {
    props.fetchIndividualRiskBasedProfiles(
      0,
      rowsPerPage,
      props.type,
      props.riskLevelToSearch,
    )
   getAllProfiles(props.type,props.riskLevelToSearch,riskProfileType)
  }, [])

  return (
    <Fragment>
      <RiskAccountsProfilesTable
        accountsProfilesData={showDataByType(props.type, page)}
        toggleSelectedRow={toggleSelectedRow}
        expandRowId={expandRowId}
        status={status}
        type={props.type}
        initializeRiskProfileReview={
          props.initializeIndividualRiskProfileReview
        }
        riskLevelToSearch={props.riskLevelToSearch}
        riskProfileType={riskProfileType}
        getAllProfiles={
          getAllProfiles
        }
      />
      <TablePagination
        component="div"
        count={availableProfiles}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  riskAccountProfiles: state.riskBasedProfiles,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMoreRiskBasedProfiles,
      fetchIndividualRiskBasedProfiles,
      initializeIndividualRiskProfileReview,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndividualRiskAccountProfiles)
