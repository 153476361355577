import React, { useEffect, useState } from 'react'

const useSanctionsNavigation = props => {
  const routes = ['/compliance/sanctions', '/compliance/sanctions/corporate']
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleRouteChange = (e, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(
    () => {
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
      return () => setCurrentPage(routes[0])
    },
    [pathname],
  )

  return {
    currentPage,
    handleRouteChange,
    routes,
  }
}

export default useSanctionsNavigation
