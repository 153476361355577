import getAxios from 'helpers/axios'
import * as moment from 'moment'

import ErrorToast from 'components/molecules/ErrorToast'

import {
  COMPATITION_DETAILS_PROCCESS,
  COMPATITION_DETAILS_SUCCESS,
  COMPATITION_DETAILS_ERROR,
  COMPATITION_DETAILS_BY_ID_PROCCESS,
  COMPATITION_DETAILS_BY_ID_SUCCESS,
  COMPATITION_DETAILS_BY_ID_ERROR,
  PAST_COMPETITITONS_PROCCESS,
  PAST_COMPETITITONS_SUCCESS,
  PAST_COMPETITITONS_ERROR,
  PAST_COMPETITITONS_BY_ID_PROCCESS,
  PAST_COMPETITITONS_BY_ID_SUCCESS,
  PAST_COMPETITITONS_BY_ID_ERROR,
  COMPETITION_LOADING_UPDATE,
  REJECT_INTERNAL_TRANSFER_BATCH_START,
  REJECT_INTERNAL_TRANSFER_BATCH_SUCCESS,
  REJECT_INTERNAL_TRANSFER_BATCH_ERROR,
} from './index'
import { toast } from 'react-toastify'

export const fetchCompetitionDetails = () => async dispatch => {
  dispatch({
    type: COMPATITION_DETAILS_PROCCESS,
  })
  try {
    const res = await getAxios().get('/active/competition')
    const { data } = res
    dispatch({
      type: COMPATITION_DETAILS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: COMPATITION_DETAILS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchPastCompetitions = () => async dispatch => {
  dispatch({
    type: PAST_COMPETITITONS_PROCCESS,
  })
  try {
    const res = await getAxios().get('/past/competition')
    const { data } = res
    dispatch({
      type: PAST_COMPETITITONS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: PAST_COMPETITITONS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchPastCompetitionsById = id => async dispatch => {
  dispatch({
    type: PAST_COMPETITITONS_BY_ID_PROCCESS,
  })
  try {
    const res = await getAxios().get(`/past/competition/byId/${id}`)
    const { data } = res
    dispatch({
      type: PAST_COMPETITITONS_BY_ID_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: PAST_COMPETITITONS_BY_ID_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchCompetitionDetailsById = id => async dispatch => {
  dispatch({
    type: COMPATITION_DETAILS_BY_ID_PROCCESS,
  })
  try {
    const res = await getAxios().get(`/active/competition/byId/${id}`)
    const { data } = res
    const mappedData = data.map(dat => {
      dat.date = moment(dat.date).format('DD-MMMM-YYYY')
      return dat
    })
    dispatch({
      type: COMPATITION_DETAILS_BY_ID_SUCCESS,
      payload: {
        data: mappedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: COMPATITION_DETAILS_BY_ID_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateLoadingStatus = id => dispatch => {
  dispatch({
    type: COMPETITION_LOADING_UPDATE,
    payload: {
      id,
    },
  })
}

export const rejectInternalTransferBatch = batchId => async dispatch => {
  try {
    dispatch({
      type: REJECT_INTERNAL_TRANSFER_BATCH_START,
    })
    await getAxios().put(`/competition/reject/${batchId}`)
    dispatch({
      type: REJECT_INTERNAL_TRANSFER_BATCH_SUCCESS,
      payload: batchId,
    })
    toast(`Rejected internal transfer batch with id ${batchId} successfully`)
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: REJECT_INTERNAL_TRANSFER_BATCH_ERROR,
    })
  }
}
