import * as moment from 'moment'

import {
  FETCH_PENDING_DUAL_AUTH,
  FETCH_PENDING_DUAL_AUTH_ERROR,
  FETCH_PENDING_DUAL_AUTH_PROCESS,
  APPROVE_DUAL_AUTH,
  APPROVE_DUAL_AUTH_ERROR,
  REJECT_DUAL_AUTH,
  REJECT_DUAL_AUTH_ERROR,
  UPDATE_TIMER,
  UPDATE_DUAL_AUTH_ROW_USER_SUCCESS,
  UPDATE_DUAL_AUTH_ROW_USER_ERROR,
  UPDATE_DUAL_AUTH_ROW_USER_START,
} from './actions'

const initialState = {
  status: 'pristine',
  updatingDualAuthRow: false,
  data: [],
  lastUpdatedTime: null,
  timer: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_DUAL_AUTH_PROCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }

    case FETCH_PENDING_DUAL_AUTH: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
        lastUpdatedTime: moment().format('YYYY-MMM-DD HH:mm:ss'),
      }
    }

    case UPDATE_DUAL_AUTH_ROW_USER_START: {
      return {
        ...state,
        updatingDualAuthRow: true,
      }
    }

    case UPDATE_DUAL_AUTH_ROW_USER_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.data &&
        state.data.map((dualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (newUserOn.id === dualAuth.id && newUserOn.userActive) {
                dualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === dualAuth.id && userOnLine.userActive,
                  ).userName,
                }
              } else {
                dualAuth.active = { userActive: false, activePersonName: '' }
              }
            })
          } else {
            dualAuth.active = { userActive: false, activePersonName: '' }
          }
          return dualAuth
        })

      return {
        ...state,
        data: updatedDualData,
        updatingDualAuthRow: false,
      }
    }

    case UPDATE_DUAL_AUTH_ROW_USER_ERROR: {
      return {
        ...state,
        updatingDualAuthRow: false,
      }
    }

    case APPROVE_DUAL_AUTH: {
      const { approveDualAuthId } = action.payload
      const data = state.data.filter(({ id }) => id !== approveDualAuthId)
      return {
        ...state,
        data,
      }
    }

    case APPROVE_DUAL_AUTH_ERROR: {
      return {
        ...state,
        data: [],
      }
    }

    case REJECT_DUAL_AUTH: {
      const { rejectDualAuthId } = action.payload
      const data = state.data.filter(({ id }) => id !== rejectDualAuthId)
      return {
        ...state,
        data,
      }
    }

    case REJECT_DUAL_AUTH_ERROR: {
      return {
        ...state,
        data: [],
      }
    }

    case FETCH_PENDING_DUAL_AUTH_ERROR: {
      return {
        ...state,
        data: [],
      }
    }

    case UPDATE_TIMER: {
      const { flag, timer } = action.payload
      if (!flag) {
        clearInterval(state.timer)
      }
      return {
        ...state,
        timer,
      }
    }

    default:
      return state
  }
}
