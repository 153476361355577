import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchTwilioSms, fetchTwilioCalls, fetchTwilioCountries } from 'redux/twilio/actions'
import { FormContainer, InputGroup } from 'components/atoms/Form'
import { FlexboxColumn } from 'components/layout'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import TextField from '@material-ui/core/TextField'
import { hasTwilioEditorRole } from 'helpers/roleBasedAccess'
import { BASE } from 'theme/base-units'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  Title,
  TitleContainer,
  TablePlaceholder,
  ActionButton,
} from 'components/atoms/Table/styles'
import CountryRow from './countryRow'
import DataRow from './dataRow'
import { renderInput  as RenderInput} from 'forms/fields'

const Twilio = (props) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [highlightedSid, setHighlightedSid] = useState(null)
  const [clear, setClear] = useState('false')
  const {
    twilioCountries,
    twilioData: { type, data },
    loading,
    dataLoading,
  } = props

  const handleNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
  }

  const handleFilterChange = (event) => {
    const { value } = event.target
    setFilterValue(value)

  }

  const handleGetSms = () => {
    props.fetchTwilioSms(phoneNumber)
    setClear(true)
  }

  const handleGetCalls = () => {
    props.fetchTwilioCalls(phoneNumber)
    setClear(true)
  }

  const changeHighlightedSid = (sid) => {
    if (sid === highlightedSid) {
      setHighlightedSid(null)
    } else {
      setHighlightedSid(sid)
    }
  }

  useEffect(() => {
    props.fetchTwilioCountries()
    setClear(false)
  }, [])

  const filteredCountriesArray = useMemo(() => {
    const filteredArray = twilioCountries.filter(country => {
      if (country.isoCode.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
        country.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())) {
        return country
      }
    })
    return filteredArray
  }, [filterValue, twilioCountries])
  const hasEditorRights = hasTwilioEditorRole()

  return (
    <>
      <h1>Twilio</h1>
      <FormContainer>
      <InputGroup justify={'space-between'} align={'baseline'} direction={'row'}>
          <RenderInput
            id="phone-number"
            label="Mobile number"
            meta={{}}   
            onChange={handleNumberChange}
            style={{ marginRight: '10px' }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                handleGetSms()
              }

            }}
            fullWidth
          />
          <ActionButton onClick={handleGetSms} style={{ width: '350px',marginLeft :'10px' }}>Search SMS Logs</ActionButton>
          <ActionButton onClick={handleGetCalls} style={{ width: '360px' }}>Search Call Logs</ActionButton>
        </InputGroup>
      </FormContainer>
      {clear ?
        <>
          <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
            {dataLoading ? <LoadingBlock /> : data.length && (
              <>
                <TitleContainer>
                  <Title>{type}</Title>
                </TitleContainer>
                <Table>
                  <TableHeader>
                    <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>From Number</TableCell>
                    <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>To Number</TableCell>
                    <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>{type === 'SMS' ? 'Delivery Status' : 'Call Status'}</TableCell>
                    <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>Date</TableCell>
                    {type === 'SMS' && (
                      <>
                        <TableCell width={'15%'}>Error Code</TableCell>
                        <TableCell width={'15%'}>Error Message</TableCell>
                      </>
                    )}
                  </TableHeader>
                  <TableBody>

                    <>
                      {data.map(item =>
                        <DataRow
                          setHighlightedSid={changeHighlightedSid}
                          isHighlighted={item.sid === highlightedSid}
                          key={item.sid}
                          item={item}
                          type={type}
                        />)
                      }
                    </>
                  </TableBody>
                </Table>
              </>
            ) || type && <TablePlaceholder>{`Account doesn't have any ${type}`}</TablePlaceholder> || ''}
          </FlexboxColumn>
        </> : ''}
      <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
        {loading ? <LoadingBlock /> : (
          <>
            <TitleContainer>
              <Title>ENABLE/DISABLE VOICE CALLS FOR COUNTRIES</Title>
            </TitleContainer>
            <TextField
              id="standard-basic"
              label="Filter"
              onChange={handleFilterChange}
              variant='outlined'
              style={{
                width: '200px',
                marginLeft: 'auto',
                marginBottom: '10px',
              }}
            />
            <Table>
              <TableHeader>
                <TableCell width={'55%'}>Country</TableCell>
                <TableCell width={'20%'}>ISO Code</TableCell>
                <TableCell width={'25%'}>Are voice calls enabled</TableCell>
              </TableHeader>
              <TableBody>
                {filteredCountriesArray.map(country =>
                  <CountryRow
                    hasEditorRights={hasEditorRights}
                    key={country.isoCode}
                    country={country}
                  />)
                }
              </TableBody>
            </Table>
          </>
        )}
      </FlexboxColumn>
    </>
  )
}

const mapStateToProps = state => ({
  twilioCountries: state.twilio.twilioCountries,
  twilioData: state.twilio.twilioData,
  loading: state.twilio.countriesLoading,
  dataLoading: state.twilio.dataLoading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTwilioSms,
      fetchTwilioCalls,
      fetchTwilioCountries,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Twilio)
