import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchPendingTransfers } from 'redux/pendingInternalTransfers/actions'
import ErrorBoundary from 'helpers/ErrorBoundary'
import PendingInternalTransfers from 'components/organisms/PendingTransfers/index'

class InternalTransfers extends Component {
  componentDidMount() {
    this.props.fetchPendingTransfers()
  }

  render() {
    const { pendingInternalTransfers } = this.props
    return (
      <ErrorBoundary message="Pending Internal Transfers">
        <PendingInternalTransfers
          name="PENDING INTERNAL TRANSFERS"
          pendingInternalTransfers={pendingInternalTransfers}
        />
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  pendingInternalTransfers: state.pendingInternalTransfers,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPendingTransfers,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternalTransfers)
