import getAxios from 'helpers/axios'
import { buildQuery } from 'helpers/utils'

import ErrorToast from 'components/molecules/ErrorToast'
import {
  SEARCH_ACCOUNTS_PROCESS,
  SEARCH_ACCOUNTS_SUCCESS,
  SEARCH_ACCOUNTS_ERROR,
  massageAccountData,
} from 'redux/accounts/actions'

export const submitFilter = async (values, dispatch, props) => {
  const query = buildQuery(values)
  props.history.push({
    pathname: '/accounts/search',
    search: query,
  })
  dispatch({
    type: SEARCH_ACCOUNTS_PROCESS,
    payload: {
      query,
    },
  })
  let res = null
  try {
    if (values.corporateName) {
      res = await getAxios().get(`/corporates/details/${values.corporateName}`)
    } else {
      res = await getAxios().get(`/account/profileinfo/?${query}`)
    }

    const massagedData = massageAccountData(res.data.profileInfoResults)

    const { accountCorporatesResults } = res.data
    dispatch({
      type: SEARCH_ACCOUNTS_SUCCESS,
      payload: {
        data: massagedData,
        accountCorporatesResults,
        query,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: SEARCH_ACCOUNTS_ERROR,
      payload: {
        error,
      },
    })
  }
}
