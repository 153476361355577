import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import {
  removeReleasedFiatDeposits,
} from 'redux/fiat/actions'

export const submit = async (values, dispatch) => {
  const { depositIds, comment } = values
  try {
    await getAxios().post('/fiat/deposit/review/release', { depositIds, comment })
    toast('Deposits Released', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(removeReleasedFiatDeposits(depositIds))
  } catch (error) {
    toast(`${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
