import React from 'react'
import PropTypes from 'prop-types'
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor'
import { StyledMessage, StyledNoContentPage } from './Styles'
import { Title } from 'components/atoms/Title/index'
import FindInPageIcon from '@material-ui/icons/FindInPage'

function NoData({
  headingType,
  headingText,
  message,
  height,
  hasMagnifier,
  messageSpacing,
  width,
}) {
  return (
    <StyledNoContentPage height={height}>
      <Title type={headingType} text={headingText} />
      <FindInPageIcon className="findPageIcon" />
      {hasMagnifier && <YoutubeSearchedForIcon className="searchIcon" />}
      <StyledMessage width={width} messageSpacing={messageSpacing}>{message}</StyledMessage>
    </StyledNoContentPage>
  )
}

NoData.propTypes = {
  hasMagnifier: PropTypes.bool,
}

NoData.defaultProps = {
  hasMagnifier: true,
}

export default NoData
