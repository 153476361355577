import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getAccountDetails } from 'redux/selectors/details'
import {
  // Table,
  // TableBody,
  // TableCell,
  TableHeader,
  Title,
  TitleContainer,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconLoading from 'components/atoms/IconLoading'
import ErrorBoundary from 'helpers/ErrorBoundary'
import Tickets from './tickets'

class UserTickets extends Component {
  render() {
    const {
      details: { status, info },
    } = this.props
    return (
      <ErrorBoundary message="User Tickets">
        <Fragment>
          <TitleContainer>
            <Title>User Tickets</Title>
          </TitleContainer>
          <Table>
            <TableCell width={'10%'}> Ticket Id</TableCell>
            <TableCell width={'40%'}>Subject</TableCell>
            <TableCell width={'20%'}>Created At</TableCell>
            <TableCell width={'15%'}>Updated At</TableCell>
            <TableBody>
              {status === 'loading' && (
                <TableCell colSpan={4}>
                  <TablePlaceholder>
                    <IconLoading />
                    <span>Loading, please wait </span>
                  </TablePlaceholder>
                </TableCell>
              )}
              {status === 'done' && <Tickets email={info.email} />}
            </TableBody>
          </Table>
        </Fragment>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  details: getAccountDetails(state),
})

export default connect(mapStateToProps)(UserTickets)
