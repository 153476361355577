import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { fetchLogsByQuery } from 'redux/elasticsearchQueryLogs/actions'
import { renderCalenderDate, renderInput } from 'forms/fields'
import {
  InputGroup,
  TransparentFormContainer,
} from 'components/atoms/Form/index'

let Form = props => {
  const { submitting, invalid, handleSubmit, onSubmit } = props

  return (
    <TransparentFormContainer width="100%" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Account Id'}
          name={'id'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'12%'}
          flex={0.4}
          component={renderInput}
          placeholder={'Account Id'}
        />
        <Field
          label={'Customer IP Address'}
          name={'ip'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'20%'}
          flex={0.4}
          component={renderInput}
          placeholder={'IP Address'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Start time'}
          name={'from'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          flex={0.4}
          marginRight={BASE * 2}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'End time'}
          name={'to'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          flex={0.4}
          marginRight={BASE * 2}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
        />
        <MuiButton
          style={{ marginRight: '12%', marginTop: '1%' }}
          type="submit"
          main="true"
          disabled={submitting || invalid}
        >
          Submit Filter
        </MuiButton>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'DynamicFilterForm',
  onSubmit: (values, dispatch, props) => {
    let newValues
    if (values.from && values.to) {
      const from = values.from.realDate
      const to = values.to.realDate
      const { id } = values
      const { skip } = values
      const { limit } = values
      newValues = {
        id,
        from,
        to,
        skip,
        limit,
      }
    } else {
      const { id } = values
      const { skip } = values
      const { limit } = values
      newValues = { id, skip, limit }
    }

    dispatch(fetchLogsByQuery(newValues))
    props.reset()
  },
  validate: values => {
    const errors = {}
    if (!values.from && !values.to && !values.id && !values.ip) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
  enableReinitialize: true,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
