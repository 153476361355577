export const KEYCLOAK_GROUPS_PROCESS = 'KEYCLOAK_GROUPS_PROCESS'
export const KEYCLOAK_GROUPS_SUCCESS = 'KEYCLOAK_GROUPS_SUCCESS'
export const KEYCLOAK_GROUPS_ERROR = 'KEYCLOAK_GROUPS_ERROR'

export const FETCH_KEYCLOAK_ADMIN_GROUPS_PROCESS =
  'FETCH_KEYCLOAK_ADMIN_GROUPS_PROCESS'
export const FETCH_KEYCLOAK_ADMIN_GROUPS_SUCCESS =
  'FETCH_KEYCLOAK_ADMIN_GROUPS_SUCCESS'
export const FETCH_KEYCLOAK_ADMIN_GROUPS_ERROR =
  'FETCH_KEYCLOAK_ADMIN_GROUPS_ERROR'

const initialState = {
  status: 'pristine',
  loading: false,
  data: [],
  error: '',
  adminKeycloakGroups: {
    loading: false,
    data: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYCLOAK_GROUPS_PROCESS: {
      return {
        ...state,
        status: 'loading',
        loading: true,
      }
    }

    case KEYCLOAK_GROUPS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        loading: false,
        status: 'done',
        error: '',
      }
    }

    case KEYCLOAK_GROUPS_ERROR: {
      return {
        ...state,
        data: [],
        status: 'done',
        loading: false,
        error: 'Error getting keycloak groups',
      }
    }

    case FETCH_KEYCLOAK_ADMIN_GROUPS_PROCESS: {
      return {
        ...state,
        adminKeycloakGroups: {
          ...state.adminKeycloakGroups,
          loading: true,
        },
      }
    }

    case FETCH_KEYCLOAK_ADMIN_GROUPS_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        adminKeycloakGroups: {
          ...state.adminKeycloakGroups,
          data,
          loading: false,
        },
      }
    }

    case FETCH_KEYCLOAK_ADMIN_GROUPS_ERROR: {
      return {
        ...state,
        adminKeycloakGroups: {
          ...state.adminKeycloakGroups,
          loading: false,
        },
      }
    }

    default:
      return state
  }
}
