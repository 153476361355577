import React, { useState } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormRemoveIgnoreStatusInDeposits from 'forms/RemoveIgnoreStatusInDeposits/index'
import * as moment from 'moment'
import { Button } from 'components/atoms/Form'

const FlagRow = ({ flag, depositId, closeOuterForm, accountId }) => {
  const [formShown, setFormShown] = useState(false)
  const toggleForm = () => {
    setFormShown(!formShown)
  }
  const initialValues = {
    comment: '',
    depositId,
    accountId,
  }

  return (
    <TableRow>
      <TableCell width={'20%'}>{flag.status || flag.flag}</TableCell>
      <TableCell mustwrap flex={1}>
        {flag.additionalInfo}
      </TableCell>
      <TableCell width={'18%'} >
        {moment(flag.insertedAt).format('YYYY-MMM-DD HH:mm:ss')}
      </TableCell>
      {flag.status === 'IGNORED' ? (
        <TableCell style={{ textAlign: 'end' }} width={'20%'}>
          {!formShown && <Button onClick={toggleForm}>Remove Status</Button>}
          {formShown && (
            <FormRemoveIgnoreStatusInDeposits
              initialValues={initialValues}
              form={`IgnoreStatusFormInDeposits+${depositId}`}
              onCancel={toggleForm}
              onSubmitSuccess={() => {
                toggleForm()
                closeOuterForm()
              }}
            />
          )}
        </TableCell>
      ) : (
        <TableCell style={{ textAlign: 'end' }} width={'20%'}></TableCell>
      )}
    </TableRow>
  )
}

export default FlagRow
