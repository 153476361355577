import {
  RESET_UPDATED_ORDER_TYPES_LIST,
  UPDATE_ORDER_TYPES_ERROR,
  UPDATE_ORDER_TYPES_START,
  UPDATE_ORDER_TYPES_SUCCESS,
} from './actions'

const initialState = {
  orderTypesDataToUpdate: [],
  isUpdatingOrderTypes: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDER_TYPES_START: {
      return {
        ...state,
        isUpdatingOrderTypes: true,
      }
    }

    case UPDATE_ORDER_TYPES_SUCCESS: {
      const data = action.payload

      const updatedOrderTypesData = [...state.orderTypesDataToUpdate]
      const indexOfExistingOrderType = updatedOrderTypesData.findIndex(
        q =>
          q.orderType === data.orderType &&
          q.currencyPairSymbol === data.currencyPairSymbol,
      )
      if (indexOfExistingOrderType > -1) {
        updatedOrderTypesData.splice(indexOfExistingOrderType, 1)
      } else {
        updatedOrderTypesData.push(data)
      }
      return {
        ...state,
        orderTypesDataToUpdate: updatedOrderTypesData,
        isUpdatingOrderTypes: false,
      }
    }

    case UPDATE_ORDER_TYPES_ERROR: {
      return {
        ...state,
        isUpdatingOrderTypes: false,
      }
    }

    case RESET_UPDATED_ORDER_TYPES_LIST: {
      return {
        ...state,
        orderTypesDataToUpdate: [],
      }
    }

    default:
      return state
  }
}
