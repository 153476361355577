import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { addUserActionComment } from 'redux/userComments/actions'

export const submit = async values => {
  try {
    try {
      const { id, comment, residentialCountry } = values
      const res = await getAxios().put('/change/countryofresidence', {
        accountId: id,
        reason: comment,
        newResidentialCountry: residentialCountry.value,
      })
      if (res.data.statusCode === 202) {
        const {
          data: { dualAuthId },
        } = res.data
        values.dualAuthId = dualAuthId
        await addUserActionComment(values)
        toast('Country of residence status update requested', {
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        })
      }
    } catch (error) {
      ErrorToast(error)
    }
  } catch (error) {
    ErrorToast(error)
  }
}
