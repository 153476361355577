import {fetchKeycloakUserByEmail} from "../../redux/keycloak/actions";
import {toast} from "react-toastify";

export const submit = async (values, dispatch) => {
  const {
    email
  } = values
  try {
    dispatch(fetchKeycloakUserByEmail(email, 'internal'))
  } catch (e) {
    toast(`${e} `, {type: toast.TYPE.ERROR, autoClose: 2000})
  }
}
