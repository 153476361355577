import getAxios from 'helpers/axios'
import { throwGenericSubmissionError } from 'helpers/utils'
import { toast } from 'react-toastify'
import { fetchDepositReview } from 'redux/fiat/actions'

export const submit = async (values, dispatch) => {
  const { status, comment = '', depositId, deposit, accountId } = values
  let res = null
  try {
    if (status.value === 'APPROVE') {
      res = await getAxios().post('/fiat/review/credit', {
        depositId,
        accountId,
        comment,
      })
      res.data.statusCode !== 202 ? toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 }) : toast('Deposit Successfully Initiated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      res = await getAxios().post('/fiat/deposit/review/update', {
        depositId,
        updateType: status.value,
        comment,
      })
      toast('Deposit status updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
    dispatch(fetchDepositReview())
    return {
      depositId,
      level: status.value,
      description: status.label,
    }

  } catch (e) {
    e.response.data !== undefined ? toast(`${e.response.data.message}`, { type: toast.TYPE.ERROR, autoClose: 3500 }) : toast('Server error', { type: toast.TYPE.ERROR, autoClose: 3500 })
    throwGenericSubmissionError({
      status: e.response.status || 400,
      message: e.response.data.message || 'Server error. Contact administrator to get help',
    })
  }
  return res
}