import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import { FETCH_VIRTUAL_ACCOUNT_SUCCESS } from '../../redux/virtual-account/actions';
import { Dispatch } from 'redux';

interface IValues {
  accountId: number,
  bankAccountId: string,
}
export const submit = async (values: IValues, dispatch: Dispatch<any>) => {
  const { accountId, bankAccountId } = values
  try {
    const res = await getAxios().put(`/fiat/virtual-account/${accountId}`, {
      bankAccountId,
    })
    if (res && res.status === 200) {
      toast('Virtual account updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
      dispatch({
        type: FETCH_VIRTUAL_ACCOUNT_SUCCESS,
        payload: {
          id: accountId,
          data: { ...res.data },
        },
      })
      return res.data
    } else {
      toast(
        `Failed to update virtual account. \n ${
          res.data.message
        }`,
        {
          type: toast.TYPE.ERROR,
          autoClose: 3500,
        },
      )
      return null
    }
  } catch (error: any) {
    const statusCode = get(error, 'response.status') || 500
    const message = get(error, 'response.message') || error
    toast(`Failed code ${statusCode}. \n ${message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3500,
    })
    return get(error, 'response.validationErrors')
  }
}
