import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'
import { RenderCheckbox } from 'forms/fields'
import { TransitionCell } from '../Styles'

export const Headers = props => {
  const {
    expandItem,
    isCellHidden,
    bulkActionsActive,
    toggleSelectAll,
    isAllDepositsSelected,
  } = props

  return (
    <TableHeader style={{ overflow: 'hidden' }} onClick={expandItem}>
      <TableCell flex={0.6}>Account Id</TableCell>
      <TableCell flex={0.6}>First Name</TableCell>
      <TableCell flex={0.6}>Last Name</TableCell>
      <TableCell flex={0.6} marginRight={'1%'} align={'right'}>
        Amount
      </TableCell>
      <TableCell flex={0.7}>Currency</TableCell>
      <TableCell flex={2.4}>Reference</TableCell>
      <TableCell flex={0.7}>Bank Date</TableCell>
      <TableCell flex={0.9}>Inserted At</TableCell>
      <TableCell flex={0.9} />
      <TableCell flex={0.8} />
      <TransitionCell
        className={bulkActionsActive ? 'suspendActive' : 'suspendFade'}
        show={isCellHidden}
        flex={0.9}
      >
        <RenderCheckbox
          onCheckFunction={() =>
            toggleSelectAll()
          }
          checked={isAllDepositsSelected}
          label={'Select All'}
        />
      </TransitionCell>
    </TableHeader>
  )
}
