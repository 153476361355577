import styled from 'styled-components'
import { BASE } from 'theme/base-units'

export const StyledDocumentsWrapper = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 700px;
`
export const StyledDocumentOuter = styled.div`
  min-width: 300px;
  width: 300px;
`
export const StyledDocumentCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  box-shadow: 0px 3px 5px -1px black;
`
export const StyledDocumentCardHeader = styled.div`
  width: 100%;
  text-align: right;
`
export const StyledDocumentCardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
`

export const StyledDocumentCardProperty = styled.span`
  font-weight: bold;
`
