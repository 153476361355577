import * as moment from 'moment'
import {
   FETCH_WIRED_DEPOSITS_UNDER_REVIEW_SUCCESS,
   FETCH_WIRED_DEPOSITS_UNDER_REVIEW_ERROR,
   FETCH_WIRED_DEPOSITS_UNDER_REVIEW_PROCESS,
   FETCH_WIRED_DEPOSITS_PENDING_RELEASE_PROCESS,
   FETCH_WIRED_DEPOSITS_PENDING_RELEASE_SUCCESS,
   FETCH_WIRED_DEPOSITS_PENDING_RELEASE_ERROR,
   FETCH_WIRED_WITHDRAWALS_REVIEW_PROCESS,
   FETCH_WIRED_WITHDRAWALS_REVIEW_SUCCESS,
   FETCH_WIRED_WITHDRAWALS_REVIEW_ERROR,
   UPDATE_WIRED_DEPOSITS_SUCCESS,
   UPDATE_WIRED_DEPOSITS_ERROR,
   UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_SUCCESS,
   UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_ERROR,
   UPDATE_WIRED_WITHDRAWALS_SUCCESS,
   UPDATE_WIRED_WITHDRAWALS_ERROR,
} from './actions'

const initialState = {
  wiredDepositReview: {
    status: 'pristine',
    data: [],
  },
  wiredDepositPendingReview:{
    status: 'pristine',
    data: [],
  },
  updateWithdrawalReview:{
    status: 'pristine',
    data: [],
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

    case  FETCH_WIRED_DEPOSITS_UNDER_REVIEW_PROCESS: {
      return {
        ...state,
        wiredDepositReview: {
          ...state.wiredDepositReview,
        },
      }
    }

    case  FETCH_WIRED_DEPOSITS_UNDER_REVIEW_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        wiredDepositReview: {
          status: 'done',
          data,
        },
      }
    }

    case UPDATE_WIRED_DEPOSITS_SUCCESS: {
      const { data } = action.payload
      const updatedDeposits = state.wiredDepositReview && state.wiredDepositReview.data && state.wiredDepositReview.data.map(wiredDeposit => {
        if (data && data.length > 0) {
          data.some(deposit => {
            if (deposit.depositId === wiredDeposit.id) {
              wiredDeposit.active = { activePersonName: deposit.userName, userActive: true }
              return true
            }
            else {
              wiredDeposit.active = { activePersonName: '', userActive: false }
            }
          })
        }
        else {
          wiredDeposit.active = { activePersonName: '', userActive: false }
        }
        return wiredDeposit
      })
      return { ...state, wiredDepositReview: { status: 'done', data: updatedDeposits } }
    }

    case UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_SUCCESS: {
      const { data } = action.payload
      const updatedDeposits = state.wiredDepositPendingReview && state.wiredDepositPendingReview.data && state.wiredDepositPendingReview.data.map(wiredDeposit => {
        if (data && data.length > 0) {
          data.some(deposit => {
            if (deposit.depositId === wiredDeposit.id) {
              wiredDeposit.active = { activePersonName: deposit.userName, userActive: true }
              return true
            }
            else {
              wiredDeposit.active = { activePersonName: '', userActive: false }
            }
          })
        }
        else {
          wiredDeposit.active = { activePersonName: '', userActive: false }
        }
        return wiredDeposit
      })
      return { ...state, wiredDepositPendingReview: { status: 'done', data: updatedDeposits } }
    }

    case  FETCH_WIRED_DEPOSITS_UNDER_REVIEW_ERROR: {
      return {
        ...state,
        wiredDepositReview: {
          status: 'error',
          data: [],
        },
      }
    }


    case FETCH_WIRED_DEPOSITS_PENDING_RELEASE_PROCESS: {
      return {
        ...state,
        wiredDepositPendingReview: {
         ...state.wiredDepositPendingReview
        },
      }
    }

    case FETCH_WIRED_DEPOSITS_PENDING_RELEASE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        wiredDepositPendingReview: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_WIRED_DEPOSITS_PENDING_RELEASE_ERROR: {
      return {
        ...state,
        wiredDepositPendingReview: {
          status: 'error',
          data: [],
        },
      }
    }

    case FETCH_WIRED_WITHDRAWALS_REVIEW_PROCESS: {
      return {
        ...state,
        updateWithdrawalReview: {
          ...state.updateWithdrawalReview,
        },
      }
    }

    case FETCH_WIRED_WITHDRAWALS_REVIEW_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        updateWithdrawalReview: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_WIRED_WITHDRAWALS_REVIEW_ERROR: {
      return {
        ...state,
        updateWithdrawalReview: {
          status: 'error',
          data: [],
        },
      }
    }

    case UPDATE_WIRED_WITHDRAWALS_SUCCESS: {
      const { data } = action.payload
      const updatedWithdrawals = state.updateWithdrawalReview && state.updateWithdrawalReview.data && state.updateWithdrawalReview.data.map(wiredWithdrawal => {
        if (data && data.length > 0) {
          data.some(withdrawal => {
            if (withdrawal.withdrawalId === wiredWithdrawal.id) {
              wiredWithdrawal.active = { activePersonName: withdrawal.userName, userActive: true }
              return true
            }
            else {
              wiredWithdrawal.active = { activePersonName: '', userActive: false }
            }
          })
        }
        else {
          wiredWithdrawal.active = { activePersonName: '', userActive: false }
        }
        return wiredWithdrawal
      })
      return { ...state, updateWithdrawalReview: { status: 'done', data: updatedWithdrawals } }
    }

    default:
      return state
  }
}
