import jsonwebtoken from 'jsonwebtoken'
import { get } from 'lodash'

import config from '../config'

export type AVAILABLE_MAIN_ROUTES =
  | 'accounts'
  | 'exchange'
  | 'fund'
  | 'finance'
  | 'dual-auth'
  | 'keycloak'
  | 'keycloak-groups'
  | 'twilio'
  | 'config'
  | 'compliance'
  | 'marketing'
  | 'leverage'
  | 'kyc'

const BreakException = {}
export const managementUserRole = 'management:user'
const depositEditorRole = 'fiat:deposit:editor'
const depositUploadRole = 'fiat:deposit:upload'
const exchangeEditor = 'management:exchange:editor'
const accountEditor = 'management:account:editor'
const twilioEditor = 'management:twilio'
const marketingEditor = 'management:marketing'
const competitionEditor = 'management:competition'
const configEditor = 'config:editor'
const configViewer = 'config:viewer'
const keycloakViewer = 'keycloak:viewer'
const addKeycloakUserToGroup = 'keycloak:user:group:add'
const removeKeycloakUserFromGroup = 'keycloak:user:group:remove'
const createKeycloakRole = 'keycloak:role:create'
const createKeycloakGroup = 'keycloak:group:create'

const corporateEditor = 'management:corporate:editor'
const fiatBulkApprover = 'management:fiat:bulk:approver'
const financeViewer = 'management:finance:viewer'
const financeEditor = 'management:exchange:currencies:editor'
const marketingMobileMessages = 'management:marketing:create:campaign'
const dualAuthViewer = 'management:dualauthorisation:view'
const accountsViewer = 'account:viewer'
const nameSurnameChange =
  'management:dualauthorisation:initiate:name_surname_change'
const cellNumberChange =
  'management:dualauthorisation:initiate:cell_number_change'
const emailChange = 'management:dualauthorisation:initiate:email_change'
const countryChange =
  'management:dualauthorisation:initiate:residential_country_change'
const identityNumberChange =
  'management:dualauthorisation:initiate:identity_number_change'
const customerTypeChange =
  'management:dualauthorisation:initiate:customer_type_change'
const kycOverrideChange = 'management:dualauthorisation:initiate:kyc_override'
const accountProfileSettingsCustomWithdrawalLimitChange =
  'management:dualauthorisation:initiate:account_profile_settings_custom_withdrawal_limit_change'
const accountStatusEditor = 'management:exchange:account_status:editor'
export const complianceEditor = 'management:compliance:editor'
const riskProfileDualAuthAction =
  'management:compliance:dualauthorisation:rba:approver'

const exchangeAllowedRoles = [
  'fiat:deposit:editor',
  'fiat:deposit:viewer',
  'fiat:withdrawal:editor',
  'fiat:withdrawal:viewer',
  'management:exchange:editor',
  'management:exchange:viewer',
]

const fundAllowedRoles = [
  'management:fund',
  'fiat:upload',
  'management:internalTransfer:initiate',
  'management:internalTransfer:approve',
]

export const accountsAllowedRoles = [
  accountsViewer,
  accountEditor,
  'management:user',
]

const riskProfilesAllowedRoles = [riskProfileDualAuthAction, complianceEditor]

const financeAllowedRoles = [financeEditor, financeViewer]

const dualAuthAllowedRoles = [dualAuthViewer]

const keycloakAllowedRoles = [keycloakViewer]

const marketingAllowedRoles = [marketingEditor, marketingMobileMessages]

export const configAllowedRoles = [configEditor, configViewer]

export const ROLES = {
  accountEditor,
  accountsViewer,
  exchangeEditor,
  financeViewer,
  depositUploadRole,
  financeEditor,
  marketingMobileMessages,
  addKeycloakUserToGroup,
  removeKeycloakUserFromGroup,
  createKeycloakRole,
  createKeycloakGroup,
  dualAuthViewer,
  twilioEditor,
  nameSurnameChange,
  cellNumberChange,
  emailChange,
  countryChange,
  identityNumberChange,
  customerTypeChange,
  kycOverrideChange,
  accountStatusEditor,
  accountProfileSettingsCustomWithdrawalLimitChange,
  riskProfileDualAuthAction,
  complianceEditor,
}

const allAvailableRoles = new Set([
  ...exchangeAllowedRoles,
  ...fundAllowedRoles,
  ...financeAllowedRoles,
  ...dualAuthAllowedRoles,
  ...keycloakAllowedRoles,
  ...configAllowedRoles,
  ...accountsAllowedRoles,
  ...marketingAllowedRoles,
  ...riskProfilesAllowedRoles,
  ...Object.values(ROLES),
])

export const returnDecodedRoles = () => {
  const jwtToken = localStorage.getItem('admin_access_token')
  if (!jwtToken) {
    return []
  }

  const decodedToken = jsonwebtoken.decode(jwtToken)
  if (!decodedToken) {
    return []
  }

  const roles = get(
    decodedToken,
    `resource_access.${config.keycloak.applicationRolesClientId}.roles`,
  )
  if (!roles) {
    return []
  }
  return roles || []
}

export const checkPathAccess = (roles: string[]) => {
  const decodedRoles: string[] = returnDecodedRoles() || []
  return decodedRoles.some((role) => roles.indexOf(role) > -1)
}

export const hasAccessToRoute = (routeName: AVAILABLE_MAIN_ROUTES) => {
  const jwtDecodedRoles = returnDecodedRoles()
  if (jwtDecodedRoles.length && !routeName) {
    return true
  }
  switch (routeName) {
    case 'accounts':
      return checkPathAccess(accountsAllowedRoles)
    case 'exchange':
    case 'leverage':
      return checkPathAccess(exchangeAllowedRoles)
    case 'fund':
      return checkPathAccess(fundAllowedRoles)
    case 'finance':
      return checkPathAccess(financeAllowedRoles)
    case 'dual-auth':
      return checkPathAccess(dualAuthAllowedRoles)
    case 'keycloak':
      return checkPathAccess(keycloakAllowedRoles)
    case 'keycloak-groups':
      return checkPathAccess(keycloakAllowedRoles)
    case 'config':
      return checkPathAccess(configAllowedRoles)
    case 'twilio':
      return true
    case 'compliance':
      return checkPathAccess([ROLES.complianceEditor])
    case 'marketing':
      return checkPathAccess(marketingAllowedRoles)
    case 'kyc':
      return checkPathAccess(accountsAllowedRoles)
    default:
      return false
  }
}

export const checkRole = (roleToCheck) => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === roleToCheck) > -1
}

export const hasManagementUserRole = () => checkRole(managementUserRole)
export const hasAccountEditorRole = () => checkRole(ROLES.accountEditor)
export const hasNameSurnameChangeRole = () => checkRole(ROLES.nameSurnameChange)
export const hasCellNumberChangeRole = () => checkRole(ROLES.cellNumberChange)
export const hasEmailChangeRole = () => checkRole(ROLES.emailChange)
export const hasCountryChangeRole = () => checkRole(ROLES.countryChange)
export const hasIdentityNumberChangeRole = () =>
  checkRole(ROLES.identityNumberChange)
export const hasCustomerTypeChangeRole = () =>
  checkRole(ROLES.customerTypeChange)
export const hasKycOverrideChangeRole = () => checkRole(ROLES.kycOverrideChange)
export const hasAccountStatusEditorRole = () =>
  checkRole(ROLES.accountStatusEditor)
export const hasAccountProfileSettingsCustomWithdrawalLimitChangeRole = () =>
  checkRole(ROLES.accountProfileSettingsCustomWithdrawalLimitChange)

export const hasCorporateEditorRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === corporateEditor) > -1
}

export const hasMarketingRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === marketingEditor) > -1
}

export const hasCompetitionRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === competitionEditor) > -1
}

export const hasDepositEditorRole = () => {
  const jwtDecodedRoles = returnDecodedRoles()
  try {
    const result = jwtDecodedRoles.findIndex(
      (role) => role === depositEditorRole,
    )
    return result > -1
  } catch (e) {
    return false
  }
}

export const hasExchangeEditorRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === exchangeEditor) > -1
}

export const hasExchangeFiatBulkEditorRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === fiatBulkApprover) > -1
}

export const hasApplicationConfigEditor = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === configEditor) > -1
}

export const hasApplicationConfigView = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === configViewer) > -1
}

export const hasTwilioEditorRole = () => {
  const roles = returnDecodedRoles()
  return roles && roles.findIndex((role) => role === twilioEditor) > -1
}

export const hasAddKeycloakUserToGroupRole = () => {
  const roles = returnDecodedRoles()
  return (
    roles && roles.findIndex((role) => role === addKeycloakUserToGroup) > -1
  )
}

export const hasRemoveKeycloakUserFromGroupRole = () => {
  const roles = returnDecodedRoles()
  return (
    roles &&
    roles.findIndex((role) => role === removeKeycloakUserFromGroup) > -1
  )
}

export const hasKeycloakViewTabRole = () => {
  const roles = returnDecodedRoles()
  const keycloakAccesRoles = [
    ROLES.addKeycloakUserToGroup,
    ROLES.removeKeycloakUserFromGroup,
  ]
  return roles && roles.some((role) => keycloakAccesRoles.indexOf(role) > -1)
}

export const groupRoleCategories = (roles) => {
  const obj = {} as any

  roles.forEach((item) => {
    const roleLevels = item.split(':')
    let currentLevel = obj

    roleLevels.forEach((roleLevel, index) => {
      if (index === roleLevels.length - 1) {
        if (!currentLevel.roles) {
          currentLevel.roles = [roleLevel]
        } else {
          currentLevel.roles.push(roleLevel)
        }
      } else {
        if (!currentLevel[roleLevel]) {
          currentLevel[roleLevel] = {}
        }
        currentLevel = currentLevel[roleLevel]
      }
    })
  })

  return obj
}

export const hasAccessToAtLeastOneRoute = () => {
  const jwtDecodedRoles = returnDecodedRoles()
  return jwtDecodedRoles.some(
    (role) => Array.from(allAvailableRoles).indexOf(role) > -1,
  )
}
