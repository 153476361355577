import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import { updateFiat } from 'redux/fiat/actions'
import { Table, TableBody } from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import * as moment from 'moment'
import Row from './row'
import { Headers } from './headers'

class UsdDepositsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      formShown: '',
      expandRawData: '',
      addNoteFormShown: '',
      highlightedId: '',
    }
    this.toggleView = this.toggleView.bind(this)
  }

  toggleView() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  showRawData = id => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleForm = id => {
    if (id !== this.state.formShown) {
      this.setState({ formShown: id, expandRawData: '', addNoteFormShown: '' })
    } else {
      this.setState({ formShown: '', expandRawData: '', addNoteFormShown: '' })
    }
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  addTotalAmount = deposit => {
    const total = deposit.map(d => d.amount).reduce((accumulator, value) => {
      const checkedCurrentValue = value
        ? parseFloat(
            typeof value === 'string' ? value.split(',').join('') : value,
          )
        : 0
      return accumulator + checkedCurrentValue
    }, 0)
    return `${new BigNumber(total).toFormat(2)} USDC`
  }

  render() {
    const { status, data } = this.props.deposits
    const title = 'DEPOSITS UNDER REVIEW'
    return (
      <Fragment>
        <ExpandingItem
          title={`${title} (${(data && data.length) || 0})`}
          itemsAmount={this.addTotalAmount(data) || 0}
          expanded={this.state.expanded}
          onExpand={this.toggleView}
          opensMultiple
          expandedView={
            <Table>
              <Headers />
              <TableBody>
                {status !== 'done' && <LoadingBlock />}
                {status === 'done' &&
                  data.map(deposit => {
                    deposit.createdAt = moment(deposit.createdAt).format(
                      'YYYY-MMM-DD HH:mm:ss',
                    )
                    return (
                      <Row
                        key={deposit.id}
                        title={title}
                        data={deposit}
                        toggleForm={this.toggleForm}
                        formShown={
                          this.state.formShown === deposit.reviewItemId
                        }
                        setHighlightedId={this.setHighlightedId}
                        isHighlighted={
                          this.state.highlightedId === deposit.reviewItemId
                        }
                        expandRawData={this.state.expandRawData}
                        showRawData={this.showRawData}
                      />
                    )
                  })}
              </TableBody>
            </Table>
          }
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFiat,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsdDepositsContainer)
