import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import AddCurrencyForm from 'forms/AddCurrency/index'
import {
  fetchCurrencies,
  allowBorrowOnCurrency,
  updateCurrencyBorrowWeight,
  updateCurrencyEnabledStatus,
} from 'redux/currencies/actions'

import CurrencyRow from './CurrencyRow'
import CurrencyHeaderActions from './CurrencyHeaderActions'
import CurrencyTableModal from './CurrencyModals/CurrencyTableModal'

import { Container } from '../styles'

// TODO: Mercy Please note i have refactored the code for
// this which needs to be tested as well but its not massive changes
// its just how the filtering was being handled and now it is being done a bit cleaner
// Once youre happy we will remove the commented out stuff and you will also be able to see what the code
// looked like before and after
class AddCurrencyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      // renderData: [],
      formShown: false,
      formValues: {},
      allowBorrowModalOpen: false,
      modalData: {
        allowBorrow: false,
        symbol: '',
        borrowWeight: 0,
      },
    }
  }

  componentDidMount() {
    this.props.fetchCurrencies()
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
      formValues: {},
    })
  }

  filterData = data =>
    data.filter(item => {
      const {
        decimalPlaces,
        isActive,
        shortName,
        longName,
        supportedWithdrawDecimalPlaces,
        paymentReferenceFieldName,
        currencyDecimalPlaces,
      } = item

      if (
        isActive
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase()) ||
        decimalPlaces.toString().includes(this.state.filter) ||
        shortName
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase()) ||
        longName
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase()) ||
        supportedWithdrawDecimalPlaces.toString().includes(this.state.filter) ||
        currencyDecimalPlaces.toString().includes(this.state.filter) ||
        (paymentReferenceFieldName &&
          paymentReferenceFieldName
            .toString()
            .toLowerCase()
            .includes(this.state.filter.toLowerCase()))
      ) {
        return item
      }
    })

  toggleEditForm = row => {
    this.setState({
      formShown: !this.state.formShown,
      formValues: { ...row },
    })
  }

  updateModalBorrowWeight = borrowWeight => {
    this.setState({
      borrowWeightModalData: {
        ...this.state.borrowWeightModalData,
        borrowWeight,
      },
    })
  }

  initiateEditBorrowWeight = (modalIsOpen, borrowWeight, shortName, symbol) => {
    this.setState({
      ...this.state,
      editBorrowWeightModalOpen: modalIsOpen,
      borrowWeightModalData: {
        borrowWeight,
        shortName,
        symbol,
        currentBorrowWeightOnCurrency: borrowWeight,
      },
    })
  }

  closeDialog = async () => {
    this.setState({
      allowBorrowModalOpen: false,
      modalData: {
        symbol: '',
        allowBorrow: false,
      },
    })
  }

  closeEditBorrowWeightModal = () => {
    this.setState({
      editBorrowWeightModalOpen: false,
      borrowWeightModalData: {
        borrowWeight: 0,
        shortName: '',
        symbol: '',
      },
    })
  }

  handleFilter = value => {
    this.setState({
      filter: value,
    })
  }

  handleUpdateAllowBorrow = (symbol, allowBorrow, borrowWeight) => {
    this.setState({
      allowBorrowModalOpen: true,
      modalData: {
        symbol,
        borrowWeight,
        allowBorrow: !allowBorrow,
      },
    })
  }

  render() {
    const {
      currencies: { data, status, isUpdatingCurrency },
    } = this.props
    data.sort((a, b) => {
      if (a.shortName < b.shortName) return -1
      return a.shortName > b.shortName ? 1 : 0
    })
    const { filter, formShown, modalData, allowBorrowModalOpen } = this.state

    if (status !== 'done') {
      return <LoadingBlock />
    }
    if (formShown) {
      return (
        <AddCurrencyForm
          initialValues={this.state.formValues}
          form={'addCurrency'}
          isEditing={!!Object.keys(this.state.formValues).length}
          onCancel={this.toggleForm}
          onSubmitSuccess={this.toggleForm}
        />
      )
    }

    return (
      <>
        <CurrencyHeaderActions
          formShown={this.state.formShown}
          toggleForm={this.toggleForm}
          handleFilter={this.handleFilter}
          filter={filter}
        />
        <Paper>
          <Container>
            <Table stickyHeader aria-label="sticky table">
              <TableHead align="right" style={{ minWidth: '170' }}>
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>Borrow Weight</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Decimal Places</TableCell>
                  <TableCell>Short Name</TableCell>
                  <TableCell>Long Name</TableCell>
                  <TableCell>Is Active</TableCell>
                  <TableCell>Withdrawal Decimal Places</TableCell>
                  <TableCell>Currency Decimal Places</TableCell>
                  <TableCell>Payment Reference Name</TableCell>
                </TableRow>
              </TableHead>
              {status === 'done' && (
                <TableBody>
                  {this.filterData(data).map((row, i) => (
                    <CurrencyRow
                      key={i}
                      row={row}
                      i={i}
                      handleUpdateAllowBorrow={this.handleUpdateAllowBorrow}
                      initiateEditBorrowWeight={this.initiateEditBorrowWeight}
                      updateCurrencyEnabledStatus={
                        this.props.updateCurrencyEnabledStatus
                      }
                      toggleEditForm={this.toggleEditForm}
                      updateCurrencyBorrowWeight={
                        this.props.updateCurrencyBorrowWeight
                      }
                      isUpdatingCurrency={isUpdatingCurrency}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </Container>
        </Paper>
        <CurrencyTableModal
          modalData={modalData}
          allowBorrowOnCurrency={this.props.allowBorrowOnCurrency}
          isUpdatingCurrency={isUpdatingCurrency}
          allowBorrowModalOpen={allowBorrowModalOpen}
          closeDialog={this.closeDialog}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  currencies: state.currencies,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrencies,
      allowBorrowOnCurrency,
      updateCurrencyBorrowWeight,
      updateCurrencyEnabledStatus,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCurrencyTable)
