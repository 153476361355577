import {
  FETCH_REQUESTED_ACCOUNT_ACTION_START,
  FETCH_REQUESTED_ACCOUNT_ACTION_SUCCESS,
  FETCH_REQUESTED_ACCOUNT_ACTION_ERROR,
  FETCH_REQUESTED_ACCOUNT_ACTION_CLEAR,
  FETCH_AVAILABLE_ACCOUNT_ACTION_START,
  FETCH_AVAILABLE_ACCOUNT_ACTION_ERROR,
  FETCH_AVAILABLE_ACCOUNT_ACTION_SUCCESS
} from './actions'

export enum RequestedAccountActionState {
  REQUESTED = 'REQUESTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  PROCESSING = 'PROCESSING',
}

export namespace RequestedAccountActionState {
  export const isTerminal = (state: RequestedAccountActionState) => {
    return (state === RequestedAccountActionState.CANCELLED || state === RequestedAccountActionState.COMPLETED)
  }
}

export enum RequestedAccountActionInitiator {
  ADMIN = 'ADMIN',
  SYSTEM = 'SYSTEM',
}

export interface RequestedAccountActionsData {
  id: number,
  primaryAccountId: number,
  action: string,
  state: RequestedAccountActionState,
  initiator: RequestedAccountActionInitiator,
  createdAt: string,
}

interface AvailableActionsState {
  data: string[],
  loading: boolean,
  error: string | null
}

export interface RequestedAccountActionsState {
  status: 'pristine' | 'loading' | 'done' | 'error',
  data: RequestedAccountActionsData[] | null,
  availableActions: AvailableActionsState
}

const initialState: RequestedAccountActionsState = {
  status: 'pristine',
  data: null,
  availableActions: { data: [], loading: false, error: null },
}

const DEFAULT_ACTIONS = ['PROVIDE_PROOF_OF_ADDRESS']

export default (state: RequestedAccountActionsState = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTED_ACCOUNT_ACTION_START: {
      return {
        ...state,
        status: 'loading',
        data: null,
      }
    }

    case FETCH_REQUESTED_ACCOUNT_ACTION_ERROR: {
      return {
        ...state,
        status: 'error',
        data: null,
      }
    }

    case FETCH_REQUESTED_ACCOUNT_ACTION_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case FETCH_REQUESTED_ACCOUNT_ACTION_CLEAR: {
      return {
        ...initialState,
        availableActions: {
          ...state.availableActions,
        },
      }
    }

    case FETCH_AVAILABLE_ACCOUNT_ACTION_START: {
      return {
        ...state,
        availableActions: {
          ...initialState.availableActions,
        },
      }
    }

    case FETCH_AVAILABLE_ACCOUNT_ACTION_ERROR: {
      const { data } = action.payload
      return {
        ...state,
        availableActions: {
          data: DEFAULT_ACTIONS,
          loading: false,
          error: data,
        },
      }
    }

    case FETCH_AVAILABLE_ACCOUNT_ACTION_SUCCESS: {
      return {
        ...state,
        availableActions: {
          data: action.payload,
          loading: false,
          error: null,
        },
      }
    }

    default:
      return state
  }
}
