import styled from 'styled-components'

interface StyledFlexBox {
  direction?: string
  flex?: string
  justify?: string
  align?: string
  width?: string
  marginRight?: number
  marginLeft?: number
  marginBottom?: number
  marginTop?: number
  padding?: string
  wrap?: boolean
  gap?: string
}

export const Main = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background: ${({ theme }) => theme.container.background};
  color: ${({ theme }) => theme.container.text};
`

export const Content = styled.div`
  width: 80%;
  flex: 1;
  padding: 40px;
  overflow: auto;
`

export const Flexbox = styled.div<StyledFlexBox>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex: ${({ flex = 'initial' }) => flex};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  width: ${({ width = 'auto' }) => `${width}`};
  margin-right: ${({ marginRight = 0 }) => `${marginRight}px`};
  margin-left: ${({ marginLeft = 0 }) => `${marginLeft}px`};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
  padding: ${({ padding = 0 }) => padding};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : '')};
  gap: ${({ gap = 0 }) => gap};
`

export const FlexRow = styled.div<any>`
  display: flex;
  font-weight: ${({ fontWeight }) => fontWeight};
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${({ padding = '.3em' }) => `${padding}`};
  width: ${({ width }) => `${width}`};
  margin-right: ${({ marginRight = '4' }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
`
export const FullscreenCentered = styled(Flexbox)<{ bgColor?: string }>`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${({ bgColor = 'transparent' }) => bgColor};
`
export const FlexboxColumn = styled(Flexbox)`
  flex-direction: column;
`

export const FlexboxRow = styled(Flexbox)`
  flex-direction: row;
`
