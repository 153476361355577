import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ContentContainer } from 'components/atoms/Generic/index'
import { Button } from 'components/atoms/Form/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import {
  StyledCorporateAddress,
  StyledCorporateAddressActions,
  StyledUnderlinedAddressHeading,
} from './Styles'
import { capitalizeFirstLetter } from 'helpers/utils'

const CorporateAddress = ({
  address,
  handleEditCorporateAddress,
  deleteCorporateAddress,
  canEditCorporateInformation,
  accountId,
}) => {
  const [isRemovingAddress, setIsRemovingAddress] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const handleDeleteCorporateAddress = async (addressId, accountId) => {
    setIsRemovingAddress(true)
    setDeleteDialogOpen(false)
    await deleteCorporateAddress(addressId, accountId)
  }

  useEffect(() => {
    return () => {
      setIsRemovingAddress(false)
      setDeleteDialogOpen(false)
    }
  }, [])

  return (
    <StyledCorporateAddress>
      <ContentContainer bgcolor={'0px solid transparent'}>
        <StyledUnderlinedAddressHeading>
          {capitalizeFirstLetter(address.addressType)} Address
        </StyledUnderlinedAddressHeading>
        <p>{address.street}</p>
        <p>
          {address.area}, {address.code}
        </p>
        <p>{address.country}</p>
        <StyledCorporateAddressActions>
          <Button
            disabled={isRemovingAddress || !canEditCorporateInformation}
            onClick={() => handleEditCorporateAddress(address)}
          >
            Edit
          </Button>
          <Button
            disabled={isRemovingAddress || !canEditCorporateInformation}
            onClick={() => setDeleteDialogOpen(true)}
            color="secondary"
          >
            Delet
            {isRemovingAddress ? 'ing' : 'e'}
          </Button>
        </StyledCorporateAddressActions>
      </ContentContainer>
      <ConfirmationDialog
        title="Remove entity from corporate"
        open={deleteDialogOpen}
        proceed={() => handleDeleteCorporateAddress(address.id, accountId)}
        message={`Are you sure you want to remove the address?`}
        disabled={isRemovingAddress}
        handleClose={() => setDeleteDialogOpen(false)}
      />
    </StyledCorporateAddress>
  )
}

CorporateAddress.propTypes = {
  address: PropTypes.shape({
    accountId: PropTypes.string,
    addressType: PropTypes.string,
    area: PropTypes.string,
    code: PropTypes.string,
    corporatesId: PropTypes.string,
    country: PropTypes.string,
    createdAt: PropTypes.string,
    id: PropTypes.string,
    street: PropTypes.string,
  }),
  accountId: PropTypes.number,
  deleteCorporateAddress: PropTypes.func,
  handleEditCorporateAddress: PropTypes.func,
  canEditCorporateInformation: PropTypes.bool,
}

export default CorporateAddress
