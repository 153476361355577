import React from 'react'
import { Page, Text, View, Document as PdfDocument } from '@react-pdf/renderer'
import { IconButton } from '@material-ui/core'
import { CopyAllRounded } from '@mui/icons-material'
import { handleCopyToClipBoard } from 'helpers/utils'

import { Title } from 'components/atoms/Title'

import { styles } from './Styles'
import ApprovalsSection from './ApprovalsSection'

import { IRiskProfileEddReport } from '../../../../interfaces/riskProfile'
import { formatRecommendationDisplayList } from './helpers'

const RiskProfileEddReportPdfView = ({ eddReportData, eddReportApprovals }) => {
  const {
    accountId,
    accountOpenedDate,
    affiliated,
    criminalHistory,
    dateOfBirth,
    emailAddress,
    employer,
    estimatedAnnualIncome,
    fullName,
    highRiskCountry,
    ipLocation,
    nationality,
    natureOfBusiness,
    occupationStatus,
    offChainTransactions,
    onChainTransactions,
    passportOrIdNumber,
    pepStatus,
    phoneNumber,
    politicalExposure,
    purposeOfAccount,
    residentialAddress,
    sourceOfFunds,
    totalCryptoReceives,
    totalCryptoSends,
    totalFiatDeposits,
    totalFiatWithdrawals,
    transactionHistoryExternal,
    transactionHistoryInternal,
    status,
    recommendation,
    createdAt,
  }: IRiskProfileEddReport = eddReportData
  const personalInformation = [
    { tableHeading: 'Full name', value: fullName },
    { tableHeading: 'Account id', value: accountId },
    {
      tableHeading: 'Account opened date',
      value: accountOpenedDate
        ? new Date(accountOpenedDate).toLocaleDateString()
        : '--',
    },
    {
      tableHeading: 'Date of birth',
      value: dateOfBirth ? new Date(dateOfBirth).toLocaleDateString() : '--',
    },
    { tableHeading: 'Nationality', value: nationality },
    { tableHeading: 'Passport/ID number', value: passportOrIdNumber },
    { tableHeading: 'Residential address', value: residentialAddress },
    { tableHeading: 'Phone number', value: phoneNumber },
    { tableHeading: 'Email address', value: emailAddress },
    { tableHeading: 'Occupation', value: occupationStatus },
    { tableHeading: 'Employer', value: employer },
    { tableHeading: 'Political exposure', value: politicalExposure },
    { tableHeading: 'Criminal history', value: criminalHistory },
    { tableHeading: 'Source of funds', value: sourceOfFunds },
    {
      tableHeading: 'Describe nature of business/employment generating funds',
      value: natureOfBusiness,
    },
    { tableHeading: 'Estimated annual income', value: estimatedAnnualIncome },
    { tableHeading: 'Purpose of account', value: purposeOfAccount },
    {
      tableHeading: 'Transaction history internal',
      value: transactionHistoryInternal,
    },
    {
      tableHeading: 'Transaction history external',
      value: transactionHistoryExternal,
    },
    { tableHeading: 'Total fiat deposits', value: totalFiatDeposits },
    { tableHeading: 'Total fiat withdrawals', value: totalFiatWithdrawals },
    { tableHeading: 'Total crypto receives', value: totalCryptoReceives },
    { tableHeading: 'Total crypto sends', value: totalCryptoSends },
    { tableHeading: 'Off-chain transactions', value: offChainTransactions },
    { tableHeading: 'On-chain transactions', value: onChainTransactions },
    { tableHeading: 'IP logs', value: ipLocation },
  ]

  const riskAssessmentInformation = [
    {
      tableHeading: 'Is the individual a PEP? If yes, provide details',
      value: pepStatus,
    },
    {
      tableHeading:
        'Is the individual located in a high-risk country? If yes, specify',
      value: highRiskCountry,
    },
    {
      tableHeading:
        'Is the individual affiliated with any sanctioned entities or individuals? If yes, provide details',
      value: affiliated,
    },
    {
      tableHeading:
        'Has the individual been involved in any criminal activities or investigations? If yes, provide details',
      value: criminalHistory,
    },
  ]

  const handleCopyReportLink = () => {
    handleCopyToClipBoard(window.location.href, false)
  }

  return accountId ? (
    <PdfDocument>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerMain}>
          <Text>
            ENHANCED DUE DILIGENCE FORM -{' '}
            {new Date(createdAt).toLocaleDateString()}
            <IconButton onClick={handleCopyReportLink}>
              <CopyAllRounded style={{ color: 'white' }} />
            </IconButton>
          </Text>
        </View>
        <View style={styles.header}>
          <Text>I. INDIVIDUAL ACCOUNTS</Text>
        </View>
        <View style={styles.header}>
          <Text>Personal Information</Text>
        </View>
        <View style={styles.table}>
          {personalInformation.map(({ tableHeading, value }) => (
            <View style={styles.tableRow} key={tableHeading}>
              <Text style={styles.cellHeading}>{tableHeading}</Text>
              <Text style={styles.cell}>{value || '--'}</Text>
            </View>
          ))}
        </View>
        <View style={styles.header}>
          <Text>Risk Assessment Information</Text>
        </View>
        <View style={styles.table}>
          {riskAssessmentInformation.map(({ tableHeading, value }) => (
            <View style={styles.tableRow} key={tableHeading}>
              <Text style={styles.cellHeading}>{tableHeading}</Text>
              <Text style={styles.cell}>{value || '--'}</Text>
            </View>
          ))}
        </View>
        <View style={styles.header}>
          <Text>Recommendation</Text>
        </View>
        <View style={styles.recommendation}>
          <View style={styles.recommendationText}>
            {status === 'APPROVED' &&
              formatRecommendationDisplayList(recommendation || '').map(
                (line) => (
                  <View style={styles.row}>
                    <Text>{line.replace('- ', '\u2022 ')}</Text>
                  </View>
                ),
              )}
            {status === 'PENDING' && (
              <Text>Edd report still pending approval</Text>
            )}
            {status === 'DECLINED' && <Text>Edd report rejected</Text>}
            {!status && <Text>No recommendation yet</Text>}
          </View>
        </View>
        <View style={styles.header}>
          <Text>Approvals</Text>
        </View>
        <ApprovalsSection eddReportApprovals={eddReportApprovals} />
      </Page>
    </PdfDocument>
  ) : (
    <Title text="No EDD report" type="h3" />
  )
}

export default RiskProfileEddReportPdfView
