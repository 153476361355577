import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { fetchUnassignedBlockchainReceives } from 'redux/unallocatedBlockchainReceives/actions'

export const submitRecieves = async (values, dispatch) => {
  const { id, accountId, comment = '' } = values
  let res = null
  try {
    res = await getAxios().post('/blockchain/receive/credit', {
      accountId,
      comment,
      id,
    })
    toast('Receive status updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(fetchUnassignedBlockchainReceives())
  } catch (e) {
    toast(`${e.response.data.message} `, { type: toast.TYPE.ERROR, autoClose: 2000 })
  }
  return res
}
