import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateOrderTypes } from 'redux/orderTypesForCurrencyPairs/actions'
import { bindActionCreators } from 'redux'
import { updateCurrencyPairOrderTypes } from 'redux/currencyPairs/actions'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import { TableRow, TableCell } from '@material-ui/core'

class OrderTypesCheckbox extends Component {
  handleChange = (event) => {
    const orderType = {
      currencyPairSymbol: this.props.pair.currencyPairSymbol,
      orderType: event.target.name,
      allowed: event.target.checked,
    }
    this.props.updateOrderTypes(orderType)
    this.props.updateCurrencyPairOrderTypes(orderType)
  }

  renderCheckBoxes = () => {
    const allAvailableOrderTypes = [
      'LIMIT_POST_ONLY',
      'LIMIT',
      'MARKET',
      'SIMPLE',
      'STOP_LOSS_LIMIT',
      'TAKE_PROFIT_LIMIT',
      'LIMIT_REDUCE_ONLY',
      'MARKET_REDUCE_ONLY',
    ]
    return allAvailableOrderTypes.map((orderType, i) => {
      const { disabled, pair } = this.props
      const handleCheckResult = () =>
        pair.orderTypes.findIndex((q) => q === orderType) > -1
      return (
        <TableCell style={this.props.cellStyles}>
          <Checkbox
            onClick={this.handleChange}
            checked={handleCheckResult()}
            name={orderType}
            disabled={disabled}
          />
        </TableCell>
      )
    })
  }

  render() {
    const { classes } = this.props
    return (
      <TableRow className={classes.headerRoot}>
        <TableCell style={this.props.cellStyles}>
          {this.props.pair.currencyPairSymbol}
        </TableCell>
        {this.renderCheckBoxes()}
      </TableRow>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateOrderTypes,
      updateCurrencyPairOrderTypes,
    },
    dispatch,
  )
export default connect(null, mapDispatchToProps)(OrderTypesCheckbox)
