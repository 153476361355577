import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { fetchKeycloakActivity } from 'redux/keycloak-activity/actions'
import { fetchRecentActivity } from 'redux/RecentActivity/actions'
import RawDataDualAuth from 'components/atoms/RawDataDualAuth/index'
import IconLoading from 'components/atoms/IconLoading'
import { getMsgDetails } from 'forms/SearchAccountEmails/submit'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { getKeycloakInfo } from 'redux/selectors/keycloak'

import KeycloakActity from './KeycloakActivity'
import Twilio from './index'

function createData(
  subject,
  status,
  opensCount,
  clicksCount,
  lastUpdated,
  msgId,
) {
  return {
    subject,
    status,
    opensCount,
    clicksCount,
    lastUpdated,
    msgId,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function RecentActivity(props) {
  const { accountId } = props.match.params
  const classes = useStyles()
  const [msgDetails, setMsgDetails] = React.useState({})
  const [expandedItem, setExpandedItem] = React.useState('')
  const [highlightedId, setHighlightedId] = React.useState('')
  const [expanded, setExpanded] = React.useState('')

  React.useEffect(() => {
    props.fetchRecentActivity(accountId)
  }, [])

  const setingExpand = (panel) => {
    setExpanded(panel)
  }

  const handleChange = (panel) => {
    setingExpand(panel)
  }

  const toggleRow = async (id) => {
    const currentId = expandedItem
    if (currentId !== id) {
      const msgDetails = await getMsgDetails(id)
      setExpandedItem(id)
      msgDetails && setMsgDetails(msgDetails)
    } else {
      setExpandedItem('')
    }
  }
  const {
    recentActivity: { data, status },
    keycloakInfo,
  } = props
  const rows = data.map((record) => {
    const { status, opensCount, subject, clicksCount, lastEventTime, msgId } =
      record

    return createData(
      subject,
      status,
      opensCount,
      clicksCount,
      lastEventTime,
      msgId,
    )
  })
  return (
    <>
      {status !== 'done' && (
        <TablePlaceholder>
          <IconLoading />
          <span> Email Activity Loading, please wait</span>
        </TablePlaceholder>
      )}
      {status === 'done' && (
        <ExpandingItem
          title={'Email Activity'}
          expanded={expanded}
          onExpand={handleChange}
          expandedView={
            <>
              <div className={classes.root}>
                {status === 'loading' && (
                  <TablePlaceholder>
                    <IconLoading />
                    <span>Loading, please wait</span>
                  </TablePlaceholder>
                )}
                {status === 'done' && (
                  <div className={classes.root}>
                    <Paper className={classes.paper}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          aria-label="enhanced table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell> Subject</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell> Opens Count</TableCell>
                              <TableCell>Clicks Count</TableCell>
                              <TableCell>Last Updated</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <>
                              {status === 'done' && data.length === 0 && (
                                <TableCell colSpan={5}>
                                  <TablePlaceholder>
                                    <span>No activity found</span>
                                  </TablePlaceholder>
                                </TableCell>
                              )}
                              {status === 'done' && (
                                <>
                                  {rows.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`
                                    const isHighlighted =
                                      highlightedId === row.msgId
                                    const shading = isHighlighted ? 'white' : ''
                                    const toggle = () => {
                                      setHighlightedId(
                                        !isHighlighted ? row.msgId : null,
                                      )
                                    }
                                    return (
                                      <>
                                        <TableRow
                                          hover
                                          onClick={toggle}
                                          style={{
                                            backgroundColor:
                                              isHighlighted && '#4569eb',
                                            color: shading,
                                          }}
                                          role="checkbox"
                                          tabIndex={-1}
                                          key={index}
                                          aria-label="collapsible table"
                                        >
                                          <TableCell
                                            onClick={() => toggleRow(row.msgId)}
                                          >
                                            {row.subject}
                                          </TableCell>
                                          <TableCell
                                            onClick={() => toggleRow(row.msgId)}
                                          >
                                            {row.status}
                                          </TableCell>
                                          <TableCell
                                            onClick={() => toggleRow(row.msgId)}
                                          >
                                            {row.opensCount}
                                          </TableCell>
                                          <TableCell
                                            onClick={() => toggleRow(row.msgId)}
                                          >
                                            {row.clicksCount}
                                          </TableCell>
                                          <TableCell
                                            onClick={() => toggleRow(row.msgId)}
                                            id={labelId}
                                            scope="row"
                                          >
                                            {moment(row.lastUpdated).format(
                                              'YYYY-MMM-DD HH:mm:ss',
                                            )}
                                          </TableCell>
                                        </TableRow>
                                        {expandedItem === row.msgId && (
                                          <TableCell colSpan={5}>
                                            <Details>
                                              <DetailsBlockWithTitle
                                                title={'Raw Data'}
                                              >
                                                <RawDataDualAuth
                                                  data={msgDetails}
                                                  title={'Raw Data'}
                                                />
                                              </DetailsBlockWithTitle>
                                            </Details>
                                          </TableCell>
                                        )}
                                      </>
                                    )
                                  })}
                                </>
                              )}
                            </>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                )}
              </div>
            </>
          }
        />
      )}
      <Twilio />
      <KeycloakActity keycloakInfo={keycloakInfo} />
    </>
  )
}

const mapStateToProps = (state) => ({
  recentActivity: state.recentActivity,
  keycloakInfo: getKeycloakInfo(state),
  twilioData: state.twilio.twilioData,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRecentActivity,
      fetchKeycloakActivity,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(RecentActivity)
