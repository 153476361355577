import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  InputGroup,
  Button,
} from 'components/atoms/Form'
import {
  required, isNumber, maxLength128, validFieldName,
} from 'forms/fieldValidators'
import { BASE } from 'theme/base-units'

import { renderInput, renderSwitch } from '../fields'
import { submit } from './submit'
import AlertDialog from '../AddCurrencyPair/confirmation'

let Form = props => {
  const {
    handleSubmit, isEditing, onCancel, onSubmit, valid, pristine, submitting,
  } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {isEditing ? (
        <>
          <FormTitle>Add Payment Reference</FormTitle>
          <InputGroup>
            <Field
              name={'paymentReferenceFieldName'}
              component={renderInput}
              label="Payment Reference Field Name"
              margin={`0 ${BASE * 2}px 0 0`}
              validate={[validFieldName, maxLength128]}
              fullWidth
            />
          </InputGroup>
          <InputGroup justify={'flex-end'}>
            <AlertDialog onSubmit={handleSubmit(onSubmit)} values={!valid || pristine || submitting} />
            <Button variant="contained" color="secondary" style={{ width: '80px' }} onClick={onCancel} >
              Cancel
            </Button>
          </InputGroup>
        </>
      ) : (
        <>
          <FormTitle>Add Currency</FormTitle>
          <InputGroup>
            <Field name={'symbol'} component={renderInput} label="Symbol" margin={`0 ${BASE * 2}px 0 0`} fullWidth validate={[required]} />
            <Field name={'decimalPlaces'} component={renderInput} label="Decimal Places" fullWidth validate={[required, isNumber]} />
          </InputGroup>
          <InputGroup>
            <Field name={'shortName'} component={renderInput} label="Short Name" margin={`0 ${BASE * 2}px 0 0`} fullWidth validate={[required]} />
            <Field name={'longName'} component={renderInput} label="Long Name" fullWidth validate={[required]} />
          </InputGroup>
          <InputGroup>
            <Field name={'withdrawalDecimalPlaces'} component={renderInput} label="Supported Withdraw Decimal Places" margin={`0 ${BASE * 2}px 0 0`} validate={[required, isNumber]} fullWidth />
            <Field name={'currencyDecimalPlaces'} component={renderInput} label="Currency Decimal Places" validate={[required, isNumber]} fullWidth />
          </InputGroup>
          <InputGroup>
            <Field name={'paymentReferenceFieldName'} component={renderInput} label="Payment Reference Field Name" margin={`0 ${BASE * 2}px 0 0`} validate={[validFieldName, maxLength128]} fullWidth/>
            <div style={{ width: '100%' }}>
              <Field name={'isActive'} component={renderSwitch} label="Is Active" formatLabel={false}/>
            </div>
          </InputGroup>
          <InputGroup justify={'flex-end'}>
            <AlertDialog onSubmit={handleSubmit(onSubmit)} values={!valid || pristine || submitting} />
            <Button variant="contained" color="secondary" style={{ width: '80px' }} onClick={onCancel} >
              Cancel
            </Button>
          </InputGroup>
      </>
      )}
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)
export default ReduxForm
