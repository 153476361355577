import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { TableCell } from '@material-ui/core'
import { toast } from 'react-toastify'

import './Styles.css'
import moment from 'moment'
import MuiSwitcherWithTitle from 'components/atoms/MuiSwitcherWithTitle'
import { FadeInButton } from 'components/atoms/Styles'
import getAxios from 'helpers/axios'
import { VIBRANT_ROYAL_BLUE } from 'theme/colors'

import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Actions } from 'components/atoms/Table/styles'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import FundingConfirmation from 'forms/FundingConfirmationForm'
import ErrorToast from 'components/molecules/ErrorToast'

import FundingRow from './FundingRow'
import { Headers } from './headers'
import { FundingInfoAndEventsRowData } from './interfaces'

type BulkAction = 'APPROVE' | 'DECLINE'

const FundingAccordion = ({
  s,
  deposits,
  toggleView,
  state,
  currency,
  toggleForm,
  status,
  updateFundingTable,
  getTitle,
  addTotalAmount,
  handleHighlightedRowChange,
}) => {
  const [isBulkActionsActive, setIsBulkActionsActive] = useState(false)
  const [selectedFundingInfo, setSelectedFundingInfo] = useState(
    [] as FundingInfoAndEventsRowData[],
  )
  const [totalDepositsAmount, setTotalDepositsAmount] = useState(0)

  const [bulkModalOpen, setBulkModalOpen] = useState(false)
  const [bulkSelectedActionType, setBulkSelectedActionType] = useState<
    BulkAction | string
  >('')

  const toggleBulkActions = () => setIsBulkActionsActive((prev) => !prev)

  const handleSelectedBulkFundingClick = (
    fundingInfo: FundingInfoAndEventsRowData,
  ) => {
    const updatedSelectedItems = [...selectedFundingInfo]
    const indexOfExistingTransfer = updatedSelectedItems.findIndex(
      (currentSelectedTransfer) =>
        currentSelectedTransfer.identifier === fundingInfo.identifier,
    )
    if (indexOfExistingTransfer === -1) {
      updatedSelectedItems.push(fundingInfo)
    } else {
      updatedSelectedItems.splice(indexOfExistingTransfer, 1)
    }
    handleHighlightedRowChange(fundingInfo.identifier)
    setSelectedFundingInfo(updatedSelectedItems)
  }

  const toggleSelectAll = (
    currentFundingData: FundingInfoAndEventsRowData[],
  ) => {
    if (selectedFundingInfo.length === currentFundingData.length) {
      setSelectedFundingInfo([])
    } else {
      setSelectedFundingInfo(currentFundingData)
    }
  }

  const addTotalAmountOfDeposits = (deposit) => {
    const total = deposit
      .map((d) => d.amount)
      .reduce((accumulator, value) => {
        const checkedCurrentValue = value
          ? parseFloat(
              typeof value === 'string' ? value.split(',').join('') : value,
            )
          : 0
        return accumulator + checkedCurrentValue
      }, 0)
    setTotalDepositsAmount(total)
    return total
  }

  const handleProceedBulkFundingInfoCheck = async (comment: string) => {
    addTotalAmountOfDeposits(selectedFundingInfo)
    await Promise.all(
      selectedFundingInfo.map(async (item) => {
        try {
          const currentStatus = item.status
          const { accountId, identifier } = item
          await getAxios().post('/funding/update/funding/deposits', {
            accountId,
            identifier,
            status: bulkSelectedActionType,
            comment,
            currentStatus,
          })
          await updateFundingTable(item)
        } catch (error) {
          ErrorToast(error)
        }
      }),
    )
    toast('Bulk deposits process finished', {
      type: toast.TYPE.SUCCESS,
      autoClose: 1500,
    })
    setBulkModalOpen(false)
    setBulkSelectedActionType('')
    setSelectedFundingInfo([])
    setTotalDepositsAmount(0)
  }

  const openBulkFundingModel = (action: BulkAction) => {
    setBulkModalOpen(true)
    addTotalAmountOfDeposits(selectedFundingInfo)
    setBulkSelectedActionType(action)
  }

  const handleCloseModal = () => {
    setBulkModalOpen(false)
    setBulkSelectedActionType('')
    setSelectedFundingInfo([])
    setTotalDepositsAmount(0)
  }

  return (
    <>
      <ExpandingItem
        key={s}
        title={getTitle(s)}
        itemsAmount={(deposits && deposits.length) || 0}
        totalAmount={`${addTotalAmount(deposits) || 0} ${currency}`}
        expanded={state.expanded === s}
        onExpand={() => toggleView(s)}
        opensMultiple
        expandedView={
          <>
            <Actions style={{ color: VIBRANT_ROYAL_BLUE }} justify={'flex-end'}>
              <>
                <FadeInButton
                  onClick={() => {
                    openBulkFundingModel('DECLINE')
                  }}
                  active={isBulkActionsActive}
                  disabled={selectedFundingInfo.length === 0}
                >
                  Reject
                </FadeInButton>
                {getTitle(s) === 'DEPOSITS UNDER REVIEW' && (
                  <FadeInButton
                    onClick={() => {
                      openBulkFundingModel('APPROVE')
                    }}
                    active={isBulkActionsActive}
                    disabled={selectedFundingInfo.length === 0}
                  >
                    Approve
                  </FadeInButton>
                )}
              </>
              <MuiSwitcherWithTitle
                checked={isBulkActionsActive}
                text="Enable Bulk Actions"
                onChange={toggleBulkActions}
              />
            </Actions>
            {deposits && (
              <div>
                <Paper>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <TableHead>
                        <TableRow>
                          <Headers
                            isBulkActionsActive={isBulkActionsActive}
                            deposits={deposits}
                            selectedFundingInfo={selectedFundingInfo}
                            toggleSelectAll={toggleSelectAll}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {status !== 'done' && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <LoadingBlock />
                            </TableCell>
                          </TableRow>
                        )}
                        {status === 'done' &&
                          deposits.map(
                            (deposit: FundingInfoAndEventsRowData, i) => {
                              deposit.createdAt = moment(
                                deposit.createdAt,
                              ).format('YYYY-MMM-DD HH:mm:ss')
                              return (
                                <FundingRow
                                  key={i}
                                  data={deposit}
                                  toggleForm={toggleForm}
                                  handleSelectedBulkFundingClick={
                                    handleSelectedBulkFundingClick
                                  }
                                  formShown={state.formShown}
                                  isHighlighted={
                                    state.highlightedId === deposit.identifier
                                  }
                                  isBulkActionsActive={isBulkActionsActive}
                                  isSelected={
                                    selectedFundingInfo.findIndex(
                                      (fundingInfo) =>
                                        fundingInfo.identifier ===
                                        deposit.identifier,
                                    ) > -1
                                  }
                                />
                              )
                            },
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <ConfirmationDialog
                    title={'Funding deposits'}
                    message={`You are about to ${bulkSelectedActionType} ${selectedFundingInfo.length} deposits, Worth ${totalDepositsAmount} ${currency}
                   Would you like to proceed?`}
                    open={bulkModalOpen}
                    proceed={handleProceedBulkFundingInfoCheck}
                    handleClose={handleCloseModal}
                    disabled={selectedFundingInfo.length === 0}
                    button={{
                      confirm: {
                        text: 'Proceed',
                      },
                      decline: {
                        text: 'Cancel',
                      },
                    }}
                    buttonsHidden={
                      bulkSelectedActionType === 'DECLINE' ||
                      bulkSelectedActionType === 'APPROVE'
                    }
                  >
                    <>
                      {
                        <FundingConfirmation
                          initialValues={{
                            comment: '',
                          }}
                          form={'funding_form'}
                          onSubmit={({ comment }) =>
                            handleProceedBulkFundingInfoCheck(comment)
                          }
                          onCancel={handleCloseModal}
                        />
                      }
                    </>
                  </ConfirmationDialog>
                </Paper>
              </div>
            )}
          </>
        }
      />
    </>
  )
}

export default FundingAccordion
