import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, maxLength256 } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { renderInput } from '../fields'
import { submit } from './submit'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <FormContainer
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormTitle>Unblock Account</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required, maxLength256]}
          component={renderInput}
          fullWidth
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin='0 16px 0 0'>
            Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
