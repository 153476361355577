import {
  FETCH_CURRENCIES_PAIRS_SUCCESS,
  FETCH_CURRENCIES_PAIRS_ERROR,
  FETCH_CURRENCY_PAIRS_SUCCESS,
  FETCH_CURRENCY_PAIRS_ERROR,
  FETCH_CURRENCY_PAIRS_START,
  UPDATE_CURRENCY_LIMIT_START,
  UPDATE_CURRENCY_LIMIT_SUCCESS,
  UPDATE_CURRENCY_LIMIT_ERROR,
  UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_START,
  UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_SUCCESS,
  UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_ERROR,
  TOGGLE_CURRENCY_PAIR_MARGINING_START,
  TOGGLE_CURRENCY_PAIR_MARGINING_SUCCESS,
  TOGGLE_CURRENCY_PAIR_MARGINING_ERROR,
  UPDATE_CURRENCY_PAIR_ORDER_TYPES,
  UPDATE_CURRENCY_EXCHANGE_START,
  UPDATE_CURRENCY_EXCHANGE_SUCCESS,
  UPDATE_CURRENCY_EXCHANGE_ERROR,
  TOGGLE_POST_ONLY_MODE_START,
  TOGGLE_POST_ONLY_MODE_SUCCESS,
  TOGGLE_POST_ONLY_MODE_ERROR,
  FETCH_POST_ONLY_MODE_DATA_START,
  FETCH_POST_ONLY_MODE_DATA_SUCCESS,
  FETCH_POST_ONLY_MODE_DATA_ERROR,
  FETCH_SPOT_CURRENCY_PAIRS_START,
  FETCH_SPOT_CURRENCY_PAIRS_SUCCESS,
  FETCH_SPOT_CURRENCY_PAIRS_ERROR,
  FETCH_PREP_CURRENCY_PAIRS_START,
  FETCH_PREP_CURRENCY_PAIRS_SUCCESS,
  FETCH_PREP_CURRENCY_PAIRS_ERROR,
  FETCH_CURRENCIES_PAIRS_START,
} from './actions'

const initialState = {
  data: [],
  status: 'pristine',
  currencyPairs: {
    status: 'pristine',
    data: [],
    isUpdatingCurrencyPair: false,
  },
  postOnlyModeData: {
    isFetchingPostOnlyModeData: false,
    isUpdatingPostOnlyMode: false,
    isInPostOnlyMode: false,
    previousCurrencyPairOrderTypesDataStored: [],
  },
  availableSpotCurrencyPairsList: [],
  fetchingSpotCurrencyPairs: false,
  availableFuturesCurrencyPairsList: [],
  fetchingFuturesCurrencyPairs: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_PAIRS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_CURRENCIES_PAIRS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    case UPDATE_CURRENCY_LIMIT_START:
    case FETCH_CURRENCIES_PAIRS_START:
    case FETCH_CURRENCY_PAIRS_START: {
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          status: 'loading',
        },
        status: 'loading',
      }
    }
    case FETCH_SPOT_CURRENCY_PAIRS_START: {
      return {
        ...state,
        fetchingSpotCurrencyPairs: true,
      }
    }
    case FETCH_SPOT_CURRENCY_PAIRS_SUCCESS: {
      const payload = action.payload
      const availableSpotCurrencyPairsList = payload
        .filter((tx) => {
          return (
            tx.currencyPairType.includes('SPOT') &&
            tx.exchange.includes('VALR') &&
            tx.active
          )
        })
        .map((pair) => ({
          label: pair.symbol,
          value: pair.symbol,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
      return {
        ...state,
        availableSpotCurrencyPairsList,
        fetchingSpotCurrencyPairs: false,
      }
    }
    case FETCH_SPOT_CURRENCY_PAIRS_ERROR: {
      return {
        ...state,
        fetchingSpotCurrencyPairs: false,
      }
    }

    case FETCH_PREP_CURRENCY_PAIRS_START: {
      return {
        ...state,
        fetchingFuturesCurrencyPairs: true,
      }
    }
    case FETCH_PREP_CURRENCY_PAIRS_SUCCESS: {
      const payload = action.payload
      const availableFuturesCurrencyPairsList = payload
        .filter((tx) => {
          return (
            tx.currencyPairType.includes('FUTURE') &&
            tx.exchange.includes('VALR') &&
            tx.active
          )
        })
        .map((pair) => ({
          label: pair.symbol,
          value: pair.symbol,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
      return {
        ...state,
        availableFuturesCurrencyPairsList,
        fetchingFuturesCurrencyPairs: false,
      }
    }
    case FETCH_PREP_CURRENCY_PAIRS_ERROR: {
      return {
        ...state,
        fetchingFuturesCurrencyPairs: false,
      }
    }

    case UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_START:
    case UPDATE_CURRENCY_EXCHANGE_START:
    case TOGGLE_CURRENCY_PAIR_MARGINING_START: {
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          isUpdatingCurrencyPair: true,
        },
      }
    }

    case TOGGLE_CURRENCY_PAIR_MARGINING_SUCCESS: {
      const { data } = action.payload
      const updatedCurrencyPairsArray = [...state.currencyPairs.data]
      const indexOfCurrencyPairToUpdate = state.currencyPairs.data.findIndex(
        (pair) => pair.symbol === data.symbol,
      )
      updatedCurrencyPairsArray[indexOfCurrencyPairToUpdate] = {
        ...updatedCurrencyPairsArray[indexOfCurrencyPairToUpdate],
        marginTradingAllowed: data.allowMargin,
      }
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          data: updatedCurrencyPairsArray,
          isUpdatingCurrencyPair: false,
        },
      }
    }

    case UPDATE_CURRENCY_EXCHANGE_SUCCESS: {
      const data = action.payload
      const updatedCurrencyPairsArray = [...state.currencyPairs.data]
      const indexOfCurrencyPairToUpdate = state.currencyPairs.data.findIndex(
        (pair) => pair.symbol === data.symbol,
      )
      updatedCurrencyPairsArray[indexOfCurrencyPairToUpdate] = {
        ...updatedCurrencyPairsArray[indexOfCurrencyPairToUpdate],
        exchange: data.exchange,
      }
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          data: updatedCurrencyPairsArray,
          isUpdatingCurrencyPair: false,
        },
      }
    }

    case FETCH_CURRENCY_PAIRS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          status: 'done',
          data,
        },
      }
    }

    case UPDATE_CURRENCY_LIMIT_ERROR:
    case FETCH_CURRENCY_PAIRS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          error,
          status: 'error',
        },
      }
    }

    case UPDATE_CURRENCY_LIMIT_SUCCESS: {
      const { data } = action.payload
      const {
        currencyPairSymbol,
        minBase,
        maxBase,
        minQuote,
        maxQuote,
        tickSize,
        baseDecimalPlaces,
      } = data
      const currencyPairIndexToUpdate = state.currencyPairs.data.findIndex(
        (currencyPair) => currencyPair.symbol === currencyPairSymbol,
      )

      if (currencyPairIndexToUpdate === -1) break

      const updatedCurrencyPairsListData = [...state.currencyPairs.data]
      const updatedCurrencyPairData = {
        ...state.currencyPairs.data[currencyPairIndexToUpdate],
        minBaseAmount: minBase,
        maxBaseAmount: maxBase,
        minQuoteAmount: minQuote,
        maxQuoteAmount: maxQuote,
        tickSize,
        baseDecimalPlaces,
      }

      updatedCurrencyPairsListData[currencyPairIndexToUpdate] =
        updatedCurrencyPairData

      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          status: 'done',
          data: updatedCurrencyPairsListData,
        },
      }
    }

    case UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_SUCCESS: {
      const { currencyPairSymbol, active } = action.payload

      const curcurrencyPairIndexToUpdateStatus =
        state.currencyPairs.data.findIndex(
          (currencyPair) => currencyPair.symbol === currencyPairSymbol,
        )
      if (curcurrencyPairIndexToUpdateStatus === -1) break

      const updatedCurrencyPairsData = [...state.currencyPairs.data]

      const newCurrencyPairData = {
        ...state.currencyPairs.data[curcurrencyPairIndexToUpdateStatus],
        active,
      }

      updatedCurrencyPairsData[curcurrencyPairIndexToUpdateStatus] =
        newCurrencyPairData

      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          data: updatedCurrencyPairsData,
        },
      }
    }

    case TOGGLE_CURRENCY_PAIR_MARGINING_ERROR:
    case UPDATE_CURRENCY_EXCHANGE_ERROR:
    case UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_ERROR: {
      return {
        ...state,
        currencyPairs: {
          ...state.currencyPairs,
          isUpdatingCurrencyPair: false,
        },
      }
    }

    case UPDATE_CURRENCY_PAIR_ORDER_TYPES: {
      try {
        const orderTypeData = action.payload

        const updatedCurrencyPairsData = [...state.data]

        const currencyPairToUpdate = updatedCurrencyPairsData.find(
          (pair) =>
            pair.currencyPairSymbol === orderTypeData.currencyPairSymbol,
        )
        if (currencyPairToUpdate) {
          const indexOfOrderType = currencyPairToUpdate.orderTypes.findIndex(
            (orderType) => orderType === orderTypeData.orderType,
          )
          if (indexOfOrderType > -1) {
            currencyPairToUpdate.orderTypes.splice(indexOfOrderType, 1)
          } else {
            currencyPairToUpdate.orderTypes.push(orderTypeData.orderType)
          }
        }

        return {
          ...state,
          currencyPairs: {
            ...state.currencyPairs,
            data: updatedCurrencyPairsData,
          },
        }
      } catch (error) {
        console.log(error)
        return state
      }
    }

    case FETCH_POST_ONLY_MODE_DATA_START: {
      return {
        ...state,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isFetchingPostOnlyModeData: true,
        },
      }
    }
    case FETCH_POST_ONLY_MODE_DATA_SUCCESS: {
      return {
        ...state,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isFetchingPostOnlyModeData: false,
          isInPostOnlyMode: action.payload.isInPostOnlyMode,
          previousCurrencyPairOrderTypesDataStored:
            action.payload.previousCurrencyPairOrderTypesDataStored,
        },
      }
    }
    case FETCH_POST_ONLY_MODE_DATA_ERROR: {
      return {
        ...state,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isFetchingPostOnlyModeData: false,
        },
      }
    }

    case TOGGLE_POST_ONLY_MODE_START: {
      return {
        ...state,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isUpdatingPostOnlyMode: true,
        },
      }
    }
    case TOGGLE_POST_ONLY_MODE_SUCCESS: {
      const { updatedCurrencyPairsOrderTypesData, ...rest } = action.payload
      const updatedCurrencyPairsOrderTypes = [...state.data]
      updatedCurrencyPairsOrderTypesData.forEach((cp) => {
        const indexOfCurrencyPairToUpdate =
          updatedCurrencyPairsOrderTypes.findIndex(
            (p) => p.currencyPairSymbol === cp.currencyPairSymbol,
          )
        if (indexOfCurrencyPairToUpdate > -1) {
          updatedCurrencyPairsOrderTypes[indexOfCurrencyPairToUpdate] = cp
        }
      })
      return {
        ...state,
        data: updatedCurrencyPairsOrderTypes,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isUpdatingPostOnlyMode: false,
          ...rest,
        },
      }
    }
    case TOGGLE_POST_ONLY_MODE_ERROR: {
      return {
        ...state,
        postOnlyModeData: {
          ...state.postOnlyModeData,
          isUpdatingPostOnlyMode: false,
        },
      }
    }

    default:
      return state
  }
}
