import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import CorporateRiskProfileDualAuth from '../RiskProfileDualAuth/CorporateRiskProfileDualAuth'
import CorporateRiskManagementNav from './CorporateRiskManagementNav'

const routes = [
  '/compliance/risk-profiles/management/risk-scoring/corporate',
  '/compliance/risk-profiles/management/risk-scoring/corporate/dual-auth',
]

const CorporateProfileManagement = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="RISK ACCOUNT SCORING"
          component={ForwardedNavLink}
          to={routes[0]}
        />
        <NavigationTab
          value={routes[1]}
          label="DUAL AUTH"
          component={ForwardedNavLink}
          to={routes[1]}
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route
            exact
            path={routes[1]}
            component={CorporateRiskProfileDualAuth}
          />
          <Route path={routes[0]} component={CorporateRiskManagementNav} />
        </Switch>
      </ContentContainer>
    </>
  )
}
export default CorporateProfileManagement
