import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Spacer } from 'components/atoms/Generic/index'
import { Button } from 'components/atoms/Form/index'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import _ from 'lodash'
import {
  StyledAddEntityFormWrapper,
  StyledDash,
  StyledLinkEntityWrapper,
  StyledOrWrapper,
} from '../../Styles'
import EntityForm from '../../../forms/EntityForm/index'
import { buildQuery } from 'helpers/utils'

const AddEntityPage = ({
  closeModal,
  entitiesCollectionName,
  corporateAccountId,
  accountId,
  onSubmit,
  linkEntityAndAddToNaturalEntities,
  fetchCorporateSanctionInformation,
}) => {
  const [foundAccounts, setFoundAccounts] = useState([])
  const [selectedAccountToLink, setSelectedAccountToLink] = useState({})
  const [isSearchingForAccount, setIsSearchingForAccount] = useState(false)
  const [linkingAccountInProgress, setLinkingAccountInProgress] = useState(
    false,
  )
  const handleSearchForAccounts = async query => {
    try {
      if (!query) return setIsSearchingForAccount(false)
      const formattedEmailQuery = buildQuery({ email: query })
      setFoundAccounts([])
      const { data: emailSearchData } = await getAxios().get(
        `/account/profileinfo/?${formattedEmailQuery}`,
      )
      const formattedNameQuery = buildQuery({ name: query })

      const { data: nameSearchData } = await getAxios().get(
        `/account/profileinfo/?${formattedNameQuery}`,
      )

      const emailSearchError = emailSearchData.profileInfoResults.find(
        item => item.error,
      )

      if (emailSearchError && emailSearchError.error) {
        throw new Error(
          emailSearchError.error.message || emailSearchError.message,
        )
      }

      const nameSearchError = nameSearchData.profileInfoResults.find(
        item => item.error,
      )

      if (nameSearchError && nameSearchError.error) {
        throw new Error(
          nameSearchError.error.message || nameSearchError.message,
        )
      }

      const formattedNameSearchData = nameSearchData.profileInfoResults.map(
        account => ({
          email: account.sensitiveInfo.email,
          accountId: account.accountId,
        }),
      )

      const formattedEmailSearchData = emailSearchData.profileInfoResults.map(
        account => ({
          email: account.sensitiveInfo.email,
          accountId: account.accountId,
        }),
      )
      const finalSearchResults = formattedEmailSearchData.concat(
        formattedNameSearchData,
      )
      setFoundAccounts(finalSearchResults)
      setIsSearchingForAccount(false)
    } catch (error) {
      ErrorToast(error)
      setIsSearchingForAccount(false)
    }
  }

  const debouncedSearch = _.debounce(q => handleSearchForAccounts(q), 1000)

  const handleSearch = useCallback(query => {
    if (!isSearchingForAccount) setIsSearchingForAccount(true)
    debouncedSearch(query)
  }, [])

  return (
    <StyledAddEntityFormWrapper>
      <p>Add an existing VALR account</p>
      <StyledLinkEntityWrapper>
        <Autocomplete
          id="combo-box-demo"
          options={foundAccounts}
          filterOptions={options => options}
          loading={isSearchingForAccount}
          getOptionLabel={option =>
            `Email: ${option.email} Account Id: ${option.accountId}`
          }
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedAccountToLink(newValue)
            } else {
              setSelectedAccountToLink({})
            }
          }}
          onInputChange={(event, newInputValue) => {
            handleSearch(newInputValue)
          }}
          style={{ width: 350 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Search for account by email or name"
              variant="outlined"
            />
          )}
        />
        <Spacer margin="0px 12px" />
        <Button
          style={{ width: '30%' }}
          onClick={async () => {
            setLinkingAccountInProgress(true)
            await linkEntityAndAddToNaturalEntities(
              {
                accountIdToLink: selectedAccountToLink.accountId,
                accountId,
                corporateAccountId,
              },
              closeModal,
            )
            await fetchCorporateSanctionInformation(accountId)
            setLinkingAccountInProgress(false)
          }}
          disabled={
            Object.values(selectedAccountToLink).length < 2 ||
            linkingAccountInProgress
          }
        >
          Link
          {linkingAccountInProgress ? 'ing' : ''} Account
          {linkingAccountInProgress ? '...' : ''}
        </Button>
      </StyledLinkEntityWrapper>
      <StyledOrWrapper>
        <StyledDash />
        <Spacer margin="0px 12px">
          <p>OR</p>
        </Spacer>
        <StyledDash />
      </StyledOrWrapper>
      <p>Create a new account</p>
      <EntityForm
        initialValues={{
          firstName: '',
          lastName: '',
          idNumber: '',
          roles: [],
          corporateAccountId,
          accountId,
          entityType: 'NATURAL',
          isPep: false,
        }}
        accountId={accountId}
        onSubmit={onSubmit}
        entitiesCollectionName={entitiesCollectionName}
        form={`AddEntityForm`}
        onCancel={() => closeModal(false)}
      />
    </StyledAddEntityFormWrapper>
  )
}

AddEntityPage.propTypes = {
  closeModal: PropTypes.func,
  entitiesCollectionName: PropTypes.string,
  corporateAccountId: PropTypes.number,
  accountId: PropTypes.string,
  onSubmit: PropTypes.func,
  linkEntityAndAddToNaturalEntities: PropTypes.func,
}

export default AddEntityPage
