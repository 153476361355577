import { match } from 'interfaces'
import { OpenOrder } from 'interfaces/openOrders'
import { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { DispatchAction } from 'redux/trading/actions'

export const useAccountOpenOrders = (
  fetchAccountOpenOrders: (accountId: string) => Promise<void>,
  cancelOpenOrderForAccount: (orderData: {
    accountId: number | string
    orderId: string
    currencyPair: string
  }) => Promise<void>,
  accountId: string,
) => {
  const [expandedItem, setExpandedItem] = useState('')
  const [confirmationModalData, setConfirmationModalData] = useState<{
    isOpen: boolean
    data: null | OpenOrder
  }>({
    isOpen: false,
    data: null,
  })
  const resetModal = () =>
    setConfirmationModalData({ isOpen: false, data: null })
  const handleProceedCancelOrder = async () => {
    if (confirmationModalData.data) {
      await cancelOpenOrderForAccount({
        accountId: confirmationModalData.data.accountId,
        currencyPair: confirmationModalData.data.pair.symbol,
        orderId: confirmationModalData.data.id,
      })
      resetModal()
    }
  }

  useEffect(() => {
    fetchAccountOpenOrders(accountId)
  }, [])

  return {
    expandedItem,
    confirmationModalData,
    setConfirmationModalData,
    handleProceedCancelOrder,
    resetModal,
    setExpandedItem,
  }
}
