import React, { Component, Fragment } from 'react'
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  Title,
  TitleContainer,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import ApplicationHttpRow from './row'
import { BASE } from 'theme/base-units'
import { FlexboxColumn } from 'components/layout'
import InfiniteScroll from 'react-infinite-scroll-component'

class LogsMainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      highlightedId: '',
    }
  }

  toggleView = () => {
    var expanded = !this.state.expanded
    this.setState({
      expanded,
    })
  }

  mapDataToTbl = logsList => {
    return logsList.map((log, i) => {
      return (
        <ApplicationHttpRow
          key={i++}
          log={log}
          setHighlightedId={() => this.setHighlightedId(i)}
          isHighlighted={this.state.highlightedId === i}
        />
      )
    })
  }

  fetchMore = () => {
    const id = this.props.match.params.accountId
    const { fetchLogs, title, data } = this.props
    const size = data.length > 100 ? data.length : 100
    fetchLogs({ id, type: title, size })
  }
  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  render() {
    const { title, data, status } = this.props
    return (
      <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
        {status === 'done' && (
          <Fragment>
            <TitleContainer>
              <Title>
                {title} ({(data && data.length) || 0})
              </Title>
            </TitleContainer>
            <Table>
              <TableHeader>
                <TableCell flex={0.4}>AccountId</TableCell>
                <TableCell flex={0.6}>IP</TableCell>
                <TableCell flex={0.5}>Country For</TableCell>
                <TableCell flex={0.7}>Device Id</TableCell>
                <TableCell flex={0.6}>Request Method</TableCell>
                <TableCell flex={2.5}>Request Url</TableCell>
                <TableCell flex={0.8}>Timestamp</TableCell>
              </TableHeader>
              <InfiniteScroll
                dataLength={data.length}
                next={this.fetchMore}
                useWindow={false}
                height={500}
              >
                {data.length > 0 && (
                  <TableBody>{this.mapDataToTbl(data)}</TableBody>
                )}
                {data.length === 0 && (
                  <TablePlaceholder>No Logs found</TablePlaceholder>
                )}
              </InfiniteScroll>
            </Table>
          </Fragment>
        )}
      </FlexboxColumn>
    )
  }
}

export default LogsMainContainer
