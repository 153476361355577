import React, { useState } from 'react'
import { pure } from 'recompose'
import TableCell from '@material-ui/core/TableCell'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import TableRow from '@material-ui/core/TableRow'
import { oppositeColor } from 'helpers/utils'
import { GREEN_COLOR_PAIR, RED_COLOR_PAIR } from 'theme/colors'
import * as moment from 'moment'

import {
  Details,
  InfoBlock,
  JsonBlock,
  DetailsBlockWithTitle,
  InfoValue,
  InfoLabel,
} from 'components/atoms/Details'
import ReviewView from 'components/organisms/ReviewView'
import { Button } from 'components/atoms/Form/index'

import RollBackFailedSends from './rollBackFailedSends'
import { submitFailedSends } from './submitFailedSends'
import { Sendstyle } from './style'

const SendsRow = props => {
  const {
    fetchPendingDualAuth = () => {},
    tx,
    rawData,
    setHighlightedId,
    isHighlighted,
    dualAuthData,
    checkIfIsPending,
  } = props
  const [copied, setIsCoppied] = useState(false)
  const [
    overRideAlreadySentAndOverRideChecked,
    setOverRideAlreadySentAndOverRideChecked,
  ] = useState(false)
  const [isReversingReservedBalance, setIsReversingReservedBalance] = useState(
    false,
  )
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const shading = isHighlighted ? 'white' : ''
  const toggle = () => {
    setHighlightedId(!isHighlighted ? tx.uniqueId : null)
  }

  const copyText = data => {
    const dataToCopy = {
      accountId: data.accountId || 'N/A',
      amount: data.amount || 'N/A',
      uniqueId: data.uniqueId || 'N/A',
      verified: data.verified || 'N/A',
      confirmations: data.confirmations,
      transactionHash: data.transactionHash || 'N/A',
      coin: data.currency.symbol || 'N/A',
      status: data.status || 'N/A',
      date: moment(data.createdAt).format('YYYY-MMM-DD HH:mm:ss') || 'N/A',
    }

    setTimeout(() => {
      setIsCoppied(false)
    }, 5000)

    return JSON.stringify(dataToCopy)
      .split(',')
      .join('\n')
  }

  const handleReverseSendsClick = async () => {
    setIsReversingReservedBalance(true)
    await submitFailedSends(
      tx.withdrawalId,
      tx.accountId,
      overRideAlreadySentAndOverRideChecked,
    )
    await fetchPendingDualAuth()
    setOverRideAlreadySentAndOverRideChecked(false)
    setIsReversingReservedBalance(false)
  }
  return (
    <>
      <TableRow
        onClick={toggle}
        style={{
          backgroundColor: isHighlighted && '#4569eb',
          color: shading,
          height: 30,
        }}
      >
        <TableCell>{tx.status}</TableCell>
        <TableCell>{tx.sendAddress}</TableCell>
        <TableCell>{tx.confirmations}</TableCell>
        <TableCell
          style={{
            color: oppositeColor(tx.amount, GREEN_COLOR_PAIR),
            fontWeight: 'bold',
          }}
        >
          {`${tx.amount} ${tx.currency.symbol}`}
        </TableCell>
        <TableCell
          style={{
            color: oppositeColor(tx.feeAmount, RED_COLOR_PAIR),
            fontWeight: 'bold',
          }}
        >
          {`${tx.feeAmount} ${tx.currency.symbol}`}
        </TableCell>
        <TableCell>
          {moment(tx.createdAt).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
      </TableRow>
      {isHighlighted && (
        <TableRow className="no-hover">
          <TableCell colSpan={6}>
            <Details>
              <DetailsBlockWithTitle title={'Details'}>
                <InfoBlock
                  style={{
                    color: oppositeColor(tx.amount, GREEN_COLOR_PAIR),
                    fontWeight: 'bold',
                  }}
                  label={'Amount'}
                  value={`${tx.amount} ${tx.currency.symbol}`}
                />
                <InfoBlock
                  style={{
                    color: oppositeColor(tx.feeAmount, RED_COLOR_PAIR),
                    fontWeight: 'bold',
                  }}
                  label={'Fee Amount'}
                  value={`${tx.feeAmount} ${tx.currency.symbol}`}
                />
                <InfoBlock label={'Send Address'} value={tx.sendAddress} />
                <InfoBlock
                  label={'Timestamp'}
                  value={moment(tx.createdAt).format('YYYY-MMM-DD HH:mm:ss')}
                />
                {tx.status === 'Processing' || tx.status === 'Failed' ? (
                  <>
                    <InfoValue>
                      <InfoLabel>Reverse Sends</InfoLabel>
                      <Sendstyle>
                        <RollBackFailedSends
                          setOverRideAlreadySentAndOverRideChecked={
                            setOverRideAlreadySentAndOverRideChecked
                          }
                          overrideSends={overRideAlreadySentAndOverRideChecked}
                          setShowConfirmationDialog={setShowConfirmationDialog}
                          showConfirmationDialog={showConfirmationDialog}
                        />
                        <button
                          disabled={
                            checkIfIsPending(tx, dualAuthData) ||
                            isReversingReservedBalance
                          }
                          onClick={handleReverseSendsClick}
                        >
                          {' '}
                          Revers
                          {isReversingReservedBalance ? 'ing' : 'e'} reserved
                          balance{' '}
                        </button>
                      </Sendstyle>
                    </InfoValue>
                  </>
                ) : (
                  <div />
                )}
              </DetailsBlockWithTitle>
            </Details>
            <Details>
              <DetailsBlockWithTitle title="Status">
                <InfoBlock label={'Status'} value={tx.status} />
                <InfoBlock label={'Verified'} value={tx.verified.toString()} />
                <InfoBlock label={'Confirmations'} value={tx.confirmations} />
                <div>
                  <CopyToClipboard
                    text={copyText(rawData)}
                    onCopy={() => setIsCoppied(true)}
                  >
                    <Button>Copy Info</Button>
                  </CopyToClipboard>
                  {copied ? (
                    <span style={{ color: 'red', fontWeight: 'bold' }}>
                      Copied.
                    </span>
                  ) : null}
                </div>
              </DetailsBlockWithTitle>
            </Details>
            {tx.reviewItemId !== undefined ? (
              <ReviewView
                rawData={rawData}
                fetchPendingDualAuth
                flags={rawData.review && rawData.review.flags}
                audits={rawData.review && rawData.review.audits}
              />
            ) : (
              <JsonBlock src={rawData} />
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default pure(SendsRow)
