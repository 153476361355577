import React from 'react'
import { pure } from 'recompose'
import {
  Details, InfoBlock, DetailsBlockWithTitle,
} from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { RED_COLOR_PAIR, GREEN_COLOR_PAIR } from 'theme/colors'
import { oppositeColor } from 'helpers/utils'
import { TableRowExpanded, TableRowExpandeded } from 'components/atoms/Table/styles'

import TableCell from '@material-ui/core/TableCell'

import TableRow from '@material-ui/core/TableRow'

import * as moment from 'moment'

const expandedView = props => {
  const { tx } = props
  if (tx.sourceCreatedAt !== undefined) {
    const debitValue = '--'
    const feeValue = '--'
    const creditValue = `${tx.amount} ${tx.currency.shortName} `
    const date = new Date(tx.sourceCreatedAt.seconds * 1000)

    return (
      <TableRow className='no-hover'>
        <TableCell colSpan={5}>
          <Details>
            <DetailsBlockWithTitle title="Transaction Info">
              <InfoBlock
                label="Debited"
                value={debitValue}
                style={{ color: oppositeColor(tx.debitValue, RED_COLOR_PAIR), fontWeight: 'bold' }}
              />
              <InfoBlock
                label="Credited"
                value={creditValue}
                style={{ color: oppositeColor(tx.debitValue, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
              />
              <InfoBlock label="Fee" value={feeValue} />
              <InfoBlock label="Timestamp" value={moment(date).format('YYYY-MMM-DD HH:mm:ss')} />
            </DetailsBlockWithTitle>
          </Details>
          <Details>
            <DetailsBlockWithTitle title="Additional Info">
              <InfoBlock label="Address" value={tx.receiveAddress} />
              <InfoBlock label="Transaction Hash" value={tx.transactionHash} />
            </DetailsBlockWithTitle>
          </Details>
          <DetailsBlockWithTitle title="Raw Data">
            <RawData data={tx} title={'Raw Data'} />
          </DetailsBlockWithTitle>
        </TableCell>
      </TableRow>
    )
  }
  const debitValue = tx.debitValue ? `${tx.debitValue} ${tx.debitCurrency.symbol}` : '--'
  const feeValue = tx.feeValue ? `${tx.feeValue} ${tx.feeCurrency.symbol}` : '--'
  const creditValue = tx.creditValue ? `${tx.creditValue} ${tx.creditCurrency.symbol} ` : '--'
  return (
    <TableRow className='no-hover'>
      <TableCell colSpan={5}>
        <Details>
          <DetailsBlockWithTitle title="Transaction Info">
            <InfoBlock
              label="Debited"
              value={debitValue}
              style={{ color: oppositeColor(tx.debitValue, RED_COLOR_PAIR), fontWeight: 'bold' }}
            />
            <InfoBlock
              label="Credited"
              value={creditValue}
              style={{ color: oppositeColor(tx.debitValue, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
            />
            <InfoBlock label="Fee" value={feeValue} />
            <InfoBlock label="Timestamp" value={moment(tx.timestampValues.timestamp).format('YYYY-MMM-DD HH:mm:ss')} />
          </DetailsBlockWithTitle>
        </Details>
        {tx.additionalInfo && (
          <Details>
            <DetailsBlockWithTitle title="Additional Info">
              <InfoBlock label="Address" value={tx.additionalInfo.address} />
              <InfoBlock label="Transaction Hash" value={tx.additionalInfo.transactionHash} />
            </DetailsBlockWithTitle>
          </Details>
        )}
        <DetailsBlockWithTitle title="Raw Data">
          <RawData data={tx} title={'Raw Data'} />
        </DetailsBlockWithTitle>
      </TableCell>
    </TableRow>
  )
}

const TransactionRow = props => {
  const { tx, setHighlightedId, isHighlighted } = props
  const shading = isHighlighted ? 'white' : ''
  if (tx.sourceCreatedAt !== undefined) {
    const date = new Date(tx.sourceCreatedAt.seconds * 1000)
    return (
      <>
        <TableRow
          key={tx.eventAt}
          onClick={() => { setHighlightedId() }}
          style={{ backgroundColor: isHighlighted && '#4569eb', color: shading }}
        >
          <TableCell title={'tx.transactionType.description'}>
            {'Receive (Pending)'}
          </TableCell>
          <TableCell style={{ color: oppositeColor('--', RED_COLOR_PAIR), fontWeight: 'bold' }} >
            {'--'}
          </TableCell>
          <TableCell
            style={{ color: isHighlighted ? '' : oppositeColor(tx.amount, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
          >
            {tx.amount} {tx.currency.shortName}
          </TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>
            {'--'}
          </TableCell>
          <TableCell title={tx.sourceCreatedAt.seconds}>
            {moment(date).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell flex={0.2} align={'right'} color={'#e96868'}>
            {tx.tag && <i className="fa fa-sticky-note" />}
          </TableCell>
        </TableRow>
        {isHighlighted && expandedView({ tx })}
      </>
    )
  }

  const debitValue = tx.debitValue ? `${tx.debitValue} ${tx.debitCurrency.symbol}` : '--'
  const feeValue = tx.feeValue ? `${tx.feeValue} ${tx.feeCurrency.symbol}` : '--'
  const creditValue = tx.creditValue ? `${tx.creditValue} ${tx.creditCurrency.symbol} ` : '--'
  return (
    <>
      <TableRow
        key={tx.eventAt}
        onClick={() => { setHighlightedId() }}
        style={{ backgroundColor: isHighlighted && '#4569eb', color: shading }}
      >
        <TableCell title={tx.transactionType.description}>
          {tx.transactionType.description}
        </TableCell>
        <TableCell style={{ color: isHighlighted ? '' : oppositeColor(tx.debitValue, RED_COLOR_PAIR), fontWeight: 'bold' }}>
          {debitValue}
        </TableCell>
        <TableCell
          flex={1}
          style={{ color: isHighlighted ? '' : oppositeColor(tx.creditValue, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
        >
          {creditValue}
        </TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>
          {feeValue}
        </TableCell>
        <TableCell title={tx.timestampValues.timestamp}>
          {moment(tx.timestampValues.timestamp).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
      </TableRow>
      {isHighlighted && expandedView({ tx })}
    </>
  )
}

export default pure(TransactionRow)
