import styled, { keyframes } from 'styled-components'

export const StyledEntityFormWrapper = styled.div`
  overflow: scroll;
  position: relative;
`

export const StyledEntityFormContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyledEntityFormLeft = styled.div`
  margin-top: 16px;
  width: 400px;
`
export const StyledEntityFormRight = styled.div`
  margin-top: 16px;
  width: 400px;
`

export const StyledCorporateAddressesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

const fadeIn = keyframes`
0% {
  margin-top: -250px;
}
100% {
  margin-top: 0px;
}
`
const fadeOut = formHeight => keyframes`
0% {
  margin-top: 0px;
}

100% {
  margin-top: ${-formHeight}px;
}
`

export const StyledCorporateFormWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  animation: 0.1s
    ${({ active, formHeight }) => (active ? fadeIn : fadeOut(formHeight))} both;
`

export const StyledCorporateFormContent = styled.div`
  width: 100%;
  display: flex;
`

export const StyledCoproateFormSection = styled.div`
  width: 50%;
`
