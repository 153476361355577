import React, { Fragment } from 'react'
import { Details, DetailsBlockWithTitle, JsonBlock } from 'components/atoms/Details'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import { Title } from 'components/atoms/Title'

let renderReviewItem = props => {
  const { flags, audits, rawData } = props

  return (
    <Fragment>
      {flags && (
        <Details direction="column">
          <Title type='h4' text='FLAGS' />
          <ReviewStatuses statuses={flags} />
        </Details>
      )}
      {audits && (
        <Details direction="column">
          <Title type='h4' text='AUDITS' />
          <ReviewStatuses
            statuses={audits.map(audit => {
              return {
                flag: audit.newReviewStatus,
                additionalInfo: audit.description,
                insertedAt: audit.insertedAt,
              }
            })}
          />
        </Details>
      )}
      {rawData && (
        <Details direction="column">
          <JsonBlock src={rawData} title={'Raw Data'} />
        </Details>
      )}
    </Fragment>
  )
}

export default renderReviewItem
