import { toast } from 'react-toastify'
import { checkIfIsSocial, checkIfAuthInfoExist } from 'redux/auth0/actions'
import { checkIfKeycloakInfoExist } from 'redux/keycloak/actions'
import { makeRequestToUpdateUserEmail } from './helpers'
import ErrorToast from '../../components/molecules/ErrorToast'

export const submit = async values => {
  const isSocial = await checkIfIsSocial(values.auth0)
  const isExisting = await checkIfAuthInfoExist(values.email)
  const { keyCloakInfo } = await checkIfKeycloakInfoExist(values.email)

  try {
    if (isExisting) {
      toast('Email entered already exists', {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      })
    } else if (isSocial) {
      toast('Cannot change email - this account is social', {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      })
    } else {
      await makeRequestToUpdateUserEmail(values, keyCloakInfo && keyCloakInfo.id || null)
    }
  } catch (error) {
    ErrorToast(error)
  }
}
