import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCurrencies } from 'redux/currencies/actions'
import { toast } from 'react-toastify'
import { renderInput as RenderInput } from 'forms/fields'
import AddCurrencyPairsForm from 'forms/AddCurrencyPair/index'
import { Actions } from 'components/atoms/Table/styles'
import UpdateCurrencyPairsLimits from 'forms/UpdateCurrencyPairsLimits/index'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import TableContainer from '@material-ui/core/TableContainer'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import _ from 'lodash'
import { checkRole, ROLES } from 'helpers/roleBasedAccess'
import {
  fetchCurrencyPairs,
  updateCurrencyPairActivationStatus,
} from 'redux/currencyPairs/actions'

import CurrencyPairRow from './CurrencyPairRow'
const Container = styled(TableContainer)({
  maxHeight: 1400,
})
const TABLE_HEADINGS = [
  'Actions',
  'Symbol',
  'Short Name',
  'Exchange',
  'Active',
  'Min Base Amount',
  'Max Base Amount',
  'Min Quote Amount',
  'Max Quote Amount',
  'Tick Size',
  'Base Decimal Places',
  'Enable Margining',
  'Currency Pair Type',
]

class AddCurrencyPairTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sellExpanded: false,
      buyExpanded: false,
      filter: '',
      highlightedId: null,
      formShown: false,
      updateLimit: false,
      currencyPairSymbol: '',
      minBase: '',
      maxBase: '',
      minQuote: '',
      maxQuote: '',
      tickSize: '',
      currencyPairType: '',
      baseDecimalPlaces: '',
      searching: false,
    }
  }

  componentDidMount() {
    this.props.fetchCurrencyPairs()
    this.props.fetchCurrencies()
  }

  updateCurrencyPair = async (
    currencyPairSymbol,
    active,
    baseCurrencyShortName,
    quoteCurrencyShortName,
  ) => {
    const { data } = this.props.currencies
    const currencyPairActivationStatus = !active
    let bothCurrenciesAreActive = true
    const activeCurrencies = data.filter(
      (currency) =>
        currency.shortName === baseCurrencyShortName ||
        currency.shortName === quoteCurrencyShortName,
    )

    activeCurrencies.forEach((currency) => {
      if (!currency.isActive) {
        bothCurrenciesAreActive = false
      }
    })
    if (bothCurrenciesAreActive) {
      try {
        await this.props.updateCurrencyPairActivationStatus({
          currencyPairSymbol,
          activationStatus: currencyPairActivationStatus,
        })
      } catch (error) {
        toast(`Error occurred when updating currency pair activation status.`, {
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        })
      }
    } else {
      toast('Can not change currency pair of an inactive currency ', {
        type: toast.TYPE.ERROR,
        autoClose: 2500,
      })
    }
  }

  updateCurrencyPairLimits = (
    currencyPairSymbol,
    miniBase,
    maxBase,
    minQuote,
    maxQuote,
    tickSize,
    baseDecimalPlaces,
    currencyPairType,
  ) => {
    this.setState({
      updateLimit: !this.state.updateLimit,
      currencyPairSymbol,
      minBase: miniBase,
      maxBase,
      minQuote,
      maxQuote,
      tickSize,
      baseDecimalPlaces,
      currencyPairType,
    })
  }

  // TODO: We will need to optimize this in future
  filterData = (value = '', data) => {
    const filteredData =
      value.length === 0
        ? data
        : data.filter((item) => {
            const {
              symbol,
              baseCurrency,
              quoteCurrency,
              shortName,
              active,
              minBaseAmount,
              maxBaseAmount,
              minQuoteAmount,
              maxQuoteAmount,
              tickSize,
              baseDecimalPlaces,
              currencyPairType,
              exchange,
            } = item
            if (
              exchange.toLowerCase().includes(value.toLowerCase()) ||
              active.toString().toLowerCase().includes(value.toLowerCase()) ||
              shortName
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              quoteCurrency
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              baseCurrency
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              symbol.toString().toLowerCase().includes(value.toLowerCase()) ||
              minBaseAmount
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              maxBaseAmount
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              minQuoteAmount
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              maxQuoteAmount
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              tickSize.toString().toLowerCase().includes(value.toLowerCase()) ||
              baseDecimalPlaces
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              currencyPairType
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            ) {
              return item
            }
          })

    return filteredData
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  debounce = _.debounce(
    (q) =>
      this.setState({
        searching: false,
      }),
    500,
  )

  handleSearch(value) {
    this.setState({
      searching: true,
      filter: value,
    })
    this.debounce(value)
  }

  checkAuthorizedRolesForTableData(headings) {
    return (
      <TableRow>
        {TABLE_HEADINGS.map((tableHeading) => {
          if (
            headings.find((heading) => heading === tableHeading) &&
            checkRole(ROLES.exchangeEditor)
          ) {
            return <TableCell key={tableHeading}>{tableHeading}</TableCell>
          }
          if (headings.find((heading) => heading !== tableHeading))
            return <TableCell key={tableHeading}>{tableHeading}</TableCell>
        })}
      </TableRow>
    )
  }

  render() {
    const {
      currencyPairs: { data, status },
    } = this.props
    const { filter, formShown, updateLimit, searching } = this.state

    data &&
      data.length &&
      data.sort((a, b) => {
        if (a.shortName < b.shortName) return -1
        return a.shortName > b.shortName ? 1 : 0
      })

    if (status !== 'done' || status === 'loading') {
      return <LoadingBlock />
    }

    if (formShown) {
      return (
        <AddCurrencyPairsForm
          initialValues={{
            symbol: '',
            baseCurrencySymbol: '',
            quoteCurrencySymbol: '',
            shortName: '',
            exchange: '',
            active: false,
            minBaseAmount: '',
            maxBaseAmount: '',
            minQuoteAmount: '',
            maxQuoteAmount: '',
            tickSize: '',
            baseDecimalPlaces: '',
            currencyPairType: '',
            formClose: this.toggleForm,
          }}
          form={'addCurrencyPair'}
          onCancel={this.toggleForm}
          currencies={this.props.currencies}
        />
      )
    }
    if (updateLimit) {
      return (
        <UpdateCurrencyPairsLimits
          initialValues={{
            currencyPairSymbol: this.state.currencyPairSymbol,
            minBase: this.state.minBase,
            maxBase: this.state.maxBase,
            minQuote: this.state.minQuote,
            maxQuote: this.state.maxQuote,
            tickSize: this.state.tickSize,
            baseDecimalPlaces: this.state.baseDecimalPlaces,
            currencyPairType: this.state.currencyPairType,
            formClose: this.updateCurrencyPairLimits,
          }}
          form={'updateCurrencyPairs'}
          onCancel={this.updateCurrencyPairLimits}
        />
      )
    }

    return (
      <>
        <Actions>
          <Actions justify="flex-start" margin="10px 0">
            {!formShown && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={this.toggleForm}
              >
                Add Currency Pair
              </Button>
            )}
          </Actions>
          <Actions justify="flex-end" margin="10px 0">
            <RenderInput
              value={filter}
              marginRight={0}
              onChange={(event) => this.handleSearch(event.target.value)}
              meta={{}}
              label={'Filter'}
            />
          </Actions>
        </Actions>
        <Paper>
          <Container>
            {searching && <LoadingBlock />}
            {!searching && (
              <Table stickyHeader aria-label="sticky table">
                <TableHead align="right" />
                {this.checkAuthorizedRolesForTableData([TABLE_HEADINGS[11]])}
                <TableBody>
                  {this.filterData(filter, data).map((row, i) => (
                    <CurrencyPairRow
                      key={i}
                      row={row}
                      updateLimit={updateLimit}
                      updateCurrencyPair={this.updateCurrencyPair}
                      updateCurrencyPairLimits={this.updateCurrencyPairLimits}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </Container>
        </Paper>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currencyPairs: state.currencyPairs.currencyPairs,
  currencies: state.currencies,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCurrencyPairs,
      fetchCurrencies,
      updateCurrencyPairActivationStatus,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCurrencyPairTable)
