import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import { DetailsBlockWithTitle } from 'components/atoms/Details/index'
import RawDataDualAuth from 'components/atoms/RawDataDualAuth/index'
import * as moment from 'moment'
import { Link } from 'components/organisms/AccountIdLink/clickableId'

class LogTableRow extends Component {
  constructor(props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.state = {
      expanded: false,
    }
  }
  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  shortenUrl = url => {
    var splitted = url.split('/')
    var strUrl = ''
    for (var i = 0; i < splitted.length; i++) {
      if (i > 2) {
        strUrl += '/' + splitted[i]
      }
    }
    return strUrl
  }

  render() {
    const {
      accountId,
      forwardedForIp,
      requestMethod,
      url,
      message: { textPayload },
    } = this.props.log
    const { reqUrl, cfCountryFor, deviceHash, mobileDeviceId } = this.props.log
    const { isHighlighted, setHighlightedId } = this.props
    const { expanded } = this.state
    const padding = '16px 16px 16px 0px'
    const shading = isHighlighted ? 'white' : ''

    return (
      <Fragment>
        <TableRow
          onClick={setHighlightedId}
          style={{
            padding: '0px',
            backgroundColor: isHighlighted && '#4569eb',
            color: shading,
          }}
        >
          <TableCell
            style={{ padding: '16px 16px' }}
            fontSize={'14px'}
            flex={0.4}
          >
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={0.6}
          >
            {forwardedForIp || 'N/A'}
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={0.5}
          >
            {cfCountryFor || 'N/A'}
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={0.7}
          >
            {(mobileDeviceId && mobileDeviceId.match('=UNKNOWN') !== null
              ? deviceHash
              : mobileDeviceId) ||
              (textPayload.match('V1API') && 'v1')}
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={0.6}
          >
            {requestMethod || 'N/A'}
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={2.5}
          >
            {this.shortenUrl(url || reqUrl)}
          </TableCell>
          <TableCell
            onClick={this.toggleView}
            style={{ padding }}
            fontSize={'14px'}
            flex={0.7}
          >
            {moment(this.props.log['@timestamp']).format(
              'YYYY-MMM-DD HH:mm:ss',
            )}
          </TableCell>
        </TableRow>
        {expanded && (
          <DetailsBlockWithTitle>
            <RawDataDualAuth data={this.props.log} />
          </DetailsBlockWithTitle>
        )}
      </Fragment>
    )
  }
}

export default LogTableRow
