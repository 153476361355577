import { reset } from 'redux-form'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import authService from 'services/Auth'

export const submitTransfer = async (values, dispatch) => {

  const {
    amount,
    comment,
    currencySymbol,
    fromAccountId,
    reasonId,
    toAccountId,
    internalComment,
  } = values

  const createdBy = authService.decodeFulNameFromToken()

  try {
    if (fromAccountId !== toAccountId) {
      await getAxios().post('/internal-transfer/initiate', {
        fromAccountId,
        toAccountId,
        amount,
        currencySymbol: currencySymbol.value,
        reasonId,
        additionalInfo: comment,
        internalComment,
        createdBy,
      })
      toast('Transfer Succesfully Initiated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      dispatch(reset('InternalTransfer'))
    } else if (fromAccountId === toAccountId) {
      toast(
        'You can not initiate internal transfers against the same account id',
        {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        },
      )
    }
    dispatch(reset('InternalTransfer'))
  } catch (e) {
    ErrorToast(e)
  }
}
