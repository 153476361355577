import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getType = (state) => state.customerType.byId

export const getCustomerType = createSelector(
  [getType, getActiveAccount],
  (customerType, id) => {
    if (!id || !customerType[id]) {
      return {
        status: 'pristine',
        data: {},
      }
    }

    return customerType[id]
  },
)
