import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Header } from 'components/atoms/Generic/index'
import ErrorBoundary from 'helpers/ErrorBoundary'

import { updateFiat, updateNewFiatDeposits } from 'redux/fiat/actions'
import NewFiatDeposit from 'components/organisms/NewFiatDepositInfo'
import UnresolvedFiatDepositsContainer from 'components/organisms/UnresolvedFiatDepositsInfo/index'
import SearchDepositByStatus from 'components/organisms/SearchForDepositByStatus/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import socket from 'redux/socket/index'
import { Title } from 'components/atoms/Title'

class FiatDeposits extends Component {
  componentDidMount() {
    socket.on('busy deposits', data => {
      this.props.updateFiat(data)
      this.props.updateNewFiatDeposits(data)
    })
    socket.on('status updated', data => {
      this.props.updateFiat(data)
      this.props.updateNewFiatDeposits(data)
    })

    socket.on('status updated on unmount', data => {
      this.props.updateFiat(data)
      this.props.updateNewFiatDeposits(data)
    })
    socket.on('deposit status updated', data => {
      this.props.updateFiat(data)
      this.props.updateNewFiatDeposits(data)
    })
  }

  render() {
    const {
      depositReview,
      newDeposits,
      suspendedDepositsStatus,
      isUpdatingFilters,
    } = this.props

    const isLoading =
      depositReview.status !== 'done' ||
      newDeposits.status !== 'done' ||
      suspendedDepositsStatus !== 'done' ||
      isUpdatingFilters

    const hasError =
      depositReview.status === 'error' ||
      newDeposits.status === 'error' ||
      suspendedDepositsStatus === 'error'
    return (
      <Fragment>
        <SearchDepositByStatus isLoading={isLoading} />
        <Header title={'Fiat Deposits'} />
        {isLoading && !hasError && <LoadingBlock />}
        {hasError && (
          <Title type="h3" text="Error Occurred When Fetching Deposits" />
        )}
        {!isLoading && (
          <Fragment>
            <ErrorBoundary message="Deposits Review">
              <UnresolvedFiatDepositsContainer depositReview={depositReview} />
            </ErrorBoundary>
            <ErrorBoundary message="New Deposits">
              <NewFiatDeposit name="NEW DEPOSITS" newDeposits={newDeposits} />
            </ErrorBoundary>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  depositReview: state.fiat.depositReview,
  newDeposits: state.fiat.newDeposits,
  isUpdatingFilters: state.fiat.isUpdatingFilters,
  suspendedDepositsStatus: state.fiat.suspendedDeposits.status,
  withdrawalsUnderReview: state.fiat.withdrawalsUnderReview,
  unresolvedWithdraws: state.fiat.unresolvedWithdraws,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFiat,
      updateNewFiatDeposits,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiatDeposits)
