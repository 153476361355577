import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { StyledDocumentsWrapper } from './Styles'
import DocumentCard from './DocumentCard'

const Documents = ({ documents, handleRemoveDocument }) => {
  return (
    <Fragment>
      <p>
        Documents(
        {documents.length}
        ):
      </p>
      <StyledDocumentsWrapper>
        {documents.map((document, index) => (
          <div id="document" key={index}>
            <DocumentCard
              index={index}
              document={document}
              handleRemoveDocument={handleRemoveDocument}
            />
          </div>
        ))}
      </StyledDocumentsWrapper>
    </Fragment>
  )
}

Documents.propTypes = {
  documents: PropTypes.array,
  handleRemoveDocument: PropTypes.func,
}

export default Documents
