import { useFlags } from 'launchdarkly-react-client-sdk'
import { AVAILABLE_FEATURE_FLAGS } from 'types/featureFlags'

const useFeatureFlagCheck = (flag: AVAILABLE_FEATURE_FLAGS): boolean => {
  const featureFlags = useFlags()

  const flagsOn = Object.keys(featureFlags).filter(
    (flagKey) => featureFlags[flagKey],
  )

  const flagIsOn = flagsOn.some(
    (flagOn: AVAILABLE_FEATURE_FLAGS) => flagOn === flag,
  )
  const isLocalDevelopment = process.env.REACT_APP_NODE_ENV === 'test'
  return isLocalDevelopment || flagIsOn
}

export default useFeatureFlagCheck
