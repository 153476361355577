import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submitFailedSends = async (id, accountId, overrideAlreadySent) => {
  let res = null
  try {
    res = await getAxios().put('/roll/back-failed-sends', {
      id, accountId, overrideAlreadySent,
    })
    if (res.data.statusCode === 202) {
      toast('Successfully rolled back sends', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
  } catch (error) {
    toast(`Failed to roll back sends  ${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
