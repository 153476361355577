import React, { PureComponent } from 'react'
// import { Table } from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'
import {
  checkIfFallBackDepositCanBeSelected,
  renderPlural,
} from 'helpers/utils'
import {
  suspendDeposits,
  bulkUpdateDeposits,
  updateDepositZendeskEmailSent,
} from 'redux/fiat/actions'
import ConfirmationForm from 'forms/ConfirmationForm/index'
import { UNRESOLVED_FIAT_DEPOSIT_TITLES } from 'helpers/const'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { UnknownReferencesTable } from '../UnknownReferencesFiatDepositsInfo/index'
import { SuspendedTable } from '../SuspendedDeposit/index'
import { RejectedDualAuthTable } from '../RejectedDualAuth/index'
import OtherTable from './other'
import { ConfirmationDialog } from '../ConfirmationDialog/index'
import { Dialog } from './Dialog'
import { toast } from 'react-toastify'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import SendZendeskEmailForm from 'forms/sendZendeskEmailForm'
import getAxios from 'helpers/axios'

const CONFIRMATION_TYPES = {
  deposit: 'DEPOSIT',
  reject: 'REJECT',
  suspend: 'SUSPEND',
  approve: 'APPROVE',
  bulkDepositToggle: 'BULK_DEPOSIT_TOGGLE',
  closeAccordion: 'CLOSE_ACCORDION',
  sendEmail: 'SEND_EMAIL',
}

class UnresolvedFiatDeposits extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      formShown: '',
      expandRawData: '',
      addNoteFormShown: '',
      highlightedId: '',
      bulkActionsActive: false,
      selectedBulkDeposits: [],
      confirmationDialog: {
        title: '',
        isOpen: false,
        message: '',
        type: '',
      },
      isProcessingBulkAction: false,
      actionType: '',
      page: 0,
      rowsPerPage: 50,
      zendeskEmailToSend: null,
    }

    this.toggleView = this.toggleView.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
  }

  clearSelectedBulkDeposits = () => {
    this.setState({
      selectedBulkDeposits: [],
    })
    this.closeDialog()
  }

  toggleBulkActions = () => {
    this.setState({
      bulkActionsActive: !this.state.bulkActionsActive,
    })
    if (this.state.bulkActionsActive) {
      this.clearSelectedBulkDeposits()
    }
    this.closeDialog()
  }

  setRowsPerPage = (rowsPerPage) => {
    this.setState({
      rowsPerPage,
    })
  }

  setPage = (page) => {
    this.setState({
      page,
    })
  }

  setBulkActionsActive = () => {
    const itemCount = this.state.selectedBulkDeposits.length

    if (itemCount > 0) {
      const dialogMessage = `Discard current selected deposits? ${itemCount > 1 ? 'All' : ''
        } ${itemCount} selected item${renderPlural(
          itemCount,
        )} will be deselected!`
      const dialog = new Dialog(
        'Discard current selected deposits',
        dialogMessage,
        CONFIRMATION_TYPES.bulkDepositToggle,
      )
      return this.setState({
        confirmationDialog: dialog,
      })
    }
    this.toggleBulkActions()
  }

  toggleView() {
    const itemCount = this.state.selectedBulkDeposits.length
    if (itemCount > 0) {
      const dialogMessage = `Discard current selected deposits? ${itemCount > 1 ? 'All' : ''
        } ${itemCount} selected item${renderPlural(
          itemCount,
        )} will be deselected!`
      const dialog = new Dialog(
        'Discard current selected deposits',
        dialogMessage,
        CONFIRMATION_TYPES.closeAccordion,
      )

      return this.setState({
        confirmationDialog: dialog,
      })
    }
    this.setState({
      expanded: !this.state.expanded,
    })
    if (this.state.bulkActionsActive) this.toggleBulkActions()
  }

  closeDialog() {
    this.setState({
      confirmationDialog: {
        title: '',
        isOpen: false,
        message: '',
        type: '',
      },
    })
  }

  setHighlightedId = (id) => {
    this.setState({ highlightedId: id })
  }

  showRawData = (id) => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleAddNotesForm = (id) => {
    if (id !== this.state.addNoteFormShown) {
      this.setState({ addNoteFormShown: id })
    } else {
      this.setState({ addNoteFormShown: '' })
    }
  }

  toggleForm = (id) => {
    if (id !== this.state.formShown) {
      this.setState({ formShown: id, expandRawData: '', addNoteFormShown: '' })
    } else {
      this.setState({ formShown: '', expandRawData: '', addNoteFormShown: '' })
    }
  }

  checkIfIsPending = (deposit) => {
    const hasPendingAuthorisation =
      deposit.statuses[0].status === 'PENDING_AUTHORISATION'
    return hasPendingAuthorisation
  }

  refineRawData = (rawData) => {
    const { source, amount, dateTime } = rawData
    rawData.source = source.replace(/\W\d*/g, ' ').trim()
    rawData.dateTime = moment(dateTime).format('YYYY-MMM-DD')
    const formattedAmount = new BigNumber(amount).toFormat(2)
    rawData.amount = formattedAmount !== 'NaN' ? formattedAmount : amount
    return rawData
  }

  initiateBulkActionUpdate = (data, actionType) => {
    const bulkItemsCount = data.length
    let dialogMessageType = ''
    let dialogType = ''
    switch (actionType) {
      case CONFIRMATION_TYPES.suspend:
        dialogMessageType = 'suspend'
        dialogType = CONFIRMATION_TYPES.suspend
        break

      case CONFIRMATION_TYPES.reject:
        dialogMessageType = 'reject'
        dialogType = CONFIRMATION_TYPES.reject
        break

      case CONFIRMATION_TYPES.approve:
        dialogMessageType = 'approve'
        dialogType = CONFIRMATION_TYPES.approve
        break

      case CONFIRMATION_TYPES.sendEmail:
        dialogMessageType = 'Send Zendesk email'
        dialogType = CONFIRMATION_TYPES.sendEmail
        break

      default:
        break
    }
    const dialogMessage = `You are about to ${dialogMessageType} ${bulkItemsCount} deposit${renderPlural(
      bulkItemsCount,
    )}. Would you like to proceed ?`
    const dialogTitle = `${actionType.slice(0, 1) + actionType.slice(1).toLowerCase()
      } Deposits`

    const dialog = new Dialog(dialogTitle, dialogMessage, dialogType)

    this.setState({
      confirmationDialog: dialog,
    })
  }

  getAllDepositsThatCanBeSelected = (data = [], title, actionType) => {
    const allDepositIds = []
    const isFallBackTableApprove =
      title === UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT &&
      actionType === 'APPROVE'
    if (data.length > 0) {
      data.forEach((deposit) => {
        if (!this.checkIfIsPending(deposit)) {
          if (isFallBackTableApprove) {
            if (checkIfFallBackDepositCanBeSelected(deposit)) {
              const fallBackDepositToBeApproved = {
                depositId: deposit.depositId,
                accountId: deposit.accountId,
                depositZendeskEmailSent: deposit.depositZendeskEmailSent,
                requesterEmail: deposit?.accountBasicInfoForDisplay?.email,
              }
              return allDepositIds.push(fallBackDepositToBeApproved)
            }
          } else {
            allDepositIds.push({
              depositId: deposit.depositId,
              title,
              depositZendeskEmailSent: deposit.depositZendeskEmailSent,
              requesterEmail: deposit?.accountBasicInfoForDisplay?.email,
            })
          }
        }
      })
    }
    return allDepositIds
  }

  toggleSelectAll = (allDepositIds) => {
    if (allDepositIds.length === 0) {
      return toast('There are not deposits that can be selected.')
    }
    const { title } = this.props
    const { actionType } = this.state
    const isFallBackTableApprove =
      title === UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT &&
      actionType === 'APPROVE'
    const depositsThatCanBeSelected = this.getAllDepositsThatCanBeSelected(
      allDepositIds,
      title,
      actionType,
    )

    if (
      depositsThatCanBeSelected.length === 0 ||
      (depositsThatCanBeSelected.length === 0 && isFallBackTableApprove)
    ) {
      return toast('There are not deposits that can be selected on this page.')
    }
    this.setState((prev) => {
      const updated = prev.selectedBulkDeposits.filter(
        (p) =>
          depositsThatCanBeSelected.findIndex(
            (dep) => p.depositId === dep.depositId,
          ) === -1,
      )
      const currentSelectedDeposits = prev.selectedBulkDeposits.filter(
        (selected) =>
          depositsThatCanBeSelected.findIndex(
            (dep) => dep.depositId === selected.depositId,
          ) > -1,
      )
      depositsThatCanBeSelected.forEach((dep) => {
        const depositIndex = prev.selectedBulkDeposits.findIndex(
          (p) => p.depositId === dep.depositId,
        )
        if (
          depositsThatCanBeSelected.length !== currentSelectedDeposits.length
        ) {
          return updated.push(dep)
        }
        if (depositIndex > -1) return updated.splice(depositIndex, 1)
        return updated.push(dep)
      })
      return { selectedBulkDeposits: updated }
    })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage)
  }

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(parseInt(event.target.value, 10))
    this.setPage(0)
  }

  handleActionTypeChange = (actionType) => {
    this.setState({ actionType })
  }

  handleProceedConfirmation(type) {
    const { selectedBulkDeposits, expanded, bulkActionsActive } = this.state

    switch (type) {
      case CONFIRMATION_TYPES.suspend:
        return async () => {
          this.setState({
            isProcessingBulkAction: true,
          })
          await this.props.suspendDeposits(selectedBulkDeposits, 'SUSPEND')
          this.clearSelectedBulkDeposits()
          this.setState({
            isProcessingBulkAction: false,
          })
        }

      case CONFIRMATION_TYPES.bulkDepositToggle:
        return () => {
          this.toggleBulkActions()
        }
      case CONFIRMATION_TYPES.closeAccordion:
        return () => {
          if (expanded) {
            this.clearSelectedBulkDeposits()
            this.setState({
              expanded: !this.state.expanded,
            })
            this.closeDialog()
          }
          if (bulkActionsActive) this.toggleBulkActions()
        }
      default:
        return () => { }
    }
  }

  handleSelectedBulkDepositClick = (deposit) => {
    const updatedSelectedItems = [...this.state.selectedBulkDeposits]
    const indexOfExisitingItem = updatedSelectedItems.findIndex(
      (depositToFind) => depositToFind.depositId === deposit.depositId,
    )

    if (indexOfExisitingItem === -1) {
      updatedSelectedItems.push(deposit)
    } else {
      updatedSelectedItems.splice(indexOfExisitingItem, 1)
    }
    this.setState({
      selectedBulkDeposits: updatedSelectedItems,
    })
  }

  handleSendIndividualZendeskEmail = (deposit) => {
    this.setState({
      zendeskEmailToSend: deposit,
    })
  }

  async handleBulkUpdateDeposits(data) {
    await this.props.bulkUpdateDeposits(data)
    this.clearSelectedBulkDeposits()
    this.closeDialog()
  }

  getAmountOfSelectedDepositsThatAreSelected = (
    deposits,
    selectedDeposits,
    title,
    actionType,
  ) => {
    const depositsThatCanBeSelected = this.getAllDepositsThatCanBeSelected(
      deposits,
      title,
      actionType,
    )
    let currentDepositsSelectedCount = 0
    depositsThatCanBeSelected.forEach((deposit) => {
      const isSelected = selectedDeposits.find(
        (selectedDeposit) => deposit.depositId === selectedDeposit.depositId,
      )

      if (isSelected) {
        currentDepositsSelectedCount += 1
      }
    })
    return depositsThatCanBeSelected.length === currentDepositsSelectedCount
  }

  render() {
    const { expanded, selectedBulkDeposits, confirmationDialog, actionType } =
      this.state
    const { data: allDepositsData } = this.props.depositReview
    const tablePaginationCount = allDepositsData.length
    const depositsToDisplay = allDepositsData.slice(
      this.state.page * this.state.rowsPerPage,
      this.state.rowsPerPage * (this.state.page + 1),
    )
    const { title, amount } = this.props
    const { page, rowsPerPage } = this.state
    const showOtherTable =
      title !== 'UNKNOWN REFERENCES' &&
      title !== 'SUSPENDED' &&
      title !== 'REJECTED DUAL AUTH'

    const isAllDepositsSelected =
      this.getAmountOfSelectedDepositsThatAreSelected(
        depositsToDisplay,
        selectedBulkDeposits,
        title,
        actionType,
      ) &&
      selectedBulkDeposits.length > 0 &&
      this.getAllDepositsThatCanBeSelected(depositsToDisplay, title, actionType)
        .length > 0

    return (
      <>
        <ExpandingItem
          actionType={actionType}
          selectedBulkDeposits={selectedBulkDeposits}
          initiateBulkActionUpdate={this.initiateBulkActionUpdate}
          titleType={title}
          title={`${title}`}
          totalAmount={`(${amount || 0})`}
          itemsAmount={(allDepositsData && allDepositsData.length) || 0}
          expanded={expanded}
          onExpand={this.toggleView}
          opensMultiple
          setBulkActionsActive={this.setBulkActionsActive}
          bulkActionsActive={this.state.bulkActionsActive}
          applyHeight={allDepositsData && allDepositsData.length > 0}
          expandedView={
            <Table>
              {title === UNRESOLVED_FIAT_DEPOSIT_TITLES.UNKNOWN_REFERENCES && (
                <UnknownReferencesTable
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  selectedBulkDeposits={selectedBulkDeposits}
                  title={title}
                  handleSelectedBulkDepositClick={
                    this.handleSelectedBulkDepositClick
                  }
                  bulkActionsActive={this.state.bulkActionsActive}
                  checkIfIsPending={this.checkIfIsPending}
                  toggleForm={this.toggleForm}
                  formShown={this.state.formShown}
                  data={depositsToDisplay}
                  tablePaginationCount={tablePaginationCount}
                  showRawData={this.showRawData}
                  toggleAddNotesForm={this.toggleAddNotesForm}
                  expandRawData={this.state.expandRawData}
                  addNoteFormShown={this.state.addNoteFormShown}
                  toggleSelectAll={() =>
                    this.toggleSelectAll(depositsToDisplay)
                  }
                  isAllDepositsSelected={isAllDepositsSelected}
                />
              )}
              {title === UNRESOLVED_FIAT_DEPOSIT_TITLES.SUSPENDED && (
                <SuspendedTable
                  title={title}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  checkIfIsPending={this.checkIfIsPending}
                  toggleForm={this.toggleForm}
                  formShown={this.state.formShown}
                  data={depositsToDisplay}
                  tablePaginationCount={tablePaginationCount}
                  showRawData={this.showRawData}
                  toggleAddNotesForm={this.toggleAddNotesForm}
                  expandRawData={this.state.expandRawData}
                  addNoteFormShown={this.state.addNoteFormShown}
                  selectedBulkDeposits={selectedBulkDeposits}
                  bulkActionsActive={this.state.bulkActionsActive}
                  handleSelectedBulkDepositClick={
                    this.handleSelectedBulkDepositClick
                  }
                  toggleSelectAll={() =>
                    this.toggleSelectAll(depositsToDisplay)
                  }
                  isAllDepositsSelected={isAllDepositsSelected}
                />
              )}
              {title === UNRESOLVED_FIAT_DEPOSIT_TITLES.REJECTED_DUAL_AUTH && (
                <RejectedDualAuthTable
                  title={title}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  checkIfIsPending={this.checkIfIsPending}
                  toggleForm={this.toggleForm}
                  formShown={this.state.formShown}
                  data={depositsToDisplay}
                  tablePaginationCount={tablePaginationCount}
                  showRawData={this.showRawData}
                  toggleAddNotesForm={this.toggleAddNotesForm}
                  expandRawData={this.state.expandRawData}
                  addNoteFormShown={this.state.addNoteFormShown}
                />
              )}
              {showOtherTable && (
                <OtherTable
                  selectedBulkDeposits={selectedBulkDeposits}
                  title={title}
                  handleSelectedBulkDepositClick={
                    this.handleSelectedBulkDepositClick
                  }
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  bulkActionsActive={this.state.bulkActionsActive}
                  checkIfIsPending={this.checkIfIsPending}
                  toggleForm={this.toggleForm}
                  formShown={this.state.formShown}
                  data={depositsToDisplay}
                  tablePaginationCount={tablePaginationCount}
                  showRawData={this.showRawData}
                  toggleAddNotesForm={this.toggleAddNotesForm}
                  expandRawData={this.state.expandRawData}
                  addNoteFormShown={this.state.addNoteFormShown}
                  isAllDepositsSelected={isAllDepositsSelected}
                  toggleSelectAll={() =>
                    this.toggleSelectAll(depositsToDisplay)
                  }
                  clearSelectedDeposits={() =>
                    this.setState({
                      selectedBulkDeposits: [],
                    })
                  }
                  handleActionTypeChange={this.handleActionTypeChange}
                  actionType={actionType}
                  handleSendIndividualZendeskEmail={
                    this.handleSendIndividualZendeskEmail
                  }
                />
              )}
            </Table>
          }
        />
        <ConfirmationDialog
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          open={confirmationDialog.isOpen}
          proceed={this.handleProceedConfirmation(confirmationDialog.type)}
          handleClose={this.closeDialog}
          buttonsHidden={
            confirmationDialog.type === CONFIRMATION_TYPES.reject ||
            confirmationDialog.type === CONFIRMATION_TYPES.approve ||
            confirmationDialog.type === 'SEND_EMAIL'
          }
          disabled={this.state.isProcessingBulkAction}
          button={{
            confirm: {
              text: this.state.isProcessingBulkAction ? 'Updating' : 'Proceed',
            },
            decline: {
              text: 'Cancel',
            },
          }}
        >
          {(confirmationDialog.type === CONFIRMATION_TYPES.reject ||
            confirmationDialog.type === CONFIRMATION_TYPES.approve) && (
              <ConfirmationForm
                initialValues={{
                  id: confirmationDialog.type,
                  comment: '',
                  status: '',
                }}
                form={`ConfirmationForm-${confirmationDialog.type}`}
                onSubmit={async (formData) => {
                  await this.handleBulkUpdateDeposits({
                    formData,
                    selectedBulkDeposits,
                    bulkActionType: confirmationDialog.type,
                  })
                }}
                onCancel={this.closeDialog}
              />
            )}
          {confirmationDialog.type === 'SEND_EMAIL' && (
            <SendZendeskEmailForm
              form={`Zendesk-email-form-bulk`}
              onSubmit={async (values) => {
                await this.handleBulkUpdateDeposits({
                  formData: values,
                  selectedBulkDeposits,
                  bulkActionType: confirmationDialog.type,
                })
              }}
              onCancel={this.closeDialog}
            />
          )}
        </ConfirmationDialog>
        <ConfirmationDialog
          title={'Send Zendesk Email'}
          message={`Are you sure you want to send a zendesk email to ${this.state.zendeskEmailToSend?.accountBasicInfoForDisplay?.email}?`}
          buttonsHidden
          open={this.state.zendeskEmailToSend ? true : false}
          handleClose={() => this.setState({ zendeskEmailToSend: null })}
        >
          <SendZendeskEmailForm
            form={`Zendesk-email-form`}
            onSubmit={async (values) => {
              await this.props.updateDepositZendeskEmailSent({
                ...values,
                userName:
                  this.state.zendeskEmailToSend.accountBasicInfoForDisplay.name,
                depositId: this.state.zendeskEmailToSend.depositId,
                requesterEmail:
                  this.state.zendeskEmailToSend?.accountBasicInfoForDisplay
                    ?.email,
              })
              this.setState({
                zendeskEmailToSend: null,
              })
            }}
            onCancel={() => this.setState({ zendeskEmailToSend: null })}
          />
        </ConfirmationDialog>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      suspendDeposits,
      bulkUpdateDeposits,
      updateDepositZendeskEmailSent,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(UnresolvedFiatDeposits)
