import React, { Component, Fragment } from 'react'
import {
  TableCell,
  TableRow,
} from 'components/atoms/Table/styles'

class MarketingContactRunsTableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rawData: {},
    }
  }

  componentDidMount() {
    const { data } = this.props
    this.setState({ rawData: data })
  }

  render() {
    const { uniqueId, data } = this.props
    const { id, latest_account_id, created_at, sendgrid_job_id, contacts_updated_in_batch, list_name } = data
    return (
      <Fragment>
        <TableRow
          key={uniqueId}
          style={{
            padding: '0px'
          }}
        >
          <TableCell flex={0.5} style={{ padding: '16px 16px' }} width={'10%'}>
            {id}
          </TableCell>
          <TableCell flex={0.65} style={{ padding: '16px 16px' }} width={'20%'}>
            {latest_account_id}
          </TableCell>
          <TableCell flex={0.65} style={{ padding: '16px 16px' }} width={'20%'}>
            {list_name}
          </TableCell>
          <TableCell flex={0.65} style={{ padding: '16px 16px' }} width={'15%'}>
            {contacts_updated_in_batch}
          </TableCell>
          <TableCell flex={1} style={{ padding: '16px 16px' }} width={'20%'}>
            {sendgrid_job_id}
          </TableCell>
          <TableCell flex={1} style={{ padding: '16px 16px' }} width={'15%'}>
            {created_at}
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}

export default MarketingContactRunsTableRow
