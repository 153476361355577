import getAxios from 'helpers/axios'
import { SubmissionError } from 'redux-form'
import { toast } from 'react-toastify'
import { fetchWiredDepositPendingRelease } from 'redux/circle/actions'

export const submit = async (values, dispatch) => {
  const { depositIds, comment } = values
  let res = null
  try {
    res = await getAxios().post('/wired/release/deposits', {
      depositIds: [depositIds],
      comment: comment,
    })
    if (res.status == 200) {
      toast('Deposits Status Updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    } else {
      toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 })
    }
    dispatch(fetchWiredDepositPendingRelease())
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    throw new SubmissionError({ error })
  }
  return res
}
