import { VeriffSession } from 'interfaces/veriffSessions'
import { VeriffSessionsActionTypes } from './actions'

export interface VeriffSessionsState {
  data: VeriffSession | null
  isFetchingAccountInfoBySessionId: 'idle' | 'pending' | 'done'
}
interface Action {
  type: VeriffSessionsActionTypes
  payload: VeriffSession
}

const initialState: VeriffSessionsState = {
  data: null,
  isFetchingAccountInfoBySessionId: 'idle',
}

export default (state = initialState, action: Action): VeriffSessionsState => {
  switch (action.type) {
    case 'FETCH_VERIFF_SESSIONS_START': {
      return {
        ...state,
        isFetchingAccountInfoBySessionId: 'pending',
      }
    }
    case 'FETCH_VERIFF_SESSIONS_SUCCESS': {
      return {
        ...state,
        data: action.payload,
        isFetchingAccountInfoBySessionId: 'done',
      }
    }

    case 'FETCH_VERIFF_SESSIONS_ERROR': {
      return {
        ...state,
        isFetchingAccountInfoBySessionId: 'idle',
      }
    }

    default:
      return state
  }
}
