import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { BASE } from 'theme/base-units'
import { oppositeColor } from 'helpers/utils'
import { bindActionCreators } from 'redux'
import BasicModal from 'components/organisms/Modal/index'

import { ActionButton } from 'components/atoms/Table/styles'
import {
  DetailsBlock,
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import { acknowledgeCheck } from 'redux/singleAccountSanctions/actions'
import InitiateSanctionsChecks from 'forms/InitiateSanctionsCheck'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { SanctionsCheckForm } from 'forms/SanctionsCheckForm/index'

class WatchlistCheck extends Component {
  constructor(props) {
    super(props)
    this.state = { showForm: false, showCheckForm: false }
  }

  toggleForm = () => {
    const toggleSetting = this.state.showForm
    this.setState({ showForm: !toggleSetting })
  }

  toggleShowCheckForm = () => {
    this.setState({
      showCheckForm: !this.state.showCheckForm,
    })
  }

  showPlaceholder = (value, placeHolder = '--') => value || placeHolder

  handleCloseCheckForm = () =>
    this.setState({ showCheckForm: !this.state.showCheckForm })

  render() {
    const {
      sanctionCheck: { data, status },
      id,
      acknowledgeCheck,
      isAccountUnverified,
    } = this.props
    const check = data[data.length - 1]
    const isLoaded = status === 'done'
    const isSanctionChecked = check && check.status === 'checked'
    const checkButtonText = isSanctionChecked
      ? 'Un-mark as checked'
      : 'Mark as checked'

    const name =
      (check &&
        check.report_data &&
        check.report_data.breakdown &&
        check.report_data.breakdown.name) ||
      'No name found'

    const handleProceedSanctionCheck = async checkInformation => {
      await acknowledgeCheck(check, checkInformation)
      this.handleCloseCheckForm()
    }

    const handleCheckButtonClick = async () => {
      if (isSanctionChecked) return acknowledgeCheck(check)
      return this.toggleShowCheckForm()
    }

    return (
      <Fragment>
        <DetailsBlockWithTitle title={'Sanctions Check'} flow={'column'}>
          {status === 'done' &&
            data.length > 0 &&
            check && (
              <DetailsBlock direction={'row'}>
                <InfoBlock
                  label={'Check Id'}
                  value={this.showPlaceholder(
                    check.foreign_sanction_id || check.local_sanction_id,
                  )}
                />
                <InfoBlock
                  label={'Search Type'}
                  value={this.showPlaceholder(check.search_type)}
                />
                <InfoBlock
                  label={'Check Result'}
                  color={oppositeColor(check.description !== 'consider')}
                  value={this.showPlaceholder(check.description)}
                />

                <InfoBlock
                  label={'Created At'}
                  value={this.showPlaceholder(check.created_at)}
                />
                <InfoBlock
                  label={'Modified At'}
                  value={this.showPlaceholder(check.modified_at)}
                />
                <InfoBlock
                  label={'Our Status'}
                  value={this.showPlaceholder(check.status)}
                />
                <InfoBlock
                  label={'Approval Status'}
                  value={this.showPlaceholder(check.approval_status)}
                />
                <InfoBlock
                  label={'Check Comment'}
                  value={this.showPlaceholder(check.check_comment)}
                />
              </DetailsBlock>
            )}
          {isLoaded &&
            data.length > 0 && <JsonBlock src={check} title={'Raw Data'} />}
          {isLoaded &&
            data.length <= 0 && (
              <DetailsBlock direction={'row'}>
                <InfoBlock
                  label={'Check Result'}
                  value={'No Watchlist Check Created For This Account'}
                />
              </DetailsBlock>
            )}
          {status === 'error' && (
            <DetailsBlock direction={'row'}>
              <InfoBlock label={'Error'} value={'Could not get check'} />
            </DetailsBlock>
          )}
          {status === 'loading' && (
            <DetailsBlock direction={'row'}>
              <LoadingBlock />
            </DetailsBlock>
          )}
          {this.state.showForm && (
            <InitiateSanctionsChecks
              initialValues={{ accountId: id }}
              onCancel={this.toggleForm}
            />
          )}
          <DetailsBlock direction={'row'}>
            {!this.state.showForm && (
              <ActionButton
                disabled={isAccountUnverified}
                onClick={this.toggleForm}
              >
                Initiate Check
              </ActionButton>
            )}
            {isLoaded &&
              data.length > 0 && (
                <ActionButton onClick={handleCheckButtonClick}>
                  {checkButtonText}
                </ActionButton>
              )}
          </DetailsBlock>
        </DetailsBlockWithTitle>
        <BasicModal
          disablePortal
          open={this.state.showCheckForm}
          handleClose={this.handleCloseCheckForm}
          buttonsHidden
        >
          <SanctionsCheckForm
            accountId={id}
            name={name}
            onCancel={this.handleCloseCheckForm}
            onSubmit={handleProceedSanctionCheck}
            form="sanctionsCheckForm"
          />
        </BasicModal>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ acknowledgeCheck }, dispatch)

export default connect(
  null,
  mapDispatchToProps,
)(WatchlistCheck)
