import getAxios from 'helpers/axios'
import { fetchReviewItem } from 'redux/review/actions'
import { toast } from 'react-toastify'
import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_PROCESS =
  'crypto/FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_PROCESS'
export const FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_SUCCESS =
  'crypto/FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_SUCCESS'
export const FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_ERROR =
  'crypto/FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_ERROR'
export const UPDATE_CRYPTO_WITHDRAWAL_SUCCESS =
  'UPDATE_CRYPTO_WITHDRAWAL_SUCCESS'
export const UPDATE_CRYPTO_WITHDRAWAL_ERROR = 'UPDATE_CRYPTO_WITHDRAWAL_ERROR'

export const UPDATE_CRYPTO_WITHDRAWALS_SUCCESS =
  'UPDATE_CRYPTO_WITHDRAWALS_SUCCESS'

  export const UPDATE_NOTES_FOR_CRYPTO_WITHDRAWALS_SUCCESS =
  'UPDATE_NOTES_FOR_CRYPTO_WITHDRAWALS_SUCCESS'

const getUnique = array => {
  const uniqueArray = []
  for (let i = 0; i < array.length; i++) {
    if (uniqueArray.indexOf(array[i]) === -1) {
      uniqueArray.push(array[i])
    }
  }
  return uniqueArray
}

const getReviewData = (arr, dispatch) =>
  arr.map(val => dispatch(fetchReviewItem(val.reviewItemId)))

const generateMetadata = data => {
  return data.map(item => {
    item = Object.values(item)
    for (var val of item) {
      if (typeof val === 'object') {
        item[item.indexOf(val)] = Object.values(val)
      }
    }
    return getUnique(item.flat()).join('')
  })
}

export const fetchCryptoWithdrawalsUnderReview = () => async dispatch => {
  dispatch({
    type: FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_PROCESS,
  })

  try {
    const res = await getAxios().get('/crypto/withdrawals/review')
    const { data } = res
    if (data) {
      data.map((prop, i) => {
        const meta = generateMetadata(data)[i]
        prop.meta = { searchString: meta }
        return prop
      })
    }
    dispatch({
      type: FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    toast(error.response.data.message, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
    dispatch({
      type: FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_ERROR,
      payload: {
        error,
      },
    })
  }
}
export const updateNotesForCryptoWithdrawals = withdrawalId => async dispatch => {
  try {
    dispatch({
      type: UPDATE_NOTES_FOR_CRYPTO_WITHDRAWALS_SUCCESS,
      payload: {
        withdrawalId,
      },
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const updateCryptoWithdrawals = withdrawalId => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CRYPTO_WITHDRAWALS_SUCCESS,
      payload: {
        withdrawalId,
      },
    })
  } catch (error) {
    ErrorToast(error)
  }
}
export const updateCryptoWithrawal = withdrawals => async dispatch => {
  try {
    dispatch({
      type: UPDATE_CRYPTO_WITHDRAWAL_SUCCESS,
      payload: {
        data: withdrawals,
      },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_CRYPTO_WITHDRAWAL_ERROR,
      payload: {
        error,
      },
    })
  }
}
