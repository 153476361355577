import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCreditCardDepositUnderReview } from 'redux/creditCard/actions'
import CreditCardDepositsContainer from 'components/organisms/CreditCard/index '
import { Header } from 'components/atoms/Generic/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import ErrorBoundary from 'helpers/ErrorBoundary'

class CreditCardDeposits extends Component {
  componentDidMount() {
    this.props.fetchCreditCardDepositUnderReview()
  }

  render() {
    const { creditCardDepositReview } = this.props.creditCard
    return (
      <div>
        <Header title={'CREDIT CARD DEPOSITS'} />
        {creditCardDepositReview.status !== 'done' && <LoadingBlock />}
        {creditCardDepositReview.status === 'done' &&
          creditCardDepositReview.status === 'done' && (
            <ErrorBoundary message="Credit Card Deposits Review">
              <CreditCardDepositsContainer deposits={creditCardDepositReview} />
            </ErrorBoundary>
          )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCreditCardDepositUnderReview,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditCardDeposits)
