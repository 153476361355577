import React, { Component, Fragment } from 'react'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {

  openForm = async () => {
    const { fetchNameAndSurnameById, nameAndSurnameInfo } = this.props
    this.props.toggleSelected(nameAndSurnameInfo.id)
    await fetchNameAndSurnameById(nameAndSurnameInfo.id)
  }

  closeForm = async () => {
    const { nameAndSurnameInfo } = this.props
    this.props.toggleSelected(nameAndSurnameInfo.id)
  }

  handleSubmitSuccess = async () => {
    await this.props.removeSubmittedItem(this.props.nameAndSurnameInfo.id)
    this.closeForm()
  }

  render() {
    const {
      nameAndSurnameInfo,
      isSelected,
      nameAndSurnameDataById,
      rowLoading,
    } = this.props
    const shading = isSelected ? 'white' : ''
    const {
      data: {
        accountId,
        reason,
        createdAt,
      },
      id,
      active,
    } = nameAndSurnameInfo

    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={this.openForm}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'10%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'40%'}>{createdAt}</TableCell>
          <TableCell width={'40%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              actionType: nameAndSurnameInfo.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'pending',
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.closeForm}
            onSubmitSuccess={this.handleSubmitSuccess}
            rawData={nameAndSurnameDataById}
            loading={rowLoading}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'NAME_SURNAME_EVENT')
