import React, { Component, Fragment } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasKycOverrideChangeRole } from 'helpers/roleBasedAccess'

import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import FormOverride from 'forms/OverrideKYC'
import FormOverrideAddress from 'forms/OverrideKYCAddress'
import { BASE } from 'theme/base-units'
import auth from 'services/Auth'

class KYCLevel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formShown: false,
      poaFormShown: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
    this.togglePoaForm = this.togglePoaForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  togglePoaForm() {
    this.setState({
      poaFormShown: !this.state.poaFormShown,
    })
  }

  render() {
    const { kyc, id } = this.props
    const { formShown, poaFormShown } = this.state
    const notAuthorized = !hasKycOverrideChangeRole()
    return (
      <Details marginBottom={`${BASE * 3}px`}>
        <DetailsBlockWithTitle
          title="Verification Status"
          flow={'column'}
          marginBottom={'20px'}
        >
          {kyc.status === 'loading' && <IconLoading />}
          {kyc.status === 'error' && (
            <InfoBlock
              label="Fetching Error"
              value={`Code ${kyc.error.errorCode}: ${kyc.error.message}`}
            />
          )}
          {kyc.status === 'done' && (
            <Fragment>
              <InfoBlock label="Level" value={kyc.info.description} />
              <InfoBlock label="Address Verified" value={kyc.info.isAddressVerified ? 'Yes' : 'No'} />
              <JsonBlock src={kyc.info} title={'Raw Data'} />
              {!formShown && (
                <MuiButton
                  onClick={this.toggleForm}
                  disabled={notAuthorized}
                  showToolTip={notAuthorized}
                  tooltipTitle={
                    notAuthorized
                      ? 'You are not authorized to perform this action'
                      : ''
                  }
                >
                  Override Status
                </MuiButton>
              )}
              {!poaFormShown && !kyc.info.isAddressVerified && (
                <MuiButton
                  onClick={this.togglePoaForm}
                  disabled={notAuthorized}
                  showToolTip={notAuthorized}
                  tooltipTitle={
                    notAuthorized
                      ? 'You are not authorized to perform this action'
                      : ''
                  }
                  style={{ marginTop: 10 }}
                >
                  Override POA Status
                </MuiButton>
              )}
            </Fragment>
          )}
          {formShown &&
            kyc.status === 'done' && (
              <FormOverride
                initialValues={{
                  id,
                  level: '',
                  comment: '',
                  data: { date: new Date(), createdBy: auth.decodeNameToken() },
                  dualAuthStatus: 'pending',
                  actionType: 'KYC_OVERRIDE',
                }}
                form={'KycOverrideForm'}
                kyc={kyc}
                onCancel={this.toggleForm}
                onSubmitSuccess={this.toggleForm}
              />
            )}
          {poaFormShown &&
            kyc.status === 'done' && (
              <FormOverrideAddress
                initialValues={{
                  id,
                  comment: '',
                  data: { date: new Date(), createdBy: auth.decodeNameToken() },
                  dualAuthStatus: 'pending',
                  actionType: 'KYC_ADDRESS_OVERRIDE',
                }}
                form={'KycAddressOverrideForm'}
                onCancel={this.togglePoaForm}
                onSubmitSuccess={this.togglePoaForm}
              />
            )}
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}
export default KYCLevel
