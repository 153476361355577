import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required,maxLength20} from 'forms/fieldValidators'
import { Button, FormContainer, FormError, FormTitle, InputGroup } from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderInput } from '../fields'
import { submit } from './submit'
import validate from './validate'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Change Identity Number</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'New Identity Number'}
          name={'newIdentityNumber'}
          width={'100%'}
          margin={`0 ${BASE * 2}px 0 0`}
          component={renderInput}
          validate={[required,maxLength20 ]}
          fullWidth
        />
        </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment'}
          name={'reason'}
          width={'100%'}
          component={renderInput}
          fullWidth
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit, validate })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
