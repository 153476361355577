import React, { FC, useState } from 'react'
import {
  CorporateRiskProfileWithDualAuthStatus,
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import RiskAccountsProfilesTable from './RiskAccountsProfilesTable'

interface ISearchedRiskProfile {
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
  foundRiskProfile:
    | RiskProfileWithDualAuthStatus
    | CorporateRiskProfileWithDualAuthStatus
  initializeRiskProfileReview: (accountId: number) => Promise<void>
  riskLevelToSearch: RiskLevelTypes
  isSearching: boolean
  searchedRiskProfileAccountId: number
}
const SearchedRiskProfile: FC<ISearchedRiskProfile> = (props) => {
  const [selectedRow, setSelectedRow] = useState(0)

  const toggleSelectedRow = (id: number) =>
    setSelectedRow((prev) => (prev === id ? 0 : id))
  if (props.isSearching) {
    return <LoadingBlock message="Searching for risk profile" />
  }

  if (!props.foundRiskProfile) {
    return (
      <TablePlaceholder>
        No risk profile found for this account id
      </TablePlaceholder>
    )
  }

  return (
    <RiskAccountsProfilesTable
      accountsProfilesData={
        props.riskProfileType === 'CORPORATE'
          ? ([
              props.foundRiskProfile,
            ] as CorporateRiskProfileWithDualAuthStatus[])
          : ([props.foundRiskProfile] as RiskProfileWithDualAuthStatus[])
      }
      expandRowId={selectedRow}
      toggleSelectedRow={toggleSelectedRow}
      status={props.isSearching ? 'pending' : 'done'}
      type={
        props.foundRiskProfile.recommendationStatus as RecommendationStatusTypes
      }
      riskProfileType={props.riskProfileType}
      initializeRiskProfileReview={props.initializeRiskProfileReview}
      riskLevelToSearch={props.riskLevelToSearch}
    />
  )
}

export default SearchedRiskProfile
