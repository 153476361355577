import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import BigNumber from 'bignumber.js'
import auth from 'services/Auth'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.sends.id)
  }

  render() {
    const {
      sends,
      isSelected,
    } = this.props
    const {
      id, accountId, actionType, reason, createdAt, sendAmount,
      sendCurrency, active,
    } = sends
    const shading = isSelected ? 'white' : ''

    let formattedAmount
    if (sendCurrency && sendCurrency.shortName === 'ZAR') {
      formattedAmount = new BigNumber(sendAmount).toFormat(2)
    } else {
      formattedAmount = sendAmount
    }
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'7%'}>{sendCurrency && sendCurrency.shortName}</TableCell>
          <TableCell width={'20%'}>{formattedAmount}</TableCell>
          <TableCell width={'25%'}>{actionType}</TableCell>
          <TableCell width={'20%'}>{reason}</TableCell>
          <TableCell width={'12%'}>{createdAt}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              accountId,
              dualAuthStatus: 'rejected',
              actionType: 'BLOCKCHAIN_SENDS_AND_REVERSAL',
              flag: false,
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
            }}
            addComment
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.toggleForm}
            rawData={sends}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'BLOCKCHAIN_SENDS_REVERSAL_EVENT')
