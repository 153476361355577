import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAccountFundingEvent } from 'redux/fundingEvents/actions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import { FundingInfoAndEventsRowData } from 'components/organisms/DepositFundingInfo/interfaces'

import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import { FundingExtraInfo } from '../../../components/organisms/DepositFundingInfo/FundingExtraInfo'
import { FundingEventsData } from '../../../redux/fundingEvents'

interface AccountFundingDetailsProps {
  fetchAccountFundingEvent: (value: any) => void
  fundingEvents: FundingEventsData
  accountId: string
  match: any
  data: FundingInfoAndEventsRowData
}

interface AccountFundingDetailsState {
  expandedRow: number | null
  addNoteFormShown: string
  highlightedId: string
}

class AccountFundingDetails extends Component<
  AccountFundingDetailsProps,
  AccountFundingDetailsState
> {
  constructor(props) {
    super(props)
    this.state = {
      expandedRow: null,
      addNoteFormShown: '',
      highlightedId: '',
    }
  }

  componentDidMount() {
    const { accountId } = this.props.match.params
    this.props.fetchAccountFundingEvent(accountId)
  }

  toggleSelectedRow = id => {
    if (id !== this.state.expandedRow) {
      this.setState({ expandedRow: id })
    } else {
      this.setState({ expandedRow: null })
    }
  }

  render() {
    const { status, data } = this.props.fundingEvents
    return (
      <Fragment>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && (
          <Paper>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <TableHead>
                <TableRow>
                  <TableCell>Amount</TableCell>
                  <TableCell>Fee Amount</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Transaction Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.length === 0 && (
                  <TableCell colSpan={6}>
                    <TablePlaceholder>
                      {'This account has no deposits'}
                    </TablePlaceholder>
                  </TableCell>
                )}
                {data &&
                  data.length > 0 &&
                  data.map((funds, index) => (
                    <>
                      <TableRow onClick={() => this.toggleSelectedRow(index)}>
                        <TableCell>{funds.amount}</TableCell>
                        <TableCell>{funds.feeAmount}</TableCell>
                        <TableCell>{funds.currency}</TableCell>
                        <TableCell>{funds.status}</TableCell>
                        <TableCell>{funds.provider}</TableCell>
                        <TableCell>
                          {moment(funds.transactionDate).format(
                            'YYYY-MMM-DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                      {this.state.expandedRow === index && (
                        <TableRow className="no-hover">
                          <TableCell colSpan={9}>
                            <Details>
                              <DetailsBlockWithTitle title={'Funding Information'}>
                                <FundingExtraInfo data={funds} defaultExpanded />
                              </DetailsBlockWithTitle>
                            </Details>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  fundingEvents: state.fundingEvents.fundingEvents,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountFundingEvent,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountFundingDetails)
