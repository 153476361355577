import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import AccountOpenOrders from './AccountOpenOrders'
import OpenFuturesPositions from './OpenFuturesPositions'
import AccountCondtionalOrders from './AccountConditionalOrders'

const routes = [
  '/accounts/byId/:accountId/trading',
  '/accounts/byId/:accountId/trading/open-futures-positions',
  '/accounts/byId/:accountId/trading/conditional-orders',
]

const TradingNav = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    if (pathname.includes('open-futures-positions')) {
      setCurrentPage(routes[1])
    }
  }, [])

  return (
    <>
      <Fragment>
        <TopNavigationBar
          value={currentPage}
          onChange={handleChange}
          variant="fullWidth"
        >
          <NavigationTab
            value={routes[0]}
            label="OPEN ORDERS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/trading`}
          />
          <NavigationTab
            value={routes[1]}
            label="OPEN FUTURES POSITIONS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/trading/open-futures-positions`}
          />
           <NavigationTab
            value={routes[2]}
            label="CONDITIONAL ORDERS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/trading/conditional-orders`}
          />
        </TopNavigationBar>
        <ContentContainer>
          <Switch>
            <Route path={routes[0]} component={AccountOpenOrders} exact />
            <Route path={routes[1]} component={OpenFuturesPositions} />
            <Route path={routes[2]} component={AccountCondtionalOrders} />
          </Switch>
        </ContentContainer>
      </Fragment>
    </>
  )
}

export default TradingNav
