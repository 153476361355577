import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getBalances = state => state.balances.byId

export const getAccountBalances = createSelector(
  [getBalances, getActiveAccount],
  (balances, id) => {
    if (!id || !balances[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }
    return balances[id]
  },
)

export const getAccountBalancesDownloadStatus = createSelector(
  [getBalances, getActiveAccount],
  (balances, id) => {
    if (!id || !balances[id]) {
      return 'pristine'
    }
    return balances[id]?.downloadStatus || 'pristine'
  },
)