import { OpenOrder } from 'interfaces/openOrders'
import { TradingViewActions } from './actions'
import { OpenFuturesPosition } from 'interfaces/openFuturesPositions'

type InitialState = {
  openOrders: {
    loading: boolean
    data: OpenOrder[]
    isCancelingOrder: boolean
  }
  openFuturesPositions: {
    loading: boolean
    // TODO: GET TYPE FOR OPEN FUTURES POSITIONS
    data: OpenFuturesPosition[]
  }
}

const initialState: InitialState = {
  openOrders: {
    loading: false,
    data: [],
    isCancelingOrder: false,
  },
  openFuturesPositions: {
    loading: false,
    data: [],
  },
}

type Action<T, P> = {
  type: T
  payload: P
}

type Payload = OpenOrder[] | { canceledOrderId: string } | OpenFuturesPosition[]

const tradingReducer = (
  state = initialState,
  action: Action<TradingViewActions, Payload>,
): InitialState => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_OPEN_ORDERS_START':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: true,
        },
      }
    case 'FETCH_ACCOUNT_OPEN_ORDERS_SUCCESS':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
          data: action.payload as OpenOrder[],
        },
      }
    case 'FETCH_ACCOUNT_OPEN_ORDERS_ERROR':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
        },
      }
    case 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_START':
      return {
        ...state,
        openFuturesPositions: {
          ...state.openFuturesPositions,
          loading: true,
        },
      }
    case 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_SUCCESS':
      return {
        ...state,
        openFuturesPositions: {
          ...state.openFuturesPositions,
          loading: false,
          data: action.payload as OpenFuturesPosition[],
        },
      }
    case 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_ERROR':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
        },
      }
    case 'CANCEL_ACCOUNT_OPEN_ORDER_START':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          isCancelingOrder: true,
        },
      }
    case 'CANCEL_ACCOUNT_OPEN_ORDER_SUCCESS':
      const payload = action.payload as { canceledOrderId: string }
      const updatedOpenedOrdersData = state.openOrders.data.filter(
        (order) => order.id !== payload.canceledOrderId,
      )
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          isCancelingOrder: false,
          data: updatedOpenedOrdersData,
        },
      }
    case 'CANCEL_ACCOUNT_OPEN_ORDER_ERROR':
      return {
        ...state,
        openOrders: {
          ...state.openOrders,
          loading: false,
        },
      }

    default:
      return state
  }
}

export default tradingReducer
