import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sortBy } from 'helpers/utils'
import * as moment from 'moment'
import { FlexboxColumn } from 'components/layout'

import {
  Table,
  TableHeader,
  TableBody,
  HeadCell,
  TablePlaceholder,
  Actions,
  ActionLabel,
  ActionInput,
  ActionInputIcon,
  TableCell,
} from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Title } from 'components/atoms/Title/index'
import ErrorToast from 'components/molecules/ErrorToast/index'

import RenderTableRow from './rows'

class WithdrawalsUnderReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: '',
      sortField: '',
      sortReverse: false,
      activeRowId: '',
      showRawData: '',
      selectedId: -1,
      expandedItem: '',
      highlightedId: '',
      scamBRFWithdrawals: [],
      sanctionedWithdrawals: [],
      otherWithdrawals: [],
      threshHoldWithdrawals: [],
      childAbuseWithdrawals: [],
      terroristFinancingWithdrawals: [],
      potentialScammerIdWithdrawals: [],
      creditCardDepositsExceededThresholdsWithdrawals: [],
      creditCardCountExceededThresholdWithdrawals: [],
      creditCardSharingExceededThresholdWithdrawals: [],
      expandRawData: '',
      addNoteFormShown: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.withdrawals !== this.props.withdrawals) {
      this.categorizeCryptoWithdrawals(this.props.withdrawals.data)
    }
  }

  showRawData = id => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleSelected = id => {
    if (this.state.selectedId === id) {
      this.setState({ selectedId: -1 })
    } else {
      this.setState({ selectedId: id })
    }
  }

  toggleForm = uniqueId => {
    const { activeRowId } = this.state.activeRowId
    if (activeRowId !== uniqueId) {
      this.setState({ activeRowId: uniqueId })
    } else {
      this.setState({ activeRowId: '' })
    }
  }

  toggleAddNotesForm = id => {
    if (id !== this.state.addNoteFormShown) {
      this.setState({ addNoteFormShown: id })
    } else {
      this.setState({ addNoteFormShown: '' })
    }
  }

  sortDataPure(data, field, reverse) {
    switch (field) {
      case 'accountId':
        return data.sort(sortBy('accountId', reverse, tx => tx.accountId))
      case 'uniqueId':
        return data.sort(sortBy('uniqueId', reverse, tx => tx.uniqueId))
      case 'status':
        return data.sort(sortBy('status', reverse, tx => tx.status))
      case 'amount':
        return data.sort(sortBy('amount', reverse, tx => tx.amount))
      case 'address':
        return data.sort(sortBy('sendAddress', reverse, tx => tx.sendAddress))
      case 'confirmations':
        return data.sort(
          sortBy('confirmations', reverse, tx => tx.confirmations),
        )
      case 'fee':
        return data.sort(sortBy('feeAmount', reverse, tx => tx.feeAmount || 0))
      case 'timestamp':
        return data.sort(sortBy('createdAt', reverse, tx => tx.createdAt))
      default:
        return data
    }
  }

  filterData(data) {
    let filteredData = []
    filteredData =
      data.length === 0
        ? data
        : data.filter(
          item =>
            item.meta.searchString
              .toLowerCase()
              .indexOf(this.state.filter.toLowerCase()) > -1,
        )
    const { sortField, sortReverse } = this.state
    return this.sortDataPure(filteredData, sortField, sortReverse)
  }

  toggleRawData = id => {
    if (this.state.showRawData === id) {
      this.setState({ showRawData: '' })
    } else {
      this.setState({ showRawData: id })
    }
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  handleSort = field => {
    const { sortField, sortReverse } = this.state
    const reverse = field === sortField ? !sortReverse : false
    this.setState({
      sortField: field,
      sortReverse: reverse,
    })
  }

  handleFilter = filter => {
    this.setState({
      filter,
    })
  }

  renderWithdrawals(txList) {
    const { filter } = this.state
    const { fetchAccountDetails, accounts } = this.props
    const message =
      filter.length === 0
        ? 'No transactions available'
        : 'No transactions that fit filter criteria'
    return txList.length === 0 ? (
      <TablePlaceholder>{message}</TablePlaceholder>
    ) : (
      txList &&
      txList.map(withdrawal => {
        withdrawal.createdAt = moment(withdrawal.createdAt).format(
          'YYYY-MMM-DD HH:mm:ss',
        )
        withdrawal.placedUnderReviewAt = moment(
          withdrawal.placedUnderReviewAt,
        ).format('YYYY-MMM-DD HH:mm:ss')
        const accId = withdrawal.accountId

        return (
          <RenderTableRow
            key={withdrawal.withdrawalId}
            rowData={withdrawal}
            toggleSelected={this.toggleSelected}
            formShown={this.state.activeRowId === withdrawal.withdrawalId}
            showForm={this.state.selectedId === withdrawal.uniqueId}
            toggleForm={this.toggleForm}
            toggleAddNotesForm={this.toggleAddNotesForm}
            addNoteFormShown={this.state.addNoteFormShown}
            expandRawData={this.state.expandRawData}
            showRawData={this.showRawData}
            toggleRawData={this.toggleRawData}
            isHighlighted={this.state.selectedId === withdrawal.uniqueId}
            setHighlightedId={this.setHighlightedId}
          />
        )
      })
    )
  }

  renderTable(cryptoWithdrawals) {
    const { sortField, sortReverse } = this.state
    return (
      <Table>
        <TableHeader>
          <HeadCell
            flex={0.6}
            justify="space-between"
            align="left"
            active={sortField === 'accountId'}
            reverse={sortReverse}
            onClick={() => this.handleSort('accountId')}
          >
            Account Id
          </HeadCell>
          <HeadCell
            flex={1.3}
            justify="space-between"
            align="left"
            active={sortField === 'uniqueId'}
            reverse={sortReverse}
            onClick={() => this.handleSort('uniqueId')}
          >
            Unique Id
          </HeadCell>
          <HeadCell
            flex={1.5}
            justify="space-between"
            active={sortField === 'address'}
            reverse={sortReverse}
            onClick={() => this.handleSort('address')}
          >
            Address
          </HeadCell>
          <HeadCell
            flex={1}
            align="right"
            justify="space-between"
            active={sortField === 'amount'}
            reverse={sortReverse}
            onClick={() => this.handleSort('amount')}
          >
            Amount
          </HeadCell>
          <HeadCell
            flex={1}
            align="right"
            justify="space-between"
            active={sortField === 'fee'}
            reverse={sortReverse}
            onClick={() => this.handleSort('fee')}
          >
            Fee Amount
          </HeadCell>
          <HeadCell
            flex={1}
            align="right"
            justify="space-between"
            active={sortField === 'paymentReference'}
            reverse={sortReverse}
            onClick={() => this.handleSort('paymentReference')}
          >
            Payment Reference
          </HeadCell>
          <HeadCell
            flex={1}
            align="right"
            justify="space-between"
            active={sortField === 'timestamp'}
            reverse={sortReverse}
            onClick={() => this.handleSort('timestamp')}
          >
            Created At
          </HeadCell>
          <TableCell flex={0.5} align="right" justify="space-between" />
          <TableCell flex={0.18} align={'center'} />
        </TableHeader>
        <TableBody>{cryptoWithdrawals}</TableBody>
      </Table>
    )
  }

  categorizeCryptoWithdrawals(cryptoWithdrawals = []) {
    try {
      const regex = /type=(\w+)/
      const categorizedUnresolvedWithdrawals = {
        threshHoldWithdrawals: [],
        scamBRFWithdrawals: [],
        sanctionedWithdrawals: [],
        terroristFinancingWithdrawals: [],
        childAbuseWithdrawals: [],
        otherWithdrawals: [],
        potentialScammerIdWithdrawals: [],
        creditCardDepositsExceededThresholdsWithdrawals: [],
        creditCardCountExceededThresholdWithdrawals: [],
        creditCardSharingExceededThresholdWithdrawals: [],
      }
      const scamBrfWithdrawalsData = cryptoWithdrawals.filter(w => {
        const { reviewData } = w

        if (reviewData) {
          const { flags } = reviewData
          const flaggedComment = flags.find(({ flag }) =>
            flag.toLowerCase().includes('flagged'),
          )

          if (!flaggedComment) {
            return categorizedUnresolvedWithdrawals.threshHoldWithdrawals.push(
              w,
            )
          }
          switch (flaggedComment.additionalInfo.match(regex)[1]) {
            case 'scam_send_address':
              categorizedUnresolvedWithdrawals.scamBRFWithdrawals.push(w)
              break
            case 'sanction_send_address':
              categorizedUnresolvedWithdrawals.sanctionedWithdrawals.push(w)
              break
            case 'other_send_address':
              categorizedUnresolvedWithdrawals.otherWithdrawals.push(w)
              break
            case 'terrorist_financing_send_address':
              categorizedUnresolvedWithdrawals.terroristFinancingWithdrawals.push(
                w,
              )
              break
            case 'child_abuse_material_send_address':
              categorizedUnresolvedWithdrawals.childAbuseWithdrawals.push(w)
              break
            case 'potential_scammer_account_id':
              categorizedUnresolvedWithdrawals.potentialScammerIdWithdrawals.push(
                w,
              )
              break
            case 'credit_card_deposits_exceeded_threshold':
              categorizedUnresolvedWithdrawals.creditCardDepositsExceededThresholdsWithdrawals.push(w)
              break
            case 'credit_card_count_exceeded_threshold':
              categorizedUnresolvedWithdrawals.creditCardCountExceededThresholdWithdrawals.push(w)
              break
            case 'potential_scammer_mobile_device_id':
              categorizedUnresolvedWithdrawals.potentialScammerIdWithdrawals.push(
                w,
              )
              break
            case 'credit_card_sharing_exceeded_threshold':
              categorizedUnresolvedWithdrawals.creditCardSharingExceededThresholdWithdrawals.push(w)
              break
            default:
              categorizedUnresolvedWithdrawals.otherWithdrawals.push(w)
              break
          }
        }
      })
      this.setState({ ...categorizedUnresolvedWithdrawals })
      return scamBrfWithdrawalsData
    } catch (error) {
      ErrorToast(error)
    }
  }

  constructWithdrawalSections() {
    const threshHoldWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.threshHoldWithdrawals),
    )
    const scamBRFWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.scamBRFWithdrawals),
    )
    const sanctionedWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.sanctionedWithdrawals),
    )
    const terroristFinancingWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.terroristFinancingWithdrawals),
    )
    const childAbuseWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.childAbuseWithdrawals),
    )
    const otherWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.otherWithdrawals),
    )
    const potentialScammerIdWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.potentialScammerIdWithdrawals),
    )

    const creditCardDepositsExceededThresholdsWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.creditCardDepositsExceededThresholdsWithdrawals)
    )
    const creditCardCountExceededThresholdWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.creditCardCountExceededThresholdWithdrawals)
    )
    const creditCardSharingExceededThresholdWithdrawals = this.renderWithdrawals(
      this.filterData(this.state.creditCardSharingExceededThresholdWithdrawals)
    )


    const withdrawalsSections = [
      {
        data: threshHoldWithdrawals,
        title: 'Threshold Withdrawals',
      },
      {
        data: scamBRFWithdrawals,
        title: 'Scam/BRF Withdrawals',
      },
      {
        data: sanctionedWithdrawals,
        title: 'Sanctioned Withdrawals',
      },
      {
        data: terroristFinancingWithdrawals,
        title: 'Terrorist Financing Withdrawals',
      },
      {
        data: childAbuseWithdrawals,
        title: 'Child Abuse Withdrawals',
      },
      {
        data: potentialScammerIdWithdrawals,
        title: 'Potential Scammer Id Withdrawals',
      },
      {
        data: creditCardDepositsExceededThresholdsWithdrawals,
        title: 'Card Deposit Threshold',
      },
      {
        data: creditCardCountExceededThresholdWithdrawals,
        title: 'Card Count Limit',
      },
      {
        data: creditCardSharingExceededThresholdWithdrawals,
        title: 'Card Sharing',
      },
      {
        data: otherWithdrawals,
        title: 'Other Withdrawals',
      },

    ]

    return withdrawalsSections
  }

  render() {
    const { name, expanded, setExpand } = this.props
    const {
      withdrawals: { status },
    } = this.props
    const { filter } = this.state
    const withdrawalsSections = this.constructWithdrawalSections()

    return (
      <>
        <Title type="h3" text={name} />
        {status === 'loading' && (
          <TablePlaceholder key="placeholder">
            <LoadingBlock />
          </TablePlaceholder>
        )}
        {status === 'done' && (
          <div>
            <Actions margin="8px 0px" justify="flex-end">
              <ActionLabel>Filter:</ActionLabel>
              <ActionInput
                key="input-filter"
                value={filter}
                marginRight={0}
                onChange={event => this.handleFilter(event.target.value)}
                actionView={() =>
                  filter.length > 0 && (
                    <ActionInputIcon
                      title="Clear Filter"
                      className="far fa-times-circle"
                      onClick={() => this.handleFilter('')}
                    />
                  )
                }
              />
            </Actions>
            {withdrawalsSections.map(section => (
              <ExpandingItem
                key={section.title}
                title={section.title}
                itemsAmount={(section.data && section.data.length) || 0}
                expanded={expanded}
                onExpand={setExpand}
                expandedView={
                  <>
                    <FlexboxColumn key="expanding-item">
                      {this.renderTable(section.data)}
                    </FlexboxColumn>
                  </>
                }
              />
            ))}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  withdrawals: state.crypto.withdrawalsUnderReview,
  reviews: state.reviews,
  accounts: state.accounts.byId,
})

export default connect(mapStateToProps)(WithdrawalsUnderReview)
