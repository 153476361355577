import { Button, Tooltip, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

interface MuiButtonProps {
  color?: 'default' | 'primary' | 'inherit' | 'secondary' | undefined
  variant?: 'contained' | 'text' | 'outlined' | undefined
  children: string | React.ReactNode
  disabled?: boolean
  type?: any
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showToolTip?: boolean
  tooltipTitle?: string
  toolTipTextSize?: string | number
}

const useStyles = (toolTipTextSize: string | number = '') =>
  makeStyles({
    tooltip: {
      fontSize: toolTipTextSize,
    },
  })

const MuiButton: FC<MuiButtonProps> = ({
  color = 'primary',
  variant = 'contained',
  children,
  disabled,
  onClick,
  type,
  showToolTip,
  toolTipTextSize,
  tooltipTitle = '',
  ...rest
}) => {
  const classes = useStyles(toolTipTextSize)()
  const handleButtonDisplay = () => {
    if (!onClick) {
      return (
        <Button
          {...rest}
          type={type || 'submit'}
          disabled={disabled}
          color={color}
          variant={variant}
        >
          {children}
        </Button>
      )
    }
    return showToolTip ? (
      <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipTitle}>
        <div>
          <Button
            {...rest}
            type={type}
            disabled={disabled}
            color={color}
            variant={variant}
            onClick={onClick}
          >
            {children}
          </Button>
        </div>
      </Tooltip>
    ) : (
      <Button
        {...rest}
        type={type}
        disabled={disabled}
        color={color}
        variant={variant}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }
  return handleButtonDisplay()
}

export default MuiButton
