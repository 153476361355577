import getAxios from 'helpers/axios'
import { Dispatch } from 'redux'

export const FETCH_REQUESTED_ACCOUNT_ACTION_START = 'account/FETCH_REQUESTED_ACCOUNT_ACTION_START'
export const FETCH_REQUESTED_ACCOUNT_ACTION_SUCCESS = 'account/FETCH_REQUESTED_ACCOUNT_ACTION_SUCCESS'
export const FETCH_REQUESTED_ACCOUNT_ACTION_ERROR = 'account/FETCH_REQUESTED_ACCOUNT_ACTION_ERROR'
export const FETCH_REQUESTED_ACCOUNT_ACTION_CLEAR = 'account/FETCH_REQUESTED_ACCOUNT_ACTION_CLEAR'

export const FETCH_AVAILABLE_ACCOUNT_ACTION_START = 'account/FETCH_AVAILABLE_ACCOUNT_ACTION_START'
export const FETCH_AVAILABLE_ACCOUNT_ACTION_SUCCESS = 'account/FETCH_AVAILABLE_ACCOUNT_ACTION_SUCCESS'
export const FETCH_AVAILABLE_ACCOUNT_ACTION_ERROR = 'account/FETCH_AVAILABLE_ACCOUNT_ACTION_ERROR'

export const clearRequestedAccountActions = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_REQUESTED_ACCOUNT_ACTION_CLEAR,
  })
}

export const fetchRequestedAccountActions = id => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_REQUESTED_ACCOUNT_ACTION_START,
  })
  try {
    const res = await getAxios().get(`/account/requested-actions/${id}`)
    const { data } = res
    dispatch({
      type: FETCH_REQUESTED_ACCOUNT_ACTION_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_REQUESTED_ACCOUNT_ACTION_ERROR,
    })
  }
}

export const fetchAvailableAccountActionsStart = () => ({
  type: FETCH_AVAILABLE_ACCOUNT_ACTION_START,
})

export const fetchAvailableAccountActionsSuccess = (data) => ({
  type: FETCH_AVAILABLE_ACCOUNT_ACTION_SUCCESS,
  payload: data,
})

export const fetchAvailableAccountActionsError = (error) => ({
  type: FETCH_AVAILABLE_ACCOUNT_ACTION_ERROR,
  payload: error,
})
