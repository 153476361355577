import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { FC, Fragment } from 'react'
import { RiskProfileDualAuth } from 'interfaces/riskProfile'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { Title } from 'components/atoms/Title'

import RiskAccountsProfilesDualAuthRow from './RiskAccountsDulAuthRow'

interface RiskProfileDualAuthTableProps {
  accountsProfilesDualAuthData: RiskProfileDualAuth[]
  toggleSelectedRow: (value: string) => void
  expandedRowId: string
  status: 'pristine' | 'pending' | 'done'
  isMultiAuth?: boolean
  isCorporateRiskProfileDualAuth?: boolean
}
const RiskAccountsDualAuthProfilesTable: FC<RiskProfileDualAuthTableProps> = (
  props,
) => {
  const {
    accountsProfilesDualAuthData,
    toggleSelectedRow,
    expandedRowId,
    status,
    isMultiAuth,
    isCorporateRiskProfileDualAuth,
  } = props

  return (
    <Fragment>
      <Title
        type="h3"
        text={isMultiAuth ? 'Pending Multi Auth' : 'Pending Dual Auth'}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account ID</TableCell>
            <TableCell>
              {isCorporateRiskProfileDualAuth ? 'Corporate ' : ''}Name
            </TableCell>
            {!isCorporateRiskProfileDualAuth && <TableCell>Surname</TableCell>}
            <TableCell>Approvals</TableCell>
            <TableCell>Recommendation Status</TableCell>
            <TableCell>Risk Level</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {status === 'pending' && (
          <TableCell colSpan={10}>
            <LoadingBlock />
          </TableCell>
        )}
        {status === 'done' && accountsProfilesDualAuthData.length === 0 && (
          <TableCell colSpan={10}>
            <TablePlaceholder>{'No Data'}</TablePlaceholder>
          </TableCell>
        )}
        {status === 'done' &&
          accountsProfilesDualAuthData.map((dualAuth) => (
            <RiskAccountsProfilesDualAuthRow
              isMultiAuth={isMultiAuth}
              key={dualAuth.id}
              accountsdualAuth={dualAuth}
              toggleSelectedRow={toggleSelectedRow}
              isSelected={
                expandedRowId ===
                `${dualAuth.id}-${
                  isCorporateRiskProfileDualAuth ? 'corporate' : 'individual'
                }`
              }
              isCorporateRiskProfileDualAuth={isCorporateRiskProfileDualAuth}
            />
          ))}
      </Table>
    </Fragment>
  )
}
export default RiskAccountsDualAuthProfilesTable
