import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const submit = async (values, dispatch, isEditing) => {
  try {
    const { accountId, currency, amount } = values

    if (isEditing) {
      try {
        const data = await getAxios().put(`/account-withdrawal-restrictions`, {
          amount,
          symbol: currency,
          accountId,
        })
        if (data.status === 202) {
          toast('Account withdrawal restrictions updated', {
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          })
        }
      } catch (error) {
        ErrorToast(
          `Error occured while updating withdrawal restriction ${error}`,
        )
      }
    } else {
      try {
        const data = await getAxios().post(`/account-withdrawal-restrictions`, {
          amount,
          symbol: currency,
          accountId,
        })
        if (data.status === 200) {
          toast('Account withdrawal restrictions added', {
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          })
        }
      } catch (error) {
        ErrorToast(`Error occured while adding withdrawal restriction ${error}`)
      }
    }
  } catch (error) {
    ErrorToast(error)
  }
}
