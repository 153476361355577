import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  InputGroup,
  Button
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput, renderSwitch } from '../fields'
import { submit } from './submit'
import { required, isNumber, requiredDropdownValue } from 'forms/fieldValidators'
import AlertDialog from './confirmation'

const currencyPairs = props => {
  const { handleSubmit, onCancel, submitting, pristine, invalid, valid, symbol, shortName, currencies, values, onSubmit } = props

  const currenciesData = useMemo(() => (
    currencies.data.filter(currencies => {
      return currencies.isActive
    }).map(property => {
      const option = { value: property.shortName, label: property.shortName }
      return option
    }).sort((a, b) => {
      if (a.label < b.label) return -1
      return a.label > b.label ? 1 : 0
    })
  ), [currencies.data])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Add Currency Pair</FormTitle>
      <InputGroup>

        <Field
          name={'baseCurrencySymbol'}
          component={renderDropdown}
          options={[...currenciesData]}
          label="Base Currency Symbol"
          validate={[requiredDropdownValue]}
          margin={`0 ${BASE * 2}px 0 0`}
          fullWidth />
        <Field
          name={'quoteCurrencySymbol'}
          component={renderDropdown}
          options={[...currenciesData]}
          label="Quote Currency Symbol"
          validate={[requiredDropdownValue]}
          fullWidth />
      </InputGroup>
      <InputGroup>
        <Field name={'symbol'} component={renderInput} inputValue={symbol} label="Symbol" margin={`0 ${BASE * 2}px 0 0`} fullWidth validate={[required]} />
        <Field name={'shortName'} component={renderInput} inputValue={shortName} label="Short Name" fullWidth validate={[required]} />
      </InputGroup>
      <InputGroup>
        <Field
          name={'exchange'}
          component={renderDropdown}
          label={'Exchange'}
          margin={`0 ${BASE * 2}px 0 0`}
          fullWidth
          validate={[requiredDropdownValue]}
          options={[
            { value: 'VALR', label: 'VALR' },
            { value: 'BITTREX', label: 'BITTREX' },
            { value: 'COINBASE', label: 'COINBASE' },
          ]}
        />
        <Field name={'minBaseAmount'} component={renderInput} label="Min Base Amount" validate={[required, isNumber]} fullWidth />
      </InputGroup>
      <InputGroup>
        <Field name={'maxBaseAmount'} component={renderInput} label="Max Base Amount" margin={`0 ${BASE * 2}px 0 0`} validate={[required, isNumber]} fullWidth />
        <Field name={'minQuoteAmount'} component={renderInput} label="Min Quote Amount" validate={[required, isNumber]} fullWidth />
      </InputGroup>
      <InputGroup>
        <Field name={'tickSize'} component={renderInput} label="Tick Size" margin={`0 ${BASE * 2}px 0 0`} validate={[required, isNumber]} fullWidth />
        <Field name={'maxQuoteAmount'} component={renderInput} label="Max Quote Amount" validate={[required, isNumber]} fullWidth />
      </InputGroup>
      <InputGroup >
        <Field
          name={'currencyPairType'}
          component={renderDropdown}
          label={'Currency Pair Type'}
          margin={`0 ${BASE * 2}px 0 0`}
          fullWidth
          validate={[requiredDropdownValue]}
          options={[
            { value: 'SPOT', label: 'SPOT' },
            { value: 'FUTURE', label: 'FUTURE' },
          ]}
        />
        <Field name={'baseDecimalPlaces'} component={renderInput} label="Base Decimal Places" validate={[required, isNumber]} fullWidth />
      </InputGroup>
      <InputGroup justify={'flex-start'}>
        <Field name={'active'} component={renderSwitch} label="Active"
          formatLabel={false}
          type={'yes/no'} />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <AlertDialog onSubmit={handleSubmit(onSubmit)} values={!valid || pristine || submitting} />
        <Button variant="contained" color="secondary" style={{ width: '80px' }} onClick={onCancel} >
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxFormAddCurrencyPair = reduxForm({
  form: 'addCurrencyPair',
  onSubmit: submit,
  onSubmitSuccess: (result, dispatch, props,) => {
  },
  validate: values => {
    const errors = {}
    if (
      !values.symbol &&
      !values.baseCurrencySymbol &&
      !values.quoteCurrencySymbol &&
      !values.shortName &&
      !values.exchange &&
      !values.minBaseAmount &&
      !values.maxBaseAmount &&
      !values.tickSize &&
      !values.maxQuoteAmount &&
      !values.currencyPairType &&
      !values.baseDecimalPlaces &&
      !values.active
    ) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
})(currencyPairs)

const mapStateToProps = (state, ownProps) => {

  const suspendedForm = state.form[ownProps.form]
  if (!suspendedForm) {
    return {
      symbol: '',
      shortName: '',
    }
  } if (suspendedForm.values) {
    const {
      values: { baseCurrencySymbol: { value }, quoteCurrencySymbol, currencyPairType },
    } = suspendedForm
    return {
      symbol: `${(value || '') + (quoteCurrencySymbol.value || '') + (currencyPairType.value === 'FUTURE' ? 'PERP' : '')}`,
      shortName: `${value || ''}/${quoteCurrencySymbol.value || ''}${(currencyPairType.value === 'FUTURE' ? 'PERP' : '')}`,
    }
  }
  return { symbol: '', shortName: '' }
}

export default connect(mapStateToProps)(ReduxFormAddCurrencyPair)
