import React, { useState } from 'react'

import { Table, TableBody } from 'components/atoms/Table/styles'
import { connectStateAndDispatch } from 'store'
import { removeSubmittedItem } from 'redux/identityNumberChange/actions'
import { Headers } from './headers'
import Row from './row'
import { fetchIdentityNumberChangeDualAuthRawData } from 'redux/identityNumberChange/actions'

const IdentityNumberChangeTable = props => {
  const { expandItem, data, identityNumberDataById, rowLoading } = props
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = uniqueId =>
    setSelectedRow(prev => (prev === uniqueId ? '' : uniqueId))

  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(identityNumberInfo => (
          <Row
            key={identityNumberInfo.id}
            dualAuthId={identityNumberInfo.id}
            identityNumberInfo={identityNumberInfo}
            isSelected={selectedRow === identityNumberInfo.id}
            toggleSelected={toggleSelectedRow}
            removeSubmittedItem={props.removeSubmittedItem}
            fetchIdentityNumberChangeDualAuthRawData={
              props.fetchIdentityNumberChangeDualAuthRawData
            }
            rowLoading={rowLoading}
            identityNumberDataById={identityNumberDataById}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const actions = {
  removeSubmittedItem,
  fetchIdentityNumberChangeDualAuthRawData,
}
export default connectStateAndDispatch(
  state => ({
    rowLoading: state.identityChange.identityNumberDataByIdLoading,
    identityNumberDataById: state.identityChange.identityNumberDataById,
  }),
  actions,
  IdentityNumberChangeTable,
)
