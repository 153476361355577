import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
interface IMuiSelectProps {
  label: string
  handleChange:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined
          value: unknown
        }>,
        child: React.ReactNode,
      ) => void)
    | undefined
  showHighlightedBackground?: boolean
  options: string[]
  value: string | number
  variant?: 'filled' | 'outlined' | 'standard' | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      padding: 20,
    },
  }),
)

export default function MuiSelect({
  options,
  value,
  label,
  variant = 'filled',
  handleChange,
}: IMuiSelectProps) {
  const classes = useStyles()

  return (
    <div>
      <FormControl variant={variant} className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
