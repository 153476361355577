import {
  FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_ERROR,
  FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_PROCESS,
  FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
  UPDATE_CRYPTO_WITHDRAWALS_SUCCESS,
  UPDATE_CRYPTO_WITHDRAWAL_SUCCESS,
  UPDATE_NOTES_FOR_CRYPTO_WITHDRAWALS_SUCCESS,
} from './actions'

const initialState = {
  withdrawalsUnderReview: {
    status: 'pristine',
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CRYPTO_WITHDRAWALS_SUCCESS: {
      const updatedData = [...state.withdrawalsUnderReview.data]
      const indexOfWithdrawalToRemove = updatedData.findIndex(
        item => item.withdrawalId === action.payload.withdrawalId,
      )
      updatedData.splice(indexOfWithdrawalToRemove, 1)
      return {
        ...state,
        withdrawalsUnderReview: {
          ...state.withdrawalsUnderReview,
          data: updatedData,
        },
      }
    }

    case UPDATE_NOTES_FOR_CRYPTO_WITHDRAWALS_SUCCESS: {
      const { withdrawalId } = action.payload
      const updatedWithdrawalsData =
        state.withdrawalsUnderReview &&
        state.withdrawalsUnderReview.data &&
        state.withdrawalsUnderReview.data.map(withdrawal => {
          if (withdrawalId === withdrawal.withdrawalId) {
            withdrawal.tag = true
          }
          return withdrawal
        })
      return {
        ...state,
        withdrawalsUnderReview: {
          ...state.withdrawalsUnderReview,
          data: updatedWithdrawalsData,
          status: 'done',
        },
      }
    }

    case FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_PROCESS: {
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'loading',
          data: [...state.withdrawalsUnderReview.data],
        },
      }
    }
    case FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'done',
          data,
        },
      }
    }

    case UPDATE_CRYPTO_WITHDRAWAL_SUCCESS: {
      const { data } = action.payload
      const updatedWithrawal =
        state.withdrawalsUnderReview &&
        state.withdrawalsUnderReview.data &&
        state.withdrawalsUnderReview.data.map(dat => {
          if (data && data.length > 0) {
            data.some(deposit => {
              if (deposit.withdrawalId === dat.uniqueId) {
                dat.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              }
              dat.active = { activePersonName: '', userActive: false }
            })
          } else {
            dat.active = { activePersonName: '', userActive: false }
          }
          return dat
        })
      return {
        ...state,
        withdrawalsUnderReview: {
          ...state.withdrawalsUnderReview,
          data: updatedWithrawal,
        },
      }
    }

    case FETCH_CRYPTO_WITHDRAWALS_UNDER_REVIEW_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'error',
          data: error,
        },
      }
    }
    default:
      return state
  }
}
