import React, { FC } from 'react'
import { IconButton } from '@material-ui/core'
import BasicModal from 'components/organisms/Modal/index'
import { ContentContainer, Spacer } from 'components/atoms/Generic'
import useCorporateSanctionResult from 'Hooks/Sanctions/useCorporateSanctionResult'
import { SanctionedCorporate } from 'interfaces/sanctions'
import InfoIcon from '@material-ui/icons/Info'
import {
  acknowledgeCorporateSanction,
  AcknowledgeCorporateSanctionParams,
} from 'redux/sanctionedCorporate/actions'
import { CancelOutlined } from '@material-ui/icons'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { connectStateAndDispatch, mapStateToProps } from 'store'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { Title } from 'components/atoms/Title'
import { SanctionsCheckForm } from 'forms/SanctionsCheckForm'
import {
  DetailsBlock,
  DetailsBlockWithTitle,
  InfoBlock,
} from 'components/atoms/Details'

import SanctionedCorporateReasons from './components/SanctionedCorporateReasons'
import ErrorNotify from './components/ErrorNotify'
import { StyledViewReasonsButton } from './styles'

interface SanctionedCorporateState extends SanctionedCorporate {
  isLoading: boolean
  isUpdating: boolean
}

interface CorporateSanctionResultProps {
  accountId: number | string
  sanctionedCorporateInformation: SanctionedCorporateState
  showErrorIcon?: boolean
  isDetailsPage?: boolean
  acknowledgeCorporateSanction: (
    a: AcknowledgeCorporateSanctionParams,
  ) => Promise<void>
}

const SanctionedCorporateInformation: FC<CorporateSanctionResultProps> = (
  props,
) => {
  const {
    accountId,
    sanctionedCorporateInformation,
    showErrorIcon,
    isDetailsPage,
  } = props
  if (!sanctionedCorporateInformation) return null
  const {
    checkButtonText,
    detailsBlockSanctionedCorporateInformation,
    foundLegalEntities,
    foundNaturalEntities,
    showCheckForm,
    showReasons,
    isCorporateChecked,
    showSanctionResultsOnCorporatePage,
    showSanctionResultsOnDetailsPage,
    reasonText,
    uncheckCorporateSanction,
    handleProceedSanctionCheck,
    toggleShowCheckForm,
    toggleShowReasons,
  } = useCorporateSanctionResult({
    sanctionedCorporateInformation,
    acknowledgeCorporateSanction: props.acknowledgeCorporateSanction,
  })

  if (sanctionedCorporateInformation.isLoading) {
    return <LoadingBlock message="Fetching Corporate Sanction Information" />
  }
  return (
    <>
      {showSanctionResultsOnCorporatePage ||
      (isDetailsPage && showSanctionResultsOnDetailsPage) ? (
        <>
          <ErrorNotify
            text="Sanction Results Found"
            showErrorIcon={showErrorIcon}
          />
          <DetailsBlockWithTitle title="Corporate Sanction Results">
            <ContentContainer bgcolor={'0px solid transparent'}>
              <Title type={'h3'} text="Corporate Details" />
              <DetailsBlock>
                <>
                  {detailsBlockSanctionedCorporateInformation.map(
                    ({ label, value }) => (
                      <InfoBlock
                        key={label}
                        label={label}
                        value={value || '--'}
                      />
                    ),
                  )}
                  <InfoBlock label={'Description'} value={reasonText} />{' '}
                </>
              </DetailsBlock>

              <StyledViewReasonsButton>
                <IconButton color="primary" onClick={toggleShowReasons}>
                  {showReasons ? (
                    <CancelOutlined fontSize="large" />
                  ) : (
                    <InfoIcon fontSize="large" />
                  )}
                </IconButton>
                <Title
                  text={`${showReasons ? 'Hide' : 'View'} Reasons`}
                  type="h4"
                />
              </StyledViewReasonsButton>

              {showReasons && (
                <SanctionedCorporateReasons
                  corporateNameMatchResult={
                    sanctionedCorporateInformation.reason.corporateNameMatch
                  }
                  corporateMatchedToList={
                    sanctionedCorporateInformation.reason
                      .matchedToSanctionsItems
                  }
                  foundNaturalEntities={foundNaturalEntities}
                  foundLegalEntities={foundLegalEntities}
                />
              )}

              <Spacer margin="26px 0px" />

              <MuiButton
                onClick={
                  isCorporateChecked
                    ? () => uncheckCorporateSanction()
                    : () => toggleShowCheckForm()
                }
              >
                {checkButtonText}
              </MuiButton>
            </ContentContainer>
          </DetailsBlockWithTitle>

          <BasicModal
            open={showCheckForm}
            handleClose={toggleShowCheckForm}
            buttonsHidden
          >
            <SanctionsCheckForm
              accountId={accountId}
              name={sanctionedCorporateInformation.name}
              onCancel={toggleShowCheckForm}
              onSubmit={handleProceedSanctionCheck}
              form="sanctionsCheckForm"
            />
          </BasicModal>
        </>
      ) : (
        <DetailsBlockWithTitle title="Corporate Sanction Results">
          <div style={{ flexDirection: 'row' }}>
            <h3>No corporate sanction results info</h3>
            {sanctionedCorporateInformation &&
              sanctionedCorporateInformation.id !== '' && (
                <MuiButton
                  onClick={
                    isCorporateChecked
                      ? () => uncheckCorporateSanction()
                      : () => toggleShowCheckForm()
                  }
                >
                  {checkButtonText}
                </MuiButton>
              )}
          </div>
        </DetailsBlockWithTitle>
      )}
    </>
  )
}

const actions = {
  acknowledgeCorporateSanction,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    sanctionedCorporateInformation: state.sanctionedCorporate,
  })),
  actions,
  SanctionedCorporateInformation,
)
