import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableHead } from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { match } from 'interfaces'

import { connectStateAndDispatch, mapStateToProps } from 'store'
import ConditionalOrderRow from './ConditionalOrderRow'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { fetchAccountConditionalOrders } from 'redux/conditionalOrders/actions'
import { useAccountConditionalOrders } from 'containers/Hooks/AccountCondtionalOrders/useAccountConditionalOrders'
import { ConditionalOrder } from 'interfaces/conditionalOrders'


interface IAccountConditionalOrders {
  fetchAccountConditionalOrders: (accountId: string) => Promise<void>
  match: match<{ accountId: string }>
  conditionalOrders: {
    fetchingAccountConditionalOrders:  'pristine' | 'pending' | 'done'
    data: ConditionalOrder[]
  }
}
const AccountConditionalOrders: FC<IAccountConditionalOrders> = (props) => {

  const {
    expandedItem,
    setExpandedItem,
  } = useAccountConditionalOrders(
    props.fetchAccountConditionalOrders,
    props.match.params.accountId,
  )

  const handleOrderClick = (positionId: string) => {setExpandedItem((prev) => (prev === positionId ? '' : positionId))}

  if (props.conditionalOrders.fetchingAccountConditionalOrders === 'pending')
    return <LoadingBlock message="Fetching account conditional orders..." />
  if (props.conditionalOrders.data.length === 0)
    return <TablePlaceholder>No conditional orders found.</TablePlaceholder>
  return (
    <Table>
      <TableHead>
        <TableCell>Account Id </TableCell>
        <TableCell>Currency Pair</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Trigger Order Side</TableCell>
        <TableCell>Trigger Type</TableCell>
        <TableCell>Trigger Order Type</TableCell>
        <TableCell>Order Id</TableCell>
        <TableCell>Updated At</TableCell>
      </TableHead>
      <TableBody>
        {props.conditionalOrders.data.map((o) => (
          <ConditionalOrderRow
            key={o.orderId}
            expandedItem={expandedItem}
            handleOrderClick={handleOrderClick}
            conditionalOrder={o}
            
          />
        ))}
      </TableBody>
    </Table>
  )
}

const actions = {
  fetchAccountConditionalOrders,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    conditionalOrders:state.conditionalOrders.accountConditionalOrders,
  })),
  actions,
  AccountConditionalOrders,
)
