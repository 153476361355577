import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  InputGroup,
  FormError,
  RawDataInput,
} from 'components/atoms/Form'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth'
import { requiredDropdownValue } from 'forms/fieldValidators'
import { BASE } from 'theme/base-units'
import { renderInput, renderSwitch } from '../fields'
import { submit } from './submit'
import Button from '@material-ui/core/Button';
import AlertDialog from '../AddCurrencyPair/confirmation'
let Form = props => {
  const {
    submitting,
    error,
    invalid,
    rawData,
    loading = false,
    pristine,
  } = props
  const { handleSubmit, onCancel, onSubmit, valid } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Edit Currency Pair</FormTitle>
      <InputGroup>
        <Field name={'currencyPairSymbol'} disabled component={renderInput} label="Currency Pair Symbol" margin={`0 ${BASE * 2}px 0 0`} fullWidth />
        <Field name={'minQuote'} component={renderInput} label="Min Quote" fullWidth />
      </InputGroup>
      <InputGroup>
        <Field name={'minBase'} component={renderInput} label="Min Base" margin={`0 ${BASE * 2}px 0 0`} fullWidth />
        <Field name={'maxQuote'} component={renderInput} label="Max Quote" fullWidth />
      </InputGroup>
      <InputGroup>
        <Field name={'maxBase'} component={renderInput} label="Max Base" margin={`0 ${BASE * 2}px 0 0`} fullWidth />
        <Field name={'tickSize'} component={renderInput} label="Tick Size" fullWidth />
      </InputGroup>
      <InputGroup width={'50.5%'}>
        <Field name={'baseDecimalPlaces'} component={renderInput} label="Base Decimal Places" margin={`0 ${BASE * 2}px 0 0`} fullWidth />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <AlertDialog onSubmit={handleSubmit(onSubmit)} values={!valid || pristine || submitting} />
        <Button variant="contained" color="secondary" style={{ width: '80px' }} onClick={onCancel} >
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)
export default ReduxForm

