import { StyleSheet } from '@react-pdf/renderer'
import { BLACK, BLUE, WHITE } from 'theme/colors'

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: WHITE,
    display: 'flex',
    fontSize: 14,
    padding: 40,
  },
  headerMain: {
    backgroundColor: BLUE,
    textAlign: 'center',
    color: WHITE,
    padding: 20,
    marginBottom: 20,
  },
  header: {
    backgroundColor: BLUE,
    textAlign: 'center',
    color: WHITE,
    padding: 20,
    borderBottom: '1px solid black',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  approvalsTableCellHeading: {
    border: '1px solid black',
    padding: 12,
    fontWeight: 'bold',
    color: BLACK,
    flex: 1,
    textAlign: 'center',
  },
  approvalsTableCell: {
    border: '1px solid black',
    padding: 12,
    color: BLACK,
    flex: 1,
    textAlign: 'center',
  },
  cellHeading: {
    width: '30%',
    border: '1px solid black',
    padding: 12,
    fontWeight: 'bold',
    color: BLACK,
  },
  cell: {
    border: '1px solid black',
    padding: 12,
    color: BLACK,
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  recommendation: {
    border: '1px solid black',
    padding: 20,
    color: BLACK,
  },
  recommendationText: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
  bullet: {
    height: '100%',
  },
  viewEddDocumentHistoryButton: {
    marginLeft: 180,
  },
})
