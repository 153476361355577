import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic/index'
import Marketing from 'containers/Accounts/AccountLists/Marketing/Marketing'
import { hasMarketingRole } from 'helpers/roleBasedAccess'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import { disconnectSocket } from 'redux/fiat/actions'
import socket from 'redux/socket/index'

const routes = ['/marketing']

const MarketingView = props => {
  const {
    location: { pathname },
  } = props
  const [currentPage, setCurrentPage] = useState(routes[0])
  const hasRights = hasMarketingRole()

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', disconnectSocket)
    socket.connect()
  }, [])

  useEffect(
    () => () => {
      disconnectSocket()
      window.removeEventListener('beforeunload', disconnectSocket)
    },
    [],
  )

  useEffect(
    () => {
      const route = routes.find(rt => pathname.includes(rt))
      if (route) {
        setCurrentPage(route)
      }
    },
    [pathname],
  )

  return (
    <>
      <h1>Marketing</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="standard"
      >
        {hasRights && (
          <NavigationTab
            value={routes[0]}
            to={routes[0]}
            component={ForwardedNavLink}
            label="Marketing"
          />
        )}
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          {hasRights && <Route path={routes[0]} component={Marketing} />}
        </Switch>
      </ContentContainer>
    </>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      disconnectSocket,
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(MarketingView)
