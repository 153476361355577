import React, { FC } from 'react'
import { FuturePosition } from 'interfaces/futures'
import { TableCell, TableRow } from '@material-ui/core'

import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'

interface FuturePositionProps {
  futurePosition: FuturePosition
  toggleSelectedRow: (value: number) => void
  isSelected:boolean,
}

const FuturePositionRow: FC<FuturePositionProps> = props => {
  const { futurePosition, toggleSelectedRow, isSelected } = props
  const {
    accountId,
    averageEntryPrice,
    createdAt,
    quantity,
    realisedPnl,
    sessionAverageEntryPrice,
    totalSessionValue,
    unrealisedPnl,
    updatedAt,
    id,
  } = futurePosition
  return (
    <>
      <TableRow onClick={() => toggleSelectedRow(id)} >
        <TableCell>{accountId}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{averageEntryPrice}</TableCell>
        <TableCell>{sessionAverageEntryPrice}</TableCell>
        <TableCell>{totalSessionValue}</TableCell>
        <TableCell>{realisedPnl}</TableCell>
        <TableCell>{unrealisedPnl}</TableCell>
        <TableCell>{new Date(updatedAt).toISOString()}</TableCell>
        <TableCell>{createdAt}</TableCell>
      </TableRow>
      {isSelected && <>
        <TableCell colSpan={9}>
          <Details>
            <DetailsBlockWithTitle title={'Raw Data'}>
              <RawData data={futurePosition} title={'Raw Data'} />
            </DetailsBlockWithTitle>
          </Details>
        </TableCell>
      </>
      }
    </>
  )
}

export default FuturePositionRow
