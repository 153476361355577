import React, { Component, Fragment } from 'react'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.changeInfo.id)
  }

  render() {
    const {
      changeInfo,
      changeInfo: {
        id,
        data: { accountId, count, reason },
        createdAt,
        active,
      },
      isSelected
    } = this.props
    const shading = isSelected ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'15%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'15%'}>{count}</TableCell>
          <TableCell width={'30%'}>{createdAt}</TableCell>
          <TableCell width={'30%'}>{reason}</TableCell>
          <TableCell width={'10%'}>
            {active &&
              active.userActive && (
                <div className="tooltip">
                  <i style={{ color: '#B9BBBD' }} className="fas fa-eye" />
                  <span className="tooltiptext">{active.activePersonName}</span>
                </div>
              )}
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              actionType: changeInfo.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'pending',
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            active={active}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.toggleForm}
            rawData={changeInfo}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'ALLOW_EXCHANGE_CONTROL_EVENT')
