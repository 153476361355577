import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submit = async (values) => {
    const accountId = values.id
    const reason = values.comment
    let res = null
    try {
        res = await getAxios().put('/enable/voiceotp', {
            accountId,
            reason
        })
        if (res.data.statusCode === 202) {
            toast('Voice OTP enabled for account', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
        } else {
            toast(`Failed to enable voice OTP for account. \n ${res.data.message}`, {
                type: toast.TYPE.ERROR,
                autoClose: 2000,
            })
        }
    } catch (error) {
        toast(`Failed code 500. \n ${error}`, {
            type: toast.TYPE.ERROR,
            autoClose: 2000,
        })
    }
}
