import React, { Component, Fragment } from 'react'
import ExpandingItem from 'components/atoms/ExpandingItem'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import InfiniteScroll from 'react-infinite-scroll-component'
import LogEventRow from './logEventRow'

class LogsMainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  mapDataToTbl = logsList => {
    if (logsList && logsList.length) {
      return logsList.map((log, i) => <LogEventRow key={i++} log={log} />)
    }
    return null
  }

  render() {
    const {
      title,
      data,
      status,
    } = this.props
    const { expanded } = this.state

    return (
      <ExpandingItem
        title={title}
        itemsAmount={data && data.length || 0}
        expanded={expanded}
        onExpand={this.toggleView}
        opensMultiple

        expandedView={(
          status === 'done' && (
            <Fragment>
              <Paper >
                <TableContainer>
                  <InfiniteScroll
                    dataLength={data && data.length}
                    useWindow={false}
                    height={500}
                  >
                    <Table
                      stickyHeader={true}
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width={'8%'}>AccountId</TableCell>
                          <TableCell width={'20%'}>Log Event</TableCell>
                          <TableCell width={'60%'}>Log Data</TableCell>
                          <TableCell width={'12%'}>Timestamp</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{this.mapDataToTbl(data)}</TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Paper>
            </Fragment>
          )
        )
        }
      />
    )
  }
}

export default LogsMainContainer
