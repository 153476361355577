import {
  FETCH_CREDIT_CARD_DEPOSITS_BY_ID_SUCCESS,
  FETCH_CREDIT_CARD_DEPOSITS_BY_ID_ERROR,
  FETCH_CREDIT_CARD_DEPOSITS_BY_ID_PROCESS,
  CLEAR_CREDIT_CARD_DEPOSITS,
} from './actions'

const initialState = {
  byId: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREDIT_CARD_DEPOSITS_BY_ID_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'loading',
            data: state.byId[id] ? state.byId[id].data : [],
          },
        },
      }
    }

    case FETCH_CREDIT_CARD_DEPOSITS_BY_ID_SUCCESS: {
      const { id, data, skip, limit } = action.payload
      const existingData =
        state.byId[id] && state.byId[id].data ? state.byId[id].data : []
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'done',
            data: existingData.concat(data),
            skip,
            limit,
          },
        },
      }
    }
    case FETCH_CREDIT_CARD_DEPOSITS_BY_ID_ERROR: {
      const { id } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'error',
            data: [],
          },
        },
      }
    }

    case CLEAR_CREDIT_CARD_DEPOSITS: {
      return initialState
    }
    default:
      return state
  }
}
