import {
    FETCH_MARKETING_CONTACT_LIST_RUNS_PROCESS,
    FETCH_MARKETING_CONTACT_LIST_RUNS_SUCCESS,
    FETCH_MARKETING_CONTACT_LIST_RUNS_ERROR,
  } from './actions'
  
  const initialState = {
    marketingContactListRunResults: {},
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case FETCH_MARKETING_CONTACT_LIST_RUNS_PROCESS: {
        return {
          ...state,
          marketingContactListRunResults: {
            data: state.marketingContactListRunResults.data || [],
            status: 'loading',
          },
        }
      }
      case FETCH_MARKETING_CONTACT_LIST_RUNS_SUCCESS: {
        const { data } = action.payload
        return {
          ...state,
          marketingContactListRunResults: {
            data,
            status: 'done',
          },
        }
      }
  
      case FETCH_MARKETING_CONTACT_LIST_RUNS_ERROR: {
        const { error } = action.payload
        return {
          ...state,
          marketingContactListRunResults: {
            error,
            status: 'error',
          },
        }
      }
  
      default:
        return state
    }
  }
  