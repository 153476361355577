import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import SimpleModal from 'components/organisms/Modal'
import { renderDatetime } from 'forms/fields'
import { TransparentFormContainer } from 'components/atoms/Form/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { downloadTransactionStatements } from 'redux/transactions/actions'
import { Flexbox } from 'components/layout'
import {
  getAvailableTransactions,
  getFromAndToDates,
  getTransactionDownloadStatus,
} from 'redux/selectors/transactions'
import { Spacer } from 'components/atoms/Generic'
import { getAccountNameAndSurname } from 'redux/selectors/details'
import { autoDownloadFile } from 'helpers/autoDownloadFile'
import { pdf } from '@react-pdf/renderer'
import TransactionStatement from './TransactionStatement'
import { formatNumber } from 'helpers/utils'
import ErrorToast from 'components/molecules/ErrorToast'
import { DownloadTransactionStatementsArgs, DownloadTransactionsFormProps, DownloadTransactionsFormStateProps } from 'interfaces/downloadStatements'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'


let DownloadTransactionsForm = (props: DownloadTransactionsFormProps) => {
  const {
    downloadStatus,
    from,
    to,
    handleSubmit,
    modalOpen,
    toggleExportModal,
  } = props

  return (
    <SimpleModal
      title="Download Transaction Statement"
      handleClose={toggleExportModal}
      open={modalOpen}
      buttonsHidden
    >
      <TransparentFormContainer
        //   @ts-ignore
        width="100%"
        direction="row"
        align="center"
        onSubmit={handleSubmit}
      >
        <Flexbox justify="space-between">
          <Field
            label={'Start time'}
            name={'from'}
            margin={`0 ${BASE * 2}px 0 0`}
            width={'40%'}
            flex={0.4}
            marginRight={BASE * 2}
            component={renderDatetime}
            placeholder={'Date & Time'}
          />
          <Field
            label={'End time'}
            name={'to'}
            margin={`0 ${BASE * 2}px 0 0`}
            width={'40%'}
            flex={0.4}
            marginRight={BASE * 2}
            component={renderDatetime}
            placeholder={'Date & Time'}
          />
          <MuiButton
            type="submit"
            disabled={downloadStatus === 'loading' || !from || !to}
          >
            { downloadStatus === 'loading' ? 'Loading Please Wait...' : 'Download' }
          </MuiButton>
          <Spacer margin="0 4px" />
        </Flexbox>
      </TransparentFormContainer>
    </SimpleModal>
  )
}

DownloadTransactionsForm = reduxForm({
  form: 'DownloadTransactionsForm',
  onSubmit: async (values, dispatch, props) => {
    const { from, to } = values
    const { accountId, customerDetails, availableTransactions, modalOpen, toggleExportModal } = props
    
    const toastId = toast("Processing transaction statement...", {type: toast.TYPE.DEFAULT, autoClose: false});
    try {
      let transactions = []

      if (
        availableTransactions.length < 100
      ) {
        transactions = availableTransactions.map(tx => ({
            transactionDate: tx.eventAt,
            transactionType: tx.transactionType.description,
            debitCurrency: tx.debitCurrency?.shortName,
            debitValue: formatNumber(tx.debitValue, 12),
            creditCurrency: tx.creditCurrency?.shortName,
            creditValue: formatNumber(tx.creditValue, 12),
            feeCurrency: tx.feeCurrency?.shortName,
            feeValue: formatNumber(tx.feeValue, 12),
          }))
      } else {
        const response = await dispatch(downloadTransactionStatements({
            accountId,
            from,
            to,
          } as DownloadTransactionStatementsArgs))

        transactions = response
          ? response.map(tx => ({
              transactionDate: tx.eventAt,
              transactionType: tx.transactionType,
              debitCurrency: tx.debitCurrency,
              debitValue: formatNumber(tx.debitValue, 14),
              creditCurrency: tx.creditCurrency,
              creditValue: formatNumber(tx.creditValue, 14),
              feeCurrency: tx.feeCurrency,
              feeValue: formatNumber(tx.feeValue, 14),
            }))
          : []
      }

      const PdfDoc = (
        <TransactionStatement
          payload={transactions}
          customerDetails={customerDetails}
          from={from}
          to={to}
        />
      )
      const blob = await pdf(PdfDoc).toBlob()
      toast.dismiss(toastId)
      if (modalOpen) {
        toggleExportModal()
      }
      autoDownloadFile(blob, 'Transaction-Statement')
    } catch (error) {
      toastId && toast.dismiss(toastId);
      if (modalOpen) {
        toggleExportModal()
      }
      ErrorToast(error)
    }
  },
  validate: values => {
    const errors: { _error?: string } = {}
    if (!values.from || !values.to) {
      errors._error = 'Both dates must be specified'
    }
    return errors
  },
})(DownloadTransactionsForm)

const mapStateToProps = (state, ownProps): DownloadTransactionsFormStateProps => {
  const downloadStatus = getTransactionDownloadStatus(state)(ownProps.accountId)
  const { from, to } = getFromAndToDates(state)
  return {
    downloadStatus,
    from,
    to,
    customerDetails: getAccountNameAndSurname(state),
    availableTransactions: getAvailableTransactions(state)(ownProps.accountId, state),
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators(
    {
      downloadTransactionStatements,
    },
    dispatch,
  )

const ReduxDownloadTransactionsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadTransactionsForm)

export default ReduxDownloadTransactionsForm
