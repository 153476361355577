import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { Dispatch } from 'redux'
import { WithdrawalRestrictionsProps } from 'containers/Accounts/SingleAccount/WithdrawalRestrictions/interface'

export type WithdrawalRestrictionsAction =
  | 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_START'
  | 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_SUCCESS'
  | 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_ERROR'
  | 'CLEAR_ACCOUNT_WITHDRAWAL_RESTRICTIONS'

interface FetchAccountWithdrawalRestrictionsDispatch {
  type: WithdrawalRestrictionsAction
  payload?: { data: WithdrawalRestrictionsProps[] }
}

export const fetchAccountRestrictions =
  (accountId: string) =>
  async (dispatch: Dispatch<FetchAccountWithdrawalRestrictionsDispatch>) => {
    try {
      dispatch({
        type: 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_START',
      })
      const { data } = await getAxios().get(
        `/account-withdrawal-restrictions/${accountId}`,
      )
      dispatch({
        type: 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_ERROR',
      })
    }
  }

export const clearAccountRestrictions =
  () =>
  async (dispatch: Dispatch<FetchAccountWithdrawalRestrictionsDispatch>) => {
    dispatch({
      type: 'CLEAR_ACCOUNT_WITHDRAWAL_RESTRICTIONS',
    })
  }
