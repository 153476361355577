import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'

export const Headers = (props) => {
  const { expandItem } = props
  return (
    <TableHead onClick={expandItem}>
      <TableRow>
        <TableCell>Id</TableCell>
        <TableCell>Currency</TableCell>
        <TableCell>Receive Address</TableCell>
        <TableCell >Transaction Hash</TableCell>
        <TableCell >Amount</TableCell>
        <TableCell >Confirmations</TableCell>
        <TableCell >Confirmed</TableCell>
        <TableCell >Confirmed At</TableCell>
        <TableCell >index In Tx</TableCell>
        <TableCell >Payment Reference</TableCell>
        <TableCell >Date</TableCell>
        <TableCell>Tag</TableCell>
      </TableRow>
    </TableHead>
  )
}
