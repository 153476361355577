import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { BASE } from 'theme/base-units'

import {
  Button,
  FormContainer,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { isValidEmail } from 'forms/fieldValidators'

import { submit } from './submit'

import { renderInput } from '../fields'
import { fetchKeycloakUserByEmail } from '../../redux/keycloak/actions'

const keycloakUser = props => {
  const { submitting, invalid, handleSubmit, onSubmit } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Get Keycloak User</FormTitle>
      <InputGroup>
        <Field
          name={'email'}
          component={renderInput}
          label="Email"
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[isValidEmail]}
          fullWidth
        />
        <div style={{ width: '100%' }}>
          <Button type="submit" main="true" disabled={submitting || invalid}>
            Filter
          </Button>
        </div>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxFormGetKeycloakUser = reduxForm({
  form: 'getKeycloakUser',
  onSubmit: submit,
  onSubmitSuccess: (result, dispatch, props) => {},
  validate: values => {
    const errors = {}
    if (!values.email) {
      errors._error = 'Email must be specified'
    }
    return errors
  },
})(keycloakUser)

const mapStateToProps = state => ({
  user: state.user,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchKeycloakUserByEmail,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduxFormGetKeycloakUser)
