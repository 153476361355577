import React from 'react'
import { List } from '@material-ui/core'
import { hasCompetitionRole } from 'helpers/roleBasedAccess'

import config from 'config'

import { NavItem } from './interfaces'
import { NestedNavMenuItem } from './NestedNavMenuItem'

import { Collapse } from '../styles'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/funds/individual',
    listItemText: 'Individual',
    selectedPathName: '/funds/individual',
    routeAccess: 'fund',
  },
  {
    route: '/funds/from-fiat-csv',
    listItemText: 'From Fiat Csv',
    selectedPathName: '/funds/from-fiat-csv',
    routeAccess: 'fund',
  },
  {
    route: '/funds/internal-transfer',
    listItemText: 'Internal Transfer',
    selectedPathName: '/funds/internal-transfer',
    routeAccess: 'fund',
  },
  {
    route: '/funds/pending-internal-transfer',
    listItemText: 'Pending Internal Transfers',
    selectedPathName: '/funds/pending-internal-transfer',
    routeAccess: 'fund',
  },
]

const NestedFundingNavigationItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) => {
  const hasCompetitionRights = hasCompetitionRole()

  return pathname.includes('/funds') ? (
    <Collapse
      in={pathname.includes(selectedPathName)}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        <>
          {nestedNavigationItems
            .filter(item => {
              if (item.route === nestedNavigationItems[0].route) {
                if (config.isProduction) {
                  return false
                }
              }
              return true
            })
            .map((nestedNavItem, index) => (
              <NestedNavMenuItem
                key={index}
                handleListItemClick={handleListItemClick}
                navItemData={nestedNavItem}
                pathname={pathname}
              />
            ))}
        </>
        {hasCompetitionRights && (
          <NestedNavMenuItem
            pathname={pathname}
            handleListItemClick={handleListItemClick}
            navItemData={{
              listItemText: 'Batch Transfers',
              route: '/funds/batch-transfers',
              routeAccess: 'fund',
              selectedPathName: '/funds/batch-transfers',
            }}
          />
        )}
      </List>
    </Collapse>
  ) : null
}

export default NestedFundingNavigationItems
