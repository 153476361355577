import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ErrorBoundary from 'helpers/ErrorBoundary'

import { getTradeFees, setTradeFees } from 'redux/tradeFees/actions'
import FormUpdateTradeFees from 'forms/UpdateTradeFees'
import LoadingBlock from 'components/molecules/LoadingBlock'
import useTradeFees from 'Hooks/TradeFees/useTradeFees'

import DefaultTradeFeesDetails from './DefaultTradeFeesDetails'
import FeesPerCurrencyPair from './FeesPerCurrencyPair/index'
import AddNewFeesPerCurrencyPair from './AddNewFeesPerCurrencyPair/index'

const TradeFees = ({ tradeFees, getTradeFees }) => {
  const {
    defaultTradeFees,
    feesPerCurrencyPair,
    formShown,
    formData,
    isDefaultTradeFeesForm,
    loading,
    toggleForm,
  } = useTradeFees(tradeFees, getTradeFees)

  return (
    <ErrorBoundary message="Account Trade Fees">
      {loading ? (
        <LoadingBlock padding={0} bgColor={'transparent'} />
      ) : (
        <>
          {!formShown ? (
            <>
              <DefaultTradeFeesDetails
                tradeFees={defaultTradeFees}
                getTradeFees={getTradeFees}
                formShown={formShown}
                toggleForm={toggleForm}
              />
              <FeesPerCurrencyPair
                getTradeFees={getTradeFees}
                toggleForm={toggleForm}
                feesPerCurrencyPair={feesPerCurrencyPair}
              />
              <AddNewFeesPerCurrencyPair />
            </>
          ) : (
            <>
              <FormUpdateTradeFees
                title={'Update Default Trade Fees'}
                initialValues={{
                  ...formData,
                  confirmation: false,
                  isDefaultTradeFeesForm,
                }}
                question="Do you want to update fees"
                onCancel={toggleForm}
                isDefaultTradeFeesForm={isDefaultTradeFeesForm}
                onSubmitSuccess={() => {
                  toggleForm()
                }}
              />
            </>
          )}
        </>
      )}
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  tradeFees: state.tradeFees,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTradeFees,
      setTradeFees,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(TradeFees)
