import {
  FETCH_KEYCLOAK_USER_PROCESS,
  FETCH_KEYCLOAK_USER_SUCCESS,
  FETCH_KEYCLOAK_USER_ERROR,
  FETCH_KEYCLOAK_USER_BY_EMAIL_PROCESS,
  FETCH_KEYCLOAK_USER_BY_EMAIL_SUCCESS,
  FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR,
  RESET_USER,
  UNLINK_KYC_SOCIAL_ACCOUNTS_START,
  UNLINK_KYC_SOCIAL_ACCOUNTS_SUCCESS,
  UNLINK_KYC_SOCIAL_ACCOUNTS_ERROR,
  CHECK_KYC_SOCIAL_DUAL_AUTHS_START,
  CHECK_KYC_SOCIAL_DUAL_AUTHS_SUCCESS,
  CHECK_KYC_SOCIAL_DUAL_AUTHS_ERROR,
  //FETCH_OTHER_KEYCLOAK_ACCOUNTS_PROCESS,
  //FETCH_OTHER_KEYCLOAK_ACCOUNTS_SUCCESS,
  //FETCH_OTHER_KEYCLOAK_ACCOUNTS_ERROR,
  //LINK_KEYCLOAK_ACCOUNTS_PROCESS,
  //LINK_KEYCLOAK_ACCOUNTS_SUCCESS,
  //LINK_KEYCLOAK_ACCOUNTS_ERROR,
  //UNLINK_KEYCLOAK_ACCOUNTS_PROCESS,
  //UNLINK_KEYCLOAK_ACCOUNTS_ERROR,
} from './actions'

const initialState = {
  byId: {},
  otherAccountsById: {},
  byEmail: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KEYCLOAK_USER_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'loading',
            isCheckingPendingKycDualAuths: true,
          },
        },
      }
    }

    case FETCH_KEYCLOAK_USER_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            info: data,
            status: 'done',
            error: null,
          },
        },
      }
    }

    case FETCH_KEYCLOAK_USER_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            error,
          },
        },
      }
    }

    case FETCH_KEYCLOAK_USER_BY_EMAIL_PROCESS: {
      return {
        ...state,
        byEmail: {
          ...state.byEmail,
          status: 'loading',
        },
      }
    }

    case FETCH_KEYCLOAK_USER_BY_EMAIL_SUCCESS: {
      return {
        ...state,
        byEmail: {
          ...state.byEmail,
          info: action.payload.data,
          status: 'done',
          error: null,
        },
      }
    }

    case FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        byEmail: {
          ...state.byEmail,
          info: null,
          status: 'error',
          error,
        },
      }
    }

    case UNLINK_KYC_SOCIAL_ACCOUNTS_START: {
      const id = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isUnlinkingAccount: true,
            hasPendingUnlinkRequest: false,
          },
        },
      }
    }

    case UNLINK_KYC_SOCIAL_ACCOUNTS_SUCCESS: {
      const id = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isUnlinkingAccount: false,
            hasPendingUnlinkRequest: true,
          },
        },
      }
    }

    case UNLINK_KYC_SOCIAL_ACCOUNTS_ERROR: {
      const id = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isUnlinkingAccount: false,
            hasPendingUnlinkRequest: false,
          },
        },
      }
    }

    case CHECK_KYC_SOCIAL_DUAL_AUTHS_START: {
      const id = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isCheckingPendingKycDualAuths: true,
            hasPendingUnlinkRequest: false,
          },
        },
      }
    }

    case CHECK_KYC_SOCIAL_DUAL_AUTHS_SUCCESS: {
      const { accountId, exists } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [accountId]: {
            ...state.byId[accountId],
            isCheckingPendingKycDualAuths: false,
            hasPendingUnlinkRequest: exists,
          },
        },
      }
    }

    case CHECK_KYC_SOCIAL_DUAL_AUTHS_ERROR: {
      const id = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            isCheckingPendingKycDualAuths: false,
            hasPendingUnlinkRequest: false,
          },
        },
      }
    }

    case RESET_USER: {
      return {
        ...state,
        byEmail: {
          ...initialState,
        },
      }
    }

    // case FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS: {
    //   const { id } = action.payload
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         status: 'loading'
    //       }
    //     }
    //   }
    // }

    // case FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS: {
    //   const { id, data } = action.payload
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         data,
    //         status: 'done',
    //         error: null
    //       }
    //     }
    //   }
    // }

    // case FETCH_OTHER_AUTHO_ACCOUNTS_ERROR: {
    //   const { id, error } = action.payload
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         status: 'error',
    //         error
    //       }
    //     }
    //   }
    // }

    // case LINK_AUTH0_ACCOUNTS_PROCESS: {
    //   const { id, index } = action.payload
    //   let data = state.otherAccountsById[id].data
    //   data[index]['status'] = 'loading'
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         ...state.otherAccountsById[id],
    //         data
    //       }
    //     }
    //   }
    // }

    // case LINK_AUTH0_ACCOUNTS_SUCCESS: {
    //   const { id, index } = action.payload
    //   let data = state.otherAccountsById[id].data
    //   delete data[index]
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         ...state.otherAccountsById[id],
    //         data
    //       }
    //     }
    //   }
    // }

    // case LINK_AUTH0_ACCOUNTS_ERROR: {
    //   const { id, index } = action.payload
    //   let data = state.otherAccountsById[id].data
    //   data[index]['status'] = 'done'
    //   return {
    //     ...state,
    //     otherAccountsById: {
    //       ...state.otherAccountsById,
    //       [id]: {
    //         ...state.otherAccountsById[id],
    //         data: data
    //       }
    //     }
    //   }
    // }

    // case UNLINK_AUTH0_ACCOUNTS_PROCESS: {
    //   const { id, index } = action.payload
    //   let info = state.byId[id].info
    //   info.identities[index]['status'] = 'loading'
    //   return {
    //     ...state,
    //     byId: {
    //       ...state.byId,
    //       [id]: {
    //         ...state.byId[id],
    //         info
    //       }
    //     }
    //   }
    // }

    // case UNLINK_AUTH0_ACCOUNTS_ERROR: {
    //   const { id, index } = action.payload
    //   let info = state.byId[id].info
    //   info.identities[index]['status'] = 'done'
    //   return {
    //     ...state,
    //     byId: {
    //       ...state.byId,
    //       [id]: {
    //         ...state.byId[id],
    //         info
    //       }
    //     }
    //   }
    // }

    default:
      return state
  }
}
