
import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = (props) => {
  const { expandItem } = props
  return (
    <TableHeader onClick={expandItem}>
      <TableCell width={'10%'}>Account Id</TableCell>
      <TableCell width={'10%'}>Currency</TableCell>
      <TableCell width={'10%'}>Amount</TableCell>
      <TableCell width={'40%'}>Created At</TableCell>
      <TableCell width={'40%'}>Reason</TableCell>
      <TableCell width={'10%'} />
    </TableHeader>
  )
}
