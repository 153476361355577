import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as moment from 'moment'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { InputGroup } from 'components/atoms/Form/index'
import {
  fetchPastCompetitions,
  fetchPastCompetitionsById,
} from 'redux/competitionDetails/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Flexbox } from 'components/layout/index'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { TablePlaceholder } from 'components/atoms/Table/styles'

function PastCompatitions(props) {
  const [showForm, setShowForm] = React.useState(false)
  const [open, setOpen] = React.useState(true)
  const [scroll, setScroll] = React.useState('paper')

  const handleClose = () => {
    setOpen(false)
  }

  const showCompetition = id => {
    setShowForm(true)
    setOpen(true)
    props.fetchPastCompetitionsById(id)
  }

  React.useEffect(() => {
    props.fetchPastCompetitions()
  }, [])

  const close = () => {
    setOpen(false)
    setShowForm(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(
    () => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef
        if (descriptionElement !== null) {
          descriptionElement.focus()
        }
      }
    },
    [open],
  )

  return (
    <Fragment>
      {props.competition.pastCompetitions.status !== 'done' && <LoadingBlock />}
      {props.competition.pastCompetitions.status === 'done' &&
        props.competition.pastCompetitions.data.length === 0 && (
        <TablePlaceholder>{'No past competitions'}</TablePlaceholder>
      )}
      {props.competition.pastCompetitions.status === 'done' && props.competition.pastCompetitions.data.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>List name</TableCell>
              <TableCell colSpan={2}>Sendgrid template id</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.competition.pastCompetitions.data.sort((a, b) => a.id < b.id).map(compatition => (
              <>
                <TableRow key={compatition.competitionId} style={{ border: '4px solid silver' }}>
                  <TableCell colSpan={2} style={{ padding: '10px' }}>
                    {`${compatition.competition_name} ${moment(compatition.created_at).format('YYYY-MMM-DD HH:mm:ss')}`}
                  </TableCell>
                  <TableCell colSpan={2} style={{ padding: '10px' }}>{compatition.template_id || 'N/A'}</TableCell>
                  <TableCell align="right" style={{ padding: '10px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showCompetition(compatition.id)}
                    >
                          Review
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '10px' }}/>
              </>
            ))}
          </TableBody>
        </Table>
      ) : ''}

      {showForm &&
        open && (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
              style: {
                margin: '40px auto 0',
                width: '95%',
                maxWidth: '800px',
              },
            }}
          >
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                  <>
                    {props.competition.pastWinnersById.status !==
                      'done' &&
                       <LoadingBlock />
                    }
                    {props.competition.pastWinnersById.status ===
                      'done' && (
                      <>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead align="right" style={{ minWidth: '170' }}>
                            <TableRow>
                              <TableCell>Counter</TableCell>
                              <TableCell>From Account Id</TableCell>
                              <TableCell>To Account Id</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Currency</TableCell>
                              <TableCell>Error</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {props.competition.pastWinnersById.status ===
                              'done' &&
                              props.competition.pastWinnersById.data &&
                              props.competition.pastWinnersById.data.filter(dat => dat.from_account_id).sort((a, b) => a.internalId - b.internalId).map(
                                (row, i) => (
                                  <TableRow key={i}>
                                    <TableCell>{i + 1}</TableCell>
                                    <TableCell>
                                      {row.from_account_id}
                                    </TableCell>
                                    <TableCell>{row.to_account_id}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.currency}</TableCell>
                                    <TableCell>{row.error || 'N/A'}</TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </>
                    )}
                  </>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <InputGroup justify={'flex-end'} style={{ marginTop: '2%' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '80px' }}
                  onClick={() => close()}
                >
                    Close
                </Button>
              </InputGroup>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  competition: state.competition,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPastCompetitionsById,
      fetchPastCompetitions,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastCompatitions)
