export const getIdsFromCsv = (
  userIdsString: string,
): { message: string; accountIds: string[]; error: string } => {
  const csvInfo = {
    message: '',
    accountIds: [],
    error: '',
  } as { message: string; accountIds: string[]; error: string }

  if (userIdsString.length === 0) {
    return csvInfo
  }

  const lines = userIdsString.split('\n')
  const header = lines[0].split(',')
  let accountIdIndex = -1
  if (header.length === 1 && header[0] === 'account_id\r') {
    accountIdIndex = 0
  } else {
    accountIdIndex = header.indexOf('account_id')
  }

  const accountIdsToCheck: string[] = []
  const accountIds: Set<string> = new Set()

  for (let i = 1; i < lines.length; i++) {
    const values: any = lines[i].split(',')
    if (accountIdIndex >= 0 && accountIdIndex < values.length) {
      if (values[accountIdIndex].trim()) {
        accountIdsToCheck.push(values[accountIdIndex])
        accountIds.add(values[accountIdIndex])
      }
    }
  }

  const error = accountIdsToCheck.reduce((acc, curr, i) => {
    if (Number(curr)) {
      return acc
    }
    acc = `CSV fails on row ${i + 1} with value '${curr}'`
    return acc
  }, '')

  if (error) {
    csvInfo.message = error
    csvInfo.error = error
    return csvInfo
  }
  if (accountIds.size === 0) {
    csvInfo.message = 'No account ids found in provided csv.'
    csvInfo.error = 'No account ids found in provided csv.'
    return csvInfo
  }
  return {
    message: 'Valid Csv',
    accountIds: [...accountIds],
    error: '',
  }
}
