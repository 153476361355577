import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  Button,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderTextArea } from 'forms/fields'
import { required, maxLength256 } from 'forms/fieldValidators'
import { Flexbox } from 'components/layout/index'
import { submit } from './submit'

let Form = props => {
  const { submitting, invalid } = props
  const {
    handleSubmit, onCancel, onSubmit,
  } = props

  return <FormContainer onSubmit={ handleSubmit((values, dispatch) =>
    onSubmit(values, dispatch)) }>
    <FormTitle>Add Notes</FormTitle>
    <Flexbox
      align={'flex-start'}
      justify={'space-between'}
      direction={'row'}
    >
      <Field
        marginTop={26}
        label={'notes'}
        name={'notes'
        } component={renderTextArea}
        validate={[required, maxLength256]}
        rows={1} />
      <div style={{ width: '286px', marginTop: '24px' }}>
        <Button type="submit" disabled={submitting || invalid} style={{ marginLeft: '45px' }} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button type="button" onClick={onCancel} color='secondary'>
          Clear
        </Button>
      </div>
    </Flexbox>

  </FormContainer>
}

Form = reduxForm({
  form: 'unallocatedReceivesNotesForm',
  onSubmit: submit,
  onSubmitSuccess: (values, dispatch, props) => props.toggleForm(),
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
