import { partition } from 'lodash'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'

import getAxios from '../../helpers/axios'

export const calculateChanges = applicationState =>
  applicationState
    .filter(applicationChange => applicationChange.configChanged)
    .map(({ value, type, envName }) => {
      let tempValue = value
      if (
        [
          'MAPPED_ACCOUNT_TYPE_BIG_DECIMAL',
          'MAPPED_STRING_TO_STRING',
          'MAPPED_CURRENCY_BIG_DECIMAL',
          'MAPPED_CURRENCY_PAIR_BIG_DECIMAL',
          'MAPPED_INT_BIG_DECIMAL',
        ].includes(type)
      ) {
        const depositReview = value.map(review => {
          let reviewData = Object.entries(review)
            .flat()
            .join(':')

          if (type === 'MAPPED_CURRENCY_PAIR_BIG_DECIMAL') {
            reviewData = reviewData.replace(/\([^)]*\)/g, '')
          }

          return reviewData
        })
        tempValue = depositReview.toString()
      } else if (type === 'LIST') {
        tempValue = value.join(',')
      }

      return { envName, value: tempValue }
    })

export const saveConfig = async (changes, system) => {
  if (changes?.length) {
    const results = await Promise.all(
      changes.map(async ({ envName, value }) => {
        try {
          const response = await getAxios().post(`/set/config/${system}`, {
            values: { envName, value },
          })
          return [envName, response.status]
        } catch (e) {
          return [envName, e.response.status]
        }
      }),
    )

    const [successful, failed] = partition(
      results,
      ([, status]) => status === 202,
    )

    toast(
      `${successful.length} value${
        successful.length === 1 ? '' : 's'
      } successfully updated`,
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      },
    )

    failed.forEach(([envName]) => {
      toast(`Failed to update ${envName}`, {
        type: toast.TYPE.ERROR,
        autoClose: 4500,
      })
    })
  }
}

export const saveCurrencyConfig = async (changes, system, currency) =>
  saveConfig(changes, `${system}/${currency}`)

const splitStr = x => {
  const y = x.split(':')
  return { [y[0].trim()]: y[1].trim() }
}

const parseKeyValues = (input) => {
  return input.split(',').reduce((acc, curr) => {
    if (curr.includes(':') || acc.length === 0) {
      const parts = curr.split(':')
      return [...acc, { [parts[0]]: parts[1] }]
    }
    const last = acc.pop()
    const key = Object.keys(last)[0]
    return [...acc, { [key]: `${last[key]},${curr}` }]
  }, [])
}

const splitCurrencyStr = x => {
  try {
    const y = x.split(':')
    if (y.length !== 3) {
      return ErrorToast(
        `The config value for ${x.toString()} is missing the third element`,
      )
    }
    const symbol = y[1].replace(/[^a-zA-Z]/g, '')
    const finalSymbol = symbol === 'R' ? 'ZAR' : symbol
    return { [finalSymbol]: y[2].trim() }
  } catch (error) {
    ErrorToast(`Error splitting currency for Currency To Split: ${x} ${y}`)
    return ''
  }
}
export const getConfigValue = (type, value) => {
  try {
    switch (type) {
      case 'BOOLEAN':
        return JSON.parse(value)
      case 'LIST':
        if (!value) return []
        return value.split(',')
      case 'MAPPED_STRING_TO_STRING':
        if (!value) return []
        return parseKeyValues(value)
      case 'MAPPED_ACCOUNT_TYPE_BIG_DECIMAL':
      case 'MAPPED_CURRENCY_PAIR_BIG_DECIMAL':
      case 'MAPPED_INT_BIG_DECIMAL':
        if (!value) return []
        return value.split(',').map(splitStr)
      case 'MAPPED_CURRENCY_BIG_DECIMAL':
        if (!value) return []
        return value.split(',').map(splitCurrencyStr)
      case 'BIG_DECIMAL':
      case 'INT':
      case 'STRING':
        return value
      default:
        return value
    }
  } catch (error) {
    ErrorToast(
      `There seems to be an error in the config values for Type ${type} and Value ${value}`,
    )
    return []
  }
}

export const getChangedConfigValue = (type, value, oldValue, mappedKey) => {
  switch (type) {
    case 'BOOLEAN':
      return value
    case 'MAPPED_STRING_TO_STRING':
    case 'MAPPED_ACCOUNT_TYPE_BIG_DECIMAL':
    case 'MAPPED_CURRENCY_BIG_DECIMAL':
    case 'MAPPED_CURRENCY_PAIR_BIG_DECIMAL':
    case 'MAPPED_INT_BIG_DECIMAL':
      return oldValue.map(item => {
        const key = Object.keys(item)[0]
        if (key === mappedKey) {
          return { [key]: value }
        }
        return item
      })
    case 'BIG_DECIMAL':
    case 'INT':
    case 'STRING':
      return value
    case 'LIST':
      if (!value) return []
      return value.split(',')
    default:
      return value
  }
}
