import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Table,
  TableBody,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { releaseFiatDeposits } from 'redux/release-fiat'
import { isArraySame, renderPlural } from 'helpers/utils'
import ConfirmationForm from 'forms/ConfirmationForm/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { updateNewFiatDeposits } from 'redux/fiat/actions'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import UnresolvedFiatDepositRow from './row'
import { Dialog } from '../UnresolvedFiatDepositsInfo/Dialog'
import { ConfirmationDialog } from '../ConfirmationDialog/index'
import { Headers } from './headers'
import { addCurrencySymbol } from '../../../helpers/currency'

const CONFIRMATION_TYPES = {
  deposit: 'deposit',
  reject: 'reject',
  suspend: 'suspend',
  bulkDepositToggle: 'bulkDepositToggle',
  closeAccordion: 'closeAccordion',
  release: 'release',
}

class NewFiatDeposit extends Component {
  constructor(props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.toggleRow = this.toggleRow.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.onCommentChange = this.onCommentChange.bind(this)
    this.state = {
      expanded: false,
      selected: [],
      isSelectAll: false,
      comment: '',
      isCellHidden: true,
      singleRowComment: '',
      expandedRow: '',
      highlightedId: '',
      bulkActionsActive: false,
      selectedBulkDeposits: [],
      addNoteFormShown: '',
      expandRawData: '',
      confirmationDialog: {
        title: '',
        isOpen: false,
        message: '',
        type: '',
      },
    }
  }

  clearSelectedBulkDeposits = () => {
    this.setState({
      selectedBulkDeposits: [],
      selected: [],
    })
  }

  toggleAddNotesForm = id => {
    if (id !== this.state.addNoteFormShown) {
      this.setState({ addNoteFormShown: id })
    } else {
      this.setState({ addNoteFormShown: '' })
    }
  }

  showRawData = id => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleBulkActions = () => {
    this.setState({
      bulkActionsActive: !this.state.bulkActionsActive,
      isCellHidden: false,
    })
    if (this.state.bulkActionsActive) {
      this.clearSelectedBulkDeposits()
      this.setState({ isCellHidden: true })
    }
    this.closeDialog()
  }

  setBulkActionsActive = () => {
    const itemCount = this.state.selectedBulkDeposits.length
    const dialogMessage = `Discard current selected deposits? ${itemCount > 1 ? 'All' : ''
    } ${itemCount} selected item${renderPlural(itemCount)} will be deselected!`
    if (itemCount > 0) {
      const dialog = new Dialog(
        'Discard current selected deposits',
        dialogMessage,
        CONFIRMATION_TYPES.bulkDepositToggle,
      )
      return this.setState({
        confirmationDialog: dialog,
      })
    }
    this.toggleBulkActions()
  }

  getAllDepositIds(data) {
    const allDepositIds = []
    if (data !== undefined && data.length > 0) {
      data.forEach(deposit => {
        allDepositIds.push(deposit.depositId)
      })
    }
    return allDepositIds
  }

  toggleView(panel) {
    const itemCount = this.state.selectedBulkDeposits.length
    const dialogMessage = `Discard current selected deposits? ${itemCount > 1 ? 'All' : ''
    } ${itemCount} selected item${renderPlural(itemCount)} will be deselected!`
    if (itemCount > 0) {
      const dialog = new Dialog(
        'Discard current selected deposits',
        dialogMessage,
        CONFIRMATION_TYPES.closeAccordion,
      )

      return this.setState({
        confirmationDialog: dialog,
      })
    }
    this.setState({
      expanded: panel,
    })
    if (this.state.bulkActionsActive) this.toggleBulkActions()
  }

  closeDialog = () => {
    this.setState({
      confirmationDialog: {
        isOpen: false,
        message: '',
        type: '',
      },
    })
  }

  initiateBulkActionUpdate = (data, actionType) => {
    const bulkItemsCount = data.length
    let dialogMessageType = ''

    switch (actionType) {
      case CONFIRMATION_TYPES.release:
        dialogMessageType = 'release'
        break

      default:
        break
    }
    const dialogMessage = `You are about to ${dialogMessageType} ${bulkItemsCount} deposit${renderPlural(
      bulkItemsCount,
    )}. Would you like to proceed ?`

    const dialogTitle = `${actionType.slice(0, 1) +
      actionType.slice(1).toLowerCase()} Deposits`

    const dialog = new Dialog(
      dialogTitle,
      dialogMessage,
      CONFIRMATION_TYPES.release,
    )

    this.setState({
      confirmationDialog: dialog,
    })
  }

  toggleRow(id) {
    const { expandedRow } = this.state
    if (id !== expandedRow) {
      this.setState({ expandedRow: id })
    } else {
      this.setState({ expandedRow: '' })
    }
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  toggleSelectAll = allDepositIds => {
    const { selected } = this.state
    if (isArraySame(selected, allDepositIds)) {
      this.setState({ selected: [], selectedBulkDeposits: [] })
    } else {
      this.setState({
        selected: allDepositIds,
        selectedBulkDeposits: allDepositIds,
      })
    }
  }

  getAllSelectedDeposits = (data = [], title) => {
    const allDepositIds = []
    if (data.length > 0) {
      data.forEach(deposit => {
        allDepositIds.push({ depositId: deposit.depositId, title })
      })
    }
    return allDepositIds
  }

  onCommentChange(event) {
    if (this.state.selected.length === 1) {
      this.setState({ singleRowComment: event.target.value })
    } else if (this.state.selected.length > 1) {
      this.setState({ comment: event.target.value })
    }
  }

  handleProceedConfirmation(type) {
    const { expanded, bulkActionsActive } = this.state
    switch (type) {
      case CONFIRMATION_TYPES.bulkDepositToggle:
        return () => {
          this.toggleBulkActions()
        }
      case CONFIRMATION_TYPES.closeAccordion:
        return () => {
          if (expanded) {
            this.clearSelectedBulkDeposits()
            this.setState({
              expanded: !this.state.expanded,
            })
          }
          this.closeDialog()
          if (bulkActionsActive) this.toggleBulkActions()
        }
      default:
        return () => { }
    }
  }

  handleSelectedBulkDepositClick = deposit => {
    const updatedSelectedItems = [...this.state.selectedBulkDeposits]
    const indexOfExisitingItem = updatedSelectedItems.findIndex(
      depositToFind => depositToFind.depositId === deposit.depositId,
    )
    if (indexOfExisitingItem === -1) {
      updatedSelectedItems.push(deposit)
    } else {
      updatedSelectedItems.splice(indexOfExisitingItem, 1)
    }
    this.setState({
      selectedBulkDeposits: updatedSelectedItems,
    })
  }

  handleReleaseBulkDeposits = async data => {
    await this.props.releaseFiatDeposits(data)
    this.clearSelectedBulkDeposits()
    this.closeDialog()
  }

  render() {
    const {
      expanded,
      selected,
      confirmationDialog,
      selectedBulkDeposits,
      bulkActionsActive,
      isCellHidden,
    } = this.state

    const {
      name,
      newDeposits: { status, data },
      currency,
    } = this.props

    const getTotalAmount = () => {
      const total = data.map(d => d.amount).reduce((a, b) => a + b, 0)
      return `${addCurrencySymbol(total, currency)}`
    }

    const hasDeposits = data && data.length > 0

    return (
      <>
        <ExpandingItem
          title={`${name}`}
          titleType={`${name}`}
          itemsAmount={(data && data.length) || 0}
          totalAmount={`(${getTotalAmount() || 0})`}
          expanded={expanded}
          onExpand={this.toggleView}
          setBulkActionsActive={this.setBulkActionsActive}
          bulkActionsActive={bulkActionsActive}
          initiateBulkActionUpdate={this.initiateBulkActionUpdate}
          opensMultiple
          applyHeight={data.length > 0}
          selectedBulkDeposits={selectedBulkDeposits}
          expandedView={
            <>

              {status === 'loading' && <LoadingBlock />}
              {status === 'done' && !hasDeposits && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.DEPOSITS.type}
                />
              )}
              {status === 'done' && hasDeposits && (
                <>
                  <Table>
                    <Headers
                      isCellHidden={isCellHidden}
                      bulkActionsActive={this.state.bulkActionsActive}
                      getAllSelectedDeposits={this.getAllSelectedDeposits}
                      data={data}
                      toggleSelectAll={this.toggleSelectAll}
                      selected={this.state.selected}
                      title={name}
                    />
                    <TableBody>
                      {data
                        .sort(
                          (a, b) =>
                            new Date(b.insertedAt) - new Date(a.insertedAt),
                        )
                        .map(deposit => (
                          <UnresolvedFiatDepositRow
                            key={deposit.depositId}
                            deposit={deposit}
                            title={name}
                            isSelected={
                              this.state.selected.indexOf(
                                deposit.depositId,
                              ) >= 0
                            }
                            toggleRow={this.toggleRow}
                            isExpandedRow={
                              this.state.expandedRow === deposit.depositId
                            }
                            isCellHidden={this.state.isCellHidden}
                            onChange={this.onCommentChange}
                            singleRowComments={this.state.singleRowComment}
                            setHighlightedId={this.setHighlightedId}
                            isHighlighted={
                              this.state.highlightedId === deposit.depositId
                            }
                            selectedBulkDeposits={selectedBulkDeposits}
                            bulkActionsActive={this.state.bulkActionsActive}
                            handleSelectedBulkDepositClick={
                              this.handleSelectedBulkDepositClick
                            }
                            showRawData={this.showRawData}
                            toggleAddNotesForm={this.toggleAddNotesForm}
                            addNoteFormShown={this.state.addNoteFormShown}
                            expandRawData={this.state.expandRawData}
                            getAllSelectedDeposits={
                              this.getAllSelectedDeposits
                            }
                            toggleSelectAll={this.toggleSelectAll}
                            selected={selected}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </>
          }
        />
        <ConfirmationDialog
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          open={confirmationDialog.isOpen}
          proceed={this.handleProceedConfirmation(confirmationDialog.type)}
          handleClose={this.closeDialog}
          buttonsHidden={confirmationDialog.type === CONFIRMATION_TYPES.release}
          button={{
            confirm: {
              text: 'Proceed',
            },
            decline: {
              text: 'Cancel',
            },
          }}
        >
          {confirmationDialog.type === CONFIRMATION_TYPES.release && (
            <>
              <ConfirmationForm
                initialValues={{
                  id: confirmationDialog.type,
                  comment: '',
                  status: '',
                }}
                form={`ConfirmationForm-${confirmationDialog.type}`}
                onSubmit={formData =>
                  this.handleReleaseBulkDeposits({
                    formData,
                    selectedBulkDeposits,
                  })
                }
                onCancel={this.closeDialog}
              />
            </>
          )}
        </ConfirmationDialog>
      </>
    )
  }
}

const mapStateToProps = state => ({
  releaseFiat: state.releaseFiat,
  currency: state.fiat.filters.currency,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      releaseFiatDeposits,
      updateNewFiatDeposits,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewFiatDeposit)
