import React from 'react'
import BigNumber from 'bignumber.js'
import { pure } from 'recompose'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import FormAccountsComments from 'forms/AccountsComments'
import { GREEN_COLOR_PAIR, RED_COLOR_PAIR, VALR_BLUE_GRAY } from 'theme/colors'
import auth from 'services/Auth'
import * as moment from 'moment'
import RawData from 'components/atoms/RawDataDualAuth/index'

const getStatusColorPair = status => {
  if (status === 'PAYMENT_ALLOCATED') {
    return GREEN_COLOR_PAIR.positive
  }
  return RED_COLOR_PAIR.positive
}

//for V2
const renderAddNoteForm = (
  depositId,
  deposit,
  toggleAddNotesForm,
  updateComments,
  accountId,
) => {
  return (
    <FormAccountsComments
      initialValues={{
        depositId,
        data: {
          date: new Date(),
          createdBy: auth.decodeNameToken(),
          depositsId: depositId,
          accountId,
        },
      }}
      form={`depositCommentsForm+${depositId}`}
      depositData={deposit}
      onSubmitSuccess={(value, dispatch) => {
        updateComments(depositId)
        toggleAddNotesForm(depositId)
      }}
    />
  )
}

const mainView = props => {
  const { tx, setHighlightedId, isHighlighted } = props
  const shading = isHighlighted ? 'white' : ''
  const formattedAmount = new BigNumber(tx.amount).toFormat(2)
  const formattedFeeAmount = new BigNumber(tx.feeAmount).toFormat(2)
  const formattedCreditedAmount = new BigNumber(tx.creditedAmount).toFormat(2)
  const rowStyling = {
    color: getStatusColorPair(tx.status),
    fontWeight: 'bold',
  }
  return (
    <TableRow
      onClick={() => setHighlightedId(tx.identifier)}
      style={{
        backgroundColor: isHighlighted && VALR_BLUE_GRAY,
        color: shading,
      }}
    >
      <TableCell flex={1.5}>
        {formattedCreditedAmount !== 'NaN' ? formattedCreditedAmount : '--'}
      </TableCell>
      <TableCell flex={1.5}>{formattedAmount}</TableCell>
      <TableCell flex={1.5}>{formattedFeeAmount}</TableCell>
      <TableCell flex={1.5} style={rowStyling}>
        {tx.status}
      </TableCell>
      <TableCell flex={2.5}>{tx.identifier}</TableCell>
      <TableCell flex={0.8}>
        {moment(tx.createdAt).format('YYYY-MMM-DD HH:mm:ss')}
      </TableCell>
      <TableCell flex={0.2} align={'right'} color={'#e96868'}>
        {tx.tag && <i className="fa fa-sticky-note" />}
      </TableCell>
    </TableRow>
  )
}

const expandedView = props => {
  const { tx } = props
  tx.createdAt = moment(tx.createdAt).format('YYYY-MMM-DD HH:mm:ss')
  return (
    <TableRow className='no-hover' >
      <TableCell colSpan={7}>
        <Details>
          <DetailsBlockWithTitle title={'Raw Data'}>
            <RawData data={tx} title={'Raw Data'} />
          </DetailsBlockWithTitle>
        </Details>
      </TableCell>
    </TableRow >
  )
}

const SendsRow = props => {
  const { isHighlighted } = props
  return (
    <>
      {mainView(props)}
      {isHighlighted && expandedView(props)}
    </>
  )
}

export default pure(SendsRow)
