import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

const SET_BUSY = 'fund/SET_BUSY'
const FUNDED = 'fund/FUNDED'
const RESET = 'fund/RESET'

const initialState = { busy: false, funded: false }

export default (state = initialState, action) => {
  switch (action.type) {
    case FUNDED: {
      return { ...state, funded: true }
    }
    case RESET: {
      return { ...initialState }
    }
    case SET_BUSY: {
      return { ...state, busy: action.value }
    }
    default: {
      return state
    }
  }
}

export const fundedAction = () => {
  toast('Individual Funded Succesfully', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
  return { type: FUNDED }
}

export const clearFunded = () => dispatch => {
  dispatch({ type: RESET })
}

export const fundIndividual = (
  referenceNumber,
  currencySymbol,
  amount,
  transactionReference,
) => async dispatch => {
  const res = await getAxios().post('/fund/individual', {
    referenceNumber,
    currencySymbol: currencySymbol.toUpperCase(),
    amount: amount.toString(),
    transactionReference,
  })

  if (res.status === 201) {
    dispatch(fundedAction())
  }
}

export const fundFromFile = () => async () => {
  await getAxios().post('/fund/serverfile')
}
