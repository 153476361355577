import styled from 'styled-components'
import { BLUE, VALR_BLUE_GRAY } from 'theme/colors'

export const StyledFileUploaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`

export const StyledFileUploaderContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border: 1px dashed black;
  border-radius: 16px;
  :hover {
    cursor: pointer;
  }
  overflow: scroll;
  width: 100%;
  height: 100%;
`

export const StyledFileViewContainer = styled.div`
  text-align: right;
  > svg {
    position: absolute;
    z-index: 1;
    right: 4px;
    color: ${BLUE};
  }
`

export const StyledImageNumber = styled.div`
  text-align: left;
  border-radius: 33px;
  width: 10px;
  height: 20px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${VALR_BLUE_GRAY};
  margin: 8px;
`
