import React from 'react'
import Leverage from 'containers/Leverage'
import DualAuth from 'components/organisms/DualAuth'
import Kyc from 'containers/Kyc'

import Accounts from 'containers/Accounts'
import Exchange from 'containers/Exchange'
import Fund from 'containers/Fund'
import DynamicConfig from 'containers/DynamicConfig'
import Twilio from 'containers/Twilio'
import Keycloak from 'containers/Keycloak'
import Finance from 'containers/Finance'
import Compliance from 'containers/Compliance'
import Marketing from 'containers/Marketing'
import KeycloakAdminGroupsWithSubGroups from 'containers/Keycloak/Groups/KeycloakAdminGroupsWithSubGroups'

interface NavigationRoute {
  path: string
  pathAccess: string
  exact?: boolean
  Component: (props: any) => React.ReactNode
}

export const navigationRoutes: NavigationRoute[] = [
  {
    path: '/accounts',
    pathAccess: 'accounts',
    Component: (props: any) => <Accounts {...props} />,
  },
  {
    path: '/exchange',
    pathAccess: 'exchange',
    Component: (props: any) => <Exchange {...props} />,
  },
  {
    path: '/leverage',
    pathAccess: 'leverage',
    Component: (props: any) => <Leverage {...props} />,
  },
  {
    path: '/funds',
    pathAccess: 'fund',
    Component: (props: any) => <Fund {...props} />,
  },
  {
    path: '/dual-auth',
    pathAccess: 'dual-auth',
    Component: (props: any) => <DualAuth {...props} />,
  },
  {
    path: '/dynamic',
    pathAccess: 'config',
    Component: (props: any) => <DynamicConfig {...props} />,
  },
  {
    path: '/twilio',
    pathAccess: 'twilio',
    Component: (props: any) => <Twilio {...props} />,
  },
  {
    path: '/ferryman',
    pathAccess: 'keycloak',
    Component: (props: any) => <Keycloak {...props} />,
  },
  {
    path: '/keycloak/admin/groups',
    pathAccess: 'keycloak-groups',
    exact: true,
    Component: (props: any) => <KeycloakAdminGroupsWithSubGroups {...props} />,
  },
  {
    path: '/finance',
    pathAccess: 'finance',
    Component: (props: any) => <Finance {...props} />,
  },
  {
    path: '/kyc',
    pathAccess: 'kyc',
    Component: (props: any) => <Kyc {...props} />,
  },
  {
    path: '/compliance',
    pathAccess: 'compliance',
    Component: (props: any) => <Compliance {...props} />,
  },
  {
    path: '/marketing',
    pathAccess: 'marketing',
    Component: (props: any) => <Marketing {...props} />,
  },
]
