import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { TitleContainer } from 'components/atoms/Table/styles'
import FormSearchAccountEmailActivity from 'forms/SearchAccountEmails'
import FormRemoveBouncedEmail from 'forms/RemoveBouncedEmail'
import FormRemoveUnsubscribeEmail from 'forms/RemoveEmailFromGlobalUnsubscribes'
import FormResendVerificationEmail from 'forms/ResendVerificationEmail'
import FormAddEmailToUnsubscribefrom from 'forms/AddToGlobalSubscrition'
import { FormContainer, Button, FormError } from 'components/atoms/Form/index'
import { Flexbox } from 'components/layout/index'
import LatestEmailHistory from './LatestEmails'
import UnsubscribesGroups from './Unsubscribes'
import RemoveUserFromCompetitionList from './RemoveUserFromCompetitionList'

class EmailStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShow: false,
    }
  }

  componentWillReceiveProps(props) {
    const { sendgridData } = props
    if (sendgridData.status === 'done') this.toggleForm()
  }

  toggleForm = () => {
    const { formShow } = this.state
    this.setState({
      formShow: !formShow,
    })
  }

  render() {
    const { sendgridData } = this.props
    const { formShow } = this.state
    const { data: unsubscribes, status } = sendgridData
    const email = sendgridData.currentEmail
    return (
      <Fragment>
        {!formShow && (
          <FormSearchAccountEmailActivity />
        )}
        {status === 'done' && formShow && (
          <FormContainer>
            <Flexbox justify={'flex-end'}>
              <Flexbox marginRight={10} direction={'row'}>
                Email: {sendgridData.currentEmail}
              </Flexbox>
              <Flexbox marginRight={10} direction={'row'}>
                Is Globally Unsubscribed:
                {unsubscribes.isGloballyUnsubscribed.toString().toUpperCase()}
              </Flexbox>
              <Flexbox marginRight={10} direction={'row'}>
                Email Bounced:
                {sendgridData.data.bounces.toString().toUpperCase()}
              </Flexbox>
            </Flexbox>
            {unsubscribes !== undefined && (
              <Fragment>
                <TitleContainer>Unsubscribe Groups</TitleContainer>
                <UnsubscribesGroups src={sendgridData} />
              </Fragment>
            )}
            <Fragment>
              <TitleContainer>Recent Emails</TitleContainer>
              {sendgridData.status === 'done' && (
                <LatestEmailHistory src={sendgridData} />
              )}
            </Fragment>
            <Fragment>
              <TitleContainer>List</TitleContainer>
              {sendgridData.status === 'done' && (
                <RemoveUserFromCompetitionList email={email} unsubscribes={unsubscribes}
                  toggleForm={this.toggleForm} />
              )
              }
            </Fragment>
            <Flexbox justify={'flex-end'} direction={'row'}>
              {sendgridData.data.bounces && (
                <FormRemoveBouncedEmail
                  initialValues={{ bouncedEmail: sendgridData.currentEmail }}
                  form={'bouncedList'}
                  onSubmitSuccess={this.toggleForm}
                />
              )}
              <FormResendVerificationEmail
                initialValues={{ email }}
                form={'resendVerification'}
                onSubmitSuccess={this.toggleForm}
              />
              <Button onClick={this.toggleForm} color='secondary'>Close</Button>
            </Flexbox>
          </FormContainer>
        )}
        {status === 'error' && <FormError error={sendgridData.error} />}
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  sendgridData: state.recentSendgridEmailByUser,
})

export default connect(mapStateToProps)(EmailStatus)
