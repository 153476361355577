import React, { Component, Fragment } from 'react'

import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  ActionLabel,
  ActionInput,
  TablePlaceholder,
  TitleContainer,
} from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { BLACK_COLOR_PAIR } from 'theme/colors'
import { oppositeColor } from 'helpers/utils'
import BigNumber from 'bignumber.js'

class PlatformBalance extends Component {
  constructor(props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.toggleZeroBalances = this.toggleZeroBalances.bind(this)
    this.handleFilter = this.handleFilter.bind(this)

    this.state = {
      expanded: props.expanded || false,
      filter: '',
      hideZero: false,
    }
  }

  searchFilter(balance) {
    const filterValue = this.state.filter.toLowerCase()
    return (
      balance.currency.symbol.toLowerCase().indexOf(filterValue) > -1 ||
      balance.currency.shortName.toLowerCase().indexOf(filterValue) > -1 ||
      balance.currency.longName.toLowerCase().indexOf(filterValue) > -1
    )
  }

  toggleView(panel) {
    this.setState({
      expanded: panel,
    })
  }

  toggleZeroBalances() {
    const hideZero = !this.state.hideZero
    this.setState({
      hideZero,
    })
  }

  handleFilter(e) {
    const { value } = e.target
    this.setState({ filter: value })
  }

  render() {
    const { name, accountId } = this.props
    const { expanded } = this.state
    const { balances } = this.props
    const { addToTotalBalance } = this.props

    //TODO: zarTotal must change to referenceCurrencyTotal, but then when the selected ref curr is changed (drop down selection?) then the data needs to be re-retrieved?
    const calculateTotalReferenceCurrencyAmount = (filteredBalances) => {
      const total = filteredBalances.reduce((accumulator, filteredBalance) => {
        const value = filteredBalance.referenceCurrencyBalance
        const checkedCurrentValue = value
          ? parseFloat(
              typeof value === 'string' ? value.split(',').join('') : value,
            )
          : 0
        return accumulator + checkedCurrentValue
      }, 0)
      if (addToTotalBalance) addToTotalBalance(total)
      return `R${new BigNumber(total).toFormat(2)}`
    }

    const filteredBalances = balances
      .filter(
        (balance) =>
          !this.state.hideZero || balance.referenceCurrencyBalance !== 0,
      )
      .filter((balance) => !this.state.filter || this.searchFilter(balance))
      .sort((x, y) =>
        x.currency.symbol === 'ETH' ? -1 : y.currency.symbol === 'ETH' ? 1 : 0,
      )
      .sort((x, y) =>
        x.currency.symbol === 'BTC' ? -1 : y.currency.symbol === 'BTC' ? 1 : 0,
      )
      .sort((x, y) =>
        x.currency.symbol === 'R' ? -1 : y.currency.symbol === 'R' ? 1 : 0,
      )

    return (
      <>
        <ExpandingItem
          title={accountId ? `${accountId}: ${name}` : `${name}`}
          expanded={expanded}
          onExpand={this.toggleView}
          adornment={
            <>
              <ActionLabel>Filter:</ActionLabel>
              <ActionInput onChange={this.handleFilter} />
            </>
          }
          expandedView={
            <Fragment>
              <div>
                <TitleContainer>
                  {`Total ZAR Balance: ${calculateTotalReferenceCurrencyAmount(
                    filteredBalances,
                  )}`}
                </TitleContainer>
                <Table>
                  <TableHeader>
                    <TableCell flex={1}>Currency</TableCell>
                    <TableCell width={'20%'} align={'flex-end'}>
                      Available
                    </TableCell>
                    <TableCell width={'20%'} align={'flex-end'}>
                      Reserved
                    </TableCell>
                    <TableCell width={'20%'} align={'flex-end'}>
                      Total
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    {filteredBalances.length === 0 ? (
                      <TablePlaceholder>No items to show</TablePlaceholder>
                    ) : (
                      filteredBalances.map((balance, i) => {
                        const {
                          currency: { symbol, longName },
                          available,
                          reserved,
                          total,
                        } = balance
                        const nonZeroAvailable = available !== '0'
                        const nonZeroReserved = reserved !== '0'
                        const nonZeroTotal = total !== '0'

                        const labelAvailable = nonZeroAvailable
                          ? `${available} ${symbol}`
                          : '0.0'
                        const labelReserved = nonZeroReserved
                          ? `${reserved} ${symbol}`
                          : '0.0'
                        const labelTotal = nonZeroTotal
                          ? `${total} ${symbol}`
                          : '0.0'
                        return (
                          <TableRow key={longName + (i + 1)}>
                            <TableCell flex={1}>{longName}</TableCell>
                            <TableCell
                              bold={nonZeroAvailable}
                              width={'20%'}
                              align={'flex-end'}
                              color={oppositeColor(
                                nonZeroAvailable,
                                BLACK_COLOR_PAIR,
                              )}
                            >
                              {labelAvailable}
                            </TableCell>
                            <TableCell
                              bold={nonZeroReserved}
                              width={'20%'}
                              align={'flex-end'}
                              color={oppositeColor(
                                nonZeroReserved,
                                BLACK_COLOR_PAIR,
                              )}
                            >
                              {labelReserved}
                            </TableCell>
                            <TableCell
                              bold={nonZeroTotal}
                              width={'20%'}
                              align={'flex-end'}
                              color={oppositeColor(
                                nonZeroTotal,
                                BLACK_COLOR_PAIR,
                              )}
                            >
                              {labelTotal}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    )}
                  </TableBody>
                </Table>
              </div>
            </Fragment>
          }
        />
      </>
    )
  }
}
export default PlatformBalance
