import ErrorBoundary from 'helpers/ErrorBoundary'
import React, { Component } from 'react'
import AddCurrencyTable from './currencyTable'

class AddCurrency extends Component {
  render() {
    return (
      <ErrorBoundary message="Finance Dynamic Currencies">
        <AddCurrencyTable />
      </ErrorBoundary>
    )
  }
}

export default AddCurrency
