import styled from 'styled-components'

export const CheckboxTextSize = styled.div`
  font-size: 15px;
`

export const Input = styled.input`
  position: relative;
  width: 20px;
  height:  20px;
`
