import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { FC, Fragment } from 'react'
import {
  CorporateRiskProfileWithDualAuthStatus,
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import RiskAccountsProfilesRow from './RiskAccountsProfilesRow'
import CorporateRiskProfilesRow from './Corporate/CorporateRiskProfileRow'

interface RiskProfilesTableProps {
  accountsProfilesData:
    | RiskProfileWithDualAuthStatus[]
    | CorporateRiskProfileWithDualAuthStatus[]
  toggleSelectedRow: (value: number) => void
  expandRowId: number
  status: 'idle' | 'pending' | 'done'
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
  type: RecommendationStatusTypes
  riskLevelToSearch: RiskLevelTypes
  initializeRiskProfileReview: (id: number) => Promise<void>
  getAllProfiles?: (
    type: RecommendationStatusTypes,
    riskLevelTypes: RiskLevelTypes,
    riskProfileType?: 'CORPORATE' | 'INDIVIDUAL',
  ) => Promise<void>
}

const RiskAccountsProfilesTable: FC<RiskProfilesTableProps> = (props) => {
  const {
    accountsProfilesData,
    toggleSelectedRow,
    expandRowId,
    status,
    type,
    initializeRiskProfileReview,
    riskLevelToSearch,
    getAllProfiles,
  } = props

  const isCorporateRiskProfileTable = props.riskProfileType === 'CORPORATE'

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account ID</TableCell>
            <TableCell>
              {isCorporateRiskProfileTable ? 'Corporate ' : ''}Name
            </TableCell>
            {!isCorporateRiskProfileTable && <TableCell>Surname</TableCell>}
            <TableCell>Recommendation Status</TableCell>
            <TableCell>Dual Auth Status</TableCell>
            <TableCell>Risk Score</TableCell>
            <TableCell>Risk Level</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        {status === 'pending' ? (
          <TableCell colSpan={10}>
            <LoadingBlock />
          </TableCell>
        ) : (
          <>
            {accountsProfilesData.length === 0 && (
              <TableCell colSpan={10}>
                <TablePlaceholder>{'No Data'}</TablePlaceholder>
              </TableCell>
            )}
            {accountsProfilesData.map((profilesData) =>
              props.riskProfileType !== 'CORPORATE' ? (
                <RiskAccountsProfilesRow
                  key={profilesData.id}
                  accountsProfilesData={profilesData}
                  toggleSelectedRow={toggleSelectedRow}
                  isSelected={expandRowId === profilesData.id}
                  initializeRiskProfileReview={() =>
                    initializeRiskProfileReview(profilesData.accountId)
                  }
                  riskLevelToSearch={riskLevelToSearch}
                  type={type}
                  riskProfileType={props.riskProfileType}
                  getAllProfiles={() =>
                    getAllProfiles
                      ? getAllProfiles(
                          type,
                          riskLevelToSearch,
                          props.riskProfileType,
                        )
                      : null
                  }
                />
              ) : (
                <CorporateRiskProfilesRow
                  key={profilesData.id}
                  accountsProfilesData={profilesData}
                  toggleSelectedRow={toggleSelectedRow}
                  isSelected={expandRowId === profilesData.id}
                  initializeRiskProfileReview={() =>
                    initializeRiskProfileReview(profilesData.accountId)
                  }
                  riskLevelToSearch={riskLevelToSearch}
                  type={type}
                  riskProfileType={props.riskProfileType}
                  getAllProfiles={() =>
                    getAllProfiles
                      ? getAllProfiles(
                          type,
                          riskLevelToSearch,
                          props.riskProfileType,
                        )
                      : null
                  }
                />
              ),
            )}
          </>
        )}
      </Table>
    </Fragment>
  )
}
export default RiskAccountsProfilesTable
