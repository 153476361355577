import { TablePlaceholder } from 'components/atoms/Table/styles'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import React from 'react'

export default function NoTableResultsMessage({ messageType }) {
  return (
    <TablePlaceholder>
      {NO_RESULT_MESSAGE[messageType].message}
    </TablePlaceholder>
  )
}
