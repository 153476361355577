import React, { Fragment, Component } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasCellNumberChangeRole } from 'helpers/roleBasedAccess'
import { Spacer } from 'components/atoms/Generic'

import { ActionButton } from 'components/atoms/Table/styles'
import InitiateCellphoneChangeForm from 'forms/InitiateCellphoneChange'
import auth from 'services/Auth'

class InitiateCellphoneChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const { id } = this.props
    const notAuthorized = !hasCellNumberChangeRole()
    return (
      <Fragment>
        <Spacer margin="0px 0px 8px 0px">
          {!formShown && (
            <MuiButton
              disabled={notAuthorized}
              onClick={this.toggleForm}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
            >
              Initiate Change
            </MuiButton>
          )}
        </Spacer>
        {formShown && (
          <InitiateCellphoneChangeForm
            initialValues={{
              id,
              comment: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
              actionType: 'CELL_NUMBER_CHANGE',
            }}
            form={'ChangeCellNumber'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}

export default InitiateCellphoneChange
