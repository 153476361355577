import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import { Headers } from './headers'
import Row from './row'

export const OtherTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(otherInfoDualAuth => {
          otherInfoDualAuth.createdAt = moment(otherInfoDualAuth.createdAt).format(
            'YYYY-MMM-DD HH:mm:ss',
          )
          return (
            <Row
              key={otherInfoDualAuth.id}
              dualAuthId={otherInfoDualAuth.id}
              otherInfoDualAuth={otherInfoDualAuth}
              isSelected={selectedRow === otherInfoDualAuth.id}
              toggleSelected={toggleSelectedRow}
            />
          )
        })}

      </TableBody>
    </Table>
  )
}
export default OtherTable
