import React, { useState, useEffect } from 'react'
import { TableBody } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { TablePagination } from '@material-ui/core'
import Row from './row'
import { Headers } from './headers'

export const SuspendedTable = props => {
  const {
    formShown,
    expandItem,
    data,
    showRawData,
    allDeposits,
    expandRawData,
    addNoteFormShown,
    toggleSelectAll,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    tablePaginationCount,
    isSelectAllChecked,
  } = props
  const {
    checkIfIsPending, toggleForm, toggleAddNotesForm, title,
  } = props
  // Separation for handling the Bulk actions props
  const {
    bulkActionsActive,
    handleSelectedBulkDepositClick,
    selectedBulkDeposits,
  } = props
  const [highlightedId, setHighlightedId] = useState('')
  const [isCellHidden, setIsCellHidden] = useState(true)
  const hasDeposits = data.length > 0

  useEffect(
    () => {
      if (bulkActionsActive) {
        return setIsCellHidden(false)
      }

      setTimeout(() => {
        setIsCellHidden(true)
      }, 200)
    },
    [bulkActionsActive, isCellHidden],
  )

  return (
    <>
      {!hasDeposits && (
        <NoTableResultsMessage messageType={NO_RESULT_MESSAGE.DEPOSITS.type} />
      )}
      {hasDeposits && (
        <>
          <Headers
            isCellHidden={isCellHidden}
            bulkActionsActive={bulkActionsActive}
            expandItem={expandItem}
            toggleSelectAll={toggleSelectAll}
            isSelectAllChecked={isSelectAllChecked}
          />
          <TableBody>
            {data.map(deposit => {
              deposit.dateTime = moment(deposit.dateTime).format('YYYY-MMM-DD')
              deposit.insertedAt = moment(deposit.insertedAt).format(
                'YYYY-MMM-DD HH:mm:ss',
              )
              return (
                <Row
                  title={title}
                  handleSelectedBulkDepositClick={
                    handleSelectedBulkDepositClick
                  }
                  isCellHidden={isCellHidden}
                  selectedBulkDeposits={selectedBulkDeposits}
                  bulkActionsActive={bulkActionsActive}
                  key={deposit.depositId}
                  deposit={deposit}
                  allDeposits={allDeposits}
                  depositId={deposit.depositId}
                  isPending={checkIfIsPending(deposit)}
                  toggleForm={() => toggleForm(deposit.depositId)}
                  formShown={formShown === deposit.depositId}
                  showRawData={showRawData}
                  toggleAddNotesForm={toggleAddNotesForm}
                  expandRawData={expandRawData}
                  setHighlightedId={setHighlightedId}
                  isHighlighted={highlightedId === deposit.depositId}
                  addNoteFormShown={addNoteFormShown === deposit.depositId}
                />
              )
            })}
            <TablePagination
              component="div"
              count={tablePaginationCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableBody>
        </>
      )}
    </>
  )
}
