import {
  all, call, put, takeLatest,
} from 'redux-saga/effects'
import getAxios from '../../helpers/axios'
import {
  FETCH_AVAILABLE_ACCOUNT_ACTION_START,
  fetchAvailableAccountActionsSuccess,
  fetchAvailableAccountActionsError,
} from './actions'

async function fetchRemoteAvailableAccountActions() {
  const { data } = await getAxios().get('/account/requested-actions/available')
  return data
}

export function* fetchAvailableAccountActions() {
  try {
    const data = yield call(fetchRemoteAvailableAccountActions)
    yield put(fetchAvailableAccountActionsSuccess(data))
  } catch (error) {
    // Silently fail
    yield put(fetchAvailableAccountActionsError({ error: error.response }))
  }
}

function* availableAccountActionsSagas () {
  return yield all([
    takeLatest(FETCH_AVAILABLE_ACCOUNT_ACTION_START, fetchAvailableAccountActions),
  ])
}

export default availableAccountActionsSagas
