import React from 'react'
import styled from 'styled-components'
import JsonView from 'react-json-view'
import {
  WARNING_COLOR,
  INPUT_LABEL_COLOR,
  ERROR_COLOR,
  BORDER_COLOR,
  LIGHTEST_GREY,
  DARK_GREY,
  GRAY_0,
  GRAY_2,
  WHITE,
  BLACK,
  GRAY_1,
  TRANSPARENT_PINK,
  PANEL_BLUE,
} from 'theme/colors'
import { FontColor } from 'components/atoms/Generic'
import { BASE } from 'theme/base-units'
import {
  TextField,
  Button as MUIButton,
  FormControl,
  RadioGroup as MUIRadioGroup,
  Radio as MUIRadio,
} from '@material-ui/core'

export const RadioGroup = styled(MUIRadioGroup)``

export const RadioButton = styled(MUIRadio)``

export const FormTitle = styled.h2`
  margin: 0;
  margin-bottom: ${BASE * 2}px;
  font-size: ${BASE * 2.5}px;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const FormContainer = styled('form')`
  width: ${({ width }) => width || '100%'};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  background-color: ${({ theme }) => theme.formContainer.background};
  margin: ${BASE * 2}px 0;
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`

export const TransparentFormContainer = styled.form`
  width: ${({ width = '50%' }) => width};
  display: flex;
  box-sizing: border-box;
  flex-direction: ${({ direction = 'column' }) => direction};
  align-content: ${({ align = 'left' }) => align};
  padding: ${BASE * 3}px;
`
export const FormBounces = styled('form')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 2}px;
  background-color: ${GRAY_0};
  margin: ${BASE * 2}px 0;
`
export const FormResendVerification = styled('form')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 2}px;
  background-color: ${GRAY_0};
  margin: ${BASE * 2}px 0;
`
export const FormLikeContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  background-color: ${({ theme }) => theme.container.background};
  color: ${({ theme }) => theme.container.text};
  margin: ${BASE * 2}px 0;
  border: 1px solid ${GRAY_1};
`

export const RawDataInput = styled.div`
  font-size: 16px;
  display: flex;
  text-align: left;
`
export const InputGroup = styled.div`
  font-size: 16px;
  display: flex;
  text-align: left;
  flex-direction: ${({ direction = 'row' }) => direction};
  margin-bottom: ${({ marginBottom = 10 }) => `${marginBottom}px`};
  margin-right: ${({ marginRight = 0 }) => `${marginRight}px`};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify = 'center' }) => justify};
  flex: ${({ flex = '1' }) => `${flex}`};
  width: ${({ width = 'auto' }) => `${width}`};
  padding-bottom: ${({ paddingBottom = 0 }) => `${paddingBottom}px`};
`

export const InputDecoration = styled.div`
  background-color: rgba(245, 245, 245, 0.5);
  position: absolute;
  right: ${BASE}px;
  top: ${BASE / 2}px;
  bottom: ${BASE / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`

export const InputLabel = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  margin-right: ${({ marginRight = 20 }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom = BASE }) => `${marginBottom}px`};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
  color: ${({ color = INPUT_LABEL_COLOR, warning }) =>
    warning ? WARNING_COLOR : color};
  text-transform: ${({ formatLabel = true }) =>
    formatLabel ? 'capitalize' : 'none'};
  font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
  min-width: ${({ minWidth = 'none' }) => `${minWidth}`};
  width: ${({ width = 'auto' }) => `${width}`};
  flex: ${({ flex }) => `${flex}`};
`

export const InputContainer = styled.div`
  position: relative;
  width: ${({ width }) => width || '100%'};
`

export const Input = styled(TextField)`
  position: relative;
  z-index: 1;
  font-size: ${BASE * 2}px;
  color: ${({ error }) => (error ? ERROR_COLOR : BLACK)};
  font-weight: 400;
  margin: ${({ marginspacing = '0px' }) => marginspacing};
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || 'auto')};

  /* .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) => (error ? ERROR_COLOR : PANEL_BLUE)};
  }
  ::placeholder {
    color: ${DARK_GREY};
  }
  &:disabled {
    border-color: transparent;
    cursor: not-allowed;
    color: ${DARK_GREY};
  }
  .MuiOutlinedInput-root {
    background-color: ${({ bgcolor = LIGHTEST_GREY }) => bgcolor};
  }
  .MuiFilledInput-root {
    background-color: ${({ bgcolor = LIGHTEST_GREY }) => bgcolor};
  }
  .Mui-error {
    color: #f00;
  } */
  transform: none;
`

export const InputArea = styled.textarea`
  font-size: ${BASE * 2}px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #f1f1f1;
  border: solid 1px #dadada;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  padding: 0.5em 1em;
  flex: ${({ flex = '1' }) => `${flex}`};
  width: ${({ width = 'auto' }) => `${width}`};
  margin-right: ${({ marginRight = 20 }) => `${marginRight}px`};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
  resize: none;
  box-sizing: border-box;
  color: ${({ error }) => (error ? ERROR_COLOR : BLACK)};
  border-color: ${({ error, warning }) =>
    error ? ERROR_COLOR : warning ? WARNING_COLOR : BORDER_COLOR};
`

export const InputError = styled.p`
  padding: 0;
  margin: 0;
  padding-top: ${BASE * 0.5}px;
  padding-left: ${({ paddingLeft = 0 }) => `${paddingLeft}px`};
  color: ${ERROR_COLOR};
  text-align: left;
  font-size: 12px;
  margin-bottom: 8px;
`

export const Button = ({
  color = 'primary',
  variant = 'contained',
  children,
  ...props
}) => (
  <MUIButton
    style={{ marginRight: 8 }}
    color={color}
    variant={variant}
    {...props}
  >
    {children}
  </MUIButton>
)

export const OutlinedButton = styled.button`
  opacity: ${({ disabled = false }) => (disabled ? 0.25 : 1)};
  padding: ${2 * BASE}px ${BASE * 5}px;
  background-color: transparent;
  color: ${({ bgColor = WHITE }) => bgColor};
  font-size: ${BASE * 2}px;
  font-weight: 600;
  border: ${({ bgColor = WHITE }) => `2px solid ${bgColor}`};
  flex: 0 0 auto;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: ${BASE * 2}px;
  border-radius: ${({ borderRadius = 4 }) => `${borderRadius}px`};
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${({ labelColor = PANEL_BLUE }) => labelColor};
    background-color: ${({ bgColor = WHITE }) => bgColor};
  }
`
export const SendEmail = styled.div`
  font-size: 1.2em;
  position: sticky;
  left: 40%;
`
export const FormErrorContainer = styled.div`
  box-sizing: border-box;
  padding: ${BASE * 2}px;
  font-size: ${BASE * 2}px;
  color: ${ERROR_COLOR};
  border: 1px solid;
  background-color: ${TRANSPARENT_PINK};
  border-radius: 4px;
  margin-bottom: ${BASE * 2}px;
  & p {
    margin: 0;
  }
`

export const SimpleFormError = ({ message = '' }) => (
  <FormErrorContainer>
    <p>{message}</p>
  </FormErrorContainer>
)

export const FormError = ({ error = { message: '', error: {} } }) => (
  <FormErrorContainer>
    <p>{error.message}</p>
    <JsonView
      src={
        error.constructor !== {}.constructor
          ? JSON.parse(JSON.stringify(error))
          : error
      }
    />
  </FormErrorContainer>
)

export const SimpleSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: ${BASE * 2}px;
  background: ${GRAY_1};
  border-radius: ${BASE}px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${BASE * 3}px;
    height: ${BASE * 3}px;
    background: ${PANEL_BLUE};
    border-radius: ${BASE * 3}px;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: ${BASE * 3}px;
    height: ${BASE * 3}px;
    border-radius: ${BASE * 3}px;
    background: ${PANEL_BLUE};
    cursor: pointer;
  }
`

export const BlueLinkButton = styled(MUIButton).attrs({
  color: 'primary',
  variant: 'contained',
})`
  width: ${({ width = 'auto' }) => `${width}`};
  margin-right: ${({ marginRight = '0px' }) => marginRight};
  margin-bottom: ${({ marginBottom = '0px' }) => marginBottom};
`
export const InputGridContainer = styled.div`
  display: grid;
  grid-template-columns: 17% 17% 15% 15% 17%;
  grid-column-gap: 2%;
  align-items: center;
`

export const Dropdown = styled(FormControl)`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || 'auto')};
  margin: ${({ margin = '0px' }) => margin};
  .MuiMenu-list {
    max-height: 200px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) => (error ? ERROR_COLOR : PANEL_BLUE)};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ disabled }) => disabled && 'rgba(0, 0, 0, 0.38)'};
    opacity: 0.6;
  }
`

export const Percentage = () => (
  <InputDecoration>
    <FontColor color={GRAY_2}>
      <b>%</b>
    </FontColor>
  </InputDecoration>
)
