import React, { Fragment, Component } from 'react'
import { oppositeColor } from 'helpers/utils'
import { VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'

import { TableRow, TableCell } from 'components/atoms/Table/styles'
import CryptoWithdrawalReview from 'forms/CryptoWithdrawalReview/index'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import FormWithdrawalNotes from 'forms/WithdrawalNotes/index'
import EyeIcon from 'components/atoms/EyeIcon/index'

import { Link } from '../AccountIdLink/clickableId'

class RenderTableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingSensitiveInfo: false,
      sensitiveInfo: {
        name: '',
        surname: '',
      },
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
      test: '',
      rowColor: '',
    }
    this.interval = null
    this.withdrawalAlerts = {
      high: {
        color: 'rgba(255, 0, 0, 0.8)',
      },
      medium: {
        color: 'rgba(255, 165, 0, 0.6)',
      },
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.checkWithdrawalAlerts()
    }, 1000)

    this.checkWithdrawalAlerts()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getProfileInfo = async (id) => {
    this.setState({ isFetchingSensitiveInfo: true })
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const { sensitiveInfo } = res.data
    this.setState((prev) => ({
      ...prev,
      sensitiveInfo,
    }))
    this.setState({ isFetchingSensitiveInfo: false })
  }

  removeActiveUser = async () => {
    const withdrawalId = this.props.rowData.uniqueId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async () => {
    const withdrawalId = this.props.rowData.uniqueId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  converHoursToMills = (hours) => hours * 60 * 60 * 1000

  checkWithdrawalAlerts = () => {
    const { rowData } = this.props
    const withdrawalAlerts = {
      high: {
        isOver:
          new Date(rowData.createdAt).getTime() + this.converHoursToMills(48) <
          Date.now(),
      },
      medium: {
        isOver:
          new Date(rowData.createdAt).getTime() + this.converHoursToMills(24) <
          Date.now(),
      },
    }
    if (withdrawalAlerts.high.isOver) {
      return this.setState({ rowColor: this.withdrawalAlerts.high.color })
    }
    if (withdrawalAlerts.medium.isOver) {
      return this.setState({ rowColor: this.withdrawalAlerts.medium.color })
    }
  }

  updateComments = async (withdrawalId) => {
    this.setState({ commentsStatus: 'loading' })
    const { data } = await getAxios().get(
      `/withdrawal/${withdrawalId}/crypto/notes`,
    )
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  render() {
    const {
      rowData,
      toggleForm,
      toggleRawData,
      isHighlighted,
      toggleSelected,
      showForm,
    } = this.props
    const { active, reviewData } = this.props.rowData
    const padding = '16px 16px 16px 0px'
    const shading = isHighlighted ? 'white' : ''
    const { withdrawalId, uniqueId, tag } = rowData
    const { resetForm, toggleAddNotesForm } = this.props
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor:
              (isHighlighted && VALR_BLUE_GRAY) || this.state.rowColor,
          }}
          onClick={() => {
            toggleSelected(rowData.uniqueId)
            this.updateComments(rowData.withdrawalId)
            this.getProfileInfo(rowData.accountId)
          }}
        >
          <TableCell style={{ padding: '16px 16px' }} flex={0.6}>
            <Link color={shading} id={rowData.accountId} />
          </TableCell>
          <TableCell style={{ padding }} flex={1.3}>
            {rowData.uniqueId}
          </TableCell>
          <TableCell style={{ padding }} flex={1.5}>
            {rowData.sendAddress}
          </TableCell>
          <TableCell style={{ padding, fontWeight: 'bold' }} flex={1}>
            {`${rowData.amount} ${rowData.currency.symbol}`}
          </TableCell>
          <TableCell style={{ padding, fontWeight: 'bold' }} flex={1}>
            {rowData.feeAmount}
          </TableCell>
          <TableCell
            style={{ padding }}
            flex={1}
            color={oppositeColor(rowData.paymentReference, 'white')}
            bold
          >
            {rowData.paymentReference || 'None'}
          </TableCell>
          <TableCell style={{ padding }} flex={1}>
            {rowData.createdAt}
          </TableCell>
          <TableCell style={{ padding }} flex={0.5}>
            {active && active.userActive && (
              <EyeIcon text={active.activePersonName} />
            )}
          </TableCell>
          <TableCell flex={0.18} style={{ padding }} align={'center'}>
            {tag && (
              <i
                style={{ color: 'black' }}
                className="fa fa-sticky-note"
                aria-hidden="true"
              />
            )}
          </TableCell>
        </TableRow>
        {showForm && (
          <CryptoWithdrawalReview
            form={`withdrawalReviewForm+${rowData.withdrawalId}`}
            rawData={{
              ...rowData,
              review: reviewData,
              ...this.state.sensitiveInfo,
            }}
            toggleRawData={toggleRawData}
            showActiveUser={this.showActiveuser}
            removeActiveUser={this.removeActiveUser}
            withdrawalId={withdrawalId}
            expandRawData={this.props.expandRawData}
            expandAddNote={this.props.addNoteFormShown}
            showRawData={() => this.props.showRawData(rowData.uniqueId)}
            showAddNote={() => this.props.toggleAddNotesForm(rowData.uniqueId)}
            comments={{
              withdrawalComments: this.state.comments,
              status: this.state.commentsStatus,
            }}
            initialValues={{
              withdrawalId: rowData.withdrawalId,
              accountId: rowData.accountId,
              comment: '',
            }}
            onCancel={toggleForm}
          />
        )}
        {showForm && this.props.addNoteFormShown && (
          <FormWithdrawalNotes
            initialValues={{
              withdrawalId: rowData.withdrawalId,
              data: {
                date: new Date(),
                createdBy: auth.decodeNameToken(),
                withdrawalId,
                uniqueId,
              },
            }}
            form={`withdrawalNotesForm+${rowData.withdrawalId}`}
            depositData={this.props.deposit}
            onCancel={resetForm}
            toggleForm={() => {
              toggleAddNotesForm(rowData.withdrawalId)
              this.updateComments(rowData.withdrawalId)
            }}
          />
        )}
      </Fragment>
    )
  }
}

export default RenderTableRow
