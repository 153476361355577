import styled from 'styled-components'
import { VALR_BLUE_GRAY } from 'theme/colors'

export const StyledOrderTypesHeader = styled.div`
  border-bottom: 1px solid ${VALR_BLUE_GRAY};
  position: sticky;
  top: -40px;
  padding: 20px;
  z-index: 99;
`

export const StyledOrderTypesTable = styled.table`
width: 100%;
`
export const StyledOrderTypesSearchBar = styled.th`
  width: 20%;
`
export const StyledOrderTypesTableHeading = styled.th`
  text-align: right;
  width: 160px;
  color: hsl(0deg 0% 50%);
  font-weight: 300;
  margin-right: 8px; 
`

export const StyledOrderTypesTableBody = styled.tbody``

export const StyledSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content:left;
`
export const StyledSelectOuter = styled.div`
  width: 300px;
`
export const BackgroundColor = styled.table`
background-color:transparent;
`

