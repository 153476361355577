import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ErrorBoundary from 'helpers/ErrorBoundary'
import MenuItem from '@material-ui/core/MenuItem'
import { Box, LinearProgress } from '@material-ui/core'
import FiatWithdrawalsDashboardStats from 'containers/FiatWithdrawalsDashboardStats'
import SimpleModal from 'components/organisms/Modal'
import { Spacer } from 'components/atoms/Generic'
import FeatureFlagBoundary from 'components/organisms/FeatureFlagBoundary'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  fetchPendingWithdraws,
  fetchUnresolvedWithdraws,
  fetchWithdrawalsUnderReview,
  updateWithrawal,
  updateUnresolvedWithrawal,
  setFiatFilters,
  setBankAccountFilter,
} from 'redux/fiat/actions'
import PendingFiatWithdraws from 'components/organisms/PendingFiatWithdraws'
import RenderResponseAfterDownload from 'components/organisms/PendingFiatWithdraws/feedback'
import ReviewFiatWithdrawals from 'components/organisms/ReviewFiatWithdrawals'
import UnresolvedFiatWithdrawals from 'components/organisms/UnresolvedFiatWithdrawals'
import { TitleContainer } from 'components/atoms/Table/styles'
import socket from 'redux/socket/index'
import auth from 'services/Auth'
import SuspendedWithdrawals from 'components/organisms/SuspendedWithdrawals'

import { BASE } from '../../theme/base-units'
import { getFiatConfig } from '../../redux/fiat/selectors'
import { BankSources } from '../../redux/fiat/constants'
import { Flexbox } from 'components/layout'

class Withdrawals extends Component {
  constructor(props) {
    super(props)
    const { bankAccounts } = this.props
    this.state = {
      downloadStatus: false,
      selectedWithdraws: [],
      expanded: '',
      withdrawalsCount: 0,
      bankSource:
        bankAccounts.find(
          b => b.country === BankSources.STANDARD_BANK_ZA.country,
        ) || {}, // Change default selected bank account here
      showFiatWithdrawalDashboardStats: false,
    }
  }

  componentDidMount() {
    this.props.fetchWithdrawalsUnderReview(this.state.bankSource.country)
    this.props.fetchPendingWithdraws(this.state.bankSource.country)
    this.props.fetchUnresolvedWithdraws(this.state.bankSource.country)
    socket.on('busy withdrawals', data => {
      this.props.updateWithrawal(data)
      this.props.updateUnresolvedWithrawal(data)
    })
    socket.on('withdrawal status updated', data => {
      this.props.updateWithrawal(data)
      this.props.updateUnresolvedWithrawal(data)
    })
    socket.on('onMount status updated', data => {
      this.props.updateWithrawal(data)
      this.props.updateUnresolvedWithrawal(data)
    })
  }

  componentDidUpdate(prevProps) {
    const {
      pendingWithdraws,
      withdrawalsUnderReview,
      unresolvedWithdraws,
      selectedWithdraws,
    } = this.props
    const {
      pendingWithdraws: prevPendingWithdraws,
      withdrawalsUnderReview: prevWithdrawalsUnderReview,
      unresolvedWithdraws: prevUnresolvedWithdraws,
      selectedWithdraws: prevSelectedWithdraws,
    } = prevProps

    if (
      pendingWithdraws.data !== prevPendingWithdraws.data ||
      withdrawalsUnderReview.data !== prevWithdrawalsUnderReview.data ||
      unresolvedWithdraws.data !== prevUnresolvedWithdraws.data ||
      selectedWithdraws.data !== prevSelectedWithdraws.data
    ) {
      const { downloadStatus } = this.state
      const count =
        (downloadStatus
          ? (selectedWithdraws.data && selectedWithdraws.data.length) || 0
          : (pendingWithdraws.data && pendingWithdraws.data.length) || 0) +
        (withdrawalsUnderReview.data ? withdrawalsUnderReview.data.length : 0) +
        (unresolvedWithdraws.data ? unresolvedWithdraws.data.length : 0)
      this.setState({ withdrawalsCount: count })
    }
  }

  removeActiveUser = async withdrawalId => {
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async withdrawalId => {
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  cancel = () => {
    if (this.state.downloadStatus === true) {
      this.setState({ downloadStatus: false })
    }
  }

  setExpand = panel => {
    this.setState({ expanded: panel })
  }

  refresh = () => {
    this.props.fetchWithdrawalsUnderReview(this.state.bankSource.country)
    this.props.fetchPendingWithdraws(this.state.bankSource.country)
    this.props.fetchUnresolvedWithdraws(this.state.bankSource.country)
  }

  changeSource = newSource => {
    this.setState({ bankSource: newSource }, () => this.refresh())
    this.props.setBankAccountFilter(newSource.id)
  }

  toggleShowFiatWithdrawalsDashboardStats = () => {
    this.setState(prevState => ({
      showFiatWithdrawalDashboardStats: !prevState.showFiatWithdrawalDashboardStats,
    }))
  }

  render() {
    const {
      pendingWithdraws,
      unresolvedWithdraws,
      withdrawalsUnderReview,
      selectedWithdraws,
      suspendedWithdrawals,
    } = this.props
    const { expanded, withdrawalsCount, bankSource } = this.state
    const isLoading =
      pendingWithdraws.status === 'loading' ||
      unresolvedWithdraws.status === 'loading' ||
      withdrawalsUnderReview.status === 'loading'

    const showSources = () =>
      this.props.bankAccounts.map(bankAccount => (
        <MenuItem key={bankAccount.id} value={bankAccount} disabled={isLoading}>
          {bankAccount.name}
        </MenuItem>
      ))
    return (
      <>
        <TitleContainer
          borderBottomWidth={isLoading ? 0 : '2px'}
          marginBottom={isLoading ? 0 : BASE}
        >
          <Flexbox>
            {this.props.bankAccounts.map(bankAccount => {
              return (
                <Spacer margin="3px 12px 0px 0px">
                  <MuiButton
                    onClick={() => {
                      this.changeSource(bankAccount)
                    }}
                    style={{ backgroundColor: bankSource.name === bankAccount.name ? 'green' : '' }}
                    disabled={isLoading}
                  >
                    {bankAccount.name}
                  </MuiButton>
                </Spacer>
              )
            })}
          </Flexbox>
          {`Total withdrawals: ${withdrawalsCount}`}
        </TitleContainer>
        {isLoading && (
          <Box sx={{ width: '100%' }} style={{ marginBottom: BASE }}>
            <LinearProgress style={{ height: 2 }} />
          </Box>
        )}
        <ErrorBoundary message="WITHDRAWALS UNDER REVIEW">
          <ReviewFiatWithdrawals
            name="WITHDRAWALS UNDER REVIEW"
            withdrawalsUnderReview={withdrawalsUnderReview}
            expanded={expanded}
            setExpand={this.setExpand}
          />
        </ErrorBoundary>
        <ErrorBoundary message="PENDING WITHDRAWALS">
          {!this.state.downloadStatus ? (
            <PendingFiatWithdraws
              name="PENDING WITHDRAWALS"
              pendingWithdraws={pendingWithdraws}
              getTotalAmount={this.getTotalAmountForSelected}
              expanded={expanded}
              setExpand={this.setExpand}
            />
          ) : (
            <RenderResponseAfterDownload
              name="PENDING WITHDRAWALS"
              done={this.cancel}
              selectedWithdraws={selectedWithdraws}
              expanded={expanded}
              setExpand={this.setExpand}
            />
          )}
        </ErrorBoundary>
        <ErrorBoundary message="SUSPENDED WITHDRAWALS">
          <SuspendedWithdrawals
            name="SUSPENDED WITHDRAWALS"
            suspendedWithdrawals={suspendedWithdrawals}
            expanded={expanded}
            setExpand={this.setExpand}
          />
        </ErrorBoundary>
        <ErrorBoundary message="UNRESOLVED WITHDRAWALS">
          <UnresolvedFiatWithdrawals
            name="UNRESOLVED WITHDRAWALS"
            unresolvedWithdraws={unresolvedWithdraws}
            expanded={expanded}
            showActiveuser={this.showActiveuser}
            removeActiveUser={this.removeActiveUser}
            setExpand={this.setExpand}
          />
          <FeatureFlagBoundary hideMessage flag="fiatWithdrawalsDashboardStats">
            <Spacer margin="12px 0px">
              <MuiButton onClick={this.toggleShowFiatWithdrawalsDashboardStats}>
                View Fiat Withdrawals Dashboard Stats
              </MuiButton>
            </Spacer>
            <SimpleModal
              handleClose={this.toggleShowFiatWithdrawalsDashboardStats}
              buttonsHidden
              open={this.state.showFiatWithdrawalDashboardStats}
              title={'Fiat Withdrawals Dashboard'}
              message=""
            >
              <FiatWithdrawalsDashboardStats />
            </SimpleModal>
          </FeatureFlagBoundary>
        </ErrorBoundary>
      </>
    )
  }
}

const mapStateToProps = state => ({
  pendingWithdraws: state.fiat.pendingWithdraws,
  fiatCSVDownload: state.fiat.download,
  withdrawalsUnderReview: state.fiat.withdrawalsUnderReview,
  unresolvedWithdraws: state.fiat.unresolvedWithdraws,
  selectedWithdraws: state.fiat.selectedWithdrawals,
  bankAccounts: getFiatConfig(state).bankAccounts,
  suspendedWithdrawals: state.fiat.suspendedWithdrawals,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPendingWithdraws,
      fetchWithdrawalsUnderReview,
      fetchUnresolvedWithdraws,
      updateWithrawal,
      updateUnresolvedWithrawal,
      setBankAccountFilter,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Withdrawals)
