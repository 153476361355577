import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { required } from 'forms/fieldValidators'
import { renderDatetime } from 'forms/fields'

import { FormContainer } from './Styles'

const Form = ({ handleSubmit, onSubmit, dirty, submitting }) => (
  <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <Field
      label={'Start date'}
      name={'from'}
      marginRight={BASE * 2}
      component={renderDatetime}
      validate={[required]}
      placeholder={'Date & Time'}
    />
    <Field
      label={'End date'}
      name={'to'}
      validate={[required]}
      marginRight={BASE * 2}
      component={renderDatetime}
      placeholder={'Date & Time'}
    />
    <MuiButton
      disabled={submitting || !dirty}
      style={{ marginBottom: 18 }}
      type="submit"
    >
      Submit Dates
    </MuiButton>
  </FormContainer>
)

const ReduxForm = reduxForm({
  form: 'dateFilters',
  enableReinitialize: true,
})(Form)

export default ReduxForm
