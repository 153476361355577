import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from '@material-ui/core'
import { toast } from 'react-toastify'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasEmailChangeRole } from 'helpers/roleBasedAccess'

import { Details } from 'components/atoms/Details'
import InitiateEmailChangeForm from 'forms/InitiateEmailChange'
import auth from 'services/Auth'
import { unlinkKeycloakSocialAccounts } from 'redux/keycloak/actions'

import { NoCopy } from './Styles'

class InitiateEmailChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      updateUserData: {},
      emailChangeProcessActive: false,
    }
  }

  toggleForm = () => {
    this.setState({ formShown: !this.state.formShown })
  }

  generateActionButtonText = (
    emailChangeProcessActive,
    isKeycloakSocialAccount,
  ) => {
    let buttonText = 'Change Email'
    const { keycloakInfo } = this.props
    if (keycloakInfo.status !== 'done') buttonText = 'Checking Email Status'
    if (isKeycloakSocialAccount && this.props.unlinkFlagOn) {
      buttonText = 'Unlink Account'
    }
    if (emailChangeProcessActive || keycloakInfo.isUnlinkingAccount) {
      buttonText = 'Updating...'
    }
    return buttonText
  }

  handleActionButtonClick = isKeycloakSocialAccount => {
    const {
      id,
      keycloakInfo: {
        info: { id: keycloakAccountId },
        hasPendingUnlinkRequest,
      },
      unlinkFlagOn,
    } = this.props
    if (hasPendingUnlinkRequest) {
      return toast('Pending Unlinking Request Already Exists.', {
        type: toast.TYPE.ERROR,
      })
    }
    if (isKeycloakSocialAccount && unlinkFlagOn) {
      return this.props.unlinkKeycloakSocialAccounts(id, keycloakAccountId)
    }
    return this.toggleForm()
  }

  handleCloseModal = () => {
    this.setState({
      modalData: {
        ...this.initialModalState,
      },
    })
  }

  renderToolTipText(
    hasPendingUnlinkRequest,
    isKeycloakSocialAccount,
    unlinkFlagOn,
  ) {
    let text = ''
    if (
      hasPendingUnlinkRequest &&
      !(isKeycloakSocialAccount && !unlinkFlagOn)
    ) {
      text = 'Pending Unlink Request'
    } else if (isKeycloakSocialAccount && !unlinkFlagOn) {
      text = 'This account is social'
    }
    return text
  }

  render() {
    const { formShown, emailChangeProcessActive } = this.state
    const { id, auth0, isSocial, keycloakInfo, unlinkFlagOn } = this.props
    const {
      status,
      isCheckingPendingKycDualAuths,
      hasPendingUnlinkRequest,
      isUnlinkingAccount,
    } = keycloakInfo
    const isCheckingKeyclokInfoStatus = status !== 'done'

    const isKeycloakSocialAccount =
      keycloakInfo.info &&
      keycloakInfo.info.federatedIdentities &&
      keycloakInfo.info.federatedIdentities.length > 0

    const disableActionButton =
      isSocial ||
      emailChangeProcessActive ||
      isCheckingKeyclokInfoStatus ||
      isCheckingPendingKycDualAuths ||
      hasPendingUnlinkRequest ||
      isUnlinkingAccount ||
      (isKeycloakSocialAccount && !unlinkFlagOn)

    const notAuthorized = !hasEmailChangeRole()

    return (
      <Details>
        <NoCopy>
          {!formShown && (
            <Tooltip
              title={this.renderToolTipText(
                hasPendingUnlinkRequest,
                isKeycloakSocialAccount,
                unlinkFlagOn,
              )}
            >
              <div>
                <MuiButton
                  disabled={notAuthorized || disableActionButton}
                  onClick={() =>
                    this.handleActionButtonClick(isKeycloakSocialAccount)
                  }
                  showToolTip={notAuthorized}
                  tooltipTitle={
                    notAuthorized
                      ? 'You are not authorized to perform this action'
                      : ''
                  }
                >
                  {this.generateActionButtonText(
                    emailChangeProcessActive,
                    isKeycloakSocialAccount,
                  )}
                </MuiButton>
              </div>
            </Tooltip>
          )}
        </NoCopy>
        {formShown && (
          <InitiateEmailChangeForm
            initialValues={{
              id,
              comment: '',
              auth0,
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
              actionType: 'EMAIL_CHANGE',
              keycloakInfo,
            }}
            updateEmailChangeProcessState={status =>
              this.setState({
                emailChangeProcessActive: status,
              })
            }
            form={'ChangeEmail'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
            isSocial={isSocial}
            isKeycloakSocial={isKeycloakSocialAccount}
          />
        )}
      </Details>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  unlinkKeycloakSocialAccounts: (accountId, keycloakUserId) =>
    dispatch(unlinkKeycloakSocialAccounts(accountId, keycloakUserId)),
})

export default connect(
  null,
  mapDispatchToProps,
)(InitiateEmailChange)
