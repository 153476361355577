import React, { Fragment, useRef } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  FormContainer, BlueLinkButton, Button, InputGroup, FormError, InputContainer,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import { renderInput } from '../fields'
import { submit } from './submit'
import { InfoLabel } from 'components/atoms/Details'

let Form = (props) => {
  const {
    submitting, error, invalid, anyTouched,
  } = props
  const {
    handleSubmit, statusType, onCancel, onSubmit, rawData, showRawData, expandRawData, showAddNote, expandAddNote, depositId,
    comments, isInDualAuth, showActiveUser, removeActiveUser
  } = props

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])
  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)} >
        <InputGroup justify={'space-between'} align={'baseline'} direction={'row'}>
          <Field
            label={'Comment (required)'}
            name={'comment'}
            width={'100%'}
            validate={[required]}
            component={renderInput}
            justify={'space-around'}
            margin={`0 ${BASE * 2}px 0 0`}
            marginTop={26}
          />
          <Flexbox marginRight={BASE * 2} marginTop={BASE * 1.5} justify={'center'} direction={'row'}>
            <Button variant="contained" color="primary" type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
              Release
            </Button>
          </Flexbox>
        </InputGroup>
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton marginRight={'50px'} marginBottom={'20px'} onClick={showRawData}>
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>

          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,

})(Form)

const mapStateToProps = (state, ownProps) => {
  const suspendedForm = state.form[ownProps.form]
  if (!suspendedForm) {
    return {
      statusType: '',
    }
  } if (suspendedForm.values) {
    const {
      values: { status },
    } = suspendedForm
    return {
      statusType: status && status.value,
    }
  }
  return { statusType: '' }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
