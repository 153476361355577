import { SubmissionError } from 'redux-form'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submitTransaction = async values => {
  const {
    confirmation, currencySymbol, amount, ...rest
  } = values

  const res = await getAxios().post('/fund/individual', {
    currencySymbol: currencySymbol.value.toUpperCase(),
    amount: amount.toString(),
    ...rest,
  })
  const { data: { error } } = res

  if (error) {
    toast(error.message, { type: toast.TYPE.ERROR, autoClose: 2000 })
    throw new SubmissionError({
      currencySymbol: error.message,
    })
  }
}
