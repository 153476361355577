import React from 'react'
import { WHITE } from 'theme/colors'
import { TableCell } from '@material-ui/core'
import { StyledTableRowWithPointerHover } from 'global/StyledComponents/Table'

import FormFundingForm from 'forms/FundingForm/index'
import { RenderCheckbox } from 'forms/fields'

import { FundingInfoAndEventsRowData } from './interfaces'
import { FundingExtraInfo } from './FundingExtraInfo'

import { Link } from '../AccountIdLink/clickableId'

interface FundingRowProps {
  data: FundingInfoAndEventsRowData
  toggleForm: (value: string) => void
  formShown: {
    id: string | null
    showForm: boolean
  }
  isHighlighted: boolean
  handleSelectedBulkFundingClick: (data: FundingInfoAndEventsRowData) => void
  isSelected: boolean
  isBulkActionsActive: boolean
}
const FundingRow = (props: FundingRowProps) => {
  const {
    data,
    toggleForm,
    formShown,
    isHighlighted,
    handleSelectedBulkFundingClick,
    isSelected,
    isBulkActionsActive,
  } = props
  const {
    accountId,
    amount,
    createdAt,
    currency,
    status,
    identifier,
    feeAmount,
    description,
    basicInfoForDisplay,
  } = data
  return (
    <>
      <StyledTableRowWithPointerHover
        isexpanded={isHighlighted}
        isselected={isSelected}
        onClick={() => {
          toggleForm(identifier)
        }}
      >
        <TableCell>
          {accountId ? (
            <Link color={isSelected ? WHITE : ''} id={accountId} />
          ) : (
            '--'
          )}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.name : '--'}{' '}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.surname : '--'}{' '}
        </TableCell>
        <TableCell>{currency || '--'}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{feeAmount}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{createdAt}</TableCell>
        {isBulkActionsActive && (
          <TableCell onClick={(e) => e.stopPropagation()}>
            <RenderCheckbox
              checked={isSelected}
              onCheckFunction={() => handleSelectedBulkFundingClick(data)}
            />
          </TableCell>
        )}
      </StyledTableRowWithPointerHover>
      {formShown.id === identifier && formShown.showForm && (
        <TableCell colSpan={isBulkActionsActive ? 9 : 9}>
          <FormFundingForm
            initialValues={{
              identifier,
              accountId,
              comment: '',
              currentStatus: status,
            }}
            form={`Funding form+${identifier}`}
            onCancel={props.toggleForm}
            extra={<FundingExtraInfo data={data} />}
          />
        </TableCell>
      )}
    </>
  )
}

export default FundingRow
