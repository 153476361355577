import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import CreditUnallocatedReceive from 'forms/CreditUnallocatedReceive'
import UnallocatedReceivesNotes from 'forms/UnallocatedReceivesNotes'
import * as moment from 'moment'
import { VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'
import auth from 'services/Auth'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      comments: [],
      commentsStatus: 'persist',
    }
  }

  updateComments = async id => {
    this.setState({ commentsStatus: 'loading' })
    const { data } = await getAxios().get(`/unallocated/${id}/receives/notes`)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  handleClick = (id) => {
    const {
      setHighlightedId,
      toggleSelected,
    } = this.props
    toggleSelected(id)
    setHighlightedId(id)
  }

  render() {
    const { unassignedBlockChainReceives, showForm, isHighlighted, resetForm, isPending } = this.props
    const {
      id, currency, receiveAddress, transactionHash, tag,
      amount, confirmations, confirmed, confirmedAt, indexInTx, paymentReference, createdAt,
    } = unassignedBlockChainReceives
    const isLightTheme = localStorage.getItem('adminTheme') === 'lightTheme'
    const shading =
      isPending && isHighlighted
        ? '#d2d3d6'
        : isPending
          ? '#8c8c8c'
          : !isPending && isHighlighted
            ? 'white'
            : ''
    return <Fragment>
      <TableRow
        onClick={() => {
          this.handleClick(id)
          this.updateComments(id)
        }}
        active={showForm}
        style={{ color: isLightTheme ? shading : 'black' }}
      >
        <TableCell>{id} </TableCell>
        <TableCell >{currency}</TableCell>
        <TableCell>{receiveAddress}</TableCell>
        <TableCell>{transactionHash}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell >{confirmations}</TableCell>
        <TableCell >{confirmed.toString()}</TableCell>
        <TableCell>
          {moment(confirmedAt).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
        <TableCell>{indexInTx}</TableCell>
        <TableCell>
          {paymentReference === undefined ? 'none' : paymentReference}
        </TableCell>
        <TableCell >
          {moment(createdAt).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
        <TableCell>
          {tag ? (
            <i
              style={{ color: '#e96868' }}
              className="fa fa-sticky-note"
              aria-hidden="true"
            />
          ) : (
            '--'
          )}
        </TableCell>
      </TableRow>
      <TableRow className='no-hover'>
        <TableCell colSpan={22}>
          {showForm &&
            <>
              <CreditUnallocatedReceive
                initialValues={{ id }}
                form={`CreditUnallocatedReceiveForm-${id}`}
                onCancel={this.handleClick}
                onSubmitSuccess={this.handleClick}
                rawData={unassignedBlockChainReceives}
                showAddNote={() => this.props.toggleAddNotesForm(id)}
                comments={{
                  depositComments: this.state.comments,
                  status: this.state.commentsStatus,
                }}
                id={id}
                toggleForm={() => {
                  this.props.toggleAddNotesForm(id)
                  this.updateComments(id)
                }}
                expandRawData={this.props.expandRawData}
                expandAddNote={this.props.addNoteFormShown}
                showRawData={this.props.showRawData}
              />
              {this.props.addNoteFormShown && (
                <UnallocatedReceivesNotes
                  initialValues={{
                    receivesId: id,
                    data: {
                      date: new Date(),
                      createdBy: auth.decodeNameToken(),
                      receivesId: id,
                    },
                  }}
                  isfiatWithdrawal
                  form={`unallocatedReceivesNotesForm+${id}`}
                  withdrawalData={this.props.withdraw}
                  onCancel={resetForm}
                  toggleForm={() => {
                    this.props.toggleAddNotesForm(id)
                    this.updateComments(id)
                  }}
                />

              )}
            </>}
        </TableCell>
      </TableRow>
    </Fragment>
  }
}
export default Row
