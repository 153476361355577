import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'

const convertUnixToDate = date => {
  var day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

const mapData = list => {
  list.map(item => {
    if (item.dateCreated) {
      item.dateCreated = convertUnixToDate(item.dateCreated)
    } if (item.dateUpdated) {
      item.dateUpdated = convertUnixToDate(item.dateUpdated)
    } if (item.startTime) {
      item.startTime = convertUnixToDate(item.startTime)
    } if (item.endTime) {
      item.endTime = convertUnixToDate(item.endTime)
    } if (item.dateSent) {
      item.dateSent = convertUnixToDate(item.dateSent)
    }
    return item
  })
  return list
}

export const updateCountryPermissions = (isoCode, isEnabled) => async dispatch => {
  dispatch({
    type: 'TWILIO_PERMISSIONS_UPDATE_START',
  })
  try {
    await getAxios().post('/twilio/update-permissions', { isoCode, isEnabled })
    dispatch({
      type: 'TWILIO_PERMISSIONS_UPDATE_SUCCESS',
      payload: {
        isoCode,
        isEnabled,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_TWILIO_ERROR',
      payload: {
        error,
      },
    })
  }
}

export const fetchTwilioCountries = () => async dispatch => {
  dispatch({
    type: 'FETCH_TWILIO_COUNTRIES_START',
  })
  try {
    const res = await getAxios().get('/twilio/countries')
    dispatch({
      type: 'FETCH_TWILIO_COUNTRIES_SUCCESS',
      payload: {
        data: res.data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_TWILIO_COUNTRIES_ERROR',
      payload: {
        error,
      },
    })
  }
}

export const fetchTwilioSms = (number) => async dispatch => {
  dispatch({
    type: 'FETCH_TWILIO',
  })
  try {
    const res = await getAxios().post('/twilio/sms-list', { number })
    dispatch({
      type: 'FETCH_TWILIO_SUCCESS',
      payload: {
        type: 'SMS',
        data: mapData(res.data),
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_TWILIO_ERROR',
      payload: {
        error,
      },
    })
  }
}

export const fetchTwilioCalls = (number) => async dispatch => {
  dispatch({
    type: 'FETCH_TWILIO',
  })
  try {
    const res = await getAxios().post('/twilio/voice-list', { number })
    dispatch({
      type: 'FETCH_TWILIO_SUCCESS',
      payload: {
        type: 'Voice calls',
        data: mapData(res.data)
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_TWILIO_ERROR',
      payload: {
        error,
      },
    })
  }
}
