import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { pure } from 'recompose'
import { BASE } from 'theme/base-units'
import { TableRow } from '@material-ui/core'
import { BLUE, PANEL_BLUE, VALR_BLUE_GRAY } from 'theme/colors'

import { LOGO, LOGO_BLUE } from 'theme/images'

import { ForwardedNavLink } from '../ForwardedNavlink'

export const FontSize = styled.div<any>`
  font-size: ${({ fontSize = 16 }) => `${fontSize}px`};
`

export const spinningKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const SimpleContainer = styled.div<any>`
  color: ${({ color = BLUE }) => color};
  font-size: ${({ fontSize = BASE }) => `${fontSize}px`};
  padding: ${({ padding = 0 }) => padding};
  margin: ${({ margin = 0 }) => margin};
  margin-right: ${({ marginRight = 0 }) => marginRight};
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom};

  display: ${({ display = 'block' }) => display};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};

  animation: 0.5s ${({ spinning }) => (spinning ? spinningKeyframes : null)}
    linear 0s infinite;
`

export const BlankLink = styled(Link)<any>`
  text-decoration: none;
  color: ${({ color }) => color || 'unset'};
`

export const BlankNavLink = styled(ForwardedNavLink)<any>`
  text-decoration: none;
  color: ${({ color }) => color || 'unset'};
`

export const LinkIcon = styled.i`
  margin-right: ${BASE}px;
`

export const LinkWithIcon = ({ to }) => (
  <BlankLink to={to} color={PANEL_BLUE}>
    <LinkIcon className="fas fa-link" />
    <span>Show Transactions</span>
  </BlankLink>
)

const LogoContainer = styled.div`
  padding: ${BASE * 5}px;
`
const WelcomeLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LogoImage = styled.img`
  width: ${BASE * 20}px;
  height: auto;
`

const StyledNavLink = styled(ForwardedNavLink)`
  color: ${({ theme }) => theme.palette.primary.main};
`

export const WelcomeLogo = ({ text = '', useTheme = true }) => {
  const theme = localStorage.getItem('adminTheme')
  const handleLogoRendering = () => {
    let logo = LOGO
    if (useTheme) {
      logo = theme === 'lightTheme' ? LOGO_BLUE : LOGO
    }
    return logo
  }
  return (
    <WelcomeLogoContainer>
      <LogoImage src={handleLogoRendering()} />
      {text && <h1>{text}</h1>}
    </WelcomeLogoContainer>
  )
}
export const Logo = ({ to = './', text = '' }) => (
  <LogoContainer>
    <StyledNavLink style={{ textDecoration: 'none' }} to={to}>
      <LogoImage src={LOGO} />
      {text && <h1>{text}</h1>}
    </StyledNavLink>
  </LogoContainer>
)

export const LogoEmpty = () => (
  <LogoContainer>
    <LogoImage src={LOGO} />
  </LogoContainer>
)

export const Padding = styled.div<any>`
  display: block;
  height: ${({ value = BASE }) => `${value}px`};
`

export const FontColor = styled.div`
  color: ${({ color = 'inherit' }) => color};
`

export const ContentContainer = styled.div<any>`
  padding: ${BASE * 3}px;
  box-shadow: 0 2px 5px 1px ${({ theme }) => theme.contentContainer.boxShadow};
  ${({ height }) => height && `height: ${height};`};
`

export const Span = styled.span<any>`
  color: ${({ color }) => color};
`

const StyledHeader = styled.h1<any>`
  color: white;
  background-color: ${({ theme }) => theme.colors.titleBackground};
  margin-top: 2.5%;
  width: 100%;
  border: 6px solid ${({ theme }) => theme.colors.titleBackground};
  border-radius: ${({ rounded }) => (rounded ? 5 : 0)}px;
`

export const StyledAnchor = styled.a`
  text-decoration: none;
`

export const Spacer = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`

export const Header = (props) => {
  const { title, bg, rounded } = props
  return (
    <StyledHeader bg={bg} rounded={rounded}>
      {title}
    </StyledHeader>
  )
}

export const StyledTableRow = styled(TableRow)`
  :hover {
    background-color: ${VALR_BLUE_GRAY};
  }
`

export const ShowStatus = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${BASE * 2.5}px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textColor};
  width: 100%;
`
