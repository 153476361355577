import React from 'react'
import PropTypes from 'prop-types'
import { LEGAL_ENTITY_TYPES } from 'helpers/const'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { StyledCorporateUserTable } from '../../pages/Styles'
import CorporateEntityTableHeadings from '../CorporateEntityTableHeadings'
import LegalEntity from './LegalEntity/LegalEntity'

const LegalCorporateEntities = ({
  entities,
  handleEditEntity,
  entityType = 'PTY',
  isFetchingEntities,
  accountId,
}) => {
  let headings = []

  switch (entityType) {
    case LEGAL_ENTITY_TYPES.PTY:
      headings = [
        'Company Name',
        'Registration Number',
        'Registration Document',
        'Actions',
      ]
      break
    case LEGAL_ENTITY_TYPES.TRUST:
      headings = [
        'Company Name',
        'Registration Number',
        'Letter Of Authority',
        'Registration Document',
        'Actions',
      ]
      break
    case LEGAL_ENTITY_TYPES.OTHER:
      headings = [
        'Company Name',
        'Registration Number',
        'Letter Of Authority',
        'Registration Document',
        'Other',
        'Actions',
      ]
      break

    default:
      break
  }
  const hasEntities = entities.length > 0

  return (
    <>
      {isFetchingEntities && <LoadingBlock />}
      {!hasEntities &&
        !isFetchingEntities && (
          <TablePlaceholder>
            {`No ${entityType.toLocaleLowerCase()} entities exist yet`}
          </TablePlaceholder>
        )}
      {!isFetchingEntities &&
        hasEntities && (
          <StyledCorporateUserTable>
            <CorporateEntityTableHeadings headings={headings} />
            <tbody>
              {entities.map((entity, index) => (
                <LegalEntity
                  accountId={accountId}
                  handleEditEntity={handleEditEntity}
                  key={`${entity.registrationNumber}${index}`}
                  entity={entity}
                />
              ))}
            </tbody>
          </StyledCorporateUserTable>
        )}
    </>
  )
}

LegalCorporateEntities.propTypes = {
  entities: PropTypes.array,
  handleEditEntity: PropTypes.func,
  entityType: PropTypes.oneOf([
    LEGAL_ENTITY_TYPES.PTY,
    LEGAL_ENTITY_TYPES.TRUST,
    LEGAL_ENTITY_TYPES.OTHER,
  ]),
  isFetchingEntities: PropTypes.bool,
}

export default LegalCorporateEntities
