import ErrorToast from 'components/molecules/ErrorToast/index'
import {
  FETCH_USER_COMMENTS_ERROR,
  FETCH_USER_COMMENTS_PROCESS,
  FETCH_USER_COMMENTS_SUCCESS,
  UPDATE_INTERNAL_TRANSFER_COMMENTS_SUCCESS,
  UPDATE_USER_COMMENTS_SUCCESS,
} from './actions'

const initialState = {
  data: [],
  internalTransferComments: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_COMMENTS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        status: 'loading',
        data: [],
      }
    }

    case FETCH_USER_COMMENTS_SUCCESS: {
      const { id, comments, internalTransferComments } = action.payload
      return {
        ...state,
        status: 'done',
        data: comments,
        internalTransferComments,
      }
    }

    case UPDATE_USER_COMMENTS_SUCCESS: {
      const { id, flag } = action.payload
      const updatedData = [...state.data]
      const indexOfCommentFlagStatusToUpdate = updatedData.findIndex(
        commentFlagStatusToUpdate => commentFlagStatusToUpdate.commentId === id,
      )

      if (indexOfCommentFlagStatusToUpdate === -1) {
        return ErrorToast(
          'Update was successfull but an error occurred when updating ui please refresh screen to see updates',
        )
      }

      updatedData[indexOfCommentFlagStatusToUpdate].flag = flag

      return {
        ...state,
        data: updatedData,
      }
    }

    case UPDATE_INTERNAL_TRANSFER_COMMENTS_SUCCESS: {
      const { id, flag } = action.payload
      const updatedData = [...state.internalTransferComments]
      const indexOfCommentFlagStatusToUpdate = updatedData.findIndex(
        commentFlagStatusToUpdate => commentFlagStatusToUpdate.id === id,
      )

      if (indexOfCommentFlagStatusToUpdate === -1) {
        return ErrorToast(
          'Update was successfull but an error occurred when updating ui please refresh screen to see updates',
        )
      }

      updatedData[indexOfCommentFlagStatusToUpdate].flag = flag

      return {
        ...state,
        internalTransferComments: updatedData,
      }
    }

    case FETCH_USER_COMMENTS_ERROR: {
      return {
        ...state,
        status: 'done',
      }
    }
    default:
      return state
  }
}
