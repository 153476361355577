import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { fetchAccountRiskStatus } from 'redux/marginStatus/actions'

export const submitAccountMarginStatus = async (values, dispatch) => {
  const { fieldName, id, marginEnabled } = values
  let res = null
  try {
    res = await getAxios().put('/account/risk-controls', {
      accountId: id,
      accountStatusFieldName: fieldName,
      value: marginEnabled,
    })
    toast('Margin status updated', {
      type: toast.TYPE.SUCCESS,
      autoClose: 1500,
    })
    dispatch(fetchAccountRiskStatus(id))
  } catch (e) {
    ErrorToast(e)
  }
  return res
}
