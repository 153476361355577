import styled from 'styled-components'
import { BASE } from 'theme/base-units'

export const StyledCorporateUserTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`

export const StyledEditEntityForm = styled.div`
  width: 100%;
`

export const StyledCorporateTypes = styled.div`
  display: grid;
  grid-template-columns: 60% 10% 15% 15%;
  margin-bottom: ${BASE}px;
  padding: 2px;
  font-size: 1em;
  align-items: center;
`

export const StyledCorporateActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
`

export const StyledDocumentContentWrapper = styled.div`
  display: flex;
  height: 500px;
  width: 500px;
  justify-content: center;
  img {
    height: 400px;
    object-fit: contain;
  }
`

export const StyledImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  width: 500px;
`
