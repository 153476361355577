import React, { useState, useEffect, useRef } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import { disconnectSocket } from 'redux/fiat/actions'
import socket from 'redux/socket/index'

import RenderAllDeposits from './AllDeposits'
import RenderAllWithdrawals from './AllWithdrawals'

import LoadingBlock from '../../components/molecules/LoadingBlock'
import Trading from 'containers/Trading'

const routes = [
  '/exchange/deposits',
  '/exchange/fiat-withdrawals',
  '/exchange/trading',
]

const Exchange = props => {
  const ref = useRef()
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
    depositReview,
    newDeposits,
    withdrawalsUnderReview,
    unresolvedWithdraws,
    cryptoWithdrawalsUnderReview,
    wiredDepositReview,
    wiredDepositPendingReview,
    wiredWithdrawalReview,
    fiatConfigLoading,
  } = props
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }
  useEffect(
    () => {
      if (
        !ref.current &&
        (depositReview.status === 'done' ||
          newDeposits.status === 'done' ||
          wiredDepositReview.status === 'done' ||
          wiredWithdrawalReview.status === 'done' ||
          (wiredDepositPendingReview.status === 'done' &&
            (withdrawalsUnderReview.status === 'done' &&
              unresolvedWithdraws.status === 'done' &&
              cryptoWithdrawalsUnderReview.status === 'done')))
      ) {
        ref.current = 'something'
        window.addEventListener('beforeunload', disconnectSocket)
        socket.connect()
      }
    },
    [
      depositReview,
      newDeposits,
      withdrawalsUnderReview,
      unresolvedWithdraws,
      cryptoWithdrawalsUnderReview,
      wiredDepositReview,
      wiredDepositPendingReview,
      wiredWithdrawalReview,
    ],
  )

  useEffect(
    () => () => {
      disconnectSocket()
      window.removeEventListener('beforeunload', disconnectSocket)
    },
    [],
  )

  useEffect(
    () => {
      let pathnamePostfix = pathname.split('/')[2]
      if (!pathnamePostfix) pathnamePostfix = pathname.split('/')[1]
      const routeToSet = routes.find(route => route.includes(pathnamePostfix))
      if (routeToSet) {
        setCurrentPage(routeToSet)
      }
    },
    [pathname],
  )

  if (fiatConfigLoading) return <LoadingBlock />

  return (
    <>
      <h1>Exchange</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="Deposits"
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label="Withdrawals"
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
        <NavigationTab
          value={routes[2]}
          label="Trading"
          component={ForwardedNavLink}
          to={routes[2]}
          exact
        />
        Trading
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={RenderAllDeposits} />
          <Route path={routes[1]} component={RenderAllWithdrawals} />
          <Route path={routes[2]} component={Trading} />
        </Switch>
      </ContentContainer>
    </>
  )
}

const mapStateToProps = state => ({
  depositReview: state.fiat.suspendedDeposits,
  withdrawalsUnderReview: state.fiat.withdrawalsUnderReview,
  unresolvedWithdraws: state.fiat.unresolvedWithdraws,
  newDeposits: state.fiat.newDeposits,
  cryptoWithdrawalsUnderReview: state.crypto.withdrawalsUnderReview,
  wiredDepositReview: state.circle.wiredDepositReview,
  wiredDepositPendingReview: state.circle.wiredDepositPendingReview,
  wiredWithdrawalReview: state.circle.updateWithdrawalReview,
  fiatConfigLoading: state.fiat.config.loading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      disconnectSocket,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Exchange)
