import React, { useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import Row from './row'
import { Headers } from './headers'

export const KycOverrideTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = uniqueId =>
    setSelectedRow(prev => (prev === uniqueId ? '' : uniqueId))
  const { expandItem, data } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(kycInfoDualAuth => {
          kycInfoDualAuth.createdAt = moment(kycInfoDualAuth.createdAt).format(
            'YYYY-MMM-DD HH:mm:ss',
          )

          return (
            <Row
              key={kycInfoDualAuth.id}
              dualAuthId={kycInfoDualAuth.id}
              kycInfoDualAuth={kycInfoDualAuth}
              isSelected={selectedRow === kycInfoDualAuth.id}
              toggleSelected={toggleSelectedRow}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

export default KycOverrideTable
