import moment from 'moment'

import {
  DOWNLOAD_PENDING_WITHDRAWS_ERROR,
  DOWNLOAD_PENDING_WITHDRAWS_PROCESS,
  DOWNLOAD_PENDING_WITHDRAWS_SUCCESS,
  FETCH_FIAT_DEPOSIT_REVIEW_ERROR,
  FETCH_FIAT_DEPOSIT_REVIEW_PROCESS,
  FETCH_FIAT_DEPOSIT_REVIEW_SUCCESS,
  FETCH_FIAT_DEPOSIT_STATUSES_PROCESS,
  FETCH_FIAT_DEPOSIT_STATUSES_SUCCESS,
  FETCH_FIAT_DEPOSIT_STATUSES_ERROR,
  FETCH_FIAT_DEPOSIT_BY_STATUS_PROCESS,
  FETCH_FIAT_DEPOSIT_BY_STATUS_SUCCESS,
  FETCH_FIAT_DEPOSIT_BY_STATUS_ERROR,
  FETCH_FIAT_PENDING_WITHDRAWS_ERROR,
  FETCH_FIAT_PENDING_WITHDRAWS_PROCESS,
  FETCH_FIAT_PENDING_WITHDRAWS_SUCCESS,
  FETCH_FIAT_UNRESOLVED_WITHDRAWS_ERROR,
  FETCH_FIAT_UNRESOLVED_WITHDRAWS_PROCESS,
  FETCH_FIAT_UNRESOLVED_WITHDRAWS_SUCCESS,
  FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_ERROR,
  FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_PROCESS,
  FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
  UPDATE_NOTES_FOR_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
  FETCH_NEW_FIAT_DEPOSITS_ERROR,
  FETCH_NEW_FIAT_DEPOSITS_PROCESS,
  FETCH_NEW_FIAT_DEPOSITS_SUCCESS,
  RESOLVE_FIAT_WITHDRAWS_ERROR,
  RESOLVE_FIAT_WITHDRAWS_PROCESS,
  RESOLVE_FIAT_WITHDRAWS_SUCCESS,
  PENDING_AUTHORISATION_UNDER_DEPOSITS,
  SAVE_CHECKED_WITHDRAWALS,
  TOTAL_AMOUNT_OF_CHECKED_WITHDRAWALS,
  RESET_FIAT_DEPOSIT_BY_STATUS,
  FETCH_SUSPENDED_DEPOSIT_PROCESS,
  FETCH_SUSPENDED_DEPOSIT_SUCCESS,
  FETCH_SUSPENDED_DEPOSIT_ERROR,
  REMOVE_SUSPENDED_DEPOSIT_PROCESS,
  REMOVE_SUSPENDED_DEPOSIT_SUCCESS,
  UPDATE_SUSPENDED_DEPOSIT,
  REMOVE_SUSPENDED_DEPOSIT_ERROR,
  APPROVE_SUSPENDED_DEPOSIT,
  REJECT_SUSPENDED_DEPOSIT,
  REJECT_SUSPENDED_DEPOSIT_ERROR,
  FETCH_NOTES_TAG_PROCESS,
  FETCH_NOTES_TAG_SUCCESS,
  FETCH_NOTES_TAG_ERROR,
  UPDATE_DEPOSIT_SUCCESS,
  UPDATE_WITHDRAWAL_SUCCESS,
  UPDATE_UNRESLOVED_WITHDRAWAL_SUCCESS,
  BULK_ACTION_DEPOSITS_START,
  BULK_ACTION_DEPOSITS_SUCCESS,
  BULK_ACTION_DEPOSITS_ERROR,
  REVERSE_PENDING_FIAT_WITHDRAWALS_START,
  REVERSE_PENDING_FIAT_WITHDRAWALS_SUCCESS,
  REVERSE_PENDING_FIAT_WITHDRAWALS_ERROR,
  MOVE_FIAT_WITHDRAWAL_TO_PENDING_START,
  MOVE_FIAT_WITHDRAWAL_TO_PENDING_SUCCESS,
  MOVE_FIAT_WITHDRAWAL_TO_PENDING_ERROR,
  CHECKOUT_PENDING_FIAT_WITHDRAWALS_START,
  CHECKOUT_PENDING_FIAT_WITHDRAWALS_SUCCESS,
  CHECKOUT_PENDING_FIAT_WITHDRAWALS_ERROR,
  SET_FILTERS_SUCCESS,
  REMOVE_RELEASED_FIAT_DIPOSITS,
  REMOVE_RELEASED_FIAT_DIPOSITS_ERROR,
  UPDATE_NEW_FIAT_DEPOSIT_SUCCESS,
  UPDATE_NEW_FIAT_DEPOSIT_ERROR,
  FETCH_HISTORICAL_FIAT_DEPOSITS_PROCESS,
  FETCH_HISTORICAL_FIAT_DEPOSITS_SUCCESS,
  FETCH_HISTORICAL_FIAT_DEPOSITS_ERROR,
  SET_HISTORICAL_DEPOSIT_FILTERS_START,
  SET_HISTORICAL_DEPOSIT_FILTERS_SUCCESS,
  SET_HISTORICAL_DEPOSIT_FILTERS_ERROR,
  CLEAR_HISTORICAL_DEPOSITS_LIST,
  SET_FILTERS_START,
  SET_FILTERS_ERROR,
  FETCH_FIAT_CONFIG_START,
  FETCH_FIAT_CONFIG_ERROR,
  FETCH_FIAT_CONFIG_SUCCESS,
  SET_FIAT_FILTER_WITHOUT_REFRESH,
  FETCH_SUSPENDED_WITHDRWALS_PROCESS,
  FETCH_SUSPENDED_WITHDRWALS_SUCCESS,
  FETCH_SUSPENDED_WITHDRWALS_ERROR,
  UPDATE_NOTES_FOR_SUSPENDED_WITHDRAWALS_SUCCESS,
  UPDATE_DEPOSIT_ZENDESK_MAIL_SENT_SUCCESS,
} from './actions'
import { BankSources } from './constants'

const defaultFilters = {
  from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  to: moment().add(2, 'days').format('YYYY-MM-DD'),
  currency: 'ZAR',
  bankAccount: 'STANDARD_BANK_ZA',
}

const defaultHistoricalFilters = {
  from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  fetchMoreData: false,
}

const defaultConfig = {
  currencies: [
    { shortName: 'ZAR', longName: 'South African Rand' },
    { shortName: 'ZMW', longName: 'Zambian Kwacha' },
  ],
  bankAccounts: [
    { ...BankSources.STANDARD_BANK_ZA },
    { ...BankSources.STANBIC_ZM },
  ],
}

const initialState = {
  depositReview: {
    status: 'pristine',
    lastUpdatedTime: null,
    data: [],
    hasAllData: false,
  },
  suspendedDeposits: {
    status: 'pristine',
    data: [],
  },
  removeSuspendedDeposits: {
    status: 'pristine',
    data: [],
  },
  newDeposits: {
    status: 'pristine',
    data: [],
  },
  depositStatuses: {
    status: 'pristine',
    statuses: [],
  },
  notesTag: {
    status: 'pristine',
    statuses: [],
  },
  depositsByStatus: {
    status: 'pristine',
    data: [],
  },
  unresolvedWithdraws: {
    status: 'pristine',
    data: [],
  },
  resolveWithdraw: {
    loading: false,
    error: null,
    success: false,
  },
  withdrawalsUnderReview: {
    status: 'pristine',
    data: [],
  },
  pendingWithdraws: {
    status: 'pristine',
    isReversingWithdrawals: false,
    isCheckingOutWithdrawals: false,
    data: [],
  },
  download: {
    loading: false,
    error: null,
    success: false,
  },
  pendingAuthorisationDeposits: {
    status: 'done',
    data: [],
  },
  bulkRejectDeposits: {
    loading: false,
    success: false,
  },
  selectedWithdrawals: {
    data: [],
    readyForDownload: [],
  },
  historicalDeposits: {
    data: [],
    status: 'pristine',
  },
  filters: { ...defaultFilters },
  historicalFilters: { ...defaultHistoricalFilters },
  isUpdatingFilters: false,
  isUpdatingHistoricalDepositFilters: false,
  config: {
    loading: false,
    data: {
      ...defaultConfig,
    },
  },

  suspendedWithdrawals: {
    data: [],
    status: 'pristine',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BULK_ACTION_DEPOSITS_START: {
      return {
        ...state,
        bulkRejectDeposits: {
          ...state.bulkRejectDeposits,
          loading: true,
          success: false,
        },
      }
    }

    case BULK_ACTION_DEPOSITS_SUCCESS: {
      return {
        ...state,
        bulkRejectDeposits: {
          loading: false,
          success: true,
        },
      }
    }

    case BULK_ACTION_DEPOSITS_ERROR: {
      return {
        ...state,
        bulkRejectDeposits: {
          ...initialState.bulkRejectDeposits,
        },
      }
    }
    case FETCH_FIAT_DEPOSIT_REVIEW_PROCESS: {
      return {
        ...state,
        depositReview: {
          ...state.depositReview,
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_REVIEW_SUCCESS: {
      const { data, query } = action.payload
      const hasAllData = query === 'fetchAll'
      return {
        ...state,
        depositReview: {
          ...state.depositReview,
          status: 'done',
          data,
          hasAllData,
        },
      }
    }

    case UPDATE_DEPOSIT_SUCCESS: {
      const { data } = action.payload
      const updatedDeposits =
        state.suspendedDeposits &&
        state.suspendedDeposits.data &&
        state.suspendedDeposits.data.map((dat) => {
          if (data && data.length > 0) {
            data.some((deposit) => {
              if (deposit.depositId === dat.depositId) {
                dat.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              }
              dat.active = { activePersonName: '', userActive: false }
            })
          } else {
            dat.active = { activePersonName: '', userActive: false }
          }
          return dat
        })
      return {
        ...state,
        suspendedDeposits: { status: 'done', data: updatedDeposits },
      }
    }

    case FETCH_SUSPENDED_DEPOSIT_PROCESS: {
      return {
        ...state,
        suspendedDeposits: {
          ...state.suspendedDeposits,
        },
      }
    }

    case FETCH_SUSPENDED_DEPOSIT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        suspendedDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_SUSPENDED_DEPOSIT_ERROR: {
      return {
        ...state,
        suspendedDeposits: {
          status: 'error',
          data: [],
        },
      }
    }

    case REMOVE_SUSPENDED_DEPOSIT_PROCESS: {
      return {
        ...state,
        removeSuspendedDeposits: {
          status: 'loading',
          data: [],
        },
      }
    }

    case REMOVE_SUSPENDED_DEPOSIT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        removeSuspendedDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case UPDATE_SUSPENDED_DEPOSIT: {
      const { removeDeposit, description } = action.payload
      const data = state.suspendedDeposits.data.map((deposit) => {
        if (deposit.depositId === removeDeposit) {
          deposit.suspended = true
          deposit.depositDescription = description
        }
        return deposit
      })
      return {
        ...state,
        suspendedDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case REJECT_SUSPENDED_DEPOSIT: {
      const { rejectedId } = action.payload
      const data = state.suspendedDeposits.data.filter(
        (deposits) => deposits.depositId !== rejectedId,
      )
      return {
        ...state,
        suspendedDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case REMOVE_RELEASED_FIAT_DIPOSITS: {
      const { depositsIds } = action.payload
      const data = state.newDeposits.data.filter(
        (deposits) =>
          !depositsIds.find(
            (removedDepositsIds) => removedDepositsIds === deposits.depositId,
          ),
      )
      return {
        ...state,
        newDeposits: {
          ...state.newDeposits,
          status: 'done',
          data,
        },
      }
    }

    case REMOVE_RELEASED_FIAT_DIPOSITS_ERROR: {
      return {
        ...state,
        newDeposits: {
          ...state.newDeposits,
          status: 'error',
          data: [],
        },
      }
    }

    case REJECT_SUSPENDED_DEPOSIT_ERROR: {
      return {
        ...state,
        data: [],
      }
    }

    case APPROVE_SUSPENDED_DEPOSIT: {
      const { removeDeposit } = action.payload
      const data = state.suspendedDeposits.data.map((deposit) => {
        if (deposit.depositId === removeDeposit) {
          deposit.suspended = false
          deposit.description = ''
        }
        return deposit
      })
      return {
        ...state,
        suspendedDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case REMOVE_SUSPENDED_DEPOSIT_ERROR: {
      return {
        ...state,
        status: 'error',
        data: [],
      }
    }

    case FETCH_FIAT_DEPOSIT_REVIEW_ERROR: {
      return {
        ...state,
        depositReview: {
          status: 'error',
          data: [],
        },
      }
    }

    case FETCH_NEW_FIAT_DEPOSITS_PROCESS: {
      return {
        ...state,
        newDeposits: {
          ...state.newDeposits,
          status: 'loading',
        },
      }
    }

    case FETCH_NEW_FIAT_DEPOSITS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        newDeposits: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_NEW_FIAT_DEPOSITS_ERROR: {
      return {
        ...state,
        newDeposits: {
          status: 'error',
          data: [],
        },
      }
    }

    case UPDATE_NEW_FIAT_DEPOSIT_SUCCESS: {
      const { data } = action.payload
      const updatedDeposits =
        state.newDeposits &&
        state.newDeposits.data &&
        state.newDeposits.data.map((fiatDeposit) => {
          if (data && data.length > 0) {
            data.some((deposit) => {
              if (deposit.depositId === fiatDeposit.depositId) {
                fiatDeposit.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              }
              fiatDeposit.active = { activePersonName: '', userActive: false }
            })
          } else {
            fiatDeposit.active = { activePersonName: '', userActive: false }
          }
          return fiatDeposit
        })

      return {
        ...state,
        newDeposits: {
          ...state.newDeposits,
          status: 'done',
          data: updatedDeposits,
        },
      }
    }

    case UPDATE_NEW_FIAT_DEPOSIT_ERROR: {
      return {
        ...state,
        newDeposits: {
          ...state.newDeposits,
          status: 'error',
          data: [],
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_STATUSES_PROCESS: {
      return {
        ...state,
        depositStatuses: {
          status: 'loading',
          statuses: [],
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_STATUSES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        depositStatuses: {
          status: 'done',
          statuses: data,
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_STATUSES_ERROR: {
      return {
        ...state,
        depositStatuses: {
          status: 'error',
          statuses: [],
        },
      }
    }

    case FETCH_NOTES_TAG_PROCESS: {
      return {
        ...state,
        notesTag: {
          status: 'loading',
          statuses: [],
        },
      }
    }

    case FETCH_NOTES_TAG_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        notesTag: {
          status: 'done',
          statuses: data,
        },
      }
    }

    case FETCH_NOTES_TAG_ERROR: {
      return {
        ...state,
        notesTag: {
          status: 'error',
          statuses: [],
        },
      }
    }

    case FETCH_HISTORICAL_FIAT_DEPOSITS_PROCESS: {
      return {
        ...state,
        historicalDeposits: {
          ...state.historicalDeposits,
          status: 'loading',
        },
      }
    }

    case FETCH_HISTORICAL_FIAT_DEPOSITS_SUCCESS: {
      const { data, hasMoreRecords, skip, limit, fetchMoreData } =
        action.payload
      const existingData = state.historicalDeposits.data
        ? state.historicalDeposits.data
        : []
      return {
        ...state,
        historicalDeposits: {
          ...state.historicalDeposits,
          status: 'done',
          data: fetchMoreData ? [...existingData, ...data] : data,
          skip,
          limit,
          hasMoreRecords,
        },
      }
    }

    case FETCH_HISTORICAL_FIAT_DEPOSITS_ERROR: {
      return {
        ...state,
        historicalDeposits: {
          ...state.historicalDeposits,
          status: 'loading',
        },
      }
    }

    case CLEAR_HISTORICAL_DEPOSITS_LIST: {
      return {
        ...state,
        historicalDeposits: initialState.historicalDeposits,
        historicalFilters: initialState.historicalFilters,
      }
    }

    case FETCH_FIAT_DEPOSIT_BY_STATUS_PROCESS: {
      return {
        ...state,
        depositsByStatus: {
          ...state.depositsByStatus,
          status: 'loading',
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_BY_STATUS_SUCCESS: {
      const { data, hasMoreRecords, skip, limit } = action.payload
      const existingData = state.depositsByStatus.data
        ? state.depositsByStatus.data
        : []
      return {
        ...state,
        depositsByStatus: {
          status: 'done',
          data: [...existingData, ...data],
          skip,
          limit,
          hasMoreRecords,
        },
      }
    }

    case FETCH_FIAT_DEPOSIT_BY_STATUS_ERROR: {
      return {
        ...state,
        depositsByStatus: {
          status: 'done',
          data: [],
        },
      }
    }

    case RESET_FIAT_DEPOSIT_BY_STATUS: {
      return {
        ...state,
        depositsByStatus: {
          status: 'pristine',
          data: [],
        },
      }
    }

    case FETCH_FIAT_UNRESOLVED_WITHDRAWS_PROCESS: {
      return {
        ...state,
        unresolvedWithdraws: {
          status: 'loading',
        },
      }
    }

    case FETCH_FIAT_UNRESOLVED_WITHDRAWS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        unresolvedWithdraws: {
          status: 'done',
          data,
        },
      }
    }

    case PENDING_AUTHORISATION_UNDER_DEPOSITS: {
      const data = action.payload
      return {
        ...state,
        pendingAuthorisationDeposits: {
          status: 'done',
          data,
        },
      }
    }
    case FETCH_FIAT_UNRESOLVED_WITHDRAWS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        unresolvedWithdraws: {
          status: 'done',
          data: [],
          error,
        },
      }
    }

    case FETCH_FIAT_PENDING_WITHDRAWS_PROCESS: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          status: 'loading',
        },
        selectedWithdrawals: {
          ...state.selectedWithdrawals,
          readyForDownload: [],
        },
      }
    }

    case FETCH_FIAT_PENDING_WITHDRAWS_SUCCESS: {
      const { data, byBank } = action.payload
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          status: 'done',
          data,
          byBank,
        },
      }
    }

    case FETCH_FIAT_PENDING_WITHDRAWS_ERROR: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          status: 'done',
          data: [],
        },
      }
    }
    case MOVE_FIAT_WITHDRAWAL_TO_PENDING_START: {
      return {
        ...state,
      }
    }

    case MOVE_FIAT_WITHDRAWAL_TO_PENDING_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          data: [...state.pendingWithdraws.data, data],
        },
      }
    }

    case MOVE_FIAT_WITHDRAWAL_TO_PENDING_ERROR: {
      return { ...state }
    }

    case FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_PROCESS: {
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'loading',
        },
      }
    }

    case FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'done',
          data: data.filter((withdrawals) => !withdrawals.suspended),
        },
      }
    }

    case UPDATE_NOTES_FOR_WITHDRAWALS_UNDER_REVIEW_SUCCESS: {
      const { withdrawalId } = action.payload
      const updatedWithdrawalsData =
        state.withdrawalsUnderReview &&
        state.withdrawalsUnderReview.data &&
        state.withdrawalsUnderReview.data.map((withdrawal) => {
          if (withdrawalId === withdrawal.withdrawalId) {
            withdrawal.tag = true
          }
          return withdrawal
        })
      return {
        ...state,
        withdrawalsUnderReview: {
          ...state.withdrawalsUnderReview,
          data: updatedWithdrawalsData,
          status: 'done',
        },
      }
    }

    case UPDATE_NOTES_FOR_SUSPENDED_WITHDRAWALS_SUCCESS: {
      const { withdrawalId } = action.payload
      const updatedWithdrawalsData =
        state.suspendedWithdrawals &&
        state.suspendedWithdrawals.data &&
        state.suspendedWithdrawals.data.map((withdrawal) => {
          if (withdrawalId === withdrawal.withdrawalId) {
            withdrawal.tag = true
          }
          return withdrawal
        })
      return {
        ...state,
        suspendedWithdrawals: {
          ...state.suspendedWithdrawals,
          data: updatedWithdrawalsData,
          status: 'done',
        },
      }
    }

    case UPDATE_WITHDRAWAL_SUCCESS: {
      const { data } = action.payload
      const updatedWithrawal =
        state.withdrawalsUnderReview &&
        state.withdrawalsUnderReview.data &&
        state.withdrawalsUnderReview.data.map((dat) => {
          if (data && data.length > 0) {
            data.some((deposit) => {
              if (deposit.withdrawalId === dat.withdrawalId) {
                dat.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              }
              dat.active = { activePersonName: '', userActive: false }
            })
          } else {
            dat.active = { activePersonName: '', userActive: false }
          }
          return dat
        })
      return {
        ...state,
        withdrawalsUnderReview: { status: 'done', data: updatedWithrawal },
      }
    }

    case UPDATE_UNRESLOVED_WITHDRAWAL_SUCCESS: {
      const { data } = action.payload
      const updatedWithrawal =
        state.unresolvedWithdraws &&
        state.unresolvedWithdraws.data &&
        state.unresolvedWithdraws.data.map((dat) => {
          if (data && data.length > 0) {
            data.some((deposit) => {
              if (deposit.withdrawalId === dat.withdrawalId) {
                dat.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              }
              dat.active = { activePersonName: '', userActive: false }
            })
          } else {
            dat.active = { activePersonName: '', userActive: false }
          }
          return dat
        })
      return {
        ...state,
        unresolvedWithdraws: { status: 'done', data: updatedWithrawal },
      }
    }

    case FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_ERROR: {
      return {
        ...state,
        withdrawalsUnderReview: {
          status: 'done',
          data: [],
        },
      }
    }

    case RESOLVE_FIAT_WITHDRAWS_PROCESS: {
      return {
        ...state,
        resolveWithdraw: { loading: true, success: false },
      }
    }

    case RESOLVE_FIAT_WITHDRAWS_SUCCESS: {
      const updatedUnresolvedWithdrawals =
        state.unresolvedWithdraws.data.filter(
          (withdrawal) =>
            !action.payload.some(
              (rw) => rw.withdrawalId === withdrawal.withdrawalId,
            ),
        )
      return {
        ...state,
        unresolvedWithdraws: {
          ...state.unresolvedWithdraws,
          data: updatedUnresolvedWithdrawals,
        },
        resolveWithdraw: { loading: false, success: true },
      }
    }

    case RESOLVE_FIAT_WITHDRAWS_ERROR: {
      return {
        ...state,
        resolveWithdraw: {
          error: action.error,
          loading: false,
          success: false,
        },
      }
    }

    case DOWNLOAD_PENDING_WITHDRAWS_PROCESS: {
      return {
        ...state,
        download: { loading: true, success: false },
      }
    }

    case DOWNLOAD_PENDING_WITHDRAWS_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        download: { data, loading: false, success: true },
      }
    }

    case DOWNLOAD_PENDING_WITHDRAWS_ERROR: {
      return {
        ...state,
        download: { error: action.error, loading: false, success: false },
      }
    }
    case SAVE_CHECKED_WITHDRAWALS: {
      const data = action.payload
      return {
        ...state,
        selectedWithdrawals: {
          ...state.selectedWithdrawals,
          data,
        },
      }
    }
    case TOTAL_AMOUNT_OF_CHECKED_WITHDRAWALS: {
      const value = action.payload
      return {
        ...state,
        selectedWithdrawalsTotalAmount: {
          value,
        },
      }
    }

    case REVERSE_PENDING_FIAT_WITHDRAWALS_START: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          isReversingWithdrawals: true,
        },
      }
    }

    case REVERSE_PENDING_FIAT_WITHDRAWALS_SUCCESS: {
      const data = action.payload
      const newData = state.pendingWithdraws.data.filter(
        (pendingWithdraw) =>
          !data.find(
            (result) => result.withdrawalId === pendingWithdraw.withdrawalId,
          ),
      )

      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          data: newData,
          isReversingWithdrawals: false,
        },
        selectedWithdrawals: { readyForDownload: [], data: [] },
      }
    }

    case REVERSE_PENDING_FIAT_WITHDRAWALS_ERROR: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          isReversingWithdrawals: false,
        },
      }
    }

    case CHECKOUT_PENDING_FIAT_WITHDRAWALS_START: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          isCheckingOutWithdrawals: true,
        },
        selectedWithdrawals: {
          ...state.selectedWithdrawals,
          readyForDownload: [],
        },
      }
    }

    case CHECKOUT_PENDING_FIAT_WITHDRAWALS_SUCCESS: {
      const data = action.payload
      // Filter out withdrawals that were checked out
      const newData = state.pendingWithdraws.data.filter(
        (pendingWithdraw) =>
          !data.find(
            (result) => result.withdrawalId === pendingWithdraw.withdrawalId,
          ),
      )
      // Filter by withdrawals that were checked out
      const readyForDownload = state.pendingWithdraws.data.filter(
        (pendingWithdraw) =>
          data.find(
            (result) => result.withdrawalId === pendingWithdraw.withdrawalId,
          ),
      )

      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          data: newData,
          isCheckingOutWithdrawals: false,
        },
        selectedWithdrawals: { data: [], readyForDownload },
      }
    }

    case CHECKOUT_PENDING_FIAT_WITHDRAWALS_ERROR: {
      return {
        ...state,
        pendingWithdraws: {
          ...state.pendingWithdraws,
          isCheckingOutWithdrawals: false,
        },
        selectedWithdrawals: {
          ...state.selectedWithdrawals,
          readyForDownload: [],
        },
      }
    }

    case SET_FILTERS_START: {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        isUpdatingFilters: true,
      }
    }
    case SET_FILTERS_SUCCESS: {
      return {
        ...state,
        isUpdatingFilters: false,
      }
    }
    case SET_FILTERS_ERROR: {
      return {
        ...state,
        isUpdatingFilters: false,
      }
    }

    case SET_FIAT_FILTER_WITHOUT_REFRESH: {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    }

    case SET_HISTORICAL_DEPOSIT_FILTERS_START: {
      return {
        ...state,
        historicalFilters: { ...state.historicalFilters, ...action.payload },
        isUpdatingHistoricalDepositFilters: true,
      }
    }

    case SET_HISTORICAL_DEPOSIT_FILTERS_SUCCESS: {
      return {
        ...state,
        isUpdatingHistoricalDepositFilters: false,
      }
    }

    case SET_HISTORICAL_DEPOSIT_FILTERS_ERROR: {
      return {
        ...state,
        isUpdatingHistoricalDepositFilters: false,
      }
    }

    case FETCH_FIAT_CONFIG_START: {
      return {
        ...state,
        config: {
          ...state.config,
          loading: true,
        },
      }
    }

    case FETCH_FIAT_CONFIG_SUCCESS: {
      const payload = action.payload || defaultConfig
      return {
        ...state,
        config: {
          ...state.config,
          data: { ...payload },
          loading: false,
        },
      }
    }

    case FETCH_FIAT_CONFIG_ERROR: {
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
        },
      }
    }

    case FETCH_SUSPENDED_WITHDRWALS_PROCESS: {
      return {
        ...state,
        suspendedWithdrawals: {
          status: 'loading',
        },
      }
    }

    case FETCH_SUSPENDED_WITHDRWALS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        suspendedWithdrawals: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_SUSPENDED_WITHDRWALS_ERROR: {
      return {
        ...state,
        suspendedWithdrawals: {
          status: 'error',
        },
      }
    }

    case UPDATE_DEPOSIT_ZENDESK_MAIL_SENT_SUCCESS: {
      const updatedSuspendedDepositsData = [...state.suspendedDeposits.data]
      const indexOfSuspendedDepositToUpdate =
        updatedSuspendedDepositsData.findIndex(
          (deposit) => deposit.depositId === action.payload.depositId,
        )
      if (indexOfSuspendedDepositToUpdate > -1) {
        updatedSuspendedDepositsData[
          indexOfSuspendedDepositToUpdate
        ].depositZendeskEmailSent = action.payload.depositZendeskEmailSent
        updatedSuspendedDepositsData[indexOfSuspendedDepositToUpdate].tag =
          action.payload.tag
      }
      return {
        ...state,
        suspendedDeposits: {
          ...state.suspendedDeposits,
          data: updatedSuspendedDepositsData,
        },
      }
    }

    default:
      return state
  }
}
