import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { reset } from 'redux-form'
import { updateNotesForCryptoWithdrawals } from 'redux/crypto/actions'
import { updateNotesForWithdrawalsUnderReview, updateNotesForSuspendedWithdrawals } from 'redux/fiat/actions'

export const submit = async (values, dispatch, isfiatWithdrawal, isSuspended) => {
  const {
    withdrawalId, data, notes,
  } = values
  data.notes = notes
  try {
    if (isfiatWithdrawal) {
      await getAxios().post('/withdrawal/fiat/notes', { withdrawalId, data })
      toast('Notes successfully added', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      if (isSuspended) {
        dispatch(updateNotesForSuspendedWithdrawals(withdrawalId))
      } else {
        dispatch(updateNotesForWithdrawalsUnderReview(withdrawalId))
      }
    } else {
      await getAxios().post('/withdrawal/crypto/notes', { withdrawalId, data })
      toast('Notes successfully added', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      dispatch(updateNotesForCryptoWithdrawals(withdrawalId))
    }
    dispatch(reset(`withdrawalNotesForm+${withdrawalId}`))
  } catch (e) {
    toast(`Failed to add a note,|| ${e}`, {
      type: toast.TYPE.ERROR,
      autoClose: 4000,
    })
  }
}
