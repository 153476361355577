import React, { FC, Fragment, useState } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import AccountMarginTradeFiatForm from 'forms/UpdateAccountMarginFiatTrade'
import {
  Details,
  InfoBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'

import { oppositeColor, capitalizeFirstLetter } from '../../../helpers/utils'

interface FuturesProps {
  id: string
  accountMarginTradeStatus: {
    status: string
    canMarginTradeFiat: boolean
  }
  notAuthorized: boolean
}

const EnableAccountMarginTradeFiat: FC<FuturesProps> = props => {
  const [formShown, setFormShown] = useState(false)
  const {
    accountMarginTradeStatus: { status, canMarginTradeFiat },
    id,
    notAuthorized,
  } = props
  const toggleForm = () => {
    setFormShown(!formShown)
  }

  return (
    <Fragment>
      <Details>
        {status === 'loading' && <IconLoading color={'black'} />}
        {status === 'done' && (
          <Fragment>
            {!formShown && (
              <MuiButton
                onClick={toggleForm}
                disabled={notAuthorized}
                showToolTip={notAuthorized}
                tooltipTitle={
                  notAuthorized
                    ? 'You are not authorized to perform this action'
                    : ''
                }
              >
                    Change Margin Trade Fiat Status
              </MuiButton>
            )}
          </Fragment>
        )}
        {formShown &&
              status === 'done' && (
          <AccountMarginTradeFiatForm
            initialValues={{ id, canMarginTradeFiat }}
            onCancel={toggleForm}
            form={'changeFeaturesStatus'}
            onSubmitSuccess={toggleForm}
          />
        )}
      </Details>
      <InfoBlock
        label={'Can Margin Trade Fiat'}
        value={capitalizeFirstLetter(canMarginTradeFiat.toString())}
        color={oppositeColor(canMarginTradeFiat)}
      />
    </Fragment>
  )
}

export default EnableAccountMarginTradeFiat
