import ErrorToast from 'components/molecules/ErrorToast/index'
export const GET_ORDER_TYPES_FOR_CURRENCY_PAIR = 'getOrderTypesForCurrencyPair'

export const UPDATE_ORDER_TYPES_START = 'UPDATE_ORDER_TYPES_START'
export const UPDATE_ORDER_TYPES_SUCCESS = 'UPDATE_ORDER_TYPES_SUCCESS'
export const UPDATE_ORDER_TYPES_ERROR = 'UPDATE_ORDER_TYPES_ERROR'

export const RESET_UPDATED_ORDER_TYPES_LIST = 'RESET_UPDATED_ORDER_TYPES_LIST'

export const getOrderTypesForCurrencyPair = data => dispatch => {
  dispatch({
    type: GET_ORDER_TYPES_FOR_CURRENCY_PAIR,
    payload: {
      data,
    },
  })
}

export const resetUpdatedOrderTypesList = () => dispatch => {
  dispatch({
    type: RESET_UPDATED_ORDER_TYPES_LIST,
  })
}

export const updateOrderTypes = updatedOrderType => dispatch => {
  try {
    dispatch({
      type: UPDATE_ORDER_TYPES_START,
    })
    dispatch({
      type: UPDATE_ORDER_TYPES_SUCCESS,
      payload: updatedOrderType,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_ORDER_TYPES_START,
    })
  }
}
