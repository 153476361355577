import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import auth from 'services/Auth'
import {
  TablePlaceholder,
  TitleContainer,
  Title,
} from 'components/atoms/Table/styles'
import moment from 'moment'
import { getAllMfas } from 'redux/getAllMfas/actions'
import { BASE } from 'theme/base-units'
import IconLoading from 'components/atoms/IconLoading'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import FormRemoveDualAuth from 'forms/RemoveTotp/index'
import ErrorBoundary from 'helpers/ErrorBoundary'
import EnableVoice from './RenderEnableVoice'

function createData(mfaType, isPrimary, Verified, verifiedAt) {
  return {
    mfaType,
    isPrimary,
    Verified,
    verifiedAt,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function MultipleFactor(props) {
  const { accountId } = props.match.params
  const classes = useStyles()
  const [expandedRow, setExpandedRow] = React.useState('')
  const [formShown, setFormShown] = React.useState('false')

  const toggleSelectedRow = id => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  const toggleRow = id => {
    toggleSelectedRow(id)
  }

  React.useEffect(() => {
    props.getAllMfas(accountId)
  }, [])
  const {
    allMfas: { data, status },
  } = props
  const list = data
  const rows = list.map((results, i) => {
    const {
      mfaType, verified, verifiedAt, isPrimary, accountId,
    } = results

    return createData(mfaType, isPrimary, verified, verifiedAt, accountId)
  })
  const toggleForm = () => {
    setFormShown(!formShown)
  }
  return (
    <ErrorBoundary message="Multi Factor Authentication">
      <>
        <TitleContainer marginBottom={BASE * 2}>
          <Title>Multiple factor authentication</Title>
        </TitleContainer>
        <Details marginTop={'30px'} marginBottom={'30px'} direction={'column'}>
          <DetailsBlockWithTitle title="Actions">
            <EnableVoice id={accountId} />
          </DetailsBlockWithTitle>
        </Details>
      </>
      {status === 'loading' && (
        <TablePlaceholder>
          <IconLoading />
          <span>Loading, please wait</span>
        </TablePlaceholder>
      )}
      {status === 'done' && (
        <>
          {data && data.length === 0 ? (
            <TablePlaceholder>{'No Mfa'}</TablePlaceholder>
          ) : (
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <TableHead align="right">
                      <TableRow>
                        <TableCell> Mfa Type</TableCell>
                        <TableCell>Is Primary</TableCell>
                        <TableCell> Verified</TableCell>
                        <TableCell>Verified At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => {
                        const isPrimary = row.isPrimary.toString()
                        const verified = row.Verified.toString()
                        const labelId = `enhanced-table-checkbox-${index}`
                        if (isPrimary === 'true' && verified === 'true') {
                          return (
                            <>
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.name}
                                aria-label="collapsible table"
                              >
                                <TableCell
                                  onClick={() => toggleRow(row.commentId)}
                                >
                                  {row.mfaType}
                                </TableCell>
                                <TableCell
                                  onClick={() => toggleRow(row.commentId)}
                                >
                                  {isPrimary}
                                </TableCell>
                                <TableCell
                                  onClick={() => toggleRow(row.commentId)}
                                >
                                  {verified}
                                </TableCell>
                                <TableCell
                                  onClick={() => toggleRow(row.commentId)}
                                  id={labelId}
                                  scope="row"
                                >
                                  {moment(row.verifiedAt).format(
                                    'YYYY-MMM-DD HH:mm',
                                  )}
                                </TableCell>
                                <TableCell
                                  onClick={() => toggleRow(row.commentId)}
                                >
                                  {row.dualAuthStatus}
                                </TableCell>
                              </TableRow>
                              {expandedRow === row.commentId &&
                                row.mfaType === 'TOTP' && (
                                <TableCell colSpan={4}>
                                  <FormRemoveDualAuth
                                    initialValues={{
                                      comment: '',
                                      id: accountId,
                                      actionType: 'TOTP_REMOVAL',
                                    }}
                                    form={'RemoveDualAuthForm'}
                                    onCancel={toggleRow}
                                    onSubmitSuccess={toggleRow}
                                  />
                                </TableCell>
                              )}
                            </>
                          )
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          )}
        </>
      )}
    </ErrorBoundary>
  )
}

const mapStateToProps = state => ({
  allMfas: state.getAllMfas,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllMfas,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleFactor)
