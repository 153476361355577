import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import * as moment from 'moment'
import { VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FiatWithdrawalReview from 'forms/FiatWithdrawalReview/index'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import EyeIcon from 'components/atoms/EyeIcon/index'
import FormWithdrawalNotes from 'forms/WithdrawalNotes/index'

import { Link } from '../AccountIdLink/clickableId'
import './style.css'

class SuspendedWithdrawalsRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingSensitiveInfo: false,
      sensitiveInfo: {
        name: '',
        surname: '',
      },
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
      test: '',
      rowColor: '',
    }
  }

  getProfileInfo = async (id) => {
    this.setState({ isFetchingSensitiveInfo: true })
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const { sensitiveInfo } = res.data
    this.setState((prev) => ({
      ...prev,
      sensitiveInfo,
    }))
    this.setState({ isFetchingSensitiveInfo: false })
  }

  removeActiveUser = async () => {
    const { withdrawalId } = this.props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async () => {
    const { withdrawalId } = this.props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  updateComments = async (withdrawalId) => {
    this.setState({ commentsStatus: 'loading' })
    const { data } = await getAxios().get(
      `/withdrawal/${withdrawalId}/fiat/notes`,
    )
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  render() {
    const { withdrawal, review, showForm, showRawData, resetForm } = this.props
    const {
      withdrawalId,
      accountId,
      amount,
      bank,
      accountHolder,
      updatedAt,
      isFast,
      active,
      currency,
      tag,
    } = withdrawal
    const {
      toggleSelected,
      toggleRawData,
      setHighlightedId,
      isHighlighted,
      title,
    } = this.props
    const { sensitiveInfo } = this.state
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const shading = isHighlighted ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && VALR_BLUE_GRAY,
          }}
          onClick={() => {
            setHighlightedId(withdrawalId)
            this.updateComments(withdrawalId)
            this.getProfileInfo(accountId)
          }}
        >
          <TableCell style={{ padding: '16px 16px' }} flex={0.5}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={1.2}
          >
            {accountHolder}
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={1.2}
          >
            {bank.displayName}
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
            color={isFast ? 'green' : 'black'}
          >
            {isFast ? 'Yes' : 'No'}
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
            align={'right'}
          >
            {formattedAmount}
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={0.8}
            align={'right'}
          >
            {currency.shortName}
          </TableCell>
          <TableCell
            className="cellStyle"
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
            align={'right'}
          >
            {moment(updatedAt).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell flex={0.5} className="cellStyle">
            {active && active.userActive && (
              <EyeIcon text={active.activePersonName} />
            )}
          </TableCell>
          <TableCell className={'tagStyle'} flex={0.18} align={'center'}>
            {tag && <i className="fa fa-sticky-note" aria-hidden="true" />}
          </TableCell>
        </TableRow>
        {showForm && (
          <FiatWithdrawalReview
            title={title}
            initialValues={{
              withdrawalId,
              status: '',
              accountId,
              comment: '',
              reverseFee: false,
            }}
            comments={{
              withdrawalComments: this.state.comments,
              status: this.state.commentsStatus,
            }}
            toggleForm={() => {
              this.props.toggleAddNotesForm(withdrawalId)
              this.updateComments(withdrawalId)
            }}
            showAddNote={() => this.props.toggleAddNotesForm(withdrawalId)}
            expandAddNote={this.props.addNoteFormShown}
            expandRawData={this.props.expandRawData}
            showActiveUser={this.showActiveuser}
            removeActiveUser={this.removeActiveUser}
            form={`withdrawalReviewForm+${withdrawalId}`}
            rawData={{ ...withdrawal, review, ...sensitiveInfo }}
            onCancel={() => toggleSelected(withdrawalId)}
            showRawData={showRawData}
            suspended
          />
        )}
        {showForm && this.props.addNoteFormShown && (
          <FormWithdrawalNotes
            initialValues={{
              withdrawalId,
              data: {
                date: new Date(),
                createdBy: auth.decodeNameToken(),
                withdrawalId,
              },
            }}
            isfiatWithdrawal
            isSuspended
            form={`withdrawalNotesForm+${withdrawalId}`}
            withdrawalData={this.props.withdrawal}
            onCancel={resetForm}
            toggleForm={() => {
              this.props.toggleAddNotesForm(withdrawalId)
              this.updateComments(withdrawalId)
            }}
          />
        )}
      </Fragment>
    )
  }
}

export default SuspendedWithdrawalsRow
