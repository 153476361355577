import React from 'react'

interface IErrorBoundaryProps {
  [x: string]: any
}

interface IErrorBoundaryState {
  error: any | null
  errorInfo: any | null
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // eslint-disable-next-line no-console
      console.error({
        error: this.state.error,
        errorInfo: this.state.errorInfo,
      })
      return (
        <div>
          <h2>
            Something went wrong{' '}
            {this.props.message ? `in ${this.props.message}` : ''}.
          </h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
