import {
  FETCH_ACCOUNT_TRANSACTIONS_PROCESS,
  FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
  FETCH_ACCOUNT_TRANSACTIONS_ERROR,
  FETCH_ACCOUNT_PENDING_BLOCKCHAIN_RECEIVES_ERROR,
  FETCH_BLOCKCHAIN_RECEIVES_PROCESS,
  FETCH_BLOCKCHAIN_RECEIVES_SUCCESS,
  RESET_TRANSACTIONS_LIST,
  DOWNLOAD_ACCOUNT_TRANSACTIONS_PROCESS,
  DOWNLOAD_ACCOUNT_TRANSACTIONS_SUCCESS,
  DOWNLOAD_ACCOUNT_TRANSACTIONS_ERROR,
} from './actions'

const initialState = {
  activeId: null,
  byId: {},
  transactionHistoryDateFilters: {
    fromDate: undefined,
    toDate: undefined
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_TRANSACTIONS_PROCESS: {
      const { id, fromDate, toDate } = action.payload
      return {
        ...state,
        transactionHistoryDateFilters: {
          fromDate,
          toDate,
        },
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            transactionsStatus: 'loading',
          },
        },
      }
    }
    case FETCH_ACCOUNT_TRANSACTIONS_SUCCESS: {
      const { id, data, hasMoreRecords, skip, limit } = action.payload
      const hasSelectedDates = state.transactionHistoryDateFilters.fromDate
        || state.transactionHistoryDateFilters.toDate

      const existingData = !hasSelectedDates && state.byId[id].data ? state.byId[id].data : []
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            transactionsStatus: 'done',
            data: [...existingData, ...data],
            hasMoreRecords,
            skip,
            limit,
          },
        },
      }
    }
    case DOWNLOAD_ACCOUNT_TRANSACTIONS_PROCESS: {
      const { accountId } = action.payload

      return {
        ...state,
        byId: {
          ...state.byId,
          [accountId]: {
            ...state.byId[accountId],
            downloadStatus: 'loading',
          },
        },
      }
    }
    case DOWNLOAD_ACCOUNT_TRANSACTIONS_SUCCESS: {
      const { accountId } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [accountId]: {
            ...state.byId[accountId],
            downloadStatus: 'done',
          },
        },
      }
    } 
    case DOWNLOAD_ACCOUNT_TRANSACTIONS_ERROR: {
      const { accountId } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [accountId]: {
            ...state.byId[accountId],
            downloadStatus: 'error',
          },
        },
      }
    } 
    case FETCH_BLOCKCHAIN_RECEIVES_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            bloackhainReceivesStatus: 'loading',
          },
        },
      }
    }

    case FETCH_BLOCKCHAIN_RECEIVES_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            bloackhainReceivesStatus: 'done',
            pendingBlockChainReceives: data,
          },
        },
      }
    }

    case FETCH_ACCOUNT_TRANSACTIONS_ERROR: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            transactionsStatus: 'error',
            data: [],
          },
        },
      }
    }
    case FETCH_ACCOUNT_PENDING_BLOCKCHAIN_RECEIVES_ERROR: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            bloackhainReceivesStatus: 'error',
          },
        },
      }
    }
    case RESET_TRANSACTIONS_LIST: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
