import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchPlatformBalances } from 'redux/balances/actions'
import SinglePlatform from 'components/organisms/SinglePlatformBalanceInfo'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { capitalizeFirstLetter } from 'helpers/utils'
import { TitleContainer } from '../../components/atoms/Table/styles'
import BigNumber from 'bignumber.js'

class PlatformBalances extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalBalance: 0,
    }
    this.addToTotalBalance = this.addToTotalBalance.bind(this)
  }

  addToTotalBalance(balance) {
    this.state.totalBalance = this.state.totalBalance + balance
  }
  componentDidMount() {
    this.props.fetchPlatformBalances()
  }

  fixCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = capitalizeFirstLetter(fixCamelCasing)
    return fixedCamelCasing
  }

  render() {
    const {
      platformBalances: { status, data },
    } = this.props
    return (
      <ErrorBoundary message="Accounts Platform Balances">
        {status === 'loading' && <p>Loading, please wait</p>}
        <TitleContainer>
          Total Balance in ZAR{' '}
          {`R${new BigNumber(this.state.totalBalance).toFormat(2)}`}
        </TitleContainer>
        {status === 'done' &&
          data
            .sort((a, b) => b.accountId - a.accountId)
            .map(({ platformAccountName, balances, accountId }) => (
              <SinglePlatform
                key={platformAccountName}
                name={this.fixCamelCasing(platformAccountName)}
                balances={balances}
                accountId={accountId}
                addToTotalBalance={this.addToTotalBalance}
              />
            ))}
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  platformBalances: state.balances.platform,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPlatformBalances,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(PlatformBalances)
