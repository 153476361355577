import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import auth from 'services/Auth'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.unblockAcc.id)
  }

  render() {
    const {
      unblockAcc,
      isSelected,
    } = this.props
    const {
      id,
      accountId,
      reason,
      createdAt,
      active,
    } = unblockAcc
    const shading = isSelected ? 'white' : ''

    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'12%'}>{createdAt}</TableCell>
          <TableCell width={'20%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              comment: '',
              actionType: unblockAcc.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'rejected',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.toggleForm}
            rawData={unblockAcc}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'UNBLOCK_ACCOUNT_EVENT')
