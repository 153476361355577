import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submitBlockStatus = async values => {
  try {
    await getAxios().put('/account/block', values)
    toast('Account blocked', { type: toast.TYPE.SUCCESS, autoClose: 2500 })
  } catch (error) {
    toast(`Failed to block account. \n ${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
