import getAxios from 'helpers/axios'
export const FETCH_ACCOUNT_ACTIVATION_STATUS_PROCESS =
  'FETCH_ACCOUNT_ACTIVATION_STATUS_PROCESS'
export const FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS =
  'FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS'
export const FETCH_ACCOUNT_ACTIVATION_STATUS_ERROR =
  'FETCH_ACCOUNT_ACTIVATION_STATUS_ERROR'

export const fetchActivationStatus = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_ACTIVATION_STATUS_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/active`)

    dispatch({
      type: FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS,
      payload: {
        id,
        ...res.data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.error.message,
      errorCode: error.response.data.error.code,
    }
    dispatch({
      type: FETCH_ACCOUNT_ACTIVATION_STATUS_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}
