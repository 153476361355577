import React, { Component, Fragment } from 'react'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import MuiButton from 'components/atoms/Buttons/MuiButton'

class SpamList extends Component {
    renderData = async () => {
        try {
            const res = await getAxios().delete(`/account/remove/spam-list`)
            if (res.status === 201) {
                toast('Emails Removed from Spam List', {
                    type: toast.TYPE.SUCCESS,
                    autoClose: 2000,
                })
            }
        } catch (error) {
            toast(` ${error.response.data.message}`, {
                type: toast.TYPE.ERROR,
                autoClose: 3000,
            })
        }
    }

    render() {
        return (
            <Fragment>
                <MuiButton onClick={this.renderData}>Remove Emails </MuiButton>
            </Fragment>
        )
    }
}

export default SpamList
