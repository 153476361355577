import React, { Fragment, useMemo } from 'react'
import { BASE } from 'theme/base-units'
import { makeDate, oppositeColor } from 'helpers/utils'
import { NEGATIVE_RED_COLOR_PAIR } from 'theme/colors'
import { FlexboxColumn } from 'components/layout/index'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

import IconLoading from 'components/atoms/IconLoading'
import {
  Details,
  DetailsBlockWithTitle,
  DetailsBlock,
  InfoBlock,
  JsonBlock,
  Splitter,
  DetailsContainer,
} from 'components/atoms/Details'

import CustomerType from './CustomerType'
import InitiateCellphoneChange from './InitiateCellphoneChange'
import AllowCellphoneReuse from './AllowCellphoneReuse'
import InitiateEmailChange from './InitiateEmailChange'
import InitiateNameAndSurnameChange from './InitiateNameAndSurnameChange'
import InitiateIdentityChange from './InitiateIdentityChange'
import { NoCopy } from './Styles'
import DepositReferencesInfo from './DepositReferencesInfo/DepositReferencesInfo'
import InitiateCountryOfResidenceChange from './InitiateCountryOfResidenceChange'

const isSocial = (auth0Info) =>
  auth0Info.status == 'done' &&
  auth0Info.info &&
  auth0Info.info.identities &&
  auth0Info.info.identities.findIndex((v) => v.isSocial === true) > -1

const DetailsView = ({
  info,
  settings,
  status,
  depositReferences,
  customerTypeInfo,
  corporateAccountSanctioned,
  cellphoneMarkedForReUse,
  id,
  auth0,
  fetchAccountDetails,
  keycloakInfo,
  currencies,
  accountActivationStatus,
  hasSuperUserRoleAndIsStaffAccount,
  individualRiskProfileInfo,
  corporateRiskProfile,
  keyRelianceAgreementType,
}) => {
  const accountFiatCurrency = useMemo(
    () =>
      settings && settings.accountFiatCurrency
        ? settings.accountFiatCurrency
        : 'No data',
    [settings],
  )
  const { riskScore } = individualRiskProfileInfo
  const { riskScore: CorporateRiskScore } = corporateRiskProfile

  const residentialCountry = useMemo(
    () =>
      info && info.residentialCountry ? info.residentialCountry : 'No data',
    [info],
  )

  const fiatCurrencies = useMemo(
    () => currencies.map((c) => c.shortName),
    [currencies],
  )
  const unlinkFlagOn = useFeatureFlagCheck('unlinkSocialAccounts')
  return (
    <Fragment>
      {status === 'loading' && (
        <Details marginBottom={`${BASE * 3}px`}>
          <DetailsBlockWithTitle title="General Info">
            <IconLoading />
          </DetailsBlockWithTitle>
        </Details>
      )}
      {status === 'done' && (
        <Details marginBottom={`${BASE * 3}px`} direction={'column'}>
          <DetailsBlockWithTitle title="General Info">
            {status === 'done' && (
              <Fragment>
                <DetailsBlock flow={'column'}>
                  <InfoBlock canCopy label="Name" value={info.name || '--'} />
                  {hasSuperUserRoleAndIsStaffAccount && (
                    <InitiateNameAndSurnameChange
                      id={id}
                      oldName={info.name}
                      oldSurname={info.surname}
                    />
                  )}
                </DetailsBlock>
                <InfoBlock
                  canCopy
                  label="Surname"
                  value={info.surname || '--'}
                />
                <DetailsBlock flow={'column'}>
                  <InfoBlock
                    label="Phone"
                    canCopy
                    style={{
                      color: !info.cellNumber
                        ? `${oppositeColor(
                            info.cellNumber,
                            NEGATIVE_RED_COLOR_PAIR,
                          )}`
                        : '',
                    }}
                    value={info.cellNumber || 'Not Set'}
                  />
                  <FlexboxColumn padding={'0px 0px 12px 0px'}>
                    {hasSuperUserRoleAndIsStaffAccount && (
                      <InitiateCellphoneChange id={id} />
                    )}
                    <AllowCellphoneReuse
                      id={id}
                      cellphoneMarkedForReUse={cellphoneMarkedForReUse}
                      fetchAccountDetails={fetchAccountDetails}
                    />
                  </FlexboxColumn>
                </DetailsBlock>
                <DetailsBlock flow={'column'}>
                  <NoCopy>
                    <InfoBlock
                      canCopy
                      label="Email"
                      value={info.email || 'Not set'}
                    />
                    {hasSuperUserRoleAndIsStaffAccount && (
                      <InitiateEmailChange
                        keycloakInfo={keycloakInfo}
                        isSocial={isSocial(auth0)}
                        id={id}
                        auth0={auth0}
                        unlinkFlagOn={unlinkFlagOn}
                      />
                    )}
                  </NoCopy>
                </DetailsBlock>
                {info.nationality && (
                  <InfoBlock label="Nationality" value={info.nationality} />
                )}
                {info.dateOfBirth && (
                  <InfoBlock
                    label="Date Of Birth"
                    value={makeDate(info.dateOfBirth)}
                  />
                )}
                {depositReferences && depositReferences.length ? (
                  <InfoBlock
                    label="Deposit References"
                    component={() => (
                      <DepositReferencesInfo
                        depositReferences={depositReferences}
                        currencies={fiatCurrencies}
                      />
                    )}
                  />
                ) : null}
                <DetailsBlock flow={'column'}>
                  <InfoBlock
                    label="Country of Residence"
                    value={residentialCountry}
                  />
                  <InfoBlock
                    label="Account Currency"
                    value={accountFiatCurrency}
                  />
                  {hasSuperUserRoleAndIsStaffAccount && (
                    <InitiateCountryOfResidenceChange id={id} />
                  )}
                </DetailsBlock>
              </Fragment>
            )}
            {accountActivationStatus.marginEnabled && (
              <InfoBlock
                label={'Margin Enabled'}
                value={accountActivationStatus.marginEnabled.toString()}
              />
            )}
            {accountActivationStatus.futuresEnabled && (
              <InfoBlock
                label={'Futures Enabled'}
                value={accountActivationStatus.futuresEnabled.toString()}
              />
            )}
            {accountActivationStatus.canMarginTradeFiat && (
              <InfoBlock
                label={'Can Margin Trade Fiat'}
                value={accountActivationStatus.canMarginTradeFiat.toString()}
              />
            )}
            {customerTypeInfo.status === 'done' &&
            customerTypeInfo.data.type !== 'CORPORATE' ? (
              <InfoBlock label={'Risk Score'} value={riskScore || '---'} />
            ) : (
              <InfoBlock
                label={'Corporate Risk Score'}
                value={CorporateRiskScore || '---'}
              />
            )}
          </DetailsBlockWithTitle>
          <Splitter>
            <DetailsContainer>
              <DetailsBlockWithTitle
                title="Identity"
                flow={'column'}
                style={{ width: `${50}%` }}
              >
                {
                  <Fragment>
                    <InfoBlock
                      label="Type"
                      value={info.identityType || 'N/A'}
                    />
                    <DetailsBlock flow={'column'}>
                      <InfoBlock
                        label="Number"
                        canCopy
                        value={info.identityNumber || 'N/A'}
                      />
                      {hasSuperUserRoleAndIsStaffAccount && (
                        <InitiateIdentityChange
                          id={id}
                          identityNumber={info.identityNumber}
                        />
                      )}
                    </DetailsBlock>
                    <InfoBlock
                      label="Issuing Country"
                      value={info.identityIssuingCountry || 'N/A'}
                    />
                    {hasSuperUserRoleAndIsStaffAccount && (
                      <InfoBlock
                        label="Expiry Date"
                        value={
                          info.identityExpiryDate
                            ? makeDate(info.identityExpiryDate)
                            : 'N/A'
                        }
                      />
                    )}
                    {!info.identityType && <p>Identity is not verified</p>}
                  </Fragment>
                }

                <JsonBlock src={info} title={'Raw Data'} />
              </DetailsBlockWithTitle>
              <CustomerType
                hasSuperUserRoleAndIsStaffAccount={
                  hasSuperUserRoleAndIsStaffAccount
                }
                corporateAccountSanctioned={corporateAccountSanctioned}
                customerTypeInfo={customerTypeInfo}
                keyRelianceAgreementType={keyRelianceAgreementType}
                id={id}
              />
            </DetailsContainer>
          </Splitter>
        </Details>
      )}
    </Fragment>
  )
}

export default DetailsView
