import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchWiredWithrawalReview,updateWiredWithdrawals } from 'redux/circle/actions'
import UsdWithdrawalsContainer from 'components/organisms/UsdWithdrawalsForReview/index '
import { Header } from 'components/atoms/Generic/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import socket from 'redux/socket/index'

class UsdWithdrawals extends Component {

  componentDidMount() {
    this.props.fetchWiredWithrawalReview()
    const busyWithdrawals = socket.on('busy withdrawals', data => {
      this.props.updateWiredWithdrawals(data)
    })

    const withdrawalStatus = socket.on('withdrawal status updated', data => {
      this.props.updateWiredWithdrawals(data)
    })

    const onUnmountStatus = socket.on('onMount status updated', data => {
      this.props.updateWiredWithdrawals(data)
    })
  }

  render() {
  const{wiredWithdrawalReview}=this.props
    return (
      <div>
        <Header title={'USD WITHDRAWALS'} />
        {wiredWithdrawalReview.status !== 'done' && <LoadingBlock />}
        {wiredWithdrawalReview.status === 'done'&&
        <>
        <UsdWithdrawalsContainer deposits={wiredWithdrawalReview}/>
        </>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
wiredWithdrawalReview:state.circle.updateWithdrawalReview,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
        fetchWiredWithrawalReview,
        updateWiredWithdrawals
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsdWithdrawals)
