import React from 'react'
import styled from 'styled-components'
import JsonView from 'react-json-view'
import { BASE } from 'theme/base-units'
import {
  BLACK,
  GRAY_2,
  TRANSPARENT_PINK,
  ORANGE,
  GRAY_1,
  DEFAULT_THEME,
  GREEN,
  WHITE,
} from 'theme/colors'
import { Button, Badge } from '@material-ui/core'
import { handleCopyToClipBoard } from 'helpers/utils'

export const InfoBlock = (props) => {
  const {
    infoLabelColor,
    canCopy,
    customCopyValue,
    label,
    value,
    onClick,
    component,
    ...rest
  } = props
  return (
    <Block
      onClick={() => {
        if (canCopy) {
          if (customCopyValue) {
            return handleCopyToClipBoard(customCopyValue)
          }
          handleCopyToClipBoard(value)
        }
      }}
      {...rest}
      canCopy={canCopy}
      title={`${label}: ${value}`}
    >
      {label && (
        <InfoLabel
          marginBottom={rest.infoLabelMarginBottom}
          infoLabelColor={infoLabelColor}
        >
          {label}
        </InfoLabel>
      )}
      <InfoValue {...props}>{component ? component() : value}</InfoValue>
    </Block>
  )
}

export const JsonBlock = (props) => {
  const { label = 'Raw Data', align, last, showTypes = false, overflow } = props
  const isLightTheme = localStorage.getItem('adminTheme') === 'lightTheme'
  return (
    <Block overflow={overflow} align={align} last={last}>
      <InfoLabel>{label}</InfoLabel>
      <JsonView
        theme={isLightTheme ? 'bright:inverted' : 'monokai'}
        displayDataTypes={showTypes}
        iconStyle={'circle'}
        name={false}
        collapsed={true}
        {...props}
      />
    </Block>
  )
}

export const Splitter = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  margin-bottom: ${BASE * 2}px;
  margin-bottom: ${({ marginBottom = `${BASE}px`, last = false }) =>
    last ? '0' : marginBottom};
`
export const ContentDiv = styled.div`
  width: ${({ width = '33.3%' }) => `${width}%`};
  margin-right: 1.3%;
`

export const BlockAcc = styled(Button)`
  margin-right: 1em;
`
export const ZendeskButton = styled(Button)`
  margin-right: 1em;
`
export const InitiateDualAuthOverride = styled(Button)`
  margin-right: 1em;
`
export const Details = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: space-between;
  margin-bottom: ${({ marginBottom = `${BASE}px`, last = false }) =>
    last ? '0' : marginBottom};
  margin-left: ${(marginLeft = 1) => `${marginLeft}%`};
`
export const DetailsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ flow = 'row' }) => flow};
  align-items: flex-start;
`
const DetailsTitleContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-right: ${({ notLast = false }) => (notLast ? `${BASE * 3}px` : 0)};
  margin-bottom: ${({ last = false }) => (last ? '0' : `${BASE}px`)};
  background-color: ${({ theme }) => theme.rawData.background};
  padding: 24px 24px;
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-right: ${({ notLast = false }) => (notLast ? `${BASE * 3}px` : 0)};
  margin-bottom: ${({ last = false }) => (last ? '0' : `${BASE}px`)};
  background-color: ${({ theme }) => theme.rawData.background};
  padding: 8px 8px;
`
const DetailsTitle = styled.div`
  display: flex;
  color: ${({ colorTheme = DEFAULT_THEME }) => colorTheme.color.text};
  margin-bottom: ${BASE * 2}px;

  & p,
  & div {
    flex: 1;
  }

  & p {
    flex: 0 0 auto;
    margin: 0;
    margin-right: ${BASE}px;
    font-size: ${BASE * 2}px;
    text-transform: uppercase;
  }

  & div {
    width: 100%;
    border-bottom: ${(props) => (props.hideTitleLine ? '' : '1px solid')};
    opacity: 0.25;
  }
`
export const DetailsBlockWithTitle = ({
  title,
  children,
  hideTitleLine = false,
  ...props
}) => (
  <DetailsTitleContainer {...props}>
    <DetailsTitle hideTitleLine={hideTitleLine} colorTheme={props.colorTheme}>
      <p>{title}</p>
      <div />
    </DetailsTitle>
    <DetailsBlock {...props}>{children}</DetailsBlock>
  </DetailsTitleContainer>
)

export const Note = styled.p`
  color: ${({ color = ORANGE }) => color};
  font-size: ${2.5 * BASE}px;
  line-height: 1.35;
  margin: 0;
  margin-bottom: ${({ marginBottom = BASE * 2 }) => `${marginBottom}px`};
  & i {
    margin-right: ${BASE}px;
  }
`

export const Block = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.main};
  flex-direction: column;
  margin-right: ${({ last = false }) => (last ? 0 : `${BASE * 4}px`)};
  margin-bottom: ${({ marginBottom = BASE * 2, last = false }) =>
    last ? 0 : `${marginBottom}px`};
  align-items: ${({ align = 'flex-start' }) => align};
  flex: 0 0 auto;
  max-width: 100%;
  overflow: ${({ overflow }) => overflow};
  :hover {
    cursor: ${({ canCopy }) => (canCopy ? 'copy' : 'default')};
  }
`

export const InfoLabel = styled.p`
  font-size: ${BASE * 2}px;
  color: ${({ theme }) => theme.infoLabel.label};
  margin: 0;
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
`

export const InfoValue = styled.div`
  font-size: ${({ fontSize = BASE * 2.5 }) => `${fontSize}px`};
  color: ${({ theme }) => theme.colors.infoValueText};
  margin: 0;
  font-weight: 600;
  max-width: ${({ noCrop, maxWidth = '24em' }) =>
    noCrop ? 'unset' : maxWidth};
  ${({ align }) => ({
    alignItems: align,
  })};
  overflow: ${({ noCrop }) => (noCrop ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  cursor: ${({ interactive = false }) => (interactive ? 'pointer' : 'normal')};
  &:hover {
    background-color: ${({ interactive = false }) =>
    interactive ? GRAY_1 : 'none'};
  }
`
export const Memo = styled.span`
  margin: 0;
  font-style: italic;
  font-weight: 400;
`

export const OneColumnTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${2 * BASE}px;
`

export const OneColumnRow = styled.div`
  color: ${BLACK};
  margin-bottom: ${({ marginBottom = BASE * 0.5 }) => `${marginBottom}px`};
  padding: ${BASE * 1.5}px;
  font-size: ${BASE * 1.75}px;
  background-color: ${({ error = false }) =>
    error ? TRANSPARENT_PINK : 'rgb(236, 236, 236)'};
  word-break: break-all;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  &:nth-child(odd) {
    background-color: rgb(226, 228, 228);
  }
  & p {
    margin: 0;
  }
`

export const CustomBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${GREEN};
    color: ${WHITE};
    margin-right: ${BASE * 3}px;
  }
`
