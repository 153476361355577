import React, { FC } from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { StyledErrorIconWrapper } from '../styles'

interface ErrorNotifyProps {
  showErrorIcon?: boolean
  text?: string
}

const ErrorNotify: FC<ErrorNotifyProps> = ({ showErrorIcon, text }) => {
  if (!showErrorIcon) return null
  return (
    <StyledErrorIconWrapper>
      <h3>{text}</h3>
      <ErrorOutlineIcon color="secondary" />
    </StyledErrorIconWrapper>
  )
}

export default ErrorNotify
