import { Button } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import WithdrawalRestrictionForm from 'forms/WithdrawalRestriction'
import MuiButton from 'components/atoms/Buttons/MuiButton'

const AddRestrictions = ({ currencies, accountId }) => {
  const [showForm, setShowForm] = useState(false)
  const toggleForm = () => {
    setShowForm((prev) => !prev)
  }
  return (
    <Fragment>
      {!showForm && (
        <MuiButton onClick={toggleForm}>{'Add Restrictions'}</MuiButton>
      )}
      {showForm && (
        <WithdrawalRestrictionForm
          initialValues={{
            accountId,
            currency: '',
          }}
          currencies={currencies}
          form={'add_restrictions'}
          onSubmitSuccess={toggleForm}
          onCancel={toggleForm}
        />
      )}
    </Fragment>
  )
}

export default AddRestrictions
