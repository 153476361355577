import {
  IRiskProfileEddReport,
  IndividualEddReportInitialValues,
  IndividualRiskProfile,
} from 'containers/Accounts/SingleAccount/Compliance/interfaces/riskProfile'
import { diffCheckBetweenObjects } from 'helpers/diffCheckBetweenObjects'
import { makeDate } from 'helpers/utils'
import { IKeycloakInfo } from 'interfaces/keycloak'
import { SingleAccountInfo } from 'interfaces/singleAccount'
import { useMemo } from 'react'

interface AccountInfoDiffCheck {
  phoneNumber: string
  emailAddress: string
  fullName: string
  nationality: string
  passportOrIdNumber: string
}

export const useIndividualRiskProfileEddReport = (
  eddReport: IRiskProfileEddReport,
  info: IndividualRiskProfile,
  accountInfo: SingleAccountInfo,
  accountId: number,
  addressData: {
    addressLine1: string
    addressLine2: string
    suburb: string
    city: string
    region: string
    country: string
    postalCode: string
  },
  keycloakInfo: IKeycloakInfo,
) => {
  const { id, createdAt, updatedAt, ...restOfEddReportData } = eddReport
  const {
    fullName,
    accountOpenedDate,
    dateOfBirth,
    nationality,
    passportOrIdNumber,
    residentialAddress,
    phoneNumber,
    emailAddress,
    employer,
    politicalExposure,
    criminalHistory,
    natureOfBusiness,
    estimatedAnnualIncome,
    purposeOfAccount,
    transactionHistoryInternal,
    transactionHistoryExternal,
    totalFiatDeposits,
    totalFiatWithdrawals,
    totalCryptoReceives,
    totalCryptoSends,
    offChainTransactions,
    onChainTransactions,
    pepStatus,
    highRiskCountry,
    affiliated,
    sourceOfFunds,
    occupationStatus,
    ipLocation,
    status,
    recommendation,
    commentId,
    type,
    createdBy,
    commentCreatedAt,
    sourceOfWealth,
  } = restOfEddReportData
  const keycloakAccountCreatedAt =
    keycloakInfo.info && keycloakInfo.info.createdTimestamp
      ? new Date(keycloakInfo.info.createdTimestamp).toISOString()
      : null

  const formattedAddress = () =>
    addressData
      ? Object.values(addressData).reduce(
          (acc, curr) => `${acc}${acc.length > 0 ? `, ${curr}` : curr}`,
          '',
        )
      : ''

  const individualEddReportInitialValues: IndividualEddReportInitialValues =
    useMemo(
      () => ({
        accountId,
        fullName: accountInfo.name
          ? `${accountInfo.name} ${accountInfo.surname}`
          : fullName,
        accountOpenedDate:
          keycloakAccountCreatedAt || accountOpenedDate || null,
        dateOfBirth:
          (accountInfo.dateOfBirth && makeDate(accountInfo.dateOfBirth)) ||
          dateOfBirth ||
          null,
        nationality: accountInfo.identityIssuingCountry || nationality,
        passportOrIdNumber: accountInfo.identityNumber || passportOrIdNumber,
        residentialAddress: formattedAddress() || residentialAddress,
        phoneNumber: accountInfo.cellNumber || phoneNumber,
        emailAddress: accountInfo.email || emailAddress,
        employer,
        politicalExposure,
        criminalHistory,
        natureOfBusiness,
        estimatedAnnualIncome,
        purposeOfAccount: info.purposeOfAccount || purposeOfAccount,
        transactionHistoryInternal,
        transactionHistoryExternal,
        totalFiatDeposits,
        totalFiatWithdrawals,
        totalCryptoReceives,
        totalCryptoSends,
        offChainTransactions,
        onChainTransactions,
        pepStatus: info.pepStatus || pepStatus,
        highRiskCountry,
        affiliated,
        sourceOfFunds: info.sourceOfFunds || sourceOfFunds,
        occupationStatus: info.occupationStatus || occupationStatus,
        ipLocation: info.ipLocation || ipLocation,
        recommendation: status !== 'REJECTED' ? recommendation : '',
        status,
        commentId,
        type,
        createdBy,
        commentCreatedAt,
        sourceOfWealth: info.sourceOfWealth|| sourceOfWealth,
      }),
      [info, restOfEddReportData, accountInfo],
    )
  const individualEddReportPdfData = {
    ...eddReport,
    sourceOfFunds: info.sourceOfFunds || sourceOfFunds,
    occupationStatus: info.occupationStatus || occupationStatus,
    purposeOfAccount: info.purposeOfAccount || purposeOfAccount,
    pepStatus: info.pepStatus || pepStatus,
    ipLocation: info.ipLocation || ipLocation,
  }

  // Object has to have the same properties as the one it is checking against
  const accountInfoDataToCheckDiff: AccountInfoDiffCheck = {
    fullName: accountInfo.name
      ? `${accountInfo.name} ${accountInfo.surname}`
      : '',
    emailAddress: accountInfo.email || '',
    phoneNumber: accountInfo.cellNumber || '',
    nationality: accountInfo.identityIssuingCountry,
    passportOrIdNumber: accountInfo.identityNumber,
  }
  const { id: _, ...eddReportDataToCheckDiff } = eddReport
  const { id: __, ...infoDataToCheckDiff } = info

  const hasAccountInfoDiff = diffCheckBetweenObjects(
    eddReportDataToCheckDiff,
    accountInfoDataToCheckDiff,
  )
  const hasRiskProfileInfoDiff = diffCheckBetweenObjects(
    eddReportDataToCheckDiff,
    infoDataToCheckDiff,
  )
  return {
    individualEddReportInitialValues,
    individualEddReportPdfData,
    hasAccountInfoDiff,
    hasRiskProfileInfoDiff,
  }
}
