import ErrorToast from 'components/molecules/ErrorToast'

export const diffCheckBetweenObjects = (
  mainObj: { [key: string]: any },
  comparatorObj: { [key: string]: any },
): boolean => {
  let hasDiff = false
  try {
    const mainObjKeys = Object.keys(mainObj)
    const comparatorObjKeys = Object.keys(comparatorObj)
    const keys = mainObjKeys.filter(
      (mk) => comparatorObjKeys.findIndex((ck) => ck === mk) > -1,
    )
    keys.forEach((key) => {
      if (!mainObj[key] && !comparatorObj[key]) return hasDiff
      const isSame = mainObj[key] === comparatorObj[key]
      if (isSame) return hasDiff
      hasDiff = true
    })
  } catch (error) {
    ErrorToast('Error occurred when checking difference between data.')
  }
  return hasDiff
}
