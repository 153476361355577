import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { fetchMarginTradeFiatStatus } from 'redux/accountProfileSettings/actions'
import { Flexbox } from 'components/layout'
import { hasAccountEditorRole } from 'helpers/roleBasedAccess'
import FeatureFlagBoundary from 'components/organisms/FeatureFlagBoundary'

import { fetchActivationStatus } from 'redux/activationStatus/actions'
import { fetchAccountRiskStatus } from 'redux/marginStatus/actions'
import { DetailsBlockWithTitle, InfoBlock } from 'components/atoms/Details'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { getAccountDetails } from 'redux/selectors/details'
import { getAccountMarginStatus } from 'redux/selectors/marginStatus'

import VeriffAccountInformation, {
  VeriffKycType,
} from './VeriffInfo/VeriffAccountInformation'
import RequestedAccountActions from './RequestedAccountActions'
import ExchangeControl from './ExchangeControl/ExchangeControl'
import VirtualAccount from './RenderVirtualAccount'
import EnableFuturesPerAccount from './EnableFuturesPerAccount'
import EnableMargin from './EnableMarginPerAccount'
import EnableAccountMarginTradeFiat from './EnableAccountMarginTradeFiat'
import InitiateWithdrawalLimit from './InitiateWithdrawalLimit'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

interface AccountRiskControlsProps {
  accountActivationStatus: {
    status: string
    inLiquidation: boolean
    marginEnabled: boolean
    futuresEnabled: boolean
  }
  accountId: string
  details: {
    settings: {
      customWithdrawLimits: string
      accountFiatCurrency: string
    }
    accountType: string
  }

  accountProfileSettings: {
    status: string
    canMarginTradeFiat: boolean
  }
  fetchMarginTradeFiatStatus: (accountId: string) => void
  match: RouteComponentProps<{ accountId: string }>
}

const AccountRiskControls: FC<AccountRiskControlsProps> = (props) => {
  const { accountId } = props.match.params
  const {
    accountActivationStatus: { status },
    details: { settings, accountType },
  } = props

  const notAuthorized = !hasAccountEditorRole()
  const flagIsOn = useFeatureFlagCheck('virtualAccount')

  useEffect(() => {
    props.fetchMarginTradeFiatStatus(accountId)
  }, [accountId])

  return (
    <>
      {status === 'loading' && (
        <DetailsBlockWithTitle title="Account Risk Controls">
          <LoadingBlock />
        </DetailsBlockWithTitle>
      )}
      {status === 'done' && (
        <>
          <DetailsBlockWithTitle title="Account Risk Controls">
            <Flexbox width="100%" align="flex-start" justify="space-between">
              <DetailsBlockWithTitle title="Margin Info" flow={'column'}>
                <EnableMargin
                  marginActivationStatus={props.accountActivationStatus}
                  id={accountId}
                  notAuthorized={notAuthorized}
                />
              </DetailsBlockWithTitle>
              <DetailsBlockWithTitle title="Futures Info" flow={'column'}>
                <EnableFuturesPerAccount
                  futuresActivationStatus={props.accountActivationStatus}
                  id={accountId}
                  notAuthorized={notAuthorized}
                />
              </DetailsBlockWithTitle>
              <DetailsBlockWithTitle title="Margin Trade Fiat" flow={'column'}>
                <EnableAccountMarginTradeFiat
                  accountMarginTradeStatus={props.accountProfileSettings}
                  id={accountId}
                  notAuthorized={notAuthorized}
                />
              </DetailsBlockWithTitle>
              <DetailsBlockWithTitle title="Withdrawal Limit" flow={'column'}>
                {accountType !== 'SUB_ACCOUNT' && (
                  <InitiateWithdrawalLimit accountId={accountId} />
                )}
                <InfoBlock
                  label="Current Withdrawal Limit"
                  value={
                    (settings &&
                      JSON.stringify(settings.customWithdrawLimits)) ||
                    'default value'
                  }
                />
              </DetailsBlockWithTitle>
            </Flexbox>
          </DetailsBlockWithTitle>
          {['INR'].includes(settings.accountFiatCurrency) && flagIsOn && (
            <VirtualAccount accountId={accountId} />
          )}
          <FeatureFlagBoundary flag="exchangeControl" hideMessage>
            <ExchangeControl
              accountId={accountId}
              // am not sure why this is passed here
              accountFiatCurrency={settings?.accountFiatCurrency}
            />
          </FeatureFlagBoundary>
          <>
            {accountType !== 'SUB_ACCOUNT' && (
              <RequestedAccountActions accountId={accountId} />
            )}
          </>

          <DetailsBlockWithTitle title="Proof Of Address" flow={'column'}>
            <VeriffAccountInformation
              accountId={accountId}
              kycType={VeriffKycType.ADDRESS}
            />
          </DetailsBlockWithTitle>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  details: getAccountDetails(state),
  accountActivationStatus: getAccountMarginStatus(state),
  accountProfileSettings: state.accountProfileSettings,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchActivationStatus,
      fetchAccountRiskStatus,
      fetchMarginTradeFiatStatus,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountRiskControls)
