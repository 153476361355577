import React, { useState } from 'react'
import FormUAddTradeFeesPerCurrencyPair from 'forms/UpdateTradeFees'
import { DetailsBlockWithTitle } from 'components/atoms/Details/index'
import { Button, Input } from 'components/atoms/Form/index'
import { Spacer } from 'components/atoms/Generic/index'
import AddIcon from '@material-ui/icons/Add'
import { StyledAddNewFeesPerCurrencyPairWrapper, StyledNewCurrencyPairAction } from '../Styles'

const AddNewFeesPerCurrencyPair = () => {
  const [formActive, setFormActive] = useState(false)
  const [currencyPairSymbol, setCurrencyPairSymbol] = useState('')
  const initialValues = {
    currencyPairSymbol,
    makerFee: 0,
    takerFee: 0,
    valrInstantOrderFee: 0.75,
    foreignExchangeInstantOrderFee: 0.1,
    foreignExchangeFee: 0.05,
  }

  const toggleForm = () => {
    if (formActive) setCurrencyPairSymbol('')
    setFormActive(!formActive)
  }
  const handleCurrencyPairChange = ({ target: { value } }) => {
    setCurrencyPairSymbol(value.toUpperCase())
  }

  return (
    <StyledAddNewFeesPerCurrencyPairWrapper>
      <DetailsBlockWithTitle title="Add New Trade Fees Per Currency Pair"></DetailsBlockWithTitle>
      {formActive ?
        <FormUAddTradeFeesPerCurrencyPair
          onCancel={toggleForm}
          form={'addNewTradeFeesPerCurrencyPair'}
          title={`Add Trade Fees For Currency Pair ${currencyPairSymbol} (0.03% = 3bps)`}
          initialValues={{ ...initialValues, confirmation: false, isDefaultTradeFeesForm: false }}
          question={`Do you want to add new trade fees for this currency pair ${currencyPairSymbol}?`}
          isDefaultTradeFeesForm={false}
          onSubmitSuccess={() => {
            toggleForm()
          }}
        />
        :
        <StyledNewCurrencyPairAction>
          <Input value={currencyPairSymbol} onChange={handleCurrencyPairChange} variant="outlined" placeholder="Enter Currency Pair" />
          <Spacer margin="0px 8px" />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={toggleForm}
            disabled={!currencyPairSymbol}
          >
            Add Currency Pair Trade Fees
          </Button>
        </StyledNewCurrencyPairAction>
      }
    </StyledAddNewFeesPerCurrencyPairWrapper>
  )
}

export default AddNewFeesPerCurrencyPair
