import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { TableRow } from '@material-ui/core'

export const Headers = () => {
  return (
    <TableHead align="right" >
      <TableRow>
        <TableCell >Effective Date</TableCell>
        <TableCell >Created At</TableCell>
        <TableCell >Description</TableCell>
        <TableCell >Amount</TableCell>
        <TableCell >Deposit Id</TableCell>
        <TableCell>Souce</TableCell>
      </TableRow>
    </TableHead>
  )
}
