import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  Button,
} from 'components/atoms/Form'
import { required, maxLength256, maxLengthWarning256 } from 'forms/fieldValidators'
import { BASE } from 'theme/base-units'
import { renderTextArea, renderSwitch } from 'forms/fields'
import { Flexbox } from 'components/layout/index'
import { submit } from './submit'

let Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <Flexbox direction={'row'} justify={'space-between'}>
      <FormTitle>Add a compliance comment</FormTitle>
      <Flexbox direction={'column'} width={'auto'}>
        <Field
          label={'Flag'}
          name={'flag'}
          type={'yes/no'}
          formatLabel={false}
          margin={`0 ${BASE * 2}px 0 0`}
          component={renderSwitch}
        />
      </Flexbox>
    </Flexbox>

    <Flexbox justify={'flex-start'} style={{ marginRight: '8%' }}>
      <Field
        label={'Comment (required)'}
        name={'comment'}
        validate={[required, maxLength256]}
        component={renderTextArea}
        marginBottom={26}
        maxLength={256}
        warn={[maxLengthWarning256]}
      />
      <div style={{ marginLeft: '3%', marginTop: '1%' }} >
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button padding={'16px 41px'} disabled={submitting || invalid} onClick={onCancel} color='secondary'>
          Cancel
        </Button>
      </div>
    </Flexbox>
  </FormContainer>
}

Form = reduxForm({ form: 'ComplianceCommentsForm', onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
