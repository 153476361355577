import styled, { keyframes } from 'styled-components'
import Button from '@material-ui/core/Button'

const fadeInBtn = keyframes`
  0% {
    margin-right: -120px;
  }
  100% {
    margin-right: 12px;
  }
`

const fadeOutBtn = keyframes`
  0% {
    margin-right: 10px;
}

100% {
  margin-right: -120px;
  }
`
const accordionBody = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

export const SuspendButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
})`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  height: 40px;
  animation: 0.5s ${({ active }) => (active ? fadeInBtn : fadeOutBtn)} both;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s;
`

export const AccordionActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const StyledAccordionContent = styled.div`
  animation: 1s ${accordionBody};
  overflow: scroll;
`

export const StyledAccordionSummaryInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
