import { useState } from 'react'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

const useWithdrawalRestrictions = ({
  withdrawalRestriction: { amount, currencyShortName, createdAt },
  currencies,
  accountId,
}) => {
  const [
    removeWithdrawalRestrictionConfirmationDialog,
    setRemoveWithdrawalRestrictionConfirmationDialog,
  ] = useState({ open: false })

  const [showModal, setShowModal] = useState({
    open: false,
    title: '',
  })

  const [withdrawalRestrictionsData, setWithdrawalRestrictionsData] = useState({
    accountId: '',
    amount: '',
    currencyShortName: '',
  })

  const handleProceedRemoveWithdrawalRestriction = async () => {
    try {
      const data = await getAxios().delete(
        `/account-withdrawal-restrictions/${accountId}/${currencyShortName}`,
      )
      if (data.status === 202) {
        toast('Account withdrawal restrictions dual auth  updated', {
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        })
      }
      setRemoveWithdrawalRestrictionConfirmationDialog({
        open: false,
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

  const handleWithdrawalRestrictionUpdate = (
    accountId: string,
    amount: string,
    currencyShortName: string,
  ) => {
    setWithdrawalRestrictionsData({
      accountId,
      amount,
      currencyShortName,
    })
    setShowModal({
      open: true,
      title: 'Edit Withdrawal Restrictions',
    })
  }
  const deleteWithdrawalRestriction = () => {
    setRemoveWithdrawalRestrictionConfirmationDialog({
      open: true,
    })
  }

  const closeModal = () => {
    setWithdrawalRestrictionsData({
      accountId: '',
      amount: '',
      currencyShortName: '',
    })
    setShowModal({ open: false, title: '' })
  }

  return {
    closeModal,
    handleWithdrawalRestrictionUpdate,
    deleteWithdrawalRestriction,
    handleProceedRemoveWithdrawalRestriction,
    withdrawalRestriction: { amount, currencyShortName, createdAt },
    currencies,
    accountId,
    setRemoveWithdrawalRestrictionConfirmationDialog,
    showModal,
    removeWithdrawalRestrictionConfirmationDialog,
    withdrawalRestrictionsData,
  }
}

export default useWithdrawalRestrictions
