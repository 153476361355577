import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchWiredDepositUnderReview,fetchWiredDepositPendingRelease,updateWiredDeposits,updateWiredDepositsPendingRelease } from 'redux/circle/actions'
import UsdDepositsContainer from 'components/organisms/UsdDeposits/index '
import PendingUsdDepositsContainer from 'components/organisms/PendingUsdDeposits/index '
import { Header } from 'components/atoms/Generic/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import socket from 'redux/socket/index'

class UsdDeposits extends Component {

  componentDidMount() {
    this.props.fetchWiredDepositUnderReview()
    this.props.fetchWiredDepositPendingRelease()
    const busyDeposits = socket.on('busy deposits', data => {
      this.props.updateWiredDeposits(data)
      this.props.updateWiredDepositsPendingRelease(data)
    })
    const updatedOnMount = socket.on('status updated', data => {
      this.props.updateWiredDeposits(data)
      this.props.updateWiredDepositsPendingRelease(data)
    })

    const updateDepositsOnMout = socket.on('status updated on unmount', data => {
      this.props.updateWiredDeposits(data)
      this.props.updateWiredDepositsPendingRelease(data)
    })
    const depositStatus = socket.on('deposit status updated', data => {
      this.props.updateWiredDeposits(data)
      this.props.updateWiredDepositsPendingRelease(data)
    })
  
  }

  render() {
  const{wiredDepositReview,wiredDepositPendingReview}=this.props
    return (
      <div>
        <Header title={'USD DEPOSITS'} />
        {(wiredDepositReview.status !== 'done' || wiredDepositPendingReview.status !== 'done') && <LoadingBlock />}
        {wiredDepositReview.status === 'done'&&wiredDepositPendingReview.status==='done'&&
        <>
        <UsdDepositsContainer deposits={wiredDepositReview} 
        />
        <PendingUsdDepositsContainer deposits={wiredDepositPendingReview}/>
        </>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
wiredDepositReview:state.circle.wiredDepositReview,
wiredDepositPendingReview:state.circle.wiredDepositPendingReview
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWiredDepositUnderReview,
      fetchWiredDepositPendingRelease,
      updateWiredDeposits,
      updateWiredDepositsPendingRelease
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsdDeposits)
