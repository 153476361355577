import React, { Component, Fragment } from 'react'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePlaceholder,
  TableHeader,
} from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import RawDataDualAuth from 'components/atoms/RawDataDualAuth/index'
import { getMsgDetails } from 'forms/SearchAccountEmails/submit'

class LatestEmailHistory extends Component {
  constructor(props) {
    super(props)
    this.state = { msgDetails: {}, loading: true, expandedItem: '' }
  }

  renderMoreInfo = details => <RawDataDualAuth data={details} />

  toggleRow = async id => {
    const currentId = this.state.expandedItem
    if (currentId !== id) {
      var msgDetails = await getMsgDetails(id)
      await this.setState({ expandedItem: id })
      msgDetails !== undefined && (await this.setState({ msgDetails }))
    } else {
      await this.setState({ expandedItem: '', msgDetails: {} })
    }
  }

  renderEmails(data) {
    return data.length === 0 || data === undefined ? (
      <TablePlaceholder>{'No activity found'}</TablePlaceholder>
    ) : (
      data.map(record => {
        const {
          msgId,
          subject,
          status,
          opensCount,
          clicksCount,
          lastEventTime,
        } = record
        var data = {
          subject,
          status,
          opensCount,
          clicksCount,
          lastEventTime,
          msgDetails: this.state.msgDetails,
        }
        return (
          <Fragment key={msgId}>
            <TableRow onClick={() => this.toggleRow(msgId)}>
              <TableCell flex={1.5}> {subject} </TableCell>
              <TableCell align={'right'} flex={0.6}>
                {status}
              </TableCell>
              <TableCell align={'right'} flex={0.6}>
                {opensCount}
              </TableCell>
              <TableCell align={'right'} flex={0.6}>
                {clicksCount}
              </TableCell>
              <TableCell align={'right'} flex={0.6}>
                {moment(lastEventTime).format('YYYY-MMM-DD HH:mm')}
              </TableCell>
            </TableRow>
            {this.state.expandedItem === msgId && this.renderMoreInfo(data)}
          </Fragment>
        )
      })
    )
  }

  render() {
    const { src } = this.props
    const {
      data,
      data: { recentEmails },
      status,
    } = src
    return (
      <Table key={'recentEmails'}>
        <TableHeader>
          <TableCell align={'center'} flex={1.5}>
            Subject
          </TableCell>
          <TableCell align={'right'} flex={0.6}>
            Status
          </TableCell>
          <TableCell align={'right'} flex={0.6}>
            Opens Count
          </TableCell>
          <TableCell align={'right'} flex={0.6}>
            Clicks Count
          </TableCell>
          <TableCell align={'right'} flex={0.6}>
            Last Updated
          </TableCell>
        </TableHeader>
        <TableBody>
          {status === 'loading' && (
            <TablePlaceholder>
              <IconLoading />
              <span>Loading, please wait</span>
            </TablePlaceholder>
          )}
          {status === 'done' && this.renderEmails(recentEmails || data)}
        </TableBody>
      </Table>
    )
  }
}

export default LatestEmailHistory
