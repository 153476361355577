import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import {
  required,
  maxLength256,
  requiredDropdownValue,
} from 'forms/fieldValidators'
import {
  TransparentFormContainer,
  Button,
  InputGroup,
} from 'components/atoms/Form'
import { renderDatetime } from 'forms/fields'

import { submitFilter } from './submit'

let Form = props => {
  const { submitting, invalid, handleSubmit, onSubmit } = props
  return (
    <TransparentFormContainer width={'100%'} onSubmit={handleSubmit(onSubmit)}>
      <InputGroup>
        <Field
          label={'Start date'}
          name={'from'}
          marginRight={BASE * 2}
          component={renderDatetime}
          placeholder={'Date & Time'}
        />
        <Field
          label={'End date'}
          name={'to'}
          marginRight={BASE * 2}
          component={renderDatetime}
          placeholder={'Date & Time'}
        />
        <Button
          style={{ marginRight: '12%', marginTop: '4%' }}
          type="submit"
          main="true"
          disabled={submitting || invalid}
        >
          Filter
        </Button>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'keycloakDateForm',
  validate: values => {
    const errors = {}
    if (!values.from && !values.to) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
  onSubmit: submitFilter,
  enableReinitialize: true,
})(Form)

const ReduxForm = connect()(Form)
export default ReduxForm
