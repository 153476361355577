import React, { Component } from 'react'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { KycOverrideTable } from './KycOverride/index'
import { KycAddressOverrideTable } from './KycAddressOverride/index'
import { CellphoneChangeTable } from './CellphoneChange/index'
import { EmailChangeTable } from './EmailChange/index'
import { TotpRemovalTable } from './TotpRemoval/index'
import { UnblockAccountTable } from './UnblockAccount/index'
import { BlockchainSendsTable } from './BlockchainSendsReversal/index'
import { BlockchainReceivesAndConfirmationTable } from './BlockchainReceivesAndConfirmationSettings/index'
import { AccountSecurityChangeTable } from './AccountSecurityChange/index'
import FundsTable from './Funds/index'
import NameAndSurnameChangeTable from './ChangeNameAndSurname/index'
import { OtherTable } from './Other/index'
import IdentityNumberChangeTable from './ChangeIdentityNumber/index'
import { ResidentialCountryChangeTable } from './ResidentialCountryChange/index'
import WithdrawalLimitChangeTable from './WithdrawalLimit/index'
import CustomerTypeChangeTable from './CustomerTypeChange/index'
import RemoveIgnoredStatusTable from './RemoveIgnoredStatus/index'
import RemoveAddressBookTable from './RemoveAddressBook'
import WithdrawalRestrictionsTable from './WithdrawalRestrictions'
import { AllowExchangeControlTable } from './AllowExchangeControlTable'
import DefaultDualAuthTable from './DefaultDualAuthTable'

class DualAuth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedId: -1,
      highlightedId: '',
      canBulkSuspend: false,
    }
  }

  toggleSelected = id => {
    if (this.state.selectedId === id) {
      this.setState({ selectedId: -1 })
    } else {
      this.setState({ selectedId: id })
    }
  }

  setHighlightedId = id => {
    if (this.state.highlightedId !== id) {
      this.setState({ highlightedId: id })
    }
  }

  handleChange = panel => {
    const { setExpand } = this.props

    setExpand(panel)
  }

  render() {
    const {
      title,
      expanded,
      itemsAmount,
      dualAuthData,
    } = this.props
    const handleTableView = () => {
      if (dualAuthData.length === 0) return <TablePlaceholder>No Dual Auths</TablePlaceholder>
      switch (title) {
        case 'FUNDS':
          return <FundsTable
            data={dualAuthData}
          />
        case 'KYC OVERRIDE':
          return <KycOverrideTable
            data={dualAuthData}
          />
        case 'KYC ADDRESS OVERRIDE':
          return <KycAddressOverrideTable
            data={dualAuthData}
          />

        case 'NAME AND SURNAME CHANGE':
          return <NameAndSurnameChangeTable
            data={dualAuthData}
          />
        case 'CELLPHONE CHANGE':
          return <CellphoneChangeTable
            data={dualAuthData}
          />
        case 'EMAIL CHANGE':
          return <EmailChangeTable
            data={dualAuthData}
          />
        case 'RESIDENTIAL COUNTRY CHANGE':
          return <ResidentialCountryChangeTable
            data={dualAuthData}
          />
        case 'TOTP REMOVAL':
          return <TotpRemovalTable
            data={dualAuthData}
          />
        case 'UNBLOCK ACCOUNT':
          return <UnblockAccountTable
            data={dualAuthData}
          />
        case 'BLOCKCHAIN SEND REVERSAL AND WITHDRAWAL REVERSAL':
          return <BlockchainSendsTable
            data={dualAuthData}
          />
        case 'BLOCKCHAIN RECEIVES AND CONFIRMATION SETTINGS':
          return <BlockchainReceivesAndConfirmationTable
            data={dualAuthData}
          />
        case 'IDENTITY NUMBER CHANGE':
          return <IdentityNumberChangeTable
            data={dualAuthData}
          />
        case 'ACCOUNT SECURITY CHANGE BLOCK OVERRIDE':
          return <AccountSecurityChangeTable
            data={dualAuthData}
          />
        case 'WITHDRAWAL LIMIT':
          return <WithdrawalLimitChangeTable
            data={dualAuthData}
          />

        case 'CUSTOMER TYPE CHANGE':
          return <CustomerTypeChangeTable
            data={dualAuthData}
          />

        case 'REMOVE DEPOSIT IGNORED STATUS':
          return <RemoveIgnoredStatusTable
            data={dualAuthData}
          />
        case 'REMOVE WITHDRAWAL TO ADDRESS BOOK ONLY':
          return <RemoveAddressBookTable
            data={dualAuthData}
          />
        case 'FUNDING MANUAL CREDIT':
        case 'OTHER':
          return <OtherTable
            data={dualAuthData}
          />
        case 'ALLOW GENERATE RECEIVE ADDRESS':
          return <AllowExchangeControlTable
            data={dualAuthData}
          />
        case 'ALLOW ADD TO ADDRESS BOOK':
          return <AllowExchangeControlTable
            data={dualAuthData}
          />
        case 'WITHDRAWAL RESTRICTIONS':
          return <WithdrawalRestrictionsTable
            data={dualAuthData}
          />
        default:
          return <DefaultDualAuthTable
            dualAuthData={dualAuthData}
          />
      }
    }

    return (
      <ExpandingItem
        title={title}
        itemsAmount={itemsAmount}
        expanded={expanded}
        onExpand={this.handleChange}
        bulkActionsActive={false}
        expandedView={expanded ? handleTableView() : null}
      />
    )
  }
}

export default DualAuth
