import { useState } from 'react'
import config from 'config'
import { VeriffSession } from 'interfaces/veriffSessions'

const useVeriffSessions = () => {
  const [sourceId, setSourceId] = useState('')
  const generateLink = (id, type: 'veriff' | 'account') => {
    let link = ''
    switch (type) {
      case 'account':
        link = `/accounts/byId/${id}/details`
        break
      case 'veriff':
        link = `${config.veriff.veriffExternalUrl}/verifications/${id}`
        break

      default:
        break
    }
    return link
  }

  const getAccountIdFromVeriffSessions = (
    veriffSessions: VeriffSession,
  ): number => veriffSessions.accountId

  return {
    sourceId,
    setSourceId,
    generateLink,
    getAccountIdFromVeriffSessions,
  }
}

export default useVeriffSessions
