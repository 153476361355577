import {
  FETCH_ACCOUNT_KYC_INFO_PROCESS,
  FETCH_ACCOUNT_KYC_INFO_SUCCESS,
  FETCH_ACCOUNT_KYC_INFO_ERROR,
} from './actions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_KYC_INFO_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          status: 'loading',
          info: { description: '' },
        },
      }
    }
    case FETCH_ACCOUNT_KYC_INFO_SUCCESS: {
      const { id, ...data } = action.payload
      const { submitted, ...info } = data
      return {
        ...state,
        [id]: {
          status: 'done',
          submitted,
          info,
        },
      }
    }
    case FETCH_ACCOUNT_KYC_INFO_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        [id]: {
          status: 'error',
          info: { description: '' },
          error,
        },
      }
    }

    default:
      return state
  }
}
