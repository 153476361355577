import { WithdrawalRestrictionsProps } from 'containers/Accounts/SingleAccount/WithdrawalRestrictions/interface'
import { WithdrawalRestrictionsAction } from './actions'

type loadingStates = 'pristine' | 'loading' | 'done'
export interface AccountWithdrawalRistrictionsInitialState {
  status: loadingStates
  data: WithdrawalRestrictionsProps[]
}

const initialState: AccountWithdrawalRistrictionsInitialState = {
  status: 'pristine',
  data: [],
}

interface Action {
  type: WithdrawalRestrictionsAction
  payload: any
}

export default (
  state = initialState,
  action: Action,
): AccountWithdrawalRistrictionsInitialState => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_START': {
      return {
        ...state,
        status: 'loading',
      }
    }
    case 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_SUCCESS': {
      return {
        ...state,
        status: 'done',
        data: action.payload,
      }
    }
    case 'FETCH_ACCOUNT_WITHDRAWAL_RESTRICTIONS_ERROR': {
      return {
        ...state,
        status: 'pristine',
      }
    }

    case 'CLEAR_ACCOUNT_WITHDRAWAL_RESTRICTIONS': {
      return initialState
    }
    default:
      return state
  }
}
