import React, { FC } from 'react'

import { DualAuthHistoryInfo } from '../../../../interfaces/riskProfile'
import { getDesignator } from './helpers'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { TablePlaceholder, Title } from 'components/atoms/Table/styles'
import { Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'

interface ApprovalsSectionProps {
  dualAuthHistory: DualAuthHistoryInfo[]
}
const HistorySection: FC<ApprovalsSectionProps> = ({ dualAuthHistory }) => (
  <>
    <Flexbox justify={'center'} style={{ padding: '10px' }}>
      <Title>Edd status history</Title>
    </Flexbox>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Designation</TableCell>
          <TableCell>Approval</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dualAuthHistory.length === 0 && (
          <TableCell colSpan={7}>
            <TablePlaceholder>Edd report has no history</TablePlaceholder>
          </TableCell>
        )}

        {dualAuthHistory
          .filter((val) => val.action !== 'PENDING')
          .map((approval) => {
            const createdAt = approval.createdAt
              ? new Date(approval.createdAt).toString()
              : '--'
            return (
              <TableRow key={approval.actionedBy}>
                <TableCell>{getDesignator(approval.actionedBy)}</TableCell>
                <TableCell>{approval.actionedBy}</TableCell>
                <TableCell>{approval.action}</TableCell>
                <TableCell>{createdAt}</TableCell>
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  </>
)

export default HistorySection
