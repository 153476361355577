import styled from 'styled-components'

export const DepositByStatusLayout = styled.div`
  display: flex;
  justifycontent: flex-end;
  width: 20%;
`

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  margin-bottom: 18px;
  justify-items: flex-end;
  align-content: flex-end;
  align-items: center;
  padding-top: 4px;
`
