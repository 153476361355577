import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { toast } from 'react-toastify'
import { bindActionCreators } from 'redux'
import getAxios from 'helpers/axios'
import { connect } from 'react-redux'
import { capitalizeFirstLetter } from 'helpers/utils'
import { NATURAL_ENTITY_DOCUMENT_TYPES } from 'helpers/const'

import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import {
  handleUploadEntityDocumentWithProgress,
  deactivateNaturalEntityForCorporate,
  removeEntityDocument,
} from 'redux/corporateEntities/actions'
import IconButton from 'components/atoms/IconButton/index'

import {
  StyledCorporateUserTableData,
  StyledCorporateUserTableDataActions,
} from '../../Styles'
import EntityDocumentSection from '../EntityDocumentSection'
import DocumentsViewer from '../../DocumentsViewer'
import { NATURAL_ENTITY_ROLES } from '../../../const/Entities'
import { Link } from 'containers/Accounts/SingleAccount/link'

const Entity = props => {
  const {
    entity,
    entityType,
    handleEditEntity,
    handleUploadEntityDocumentWithProgress,
    deactivateNaturalEntityForCorporate,
    canEditCorporateInformation,
    isCheckingCorporateEditorRole,
    accountId,
  } = props
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)
  const [viewDocument, setViewDocument] = useState(false)
  const [documentsDataBeingViewed, setDocumentsDataBeingViewed] = useState([])
  const [showDeactivateEntityModal, setShowDeactivateEntityModal] = useState(
    false,
  )
  const [isDeactivatingEntitiy, setIsDeactivatingEntity] = useState(false)
  const unmounted = useRef(false)

  const handleCloseDocumentViewer = () => {
    setViewDocument(false)
    setDocumentsDataBeingViewed([])
  }

  const clearDocumentViewer = () => {
    setDocumentsDataBeingViewed([])
    setIsLoadingDocuments(false)
    setViewDocument(false)
  }

  const handleViewDocuments = async (entityId, documentType) => {
    try {
      setIsLoadingDocuments(true)
      setViewDocument(true)
      const documentsTypeToView = entity.documents.filter(
        document => document.documentType === documentType,
      )
      const documentsInfo = await Promise.all(
        documentsTypeToView.map(async document => {
          const {
            data: { file, fileType, documentName },
          } = await getAxios().get(
            `/corporates/document/${entityId}/${document.id}`,
          )
          return {
            id: document.id,
            file,
            documentType,
            createdAt: document.createdAt,
            documentName,
            mimeType: fileType,
          }
        }),
      )

      setDocumentsDataBeingViewed(documentsInfo)
      setIsLoadingDocuments(false)
    } catch (error) {
      toast(
        `${error.response.data.message ||
          'An error occurred when fetching the document. If the problem persists please contact support'}`,
        { type: toast.TYPE.ERROR, autoClose: 3000 },
      )
      clearDocumentViewer()
    }
  }

  const handleDeactivateEntityForCorporate = async (entityId, accountId) => {
    setIsDeactivatingEntity(true)
    await deactivateNaturalEntityForCorporate(entityId, accountId)
    if (unmounted.current) return
    setIsDeactivatingEntity(false)
    setDocumentsDataBeingViewed([])
    setShowDeactivateEntityModal(false)
  }

  useEffect(
    () => () => {
      unmounted.current = true
    },
    [],
  )

  return (
    <tr>
      <StyledCorporateUserTableData>
        {entity.firstName}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.lastName}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.idNumber || 'empty'}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.roles.length > 0 &&
          entity.roles.map(role => capitalizeFirstLetter(role)).join(', ')}
        {entity.roles.length === 0 && 'No roles selected yet'}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.nationality || '--'}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.isPep ? 'Yes' : 'No'}
      </StyledCorporateUserTableData>
      <StyledCorporateUserTableData style={{ textAlign: 'center' }}>
        {<Link id={entity.linkedAccountId}>{entity.linkedAccountId}</Link>}
      </StyledCorporateUserTableData>
      {Object.values(NATURAL_ENTITY_DOCUMENT_TYPES).map(documentType => {
        const entityDocuments =
          entity.documents &&
          entity.documents.filter(doc => doc.documentType === documentType)
        return (
          <EntityDocumentSection
            isLinkedAccount={entity.linkedAccountId}
            entityType={entityType}
            canEditCorporateInformation={canEditCorporateInformation}
            handleUploadEntityDocumentWithProgress={
              handleUploadEntityDocumentWithProgress
            }
            handleViewDocuments={handleViewDocuments}
            isCheckingCorporateEditorRole={isCheckingCorporateEditorRole}
            key={documentType}
            accountId={accountId}
            documentType={documentType}
            documents={entityDocuments || []}
            entityId={entity.id}
          />
        )
      })}
      <StyledCorporateUserTableDataActions>
        <IconButton
          disabled={
            !canEditCorporateInformation || isCheckingCorporateEditorRole
          }
          onClick={() => handleEditEntity(entity)}
          icon={<EditIcon />}
        />
        <IconButton
          disabled={
            !canEditCorporateInformation ||
            isCheckingCorporateEditorRole ||
            isDeactivatingEntitiy
          }
          onClick={() => setShowDeactivateEntityModal(true)}
          icon={<DeleteIcon />}
        />
      </StyledCorporateUserTableDataActions>
      <ConfirmationDialog
        title="Remove entity from corporate"
        open={showDeactivateEntityModal}
        proceed={() => handleDeactivateEntityForCorporate(entity.id, accountId)}
        message={`Are you sure you want to remove the natural entity named ${
          entity.firstName
        }?`}
        disabled={isDeactivatingEntitiy}
        handleClose={() => setShowDeactivateEntityModal(false)}
      />
      <DocumentsViewer
        entityId={entity.id}
        isLoadingDocuments={isLoadingDocuments}
        isOpen={viewDocument}
        documentsDataBeingViewed={documentsDataBeingViewed}
        handleCloseDocumentViewer={handleCloseDocumentViewer}
        accountId={accountId}
        removeDocument={props.removeEntityDocument}
        setDocumentsDataBeingViewed={setDocumentsDataBeingViewed}
      />
    </tr>
  )
}

Entity.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    idNumber: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.oneOf(NATURAL_ENTITY_ROLES)),
    linkedAccountId: PropTypes.number,
    idDocument: PropTypes.shape({
      fileName: PropTypes.string,
      uploadDate: PropTypes.string,
    }),
    proofOfResidenceDocument: PropTypes.shape({
      fileName: PropTypes.string,
      uploadDate: PropTypes.string,
    }),
    uploadState: PropTypes.shape({
      idDocumentUploadState: PropTypes.shape({
        progress: PropTypes.number,
        uploadErrorMessage: PropTypes.string,
      }),
      proofOfResidenceDocumentUploadState: PropTypes.shape({
        progress: PropTypes.number,
        uploadErrorMessage: PropTypes.string,
      }),
    }),
  }),
  entityType: PropTypes.string,
  handleEditEntity: PropTypes.func,
  handleUploadEntityDocumentWithProgress: PropTypes.func,
}

const mapStateToProps = state => {
  const {
    canEditCorporateInformation,
    isCheckingCorporateEditorRole,
  } = state.corporate
  return {
    canEditCorporateInformation,
    isCheckingCorporateEditorRole,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleUploadEntityDocumentWithProgress,
      deactivateNaturalEntityForCorporate,
      removeEntityDocument,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Entity)
