import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  FormContainer,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import {
  Details,
  DetailsBlockWithTitle,
} from 'components/atoms/Details'
import DepositStatusView from 'components/organisms/ReviewStatuses'
import { hasDepositEditorRole } from 'helpers/roleBasedAccess'
import RawData from 'components/atoms/RawDataDualAuth/index'
import Notes from 'components/atoms/Notes/index'
import { submitDepositReviewByStatus } from './submit'
import { renderDropdown, renderInput } from '../fields'

const renderAccountIdField = accountId => {
  const hasRole = hasDepositEditorRole()
  return (
    <Field
      label={'Account ID (required)'}
      name={'accountId'}
      width={'50%'}
      margin={`0 ${BASE * 2}px 0 0`}
      value={accountId || ''}
      validate={[required]}
      disabled={!hasRole}
      component={renderInput}
    />
  )
}

let Form = props => {
  const {
    submitting, error, invalid, statusType,
  } = props
  const {
    handleSubmit, onCancel, onSubmit, rawData, comments, depositId,
  } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'space-between'} align={'baseline'} direction={'row'}>
        <Field
          label={'Deposit Status'}
          name={'status'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'50%'}
          component={renderDropdown}
          options={[
            { value: 'CREDIT', label: 'Approve' },
            { value: 'IGNORE', label: 'Reject' },
          ]}
        />
        {(rawData.status.match('UNKNOWN_REFERENCE') !== null ||
          rawData.status.match('INVALID_DESCRIPTION') !== null) &&
          statusType !== 'SUSPEND' &&
          statusType !== 'IGNORE' &&
          renderAccountIdField(rawData.accountId)}
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required]}
          component={renderInput}
          justify={'space-around'}
          margin={`0 ${BASE * 2}px 0 0`}
          marginTop={26}
        />
      </InputGroup>
      <Details>
        <DetailsBlockWithTitle title="Statuses">
          <DepositStatusView statuses={rawData.statuses} />
        </DetailsBlockWithTitle>
      </Details>
      <Details>
        <DetailsBlockWithTitle title="Notes">
          <Notes depositId={depositId} comments={comments} />
        </DetailsBlockWithTitle>
      </Details>
      <Details>
        <DetailsBlockWithTitle title="Raw Data">
          <RawData data={rawData} />
        </DetailsBlockWithTitle>
      </Details>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}
Form = reduxForm({ onSubmit: submitDepositReviewByStatus })(Form)

const mapStateToProps = (state, ownProps) => {
  const depositByStatusForm = state.form[ownProps.form]
  if (!depositByStatusForm) {
    return {
      statusType: '',
    }
  }
  if (depositByStatusForm.values) {
    const {
      values: {
        status, comment, selectedValue,
      },
    } = depositByStatusForm
    return {
      statusType: status && status.value,
      selectedValue: selectedValue || '',
      comment: comment || '',
    }
  }
  return { statusType: '' }
}


const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
