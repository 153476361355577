import React, { FC, useState } from 'react'
import { KeycloakGroup } from '../interfaces'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import CustomAccordion from 'components/organisms/Accordion'
import { BLUISH_GREY, VALR_BLUE_GRAY } from 'theme/colors'
import {
  StyledAccordionAction,
  StyledGroup,
  StyledSubGroupsSection,
} from '../Styles'
interface IKeycloakAdminGroup {
  group: KeycloakGroup
  isFetchingMembers: boolean
  handleFetchMembersForGroup: (group: KeycloakGroup) => Promise<void>
}

const KeycloakAdminGroup: FC<IKeycloakAdminGroup> = ({
  group,
  isFetchingMembers,
  handleFetchMembersForGroup,
}) => {
  const theme = localStorage.getItem('adminTheme') as 'lightTheme' | 'darkTheme'
  const showGroupsAndSubGroups = (groups: KeycloakGroup[]) => {
    return groups.map((g) => {
      return (
        <KeycloakAdminGroup
          key={g.id}
          isFetchingMembers={isFetchingMembers}
          handleFetchMembersForGroup={handleFetchMembersForGroup}
          group={g}
        />
      )
    })
  }
  if (group.subGroupCount > 0) {
    return (
      <CustomAccordion
        extraHeader={
          <MuiButton
            disabled={isFetchingMembers}
            onClick={(e) => {
              e.stopPropagation()
              handleFetchMembersForGroup(group)
            }}
          >
            View Users
          </MuiButton>
        }
        title={group.name}
      >
        <StyledSubGroupsSection key={group.id}>
          <h3>Subgroups: </h3>
          {showGroupsAndSubGroups(group.subGroups)}
        </StyledSubGroupsSection>
      </CustomAccordion>
    )
  }
  return (
    <StyledGroup theme={theme}>
      {group.name}{' '}
      <MuiButton
        disabled={isFetchingMembers}
        onClick={(e) => {
          e.stopPropagation()
          handleFetchMembersForGroup(group)
        }}
      >
        View Users
      </MuiButton>
    </StyledGroup>
  )
}

export default KeycloakAdminGroup
