import { reset, SubmissionError } from 'redux-form'
import getAxios from 'helpers/axios'
import { throwGenericSubmissionError } from 'helpers/utils'
import ErrorToast from 'components/molecules/ErrorToast'
import { toast } from 'react-toastify'
import auth from 'services/Auth'

export const submitAddRoleToGroup = (values) => async dispatch => {
  const {
    initiatorEmail = auth.getEmailFromToken(),
    serviceType = 'admin',
    requestType = 'create_keycloak_role_for_group',
    role,
    group,
  } = values
  let res
  try {
    res = await getAxios().post('/ferryman/requestferryauth', {
      initiatorEmail,
      serviceType,
      requestType,
      payload: {
        role,
        keycloakGroupName: group.label,
        keycloakGroupId: group.value,
      },
    })
    toast(`Ferry Auth Request to Create Role Successfully Initiated: # ${res.data.message_id}`, {
      type: toast.TYPE.SUCCESS,
      autoClose: 20000,
    })
    dispatch(reset('AddKeycloakRole'))
  } catch (e) {
    ErrorToast(e)
    if (e.response.data.error) {
      const { error } = e.response.data
      switch (error.code) {
        case -7:
          throw new SubmissionError({
            referenceNumber: error.message,
          })

        default:
          throwGenericSubmissionError({
            status: e.response.status || 400,
            message:
              e.response.data.message ||
              'Server error. Contact administrator to get help',
          })
          break
      }
    }
  }
}
