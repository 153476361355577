import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submit = async (values) => {
  const {
    accountId,
    belowThresholdConfirmationCount,
    threshold, aboveThresholdConfirmationCount } = values
  const currency = values.currency.value
  const body = {
    currency,
    belowThresholdConfirmationCount,
    threshold,
    aboveThresholdConfirmationCount,
  }
  try {
    const request = {
      path: `/admin/account/${accountId}/confirmations`,
      body,
    }
    const res = await getAxios().post('/generic', request, body)
    if (res.data.success) {
      toast('Account confirmation updated', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
    } else {
      toast(res.data.error.message, { type: toast.TYPE.ERROR, autoClose: 3000 })
    }
    return res
  } catch (error) {
    toast(`Failed to update account confirmation. \n ${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
