import { FontColor } from 'components/atoms/Generic'
import { FullscreenCentered } from 'components/layout'
import React, { useEffect } from 'react'
import { BASE } from 'theme/base-units'
import { useAuth } from 'context/auth'
import { PANEL_BLUE, WHITE } from 'theme/colors'

import IconLoading from 'components/atoms/IconLoading'

const AuthenticatingDisplay = () => (
  <FullscreenCentered bgColor={WHITE}>
    <FontColor color={PANEL_BLUE}>
      <IconLoading size={BASE * 5} />
      <p>Authenticating...</p>
    </FontColor>
  </FullscreenCentered>
)

const Callback = () => {
  const { handleAuthentication } = useAuth()
  useEffect(() => {
    handleAuthentication()
  }, [])

  return <AuthenticatingDisplay />
}

export default Callback
