import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  FormContainer,
  FormTitle,
  InputGroup,
  SimpleFormError,
} from 'components/atoms/Form'
import { renderInput } from 'forms/fields'
import {
  isValidEmail,
  isNumber,
  maxLength20,
  maxLength64,
} from 'forms/fieldValidators'

import { submitFilter } from './submit'

const FormSearchAccount = props => {
  const { handleSubmit, submitting, invalid, error, pristine } = props
  return (
    <FormContainer onSubmit={handleSubmit(submitFilter)}>
      <FormTitle>Search Accounts</FormTitle>
      <InputGroup justify={'space-between'}>
        <Field name={'name'} component={renderInput} label="Name" width="49%" />
        <Field
          name={'surname'}
          component={renderInput}
          label="Surname"
          width="49%"
        />
      </InputGroup>
      <InputGroup justify={'space-between'}>
        <Field
          name={'cellNumber'}
          component={renderInput}
          label="Phone Number"
          validate={[isNumber]}
          width="49%"
        />
        <Field
          name={'email'}
          component={renderInput}
          label="Email"
          validate={[isValidEmail]}
          width="49%"
        />
      </InputGroup>
      <InputGroup justify={'space-between'}>
        <Field
          name={'depositReference'}
          component={renderInput}
          label="Fiat Deposit Reference"
          width="49%"
        />
        <Field
          name={'idNumber'}
          component={renderInput}
          label="Identity Number"
          validate={[maxLength20]}
          width="49%"
        />
      </InputGroup>
      <InputGroup justify={'space-between'}>
        <Field
          name={'corporateName'}
          component={renderInput}
          label="Corporate Name"
          validate={[maxLength64]}
          width="49%"
        />
      </InputGroup>
      {error && !pristine && <SimpleFormError message={error} />}
      <InputGroup justify={'flex-end'}>
        <MuiButton
          color="primary"
          variant="contained"
          type="submit"
          main="true"
          disabled={submitting || invalid}
        >
          Search
        </MuiButton>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxFormSearchAccount = reduxForm({
  form: 'searchAccounts',
  onSubmitSuccess: (result, dispatch, props) => {},
  validate: values => {
    const errors = {}
    if (
      !values.name &&
      !values.surname &&
      !values.email &&
      !values.cellNumber &&
      !values.depositReference &&
      !values.idNumber &&
      !values.corporateName
    ) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
})(FormSearchAccount)

const mapStateToProps = state => ({
  state,
})

export default connect(mapStateToProps)(ReduxFormSearchAccount)
