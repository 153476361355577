import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import Button from '@material-ui/core/Button'
import getAxios from 'helpers/axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { toast } from 'react-toastify'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchCompetitionDetails } from 'redux/competitionDetails/actions'
import { InputGroup } from 'components/atoms/Form/index'
import ErrorToast from 'components/molecules/ErrorToast'

import CompetitionForm from './FormCompetition'
import CompetitionDetails from './CompetitionDetails/CompetitionDetails'
import { Spacer } from 'components/atoms/Generic'

function Competition(props) {
  const [showActiveCompetitionUsers, setShowActiveCompetitionUsers] =
    React.useState({
      activeCompetition: {
        competition: '',
        activeWinnersById: [],
      },
    })
  const [open, setOpen] = React.useState(false)
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false)
  const [scroll] = React.useState('paper')
  const [loading, setLoading] = React.useState(false)
  const [validateLoading, setValidateLoading] = React.useState(false)
  // need to re-check this
  const [activateConfirm, setActiveConfirm] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const cancel = () => {
    setOpen(false)
    setActiveConfirm(false)
    setShowConfirmationModal(false)
  }

  const [showForm, setShowForm] = React.useState(false)

  const descriptionElementRef = React.useRef(null)

  function isNumber(s) {
    return /^-?\d+(\.\d+)?$/.test(s)
  }

  const generateCsvFailureReason = (row) => {
    let reason = ''
    if (!row.from_account_id) {
      reason = reason += `from account id is missing`
    } else if (!isNumber(row.from_account_id)) {
      reason = reason += `from account id is incorrect value or format`
    }
    if (!row.to_account_id) {
      reason = reason += `${reason && ', '}to account id is missing`
    } else if (!isNumber(row.to_account_id)) {
      reason = reason += `${
        reason && ', '
      }to account id is incorrect value or format`
    }
    if (!row.amount) {
      reason = reason += `${reason && ', '}amount is missing`
    } else if (!isNumber(row.amount)) {
      reason = reason += `${reason && ', '}amount is incorrect value or format`
    }
    if (!row.clients_transaction_history_comment) {
      reason = reason += `${
        reason && ', '
      }clients transaction history comment not provided.`
    }
    return reason
  }
  const validate = async (data) => {
    setValidateLoading(true)
    const competitionCheck = data.activeWinnersById.filter(
      (dat) => dat.reasonId !== undefined,
    )
    const allFailureReasons = []
    const comparingArray = []
    for (let i = 0; i < competitionCheck.length; i++) {
      if (
        isNumber(competitionCheck[i].from_account_id) &&
        isNumber(competitionCheck[i].to_account_id) &&
        isNumber(competitionCheck[i].amount) &&
        competitionCheck[i].currency &&
        competitionCheck[i].clients_transaction_history_comment
      ) {
        comparingArray.push(competitionCheck[i])
      } else {
        const reason = generateCsvFailureReason(competitionCheck[i])
        allFailureReasons.push(`Csv failing at row ${i + 1} ${reason}`)
      }
    }
    if (allFailureReasons.length > 0) {
      const reasonText = allFailureReasons.reduce(
        (acc, curr) => acc + ` ${curr}`,
        '',
      )
      toast(reasonText, { type: toast.TYPE.ERROR, autoClose: 3500 })
    }
    if (competitionCheck.length === comparingArray.length) {
      setActiveConfirm(true)
      toast('Successfully validated csv data.', {
        type: toast.TYPE.SUCCESS,
      })
    }
    setValidateLoading(false)
    return data
  }

  const confirmed = async (data) => {
    setLoading(true)
    const activeWinnersById = data.activeWinnersById.filter(
      (dat) => dat.from_account_id,
    )
    try {
      const results = await getAxios().post(
        '/internal-transfer/create',
        activeWinnersById,
      )
      const finalResults = activeWinnersById.map((comp, index) => {
        const objectToReturn = comp
        objectToReturn.internalId = results.data[index]
        return objectToReturn
      })
      const theResults = {
        competitionName: data.competitionName,
        templateId: data.templateId,
        activeWinnersById: finalResults,
      }
      await getAxios().post('/trigger/competition-update', {
        data: theResults,
      })
      toast('Competition Successfully added', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
      props.fetchCompetitionDetails()
      props.reset('CompetitionForm')
    } catch (error) {
      ErrorToast(error)
    } finally {
      setLoading(false)
      setShowForm(false)
      setActiveConfirm(false)
      setShowConfirmationModal(false)
    }
  }

  const showTotalsForCurrencies = (transfers) => {
    let currenciesObjWithTotal = transfers.reduce((acc, curr) => {
      if (acc[curr.currency]) {
        const oldAmount = acc[curr.currency]
        acc[curr.currency] = parseFloat(oldAmount) + parseFloat(curr.amount)
      } else {
        acc[curr.currency] = curr.amount
      }
      return acc
    }, {})
    return Object.keys(currenciesObjWithTotal).map(
      (key, i) =>
        `${currenciesObjWithTotal[key]} ${key}${
          i === Object.keys(currenciesObjWithTotal).length - 1 ? ' ' : ', '
        }`,
    )
  }

  const triggerCompetitionUpdate = (res) => (
    <>
      {showForm && open && (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
              style: {
                margin: '40px auto 0',
                width: '95%',
                maxWidth: '1100px',
              },
            }}
          >
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead align="right" style={{ minWidth: '170' }}>
                    <TableRow>
                      <TableCell>Counter</TableCell>
                      <TableCell>From Account Id</TableCell>
                      <TableCell>To Account Id</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>
                        Client's transaction history comment
                      </TableCell>
                      <TableCell>Internal comment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {res.activeCompetition.activeWinnersById.map((row, i) => {
                      if (row.reasonId) {
                        return (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.from_account_id}</TableCell>
                            <TableCell>{row.to_account_id}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.currency}</TableCell>
                            <TableCell>
                              {row.clients_transaction_history_comment}
                            </TableCell>
                            <TableCell>{row.internal_comment}</TableCell>
                          </TableRow>
                        )
                      }
                    })}
                  </TableBody>
                </Table>
              </DialogContentText>
            </DialogContent>
            <Spacer margin="0px 0px 0px 12px">
              <h4>
                Totals for currencies:{' '}
                {showTotalsForCurrencies(
                  res.activeCompetition.activeWinnersById,
                )}
              </h4>
            </Spacer>
            <DialogActions>
              <InputGroup justify={'flex-end'} style={{ marginTop: '2%' }}>
                {validateLoading ? (
                  <LoadingBlock
                    width="auto"
                    margin="0 2% 0 0"
                    message="Loading..."
                  />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '80px', marginRight: '2%' }}
                    onClick={() => validate(res.activeCompetition)}
                  >
                    Validate
                  </Button>
                )}
                {loading ? (
                  <LoadingBlock
                    width="auto"
                    margin="0 2% 0 0"
                    message="Loading..."
                  />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '80px', marginRight: '2%' }}
                    onClick={() => confirmed(res.activeCompetition)}
                    disabled={!activateConfirm}
                  >
                    Confirm
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '80px' }}
                  onClick={() =>
                    cancel(res.activeCompetition.activeWinnersById)
                  }
                >
                  Cancel
                </Button>
              </InputGroup>
            </DialogActions>
          </Dialog>
        </>
      )}
      <Dialog
        onClose={() => setShowConfirmationModal(false)}
        open={showConfirmationModal}
      >
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            No emails will be sent for this batch. Please specify a Sendgrid
            template ID for email notifications
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <InputGroup justify={'flex-end'} style={{ marginTop: '2%' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ width: '80px', marginRight: '2%' }}
              onClick={() => {
                setShowConfirmationModal(false)
                setOpen(true)
                setShowForm(true)
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ width: '80px' }}
              onClick={() => cancel(res.activeCompetition.activeWinnersById)}
            >
              Cancel
            </Button>
          </InputGroup>
        </DialogActions>
      </Dialog>
    </>
  )

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Fragment>
      <CompetitionForm
        onSubmitSuccess={(value) => {
          if (value) {
            setShowActiveCompetitionUsers({ activeCompetition: value })
            if (
              value.activeWinnersById.length &&
              value.activeWinnersById[0].templateId
            ) {
              setOpen(true)
              setShowForm(true)
            } else {
              setShowConfirmationModal(true)
            }
          }
        }}
      />
      {showActiveCompetitionUsers.activeCompetition.activeWinnersById.length > 0
        ? triggerCompetitionUpdate(showActiveCompetitionUsers)
        : ''}
      <CompetitionDetails />
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCompetitionDetails,
      reset,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(Competition)
