import React, { Component } from 'react'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormSuspendedDeposit from 'forms/DepositReview/index'
import { fetchDepositReview } from 'redux/fiat/actions'
import BigNumber from 'bignumber.js'
import FormDepositsComments from 'forms/DepositsComments/index'
import auth from 'services/Auth'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import './Styles.css'
import socket from 'redux/socket/index'
import { RenderCheckbox } from 'forms/fields'
import { checkIfFallBackDepositCanBeSelected } from 'helpers/utils'
import EyeIcon from 'components/atoms/EyeIcon/index'
import { UNRESOLVED_FIAT_DEPOSIT_TITLES } from 'helpers/const'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { TransitionCell } from '../Styles'
import { Link } from '../AccountIdLink/clickableId'
import { VALR_BLUE_GRAY } from 'theme/colors'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { TableCell, TableRow } from '@material-ui/core'
import { showSendEmailActionButton } from 'components/atoms/ExpandingItem/helpers'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
      test: '',
    }
  }

  updateComments = async (depositId) => {
    this.setState({ commentsStatus: 'loading' })
    const data = await fetchDepositComments(depositId)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  refineRawData = (rawData) => {
    const { source } = rawData
    rawData.source = source.replace(/\W\d*/g, ' ').trim()
    return rawData
  }

  setNotification = (notes, depositId) =>
    notes.depositComments.length > 0 &&
    notes.depositComments.find((val) => val.depositId === depositId) !== -1

  expandRow = (depositId) => {
    this.props.toggleForm(depositId)
    this.updateComments(depositId)
  }

  removeActiveUser = async () => {
    const depositId = this.props.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId }
    const removeUser = { depositId, activeUserQue }
    socket.emit('remove user', removeUser)
  }

  showActiveuser = async () => {
    const depositId = this.props.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId }
    const activeUser = { depositId, activeUserQue }
    socket.emit('add user', activeUser)
  }

  renderAddNoteForm = () => {
    const {
      resetForm,
      deposit: { depositId },
      toggleAddNotesForm,
    } = this.props

    return (
      <FormDepositsComments
        initialValues={{
          depositId,
          data: {
            date: new Date(),
            createdBy: auth.decodeNameToken(),
            depositsId: depositId,
          },
        }}
        form={'depositCommentsForm'}
        depositData={this.props.deposit}
        onCancel={resetForm}
        toggleForm={() => {
          toggleAddNotesForm(depositId)
          this.updateComments(depositId)
        }}
      />
    )
  }

  render() {
    const {
      deposit,
      isPending,
      isHighlighted,
      setHighlightedId,
      bulkActionsActive,
      title,
      handleSelectedBulkDepositClick,
      selectedBulkDeposits,
      isCellHidden,
      actionType,
      handleSendIndividualZendeskEmail,
    } = this.props
    const {
      depositId,
      accountId,
      amount,
      depositsDescription,
      dateTime,
      accountBasicInfoForDisplay,
      insertedAt,
      tag,
      active,
      currency,
    } = deposit
    const { formShown } = this.props
    const name = accountBasicInfoForDisplay && accountBasicInfoForDisplay.name
    const surname =
      accountBasicInfoForDisplay && accountBasicInfoForDisplay.surname
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const shading =
      isPending && depositRowSelected
        ? '#d2d3d6'
        : isPending
          ? '#8c8c8c'
          : !isPending && depositRowSelected
            ? 'white'
            : ''
    const isFallBackDepositsTable =
      title === UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT

    const depositRowSelected = !!selectedBulkDeposits.find(
      (deposit) => deposit.depositId === depositId,
    )
    const checkboxDisabled =
      isPending ||
      (actionType === 'APPROVE' &&
        title === UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT &&
        !checkIfFallBackDepositCanBeSelected(deposit))

    return (
      <>
        <TableRow
          className={isHighlighted || depositRowSelected ? 'active' : ''}
          onClick={() => {
            setHighlightedId((prev) => (prev === depositId ? '' : depositId))
            this.expandRow(depositId)
          }}
        >
          <TableCell>
            {accountId ? <Link color={shading} id={accountId} /> : '--'}
          </TableCell>
          <TableCell style={{ color: shading }}>{name || '--'}</TableCell>
          <TableCell style={{ color: shading }}>{surname || '--'}</TableCell>
          <TableCell style={{ color: shading }}>
            {formattedAmount !== 'NaN' ? formattedAmount : amount}
          </TableCell>
          <TableCell style={{ color: shading }}>{currency}</TableCell>
          <TableCell style={{ color: shading }}>{depositsDescription}</TableCell>
          <TableCell style={{ color: shading }}>{dateTime}</TableCell>
          <TableCell style={{ color: shading }}>{insertedAt}</TableCell>
          <TableCell style={{ color: shading }}>
            {active && active.userActive ? (
              <EyeIcon text={active.activePersonName} />
            ) : (
              '--'
            )}
          </TableCell>
          <TableCell style={{ color: shading }}>
            {tag ? (
              <i
                style={{ color: '#e96868' }}
                className="fa fa-sticky-note"
                aria-hidden="true"
              />
            ) : (
              '--'
            )}
          </TableCell>
          {showSendEmailActionButton(title) && (
            <TableCell>
              <MuiButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleSendIndividualZendeskEmail(deposit)
                }}
                disabled={deposit.depositZendeskEmailSent}
              >
                Send Email
              </MuiButton>
            </TableCell>
          )}
          <TransitionCell
            className={
              bulkActionsActive
                ? 'bulkSuspendCheckBoxActive'
                : 'bulkSuspendCheckBoxfade'
            }
            onClick={(e) => e.stopPropagation()}
            show={isCellHidden}
          >
            <TableCell>
              <RenderCheckbox
                onCheckFunction={() => {
                  if (isFallBackDepositsTable && !actionType) {
                    return ErrorToast(
                      'Please select an action type before selecting deposits',
                    )
                  }
                  handleSelectedBulkDepositClick({
                    depositId,
                    title,
                    accountId,
                    depositZendeskEmailSent: deposit.depositZendeskEmailSent,
                    requesterEmail: deposit.accountBasicInfoForDisplay ? deposit.accountBasicInfoForDisplay.email : 'N/A',
                    userName: deposit.accountBasicInfoForDisplay ? deposit.accountBasicInfoForDisplay.name : 'N/A',
                  })
                }}
                style={{ color: depositRowSelected ? 'white' : '' }}
                checked={depositRowSelected}
                disabled={checkboxDisabled}
              />
            </TableCell>
          </TransitionCell>
        </TableRow>
        {formShown && (
          <TableRow className="no-hover">
            <TableCell colSpan={12}>
              <FormSuspendedDeposit
                title={title}
                initialValues={{
                  depositId,
                  accountId: accountId || '',
                  comment: '',
                  status: '',
                  zendeskCode: '',
                }}
                depositId={depositId}
                expandRawData={this.props.expandRawData}
                expandAddNote={this.props.addNoteFormShown}
                showRawData={() => this.props.showRawData(depositId)}
                form={`SuspendedDepositsForm+${depositId}`}
                removeActiveUser={this.removeActiveUser}
                showActiveUser={this.showActiveuser}
                rawData={this.refineRawData(this.props.deposit)}
                onCancel={this.props.toggleForm}
                showAddNote={() => this.props.toggleAddNotesForm(depositId)}
                isInDualAuth={isPending}
                comments={{
                  depositComments: this.state.comments,
                  status: this.state.commentsStatus,
                }}
              />
              {this.props.addNoteFormShown && this.renderAddNoteForm()}
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDepositReview,
      resetForm: () => reset('depositCommentsForm'),
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(Row)
