import getAxios from 'helpers/axios'
import { Dispatch } from 'redux'

export const FETCH_VIRTUAL_ACCOUNT_START = 'account/FETCH_VIRTUAL_ACCOUNT_START'
export const FETCH_VIRTUAL_ACCOUNT_SUCCESS = 'account/FETCH_VIRTUAL_ACCOUNT_SUCCESS'
export const FETCH_VIRTUAL_ACCOUNT_ERROR = 'account/FETCH_VIRTUAL_ACCOUNT_ERROR'
export const FETCH_VIRTUAL_ACCOUNT_CLEAR = 'account/FETCH_VIRTUAL_ACCOUNT_CLEAR'

export const clearVirtualAccount = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_VIRTUAL_ACCOUNT_CLEAR,
  })
}

export const fetchVirtualAccount = id => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_VIRTUAL_ACCOUNT_START,
  })
  try {
    const res = await getAxios().get(`/fiat/virtual-account/${id}`)
    const { data } = res
    dispatch({
      type: FETCH_VIRTUAL_ACCOUNT_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_VIRTUAL_ACCOUNT_ERROR,
    })
  }
}
