import { LOCATION_CHANGE } from 'react-router-redux'
import { CLEAR_REFRESH_REDIRECT } from './actions'

const initialState = { redirectOnRefresh: undefined };

const redirectOnRefreshBlacklist = ['/', '/callback', '/login'];

export default function routing(state = initialState, action)
{
  switch (action.type) {
    case LOCATION_CHANGE: {
        let redirectLocation = ''
        if(action.payload && action.payload.pathname) {
            redirectLocation += action.payload.pathname
        }
        if(action.payload && action.payload.search) {
            redirectLocation += action.payload.search
        }
        if(redirectOnRefreshBlacklist.indexOf(redirectLocation) > -1) {
            return state
        }else{
            return { redirectOnRefresh: redirectLocation }
        }
    }
    case CLEAR_REFRESH_REDIRECT:
        return initialState
    case 'persist/REHYDRATE': {
        // This is used to prevent newly opened tabs from redirecting to the previous tab's URL
        let redirectLocation = ''
        if(window.location.pathname) {
            redirectLocation += window.location.pathname
        }
        if(window.location.search) {
            redirectLocation += window.location.search
        }
        if(redirectOnRefreshBlacklist.indexOf(redirectLocation) > -1) {
            return state
        }else if(redirectOnRefreshBlacklist.indexOf(redirectLocation) === -1 && redirectLocation !== ""){
            return { redirectOnRefresh: redirectLocation }
        }
    }
    default:
      return state
  }
}