import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_ELASTIC_SEARCH_LOGS_PROCESS = 'accounts/FETCH_ELASTIC_SEARCH_LOGS_PROCESS'
export const FETCH_ELASTIC_SEARCH_LOGS_SUCCESS = 'accounts/FETCH_ELASTIC_SEARCH_LOGS_SUCCESS'
export const FETCH_ELASTIC_SEARCH_LOGS_ERROR = 'accounts/FETCH_ELASTIC_SEARCH_LOGS_ERROR'
export const RESET_ELASTIC_SEARCH_LOGS = 'accounts/RESET_ELASTIC_SEARCH_LOGS'

export const fetchLogsByQuery = values => async dispatch => {
    const { id, ip} = values
    values.id = id && id.trim()
    values.ip = ip && ip.trim()

  try {
    dispatch({
      type: FETCH_ELASTIC_SEARCH_LOGS_PROCESS,
    })
    const res = await getAxios().post(`/accounts/generic/logs`, values)
    const { data } = res
    dispatch({
      type: FETCH_ELASTIC_SEARCH_LOGS_SUCCESS,
      payload: { data },
    })
    return data
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ELASTIC_SEARCH_LOGS_ERROR,
      payload: { error },
    })
  }
}

export const refreshScreen = () => (dispatch) => {
  dispatch({
    type: RESET_ELASTIC_SEARCH_LOGS,
  })
}