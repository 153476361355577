import getAxios from 'helpers/axios'
import {
  RecommendationStatusTypes,
  RiskLevelTypes,
} from 'interfaces/riskProfile'
import { Dispatch } from 'react'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'

import {
  CorporateRiskBasedApproachResponse,
  DualAuthPayload,
  DualAuthResponse,
  FetchCorporateRiskBasedApproachDispatch,
  InitializeRiskProfileReviewDispatch,
  InitializeRiskProfileReviewResponse,
  InitiateDualAuthDispatch,
} from './interfaces'

export const fetchCorporateRiskBasedProfiles =
  (
    offSet: string,
    limit: string,
    type: string,
    riskLevelToSearch: RiskLevelTypes,
  ) =>
  async (dispatch: Dispatch<FetchCorporateRiskBasedApproachDispatch>) => {
    try {
      dispatch({ type: 'FETCH_CORPORATE_RISK_PROFILES_START' })
      const { data }: CorporateRiskBasedApproachResponse = await getAxios().get(
        `risk-based-approach/profiles/corporate?offSet=${offSet}&limit=${limit}&type=${type}&level=${riskLevelToSearch}`,
      )

      dispatch({
        type: 'FETCH_CORPORATE_RISK_PROFILES_SUCCESS',
        payload: {
          data,
          type,
          limit,
          offSet,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'FETCH_CORPORATE_RISK_PROFILES_ERROR' })
    }
  }

export const fetchMoreCorporateRiskBasedProfiles =
  (
    offSet: string,
    limit: string,
    type: string,
    riskLevelToSearch: RiskLevelTypes,
  ) =>
  async (dispatch: Dispatch<FetchCorporateRiskBasedApproachDispatch>) => {
    try {
      dispatch({ type: 'FETCH_MORE_CORPORATE_RISK_PROFILES_START' })
      const { data }: CorporateRiskBasedApproachResponse = await getAxios().get(
        `risk-based-approach/profiles/corporate?offSet=${offSet}&limit=${limit}&type=${type}&level=${riskLevelToSearch}`,
      )
      dispatch({
        type: 'FETCH_MORE_CORPORATE_RISK_PROFILES_SUCCESS',
        payload: {
          data,
          type,
          limit,
          offSet,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'FETCH_MORE_CORPORATE_RISK_PROFILES_ERROR' })
    }
  }

export const initiateCorporateRiskProfileDualAuth =
  (dualAuthInfo: DualAuthPayload) =>
  async (dispatch: Dispatch<InitiateDualAuthDispatch>) => {
    const { comment, recommendationStatus, type } = dualAuthInfo
    const dualAuthData = {
      ...dualAuthInfo,
      data: { comment, recommendationStatus },
    }

    try {
      const { data }: DualAuthResponse = await getAxios().post(
        'risk-based-approach/dual-auth/corporate',
        {
          dualAuthData,
        },
      )
      dispatch({
        type: 'CREATE_CORPORATE_RISK_PROFILE_DUAL_AUTH_SUCCESS',
        payload: { data, type },
      })

      toast('Dual Auth Successfully created ', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'DUAL_AUTH_ERROR' })
    }
  }

export const initializeCorporateRiskProfileReview =
  (accountId: number) =>
  async (dispatch: Dispatch<InitializeRiskProfileReviewDispatch>) => {
    try {
      const { data }: InitializeRiskProfileReviewResponse =
        await getAxios().put(
          'risk-based-approach/profile/corporate/status-change',
          {
            accountId,
          },
        )
      dispatch({
        type: 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_SUCCESS',
        payload: { data, accountId },
      })
      toast('Recomandation Status set to pending', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_ERROR' })
    }
  }
