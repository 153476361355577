import React, { Component } from 'react'
import HistoricalDepositsContainer from 'components/organisms/HistoricalDeposits/index'
import { Header } from 'components/atoms/Generic/index'

class HistoricalDeposits extends Component {
  render() {
    return (
      <div>
        <Header title={'HISTORICAL SEARCH'} />
        <HistoricalDepositsContainer/>
      </div>
    )
  }
}

export default HistoricalDeposits
