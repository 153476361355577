import React, { Component, Fragment } from 'react'
import { ContentContainer } from 'components/atoms/Generic/index'
import { BASE } from 'theme/base-units'
import { hasAccountEditorRole } from 'helpers/roleBasedAccess'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import StatusForm from 'forms/ChangeStatus'
import { Title } from 'components/atoms/Title/index'
import auth from 'services/Auth'

import { oppositeColor, capitalizeFirstLetter } from '../../../helpers/utils'

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const {
      activationStatus: { status, activated },
      id,
    } = this.props
    const { formShown } = this.state
    const notAuthorized = !hasAccountEditorRole()
    return (
      <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
        <DetailsBlockWithTitle title="Activation Status" flow={'row'}>
          <ContentContainer
            bgcolor={'0px solid transparent'}
            style={{ marginTop: '10px' }}
          >
            <Title type="h3" text="Account activation Information" />
            {status === 'loading' && <IconLoading />}
            {status === 'done' && (
              <Fragment>
                <InfoBlock
                  label={'Account Active'}
                  value={capitalizeFirstLetter(activated.toString())}
                  color={oppositeColor(activated)}
                />
                <JsonBlock src={{ activated }} title={'Raw Data'} />
                {!formShown && (
                  <MuiButton
                    onClick={this.toggleForm}
                    disabled={notAuthorized}
                    showToolTip={notAuthorized}
                    tooltipTitle={
                      notAuthorized
                        ? 'You are not authorized to perform this action'
                        : ''
                    }
                  >
                    Change Status
                  </MuiButton>
                )}
              </Fragment>
            )}
            {formShown &&
              status === 'done' && (
                <StatusForm
                  initialValues={{
                    id,
                    activated,
                    sendWelcomeEmail: false,
                    actionType: 'CHANGE_ACTIVATION_STATUS',
                    data: { date: new Date(), createdBy: auth.decodeNameToken() },
                    dualAuthStatus: 'N/A',
                  }}
                  onCancel={this.toggleForm}
                />
              )}
          </ContentContainer>
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}
export default Status
