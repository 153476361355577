import React, { useState, useEffect } from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { connect } from 'react-redux'

import { getAccountDetails } from 'redux/selectors/details'

import AccountDetails from './AccountDetails'
import AccountRiskControls from './AccountRiskControls'
import AccountWithdrawalRestrictions from './WithdrawalRestrictions'
import Fraud from './Fraud/Fraud'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { checkRole } from 'helpers/roleBasedAccess'

const ViewAccountDetails = (props) => {
  const routes = [
    '/accounts/byId/:accountId/details',
    '/accounts/byId/:accountId/details/account-risk-control',
    '/accounts/byId/:accountId/details/withdrawals-restrictions',
    '/accounts/byId/:accountId/details/fraud',
  ]

  const tabs = [
    'details',
    'account-risk-control',
    'withdrawals-restrictions',
    'fraud',
  ]

  const [currentPage, setCurrentPage] = useState(tabs[0])
  const {
    location: { pathname },
    details: { accountType, isValrStaffAccount },
  } = props
  const hasSuperUserRoleAndIsStaffAccount = isValrStaffAccount
    ? checkRole('management:internal:account:editor')
    : true

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const checkPath = (path) => {
    tabs.forEach((tab) => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  useEffect(() => {
    checkPath(pathname)
  }, [pathname])

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'details'}
          label="ACCOUNT Details"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/details`}
        />
        {hasSuperUserRoleAndIsStaffAccount && (
          <NavigationTab
            value={'account-risk-control'}
            label="Account Risk Control"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/details/account-risk-control`}
          />
        )}

        {accountType === 'PRIMARY' && hasSuperUserRoleAndIsStaffAccount && (
          <NavigationTab
            value={'withdrawals-restrictions'}
            label="Withdrawal Restrictions"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/details/withdrawals-restrictions`}
          />
        )}
        {hasSuperUserRoleAndIsStaffAccount && (
          <NavigationTab
            value={'fraud'}
            label="Ops tags"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/details/fraud`}
          />
        )}
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={AccountDetails} exact />
          {hasSuperUserRoleAndIsStaffAccount && (
            <>
              <Route path={routes[1]} component={AccountRiskControls} exact />
              {accountType === 'PRIMARY' && (
                <Route
                  path={routes[2]}
                  component={AccountWithdrawalRestrictions}
                  exact
                />
              )}
              <Route path={routes[3]} component={Fraud} exact />
            </>
          )}
        </Switch>
      </ContentContainer>
    </>
  )
}
const mapStateToProps = (state) => ({
  details: getAccountDetails(state),
})

export default connect(mapStateToProps, null)(ViewAccountDetails)
