import getAxios from 'helpers/axios'
import * as moment from 'moment'

import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_NAME_AND_SURNAME_PROCESS =
  'pendingDualAuth/FETCH_NAME_AND_SURNAME_PROCESS'
export const FETCH_NAME_AND_SURNAME_SUCCESS =
  'pendingInternalTransfers/FETCH_NAME_AND_SURNAME_SUCCESS'
export const FETCH_NAME_AND_SURNAME_SUCCESS_ERROR =
  'pendingInternalTransfers/FETCH_NAME_AND_SURNAME_SUCCESS_ERROR'
export const FETCH_NAME_AND_SURNAME_BY_ID_PROCESS =
  'pendingDualAuth/FETCH_NAME_AND_SURNAME_BY_ID_PROCESS'
export const FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS =
  'pendingInternalTransfers/FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS'
export const FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS_ERROR =
  'pendingInternalTransfers/FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS_ERROR'
export const REMOVE_SUBMITTED_ITEM =
  'pendingInternalTransfers/REMOVE_SUBMITTED_ITEM'

export const UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_START =
  'UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_START'
export const UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_SUCCESS =
  'UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_SUCCESS'
export const UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_ERROR =
  'UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_ERROR'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const fetchNameAndSurname = type => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_NAME_AND_SURNAME_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const { data } = res

    const formattedData = data.map(item => {
      item.data = JSON.parse(item.data)
      item.createdAt = convertUnixToDate(item.createdAt)
      item.data.createdAt = convertUnixToDate(item.data.createdAt)

      return item
    })

    dispatch({
      type: FETCH_NAME_AND_SURNAME_SUCCESS,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_NAME_AND_SURNAME_SUCCESS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}
export const updateNameAndSurnameDualAuthRowSocketEvent = newInfo => async dispatch => {
  try {
    dispatch({
      type: UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_START,
    })
    dispatch({
      type: UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_SUCCESS,
      payload: {
        data: newInfo,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchNameAndSurnameById = id => async dispatch => {
  let res = null

  dispatch({
    type: FETCH_NAME_AND_SURNAME_BY_ID_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/single/${id}`)
    const { data } = res

    data.data = JSON.parse(data.data)
    data.createdAt = convertUnixToDate(data.createdAt)

    dispatch({
      type: FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const removeSubmittedItem = id => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_ITEM,
    payload: {
      id,
    },
  })
}
