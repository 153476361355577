import React from 'react'
import { connect } from 'react-redux'

import { Field, reduxForm } from 'redux-form'
import { required, maxLength256, requiredDropdownValue } from 'forms/fieldValidators'
import { FormContainer, FormTitle, Button, InputGroup, FormError } from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput } from '../fields'
import { submitNewKYCLevel } from './submit'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit,kyc } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Override Status</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'KYC Level'}
          name={'level'}
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[requiredDropdownValue]}
          component={renderDropdown}
          options={[{ value: 1, label: 'Semi-verified' }, { value: 2, label: 'Fully-verified' }]}
          fullWidth
          kycLevel={ kyc.info.level}
        />
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required, maxLength256]}
          component={renderInput}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

 Form = reduxForm({
  form: 'KycOverrideForm',
  onSubmit: submitNewKYCLevel,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
