import styled, { keyframes } from 'styled-components'

export const ModalTitle = styled.h4``
export const ModalContent = styled.div``
export const ModalContentText = styled.div``
export const ModalActions = styled.div`
  display: flex;
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

interface IFade {
  in: boolean;
}

export const Fade = styled.div<IFade>`
  animation: ${props => props.in ? `${fadeIn} 0.1s ease-in-out` : `${fadeOut} 0.1s ease-in-out`};
`;