import React, { FC } from 'react'
import { Title } from 'components/atoms/Title'
import { renderPlural } from 'helpers/utils'
import { FoundSanctionLegalEntity } from 'interfaces/sanctions'
import FoundEntitiesTable from './FoundEntitiesTable'

interface FoundLegalEntitySanctionsProps {
  foundLegalEntities: FoundSanctionLegalEntity[]
}
const FoundLegalEntitySanctions: FC<FoundLegalEntitySanctionsProps> = ({
  foundLegalEntities,
}) => (
  <>
    <Title
      type={'h3'}
      text={`Found ${
        foundLegalEntities.length
      } Legal Entity Sanctions Item${renderPlural(foundLegalEntities.length)}`}
    />
    {foundLegalEntities.length > 0 ? (
      <FoundEntitiesTable
        entities={foundLegalEntities}
        tableType="LEGAL"
        headings={[
          { heading: 'Entity ID', key: 'id' },
          { heading: 'Entity Name', key: 'legalEntityNameMatch' },
        ]}
      />
    ) : (
      <p>Legal Entities for this corporate are in the clear</p>
    )}
  </>
)

export default FoundLegalEntitySanctions
