import React, { FC } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import { hasAccessToRoute } from 'helpers/roleBasedAccess'

import { ListItem, ListItemIcon } from './styles'
import NestedExchangeNavigationItems from './Navigation/NestedExchangeNavigationItems'
import { SideTabNavigationMenuItemProps } from './Navigation/interfaces'
import NestedFundingNavigationItems from './Navigation/NestedFundingNavigationItems'
import NestedFinanceNavigationItems from './Navigation/NestedFinanceNavigationItems'
import NestedLeverageNavigationItems from './Navigation/NestedLeverageNavigationItems'
import NestedComplianceNavigationItems from './Navigation/NestedComplianceNavigationItems'
import NestedApplicationConfigItems from './Navigation/NestedApplicationConfigItems'

const SideTabNavigationMenuItem: FC<SideTabNavigationMenuItemProps> = ({
  pathname,
  handleListItemClick,
  navItemData,
}) => {
  const { routeAccess, route, selectedPathName, listItemText, icon } =
    navItemData
  const selectedPath =
    pathname.includes('ferryman') && selectedPathName.includes('ferryman')
      ? true
      : pathname === selectedPathName
  return (
    <>
      {hasAccessToRoute(routeAccess) ? (
        <>
          <ListItem
            button
            selected={selectedPath}
            onClick={(event) => handleListItemClick(event, route)}
            component="a"
            href={route}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={listItemText} />
          </ListItem>
          <NestedExchangeNavigationItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
          <NestedFundingNavigationItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
          <NestedFinanceNavigationItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
          <NestedLeverageNavigationItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
          <NestedComplianceNavigationItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
          <NestedApplicationConfigItems
            handleListItemClick={handleListItemClick}
            pathname={pathname}
            selectedPathName={selectedPathName}
          />
        </>
      ) : null}
    </>
  )
}

export default SideTabNavigationMenuItem
