export const IDENTITY_NUMBER_CHANGE_PROCESS = 'IDENTITY_NUMBER_CHANGE_PROCESS'
export const IDENTITY_NUMBER_CHANGE_SUCCESS = 'IDENTITY_NUMBER_CHANGE_SUCCESS'
export const IDENTITY_NUMBER_CHANGE_SUCCESS_ERROR =
  'IDENTITY_NUMBER_CHANGE_SUCCESS_ERROR'
export const REMOVE_SUBMITTED_ITEM = 'REMOVE_SUBMITTED_ITEM'

export const UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_START =
  'UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_START'
export const UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_SUCCESS =
  'UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_SUCCESS'
export const UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_ERROR =
  'UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_ERROR'

export const IDENTITY_NUMBER_CHANGE_BY_ID_PROCESS =
  'IDENTITY_NUMBER_CHANGE_BY_ID_PROCESS'
export const IDENTITY_NUMBER_CHANGE_BY_ID_SUCCESS =
  'IDENTITY_NUMBER_CHANGE_BY_ID_SUCCESS'
export const IDENTITY_NUMBER_CHANGE_BY_ID_ERROR =
  'IDENTITY_NUMBER_CHANGE_BY_ID_ERROR'

const initialState = {
  identityNumberStatus: 'pristine',
  identityNumberData: [],
  identityNumberDataById: [],
  identityNumberDataByIdLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case IDENTITY_NUMBER_CHANGE_PROCESS: {
      return {
        ...state,
        identityNumberStatus: 'loading',
      }
    }

    case IDENTITY_NUMBER_CHANGE_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        identityNumberStatus: 'done',
        identityNumberData: data,
      }
    }

    case IDENTITY_NUMBER_CHANGE_SUCCESS_ERROR: {
      return {
        ...state,
        identityNumberData: [],
      }
    }

    case REMOVE_SUBMITTED_ITEM: {
      const filteredArray = state.identityNumberData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        identityNumberData: filteredArray,
      }
    }

    case IDENTITY_NUMBER_CHANGE_BY_ID_PROCESS: {
      return {
        ...state,
        identityNumberDataById: {},
        identityNumberDataByIdLoading: true,
      }
    }

    case IDENTITY_NUMBER_CHANGE_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        identityNumberDataByIdLoading: false,
        identityNumberDataById: data,
      }
    }

    case UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_START: {
      return {
        ...state,
        identityNumberStatus: 'loading',
      }
    }

    case UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_SUCCESS: {
      const { data } = action.payload
      const updatedIdNumberData =
        state.identityNumberData &&
        state.identityNumberData.map((idNumberChangeDualAuth) => {
          if (data && data.length > 0) {
            data.some((newUserOn) => {
              if (
                newUserOn.id === idNumberChangeDualAuth.id &&
                newUserOn.userActive
              ) {
                idNumberChangeDualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === idNumberChangeDualAuth.id &&
                      userOnLine.userActive,
                  ).userName,
                }
              } else {
                idNumberChangeDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            idNumberChangeDualAuth.active = {
              userActive: false,
              activePersonName: '',
            }
          }
          return idNumberChangeDualAuth
        })
      return {
        ...state,
        identityNumberData: updatedIdNumberData,
        identityNumberStatus: 'done',
      }
    }

    case UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_ERROR: {
      return {
        ...state,
        identityNumberStatus: 'pristine',
      }
    }

    case IDENTITY_NUMBER_CHANGE_BY_ID_ERROR: {
      return {
        ...state,
        identityNumberDataById: {},
        identityNumberDataByIdLoading: false,
      }
    }

    default:
      return state
  }
}
