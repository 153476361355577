import React, { Component } from 'react'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { hasAccountEditorRole } from 'helpers/roleBasedAccess'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import ErrorToast from 'components/molecules/ErrorToast'

class InitiateCellphoneChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      allowReuse: false,
    }
  }

  allowReuse = async () => {
    try {
      const { id, fetchAccountDetails } = this.props

      const request = {
        path: `/admin/account/${id}/allowcellreuse`,
        body: {},
      }
      const res = await getAxios().post('/generic', request)

      if (res.data.success) {
        toast('Cellphone marked for reuse', {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        })
        fetchAccountDetails(id)
      } else {
        toast('Error occurred. Please contact support', {
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        })
      }
    } catch (error) {
      ErrorToast(error)
    }
  }

  render() {
    const { cellphoneMarkedForReUse } = this.props
    const notAuthorized = !hasAccountEditorRole()
    return (
      <MuiButton
        disabled={notAuthorized || cellphoneMarkedForReUse}
        onClick={this.allowReuse}
        showToolTip={notAuthorized}
        tooltipTitle={
          notAuthorized ? 'You are not authorized to perform this action' : ''
        }
      >
        {cellphoneMarkedForReUse ? 'Marked for Re-use' : 'Allow Re-use'}
      </MuiButton>
    )
  }
}

export default InitiateCellphoneChange
