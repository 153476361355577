import React from 'react'
import TableCell from '@material-ui/core/TableCell'

export const Headers = () => {
  return (
    <>
      <TableCell>Account Id</TableCell>
      <TableCell>First Name</TableCell>
      <TableCell>Last Name</TableCell>
      <TableCell>Currency</TableCell>
      <TableCell>Amount</TableCell>
      <TableCell>Fee Amount</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Created At</TableCell>
    </>
  )
}
