import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { fetchCurrencies } from 'redux/currencies/actions'

export const submit = async (values, dispatch, props) => {
  const {
    symbol,
    decimalPlaces,
    isActive,
    shortName,
    longName,
    currencyDecimalPlaces,
    withdrawalDecimalPlaces,
    paymentReferenceFieldName,
  } = values
  const { isEditing } = props

  try {
    const request = {
      path: isEditing ? '/admin/currencies/paymentreferencefield' : '/admin/currencies',
      body: isEditing ?
        {
          symbol: symbol.toUpperCase(),
          paymentReferenceFieldName,
        } : {
          symbol: symbol.toUpperCase(),
          decimalPlaces,
          isActive,
          shortName: shortName.toUpperCase(),
          longName: longName.charAt(0).toUpperCase() + longName.slice(1),
          currencyDecimalPlaces,
          supportedWithdrawDecimalPlaces: withdrawalDecimalPlaces,
          paymentReferenceFieldName,
        },

    }
    const res = isEditing ? await getAxios().put('/generic', request) : await getAxios().post('/generic', request)
    if (res.data.success) {
      toast('Currency Successful added', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
    } else {
      toast(res.data.error.message, { type: toast.TYPE.ERROR, autoClose: 3000 })
    }
    dispatch(fetchCurrencies())
  } catch (error) {
    toast(`Currency not added, ${error.response && error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
