import React, { FC } from 'react'
import { DetailsBlock, InfoBlock } from 'components/atoms/Details'
import { MatchedTo } from 'interfaces/sanctions'
import {
  StyledEntityMatchedHeading,
  StyledEntityMatchedToWrapper,
} from '../styles'

interface MatchedToDataProps {
  matchedTo: MatchedTo
  infoLabelColor?: string
}

const MatchedToData: FC<MatchedToDataProps> = ({
  matchedTo,
  infoLabelColor,
}) => (
  <StyledEntityMatchedToWrapper>
    <StyledEntityMatchedHeading>Matched To Data</StyledEntityMatchedHeading>
    <DetailsBlock>
      <InfoBlock
        label={'Sanctions List'}
        value={matchedTo.sanctionsList || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'First Name'}
        value={matchedTo.firstName || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Surname'}
        value={matchedTo.surname || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Entity Logical Id'}
        value={matchedTo.entityLogicalId || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Alias 1'}
        value={matchedTo.alias1 || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Alias 2'}
        value={matchedTo.alias2 || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Adverse Finds'}
        value={matchedTo.adverseFinds || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'DOB 1'}
        value={matchedTo.dob1 || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'DOB 2'}
        value={matchedTo.dob2 || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Place Of Birth Country Of Operations 1'}
        value={matchedTo.placeOfBirthCountryOfOperations1 || '--'}
        infoLabelColor={infoLabelColor}
      />
      <InfoBlock
        label={'Place Of Birth Country Of Operations 2'}
        value={matchedTo.placeOfBirthCountryOfOperations2 || '--'}
        infoLabelColor={infoLabelColor}
      />
    </DetailsBlock>
  </StyledEntityMatchedToWrapper>
)

export default MatchedToData
