import { PEPScreeningAction } from './actions'
import {
  CheckPEPScreeningResult,
  PEPScreeningInitialState,
  PepScreeningResult,
} from './interfaces'
import { ReduxAction } from 'interfaces/global'

const initialState: PEPScreeningInitialState = {
  loading: false,
  data: [],
}

export default (
  state = initialState,
  action: ReduxAction<
    PEPScreeningAction,
    PepScreeningResult[] | CheckPEPScreeningResult
  >,
): PEPScreeningInitialState => {
  switch (action.type) {
    case 'FETCH_PEP_SCREENING_RESULTS_START':
      return { ...state, loading: true }
    case 'FETCH_PEP_SCREENING_RESULTS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: (action.payload as PepScreeningResult[]) || [],
      }
    case 'FETCH_PEP_SCREENING_RESULTS_ERROR':
      return { ...state, loading: false }
    case 'CHECK_PEP_SCREENING_RESULTS_SUCCESS':
      const updatedPEPScreeningData = state.data.map((pepScreeningResult) => {
        const payload = action.payload as CheckPEPScreeningResult
        if (parseInt(payload.pep_screening_id) === pepScreeningResult.id) {
          pepScreeningResult.checked = payload.checked
          pepScreeningResult.checkStatus = payload.check_status
        }
        return pepScreeningResult
      })

      return { ...state, data: updatedPEPScreeningData }

    default:
      return state
  }
}
