import {
  FETCH_ACCOUNT_CONFIRMATIONS_PROCESS,
  FETCH_ACCOUNT_CONFIRMATIONS_SUCCESS,
  FETCH_ACCOUNT_CONFIRMATIONS_ERROR,
} from './actions'

const initialState = {
  status: 'pristine',
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_CONFIRMATIONS_PROCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }

    case FETCH_ACCOUNT_CONFIRMATIONS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case FETCH_ACCOUNT_CONFIRMATIONS_ERROR: {
      return {
        ...state,
        status: 'error',
        data: [],
      }
    }

    default:
      return state
  }
}
