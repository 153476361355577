import React, { Fragment, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import { groupBy } from 'lodash'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import './Styles.css'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'

import { FundingInfoAndEventsRowData } from './interfaces'
import FundingAccordion from './FundingAccordion'

import SimpleModal from '../Modal'
import { BASE } from '../../../theme/base-units'
import { Flexbox } from '../../layout'
import { InfoLabel } from '../../atoms/Details'
import ReviewView from '../ReviewView'
import {
  clearFundingReview,
  updateFundingEvents,
} from '../../../redux/fundingEvents/actions'
import { ReviewPreview } from '../../../redux/fundingEvents'
import { submitFundingUpdate } from '../../../forms/FundingForm/submit'
import ExpandingItem from 'components/atoms/ExpandingItem'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Headers } from './headers'
import SuspendedFundingRow from './DepositFundingFilter/SuspendedFundingRow'

const getReviewStatusColor = (advice: string) => {
  switch (advice) {
    case 'PASS':
      return 'green'
    case 'REVIEW':
      return 'orange'
    default:
      return 'red'
  }
}

const getTitle = (statusStr: string) => {
  switch (statusStr) {
    case 'FLAGGED_FOR_REVIEW':
      return 'DEPOSITS UNDER REVIEW'
    case 'PENDING_MANUAL_ALLOCATION':
      return 'UNKNOWN REFERENCE'
    case 'MANUAL_ALLOCATION_DUAL_AUTH_REJECTED':
      return 'DUAL AUTH REJECTED'
    default:
      return statusStr.replace(/_/g, ' ')
  }
}

const addTotalAmount = (deposit) => {
  const total = deposit
    .map((d) => d.amount)
    .reduce((accumulator, value) => {
      const checkedCurrentValue = value
        ? parseFloat(
            typeof value === 'string' ? value.split(',').join('') : value,
          )
        : 0
      return accumulator + checkedCurrentValue
    }, 0)
  return new BigNumber(total).toFormat(2)
}

const ReviewStatus = ({ review }) => (
  <b style={{ color: getReviewStatusColor(review.advice) }}>{review.advice}</b>
)

interface FundingInfo {
  status: string
  data: FundingInfoAndEventsRowData[]
  suspendedDeposits: []
}
interface DepositFundingInfoProps {
  fundingInfo: FundingInfo
  currency: string
  review: ReviewPreview
  updateFundingTable: (fundingInfo: any) => void
  clearReview: () => void
}

interface DepositFundingInfoState {
  expanded: string | null
  formShown: {
    id: string | null
    showForm: boolean
  }
  modal: {
    submitting: boolean
  }
  addNoteFormShown: string
  highlightedId: string
  suspendedDepositExpanded: boolean
}

const defaultState: DepositFundingInfoState = {
  expanded: null,
  formShown: {
    showForm: false,
    id: null,
  },
  modal: {
    submitting: false,
  },
  addNoteFormShown: '',
  highlightedId: '',
  suspendedDepositExpanded: false,
}

const DepositFundingInfo = ({
  currency,
  fundingInfo: { status, data, suspendedDeposits },
  review,
  updateFundingTable,
  clearReview,
}: DepositFundingInfoProps) => {
  const [state, setState] = useState<DepositFundingInfoState>(defaultState)
  const loading = useMemo(() => status === 'loading', [status])

  const toggleView = (statusToExpand: string) => {
    if (statusToExpand === state.expanded) {
      setState((prev) => ({ ...prev, expanded: null }))
    } else {
      setState((prev) => ({ ...prev, expanded: statusToExpand }))
    }
  }

  const toggleForm = (id) => {
    if (id !== state.formShown.id) {
      setState((prev) => ({
        ...prev,
        highlightedId: id,
        formShown: { id, showForm: true },
        addNoteFormShown: '',
      }))
    } else {
      setState((prev) => ({
        ...prev,
        highlightedId: id,
        formShown: { id: null, showForm: false },
        addNoteFormShown: '',
      }))
    }
  }

  const handleHighlightedRowChange = (id: string) => {
    setState((prev) => ({ ...prev, highlightedId: id }))
  }

  const handleHideModal = () => {
    clearReview()
  }

  const handleSubmitSuccess = (identifier) => {
    updateFundingTable(review.fundingEvent)
  }

  const handleProceed = async () => {
    setState((prev) => ({ ...prev, modal: { submitting: true } }))
    await submitFundingUpdate(review.fundingEvent, handleSubmitSuccess)
    setState((prev) => ({ ...prev, modal: { submitting: false } }))
  }

  const toggleSuspendedDepositView = () => {
    setState((prev) => ({
      ...prev,
      suspendedDepositExpanded: !state.suspendedDepositExpanded,
    }))
  }

  const hasDeposits = useMemo(() => data && data.length > 0, [data])

  const depositsPerState = useMemo(
    () => (data ? groupBy(data, 'status', 'suspended') : null),
    [data],
  )
  if (loading) return <LoadingBlock />
  return (
    <Fragment>
      {!hasDeposits && (
        <NoTableResultsMessage messageType={NO_RESULT_MESSAGE.DEPOSITS.type} />
      )}
      {Object.keys(depositsPerState)
        .sort()
        .map((s) => {
          const deposits = depositsPerState[s]
          return (
            <FundingAccordion
              currency={currency}
              state={state}
              key={s}
              s={s}
              deposits={deposits}
              toggleForm={toggleForm}
              toggleView={toggleView}
              status={status}
              updateFundingTable={updateFundingTable}
              getTitle={getTitle}
              addTotalAmount={addTotalAmount}
              handleHighlightedRowChange={handleHighlightedRowChange}
            />
          )
        })}
      <SimpleModal
        open={review != null}
        handleClose={handleHideModal}
        width={'70%'}
        buttonsHidden
      >
        {review && (
          <>
            <h3>Screening Preview</h3>
            <InfoLabel style={{ marginBottom: BASE * 2 }}>
              Review advice: <ReviewStatus review={review} />
            </InfoLabel>
            <ReviewView flags={review.flags} />
            <Flexbox direction={'row'} justify={'flex-end'} gap={'10px'}>
              <MuiButton onClick={handleHideModal} color="secondary">
                Cancel
              </MuiButton>
              <MuiButton
                onClick={handleProceed}
                disabled={state.modal.submitting}
              >
                Proceed
              </MuiButton>
            </Flexbox>
          </>
        )}
      </SimpleModal>
      {suspendedDeposits.length <= 0 && (
        <NoTableResultsMessage
          messageType={NO_RESULT_MESSAGE.SUSPENDED_DEPOSITS.type}
        />
      )}
      {suspendedDeposits.length > 0 && (
        <ExpandingItem
          title={'SUSPENDED'}
          itemsAmount={(suspendedDeposits && suspendedDeposits.length) || 0}
          totalAmount={`${addTotalAmount(suspendedDeposits) || 0} ${currency}`}
          expanded={state.suspendedDepositExpanded}
          onExpand={toggleSuspendedDepositView}
          opensMultiple
          expandedView={
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <Headers />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {suspendedDeposits &&
                      suspendedDeposits.map((deposit: any, i) => {
                        return (
                          <SuspendedFundingRow
                            key={i}
                            data={deposit}
                            toggleForm={toggleForm}
                            formShown={state.formShown}
                            isHighlighted={
                              state.highlightedId === deposit.identifier
                            }
                          />
                        )
                      })}
                  </>
                </TableBody>
              </Table>
            </>
          }
        />
      )}
    </Fragment>
  )
}

const actions = {
  updateFundingTable: updateFundingEvents,
  clearReview: clearFundingReview,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    fundingInfo: state.fundingEvents.fundingInfo,
    review: state.fundingEvents.fundingReview,
  })),
  actions,
  DepositFundingInfo,
)
