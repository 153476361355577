import React from 'react'
import { Field, reduxForm, untouch, change } from 'redux-form'
import SearchIcon from '@material-ui/icons/Search'
import { BLUE } from 'theme/colors'

import { renderSearch } from 'forms/fields'
import {
  isNumber,
  noSpacesAllowed,
  moreThanZero,
  required,
} from 'forms/fieldValidators'

import { Search, SearchButton } from './styles'

class SearchById extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: '',
      redirect: false,
    }
  }

  eventListener = event => {
    if (event.key === 'Enter' && !this.props.invalid && this.state.accountId) {
      this.redirect()
    }
  }

  resetField = () => {
    const fields = ['search']
    for (let i = 0; i < fields.length; i++) {
      this.props.dispatch(change('searchById', fields[i], null))
      this.props.dispatch(untouch('searchById', fields[i]))
    }
  }

  redirect = async () => {
    const newPathName = `/accounts/byId/${this.state.accountId}/details`
    if (this.props.history.location.pathname === newPathName) {
      return
    }
    const { history } = this.props
    await this.props.clearAccountDetails()
    history.push(newPathName, true)
    this.setState({ accountId: '' })
    this.resetField()
  }

  handleChange = event => {
    this.setState({ accountId: event.target.value })
  }

  render() {
    const { invalid } = this.props
    const { accountId } = this.state

    return (
      <Search>
        <Field
          placeholder="Search account by #"
          name={'search'}
          onChange={this.handleChange}
          onKeyPress={this.eventListener}
          component={renderSearch}
          validate={[noSpacesAllowed, isNumber, moreThanZero]}
          type="filled"
          size="small"
          value={accountId}
        />
        <SearchButton
          type="submit"
          onClick={this.redirect}
          disabled={invalid || !accountId}
        >
          <SearchIcon style={{ color: BLUE }} />
        </SearchButton>
      </Search>
    )
  }
}

const ReduxForm = reduxForm({
  form: 'searchById',
})(SearchById)

export default ReduxForm
