import getAxios from 'helpers/axios'
import { AvailableCurrencyPair, FuturePosition } from 'interfaces/futures'
import { Dispatch } from 'react'

import ErrorToast from 'components/molecules/ErrorToast'

export type FuturesActions =
  | 'FETCH_FUTURES_START'
  | 'FETCH_FUTURES_SUCCESS'
  | 'FETCH_FUTURES_ERROR'
  | 'FETCH_FUTURES_CURRENCY_PAIRS_START'
  | 'FETCH_FUTURES_CURRENCY_PAIRS_SUCCESS'
  | 'FETCH_FUTURES_CURRENCY_PAIRS_ERROR'
  | 'RESET_CURRENCY_PAIRS_SUCCESS'

interface FuturesDispatch {
  type: FuturesActions
  payload?: FuturePosition | FuturePosition[] | AvailableCurrencyPair[]
}

export const getAvailableCurrencyPairs = () => async (dispatch: Dispatch<FuturesDispatch>,) => {
  try {
    dispatch({
      type: 'FETCH_FUTURES_CURRENCY_PAIRS_START',
    })
    const { data }: {data: AvailableCurrencyPair[]} = await getAxios().get('/admin/currency/pairs')

    dispatch({
      type: 'FETCH_FUTURES_CURRENCY_PAIRS_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_FUTURES_CURRENCY_PAIRS_ERROR',
    })
  }
}

export const resetAvailableCurrencyPairs =() => async (dispatch: Dispatch<FuturesDispatch>,) => {
  dispatch({
    type: 'RESET_CURRENCY_PAIRS_SUCCESS',
  })
}

export const fetchFuturesPositions = (currencyPair: string) => async (
  dispatch: Dispatch<FuturesDispatch>,
) => {
  try {
    dispatch({
      type: 'FETCH_FUTURES_START',
    })
    const { data }: { data: FuturePosition[] } = await getAxios().get(
      `/futures/positions?currencyPair=${currencyPair}`,
    )
    dispatch({
      type: 'FETCH_FUTURES_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_FUTURES_ERROR',
    })
  }
}
