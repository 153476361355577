import { Button, Collapse, TableCell, TableRow } from '@material-ui/core'
import { convertUnixToDate } from 'helpers/fileHelpers'
import { OpenOrder } from 'interfaces/openOrders'
import React, { FC, Fragment, SetStateAction } from 'react'
import { StyledExpandTableCell, StyledExpandedSectionContainer } from './Styles'
import { JsonBlock } from 'components/atoms/Details'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasExchangeEditorRole } from 'helpers/roleBasedAccess'

interface IOpenOrderRow {
  setExpandedItem: React.Dispatch<React.SetStateAction<string>>
  setConfirmationModalData: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean
      data: null | OpenOrder
    }>
  >
  openOrder: OpenOrder
  expandedItem: string
}

const OpenOrderRow: FC<IOpenOrderRow> = ({
  expandedItem,
  setExpandedItem,
  setConfirmationModalData,
  openOrder,
}) => {
  const notAuthorized = !hasExchangeEditorRole()
  return (
    <Fragment key={openOrder.id}>
      <TableRow
        onClick={() =>
          setExpandedItem((prev) => (prev === openOrder.id ? '' : openOrder.id))
        }
      >
        <TableCell>{openOrder._total}</TableCell>
        <TableCell>{String(openOrder.allowMargin)}</TableCell>
        <TableCell>{openOrder.pair.shortName}</TableCell>
        <TableCell>{openOrder.price}</TableCell>
        <TableCell>{openOrder.quantity}</TableCell>
        <TableCell>{openOrder.side}</TableCell>
        <TableCell>{openOrder.status}</TableCell>
        <TableCell>{openOrder.originalQuantity}</TableCell>
        <TableCell>{convertUnixToDate(openOrder.createdAt)}</TableCell>
        <TableCell>{convertUnixToDate(openOrder.updatedAt)}</TableCell>
      </TableRow>
      <TableRow className="no-hover">
        <StyledExpandTableCell colSpan={10}>
          <Collapse
            in={expandedItem === openOrder.id}
            timeout="auto"
            unmountOnExit
          >
            <StyledExpandedSectionContainer>
              <JsonBlock src={openOrder} title={'Raw Data'} />
              <MuiButton
                onClick={() =>
                  setConfirmationModalData({ isOpen: true, data: openOrder })
                }
                disabled={notAuthorized}
                showToolTip={notAuthorized}
                tooltipTitle={
                  notAuthorized
                    ? 'You are not authorized to perform this action'
                    : ''
                }
              >
                Cancel Order
              </MuiButton>
            </StyledExpandedSectionContainer>
          </Collapse>
        </StyledExpandTableCell>
      </TableRow>
    </Fragment>
  )
}

export default OpenOrderRow
