import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import * as moment from 'moment'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.securityChange.id)
  }

  render() {
    const {
      securityChange,
      securityChange: {
        id,
        data: { accountId, expiryTime },
        createdAt,
        active,
      },
      isSelected,
    } = this.props
    const shading = isSelected ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'12%'}>{createdAt}</TableCell>
          <TableCell width={'20%'}>
            {moment(expiryTime).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              accountId,
              dualAuthStatus: 'rejected',
              actionType: 'ACCOUNT_SECURITY',
              flag: false,
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.toggleForm}
            rawData={securityChange}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'ACCOUNT_SECURITY_CHANGE_EVENT')
