import React, { Component, Fragment } from 'react'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TitleContainer,
  Actions,
} from 'components/atoms/Table/styles'
import { BlankNavLink } from 'components/atoms/Generic'
class PendingView extends Component {
  componentDidMount() {
    const { status } = this.props.pending
    if (status === 'pristine') {
      this.props.fetchList()
    }
  }

  renderTable(list) {
    return (
      <Table>
        <TableHeader>
          <TableCell width={'10%'}>Id</TableCell>
          <TableCell width={'20%'}>Status</TableCell>
          <TableCell width={'20%'}>Email</TableCell>
          <TableCell width={'20%'}>Cell Number</TableCell>
          <TableCell width={'10%'} align={'flex-end'}>
            Check Count
          </TableCell>
          <TableCell flex={1} align={'flex-end'}>
            Timestamp
          </TableCell>
        </TableHeader>
        <TableBody>
          {list.map(account => {
            const {
              id,
              status,
              checkCount,
              timestamp,
              profile: { email, cellNumber },
            } = account
            return (
              <BlankNavLink to={`/accounts/byId/${id}/details`} key={id}>
                <TableRow key={id}>
                  <TableCell width={'10%'}>{id}</TableCell>
                  <TableCell width={'20%'}>{status}</TableCell>
                  <TableCell width={'20%'}>{email}</TableCell>
                  <TableCell width={'20%'}>{cellNumber}</TableCell>
                  <TableCell width={'10%'} align={'flex-end'}>
                    {checkCount}
                  </TableCell>
                  <TableCell flex={1} align={'flex-end'}>
                    {timestamp}
                  </TableCell>
                </TableRow>
              </BlankNavLink>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  render() {
    const { list, status } = this.props.pending
    return (
      <Fragment>
        <TitleContainer>
          <Actions />
        </TitleContainer>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && <div>{this.renderTable(list)}</div>}
      </Fragment>
    )
  }
}

export default PendingView
