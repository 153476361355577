import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiButton from 'components/atoms/Buttons/MuiButton'

export const ConfirmationDialog = ({
  children = <></>,
  open,
  handleClose,
  title,
  message,
  button,
  declineButtonColor = 'secondary',
  confirmButtonColor = 'primary',
  proceed,
  buttonsHidden = false,
  disabled = false,
}) => {
  const isLightTheme = localStorage.getItem('adminTheme') === 'lightTheme'
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        style={{ color: isLightTheme ? 'black' : 'white' }}
        id="alert-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {children}
      </DialogContent>
      {!buttonsHidden && (
        <DialogActions>
          <MuiButton onClick={handleClose} color={declineButtonColor}>
            {button.decline.text || 'No'}
          </MuiButton>
          <MuiButton
            onClick={(e) => {
              e.persist()
              proceed()
            }}
            color={confirmButtonColor}
            autoFocus
            disabled={disabled}
          >
            {button.confirm.text || 'Yes'}
          </MuiButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  button: PropTypes.object,
  buttonsHidden: PropTypes.bool,
  //   button: PropTypes.object({
  //     confirm: PropTypes.object({
  //       text: PropTypes.string,
  //       color: PropTypes.string,
  //       proceedCallBack: PropTypes.func,
  //     }),
  //     decline: PropTypes.object({
  //       text: PropTypes.string,
  //       color: PropTypes.string,
  //     }),
  //   }),
}

ConfirmationDialog.defaultProps = {
  title: 'Enter title text here',
  message: 'Enter message text here',
  handleClose: () => console.log('Pass a function to close the modal'),
  button: {
    confirm: {
      text: 'Yes',
    },
    decline: {
      text: 'No',
    },
  },
  proceed: () => console.log('Please pass a function for accepting'),
  confirmButtonColor: 'primary',
  declineButtonColor: 'secondary',
  buttonsHidden: false,
}
