import React, { useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import { Headers } from './headers'
import Row from './row'

export const AccountSecurityChangeTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(securityChange => {
          securityChange.createdAt = moment(securityChange.createdAt).format(
            'YYYY-MMM-DD HH:mm:ss',
          )
          return (
            <Row
              dualAuthId={securityChange.id}
              key={securityChange.id}
              securityChange={securityChange}
              isSelected={selectedRow === securityChange.id}
              toggleSelected={toggleSelectedRow}
            />
          )
        })}

      </TableBody>
    </Table>
  )

}