import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'

export const FETCH_MANUAL_DOCUMENTS_PROCCESS = 'FETCH_MANUAL_DOCUMENTS_PROCCESS'
export const FETCH_MANUAL_DOCUMENTS_SUCCESS = 'FETCH_MANUAL_DOCUMENTS_SUCCESS'
export const FETCH_MANUAL_DOCUMENTS_ERROR = 'FETCH_MANUAL_DOCUMENTS_ERROR'
export const REJECT_KYC_ATTEMPT ='REJECT_KYC_ATTEMPT'
export const REJECT_KYC_ATTEMPT_ERROR='REJECT_KYC_ATTEMPT_ERROR'

export const APPROVE_KYC_ATTEMPT ='APPROVE_KYC_ATTEMPT'
export const APPROVE_KYC_ATTEMPT_ERROR='APPROVE_KYC_ATTEMPT_ERROR'
export const UPDATE_MANUAL_KYC ='UPDATE_MANUAL_KYC'
export const UPDATE_MANUAL_KYC_ERROR ='UPDATE_MANUAL_KYC_ERROR'

export const fetchManualDocuments = () => async dispatch => {
  dispatch({ type: FETCH_MANUAL_DOCUMENTS_PROCCESS })
  let res = null
  try {
    res = await getAxios().get(`/accounts/pending/kyc/manual-uploads`)
    const { data } = res
    dispatch({
      type: FETCH_MANUAL_DOCUMENTS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_MANUAL_DOCUMENTS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const approveKycAttempt = (approvedKycAttempt) => async dispatch => {
  try {
    dispatch({
      type: APPROVE_KYC_ATTEMPT,
      payload: {
        approvedKycAttempt,
      },
    })
  } catch(error) {
    ErrorToast(error)
    dispatch({
      type: APPROVE_KYC_ATTEMPT_ERROR,
      payload: {
        error: error.response,
      },
    })

  }
}

export const rejectKycAttempt = (rejectedKycAttempt) => async dispatch => {
  try {
    dispatch({
      type: REJECT_KYC_ATTEMPT,
      payload: {
        rejectedKycAttempt,
      },
    })
  } catch(error) {
    ErrorToast(error)
    dispatch({
      type: REJECT_KYC_ATTEMPT_ERROR,
      payload: {
        error: error.response,
      },
    })

  }
}

export const updateManualKyc = data => async dispatch => {
  try {
    dispatch({
      type: UPDATE_MANUAL_KYC,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_MANUAL_KYC_ERROR,
      payload: {
        error,
      },
    })
  }
}
