import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_ACCOUNT_RISK_SETTINGS_PROCESS =
  'FETCH_ACCOUNT_RISK_SETTINGS_PROCESS'
export const FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS =
  'FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS'
export const FETCH_ACCOUNT_RISK_SETTINGS_ERROR =
  'FETCH_ACCOUNT_RISK_SETTINGS_ERROR'

export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'UPDATE_ACCOUNT_STATUS_SUCCESS'

export const fetchAccountRiskStatus = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_RISK_SETTINGS_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/risk-settings`)
    dispatch({
      type: FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS,
      payload: {
        id,
        ...res.data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_RISK_SETTINGS_ERROR,
      payload: {
        id,
        error,
      },
    })
  }
}

export const UpdateAccountRiskStatus =
  (id, updatedStatus) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_ACCOUNT_STATUS_SUCCESS,
        payload: {
          updatedStatus,
          id,
        },
      })
    } catch (error) {
      ErrorToast(error)
    }
  }
