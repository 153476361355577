import React, { Component, Fragment } from 'react'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import getAxios from 'helpers/axios'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import FormSuspendDepositReview from 'forms/SuspendDeposits'
import { fetchDepositReview } from 'redux/fiat/actions'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import BigNumber from 'bignumber.js'
import FormDepositsComments from 'forms/DepositsComments/index'
import auth from 'services/Auth'
import { Link } from '../AccountIdLink/clickableId'
import socket from 'redux/socket/index'
import { TransitionCell } from '../Styles'
import { RenderCheckbox } from 'forms/fields'
import EyeIcon from 'components/atoms/EyeIcon/index'
import { VALR_BLUE_GRAY } from 'theme/colors'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
    }
  }

  updateComments = async depositId => {
    this.setState({ commentsStatus: 'loading' })
    const data = await fetchDepositComments(depositId)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  refineRawData = rawData => {
    const { source } = rawData
    rawData.source = source.replace(/\W\d*/g, ' ').trim()
    return rawData
  }

  setNotification = (notes, depositId) => {
    return (
      notes.depositComments.length > 0 &&
      notes.depositComments.find(val => val.depositId === depositId) !== -1
    )
  }

  removeActiveUser = async () => {
    const depositId = this.props.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const removeUser = { depositId, activeUserQue }
    socket.emit('remove user', removeUser)
  }

  showActiveuser = async () => {
    const depositId = this.props.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const activeUser = { depositId, activeUserQue }
    socket.emit('add user', activeUser)
  }

  expandRow = depositId => {
    this.props.toggleForm(depositId)
    this.updateComments(depositId)
  }

  renderAddNoteForm = () => {
    const {
      resetForm,
      deposit: { depositId },
      toggleAddNotesForm,
    } = this.props
    return (
      <FormDepositsComments
        initialValues={{
          depositId,
          data: {
            date: new Date(),
            createdBy: auth.decodeNameToken(),
            depositsId: depositId,
          },
        }}
        form={'depositCommentsForm'}
        depositData={this.props.deposit}
        onCancel={resetForm}
        toggleForm={() => {
          toggleAddNotesForm(depositId)
          this.updateComments(depositId)
        }}
      />
    )
  }

  render() {
    const {
      deposit,
      isPending,
      isHighlighted,
      setHighlightedId,
      title,
      bulkActionsActive,
      handleSelectedBulkDepositClick,
      selectedBulkDeposits,
      isCellHidden,
    } = this.props
    const {
      depositId,
      accountId,
      amount,
      description,
      dateTime,
      tag,
      accountBasicInfoForDisplay,
      insertedAt,
      active,
      currency,
    } = deposit
    const { formShown } = this.props
    const name = accountBasicInfoForDisplay && accountBasicInfoForDisplay.name
    const surname =
      accountBasicInfoForDisplay && accountBasicInfoForDisplay.surname
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const padding = '16px 16px 16px 0px'

    var shading =
      isPending && isHighlighted
        ? '#d2d3d6'
        : isPending
          ? '#8c8c8c'
          : !isPending && isHighlighted
            ? 'white'
            : ''
    const depositRowSelected = !!selectedBulkDeposits.find(
      deposit => deposit.depositId === depositId,
    )
    return (
      <Fragment>
        <TableRow
          style={{
            color: shading,
            padding: '0px 16px 0px 0px',
            backgroundColor:
              (isHighlighted || depositRowSelected) && VALR_BLUE_GRAY,
            fontWeight: '600',
            overflow: 'hidden',
          }}
          onClick={() => setHighlightedId(depositId)}
        >
          <TableCell bold flex={0.6} style={{ padding: '16px 16px' }}>
            {accountId ? <Link color={shading} id={accountId} /> : '--'}
          </TableCell>
          <TableCell
            flex={0.6}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {name || '--'}
          </TableCell>
          <TableCell
            flex={0.6}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {surname || '--'}
          </TableCell>
          <TableCell
            flex={0.6}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
            align={'right'}
          >
            {formattedAmount !== 'NaN' ? formattedAmount : amount}
          </TableCell>
          <TableCell
            flex={0.7}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {currency}
          </TableCell>
          <TableCell
            flex={2.4}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {description}
          </TableCell>
          <TableCell
            flex={0.7}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {dateTime}
          </TableCell>
          <TableCell
            flex={0.9}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
          >
            {insertedAt}
          </TableCell>
          <TableCell flex={0.9} style={{ padding }}>
            {active &&
              active.userActive && <EyeIcon text={active.activePersonName} />}
          </TableCell>
          <TableCell
            flex={0.8}
            style={{ padding }}
            onClick={() => this.expandRow(depositId)}
            align={'center'}
          >
            {tag && (
              <i
                style={{ color: '#e96868' }}
                className="fa fa-sticky-note"
                aria-hidden="true"
              />
            )}
          </TableCell>
          <TransitionCell
            className={
              bulkActionsActive
                ? 'bulkSuspendCheckBoxActive'
                : 'bulkSuspendCheckBoxfade'
            }
            onClick={e => e.stopPropagation()}
            flex={0.9}
            show={isCellHidden}
          >
            <RenderCheckbox
              style={{ color: depositRowSelected ? 'white' : '' }}
              onCheckFunction={() =>
                handleSelectedBulkDepositClick({ depositId, title })
              }
              checked={depositRowSelected}
              disabled={isPending}
            />
          </TransitionCell>
        </TableRow>

        {formShown && (
          <FormSuspendDepositReview
            initialValues={{
              depositId,
              accountId: accountId || '',
              comment: '',
              deposit: { deposit },
              description: 'DEPOSIT',
            }}
            depositId={depositId}
            expandRawData={this.props.expandRawData}
            expandAddNote={this.props.addNoteFormShown}
            showRawData={() => this.props.showRawData(depositId)}
            form={`depositReviewForm+${depositId}`}
            rawData={this.refineRawData(this.props.deposit)}
            onCancel={this.props.toggleForm}
            showAddNote={() => this.props.toggleAddNotesForm(depositId)}
            isInDualAuth={isPending}
            removeActiveUser={this.removeActiveUser}
            showActiveUser={this.showActiveuser}
            comments={{
              depositComments: this.state.comments,
              status: this.state.commentsStatus,
            }}
          />
        )}
        {this.props.addNoteFormShown && this.renderAddNoteForm()}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDepositReview,
      fetchDepositComments,
      resetForm: () => reset('depositCommentsForm'),
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(Row)
