import { toast } from 'react-toastify'

import { fetchKeycloakActivity } from 'redux/keycloak-activity/actions'

export const submitFilter = async (values, dispatch) => {
  const { from, to } = values
  const { id } = values.keycloakInfo.info
  const dateRange = { id, from, to }
  try {
    dispatch(fetchKeycloakActivity(dateRange))
  } catch (e) {
    toast(`${e} `, { type: toast.TYPE.ERROR, autoClose: 2000 })
  }
}
