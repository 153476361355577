import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import ErrorToast from 'components/molecules/ErrorToast'

import CurrencyActions from './CurrencyActions'
import CurrencyBorrowWeightModal from './CurrencyModals/CurrencyBorrowWeightModal'

const CurrencyRow = ({
  row,
  handleUpdateAllowBorrow,
  updateCurrencyEnabledStatus,
  toggleEditForm,
  updateCurrencyBorrowWeight,
  isUpdatingCurrency,
}) => {
  const [editBorrowWeightModal, setEditBorrowWeightModal] = useState({
    isOpen: false,
    borrowWeight: 0,
  })
  const [isUpdatingCurrencyRow, setIsUpdatingCurrencyRow] = useState(false)
  const handleCloseEditBorrowWeightModal = () => {
    setEditBorrowWeightModal({
      ...editBorrowWeightModal,
      isOpen: false,
    })
  }

  const handleUpdateBorrowWeight = async () => {
    await updateCurrencyBorrowWeight({
      symbol: row.symbol,
      allowBorrow: true,
      borrowWeight: editBorrowWeightModal.borrowWeight,
    })
    setEditBorrowWeightModal({
      isOpen: false,
      borrowWeight: 0,
    })
  }

  const handleEnableCurrencyClick = async () => {
    try {
      setIsUpdatingCurrencyRow(true)
      await updateCurrencyEnabledStatus(row.symbol, row.isActive)
    } catch (error) {
      ErrorToast(error)
    }
    setIsUpdatingCurrencyRow(false)
  }

  const modalButtonDisabled =
    row.borrowWeight === editBorrowWeightModal.borrowWeight ||
    isUpdatingCurrency

  return (
    <>
      <TableRow className="light-hover">
        <TableCell>
          <CurrencyActions
            row={row}
            handleUpdateAllowBorrow={handleUpdateAllowBorrow}
            handleEnableCurrencyClick={handleEnableCurrencyClick}
            isUpdatingCurrencyRow={isUpdatingCurrencyRow}
          />
        </TableCell>
        <TableCell>
          <MuiButton
            onClick={() =>
              setEditBorrowWeightModal({
                isOpen: true,
                borrowWeight: row.borrowWeight,
              })
            }
            disabled={!row.allowBorrow}
            variant="outlined"
            endIcon={<EditIcon />}
          >
            {row.borrowWeight || 'Not Set'}
          </MuiButton>
        </TableCell>
        <TableCell>{row.symbol}</TableCell>
        <TableCell>{row.decimalPlaces}</TableCell>
        <TableCell>{row.shortName}</TableCell>
        <TableCell>{row.longName}</TableCell>
        <TableCell>{row.isActive.toString()}</TableCell>
        <TableCell style={{ minWidth: '120px' }}>
          {row.supportedWithdrawDecimalPlaces}
        </TableCell>
        <TableCell style={{ minWidth: '120px' }}>
          {row.currencyDecimalPlaces}
        </TableCell>
        <TableCell>
          {row.paymentReferenceFieldName || 'N/A'}
          {!row.paymentReferenceFieldName && (
            <MuiButton
              style={{
                marginLeft: '10px',
              }}
              variant="outlined"
              color="primary"
              onClick={() => toggleEditForm(row)}
            >
              Add Payment Reference
            </MuiButton>
          )}
        </TableCell>
      </TableRow>
      <CurrencyBorrowWeightModal
        isOpen={editBorrowWeightModal.isOpen}
        updateCurrencyBorrowWeight={handleUpdateBorrowWeight}
        currencyShortName={row.shortName}
        setEditBorrowWeightModal={setEditBorrowWeightModal}
        borrowWeight={editBorrowWeightModal.borrowWeight}
        modalButtonDisabled={modalButtonDisabled}
        handleCloseEditBorrowWeightModal={handleCloseEditBorrowWeightModal}
      />
    </>
  )
}

CurrencyRow.propTypes = {
  row: PropTypes.shape({
    symbol: PropTypes.string,
    decimalPlaces: PropTypes.any,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    isActive: PropTypes.bool,
    withdrawalDecimalPlaces: PropTypes.any,
    paymentReferenceFieldName: PropTypes.string,
  }),
  toggleEditForm: PropTypes.func,
  handleUpdateAllowBorrow: PropTypes.func,
  updateCurrency: PropTypes.func,
  updateCurrencyBorrowWeight: PropTypes.func,
}

export default CurrencyRow
