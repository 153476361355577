import styled from 'styled-components'
import { GRAY_2, PANEL_BLUE, WHITE } from 'theme/colors'

export const SwitcherContainer = styled.div`
  font-size: ${({ fontSize = 17 }) => `${parseInt(fontSize, 10) + 1}px`};
  background-color: ${({
  active,
  activeColorBg = PANEL_BLUE,
  inactiveColorBg = GRAY_2,
}) => (active ? activeColorBg : inactiveColorBg)};
  color: ${WHITE};
  border-radius: 2em;
  cursor: pointer;
  position: relative;
  padding: 2px;
  user-select: none;
  box-sizing: content-box;
  background-clip: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Dot = styled.div`
  background: #fff;
  order: ${({ active }) => (active ? 2 : 'none')};
  margin: ${({ active }) =>
    active ? '0.2em 0.2em 0.2em 0.5em' : '0.2em 0.5em 0.2em 0.2em'};
  border-radius: 100%;
  height: 1.5em;
  width: 1.5em;
  transition: background-color 0.4s, left 0.2s;
  box-sizing: border-box;
`

export const Label = styled.div`
  position: relative;
  text-align: center;
  flex: 1;
  padding-right: ${({ active, padding = '1em' }) => (active ? 0 : padding)};
  padding-left: ${({ active, padding = '1em' }) => (active ? padding : 0)};
  padding-bottom: 0.05em;
`
