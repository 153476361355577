import React from 'react'
import PropTypes from 'prop-types'
import { StyledCorporateUserTableHeading } from './Styles'

const CorporateEntityTableHeadings = ({ headings }) => {
  return (
    <thead>
      <tr>
        {headings.map(heading => (
          <StyledCorporateUserTableHeading key={heading}>
            {heading}
          </StyledCorporateUserTableHeading>
        ))}
      </tr>
    </thead>
  )
}

CorporateEntityTableHeadings.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string),
}

export default CorporateEntityTableHeadings
