import React from 'react'
import FerryAuths from '../../FerryAuths'
import AddKeycloakGroupForm from '../../../forms/AddKeycloakGroup'

const ManageKeycloakGroups = () => (
  <>
    <h1>Manage Keycloak Groups</h1>
    <AddKeycloakGroupForm />
    <FerryAuths ferryAuthRequestType={'add_keycloak_group'} />
  </>
)
export default ManageKeycloakGroups
