import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAccountBalanceSummary } from 'redux/balances/actions'
import SinglePlatform from 'components/organisms/SinglePlatformBalanceInfo'
import ErrorBoundary from 'helpers/ErrorBoundary'

class AccountBalanceSummary extends Component {
  componentDidMount() {
    this.props.fetchAccountBalanceSummary()
  }

  render() {
    const {
      accountBalanceSummary: { status, data },
    } = this.props
    return (
      <ErrorBoundary message="Finance Accounts Balance Summary">
        {status === 'loading' && <p>Loading, please wait</p>}
        {status === 'done' && (
          <SinglePlatform
            name="Accounts Balance Summary"
            balances={data}
            expanded={true}
          />
        )}
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  accountBalanceSummary: state.balances.summary,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountBalanceSummary,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountBalanceSummary)
