import styled from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'

export const FormContainer = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 60%;
`

export const DatePicker = styled(KeyboardDatePicker)`
  margin-top: 0;
`
