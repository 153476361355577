import getAxios from 'helpers/axios'
import { viewPdf } from 'helpers/utils'
import { FILE_TYPES } from '../const/Entities'

// TODO: remove this as its never used
export const handleAddDocument = async (
  type,
  file,
  doc,
  uploadCorporateDocuments,
) => {
  const documentData = {
    file,
    metaData: {
      documentType: type,
      uploadDate: Date.now(),
      fileName: file.name,
    },
    corporateId: doc.corporate_id,
    corporateType: doc.corporate_type,
  }
  const files = [documentData]
  const documentToSend = { files }
  await uploadCorporateDocuments(documentToSend)
}

export const handleViewDocuments = async (
  document,
  corporateId,
  setIsLoadingDocument,
  setViewDocument,
  setDocumentsDataBeingViewed,
) => {
  const { documentType } = document
  setIsLoadingDocument(true)
  setViewDocument(true)

  const { data } = await getAxios().get(
    `/corporates/documents/${corporateId}/${documentType}`,
  )

  setIsLoadingDocument(false)
  const documentsInfo = data.map(document => ({
    id: document.id,
    file: document.file,
    documentType,
    createdAt: document.createdAt,
    documentName: document.documentName,
    mimeType: document.fileType,
  }))
  setDocumentsDataBeingViewed(documentsInfo)
  setIsLoadingDocument(false)
}
