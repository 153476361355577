import getAxios from 'helpers/axios'
import { MobileNotification } from 'interfaces/mobileNotifications'
import { Dispatch } from 'react'
import { reset } from 'redux-form'
import ErrorToast from 'components/molecules/ErrorToast'
import { RootState } from 'store'

export type MobileNotificationsActions =
  | 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_START'
  | 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS'
  | 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_ERROR'
  | 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_START'
  | 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS'
  | 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR'
  | 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_START'
  | 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS'
  | 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR'
  | 'TOGGLE_MOBILE_MESSAGING_CAMPAIGN_FORM'
  | 'UPDATE_MOBILE_NOTIFICATION_CAMPAIGN'

interface MobileNotificationDispatch {
  type: MobileNotificationsActions
  payload?: MobileNotification | MobileNotification[]
}

export const fetchMobileCampaigns =
  (campaign = 'all') =>
  async (dispatch: Dispatch<MobileNotificationDispatch>) => {
    try {
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_START',
      })
      const { data }: { data: MobileNotification[] } = await getAxios().get(
        '/notifications/mobile/campaign?limit=100&offset=0',
      )
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_ERROR',
      })
    }
  }

export const fetchMoreMobileCampaigns =
  (campaign = 'all') =>
  async (
    dispatch: Dispatch<MobileNotificationDispatch>,
    getState: () => RootState,
  ) => {
    try {
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_START',
      })
      const offset = getState().mobileNotifications.mobileCampaigns.length
      const { data }: { data: MobileNotification[] } = await getAxios().get(
        `/notifications/mobile/campaign/${campaign}?limit=100&offset=${offset}`,
      )
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_MOBILE_NOTIFICATIONS_MESSAGES_ERROR',
      })
    }
  }

interface SendMobileNotificationsValues {
  campaignName: string
  messageTitle: string
  message: string
  userIds: string[]
  scheduledDateTime: {
    realDateTime: Date
  }
  mobilePageUrl: string
  notificationType: 'SMS' | 'PUSH'
}
export const sendMobileNotificationsToUsers =
  (values: SendMobileNotificationsValues) =>
  async (dispatch: Dispatch<MobileNotificationDispatch>) => {
    try {
      dispatch({ type: 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_START' })
      const userIdsSet = new Set()
      if (values.userIds) {
        const results = values.userIds
        if (results) {
          results.forEach((id) => userIdsSet.add(id))
        }
      }
      const userIds = Array.from(userIdsSet)
      const scheduledDateTime = values.scheduledDateTime
        ? values.scheduledDateTime.realDateTime.toISOString()
        : new Date(Date.now()).toISOString()
      const mobilePageUrl = values.mobilePageUrl
      const { data }: { data: MobileNotification } = await getAxios().post(
        '/notifications/mobile/campaign',
        {
          campaignName: values.campaignName,
          messageTitle: values.messageTitle,
          message: values.message,
          notificationType: values.notificationType,
          userIds,
          scheduledDateTime,
          mobilePageUrl,
        },
      )
      dispatch({
        type: 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS',
        payload: data,
      })
      dispatch(reset('mobileNotifications'))
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR' })
    }
  }

export const retrySendMobileNotificationsToUsers =
  (campaignId: number) =>
  async (dispatch: Dispatch<MobileNotificationDispatch>) => {
    try {
      dispatch({ type: 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_START' })
      const { data }: { data: MobileNotification } = await getAxios().post(
        `/notifications/mobile/campaign/retry?campaignId=${campaignId}`,
      )
      dispatch({
        type: 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_SUCCESS',
        payload: data,
      })
      dispatch(reset('mobileNotifications'))
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'RETRY_SEND_MOBILE_NOTIFICATIONS_MESSAGES_ERROR' })
    }
  }

export const toggleCreateMarketingCampaignForm =
  (value: boolean) => async (dispatch) => {
    dispatch({
      type: 'TOGGLE_MOBILE_MESSAGING_CAMPAIGN_FORM',
      payload: value,
    })
  }

export const updateMobileNotificationCampaign =
  (mobileNotificationCampaign) => (dispatch) => {
    dispatch({
      type: 'UPDATE_MOBILE_NOTIFICATION_CAMPAIGN',
      payload: mobileNotificationCampaign,
    })
  }
