import {
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_ERROR,
  ALLOW_BORROW_ON_CURRENCY_START,
  ALLOW_BORROW_ON_CURRENCY_SUCCESS,
  ALLOW_BORROW_ON_CURRENCY_ERROR,
  UPDATE_CURRENCY_BORROW_WEIGHT_START,
  UPDATE_CURRENCY_BORROW_WEIGHT_SUCCESS,
  UPDATE_CURRENCY_BORROW_WEIGHT_ERROR,
  UPDATE_CURRENCY_ENABLED_STATUS_SUCCESS,
} from './actions'

const initialState = {
  data: [],
  status: 'pristine',
  isUpdatingCurrency: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_CURRENCIES_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    case ALLOW_BORROW_ON_CURRENCY_START:
    case UPDATE_CURRENCY_BORROW_WEIGHT_START: {
      return { ...state, isUpdatingCurrency: true }
    }

    case UPDATE_CURRENCY_ENABLED_STATUS_SUCCESS:
    case ALLOW_BORROW_ON_CURRENCY_SUCCESS:
    case UPDATE_CURRENCY_BORROW_WEIGHT_SUCCESS: {
      const data = action.payload
      const { symbol, ...rest } = data

      const updatedData = [...state.data]

      const indexOfCurrencyToUpdate = state.data.findIndex(
        currency => currency.symbol === symbol,
      )
      if (indexOfCurrencyToUpdate !== -1) {
        updatedData[indexOfCurrencyToUpdate] = {
          ...state.data[indexOfCurrencyToUpdate],
          ...rest,
        }
      }

      return {
        ...state,
        data: updatedData,
        isUpdatingCurrency: false,
      }
    }

    case ALLOW_BORROW_ON_CURRENCY_ERROR:
    case UPDATE_CURRENCY_BORROW_WEIGHT_ERROR: {
      return { ...state, isUpdatingCurrency: false }
    }

    default:
      return state
  }
}
