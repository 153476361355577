import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import ApplicationHttpView from './table'
import DynamicFilterForm from 'forms/Elasticsearch/DynamicFilter'
import { bindActionCreators } from 'redux'
import { refreshScreen } from 'redux/elasticsearchQueryLogs/actions'

class Logs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShow: true,
      title: '',
    }
  }

  componentDidMount(){
    this.props.refreshScreen()
  }

  toggleForm = () => {
    const { formShow } = this.state
    this.setState({
      formShow: !formShow,
    })
  }

  filterByType = (data, type) => {
    var filtered = data.filter(val => {
      var ignoreOutgoing = val.message.textPayload.match('OUTGOING') === null
      return val.valrType === type && ignoreOutgoing
    })
    return filtered.sort(
      (a, b) => new Date(b['@timestamp']) - new Date(a['@timestamp']),
    )
  }

  render() {
    const {
      logs: { data, status },
    } = this.props.elasticsearchQuery
    const { accountId } = this.props.match.params

    return (
      <Fragment>
        <Fragment>
          <DynamicFilterForm
            initialValues={{
              id: accountId,
              skip:0,
              limit:101
            }}
          />
        </Fragment>
        <ApplicationHttpView
          status={status}
          data={data && this.filterByType(data, 'application-http')}
          title={'http-logs'}
        />
        {status === 'loading' && <LoadingBlock />}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    elasticsearchQuery: state.elasticsearchQuery,
  }
}
const mapDispatchToProps = (dispatch)=>
  bindActionCreators(
    {
      refreshScreen
    },
    dispatch
  )


export default connect(mapStateToProps,mapDispatchToProps)(Logs)
