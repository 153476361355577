import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const SubmitCurrencyPairs = async (orderTypesToUpdate) => {
  try {
    for (const orderTypeData of orderTypesToUpdate) {
      const { currencyPairSymbol, orderType, allowed } = orderTypeData
      await getAxios().post('/orderTypes-allowed', {
        currencyPair: currencyPairSymbol,
        orderType,
        allowed,
      })
    }
    toast(
      `${orderTypesToUpdate.length} currency pair order types updated successfully`,
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      },
    )
  } catch (e) {
    ErrorToast(e)
  }
}
