import React, { useState } from 'react'

import { Table, TableBody } from 'components/atoms/Table/styles'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import {
  removeSubmittedItem as removeNameSurnameSubmittedItem,
  fetchNameAndSurnameById,
} from 'redux/nameAndSurnameChange/actions'
import { Headers } from './headers'
import Row from './row'

const NameAndSurnameChangeTable = props => {
  const {
    expandItem,
    data,
    nameAndSurnameDataById,
    rowLoading,
  } = props
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)

  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(nameAndSurnameInfo => (
          <Row
            key={nameAndSurnameInfo.id}
            dualAuthId={nameAndSurnameInfo.id}
            nameAndSurnameInfo={nameAndSurnameInfo}
            isSelected={selectedRow === nameAndSurnameInfo.id}
            toggleSelected={toggleSelectedRow}
            removeSubmittedItem={props.removeNameSurnameSubmittedItem}
            nameAndSurnameDataById={nameAndSurnameDataById}
            rowLoading={rowLoading}
            fetchNameAndSurnameById={props.fetchNameAndSurnameById}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const actions = {
  removeNameSurnameSubmittedItem,
  fetchNameAndSurnameById,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    rowLoading: state.dualAuthByType.nameAndSurnameDataByIdLoading,
    nameAndSurnameDataById: state.dualAuthByType.nameAndSurnameDataById,
  })),
  actions,
  NameAndSurnameChangeTable
)
