import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { IApplicationState } from 'interfaces'
import React, { FC, useEffect, useState } from 'react'
import {
  checkPEPScreeningResult,
  fetchPEPScreeningResults,
} from 'redux/pepScreening/actions'
import {
  PEPScreeningInitialState,
  PEP_SCREENING_CHECK_STATUS,
} from 'redux/pepScreening/interfaces'
import { connectStateAndDispatch } from 'store'
import PEPScreeningRow from './PEPScreeningRow'
import MuiSwitcherWithTitle from 'components/atoms/MuiSwitcherWithTitle'
import { StyledActionsWrapper } from './Styles'
import { TablePlaceholder } from 'components/atoms/Table/styles'

interface IPEPScreeningProps extends PEPScreeningInitialState {
  fetchPEPScreeningResults: () => Promise<void>
  checkPEPScreeningResult: (
    pepScreeningId: number,
    checkStatus: PEP_SCREENING_CHECK_STATUS,
    comment: string,
  ) => Promise<void>
}

const PEPScreening: FC<IPEPScreeningProps> = ({ loading, data, ...props }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [showCheckedPEPScreeningResults, setShowCheckedPEPScreeningResults] =
    useState(false)
  const [selectedRow, setSelectedRow] = useState<null | number>(null)
  const toggleSelectedRow = (id: number) =>
    setSelectedRow((prev) => (prev === id ? null : id))
  const filteredPepScreeningResults = data.filter((pepScreeningResult) =>
    showCheckedPEPScreeningResults
      ? pepScreeningResult.checked === true
      : pepScreeningResult.checked === false ||
        pepScreeningResult.checked === null,
  )
  const tablePaginationCount = filteredPepScreeningResults.length
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
  }
  const togglePEPScreeningChecked = () =>
    setShowCheckedPEPScreeningResults((prev) => !prev)
  useEffect(() => {
    props.fetchPEPScreeningResults()
  }, [])

  if (loading) return <LoadingBlock message="Fetching PEP screening data..." />

  return (
    <div>
      <StyledActionsWrapper>
        <MuiSwitcherWithTitle
          checked={showCheckedPEPScreeningResults}
          text={`${
            showCheckedPEPScreeningResults ? 'Hide' : 'Show'
          } checked PEP screening results`}
          onChange={togglePEPScreeningChecked}
        />
      </StyledActionsWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Account ID</TableCell>
            <TableCell>Full name</TableCell>
            <TableCell>Check status</TableCell>
            {showCheckedPEPScreeningResults && <TableCell>Comment</TableCell>}
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell>Check</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPepScreeningResults.length === 0 && (
            <TableRow className="no-hover">
              <TableCell colSpan={9}>
                <TablePlaceholder>
                  No {showCheckedPEPScreeningResults ? 'Checked' : 'Unchecked'}{' '}
                  PEP screening results.
                </TablePlaceholder>
              </TableCell>
            </TableRow>
          )}
          {filteredPepScreeningResults
            .slice(page * rowsPerPage, rowsPerPage * (page + 1))
            .map((pepScreeningResult) => (
              <PEPScreeningRow
                key={pepScreeningResult.id}
                {...pepScreeningResult}
                checkPEPScreeningResult={props.checkPEPScreeningResult}
                toggleSelectedRow={toggleSelectedRow}
                selectedRow={selectedRow === pepScreeningResult.id}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={tablePaginationCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

const mapStateToProps = (state: IApplicationState) => {
  return state.pepScreening
}

const actions = {
  fetchPEPScreeningResults,
  checkPEPScreeningResult,
}

export default connectStateAndDispatch(mapStateToProps, actions, PEPScreening)
