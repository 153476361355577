import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { hasExchangeEditorRole } from 'helpers/roleBasedAccess'

import { ConfigStyle } from '../styles'

const CurrencyActions = ({
  row,
  handleEnableCurrencyClick,
  handleUpdateAllowBorrow,
  isUpdatingCurrencyRow,
}) => (
  <ConfigStyle>
    <div style={{ display: 'flex' }}>
      <Button
        style={{ marginRight: '10px', width: '90px' }}
        disabled={isUpdatingCurrencyRow}
        variant="contained"
        color={row.isActive ? 'secondary' : 'primary'}
        onClick={handleEnableCurrencyClick}
      >
        {row.isActive ? 'Disable' : 'Enable'}
      </Button>
      {hasExchangeEditorRole() !== -1 && (
        <Button
          style={{
            marginRight: '10px',
            width: '160px',
          }}
          variant="contained"
          color={row.allowBorrow ? 'secondary' : 'primary'}
          onClick={() =>
            handleUpdateAllowBorrow(
              row.symbol,
              row.allowBorrow,
              row.borrowWeight,
            )
          }
        >
          {row.allowBorrow ? 'Disable Borrow' : 'Enable Borrow'}
        </Button>
      )}
    </div>
  </ConfigStyle>
)

CurrencyActions.propTypes = {
  row: PropTypes.shape({
    symbol: PropTypes.string,
    decimalPlaces: PropTypes.any,
    shortName: PropTypes.string,
    longName: PropTypes.string,
    isActive: PropTypes.bool,
    withdrawalDecimalPlaces: PropTypes.any,
    paymentReferenceFieldName: PropTypes.string,
  }),
  handleUpdateAllowBorrow: PropTypes.func,
  updateCurrency: PropTypes.func,
}

export default CurrencyActions
