import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { Flexbox } from 'components/layout/index'

import {
  isNumber, moreThanZero, required, requiredSelectDropdownValue,
} from 'forms/fieldValidators'
import {
  FormContainer,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'
import { renderDropdown, renderInput } from 'forms/fields'

import { submit, isPendingManualAllocation } from './submit'

import { hasDepositEditorRole } from '../../helpers/roleBasedAccess'

let Form = props => {
  const {
    submitting,
    error,
    invalid,
    extra,
    handleSubmit,
    onCancel,
    onSubmit,
    currentStatus,
    status,
  } = props

  const hasEditorRole = hasDepositEditorRole()

  if (!['FLAGGED_FOR_REVIEW', 'PENDING_MANUAL_ALLOCATION', 'MANUAL_ALLOCATION_DUAL_AUTH_REJECTED'].includes(currentStatus)) {
    return <FormContainer>{extra}</FormContainer>
  }

  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          justify={'space-between'}
          align={'baseline'}
          direction={'row'}
        >
          <Field
            label={'Action'}
            name={'status'}
            margin={`0 ${BASE * 2}px 0 0`}
            width="30%"
            type="deposits"
            component={renderDropdown}
            validate={[requiredSelectDropdownValue]}
            disabled={!hasEditorRole}
            options={[
              { value: 'UNSUSPEND', label: 'Unsuspend' },
              { value: 'DECLINE', label: 'Reject' },
            ]}
          />

          {isPendingManualAllocation(currentStatus) && status !== 'DECLINE' && status !== 'UNSUSPEND' && <Field
            label={'Account Id (required)'}
            name={'accountId'}
            width={'100%'}
            validate={[required, isNumber, moreThanZero]}
            component={renderInput}
            justify={'space-around'}
            margin={`0 ${BASE * 2}px 0 0`}
            disabled={!hasEditorRole}
          />}
          {status !== 'UNSUSPEND' &&
            <Field
              label={'Comment (required)'}
              name={'comment'}
              width={'100%'}
              validate={[required]}
              component={renderInput}
              justify={'space-around'}
              margin={`0 ${BASE * 2}px 0 0`}
              disabled={!hasEditorRole}
            />
          }
          <Flexbox
            marginRight={BASE * 2}
            marginTop={BASE * 1.5}
            justify={'center'}
            direction={'row'}
          >
            <Button
              type="submit"
              disabled={submitting || invalid || !hasEditorRole}
              margin={`0 ${BASE * 2}px 0 0`}
            >
              Submit
            </Button>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
          </Flexbox>
        </InputGroup>
        {error && <FormError error={error} />}
        {extra}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)

const mapStateToProps = (state, ownProps) => {
  const form = state.form[ownProps.form] || { values: {} }
  const {
    values: {
      status, comment, accountId, selectedValue, currentStatus,
    },
  } = form

  return {
    status: status ? status.value : '',
    comment: comment || '',
    accountId: accountId || '',
    selectedValue: selectedValue || '',
    currentStatus: currentStatus || ownProps.initialValues.currentStatus,
  }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
