import React, { Component } from 'react'
import FormFundIndividual from 'forms/FundIndividual'
import { ActionButton } from 'components/atoms/Table/styles'
import { fundedAction } from 'redux/fund'
import ErrorBoundary from 'helpers/ErrorBoundary'

class FundIndividual extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formShown: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    return (
      <ErrorBoundary message="Individual Funding">
        {!formShown && (
          <ActionButton onClick={this.toggleForm}>Start Funding</ActionButton>
        )}
        {formShown && (
          <FormFundIndividual
            onCancel={this.toggleForm}
            onSubmitSuccess={(values, dispatch) => {
              dispatch(fundedAction())
              this.toggleForm()
            }}
          />
        )}
      </ErrorBoundary>
    )
  }
}

export default FundIndividual
