import styled from 'styled-components'

export const ListStyle = styled.tr`
  display: grid;
  grid-template-columns: auto auto ;
  grid-column-gap:4px;
  grid-row-gap:5px

`

export const EnlargedDocumentImage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  maxheight: 200px;
  `