import React, { FC, useState } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid'
import Tooltip from '@material-ui/core/Tooltip'
import { checkRole, ROLES } from 'helpers/roleBasedAccess'
import SimpleModal from 'components/organisms/Modal'
import ChangeCurrencyPairExchangeForm from 'forms/ChangeCurrencyPairExchangeForm'
import { connectStateAndDispatch } from 'store'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import {
  updateCurrencyPairActivationStatus,
  toggleMarginingOnCurrencyPair,
  updateExchangeForCurrencyPair,
} from 'redux/currencyPairs/actions'

import CurrencyPairActions from './CurrencyPairActions'

import { ConfigStyle, StyledExchangeTableData } from '../styles'

interface CurrencyPair {
  id: number
  symbol: string
  baseCurrency: {
    id: number
    symbol: string
    decimalPlaces: number
    _isActive: boolean
    shortName: string
    longName: string
    currencyDecimalPlaces: number
    supportedWithdrawDecimalPlaces: number
    _allowBorrow: boolean
    _borrowWeight: number
  }
  quoteCurrency: {
    id: number
    symbol: string
    decimalPlaces: number
    _isActive: boolean
    shortName: string
    longName: string
    currencyDecimalPlaces: number
    supportedWithdrawDecimalPlaces: number
    _allowBorrow: boolean
    _borrowWeight: number
  }
  shortName: string
  exchange: string
  active: boolean
  minBaseAmount: number
  maxBaseAmount: number
  minQuoteAmount: number
  maxQuoteAmount: number
  tickSize: string
  baseDecimalPlaces: number
  marginTradingAllowed: boolean
  currencyPairType: string
}

interface CurrencyPairRowProps {
  row: CurrencyPair
  updateLimit: () => void
  updateCurrencyPairLimits: (
    symbol: string,
    minBaseAmount: number,
    maxBaseAmount: number,
    minQuoteAmount: number,
    maxQuoteAmount: number,
    tickSize: string,
    baseDecimalPlaces: number,
  ) => Promise<void>
  updateCurrencyPair: (
    symbol: string,
    active: boolean,
    baseCurrencyShortName: string,
    quoteCurrencyShortName: string,
  ) => Promise<void>
  isUpdatingCurrencyPair: boolean
  updateExchangeForCurrencyPair: (
    value: { symbol: string; newExchange: string },
  ) => Promise<void>
  updateCurrencyPairActivationStatus: () => void
  toggleMarginingOnCurrencyPair: (
    value: { symbol: string; allowMargin: boolean },
  ) => Promise<void>
}

const CurrencyPairRow: FC<CurrencyPairRowProps> = ({
  row,
  updateLimit,
  updateCurrencyPairLimits,
  updateCurrencyPair,
  ...props
}) => {
  const exchangeEditor = checkRole(ROLES.exchangeEditor)
  const [changeExchangeModalOpen, setChangeExchangeModalOpen] = useState(false)
  const [isUpdatingCurrencyPair, setIsUpdatingCurrencyPair] = useState(false)
  const [
    allowMarginConfirmationDialogOpen,
    setAllowMarginConfirmationDialogOpen,
  ] = useState(false)

  const handleProceedChangeExchange = async (values: {
    symbol: string
    newExchange: string
  }) => {
    setIsUpdatingCurrencyPair(true)
    const { symbol, newExchange } = values
    const exchangePairData = {
      symbol,
      newExchange,
    }
    await props.updateExchangeForCurrencyPair(exchangePairData)
    setIsUpdatingCurrencyPair(false)
    setChangeExchangeModalOpen(false)
  }

  const handleProceedConfirmation = async () => {
    setIsUpdatingCurrencyPair(true)
    await props.toggleMarginingOnCurrencyPair({
      symbol: row.symbol,
      allowMargin: !row.marginTradingAllowed,
    })
    setIsUpdatingCurrencyPair(false)
    setAllowMarginConfirmationDialogOpen(false)
  }

  const handleUpdateCurrencyPair = async () => {
    setIsUpdatingCurrencyPair(true)
    await updateCurrencyPair(
      row.symbol,
      row.active,
      row.baseCurrency.shortName,
      row.quoteCurrency.shortName,
    )
    setIsUpdatingCurrencyPair(false)
  }

  const handleUpdateCurrencyPairLimits = async () => {
    setIsUpdatingCurrencyPair(true)
    await updateCurrencyPairLimits(
      row.symbol,
      row.minBaseAmount,
      row.maxBaseAmount,
      row.minQuoteAmount,
      row.maxQuoteAmount,
      row.tickSize,
      row.baseDecimalPlaces,
    )
    setIsUpdatingCurrencyPair(false)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <ConfigStyle>
            <CurrencyPairActions
              active={row.active}
              updateLimit={updateLimit}
              handleUpdateCurrencyPair={handleUpdateCurrencyPair}
              handleUpdateCurrencyPairLimits={handleUpdateCurrencyPairLimits}
              isUpdatingCurrencyPair={isUpdatingCurrencyPair}
            />
          </ConfigStyle>
        </TableCell>
        <TableCell>{row.symbol}</TableCell>
        <TableCell>{row.shortName}</TableCell>
        <TableCell>
          <StyledExchangeTableData>
            {row.exchange}{' '}
            {exchangeEditor && (
              <Tooltip title={'Change Exchange'}>
                <span>
                  <IconButton
                    disabled={!exchangeEditor}
                    onClick={() => {
                      setChangeExchangeModalOpen(true)
                    }}
                  >
                    <FlipCameraAndroidIcon
                      // @ts-ignore
                      color={!exchangeEditor ? 'grey' : 'primary'}
                      fontSize="small"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </StyledExchangeTableData>
        </TableCell>
        <TableCell>{row.active.toString()}</TableCell>
        <TableCell>{row.minBaseAmount}</TableCell>
        <TableCell>{row.maxBaseAmount}</TableCell>
        <TableCell>{row.minQuoteAmount}</TableCell>
        <TableCell>{row.maxQuoteAmount}</TableCell>
        <TableCell>{row.tickSize}</TableCell>
        <TableCell>{row.baseDecimalPlaces}</TableCell>
        {exchangeEditor && (
          <TableCell>
            <MuiButton
              color={row.marginTradingAllowed ? 'secondary' : 'primary'}
              onClick={() => setAllowMarginConfirmationDialogOpen(true)}
            >
              {row.marginTradingAllowed ? 'Disable' : 'Enable'}
            </MuiButton>
          </TableCell>
        )}
        <TableCell>{row.currencyPairType}</TableCell>
      </TableRow>
      <ConfirmationDialog
        disabled={isUpdatingCurrencyPair}
        title={`${
          row.marginTradingAllowed ? 'Cancel' : 'Allow'
        } Margining for ${row.symbol}`}
        message={`Are you sure you want to ${
          row.marginTradingAllowed ? 'disable' : 'enable'
        } margining on this currency pair?`}
        open={allowMarginConfirmationDialogOpen}
        proceed={handleProceedConfirmation}
        handleClose={() => setAllowMarginConfirmationDialogOpen(false)}
        button={{
          confirm: {
            text: 'Proceed',
          },
          decline: {
            text: 'Cancel',
          },
        }}
      />
      <SimpleModal
        handleClose={() => setChangeExchangeModalOpen(false)}
        title="Change Exchange"
        message={`Change exchange for ${row.symbol}`}
        open={changeExchangeModalOpen}
        width="40%"
        buttonsHidden
      >
        <ChangeCurrencyPairExchangeForm
          onCancel={() => setChangeExchangeModalOpen(false)}
          currentExchange={row.exchange}
          initialValues={{
            symbol: row.symbol,
            newExchange: row.exchange,
          }}
          onSubmit={handleProceedChangeExchange}
        />
      </SimpleModal>
    </>
  )
}

const actions = {
  updateCurrencyPairActivationStatus,
  toggleMarginingOnCurrencyPair,
  updateExchangeForCurrencyPair,
}

export default connectStateAndDispatch(null, actions, CurrencyPairRow)
