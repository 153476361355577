import getAxios from 'helpers/axios'
import {
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'
import { Dispatch } from 'react'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'

import {
  DualAuthPayload,
  DualAuthResponse,
  FetchRiskBasedApproachDispatch,
  InitializeRiskProfileReviewDispatch,
  InitializeRiskProfileReviewResponse,
  InitiateDualAuthDispatch,
  RiskBasedApproachResponse,
  SearchedRiskProfileDispatch,
  SearchedRiskProfileResponse,
} from './interfaces'

export const fetchIndividualRiskBasedProfiles =
  (
    offSet: string,
    limit: string,
    type: string,
    riskLevelToSearch: RiskLevelTypes,
  ) =>
  async (dispatch: Dispatch<FetchRiskBasedApproachDispatch>) => {
    try {
      dispatch({ type: 'FETCH_RISK_PROFILES_START' })
      const { data }: RiskBasedApproachResponse = await getAxios().get(
        `risk-based-approach/profiles?offSet=${offSet}&limit=${limit}&type=${type}&level=${riskLevelToSearch}`,
      )
      dispatch({
        type: 'FETCH_RISK_PROFILES_SUCCESS',
        payload: {
          data,
          type,
          limit,
          offSet,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'FETCH_RISK_PROFILES_ERROR' })
    }
  }

export const fetchMoreRiskBasedProfiles =
  (
    offSet: string,
    limit: string,
    type: string,
    riskLevelToSearch: RiskLevelTypes,
  ) =>
  async (dispatch: Dispatch<FetchRiskBasedApproachDispatch>) => {
    try {
      dispatch({ type: 'FETCH_MORE_RISK_PROFILES_START' })
      const { data }: RiskBasedApproachResponse = await getAxios().get(
        `risk-based-approach/profiles?offSet=${offSet}&limit=${limit}&type=${type}&level=${riskLevelToSearch}`,
      )
      dispatch({
        type: 'FETCH_MORE_RISK_PROFILES_SUCCESS',
        payload: {
          data,
          type,
          limit,
          offSet,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'FETCH_MORE_RISK_PROFILES_ERROR' })
    }
  }

export const initiateRiskProfileDualAuth =
  (dualAuthInfo: DualAuthPayload) =>
  async (dispatch: Dispatch<InitiateDualAuthDispatch>) => {
    const { comment, recommendationStatus, type } = dualAuthInfo
    const dualAuthData = {
      ...dualAuthInfo,
      data: { comment, recommendationStatus },
    }
    try {
      const { data }: DualAuthResponse = await getAxios().post(
        'risk-based-approach/dual-auth',
        {
          dualAuthData,
        },
      )
      dispatch({
        type: 'CREATE_DUAL_AUTH_SUCCESS',
        payload: { data, type },
      })

      toast('Dual Auth Successfully created ', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'DUAL_AUTH_ERROR' })
    }
  }

export const initializeIndividualRiskProfileReview =
  (accountId: number) =>
  async (dispatch: Dispatch<InitializeRiskProfileReviewDispatch>) => {
    try {
      dispatch({
        type: 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_START',
      })
      const { data }: InitializeRiskProfileReviewResponse =
        await getAxios().put('risk-based-approach/profile/status-change', {
          accountId,
        })
      dispatch({
        type: 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_SUCCESS',
        payload: { data, accountId },
      })
      toast('Recommendation Status set to pending', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_ERROR' })
    }
  }

export const searchRiskProfileByAccountId =
  (accountId: number, type?: 'CORPORATE' | 'INDIVIDUAL') =>
  async (dispatch: Dispatch<SearchedRiskProfileDispatch>) => {
    try {
      dispatch({
        type: 'SEARCH_RISK_PROFILE_START',
      })
      let data: RiskProfileWithDualAuthStatus | null | number = null
      if (type === 'CORPORATE') {
        const response: SearchedRiskProfileResponse = await getAxios().get(
          `risk-based-approach/profiles/corporate/${accountId}`,
        )
        data = response.data
      } else {
        const response: SearchedRiskProfileResponse = await getAxios().get(
          `risk-based-approach/profiles/${accountId}`,
        )
        data = response.data
      }

      dispatch({
        type: 'SEARCH_RISK_PROFILE_SUCCESS',
        payload: {
          data,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'SEARCH_RISK_PROFILE_ERROR' })
    }
  }

export const updateSearchedRiskProfileState =
  (accountId: number) =>
  async (dispatch: Dispatch<SearchedRiskProfileDispatch>) => {
    dispatch({ type: 'SEARCH_RISK_PROFILE_START' })
    dispatch({
      type: 'UPDATE_RISK_PROFILE_ACCOUNT_ID',
      payload: { data: accountId },
    })
  }

export const updateSearchedRiskProfileAccountId =
  (accountId: number) =>
  async (dispatch: Dispatch<SearchedRiskProfileDispatch>) =>
    dispatch({
      type: 'UPDATE_RISK_PROFILE_ACCOUNT_ID',
      payload: { data: accountId },
    })

export const clearSearchedRiskProfile =
  () => async (dispatch: Dispatch<SearchedRiskProfileDispatch>) =>
    dispatch({ type: 'CLEAR_SEARCHED_RISK_PROFILE' })
