import styled from 'styled-components'

export const StyledMuiSwitcherWithTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledMuiSwitcherTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  margin: 0px;
  margin-bottom: 5px;
`
