import { IconButton, LinearProgress } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import SimpleModal from 'components/organisms/Modal'
import React, { FC, useCallback, useState } from 'react'
import getAxios from 'helpers/axios'
import { connectStateAndDispatch } from 'store'
import { toast } from 'react-toastify'
import { renderPlural } from 'helpers/utils'

import FilesUploader from 'components/molecules/FilesUploader'
import { Title } from 'components/atoms/Title'
import ErrorToast from 'components/molecules/ErrorToast'
import { addNewRiskProfileEddDocument } from 'redux/accounts/actions'

import {
  RiskProfileEddDocumentItemWrapper,
  RiskProfileGridItem,
  RiskProfileUploaderContainer,
} from '../../Styles'

import {
  ALLOWED_RISK_PROFILE_EDD_DOCUMENT_TYPES,
  RISK_PROFILE_EDD_DOCUMENT_TYPE,
} from '../../../../CorporateAccount/const/RiskProfile'
import { RiskProfileEddDocument } from '../../../interfaces/riskProfile'

interface RiskProfileEddDocumentItemProps {
  type: RISK_PROFILE_EDD_DOCUMENT_TYPE
  documentsByType: RiskProfileEddDocument[]
  accountId: string
  addNewRiskProfileEddDocument: (
    document: RiskProfileEddDocument,
  ) => Promise<void>
  handleViewDocuments: (documentType: string) => Promise<void>
}

const RiskProfileEddDocumentItem: FC<RiskProfileEddDocumentItemProps> = ({
  type,
  documentsByType,
  handleViewDocuments,
  accountId,
  addNewRiskProfileEddDocument,
}) => {
  const [showUploader, setShowUploader] = useState(false)
  const [uploadProgress, setUploadProgress] = useState({})
  const toggleShowUploader = () => setShowUploader((prev) => !prev)
  const [isUploadingDocument, setIsUploadingDocument] = useState(false)
  const getTotalUploadProgress = () => {
    const totalFilesCount = Object.keys(uploadProgress).length
    if (totalFilesCount === 0) return 0
    return (
      (Object.values(uploadProgress).reduce(
        (acc: number, curr: number) => acc + curr,
        0,
      ) as number) / totalFilesCount
    )
  }
  const handleUploadDocument = useCallback(async (files: File[]) => {
    try {
      setIsUploadingDocument(true)
      await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData()

          formData.append('selectedFile', file)
          formData.append('accountId', accountId)
          formData.append('documentType', type.documentType)

          const { data } = await getAxios().post(
            '/risk-based-approach/profile/edd-documents',
            formData,
            {
              onUploadProgress: (uploadingData) => {
                if (uploadingData && uploadingData.total) {
                  const progress = Math.round(
                    (100 * uploadingData.loaded) / uploadingData.total,
                  )
                  setUploadProgress((prev) => ({ ...prev, [index]: progress }))
                }
              },
            },
          )
          addNewRiskProfileEddDocument(data)
        }),
      )
      toast(`Document${renderPlural(files.length)} uploaded successfully`, {
        type: toast.TYPE.SUCCESS,
      })

      setUploadProgress({})
      setShowUploader(false)
    } catch (error) {
      ErrorToast(error)
    }
    setIsUploadingDocument(false)
  }, [])

  return (
    <RiskProfileEddDocumentItemWrapper>
      <Title type="h4" text={type.documentLabel} />
      <RiskProfileGridItem>
        {documentsByType.length > 0 && (
          <IconButton onClick={() => handleViewDocuments(type.documentType)}>
            <Visibility />
          </IconButton>
        )}
      </RiskProfileGridItem>
      <RiskProfileGridItem>
        <MuiButton onClick={toggleShowUploader}>add documents</MuiButton>
      </RiskProfileGridItem>
      <SimpleModal
        width="30%"
        handleClose={toggleShowUploader}
        open={showUploader}
        buttonsHidden
      >
        <RiskProfileUploaderContainer>
          <p>{type.documentLabel}</p>
          <FilesUploader
            width={''}
            isUploading={isUploadingDocument}
            clearFile={false}
            acceptedFileTypes={ALLOWED_RISK_PROFILE_EDD_DOCUMENT_TYPES}
            manual
            handleUpload={handleUploadDocument}
            height={100}
            uploadButtonText="ADD MORE"
            showImageNumbers
          />
        </RiskProfileUploaderContainer>
        {Object.keys(uploadProgress).length > 0 && (
          <LinearProgress
            variant="determinate"
            value={getTotalUploadProgress()}
          />
        )}
      </SimpleModal>
    </RiskProfileEddDocumentItemWrapper>
  )
}
const actions = {
  addNewRiskProfileEddDocument,
}

export default connectStateAndDispatch(
  null,
  actions,
  RiskProfileEddDocumentItem,
)
