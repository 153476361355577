
import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = (props) => {
  const { expandItem } = props
  //this will change based on what ever field we are sending through from the server
  return (
    <TableHeader onClick={expandItem}>
      <TableCell width={'7%'}>Account Id</TableCell>
      <TableCell width={'15%'}>New Customer Type</TableCell>
      <TableCell width={'15%'}>Created At</TableCell>
      <TableCell width={'20%'}>Reason</TableCell>
      <TableCell width={'10%'}/>
    </TableHeader>
  )
}
