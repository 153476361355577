import React, { Component, Fragment } from 'react'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.kycInfoDualAuth.id)
  }

  render() {
    const {
      kycInfoDualAuth,
      isSelected,
    } = this.props
    const {
      id,
      accountId,
      reason,
      newKYCLevelId,
      createdAt,
      active,
    } = kycInfoDualAuth
    const shading = isSelected ? 'white' : ''

    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => this.toggleForm()}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'9%'}>{newKYCLevelId}</TableCell>
          <TableCell width={'12%'}>{createdAt}</TableCell>
          <TableCell width={'20%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {
          isSelected && (
            <FormPendingDualAuthorisation
              initialValues={{
                id,
                actionType: kycInfoDualAuth.actionType,
                accountId,
                flag: false,
                dualAuthStatus: 'pending',
              }}
              form={`PendingDualAuthorisationForm-${id}`}
              showActiveUser={this.props.showActiveUser}
              removeActiveUser={this.props.removeActiveUser}
              onCancel={this.toggleForm}
              onSubmitSuccess={this.toggleForm}
              rawData={kycInfoDualAuth}
              active={active}
            />
          )
        }
      </Fragment >
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'KYC_OVERRIDE_EVENT')
