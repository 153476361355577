export default {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  keycloak: {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_SERVER_URL,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    callbackUrl: process.env.REACT_APP_KEYCLOAK_CALLBACK_URL,
    applicationRolesClientId:
      process.env.REACT_APP_APPLICATION_ROLES_CLIENT_ID || 'valr-admin',
  },
  depositsAccountNumbers: {
    fnb: process.env.REACT_APP_FNB_ACCOUNT_NUMBER,
    stb: process.env.REACT_APP_STANDARD_BANK_ACCOUNT_NUMBER,
    stanbicZambia: process.env.REACT_APP_STANBIC_ZAMBIA_ACCOUNT_NUMBER,
  },
  isProduction: !(
    !process.env.REACT_APP_NODE_ENV ||
    process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'test'
  ),
  zendeskSupportUrl: process.env.REACT_APP_ZENDESK_URL,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
  veriff: {
    veriffExternalUrl:
      process.env.REACT_APP_VERIFF_URL || 'https://station.veriff.com',
  },
  logoutCallbackUri: `${window.location.origin}/login`,
  changeCustomerTypeByDualAuth:
    process.env.REACT_APP_CHANGE_CUSTOMER_TYPE_BY_DUAL_AUTH || false,
  enabledWithdrawalCountries: process.env.REACT_APP_ENABLED_WITHDRAWAL_COUNTRIES
    ? process.env.REACT_APP_ENABLED_WITHDRAWAL_COUNTRIES.split(',')
    : ['ZA'],
  validNotificationUrlDomain: process.env.REACT_APP_VALID_NOTIFICATION_URL || 'valr.com'
}
