import config from '../config'

const rxpValidName = /^[\x20-\x7E]+$/
const rxpEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const rxpNumbersAndLetters = /^[a-zA-Z0-9 -]+$/

export const required = value =>
  value && value.toString().trim().length > 0 ? undefined : 'Required'

export const requiredDropdownValue = item =>
  item || (item && item.value) ? undefined : 'Required'
export const requiredSelectDropdownValue = item =>
  item ? undefined : 'Required'

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

export const maxLengthWarning = max => value =>
  value && value.length >= max
    ? `You've reached the maximum symbols of ${max} characters`
    : undefined

export const maxLength20 = maxLength(20)
export const maxLength3 = maxLength(3)

export const noSpacesAllowed = value =>
  value && value.toString().indexOf(' ') >= 0
    ? 'Spaces are not allowed'
    : undefined

export const maxLength32 = maxLength(32)
export const maxLength64 = maxLength(64)
export const maxLength128 = maxLength(128)
export const maxLength256 = maxLength(256)
export const maxLengthWarning256 = maxLengthWarning(256)

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : ''
export const minLength11 = minLength(11)
export const minLength3 = minLength(3)

export const lessThan = limit => value =>
  value && value > limit ? `Must be less than ${limit}` : undefined

export const isNumber = value =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined

export const validName = value =>
  value && !value.match(rxpValidName) ? 'Please enter valid name' : undefined

export const validFieldName = value =>
  value && !value.match(rxpNumbersAndLetters)
    ? 'Field may only contain numbers, letters, dashes and spaces'
    : undefined

export const isValidEmail = value =>
  value && !value.match(rxpEmail) ? 'Please enter valid email' : undefined

export const isValidValrUrl = value => {
  if (!value || value === '') return undefined
  let validDomainRegex = new RegExp(
    '^https://([A-Za-z0-9-]+.)?' +
    config.validNotificationUrlDomain.replace(/(^\w+:|^)\/\//, '') +
    '(?!\\.).*',
  )
  if (
    value.startsWith('https://') &&
    value.match(validDomainRegex) &&
    value.match(validDomainRegex).length > 0
  ) {
    return undefined
  } else {
    return 'Invalid URL'
  }
}

export const moreThanZero = value =>
  value && value <= 0 ? 'Value must not be negative' : undefined
