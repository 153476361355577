import React, { FC } from 'react'
import { InfoBlock } from 'components/atoms/Details'
import { Title } from 'components/atoms/Title'
import { ContentContainer, Spacer } from 'components/atoms/Generic'
import {
  FoundSanctionLegalEntity,
  FoundSanctionNaturalEntity,
  MatchedTo,
} from 'interfaces/sanctions'
import FoundNaturalEntitySanctions from './FoundNaturalEntitySanctions'
import FoundLegalEntitySanctions from './FoundLegalEntitySanctions'
import MatchedToData from './MatchedToData'
import {
  StyledMatchedToSanctionsListHeading,
  StyledMatchedToSanctionsListWrapper,
} from '../styles'

interface SanctionedCorporateReasonProps {
  corporateNameMatchResult: string
  foundNaturalEntities: FoundSanctionNaturalEntity[]
  foundLegalEntities: FoundSanctionLegalEntity[]
  corporateMatchedToList: MatchedTo[]
}

const SanctionedCorporateReasons: FC<SanctionedCorporateReasonProps> = ({
  corporateNameMatchResult,
  foundNaturalEntities,
  foundLegalEntities,
  corporateMatchedToList,
}) => (
  <>
    <Title type={'h3'} text="Reasons For Corporate Being Sanctioned" />
    <InfoBlock
      label="Corporate Name Match"
      value={corporateNameMatchResult || 'Name is in the clear'}
    />
    {corporateNameMatchResult && (
      <StyledMatchedToSanctionsListWrapper>
        <StyledMatchedToSanctionsListHeading>
          <Title
            type={'h3'}
            text={`Sanctions Items Match To ${
              corporateMatchedToList.length
            } Sanctions Items`}
          />
        </StyledMatchedToSanctionsListHeading>
        {corporateMatchedToList.map((sanctionItem, i) => (
          <MatchedToData key={i} matchedTo={sanctionItem} />
        ))}
      </StyledMatchedToSanctionsListWrapper>
    )}
    <Spacer margin="24px 0px" />
    <ContentContainer>
      <FoundNaturalEntitySanctions
        foundNaturalEntities={foundNaturalEntities}
      />
    </ContentContainer>
    <ContentContainer>
      <FoundLegalEntitySanctions foundLegalEntities={foundLegalEntities} />
    </ContentContainer>
  </>
)

export default SanctionedCorporateReasons
