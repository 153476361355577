import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { fetchConfig } from 'redux/applicationConfig/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { hasApplicationConfigEditor } from 'helpers/roleBasedAccess'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { toast } from 'react-toastify'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { getFiatConfig } from '../../redux/fiat/selectors'
import { setFiatCurrencyFilter } from '../../redux/fiat/actions'
import {
  calculateChanges, getChangedConfigValue, getConfigValue, saveCurrencyConfig,
} from './helpers'
import { ConfirmModal } from './ConfirmModal'
import DynamicConfig from './DynamicConfig'

class DynamicCurrency extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currencyState: [],
      confirm: false,
      changes: [],
    }
  }

  componentDidMount() {
    this.props.fetchConfig('currency', this.props.selectedCurrency)
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props.currencyConfig
    if (prevProps.currencyConfig.data.length !== data.length) {
      const applicationNewState =
        data.map(application => {
          const value = getConfigValue(application.type, application.value)
          return { envName: application.name, value, type: application.type }
        })
      this.setState({ currencyState: applicationNewState })
    }
  }

  showCurrencies = () => this.props.currencies.map(currency => (
    <MenuItem key={currency.shortName} value={currency.shortName}>
      {currency.longName}
    </MenuItem>
  ))

  changeCurrency = newCurrency => {
    this.props.setFiatCurrencyFilter(newCurrency)
    this.props.fetchConfig('currency', newCurrency)
  }

  addToList = key => {
    const newItemAdded = this.state.currencyState.map(newState => {
      if (newState.envName === key) {
        newState.value.push(window[`${key}Ref`].current.value.toUpperCase())
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ currencyState: newItemAdded })
  }

  addToMap = key => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const newItemAdded = this.state.currencyState.map(newState => {
      if (newState.envName === key) {
        const value = window[`${key}Ref`].current.value.toUpperCase().split(':')
        const newKey = value[0]
        const found = newState.value.find(v => v[newKey] != null)
        if (found) {
          toast.error(`Key already exists. ${newKey} : ${found[newKey]}`)
          return newState
        }
        newState.value.push({ [newKey]: value[1] || '' })
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ currencyState: newItemAdded })
  }

  onChange = (type, name, value, mappedKey) => {
    const newApplicationState = this.state.currencyState.map(newState => {
      if (newState.envName === name) {
        newState.value = getChangedConfigValue(type, value, newState.value, mappedKey)
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ currencyState: newApplicationState })
  }

  handleRemove = (index, name) => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const newItemAdded = this.state.currencyState.map(newState => {
      if (newState.envName === name) {
        newState.value = newState.value.filter((todo, i) => index !== i)
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ currencyState: newItemAdded })
  }

  confirmChanges = async () => {
    this.setState({ confirm: true })
    const changes = calculateChanges(this.state.currencyState)
    this.setState({ changes })
  }

  save = async () => {
    const system = 'currency'
    await saveCurrencyConfig(this.state.changes, system, this.props.selectedCurrency)
    this.props.fetchConfig(system, this.props.selectedCurrency)
  }

  renderButton() {
    return (
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <MuiButton
          onClick={this.confirmChanges}
          disabled={!this.state.currencyState?.some(_ => _.configChanged)}
        >
          Review and save
        </MuiButton>
      </div>
    )
  }

  render() {
    const { status } = this.props.currencyConfig
    const { selectedCurrency } = this.props

    return (
      <ErrorBoundary message="Dynamic Currency">
        <div>
          <Select
            id="currency-select"
            value={selectedCurrency}
            onChange={({ target: { value } }) => {
              this.changeCurrency(value)
            }}
          >
            {this.showCurrencies()}
          </Select>
        </div>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && (
          <div>
            {this.renderButton()}
            <DynamicConfig
              items={this.state.currencyState}
              onChange={this.onChange}
              onListAdd={this.addToList}
              onRemove={this.handleRemove}
              onMapAdd={this.addToMap}
            />
            {this.renderButton()}
          </div>
        )}
        <ConfirmModal
          open={this.state.confirm}
          handleClose={() => this.setState({ confirm: false })}
          proceed={this.save}
          dataToConfirm={this.state.changes}
          initialData={this.props.currencyConfig.data}
        />
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  currencyConfig: state.applicationConfig.application,
  selectedCurrency: state.fiat.filters.currency,
  currencies: getFiatConfig(state).currencies,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchConfig,
      setFiatCurrencyFilter,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicCurrency)
