import {
  CorporateRiskProfileDualAuth,
  RiskProfileDualAuth,
} from 'interfaces/riskProfile'

import {
  RiskBasedApproachActionTypes,
  RiskProfileDualAuthResponse,
} from './actions'

export interface RiskBasedProfilesState {
  individualRiskProfileDualAuths: {
    pending: RiskProfileDualAuth[]
    pendingMultiAuth: RiskProfileDualAuth[]
  }
  corporateRiskProfileDualAuths: {
    pending: CorporateRiskProfileDualAuth[]
    pendingMultiAuth: CorporateRiskProfileDualAuth[]
  }
  status: 'idle' | 'pending' | 'done'
}
interface Action {
  type: RiskBasedApproachActionTypes
  payload: {
    data:
      | RiskProfileDualAuthResponse
      | RiskProfileDualAuth
      | CorporateRiskProfileDualAuth
    type: string
  }
}

const initialState: RiskBasedProfilesState = {
  individualRiskProfileDualAuths: {
    pending: [],
    pendingMultiAuth: [],
  },
  corporateRiskProfileDualAuths: {
    pending: [],
    pendingMultiAuth: [],
  },
  status: 'idle',
}

export default (
  state = initialState,
  action: Action,
): RiskBasedProfilesState => {
  switch (action.type) {
    case 'FETCH_RISK_PROFILES_DUAL_AUTH_START': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_RISK_PROFILES_DUAL_AUTH_SUCCESS': {
      const { individualRiskProfileDualAuths, corporateRiskProfileDualAuths } =
        action.payload.data as RiskProfileDualAuthResponse

      return {
        ...state,
        individualRiskProfileDualAuths,
        corporateRiskProfileDualAuths,
        status: 'done',
      }
    }
    case 'UPDATE_RISK_PROFILES_DUAL_AUTH_SUCCESS': {
      const updatedState = {
        ...state,
      }
      const data = action.payload.data as RiskProfileDualAuth
      if (data.multiAuth) {
        if (data.status === 'APPROVED' || data.status === 'DECLINED') {
          updatedState.individualRiskProfileDualAuths.pendingMultiAuth =
            updatedState.individualRiskProfileDualAuths.pendingMultiAuth.filter(
              (multiAuth) => multiAuth.id !== data.id,
            )
        } else {
          const isPendingDualAuth =
            updatedState.individualRiskProfileDualAuths.pending.find(
              (dualAuth) => dualAuth.id === data.id,
            )
          if (isPendingDualAuth) {
            updatedState.individualRiskProfileDualAuths.pending =
              updatedState.individualRiskProfileDualAuths.pending.filter(
                (dualAuth) => dualAuth.id !== data.id,
              )
            updatedState.individualRiskProfileDualAuths.pendingMultiAuth.push(
              data,
            )
          } else {
            const multiAuthIndex =
              updatedState.individualRiskProfileDualAuths.pendingMultiAuth.findIndex(
                (multiAuth) => multiAuth.id === data.id,
              )
            if (multiAuthIndex > -1) {
              updatedState.individualRiskProfileDualAuths.pendingMultiAuth[
                multiAuthIndex
              ] = data
            }
          }
        }
      } else if (data.status === 'APPROVED' || data.status === 'DECLINED') {
        updatedState.individualRiskProfileDualAuths.pending =
          updatedState.individualRiskProfileDualAuths.pending.filter(
            (multiAuth) => multiAuth.id !== data.id,
          )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }

    case 'UPDATE_CORPORATE_RISK_PROFILES_DUAL_AUTH_SUCCESS': {
      const updatedState = {
        ...state,
      }
      const data = action.payload.data as CorporateRiskProfileDualAuth
      if (data.multiAuth) {
        if (data.status === 'APPROVED' || data.status === 'DECLINED') {
          updatedState.corporateRiskProfileDualAuths.pendingMultiAuth =
            updatedState.corporateRiskProfileDualAuths.pendingMultiAuth.filter(
              (multiAuth) => multiAuth.id !== data.id,
            )
        } else {
          const isPendingDualAuth =
            updatedState.corporateRiskProfileDualAuths.pending.find(
              (dualAuth) => dualAuth.id === data.id,
            )
          if (isPendingDualAuth) {
            updatedState.corporateRiskProfileDualAuths.pending =
              updatedState.corporateRiskProfileDualAuths.pending.filter(
                (dualAuth) => dualAuth.id !== data.id,
              )
            updatedState.corporateRiskProfileDualAuths.pendingMultiAuth.push(
              data,
            )
          } else {
            const multiAuthIndex =
              updatedState.corporateRiskProfileDualAuths.pendingMultiAuth.findIndex(
                (multiAuth) => multiAuth.id === data.id,
              )
            if (multiAuthIndex > -1) {
              updatedState.corporateRiskProfileDualAuths.pendingMultiAuth[
                multiAuthIndex
              ] = data
            }
          }
        }
      } else if (data.status === 'APPROVED' || data.status === 'DECLINED') {
        updatedState.corporateRiskProfileDualAuths.pending =
          updatedState.corporateRiskProfileDualAuths.pending.filter(
            (multiAuth) => multiAuth.id !== data.id,
          )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }
    case 'CLEAR_PENDING_DUAL_AUTHS': {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
