import { useEffect, useState } from 'react'

const useTradeFees = (tradeFees, getTradeFees) => {
  const [formShown, setFormShown] = useState(false)
  const [formData, setFormData] = useState({})
  const [isDefaultTradeFeesForm, setIsDefaultTradeFeesForm] = useState(false)
  const { feesPerCurrencyPair, loading, ...defaultTradeFees } = tradeFees
  const toggleForm = (updatedFormData, defaultTradeFeesForm) => {
    setIsDefaultTradeFeesForm(defaultTradeFeesForm)
    setFormShown(!formShown)
    setFormData(updatedFormData)
  }

  useEffect(() => {
    getTradeFees()
  }, [])

  return {
    defaultTradeFees,
    feesPerCurrencyPair,
    formShown,
    formData,
    isDefaultTradeFeesForm,
    loading,
    toggleForm,
  }
}

export default useTradeFees
