import {
  FETCH_WATCHLIST_CHECK_PROCESS,
  FETCH_WATCHLIST_CHECK_SUCCESS,
  FETCH_WATCHLIST_CHECK_ERROR,
} from './actions'

const initialState = {
  byId: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WATCHLIST_CHECK_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'loading',
            data: [],
          },
        },
      }
    }

    case FETCH_WATCHLIST_CHECK_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            data: [],
            error,
          },
        },
      }
    }

    case FETCH_WATCHLIST_CHECK_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data,
            error: null,
          },
        },
      }
    }

    default:
      return state
  }
}
