const validate = values => {
  const errors = {}

  if (!values.newName.trim()) errors.newName = 'Name is required'
  if (!values.newSurname.trim()) errors.newSurname = 'Surname is required'
  if (!values.reason.trim()) errors.reason = 'Comment is required'

  return errors
}

export default validate
