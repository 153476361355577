import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS = 'FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVE_SUCCESS'
export const FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_ERROR = 'FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVE_ERROR'

export const UPDATE_NOTES_FOR_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS = 'UPDATE_NOTES_FOR_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS'

export const fetchUnassignedBlockchainReceives = () => async dispatch => {
  let res = null
  try {
    res = await getAxios().get('/account/blockchain/receive/unassigned')
    const { data } = res
    dispatch({
      type: FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateNotesForBlockchainReceives = id => async dispatch => {
  try {
    dispatch({
      type: UPDATE_NOTES_FOR_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS,
      payload: {
        id,
      },
    })
  } catch (error) {
    ErrorToast(error)
  }
}
