import React, { Fragment } from 'react'
import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
} from 'components/atoms/Details'
import {
  Actions,
  ActionButton,
  ActionDivider,
} from 'components/atoms/Table/styles'
import { hasExchangeEditorRole } from 'helpers/roleBasedAccess'
import { Flexbox } from 'components/layout/index'

const DefaultTradeFeeDetails = ({
  formShown,
  tradeFees,
  getTradeFees,
  toggleForm,
}) => {
  const {
    makerFee,
    takerFee,
    valrInstantOrderFee,
    bittrexInstantOrderFee,
    bittrexFee,
  } = tradeFees
  return (
    <Fragment>
      {!formShown && (
        <Details>
          <DetailsBlockWithTitle title="Trade Fees">
            <InfoBlock label="Maker Fee" value={`${makerFee}%`} />
            <InfoBlock label="Taker Fee" value={`${takerFee}%`} />
            <InfoBlock
              label="VALR Instant Order Fee"
              value={`${valrInstantOrderFee}%`}
            />
            <InfoBlock
              label="Bittrex Instant Order Fee"
              value={`${bittrexInstantOrderFee}%`}
            />
            <InfoBlock label="Bittrex Fee" value={`${bittrexFee}%`} />
          </DetailsBlockWithTitle>
        </Details>
      )}
      {hasExchangeEditorRole() && (
        <Flexbox direction={'row'} align={'flex-start'}>
          <Actions>
            {!formShown && (
              <ActionButton marginRight={0} onClick={getTradeFees}>
                Refresh
              </ActionButton>
            )}
            <ActionDivider />
            {!formShown && (
              <ActionButton onClick={() => toggleForm(tradeFees, true)}>Update</ActionButton>
            )}
          </Actions>
        </Flexbox>
      )}
    </Fragment>
  )
}

export default DefaultTradeFeeDetails
