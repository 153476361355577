import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import { updateCurrencyPairLimits } from 'redux/currencyPairs/actions'

export const submit = async (values, dispatch) => {
  const {
    currencyPairSymbol, minBase, maxBase, minQuote,
    maxQuote, tickSize, baseDecimalPlaces,
  } = values
  try {
    const res = await getAxios().put('/update/currency/pairs/limits', {
      currencyPairSymbol,
      minBase,
      maxBase,
      minQuote,
      maxQuote,
      tickSize,
      baseDecimalPlaces,
    })
    if (res.data.statusCode === 202) {
      toast('Currency Pair Limits updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
    } else {
      toast(res.data.message, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
    const { data } = res

    dispatch(updateCurrencyPairLimits(data.data))

    dispatch(values.formClose)
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
