import {
  FETCH_ACCOUNT_SENDS_PROCESS,
  FETCH_ACCOUNT_SENDS_SUCCESS,
  FETCH_ACCOUNT_SENDS_ERROR,
  CLEAR_ACCOUNT_SENDS,
} from './actions'

const initialState = {
  byId: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SENDS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'loading',
          },
        },
      }
    }

    case FETCH_ACCOUNT_SENDS_SUCCESS: {
      const {
        fetchMoreData, id, data, hasMoreRecords, skip, limit,
      } = action.payload
      const existingData = state.byId[id].data ? state.byId[id].data : []
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data: fetchMoreData ? [...existingData, ...data] : data,
            hasMoreRecords,
            skip,
            limit,
          },
        },
      }
    }

    case FETCH_ACCOUNT_SENDS_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            data: [],
            error,
          },
        },
      }
    }

    case CLEAR_ACCOUNT_SENDS: {
      return initialState
    }

    default:
      return state
  }
}
