import React, { useState } from 'react'
import { TableBody } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { TablePagination } from '@material-ui/core'
import { Headers } from './headers'
import Row from './row'

export const RejectedDualAuthTable = props => {
  const {
    formShown,
    expandItem,
    data,
    showRawData,
    allDeposits,
    expandRawData,
    addNoteFormShown,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    tablePaginationCount
  } = props
  const { checkIfIsPending, toggleForm, toggleAddNotesForm } = props
  const [highlightedId, setHighlightedId] = useState('')
  const hasDeposits = data.length > 0

  return (
    <>
      {!hasDeposits && (
        <NoTableResultsMessage messageType={NO_RESULT_MESSAGE.DEPOSITS.type} />
      )}
      {hasDeposits && (
        <>
          <Headers expandItem={expandItem} />
          <TableBody>
            {data.map(deposit => {
              deposit.dateTime = moment(deposit.dateTime).format('YYYY-MMM-DD')
              deposit.insertedAt = moment(deposit.insertedAt).format(
                'YYYY-MMM-DD HH:mm:ss',
              )
              return (
                <Row
                  key={deposit.depositId}
                  deposit={deposit}
                  allDeposits={allDeposits}
                  depositId={deposit.depositId}
                  isPending={checkIfIsPending(deposit)}
                  toggleForm={() => toggleForm(deposit.depositId)}
                  formShown={formShown === deposit.depositId}
                  showRawData={showRawData}
                  toggleAddNotesForm={toggleAddNotesForm}
                  expandRawData={expandRawData}
                  setHighlightedId={setHighlightedId}
                  isHighlighted={highlightedId === deposit.depositId}
                  addNoteFormShown={addNoteFormShown === deposit.depositId}
                />
              )
            })}
            <TablePagination
              component="div"
              count={tablePaginationCount}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />

          </TableBody>
        </>
      )}
    </>
  )
}
