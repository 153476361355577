import { TableBody, TableCell } from '@material-ui/core'
import { StyledTableRow } from 'components/atoms/Generic'
import { MobileNotificationStatuses } from 'interfaces/mobileNotifications'
import React, { FC } from 'react'
import { connectStateAndDispatch } from 'store'
interface MobileCampaignProps {
  id: number
  name: string
  title: string
  message: string
  status: MobileNotificationStatuses
  userCount: number
  usersSentTo: number
  batches: number
  notificationType: 'PUSH' | 'SMS'
  createdAt: string
  canResubmit: React.ReactElement
}
const MobileCampaign: FC<MobileCampaignProps> = ({
  id,
  name,
  title,
  message,
  status,
  userCount,
  usersSentTo,
  batches,
  notificationType,
  createdAt,
  canResubmit,
}) => {
  return (
    <TableBody>
      <StyledTableRow>
        <TableCell>{name}</TableCell>
        <TableCell>{title}</TableCell>
        <TableCell>{message}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>{userCount}</TableCell>
        <TableCell>{usersSentTo}</TableCell>
        <TableCell>{batches}</TableCell>
        <TableCell>{notificationType || 'PUSH'}</TableCell>
        <TableCell>{createdAt}</TableCell>
        <TableCell>{canResubmit}</TableCell>
      </StyledTableRow>
    </TableBody>
  )
}

const actions = {}

export default connectStateAndDispatch(null, actions, MobileCampaign)
