import { FETCH_MANUAL_DOCUMENTS_PROCCESS, FETCH_MANUAL_DOCUMENTS_SUCCESS, 
  UPDATE_MANUAL_KYC,UPDATE_MANUAL_KYC_ERROR,
  FETCH_MANUAL_DOCUMENTS_ERROR,APPROVE_KYC_ATTEMPT,REJECT_KYC_ATTEMPT} from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANUAL_DOCUMENTS_PROCCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_MANUAL_DOCUMENTS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }
    case APPROVE_KYC_ATTEMPT: {
      const { approvedKycAttempt } = action.payload
      const filteredArray = state.data.filter(item => item.identifier !== approvedKycAttempt)
      return {
        ...state,
        data: filteredArray,
      }
    }
    case FETCH_MANUAL_DOCUMENTS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }
    case UPDATE_MANUAL_KYC: {
      const { data } = action.payload
      const updatedDeposits =
        state &&
        state.data &&
        state.data.map(manualKycData => {
          if (data && data.length > 0) {
            data.some(deposit => {
              if (deposit.id === manualKycData.identifier) {
                manualKycData.active = {
                  activePersonName: deposit.userName,
                  userActive: true,
                }
                return true
              } else {
                manualKycData.active = { activePersonName: '', userActive: false }
              }
            })
          } else {
            manualKycData.active = { activePersonName: '', userActive: false }
          }
          return manualKycData
        })
      return {
        ...state,
        suspendedDeposits: { status: 'done', data: updatedDeposits },
      }
    }

    case UPDATE_MANUAL_KYC_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        status: 'error',
        error,
      }
    }

    case REJECT_KYC_ATTEMPT: {
      const { rejectedKycAttempt } = action.payload
      const filteredArray = state.data.filter(item => item.identifier !== rejectedKycAttempt)
      return {
        ...state,
        data: filteredArray,
      }
    }

    default:
      return state
  }
}
