import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import React from 'react'
import { BASE } from 'theme/base-units'
import {
  Button, FormContainer, FormError, FormTitle, InputGroup,
} from '../../components/atoms/Form'
import { renderInput } from '../fields'
import { submit } from './submit'

let Form = props => {
  const {
    submitting, error, invalid, handleSubmit, onCancel, onSubmit, errors,
  } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Create Virtual Account</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Pan Number'}
          name={'panNumber'}
          width={'100%'}
          margin={`0 ${BASE * 2}px 0 0`}
          component={renderInput}
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Bank Account UUID'}
          name={'bankAccountId'}
          width={'100%'}
          component={renderInput}
          fullWidth
        />
      </InputGroup>
      {error && <FormError error={error} />}
      {errors && errors.map(e => <p style={{ color: 'red' }}>{e}</p>)}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

const mapStateToProps = state => ({
  formData: state.form,
})

Form = reduxForm({
  onSubmit: submit,
})(Form)
const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
