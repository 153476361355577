import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { Dispatch } from 'redux'
import { StakingProps } from 'containers/Accounts/SingleAccount/interfaces'

export type StakingAction =
  | 'FETCH_ACCOUNT_STAKING_START'
  | 'FETCH_ACCOUNT_STAKING_SUCCESS'
  | 'FETCH_ACCOUNT_STAKING_ERROR'
  | 'CLEAR_ACCOUNT_STAKING'

interface FetchAccountPendingMultiAuthsDispatch {
  type: StakingAction
   payload?: {data:StakingProps[]}
}

export const fetchAccountStaking = (id: string) => async (
  dispatch: Dispatch<FetchAccountPendingMultiAuthsDispatch>,
) => {
  try {
    dispatch({
      type: 'FETCH_ACCOUNT_STAKING_START',
    })
    const { data } = await getAxios().get(`/staking/${id}`)
    dispatch({
      type: 'FETCH_ACCOUNT_STAKING_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_ACCOUNT_STAKING_ERROR',
    })
  }
}

export const clearAccountStakingState = () => async (
  dispatch: Dispatch<FetchAccountPendingMultiAuthsDispatch>,
) => {
  dispatch({
    type: 'CLEAR_ACCOUNT_STAKING',
  })
}
