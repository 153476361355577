import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

import getAxios from '../../../helpers/axios'

interface IProps {
  match: {
    params: {
      sessionId: string
    }
  }
}

export function GetAccountByKycSession(props: IProps) {
  const [accountId, setAccountId] = useState<string>()

  async function lookupAccountIdByKycSession() {
    const { params: { sessionId } } = props.match
    if (sessionId) {
      try {
        const axios = getAxios()
        const url = `/veriff/session-account-info/${sessionId}`
        const response = await axios.get<{ accountId: string }>(url)
        const { accountId: foundAccountId } = response.data
        setAccountId(foundAccountId)
      } catch (e) {
        toast.error('Error fetching kyc session account info')
      }
    }
  }

  useEffect(() => {
    lookupAccountIdByKycSession()
  }, [])

  if (!accountId) {
    return null
  }

  return <Redirect to={`/accounts/byId/${accountId}/details/account-risk-control`}/>
}
