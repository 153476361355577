import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  removeFromHiddenSanctionsList,
  fetchPotentialUsersInSanctionsList,
} from 'redux/sanctions/actions'
import RemovedAccounts from './RemovedAccounts'

const mapStateToProps = state => ({
  potentialUserInSanctions: state.potentialUserInSanctions,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeFromHiddenSanctionsList,
      fetchPotentialUsersInSanctionsList,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(RemovedAccounts)
