import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS } from '../SearchAccountEmails/submit'

export const submit = async (values, dispatch) => {
  const { email } = values
  let res = null
  try {
    res = await getAxios().delete(
      `/account/global-unsubscribes/remove/${email}`,
    )
    toast(res.data.message, { type: toast.TYPE.SUCCESS, autoClose: 2500 })
    const results = await getAxios().get(`/accounts/emails-activity/${email}`)
    dispatch({
      type: FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS,
      data: results && results.data,
      currentEmail: email,
    })
    return res.data
  } catch (e) {
    toast('Something went wrong', {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
