import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const Search = styled.div`
  width: 90%;
  position: center;
  display: flex;
  margin: 5%;
`

export const SearchButton = styled(Button).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  height: 48px;
  width: 48px;
  min-width: 48px;
  background:  #F9F9F9;
  cursor:  ${props => !props.disabled ? 'pointer' : 'default'};
  fontSize:  20px;
  margin-left: 2px;
  padding: 0;
`
