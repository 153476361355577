import {
  FETCH_POTENTIAL_USERS_IN_SANCTIONS_PROCESS,
  FETCH_POTENTIAL_USERS_IN_SANCTIONS_SUCCESS,
  FETCH_POTENTIAL_USERS_IN_SANCTIONS_ERROR,
  UPDATE_SANCTION_DATA_START,
  UPDATE_SANCTION_DATA_SUCCESS,
  UPDATE_SANCTION_DATA_ERROR,
  CHECK_LOCAL_SANCTIONS_PROCESS_START,
  CHECK_LOCAL_SANCTIONS_PROCESS_SUCCESS,
  CHECK_LOCAL_SANCTIONS_PROCESS_ERROR,
  TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS,
  FETCH_CORPORATE_SANCTIONS_START,
  FETCH_CORPORATE_SANCTIONS_SUCCESS,
  FETCH_CORPORATE_SANCTIONS_ERROR,
  CHECK_OUT_CORPORATE_SANCTION_START,
  CHECK_OUT_CORPORATE_SANCTION_SUCCESS,
  CHECK_OUT_CORPORATE_SANCTION_ERROR,
  CHECK_CORPORATE_SANCTIONS_PROCESS_START,
  CHECK_CORPORATE_SANCTIONS_PROCESS_SUCCESS,
  CHECK_CORPORATE_SANCTIONS_PROCESS_ERROR,
  TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS,
  UPDATE_CORPORATE_SANCTIONS_LIST,
  UPDATE_BULK_SANCTION_DATA_START,
  UPDATE_BULK_SANCTION_DATA_SUCCESS,
  UPDATE_BULK_SANCTION_DATA_ERROR,
  FETCH_SANCTIONS_RESET_SUCCESS,
  FETCH_SANCTIONS_RESET_PROCESS,
  FETCH_SANCTIONS_RESET_ERROR,
  UPDATE_SANCTIONS_RESET_SUCCESS,
  UPDATE_SANCTIONS_RESET_ERROR,
} from './actions'

const initialState = {
  potentialUserInSanctions: {
    updating: false,
    status: 'idle',
    data: [],
  },
  localSanctionsProcessData: {
    status: false,
    loading: false,
  },
  corporateSanctions: {
    data: [],
    isFetching: false,
    isUpdating: false,
    isCheckingProcess: false,
    processRunning: false,
  },
  resetStatus: {
    status: 'pristine',
    data: {},
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POTENTIAL_USERS_IN_SANCTIONS_PROCESS: {
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          status: 'loading',
        },
      }
    }

    case UPDATE_SANCTION_DATA_START:
    case UPDATE_BULK_SANCTION_DATA_START:
    {
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          updating: true,
        },
      }
    }

    case FETCH_POTENTIAL_USERS_IN_SANCTIONS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          data,
          status: 'done',
        },
      }
    }

    case FETCH_SANCTIONS_RESET_PROCESS: {
      return {
        ...state,
        resetStatus: {
          ...state.resetStatus,
          status: 'loading',
        },
      }
    }

    case FETCH_SANCTIONS_RESET_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        resetStatus: {
          ...state.resetStatus,
          data,
          status: 'done',
        },
      }
    }

    case FETCH_SANCTIONS_RESET_ERROR: {
      return {
        ...state,
        resetStatus: {
          ...state.resetStatus,
          status: 'pristine',
        },
      }
    }

    case UPDATE_SANCTIONS_RESET_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        resetStatus: {
          ...state.resetStatus,
          data,
          status: 'done',
        },
      }
    }

    case UPDATE_SANCTIONS_RESET_ERROR:{
      return {
        ...state,
        resetStatus: {
          ...state.resetStatus,
          status: 'pristine',
        },
      }
    }

   

    case UPDATE_SANCTION_DATA_SUCCESS: {
      const { data, searchType } = action.payload
      const { id } = data

      const indexOfSanctionToUpdate = state.potentialUserInSanctions.data.findIndex(
        sanction => sanction.id === id && sanction.search_type === searchType,
      )

      if (indexOfSanctionToUpdate !== -1) {
        const newSanctionsListData = [...state.potentialUserInSanctions.data]
        newSanctionsListData[indexOfSanctionToUpdate] = {
          ...newSanctionsListData[indexOfSanctionToUpdate],
          ...data,
        }
        return {
          ...state,
          potentialUserInSanctions: {
            ...state.potentialUserInSanctions,
            data: newSanctionsListData,
            updating: false,
          },
        }
      }
      throw new Error(`Error updating sanction with id ${id}`)
    }

    case UPDATE_BULK_SANCTION_DATA_SUCCESS: {
      const bulkSanctionsData = action.payload
      const newSanctionsListData = [...state.potentialUserInSanctions.data]

      bulkSanctionsData.forEach((bulkSanction) => {
        const { searchType, ...data } = bulkSanction
        const indexOfSanctionToUpdate = newSanctionsListData
          .findIndex((sanction =>
            sanction.id === bulkSanction.id && sanction.search_type === searchType))
        if (indexOfSanctionToUpdate !== -1) {
          newSanctionsListData[indexOfSanctionToUpdate] = {
            ...newSanctionsListData[indexOfSanctionToUpdate],
            ...data,
          }
        } else {
          throw new Error(`Error updating sanction with id ${bulkSanction.id}`)
        }
      })
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          data: newSanctionsListData,
          updating: false,
        },
      }
    }

    case UPDATE_SANCTION_DATA_ERROR:
    case UPDATE_BULK_SANCTION_DATA_ERROR:
    {
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          updating: false,
        },
      }
    }

    case FETCH_POTENTIAL_USERS_IN_SANCTIONS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        potentialUserInSanctions: {
          ...state.potentialUserInSanctions,
          error,
          status: 'error',
        },
      }
    }

    case CHECK_LOCAL_SANCTIONS_PROCESS_START: {
      return {
        ...state,
        localSanctionsProcessData: {
          ...state.localSanctionsProcessData,
          loading: true,
        },
      }
    }
    case CHECK_LOCAL_SANCTIONS_PROCESS_SUCCESS: {
      return {
        ...state,
        localSanctionsProcessData: {
          ...state.localSanctionsProcessData,
          status: action.payload,
          loading: false,
        },
      }
    }
    case CHECK_LOCAL_SANCTIONS_PROCESS_ERROR: {
      return {
        ...state,
        localSanctionsProcessData: {
          ...state.localSanctionsProcessData,
          loading: false,
        },
      }
    }

    case TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS: {
      return {
        ...state,
        localSanctionsProcessData: {
          ...state.localSanctionsProcessData,
          status: action.payload,
        },
      }
    }

    case TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          processRunning: action.payload,
        },
      }
    }

    case FETCH_CORPORATE_SANCTIONS_START: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isFetching: true,
        },
      }
    }
    case FETCH_CORPORATE_SANCTIONS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          data,
          isFetching: false,
        },
      }
    }
    case FETCH_CORPORATE_SANCTIONS_ERROR: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isFetching: false,
        },
      }
    }

    case CHECK_OUT_CORPORATE_SANCTION_START: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isUpdating: true,
        },
      }
    }

    case CHECK_OUT_CORPORATE_SANCTION_SUCCESS: {
      const { id } = action.payload
      const indexOfSanctionToUpdate = state.corporateSanctions.data.findIndex(
        sanction => sanction.id === id,
      )

      if (indexOfSanctionToUpdate !== -1) {
        const updatedCorporateSanctionsData = [...state.corporateSanctions.data]
        updatedCorporateSanctionsData[indexOfSanctionToUpdate] = {
          ...updatedCorporateSanctionsData[indexOfSanctionToUpdate],
          ...action.payload,
        }
        return {
          ...state,
          corporateSanctions: {
            ...state.corporateSanctions,
            data: updatedCorporateSanctionsData,
            isUpdating: false,
          },
        }
      }
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isUpdating: false,
        },
      }
    }

    case CHECK_OUT_CORPORATE_SANCTION_ERROR: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isUpdating: false,
        },
      }
    }

    case CHECK_CORPORATE_SANCTIONS_PROCESS_START: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isCheckingProcess: true,
        },
      }
    }
    case CHECK_CORPORATE_SANCTIONS_PROCESS_SUCCESS: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          processRunning: action.payload,
          isCheckingProcess: false,
        },
      }
    }
    case CHECK_CORPORATE_SANCTIONS_PROCESS_ERROR: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          isCheckingProcess: false,
        },
      }
    }

    case UPDATE_CORPORATE_SANCTIONS_LIST: {
      return {
        ...state,
        corporateSanctions: {
          ...state.corporateSanctions,
          data: action.payload,
        },
      }
    }

    default:
      return state
  }
}
