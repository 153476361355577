import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_VALR_PAY_START =
  'FETCH_VALR_PAY_START'
export const FETCH_VALR_PAY_SUCCESS =
  'FETCH_VALR_PAY_SUCCESS'
export const FETCH_VALR_PAY_ERROR =
  'FETCH_VALR_PAY_ERROR'


export const CLEAR_VALR_PAY_TRANSACTIONS_LIST = 'CLEAR_VALR_PAY_TRANSACTIONS_LIST'

export const fetchAccountValrPayTransactions = (accountId) => async dispatch => {
  dispatch({
    type: FETCH_VALR_PAY_START,
  })
  try {
    const { data } = await getAxios().get(`valr-pay/account/${accountId}`)
    dispatch({
      type: FETCH_VALR_PAY_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_VALR_PAY_ERROR,
    })
  }
}

export const clearValrPayTransactionsList = () => dispatch => {
  dispatch({
    type: CLEAR_VALR_PAY_TRANSACTIONS_LIST
  })
}
