import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  groupRoleCategories,
  returnDecodedRoles,
} from 'helpers/roleBasedAccess'
import { bindActionCreators } from 'redux'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import { useAuth } from 'context/auth'
import { BLUE } from 'theme/colors'

import { JsonBlock } from 'components/atoms/Details'
import { fetchKeycloakUserByEmail } from 'redux/keycloak/actions'
import { LogoutMenuItem, Settings } from 'components/atoms/Sidebar/styles'
import LoadingBlock from 'components/molecules/LoadingBlock'
import auth from 'services/Auth'
import config from 'config'

import {
  HoverConnector,
  StyledCardContentHeader,
  StyledCurrentUserWelcome,
  StyledCurrentUserWelcomeText,
  StyledUserMenu,
  StyledUserMenuWrapper,
} from './styles'

interface Group {
  id: string
  name: string
  path: string
}

interface UserMenuProps {
  status: string
  firstName: string
  lastName: string
  groupNames: string[]
  errorGettingKeycloakUser: string
  fetchKeycloakUserByEmail: (email: string, realm: string) => void
  toggleTheme: (themeType: 'lightTheme' | 'darkTheme') => void
}

const UserMenu: FC<UserMenuProps> = props => {
  const {
    status,
    firstName,
    lastName,
    groupNames,
    errorGettingKeycloakUser,
    fetchKeycloakUserByEmail,
    toggleTheme,
  } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleModal = () => setIsMenuOpen(prev => !prev)
  const theme = localStorage.getItem('adminTheme') as 'lightTheme' | 'darkTheme'
  const email = auth.getEmailFromToken()
  const { handleLogout } = useAuth()

  const getInitials = (name: string, surname: string) => {
    const nameParts = name.split(' ')
    const surnameParts = surname.split(' ')

    const nameInitials = nameParts
      .map(part => part.charAt(0).toUpperCase())
      .join('')
    const surnameInitials = surnameParts
      .map(part => part.charAt(0).toUpperCase())
      .join('')

    const initials = `${nameInitials}${surnameInitials}`

    return initials
  }

  const initials = getInitials(firstName, lastName)

  useEffect(() => {
    fetchKeycloakUserByEmail(email, config.keycloak.realm || 'internal')
  }, [])

  return (
    <StyledUserMenuWrapper>
      <StyledCurrentUserWelcome theme={theme}>
        <StyledCurrentUserWelcomeText
          onMouseEnter={toggleModal}
          onMouseLeave={toggleModal}
        >
          <AccountCircleIcon
            style={{ color: BLUE, opacity: 0.5 }}
            fontSize="large"
          />
          {`Hello, ${firstName || 'there'}`}
          <HoverConnector />
        </StyledCurrentUserWelcomeText>
        <Divider orientation="vertical" flexItem />

        <IconButton
          onClick={() => {
            toggleTheme(
              theme === 'lightTheme' || !theme ? 'darkTheme' : 'lightTheme',
            )
          }}
        >
          {theme === 'lightTheme' ? <Brightness2Icon /> : <WbSunnyIcon />}
        </IconButton>
      </StyledCurrentUserWelcome>
      {isMenuOpen && (
        <StyledUserMenu onMouseEnter={toggleModal} onMouseLeave={toggleModal}>
          <Card>
            <CardContent>
              <StyledCardContentHeader>
                <Avatar>{initials}</Avatar>
                <Typography variant="h6">{`${firstName} ${lastName}`}</Typography>
                <Typography variant="body2" gutterBottom>
                  {email}
                </Typography>
              </StyledCardContentHeader>
              <Divider />
            </CardContent>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="subtitle2">MY GROUPS</Typography>
                  {!errorGettingKeycloakUser && status === 'done' ? (
                    groupNames.length === 0 && (
                      <Typography variant="overline">
                        No groups found
                      </Typography>
                    )
                  ) : (
                    <LoadingBlock />
                  )}
                  {errorGettingKeycloakUser && (
                    <Typography variant="overline" color="secondary">
                      {errorGettingKeycloakUser}
                    </Typography>
                  )}
                  {!errorGettingKeycloakUser &&
                    groupNames.length > 0 && (
                      <ul>
                        {groupNames.map((group, index) => (
                          <li key={index}>
                            <Typography variant="overline">{group}</Typography>
                          </li>
                        ))}
                      </ul>
                    )}
                </Grid>
              </Grid>
              <Divider />
            </CardContent>
            <CardContent
              style={{
                overflowX: 'auto',
              }}
            >
              <JsonBlock
                src={groupRoleCategories(returnDecodedRoles() || [])}
                label={'My Roles'}
              />
              <Divider />
            </CardContent>
            <CardContent>
              <Settings>
                <LogoutMenuItem label="Logout" onClick={handleLogout} />
              </Settings>
            </CardContent>
          </Card>
        </StyledUserMenu>
      )}
    </StyledUserMenuWrapper>
  )
}

const mapStateToProps = (state: {
  keycloak: {
    byEmail: { status: string; info?: any; error?: string }
  }
}) => {
  const keycloakByEmail = state.keycloak.byEmail

  if (!keycloakByEmail || keycloakByEmail.status === 'loading') {
    return {
      status: 'loading',
      firstName: '',
      lastName: '',
      groupNames: [],
      errorGettingKeycloakUser: '',
    }
  }

  const { status, info, error: errorGettingKeycloakUser } = keycloakByEmail
  const { user, groups } = info || {}
  const { firstName = '', lastName = '' } = user || {}

  const groupNames = groups
    ? Object.values(groups).map((group: Group) => group.name)
    : []

  return {
    status,
    firstName,
    lastName,
    groupNames,
    errorGettingKeycloakUser,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchKeycloakUserByEmail,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserMenu)
