import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_AUTH0_USER_PROCESS = 'auth0/FETCH_AUTH0_USER_PROCESS'
export const FETCH_AUTH0_USER_SUCCESS = 'auth0/FETCH_AUTH0_USER_SUCCESS'
export const FETCH_AUTH0_USER_ERROR = 'auth0/FETCH_AUTH0_USER_ERROR'
export const FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS'
export const FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS'
export const FETCH_OTHER_AUTHO_ACCOUNTS_ERROR = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_ERROR'
export const LINK_AUTH0_ACCOUNTS_PROCESS = 'auth0/LINK_AUTH0_ACCOUNT_PROCESS'
export const LINK_AUTH0_ACCOUNTS_SUCCESS = 'auth0/LINK_AUTH0_ACCOUNT_SUCCESS'
export const LINK_AUTH0_ACCOUNTS_ERROR = 'auth0/LINK_AUTH0_ACCOUNT_ERROR'
export const UNLINK_AUTH0_ACCOUNTS_PROCESS = 'auth0/UNLINK_AUTH0_ACCOUNT_PROCESS'
export const UNLINK_AUTH0_ACCOUNTS_ERROR = 'auth0/UNLINK_AUTH0_ACCOUNT_ERROR'
export const REMOVE_AUTH0_LOADING_STATE = 'auth0/REMOVE_AUTH0_LOADING_STATE'

export const fetchAuth0User = (id, auth0Id) => async dispatch => {
  dispatch({
    type: FETCH_AUTH0_USER_PROCESS,
    payload: { id },
  })
  let res = null
  try {
    res = await getAxios().get(`/account/auth0/${auth0Id}`)
    const { data } = res
    if(data.email!==undefined){
    dispatch({
      type: FETCH_AUTH0_USER_SUCCESS,
       payload: { id, data }
    })
  }else{
    toast(data, { type: toast.TYPE.ERROR, autoClose: 2000 })
    dispatch({
      type: REMOVE_AUTH0_LOADING_STATE,
       payload: { id, data }
    })
  }
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_AUTH0_USER_ERROR,
      payload: {
        id,
        error: error.response.data,
      },
    })
  }
}

export const fetchOtherAuth0Accounts = (id, user) => async dispatch => {
  dispatch({ type: FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS, payload: { id } })
  let res = null
  try {
    const { email, email_verified, user_id } = user
    res = await getAxios().get(`/account/auth0/other/${user_id}?email=${email}&email_verified=${email_verified}`)
    const { data } = res
    dispatch({
      type: FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS,
      payload: { id, data },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_OTHER_AUTHO_ACCOUNTS_ERROR,
      payload: {
        id,
        error: error.response.data,
      },
    })
  }
}

export const linkAuth0Accounts = (id, index, mainId, targetId) => async dispatch => {
  dispatch({
    type: LINK_AUTH0_ACCOUNTS_PROCESS,
    payload: { id, index },
  })
  let res = null
  try {
    await getAxios().post(`/account/auth0/link`, { mainId, targetId: targetId })
    dispatch({ type: LINK_AUTH0_ACCOUNTS_SUCCESS, payload: { id, index } })
    // TODO: Fix updating auth0 user info in a different way
    res = await getAxios().get(`/account/auth0/${mainId}`)
    const { data } = res
    dispatch({ type: FETCH_AUTH0_USER_SUCCESS, payload: { id, data } })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: LINK_AUTH0_ACCOUNTS_ERROR,
      payload: {
        id,
        index,
        error: error.response.data,
      },
    })
  }
}

export const unlinkAuth0Accounts = (id, index, mainId, targetProvider, targetId) => async dispatch => {
  dispatch({ type: UNLINK_AUTH0_ACCOUNTS_PROCESS, payload: { id, index } })
  let res = null
  try {
    await getAxios().post(`/account/auth0/unlink`, { mainId, targetProvider, targetId })
    res = await getAxios().get(`/account/auth0/${mainId}`)
    const { data } = res
    dispatch({ type: FETCH_AUTH0_USER_SUCCESS, payload: { id, data } })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UNLINK_AUTH0_ACCOUNTS_ERROR,
      payload: {
        id,
        index,
        error: error.response.data,
      },
    })
  }
}

export const checkIfAuthInfoExist = async newEmail => {
  const infoForNewEmail = await getAuth0InfoByEmail(newEmail)
  const isExisting = !!infoForNewEmail
  return isExisting
}

export const checkIfIsSocial = async auth0 => {
  const isSocial = auth0.info && auth0.info.identities[0].isSocial
  return isSocial
}

const getAuth0InfoByEmail = async email => {
  var emailAddress = encodeURIComponent(email)
  try {
    const res = await getAxios().get(`/account/auth0/by-email/${emailAddress}`)
    let { data } = res
    if (data) {
      data = JSON.parse(data)[0]
    }
    return data
  } catch (error) {
    ErrorToast(error)
    toast(error.response.data, { type: toast.TYPE.ERROR, autoClose: 5000 })
    return error
  }
}
