import getAxios from 'helpers/axios'

export const FETCH_MARKETING_CONTACT_LIST_RUNS_PROCESS =
  'accounts/FETCH_MARKETING_CONTACT_LIST_RUNS_PROCESS'
export const FETCH_MARKETING_CONTACT_LIST_RUNS_SUCCESS =
  'accounts/FETCH_MARKETING_CONTACT_LIST_RUNS_SUCCESS'
export const FETCH_MARKETING_CONTACT_LIST_RUNS_ERROR =
  'accounts/FETCH_MARKETING_CONTACT_LIST_RUNS_ERROR'

export const fetchMarketingContactRunsList = () => async dispatch => {
  dispatch({
    type: FETCH_MARKETING_CONTACT_LIST_RUNS_PROCESS
  })
  try {
    const res = await getAxios().get('/latest-marketing-contact-runs')
    const { data } = res
    dispatch({
      type: FETCH_MARKETING_CONTACT_LIST_RUNS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_MARKETING_CONTACT_LIST_RUNS_ERROR,
      payload: {
        error,
      },
    })
  }
}
