import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import { hasApplicationConfigEditor } from 'helpers/roleBasedAccess'
import ErrorBoundary from 'helpers/ErrorBoundary'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { fetchConfig } from 'redux/applicationConfig/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import {
  calculateChanges,
  getChangedConfigValue,
  getConfigValue,
  saveConfig,
} from './helpers'
import { ConfirmModal } from './ConfirmModal'
import DynamicConfig from './DynamicConfig'

class DynamicRules extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rulesState: [],
      confirm: false,
      changes: [],
    }
  }

  componentDidMount() {
    this.props.fetchConfig('rules')
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props.rulesConfig
    if (prevProps.rulesConfig.data.length !== data.length) {
      const applicationNewState = data.map(application => {
        const value = getConfigValue(application.type, application.value)
        return { envName: application.name, value, type: application.type }
      })
      this.setState({ rulesState: applicationNewState })
    }
  }

  addToList = key => {
    const newItemAdded = this.state.rulesState.map(newState => {
      if (newState.envName === key) {
        newState.value.push(window[`${key}Ref`].current.value.toUpperCase())
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ rulesState: newItemAdded })
  }

  addToMap = key => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const newItemAdded = this.state.rulesState.map(newState => {
      if (newState.envName === key) {
        const value = window[`${key}Ref`].current.value.toUpperCase().split(':')
        const newKey = value[0]
        const found = newState.value.find(v => v[newKey] != null)
        if (found) {
          toast.error(`Key already exists. ${newKey} : ${found[newKey]}`)
          return newState
        }
        newState.value.push({ [newKey]: value[1] || '' })
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ rulesState: newItemAdded })
  }

  onChange = (type, name, value, mappedKey) => {
    const newApplicationState = this.state.rulesState.map(newState => {
      if (newState.envName === name) {
        newState.value = getChangedConfigValue(
          type,
          value,
          newState.value,
          mappedKey,
        )
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ rulesState: newApplicationState })
  }

  handleRemove = (index, name) => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const newItemAdded = this.state.rulesState.map(newState => {
      if (newState.envName === name) {
        newState.value = newState.value.filter((todo, i) => index !== i)
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ rulesState: newItemAdded })
  }

  confirmChanges = async () => {
    this.setState({ confirm: true })
    const changes = calculateChanges(this.state.rulesState)
    this.setState({ changes })
  }

  save = async () => {
    const system = 'rules'
    await saveConfig(this.state.changes, system)
    this.props.fetchConfig(system)
  }

  renderButton() {
    return (
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <MuiButton
          onClick={this.confirmChanges}
          disabled={!this.state.rulesState?.some(_ => _.configChanged)}
        >
          Review and save
        </MuiButton>
      </div>
    )
  }

  render() {
    const { status } = this.props.rulesConfig

    return (
      <ErrorBoundary message="Dynamic Rules">
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && (
          <div>
            {this.renderButton()}
            <DynamicConfig
              items={this.state.rulesState}
              onChange={this.onChange}
              onListAdd={this.addToList}
              onRemove={this.handleRemove}
              onMapAdd={this.addToMap}
            />
            {this.renderButton()}
          </div>
        )}
        <ConfirmModal
          open={this.state.confirm}
          handleClose={() => this.setState({ confirm: false })}
          proceed={this.save}
          dataToConfirm={this.state.changes}
          initialData={this.props.rulesConfig.data}
        />
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  rulesConfig: state.applicationConfig.application,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchConfig,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicRules)
