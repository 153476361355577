import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { UpdateAccountTradeFiatStatus } from 'redux/accountProfileSettings/actions'

import ErrorToast from 'components/molecules/ErrorToast'

export const submitAccountMarginTradeFiatStatus = async (values, dispatch) => {
  const { canMarginTradeFiat, id } = values
  try {
    await getAxios().put('/account/change/margin-trade-fiat', {
      accountId: id,
      newStatus: canMarginTradeFiat,
    })
    toast('Status updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(UpdateAccountTradeFiatStatus(canMarginTradeFiat))
  } catch (e) {
    ErrorToast(e)
  }
}
