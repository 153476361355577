import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { addUserActionComment } from 'redux/userComments/actions'

export const submit = async values => {
  try {
    const { accountId, count, reason, action } = values
    const res = await getAxios().post('/dualauth/initiate/exchange-control', {
      accountId: Number(accountId),
      count: Number(count),
      reason,
      action,
    })
    if (res.status === 202) {
      const { data } = res
      values.dualAuthId = data.dualAuthId
      values.data = data
      await addUserActionComment(values)
      toast('Exchange control update requested', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
  } catch (error) {
    ErrorToast(error)
  }
}
