import React, { Fragment, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { connect } from 'react-redux'
import CustomTable from '../../components/atoms/CustomTable'
import { fetchPendingFerryAuths } from '../../redux/ferry-auths/actions'
import { BASE } from '../../theme/base-units'
import LoadingBlock from '../../components/molecules/LoadingBlock'
import { FlexboxColumn } from '../../components/layout'
import Row from '../../components/atoms/CustomTable/Row'
import { InfoBlock } from '../../components/atoms/Details'

const FerryAuths = props => {
  const [expandedRowId, setExpandedRowId] = useState('')
  const {
    isFetchingPendingFerryAuthsArray,
    pendingFerryAuths,
    ferryAuthRequestType,
  } = props

  useEffect(() => {
    props.fetchPendingFerryAuths(ferryAuthRequestType)
  }, [])

  function getStateString(state) {
    switch (state) {
      case 0:
        return 'initiated'
      case 1:
        return 'sent'
      case 2:
        return 'partially-approved'
      case 3:
        return 'approved'
      case 4:
        return 'actioned'
      case 5:
        return 'denied'
      case 6:
        return 'cancelled'
      case 7:
        return 'failed'
      default:
        return 'unknown'
    }
  }

  const headings = ['id', 'initiator', 'type', 'state']

  if (isFetchingPendingFerryAuthsArray) return <LoadingBlock style={{ height: 10 }} />

  return (
    <>
      {pendingFerryAuths.data.length > 0 &&
        <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
          <CustomTable
            headings={headings}
            customRows={pendingFerryAuths.data.map(pendingFerryAuth => (
              <Row
                onExpand={setExpandedRowId}
                expandedRowId={expandedRowId}
                key={pendingFerryAuth.id}
                row={{
                  id: pendingFerryAuth.id,
                  initiator: pendingFerryAuth.initiator,
                  type: pendingFerryAuth.requestType,
                  state: getStateString(pendingFerryAuth.state),
                }}
                headings={headings}
                height='50px'
                customExpandData={
                  <>
                    {Object.entries(pendingFerryAuth.payload).map(ferryAuthDetailKey =>
                      <TableRow key={ferryAuthDetailKey[0]}>
                        <TableCell>
                          <InfoBlock
                            label={ferryAuthDetailKey[0]}
                            value={ferryAuthDetailKey[1]}
                          />
                        </TableCell>
                      </TableRow>)}
                  </>
                }
              />
            ))}
          />
        </FlexboxColumn>
      }
    </>
  )
}
const mapStateToProps = state => ({
  pendingFerryAuths: state.pendingFerryAuths,
  isFetchingPendingFerryAuthsArray: state.pendingFerryAuths.loading,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPendingFerryAuths,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FerryAuths)
