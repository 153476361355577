import React from 'react'
import { Flexbox } from 'components/layout'

import { StyledMultiBadge } from './Styles'

export const MultiBadge = ({ badges, children }) => (
  <div>
    <Flexbox
      style={{
        position: 'relative',
        right: 0,
        marginBottom: -12,
        marginRight: -80,
      }}
      direction="row"
      justify="flex-end"
    >
      {badges.map((badge) => (
        <StyledMultiBadge
          background={badge === 'Staff Account' ? 'green' : '#f50057'}
          key={badge}
        >
          {badge}
        </StyledMultiBadge>
      ))}
    </Flexbox>
    {children}
  </div>
)
