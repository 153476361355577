import React, { Component, Fragment } from 'react'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  Details,
  InfoBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import MarginStatusForm from 'forms/marginStatusForm'

import { oppositeColor, capitalizeFirstLetter } from '../../../helpers/utils'

class EnableMarginPerAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const {
      marginActivationStatus: { status, inLiquidation, marginEnabled },
      id,
      notAuthorized,
    } = this.props
    const { formShown } = this.state
    return (
      <>
        <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
          {status === 'loading' && <IconLoading />}
          {status === 'done' && (
            <Fragment>
              {!formShown && (
                <MuiButton
                  width={'70%'}
                  onClick={this.toggleForm}
                  disabled={notAuthorized}
                  showToolTip={notAuthorized}
                  tooltipTitle={
                    notAuthorized
                      ? 'You are not authorized to perform this action'
                      : ''
                  }
                >
                    Change Margin Status
                </MuiButton>
              )}
            </Fragment>
          )}
          {formShown &&
              status === 'done' && (
            <MarginStatusForm
              initialValues={{
                id,
                fieldName: 'margin_enabled',
                marginEnabled,
              }}
              onCancel={this.toggleForm}
            />
          )}
        </Details>
        <InfoBlock
          label={'Margin Enabled'}
          value={capitalizeFirstLetter(marginEnabled.toString())}
          color={oppositeColor(marginEnabled)}
        />
        <InfoBlock
          label={'Account In Liquidation'}
          value={capitalizeFirstLetter(inLiquidation.toString())}
          color={oppositeColor(inLiquidation)}
        />
      </>
    )
  }
}
export default EnableMarginPerAccount
