import {
  FETCH_COMPETITION_LIST_PROCESS,
  FETCH_COMPETITION_LIST_SUCCESS,
  FETCH_COMPETITION_LIST_ERROR,
  REMOVE_ITEM,
  REMOVE_ITEM_ERROR,
} from './actions'

const initialState = {
  status: 'pristine',
  error: '',
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPETITION_LIST_PROCESS: {
      return {
        ...state,
        status: 'loading',
        error: '',
      }
    }

    case FETCH_COMPETITION_LIST_SUCCESS: {
      const { data } = action.payload
      const listData = data.map((list) => {
        return {...list.response, deleteId: list.deleteId}
      })
      return {
        ...state,
        status: 'done',
        error: '',
        data: listData,
      }
    }

    case FETCH_COMPETITION_LIST_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        status: 'done',
        error,
      }
    }

    case REMOVE_ITEM: {
      const id = action.payload
      const finalData = state.data.filter(list => {
        return list.id !== id
      })
      return {
        ...state,
        status: 'done',
        data: finalData,
      }
    }

    case REMOVE_ITEM_ERROR: {
      return {
        ...state,
        status: 'done',
      }
    }

    default:
      return state
  }
}
