import React, { Dispatch, FC, Fragment, useEffect, useState } from 'react'
import BasicModal from 'components/organisms/Modal/index'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DeleteIcon from '@material-ui/icons/Delete'
import { convertToString, viewPdf } from 'helpers/utils'
import { Typography } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { toast } from 'react-toastify'

import IconButton from 'components/atoms/IconButton/index'
import { Title } from 'components/atoms/Title'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import ErrorToast from 'components/molecules/ErrorToast'
import IconLoading from 'components/atoms/IconLoading'

import {
  PdfViewActionContainer,
  StyledDocumentsActionsContainer,
} from './Styles'

interface DocumentToView {
  id: number
  file: {
    type: string
    data: Buffer
  }
  fileType: string
  createdAt: string
  documentType: string
  documentName: string
}

interface MultiDocumentViewerProps {
  isOpen: boolean
  documentsDataBeingViewed: DocumentToView[]
  isLoadingDocuments: boolean
  isDeletingDocument: boolean
  handleCloseDocumentViewer: () => void
  removeDocument: (doucmentId: number) => Promise<void>
  setDocumentsDataBeingViewed: Dispatch<any>
  setIsDeletingDocument: Dispatch<any>
}

export const MultiDocumentViewer: FC<MultiDocumentViewerProps> = ({
  isOpen,
  documentsDataBeingViewed,
  isLoadingDocuments,
  isDeletingDocument,
  handleCloseDocumentViewer,
  removeDocument,
  setDocumentsDataBeingViewed,
  setIsDeletingDocument,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false)
  let blob = new Blob()
  let url = ''
  const isPdf =
    documentsDataBeingViewed[currentImageIndex] &&
    documentsDataBeingViewed[currentImageIndex].fileType === 'application/pdf'

  if (
    documentsDataBeingViewed.length > 0 &&
    documentsDataBeingViewed[currentImageIndex].file &&
    documentsDataBeingViewed[currentImageIndex].fileType
  ) {
    blob = new Blob(
      [new Uint8Array(documentsDataBeingViewed[currentImageIndex].file.data)],
      {
        type: documentsDataBeingViewed[currentImageIndex].fileType,
      },
    )
    url = URL.createObjectURL(blob)
  }

  const handleDeleteDocument = async () => {
    try {
      setShowDeleteDocumentModal(false)
      await removeDocument(documentsDataBeingViewed[currentImageIndex].id)
      const updatedDocumentsDataBeingViewed = documentsDataBeingViewed.filter(
        doc => doc.id !== documentsDataBeingViewed[currentImageIndex].id,
      )
      if (updatedDocumentsDataBeingViewed.length === 0) {
        return handleCloseDocumentViewer()
      }
      setCurrentImageIndex(
        currentImageIndex - 1 < 0 ? 0 : currentImageIndex - 1,
      )
      setDocumentsDataBeingViewed(updatedDocumentsDataBeingViewed)
    } catch (error) {
      ErrorToast('Error deleting document')
      setIsDeletingDocument(false)
    }
  }

  const getModalTitle = () => {
    if (documentsDataBeingViewed.length === 0) return ''
    const docType =
      documentsDataBeingViewed[currentImageIndex] &&
      documentsDataBeingViewed[currentImageIndex].documentType
    const documentType = convertToString(docType, '_')
    const documentCount = ` [ ${currentImageIndex + 1} of ${
      documentsDataBeingViewed.length
    } ]`
    return `${documentType}${documentCount}`
  }

  const handleViewNextDocument = () => {
    if (currentImageIndex < documentsDataBeingViewed.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1)
    }
  }
  const handleViewPrevDocument = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1)
    }
  }

  function previewSelectedPdfFile(src) {
    return <embed src={src} width="100%" height="350" />
  }

  useEffect(
    () => {
      setCurrentImageIndex(0)
    },
    [isLoadingDocuments],
  )

  return (
    <Fragment>
      <BasicModal
        handleClose={handleCloseDocumentViewer}
        buttonsHidden
        open={isOpen}
        message={
          !isLoadingDocuments ? (
            <StyledDocumentsActionsContainer>
              <Title type="h3" text={getModalTitle()} />
              {isDeletingDocument ? (
                <IconLoading />
              ) : (
                <IconButton
                  height={''}
                  type={''}
                  width={''}
                  onClick={() => setShowDeleteDocumentModal(true)}
                  disabled={false}
                  icon={<DeleteIcon />}
                />
              )}
            </StyledDocumentsActionsContainer>
          ) : (
            ''
          )
        }
      >
        <div style={{ width: 500 }}>
          {isLoadingDocuments && <LoadingBlock message="Fetching Documents" />}
          {!isLoadingDocuments &&
            !isPdf && (
              <>
                <img
                  style={{ height: 500, width: 500, objectFit: 'contain' }}
                  src={url}
                  alt="img"
                />
                {false && <LoadingBlock message="Deleting Document..." />}
              </>
            )}
          {!isLoadingDocuments && isPdf && previewSelectedPdfFile(url)}
          {isPdf && (
            <PdfViewActionContainer>
              <div>
                <IconButton
                  disabled={false}
                  height={''}
                  type={''}
                  width={''}
                  icon={<VisibilityIcon style={{ color: '#13ab18' }} />}
                  onClick={() => {
                    viewPdf(
                      documentsDataBeingViewed[currentImageIndex].file.data,
                      documentsDataBeingViewed[currentImageIndex].fileType,
                    )
                  }}
                />
                <Typography variant="body1">View PDF</Typography>
              </div>
            </PdfViewActionContainer>
          )}
        </div>
        {!isLoadingDocuments && (
          <StyledDocumentsActionsContainer>
            <div>
              {documentsDataBeingViewed.length > 0 && (
                <>
                  <Typography
                    component="div"
                    variant="overline"
                    style={{ maxWidth: 400, wordWrap: 'break-word' }}
                  >
                    {documentsDataBeingViewed[currentImageIndex].documentName}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="primary"
                    style={{ maxWidth: 400, wordWrap: 'break-word' }}
                  >
                    {
                      documentsDataBeingViewed[
                        currentImageIndex
                      ].createdAt.split('(')[0]
                    }
                  </Typography>
                </>
              )}
            </div>
            <div>
              <IconButton
                height={''}
                type={''}
                width={''}
                onClick={handleViewPrevDocument}
                icon={<ArrowBackIcon />}
                disabled={currentImageIndex === 0}
              />
              <IconButton
                height={''}
                type={''}
                width={''}
                onClick={handleViewNextDocument}
                icon={<ArrowForwardIcon />}
                disabled={
                  currentImageIndex === documentsDataBeingViewed.length - 1
                }
              />
            </div>
          </StyledDocumentsActionsContainer>
        )}
      </BasicModal>
      {/* @ts-ignore */}
      <ConfirmationDialog
        title="Delete document"
        open={showDeleteDocumentModal}
        proceed={() => handleDeleteDocument()}
        message={`Are you sure you want to delete ${documentsDataBeingViewed[
          currentImageIndex
        ] && documentsDataBeingViewed[currentImageIndex].documentName}?`}
        handleClose={() => setShowDeleteDocumentModal(false)}
      />
    </Fragment>
  )
}
