import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const submit = async values => {
  try {
    const { accountId, requestedAction } = values
    const res = await getAxios().post('/account/requested-actions', {
      accountId,
      action: requestedAction.value,
    })
    if (res.status === 201) {
      toast('Added requested action to account', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
  } catch (error) {
    ErrorToast(error)
  }
}
