import React, { useState, useEffect } from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import AccountsTransactions from './AccountsTransactions'

import DepositsNav from './DepositsNav'
import WithdrawalsNav from './WithdrawalsNav'
import AccountValrPayTransactions from './ValrPay/AccountValrPayTransactions'

const RenderTransactionalHistory = (props) => {
  const routes = [
    '/accounts/byId/:accountId/transactions',
    '/accounts/byId/:accountId/transactions/deposits',
    '/accounts/byId/:accountId/transactions/withdrawals',
    '/accounts/byId/:accountId/transactions/valr-pay',
  ]

  const tabs = ['transactions', 'deposits', 'withdrawals', 'valr-pay']

  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
  }, [pathname])

  const checkPath = (path) => {
    tabs.forEach((tab) => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  useEffect(() => {
    checkPath(pathname)
  }, [pathname])
  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'transactions'}
          label="Transaction History"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/transactions`}
          exact
        />
        <NavigationTab
          value={'deposits'}
          label="DEPOSITS"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/transactions/deposits`}
          exact
        />
        <NavigationTab
          value={'withdrawals'}
          label="WITHDRAWALS"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/transactions/withdrawals`}
          exact
        />
        <NavigationTab
          value={'valr-pay'}
          label="VALR PAY"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/transactions/valr-pay`}
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={AccountsTransactions} exact />
          <Route
            path={routes[1]}
            component={DepositsNav}
            accountId={props.match.params.accountId}
          />
          <Route
            path={routes[2]}
            component={WithdrawalsNav}
            accountId={props.match.params.accountId}
          />
          <Route
            path={routes[3]}
            component={AccountValrPayTransactions}
            accountId={props.match.params.accountId}
          />

        </Switch>
      </ContentContainer>
    </>
  )
}
export default RenderTransactionalHistory
