import { ConditionalOrder } from 'interfaces/conditionalOrders'
import { ConditionalOrdersActions } from './actions'

export interface FuturesInitialState {
  accountConditionalOrders:{
    data: ConditionalOrder[]
   fetchingAccountConditionalOrders: 'pristine' | 'pending' | 'done'
  }
    data: ConditionalOrder[]
    fetchingConditionalOrders: 'pristine' | 'pending' | 'done'
}

interface Action {
  type: ConditionalOrdersActions
  payload: ConditionalOrder[]
}

const initialState: FuturesInitialState = {
  accountConditionalOrders:{
    data: [],
    fetchingAccountConditionalOrders:'pristine'
  },

    data:[],
    fetchingConditionalOrders: 'pristine' 
}

export default (
  state = initialState,
  action: Action,
): FuturesInitialState => {
  switch (action.type) {
    case 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_START': {
      return {
       ...state,
          fetchingConditionalOrders: 'pending',
      }
    }
    case 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_SUCCESS': {
      return {
        ...state,
          fetchingConditionalOrders: 'done',
          data: action.payload as ConditionalOrder[]
      }
    }
    case 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_ERROR': {
      return {
        ...state,
          fetchingConditionalOrders: 'done',
      }
    }
    case 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_START': {
      return {
        ...state,
        accountConditionalOrders: {
          ...state.accountConditionalOrders,
          fetchingAccountConditionalOrders: 'pending',
        },
      }
    }
    case 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_SUCCESS': {
      return {
        ...state,
        accountConditionalOrders: {
          ...state.accountConditionalOrders,
          fetchingAccountConditionalOrders: 'done',
          data: action.payload as ConditionalOrder[]
        },
      }
    }
    case 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_ERROR': {
      return {
        ...state,
        accountConditionalOrders: {
          ...state.accountConditionalOrders,
          fetchingAccountConditionalOrders: 'pending',
        },
      }
    }
    case 'RESET_CONDITIONAL_ORDERS':{
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
