const initialState = {
  twilioCountries: [],
  twilioData: { type: '', data: [] },
  countriesLoading: false,
  dataLoading: false,
  updateLoading: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TWILIO_COUNTRIES_START': {
      return {
        ...state,
        countriesLoading: true,
      }
    }

    case 'TWILIO_PERMISSIONS_UPDATE_START': {
      return {
        ...state,
        updateLoading: true,
      }
    }

    case 'FETCH_TWILIO': {
      return {
        ...state,
        dataLoading: true,
      }
    }

    case 'FETCH_TWILIO_COUNTRIES_SUCCESS': {
      const { data } = action.payload

      return {
        ...state,
        twilioCountries: data,
        countriesLoading: false,
      }
    }

    case 'FETCH_TWILIO_SUCCESS': {
      const { data, type } = action.payload

      return {
        ...state,
        twilioData: { type, data },
        countriesLoading: false,
        dataLoading: false,
      }
    }

    case 'FETCH_TWILIO_COUNTRIES_ERROR': {
      const { error } = action.payload

      return {
        ...state,
        loading: false,
        error,
      }
    }

    case 'TWILIO_PERMISSIONS_UPDATE_SUCCESS': {
      const { isoCode, isEnabled } = action.payload
      const modifiedCountryList = state.twilioCountries.map(item => {
        if (item.isoCode === isoCode) item.lowRiskNumbersEnabled = isEnabled
        return item
      })

      return {
        ...state,
        twilioCountries: modifiedCountryList,
        updateLoading: false,
      }
    }

    default:
      return state
  }
}
