import React from 'react'
import { pure } from 'recompose'
import { RED_COLOR_PAIR, GREEN_COLOR_PAIR } from 'theme/colors'
import { oppositeColor } from 'helpers/utils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as moment from 'moment'
import ExpandedView from './ExpandView'
import { Link } from './clickableId'

const ValrPayTransactionRow = props => {
  const {
    tx, setHighlightedId, isHighlighted, accountId,
  } = props
  const shading = isHighlighted ? 'white' : ''

  const date = new Date(tx.timestamp)
  const debitValue = tx.type === 'DEBIT' ? `${tx.amount} ${tx.currency}` : '--'
  const creditValue = tx.type === 'CREDIT' ? `${tx.amount} ${tx.currency}` : '--'
  return (
    <>
      <TableRow
        key={''}
        onClick={() => { setHighlightedId() }}
        style={{ backgroundColor: isHighlighted && '#4569eb', color: shading }}
      >
        <TableCell title={tx.type}>
          {tx.type}
        </TableCell>
        <TableCell title={tx.status}>
          {tx.status}
        </TableCell>
        <TableCell >
          {tx.type === 'DEBIT' ? accountId : (tx.otherPartyAccountId ? <Link color={shading} id={tx.otherPartyAccountId} /> : tx.otherPartyIdentifier)}
        </TableCell>
        <TableCell >
          {tx.type === 'CREDIT' ? accountId : (tx.otherPartyAccountId ? <Link color={shading} id={tx.otherPartyAccountId} /> : tx.otherPartyIdentifier)}
        </TableCell>
        <TableCell style={{ color: isHighlighted ? '' : oppositeColor(tx.amount, RED_COLOR_PAIR), fontWeight: 'bold' }}>
          {debitValue}
        </TableCell>
        <TableCell
          flex={1}
          style={{ color: isHighlighted ? '' : oppositeColor(tx.amount, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
        >
          {creditValue}
        </TableCell>
        <TableCell title={'time stamp'}>
          {moment(date).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
      </TableRow>
      {isHighlighted && <ExpandedView tx={tx} />}
    </>
  )
}

export default pure(ValrPayTransactionRow)
