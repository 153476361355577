import React, { useEffect, useState, createRef, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import {
  fetchManualDocuments,
  updateManualKyc,
} from 'redux/manualKycUploads/actions'
import { fetchManualAttempts } from 'redux/manualKycAttempts/actions'
import ParachuteForm from 'forms/Parachute/index'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import './style.css'
import { ListStyle, EnlargedDocumentImage } from './styles'
import Modal from '@material-ui/core/Modal'
import auth from 'services/Auth'
import socket from 'redux/socket/index'

function createData(
  accountId,
  identifier,
  cellNumber,
  email,
  insertedAt,
  activePersonName,
  userActive,
) {
  return {
    accountId,
    identifier,
    cellNumber,
    email,
    insertedAt,
    activePersonName,
    userActive,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function Parachute(props) {
  const classes = useStyles()
  const [expandedRow, setExpandedRow] = useState('')
  const [isOpen, setIsOpen] = useState('')
  const [highlightedId, setHighlightedId] = useState('')

  const toggleSelectedRow = id => {
    if (id !== expandedRow) {
      setExpandedRow(id)
      setHighlightedId(id)
      props.fetchManualAttempts(id)
    } else {
      setExpandedRow('')
      setHighlightedId('')
    }
  }

  useEffect(() => {
    props.fetchManualDocuments()
    socket.on('busy parachute user', data => {
      props.updateManualKyc(data)
    })
    socket.on('parachute status updated on unmount', data => {
      props.updateManualKyc(data)
    })
    socket.on('parachute status updated', data => {
      props.updateManualKyc(data)
    })
  }, [])

  const {
    kycAttempts: { data, status },
    manualKycAttempts: { manualData, manualStatus },
  } = props
  const list = data
  const rows = list.map((results, i) => {
    const {
      accountId,
      identifier,
      profileInfo: { cellNumber, email },
      insertedAt,
      active: { activePersonName, userActive },
    } = results
    return createData(
      accountId,
      identifier,
      cellNumber,
      email,
      insertedAt,
      activePersonName,
      userActive,
    )
  })

  const handleShowDialog = url => {
    setIsOpen(url)
  }

  return (
    <>
      {status === 'loading' && (
        <TablePlaceholder>
          <IconLoading />
          <span>Loading, please wait</span>
        </TablePlaceholder>
      )}
      {status === 'done' && (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead align="right">
                  <TableRow>
                    <TableCell width={'10%'}>Id</TableCell>
                    <TableCell width={'20%'}>Kyc attempt Id</TableCell>
                    <TableCell width={'20%'}>Email</TableCell>
                    <TableCell width={'20%'}>Cell Number</TableCell>
                    <TableCell flex={1}>Date</TableCell>
                    <TableCell width={'20%'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`
                    const identifier = row.identifier

                    const toggleRow = () => {
                      toggleSelectedRow(identifier)
                    }
                    const removeActiveUser = async () => {
                      const depositId = row.identifier
                      const name = auth.decodeNameToken()
                      const activeUserQue = {
                        userName: name,
                        depositId: depositId,
                      }
                      const removeUser = { depositId, activeUserQue }
                      socket.emit('remove active parachute user', removeUser)
                    }

                    const showActiveUser = async () => {
                      const id = row.identifier
                      const name = auth.decodeNameToken()
                      const activeUserQue = {
                        userName: name,
                        depositId: id,
                      }
                      const activeUser = { id, activeUserQue }
                      socket.emit('add parachute user', activeUser)
                    }

                    return (
                      <>
                        <TableRow
                          key={row.identifier + index}
                          style={{
                            backgroundColor:
                              highlightedId === identifier && '#4569eb',
                          }}
                          onClick={() => toggleRow()}
                        >
                          <TableCell>{row.accountId}</TableCell>
                          <TableCell>{row.identifier}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.cellNumber}</TableCell>
                          <TableCell id={labelId} scope="row">
                            {moment(row.insertedAt).format('YYYY-MMM-DD HH:mm')}
                          </TableCell>
                          <TableCell width={'20%'}>
                            {row.userActive && (
                              <>
                                <i
                                  style={{ color: '#B9BBBD' }}
                                  className="fas fa-eye"
                                />
                                <span
                                  className="tooltiptext"
                                  style={{ marginLeft: '6px' }}
                                >
                                  {row.activePersonName}
                                </span>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                        {expandedRow === row.identifier && (
                          <>
                            {manualStatus === 'loading' && (
                              <TableCell colSpan={6}>
                                <TablePlaceholder>
                                  <IconLoading />
                                  <span>Loading, please wait</span>
                                </TablePlaceholder>
                              </TableCell>
                            )}
                            {manualStatus === 'done' &&
                              manualData.length === 0 && (
                                <TableCell colSpan={6}>
                                  <TablePlaceholder>
                                    <span>Attempt id has no data</span>
                                  </TablePlaceholder>
                                </TableCell>
                              )}
                            {manualStatus === 'done' &&
                              manualData.length > 0 && (
                                <>
                                  <TableCell colSpan={6}>
                                    <ListStyle>
                                      {manualData.map((data, index) => {
                                        const blob = new Blob(
                                          [new Uint8Array(data.data.data)],
                                          {
                                            type: `image/${data.fileExtension}`,
                                          },
                                        )

                                        const url = window.URL.createObjectURL(
                                          blob,
                                        )
                                        return (
                                          <>
                                            <div
                                              colSpan={6}
                                              style={{ position: 'relative' }}
                                            >
                                              <p>
                                                {data.type.split('_').join(' ')}
                                              </p>
                                              <img
                                                className="parachuteImage"
                                                onContextMenu={e =>
                                                  e.preventDefault()
                                                }
                                                draggable={false}
                                                style={{
                                                  maxHeight: '650px',
                                                  maxWidth: '100%',
                                                  opacity:
                                                    isOpen !== data.identifier
                                                      ? '1'
                                                      : '0',
                                                }}
                                                key={index}
                                                src={url}
                                                alt="Could not genarate image, file could be corrupt"
                                                onClick={() =>
                                                  handleShowDialog(
                                                    data.identifier,
                                                  )
                                                }
                                              />
                                            </div>
                                            <>
                                              <Modal
                                                disablePortal
                                                id="uniqueId"
                                                open={
                                                  isOpen === data.identifier
                                                }
                                                onClose={() =>
                                                  handleShowDialog('')
                                                }
                                              >
                                                <EnlargedDocumentImage>
                                                  <img
                                                    onContextMenu={e =>
                                                      e.preventDefault()
                                                    }
                                                    draggable={false}
                                                    className="image"
                                                    src={url}
                                                    onClick={() =>
                                                      handleShowDialog('')
                                                    }
                                                    alt="no image"
                                                  />
                                                </EnlargedDocumentImage>
                                              </Modal>
                                            </>
                                          </>
                                        )
                                      })}
                                    </ListStyle>
                                    <ParachuteForm
                                      initialValues={{
                                        accountId: row.accountId,
                                        id: row.identifier,
                                        comment: '',
                                        status: '',
                                        data: {
                                          date: new Date(),
                                          createdBy: auth.decodeNameToken(),
                                        },
                                        dualAuthStatus: 'pending',
                                        flag: false,
                                      }}
                                      removeActiveUser={removeActiveUser}
                                      showActiveUser={showActiveUser}
                                      onCancel={toggleRow}
                                      form={`ParachuteForm-${row.identifier}`}
                                    />
                                  </TableCell>
                                </>
                              )}
                          </>
                        )}
                      </>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  kycAttempts: state.kycAttempts,
  manualKycAttempts: state.manualKycAttempts,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchManualDocuments,
      fetchManualAttempts,
      updateManualKyc,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Parachute)
