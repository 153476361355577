import { AvailableCurrencyPair, AvailableCurrencyPairsList, FuturePosition } from 'interfaces/futures'

import { FuturesActions } from './actions'

export interface FuturesInitialState {
  futuresPositions: FuturePosition[]
  availableCurrencyPairsList: AvailableCurrencyPairsList
  fetchingCurrencyPairs: boolean
  fetchingFuturesPositions: 'pristine' | 'pending' | 'done'
}

interface Action {
  type: FuturesActions
  payload: FuturePosition[] | FuturePosition | AvailableCurrencyPair[]
}

const initialState: FuturesInitialState = {
  futuresPositions: [],
  availableCurrencyPairsList: [],
  fetchingCurrencyPairs: false,
  fetchingFuturesPositions: 'pristine',
}

export default (
  state = initialState,
  action: Action,
): FuturesInitialState => {
  switch (action.type) {
    case 'FETCH_FUTURES_CURRENCY_PAIRS_START': {
      return {
        ...state,
        fetchingCurrencyPairs: true,
      }
    }
    case 'FETCH_FUTURES_CURRENCY_PAIRS_SUCCESS': {
      const payload = action.payload as AvailableCurrencyPair[]
      const availableCurrencyPairsList = payload.filter((pair) => pair.currencyPairSymbol.includes('PERP')).map((pair) => ({
        label: pair.currencyPairSymbol,
        value: pair.currencyPairSymbol,
      })).sort((a, b) => a.label > b.label ? 1 : -1)
      return {
        ...state,
        availableCurrencyPairsList,
        fetchingCurrencyPairs: false,
      }
    }
    case 'FETCH_FUTURES_CURRENCY_PAIRS_ERROR': {
      return {
        ...state,
        fetchingCurrencyPairs: false,
      }
    }
    case 'FETCH_FUTURES_START': {
      return {
        ...state,
        fetchingFuturesPositions: 'pending',
      }
    }
    case 'FETCH_FUTURES_SUCCESS': {
      return {
        ...state,
        futuresPositions: action.payload as FuturePosition[],
        fetchingFuturesPositions: 'done',
      }
    }
    case 'FETCH_FUTURES_ERROR': {
      return {
        ...state,
        fetchingFuturesPositions: 'pristine',
      }
    }

    case 'RESET_CURRENCY_PAIRS_SUCCESS': {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
