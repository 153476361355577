import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BlockAcc } from 'components/atoms/Details/index'
import { fetchAuth0User } from 'redux/auth0/actions'
import { fetchKeycloakUser } from 'redux/keycloak/actions'
import { submitBlockStatus } from './block-account'
import UnblockAccount from '../../containers/Accounts/SingleAccount/UnblockAccount'

const BlockAccount = props => {
  const {
    auth0Info,
    id,
    fetchAuth0User,
    keycloakInfo,
    fetchKeycloakUser,
  } = props
  const values = { accountId: id, sendBlockEmail: true }
  const isLoadingBlockedAccountStatus =
    (keycloakInfo.status === 'loading' && auth0Info.status !== 'done') ||
    (auth0Info.status === 'loading' && keycloakInfo.status !== 'done')

  return (auth0Info.status === 'done' && auth0Info.info.blocked) ||
    (keycloakInfo.status === 'done' && !keycloakInfo.info.enabled) ? (
    <UnblockAccount id={id} />
  ) : (
    <BlockAcc
      variant="contained"
      color="secondary"
      onClick={async () => {
        await submitBlockStatus(values)
        auth0Info.status === 'done'
          ? fetchAuth0User(id, auth0Info.info.user_id)
          : fetchKeycloakUser(id, keycloakInfo.info.id)
      }}
      disabled={isLoadingBlockedAccountStatus}
    >
      {isLoadingBlockedAccountStatus ? 'Loading...' : 'Block Account'}
    </BlockAcc>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAuth0User,
      fetchKeycloakUser,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlockAccount)
