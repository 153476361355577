import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { ContentContainer } from 'components/atoms/Generic'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import OrderTypes from 'components/organisms/OrderTypes'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Spot from './SpotOrders'
import Futures from './Futures'

const Trading = (props) => {
  const routes = [
    '/exchange/trading',
    '/exchange/trading/spot',
    '/exchange/trading/futures',
  ]

  const tabs = ['order-types', 'spot', 'futures']

  const [currentPage, setCurrentPage] = useState(tabs[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const checkPath = (path) => {
    tabs.forEach((tab) => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  useEffect(() => {
    checkPath(pathname)
  }, [pathname])

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'order-types'}
          label="Order Types"
          component={ForwardedNavLink}
          to={routes[0]}
        />
        <NavigationTab
          value={'spot'}
          label="Spot"
          component={ForwardedNavLink}
          to={routes[1]}
        />
        <NavigationTab
          value={'futures'}
          label="Futures"
          component={ForwardedNavLink}
          to={routes[2]}
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={OrderTypes} exact />
          <Route path={routes[1]} component={Spot} />
          <Route path={routes[2]} component={Futures} />
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Trading
