import React, { Fragment, useState, useRef } from 'react'

import './styles.css'
import ImageIcon from '@material-ui/icons/Image'
import { ListItem } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SendIcon from '@material-ui/icons/Send'
import { WHITE } from 'theme/colors'

import { StyledDocumentsActionsContainer } from 'containers/Accounts/SingleAccount/CorporateAccount/components/Styles'
import { FILE_TYPES } from 'containers/Accounts/SingleAccount/CorporateAccount/const/Entities'
import { Button } from 'components/atoms/Form/index'
import IconLoading from 'components/atoms/IconLoading'

import HeicToImage from './HeicToImage'
import {
  StyledFileUploaderContent,
  StyledFileUploaderWrapper,
  StyledImage,
  StyledImageNumber,
} from './Styles'
import { FileViewContainer } from './FileViewContainer'

const UPLOAD_ERROR_TYPES = {
  invalidFileType: 'Files type not allowed',
  fileSizeTooLarge: 'Files too large max files size allowed is',
}

function FilesUploader({
  isUploading = false,
  acceptedFileTypes = ['image/*'],
  showUploadButton = true,
  uploadButtonText = 'Upload Documents',
  input: { onChange } = { onChange: () => {} },
  handleUpload = (files) => {},
  height,
  width,
  manual = false,
  clearFile,
  showImageNumbers = false,
}) {
  const [files, setFiles] = useState([])
  const fileInputRef = useRef(null)
  const FILE_SIZE_LIMIT_MB = 20
  const bytesToMegaBytes = (bytes) => bytes / 1024 / 1024
  const [uploadError, setUploadError] = useState('')

  function previewSelectedPdfFile(src) {
    return <embed src={`${src}#toolbar=0`} width="250" height="350" />
  }

  function removeFile(index) {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
  }

  function renderFileView() {
    return (
      <div style={{ maxHeight: '70vh' }}>
        {files.map((file, index) => {
          let elementToView = <></>
          const src = URL.createObjectURL(file)
          switch (file.type) {
            case FILE_TYPES.HEIC:
              elementToView = <HeicToImage file={file} />
              break
            case FILE_TYPES.DOCX:
              elementToView = <p>{file.name} selected</p>
              break
            case FILE_TYPES.PDF:
              elementToView = previewSelectedPdfFile(src)
              break

            default:
              elementToView = (
                <ListItem divider>
                  <StyledImage src={src} alt="file" />
                </ListItem>
              )
              break
          }

          return (
            <FileViewContainer removeFile={() => removeFile(index)} key={index}>
              {showImageNumbers && (
                <StyledImageNumber>
                  <p>{index + 1}</p>
                </StyledImageNumber>
              )}
              {elementToView}
            </FileViewContainer>
          )
        })}
      </div>
    )
  }

  const checkFileSize = (fileSize) =>
    bytesToMegaBytes(fileSize) > FILE_SIZE_LIMIT_MB

  const handleSelectedFile = (fileData) => {
    if (!fileData) return ''
    if (checkFileSize(fileData.size)) {
      return setUploadError(
        `${UPLOAD_ERROR_TYPES.fileSizeTooLarge} ${FILE_SIZE_LIMIT_MB}MB`,
      )
    }
    setFiles((prevFiles) => [...prevFiles, fileData])
    if (!manual) {
      return onChange(fileData)
    }
  }

  const checkFileTypesAndSize = (
    allowedFileTypes = ['image'],
    fileType,
    fileSize,
  ) => {
    let fileCheckSuccess = true
    if (!fileType || !fileSize) {
      fileCheckSuccess = false
      return setUploadError(`${UPLOAD_ERROR_TYPES.invalidFileType}`)
    }

    allowedFileTypes.forEach((type) => {
      if (!fileType.includes(type)) {
        fileCheckSuccess = false
        return setUploadError(
          `${UPLOAD_ERROR_TYPES.invalidFileType} ${fileType}`,
        )
      }
      if (checkFileSize(fileSize)) {
        fileCheckSuccess = false
        return setUploadError(
          `${UPLOAD_ERROR_TYPES.fileSizeTooLarge} ${FILE_SIZE_LIMIT_MB}MB`,
        )
      }
      setUploadError('')
    })
    return fileCheckSuccess
  }

  const drop = (e) => {
    e.preventDefault()
    if (e.dataTransfer) {
      const draggedAndDroppedFiles = []
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i]
        if (item.kind === 'file') {
          const fileItem = item.getAsFile()
          if (fileItem) {
            const allowed = checkFileTypesAndSize(
              ['image'],
              fileItem.type,
              fileItem.size,
            )
            if (allowed) draggedAndDroppedFiles.push(fileItem)
          }
        }
      }
      setFiles(draggedAndDroppedFiles)
    }
  }

  return (
    <Fragment>
      <StyledFileUploaderWrapper
        id="abc"
        onDragEnter={(e) => {
          e.preventDefault()
        }}
        onDragStart={(e) => {
          e.preventDefault()
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={drop}
      >
        <StyledFileUploaderContent>
          {files.length === 0 && (
            <p>Click here or drag and drop files to upload</p>
          )}
          {files.length === 0 && (
            <ImageIcon
              style={{
                opacity: 0.1,
                height: height || 300,
                width: width || 300,
              }}
            />
          )}
          {files.length === 0 && <Button>Upload files</Button>}
          {files.length > 0 && renderFileView()}
          {uploadError && <p>{uploadError}</p>}
          <input
            ref={fileInputRef}
            aria-hidden="true"
            accept={acceptedFileTypes}
            type="file"
            multiple
            onChange={({ target: { files } }) => {
              Array.from(files).forEach((file) => handleSelectedFile(file))
            }}
          />
        </StyledFileUploaderContent>
      </StyledFileUploaderWrapper>
      {showUploadButton && files.length > 0 && (
        <StyledDocumentsActionsContainer>
          <Button
            margin="20px 20px 0 0"
            startIcon={<CloudUploadIcon />}
            disabled={isUploading}
            onClick={() => {
              fileInputRef.current.click()
            }}
          >
            {uploadButtonText}
          </Button>
          <Button
            disabled={isUploading}
            margin="20px 0 0 0"
            endIcon={
              isUploading ? (
                <IconLoading />
              ) : (
                <SendIcon style={{ color: WHITE }} />
              )
            }
            onClick={() => {
              handleUpload(files)
              if (clearFile) setFiles([])
            }}
          >
            Upload
            {isUploading ? 'ing' : ''}
          </Button>
        </StyledDocumentsActionsContainer>
      )}
    </Fragment>
  )
}

export default FilesUploader
