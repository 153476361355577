import getAxios from 'helpers/axios'
import { ACCOUNT_TYPES } from 'helpers/const'
import { hasCorporateEditorRole } from 'helpers/roleBasedAccess'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast/index'
import { updateKeyRelianceAgreementStatus } from 'redux/customerType/actions'

export const CAPTURE_CORPORATE_DETAILS_START = 'CAPTURE_CORPORATE_DETAILS_START'
export const CAPTURE_ADDRESS_DETAILS_START = 'CAPTURE_ADDRESS_DETAILS_START'

export const UPDATE_CORPORATE_ADDRESS_DETAILS_START =
  'UPDATE_CORPORATE_ADDRESS_DETAILS_START'
export const CAPTURE_CORPORATE_DETAILS_SUCCESS =
  'CAPTURE_CORPORATE_DETAILS_SUCCESS'
export const CAPTURE_ADDRESS_DETAILS_SUCCESS = 'CAPTURE_ADDRESS_DETAILS_SUCCESS'

export const UPDATE_CORPORATE_ADDRESS_DETAILS_SUCCESS =
  'UPDATE_CORPORATE_ADDRESS_DETAILS_SUCCESS'
export const CAPTURE_CORPORATE_DETAILS_ERROR = 'CAPTURE_CORPORATE_DETAILS_ERROR'

export const FETCH_CORPORATE_DETAILS_START = 'FETCH_CORPORATE_DETAILS_START'
export const FETCH_CORPORATE_DETAILS_SUCCESS = 'FETCH_CORPORATE_DETAILS_SUCCESS'
export const FETCH_CORPORATE_DETAILS_ERROR = 'FETCH_CORPORATE_DETAILS_ERROR'
export const CAPTURE_ADDRESS_DETAILS_ERROR = 'CAPTURE_ADDRESS_DETAILS_ERROR'

export const UPDATE_CORPORATE_ADDRESS_DETAILS_ERROR =
  'UPDATE_CORPORATE_ADDRESS_DETAILS_ERROR'

export const UPLOAD_CORPORATE_DOCUMENTS_START =
  'UPLOAD_CORPORATE_DOCUMENTS_START'
export const UPLOAD_CORPORATE_DOCUMENTS_SUCCESS =
  'UPLOAD_CORPORATE_DOCUMENTS_SUCCESS'
export const UPLOAD_CORPORATE_DOCUMENTS_ERROR =
  'UPLOAD_CORPORATE_DOCUMENTS_ERROR'

export const REMOVE_CORPORATE_DOCUMENT_START = 'REMOVE_CORPORATE_DOCUMENT_START'
export const REMOVE_CORPORATE_DOCUMENT_SUCCESS =
  'REMOVE_CORPORATE_DOCUMENT_SUCCESS'
export const REMOVE_CORPORATE_DOCUMENT_ERROR = 'REMOVE_CORPORATE_DOCUMENT_ERROR'

export const REMOVE_CORPORATE_DOCUMENTS_START =
  'REMOVE_CORPORATE_DOCUMENTS_START'
export const REMOVE_CORPORATE_DOCUMENTS_SUCCESS =
  'REMOVE_CORPORATE_DOCUMENTS_SUCCESS'
export const REMOVE_CORPORATE_DOCUMENTS_ERROR =
  'REMOVE_CORPORATE_DOCUMENTS_ERROR'

export const UPDATE_CORPORATE_DETAILS_START = 'UPDATE_CORPORATE_DETAILS_START'
export const UPDATE_CORPORATE_DETAILS_SUCCESS =
  'UPDATE_CORPORATE_DETAILS_SUCCESS'
export const UPDATE_CORPORATE_DETAILS_ERROR = 'UPDATE_CORPORATE_DETAILS_ERROR'
export const REMOVE_CORPORATE_ADDRESS_START = 'REMOVE_CORPORATE_ADDRESS_START'
export const REMOVE_CORPORATE_ADDRESS_SUCCESS =
  'REMOVE_CORPORATE_ADDRESS_SUCCESS'
export const REMOVE_CORPORATE_ADDRESS_ERROR = 'REMOVE_CORPORATE_ADDRESS_ERROR'

export const FETCH_COMPANY_DOCUMENTS_START = 'FETCH_COMPANY_DOCUMENTS_START'
export const FETCH_COMPANY_DOCUMENTS_SUCCESS = 'FETCH_COMPANY_DOCUMENTS_SUCCESS'
export const FETCH_COMPANY_DOCUMENTS_ERROR = 'FETCH_COMPANY_DOCUMENTS_ERROR'

export const CHECK_CORPORATE_EDITOR_ROLE_START =
  'CHECK_CORPORATE_EDITOR_ROLE_START'
export const CHECK_CORPORATE_EDITOR_ROLE_SUCCESS =
  'CHECK_CORPORATE_EDITOR_ROLE_SUCCESS'
export const CHECK_CORPORATE_EDITOR_ROLE_ERROR =
  'CHECK_CORPORATE_EDITOR_ROLE_ERROR'

export const RESTORE_CORPORATE_DOCUMENT_START =
  'RESTORE_CORPORATE_DOCUMENT_START'
export const RESTORE_CORPORATE_DOCUMENT_SUCCESS =
  'RESTORE_CORPORATE_DOCUMENT_SUCCESS'
export const RESTORE_CORPORATE_DOCUMENT_ERROR =
  'RESTORE_CORPORATE_DOCUMENT_ERROR'

export const CLEAR_CORPORPRATE_INFORMATION = 'CLEAR_CORPORPRATE_INFORMATION'

// TODO: We need to get the dual auth status of the account being edited and if there
// is a dual auth for the account to be changing to a corp we pass that value through
// and we also disable all the buttons on the front end

export const captureAccountCorporateInformation =
  (corporateData) => async (dispatch) => {
    try {
      const { accountId } = corporateData
      dispatch({ type: CAPTURE_CORPORATE_DETAILS_START })

      const { data } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}`,
        {
          corporateData,
        },
      )

      dispatch({
        type: CAPTURE_CORPORATE_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: CAPTURE_CORPORATE_DETAILS_ERROR,
      })
    }
  }

export const updateAccountCorporateInformation =
  (updatedInfo, accountId) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CORPORATE_DETAILS_START })
      const { data } = await getAxios().put(
        `/corporates/editor/${accountId}/${false}/update/corporate`,
        {
          updatedInfo,
        },
      )
      dispatch({
        type: UPDATE_CORPORATE_DETAILS_SUCCESS,
        payload: data,
      })
      toast('Corporate information updated successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: UPDATE_CORPORATE_DETAILS_ERROR,
      })
    }
  }

const checkIfCanEditCorporateInformation = (accountId) => async (dispatch) => {
  dispatch({
    type: CHECK_CORPORATE_EDITOR_ROLE_START,
  })
  try {
    const {
      data: { type },
    } = await getAxios().get(`/account/${accountId}/account-type`)
    const isCorporate = type === ACCOUNT_TYPES.CORPORATE
    const hasEditorRole = hasCorporateEditorRole()

    const canEditCorporateInformation = !!(
      !isCorporate ||
      (isCorporate && hasEditorRole)
    )

    dispatch({
      type: CHECK_CORPORATE_EDITOR_ROLE_SUCCESS,
      payload: canEditCorporateInformation,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: CHECK_CORPORATE_EDITOR_ROLE_ERROR })
  }
}

export const fetchAccountCorporateInformation =
  (accountId) => async (dispatch) => {
    try {
      dispatch(checkIfCanEditCorporateInformation(accountId))
      dispatch({ type: FETCH_CORPORATE_DETAILS_START })
      const { data } = await getAxios().get(`/corporates/${accountId}/info`)
      dispatch({
        type: FETCH_CORPORATE_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: FETCH_CORPORATE_DETAILS_ERROR,
      })
    }
  }

export const fetchAccountDocumentsInformation =
  (accountId, corporateType) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_COMPANY_DOCUMENTS_START })
      const { data } = await getAxios().get(
        `/corporates/${accountId}/${corporateType}`,
      )
      dispatch({
        type: FETCH_COMPANY_DOCUMENTS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: FETCH_COMPANY_DOCUMENTS_ERROR,
      })
    }
  }

export const captureAddressInformation =
  (addressInfo, closeFormCallBack) => async (dispatch) => {
    try {
      const { accountId } = addressInfo
      dispatch({ type: CAPTURE_ADDRESS_DETAILS_START })
      const { data } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}/addresses`,
        {
          addressInfo,
        },
      )

      dispatch({
        type: CAPTURE_ADDRESS_DETAILS_SUCCESS,
        payload: data,
      })
      toast('Successfully added address to corporate', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      closeFormCallBack()
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: CAPTURE_ADDRESS_DETAILS_ERROR,
      })
    }
  }

export const updateAccountAddressInformation =
  (accountId, updatedInfo, closeFormCallBack) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CORPORATE_ADDRESS_DETAILS_START })

      const { data } = await getAxios().put(
        `/corporates/editor/${accountId}/${false}/update/addresses`,
        {
          updatedInfo,
        },
      )
      dispatch({
        type: UPDATE_CORPORATE_ADDRESS_DETAILS_SUCCESS,
        payload: data,
      })
      toast('Successfully updated corproate address', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
      closeFormCallBack()
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: UPDATE_CORPORATE_ADDRESS_DETAILS_ERROR,
      })
    }
  }

export const removeCorporateDocument = (documentId, accountId) => async (
  dispatch,
  getState,
) => {
  let startAction = () => { }
  let successAction = () => { }
  let errorAction = () => { }
  try {
    const { companyDocuments } = getState().corporate
    const message = 'Document successfully removed'
    startAction = () => dispatch({ type: REMOVE_CORPORATE_DOCUMENT_START })
    errorAction = () =>
      dispatch({
        type: REMOVE_CORPORATE_DOCUMENT_ERROR,
      })
    successAction = data =>
      dispatch({
        type: REMOVE_CORPORATE_DOCUMENT_SUCCESS,
        payload: data,
      })
    const removedDocument = companyDocuments.find(doc => doc.id === documentId)
    if (
      removedDocument &&
      (removedDocument.documentType === 'key_reliance_agreement_document')
    ) {
      dispatch(updateKeyRelianceAgreementStatus(accountId, false))
    }

    startAction()
    const { data } = await getAxios().delete(
      `/corporates/editor/${accountId}/${false}/remove/document`,
      {
        data: {
          documentId,
        },
      },
    )

    successAction(data)
    toast(message, {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
  } catch (error) {
    ErrorToast(error)
    errorAction()
  }
}

export const removeCorporateDocuments = (
  documentType,
  accountId,
) => async dispatch => {
  const errorAction = () =>
    dispatch({
      type: REMOVE_CORPORATE_DOCUMENTS_ERROR,
    })
  try {
    let message = ''
    message = 'Document successfully removed'
    const startAction = () =>
      dispatch({ type: REMOVE_CORPORATE_DOCUMENTS_START })
    const successAction = data => {
      if (
        documentType === 'key_reliance_agreement_document'
      ) {
        dispatch(updateKeyRelianceAgreementStatus(accountId, false))
      }
      dispatch({
        type: REMOVE_CORPORATE_DOCUMENTS_SUCCESS,
        payload: data,
      })
    }
    startAction()
    const { data } = await getAxios().delete(
      `/corporates/editor/${accountId}/${false}/remove/documents`,
      {
        data: {
          documentType,
        },
      },
    )

    successAction(data)
    toast(message, {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
  } catch (error) {
    ErrorToast(error)
    errorAction()
  }
}

export const restoreDocument = (documentId, accountId) => async (dispatch) => {
  try {
    dispatch({ type: RESTORE_CORPORATE_DOCUMENT_START })
    const { data } = await getAxios().post(
      `/corporates/editor/${accountId}/${false}/restore/document/${documentId}`,
    )
    dispatch({ type: RESTORE_CORPORATE_DOCUMENT_SUCCESS, payload: data })
    toast('Restored document successfully.')
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: RESTORE_CORPORATE_DOCUMENT_ERROR })
  }
}

export const uploadCorporateDocument =
  (values, setProgress, accountId) => async (dispatch) => {
    try {
      const formData = new FormData()
      const { file, corporateId, corporateType, metaData } = values

      const uploadPtyData = {
        corporateId,
        corporateType,
      }

      formData.append('selectedFile', file)
      formData.append('documentType', metaData.documentType)
      formData.append('corporateId', corporateId)
      formData.append('corporateType', corporateType)
      formData.append('accountId', accountId)

      formData.append('uploadPtyData', JSON.stringify(uploadPtyData))
      dispatch({ type: UPLOAD_CORPORATE_DOCUMENTS_START })
      const { data } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}/documents`,
        formData,
        {
          onUploadProgress: (uploadingData) => {
            const progress = Math.round(
              (100 * uploadingData.loaded) / uploadingData.total,
            )

            setProgress(progress)
          },
        },
      )
      setProgress(0)
      dispatch({
        type: UPLOAD_CORPORATE_DOCUMENTS_SUCCESS,
        payload: data,
      })
      toast('Document successfully added', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      if (
        data.companyDocument.documentType === 'key_reliance_agreement_document'
      ) {
        dispatch(updateKeyRelianceAgreementStatus(accountId))
      }
    } catch (error) {
      ErrorToast(error)
      setProgress(0)
      dispatch({ type: UPLOAD_CORPORATE_DOCUMENTS_ERROR })
    }
  }

export const removeCorporateAddress =
  (addressId, accountId) => async (dispatch) => {
    dispatch({ type: REMOVE_CORPORATE_ADDRESS_START })
    try {
      const { data } = await getAxios().delete(
        `/corporates/editor/${accountId}/${false}/${addressId}/remove/addresses`,
      )

      dispatch({
        type: REMOVE_CORPORATE_ADDRESS_SUCCESS,
        payload: data,
      })
      toast('Address removed successfully.', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: REMOVE_CORPORATE_ADDRESS_ERROR })
    }
  }

export const clearCorporateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CORPORPRATE_INFORMATION })
}
