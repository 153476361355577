import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ExpandingItem from 'components/atoms/ExpandingItem'
import { fetchUnassignedBlockchainReceives } from 'redux/unallocatedBlockchainReceives/actions'
import { fetchPendingDualAuth } from 'redux/dual-auth/actions'

import ErrorBoundary from 'helpers/ErrorBoundary'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import Row from './row'
import { Headers } from './headers'
class UnallocatedBlockChainReceives extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      selectedId: -1,
      highlightedId: '',
      expandRawData: '',
      addNoteFormShown: '',
    }
  }

  componentDidMount() {
    this.props.fetchPendingDualAuth()
    this.props.fetchUnassignedBlockchainReceives()
  }

  toggle = panel => {
    this.setState({
      expanded: panel,
    })
  }

  toggleSelected = id => {
    this.setState((prev) => ({
      selectedId: prev.selectedId === id ? -1 : id
    }))
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  showRawData = id =>
    this.setState((prev) => ({
      expandRawData: prev.expandRawData === id ? '' : id
    }))

  toggleAddNotesForm = id =>
    this.setState((prev) => ({
      addNoteFormShown: prev.addNoteFormShown === id ? '' : id
    }))

  checkIfIsPending = (withdrawal, dualAuth) => {
    const pendingValue = dualAuth.data.some(value => {
      if (value.actionType === 'BLOCKCHAIN_RECEIVE_CREDIT') {
        return value.receive.id === withdrawal.id
      }
    })
    return pendingValue
  }

  render() {
    const {
      unassignedBlockChain: { status, data },
      dualAuth,
    } = this.props
    const { expanded } = this.state
    const hasDeposits = data && data.length > 0
    return (
      <ExpandingItem
        title="Unallocated blockchain receives"
        itemsAmount={(data && data.length) || 0}
        expanded={expanded}
        onExpand={this.toggle}
        expandedView={
          <ErrorBoundary message="Unallocated blockchain receives">
            {status === 'done' && !hasDeposits && (
              <NoTableResultsMessage
                messageType={NO_RESULT_MESSAGE.DEPOSITS.type}
              />
            )}
            {hasDeposits && (
              <>
                <Headers />
                {status === 'done' &&
                  data.map(receives => (
                    <Row
                      key={receives.id}
                      unassignedBlockChainReceives={receives}
                      showForm={this.state.selectedId === receives.id}
                      toggleSelected={this.toggleSelected}
                      isHighlighted={this.state.highlightedId === receives.id}
                      setHighlightedId={this.setHighlightedId}
                      toggleAddNotesForm={this.toggleAddNotesForm}
                      addNoteFormShown={this.state.addNoteFormShown}
                      expandRawData={this.state.expandRawData}
                      showRawData={this.showRawData}
                      isPending={this.checkIfIsPending(receives, dualAuth)}
                    />
                  ))}
              </>
            )}
          </ErrorBoundary>
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  unassignedBlockChain: state.unallocatedBlockchainReceives,
  dualAuth: state.pendingDualAuth,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUnassignedBlockchainReceives,
      fetchPendingDualAuth,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnallocatedBlockChainReceives)
