import ErrorToast from 'components/molecules/ErrorToast'
import { REMOVE_SUBMITTED_ITEM } from './index'

export const UPDATE_FUNDS_DUAL_AUTH_ROW_USER_START = 'UPDATE_FUNDS_DUAL_AUTH_ROW_USER_START'
export const UPDATE_FUNDS_DUAL_AUTH_ROW_USER_SUCCESS = 'UPDATE_FUNDS_DUAL_AUTH_ROW_USER_SUCCESS'
export const UPDATE_FUNDS_DUAL_AUTH_ROW_USER_ERROR = 'UPDATE_FUNDS_DUAL_AUTH_ROW_USER_ERROR'

export const removeSubmittedItem = (id) => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_ITEM,
    payload: {
      id,
    },
  })
}

export const updateFundsDualAuthRowUserSocketEvent = newInfo => async dispatch => {
  try {
    dispatch({
      type: UPDATE_FUNDS_DUAL_AUTH_ROW_USER_START,
    })
    dispatch({
      type: UPDATE_FUNDS_DUAL_AUTH_ROW_USER_SUCCESS,
      payload: {
        data: newInfo,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_FUNDS_DUAL_AUTH_ROW_USER_ERROR,
      payload: {
        error,
      },
    })
  }
}
