import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ActionButton, TitleContainer } from 'components/atoms/Table/styles'
import CustomTable from 'components/atoms/CustomTable/index'
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { TableCell } from '@material-ui/core'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import NoData from 'components/molecules/NoData/index'
import IconButton from 'components/atoms/IconButton/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

const RemovedAccounts = props => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [sanctionDataToUpdate, setSanctionDataToUpdate] = useState({})
  const {
    potentialUserInSanctions: { data, updating, status },
  } = props.potentialUserInSanctions
  const { fetchPotentialUsersInSanctionsList } = props
  const filteredAndSortedSanctionsData =
    data &&
    data
      .filter(sanction => sanction.hidden === true)
      .sort((x, y) => x.id - y.id)

  const handleRestoreSanctionClick = data => {
    setSanctionDataToUpdate(data)
    setShowConfirmation(true)
  }

  const processRestoreSanction = () => {
    props.removeFromHiddenSanctionsList({
      hidden: !sanctionDataToUpdate.hidden,
      search_type: sanctionDataToUpdate.search_type,
      id: sanctionDataToUpdate.id,
    })
  }

  const customCellAction = data => (
    <TableCell key={data.id}>
      <IconButton
        icon={<RestoreFromTrash />}
        type="button"
        onClick={e => {
          e.stopPropagation()
          handleRestoreSanctionClick(data)
        }}
      />
    </TableCell>
  )

  useEffect(() => {
    fetchPotentialUsersInSanctionsList(true)

    // return () => {}
  }, [])

  return (
    <div>
      <TitleContainer>
        <h1>Removed Sanctions Accounts list</h1>
        <ActionButton
          onClick={() => props.history.replace('/accounts/sanctions')}
        >
          <ArrowBackIcon />
          Back To Sanctions Accounts
        </ActionButton>
      </TitleContainer>
      {filteredAndSortedSanctionsData &&
        status !== 'loading' &&
        filteredAndSortedSanctionsData.length > 0 && (
          <CustomTable
            headings={[
              'id',
              'account_id',
              'name',
              'surname',
              'search_type',
              'description',
              'created_at',
              'modified_at',
              'status',
            ]}
            customColumnHeadings={[<TableCell>Restore</TableCell>]}
            customColumnValues={[customCellAction]}
            rows={filteredAndSortedSanctionsData}
          />
        )}
      {status === 'loading' && <LoadingBlock />}
      {(!filteredAndSortedSanctionsData && status !== 'loading') ||
        (filteredAndSortedSanctionsData &&
          !filteredAndSortedSanctionsData.length > 0 &&
          status !== 'loading' && (
            <NoData
              headingType="h3"
              headingText="No Removed Sanctions Found"
              message="Remove sanctions on the sanctions page then they will appear here"
            />
          ))}

      <ConfirmationDialog
        open={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        title="Restore sanction"
        message={`${
          updating
            ? 'Restoring sanction'
            : 'Are you sure you want to restore this sanction?'
        }`}
        proceed={processRestoreSanction}
      />
    </div>
  )
}

RemovedAccounts.propTypes = {}

export default RemovedAccounts
