import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { IconButton, OutlinedInput, makeStyles } from '@material-ui/core'
import { connectStateAndDispatch } from 'store'
import {
  clearSearchedRiskProfile,
  initializeIndividualRiskProfileReview,
  searchRiskProfileByAccountId,
  updateSearchedRiskProfileAccountId,
} from 'redux/riskBasedProfiles/actions'
import { FlexRow } from 'components/layout'
import { ArrowBack } from '@material-ui/icons'
import _ from 'lodash'
import {
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'

import IndividualRiskAccountProfiles from './IndividualRiskAccountProfiles'

import SearchedRiskProfile from '../SearchedRiskProfile'
import MuiSelect from 'components/atoms/MuiSelect'

const routes = [
  '/compliance/risk-profiles/management/risk-scoring/individual',
  '/compliance/risk-profiles/management/risk-scoring/individual/pending',
  '/compliance/risk-profiles/management/risk-scoring/individual/monitor',
  '/compliance/risk-profiles/management/risk-scoring/individual/block',
  '/compliance/risk-profiles/management/risk-scoring/individual/terminate',
]

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

interface IIndividualRiskProfileManagementNav {
  searchedRiskProfile: {
    isSearching: boolean
    searchedRiskProfileAccountId: number | null
    data: RiskProfileWithDualAuthStatus | null
  }
  searchRiskProfileByAccountId: (accountId: number) => Promise<void>
  updateSearchedRiskProfileAccountId: (accountId: number) => Promise<void>
  clearSearchedRiskProfile: () => Promise<void>
  initializeIndividualRiskProfileReview: (accountId: number) => Promise<void>
  fetchIndividualRiskBasedProfiles: () => Promise<void>
  fetchMoreRiskBasedProfiles: () => Promise<void>
  type: RecommendationStatusTypes
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
  getAllProfiles: (
    type: RecommendationStatusTypes,
    riskLevelTypes: RiskLevelTypes,
    riskProfileType?: 'CORPORATE' | 'INDIVIDUAL',
  ) => Promise<void>
}

const IndividualRiskProfileManagementNav: FC<
  IIndividualRiskProfileManagementNav
> = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const [riskLevelToFetch, setRiskLevelToFetch] =
    useState<RiskLevelTypes>('All')
  const classes = useStyles()
  const riskLevels: RiskLevelTypes[] = [
    'All',
    'Low',
    'Medium',
    'High',
    'Severe',
  ]
  const debouncedSearch = useMemo(
    () =>
      _.debounce(
        (accId: number) => props.searchRiskProfileByAccountId(accId),
        1000,
      ),
    [],
  )

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const handleRiskProfileSearch = useCallback((e) => {
    props.updateSearchedRiskProfileAccountId(Number(e.target.value))
    debouncedSearch(Number(e.target.value))
  }, [])

  const MemoizedRoutes = useMemo(() => {
    return (
      <ContentContainer>
        <Switch>
          <Route
            path={routes[0]}
            component={(props) => (
              <IndividualRiskAccountProfiles
                riskLevelToSearch={riskLevelToFetch}
                type="NEW"
                {...props}
              />
            )}
            exact
          />
          <Route
            path={routes[1]}
            component={(props) => (
              <IndividualRiskAccountProfiles
                riskLevelToSearch={riskLevelToFetch}
                type="PENDING"
                {...props}
              />
            )}
          />
          <Route
            path={routes[2]}
            component={(props) => (
              <IndividualRiskAccountProfiles
                riskLevelToSearch={riskLevelToFetch}
                type="MONITOR"
                {...props}
              />
            )}
          />
          <Route
            path={routes[3]}
            component={(props) => (
              <IndividualRiskAccountProfiles
                riskLevelToSearch={riskLevelToFetch}
                type="BLOCK"
                {...props}
              />
            )}
          />
          <Route
            path={routes[4]}
            component={(props) => (
              <IndividualRiskAccountProfiles
                riskLevelToSearch={riskLevelToFetch}
                type="TERMINATE"
                {...props}
              />
            )}
          />
        </Switch>
      </ContentContainer>
    )
  }, [riskLevelToFetch])

  useEffect(() => {
    if (props.searchedRiskProfile.searchedRiskProfileAccountId) {
      props.searchRiskProfileByAccountId(
        props.searchedRiskProfile.searchedRiskProfileAccountId,
      )
    }
    return () => {
      props.clearSearchedRiskProfile()
    }
  }, [])

  useEffect(() => {
    setCurrentPage(window.location.pathname)

    return () => {}
  }, [window.location.pathname])

  return (
    <>
      <FlexRow>
        <OutlinedInput
          className={classes.input}
          type="number"
          placeholder="Search for account #"
          value={props.searchedRiskProfile.searchedRiskProfileAccountId || ''}
          onChange={handleRiskProfileSearch}
        />
        <MuiSelect
          handleChange={(e) =>
            setRiskLevelToFetch(e.target.value as RiskLevelTypes)
          }
          label="Risk Level"
          options={riskLevels}
          value={riskLevelToFetch}
        />
        <IconButton onClick={() => props.clearSearchedRiskProfile()}>
          <ArrowBack />
        </IconButton>
      </FlexRow>

      {props.searchedRiskProfile.searchedRiskProfileAccountId ? (
        <SearchedRiskProfile
          isSearching={props.searchedRiskProfile.isSearching}
          searchedRiskProfileAccountId={
            props.searchedRiskProfile.searchedRiskProfileAccountId
          }
          foundRiskProfile={
            props.searchedRiskProfile.data as RiskProfileWithDualAuthStatus
          }
          initializeRiskProfileReview={
            props.initializeIndividualRiskProfileReview
          }
          riskLevelToSearch={riskLevelToFetch}
        />
      ) : (
        <Fragment>
          <TopNavigationBar
            value={currentPage}
            onChange={handleChange}
            variant="fullWidth"
          >
            <NavigationTab
              value={routes[0]}
              label="NEW"
              component={ForwardedNavLink}
              to={routes[0]}
            />
            <NavigationTab
              value={routes[1]}
              label="PENDING"
              component={ForwardedNavLink}
              to={routes[1]}
            />
            <NavigationTab
              value={routes[2]}
              label="MONITOR"
              component={ForwardedNavLink}
              to={routes[2]}
            />
            <NavigationTab
              value={routes[3]}
              label="BLOCK"
              component={ForwardedNavLink}
              to={routes[3]}
            />
            <NavigationTab
              value={routes[4]}
              label="TERMINATE"
              component={ForwardedNavLink}
              to={routes[4]}
            />
          </TopNavigationBar>
          {MemoizedRoutes}
        </Fragment>
      )}
    </>
  )
}

export default connectStateAndDispatch(
  (state) => ({
    searchedRiskProfile: state.riskBasedProfiles.searchedRiskProfile,
  }),
  {
    searchRiskProfileByAccountId,
    clearSearchedRiskProfile,
    updateSearchedRiskProfileAccountId,
    initializeIndividualRiskProfileReview,
  },
  IndividualRiskProfileManagementNav,
)
