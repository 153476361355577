// eslint-disable-next-line import/no-cycle
import {
  FETCH_WITHDRAWAL_LIMIT_PROCESS,
  FETCH_WITHDRAWAL_LIMIT_SUCCESS,
  FETCH_WITHDRAWAL_LIMIT_ERROR,
  FETCH_WITHDRAWAL_LIMIT_BY_ID_PROCESS,
  FETCH_WITHDRAWAL_LIMIT_BY_ID_SUCCESS,
  FETCH_WITHDRAWAL_LIMIT_BY_ID_ERROR,
  REMOVE_SUBMITTED_ITEM,
  UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_SUCCESS,
  UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_ERROR,
  UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_START,
} from './actions'

const initialState = {
  withdrawalLimitStatus: 'pristine',
  error: '',
  withdrawalLimitData: [],
  withdrawalLimitById: {},
  withdrawalLimitByIdLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WITHDRAWAL_LIMIT_PROCESS: {
      return {
        ...state,
        withdrawalLimitStatus: 'loading',
      }
    }

    case FETCH_WITHDRAWAL_LIMIT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        withdrawalLimitStatus: 'done',
        error: '',
        withdrawalLimitData: data,
      }
    }

    case FETCH_WITHDRAWAL_LIMIT_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        withdrawalLimitData: [],
        error,
      }
    }
    case UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_START: {
      return {
        ...state,
        withdrawalLimitStatus: 'loading',
        error: '',
      }
    }

    case UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.withdrawalLimitData &&
        state.withdrawalLimitData.map((withdrawalLimitChangeDualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (
                newUserOn.id === withdrawalLimitChangeDualAuth.id &&
                newUserOn.userActive
              ) {
                withdrawalLimitChangeDualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === withdrawalLimitChangeDualAuth.id &&
                      userOnLine.userActive,
                  ).userName,
                }
              } else {
                withdrawalLimitChangeDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            withdrawalLimitChangeDualAuth.active = {
              userActive: false,
              activePersonName: '',
            }
          }
          return withdrawalLimitChangeDualAuth
        })

      return {
        ...state,
        withdrawalLimitData: updatedDualData,
      }
    }

    case UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        withdrawalLimitStatus: 'pristine',
        error,
      }
    }

    case REMOVE_SUBMITTED_ITEM: {
      const filteredArray = state.withdrawalLimitData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        withdrawalLimitData: filteredArray,
        error: '',
      }
    }

    case FETCH_WITHDRAWAL_LIMIT_BY_ID_PROCESS: {
      return {
        ...state,
        withdrawalLimitById: {},
        withdrawalLimitByIdLoading: true,
      }
    }

    case FETCH_WITHDRAWAL_LIMIT_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        withdrawalLimitByIdLoading: false,
        withdrawalLimitById: data,
        error: '',
      }
    }

    case FETCH_WITHDRAWAL_LIMIT_BY_ID_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        withdrawalLimitById: {},
        withdrawalLimitByIdLoading: false,
        error,
      }
    }
    default:
      return state
  }
}
