import React from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'

const Checkbox = ({
  onClick,
  checked,
  name,
  color = 'primary',
  disabled = false,
}) => {
  return (
    <MuiCheckbox
      disabled={disabled}
      color={color}
      onClick={onClick}
      checked={checked}
      name={name}
    />
  )
}

export default Checkbox
