import FundingEvents from 'containers/Accounts/SingleAccount/FundingEvents'
import { Reducer } from 'redux'
import { FundingInfoAndEventsRowData } from 'components/organisms/DepositFundingInfo/interfaces'
import { ActionTypes } from './actions'

interface ReviewFlags {
  flag: string
  additionalInfo: string
}
export interface FundingEventReviewData {
  accountId: number
  identifier: string
  comment: string
  status: any
  currentStatus: string
}
export interface ReviewPreview {
  advice: string
  flags: ReviewFlags[]
  fundingEvent: FundingEventReviewData
}

type loadingStates = 'pristine' | 'loading' | 'done' | 'error'

export interface FundingEventsData {
  status: loadingStates
  data: FundingInfoAndEventsRowData[]
}

export interface FundingInfoData {
  status: loadingStates
  data: FundingInfoAndEventsRowData[]
  suspendedDeposits:FundingInfoAndEventsRowData[]
}

export interface FundingEventsByStatusData {
  status: loadingStates
  data: FundingInfoAndEventsRowData[]
}

interface FundingEventsInitialState {
  fundingEvents: FundingEventsData
  fundingInfo: FundingInfoData
  fundingReview: ReviewPreview | null
  fundingEventsByStatus: FundingEventsByStatusData
}

interface Action {
  type: ActionTypes
  payload?: any
}

const initialState: FundingEventsInitialState = {
  fundingEvents: {
    status: 'pristine',
    data: [],
  },
  fundingInfo: {
    status: 'pristine',
    data: [],
    suspendedDeposits:[],
  },
  fundingEventsByStatus: {
    status: 'pristine',
    data: [],
  },
  fundingReview: null,
}

const reducer: Reducer<FundingEventsInitialState, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_START': {
      return {
        ...state,
        fundingEvents: {
          ...FundingEvents,
          status: 'loading',
        },
      }
    }

    case 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_ERROR': {
      return {
        ...state,
        fundingEvents: {
          ...FundingEvents,
          status: 'error',
          data: [],
        },
      }
    }

    case 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_SUCCESS': {
      if (action.payload) {
        const { data } = action.payload
        return {
          ...state,
          fundingEvents: {
            ...FundingEvents,
            status: 'done',
            data,
          },
        }
      }
    }

    case 'FETCH_FUNDING_EVENTS_FOR_REVIEW_START': {
      return {
        ...state,
        fundingInfo: {
          ...state.fundingInfo,
          status: 'loading',
        },
      }
    }

    case 'FETCH_FUNDING_EVENTS_FOR_REVIEW_SUCCESS': {
      const { data,suspendedDeposits } = action.payload
      return {
        ...state,
        fundingInfo: {
          ...state.fundingInfo,
          status: 'done',
          data,
          suspendedDeposits,
        },
      }
    }

    case 'FETCH_FUNDING_EVENTS_FOR_REVIEW_ERROR': {
      return {
        ...state,
        fundingInfo: {
          status: 'error',
          data: [],
          suspendedDeposits:[]
        },
      }
    }

    case 'UPDATE_FUNDING_EVENTS': {
      const { values } = action.payload
      const data = state.fundingInfo.data.filter(
        ({ identifier }) => identifier !== values.identifier,
      )
      const suspendedDeposits= state.fundingInfo.suspendedDeposits.filter(
        ({ identifier }) => identifier !== values.identifier,
      )
      if (values.status.value === 'SUSPEND') {
        state.fundingInfo.data.map((deposit) => {
        if (deposit.identifier === values.identifier) {
          deposit.suspended = true
          suspendedDeposits.push(deposit)
        }
      })
      }
      if (values.status.value === 'UNSUSPEND') {
        state.fundingInfo.suspendedDeposits.map((deposit) => {
          if (deposit.identifier === values.identifier) {
            deposit.suspended = false
            data.push(deposit)
          }
        })
        }
      return {
        ...state,
        fundingInfo: {
          ...state.fundingInfo,
          status: 'done',
          data,
          suspendedDeposits,
        },
        fundingReview: null,
      }
    }

    case 'FETCH_FUNDING_DEPOSIT_BY_STATUS_SUCCESS': {
      const { data } = action.payload
      return {
        ...state,
        fundingEventsByStatus: {
          ...state.fundingEventsByStatus,
          status: 'done',
          data,
        },
      }
    }

    case 'FETCH_FUNDING_DEPOSIT_BY_STATUS_PROCESS': {
      return {
        ...state,
        fundingEventsByStatus: {
          ...state.fundingEventsByStatus,
          status: 'loading',
        },
      }
    }

    case 'RESET_FUNDING_DEPOSIT_BY_STATUS': {
      return {
        ...state,
        fundingEventsByStatus: {
          ...state.fundingEventsByStatus,
          status: 'pristine',
          data: [],
        },
      }
    }

    case 'CLEAR_FUNDING_REVIEW':
      return {
        ...state,
        fundingReview: null,
      }

    case 'FETCH_FUNDING_REVIEW_SUCCESS':
      const { data } = action.payload
      return {
        ...state,
        fundingReview: data,
      }
    default:
      return state
  }
}

export default reducer
