import React, { FC, Fragment, useState } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import FuturesStatusForm from 'forms/FuturesForm/index'
import {
  Details,
  InfoBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'

import { oppositeColor, capitalizeFirstLetter } from '../../../helpers/utils'

interface FuturesProps {
  id: string
  futuresActivationStatus: {
    futuresEnabled: boolean
    status: string
  }
  notAuthorized: boolean
}

const EnableFuturesPerAccount: FC<FuturesProps> = props => {
  const [formShown, setFormShown] = useState(false)
  const {
    futuresActivationStatus: { status, futuresEnabled },
    id,
    notAuthorized,
  } = props

  const toggleForm = () => {
    setFormShown(!formShown)
  }

  return (
    <Fragment>
      <Details>
        {status === 'loading' && <IconLoading color={'black'} />}
        {status === 'done' && (
          <Fragment>
            {!formShown && (
              <MuiButton
                onClick={toggleForm}
                disabled={notAuthorized}
                showToolTip={notAuthorized}
                tooltipTitle={
                  notAuthorized
                    ? 'You are not authorized to perform this action'
                    : ''
                }
              >
                    Change Futures Status
              </MuiButton>
            )}
          </Fragment>
        )}
        {formShown &&
              status === 'done' && (
          <FuturesStatusForm
            initialValues={{
              id,
              fieldName: 'FUTURES_ENABLED',
              futuresEnabled,
            }}
            onCancel={toggleForm}
            form={'changeFeaturesStatus'}
            onSubmitSuccess={toggleForm}
          />
        )}
      </Details>
      <InfoBlock
        label={'Futures Enabled'}
        value={capitalizeFirstLetter(futuresEnabled.toString())}
        color={oppositeColor(futuresEnabled)}
      />
    </Fragment>
  )
}

export default EnableFuturesPerAccount
