export const BankSources = {
  STANDARD_BANK_ZA: {
    id: 'STANDARD_BANK_ZA',
    name: 'Standard Bank South Africa',
    accountNumber: '001609890',
    currency: 'ZAR',
    country: 'ZA',
    canCheckout: false,
    csvEnabled: false,
  },
  STANBIC_ZM: {
    id: 'STANBIC_ZM',
    name: 'Stanbic Zambia',
    accountNumber: '9130005627935',
    currency: 'ZMW',
    country: 'ZM',
    canCheckout: true,
    csvEnabled: true,
  },
}
