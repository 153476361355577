import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'

import FormTransferInternal from 'forms/InternalTransfer'
import { connect } from 'react-redux'
import { transferAction } from 'redux/transfer'
import { fetchCurrencies } from 'redux/currencies/actions'
import ErrorBoundary from 'helpers/ErrorBoundary'

class InitiateTransfer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reset: false,
    }
  }
  componentDidMount() {
    this.props.fetchCurrencies()
  }

  mapCurrencyPairs = data => {
    return data.map(properties => {
      var option = { value: properties.shortName, label: properties.shortName }
      return option
    })
  }

  render() {
    const { currencies, resetForm } = this.props

    return (
      <ErrorBoundary message="Initiate Internal Transfer">
        <FormTransferInternal
          onSubmitSuccess={(value, dispatch) => {
            dispatch(transferAction)
          }}
          onCancel={resetForm}
          disabled={currencies.data.length > 0}
          currencies={this.mapCurrencyPairs(currencies.data || [])}
        />
      </ErrorBoundary>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrencies,
      resetForm: () => {
        return reset('InternalTransfer')
      },
    },

    dispatch,
  )
const mapStateToProps = state => ({
  currencies: state.currencies,
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitiateTransfer)
