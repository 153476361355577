import { createTheme } from '@material-ui/core/styles'
import { Theme } from 'interfaces/theme'
import {
  VALR_BLUE_GRAY,
  BLUE,
  WHITE,
  TRANSPARENT_LIGHT,
  TRANSPARENT_DARK,
  BLACK,
  GRAY_0,
  BLUE_GRAY,
  LIGHT_GREEN,
  GRAY_1,
  DARK_MODE_PRIMARY,
  NAVY_BLUE,
  DARK_MIDNIGHT_BLUE,
  DARK_INDIGO,
  DEEP_ROYAL_BLUE,
  BLUISH_GREY,
  VIBRANT_ROYAL_BLUE,
  MIDNIGHT_BLUE,
  DARK_MIDNIGHT_BLUE2,
  themeColors,
  BELL_BLUE,
  LIGHT_GRAY,
  ALICE_BLUE,
} from './colors'
import { generateThemeOverrides } from './generateThemeOverRides'

const darkTheme = {
  container: {
    background: `linear-gradient(95deg, ${NAVY_BLUE}, ${DARK_INDIGO})`,
    text: VALR_BLUE_GRAY,
  },
  formContainer: {
    background: BELL_BLUE,
  },
  contentContainer: {
    boxShadow: TRANSPARENT_DARK,
  },
  sidebarNavigation: {
    background: `linear-gradient(95deg, ${NAVY_BLUE}, ${DARK_MIDNIGHT_BLUE})`,
  },
  navigationTabs: {
    background: DARK_MIDNIGHT_BLUE,
    selected: {
      background: DARK_MODE_PRIMARY,
    },
  },
  table: {
    header: {
      background: NAVY_BLUE,
      text: VALR_BLUE_GRAY,
    },
    row: {
      even: DEEP_ROYAL_BLUE,
      odd: BLUISH_GREY,
      highlighted: VIBRANT_ROYAL_BLUE,
      hover: VIBRANT_ROYAL_BLUE,
      text: WHITE,
    },
    placeholder: {
      background: NAVY_BLUE,
    },
  },
  rawData: {
    background: MIDNIGHT_BLUE,
    text: WHITE,
  },
  corporateSanctions: {
    extraData: {
      background: NAVY_BLUE,
    },
    matchedToSanctionsHeading: VIBRANT_ROYAL_BLUE,
    matchedToDataBackground: DARK_MIDNIGHT_BLUE2,
  },
  loadingBlock: NAVY_BLUE,
  accordion: {
    summary: {
      accordionSummary: VALR_BLUE_GRAY,
    },
  },
  infoLabel: {
    label: VALR_BLUE_GRAY,
    value: WHITE,
  },
  link: {
    text: WHITE,
  },
  colors: {
    titleBackground: VALR_BLUE_GRAY,
    text: WHITE,
    accountIdText: WHITE,
    infoValueText: VALR_BLUE_GRAY,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: DARK_MODE_PRIMARY,
    },
    background: {
      paper: DARK_MIDNIGHT_BLUE,
    },
    // Customize other palette colors for dark mode
  },
  overrides: generateThemeOverrides(themeColors.dark as Theme),
  // Customize other theme properties for dark mode
}
const lightTheme = {
  container: {
    background: WHITE,
    text: BLACK,
  },
  formContainer: {
    background: LIGHT_GRAY,
  },
  contentContainer: {
    boxShadow: TRANSPARENT_LIGHT,
  },
  sidebarNavigation: {
    background: BLUE,
  },
  navigationTabs: {
    background: BLUE,
    selected: {
      background: NAVY_BLUE,
    },
  },
  table: {
    header: {
      background: GRAY_1,
      text: BLACK,
    },
    row: {
      even: BLUE_GRAY,
      odd: LIGHT_GREEN,
      highlighted: VALR_BLUE_GRAY,
      hover: VALR_BLUE_GRAY,
      text: BLACK,
    },
    placeholder: {
      background: GRAY_0,
    },
  },
  rawData: {
    background: WHITE,
    text: BLACK,
  },
  corporateSanctions: {
    extraData: {
      background: WHITE,
    },
    matchedToSanctionsHeading: WHITE,
    matchedToDataBackground: ALICE_BLUE,
  },
  loadingBlock: GRAY_0,
  accordion: {
    summary: {
      accordionSummary: BLACK,
    },
  },
  infoLabel: {
    label: BLACK,
    value: BLACK,
  },
  link: {
    text: BLACK,
  },
  colors: {
    titleBackground: BLUE,
    text: BLUE,
    accountIdText: BLACK,
    infoValueText: BLACK,
  },
  palette: {
    mode: 'light',
    primary: {
      main: BLUE,
    },
    // Customize other palette colors for light mode
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
  },
  overrides: generateThemeOverrides(themeColors.light as Theme),
  // Customize other theme properties for light mode
}

const themes = {
  darkTheme,
  lightTheme,
}

export const getTheme = mode => createTheme(themes[mode])
