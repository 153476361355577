import React, { FC } from 'react'
import { Collapse, TableCell, TableRow } from '@material-ui/core'

import { ConditionalOrder } from 'interfaces/conditionalOrders'
import moment from 'moment'
import { StyledExpandedSectionContainer, StyledExpandTableCell } from './Styles'
import { JsonBlock } from 'components/atoms/Details'
import { Link } from 'components/atoms/Table/rows/clickableId'
interface ConditionalOrdersProps {
  handleOrderClick: React.Dispatch<React.SetStateAction<string>>
  expandRowId: string
  conditionalOrder: ConditionalOrder
}

const ConditionalOrderRow: FC<ConditionalOrdersProps> = (props) => {
  const { handleOrderClick, conditionalOrder, expandRowId } = props

  const {
    accountId,
    currencyPair,
    quantity,
    positionId,
    updatedAt,
    takeProfitTriggerPrice,
    takeProfitPlacePrice,
  } = conditionalOrder
  return (
    <>
      <TableRow onClick={() => handleOrderClick(positionId)}>
        <TableCell>{accountId ? <Link id={accountId} /> : '--'}</TableCell>
        <TableCell>{currencyPair}</TableCell>
        <TableCell>{takeProfitTriggerPrice}</TableCell>
        <TableCell>{takeProfitPlacePrice}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>
          {moment(updatedAt).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
      </TableRow>
      <TableRow className="no-hover">
        <StyledExpandTableCell colSpan={10}>
          <Collapse
            in={expandRowId === positionId}
            timeout="auto"
            unmountOnExit
          >
            <StyledExpandedSectionContainer>
              <JsonBlock src={props.conditionalOrder} title={'Raw Data'} />
            </StyledExpandedSectionContainer>
          </Collapse>
        </StyledExpandTableCell>
      </TableRow>
    </>
  )
}

export default ConditionalOrderRow
