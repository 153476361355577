import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { FormContainer, FormTitle, Button, InputGroup, SendEmail } from 'components/atoms/Form'
import { FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS } from 'redux/activationStatus/actions'
import { BASE } from 'theme/base-units'
import { renderSwitch, renderSendEmail, renderInput } from 'forms/fields'
import { submitNewStatus } from './submit'
import { maxLength256, required } from 'forms/fieldValidators'

const Form = props => {
  const checkChangeStatus = () => {
    const Status = { ...props.state.form.changeStatus }
    var checkStatus = Object.values(Object.values(props.state.status))[0]
    if (checkStatus.status === "done") {
      return Status.values
    }
  }
  const getValues = checkChangeStatus();
  const activationStatus = { ...getValues };
  const activation = activationStatus.activated
  const { submitting, invalid, pristine } = props
  const { handleSubmit, onCancel } = props

  return (
    <FormContainer onSubmit={handleSubmit(submitNewStatus)}>
      <FormTitle>Update Activation Status</FormTitle>
      <InputGroup align={'flex-start'} direction={'rows'}>
        <Field
          label={'Active'}
          name={'activated'}
          marginRight={BASE * 2}
          component={renderSwitch}
        />
        {activation === true && !pristine ?
          <SendEmail>
            <Field
              label={'Send email'}
              name={'sendWelcomeEmail'}
              marginRight={BASE * 2}
              component={renderSwitch}
            />
          </SendEmail>
          : <hr />
        }
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required, maxLength256]}
          component={renderInput}
          fullWidth
        />


      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid || pristine} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'changeStatus',
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch({
      type: FETCH_ACCOUNT_ACTIVATION_STATUS_SUCCESS,
      payload: result,
    })
    props.onCancel()
  },
})(Form)

const mapStateToProps = state => {
  return {
    state,
  }
}

export default connect(
  mapStateToProps,
)(ReduxForm)
