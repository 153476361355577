import getAxios from 'helpers/axios'
import { LEGAL_ENTITY_TYPES } from 'helpers/const'
import { convertToString } from 'helpers/utils'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCHING_CORPORATE_ENTITIES_START =
  'FETCHING_CORPORATE_ENTITIES_START'
export const FETCHING_CORPORATE_ENTITIES_SUCCESS =
  'FETCHING_CORPORATE_ENTITIES_SUCCESS'
export const FETCHING_CORPORATE_ENTITIES_ERROR =
  'FETCHING_CORPORATE_ENTITIES_ERROR'

export const CREATE_NATURAL_ENTITIES_START = 'CREATE_NATURAL_ENTITIES_START'
export const CREATE_NATURAL_ENTITIES_SUCCESS = 'CREATE_NATURAL_ENTITIES_SUCCESS'
export const CREATE_NATURAL_ENTITIES_ERROR = 'CREATE_NATURAL_ENTITIES_ERROR'

export const CREATE_LEGAL_PTY_ENTITIES_START = 'CREATE_LEGAL_PTY_ENTITIES_START'
export const CREATE_LEGAL_PTY_ENTITIES_SUCCESS =
  'CREATE_LEGAL_PTY_ENTITIES_SUCCESS'
export const CREATE_LEGAL_PTY_ENTITIES_ERROR = 'CREATE_LEGAL_PTY_ENTITIES_ERROR'

export const CREATE_LEGAL_TRUST_ENTITIES_START =
  'CREATE_LEGAL_TRUST_ENTITIES_START'
export const CREATE_LEGAL_TRUST_ENTITIES_SUCCESS =
  'CREATE_LEGAL_TRUST_ENTITIES_SUCCESS'
export const CREATE_LEGAL_TRUST_ENTITIES_ERROR =
  'CREATE_LEGAL_TRUST_ENTITIES_ERROR'

export const CREATE_LEGAL_OTHER_ENTITIES_START =
  'CREATE_LEGAL_OTHER_ENTITIES_START'
export const CREATE_LEGAL_OTHER_ENTITIES_SUCCESS =
  'CREATE_LEGAL_OTHER_ENTITIES_SUCCESS'
export const CREATE_LEGAL_OTHER_ENTITIES_ERROR =
  'CREATE_LEGAL_OTHER_ENTITIES_ERROR'

export const UPDATE_NATURAL_ENTITY_START = 'UPDATE_NATURAL_ENTITY_START'
export const UPDATE_NATURAL_ENTITY_SUCCESS = 'UPDATE_NATURAL_ENTITY_SUCCESS'
export const UPDATE_NATURAL_ENTITY_ERROR = 'UPDATE_NATURAL_ENTITY_ERROR'

export const UPDATE_LEGAL_PTY_ENTITY_START = 'UPDATE_LEGAL_PTY_ENTITY_START'
export const UPDATE_LEGAL_PTY_ENTITY_SUCCESS = 'UPDATE_LEGAL_PTY_ENTITY_SUCCESS'
export const UPDATE_LEGAL_PTY_ENTITY_ERROR = 'UPDATE_LEGAL_PTY_ENTITY_ERROR'

export const UPDATE_LEGAL_TRUST_ENTITY_START = 'UPDATE_LEGAL_TRUST_ENTITY_START'
export const UPDATE_LEGAL_TRUST_ENTITY_SUCCESS =
  'UPDATE_LEGAL_TRUST_ENTITY_SUCCESS'
export const UPDATE_LEGAL_TRUST_ENTITY_ERROR = 'UPDATE_LEGAL_TRUST_ENTITY_ERROR'

export const UPDATE_LEGAL_OTHER_ENTITY_START = 'UPDATE_LEGAL_OTHER_ENTITY_START'
export const UPDATE_LEGAL_OTHER_ENTITY_SUCCESS =
  'UPDATE_LEGAL_OTHER_ENTITY_SUCCESS'
export const UPDATE_LEGAL_OTHER_ENTITY_ERROR = 'UPDATE_LEGAL_OTHER_ENTITY_ERROR'

export const UPDATE_NATURAL_ENTITIES = 'UPDATE_NATURAL_ENTITIES'
export const UPDATE_LEGAL_PTY_ENTITIES = 'UPDATE_LEGAL_PTY_ENTITIES'
export const UPDATE_LEGAL_TRUST_ENTITIES = 'UPDATE_LEGAL_TRUST_ENTITIES'
export const UPDATE_LEGAL_OTHER_ENTITIES = 'UPDATE_LEGAL_OTHER_ENTITIES'

export const UPDATE_NATURAL_ENTITIES_UPLOAD_COMPLETE =
  'UPDATE_NATURAL_ENTITIES_UPLOAD_COMPLETE'
export const UPDATE_NATURAL_ENTITIES_UPLOAD_FAILURE =
  'UPDATE_NATURAL_ENTITIES_UPLOAD_FAILURE'

export const UPDATE_LEGAL_ENTITIES_UPLOAD_COMPLETE =
  'UPDATE_LEGAL_ENTITIES_UPLOAD_COMPLETE'
export const UPDATE_LEGAL_ENTITIES_UPLOAD_FAILURE =
  'UPDATE_LEGAL_ENTITIES_UPLOAD_FAILURE'

export const LINK_ENTITY_START = 'LINK_ENTITY_START'
export const LINK_ENTITY_SUCCESS = 'LINK_ENTITY_SUCCESS'
export const LINK_ENTITY_ERROR = 'LINK_ENTITY_ERROR'

export const UPDATE_LINKED_ENTITY_START = 'UPDATE_LINKED_ENTITY_START'
export const UPDATE_LINKED_ENTITY_SUCCESS = 'UPDATE_LINKED_ENTITY_SUCCESS'
export const UPDATE_LINKED_ENTITY_ERROR = 'UPDATE_LINKED_ENTITY_ERROR'

export const DEACTIVATE_NATURAL_ENTITY_START = 'DEACTIVATE_NATURAL_ENTITY_START'
export const DEACTIVATE_NATURAL_ENTITY_SUCCESS =
  'DEACTIVATE_NATURAL_ENTITY_SUCCESS'
export const DEACTIVATE_NATURAL_ENTITY_ERROR = 'DEACTIVATE_NATURAL_ENTITY_ERROR'

export const DEACTIVATE_LEGAL_ENTITY_START = 'DEACTIVATE_LEGAL_ENTITY_START'
export const DEACTIVATE_LEGAL_ENTITY_SUCCESS = 'DEACTIVATE_LEGAL_ENTITY_SUCCESS'
export const DEACTIVATE_LEGAL_ENTITY_ERROR = 'DEACTIVATE_LEGAL_ENTITY_ERROR'

export const CLEAR_CORPORATE_ENTITIES = 'CLEAR_CORPORATE_ENTITIES'

export const REMOVE_ENTITY_DOCUMENT_START = 'REMOVE_ENTITY_DOCUMENT_START'
export const REMOVE_ENTITY_DOCUMENT_SUCCESS = 'REMOVE_ENTITY_DOCUMENT_SUCCESS'
export const REMOVE_ENTITY_DOCUMENT_ERROR = 'REMOVE_ENTITY_DOCUMENT_ERROR'

const toastUploadSuccessWithFileUploadError = () =>
  ErrorToast(
    'Entity was created successfully but failed to upload files please contact support if this problem persits',
  )

// TODO: We need to get the dual auth status of the account being edited and if there
// is a dual auth for the account to be changing to a corp we pass that value through
// and we also disable all the buttons on the front end

export const updateNaturalEntities = (entitiesData) => async (dispatch) => {
  dispatch({
    type: UPDATE_NATURAL_ENTITIES,
    payload: entitiesData,
  })
}
export const updateLeglaPtyEntities = (entitiesData) => async (dispatch) => {
  dispatch({
    type: UPDATE_LEGAL_PTY_ENTITIES,
    payload: entitiesData,
  })
}
export const updateLegalTrustEntities = (entitiesData) => async (dispatch) => {
  dispatch({
    type: UPDATE_LEGAL_TRUST_ENTITIES,
    payload: entitiesData,
  })
}
export const updateLegalOtherEntities = (entitiesData) => async (dispatch) => {
  dispatch({
    type: UPDATE_LEGAL_OTHER_ENTITIES,
    payload: entitiesData,
  })
}

export const handleUploadEntityDocumentWithProgress =
  (
    documentType,
    entityId,
    accountId,
    entityType,
    file,
    setUploadProgress,
    setUploadErrorMessage,
  ) =>
  async (dispatch) => {
    const isPendingDualAuth = false
    try {
      const formData = new FormData()
      formData.append('selectedFile', file)
      const { data } = await getAxios().put(
        `corporates/editor/${accountId}/${isPendingDualAuth}/entities/${entityId}/${entityType}/upload/${documentType}`,
        formData,
        {
          onUploadProgress: (uploadingData) => {
            const progress = Math.round(
              (100 * uploadingData.loaded) / uploadingData.total,
            )
            setUploadProgress(progress)
          },
        },
      )

      switch (entityType.toUpperCase()) {
        case LEGAL_ENTITY_TYPES.PTY:
        case LEGAL_ENTITY_TYPES.TRUST:
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: UPDATE_LEGAL_ENTITIES_UPLOAD_COMPLETE,
            payload: { data, entityType, entityId },
          })
          break
        case 'NATURAL':
          dispatch({
            type: UPDATE_NATURAL_ENTITIES_UPLOAD_COMPLETE,
            payload: { data, entityId },
          })
          break

        default:
          break
      }

      setUploadProgress(0)

      toast(
        `${convertToString(documentType, '_')} document uploaded successfully`,
        {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        },
      )
    } catch (error) {
      switch (entityType.toUpperCase()) {
        case LEGAL_ENTITY_TYPES.PTY:
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: UPDATE_LEGAL_ENTITIES_UPLOAD_FAILURE,
          })
          break
        case 'NATURAL':
          dispatch({
            type: UPDATE_NATURAL_ENTITIES_UPLOAD_FAILURE,
          })
          break

        default:
          break
      }
      setUploadErrorMessage(
        error.response.data.message ||
          'An error occurred when uploading documents',
      )
      toast(
        `${
          error.response.data.message ||
          'An error occurred when uploading documents'
        }. Please contact support if this problem persits` ||
          'Oops something went wrong when uploading documents',
        { type: toast.TYPE.ERROR, autoClose: 3000 },
      )
    }
  }

export const createNaturalEntityRequest =
  (
    values,
    uploadEntityFilesCallBack,
    corporateAccountId,
    accountId,
    closeModal,
  ) =>
  async (dispatch) => {
    dispatch({
      type: CREATE_NATURAL_ENTITIES_START,
    })
    try {
      const {
        files = [],
        firstName,
        lastName,
        roles = [],
        idNumber,
        nationality,
        isPep,
      } = values

      const entityDataToCreate = {
        firstName,
        lastName,
        roles,
        idNumber,
        nationality,
        isPep,
      }
      const corporateAccountData = {
        corporateAccountId,
        accountId,
      }

      const { data: createdEntityData } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}/entities/natural`,
        { entityData: entityDataToCreate, corporateAccountData },
      )

      try {
        await uploadEntityFilesCallBack(files, createdEntityData.entityData.id)
      } catch (error) {
        toastUploadSuccessWithFileUploadError()
      }

      const {
        data: { entityData },
      } = await getAxios().get(
        `/corporates/entities/${createdEntityData.entityData.id}/NATURAL`,
      )
      dispatch({
        type: CREATE_NATURAL_ENTITIES_SUCCESS,
        payload: entityData,
      })
      toast('Natural entity created successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      ErrorToast(error)
    }
  }

export const updateNaturalEntityRequest =
  (values, uploadEntityFilesCallBack, accountId, closeModal) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_NATURAL_ENTITY_START,
    })
    try {
      const {
        files = [],
        firstName,
        lastName,
        roles = [],
        idNumber,
        id: entityId,
        nationality,
        isPep,
      } = values

      const entityDataToUpdate = {
        entityId,
        firstName,
        lastName,
        roles,
        idNumber,
        nationality,
        isPep,
      }

      const {
        data: { entityData: updatedEntityData },
      } = await getAxios().put(
        `/corporates/editor/${accountId}/${false}/entities/natural`,
        { entityData: entityDataToUpdate },
      )

      await uploadEntityFilesCallBack(files, updatedEntityData.id)

      const {
        data: { entityData },
      } = await getAxios().get(
        `/corporates/entities/${updatedEntityData.id}/NATURAL`,
      )
      dispatch({
        type: UPDATE_NATURAL_ENTITY_SUCCESS,
        payload: entityData,
      })
      toast('Natural entity updated successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      dispatch({
        type: UPDATE_NATURAL_ENTITY_ERROR,
      })

      ErrorToast(error)
    }
  }

export const createLegalEntityRequest =
  (
    values,
    uploadEntityFilesCallBack,
    corporateAccountId,
    accountId,
    closeModal,
  ) =>
  async (dispatch) => {
    try {
      const {
        files = [],
        name,
        registrationNumber,
        // TODO: why is this not used ?
        otherDocumentTypeName,
        id: entityId,
        entityType,
      } = values
      const entitiyDataToCreate = {
        entityId,
        name,
        registrationNumber,
        entityType,
      }
      const corporateAccountData = {
        corporateAccountId,
        accountId,
      }

      switch (entityType) {
        case LEGAL_ENTITY_TYPES.PTY:
          dispatch({
            type: CREATE_LEGAL_PTY_ENTITIES_START,
            payload: entityId,
          })

          break
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: CREATE_LEGAL_TRUST_ENTITIES_START,
            payload: entityId,
          })
          break
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: CREATE_LEGAL_OTHER_ENTITIES_START,
            payload: entityId,
          })
          break

        default:
          break
      }

      const {
        data: { entityData: updatedEntityData },
      } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}/entities/legal`,
        { entityData: entitiyDataToCreate, corporateAccountData },
      )

      try {
        await uploadEntityFilesCallBack(files, updatedEntityData.id)
      } catch (error) {
        toastUploadSuccessWithFileUploadError()
      }

      const {
        data: { entityData },
      } = await getAxios().get(
        `/corporates/entities/${updatedEntityData.id}/${entityType}`,
      )

      switch (entityType.toUpperCase()) {
        case LEGAL_ENTITY_TYPES.PTY:
          dispatch({
            type: CREATE_LEGAL_PTY_ENTITIES_SUCCESS,
            payload: entityData,
          })

          break
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: CREATE_LEGAL_TRUST_ENTITIES_SUCCESS,
            payload: entityData,
          })
          break
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: CREATE_LEGAL_OTHER_ENTITIES_SUCCESS,
            payload: entityData,
          })
          break

        default:
          break
      }

      toast(` Legal ${entityType} entity created successfully`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      ErrorToast(error)
    }
  }

export const updateLegalEntityRequest =
  (values, uploadEntityFilesCallBack, accountId, closeModal) =>
  async (dispatch) => {
    const {
      files = [],
      name,
      registrationNumber,
      // TODO: why is this not used
      otherDocumentTypeName,
      id: entityId,
      entityType,
    } = values
    const entityDataToUpdate = {
      entityId,
      name,
      registrationNumber,
      entityType,
    }
    try {
      switch (entityType) {
        case LEGAL_ENTITY_TYPES.PTY:
          dispatch({
            type: UPDATE_LEGAL_PTY_ENTITY_START,
          })
          break
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: UPDATE_LEGAL_TRUST_ENTITY_START,
          })
          break
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: UPDATE_LEGAL_OTHER_ENTITY_START,
          })
          break

        default:
          break
      }

      const {
        data: { entityData: updatedEntityData },
      } = await getAxios().put(
        `/corporates/editor/${accountId}/${false}/entities/legal`,
        { entityData: entityDataToUpdate },
      )

      await uploadEntityFilesCallBack(files, updatedEntityData.id)

      const {
        data: { entityData },
      } = await getAxios().get(
        `/corporates/entities/${updatedEntityData.id}/${entityType}`,
      )

      switch (entityType) {
        case LEGAL_ENTITY_TYPES.PTY:
          dispatch({
            type: UPDATE_LEGAL_PTY_ENTITY_SUCCESS,
            payload: entityData,
          })
          break
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: UPDATE_LEGAL_TRUST_ENTITY_SUCCESS,
            payload: entityData,
          })
          break
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: UPDATE_LEGAL_OTHER_ENTITY_SUCCESS,
            payload: entityData,
          })
          break

        default:
          break
      }

      toast(`Legal ${entityType} entity updated successfully`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      switch (entityType) {
        case LEGAL_ENTITY_TYPES.PTY:
          dispatch({
            type: UPDATE_LEGAL_PTY_ENTITY_ERROR,
          })
          break
        case LEGAL_ENTITY_TYPES.TRUST:
          dispatch({
            type: UPDATE_LEGAL_TRUST_ENTITY_ERROR,
          })
          break
        case LEGAL_ENTITY_TYPES.OTHER:
          dispatch({
            type: UPDATE_LEGAL_OTHER_ENTITY_ERROR,
          })
          break

        default:
          break
      }
      ErrorToast(error)
    }
  }

export const getEntitiesForCorporate =
  (corporateAccountId) => async (dispatch) => {
    try {
      dispatch({
        type: FETCHING_CORPORATE_ENTITIES_START,
      })
      const {
        data: {
          naturalEntities,
          legalPtyEntities,
          legalTrustEntities,
          legalOtherEntities,
        },
      } = await getAxios().get(`/corporates/${corporateAccountId}/entities`)
      await dispatch(updateNaturalEntities(naturalEntities))
      await dispatch(updateLeglaPtyEntities(legalPtyEntities))
      await dispatch(updateLegalTrustEntities(legalTrustEntities))
      await dispatch(updateLegalOtherEntities(legalOtherEntities))
      dispatch({
        type: FETCHING_CORPORATE_ENTITIES_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: FETCHING_CORPORATE_ENTITIES_ERROR,
      })
      ErrorToast(error)
    }
  }

const checkIfEntityIsLinkedAlready = (naturalEntities, accountIdToLink) => {
  const entityIsLinked =
    naturalEntities.findIndex(
      (entity) => entity.linkedAccountId === accountIdToLink,
    ) !== -1

  if (entityIsLinked) {
    throw new Error(
      `Sorry this entity with account id ${accountIdToLink} is linked already!`,
    )
  }
}

export const linkEntityAndAddToNaturalEntities =
  (dataToLinkAccount, closeModal) => async (dispatch, getState) => {
    const { accountId } = dataToLinkAccount
    try {
      const { naturalEntities } = getState().corporateEntities
      checkIfEntityIsLinkedAlready(
        naturalEntities,
        dataToLinkAccount.accountIdToLink,
      )

      dispatch({
        type: LINK_ENTITY_START,
      })
      const {
        data: { entityData },
      } = await getAxios().post(
        `/corporates/editor/${accountId}/${false}/entities/natural/link-account`,
        dataToLinkAccount,
      )

      dispatch({
        type: LINK_ENTITY_SUCCESS,
        payload: entityData,
      })
      toast('Linked and created entity successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      dispatch({
        type: LINK_ENTITY_ERROR,
      })
      ErrorToast(error)
    }
  }

export const updateLinkedNaturalEntity =
  (linkedNaturalEntityData, accountId, closeModal) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_LINKED_ENTITY_START,
      })
      const {
        firstName,
        lastName,
        roles,
        idNumber,
        id: entityId,
        nationality,
        isPep,
      } = linkedNaturalEntityData

      const {
        data: { entityData },
      } = await getAxios().put(
        `/corporates/editor/${accountId}/${false}/entities/natural/linked-account`,
        {
          entityId,
          firstName,
          lastName,
          roles,
          idNumber,
          nationality,
          isPep,
        },
      )

      dispatch({
        type: UPDATE_LINKED_ENTITY_SUCCESS,
        payload: entityData,
      })
      toast('Natural entity updated successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
      closeModal()
    } catch (error) {
      dispatch({
        type: UPDATE_LINKED_ENTITY_ERROR,
      })
      ErrorToast(error)
    }
  }

export const deactivateNaturalEntityForCorporate =
  (entityId, accountId) => async (dispatch) => {
    dispatch({
      type: DEACTIVATE_NATURAL_ENTITY_START,
    })
    try {
      const {
        data: { id },
      } = await getAxios().delete(
        `corporates/editor/${accountId}/${false}/entities/${entityId}/natural`,
      )

      dispatch({
        type: DEACTIVATE_NATURAL_ENTITY_SUCCESS,
        payload: id,
      })
      toast('Natural entity removed successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: DEACTIVATE_NATURAL_ENTITY_ERROR,
      })
    }
  }

export const deactivateLegalEntityForCorporate =
  (entityId, accountId) => async (dispatch) => {
    dispatch({
      type: DEACTIVATE_LEGAL_ENTITY_START,
    })
    try {
      const { data } = await getAxios().delete(
        `corporates/editor/${accountId}/${false}/entities/${entityId}/legal`,
      )
      dispatch({
        type: DEACTIVATE_LEGAL_ENTITY_SUCCESS,
        payload: data,
      })
      toast('Legal entity remove successfully', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: DEACTIVATE_LEGAL_ENTITY_ERROR,
      })
    }
  }

export const clearCorporateEntitiesState = () => async (dispatch) => {
  dispatch({ type: CLEAR_CORPORATE_ENTITIES })
}

export const removeEntityDocument =
  (documentId, accountId, entityId) => async (dispatch) => {
    const errorAction = () =>
      dispatch({
        type: REMOVE_ENTITY_DOCUMENT_ERROR,
      })
    try {
      let message = ''
      message = 'Document successfully removed'
      const startAction = () => dispatch({ type: REMOVE_ENTITY_DOCUMENT_START })

      const successAction = (data) => {
        dispatch({
          type: REMOVE_ENTITY_DOCUMENT_SUCCESS,
          payload: { ...data, entityId },
        })
      }
      startAction()
      const { data } = await getAxios().delete(
        `/corporates/editor/${accountId}/${false}/entities/document`,
        {
          data: {
            documentId,
          },
        },
      )
      successAction(data)
      toast(message, {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } catch (error) {
      ErrorToast(error)
      errorAction()
    }
  }
