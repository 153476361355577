import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic/index'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import KeycloakGroupMembership from './KeycloakGroupMembership'
import Groups from './Groups'
import ManageKeycloakRoles from './Roles'

const routes = [
  '/ferryman/groups',
  '/ferryman/roles',
  '/ferryman/group-membership',
]

const Keycloak = (props) => {
  const {
    location: { pathname },
  } = props
  const [currentPage, setCurrentPage] = useState(routes[0])

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
  }, [pathname])

  return (
    <>
      <h1>Keycloak</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          to={routes[0]}
          component={ForwardedNavLink}
          label="Groups"
        />
        <NavigationTab
          value={routes[1]}
          to={routes[1]}
          component={ForwardedNavLink}
          label="Roles"
        />
        <NavigationTab
          value={routes[2]}
          to={routes[2]}
          component={ForwardedNavLink}
          label="Group Membership"
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={Groups} />
          <Route path={routes[1]} component={ManageKeycloakRoles} />
          <Route path={routes[2]} component={KeycloakGroupMembership} />
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Keycloak
