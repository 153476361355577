import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  Button,
  FormBounces,
} from 'components/atoms/Form'
import { submit } from './submit'

let Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onSubmit } = props
  return (
    <FormBounces onSubmit={handleSubmit(onSubmit)}>
      <Button type="submit" main disabled={submitting || invalid}>
        Add to Global Unsubscribe
      </Button>
    </FormBounces>
  )
}

Form = reduxForm({ form: 'addGlobalUnsubscribe', onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
