import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { InfoBlock } from 'components/atoms/Details/index'

const DepositReferencesInfo = ({ depositReferences, currencies }) => {
  return (
    <div>
      {depositReferences.map(item => (
        <div key={item.reference}>
          {!currencies.includes(
            item.buyCurrency.shortName,
          ) ? (
            <InfoBlock
              canCopy
              customCopyValue={item.reference}
              value={`${item.reference} (Auto-Buy ${
                item.buyCurrency.shortName
              })`}
            />
          ) : (
            <InfoBlock
              canCopy
              customCopyValue={item.reference}
              value={`${item.reference} (${item.buyCurrency.shortName})`}
            />
          )}
        </div>
      ))}
    </div>
  )
}

DepositReferencesInfo.propTypes = {
  depositReferences: PropTypes.arrayOf(
    PropTypes.shape({
      buyCurrency: PropTypes.shape({
        collateral: PropTypes.bool,
        collateralWeight: PropTypes.string,
        decimalPlaces: PropTypes.number,
        isActive: PropTypes.bool,
        longName: PropTypes.string,
        shortName: PropTypes.string,
        supportedWithdrawDecimalPlaces: PropTypes.number,
        symbol: PropTypes.string,
      }),
      reference: PropTypes.string,
    }),
  ),
}

export default DepositReferencesInfo
