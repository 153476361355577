import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import { maxLength256, required } from 'forms/fieldValidators'
import { renderInput } from 'forms/fields'
import { Button, FormContainer, FormError, InputGroup } from 'components/atoms/Form/index'

import { ActionsWrapper } from './Styles'

let Form = props => {
    const { submitting, error, invalid } = props
    const { handleSubmit, onCancel, onSubmit } = props
    return (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputGroup align={'flex-start'} direction={'column'}>
                <Field
                    label={'Comment (required)'}
                    name={'comment'}
                    width={'100%'}
                    validate={[required, maxLength256]}
                    component={renderInput}
                    marginBottom={26}
                    labelWidth={'150px'}
                />
            </InputGroup>
            <ActionsWrapper>
                <Button
                    margin={'0px 8px 0px 0px'}
                    padding={'16px 41px'}
                    onClick={onCancel}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    disabled={submitting || invalid}
                    margin={`0 ${BASE * 2}px 0 0`}
                >
                    Proceed
                </Button>
            </ActionsWrapper>
            {error && <FormError error={error} />}
        </FormContainer>
    )
}

Form = reduxForm()(Form)
const FundingConfirmation = connect()(Form)

export default FundingConfirmation
