import { groupBy } from 'lodash';
import { addCurrencySymbol } from '../../../helpers/currency';

export const addTotalAmount = deposits => {
  if (deposits.length === 0) return 0
  const groupedTotals = groupBy(deposits
    .filter(d => d.statuses.some(s => s.status === 'ACCOUNT_CREDITED')), 'currency')


  return Object.keys(groupedTotals).map(symbol => {
    const total = (groupedTotals[symbol] || []).reduce((accumulator, value) => {
      const { amount } = value
      const checkedCurrentValue = amount
        ? parseFloat(
          typeof amount === 'string' ? amount.split(',').join('') : amount,
        )
        : 0
      return accumulator + checkedCurrentValue
    }, 0)

    const currencySymbol = [null, undefined, '', 'null', 'undefined'].includes(symbol) ? 'ZAR' : symbol
    return `${addCurrencySymbol(total, currencySymbol)}`
  }).join(' - ')
}
