import { EXCHANGE_DEPOSIT_TYPES } from 'helpers/const'

export const showSwitchButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.depositForReview:
    case EXCHANGE_DEPOSIT_TYPES.potentialDuplicates:
    case EXCHANGE_DEPOSIT_TYPES.unknownReferences:
    case EXCHANGE_DEPOSIT_TYPES.suspended:
    case EXCHANGE_DEPOSIT_TYPES.fallBackImport:
    case EXCHANGE_DEPOSIT_TYPES.newDeposits:
      show = true
      break
    default:
      break
  }
  return show
}

export const showReleaseButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.newDeposits:
      show = true
      break
    default:
      break
  }
  return show
}

export const showRejectButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.depositForReview:
    case EXCHANGE_DEPOSIT_TYPES.potentialDuplicates:
    case EXCHANGE_DEPOSIT_TYPES.unknownReferences:
    case EXCHANGE_DEPOSIT_TYPES.suspended:
    case EXCHANGE_DEPOSIT_TYPES.fallBackImport:
    case EXCHANGE_DEPOSIT_TYPES.temporarySuspended:
      show = true
      break
    default:
      break
  }
  return show
}

export const showApproveButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.fallBackImport:
      show = true
      break
    default:
      break
  }
  return show
}

export const showBulkActionButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.depositForReview:
    case EXCHANGE_DEPOSIT_TYPES.potentialDuplicates:
    case EXCHANGE_DEPOSIT_TYPES.unknownReferences:
    case EXCHANGE_DEPOSIT_TYPES.fallBackImport:
      show = true
      break

    default:
      break
  }
  return show
}

export const showSendEmailActionButton = (dataType) => {
  let show = false
  switch (dataType) {
    case EXCHANGE_DEPOSIT_TYPES.depositForReview:
    case EXCHANGE_DEPOSIT_TYPES.potentialDuplicates:
    case EXCHANGE_DEPOSIT_TYPES.fallBackImport:
      show = true
      break

    default:
      break
  }
  return show
}
