import {
  FETCH_MANUAL_KYC_ATTEMPTS_PROCCESS,
  FETCH_MANUAL_KYC_ATTEMPTS_SUCCESS,
  FETCH_MANUAL_KYC_ATTEMPTS_ERROR,
} from './actions'

const initialState = {
  manualData: [],
  manualStatus: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANUAL_KYC_ATTEMPTS_PROCCESS: {
      return {
        ...state,
        manualStatus: 'loading',
        manualData: [],
      }
    }
    case FETCH_MANUAL_KYC_ATTEMPTS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        manualData: data,
        manualStatus: 'done',
      }
    }

    case FETCH_MANUAL_KYC_ATTEMPTS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        manualStatus: 'error',
      }
    }

    default:
      return state
  }
}
