import { Theme } from 'interfaces/theme'

export const RED = '#e42013'
export const TRANSPARENT_PINK = 'rgba(228, 32, 19, 0.1)'
export const ORANGE = '#ffbb3a'
export const GREEN = '#7CB246'
export const LIGHT_GREEN = '#e3e9dd'
export const LIGHT_BLUE = '#36b3eb'
export const BLUE = '#376fff'
export const DARK_BLUE = '#3550b2'
export const VIOLET = '#6846c3'
export const WHITE = '#FFF'
export const GRAY_0 = '#F9F9F9'
export const GRAY_1 = '#dee1e3'
export const GRAY_2 = '#B9BBBD'
export const GRAY_3 = '#6e6e6e'
export const BLUE_GRAY = '#e4ebef'
export const VALR_BLUE_GRAY = '#acc2fa'
export const LIGHT_GRAY = 'rgb(249, 249, 249)'
export const BLACK = '#444444'
export const TRANSPARENT_LIGHT = '#0000002f'
export const TRANSPARENT_DARK = '#000000b3'
export const DARK_MODE_PRIMARY = '#678ff3'
export const NAVY_BLUE = '#293a70'
export const DARK_MIDNIGHT_BLUE = '#151c36'
export const DARK_INDIGO = '#0b122e'
export const DEEP_ROYAL_BLUE = '#1a2546'
export const BLUISH_GREY = '#66739d'
export const VIBRANT_ROYAL_BLUE = '#435db0'
export const MIDNIGHT_BLUE = '#131b32'
export const DARK_MIDNIGHT_BLUE2 = '#0a122e'
export const DARK_BLUE_GREY = '#435db0'
export const MIDNIGHT_GRAY = '#2c324a'
export const BELL_BLUE = '#2f438a'
export const ALICE_BLUE = 'aliceblue'

export const PAGINATION_ACTIVE = BLUE
export const PAGINATION_NORMAL = GRAY_2

export const WARNING_COLOR = ORANGE
export const ERROR_COLOR = RED
export const INPUT_LABEL_COLOR = GRAY_3
export const TABLE_HEADER = GRAY_2
export const BORDER_COLOR = GRAY_2
export const LIGHT_GREY = GRAY_1
export const LIGHTEST_GREY = GRAY_0
export const DARK_GREY = GRAY_3
export const DROPDOWN_ARROW_BG_COLOR = GRAY_1
export const PANEL_BLUE = BLUE
export const TIMELINE_COLOR = GRAY_1

export const DEFAULT_THEME = {
  color: {
    even: BLUE_GRAY,
    odd: GRAY_2,
    text: PANEL_BLUE,
    hover: '#c4deed',
    headerBg: '#bfc9d4',
  },
}

export const ENDPOINT_THEME = {
  color: {
    even: LIGHT_GREEN,
    odd: LIGHT_GREEN,
    hover: LIGHT_GREEN,
    text: GREEN,
    headerBg: '#bbbfb6',
  },
}

export const NEGATIVE_RED_COLOR_PAIR = {
  positive: BLACK,
  negative: RED,
}

export const RED_COLOR_PAIR = {
  positive: RED,
  negative: GRAY_2,
}

export const GREEN_COLOR_PAIR = {
  positive: GREEN,
  negative: GRAY_2,
}

export const BLACK_COLOR_PAIR = {
  positive: BLACK,
  negative: GRAY_2,
}

export const themeColors: { light: Theme; dark: Theme } = {
  light: {
    button: {
      backgroundColor: BLUE,
      hoverColor: DARK_BLUE,
      textColor: WHITE,
    },
    datePicker: {
      text: BLACK,
      selected: WHITE,
    },
    input: {
      backgroundColor: WHITE,
      borderColor: BLUE,
      textColor: BLACK,
    },
    chip: {
      background: VALR_BLUE_GRAY,
    },
    formLabel: {
      color: GRAY_3,
      filled: '',
      inputShrink: '',
    },
    checkBox: {
      checked: BLUE,
    },
    radio: {
      color: BLUE,
    },
    icon: {
      color: BLUE,
    },
    muiSwitch: {
      backgroundColor: GRAY_3,
    },
    muiTable: {
      color: '',
      backgroundColor: '',
      tableHead: {
        backgroundColor: GRAY_1,
        color: BLACK,
      },
      tableRow: {
        backgroundColor: GRAY_0,
        hoverColor: VALR_BLUE_GRAY,
        textHoverColor: WHITE,
        lightHoverColor: GRAY_0,
      },
    },
    muiCollapse: {
      color: BLACK,
    },
    muiAccordion: {
      expandedBackgroundColor: WHITE,
      expandedText: BLACK,
      color: BLACK,
      borderBottomColor: VALR_BLUE_GRAY,
    },
    muiListItem: {
      text: BLACK,
    },
    muiTablePagination: {
      text: BLACK,
    },
    muiCardContent: {
      background: WHITE,
      color: BLACK,
      text: BLACK,
    },
    muiTypography: {
      color: '',
    },
    muiDivider: {
      color: GRAY_1,
    },
    formGroup: {
      text: BLACK,
    },
    autoComplete: {
      background: WHITE,
      color: BLACK,
    },
  },
  dark: {
    button: {
      backgroundColor: DARK_MODE_PRIMARY,
      hoverColor: BLUE,
      textColor: WHITE,
    },
    datePicker: {
      text: WHITE,
      selected: WHITE,
    },
    input: {
      backgroundColor: MIDNIGHT_GRAY,
      borderColor: BLUE_GRAY,
      textColor: WHITE,
    },
    chip: {
      background: VALR_BLUE_GRAY,
    },
    formLabel: {
      color: GRAY_3,
      filled: WHITE,
      inputShrink: WHITE,
    },
    checkBox: {
      checked: BLUE,
    },
    radio: {
      color: BLUE,
    },
    icon: {
      color: WHITE,
    },
    muiSwitch: {
      backgroundColor: GRAY_3,
    },
    muiTable: {
      color: VALR_BLUE_GRAY,
      tableHead: {
        backgroundColor: NAVY_BLUE,
        color: '',
      },
      backgroundColor: '',
      tableRow: {
        backgroundColor: DEEP_ROYAL_BLUE,
        hoverColor: VIBRANT_ROYAL_BLUE,
        textHoverColor: WHITE,
        lightHoverColor: DARK_BLUE_GREY,
      },
    },
    muiCollapse: {
      color: VALR_BLUE_GRAY,
    },
    muiAccordion: {
      expandedBackgroundColor: VALR_BLUE_GRAY,
      expandedText: WHITE,
      color: VALR_BLUE_GRAY,
      borderBottomColor: VALR_BLUE_GRAY,
    },
    muiListItem: {
      text: WHITE,
    },
    muiTablePagination: {
      text: VALR_BLUE_GRAY,
    },
    muiCardContent: {
      background: MIDNIGHT_GRAY,
      text: WHITE,
      color: '',
    },
    muiTypography: {
      color: WHITE,
    },
    muiDivider: {
      color: DARK_GREY,
    },
    formGroup: {
      text: WHITE,
    },
    autoComplete: {
      background: WHITE,
      color: BLACK,
    },
  },
}
