import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  fetchAccountStaking,
  clearAccountStakingState,
} from 'redux/staking/actions'
import { AccountStakingInitialState } from 'redux/staking/index'
import LoadingBlock from 'components/molecules/LoadingBlock'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'
import { TablePlaceholder } from 'components/atoms/Table/styles'

interface StakingProps {
  match: RouteComponentProps<{ accountId: string }>
  fetchAccountStaking: (id: string) => void
  clearAccountStakingState: () => void
  accountStakingData: AccountStakingInitialState
}

const Staking: FC<StakingProps> = (props) => {
  const { accountId } = props.match.params
  const { status, data } = props.accountStakingData
  const flagOn = useFeatureFlagCheck('staking')
  if (!flagOn) {
    return <TablePlaceholder>Feature under maintenance</TablePlaceholder>
  }

  useEffect(() => {
    props.fetchAccountStaking(accountId)
    return () => {
      props.clearAccountStakingState()
    }
  }, [])

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency Apr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {status === 'loading' && (
            <TableCell colSpan={6}>
              <LoadingBlock />
            </TableCell>
          )}
          {status === 'done' &&
            data.map((stake, i) => (
              <TableRow key={i}>
                <TableCell>{stake.currency}</TableCell>
                <TableCell>{stake.amount}</TableCell>
                <TableCell>{stake.currentApr}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

const mapStateToProps = (state) => ({
  accountStakingData: state.staking,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountStaking,
      clearAccountStakingState,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(Staking)
