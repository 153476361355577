import React from 'react'
import { FullscreenCentered, FlexboxColumn } from 'components/layout'
import { LogoEmpty } from 'components/atoms/Generic'
import { PANEL_BLUE } from 'theme/colors'

import auth from 'services/Auth'

import { WhiteButton } from './styles'

const Login = () => (
  <FullscreenCentered bgColor={PANEL_BLUE}>
    <FlexboxColumn>
      <LogoEmpty />
      <WhiteButton label="Login" onClick={auth.login} />
    </FlexboxColumn>
  </FullscreenCentered>
)

export default Login
