import React from 'react'
import { oppositeColor } from 'helpers/utils'
import { GREEN_COLOR_PAIR, RED_COLOR_PAIR } from 'theme/colors'
import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
} from 'components/atoms/Details/index'

const RenderSendDetails = props => {
  const { tx } = props
  return (
    <Details>
      <DetailsBlockWithTitle title={'Details'}>
        <InfoBlock
          color={oppositeColor(tx.amount, GREEN_COLOR_PAIR)}
          label={'Amount'}
          value={`${tx.amount} ${tx.currency.symbol}`}
        />
        <InfoBlock
          color={oppositeColor(tx.feeAmount, RED_COLOR_PAIR)}
          label={'Fee Amount'}
          value={`${tx.feeAmount} ${tx.currency.symbol}`}
        />
        <InfoBlock label={'Send Address'} value={tx.sendAddress} />
        <InfoBlock label={'Timestamp'} value={tx.createdAt} />
      </DetailsBlockWithTitle>
    </Details>
  )
}
export default RenderSendDetails
