import SimpleModal from 'components/organisms/Modal'
import React, { FC, useEffect, useState } from 'react'
import { Flexbox } from 'components/layout'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { AxiosResponse } from 'axios'

import IconButton from 'components/atoms/IconButton'

import { RestoringDocumentView } from './RestoringDocumentView'
import { RemovedDocument } from './interfaces'

interface RestoreDocumentsModalProps {
  isOpen: boolean
  removedDocuments: RemovedDocument[]
  toggleRestoringDocumentsModal: () => void
  restoringDocument: boolean
  canRestoreDocument: boolean
  restoreDocument: (docId: number) => Promise<void>
  getDocumentBufferData: (documentId: number) => Promise<AxiosResponse>
}

const RestoreDocumentsModal: FC<RestoreDocumentsModalProps> = ({
  isOpen,
  removedDocuments,
  toggleRestoringDocumentsModal,
  restoringDocument,
  canRestoreDocument,
  restoreDocument,
  getDocumentBufferData,
}) => {
  const [removedDocInView, setRemovedDocInView] = useState(0)

  const handleNavigateRemovedDocuments = (type: 'PREV' | 'NEXT') => {
    if (type === 'NEXT' && removedDocInView !== removedDocuments.length - 1) {
      setRemovedDocInView(prev => prev + 1)
    } else if (removedDocInView !== 0) {
      setRemovedDocInView(prev => prev - 1)
    }
  }

  const canNavigatePrevious = removedDocInView > 0
  const canNavigateNext = removedDocInView < removedDocuments.length - 1

  useEffect(
    () => {
      if (
        removedDocInView > removedDocuments.length - 1 &&
        removedDocuments.length > 0
      ) {
        setRemovedDocInView(removedDocuments.length - 1)
      }

      if (removedDocuments.length === 0 && isOpen) {
        toggleRestoringDocumentsModal()
      }

      return () => {}
    },
    [removedDocuments],
  )

  return (
    <SimpleModal
      open={isOpen && removedDocuments.length > 0}
      handleClose={toggleRestoringDocumentsModal}
      buttonsHidden
    >
      {removedDocuments.filter((_, i) => i === removedDocInView).map(doc => (
        <RestoringDocumentView
          key={doc.id}
          document={doc}
          canRestoreDocument={canRestoreDocument}
          restoreDocument={async () => {
            restoreDocument(doc.id)
          }}
          getDocumentBufferData={getDocumentBufferData}
          restoringDocument={restoringDocument}
          handleCloseDocumentViewer={toggleRestoringDocumentsModal}
        />
      ))}
      <Flexbox justify="flex-end">
        Document {removedDocInView + 1}/{removedDocuments.length}
        <div>
          {/* @ts-ignore */}
          <IconButton
            onClick={() => handleNavigateRemovedDocuments('PREV')}
            icon={<ArrowBackIcon />}
            disabled={!canNavigatePrevious}
          />
          {/* @ts-ignore */}
          <IconButton
            onClick={() => handleNavigateRemovedDocuments('NEXT')}
            icon={<ArrowForwardIcon />}
            disabled={!canNavigateNext}
          />
        </div>
      </Flexbox>
    </SimpleModal>
  )
}

export default RestoreDocumentsModal
