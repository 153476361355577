import React, { FC, Fragment, lazy, Suspense } from 'react'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { Route } from 'react-router-dom'
import useSanctionsNavigation from 'Hooks/Sanctions/useSanctionsNavigation'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import ErrorBoundary from 'helpers/ErrorBoundary'

const LocalSanctions = lazy(() => import('./LocalSanctions/LocalSanctions'))

const CorporateSanctions = lazy(
  () => import('./CorporateSanctions/CorporateSanctions'),
)

interface SanctionsPageProps {
  location: Location
}

const SanctionsPage: FC<SanctionsPageProps> = (props) => {
  const { currentPage, handleRouteChange, routes } =
    useSanctionsNavigation(props)
  return (
    <ErrorBoundary message="Sanctions">
      <Fragment>
        <TopNavigationBar
          value={currentPage}
          onChange={handleRouteChange}
          variant="fullWidth"
        >
          <NavigationTab
            value={routes[0]}
            label="Local Sanctions"
            component={ForwardedNavLink}
            to={routes[0]}
          />
          <NavigationTab
            value={routes[1]}
            label="Corporate Sanctions"
            component={ForwardedNavLink}
            to={routes[1]}
          />
        </TopNavigationBar>
        <ContentContainer>
          <Route
            exact
            path={routes[0]}
            component={() => (
              <Suspense fallback="Loading Local Sanctions Page...">
                <LocalSanctions {...props} />
              </Suspense>
            )}
          />
          <Route
            path={routes[1]}
            component={() => (
              <Suspense fallback="Loading Corporate Sanctions Page...">
                <CorporateSanctions {...props} />
              </Suspense>
            )}
          />
        </ContentContainer>
      </Fragment>
    </ErrorBoundary>
  )
}

export default SanctionsPage
