import React from 'react'
import {
  PermPhoneMsg,
  DirectionsBoat,
  AssignmentInd,
  Public,
  MonetizationOn,
  PeopleOutline,
} from '@material-ui/icons'
import { WHITE } from 'theme/colors'

import { NavItem } from './interfaces'
import config from 'config'

export const sideBarNavigationItems: NavItem[] = [
  {
    route: '/accounts/search',
    listItemText: 'Accounts',
    selectedPathName: '/accounts/search',
    routeAccess: 'accounts',
    icon: <i className={'fas fa-user-circle'} />,
  },
  {
    route: '/exchange/deposits',
    listItemText: 'Exchange',
    selectedPathName: '/exchange',
    routeAccess: 'exchange',
    icon: <i className={'fas fa-exchange-alt'} />,
  },
  {
    route: '/leverage/loans-book',
    listItemText: 'Leverage',
    selectedPathName: '/leverage',
    routeAccess: 'leverage',
    icon: <MonetizationOn style={{ color: WHITE }} />,
  },
  {
    route: `/funds/${!config.isProduction ? 'individual' : 'from-fiat-csv'}`,
    listItemText: 'Funding',
    selectedPathName: '/funds',
    routeAccess: 'fund',
    icon: <i className={'fas fa-hand-holding-usd'} />,
  },
  {
    route: '/dual-auth',
    listItemText: 'Dual Auth',
    selectedPathName: '/dual-auth',
    routeAccess: 'dual-auth',
    icon: <i className={'fas fa-key'} />,
  },
  {
    route: '/dynamic/application',
    listItemText: 'Application Config',
    selectedPathName: '/dynamic',
    routeAccess: 'config',
    icon: <i className={'fas fa-cog'} />,
  },
  {
    route: '/twilio',
    listItemText: 'Twilio',
    selectedPathName: '/twilio',
    routeAccess: 'twilio',
    icon: <PermPhoneMsg style={{ color: WHITE }} />,
  },
  {
    route: '/ferryman/groups',
    listItemText: 'Ferryman',
    selectedPathName: '/ferryman',
    routeAccess: 'keycloak',
    icon: <DirectionsBoat style={{ color: WHITE }} />,
  },
  {
    route: '/keycloak/admin/groups',
    listItemText: 'Keycloak groups',
    selectedPathName: '/keycloak/admin/groups',
    routeAccess: 'keycloak',
    icon: <PeopleOutline style={{ color: WHITE }} />,
  },
  {
    route: '/finance/summary-balances',
    listItemText: 'Finance',
    selectedPathName: '/finance',
    routeAccess: 'finance',
    icon: <i className={'fas fa-donate'} />,
  },
  {
    route: '/compliance/sanctions',
    listItemText: 'Compliance',
    selectedPathName: '/compliance',
    routeAccess: 'compliance',
    icon: <AssignmentInd style={{ color: WHITE }} />,
  },
  {
    route: '/marketing',
    listItemText: 'Marketing',
    selectedPathName: '/marketing',
    routeAccess: 'marketing',
    icon: <Public style={{ color: WHITE }} />,
  },
]
