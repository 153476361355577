import React, { FC, Fragment, useState } from 'react'
import {
  fetchFuturesPositions,
  getAvailableCurrencyPairs,
} from 'redux/futures/actions'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import Select from 'react-select'
import { FuturesInitialState } from 'redux/futures'
import {
  AvailableCurrencyPairsListItem,
  CurrencyPair,
} from 'interfaces/futures'
import { Spacer } from 'components/atoms/Generic'
import BigNumber from 'bignumber.js'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import FuturesPositionsTable from './components/FuturesPositionsTable'

interface FuturesProps {
  futures: FuturesInitialState
  getAvailableCurrencyPairs: () => Promise<void>
  fetchFuturesPositions: (currencyPair: CurrencyPair) => Promise<void>
}

const Futures: FC<FuturesProps> = props => {
  const {
    availableCurrencyPairsList,
    fetchingCurrencyPairs,
    fetchingFuturesPositions,
    futuresPositions,
  } = props.futures

  const [expandRowId, setSelectedRow] = useState(-1)

  const handleFetchAvailableCurrencyPairs = () => {
    if (availableCurrencyPairsList.length === 0) {
      props.getAvailableCurrencyPairs()
    }
  }

  const futuresPositionsSell = futuresPositions.filter(
    futurePosition => futurePosition.side === 'sell',
  )
  const futuresPositionsBuy = futuresPositions.filter(
    futurePosition => futurePosition.side === 'buy',
  )

  const totalBuyQuntiy = () => {
    const total = futuresPositionsBuy
      .map(d => d.quantity)
      .reduce((accumulator, value) => {
        const amount = parseFloat(value)
        return accumulator + amount
      }, 0)
    return total
  }

  const totalSellQuntiy = () => {
    const total = futuresPositionsSell
      .map(d => d.quantity)
      .reduce((accumulator, value) => {
        const amount = parseFloat(value)
        return accumulator + amount
      }, 0)
    return total
  }

  const toggleSelectedRow = (id: number) =>
    setSelectedRow(prev => (prev === id ? -1 : id))

  return (
    <div>
      <Select
        onFocus={handleFetchAvailableCurrencyPairs}
        options={
          fetchingCurrencyPairs
            ? [
                {
                  label: <LoadingBlock message="Fetching Currency Pairs" />,
                  value: '',
                },
              ]
            : availableCurrencyPairsList || []
        }
        placeholder={'Select currency pair'}
        onChange={(currencyPairItem: AvailableCurrencyPairsListItem) =>
          props.fetchFuturesPositions(currencyPairItem.value)
        }
      />
      {fetchingFuturesPositions === 'pending' ? (
        <Spacer margin="12px 0px">
          <LoadingBlock message="Fetching Futures Positions" />
        </Spacer>
      ) : (
        <Fragment>
          {futuresPositions.length > 0 ? (
            <Fragment>
              <FuturesPositionsTable
                type="BUY"
                fetchingFuturesPositions={fetchingFuturesPositions}
                futurePositionsData={futuresPositionsBuy}
                quantity={totalBuyQuntiy()}
                toggleSelectedRow={toggleSelectedRow}
                expandRowId={expandRowId}
              />
              <FuturesPositionsTable
                type={'SELL'}
                fetchingFuturesPositions={fetchingFuturesPositions}
                futurePositionsData={futuresPositionsSell}
                quantity={totalSellQuntiy()}
                toggleSelectedRow={toggleSelectedRow}
                expandRowId={expandRowId}
              />
            </Fragment>
          ) : (
            <Spacer margin="12px 0px">
              <TablePlaceholder>
                No Futures Positions To Show. Select An Option From The List
                Above.
              </TablePlaceholder>
            </Spacer>
          )}
        </Fragment>
      )}
    </div>
  )
}

const actions = {
  getAvailableCurrencyPairs,
  fetchFuturesPositions,
}

export default connectStateAndDispatch(
  mapStateToProps(state => ({
    futures: state.futures,
  })),
  actions,
  Futures,
)
