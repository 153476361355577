import {
  FETCH_CURRENCY_ENABLED_BORROW_ERROR,
  FETCH_CURRENCY_ENABLED_BORROW_START,
  FETCH_CURRENCY_ENABLED_BORROW_SUCCESS,
} from './actions'

const initialState = {
  isLoading: false,
  currencyList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_ENABLED_BORROW_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case FETCH_CURRENCY_ENABLED_BORROW_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        currencyList: action.payload.data,
      }
    }

    case FETCH_CURRENCY_ENABLED_BORROW_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
