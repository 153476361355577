import React, { Dispatch, FC, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { FlexRow, Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'
import { camelCaseToText } from 'helpers/utils'

import { DatePicker, renderDropDownField, renderInput } from 'forms/fields'
import { FormTitle } from 'components/atoms/Form'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'

import { RiskProfileFormContainer } from '../../Styles'

import { ICorporateRiskProfileEddReportInitialValues } from '../../../interfaces/riskProfile'
import { Tooltip } from '@material-ui/core'
import { RiskScoreOption } from 'interfaces/riskProfile'

interface FormProps {
  initialValues: ICorporateRiskProfileEddReportInitialValues
  handleSubmit: (
    cb: (values: any, dispatch: Dispatch<any>) => Promise<void>,
  ) => Promise<void>
  onSubmit: () => Promise<void>
  onCancel: () => void
  reset: () => void

  setIsSaved: Dispatch<boolean>
  setShowExitModal: Dispatch<boolean>
  submitting: boolean
  invalid: boolean
  submitSucceeded: boolean
  exists: boolean
  dirty: boolean
  pristine: boolean
  isSaved: boolean
  showExitModal: boolean
  ipLogsOptions: RiskScoreOption[]
  pendingDualAuth: boolean
}

const disableField = (key: keyof ICorporateRiskProfileEddReportInitialValues) =>
  key === 'sourceOfFunds' ||
  key === 'purposeOfAccount' ||
  key === 'countryOfRegistration' ||
  key === 'documentsStatus' ||
  key === 'natureOfBusiness' ||
  key === 'name' ||
  key === 'riskScore' ||
  key === 'companyRegistrationNumber' ||
  key === 'registeredOfficeAddress' ||
  key === 'phoneNumber' ||
  key === 'emailAddress' ||
  key === 'typeOfCorporate' ||
  key === 'directors' ||
  key === 'pepDirectors'||
  key === 'sourceOfWealth'

const Form: FC<FormProps> = (props) => {
  const handleTooltipText = (
    key: keyof ICorporateRiskProfileEddReportInitialValues,
  ) => {
    let text = ''
    if (key === 'sourceOfFunds') {
      text = 'This data is recorded on the risk profile.'
    }

    if (disableField(key)) {
      text = 'This data is recorded from the corporate account'
    }
    return text
  }
  useEffect(() => {
    if (
      (props.exists && !props.dirty) ||
      (props.submitSucceeded && !props.dirty)
    ) {
      props.setIsSaved(true)
    } else {
      props.setIsSaved(false)
    }
    return () => {
      props.setIsSaved(true)
      props.setShowExitModal(false)
    }
  }, [props.dirty, props.submitSucceeded])

  return (
    <RiskProfileFormContainer onSubmit={props.handleSubmit(props.onSubmit)}>
      <FormTitle>Risk Profile EDD Report Information</FormTitle>
      <FlexRow style={{ textAlign: 'center' }}>
        {Object.keys(props.initialValues)
          .filter(
            (key: keyof ICorporateRiskProfileEddReportInitialValues) =>
              !(
                key === 'accountId' ||
                key === 'corporateId' ||
                key === 'isCorporateAccount' ||
                key === 'ipLogs' ||
                key === 'recommendation'
              ),
          )
          .map((key: keyof ICorporateRiskProfileEddReportInitialValues) => (
            <>
              {key === 'accountOpenedDate' ? (
                <div key={key} style={{ width: '25%' }}>
                  <Field
                    width="90%"
                    label={camelCaseToText(key)}
                    name={key}
                    disabled={disableField(key)}
                    multiline
                    component={DatePicker}
                    margin={`0px 2px ${BASE * 2}px 0px`}
                  />
                </div>
              ) : (
                <Tooltip title={handleTooltipText(key)} placement="top">
                  <div key={key} style={{ width: '25%' }}>
                    <Field
                      width="90%"
                      label={
                        key ===
                        'describedNatureOfBusinessEmploymentGeneratingFunds'
                          ? 'Described nature of business'
                          : camelCaseToText(key)
                      }
                      name={key}
                      disabled={disableField(key)}
                      multiline
                      component={renderInput}
                      margin={`0px 2px ${BASE * 2}px 0px`}
                    />
                  </div>
                </Tooltip>
              )}
            </>
          ))}
        <div style={{ width: '25%' }}>
          <Field
            width="90%"
            label={'Ip logs'}
            name={'ipLogs'}
            component={renderDropDownField}
            options={props.ipLogsOptions.map((option) => ({
              value: option.value,
              label: option.value,
            }))}
            margin={`0px 2px ${BASE * 2}px 0px`}
          />
        </div>
        <Tooltip
          title={
            'This data is recorded when initiating a corp risk profile dual auth and can only be updated when the dual auth is in a pending state.'
          }
          placement="top"
        >
          <div style={{ width: '25%' }}>
            <Field
              width="90%"
              label={'Recommendation'}
              name={'recommendation'}
              disabled={!props.pendingDualAuth}
              multiline
              component={renderInput}
              margin={`0px 2px ${BASE * 2}px 0px`}
            />
          </div>
        </Tooltip>
      </FlexRow>

      <Flexbox justify={'flex-end'} direction={'row'}>
        <MuiButton
          onClick={() => {
            if (!props.isSaved) {
              props.setShowExitModal(true)
            } else {
              props.onCancel()
            }
          }}
        >
          go back
        </MuiButton>
        <Spacer margin="0px 12px">
          <MuiButton
            type="submit"
            disabled={props.submitting || props.invalid || props.isSaved}
          >
            sav
            {props.submitting ? 'ing' : 'e'}
          </MuiButton>
        </Spacer>
      </Flexbox>
      <ConfirmationDialog
        open={props.showExitModal}
        proceed={() => props.onCancel()}
        title="You have unsaved changes are you sure you want to proceed?"
        message=""
        handleClose={() => props.setShowExitModal(false)}
      />
    </RiskProfileFormContainer>
  )
}

export const CorporateRiskProfileEddReportForm = reduxForm({
  form: 'riskProfileEddReportForm',
  onSubmit: (v) => {
    console.log({ v })
  },
  enableReinitialize: true,
})(Form)
