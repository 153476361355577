import React, { Component, Fragment } from 'react'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import FormUsdWithrawalsReview from 'forms/UsdWithdrawals/index'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import EyeIcon from 'components/atoms/EyeIcon/index'
import { VALR_BLUE_GRAY } from 'theme/colors'
import { Link } from '../AccountIdLink/clickableId'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
    }
  }

  removeActiveUser = async () => {
    const withdrawalId = this.props.data.id
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async () => {
    const withdrawalId = this.props.data.id
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  render() {
    const {
      data,
      toggleForm,
      formShown,
      expandRawData,
      showRawData,
      isHighlighted,
      setHighlightedId,
      title,
    } = this.props
    const {
      id,
      accountId,
      amount,
      createdAt,
      currency,
      status,
      reviewItemId,
      active,
    } = data
    const shading = isHighlighted ? 'white' : ''
    const padding = '16px 16px 16px 0px'
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && VALR_BLUE_GRAY,
          }}
          onClick={() => {
            toggleForm(reviewItemId)
            setHighlightedId(reviewItemId)
          }}
        >
          <TableCell bold flex={0.6} style={{ padding: '16px 16px' }}>
            {accountId ? <Link id={accountId} /> : '--'}
          </TableCell>
          <TableCell flex={0.4}>{currency || '--'}</TableCell>

          <TableCell flex={0.6} align={'right'}>
            {amount}
          </TableCell>
          <TableCell flex={0.6} style={{ marginLeft: '12%' }}>
            {status}
          </TableCell>
          <TableCell flex={0.6}>{createdAt}</TableCell>
          <TableCell flex={0.6} style={{ padding }}>
            {active &&
              active.userActive && <EyeIcon text={active.activePersonName} />}
          </TableCell>
        </TableRow>
        {formShown && (
          <FormUsdWithrawalsReview
            title={title}
            initialValues={{
              withdrawalId: id,
              accountId,
              comment: '',
              status,
            }}
            expandRawData={expandRawData}
            removeActiveUser={this.removeActiveUser}
            showActiveUser={this.showActiveuser}
            showRawData={() => showRawData(reviewItemId)}
            form={`UsdDepositReviewForm+${reviewItemId}`}
            rawData={this.props.data}
            onCancel={this.props.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}

export default Row
