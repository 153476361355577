// @ts-nocheck
import React from 'react'
import { Field, reduxForm } from 'redux-form'

import MuiButton from 'components/atoms/Buttons/MuiButton'
import { requiredDropdownValue } from 'forms/fieldValidators'
import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'

import { renderDropDownField } from '../fields'
import { Spacer } from 'components/atoms/Generic'

enum AVAILABLE_EXCHANGES {
  VALR = 'VALR',
  COINBASE = 'COINBASE',
  BITTREX = 'BITTREX',
}

const Form = props => {
  const { submitting, error, invalid, dirty } = props
  const { handleSubmit, onCancel, onSubmit, currentExchange } = props
  const availableExchanges = Object.values(AVAILABLE_EXCHANGES).map(
    exchange => {
      let disabledHelperText = null
      const disableBittrexOption =
        exchange === AVAILABLE_EXCHANGES.BITTREX &&
        currentExchange === AVAILABLE_EXCHANGES.VALR
      const disabled = currentExchange === exchange
      if (disabled) {
        disabledHelperText = ' is the current exchange'
      }
      if (disableBittrexOption) {
        disabledHelperText = `Can not change from ${
          AVAILABLE_EXCHANGES.VALR
        } to ${AVAILABLE_EXCHANGES.BITTREX}`
      }
      return {
        value: exchange,
        label: exchange,
        disabled: disabled || disableBittrexOption,
        disabledHelperText,
      }
    },
  )
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Change Exchange</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Select Exchange'}
          name={'newExchange'}
          showNoneLabel={false}
          validate={[requiredDropdownValue]}
          component={renderDropDownField}
          fullWidth
          options={availableExchanges}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Spacer margin="0px 12px">
          <MuiButton
            type="submit"
            disabled={submitting || invalid || !dirty}
            color="primary"
          >
            Change Exchange
          </MuiButton>
        </Spacer>
        <MuiButton onClick={onCancel} color="secondary">
          Cancel
        </MuiButton>
      </InputGroup>
    </FormContainer>
  )
}

const ChangeCurrencyPairExchangeForm = reduxForm({
  form: 'changeExchangeForm',
})(Form)

export default ChangeCurrencyPairExchangeForm
