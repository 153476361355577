import styled from 'styled-components'

export const StyledFileUploaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`

export const StyledImage = styled.img`
  width: auto;
  height: auto;
`

export const StyledFileUploaderContent = styled.div`
  position: relative;
  overflowY: scroll !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border: 1px dashed black;
  border-radius: 16px;
  :hover {
    cursor: pointer;
  }
  width: 100%;
  height: 100%;
`
