import {
  legacy_createStore,
  applyMiddleware,
  compose,
  bindActionCreators,
  Dispatch,
} from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import createHistory from 'history/createBrowserHistory'
import { IApplicationState } from 'interfaces'
import { connect } from 'react-redux'

import rootSaga from 'redux/sagas/rootSaga'

import rootReducer from './redux'

export const history = createHistory()

const sagaMiddleware = createSagaMiddleware()

const initialState: any = {}
const enhancers: any[] = []
const middleware: any[] = [thunk, routerMiddleware(history), sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window as any

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__())
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const store = legacy_createStore(rootReducer(), initialState, composedEnhancers)

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export const mapStateToProps = (cb: (state: IApplicationState) => any) => cb

export const connectStateAndDispatch = (
  cb: ((state: IApplicationState) => any) | null,
  actions: {
    [key: string]: (
      ...values: any[]
    ) => (dispatch: Dispatch<any>, getState: () => RootState) => Promise<void>
  },
  component: React.ComponentClass | React.FunctionComponent,
) =>
  connect(cb, (dispatch: Dispatch<any>) =>
    bindActionCreators(actions, dispatch),
  )(component)

export type RootState = ReturnType<typeof store.getState>

export default store
