import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { FC, Fragment } from 'react'
import { FuturePosition } from 'interfaces/futures'
import { Spacer } from 'components/atoms/Generic'

import { Title } from 'components/atoms/Title'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import FuturePositionRow from './FuturesPositionRow'

interface FuturesPositionsTableProps {
  futurePositionsData: FuturePosition[]
  fetchingFuturesPositions: 'pristine' | 'pending' | 'done'
  type: 'BUY' | 'SELL'
  quantity: number
  toggleSelectedRow: (value: number) => void
  expandRowId: number
}

const FuturesPositionsTable: FC<FuturesPositionsTableProps> = ({
  futurePositionsData,
  fetchingFuturesPositions,
  type,
  quantity,
  toggleSelectedRow,
  expandRowId,
}) =>
  futurePositionsData.length > 0 ? (
    <Fragment>
      <Title
        type="h3"
        text={`${
          futurePositionsData[0].pair
        } ${futurePositionsData[0].side.toUpperCase()}`}
      />
      <h4>{`Total ${type} quantity: ${quantity}`}</h4>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account ID</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Average Entry Price</TableCell>
            <TableCell>Session Average Entry Price</TableCell>
            <TableCell>Total Session Value</TableCell>
            <TableCell>Realized Profit & Loss</TableCell>
            <TableCell>Un-realized Profit & loss</TableCell>
            <TableCell>Updated At</TableCell>
            <TableCell>Created At</TableCell>
          </TableRow>
        </TableHead>
        {futurePositionsData.map(futurePosition => (
          <FuturePositionRow
            key={futurePosition.id}
            futurePosition={futurePosition}
            toggleSelectedRow={toggleSelectedRow}
            isSelected={expandRowId === futurePosition.id}
          />
        ))}
      </Table>
    </Fragment>
  ) : (
    <Fragment>
      {fetchingFuturesPositions === 'done' && (
        <Spacer margin="12px 0px">
          <TablePlaceholder>
            No {type} Futures Positions Found.
          </TablePlaceholder>
        </Spacer>
      )}
      {fetchingFuturesPositions === 'pristine' && (
        <Spacer margin="12px 0px">
          <TablePlaceholder>
            No {type} Futures Positions To Show. Select An Option From The List
            Above.
          </TablePlaceholder>
        </Spacer>
      )}
    </Fragment>
  )

export default FuturesPositionsTable
