import styled, { keyframes } from 'styled-components'
import { KeyboardDatePicker } from '@material-ui/pickers'

const fadeInProfileActions = keyframes`
  0% {
    margin-right: -120px;
    height: 0px;
  }
  100% {
    margin-right: 12px;
    height: 80px;
  }
`

const fadeOutProfileActions = keyframes`
  0% {
    margin-right: 10px;
    height: 80px;
  }
  
  100% {
    margin-right: -120px;
    height: 0px;
  }
`

export const FadeInOutProfileActions = styled.div`
  height: 0px;
  animation: 0.5s
    ${({ active }) => (active ? fadeInProfileActions : fadeOutProfileActions)}
    both;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s;
`

export const NoCopy = styled.div`
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const StyledTransActionHistorySelectWrapper = styled.div`
  display: flex;
  width: 25%;
`
export const StyledTransActionHistoryFilter = styled.div`
  display: flex;
  align-items: center;
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  height: 100%;
`
