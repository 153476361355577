export const submitCompetition = async (values, dispatch) => {
  const lines = values.userIds.split(/\r\n|\n/)
  let [headings, ...entries] = lines
  headings = headings.split(',')
  headings.push('templateId')
  headings.push('competitionName')
  headings.push('reasonId')
  headings.push('status')
  const activeWinnersById = []
  entries.map((entry) => {
    let rowValues = entry.split(',')
    rowValues.push(values.templateId)
    rowValues.push(values.listName.trim())
    rowValues.push(values.reasonId.value)
    rowValues.push('Pending Approval')
    activeWinnersById.push(
      Object.fromEntries(headings.map((head, i) => [head, rowValues[i]])),
    )
  })
  return {
    competitionName: values.listName,
    templateId: values.templateId,
    activeWinnersById,
  }
}
