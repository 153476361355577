import { AccountPendingMultiAuth } from 'interfaces/accountPendingMultiAuths'
import { AccountPendingMultiAuthsAction } from './actions'

export interface AccountPendingMultiAuthsInitialState {
  fetchingAccountPendingMultiAuths: boolean
  data: AccountPendingMultiAuth[]
}

const initialState: AccountPendingMultiAuthsInitialState = {
  fetchingAccountPendingMultiAuths: false,
  data: [],
}

interface Action {
  type: AccountPendingMultiAuthsAction
  payload: AccountPendingMultiAuth[]
}

export default (
  state = initialState,
  action: Action,
): AccountPendingMultiAuthsInitialState => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_START': {
      return {
        ...state,
        fetchingAccountPendingMultiAuths: true,
      }
    }
    case 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_SUCCESS': {
      return {
        ...state,
        fetchingAccountPendingMultiAuths: false,
        data: action.payload,
      }
    }
    case 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_ERROR': {
      return {
        ...state,
        fetchingAccountPendingMultiAuths: false,
      }
    }

    case 'CLEAR_ACCOUNT_PENDING_MULTI_AUTHS': {
      return initialState
    }

    default:
      return state
  }
}
