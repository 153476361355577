import { TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { VALR_BLUE_GRAY, VIBRANT_ROYAL_BLUE, WHITE } from 'theme/colors'

interface StyledTableExpandedTableCellProps {
  isexpanded: boolean;
}
interface StyledTableExpandedTableRowProps {
  isexpanded: boolean;
  isselected?: boolean;
}
export const StyledTableExpandedTableCell = styled(TableCell)<
  StyledTableExpandedTableCellProps
>`
  padding-top: ${({ isexpanded }) => (isexpanded ? '' : '0px')};
  padding-bottom: ${({ isexpanded }) => (isexpanded ? '' : '0px')};
`

export const StyledTableRowWithPointerHover = styled(TableRow)<StyledTableExpandedTableRowProps>`
  background-color: ${({ isexpanded, isselected, theme }) => {
    if (isselected) return VIBRANT_ROYAL_BLUE
    if (isexpanded) return theme.table.row.hover
  }};
  td {
    color: ${({ isselected }) => isselected ? WHITE : ''};
  }
  :hover {
    cursor: pointer;
  };
  .MuiCheckbox-root .MuiSvgIcon-root {
    color: ${({ isselected }) => isselected ? WHITE : ''};
  }
`
