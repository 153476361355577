import authService from 'services/Auth'

export type DualAuthSocketEventTypes =
  | 'NAME_SURNAME_EVENT'
  | 'FUNDS_EVENT'
  | 'ID_NUMBER_EVENT'
  | 'WITHDRAWAL_LIMIT'
  | 'CUSTOMER_TYPE_EVENT'
  | 'IGNORED_DEPOSIT_EVENT'
  | 'ADDRESS_BOOK_EVENT'
  | 'KYC_OVERRIDE_EVENT'
  | 'CELL_PHONE_EVENT'
  | 'EMAIL_CHANGE_EVENT'
  | 'RESIDENTIAL_ADDRESS_CHANGE_EVENT'
  | 'TOTP_REMOVAL_EVENT'
  | 'UNBLOCK_ACCOUNT_EVENT'
  | 'BLOCKCHAIN_SENDS_REVERSAL_EVENT'
  | 'BLOCKCHAIN_RECEIVES_AND_CONFIRMATION_EVENT'
  | 'ACCOUNT_SECURITY_CHANGE_EVENT'
  | 'OTHER_EVENT'
  | 'ALLOW_EXCHANGE_CONTROL_EVENT'
  | 'DEFAULT_EVENT'
  | 'WITHDRAWAL_RESTRICTIONS'

interface ActiveDualAuthUserQueProps {
  id: number
  userActive: boolean
  dualAuthType: DualAuthSocketEventTypes
}

export class ActiveDualAuthUserQue {
  id: number

  activeUserQue: {
    dualAuthType: DualAuthSocketEventTypes

    id: number

    userName: string
    userActive: boolean
  }

  constructor(props: ActiveDualAuthUserQueProps) {
    this.id = props.id
    this.activeUserQue = {
      id: props.id,
      dualAuthType: props.dualAuthType,
      userActive: props.userActive,
      userName: authService.decodeNameToken(),
    }
  }
}
