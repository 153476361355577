import axios, { Canceler } from 'axios'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import authService from 'services/Auth'

// Array to store cancel tokens
let cancelTokens: Canceler[] = []

const cancelAllRequests = () => {
  cancelTokens.forEach((cancel) =>
    cancel('Not authorized so canceling request'),
  )
}

// Function to create a new CancelToken
const createCancelToken = () => {
  const CancelToken = axios.CancelToken
  let cancel
  const token = new CancelToken((c) => {
    cancel = c
  })

  cancelTokens.push(cancel)

  return token
}

const getAxios = (config = {}) => {
  const axiosInstance = axios.create({
    ...config,
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    headers: {
      common: {
        Authorization: `Bearer ${localStorage.getItem('admin_access_token')}`,
      },
    },
    cancelToken: createCancelToken(),
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const statusCode = get(error, 'response.status')
      if (statusCode === 401) {
        cancelAllRequests()
        window.confirm('Your session has expired please login again.')
        return authService.logout()
      }
      const data = get(error, 'response.data')

      if (statusCode === 403) {
        return Promise.reject(error)
      }
      const missingRoleCode = /-12001/
      if (data && data.message && missingRoleCode.test(data.message)) {
        toast(data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 2000,
        })
        return Promise.reject(error)
      }

      return Promise.reject(error)
    },
  )
  return axiosInstance
}

export default getAxios
