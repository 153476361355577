import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'

export const FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_PROCCESS =
  'FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_PROCCESS'
export const FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_SUCCESS =
  'FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_SUCCESS'
export const FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_ERROR =
  'FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_ERROR'

export const fetchManualAttemptsById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_PROCCESS })
  try {
    const res = await getAxios().get(
      `/accounts/${id}/manual-kyc-attempts-by-id`,
    )
    const { data } = res
    dispatch({
      type: FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_ERROR,
      payload: {
        error,
      },
    })
  }
}
