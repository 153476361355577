import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { fetchActivationStatus } from 'redux/activationStatus/actions'
import { addUserActionComment } from 'redux/userComments/actions'

export const submitNewStatus = async (values, dispatch) => {
  const { activated, id, sendWelcomeEmail } = values
  let res = null
  try {
    res = await getAxios().put('/account/active', {
      accountId: id,
      newActivatedStatus: activated,
      sendWelcomeEmail,
    })
    await addUserActionComment({ ...values })
    toast('Activation status updated', {
      type: toast.TYPE.SUCCESS,
      autoClose: 1500,
    })
    dispatch(fetchActivationStatus(id))
  } catch (e) {
    ErrorToast(e)
  }
  return res
}
