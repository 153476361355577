import React, { useState, useEffect } from 'react'
import { Route, Switch, NavLink } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import AccountBalances from './AccountBalances'
import Staking from './Staking'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

const RenderAccountBalances = (props) => {
  const routes = [
    '/accounts/byId/:accountId/balances',
    '/accounts/byId/:accountId/balances/staking',
  ]

  const tabs = ['balances', 'staking']

  const [currentPage, setCurrentPage] = useState(tabs[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const checkPath = (path) => {
    tabs.forEach((tab) => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
    checkPath(pathname)
  }, [pathname])

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'balances'}
          label="ACCOUNT BALANCES"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/balances`}
          exact
        />
        <NavigationTab
          value={'staking'}
          label="STAKING"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId}/balances/staking`}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={AccountBalances} exact />
          <Route path={routes[1]} component={Staking} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}
export default RenderAccountBalances
