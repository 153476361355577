import styled from 'styled-components'

export const StyledEyeIconWrapper = styled.div`
  position: relative;
  display: inline-block;
  align-items: center;
`
export const StyledEyeIcon = styled.i`
  color: blue;
`
export const StyledEyeIconText = styled.span`
  visibility: hidden;
  width: 1px;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  z-index: 1;
`
