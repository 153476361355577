import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { Link } from 'containers/Accounts/SingleAccount/link'
import moment from 'moment'

const DepositByStatusRow = (props) => {
  const { data } = props
  const {
    accountId,
    amount,
    createdAt,
    currency,
    feeAmount,
    description,
    basicInfoForDisplay,
  } = data

  return (
    <>
      <TableRow>
        <TableCell>
          {accountId ? <Link color={'black'} id={accountId} /> : '--'}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.name : '--'}{' '}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.surname : '--'}{' '}
        </TableCell>
        <TableCell>{currency || '--'}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{feeAmount}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{moment(createdAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
      </TableRow>
    </>
  )
}

export default DepositByStatusRow
