import React from 'react'
import socket from 'redux/socket'
import { ActiveDualAuthUserQue, DualAuthSocketEventTypes } from './helpers'

const dualAuthTableRowEventsEmitter = (
  TableRow: React.ComponentType<{
    showActiveUser: () => void
    removeActiveUser: () => void
  }>,
  dualAuthType: DualAuthSocketEventTypes,
) =>
  class DualAuthTableRowEmitter extends React.Component<{
    dualAuthId: number
  }> {
    removeActiveUser = async () => {
      const inactiveDualAuthUser = new ActiveDualAuthUserQue({
        id: this.props.dualAuthId,
        dualAuthType,
        userActive: false,
      })
      socket.emit('REMOVE_DUAL_AUTH_USER', inactiveDualAuthUser)
    }

    showActiveUser = async () => {
      const activeDualAuthUser = new ActiveDualAuthUserQue({
        id: this.props.dualAuthId,
        dualAuthType,
        userActive: true,
      })
      socket.emit('ADD_DUAL_AUTH_USER', activeDualAuthUser)
    }

    render() {
      return (
        <TableRow
          showActiveUser={this.showActiveUser}
          removeActiveUser={this.removeActiveUser}
          {...this.props}
        />
      )
    }
  }

export default dualAuthTableRowEventsEmitter
