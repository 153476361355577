import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import { Dispatch } from 'redux'

import ErrorToast from 'components/molecules/ErrorToast'

import { FETCH_VIRTUAL_ACCOUNT_SUCCESS } from '../../redux/virtual-account/actions'

interface IValues {
  accountId: number,
  panNumber: string,
  bankAccountId: string,
}
export const submit = async (values: IValues, dispatch: Dispatch<any>) => {
  const { accountId, ...data } = values
  try {
    const res = await getAxios().post(`/fiat/virtual-account/${accountId}`, {
      ...data,
    })
    if (res && res.status === 201) {
      toast('Virtual account added', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
      dispatch({
        type: FETCH_VIRTUAL_ACCOUNT_SUCCESS,
        payload: {
          id: accountId,
          data: { ...res.data },
        },
      })
      return res.data
    }
    toast(
      `Failed to add virtual account. \n ${
        res.data.message
      }`,
      {
        type: toast.TYPE.ERROR,
        autoClose: 3500,
      },
    )
    return null
  } catch (error: any) {
    ErrorToast(error)
    return get(error, 'response.data.error.validationErrors')
  }
}
