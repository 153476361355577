import React from 'react'
import {
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TableBody,
} from 'components/atoms/Table/styles'
import * as moment from 'moment'

const ValrPayStatuses = props => {
  const { statuses } = props
  return (
    statuses.length > 0 && (
      <Table>
        <TableHeader>
          <TableCell width={'50%'}>Status</TableCell>
          <TableCell width={'20%'}>Inserted At</TableCell>
        </TableHeader>
        <TableBody>
          {statuses.map((status, index) => (
            <TableRow key={index}>
              <TableCell width={'50%'}>{status.statusType}</TableCell>
              <TableCell width={'20%'} style={{ marginRight: '2%' }}>
                {moment(status.insertedAt).format('YYYY-MMM-DD HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  )
}

export default ValrPayStatuses
