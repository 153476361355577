import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_ACCOUNT_BALANCES_PROCESS =
  'balances/FETCH_ACCOUNT_BALANCES_PROCESS'
export const FETCH_ACCOUNT_BALANCES_SUCCESS =
  'balances/FETCH_ACCOUNT_BALANCES_SUCCESS'
export const FETCH_ACCOUNT_BALANCES_ERROR =
  'balances/FETCH_ACCOUNT_BALANCES_ERROR'

export const FETCH_ALL_ACCOUNT_BALANCES_PROCESS =
  'balances/FETCH_ALL_ACCOUNT_BALANCES_PROCESS'
export const FETCH_ALL_ACCOUNT_BALANCES_SUCCESS =
  'balances/FETCH_ALL_ACCOUNT_BALANCES_SUCCESS'
export const FETCH_ALL_ACCOUNT_BALANCES_ERROR =
  'balances/FETCH_ALL_ACCOUNT_BALANCES_ERROR'

export const FETCH_PLATFORM_BALANCES_PROCESS =
  'balances/FETCH_PLATFORM_BALANCES_PROCESS'
export const FETCH_PLATFORM_BALANCES_SUCCESS =
  'balances/FETCH_PLATFORM_BALANCES_SUCCESS'
export const FETCH_PLATFORM_BALANCES_ERROR =
  'balances/FETCH_PLATFORM_BALANCES_ERROR'

export const FETCH_ACCOUNT_BALANCE_SUMMARY_PROCESS =
  'balances/FETCH_ACCOUNT_BALANCE_SUMMARY_PROCESS'
export const FETCH_ACCOUNT_BALANCE_SUMMARY_SUCCESS =
  'balances/FETCH_ACCOUNT_BALANCE_SUMMARY_SUCCESS'
export const FETCH_ACCOUNT_BALANCE_SUMMARY_ERROR =
  'balances/FETCH_ACCOUNT_BALANCE_SUMMARY_ERROR'

const showErrorToast = (error) => {
  const errorMsg = error.response
    ? `${error.response.status} || ${
        JSON.stringify(error.response.data.error) ||
        error.response.statusText ||
        error.response.body
      }`
    : 'Unknown Error'
  toast(errorMsg, { type: toast.TYPE.ERROR, autoClose: 2000 })
}

export const fetchBalances = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_BALANCES_PROCESS,
    payload: {
      id,
    },
  })
  let res = null
  try {
    res = await getAxios().get(`/account/balances/${id}`)
    const { data } = res
    dispatch({
      type: FETCH_ACCOUNT_BALANCES_SUCCESS,
      payload: {
        id,
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_ACCOUNT_BALANCES_ERROR,
      payload: {
        id,
        error: error.response.data,
      },
    })
  }
}

export const fetchAllAccountBalances = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ALL_ACCOUNT_BALANCES_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/account/all/balances/${id}`)
    const { data } = res
    const balancesToReturn = data.balances
    dispatch({
      type: FETCH_ALL_ACCOUNT_BALANCES_SUCCESS,
      payload: {
        id,
        data: balancesToReturn,
      },
    })
  } catch (error) {
    console.log(error)
    dispatch({
      type: FETCH_ALL_ACCOUNT_BALANCES_ERROR,
      payload: {
        id,
        error: error.response.data,
      },
    })
  }
}

export const fetchPlatformBalances = () => async (dispatch) => {
  dispatch({
    type: FETCH_PLATFORM_BALANCES_PROCESS,
  })

  let res = null
  try {
    res = await getAxios().get('/account/platform/balances')
    const { data } = res
    dispatch({
      type: FETCH_PLATFORM_BALANCES_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    console.log({ error })
    showErrorToast(error)
    dispatch({
      type: FETCH_PLATFORM_BALANCES_ERROR,
    })
  }
}

export const fetchAccountBalanceSummary = () => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_BALANCE_SUMMARY_PROCESS,
  })

  let res = null
  try {
    res = await getAxios().get('/account/balances/summary')
    const { data } = res
    dispatch({
      type: FETCH_ACCOUNT_BALANCE_SUMMARY_SUCCESS,
      payload: {
        data: data.balances || data,
      },
    })
  } catch (error) {
    showErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_BALANCE_SUMMARY_ERROR,
    })
  }
}
