export const fundingStatuses = [
  'NEW',
  'FLAGGED_FOR_REVIEW',
  'DECLINED_BY_REVIEW',
  'CLEARED_REVIEW',
  'ALLOCATION_REQUESTED',
  'FUNDING_ALLOCATED',
  'FUNDING_FAILED',
  'PENDING_MANUAL_ALLOCATION',
  'PENDING_MANUAL_ALLOCATION_DUAL_AUTH',
  'MANUAL_ALLOCATION_APPROVED',
  'MANUAL_ALLOCATION_DUAL_AUTH_REJECTED',
]
