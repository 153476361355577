import React, { FC } from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

interface StatusIconIndicatorProps {
  status: boolean
  height?: string | number
  width?: string | number
}

const StatusIconIndicator: FC<StatusIconIndicatorProps> = ({
  status,
  height,
  width,
}) => (
  <FiberManualRecordIcon
    style={{
      color: status ? 'lightgreen' : 'red',
      height,
      width,
    }}
  />
)

export default StatusIconIndicator
