import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { Dispatch } from 'redux'
import { AccountProfileSettings } from 'interfaces/accountProfileSettings'

export type AccountProfileSetttingsActions =
  | 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_PROCESS'
  | 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_SUCCESS'
  | 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_ERROR'
  | 'UPDATE_ACCOUNT_TRADE_FIAT_STATUS_SUCCESS'

interface FetchAccountProfileSettingsDispatch {
  type: AccountProfileSetttingsActions
  payload?: AccountProfileSettings
}

export interface AccountProfileSettingsResponse {
  data: AccountProfileSettings
}

interface UpdateAccountTradeFiatStatusDispatch {
  type: AccountProfileSetttingsActions
  payload?: any
}

export const fetchMarginTradeFiatStatus =
  (id: string) =>
  async (dispatch: Dispatch<FetchAccountProfileSettingsDispatch>) => {
    try {
      dispatch({
        type: 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_PROCESS',
      })
      const { data }: AccountProfileSettingsResponse = await getAxios().get(
        `/account/${id}/profile/settings-fiat-margin-trading`,
      )
      dispatch({
        type: 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_ERROR',
      })
    }
  }

export const UpdateAccountTradeFiatStatus =
  (newStatus: string) =>
  async (dispatch: Dispatch<UpdateAccountTradeFiatStatusDispatch>) => {
    try {
      dispatch({
        type: 'UPDATE_ACCOUNT_TRADE_FIAT_STATUS_SUCCESS',
        payload: {
          newStatus,
        },
      })
    } catch (error) {
      ErrorToast(error)
    }
  }
