import styled from 'styled-components'

export const Block = styled.div`
  display: block;
  padding: 20px;
  margin-bottom: 10px;
  max-width: 100%;
  background-color: rgb(249, 249, 249);
  margin: 16px 0px;
  border: 1px solid rgb(222, 225, 227);
`
