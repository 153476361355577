import React, { Component } from 'react'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasCustomerTypeChangeRole } from 'helpers/roleBasedAccess'

import ChangeCustomerTypeForm from 'forms/ChangeCustomerType'
import {
  DetailsBlock,
  CustomBadge,
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details/index'
import auth from 'services/Auth'

class CustomerType extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formShown: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const {
      id,
      corporateAccountSanctioned,
      hasSuperUserRoleAndIsStaffAccount,
      keyRelianceAgreementType,
    } = this.props
    const customerType = this.props.customerTypeInfo.data
    const fullInfo = this.props.customerTypeInfo
    const formAvailable = formShown && fullInfo.status === 'done'
    const notAuthorized = !hasCustomerTypeChangeRole()

    return (
      <Details
        marginBottom={`${BASE * 3}px`}
        direction={'column'}
        style={{ width: `${50}%` }}
      >
        <DetailsBlockWithTitle
          title="Customer Type"
          flow={'column'}
          marginBottom={'20px'}
        >
          <DetailsBlock flow={'row'}>
            <InfoBlock label="Type" value={customerType.type} />
            <InfoBlock label="Comment" value={customerType.comment} />
            {customerType.keyRelianceAgreementStatus && (
              <InfoBlock label="Key Reliance Agreement" value={'Active'} />
            )}
            <InfoBlock
              label="Key Reliance Agreement type"
              value={keyRelianceAgreementType || '--'}
            />
          </DetailsBlock>
          <JsonBlock src={customerType} title={'Raw Data'} />
          {!formShown && hasSuperUserRoleAndIsStaffAccount && (
            <MuiButton
              onClick={this.toggleForm}
              disabled={notAuthorized}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
            >
              Change Customer Type
            </MuiButton>
          )}
          {formAvailable && hasSuperUserRoleAndIsStaffAccount && (
            <ChangeCustomerTypeForm
              corporateAccountSanctioned={corporateAccountSanctioned}
              initialValues={{
                id,
                customerType: '',
                comment: '',
                data: { date: new Date(), createdBy: auth.decodeNameToken() },
                actionType: 'CUSTOMER_TYPE_CHANGE',
              }}
              onCancel={this.toggleForm}
              onSubmitSuccess={() => {
                this.toggleForm()
              }}
            />
          )}
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}
export default CustomerType
