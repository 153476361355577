import React from 'react'
import { List } from '@material-ui/core'

import { NavItem } from './interfaces'
import { NestedNavMenuItem } from './NestedNavMenuItem'

import { Collapse } from '../styles'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/finance/summary-balances',
    listItemText: 'Summary Balances',
    selectedPathName: '/finance/summary-balances',
    routeAccess: 'finance',
  },
  {
    route: '/finance/balances',
    listItemText: 'Platform Balances',
    selectedPathName: '/finance/balances',
    routeAccess: 'finance',
  },
  {
    route: '/finance/fees',
    listItemText: 'Fees',
    selectedPathName: '/finance/fees',
    routeAccess: 'finance',
  },
  {
    route: '/finance/currencies',
    listItemText: 'Dynamic Currencies',
    selectedPathName: '/finance/currencies',
    routeAccess: 'finance',
  },
]

const NestedFinanceNavigationItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) =>
  pathname.includes('/finance') ? (
    <Collapse
      in={pathname.includes(selectedPathName)}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        {nestedNavigationItems.map((nestedNavItem, index) => (
          <NestedNavMenuItem
            key={index}
            handleListItemClick={handleListItemClick}
            navItemData={nestedNavItem}
            pathname={pathname}
          />
        ))}
      </List>
    </Collapse>
  ) : null

export default NestedFinanceNavigationItems
