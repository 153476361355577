import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { toast } from 'react-toastify'
import { isNaN } from 'lodash'
import IconLoading from 'components/atoms/IconLoading'
import { BASE } from 'theme/base-units'
import { renderInput, renderFileInput } from 'forms/fields'
import { isNumber, required, maxLength32 } from 'forms/fieldValidators'
import {
  InputGroup,
  TransparentFormContainer,
} from 'components/atoms/Form/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { StyledLink } from 'components/atoms/Styles'

let Form = props => {
  const {
    submitting, invalid, handleSubmit, onSubmit, isRunning, disableSubmit, userIds,
  } = props
  const [isValidating, setIsValidating] = useState(false)
  const [isCSVValid, setIsCSVValid] = useState(false)

  const validate = () => {
    setIsValidating(true)
    try {
      if (userIds.includes(',')) {
        toast('CSV shouldn\'t contain commas', {
          type: toast.TYPE.ERROR,
          autoClose: 3500,
        })
        return
      }
      const isWindowsCSV = userIds.includes('\r\n')
      const splittedUserIds = isWindowsCSV ? userIds.split('\r\n') : userIds.split('\n')
      if (splittedUserIds[0] !== 'account_id') {
        toast('First row should be \'account_id\' without quotes', {
          type: toast.TYPE.ERROR,
          autoClose: 3500,
        })
        return
      }
      const userIdsArray = splittedUserIds.filter(userId => !isNaN(parseInt(userId, 10)))
      const error = userIdsArray.some((userId, i) => {
        if (Number(userId)) {
          return false
        }
        toast(`CSV fails on row ${i + 1} with value '${userId}'`, {
          type: toast.TYPE.ERROR,
          autoClose: 3500,
        })
        return true
      })
      if (!error) {
        toast('CSV validated successfully', {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        })
        setIsCSVValid(true)
      }
    } catch {
      toast('Invalid CSV', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    } finally {
      setIsValidating(false)
    }
  }

  useEffect(() => {
    setIsCSVValid(false)
    setIsValidating(false)
  }, [userIds])

  return (
    <TransparentFormContainer width='100%' onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Sync in batches of'}
          name={'batchSize'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'15%'}
          flex={0.4}
          component={renderInput}
          validate={[isNumber, required]}
          placeholder={'Sync in batches of'}
        />
        <Field
          label={'List name'}
          name={'listName'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'15%'}
          flex={0.4}
          component={renderInput}
          validate={[maxLength32]}
          placeholder={'Name of contact list'}
        />
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <span style={{ fontSize: '14px', color: '#6e6e6e' }}>
            Upload Account ID CSV
          </span>
          ( <StyledLink
            href={'https://docs.google.com/spreadsheets/d/14O3BM91q2urBC6GWOLPEAc2sLLAmJa_q3dbztEap2FI/edit?usp=sharing'}
            target={'_blank'}
          >
            CSV Example)
          </StyledLink>
        </div>
        <Field
          width={'1%'}
          name={'userIds'}
          component={renderFileInput}
          accept={'.csv'}
        />
        <MuiButton
          onClick={validate}
          style={{ marginLeft: `${BASE * 2}px` }}
          disabled={isValidating || !userIds}
        >
          Validate
        </MuiButton>
        <MuiButton
          style={{ marginLeft: `${BASE * 2}px` }}
          type="submit" main disabled={submitting || invalid || isRunning || disableSubmit || !isCSVValid}
        >
          {isRunning && <IconLoading />}
          Create/Update Sendgrid List
        </MuiButton>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'DynamicFilterFormListSync',
  onSubmit: (values, dispatch, props) => {
    props.triggerMarketingContactsUpdate(values.batchSize, null, values.userIds, values.listName)
  },
  validate: values => {
    const errors = {}
    if (!values.batchSize || !values.userIds || !values.listName) {
      errors._error = 'All values required'
    }
    return errors
  },
  initialValues: {
    batchSize: 1000,
  },
  enableReinitialize: true,
})(Form)

const selector = formValueSelector('DynamicFilterFormListSync')

const ReduxForm = connect(state => ({ userIds: selector(state, 'userIds') }))(Form)

export default ReduxForm
