import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { SubmissionError } from 'redux-form'
import { approveDualAuth, rejectDualAuth } from 'redux/dual-auth/actions'
import {
  updateDualAuthStatus,
  addUserActionComment,
} from 'redux/userComments/actions'
import ErrorToast from 'components/molecules/ErrorToast'

const validateAndChangeIdNumber = async (
  newIdentityNumber,
  accountId,
  dualAuthStatus,
  dualAuthId,
) => {
  if (!newIdentityNumber) {
    throw new Error(
      'The new identity number seems to be missing in the raw data and is required to update the corporate entities.',
    )
  }
  const {
    data: { profileOldIdentityNumber },
  } = await getAxios().get(
    `/account/profileinfo/old-identity-number/${accountId}`,
  )
  if (newIdentityNumber !== profileOldIdentityNumber) {
    try {
      const dualAuthResponse = await getAxios().put(
        `/dual-auth/${dualAuthStatus}`,
        {
          id: dualAuthId,
        },
      )
      await getAxios().put(
        `/corporate-entity/id-number?oldIdentityNumber=${profileOldIdentityNumber}&newIdentityNumber=${newIdentityNumber}&linkedAccountId=${accountId}`,
      )
      return dualAuthResponse
    } catch (error) {
      ErrorToast(
        'An Error occurred when updating the id number for this natural entity in the corporate. Please manually update the entity id number.',
      )
    }
  } else {
    throw new Error(`The identity number is already ${newIdentityNumber}.`)
  }
}

export const submitAccountIdNumberChangeDualAuthorisation = async (
  values,
  dispatch,
  addComment,
  newIdentityNumber,
) => {
  try {
    const {
      status: { value: dualAuthStatus },
      reason,
      id,
    } = values
    if (!dualAuthStatus) {
      toast('No action status provided', {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      })
    }
    let log = ''
    let res
    if (
      dualAuthStatus === 'approve' &&
      values.actionType === 'IDENTITY_NUMBER_CHANGE'
    ) {
      res = await validateAndChangeIdNumber(
        newIdentityNumber,
        values.accountId,
        dualAuthStatus,
        id,
      )
    } else {
      res = await getAxios().put(`/dual-auth/${dualAuthStatus}`, {
        id,
      })
    }
    if (res.data.statusCode !== 202) {
      throw new Error(res.data.message)
    } else if (res.data.statusCode === 202) {
      if (dualAuthStatus === 'approve') {
        dispatch(approveDualAuth(id))
        log = 'Approved Dual Auth'
      } else if (dualAuthStatus === 'reject') {
        await dispatch(rejectDualAuth(id))
        if (addComment) {
          toast('Adding Comment...', {
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          })
          await addUserActionComment({ ...values, dualAuthId: id })
        }
        log = 'Rejected Dual Auth.'
      }
      if (!addComment) {
        toast('Updating Dual Auth Status...', {
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        })
        await updateDualAuthStatus({ ...values, dualAuthId: id, reason })
      }
      toast(log, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
    return res
  } catch (error) {
    ErrorToast(error)
    throw new SubmissionError({ error })
  }
}
