import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

import {
  RECENT_ACTIVITY_PROCESS,
  RECENT_ACTIVITY_SUCCESS,
  RECENT_ACTIVITY_ERROR,
} from './index'

export const fetchRecentActivity = (id) => async (dispatch) => {
  let res = null
  dispatch({
    type: RECENT_ACTIVITY_PROCESS,
  })

  try {
    res = await getAxios().get(`/account/activity/${id}`)
    const { data } = res
    const emails = data.emails
    dispatch({
      type: RECENT_ACTIVITY_SUCCESS,
      payload: {
        data: emails,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: RECENT_ACTIVITY_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}
