import { useCallback, useState } from 'react'
import {
  APPROVAL_STATUS_TYPES,
  FoundSanctionLegalEntity,
  FoundSanctionNaturalEntity,
  SanctionedCorporate,
  SanctionedCorporateReason,
} from 'interfaces/sanctions'
import { AcknowledgeCorporateSanctionParams } from 'redux/sanctionedCorporate/actions'
interface CorporateSanctionResultHookProps {
  sanctionedCorporateInformation: SanctionedCorporate
  acknowledgeCorporateSanction: (
    a: AcknowledgeCorporateSanctionParams,
  ) => Promise<void>
}

const useCorporateSanctionResult = ({
  sanctionedCorporateInformation,
  acknowledgeCorporateSanction,
}: CorporateSanctionResultHookProps) => {
  const [showCheckForm, setShowCheckForm] = useState(false)
  const [showReasons, setShowReasons] = useState(false)
  const toggleShowCheckForm = () => setShowCheckForm(prev => !prev)
  const toggleShowReasons = () => setShowReasons(prev => !prev)
  const detailsBlockSanctionedCorporateInformation = [
    { label: 'ID', value: sanctionedCorporateInformation.id },
    { label: 'Account ID', value: sanctionedCorporateInformation.account_id },
    { label: 'Name', value: sanctionedCorporateInformation.name },
    { label: 'Status', value: sanctionedCorporateInformation.status },
    {
      label: 'Check Comment',
      value: sanctionedCorporateInformation.check_comment,
    },
    {
      label: 'Approval Status',
      value: sanctionedCorporateInformation.approval_status,
    },
    {
      label: 'Created At',
      value: sanctionedCorporateInformation.created_at,
    },
    {
      label: 'Modified At',
      value: sanctionedCorporateInformation.modified_at,
    },
    {
      label: 'Acknowledged',
      value:
        sanctionedCorporateInformation.status === 'checked'
          ? 'Acknowledged'
          : 'Unacknowledged',
    },
  ]

  const checkButtonText =
    sanctionedCorporateInformation.status === 'checked'
      ? 'Un-mark as checked'
      : 'Mark as checked'

  const foundNaturalEntities = sanctionedCorporateInformation.reason.foundEntities.filter(
    foundEntity => foundEntity.type === 'natural',
  ) as FoundSanctionNaturalEntity[]
  const foundLegalEntities = sanctionedCorporateInformation.reason.foundEntities.filter(
    foundEntity => foundEntity.type === 'legal',
  ) as FoundSanctionLegalEntity[]

  const handleProceedSanctionCheck = async (checkInformation: {
    comment: string
    approvalStatus: APPROVAL_STATUS_TYPES
  }) => {
    await acknowledgeCorporateSanction({
      accountId: sanctionedCorporateInformation.account_id,
      checkInformation,
    })
    setShowCheckForm(prev => !prev)
  }
  const uncheckCorporateSanction = async () => {
    await acknowledgeCorporateSanction({
      accountId: sanctionedCorporateInformation.account_id,
      checkInformation: {
        approvalStatus: '',
        comment: '',
      },
    })
  }

  const generateReasonText = useCallback(
    (reason: SanctionedCorporateReason): string => {
      let reasonText = ''
      const amountOfEntitiesFound = reason.foundEntities.length
      const amountOfSanctionsItems = reason.matchedToSanctionsItems.length
      if (reason.corporateNameMatch) {
        reasonText = 'Corporate name was found'
      }
      if (amountOfSanctionsItems > 0) {
        reasonText = `${reasonText}${
          reasonText.length > 0 ? ', m' : 'M'
        }atched to ${amountOfSanctionsItems} sanctions results`
      }
      if (amountOfEntitiesFound > 0) {
        reasonText = `${reasonText}${
          reasonText.length > 0
            ? ` and ${amountOfEntitiesFound > 0 ? amountOfEntitiesFound : ''} c`
            : ' C'
        }orporate entity matches were found`
      }
      return reasonText
    },
    [],
  )

  const showSanctionResultsOnCorporatePage =
    (sanctionedCorporateInformation.id !== '' &&
      sanctionedCorporateInformation.status === 'unchecked') ||
    sanctionedCorporateInformation.approval_status === 'declined'

  const showSanctionResultsOnDetailsPage =
    sanctionedCorporateInformation.id !== ''

  return {
    checkButtonText,
    detailsBlockSanctionedCorporateInformation,
    foundLegalEntities,
    foundNaturalEntities,
    showCheckForm,
    showReasons,
    toggleShowCheckForm,
    toggleShowReasons,
    handleProceedSanctionCheck,
    uncheckCorporateSanction,
    isCorporateChecked: sanctionedCorporateInformation.status === 'checked',
    showSanctionResultsOnCorporatePage,
    showSanctionResultsOnDetailsPage,
    reasonText: generateReasonText(sanctionedCorporateInformation.reason),
  }
}

export default useCorporateSanctionResult
