import {
  FETCH_ELASTIC_SEARCH_LOGS_PROCESS,
  FETCH_ELASTIC_SEARCH_LOGS_SUCCESS,
  FETCH_ELASTIC_SEARCH_LOGS_ERROR,
  RESET_ELASTIC_SEARCH_LOGS
} from './actions'

const initialState = {
  logs: {
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ELASTIC_SEARCH_LOGS_PROCESS: {
      return {
        ...state,
        logs: {
          status: 'loading',
          data: [],
        },
      }
    }
    case FETCH_ELASTIC_SEARCH_LOGS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        logs: {
          data: [...state.logs.data, ...data],
          status: 'done',
        },
      }
    }

    case FETCH_ELASTIC_SEARCH_LOGS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        logs: {
          error,
          status: 'error',
        },
      }
    }

    case RESET_ELASTIC_SEARCH_LOGS:{
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}
