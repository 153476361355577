import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Flexbox } from 'components/layout/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'
import { MultiBadge } from 'components/molecules/MultiBadge'

import { getAccountMarginStatus } from 'redux/selectors/marginStatus'
import { getAccountDetails } from 'redux/selectors/details'
import { getAuth0Info } from 'redux/selectors/auth0'
import { getKeycloakInfo } from 'redux/selectors/keycloak'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import BlockAccount from 'forms/BlockAccount/index'
import InitiateWithdrawalDualAuthOverride from 'forms/initiate-withdrawal-dual-auth-override/index'

import NavigateToZendesk from './NavigateToZendesk'
import RemoveWithdrawalAddressBook from './RemoveAddressBook'
import { FadeInOutProfileActions } from './Styles'
import { Link } from './link'

import { IconButton } from '@material-ui/core'
import { CopyAllRounded } from '@mui/icons-material'
import { handleCopyToClipBoard } from 'helpers/utils'
import { RenderCheckbox } from 'forms/fields'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'
import { updateValrStaffAccountStatus } from 'redux/accounts/actions'
import { bindActionCreators } from 'redux'
import IconLoading from 'components/atoms/IconLoading'
import { VALR_BLUE_GRAY } from 'theme/colors'
import { checkRole } from 'helpers/roleBasedAccess'

const ProfileHeader = (props) => {
  const {
    details: {
      info,
      status,
      primaryAccountId,
      primaryAccountSubAccounts,
      accountType,
      settings,
      fraud,
      //  TODO: Get this data when fetching account profile information
      isValrStaffAccount,
      isUpdatingValrStaffAccountStatus,
    },
    auth0Info,
    keycloakInfo,
    accountId,
    accountActivationStatus,
  } = props
  const [isAccountWithdrawalsSuspended, setIsAccountWithdrawalsSuspended] =
    useState(false)
  const [showActionsModal, setSetShowActionsModal] = useState(false)
  const [actionsDirty, setActionsDirty] = useState(false)
  const [accountRiskAlerts, setAccountRiskAlerts] = useState(fraud.alerts || [])
  const [changeToStaffAccountModalOpen, setChangeToStaffAccountModalOpen] =
    useState(false)
  const toggleShowActionsModal = () => {
    setSetShowActionsModal((prev) => !prev)
    setActionsDirty(true)
  }
  const handleCopyAccountId = () => {
    handleCopyToClipBoard(accountId)
  }

  const isPrimaryAccountType = accountType === 'PRIMARY'
  const hasSuperUserRole = checkRole('management:internal:account:editor')
  // TODO: Implement a way to get the different risk alerts
  if (
    accountActivationStatus.status === 'done' &&
    accountActivationStatus.inLiquidation
  ) {
    setAccountRiskAlerts((prev) => [...prev, 'In Liquidation'])
  }
  if (isValrStaffAccount && !accountRiskAlerts.includes('Staff Account')) {
    setAccountRiskAlerts((prev) => [...prev, 'Staff Account'])
  }
  const checkIfAccountHasSuspendedWithdrawals = async () => {
    try {
      const { data } = await getAxios().get(
        `/account/${accountId}/withdrawals-suspended/status`,
      )
      setIsAccountWithdrawalsSuspended(data.withdrawalsSuspended)
    } catch (error) {
      toast(
        'An error occurred when checking if this account has suspended withdrawals',
        { type: toast.TYPE.ERROR, autoClose: 2000 },
      )
    }
  }
  const handleChangeAccountStaffStatus = async () => {
    await props.updateValrStaffAccountStatus({
      accountId,
      status: !isValrStaffAccount,
    })
    if (isValrStaffAccount && !accountRiskAlerts.includes('Staff Account')) {
      setAccountRiskAlerts((prev) => [...prev, 'Staff Account'])
    } else {
      const indexOfStaffAccountTag = accountRiskAlerts.findIndex(
        (alert) => alert === 'Staff Account',
      )
      if (indexOfStaffAccountTag > -1) {
        setAccountRiskAlerts((prev) => {
          const updatedData = [...prev]
          updatedData.splice(indexOfStaffAccountTag, 1)
          return updatedData
        })
      }
    }
    setChangeToStaffAccountModalOpen(false)
  }
  useEffect(() => {
    if (accountId) {
      checkIfAccountHasSuspendedWithdrawals()
    }

    return () => {
      setIsAccountWithdrawalsSuspended(false)
      setAccountRiskAlerts([])
    }
  }, [accountId])

  useEffect(() => {
    setAccountRiskAlerts(fraud.alerts)
  }, [fraud.alerts])

  if (status !== 'done' && status !== 'error') {
    return <LoadingBlock />
  }
  if (status === 'done' && info !== undefined) {
    const showToggleActionsButton =
      isPrimaryAccountType ||
      (settings && settings.allowAddressBookWithdrawalsOnly)
    return (
      <Fragment>
        <Flexbox direction={'row'} justify={'space-between'}>
          <Flexbox justify="center">
            <MultiBadge badges={accountRiskAlerts}>
              <>
                <h1>
                  Account #{accountId}
                  <IconButton onClick={handleCopyAccountId}>
                    <CopyAllRounded style={{ color: 'black' }} />
                  </IconButton>
                </h1>
              </>
            </MultiBadge>
            {accountType === 'SUB_ACCOUNT' &&
              primaryAccountSubAccounts.map((acc) => {
                if (acc.accountId === parseInt(accountId)) {
                  return (
                    <Flexbox
                      style={{
                        marginLeft: 8,
                        marginTop: accountRiskAlerts.length > 0 ? 5 : -13,
                      }}
                      key={accountId}
                    >
                      (
                      <span style={{ marginRight: 8 }}>
                        {acc.label} Subaccount to
                      </span>{' '}
                      <Link
                        style={{ marginRight: '1em' }}
                        id={primaryAccountId}
                        target="_blank"
                      >
                        #{primaryAccountId}
                      </Link>
                      )
                    </Flexbox>
                  )
                }
              })}
          </Flexbox>
          <Flexbox direction={'row'} justify={'flex-end'}>
            {isPrimaryAccountType && (
              <BlockAccount
                auth0Info={auth0Info}
                keycloakInfo={keycloakInfo}
                id={accountId}
              />
            )}
            {showToggleActionsButton && (
              <MuiButton onClick={toggleShowActionsModal}>
                {showActionsModal ? 'Hide' : 'Show'} Actions
              </MuiButton>
            )}
          </Flexbox>
        </Flexbox>
        {actionsDirty &&
          (isPrimaryAccountType ||
            (settings && settings.allowAddressBookWithdrawalsOnly)) && (
            <Flexbox wrap direction={'row'} justify="flex-end">
              <FadeInOutProfileActions active={showActionsModal}>
                <Spacer margin="12px 0px">
                  {isPrimaryAccountType && (
                    <Fragment>
                      {isAccountWithdrawalsSuspended && (
                        <InitiateWithdrawalDualAuthOverride
                          auth0Info={auth0Info}
                          id={accountId}
                        />
                      )}
                      {settings && settings.allowAddressBookWithdrawalsOnly && (
                        <RemoveWithdrawalAddressBook accountId={accountId} />
                      )}
                      <NavigateToZendesk email={info.email} />
                    </Fragment>
                  )}
                  {hasSuperUserRole && (
                    <RenderCheckbox
                      style={{ marginLeft: 24 }}
                      checked={isValrStaffAccount}
                      onClick={() => setChangeToStaffAccountModalOpen(true)}
                      label="Staff account"
                    />
                  )}
                </Spacer>
              </FadeInOutProfileActions>
            </Flexbox>
          )}
        <ConfirmationDialog
          title={'Update staff account status'}
          message={`Are you sure you want to update this accounts staff account status?`}
          open={changeToStaffAccountModalOpen}
          handleClose={() => setChangeToStaffAccountModalOpen(false)}
          buttonsHidden
        >
          <Flexbox justify="flex-end">
            <Flexbox width="30%" justify="space-around">
              <MuiButton
                onClick={() => setChangeToStaffAccountModalOpen(false)}
                color="secondary"
                disabled={isUpdatingValrStaffAccountStatus}
              >
                No
              </MuiButton>
              {isUpdatingValrStaffAccountStatus ? (
                <IconLoading color={VALR_BLUE_GRAY} />
              ) : (
                <MuiButton
                  onClick={handleChangeAccountStaffStatus}
                  disabled={isUpdatingValrStaffAccountStatus}
                >
                  Yes
                </MuiButton>
              )}
            </Flexbox>
          </Flexbox>
        </ConfirmationDialog>
      </Fragment>
    )
  }
  return <p>Account not found</p>
}
const mapStateToProps = (state) => ({
  details: getAccountDetails(state),
  auth0Info: getAuth0Info(state),
  keycloakInfo: getKeycloakInfo(state),
  accountId: state.ui.activeId,
  accountActivationStatus: getAccountMarginStatus(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateValrStaffAccountStatus,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader)
