import getAxios from 'helpers/axios'
import { reset } from 'redux-form'
import { toast } from 'react-toastify'
import ErrorToast from 'components/molecules/ErrorToast'

export const submit = async (values, dispatch) => {
  const {
    accountId, data, comment, flag,
  } = values
  data.comment = comment
  try {
    const res = await getAxios().post('/compliance/comments', {
      accountId,
      data,
      flag,
    })
    if (res.status === 200) {
      toast(`${res.data}`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
    } else {
      toast('Error occured while adding a comment', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
    dispatch(reset('ComplianceCommentsForm'))
  } catch (error) {
    ErrorToast(error)
  }
}
