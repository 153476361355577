import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import { fetchRebatesAndReferrals } from 'redux/rebateAndReferral/actions'
import {
  Details,
  DetailsBlock,
  DetailsBlockWithTitle,
  InfoBlock,
} from 'components/atoms/Details/index'
import { BASE } from 'theme/base-units'

class RebatesAndReferrals extends Component {
  componentDidMount() {
    const { accountId } = this.props
    this.props.fetchRebatesAndReferrals({
      path: `/admin/account/rewardlevel/${accountId}`,
    })
  }

  getData(data) {
    return data.rewardLevelsReached.map((results, i) => {
      const { rewardLevel, dateReached } = results
      return (
        <Fragment key={`#${i++}`}>
          <InfoBlock
            label={'Referral level'}
            value={rewardLevel.toString() || '--'}
          />
          <InfoBlock
            label={'Date reached'}
            value={
              dateReached !== undefined
                ? moment(dateReached).format('YYYY-MMM-DD HH:mm')
                : '...' || '--'
            }
          />
        </Fragment>
      )
    })
  }

  render() {
    const {
      rebatesAndReferrals: { status, data },
    } = this.props
    return (
      <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
        <DetailsBlockWithTitle title={'Referral level'} flow={'column'}>
          {status === 'loading' && (
            <TablePlaceholder>
              <IconLoading />
              <span>Loading, please wait</span>
            </TablePlaceholder>
          )}
          {status === 'done' && (
            <DetailsBlock flow={'row'}>
              {status === 'done' && this.getData(data)}
            </DetailsBlock>
          )}
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}

const mapStateToProps = state => ({
  rebatesAndReferrals: state.rebatesAndReferrals,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRebatesAndReferrals,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RebatesAndReferrals)
