import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import { Header } from 'components/atoms/Generic/index'
import { Flexbox } from 'components/layout'
import { RefreshOutlined } from '@material-ui/icons'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  uploadCorporateDocument,
  removeCorporateDocument,
  removeCorporateDocuments,
  restoreDocument,
} from 'redux/corporate/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import CorporateDocumentsSection from './CorporateDocumentsSection'

import { documentTypes } from '../const/CorporateDocuments'
import DocumentsViewer from '../components/DocumentsViewer'
import RestoreDocumentsModal from '../components/RestoreDocumentsModal'

const CorporateDocuments = props => {
  const {
    corporateAccountData: {
      id: corporateId,
      type: corporateType,
      companyDocuments,
      isFetchingCompanyDocuments,
      canEditCorporateInformation,
      removedDocuments,
      restoringCorporateDocument,
    },
    accountId,
  } = props

  const [isAddingEddDocuments, setIsAddingEddDocuments] = useState(false)
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)
  const [viewDocument, setViewDocument] = useState(false)
  const [documentsDataBeingViewed, setDocumentsDataBeingViewed] = useState([])
  const [
    restoringDocumentsModalOpen,
    setRestoringDocumentsModalOpen,
  ] = useState(false)

  const handleCloseDocumentViewer = () => {
    setViewDocument(false)
    setDocumentsDataBeingViewed([])
  }

  const handleEddDocumentsToggle = () => {
    setIsAddingEddDocuments(!isAddingEddDocuments)
  }

  const toggleRestoringDocumentsModal = () =>
    setRestoringDocumentsModalOpen(prev => !prev)

  const getEddDocuments = docsArray => {
    const regex = /^edd-/
    return docsArray.filter(doc => regex.test(doc.documentType))
  }

  const companyEddDocuments = getEddDocuments(companyDocuments)

  return (
    <>
      <Header title={'Company Documents'} />
      {isFetchingCompanyDocuments && <LoadingBlock />}
      {!isFetchingCompanyDocuments &&
        documentTypes[corporateType.split(' ').join('_')].map((type, i) => (
          <CorporateDocumentsSection
            key={i}
            accountId={accountId}
            canEditCorporateInformation={canEditCorporateInformation}
            type={type}
            document={companyDocuments.find(
              doc => doc.documentType === type.documentType,
            )}
            uploadCorporateDocument={props.uploadCorporateDocument}
            corporateType={corporateType}
            corporateId={corporateId}
            removeCorporateDocuments={props.removeCorporateDocuments}
            setIsLoadingDocument={setIsLoadingDocuments}
            setViewDocument={setViewDocument}
            setDocumentsDataBeingViewed={setDocumentsDataBeingViewed}
          />
        ))}
      {(isAddingEddDocuments || companyEddDocuments.length > 0) && (
        <>
          <Header title={'EDD Documents'} />
          {isFetchingCompanyDocuments && <LoadingBlock />}
          {!isFetchingCompanyDocuments &&
            documentTypes.EDD.map((type, i) => (
              <CorporateDocumentsSection
                key={i}
                accountId={accountId}
                canEditCorporateInformation={canEditCorporateInformation}
                type={type}
                document={companyEddDocuments.find(
                  doc => doc.documentType === type.documentType,
                )}
                uploadCorporateDocument={props.uploadCorporateDocument}
                corporateType={corporateType}
                corporateId={corporateId}
                removeCorporateDocuments={props.removeCorporateDocuments}
                setIsLoadingDocument={setIsLoadingDocuments}
                setViewDocument={setViewDocument}
                setDocumentsDataBeingViewed={setDocumentsDataBeingViewed}
              />
            ))}
        </>
      )}
      <Flexbox justify="space-between" marginTop='20'>
        {companyEddDocuments.length === 0 && (
          <MuiButton
            disabled={!canEditCorporateInformation}
            onClick={handleEddDocumentsToggle}
            startIcon={!isAddingEddDocuments ? <AddBoxIcon /> : <CancelIcon />}
          >
            {!isAddingEddDocuments ? 'ADD EDD DOCUMENTS' : 'CANCEL'}
          </MuiButton>
        )}
        {removedDocuments.length > 0 && (
          <MuiButton
            disabled={
              !canEditCorporateInformation || restoringCorporateDocument
            }
            onClick={toggleRestoringDocumentsModal}
            startIcon={<RefreshOutlined />}
          >
            RESTORE DOCUMENTS
          </MuiButton>
        )}
      </Flexbox>
      <DocumentsViewer
        isLoadingDocuments={isLoadingDocuments}
        isOpen={viewDocument}
        documentsDataBeingViewed={documentsDataBeingViewed}
        handleCloseDocumentViewer={handleCloseDocumentViewer}
        accountId={accountId}
        removeDocument={props.removeCorporateDocument}
        setDocumentsDataBeingViewed={setDocumentsDataBeingViewed}
      />
      <RestoreDocumentsModal
        restoringDocumentsModalOpen={restoringDocumentsModalOpen}
        removedDocuments={removedDocuments}
        toggleRestoringDocumentsModal={toggleRestoringDocumentsModal}
        accountId={accountId}
        canEditCorporateInformation={canEditCorporateInformation}
        restoreDocument={props.restoreDocument}
        restoringCorporateDocument={restoringCorporateDocument}
      />
    </>
  )
}

CorporateDocuments.propTypes = {}
const mapStateToProps = state => {
  const corporateAccountData = state.corporate
  return {
    corporateAccountData,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadCorporateDocument,
      removeCorporateDocument,
      removeCorporateDocuments,
      restoreDocument,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateDocuments)
