import React from 'react'
import { Field, reduxForm } from 'redux-form'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  FormContainer,
  FormTitle,
  InputGroup,
  SimpleFormError,
} from 'components/atoms/Form'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { renderInput } from 'forms/fields'
import { isValidEmail } from 'forms/fieldValidators'

import { submitFilter } from './submit'

const FormSearchAccountEmailActivity = props => {
  const { handleSubmit, submitting, invalid, error, pristine } = props
  return (
    <FormContainer onSubmit={handleSubmit(submitFilter)}>
      <FormTitle>Search Accounts</FormTitle>
      <InputGroup>
        <Field
          name={'email'}
          component={renderInput}
          label="Email"
          validate={[isValidEmail]}
          fullWidth
        />
      </InputGroup>
      {submitting && <LoadingBlock />}
      {error && !pristine && <SimpleFormError message={error} />}
      <InputGroup justify={'flex-end'}>
        <MuiButton type="submit" main="true" disabled={submitting || invalid}>
          Search
        </MuiButton>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxFormSearchAccountEmailActivity = reduxForm({
  form: 'searchAccountEmailActivity',
  validate: values => {
    const errors = {}
    if (!values.email) {
      errors._error = 'Please enter email address'
    }
    return errors
  },
})(FormSearchAccountEmailActivity)

export default ReduxFormSearchAccountEmailActivity
