import { RecommendationStatusTypes } from 'interfaces/riskProfile'
import {
  initiateCorporateRiskProfileDualAuth,
  initiateRiskProfileDualAuth,
} from 'redux/riskBasedProfiles/actions'
import { DualAuthPayload } from 'redux/riskBasedProfiles/interfaces'

export const submit = async (
  { isCorporateRiskProfile, ...values },
  dispatch,
) =>
  !isCorporateRiskProfile
    ? dispatch(initiateRiskProfileDualAuth(values as DualAuthPayload))
    : dispatch(initiateCorporateRiskProfileDualAuth(values as DualAuthPayload))
