import React from 'react'
import { Page, Text, View, Document as PdfDocument } from '@react-pdf/renderer'
import { IconButton } from '@material-ui/core'
import { CopyAllRounded } from '@mui/icons-material'
import { handleCopyToClipBoard } from 'helpers/utils'

import { Title } from 'components/atoms/Title'

import { styles } from './Styles'
import ApprovalsSection from './ApprovalsSection'

import { ICorporateRiskProfileEddReport } from '../../../../interfaces/riskProfile'
import { formatRecommendationDisplayList } from './helpers'

const CorporateRiskProfileEddReportPdfView = ({
  eddReportData,
  eddReportApprovals,
}) => {
  const {
    id,
    accountId,
    corporateId,
    name,
    countryOfRegistration,
    documentsStatus,
    natureOfBusiness,
    purposeOfAccount,
    riskScore,
    sourceOfFunds,
    accountOpenedDate,
    affiliated,
    companyRegistrationNumber,
    criminalHistory,
    describedNatureOfBusinessEmploymentGeneratingFunds,
    directors,
    emailAddress,
    estimatedAnnualRevenue,
    estimatedNetWorth,
    highRiskCountry,
    ipLogs,
    originOfFunds,
    pepDirectors,
    phoneNumber,
    preventionMeasures,
    registeredOfficeAddress,
    shareHolders,
    totalCryptoReceives,
    totalCryptoSends,
    totalFiatDeposits,
    totalFiatWithdrawals,
    transactionHistoryInternal,
    transactionHistoryExternal,
    typeOfCorporate,
    ultimateBeneficialOwner,
    website,
    recommendation,
    status,
    sourceOfWealth,
  }: ICorporateRiskProfileEddReport = eddReportData

  const corporateInformation = [
    { tableHeading: 'Corporate name', value: name },
    { tableHeading: 'Account ID', value: accountId },
    { tableHeading: 'Corporate ID', value: corporateId },
    { tableHeading: 'Account opened date', value: accountOpenedDate },
    {
      tableHeading: 'Company registration number',
      value: companyRegistrationNumber,
    },
    {
      tableHeading: 'Registered office address',
      value: registeredOfficeAddress,
    },
    { tableHeading: 'Phone number', value: phoneNumber },
    { tableHeading: 'Email address', value: emailAddress },
    { tableHeading: 'Website', value: website },
    { tableHeading: 'Nature of business', value: natureOfBusiness },
    { tableHeading: 'Type of corporate', value: typeOfCorporate },
    { tableHeading: 'Shareholders (10%>)', value: shareHolders },
    {
      tableHeading: 'Ultimate beneficial owner',
      value: ultimateBeneficialOwner,
    },
    { tableHeading: 'Directors', value: directors },
    { tableHeading: 'Source of funds', value: sourceOfFunds },
    {
      tableHeading: 'Describe nature of business/employment generating funds',
      value: describedNatureOfBusinessEmploymentGeneratingFunds,
    },
    { tableHeading: 'Origin of funds', value: originOfFunds },
    { tableHeading: 'Estimated net worth', value: estimatedNetWorth },
    { tableHeading: 'Estimated annual revenue', value: estimatedAnnualRevenue },
    { tableHeading: 'Purpose of account', value: purposeOfAccount },
    {
      tableHeading: 'Transaction history Internal',
      value: transactionHistoryInternal,
    },
    {
      tableHeading: 'Transaction history External',
      value: transactionHistoryExternal,
    },
    { tableHeading: 'Total fiat deposits', value: totalFiatDeposits },
    { tableHeading: 'Total fiat withdrawals', value: totalFiatWithdrawals },
    { tableHeading: 'Total crypto sends', value: totalCryptoSends },
    { tableHeading: 'Total crypto receives', value: totalCryptoReceives },
    { tableHeading: 'Ip logs', value: ipLogs },
    {
      tableHeading:
        'Is the company located in a high risk country? if yes, specify',
      value: highRiskCountry,
    },
    {
      tableHeading:
        'Is the company affiliated with any sanctioned entities or individuals? If yes, provide details',
      value: affiliated,
    },
    {
      tableHeading:
        'Has the company been involved in any criminal activities or investigations? If yes, provide details',
      value: criminalHistory,
    },
    {
      tableHeading:
        'Are any of the shareholders or directors PEPs? If yes, provide details',
      value: pepDirectors,
    },
    {
      tableHeading:
        'What measures are in place to prevent money laundering and terrorist financing?',
      value: preventionMeasures,
    },

    { tableHeading: 'Country of registration', value: countryOfRegistration },
    { tableHeading: 'Document status', value: documentsStatus },
    { tableHeading: 'Nature of business', value: natureOfBusiness },
    { tableHeading: 'Risk score', value: riskScore },
    { tableHeading: 'Affiliated', value: affiliated },
    { tableHeading: 'Source Of Wealth', value: sourceOfWealth },
  ]

  const riskAssessmentInformation = []

  const handleCopyReportLink = () => {
    handleCopyToClipBoard(window.location.href, false)
  }

  return id ? (
    <PdfDocument>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerMain}>
          <Text>
            ENHANCED DUE DILIGENCE FORM - 20 September 2023
            <IconButton onClick={handleCopyReportLink}>
              <CopyAllRounded style={{ color: 'white' }} />
            </IconButton>
          </Text>
        </View>
        <View style={styles.header}>
          <Text>I. CORPORATE ACCOUNTS</Text>
        </View>
        <View style={styles.header}>
          <Text>Corporate Information</Text>
        </View>
        <View style={styles.table}>
          {corporateInformation.map(({ tableHeading, value }) => (
            <View style={styles.tableRow} key={tableHeading}>
              <Text style={styles.cellHeading}>{tableHeading}</Text>
              <Text style={styles.cell}>{value || '--'}</Text>
            </View>
          ))}
        </View>
        <View style={styles.header}>
          <Text>Risk Assessment Information</Text>
        </View>
        <View style={styles.table}>
          {riskAssessmentInformation.map(({ tableHeading, value }) => (
            <View style={styles.tableRow} key={tableHeading}>
              <Text style={styles.cellHeading}>{tableHeading}</Text>
              <Text style={styles.cell}>{value || '--'}</Text>
            </View>
          ))}
        </View>
        <View style={styles.header}>
          <Text>Recommendation</Text>
        </View>
        <View style={styles.recommendation}>
          <View style={styles.recommendationText}>
            {status === 'APPROVED' &&
              formatRecommendationDisplayList(recommendation).map((line) => (
                <View style={styles.row}>
                  <Text>{line.replace('- ', '\u2022 ')}</Text>
                </View>
              ))}
            {status === 'PENDING' && (
              <Text>Edd report still pending approval</Text>
            )}
            {status === 'DECLINED' && <Text>Edd report rejected</Text>}
            {!status && <Text>No recommendation yet</Text>}
          </View>
        </View>
        <View style={styles.header}>
          <Text>Approvals</Text>
        </View>
        <ApprovalsSection eddReportApprovals={eddReportApprovals} />
      </Page>
    </PdfDocument>
  ) : (
    <Title text="No EDD report" type="h3" />
  )
}

export default CorporateRiskProfileEddReportPdfView
