import React, { useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import { Headers } from './headers'
import Row from './row'

export const BlockchainSendsTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const { expandItem, data } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(sends => {
            sends.createdAt = moment(sends.createdAt).format(
              'YYYY-MMM-DD HH:mm:ss',
            )
            return (
              <Row
                key={sends.id}
                dualAuthId={sends.id}
                sends={sends}
                isSelected={selectedRow === sends.id}
                toggleSelected={toggleSelectedRow}
              />
            )
          })}
      </TableBody>
    </Table>
  )
}
