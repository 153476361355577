import { UI_SET_ACTIVE_ID } from './actions'

const initialState = {
  activeId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_SET_ACTIVE_ID: {
      const { id } = action.payload
      return {
        ...state,
        activeId: id,
      }
    }
    default:
      return state
  }
}
