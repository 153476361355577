import {
  FETCH_VIRTUAL_ACCOUNT_START,
  FETCH_VIRTUAL_ACCOUNT_SUCCESS,
  FETCH_VIRTUAL_ACCOUNT_ERROR,
  FETCH_VIRTUAL_ACCOUNT_CLEAR
} from './actions'

interface VirtualAccountData {
  accountNumber: string,
  ifsc: string,
  bankAccountId: string,
}

interface VirtualAccountState {
  status: 'pristine' | 'loading' | 'done' | 'error',
  data: VirtualAccountData | null
}

const initialState: VirtualAccountState = {
  status: 'pristine',
  data: null,
}

export default (state: VirtualAccountState = initialState, action) => {
  switch (action.type) {
    case FETCH_VIRTUAL_ACCOUNT_START: {
      return {
        ...state,
        status: 'loading',
        data: null,
      }
    }

    case FETCH_VIRTUAL_ACCOUNT_ERROR: {
      return {
        ...state,
        status: 'error',
        data: null,
      }
    }

    case FETCH_VIRTUAL_ACCOUNT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case FETCH_VIRTUAL_ACCOUNT_CLEAR: {
      return {
        ...initialState,
      }
    }

    default:
      return state
  }
}
