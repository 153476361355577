import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import auth from 'services/Auth'
import { Field, reduxForm } from 'redux-form'
import {
  required,
  isNumber,
  moreThanZero,
  requiredSelectDropdownValue,
} from 'forms/fieldValidators'
import {
  FormContainer,
  BlueLinkButton,
  Button,
  InputGroup,
  FormError,
  InputContainer,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import {
  Details,
  DetailsBlockWithTitle,
  InfoLabel,
  JsonBlock,
} from 'components/atoms/Details'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import { hasDepositEditorRole } from 'helpers/roleBasedAccess'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import Notes from 'components/atoms/Notes'
import {
  constructArrayWithInitials,
  getCommentsForExchangeForm,
  returnInitials,
  returnStatusTypeComments,
} from 'helpers/utils'
import { EditableDropdown } from 'components/organisms/EditableDropdown/index'
import { renderDropdown, renderInput } from '../fields'
import { submit } from './submit'

const renderAccountIdField = (accountId, anyTouched) => {
  const hasRole = hasDepositEditorRole()
  return (
    <Field
      label={'Account Id (required)'}
      name={'accountId'}
      flex={0.3}
      value={accountId || ''}
      validate={[required, isNumber, moreThanZero]}
      disabled={!hasRole}
      component={renderInput}
      margin={`0 ${BASE * 2}px 0 0`}
      justify={'space-around'}
      marginTop={26}
      width="25%"
    />
  )
}

let Form = props => {
  // Form data mapped to props
  const {
    submitting,
    error,
    invalid,
    anyTouched,
    statusType,
    comment,
    selectedValue,
    change,
  } = props
  // ----------------
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    showRawData,
    expandRawData,
    showAddNote,
    title,
    expandAddNote,
    depositId,
    comments,
    isInDualAuth,
    showActiveUser,
    removeActiveUser,
  } = props

  const initials = returnInitials(auth.decodeFulNameFromToken())

  const commentsForForm = getCommentsForExchangeForm(`FIAT ${title}`)

  const commentsForStatusType = returnStatusTypeComments(
    commentsForForm,
    statusType,
  )

  const options = constructArrayWithInitials(
    commentsForStatusType,
    initials,
    statusType,
  )


  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  React.useEffect(
    () => {
      change('comment', '')
      change('zendeskCode', '')
      change('selectedValue', '')
    },
    [statusType],
  )

  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        {!isInDualAuth && (
          <InputContainer style={{ marginTop: '0px' }}>
            <InputGroup
              justify={'space-between'}
              align={'baseline'}
              direction={'row'}
            >
              <Field
                label={'Action'}
                name={'status'}
                margin={`0 ${BASE * 2}px 0 0`}
                flex={'0.3'}
                width="20%"
                type="deposits"
                component={renderDropdown}
                validate={[requiredSelectDropdownValue]}
                options={[
                  { value: 'APPROVE', label: 'Approve' },
                  { value: 'SUSPEND', label: 'Suspend' },
                  { value: 'IGNORE', label: 'Reject' },
                ]}
              />
              {(rawData.status.match('UNKNOWN_REFERENCE') !== null ||
                rawData.status.match('INVALID_DESCRIPTION') !== null) &&
                statusType !== 'SUSPEND' &&
                statusType !== 'IGNORE' &&
                renderAccountIdField(rawData.accountId, anyTouched)}
              {statusType !== 'TEMPORARY SUSPEND' &&
                <EditableDropdown
                  condition={comment}
                  label={'Comment (required)'}
                  name={'comment'}
                  selectFieldDropDownName={'selectedValue'}
                  width={'60%'}
                  validate={[required]}
                  options={[...options]}
                  justify={'space-around'}
                  margin={`0px ${BASE * 2}px 26px 0px`}
                  selectedValue={selectedValue ? selectedValue.value : ''}
                  comment={comment || ''}
                  change={change}
                />
              }
              {comment &&
                comment.toLowerCase().includes('zendesk') && (
                  <Field
                    label={'Zendesk code # (required)'}
                    name={'zendeskCode'}
                    width={'40%'}
                    validate={[required]}
                    component={renderInput}
                    justify={'space-around'}
                    margin={`0 ${BASE * 2}px 0 0`}
                    marginTop={26}
                  />
                )}
              <Flexbox
                marginRight={BASE * 2}
                marginTop={BASE * 1.5}
                justify={'center'}
                direction={'row'}
              >
                <Button
                  type="submit"
                  disabled={submitting || invalid || !comment}
                  margin={`0 ${BASE * 2}px 0 0`}
                >
                  {`Submit${submitting ? 'ting' : ''}`}
                </Button>
                <Button onClick={onCancel} color="secondary">
                  Cancel
                </Button>
              </Flexbox>
            </InputGroup>
          </InputContainer>
        )}
        <Details>
          <DetailsBlockWithTitle title="Statuses">
            <JsonBlock src={rawData} title={'Raw Data'} />
            <ReviewStatuses statuses={rawData.statuses} />
          </DetailsBlockWithTitle>
        </Details>
        <Details>
          <DetailsBlockWithTitle title="Notes">
            <Notes depositId={depositId} comments={comments} />
          </DetailsBlockWithTitle>
        </Details>
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showRawData}
            >
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showAddNote}
            >
              {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)


const mapStateToProps = (state, ownProps) => {
  const suspendedForm = state.form[ownProps.form]
  if (!suspendedForm) {
    return {
      statusType: '',
    }
  }
  if (suspendedForm.values) {
    const {
      values: { status, comment, selectedValue },
    } = suspendedForm
    return {
      statusType: status && status.value,
      selectedValue: selectedValue ? selectedValue : '',
      comment: comment || '',
    }
  }
  return { statusType: '' }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
