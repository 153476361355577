import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Button } from 'components/atoms/Form/index'
import { Actions } from 'components/atoms/Table/styles'
import {
  updateGlobalList,
  updateUserCompetitionListForm,
} from 'forms/RemoveUserFromList/submit'
import RenderCompetitionList from 'forms/RemoveUserFromList/render-marketing-contact-list'
import GlobalUnsubscribe from 'forms/RemoveUserFromList/add-or-remove-from-global-unsubscribe'

class RemoveUserFromCompetitionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdatingCompetitionList: false,
    }
  }

  toggleLoadingState = () => {
    this.setState({
      isUpdatingCompetitionList: !this.state.isUpdatingCompetitionList,
    })
  }

  render() {
    const { updatedList } = this.props.updatedData
    const { isGloballyUnsubscribed } = this.props.unsubscribes
    const handleUpdateGlobalSubcriptions = async addToGlobalList =>
      await updateGlobalList(
        addToGlobalList,
        this.props.email,
        this.props.dispatch,
        this.props.toggleForm,
      )
    return (
      <div>
        <GlobalUnsubscribe
          handleUpdateGlobalSubcriptions={handleUpdateGlobalSubcriptions}
          isGloballyUnsubscribed={isGloballyUnsubscribed}
        />
        <RenderCompetitionList email={this.props.email} />
        <Actions justify={'flex-start'}>
          <Button
            style={{ marginTop: '12px' }}
            onClick={async () => {
              this.toggleLoadingState()
              await updateUserCompetitionListForm(
                updatedList,
                this.props.dispatch,
              )
              this.toggleLoadingState()
            }}
            main="true"
            disabled={
              this.state.isUpdatingCompetitionList || updatedList.length === 0
            }
            type="button"
          >
            {` Updat${
              this.state.isUpdatingCompetitionList ? 'ing' : 'e'
            } Subscriptions`}
          </Button>
        </Actions>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  updatedData: state.updatedList,
})

export default connect(
  mapStateToProps,
  null,
)(RemoveUserFromCompetitionList)
