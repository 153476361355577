import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getStatus = state => state.status

export const getActivationStatus = createSelector(
  [getStatus, getActiveAccount],
  (status, id) => {
    if (!id || !status[id]) {
      return {
        status: 'pristine',
      }
    }

    return status[id]
  },
)
