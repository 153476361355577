import React, { Component, Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import * as moment from 'moment'
import { ZendeskLink } from 'components/organisms/ZendeskLink/zendesk'
import ShowTicketsComments from './showTicketsComments'
// import config from '../../../../config'

class TicketsRow extends Component {
  render() {
    const { toggleSelected, setHighlightedId, email } = this.props
    const { ticket, showForm, isHighlighted } = this.props
    const { id, subject, created_at, updated_at } = ticket
    const shading = isHighlighted ? 'white' : ''
    // TODO: Why is this not being used can it be removed? I have commented it out for now
    // const url = config.zendeskSupportUrl + '/agent/search/1?q=' + email
    return (
      <Fragment>
        <TableRow
          active={showForm}
          onClick={(e) => {
            setHighlightedId(id)
            toggleSelected(id)
          }}
          style={{
            backgroundColor: isHighlighted && '#4569eb',
            color: shading,
          }}
        >
          <TableCell width={'10%'}>
            <ZendeskLink color={shading} email={email} id={id} />
          </TableCell>
          <TableCell width={'40%'}>{subject}</TableCell>
          <TableCell width={'20%'}>
            {moment(created_at).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell width={'15%'}>
            {moment(updated_at).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
        </TableRow>
        {showForm && (
          <TableCell colSpan={4}>
            <ShowTicketsComments
              ticketId={id}
              form={`TicketsForm+${id}`}
              onCancel={this.toggleForm}
              onSubmitSuccess={this.toggleForm}
            />
          </TableCell>
        )}
      </Fragment>
    )
  }
}
export default TicketsRow
