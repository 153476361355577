import {FETCH_ASKS_AND_BIDS_PROCESS, FETCH_ASKS_AND_BIDS_SUCCESS, FETCH_ASKS_AND_BIDS_ERROR } from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASKS_AND_BIDS_PROCESS: {
      return {
        ...state,
        status: 'loading',
        data: [],
      }
    }

    case FETCH_ASKS_AND_BIDS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_ASKS_AND_BIDS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    default:
      return state
  }
}
