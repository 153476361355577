import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, isNumber } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropDownField, renderInput } from 'forms/fields'
import { submit } from './submit'
import { CORPORATE_ADDRESS_TYPES } from '../../const/Corporate'

const FormTypes = {
  address: 'Add Address',
  corporateAddressEdit: 'Edit Address',
  corporateAddressDelete: 'corporateAddressDelete',
}

let Form = props => {
  // REDUX-FORM-PROPS
  const { submitting, error, invalid, pristine, formType } = props
  const { handleSubmit, onCancel, onSubmit } = props

  const checkFormType = () => {
    const text = FormTypes[formType]
    return text
  }

  const addressTypeOptions = Object.keys(CORPORATE_ADDRESS_TYPES).map(
    key => CORPORATE_ADDRESS_TYPES[key],
  )

  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(async (values, dispatch) => {
          await onSubmit(values, dispatch, props.formActionType, props.onCancel)
        })}
      >
        <FormTitle>Address Details</FormTitle>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Type'}
            name={'addressType'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required]}
            options={addressTypeOptions}
            showNoneLabel={false}
            component={renderDropDownField}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Street'}
            name={'street'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Area'}
            name={'area'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Country'}
            name={'country'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Code'}
            name={'code'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required, isNumber]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Button
            type="submit"
            disabled={submitting || invalid || pristine}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            {checkFormType()}
          </Button>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)

const CorporateAddressForm = connect()(Form)

export default CorporateAddressForm
