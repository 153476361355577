import React, { useState } from 'react'

import { Table, TableBody } from 'components/atoms/Table/styles'
import { connectStateAndDispatch } from 'store'
import {
  fetchWithdrawalLimit,
  removeSubmittedWithdrawalItem,
  fetchWithdrawalLimitById,
} from 'redux/withdrawal-limit/actions'
import { Headers } from './headers'
import Row from './row'

const WithdrawalLimitChangeTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
    rowLoading,
    withdrawalLimit,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(withdrawalLimitDualAuth => (
          <Row
            key={withdrawalLimitDualAuth.id}
            dualAuthId={withdrawalLimitDualAuth.id}
            withdrawalLimitInfo={withdrawalLimitDualAuth}
            isSelected={selectedRow === withdrawalLimitDualAuth.id}
            toggleSelected={toggleSelectedRow}
            removeSubmittedWithdrawalItem={props.removeSubmittedWithdrawalItem}
            fetchWithdrawalLimitById={props.fetchWithdrawalLimitById}
            rowLoading={rowLoading}
            withdrawalLimit={withdrawalLimit} />
        ))}
      </TableBody>
    </Table>
  )
}

const actions = {
  fetchWithdrawalLimit,
  fetchWithdrawalLimitById,
  removeSubmittedWithdrawalItem,
}

export default connectStateAndDispatch((state) => ({
  rowLoading: state.withdrawalLimit.withdrawalLimitByIdLoading,
  withdrawalLimit: state.withdrawalLimit,
}), actions, WithdrawalLimitChangeTable)
