import styled from 'styled-components'


export const StyledFeesPerCurrencyPairWrapper = styled.div`
margin-top: 24px;
`
export const StyledAddNewFeesPerCurrencyPairWrapper = styled.div`
margin-top: 24px;
`
export const StyledNewCurrencyPairAction = styled.div`
display: flex;
align-items: center;
`