import {
  FETCH_CORPORATE_DETAILS_ERROR,
  FETCH_CORPORATE_DETAILS_START,
  FETCH_CORPORATE_DETAILS_SUCCESS,
  CAPTURE_CORPORATE_DETAILS_START,
  CAPTURE_CORPORATE_DETAILS_SUCCESS,
  CAPTURE_CORPORATE_DETAILS_ERROR,
  CAPTURE_ADDRESS_DETAILS_START,
  CAPTURE_ADDRESS_DETAILS_SUCCESS,
  CAPTURE_ADDRESS_DETAILS_ERROR,
  UPDATE_CORPORATE_ADDRESS_DETAILS_START,
  UPDATE_CORPORATE_ADDRESS_DETAILS_SUCCESS,
  UPDATE_CORPORATE_ADDRESS_DETAILS_ERROR,
  UPLOAD_CORPORATE_DOCUMENTS_START,
  UPLOAD_CORPORATE_DOCUMENTS_SUCCESS,
  UPLOAD_CORPORATE_DOCUMENTS_ERROR,
  UPDATE_CORPORATE_DETAILS_START,
  REMOVE_CORPORATE_DOCUMENT_START,
  REMOVE_CORPORATE_DOCUMENTS_START,
  REMOVE_CORPORATE_DOCUMENT_SUCCESS,
  REMOVE_CORPORATE_DOCUMENTS_SUCCESS,
  REMOVE_CORPORATE_ADDRESS_START,
  REMOVE_CORPORATE_ADDRESS_SUCCESS,
  REMOVE_CORPORATE_ADDRESS_ERROR,
  UPDATE_CORPORATE_DETAILS_SUCCESS,
  FETCH_COMPANY_DOCUMENTS_START,
  FETCH_COMPANY_DOCUMENTS_SUCCESS,
  FETCH_COMPANY_DOCUMENTS_ERROR,
  UPDATE_CORPORATE_DETAILS_ERROR,
  CHECK_CORPORATE_EDITOR_ROLE_START,
  CHECK_CORPORATE_EDITOR_ROLE_ERROR,
  CHECK_CORPORATE_EDITOR_ROLE_SUCCESS,
  CLEAR_CORPORPRATE_INFORMATION,
  RESTORE_CORPORATE_DOCUMENT_START,
  RESTORE_CORPORATE_DOCUMENT_SUCCESS,
  RESTORE_CORPORATE_DOCUMENT_ERROR,
  REMOVE_CORPORATE_DOCUMENT_ERROR,
  REMOVE_CORPORATE_DOCUMENTS_ERROR,
} from './actions'

const initialState = {
  id: null,
  account_id: null,
  name: '',
  type: '',
  purpose: '',
  nature_of_business: '',
  created_at: '',
  isLoading: false,
  isUpdating: false,
  corporateAddressData: {
    isLoading: false,
    data: [],
  },
  companyDocuments: [],
  removedDocuments: [],
  isLoadingCompanyDocument: false,
  isFetchingCompanyDocuments: false,
  isCheckingCorporateEditorRole: false,
  canEditCorporateInformation: false,
  restoringCorporateDocument: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CAPTURE_CORPORATE_DETAILS_START:
    case FETCH_CORPORATE_DETAILS_START: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case CAPTURE_CORPORATE_DETAILS_SUCCESS:
    case FETCH_CORPORATE_DETAILS_SUCCESS: {
      const { corporateData, corporateAddressDetails, removedDocuments } =
        action.payload
      return {
        ...state,
        ...corporateData,
        removedDocuments,
        isLoading: false,
        corporateAddressData: {
          isLoading: false,
          data: corporateAddressDetails,
        },
      }
    }

    case CAPTURE_ADDRESS_DETAILS_START: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: true,
        },
      }
    }

    case CAPTURE_ADDRESS_DETAILS_SUCCESS: {
      const data = action.payload
      const updatedCorporateAddressData = [...state.corporateAddressData.data]
      updatedCorporateAddressData.push(data)
      return {
        ...state,
        corporateAddressData: {
          data: updatedCorporateAddressData,
          isLoading: false,
        },
      }
    }

    case CAPTURE_ADDRESS_DETAILS_ERROR: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: false,
        },
      }
    }

    case UPDATE_CORPORATE_ADDRESS_DETAILS_START: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: true,
        },
      }
    }
    case UPDATE_CORPORATE_ADDRESS_DETAILS_SUCCESS: {
      const data = action.payload
      const updatedData = [...state.corporateAddressData.data]
      const indexOfCorporateAddress = updatedData.findIndex(
        (address) => address.id === data.id,
      )
      updatedData[indexOfCorporateAddress] = {
        ...updatedData[indexOfCorporateAddress],
        ...data,
      }
      if (indexOfCorporateAddress !== -1) {
        return {
          ...state,
          corporateAddressData: {
            isLoading: false,
            data: updatedData,
          },
        }
      }
      return state
    }

    case UPDATE_CORPORATE_ADDRESS_DETAILS_ERROR: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: false,
        },
      }
    }

    case CAPTURE_CORPORATE_DETAILS_ERROR:
    case FETCH_CORPORATE_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case UPDATE_CORPORATE_DETAILS_START: {
      return {
        ...state,
        isUpdating: true,
      }
    }
    case UPDATE_CORPORATE_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isUpdating: false,
      }
    }

    case UPDATE_CORPORATE_DETAILS_ERROR: {
      return {
        ...state,
        isUpdating: false,
      }
    }

    case FETCH_COMPANY_DOCUMENTS_START: {
      return {
        ...state,
        isFetchingCompanyDocuments: true,
      }
    }

    case FETCH_COMPANY_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        companyDocuments: action.payload,
        isFetchingCompanyDocuments: false,
      }
    }

    case FETCH_COMPANY_DOCUMENTS_ERROR: {
      return {
        ...state,
        isFetchingCompanyDocuments: false,
      }
    }

    case UPLOAD_CORPORATE_DOCUMENTS_START: {
      return {
        ...state,
        isLoadingCompanyDocument: true,
      }
    }

    case UPLOAD_CORPORATE_DOCUMENTS_SUCCESS: {
      const { companyDocument } = action.payload
      const udpatedCompanyDocuments = [...state.companyDocuments]
      udpatedCompanyDocuments.push(companyDocument)
      return {
        ...state,
        companyDocuments: udpatedCompanyDocuments,
        isLoadingCompanyDocument: false,
      }
    }

    case UPLOAD_CORPORATE_DOCUMENTS_ERROR: {
      return {
        ...state,
        isLoadingCompanyDocument: false,
      }
    }

    case REMOVE_CORPORATE_DOCUMENT_START:
    case REMOVE_CORPORATE_DOCUMENTS_START: {
      return {
        ...state,
        isLoadingCompanyDocument: true,
      }
    }

    case REMOVE_CORPORATE_DOCUMENT_SUCCESS: {
      const { id } = action.payload
      const removedDocument = state.companyDocuments.find(
        (doc) => doc.id === id,
      )
      const companyDocuments = state.companyDocuments.filter(
        (doc) => doc.id !== id,
      )
      return {
        ...state,
        companyDocuments,
        removedDocuments: [...state.removedDocuments, removedDocument],
        isLoadingCompanyDocument: false,
      }
    }
    case REMOVE_CORPORATE_DOCUMENT_ERROR: {
      return {
        ...state,
        isLoadingCompanyDocument: false,
      }
    }

    case REMOVE_CORPORATE_DOCUMENTS_SUCCESS: {
      const documentCategoryToRemove = action.payload?.[0]?.documentType
      if (!documentCategoryToRemove) return state
      const companyDocuments = state.companyDocuments.filter(
        (doc) => doc.documentType !== documentCategoryToRemove,
      )
      const removedDocuments = action.payload.filter(
        (doc) =>
          state.removedDocuments.findIndex(
            (removedDoc) => removedDoc.id === doc.id,
          ) === -1,
      )

      return {
        ...state,
        companyDocuments,
        isLoadingCompanyDocument: false,
        removedDocuments: state.removedDocuments.concat(removedDocuments),
      }
    }

    case REMOVE_CORPORATE_DOCUMENTS_ERROR: {
      return {
        ...state,
        isLoadingCompanyDocument: false,
      }
    }

    case REMOVE_CORPORATE_ADDRESS_START: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: true,
        },
      }
    }

    case REMOVE_CORPORATE_ADDRESS_SUCCESS: {
      const addressToRemove = action.payload
      const { id } = addressToRemove
      const updatedCorporateAddresses = [...state.corporateAddressData.data]
      const indexOfAddressToRemove = updatedCorporateAddresses.findIndex(
        (address) => address.id === id,
      )
      updatedCorporateAddresses.splice(indexOfAddressToRemove, 1)

      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          data: updatedCorporateAddresses,
          isLoading: false,
        },
      }
    }

    case REMOVE_CORPORATE_ADDRESS_ERROR: {
      return {
        ...state,
        corporateAddressData: {
          ...state.corporateAddressData,
          isLoading: false,
        },
      }
    }

    case CHECK_CORPORATE_EDITOR_ROLE_START: {
      return {
        ...state,
        isCheckingCorporateEditorRole: true,
      }
    }

    case CHECK_CORPORATE_EDITOR_ROLE_SUCCESS: {
      return {
        ...state,
        canEditCorporateInformation: action.payload,
        isCheckingCorporateEditorRole: false,
      }
    }

    case CHECK_CORPORATE_EDITOR_ROLE_ERROR: {
      return {
        ...state,
        isCheckingCorporateEditorRole: false,
      }
    }

    case RESTORE_CORPORATE_DOCUMENT_START: {
      return {
        ...state,
        restoringCorporateDocument: true,
      }
    }

    case RESTORE_CORPORATE_DOCUMENT_SUCCESS: {
      const restoredDocument = action.payload
      const updatedDocuments = [...state.companyDocuments, restoredDocument]
      const updatedRemovedDocuments = state.removedDocuments.filter(
        (doc) => doc.id !== restoredDocument.id,
      )
      return {
        ...state,
        companyDocuments: updatedDocuments,
        removedDocuments: updatedRemovedDocuments,
        restoringCorporateDocument: false,
      }
    }

    case RESTORE_CORPORATE_DOCUMENT_ERROR: {
      return {
        ...state,
        restoringCorporateDocument: false,
      }
    }

    case CLEAR_CORPORPRATE_INFORMATION: {
      return initialState
    }

    default:
      return state
  }
}
