import { useEffect, useState } from 'react'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'


const useHistoricalDeposits = (
  setHistoricalFiatFilters, clearHistoricalDeposits, historicalDeposits
) => {

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }))

  const [searching, setSearching] = useState(false)
  const [filter, setFilter] = useState('')
  const [limit] = useState(100)
  const [skip, setSkip] = useState(0)
  const [expandedRow, setExpandedRow] = useState('')


  useEffect(() => {
    setHistoricalFiatFilters({ skip, limit })
    return () => clearHistoricalDeposits()
  }, [])

  const fetchMore = async () => {
    const { limit: historicalDepositLimit } = historicalDeposits
    if (historicalDeposits.hasMoreRecords) {
      setSkip(skip + historicalDepositLimit)
    }
    await setHistoricalFiatFilters({
      skip: skip + historicalDepositLimit,
      limit: historicalDepositLimit,
      fetchMoreData: true,
    })
  }

  const toggleSelectedRow = id => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  const filterData = (value = '', data) => {
    const filteredData =
      value.length === 0
        ? data
        : data.filter((item) => {
          const {
            amount,
            bankReceivedAt,
            depositId,
            description,
            effectiveDate,
            insertedAt,
            source
          } = item
          if (
            source.toLowerCase().includes(value.toLowerCase()) ||
            depositId.toString().toLowerCase().includes(value.toLowerCase()) ||
            description
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            amount
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            bankReceivedAt
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            effectiveDate.toString().toLowerCase().includes(value.toLowerCase()) ||
            insertedAt
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            return item
          }
        })

    return filteredData
  }

  const debounce = _.debounce(
    (q) =>
      setSearching(false),
    500,
  )

  const handleSearch = (value) => {
    setFilter(value)
    setSearching(true)
    debounce(value)
  }

  return {
    filterData,
    handleSearch,
    filter,
    fetchMore,
    searching,
    toggleSelectedRow,
    useStyles,
  }
}

export default useHistoricalDeposits