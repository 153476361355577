import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { ERROR_COLOR, PANEL_BLUE } from 'theme/colors'
import { Autocomplete } from '@material-ui/lab'

export const StyledMuiInputLabel = styled(InputLabel)`
  padding-left: 5px;
  padding-right: 5px;
`

export const Dropdown = styled(FormControl)`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || 'auto')};
  margin: ${({ margin = '0px' }) => margin};

  .MuiInputLabel-root {
    background-color: transparent;
  }
  .MuiMenu-list {
    max-height: 200px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) => (error ? ERROR_COLOR : PANEL_BLUE)};
  }
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${({ width, fullWidth }) => (fullWidth ? '100%' : width || '300px')};
  margin: ${({ margin = '0px' }) => margin};
`

export const StyledOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${({ isSelected }) => (isSelected ? 'bolder' : 'lighter')};
`
