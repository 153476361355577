import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import { maxLength256, required } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  InputGroup,
} from 'components/atoms/Form'

import { submit } from './submit'

import { renderRadioGroup, renderTextArea } from '../fields'

let Form = (props) => {
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    submitting,
    error,
    invalid,
    recommendationStatus,
  } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {/* @ts-ignore */}
      <InputGroup align={'flex-start'} direction={'row'}>
        <Field
          label={'Recommendation status'}
          name={'recommendationStatus'}
          margin={`0 ${BASE * 2}px 8px 0`}
          width={'20%'}
          flex={0.4}
          component={renderRadioGroup}
          validate={[required]}
          options={[
            { value: 'MONITOR', label: 'Monitor' },
            { value: 'BLOCK', label: 'Block' },
            { value: 'TERMINATE', label: 'Terminate' },
          ].filter((option) => option.value !== recommendationStatus)}
        />

        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          style={{ padding: '0px 20px' }}
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[required]}
          component={renderTextArea}
          marginBottom={26}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      {/* @ts-ignore */}
      <InputGroup justify={'flex-end'}>
        <Button
          margin={`0 ${BASE * 2}px 0 0`}
          type="submit"
          disabled={submitting || invalid}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({
  form: 'risk_score_Form',
  onSubmit: submit,
  onSubmitSuccess: (values, dispatch, props) => props.toggleForm(),
  destroyOnUnmount: true,
})(Form)

export const RiskScoreForm = connect()(Form)
