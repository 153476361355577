import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { TransparentFormContainer, InputGroup } from 'components/atoms/Form'
import { fetchAccountLogs } from 'redux/accountLogs/actions'
import { renderCalenderDate } from 'forms/fields'
import { required } from 'forms/fieldValidators'

let Form = props => {
  const { submitting, invalid, handleSubmit, onSubmit } = props

  return (
    <TransparentFormContainer width={'100%'} onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'flex-start'}>
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Start Time'}
          name={'from'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          flex={0.4}
          marginRight={BASE * 2}
          validate={[required]}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
          value={new Date().toUTCString()}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          flex={0.4}
          label={'End Time'}
          validate={[required]}
          name={'to'}
          marginRight={BASE * 2}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
        />
        <MuiButton
          style={{ marginRight: '40%', marginTop: '1%' }}
          type="submit"
          main="true"
          disabled={submitting || invalid}
        >
          Submit Filter
        </MuiButton>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'ElasticsearchForm',
  onSubmit: (values, dispatch, props) => {
    const from = values.from.realDate
    const to = values.to.realDate
    const { id } = values
    const newValues = { id, from, to }
    dispatch(fetchAccountLogs(newValues))
    props.reset()
  },
  validate: values => {
    const errors = {}
    if (!values.from && !values.to) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
  enableReinitialize: true,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
