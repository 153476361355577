import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateFiat } from 'redux/fiat/actions'
import UnresolvedFiatDeposits from './unresolved-fiat-deposits'
import { addCurrencySymbol } from '../../../helpers/currency'
import { DEPOSIT_STATUSES, UNRESOLVED_FIAT_DEPOSIT_TITLES } from 'helpers/const'

class UnresolvedFiatDepositsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
    this.hasStatus = (depositStatuses, statusType) =>
      depositStatuses.findIndex(({ status }) => status === statusType) > -1
  }

  filterDepositsByStatus(data) {
    const potentialDuplicates = []
    const unknownReferences = []
    const depositsForReview = []
    const blacklisted = []
    const rejectedDeposit = []
    const fallBackImport = []
    const suspended = []
    const temporarySuspended = []
    data.forEach(element => {
      const showInPotentialDuplicates =
        this.hasStatus(element.statuses, DEPOSIT_STATUSES.POTENTIAL_DUPLICATE) &&
        !this.hasStatus(element.statuses, DEPOSIT_STATUSES.BLACKLISTED)
      const showInBlackListing =
        this.hasStatus(element.statuses, DEPOSIT_STATUSES.POTENTIAL_DUPLICATE) &&
        this.hasStatus(element.statuses, DEPOSIT_STATUSES.BLACKLISTED)

      if (this.hasStatus(element.statuses, DEPOSIT_STATUSES.AUTHORISATION_REJECTED)) {
        return rejectedDeposit.push(element)
      }
      if (!element.suspended) {
        if (
          showInPotentialDuplicates
        ) {
          return potentialDuplicates.push(element)
        } if (
          showInBlackListing
        ) {
          return blacklisted.push(element)
        } if (
          this.hasStatus(element.statuses, DEPOSIT_STATUSES.UNKNOWN_REFERENCE)
        ) {
          return unknownReferences.push(element)
        } if (
          this.hasStatus(element.statuses, DEPOSIT_STATUSES.FALLBACK_IMPORT)
        ) {
          return fallBackImport.push(element)
        } if (
          this.hasStatus(element.statuses, DEPOSIT_STATUSES.INVALID_DESCRIPTION)
        ) {
          return unknownReferences.push(element)
        }
      } else {
        if (['SUSPEND', 'DEPOSIT'].includes(element.depositDescription)) {
          return suspended.push(element)
          // we will remove this once all the deposit's in the temporray suspend folder has been removed
        } if (element.depositDescription === 'TEMPORARY SUSPEND') {
          return suspended.push(element)
        }
      }
      return depositsForReview.push(element)
    })

    ///111.00 tracy hand 525///date 1 october 2023 2024 june 2
    const addTotalAmount = (deposit) => {
      const total = deposit.map(d => d.amount).reduce((accumulator, value) => {
        const checkedCurrentValue = value ? parseFloat(typeof value === 'string' ? value.split(',').join('') : value) : 0
        return (accumulator + checkedCurrentValue)
      }, 0)

      const { currency } = this.props

      return `${addCurrencySymbol(total, currency)}`
    }

    return [
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.DEPOSITS_FOR_REVIEW, data: depositsForReview, amount: addTotalAmount(depositsForReview) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.POTENTIAL_DUPLICATES, data: potentialDuplicates, amount: addTotalAmount(potentialDuplicates) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.UNKNOWN_REFERENCES, data: unknownReferences, amount: addTotalAmount(unknownReferences) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.BLACKLISTED, data: blacklisted, amount: addTotalAmount(blacklisted) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT, data: fallBackImport, amount: addTotalAmount(fallBackImport) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.SUSPENDED, data: suspended, amount: addTotalAmount(suspended) },
      { title: UNRESOLVED_FIAT_DEPOSIT_TITLES.REJECTED_DUAL_AUTH, data: rejectedDeposit, amount: addTotalAmount(rejectedDeposit) },
    ]
  }

  render() {
    const {
      suspendedDeposits: { data },
      pendingAuthorizationData,
    } = this.props

    return (
      <Fragment>
        {this.filterDepositsByStatus(data).map(obj => (
          <UnresolvedFiatDeposits
            depositReview={{
              data: obj.data,
              allDeposits: data,
              pendingAuthorizationData,
            }}
            amount={obj.amount}
            title={obj.title}
            key={obj.title}
          />
        ))}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  pendingAuthorizationData: state.fiat.pendingAuthorisationDeposits,
  suspendedDeposits: state.fiat.suspendedDeposits,
  currency: state.fiat.filters.currency,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFiat,
    },
    dispatch,
  )


export default connect(mapStateToProps, mapDispatchToProps,)(UnresolvedFiatDepositsContainer)
