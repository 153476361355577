import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends Component {
  render() {
    const { component: Home, auth } = this.props
    return (
      <Route
        render={() => {
          if (auth.isAuthenticated()) {
            return <Home {...this.props} />
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )
        }}
      />
    )
  }
}

export default PrivateRoute
