import {
  RiskProfileWithDualAuthStatus,
  RiskProfileDualAuth,
  CorporateRiskProfileWithDualAuthStatus,
  CorporateRiskProfileDualAuth,
  RiskProfile,
} from 'interfaces/riskProfile'

import {
  CorporateRiskBasedApproachActionTypes,
  RiskBasedApproachActionTypes,
} from './types'

export interface RiskBasedProfilesState {
  individualRiskBasedProfileManagement: {
    newRiskProfiles: RiskProfileWithDualAuthStatus[]
    pendingRiskProfiles: RiskProfileWithDualAuthStatus[]
    monitorRiskProfiles: RiskProfileWithDualAuthStatus[]
    blockedRiskProfiles: RiskProfileWithDualAuthStatus[]
    terminatedRiskProfiles: RiskProfileWithDualAuthStatus[]
  }
  corporateRiskBasedProfileManagement: {
    newRiskProfiles: CorporateRiskProfileWithDualAuthStatus[]
    pendingRiskProfiles: CorporateRiskProfileWithDualAuthStatus[]
    monitorRiskProfiles: CorporateRiskProfileWithDualAuthStatus[]
    blockedRiskProfiles: CorporateRiskProfileWithDualAuthStatus[]
    terminatedRiskProfiles: CorporateRiskProfileWithDualAuthStatus[]
  }
  status: 'idle' | 'pending' | 'done'
  searchedRiskProfile: {
    isSearching: boolean
    searchedRiskProfileAccountId: number | null
    data: RiskProfileWithDualAuthStatus | null
  }
}
interface Action {
  type: RiskBasedApproachActionTypes | CorporateRiskBasedApproachActionTypes
  payload: {
    data:
      | RiskProfileWithDualAuthStatus[]
      | RiskProfileDualAuth
      | RiskProfileWithDualAuthStatus
      | CorporateRiskProfileWithDualAuthStatus
      | CorporateRiskProfileWithDualAuthStatus[]
      | number
    type: string
  }
}

const initialState: RiskBasedProfilesState = {
  individualRiskBasedProfileManagement: {
    newRiskProfiles: [],
    pendingRiskProfiles: [],
    monitorRiskProfiles: [],
    blockedRiskProfiles: [],
    terminatedRiskProfiles: [],
  },
  corporateRiskBasedProfileManagement: {
    newRiskProfiles: [],
    pendingRiskProfiles: [],
    monitorRiskProfiles: [],
    blockedRiskProfiles: [],
    terminatedRiskProfiles: [],
  },
  status: 'idle',
  searchedRiskProfile: {
    isSearching: false,
    data: null,
    searchedRiskProfileAccountId: null,
  },
}

export default (
  state = initialState,
  action: Action,
): RiskBasedProfilesState => {
  switch (action.type) {
    case 'FETCH_RISK_PROFILES_START':
    case 'FETCH_MORE_RISK_PROFILES_START':
    case 'FETCH_CORPORATE_RISK_PROFILES_START': {
      return {
        ...state,
        status: 'pending',
      }
    }
    case 'FETCH_RISK_PROFILES_SUCCESS': {
      const data = action.payload.data as RiskProfileWithDualAuthStatus[]
      const updatedState = {
        ...state,
      }
      if (action.payload.type === 'NEW') {
        updatedState.individualRiskBasedProfileManagement.newRiskProfiles = data
      }
      if (action.payload.type === 'PENDING') {
        updatedState.individualRiskBasedProfileManagement.pendingRiskProfiles = data
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.individualRiskBasedProfileManagement.monitorRiskProfiles = data
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.individualRiskBasedProfileManagement.blockedRiskProfiles = data
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.individualRiskBasedProfileManagement.terminatedRiskProfiles = data
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }

    case 'FETCH_MORE_RISK_PROFILES_SUCCESS': {
      const data = action.payload.data as RiskProfileWithDualAuthStatus[]
      const updatedState = {
        ...state,
      }
      if (action.payload.type === 'NEW') {
        updatedState.individualRiskBasedProfileManagement.newRiskProfiles = updatedState.individualRiskBasedProfileManagement.newRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'PENDING') {
        updatedState.individualRiskBasedProfileManagement.pendingRiskProfiles = updatedState.individualRiskBasedProfileManagement.pendingRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.individualRiskBasedProfileManagement.monitorRiskProfiles = updatedState.individualRiskBasedProfileManagement.monitorRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.individualRiskBasedProfileManagement.blockedRiskProfiles = updatedState.individualRiskBasedProfileManagement.blockedRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.individualRiskBasedProfileManagement.terminatedRiskProfiles = updatedState.individualRiskBasedProfileManagement.terminatedRiskProfiles.concat(
          data,
        )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }

    case 'FETCH_CORPORATE_RISK_PROFILES_SUCCESS': {
      const data = action.payload
        .data as CorporateRiskProfileWithDualAuthStatus[]
      const updatedState = {
        ...state,
      }
      if (action.payload.type === 'NEW') {
        updatedState.corporateRiskBasedProfileManagement.newRiskProfiles = data
      }
      if (action.payload.type === 'PENDING') {
        updatedState.corporateRiskBasedProfileManagement.pendingRiskProfiles = data
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.corporateRiskBasedProfileManagement.monitorRiskProfiles = data
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.corporateRiskBasedProfileManagement.blockedRiskProfiles = data
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.corporateRiskBasedProfileManagement.terminatedRiskProfiles = data
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }
    
    case 'FETCH_MORE_CORPORATE_RISK_PROFILES_SUCCESS': {
      const data = action.payload
        .data as CorporateRiskProfileWithDualAuthStatus[]
      const updatedState = {
        ...state,
      }
      if (action.payload.type === 'NEW') {
        updatedState.corporateRiskBasedProfileManagement.newRiskProfiles = updatedState.corporateRiskBasedProfileManagement.newRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'PENDING') {
        updatedState.corporateRiskBasedProfileManagement.pendingRiskProfiles = updatedState.corporateRiskBasedProfileManagement.pendingRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.corporateRiskBasedProfileManagement.monitorRiskProfiles = updatedState.corporateRiskBasedProfileManagement.monitorRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.corporateRiskBasedProfileManagement.blockedRiskProfiles = updatedState.corporateRiskBasedProfileManagement.blockedRiskProfiles.concat(
          data,
        )
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.corporateRiskBasedProfileManagement.terminatedRiskProfiles = updatedState.corporateRiskBasedProfileManagement.terminatedRiskProfiles.concat(
          data,
        )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }
    case 'FETCH_RISK_PROFILES_ERROR':
    case 'FETCH_MORE_RISK_PROFILES_ERROR': {
      return {
        ...state,
        status: 'idle',
      }
    }

    case 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_SUCCESS': {
      const data = action.payload.data as RiskProfile
      const updatedState = {
        ...state,
      }
      updatedState.individualRiskBasedProfileManagement.newRiskProfiles = updatedState.individualRiskBasedProfileManagement.newRiskProfiles.filter(
        item => item.accountId !== data.accountId,
      )
      updatedState.corporateRiskBasedProfileManagement.newRiskProfiles = updatedState.corporateRiskBasedProfileManagement.newRiskProfiles.filter(
        item => item.accountId !== data.accountId,
      )
      if (
        updatedState.searchedRiskProfile.data !== null &&
        updatedState.searchedRiskProfile.searchedRiskProfileAccountId
      ) {
        updatedState.searchedRiskProfile = {
          ...updatedState.searchedRiskProfile,
          data: {
            ...updatedState.searchedRiskProfile.data,
            recommendationStatus: 'PENDING',
          },
        }
      }

      return {
        ...updatedState,
        status: 'done',
      }
    }

    case 'UPDATE_RISK_PROFILES_INITIALIZATION_STATUS_ERROR': {
      return {
        ...state,
        status: 'idle',
      }
    }

    case 'CREATE_DUAL_AUTH_SUCCESS': {
      const data = action.payload.data as RiskProfileDualAuth
      const { accountId, status } = data
      const updatedState = {
        ...state,
      }

      if (
        updatedState.searchedRiskProfile.data !== null &&
        updatedState.searchedRiskProfile.searchedRiskProfileAccountId ===
          accountId
      ) {
        updatedState.searchedRiskProfile = {
          ...updatedState.searchedRiskProfile,
          data: {
            ...updatedState.searchedRiskProfile.data,
            dualAuthStatus: status,
          },
        }
      }

      if (action.payload.type === 'PENDING') {
        updatedState.individualRiskBasedProfileManagement.pendingRiskProfiles = updatedState.individualRiskBasedProfileManagement.pendingRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.individualRiskBasedProfileManagement.monitorRiskProfiles = updatedState.individualRiskBasedProfileManagement.monitorRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.individualRiskBasedProfileManagement.blockedRiskProfiles = updatedState.individualRiskBasedProfileManagement.blockedRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.individualRiskBasedProfileManagement.terminatedRiskProfiles = updatedState.individualRiskBasedProfileManagement.terminatedRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }
    case 'CREATE_CORPORATE_RISK_PROFILE_DUAL_AUTH_SUCCESS': {
      const data = action.payload.data as CorporateRiskProfileDualAuth
      const { accountId, status } = data
      const updatedState = {
        ...state,
      }

      if (
        updatedState.searchedRiskProfile.data !== null &&
        updatedState.searchedRiskProfile.searchedRiskProfileAccountId ===
          accountId
      ) {
        updatedState.searchedRiskProfile = {
          ...updatedState.searchedRiskProfile,
          data: {
            ...updatedState.searchedRiskProfile.data,
            dualAuthStatus: status,
          },
        }
      }

      if (action.payload.type === 'PENDING') {
        updatedState.corporateRiskBasedProfileManagement.pendingRiskProfiles = updatedState.corporateRiskBasedProfileManagement.pendingRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'MONITOR') {
        updatedState.corporateRiskBasedProfileManagement.monitorRiskProfiles = updatedState.corporateRiskBasedProfileManagement.monitorRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'BLOCK') {
        updatedState.corporateRiskBasedProfileManagement.blockedRiskProfiles = updatedState.corporateRiskBasedProfileManagement.blockedRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      if (action.payload.type === 'TERMINATE') {
        updatedState.corporateRiskBasedProfileManagement.terminatedRiskProfiles = updatedState.corporateRiskBasedProfileManagement.terminatedRiskProfiles.map(
          item => {
            if (item.accountId === accountId) {
              item.dualAuthStatus = status
            }
            return item
          },
        )
      }
      return {
        ...updatedState,
        status: 'done',
      }
    }

    case 'DUAL_AUTH_ERROR': {
      return {
        ...state,
        status: 'idle',
      }
    }

    case 'SEARCH_RISK_PROFILE_START': {
      return {
        ...state,
        searchedRiskProfile: {
          ...state.searchedRiskProfile,
          isSearching: true,
        },
      }
    }

    case 'SEARCH_RISK_PROFILE_SUCCESS': {
      return {
        ...state,
        searchedRiskProfile: {
          ...state.searchedRiskProfile,
          data: action.payload.data as RiskProfileWithDualAuthStatus,
          isSearching: false,
        },
      }
    }

    case 'SEARCH_RISK_PROFILE_ERROR': {
      return {
        ...state,
        searchedRiskProfile: {
          ...state.searchedRiskProfile,
          isSearching: false,
        },
      }
    }

    case 'CLEAR_SEARCHED_RISK_PROFILE': {
      return {
        ...state,
        searchedRiskProfile: {
          data: null,
          searchedRiskProfileAccountId: null,
          isSearching: false,
        },
      }
    }

    case 'UPDATE_RISK_PROFILE_ACCOUNT_ID': {
      return {
        ...state,
        searchedRiskProfile: {
          ...state.searchedRiskProfile,
          searchedRiskProfileAccountId: action.payload.data as number,
          isSearching: true,
        },
      }
    }

    default:
      return state
  }
}
