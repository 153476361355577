import {
  FETCH_AUTH0_USER_PROCESS,
  FETCH_AUTH0_USER_SUCCESS,
  FETCH_AUTH0_USER_ERROR,
  FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS,
  FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS,
  FETCH_OTHER_AUTHO_ACCOUNTS_ERROR,
  LINK_AUTH0_ACCOUNTS_PROCESS,
  LINK_AUTH0_ACCOUNTS_SUCCESS,
  LINK_AUTH0_ACCOUNTS_ERROR,
  UNLINK_AUTH0_ACCOUNTS_PROCESS,
  UNLINK_AUTH0_ACCOUNTS_ERROR,
  REMOVE_AUTH0_LOADING_STATE,
} from './actions'

const initialState = {
  byId: {},
  otherAccountsById: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTH0_USER_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'loading',
          }
        }
      }
    }

    case FETCH_AUTH0_USER_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            info: data,
            status: 'done',
            error: null,
          }
        }
      }
    }

    case FETCH_AUTH0_USER_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            error
          }
        }
      }
    }
     
    case REMOVE_AUTH0_LOADING_STATE: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'auth0Error',
            data
          }
        }
      }
    }

    case FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            status: 'loading'
          }
        }
      }
    }

    case FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            data,
            status: 'done',
            error: null
          }
        }
      }
    }

    case FETCH_OTHER_AUTHO_ACCOUNTS_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            status: 'error',
            error
          }
        }
      }
    }

    case LINK_AUTH0_ACCOUNTS_PROCESS: {
      const { id, index } = action.payload
      let data = state.otherAccountsById[id].data
      data[index]['status'] = 'loading'
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            ...state.otherAccountsById[id],
            data
          }
        }
      }
    }

    case LINK_AUTH0_ACCOUNTS_SUCCESS: {
      const { id, index } = action.payload
      let data = state.otherAccountsById[id].data
      delete data[index]
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            ...state.otherAccountsById[id],
            data
          }
        }
      }
    }

    case LINK_AUTH0_ACCOUNTS_ERROR: {
      const { id, index } = action.payload
      let data = state.otherAccountsById[id].data
      data[index]['status'] = 'done'
      return {
        ...state,
        otherAccountsById: {
          ...state.otherAccountsById,
          [id]: {
            ...state.otherAccountsById[id],
            data: data
          }
        }
      }
    }

    case UNLINK_AUTH0_ACCOUNTS_PROCESS: {
      const { id, index } = action.payload
      let info = state.byId[id].info
      info.identities[index]['status'] = 'loading'
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            info
          }
        }
      }
    }

    case UNLINK_AUTH0_ACCOUNTS_ERROR: {
      const { id, index } = action.payload
      let info = state.byId[id].info
      info.identities[index]['status'] = 'done'
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            info
          }
        }
      }
    }

    default:
      return state
  }
}