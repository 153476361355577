import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getMarginStatus = state => state.marginStatus

export const getAccountMarginStatus = createSelector(
  [getMarginStatus, getActiveAccount],
  (marginStatus, id) => {
    if (!id || !marginStatus[id]) {
      return {
        status: 'pristine',
      }
    }

    return marginStatus[id]
  },
)
