
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_ACCOUNT_CONFIRMATIONS_PROCESS = 'FETCH_ACCOUNT_CONFIRMATIONS_PROCESS'
export const FETCH_ACCOUNT_CONFIRMATIONS_SUCCESS = 'FETCH_ACCOUNT_CONFIRMATIONS_SUCCESS'
export const FETCH_ACCOUNT_CONFIRMATIONS_ERROR = 'FETCH_ACCOUNT_CONFIRMATIONS_ERROR'

export const fetchAccountConfirmations = accountId => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNT_CONFIRMATIONS_PROCESS,
    payload: {
      accountId,
    },
  })
  try {
    const res = await getAxios().get(`/account/confirmations/${accountId}`)
    const { data } = res
    dispatch({
      type: FETCH_ACCOUNT_CONFIRMATIONS_SUCCESS,
      payload: {
        accountId,
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_CONFIRMATIONS_ERROR,
      payload: { error },
    })
  }
}
