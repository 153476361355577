import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { OpenOrder } from 'interfaces/openOrders'
import { Dispatch } from 'redux'

export type TradingViewActions =
  | 'FETCH_ACCOUNT_OPEN_ORDERS_START'
  | 'FETCH_ACCOUNT_OPEN_ORDERS_SUCCESS'
  | 'FETCH_ACCOUNT_OPEN_ORDERS_ERROR'
  | 'CANCEL_ACCOUNT_OPEN_ORDER_START'
  | 'CANCEL_ACCOUNT_OPEN_ORDER_SUCCESS'
  | 'CANCEL_ACCOUNT_OPEN_ORDER_ERROR'
  | 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_START'
  | 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_SUCCESS'
  | 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_ERROR'

export type DispatchAction<P> = {
  type: TradingViewActions
  payload?: P
}

export const fetchAccountOpenOrders =
  (accountId: string) =>
  async (dispatch: Dispatch<DispatchAction<OpenOrder[]>>) => {
    try {
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_ORDERS_START',
      })
      const { data } = await getAxios().get(
        `/account/orderbook/open/${accountId}`,
      )
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_ORDERS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_ORDERS_ERROR',
      })
    }
  }

export const fetchAccountOpenFuturesPositions =
  (accountId: string) => async (dispatch: Dispatch<DispatchAction<any>>) => {
    try {
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_START',
      })
      const { data } = await getAxios().get(
        `/futures/positions/open/${accountId}`,
      )
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_ACCOUNT_OPEN_FUTURES_POSITIONS_ERROR',
      })
    }
  }

export const cancelOpenOrderForAccount =
  (orderData: {
    accountId: number | string
    orderId: string
    currencyPair: string
  }) =>
  async (dispatch: Dispatch<DispatchAction<{ canceledOrderId: string }>>) => {
    try {
      dispatch({
        type: 'CANCEL_ACCOUNT_OPEN_ORDER_START',
      })
      const { data } = await getAxios().delete(`/account/orderbook/cancel`, {
        data: orderData,
      })
      dispatch({
        type: 'CANCEL_ACCOUNT_OPEN_ORDER_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'CANCEL_ACCOUNT_OPEN_ORDER_ERROR',
      })
    }
  }
