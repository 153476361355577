import styled, { keyframes } from 'styled-components'

import MuiButton from './Buttons/MuiButton'

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.link.text};
  display: block;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    color: red;
  }
`
const fadeInBtn = keyframes`
  0% {
    margin-right: -120px;
  }
  100% {
    margin-right: 12px;
  }
`

const fadeOutBtn = keyframes`
  0% {
    margin-right: 10px;
}

100% {
  margin-right: -120px;
  }
`

export const FadeInButton = styled(MuiButton)<{
  hidden?: boolean
  active?: boolean
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  height: 40px;
  animation: 0.5s ${({ active }) => (active ? fadeInBtn : fadeOutBtn)} both;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.5s;
`
