import React, { FC } from 'react'
import { CorporateNaturalEntity } from '../../../interfaces/riskProfile'
import {
  CorporateRiskProfileEntityData,
  StyledCorporateRiskProfileEntityCard,
} from '../../Styles'

interface ICorporateRiskProfileEntity {
  entity: CorporateNaturalEntity
}

const CorporateRiskProfileEntity: FC<ICorporateRiskProfileEntity> = ({
  entity,
}) => {
  return (
    <StyledCorporateRiskProfileEntityCard
      align="flex-start"
      marginRight={8}
      direction="column"
      padding="12px"
      marginBottom={8}
      key={entity.id}
    >
      <CorporateRiskProfileEntityData>
        Name: {entity.firstName || '--'}
      </CorporateRiskProfileEntityData>
      <CorporateRiskProfileEntityData>
        Surname: {entity.lastName || '--'}
      </CorporateRiskProfileEntityData>
      <CorporateRiskProfileEntityData>
        Roles:{' '}
        {entity.roles!.map((r: string, i: number) =>
          i === entity.roles!.length - 1 ? r : r + ', ',
        ) || '--'}
      </CorporateRiskProfileEntityData>
      <span>Nationality: {entity.nationality || '--'}</span>
    </StyledCorporateRiskProfileEntityCard>
  )
}

export default CorporateRiskProfileEntity
