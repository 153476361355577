import LoadingBlock from 'components/molecules/LoadingBlock/index'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchCompetitionList } from 'redux/displayList/actions'
import { updateCompetitionList } from 'redux/updateList/actions'
import { CheckboxTextSize, Input } from './styles'

class RenderList extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.fetchCompetitionList(this.props.email)
  }

  onCheck = (e, i, formId, deleteId) => {
    this.props.updateCompetitionList({
      id: formId,
      deleteId,
      checked: e.target.checked,
    })
  }

  render() {
    const { status, data } = this.props.listData
    const updatedListData = this.props.updatedListData

    return (
      <div>
        {status !== 'done' && (
          <LoadingBlock message="Loading competition lists data" />
        )}
        {status === 'done' &&
          data &&
          data.map((marketingContactList, i) => {
            return (
              <div key={i}>
                <CheckboxTextSize>
                  <label>
                    <Input
                      onChange={e =>
                        this.onCheck(
                          e,
                          i,
                          marketingContactList.id,
                          marketingContactList.deleteId,
                        )
                      }
                      checked={
                        updatedListData.findIndex(
                          list =>
                            list.id === marketingContactList.id,
                        ) === -1
                      }
                      name={marketingContactList.name}
                      type="checkbox"
                    />
                    {marketingContactList.name}
                  </label>
                </CheckboxTextSize>
              </div>
            )
          })}
          {data.length === 0 && status === 'done' && 'No marketing list data'}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listData: state.listData,
  updatedListData: state.updatedList.updatedList,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCompetitionList,
      updateCompetitionList,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RenderList)
