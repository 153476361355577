import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  Button, FormContainer, FormError, BlueLinkButton,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import ReviewView from 'components/organisms/ReviewView'
import { Flexbox } from 'components/layout/index'
import RawData from 'components/atoms/RawDataDualAuth/index'
import auth from 'services/Auth'
import {
  constructArrayWithInitials,
  getCommentsForExchangeForm,
  returnInitials,
  returnStatusTypeComments,
} from 'helpers/utils'
import { EditableDropdown } from 'components/organisms/EditableDropdown/index'
import { Details, DetailsBlockWithTitle, InfoLabel } from 'components/atoms/Details'
import WithdrawalNotes from 'components/atoms/Notes/withdrawalNotes'
import { renderDropdown, renderInput, RenderSimpleCheckbox } from '../fields'
import { submitFiatWithdrawalReview } from './submit'
import { getSelectedBankAccount } from '../../redux/fiat/selectors'
import { Title } from 'components/atoms/Title'

const refineRawData = data => {
  const {
    bank, name, surname, withdrawalId, branchCode, accountType,
  } = data
  const { rtcParticipant, defaultBranchCode } = bank
  return {
    name,
    surname,
    withdrawalId,
    branchCode,
    accountType,
    defaultBranchCode,
    rtcParticipant,
  }
}

let Form = props => {
  const {
    submitting, error, invalid, form, change,
  } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    formData,
    showRawData,
    showActiveUser,
    removeActiveUser,
    title,
    country,
    suspended,
    withdrawalId,
    comments,
    showAddNote,
    expandAddNote,
    expandRawData,
  } = props

  const reviews = rawData.review
  const flags = reviews && reviews.flags
  const audits = reviews && reviews.audits
  const formValues = formData[form] !== undefined ? formData[form].values : ''
  const { status, comment, selectedValue } = formValues || { status: '', comment: '', selectedValue: '' }

  const initials = returnInitials(auth.decodeFulNameFromToken())

  const commentsForForm = getCommentsForExchangeForm(`FIAT ${title}`)

  const commentsForStatusType = returnStatusTypeComments(
    commentsForForm,
    status && status.value,
  )

  const options = constructArrayWithInitials(
    commentsForStatusType,
    initials,
    status && status.value,
  )

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => () => {
    removeActiveUser()
  }, [])

  React.useEffect(
    () => {
      change('comment', '')
      change('selectedValue', '')
    },
    [status],
  )

  React.useEffect(() => {
    change('country', country)
  }, [country, change])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: '1%' }}>
        <Flexbox
          align={'flex-start'}
          justify={'space-between'}
          direction={'row'}
        >
          {suspended && <Field
            label={'Action'}
            name={'status'}
            margin={`0 ${BASE * 2.5}px 0 0`}
            width={'20%'}
            flex={0.4}
            component={renderDropdown}
            options={[
              { value: 'APPROVE', label: 'Approve' },
              { value: 'DECLINE', label: 'Reverse' },
            ]}
          />}
          {!suspended &&
            <Field
              label={'Action'}
              name={'status'}
              margin={`0 ${BASE * 2.5}px 0 0`}
              width={'20%'}
              flex={0.4}
              component={renderDropdown}
              options={[
                { value: 'APPROVE', label: 'Approve' },
                { value: 'DECLINE', label: 'Reverse' },
                { value: 'SUSPEND', label: 'Suspend' },

              ]}
            />
          }
          {status && status.value === 'DECLINE' ? (
            <Field
              label={'Reverse Fee'}
              name={'reverseFee'}
              width={'85px'}
              flex={0}
              marginTop={12}
              marginLeft={10}
              marginRight={BASE * 2.5}
              transform={2.9}
              component={RenderSimpleCheckbox}
            />
          ) : (
            <></>
          )}
          {status.value !== 'SUSPEND' && <EditableDropdown
            condition={comment}
            label={'Comment'}
            name={'comment'}
            selectFieldDropDownName={'selectedValue'}
            width={'60%'}
            validate={[required]}
            options={[...options]}
            justify={'space-around'}
            margin={`0px ${BASE * 2}px 26px 0px`}
            selectedValue={selectedValue ? selectedValue.value : ''}
            comment={comment || ''}
            change={change}
          />
          }
          {status && status.value === 'SUSPEND' && (
            <Field
              label={'Comment (required)'}
              name={'comment'}
              width={'100%'}
              validate={[required]}
              component={renderInput}
              justify={'space-around'}
              margin={`0 ${BASE * 2}px 0 0`}
              marginTop={26}
            />
          )}

          <div style={{ width: '286px', marginTop: '16px' }}>
            <Button
              margin={`0 ${BASE * 2}px 0 0`}
              type="submit"
              disabled={
                (status && status.value === 'Select') ||
                (status && status.value === null) ||
                submitting ||
                invalid
              }
            >
              Submit
            </Button>
            <Button padding={'16px 41px'} onClick={onCancel} color="secondary">
              Cancel
            </Button>
          </div>
        </Flexbox>
      </div>
      <ReviewView flags={flags} audits={audits} />
      <Details direction="column">
        <Title type='h4' text='AUDITS' />
        <WithdrawalNotes withdrawalId={withdrawalId} comments={comments} />
      </Details>
      <Flexbox
        direction={'column'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <Flexbox
          style={{ color: 'white' }}
          direction={'row'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showRawData}
          >
            {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
          </BlueLinkButton>
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showAddNote}
          >
            {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
          </BlueLinkButton>
        </Flexbox>
        {expandRawData && (
          <Fragment>
            <Flexbox
              marginBottom={'10'}
              marginTop={'10'}
              direction={'column'}
              align={'flex-start'}
              justify={'space-around'}
            >
              <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
              <RawData data={refineRawData(rawData)} />
            </Flexbox>
          </Fragment>
        )}
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submitFiatWithdrawalReview })(Form)
const mapStateToProps = state => ({
  formData: state.form,
  country: getSelectedBankAccount(state).country,
})
const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
