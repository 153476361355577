import React, { FC, useEffect, useState } from 'react'
import { FlexRow, Flexbox } from 'components/layout'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { connectStateAndDispatch } from 'store'
import { SingleAccountInfo } from 'interfaces/singleAccount'

import { Title } from 'components/atoms/Title'
import {
  fetchRiskProfileEddReport,
  fetchCorporateRiskProfileEddReport,
  fetchRiskProfileInfo,
  updateCorporateRiskProfileEddReport,
  updateRiskProfileEddReport,
  fetchAccountCorporateInformation,
} from 'redux/accounts/actions'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { getAccountAddress } from 'redux/selectors/address'
import { getCustomerType } from 'redux/selectors/type'

import { RiskProfileEddReportForm } from './RiskProfileEddReportForm'
import RiskProfileEddReportPdfView from './RiskProfilePdf/RiskProfileEddReportPdfView'
import { RiskProfilePdfContainer } from '../../Styles'
import CorporateRiskProfileEddReportPdfView from './RiskProfilePdf/CorporateRiskProfilePdfView'
import { CorporateRiskProfileEddReportForm } from './CorporateRiskProfileEddReportForm'

import { AccountRiskProfile } from '../../../interfaces/riskProfile'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'
import { useIndividualRiskProfileEddReport } from 'Hooks/RiskBasedApproach/useIndividualRiskProfileEddReport'
import { useCorporateRiskProfileEddReport } from 'Hooks/RiskBasedApproach/useCorporateRiskProfileEddReport'
import { getKeycloakInfo } from 'redux/selectors/keycloak'
import { IKeycloakInfo } from 'interfaces/keycloak'
import HistorySection from './RiskProfilePdf/HistorySection'
import { checkIfAccountIsCorporate } from './RiskProfilePdf/helpers'
import { Spacer } from 'components/atoms/Generic'

interface RiskProfileEddReportProps {
  riskProfile: AccountRiskProfile
  address: {
    status: string
    data: {
      addressLine1: string
      addressLine2: string
      suburb: string
      city: string
      region: string
      country: string
      postalCode: string
    }
    error: any
  }
  customerTypeInfo: {
    [key: string]: {
      status: string
      data: {
        accountId: number
        type: string
        updatedByAuthProviderId: string
        comment: string
        insertedAt: string
        updatedAt: string
        keyRelianceAgreementStatus: boolean
      }
      error: string
    }
  }
  accountInfo: SingleAccountInfo
  keycloakInfo: IKeycloakInfo
  fetchAccountCorporateInformation: (accountId: string) => Promise<void>
  fetchRiskProfileEddReport: (accountId: string) => Promise<void>
  fetchCorporateRiskProfileEddReport: (accountId: string) => Promise<void>
  fetchRiskProfileInfo: (accountId: string) => Promise<void>
  resetForm: () => Promise<void>
  match: any
  history: any
}

const RiskProfileEddReport: FC<RiskProfileEddReportProps> = (props) => {
  const [showRiskProfileEddReport, setShowRiskProfileEddReport] =
    useState(false)
  const [backArrowClicked, setBackArrowClicked] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [viewHistory, setViewHistory] = useState(false)
  const flagOn = useFeatureFlagCheck('riskBasedApproach')
  const isCorporateEddReport = window.location.pathname.includes('corporate')
  const { riskProfile, accountInfo, address, keycloakInfo } = props
  const {
    eddReport,
    eddReportApprovals: individualEddReportApprovals,
    corporateEddReport,
    corporateEddReportApprovals,
    corporateDualAuthHistory,
    info,
    corporateInfo,
    isFetchingRiskProfileEddReport,
    isFetchingRiskProfileInfo,
    riskScoringFormOptions,
    accountCorporate,
    accountCorporateNaturalEntities,
    isFetchingCorporateForAccount,
    isFetchingRiskScoringOptions,
    dualAuthHistory,
  } = riskProfile
  const { accountId } = props.match.params

  const {
    individualEddReportInitialValues,
    individualEddReportPdfData,
    hasAccountInfoDiff,
    hasRiskProfileInfoDiff,
  } = useIndividualRiskProfileEddReport(
    eddReport,
    info,
    accountInfo,
    accountId,
    address.data,
    keycloakInfo,
  )

  const {
    corporateEddReportInitialValues,
    hasAccountCorporateRiskProfileEddReportDiff,
    hasCorporateRiskProfileEddReportDiff,
    hasCorporateTypeDiff,
  } = useCorporateRiskProfileEddReport(
    accountCorporate,
    accountCorporateNaturalEntities,
    corporateInfo,
    corporateEddReport,
    accountInfo,
  )

  const eddReportExists = eddReport.id
  const status = eddReport.status
  const corporateEddReportExists = corporateEddReport.id

  const toggleRiskProfileEddReportForm = () =>
    setShowRiskProfileEddReport((prev) => !prev)

  const isCorporateAccount = checkIfAccountIsCorporate(props.customerTypeInfo)

  const fetchCorporateInfo = async () => {
    if (accountId) {
      if (isCorporateAccount) {
        await props.fetchAccountCorporateInformation(accountId)
      }
      if (isCorporateEddReport && isCorporateAccount) {
        await props.fetchCorporateRiskProfileEddReport(accountId)
      }
    }
  }

  const fetchData = async () => {
    if (accountId) {
      if (isCorporateAccount) {
        await props.fetchAccountCorporateInformation(accountId)
      }
      await props.fetchRiskProfileInfo(accountId)
      if (!isCorporateEddReport) {
        await props.fetchRiskProfileEddReport(accountId)
      }
    }
  }

  const viewEddDocumentHistory = () => {
    setViewHistory((prev) => !prev)
  }

  useEffect(() => {
    if (!flagOn) {
      props.history.goBack()
    } else {
      fetchData()
    }

    return () => {}
  }, [])

  useEffect(() => {
    fetchCorporateInfo()

    return () => {}
  }, [props.customerTypeInfo])

  if (isFetchingRiskProfileEddReport || isFetchingRiskProfileInfo) {
    return (
      <LoadingBlock
        message={`Fetching ${
          isCorporateEddReport ? 'Corporate ' : ''
        }risk profile edd report`}
      />
    )
  }
  if (isFetchingCorporateForAccount || isFetchingRiskScoringOptions) {
    return <LoadingBlock message="Loading..." />
  }

  return (
    <div>
      {(hasAccountInfoDiff || hasRiskProfileInfoDiff) &&
      !isCorporateEddReport &&
      !viewHistory &&
      eddReportExists ? (
        <Title
          color="red"
          type="h4"
          text="EDD report is outdated please update!"
        />
      ) : null}
      {(hasAccountCorporateRiskProfileEddReportDiff ||
        (hasCorporateRiskProfileEddReportDiff && hasCorporateTypeDiff)) &&
      corporateEddReportExists &&
      !viewHistory &&
      isCorporateEddReport ? (
        <Title
          color="red"
          type="h4"
          text="Corporate EDD report is outdated please update!"
        />
      ) : null}
      <Flexbox marginBottom={18} justify="space-between">
        {!viewHistory && (
          <Title
            type="h3"
            text={`${
              isCorporateEddReport ? 'Corporate' : 'Individual'
            } RBA EDD report for account`}
          />
        )}
        <FlexRow>
          {!isSaved && <Title type="h4" text="You have unsaved changes" />}
          <Flexbox marginBottom={18} justify="space-end" marginLeft={13}>
            {!viewHistory && (
              <div style={{ marginRight: '12px' }}>
                <MuiButton
                  onClick={viewEddDocumentHistory}
                  disabled={
                    (isCorporateEddReport && !corporateEddReport.id) ||
                    (!isCorporateEddReport && !eddReport.id)
                  }
                >
                  {'View Edd Status History'}
                </MuiButton>
              </div>
            )}
            {!viewHistory && (
              <>
                {isCorporateEddReport ? (
                  <MuiButton onClick={toggleRiskProfileEddReportForm}>
                    {corporateEddReport.id ? 'update' : 'create'} edd report
                  </MuiButton>
                ) : (
                  <MuiButton onClick={toggleRiskProfileEddReportForm}>
                    {eddReport.id ? 'update' : 'create'} edd report
                  </MuiButton>
                )}
              </>
            )}
            {!viewHistory && (
              <IconButton
                onClick={() => {
                  if (!isSaved) {
                    setBackArrowClicked(true)
                    setShowExitModal(true)
                  } else {
                    props.history.goBack()
                  }
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
          </Flexbox>
        </FlexRow>
      </Flexbox>
      {showRiskProfileEddReport ? (
        <>
          {isCorporateEddReport ? (
            <CorporateRiskProfileEddReportForm
              isCorporateEddReport={isCorporateEddReport}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              showExitModal={showExitModal}
              setShowExitModal={setShowExitModal}
              exists={!!corporateEddReport.id}
              onSubmit={updateCorporateRiskProfileEddReport}
              onCancel={
                backArrowClicked
                  ? props.history.goBack
                  : toggleRiskProfileEddReportForm
              }
              initialValues={corporateEddReportInitialValues}
              ipLogsOptions={riskScoringFormOptions.ipLocation}
              pendingDualAuth={corporateEddReport.status === 'PENDING'}
              hasDiff={hasAccountInfoDiff || hasRiskProfileInfoDiff}
            />
          ) : (
            <RiskProfileEddReportForm
              isCorporateEddReport={isCorporateEddReport}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              status={status}
              showExitModal={showExitModal}
              setShowExitModal={setShowExitModal}
              exists={!!eddReport.id}
              onSubmit={updateRiskProfileEddReport}
              onCancel={
                backArrowClicked
                  ? props.history.goBack
                  : toggleRiskProfileEddReportForm
              }
              initialValues={individualEddReportInitialValues}
              hasDiff={hasAccountInfoDiff || hasRiskProfileInfoDiff}
            />
          )}
        </>
      ) : (
        <RiskProfilePdfContainer>
          {isCorporateEddReport && !viewHistory ? (
            <>
              <CorporateRiskProfileEddReportPdfView
                eddReportApprovals={corporateEddReportApprovals}
                eddReportData={corporateEddReport}
              />
            </>
          ) : (
            <>
              {!viewHistory && (
                <RiskProfileEddReportPdfView
                  eddReportApprovals={individualEddReportApprovals}
                  eddReportData={individualEddReportPdfData}
                />
              )}
            </>
          )}
        </RiskProfilePdfContainer>
      )}
      {viewHistory && !isCorporateEddReport && (
        <>
          <HistorySection dualAuthHistory={dualAuthHistory} />
        </>
      )}
      {viewHistory && isCorporateEddReport && (
        <>
          <HistorySection dualAuthHistory={corporateDualAuthHistory} />
        </>
      )}
      {viewHistory && (
        <Flexbox justify={'flex-end'} direction={'row'}>
          <Spacer margin="8px 12px">
            <MuiButton
              onClick={viewEddDocumentHistory}
              disabled={
                (isCorporateEddReport && !corporateEddReport.id) ||
                (!isCorporateEddReport && !eddReport.id)
              }
            >
              {'Go Back'}
            </MuiButton>
          </Spacer>
        </Flexbox>
      )}
    </div>
  )
}

const actions = {
  fetchRiskProfileEddReport,
  fetchCorporateRiskProfileEddReport,
  fetchRiskProfileInfo,
  fetchAccountCorporateInformation,
}

export default connectStateAndDispatch(
  (state) => ({
    riskProfile: state.accounts.byId.riskProfile,
    accountInfo: state.accounts.byId.info,
    keycloakInfo: getKeycloakInfo(state),
    address: getAccountAddress(state),
    customerTypeInfo: getCustomerType(state),
  }),
  actions,
  RiskProfileEddReport,
)
