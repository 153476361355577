import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import auth from 'services/Auth'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import FormComments from 'forms/UserComments/index'
import IconLoading from 'components/atoms/IconLoading'
import {
  fetchUserComments,
  flagComment,
  flagInternalTransferComment,
} from 'redux/userComments/actions'
import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from 'helpers/ErrorBoundary'
import AccountComments from './AccountComments'
import InternalTransferComments from './InternalTransferComments'
import CustomAccordion from 'components/organisms/Accordion'
import NoData from 'components/molecules/NoData'
import { StyledNoCommentsMessage } from './Styles'

function createData(
  date,
  createdBy,
  comments,
  actionType,
  dualAuthStatus,
  flag,
  commentId,
  rejectionReason,
  oldName,
  oldSurname,
) {
  const extraInfo = {
    oldName,
    oldSurname,
  }
  if (!extraInfo.oldName) delete extraInfo.oldName
  if (!extraInfo.oldSurname) delete extraInfo.oldSurname
  return {
    date,
    createdBy,
    comments,
    actionType,
    dualAuthStatus,
    flag,
    commentId,
    rejectionReason,
    ...extraInfo,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function Comments(props) {
  const { accountId } = props.match.params
  const classes = useStyles()

  React.useEffect(() => {
    props.fetchUserComments(accountId)
  }, [])

  const {
    userComments: { data, internalTransferComments, status },
    resetForm,
  } = props
  const commentsRows =
    data &&
    data.length > 0 &&
    data.map((results, i) => {
      const {
        date,
        createdBy,
        comment,
        flag,
        commentId,
        actionType,
        dualAuthStatus,
        rejectionReason,
        oldName,
        oldSurname,
      } = results
      return createData(
        date,
        createdBy,
        comment,
        actionType,
        dualAuthStatus,
        flag,
        commentId,
        rejectionReason,
        oldName,
        oldSurname,
      )
    })
  const hasAccountComments = commentsRows.length > 0
  const hasInternalTransferComments = internalTransferComments.length > 0
  return (
    <>
      <ErrorBoundary message="Form Comments">
        <FormComments
          initialValues={{
            accountId,
            data: { date: new Date(), createdBy: auth.decodeNameToken() },
            flag: false,
            actionType: 'NORMAL_COMMENT',
            dualAuthStatus: 'n/a',
          }}
          onCancel={resetForm}
          onSubmitSuccess={(value, dispatch) => {
            dispatch(fetchUserComments(accountId))
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary message="Comments">
        {status === 'loading' && (
          <TablePlaceholder>
            <IconLoading />
            <span>Loading, please wait</span>
          </TablePlaceholder>
        )}
        {status === 'done' && (
          <CustomAccordion title="Account comments">
            {hasAccountComments && (
              <div className={classes.root}>
                <AccountComments
                  commentsRows={commentsRows}
                  flagComment={props.flagComment}
                  accountId={accountId}
                  paperStyle={classes.paper}
                />
              </div>
            )}
            {!hasAccountComments && (
              <StyledNoCommentsMessage>
                No account comments
              </StyledNoCommentsMessage>
            )}
          </CustomAccordion>
        )}
        {status === 'done' && (
          <CustomAccordion title="Internal transfer comments">
            {hasInternalTransferComments && (
              <div className={classes.root}>
                <InternalTransferComments
                  internalTransferComments={internalTransferComments}
                  accountId={accountId}
                  flagComment={props.flagInternalTransferComment}
                  paperStyle={classes.paper}
                />
              </div>
            )}
            {!hasInternalTransferComments && (
              <StyledNoCommentsMessage>
                No internal comments
              </StyledNoCommentsMessage>
            )}
          </CustomAccordion>
        )}
      </ErrorBoundary>
    </>
  )
}

const mapStateToProps = (state) => ({
  userComments: state.userComments,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserComments,
      flagComment,
      flagInternalTransferComment,
      resetForm: () => reset('CommentsForm'),
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(Comments)
