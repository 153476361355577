import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchAddressBook,
  updateAddressBookDualAuthRowUserSocketEvent,
} from 'redux/address-book/actions'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { Button } from '@material-ui/core'
import {
  fetchDeleteWithdrawalRestrictions,
  fetchUpdateWithdrawalRestrictions,
  fetchAddWithdrawalRestrictions,
} from 'redux/withdrawalRestrictionsDualAuth/actions'
import {
  fetchPendingDualAuth,
  updateDualAuthRowUserSocketEvent,
} from 'redux/dual-auth/actions'
import fetchDualAuthByType from 'redux/dualAuthByType/index'
import {
  fetchNameAndSurname,
  updateNameAndSurnameDualAuthRowSocketEvent,
} from 'redux/nameAndSurnameChange/actions'
import {
  fetchCustomerTypeChange,
  updateCustomerTypeChangeDualAuthSocketEvent,
} from 'redux/customerType/actions'
import {
  updateIdNumberDualAuthRowUserSocketEvent,
  removeSubmittedItem,
} from 'redux/identityNumberChange/actions'
import { updateFundsDualAuthRowUserSocketEvent } from 'redux/funds/actions'
import {
  fetchWithdrawalLimit,
  fetchWithdrawalLimitById,
  updateWithdrawalLimitDualAuthUserRowSocketEvent,
} from 'redux/withdrawal-limit/actions'
import {
  fetchIgnoredDeposits,
  updateIgnoredDepositDualAuthRowUserSocketEvent,
} from 'redux/ignoredDeposits/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  TitleContainer,
  ActionButton,
  Table,
} from 'components/atoms/Table/styles'
import { RenderCheckbox } from 'forms/fields'
import socket from 'redux/socket/index'
import { disconnectSocket } from 'redux/fiat/actions'
import authService from 'services/Auth'
import ErrorToast from 'components/molecules/ErrorToast'

import { FlexboxColumn } from './styles'
import DualAuth from './dual-auth'
import { DualAuthSocketEventTypes } from './helpers'

class PendingDualAuth extends Component<any> {
  interval: any

  state: any

  constructor(props) {
    super(props)
    this.state = {
      showUsersDualAuths: false,
      expanded: '',
      showEmpty: false,
      renderData: [],
      reject: false,
      selectedId: -1,
      interval: null,
    }
    this.componentGracefulUnmount = this.componentGracefulUnmount.bind(this)
  }

  componentDidMount() {
    socket.connect()

    socket.on('ADD_DUAL_AUTH_USER_SUCCESS', (socketData) => {
      this.handleDualAuthSocketEvents(socketData)
    })

    socket.on('REMOVE_DUAL_AUTH_USER_SUCCESS', (socketData) => {
      this.handleDualAuthSocketEvents(socketData)
    })

    this.props.fetchPendingDualAuth()
    this.props.fetchDualAuthByType('FUND_ACCOUNT')
    this.props.fetchNameAndSurname('NAME_SURNAME_CHANGE')
    this.props.fetchDualAuthByType('IDENTITY_NUMBER_CHANGE')
    this.props.fetchWithdrawalLimit('CUSTOM_WITHDRAWAL_LIMIT')
    this.props.fetchCustomerTypeChange('CUSTOMER_TYPE_CHANGE')
    this.props.fetchIgnoredDeposits('REMOVE_FIAT_DEPOSIT_IGNORED_STATUS')
    this.props.fetchAddressBook('DISABLE_ADDRESS_BOOK_ONLY_WITHDRAWALS')
    this.props.fetchAddWithdrawalRestrictions('ADD_WITHDRAWAL_RESTRICTION')
    this.props.fetchDeleteWithdrawalRestrictions(
      'DELETE_WITHDRAWAL_RESTRICTION',
    )
    this.props.fetchUpdateWithdrawalRestrictions(
      'UPDATE_WITHDRAWAL_RESTRICTION',
    )
    this.interval = setInterval(() => {
      this.refreshState()
    }, 300000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  componentGracefulUnmount() {
    window.removeEventListener('beforeunload', this.componentGracefulUnmount)
    disconnectSocket()
    clearInterval(this.interval)
  }

  refreshState = () => {
    this.props.fetchPendingDualAuth()
    this.props.fetchDualAuthByType('FUND_ACCOUNT')
    this.props.fetchNameAndSurname('NAME_SURNAME_CHANGE')
    this.props.fetchDualAuthByType('IDENTITY_NUMBER_CHANGE')
    this.props.fetchWithdrawalLimit('CUSTOM_WITHDRAWAL_LIMIT')
    this.props.fetchCustomerTypeChange('CUSTOMER_TYPE_CHANGE')
    this.props.fetchIgnoredDeposits('REMOVE_FIAT_DEPOSIT_IGNORED_STATUS')
    this.props.fetchAddressBook('DISABLE_ADDRESS_BOOK_ONLY_WITHDRAWALS')
    this.props.fetchAddWithdrawalRestrictions('ADD_WITHDRAWAL_RESTRICTION')
    this.props.fetchDeleteWithdrawalRestrictions(
      'DELETE_WITHDRAWAL_RESTRICTION',
    )
    this.props.fetchUpdateWithdrawalRestrictions(
      'UPDATE_WITHDRAWAL_RESTRICTION',
    )
  }

  toggleSelected = (id) => {
    if (this.state.selectedId === id) {
      this.setState({ selectedId: -1 })
    } else {
      this.setState({ selectedId: id })
    }
  }

  filterDualAuthByStatus(data) {
    const potentialDuplicates: any[] = []
    const unknownReferences: any[] = []
    const depositsForReview: any[] = []
    const kycOverride: any[] = []
    const blacklisted: any[] = []
    const cellphoneChange: any[] = []
    const emailChange: any[] = []
    const residentialCountryChange: any[] = []
    const removeDepositIgnoredStatus: any[] = []
    const totpRemoval: any[] = []
    const customerTypeChange: any[] = []
    const unblockAcc: any[] = []
    const sendsReversal: any[] = []
    const receivesAndConfirmation: any[] = []
    const securityChange: any[] = []
    const potentialDuplicateRtc: any[] = []
    const other: any[] = []
    const funds: any[] = []
    const removeWithdrawalAddressBook: any[] = []
    const allowAddToAddressBook: any[] = []
    const allowGenerateReceiveAddress: any[] = []
    const fundingManualCredit: any[] = []
    const kycAddressOverride: any[] = []
    const withdrawalRestrictions: any[] = []

    data.forEach((value: any) => {
      if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) => values.status === 'UNKNOWN_REFERENCE',
        ) !== -1
      ) {
        unknownReferences.push(value)
      } else if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) => values.status === 'POTENTIAL_DUPLICATE',
        ) !== -1
      ) {
        potentialDuplicates.push(value)
      } else if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) => values.status === 'POTENTIAL_DUPLICATE_RTC_DEPOSIT',
        ) !== -1
      ) {
        potentialDuplicateRtc.push(value)
      } else if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) => values.status === 'INVALID_DESCRIPTION',
        ) !== -1
      ) {
        unknownReferences.push(value)
      } else if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) => values.status === 'BLACKLISTED',
        ) !== -1
      ) {
        blacklisted.push(value)
      } else if (
        value.actionType === 'FIAT_DEPOSIT_CREDIT' &&
        value.deposit.statuses.findIndex(
          (values) =>
            values.status !== 'BLACKLISTED' &&
            values.status !== 'POTENTIAL_DUPLICATE' &&
            values.status !== 'UNKNOWN_REFERENCE' &&
            values.status !== 'POTENTIAL_DUPLICATE_RTC_DEPOSIT',
        ) !== -1
      ) {
        depositsForReview.push(value)
      } else if (value.actionType === 'KYC_OVERRIDE') {
        kycOverride.push(value)
      } else if (value.actionType === 'CELL_NUMBER_CHANGE') {
        cellphoneChange.push(value)
      } else if (value.actionType === 'EMAIL_CHANGE') {
        emailChange.push(value)
      } else if (value.actionType === 'RESIDENTIAL_COUNTRY_CHANGE') {
        residentialCountryChange.push(value)
      } else if (value.actionType === 'CUSTOMER_TYPE_CHANGE') {
        customerTypeChange.push(value)
      } else if (value.actionType === 'REMOVE DEPOSIT IGNORED STATUS') {
        removeDepositIgnoredStatus.push(value)
      } else if (value.actionType === 'TOTP_REMOVAL') {
        totpRemoval.push(value)
      } else if (value.actionType === 'ACCOUNT_UNBLOCK') {
        unblockAcc.push(value)
      } else if (value.actionType === 'BLOCKCHAIN_SEND_REVERSAL') {
        sendsReversal.push(value)
      } else if (value.actionType === 'FIAT_WITHDRAWAL_REVERSAL') {
        sendsReversal.push(value)
      } else if (value.actionType === 'ACCOUNT_CONFIRMATIONS_CHANGE') {
        receivesAndConfirmation.push(value)
      } else if (value.actionType === 'BLOCKCHAIN_RECEIVE_CREDIT') {
        receivesAndConfirmation.push(value)
      } else if (value.actionType === 'FUNDS') {
        funds.push(value)
      } else if (
        value.actionType === 'REMOVE WITHDRAWAL TO ADDRESS BOOK ONLY'
      ) {
        removeWithdrawalAddressBook.push(value)
      } else if (value.actionType === 'ALLOW_ADD_TO_ADDRESS_BOOK') {
        allowAddToAddressBook.push(value)
      } else if (value.actionType === 'ALLOW_GENERATE_RECEIVE_ADDRESS') {
        allowGenerateReceiveAddress.push(value)
      } else if (value.actionType === 'FIAT_FUNDING_CREDIT') {
        fundingManualCredit.push(value)
      } else if (
        value.actionType === 'ACCOUNT_SECURITY_CHANGE_BLOCK_OVERRIDE'
      ) {
        securityChange.push(value)
      } else if (value.actionType === 'KYC_ADDRESS_OVERRIDE') {
        kycAddressOverride.push(value)
      } else if (value.actionType === 'ADD_WITHDRAWAL_RESTRICTION') {
        withdrawalRestrictions.push(value)
      } else if (value.actionType === 'DELETE_WITHDRAWAL_RESTRICTION') {
        withdrawalRestrictions.push(value)
      } else if (value.actionType === 'UPDATE_WITHDRAWAL_RESTRICTION') {
        withdrawalRestrictions.push(value)
      } else {
        other.push(value)
      }
    })
    const {
      funds: { fetchFundsStatus, fetchFundsData },
      nameAndSurname: { nameAndSurnameStatus, nameAndSurnameData },
      identityNumber: { identityNumberStatus, identityNumberData },
      withdrawalLimit: { withdrawalLimitStatus, withdrawalLimitData },
      customerTypeChange: { customerTypeStatus, customerTypeData },
      ignoredStatusChange: { ignoredDepositsStatus, ignoredDeposits },
      addressBook: { addressBookStatus, addressBookData },
    } = this.props
    return [
      { title: 'FUNDS', data: fetchFundsData, status: fetchFundsStatus },
      {
        title: 'NAME AND SURNAME CHANGE',
        data: nameAndSurnameData,
        status: nameAndSurnameStatus,
      },
      {
        title: 'IDENTITY NUMBER CHANGE',
        data: identityNumberData,
        status: identityNumberStatus,
      },
      {
        title: 'WITHDRAWAL LIMIT',
        data: withdrawalLimitData,
        status: withdrawalLimitStatus,
      },
      {
        title: 'CUSTOMER TYPE CHANGE',
        data: customerTypeData,
        status: customerTypeStatus,
      },
      {
        title: 'REMOVE DEPOSIT IGNORED STATUS',
        data: ignoredDeposits,
        status: ignoredDepositsStatus,
      },
      {
        title: 'REMOVE WITHDRAWAL TO ADDRESS BOOK ONLY',
        data: addressBookData,
        status: addressBookStatus,
      },
      {
        title: 'WITHDRAWAL RESTRICTIONS',
        data: withdrawalRestrictions,
      },
      { title: 'DEPOSITS FOR REVIEW', data: depositsForReview },
      { title: 'FUNDING MANUAL CREDIT', data: fundingManualCredit },
      { title: 'POTENTIAL_DUPLICATE_RTC_DEPOSIT', data: potentialDuplicateRtc },
      { title: 'POTENTIAL DUPLICATES', data: potentialDuplicates },
      { title: 'UNKNOWN REFERENCES', data: unknownReferences },
      { title: 'BLACKLISTED', data: blacklisted },
      { title: 'KYC OVERRIDE', data: kycOverride },
      { title: 'CELLPHONE CHANGE', data: cellphoneChange },
      { title: 'EMAIL CHANGE', data: emailChange },
      { title: 'RESIDENTIAL COUNTRY CHANGE', data: residentialCountryChange },
      { title: 'TOTP REMOVAL', data: totpRemoval },
      { title: 'UNBLOCK ACCOUNT', data: unblockAcc },
      {
        title: 'BLOCKCHAIN RECEIVES AND CONFIRMATION SETTINGS',
        data: receivesAndConfirmation,
      },
      {
        title: 'BLOCKCHAIN SEND REVERSAL AND WITHDRAWAL REVERSAL',
        data: sendsReversal,
      },
      { title: 'ACCOUNT SECURITY CHANGE BLOCK OVERRIDE', data: securityChange },
      { title: 'ALLOW_ADD_TO_ADDRESS_BOOK', data: allowAddToAddressBook },
      {
        title: 'ALLOW_GENERATE_RECEIVE_ADDRESS',
        data: allowGenerateReceiveAddress,
      },
      { title: 'KYC ADDRESS OVERRIDE', data: kycAddressOverride },
      { title: 'OTHER', data: other },
    ]
  }

  countDualAuths = () => {
    const {
      pendingDualAuth: { status, data },
      funds: { fetchFundsStatus, fetchFundsData },
      nameAndSurname: { nameAndSurnameStatus, nameAndSurnameData },
      identityNumber: { identityNumberStatus, identityNumberData },
      withdrawalLimit: { withdrawalLimitStatus, withdrawalLimitData },
      customerTypeChange: { customerTypeStatus, customerTypeData },
      ignoredStatusChange: { ignoredDepositsStatus, ignoredDeposits },
      withdrawalRestrictions: {
        withdrawalRestrictionsStatus,
        withdrawalRestrictionsData,
      },
    } = this.props
    let summ = 0

    if (status === 'done') summ += data.length
    if (fetchFundsStatus === 'done') summ += fetchFundsData.length
    if (nameAndSurnameStatus === 'done') summ += nameAndSurnameData.length
    if (identityNumberStatus === 'done') summ += identityNumberData.length
    if (withdrawalLimitStatus === 'done') summ += withdrawalLimitData.length
    if (customerTypeStatus === 'done') summ += customerTypeData.length
    if (ignoredDepositsStatus === 'done') summ += ignoredDeposits.length
    if (withdrawalRestrictionsStatus === 'done')
      summ += withdrawalRestrictionsData.length
    return summ
  }

  setExpand = (panel) => {
    this.setState({ expanded: panel })
  }

  handleDualAuthSocketEvents = (socketData) => {
    try {
      const { dualAuthType }: { dualAuthType: DualAuthSocketEventTypes } =
        socketData[0]
      switch (dualAuthType) {
        case 'FUNDS_EVENT':
          this.props.updateFundsDualAuthRowUserSocketEvent(socketData)
          break
        case 'NAME_SURNAME_EVENT':
          this.props.updateNameAndSurnameDualAuthRowSocketEvent(socketData)
          break
        case 'ID_NUMBER_EVENT':
          this.props.updateIdNumberDualAuthRowUserSocketEvent(socketData)
          break
        case 'WITHDRAWAL_LIMIT':
          this.props.updateWithdrawalLimitDualAuthUserRowSocketEvent(socketData)
          break
        case 'CUSTOMER_TYPE_EVENT':
          this.props.updateCustomerTypeChangeDualAuthSocketEvent(socketData)
          break
        case 'IGNORED_DEPOSIT_EVENT':
          this.props.updateIgnoredDepositDualAuthRowUserSocketEvent(socketData)
          break
        case 'ADDRESS_BOOK_EVENT':
          this.props.updateAddressBookDualAuthRowUserSocketEvent(socketData)
          break
        case 'ACCOUNT_SECURITY_CHANGE_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'ALLOW_EXCHANGE_CONTROL_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'BLOCKCHAIN_RECEIVES_AND_CONFIRMATION_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'BLOCKCHAIN_SENDS_REVERSAL_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'CELL_PHONE_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'KYC_OVERRIDE_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'EMAIL_CHANGE_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'RESIDENTIAL_ADDRESS_CHANGE_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'TOTP_REMOVAL_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'UNBLOCK_ACCOUNT_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'OTHER_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        case 'DEFAULT_EVENT':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
        case 'WITHDRAWAL_RESTRICTIONS':
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
        default:
          this.props.updateDualAuthRowUserSocketEvent(socketData)
          break
      }
    } catch (error) {
      ErrorToast('Socket update error')
    }
  }

  handleShowEmpty = (event) => {
    this.setState({ showEmpty: event.target.checked })
  }

  handleChange = (event) => {
    this.setState({ showUsersDualAuths: event.target.checked })
  }

  filterDualAuthsByInitiator = (daulAuths) => {
    const currentUser = authService.decodeToken()
    const { showUsersDualAuths } = this.state
    if (!showUsersDualAuths) return daulAuths
    return daulAuths.filter((da) => {
      if (da.initiatorId) {
        if (!showUsersDualAuths) {
          return da.initiatorId === currentUser
        }
        return da.initiatorId && da.initiatorId !== currentUser
      }
      if (da.initiatedBy) {
        if (!showUsersDualAuths) {
          return da.initiatedBy === currentUser
        }
        return da.initiatedBy !== currentUser
      }
    })
  }

  handleShowEmptyDualAuthCategories = (data) => {
    if (this.state.showEmpty) {
      return true
    }
    return this.filterDualAuthsByInitiator(data).length > 0
  }

  render() {
    const {
      pendingDualAuth: { status, data, lastUpdatedTime },
    } = this.props
    const { showEmpty, expanded } = this.state

    return (
      <Fragment>
        <div>
          <TitleContainer>
            {/* @ts-ignore */}
            <Table width="auto">
              <ErrorBoundary message="Dual Auth Header">
                <h1>Pending Dual Auth ({this.countDualAuths()})</h1>
                {status !== 'done' ? (
                  <LoadingBlock message="Updating time..." />
                ) : (
                  <div>
                    <span style={{ marginRight: '10px' }}>
                      Last updated at: {lastUpdatedTime}
                    </span>
                    {/* @ts-ignore */}
                    <ActionButton
                      onClick={this.refreshState}
                      disabled={status === 'loading'}
                    >
                      Refresh Data
                    </ActionButton>
                  </div>
                )}
              </ErrorBoundary>
            </Table>
            <FlexboxColumn>
              <RenderCheckbox
                onCheckFunction={this.handleChange}
                checked={this.state.showUsersDualAuths}
                label="My Dual Auths To Action"
              />
              <RenderCheckbox
                onCheckFunction={this.handleShowEmpty}
                checked={showEmpty}
                label="Show All Dual Auth Categories"
              />
            </FlexboxColumn>
          </TitleContainer>
          <ErrorBoundary message="Other Dropdown">
            {status === 'done' &&
              this.filterDualAuthByStatus(data)
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map((obj) =>
                  this.handleShowEmptyDualAuthCategories(obj.data) ? (
                    <DualAuth
                      dualAuthData={this.filterDualAuthsByInitiator(obj.data)}
                      itemsAmount={
                        this.filterDualAuthsByInitiator(obj.data).length
                      }
                      title={obj.title.replaceAll('_', ' ')}
                      key={obj.title}
                      expanded={expanded}
                      setExpand={this.setExpand}
                    />
                  ) : null,
                )}
          </ErrorBoundary>
          {status === 'loading' && <LoadingBlock />}
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  pendingDualAuth: state.pendingDualAuth,
  funds: state.funds,
  nameAndSurname: state.dualAuthByType,
  identityNumber: state.identityChange,
  withdrawalLimit: state.withdrawalLimit,
  customerTypeChange: state.customerType,
  ignoredStatusChange: state.ignoredStatusDeposits,
  addressBook: state.addressBook,
  withdrawalRestrictions: state.dualAuthRestrictions,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPendingDualAuth,
      fetchDualAuthByType,
      fetchNameAndSurname,
      removeSubmittedItem,
      fetchWithdrawalLimit,
      fetchWithdrawalLimitById,
      fetchCustomerTypeChange,
      fetchIgnoredDeposits,
      fetchAddressBook,
      fetchDeleteWithdrawalRestrictions,
      fetchUpdateWithdrawalRestrictions,
      fetchAddWithdrawalRestrictions,
      // SOCKET EVENTS
      updateFundsDualAuthRowUserSocketEvent,
      updateNameAndSurnameDualAuthRowSocketEvent,
      updateIdNumberDualAuthRowUserSocketEvent,
      updateWithdrawalLimitDualAuthUserRowSocketEvent,
      updateCustomerTypeChangeDualAuthSocketEvent,
      updateIgnoredDepositDualAuthRowUserSocketEvent,
      updateAddressBookDualAuthRowUserSocketEvent,
      updateDualAuthRowUserSocketEvent,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(PendingDualAuth)
