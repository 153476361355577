import { EXCHANGE_DEPOSIT_TYPES } from 'helpers/const'
import { hasExchangeFiatBulkEditorRole } from 'helpers/roleBasedAccess'
import React from 'react'

import {
  showApproveButton,
  showBulkActionButton,
  showRejectButton,
  showReleaseButton,
  showSendEmailActionButton,
  showSwitchButton,
} from './helpers'
import { AccordionActionsWrapper, SuspendButton } from './styles'

import Switcher from '../Switcher/index'

const ExpandItemActions = ({
  titleType,
  bulkActionsActive,
  selectedBulkDeposits,
  isButtonHidden,
  initiateBulkActionUpdate,
  opensMultiple,
  expanded,
  handleBulkSuspendClick,
  actionType,
}) => {
  const fallBackImportAccordion =
    titleType === EXCHANGE_DEPOSIT_TYPES.fallBackImport
  return (
    <div>
      {showSwitchButton(titleType) && (
        <AccordionActionsWrapper onClick={(e) => e.stopPropagation()}>
          {showApproveButton(titleType) && hasExchangeFiatBulkEditorRole() && (
            <SuspendButton
              active={bulkActionsActive ? 'true' : undefined}
              disabled={
                (selectedBulkDeposits && selectedBulkDeposits.length < 1) ||
                !hasExchangeFiatBulkEditorRole() ||
                (fallBackImportAccordion && actionType !== 'APPROVE')
              }
              hidden={isButtonHidden}
              size="small"
              onClick={() => {
                initiateBulkActionUpdate(selectedBulkDeposits, 'APPROVE')
              }}
              main="true"
            >
              Approve
            </SuspendButton>
          )}
          {showBulkActionButton(titleType) && (
            <SuspendButton
              active={bulkActionsActive ? 'true' : undefined}
              disabled={
                (selectedBulkDeposits && selectedBulkDeposits.length < 1) ||
                (fallBackImportAccordion && actionType !== 'SUSPEND')
              }
              hidden={isButtonHidden}
              size="small"
              onClick={() => {
                initiateBulkActionUpdate(selectedBulkDeposits, 'SUSPEND')
              }}
              main="true"
            >
              Suspend
            </SuspendButton>
          )}

          {showReleaseButton(titleType) && (
            <SuspendButton
              active={bulkActionsActive ? 'true' : undefined}
              disabled={selectedBulkDeposits && selectedBulkDeposits.length < 1}
              hidden={isButtonHidden}
              size="small"
              onClick={() => {
                initiateBulkActionUpdate(selectedBulkDeposits, 'RELEASE')
              }}
              main="true"
            >
              Release
            </SuspendButton>
          )}

          {showRejectButton(titleType) && (
            <SuspendButton
              active={bulkActionsActive ? 'true' : undefined}
              disabled={
                (selectedBulkDeposits && selectedBulkDeposits.length < 1) ||
                (fallBackImportAccordion && actionType !== 'REJECT')
              }
              hidden={isButtonHidden}
              size="small"
              onClick={() => {
                initiateBulkActionUpdate(selectedBulkDeposits, 'REJECT')
              }}
              main="true"
            >
              Reject
            </SuspendButton>
          )}
          {showSendEmailActionButton(titleType) && (
            <SuspendButton
              active={bulkActionsActive ? 'true' : undefined}
              disabled={
                (selectedBulkDeposits && selectedBulkDeposits.length < 1) ||
                selectedBulkDeposits.findIndex(
                  (deposit) => deposit.zendeskEmailToSend,
                ) > -1 ||
                (fallBackImportAccordion && actionType !== 'SEND_EMAIL') ||
                selectedBulkDeposits.findIndex(
                  (deposit) => deposit.depositZendeskEmailSent,
                ) > -1
              }
              hidden={isButtonHidden}
              size="small"
              onClick={() => {
                initiateBulkActionUpdate(selectedBulkDeposits, 'SEND_EMAIL')
              }}
              main="true"
            >
              Send Email
            </SuspendButton>
          )}
          <Switcher
            width="80px"
            show={opensMultiple && expanded}
            title={'Enable bulk actions'}
            active={bulkActionsActive ? 'true' : undefined}
            onClick={handleBulkSuspendClick}
          />
        </AccordionActionsWrapper>
      )}
    </div>
  )
}

export const MemoizedExpandItemActions = React.memo(ExpandItemActions)
