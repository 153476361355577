import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { addUserActionComment } from 'redux/userComments/actions'

export const submitWithdrawalDualOverride = async (values) => {
  const { accountId } = values
  try {
    const type = 'ACCOUNT_SECURITY_CHANGE_BLOCK_OVERRIDE'
    const request = {
      path: `/admin/dualauthorisation/initiate/${type}`,
      body: { accountId },
    }
    const res = await getAxios().post('/generic', request)
    if (res.data.success) {
      const { dualAuthId } = res.data.body
      await addUserActionComment({
        ...values,
        dualAuthId,
      })
      toast('Sensitive Withdrawal Dual Auth Override Initiated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } else {
      toast(res.data.error.message || res.data.message, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      })
    }
  } catch (e) {
    toast(`Server error${e}`, { type: toast.TYPE.ERROR, autoClose: 1500 })
  }
}
