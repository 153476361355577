import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getKeycloakUsers = state => state.keycloak.byId

export const getKeycloakInfo = createSelector(
  [getKeycloakUsers, getActiveAccount],
  (users, id) => {
    if (!id || !users[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }
    return users[id]
  },
)

export const otherKeycloakAccounts = state => state.auth0.otherAccountsById

export const getOtherKeycloakAccounts = createSelector(
  [otherKeycloakAccounts, getActiveAccount],
  (accounts, id) => {
    if (!id || !accounts[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }
    return accounts[id]
  },
)
