import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'
export const Headers = props => {
  const { expandItem } = props
  return (
    <TableHeader onClick={expandItem}>
      <TableCell flex={0.6}>Account Id</TableCell>
      <TableCell flex={0.6}>Amount</TableCell>
      <TableCell flex={0.6}>Created At</TableCell>
    </TableHeader>
  )
}
