import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import * as moment from 'moment'
import { VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FiatWithdrawalReview from 'forms/FiatWithdrawalReview/index'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import EyeIcon from 'components/atoms/EyeIcon/index'
import FormWithdrawalNotes from 'forms/WithdrawalNotes/index'
import IconLoading from 'components/atoms/IconLoading'

import { Link } from '../AccountIdLink/clickableId'

class ReviewFiatWithdrawalsRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingSensitiveInfo: false,
      sensitiveInfo: {
        name: '',
        surname: '',
      },
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
      test: '',
      rowColor: '',
    }
  }

  removeActiveUser = async () => {
    const { withdrawalId } = this.props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async () => {
    const { withdrawalId } = this.props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  updateComments = async (withdrawalId) => {
    this.setState({ commentsStatus: 'loading' })
    const { data } = await getAxios().get(
      `/withdrawal/${withdrawalId}/fiat/notes`,
    )
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  getProfileInfo = async (id) => {
    this.setState({ isFetchingSensitiveInfo: true })
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const { sensitiveInfo } = res.data
    this.setState((prev) => ({
      ...prev,
      sensitiveInfo,
    }))
    this.setState({ isFetchingSensitiveInfo: false })
  }

  render() {
    const { withdraw, review, showForm, showRawData, resetForm } = this.props
    const {
      withdrawalId,
      accountId,
      amount,
      bank,
      accountHolder,
      updatedAt,
      isFast,
      active,
      currency,
      tag,
    } = withdraw
    const {
      toggleSelected,
      toggleRawData,
      setHighlightedId,
      isHighlighted,
      title,
      isFetchingAccountDetails,
    } = this.props
    const { sensitiveInfo, isFetchingSensitiveInfo } = this.state
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const padding = '16px 16px 16px 0px'
    const shading = isHighlighted ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && VALR_BLUE_GRAY,
          }}
          onClick={() => {
            if (!isFetchingAccountDetails && !sensitiveInfo.name) {
              this.getProfileInfo(accountId)
            }
            setHighlightedId(withdrawalId)
            this.updateComments(withdrawalId)
          }}
        >
          <TableCell style={{ padding: '16px 16px' }} flex={0.5}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1.2}
          >
            {accountHolder}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1.2}
          >
            {bank.displayName}
          </TableCell>
          {isFetchingSensitiveInfo && (
            <TableCell style={{ padding }} flex={1}>
              <div>
                <IconLoading />
              </div>
            </TableCell>
          )}
          {!isFetchingSensitiveInfo && (
            <>
              {sensitiveInfo.name ? (
                <TableCell style={{ padding }} flex={1}>
                  {sensitiveInfo.name}
                </TableCell>
              ) : (
                <TableCell style={{ padding }} flex={1}>
                  <EyeIcon
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </TableCell>
              )}
            </>
          )}
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
          >
            {sensitiveInfo.surname || '--'}
          </TableCell>
          <TableCell
            style={{ padding, marginLeft: '2px' }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
            color={isFast ? 'green' : 'black'}
          >
            {isFast ? 'Yes' : 'No'}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
          >
            {formattedAmount}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={0.8}
          >
            {currency.shortName}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleSelected(withdrawalId)}
            flex={1}
            align={'right'}
          >
            {moment(updatedAt).format('YYYY-MMM-DD HH:mm')}
          </TableCell>
          <TableCell flex={0.5} style={{ padding }}>
            {active && active.userActive && (
              <EyeIcon text={active.activePersonName} />
            )}
          </TableCell>
          <TableCell flex={0.18} style={{ padding }} align={'center'}>
            {tag && (
              <i
                style={{ color: 'black' }}
                className="fa fa-sticky-note"
                aria-hidden="true"
              />
            )}
          </TableCell>
        </TableRow>
        {showForm && (
          <FiatWithdrawalReview
            title={title}
            initialValues={{
              withdrawalId,
              status: '',
              accountId,
              comment: '',
              reverseFee: false,
            }}
            showActiveUser={this.showActiveuser}
            showAddNote={() => this.props.toggleAddNotesForm(withdrawalId)}
            removeActiveUser={this.removeActiveUser}
            form={`withdrawalReviewForm+${withdrawalId}`}
            rawData={{ ...withdraw, review, ...sensitiveInfo }}
            onCancel={() => toggleSelected(withdrawalId)}
            toggleRawData={toggleRawData}
            showRawData={showRawData}
            withdrawalId={withdrawalId}
            comments={{
              withdrawalComments: this.state.comments,
              status: this.state.commentsStatus,
            }}
            toggleForm={() => {
              this.props.toggleAddNotesForm(withdrawalId)
              this.updateComments(withdrawalId)
            }}
            expandRawData={this.props.expandRawData}
            expandAddNote={this.props.addNoteFormShown}
          />
        )}
        {showForm && this.props.addNoteFormShown && (
          <FormWithdrawalNotes
            initialValues={{
              withdrawalId,
              data: {
                date: new Date(),
                createdBy: auth.decodeNameToken(),
                withdrawalId,
              },
            }}
            isfiatWithdrawal
            form={`withdrawalNotesForm+${withdrawalId}`}
            withdrawalData={this.props.withdraw}
            onCancel={resetForm}
            toggleForm={() => {
              this.props.toggleAddNotesForm(withdrawalId)
              this.updateComments(withdrawalId)
            }}
          />
        )}
      </Fragment>
    )
  }
}

export default ReviewFiatWithdrawalsRow
