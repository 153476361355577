import { toast } from 'react-toastify'
import {
  LEGAL_ENTITY_TYPES,
  LEGAL_ENTITY_DOCUMENT_TYPES,
  LEGAL_TRUST_ENTITY_DOCUMENT_TYPES,
  NATURAL_ENTITY_DOCUMENT_TYPES,
  ENTITY_TYPES,
} from 'helpers/const'
import {
  CREATE_LEGAL_PTY_ENTITIES_ERROR,
  CREATE_LEGAL_PTY_ENTITIES_START,
  CREATE_LEGAL_PTY_ENTITIES_SUCCESS,
  CREATE_LEGAL_TRUST_ENTITIES_ERROR,
  CREATE_LEGAL_TRUST_ENTITIES_START,
  CREATE_LEGAL_TRUST_ENTITIES_SUCCESS,
  CREATE_LEGAL_OTHER_ENTITIES_ERROR,
  CREATE_LEGAL_OTHER_ENTITIES_START,
  CREATE_LEGAL_OTHER_ENTITIES_SUCCESS,
  CREATE_NATURAL_ENTITIES_ERROR,
  CREATE_NATURAL_ENTITIES_START,
  CREATE_NATURAL_ENTITIES_SUCCESS,
  FETCHING_CORPORATE_ENTITIES_ERROR,
  FETCHING_CORPORATE_ENTITIES_START,
  FETCHING_CORPORATE_ENTITIES_SUCCESS,
  LINK_ENTITY_SUCCESS,
  DEACTIVATE_NATURAL_ENTITY_SUCCESS,
  UPDATE_LEGAL_ENTITIES_UPLOAD_COMPLETE,
  UPDATE_LEGAL_ENTITIES_UPLOAD_FAILURE,
  UPDATE_LEGAL_PTY_ENTITIES,
  UPDATE_LEGAL_PTY_ENTITY_ERROR,
  UPDATE_LEGAL_PTY_ENTITY_START,
  UPDATE_LEGAL_PTY_ENTITY_SUCCESS,
  UPDATE_LEGAL_TRUST_ENTITIES,
  UPDATE_LEGAL_TRUST_ENTITY_ERROR,
  UPDATE_LEGAL_TRUST_ENTITY_START,
  UPDATE_LEGAL_TRUST_ENTITY_SUCCESS,
  UPDATE_LEGAL_OTHER_ENTITIES,
  UPDATE_LEGAL_OTHER_ENTITY_ERROR,
  UPDATE_LEGAL_OTHER_ENTITY_START,
  UPDATE_LEGAL_OTHER_ENTITY_SUCCESS,
  UPDATE_LINKED_ENTITY_SUCCESS,
  UPDATE_NATURAL_ENTITIES,
  UPDATE_NATURAL_ENTITIES_UPLOAD_COMPLETE,
  UPDATE_NATURAL_ENTITIES_UPLOAD_FAILURE,
  UPDATE_NATURAL_ENTITY_ERROR,
  UPDATE_NATURAL_ENTITY_START,
  UPDATE_NATURAL_ENTITY_SUCCESS,
  DEACTIVATE_LEGAL_ENTITY_SUCCESS,
  CLEAR_CORPORATE_ENTITIES,
  REMOVE_ENTITY_DOCUMENT_START,
  REMOVE_ENTITY_DOCUMENT_SUCCESS,
  REMOVE_ENTITY_DOCUMENT_ERROR,
} from './actions'

const initialState = {
  isCreatingEntity: false,
  naturalEntities: [],
  legalPtyEntities: [],
  legalTrustEntities: [],
  legalOtherEntities: [],
  isFetchingEntitiesForCorporate: false,
  isUpdatingEntity: false,
}

const toastStateUpdateError = () =>
  toast(
    `An error occurred when updating the user interface. Please 
    refresh your screen to see the updates. If this problem persists 
    please contact support.`,
    {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    },
  )

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LEGAL_PTY_ENTITY_START:
    case UPDATE_LEGAL_OTHER_ENTITY_START:
    case UPDATE_NATURAL_ENTITY_START:
    case UPDATE_LEGAL_TRUST_ENTITY_START: {
      return {
        ...state,
        isUpdatingEntity: true,
      }
    }
    case FETCHING_CORPORATE_ENTITIES_START: {
      return {
        ...state,
        isFetchingEntitiesForCorporate: true,
      }
    }

    case CREATE_NATURAL_ENTITIES_START:
    case CREATE_LEGAL_PTY_ENTITIES_START:
    case CREATE_LEGAL_OTHER_ENTITIES_START:
    case CREATE_LEGAL_TRUST_ENTITIES_START: {
      return {
        ...state,
        isCreatingEntity: true,
      }
    }

    case FETCHING_CORPORATE_ENTITIES_SUCCESS: {
      return {
        ...state,
        isFetchingEntitiesForCorporate: false,
      }
    }

    case CREATE_NATURAL_ENTITIES_SUCCESS:
    case LINK_ENTITY_SUCCESS: {
      const entityData = action.payload
      entityData.uploadState = {
        idDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
        proofOfResidenceDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
      }
      const updatedEntities = [...state.naturalEntities]
      updatedEntities.push(entityData)
      return {
        ...state,
        naturalEntities: updatedEntities,
        isCreatingEntity: false,
      }
    }

    case CREATE_LEGAL_PTY_ENTITIES_SUCCESS: {
      const entityData = action.payload
      entityData.uploadState = {
        ptyRegistrationDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
      }
      const updatedEntities = [...state.legalPtyEntities]
      updatedEntities.push(entityData)
      return {
        ...state,
        legalPtyEntities: updatedEntities,
        isCreatingEntity: false,
      }
    }

    case CREATE_LEGAL_TRUST_ENTITIES_SUCCESS: {
      const entityData = action.payload
      entityData.uploadState = {
        trustRegistrationDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
        trustLetterOfAuthorityDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
      }
      const updatedEntities = [...state.legalTrustEntities]
      updatedEntities.push(entityData)
      return {
        ...state,
        legalTrustEntities: updatedEntities,
        isCreatingEntity: false,
      }
    }

    case CREATE_LEGAL_OTHER_ENTITIES_SUCCESS: {
      const entityData = action.payload
      entityData.uploadState = {
        otherRegistrationDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
        otherLetterOfAuthorityDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
        otherDocumentUploadState: {
          progress: 0,
          uploadErrorMessage: '',
        },
      }
      const updatedEntities = [...state.legalOtherEntities]
      updatedEntities.push(entityData)
      return {
        ...state,
        legalOtherEntities: updatedEntities,
        isCreatingEntity: false,
      }
    }

    case UPDATE_NATURAL_ENTITY_SUCCESS:
    case UPDATE_LINKED_ENTITY_SUCCESS: {
      const entityData = action.payload
      const updatedEntities = [...state.naturalEntities]
      const indexOfEntity = updatedEntities.findIndex(
        (entity) => entity.id === entityData.id,
      )
      updatedEntities[indexOfEntity] = {
        ...updatedEntities[indexOfEntity],
        ...entityData,
      }

      if (indexOfEntity !== -1) {
        return {
          ...state,
          naturalEntities: updatedEntities,
          isUpdatingEntity: false,
        }
      }
      toastStateUpdateError()

      return state
    }

    case UPDATE_LEGAL_PTY_ENTITY_SUCCESS: {
      const entityData = action.payload
      const updatedEntities = [...state.legalPtyEntities]
      const indexOfEntity = updatedEntities.findIndex(
        (entity) => entity.id === entityData.id,
      )
      updatedEntities[indexOfEntity] = {
        ...updatedEntities[indexOfEntity],
        ...entityData,
      }

      if (indexOfEntity !== -1) {
        return {
          ...state,
          legalPtyEntities: updatedEntities,
          isUpdatingEntity: false,
        }
      }
      toastStateUpdateError()

      return state
    }

    case UPDATE_LEGAL_TRUST_ENTITY_SUCCESS: {
      const entityData = action.payload
      const updatedEntities = [...state.legalTrustEntities]
      const indexOfEntity = updatedEntities.findIndex(
        (entity) => entity.id === entityData.id,
      )
      updatedEntities[indexOfEntity] = {
        ...updatedEntities[indexOfEntity],
        ...entityData,
      }

      if (indexOfEntity !== -1) {
        return {
          ...state,
          legalTrustEntities: updatedEntities,
          isUpdatingEntity: false,
        }
      }
      toastStateUpdateError()
      return state
    }

    case FETCHING_CORPORATE_ENTITIES_ERROR: {
      return {
        ...state,
        isFetchingEntitiesForCorporate: false,
      }
    }

    case UPDATE_LEGAL_OTHER_ENTITY_SUCCESS: {
      const entityData = action.payload
      const updatedEntities = [...state.legalOtherEntities]
      const indexOfEntity = updatedEntities.findIndex(
        (entity) => entity.id === entityData.id,
      )
      updatedEntities[indexOfEntity] = {
        ...updatedEntities[indexOfEntity],
        ...entityData,
      }

      if (indexOfEntity !== -1) {
        return {
          ...state,
          legalOtherEntities: updatedEntities,
        }
      }
      toastStateUpdateError()
      return state
    }

    case CREATE_NATURAL_ENTITIES_ERROR:
    case CREATE_LEGAL_PTY_ENTITIES_ERROR:
    case CREATE_LEGAL_OTHER_ENTITIES_ERROR:
    case CREATE_LEGAL_TRUST_ENTITIES_ERROR: {
      return {
        ...state,
        isCreatingEntity: false,
      }
    }

    case UPDATE_LEGAL_PTY_ENTITY_ERROR:
    case UPDATE_NATURAL_ENTITY_ERROR:
    case UPDATE_LEGAL_OTHER_ENTITY_ERROR:
    case UPDATE_LEGAL_TRUST_ENTITY_ERROR: {
      return {
        ...state,
        isUpdatingEntity: false,
      }
    }

    case UPDATE_NATURAL_ENTITIES: {
      const naturalEntities = action.payload
      naturalEntities.forEach((entity) => {
        entity.uploadState = {
          idDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
          proofOfResidenceDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
        }
      })
      return {
        ...state,
        naturalEntities,
      }
    }

    case UPDATE_LEGAL_PTY_ENTITIES: {
      const legalPtyEntities = action.payload
      legalPtyEntities.forEach((entity) => {
        entity.uploadState = {
          ptyRegistrationDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
        }
      })
      return {
        ...state,
        legalPtyEntities,
      }
    }

    case UPDATE_LEGAL_TRUST_ENTITIES: {
      const legalTrustEntities = action.payload
      legalTrustEntities.forEach((entity) => {
        entity.uploadState = {
          trustRegistrationDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
          trustLetterOfAuthorityDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
        }
      })
      return {
        ...state,
        legalTrustEntities,
      }
    }

    case UPDATE_LEGAL_OTHER_ENTITIES: {
      const legalOtherEntities = action.payload
      legalOtherEntities.forEach((entity) => {
        entity.uploadState = {
          otherRegistrationDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
          otherLetterOfAuthorityDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
          otherDocumentUploadState: {
            progress: 0,
            uploadErrorMessage: '',
          },
        }
      })
      return {
        ...state,
        legalOtherEntities,
      }
    }

    case UPDATE_NATURAL_ENTITIES_UPLOAD_COMPLETE: {
      try {
        const { data, entityId } = action.payload
        const updatedEntitesData = [...state.naturalEntities]
        const indexOfEntity = updatedEntitesData.findIndex(
          (entity) => entity.id === entityId,
        )
        if (indexOfEntity !== -1) {
          updatedEntitesData[indexOfEntity].documents =
            updatedEntitesData[indexOfEntity].documents.concat(data)
          return {
            ...state,
            naturalEntities: updatedEntitesData,
          }
        }
        toastStateUpdateError()
        return state
      } catch (error) {
        console.log({ error })
      }
    }

    case UPDATE_LEGAL_ENTITIES_UPLOAD_COMPLETE: {
      const { data, entityId, entityType } = action.payload
      const entityDataMap = {
        [LEGAL_ENTITY_TYPES.PTY]: state.legalPtyEntities,
        [LEGAL_ENTITY_TYPES.TRUST]: state.legalTrustEntities,
        [LEGAL_ENTITY_TYPES.OTHER]: state.legalOtherEntities,
      }
      const updatedEntitesData = [...entityDataMap[entityType]]

      const indexOfEntity = updatedEntitesData.findIndex(
        (entity) => entity.id === entityId,
      )
      if (indexOfEntity !== -1) {
        updatedEntitesData[indexOfEntity].documents.push(data[0])

        const updatedStateKey =
          entityType === LEGAL_ENTITY_TYPES.PTY
            ? 'legalPtyEntities'
            : entityType === LEGAL_ENTITY_TYPES.TRUST
              ? 'legalTrustEntities'
              : 'legalOtherEntities'

        return {
          ...state,
          [updatedStateKey]: updatedEntitesData,
        }
      }
      toastStateUpdateError()
      return state
    }

    case UPDATE_NATURAL_ENTITIES_UPLOAD_FAILURE: {
      const { entityId, documentType, progress, uploadErrorMessage } =
        action.payload
      const updatedEntitesData = [...state.naturalEntities]
      const indexOfEntity = updatedEntitesData.findIndex(
        (entity) => entity.id === entityId,
      )

      if (indexOfEntity !== -1) {
        if (documentType === NATURAL_ENTITY_DOCUMENT_TYPES.ID) {
          updatedEntitesData[indexOfEntity].uploadState.idDocumentUploadState =
            {
              progress,
              uploadErrorMessage,
            }
        }
        if (documentType === NATURAL_ENTITY_DOCUMENT_TYPES.PROOF_OF_RESIDENCE) {
          updatedEntitesData[
            indexOfEntity
          ].uploadState.proofOfResidenceDocumentUploadState = {
            progress,
            uploadErrorMessage,
          }
        }

        return {
          ...state,
          naturalEntities: updatedEntitesData,
        }
      }
      toastStateUpdateError()
      return state
    }

    case UPDATE_LEGAL_ENTITIES_UPLOAD_FAILURE: {
      const {
        entityId,
        documentType,
        progress,
        entityType,
        uploadErrorMessage,
      } = action.payload
      const entityDataMap = {
        [LEGAL_ENTITY_TYPES.PTY]: state.legalPtyEntities,
        [LEGAL_ENTITY_TYPES.TRUST]: state.legalTrustEntities,
        [LEGAL_ENTITY_TYPES.OTHER]: state.legalOtherEntities,
      }
      const updatedEntitesData = [...entityDataMap[entityType]]
      const indexOfEntity = updatedEntitesData.findIndex(
        (entity) => entity.id === entityId,
      )
      const uploadState = {
        progress,
        uploadErrorMessage,
      }

      if (indexOfEntity !== -1) {
        const entityUploadStateKeyMap = {
          [LEGAL_ENTITY_DOCUMENT_TYPES.REGISTRATION]:
            'registrationDocumentUploadState',
          [LEGAL_ENTITY_DOCUMENT_TYPES.LETTER_OF_AUTHORITY]:
            'letterOfAuthorityDocumentUploadState',
          [LEGAL_ENTITY_DOCUMENT_TYPES.OTHER]: 'otherDocumentUploadState',
        }
        const uploadStateKey = entityUploadStateKeyMap[documentType]

        if (uploadStateKey) {
          updatedEntitesData[indexOfEntity].uploadState[uploadStateKey] =
            uploadState
        }

        return {
          ...state,
          [entityType === LEGAL_ENTITY_TYPES.PTY
            ? 'legalPtyEntities'
            : entityType === LEGAL_ENTITY_TYPES.TRUST
              ? 'legalTrustEntities'
              : 'legalOtherEntities']: updatedEntitesData,
        }
      }
      toastStateUpdateError()
      return state
    }

    case DEACTIVATE_LEGAL_ENTITY_SUCCESS: {
      const { id, entityType } = action.payload
      const entityDataMap = {
        [LEGAL_ENTITY_TYPES.PTY]: state.legalPtyEntities,
        [LEGAL_ENTITY_TYPES.TRUST]: state.legalTrustEntities,
        [LEGAL_ENTITY_TYPES.OTHER]: state.legalOtherEntities,
      }
      const updatedEntities = [...entityDataMap[entityType]]

      const indexOfEntityToRemove = updatedEntities.findIndex(
        (entity) => entity.id === id,
      )
      updatedEntities.splice(indexOfEntityToRemove, 1)

      const updatedStateKey =
        entityType === LEGAL_ENTITY_TYPES.PTY
          ? 'legalPtyEntities'
          : entityType === LEGAL_ENTITY_TYPES.TRUST
            ? 'legalTrustEntities'
            : 'legalOtherEntities'

      return {
        ...state,
        [updatedStateKey]: updatedEntities,
      }
    }

    case DEACTIVATE_NATURAL_ENTITY_SUCCESS: {
      const { id } = action.payload
      const updatedNaturalEntities = [...state.naturalEntities]
      const indexOfEntityToRemove = updatedNaturalEntities.findIndex(
        (entity) => entity.id === id,
      )
      updatedNaturalEntities.splice(indexOfEntityToRemove, 1)
      return {
        ...state,
        naturalEntities: updatedNaturalEntities,
      }
    }

    case REMOVE_ENTITY_DOCUMENT_START: {
      return {
        ...state,
        isUpdatingEntity: true,
      }
    }

    case REMOVE_ENTITY_DOCUMENT_ERROR: {
      return {
        ...state,
        isUpdatingEntity: false,
      }
    }

    case REMOVE_ENTITY_DOCUMENT_SUCCESS: {
      const { id, entity_type, entityId } = action.payload
      const entityType = ENTITY_TYPES[entity_type.toUpperCase()]
      const updatedEntities = [...state[entityType]]
      const indexOfEntityToRemoveDocument = updatedEntities.findIndex(
        (entity) => entity.id === entityId,
      )
      const updatedDocuments = state[entityType][
        indexOfEntityToRemoveDocument
      ].documents.filter((doc) => doc.id !== id)
      updatedEntities[indexOfEntityToRemoveDocument].documents =
        updatedDocuments
      return {
        ...state,
        [entityType]: updatedEntities,
        isUpdatingEntity: false,
      }
    }

    case CLEAR_CORPORATE_ENTITIES: {
      return initialState
    }

    default:
      return state
  }
}
