import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import SimpleModal from 'components/organisms/Modal'
import { Input, TransparentFormContainer } from 'components/atoms/Form/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Flexbox } from 'components/layout'
import { getAccountBalancesDownloadStatus } from 'redux/selectors/balances'
import { Typography } from '@material-ui/core'
import { Spacer } from 'components/atoms/Generic'
import { getAccountNameAndSurname } from 'redux/selectors/details'
import { formatNumber } from 'helpers/utils'
import { pdf } from '@react-pdf/renderer'
import { autoDownloadFile } from 'helpers/autoDownloadFile'
import AccountStatement from './DownloadAccountBalances'

type Props = {
  handleSubmit: any
  modalOpen: boolean
  accountId: string
  downloadStatus: string
  balances: any[]
  customerDetails: string
  toggleExportModal: () => void
}

let DownloadAccountBalancesForm = (props: Props) => {
  const { downloadStatus, handleSubmit, modalOpen, toggleExportModal } = props
  if (modalOpen && (downloadStatus === 'done' || downloadStatus === 'error')) {
    toggleExportModal()
  }

  return (
    <SimpleModal
      title="Download Balance Statement"
      handleClose={toggleExportModal}
      open={modalOpen}
      buttonsHidden
    >
      <TransparentFormContainer
        //   @ts-ignore
        width="100%"
        direction="row"
        align="center"
        onSubmit={handleSubmit}
      >
        <Flexbox justify="space-between">
          <Typography color="textSecondary">Include Subaccounts</Typography>
          <Spacer margin="0 4px" />
          <Input type="checkbox" name="includeStakingBalances" disabled />
          <Spacer margin="0 4px" />
          <Typography color="secondary">Coming Soon</Typography>
          <Spacer margin="0 4px" />
          <Spacer margin="0 40px" />
          <MuiButton type="submit" disabled={downloadStatus === 'loading'}>
            Download
          </MuiButton>
        </Flexbox>
      </TransparentFormContainer>
    </SimpleModal>
  )
}

DownloadAccountBalancesForm = reduxForm({
  form: 'DownloadAccountBalancesForm',
  onSubmit: (values, dispatch, props) => {
    const { customerDetails, balances } = props
    const payload = balances
      ? balances.map(item => ({
          asset: item.shortName,
          value: formatNumber(item.total),
          approxValueInZAR: formatNumber(item.zarTotal.toString()),
        }))
      : []

    pdf(
      <AccountStatement customerDetails={customerDetails} payload={payload} />,
    )
      .toBlob()
      .then(blob => {
        autoDownloadFile(blob, 'Balance-Statement')
      })
  },
})(DownloadAccountBalancesForm)

const mapStateToProps = state => ({
  downloadStatus: getAccountBalancesDownloadStatus(state),
  customerDetails: getAccountNameAndSurname(state),
})

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

const ReduxDownloadAccountBalancesForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadAccountBalancesForm)

export default ReduxDownloadAccountBalancesForm
