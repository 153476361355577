export const isFiatConfigLoading = state => state.fiat.config.loading

export const getFiatConfig = state => state.fiat.config.data || { currencies: [], bankAccounts: [] }

export const getSelectedBankAccount = state => {
  const selectedBankId = state.fiat.filters.bankAccount
  const fiatConfig = getFiatConfig(state)

  return fiatConfig.bankAccounts.find(b => b.id === selectedBankId) || {}
}
