import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { selectButtonColors } from '../../atoms/Table/styles'
import { Button } from '../../atoms/Form'
import { renderPlural } from '../../../helpers/utils'
import { saveFile } from '../../../helpers/fileHelpers'
import { BankSources } from '../../../redux/fiat/constants'

const shortenId = (id) => {
  const parts = id.split('-')
  return parts[parts.length - 1]
}

const downloadStanbicZMW = (withdrawals) => {
  if (withdrawals.length <= 0) return

  // Example required format:
  // GI Jane,1234567891234,123456,0.00,VALR Withdrawal - <end of id>
  const data = withdrawals.map(w => `${w.accountHolder},${w.accountNumber},${w.branchCode},${w.amount},VALR Withdrawal - ${shortenId(w.withdrawalId)}`)

  const blob = new Blob([data.join('\n')], { type: 'text/csv' })
  saveFile('Stanbic_ZMW', blob)
}

export const downloadBankInstructions = (bankAccount, withdrawals) => {
  switch (bankAccount.id) {
    case BankSources.STANBIC_ZM.id:
      downloadStanbicZMW(withdrawals)
      break
    default:
      toast('Unknown, unsupported or unimplemented bank source', { type: toast.TYPE.ERROR, autoClose: 3500 })
      break
  }
}

const DownloadBankInstructions = ({ withdrawals, bankAccount }) => {

  const downloadInstructions = useCallback(() => downloadBankInstructions(bankAccount, withdrawals), [bankAccount, withdrawals])

  if (!bankAccount.canCheckout || !withdrawals || withdrawals.length <= 0) return null

  return (
    <Button
      style={{ marginRight: '15px', backgroundColor: selectButtonColors.GREEN }}
      onClick={downloadInstructions}
    >
      Download {withdrawals.length} withdrawal{renderPlural(withdrawals)}
    </Button>
  )
}
export default DownloadBankInstructions
