import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchLiquidationSettings } from 'redux/marginSettings/actions'
import UpdateLiqudationEngineSettings from './UpdateLiquidationSettings'

import { ConfigStyle } from './styles'

class LiquidationSettings extends Component {
  componentDidMount() {
    this.props.fetchLiquidationSettings()
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing =
      fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }

  render() {
    const { liquidationSettingsStatus, liquidationSettingsData } =
      this.props.liquidationSettings
    return (
      <Fragment>
        <h3>Margin Requirements</h3>
        {liquidationSettingsStatus === 'loading' && <LoadingBlock />}
        {liquidationSettingsStatus === 'done' && (
          <>
            {liquidationSettingsStatus === 'done' &&
              Object.entries(liquidationSettingsData).map(([key, value]) => (
                <>
                  {typeof value === 'boolean' ? (
                    <>
                      <ConfigStyle>
                        {this.removeCamelCasing(key)}: {value.toString()}
                      </ConfigStyle>
                    </>
                  ) : (
                    <>
                      <ConfigStyle>
                        {this.removeCamelCasing(key)}: {value}
                      </ConfigStyle>
                    </>
                  )}
                </>
              ))}

            {liquidationSettingsStatus === 'done' && (
              <UpdateLiqudationEngineSettings
                fetchLiquidationSettings={this.props.fetchLiquidationSettings}
                data={liquidationSettingsData}
              />
            )}
          </>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  liquidationSettings: state.marginSettings.liquidationSettings,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLiquidationSettings,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(LiquidationSettings)
