import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { pure } from 'recompose'
import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  InfoLabel,
} from 'components/atoms/Details'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import FormAccountsComments from 'forms/AccountsComments'
import FormAccountDeposits from 'forms/AccountsDeposits/index'
import { GREEN_COLOR_PAIR, RED_COLOR_PAIR } from 'theme/colors'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import auth from 'services/Auth'
import * as moment from 'moment'
import Notes from 'components/atoms/Notes'
import { BlueLinkButton } from 'components/atoms/Form'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'

const getStatusColorPair = (status) => {
  if (status === 'ACCOUNT_CREDITED') {
    return GREEN_COLOR_PAIR.positive
  }
  return RED_COLOR_PAIR.positive
}

const creditDeposit = (status) =>
  status.findIndex(
    (element) =>
      element.status === 'ACCOUNT_CREDITED' ||
      element.status === 'SUBMITTED_FOR_CREDITING' ||
      element.status === 'IGNORED' ||
      element.status === 'EXCLUDED' ||
      element.status === 'PENDING_AUTHORISATION',
  ) !== -1

const renderAddNoteForm = (
  depositId,
  deposit,
  toggleAddNotesForm,
  updateComments,
  accountId,
  toggleForm,
) => {
  return (
    <FormAccountsComments
      initialValues={{
        depositId,
        data: {
          date: new Date(),
          createdBy: auth.decodeNameToken(),
          depositsId: depositId,
          accountId,
        },
      }}
      form={`depositCommentsForm+${depositId}`}
      depositData={deposit}
      onSubmitSuccess={(value, dispatch) => {
        updateComments(depositId)
        toggleAddNotesForm(depositId)
      }}
      onCancel={toggleForm}
    />
  )
}

const displayStatuses = (status) => {
  status.find((s) => {
    if (s.status === 'IGNORED') {
      return s.status
    }
  })
  return status[0].status
}

const mainView = (props) => {
  const { tx, setHighlightedId, isHighlighted } = props
  const shading = isHighlighted ? 'white' : ''
  const formattedAmount = new BigNumber(tx.amount).toFormat(2)

  const rowStyling = {
    color: getStatusColorPair(tx.statuses[0].status),
    fontWeight: 'bold',
  }

  return (
    <TableRow
      onClick={() => {
        setHighlightedId(tx.depositId)
        props.expandItem(tx.depositId)
      }}
      style={{ backgroundColor: isHighlighted && '#4569eb', color: shading }}
    >
      <TableCell flex={1.5}>{tx.source}</TableCell>
      <TableCell flex={1} style={rowStyling}>
        {formattedAmount}
      </TableCell>
      <TableCell flex={0.7} style={rowStyling}>
        {tx.currency}
      </TableCell>
      <TableCell flex={1.5} style={rowStyling}>
        {displayStatuses(tx.statuses)}
      </TableCell>
      <TableCell flex={2.5}>{tx.description}</TableCell>
      <TableCell flex={0.8}>
        {moment(tx.dateTime).format('YYYY-MMM-DD HH:mm:ss')}
      </TableCell>
      <TableCell flex={0.2} align={'right'} color={'#e96868'}>
        {tx.tag && <i className="fa fa-sticky-note" />}
      </TableCell>
    </TableRow>
  )
}

const expandedView = (props) => {
  const {
    tx,
    accountId,
    expandRawData,
    addNoteFormShown,
    toggleAddNotesForm,
    showRawData,
    comments,
    commentsStatus,
    updateComments,
    skip,
    limit,
    fetchMore,
    setHighlightedId,
    expandItem,
  } = props

  const [showForm, setShowForm] = useState(false)
  const [rawDataDetails, setShowRawDataDetails] = useState(false)

  const rowStyling = {
    color: getStatusColorPair(tx.statuses[0].status),
    fontWeight: 'bold',
  }

  const toggleForm = () => {
    expandItem(tx.depositId)
    setHighlightedId(tx.depositId)
  }
  const showNoteForm = () => {
    setShowForm((prev) => !prev)
  }

  const showRawDataDetails = () => {
    setShowRawDataDetails((prev) => !prev)
  }

  tx.meta.timestampValue = moment(new Date(tx.meta.timestampValue)).format(
    'YYYY-MMM-DD HH:mm:ss',
  )
  tx.dateTime = moment(tx.dateTime).format('YYYY-MMM-DD HH:mm:ss')
  return (
    <TableRow className="no-hover">
      <TableCell colSpan={7}>
        <Details>
          <DetailsBlockWithTitle title={'Details'}>
            <InfoBlock label={'Id'} value={tx.depositId} />
            <InfoBlock style={rowStyling} label={'Amount'} value={tx.amount} />
            <InfoBlock label={'Currency'} value={tx.currency} />
            <InfoBlock label={'Source'} value={tx.source} />
            <InfoBlock label={'Reference'} value={tx.description} />
            <InfoBlock label={'Timestamp'} value={tx.meta.timestampValue} />
          </DetailsBlockWithTitle>
        </Details>
        {creditDeposit(tx.statuses) ? (
          ''
        ) : (
          <div>
            <FormAccountDeposits
              initialValues={{
                depositId: tx.depositId,
                accountId: accountId || '',
                skip,
                limit,
                fetchMore,
                comment: '',
              }}
              depositId={tx.depositId}
              expandRawData={expandRawData}
              expandAddNote={addNoteFormShown}
              showRawData={() => showRawData(tx.depositId)}
              showAddNote={() => toggleAddNotesForm(tx.depositId)}
              form={`accountDepositsForm+${tx.depositId}`}
              rawData={tx}
              onCancel={toggleForm}
              onSubmitSuccess={toggleForm}
              comments={{ depositComments: comments, status: commentsStatus }}
            />
            {addNoteFormShown &&
              renderAddNoteForm(
                tx.depositId,
                tx,
                toggleAddNotesForm,
                updateComments,
                accountId,
                toggleForm,
              )}
          </div>
        )}
        {creditDeposit(tx.statuses) && (
          <>
            <DetailsBlockWithTitle title="Status">
              <ReviewStatuses
                depositId={tx.depositId}
                accountId={accountId}
                statuses={tx.statuses}
                closeOuterForm={toggleForm}
              />
              <Notes
                depositId={tx.depositId}
                comments={{
                  depositComments: comments,
                  status: commentsStatus,
                }}
              />
            </DetailsBlockWithTitle>
            {showForm &&
              renderAddNoteForm(
                tx.depositId,
                tx,
                toggleAddNotesForm,
                updateComments,
                accountId,
                toggleForm,
              )}
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showRawDataDetails}
            >
              {rawDataDetails ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showNoteForm}
            >
              {showForm ? 'Hide Notes Form' : 'Show Notes Form'}
            </BlueLinkButton>
            {rawDataDetails && (
              <>
                <Flexbox
                  marginBottom={'10'}
                  marginTop={'10'}
                  direction={'column'}
                  align={'flex-start'}
                  justify={'space-around'}
                >
                  <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                  <RawData data={tx} />
                </Flexbox>
              </>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  )
}

const SendsRow = (props) => {
  const { isHighlighted } = props

  return (
    <>
      {mainView(props)}
      {isHighlighted && expandedView(props)}
    </>
  )
}

export default pure(SendsRow)
