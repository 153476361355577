import {
  FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_PROCCESS,
  FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_SUCCESS,
  FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_ERROR,
} from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_PROCCESS: {
      return {
        ...state,
        status: 'loading',
        data: [],
      }
    }
    case FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_MANUAL_KYC_ATTEMPTS_BY_ID_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    default:
      return state
  }
}
