import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import {
  renderInput, renderRadioGroup,
} from '../fields'
import { Spacer } from 'components/atoms/Generic'

let Form = props => {
  const {
    submitting, error, invalid, bulkAction, length,
  } = props
  const {
    handleSubmit, onCancel, onSubmit, accountId, name,
  } = props
  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(onSubmit)}
        width={'40vw'}
      >
        {bulkAction ? <FormTitle>Your are about to bulk action : {length} items </FormTitle> :
          <FormTitle>Check Sanction User Account Id: {accountId} Name: {name}</FormTitle>}
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Enter Comment'}
            name={'comment'}
            width={'100%'}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            name={'approvalStatus'}
            width={'100%'}
            validate={[required]}
            component={renderRadioGroup}
            defaultValue='approved'
            fullWidth
            options={[{ value: 'approved', label: 'approve' }, { value: 'declined', label: 'decline' }]}
          />
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Spacer margin="0px 8px">
            <MuiButton
              type="submit"
              disabled={submitting || invalid}
            >
              Submit
            </MuiButton>
          </Spacer>
          <MuiButton onClick={onCancel} color="secondary">
            Cancel
          </MuiButton>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({
  initialValues: {
    comment: '',
    approvalStatus: 'approved',
  },
})(Form)

export const SanctionsCheckForm = connect()(Form)
