import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import {reset} from 'redux-form';
import { get } from 'lodash'

const UPLOAD_FIAT_CSV = 'fundFiat/UPLOAD_FIAT_CSV'
const UPLOAD_FIAT_CSV_FAILED = 'fundFiat/UPLOAD_FIAT_CSV_FAILED'
const LOADING = 'fundFiat/loading'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return { ...state, loading: true, success: false, error: null }
    }

    case UPLOAD_FIAT_CSV: {
      return { ...state, loading: false, success: true, error: null }
    }

    case UPLOAD_FIAT_CSV_FAILED: {
      return { ...state, error: action.error, loading: false, success: false }
    }

    default:
      return state
  }
}

export const uploadFiatCsv = data => async dispatch => {
  dispatch({ type: LOADING })

  data.source = data.source.value

  try {
    const res = await getAxios().post(`/fund/fiatcsv`, data)
    dispatch({ type: UPLOAD_FIAT_CSV })
    if (!res.data || !res.data.deposits) throw Error('No deposit data received')
    const depositCount = res.data.deposits.length || 0
    toast(`Uploaded ${depositCount} deposit(s) successfully`, { type: toast.TYPE.SUCCESS, autoClose: 2000 })
    dispatch(reset('uploadCsv'))
  } catch (e) {
    const message = get(e, 'response.data.message', 'Internal server error')
    toast(`Failed To Upload Deposits: ${message}`, { type: toast.TYPE.ERROR, autoClose: 4000 })
    dispatch({ type: UPLOAD_FIAT_CSV_FAILED, error: e })
  }
}
