import React, { useState } from 'react'
import { Table, TableBody } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import { Headers } from './headers'
import DualAuthRow from './row'

const DefaultDualAuthTable = props => {
  const { dualAuthData } = props
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = uniqueId =>
    setSelectedRow(prev => (prev === uniqueId ? '' : uniqueId))
  return (
    <Table>
      <Headers />
      <TableBody>
        {dualAuthData.map(deposit => {
          deposit.dateTime = moment
            .unix(deposit.dateTime)
            .format('YYYY-MMM-DD HH:mm:ss')
          return (
            <DualAuthRow
              key={deposit.id}
              dualAuth={deposit}
              isSelected={selectedRow === deposit.id}
              toggleSelected={toggleSelectedRow}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

export default DefaultDualAuthTable
