import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  required,
  maxLength256,
  minLength,
  maxLength,
  maxLength3,
  minLength3,
} from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput } from '../fields'
import { submit } from './submit'
import { getCountryOptions } from 'helpers/utils'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(async values => {
          props.countryOfResidenceChangeProcessActive(true)
          await onSubmit(values)
          props.countryOfResidenceChangeProcessActive(false)
        })}
      >
        <FormTitle>Change Country</FormTitle>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'New country (required)'}
            name={'residentialCountry'}
            width={'100%'}
            validate={[required, minLength3, maxLength3]}
            component={renderDropdown}
            options={getCountryOptions()}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Comment (required)'}
            name={'comment'}
            width={'100%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required, maxLength256]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
