

import React, { Component, Fragment } from 'react'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  openForm = () => {
    this.props.toggleSelected(this.props.withdrawalLimitInfo.id)
    this.props.fetchWithdrawalLimitById(this.props.withdrawalLimitInfo.id)
  }

  closeForm = () => {
    this.props.toggleSelected(this.props.withdrawalLimitInfo.id)
  }

  handleSubmitSuccess = async () => {
    await this.props.removeSubmittedWithdrawalItem(this.props.withdrawalLimitInfo.id)
    this.closeForm()
  }

  render() {
    const {
      withdrawalLimitInfo,
      withdrawalLimitInfo: {
        data: {
          accountId,
          reason,
        },
        id,
        createdAt,
        active,
      },
      withdrawalLimit,
      isSelected,
      rowLoading,
    } = this.props
    const shading = isSelected ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => {
            this.openForm()
          }}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'10%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'40%'}>{createdAt}</TableCell>
          <TableCell width={'40%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              actionType: withdrawalLimitInfo.actionType,
              accountId,
              flag: false,
              dualAuthStatus: 'pending',
            }}
            form={`PendingDualAuthorisationForm-${id}`}
            onCancel={this.closeForm}
            active={active}
            onSubmitSuccess={this.handleSubmitSuccess}
            rawData={withdrawalLimit}
            loading={rowLoading}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}

          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'WITHDRAWAL_LIMIT')
