import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submit = async values => {
  const { email } = values
  try {
    await getAxios().post(`/account/resend-verification/${email}`)
    toast('Email verification resent', {
      type: toast.TYPE.SUCCESS,
      autoClose: 2500,
    })
  } catch (e) {
    toast('Could not resend email - '+e, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
