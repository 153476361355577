import {
  DISPLAY_USER_TICKETS_PROCESS,
  DISPLAY_USER_TICKETS_SUCCESS,
  DISPLAY_USER_TICKETS_ERROR,
} from './actions'

const initialState = {
  userTickets: [],
  userTicketsStatus: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_USER_TICKETS_PROCESS: {
      return {
        ...state,
        userTicketsStatus: 'loading',
        userTickets: [],
      }
    }
    case DISPLAY_USER_TICKETS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        userTicketsStatus: 'done',
        userTickets: data,
      }
    }
    case DISPLAY_USER_TICKETS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        userTicketsStatus: 'error',
      }
    }
    default:
      return state
  }
}
