import React, { useMemo } from 'react'
import { Field } from 'redux-form'
import { BASE } from 'theme/base-units'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { FormContainer, Button } from 'components/atoms/Form'

import { required } from 'forms/fieldValidators'
import { renderDropDownField, renderInput } from 'forms/fields'
import { Flexbox } from 'components/layout/index'
import { submit } from './submit'

let Form = (props) => {
  const { handleSubmit, isEditing, onCancel, onSubmit, invalid, submitting } =
    props
  const { currencies } = props
  const currenciesData = useMemo(
    () =>
      currencies.data
        .filter((currencies) => {
          return currencies.isActive
        })
        .map((property) => {
          const option = {
            value: property.shortName,
            label: property.shortName,
          }
          return option
        })
        .sort((a, b) => {
          if (a.label < b.label) return -1
          return a.label > b.label ? 1 : 0
        }),
    [currencies.data],
  )
  return (
    <FormContainer
      onSubmit={handleSubmit((values, dispatch) =>
        onSubmit(values, dispatch, isEditing),
      )}
    >
      <Flexbox align={'flex-start'} justify={'space-between'} direction={'row'}>
        <Field
          name={'currency'}
          component={renderDropDownField}
          options={[...currenciesData]}
          label="Currency"
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          showNoneLabel={false}
          disabled={isEditing}
        />
        <Field
          label={'Amount'}
          name={'amount'}
          width={'40%'}
          margin={`0 ${BASE * 2}px 0 0`}
          validate={[required]}
          component={renderInput}
        />
        <div style={{ width: '286px', marginTop: '16px' }}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button padding={'16px 41px'} onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </div>
      </Flexbox>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit, form: 'add_restrictions' })(Form)

const ReduxForm = connect()(Form)
export default ReduxForm
