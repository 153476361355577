import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { isVerifiedAccount } from 'helpers/utils'

export const FETCH_WATCHLIST_CHECK_PROCESS = 'FETCH_WATCHLIST_CHECK_PROCESS'
export const FETCH_WATCHLIST_CHECK_SUCCESS = 'FETCH_WATCHLIST_CHECK_SUCCESS'
export const FETCH_WATCHLIST_CHECK_ERROR = 'FETCH_WATCHLIST_CHECK_ERROR'

export const fetchWatchlistCheckById = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_WATCHLIST_CHECK_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/sanctions-check`)
    const { data } = res
    var sortedData = data.sort(function (a, b) {
      return new Date(a.created_at) - new Date(b.created_at)
    })
    dispatch({
      type: FETCH_WATCHLIST_CHECK_SUCCESS,
      payload: {
        id,
        data: sortedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WATCHLIST_CHECK_ERROR,
      payload: {
        id,
        error: error,
        data: [],
      },
    })
  }
}

export const initiateCheck = async (values) => {
  try {
    const res = await getAxios().post(`/sanctions/individual/check`, values)
    res &&
      toast('Check Initiated', { type: toast.TYPE.SUCCESS, autoClose: 2500 })
    return res
  } catch (error) {
    console.log('error', error)
    ErrorToast(error)
  }
}

export const acknowledgeCheck =
  (sanctionData, checkInformation) => async (dispatch) => {
    try {
      const res = await getAxios().post('/account/acknowledge/check/false', {
        sanctionData,
        checkInformation,
      })
      toast('Request Completed', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
      res && (await dispatch(fetchWatchlistCheckById(sanctionData.account_id)))
      return res
    } catch (error) {
      ErrorToast(error)
    }
  }
