import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { addUserActionComment } from 'redux/userComments/actions'

export const submit = async values => {
  const accountId = values.id
  const reason = values.comment
  let res = null
  try {
    res = await getAxios().put('/account/unblock', {
      accountId,
      reason,
    })
    if (res.data.statusCode === 202) {
      const { dualAuthId } = res.data
      toast('Account status updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
      await addUserActionComment({
        ...values,
        dualAuthId,
      })
    } else {
      toast(`Failed to unblock account. \n ${res.data.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
