import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  BlueLinkButton,
  Button,
  FormError,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { Details, DetailsBlockWithTitle, InfoLabel } from 'components/atoms/Details'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import Notes from 'components/atoms/Notes'
import { required } from 'forms/fieldValidators'
import { renderDropdown, renderInput } from '../fields'
import { submitDepositReview } from './submit'

let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit, onCancel, onSubmit, showRawData, showAddNote,
  } = props
  const {
    rawData, expandRawData, expandAddNote, depositId, statusType, isInDualAuth, comments, showActiveUser, removeActiveUser,
  } = props

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => () => {
    removeActiveUser()
  }, [])
  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: '1%' }}>
          {!isInDualAuth && (
            <Flexbox align={'flex-start'} justify={'space-between'} direction={'row'}>
              <Field
                label={'Action'}
                name={'status'}
                margin={`0 ${BASE * 2}px 0 0`}
                width={'40%'}
                flex={0.4}
                component={renderDropdown}
                options={[
                  { value: 'UNSUSPEND', label: 'Unsuspend' },
                  { value: 'IGNORE', label: 'Reject' },
                ]}
              />
              { statusType === 'IGNORE' &&
              <Field
                label={'Comment (required)'}
                name={'comment'}
                width={'60%'}
                margin={`0 ${BASE * 2}px 0 0`}
                validate={[required]}
                component={renderInput}
                marginBottom={26}
                labelWidth={'150px'}
              />
}
              <div style={{ width: '286px', marginTop: '16px' }}>
                <Button
                  type="submit"
                  main
                  disabled={statusType === null || statusType === undefined || submitting || invalid}
                  margin={`0 ${BASE * 2}px 0 0`}
                >
                  Submit
                </Button>
                <Button padding={'16px 41px'} onClick={onCancel} color='secondary'>Cancel</Button>
              </div>
            </Flexbox>
          )}
        </div>
        <Details>
          <DetailsBlockWithTitle title="Statuses">
            <ReviewStatuses statuses={rawData.statuses} />
          </DetailsBlockWithTitle>
        </Details>
        <Details>
          <DetailsBlockWithTitle title="Notes">
            <Notes depositId={depositId} comments={comments} />
          </DetailsBlockWithTitle>
        </Details>
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton marginRight={'50px'} marginBottom={'20px'} onClick={showRawData}>
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
            <BlueLinkButton marginRight={'50px'} marginBottom={'20px'} onClick={showAddNote}>
              {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({ onSubmit: submitDepositReview })(Form)

const mapStateToProps = (state, ownProps) => {
  const creditAccountForm = state.form[ownProps.form]
  if (!creditAccountForm) {
    return {
      statusType: '',
    }
  } if (creditAccountForm.values) {
    const {
      values: { status },
    } = creditAccountForm
    return {
      statusType: status && status.value,
    }
  }
  return { statusType: '' }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
