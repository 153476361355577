import React, { useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import { Headers } from './headers'
import Row from './row'

export const TotpRemovalTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(totpDualAuth => {
          totpDualAuth.createdAt = moment(totpDualAuth.createdAt).format(
            'YYYY-MMM-DD HH:mm:ss',
          )
          return (
            <Row
              key={totpDualAuth.id}
              dualAuthId={totpDualAuth.id}
              totpDualAuth={totpDualAuth}
              isSelected={selectedRow === totpDualAuth.id}
              toggleSelected={toggleSelectedRow}
            />
          )
        })}
      </TableBody>
    </Table>
  )

}