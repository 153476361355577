import React from 'react'
import {
  StyledEyeIconText,
  StyledEyeIcon,
  StyledEyeIconWrapper,
} from './Styles'
import './Styles.css'

const EyeIcon = ({ text }) => {
  return (
    <StyledEyeIconWrapper className="tooltip">
      <StyledEyeIcon className="fas fa-eye" />
      <StyledEyeIconText className="tooltiptext">{text}</StyledEyeIconText>
    </StyledEyeIconWrapper>
  )
}

export default EyeIcon
