import styled from 'styled-components'

export const StyledReviewersContainer = styled.div`
margin-bottom: 24px;
`

export const StyledReviewer = styled.div`
display: flex;
align-items: center;
> P {
    margin-left: 8px;
    margin-right: 8px;
}    
`
