import {
  FETCH_PLATFORM_SUMMARY_PROCESS,
  FETCH_PLATFORM_SUMMARY_SUCCESS,
  FETCH_PLATFORM_SUMMARY_ERROR,
  FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_PROCESS,
  FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_SUCCESS,
  FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_ERROR,
  FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_PROCESS,
  FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_SUCCESS,
  FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_ERROR,
} from './actions'

const initialState = {
  PlatformSummary: {
    status: 'pristine',
    data: [],
  },
  accountsWithMarginSummary: {
    status: 'pristine',
    data: [],
  },
  accountsWithFullMarginInfo: {
    status: 'pristine',
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLATFORM_SUMMARY_PROCESS: {
      return {
        ...state,
        PlatformSummary: {
          ...state.PlatformSummary,
          status: 'loading',
        },
      }
    }

    case FETCH_PLATFORM_SUMMARY_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        PlatformSummary: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_PLATFORM_SUMMARY_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        PlatformSummary: {
          ...state.PlatformSummary,
          error,
          status: 'error',
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_PROCESS: {
      return {
        ...state,
        accountsWithMarginSummary: {
          ...state.accountsWithMarginSummary,
          status: 'loading',
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        accountsWithMarginSummary: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        accountsWithMarginSummary: {
          ...state.accountsWithMarginSummary,
          error,
          status: 'error',
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_PROCESS: {
      return {
        ...state,
        accountsWithFullMarginInfo: {
          ...state.accountsWithFullMarginInfo,
          status: 'loading',
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        accountsWithFullMarginInfo: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        accountsWithFullMarginInfo: {
          ...state.accountsWithFullMarginInfo,
          error,
          status: 'error',
        },
      }
    }
    default:
      return state
  }
}
