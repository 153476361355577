import getAxios from 'helpers/axios'
import {
  CorporateRiskProfileDualAuth,
  RiskProfileDualAuth,
} from 'interfaces/riskProfile'
import { Dispatch } from 'react'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'

export type RiskBasedApproachActionTypes =
  | 'FETCH_RISK_PROFILES_DUAL_AUTH_START'
  | 'FETCH_RISK_PROFILES_DUAL_AUTH_SUCCESS'
  | 'FETCH_RISK_PROFILES_DUAL_AUTH_ERROR'
  | 'UPDATE_RISK_PROFILES_DUAL_AUTH_SUCCESS'
  | 'UPDATE_CORPORATE_RISK_PROFILES_DUAL_AUTH_SUCCESS'
  | 'UPDATE_RISK_PROFILES_DUAL_AUTH_ERROR'
  | 'CLEAR_PENDING_DUAL_AUTHS'

export interface RiskProfileDualAuthResponse {
  individualRiskProfileDualAuths: {
    pending: RiskProfileDualAuth[]
    pendingMultiAuth: RiskProfileDualAuth[]
  }
  corporateRiskProfileDualAuths: {
    pending: CorporateRiskProfileDualAuth[]
    pendingMultiAuth: CorporateRiskProfileDualAuth[]
  }
}

export interface FetchRiskProfileDualAuthDispatch {
  type: RiskBasedApproachActionTypes
  payload?: {
    data: RiskProfileDualAuthResponse
  }
}

export interface UpdatedRiskProfileDualAuthDispatch {
  type: RiskBasedApproachActionTypes
  payload?: { data: RiskProfileDualAuth }
}

export interface FetchRiskProfileDualAuthResponse {
  data: RiskProfileDualAuthResponse
}

export interface UpdateRiskProfileDualAuthResponse {
  data: { updatedDualAuthData: RiskProfileDualAuth }
}

export const fetchRiskProfileDualAuth = () => async (
  dispatch: Dispatch<FetchRiskProfileDualAuthDispatch>,
) => {
  try {
    dispatch({
      type: 'FETCH_RISK_PROFILES_DUAL_AUTH_START',
    })
    const { data }: FetchRiskProfileDualAuthResponse = await getAxios().get(
      'risk-based-approach/dual-auth',
    )
    dispatch({
      type: 'FETCH_RISK_PROFILES_DUAL_AUTH_SUCCESS',
      payload: { data },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: 'FETCH_RISK_PROFILES_DUAL_AUTH_ERROR' })
  }
}

export const updateRiskProfileDualAuth = values => async (
  dispatch: Dispatch<UpdatedRiskProfileDualAuthDispatch>,
) => {
  try {
    const { data }: UpdateRiskProfileDualAuthResponse = await getAxios().put(
      'risk-based-approach/dual-auth',
      {
        values,
      },
    )
    if (values.isCorporateRiskProfileDualAuth) {
      dispatch({
        type: 'UPDATE_CORPORATE_RISK_PROFILES_DUAL_AUTH_SUCCESS',
        payload: { data: data.updatedDualAuthData },
      })
    } else {
      dispatch({
        type: 'UPDATE_RISK_PROFILES_DUAL_AUTH_SUCCESS',
        payload: { data: data.updatedDualAuthData },
      })
    }
    toast('Dual Auth Successfully Updated ', {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: 'UPDATE_RISK_PROFILES_DUAL_AUTH_ERROR' })
    throw error
  }
}

export const clearRiskProfileDualAuth = () => async (
  dispatch: Dispatch<UpdatedRiskProfileDualAuthDispatch>,
) => {
  dispatch({ type: 'CLEAR_PENDING_DUAL_AUTHS' })
}
