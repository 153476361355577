import { combineReducers } from 'redux'
import info from './info'
import level from './level'
import pending from './pending'

export default combineReducers({
  info,
  level,
  pending,
})
