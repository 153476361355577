import React, { Component, Fragment } from 'react'
import {
  TableCell,
  TableRow,
} from 'components/atoms/Table/styles'

class MarketingContactRunsTableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rawData: {},
    }
  }

  componentDidMount() {
    const { data } = this.props
    this.setState({ rawData: data })
  }

  render() {
    const { uniqueId, data } = this.props
    const { id, name, contact_count } = data
    return (
      <Fragment>
        <TableRow
          key={uniqueId}
          style={{
            padding: '0px'
          }}
        >
          <TableCell flex={0.5} style={{ padding: '16px 16px' }} width={'30%'}>
            {id}
          </TableCell>
          <TableCell flex={0.65} style={{ padding: '16px 16px' }} width={'40%'}>
            {name}
          </TableCell>
          <TableCell flex={0.65} style={{ padding: '16px 16px' }} width={'30%'}>
            {contact_count}
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}

export default MarketingContactRunsTableRow