import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { StyledLink } from 'components/atoms/Styles'

import {
  TransparentFormContainer,
  Button,
  InputGroup,
  FormTitle,
} from 'components/atoms/Form'
import {
  renderInput,
  renderDropdown,
  renderFileUploaderInput,
} from 'forms/fields'
import { required, requiredDropdownValue } from 'forms/fieldValidators'

import { submitCompetition } from './submit'

let Form = (props) => {
  const { submitting, invalid, pristine, handleSubmit } = props

  return (
    <TransparentFormContainer
      width="100%"
      onSubmit={handleSubmit(submitCompetition)}
    >
      <FormTitle>Upload Batch Transfer Csv</FormTitle>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Reason ID'}
          name={'reasonId'}
          component={renderDropdown}
          options={[
            { value: 1, label: 'COMPETITION' },
            { value: 2, label: 'OTHER' },
          ]}
          validate={[requiredDropdownValue]}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'30%'}
        />
        <Field
          label={'Sendgrid template id'}
          name={'templateId'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'30%'}
          flex={0.4}
          component={renderInput}
          placeholder={'Sendgrid template id'}
        />
      </InputGroup>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'List name(used for emails)'}
          name={'listName'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'28%'}
          flex={0.4}
          component={renderInput}
          validate={[required]}
          placeholder={'Name of contact list'}
        />
        <div>
          <Field
            iconWidth={50}
            iconHeight={50}
            allowedFileTypes={['.csv']}
            label={'Batch file'}
            name={'userIds'}
            buttonText="Upload batch file"
            component={renderFileUploaderInput}
            validate={[required]}
            acceptedFileTypes={'.csv'}
          />
          <StyledLink
            style={{ marginTop: 15 }}
            href={
              'https://docs.google.com/spreadsheets/d/13bMFjca75RGv5dr3b6z20rCfLc9FwYncg2qR4RbkKLw/edit?usp=sharing'
            }
            target={'_blank'}
          >
            Csv Example
          </StyledLink>
        </div>

        <Button
          style={{ marginTop: '10px', marginLeft: '45px' }}
          type="submit"
          disabled={submitting || invalid || pristine}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Preview
        </Button>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'CompetitionForm',
  onSubmit: submitCompetition,
  enableReinitialize: true,
})(Form)

const ReduxForm = connect()(Form)
export default ReduxForm
