import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const submit = async (values) => {
  const accountId = values.id
  const reason = values.comment
  let res = null
  try {
    res = await getAxios().put('/remove/totp', {
      accountId,
      reason,
    })
    if (res.data.statusCode === 202) {
      toast('MFA  updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      toast(`Failed to remove MFA. \n ${res.data.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
  } catch (error) {
    toast(`Failed code 500. \n ${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}