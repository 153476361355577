import React, { Component, Fragment } from 'react'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import { RenderCheckbox } from 'forms/fields'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'
import socket from 'redux/socket/index'
import ReleaseFiatDepositsForm from 'forms/ReleaseDeposits'
import FormDepositsComments from 'forms/DepositsComments/index'
import auth from 'services/Auth'
import { VALR_BLUE_GRAY } from 'theme/colors'
import { TransitionCell } from '../Styles'
import { Link } from '../AccountIdLink/clickableId'
import EyeIcon from 'components/atoms/EyeIcon/index'

class UnresolvedFiatDepositRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      activeUser: '',
      commentsStatus: 'persist',
      test: '',
    }
  }

  updateComments = async depositId => {
    this.setState({ commentsStatus: 'loading' })
    const data = await fetchDepositComments(depositId)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  removeActiveUser = async () => {
    const { depositId } = this.props.deposit
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId }
    const removeUser = { depositId, activeUserQue }
    socket.emit('remove user', removeUser)
  }

  showActiveUser = async () => {
    const { depositId } = this.props.deposit
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId }
    const activeUser = { depositId, activeUserQue }
    socket.emit('add user', activeUser)
  }

  toggleCancel = () => {
    this.props.toggleRow(this.props.deposit.depositId)
  }

  renderAddNoteForm = () => {
    const {
      deposit: { depositId },
      toggleAddNotesForm,
    } = this.props

    return (
      <FormDepositsComments
        initialValues={{
          depositId,
          data: {
            date: new Date(),
            createdBy: auth.decodeNameToken(),
            depositsId: depositId,
          },
        }}
        form={'depositCommentsForm'}
        depositData={this.props.deposit}
        onCancel={this.toggleCancel}
        toggleForm={() => {
          toggleAddNotesForm(depositId)
          this.updateComments(depositId)
        }}
      />
    )
  }

  render() {
    const {
      deposit: {
        accountId,
        depositId,
        source,
        amount,
        description,
        dateTime,
        currency,
        active,
        tag,
      },
      deposit,
      toggleRow,
      isExpandedRow,
      isHighlighted,
      setHighlightedId,
      handleSelectedBulkDepositClick,
      bulkActionsActive,
      isCellHidden,
      title,
      selectedBulkDeposits,
    } = this.props
    const formatedAmount = new BigNumber(amount).toFormat(2)
    const padding = '16px 16px 16px 0px'
    const shading = isHighlighted ? 'white' : ''
    const depositRowSelected = !!selectedBulkDeposits.find(
      deposit => deposit.depositId === depositId,
    )
    return (
      <Fragment>
        <TableRow
          active={isHighlighted || depositRowSelected}
          onClick={() => {
            setHighlightedId(depositId)
            this.updateComments(depositId)
            toggleRow(depositId)
          }}
          style={{
            color: shading,
            fontWeight: '600',
            overflow: 'hidden',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <TableCell flex={0.5} style={{ padding: '16px 16px' }}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell flex={0.6} style={{ padding }}>
            {source}
          </TableCell>
          <TableCell flex={0.6} style={{ padding }}>
            {formatedAmount}
          </TableCell>
          <TableCell flex={0.8} style={{ padding }}>
            {currency}
          </TableCell>
          <TableCell flex={2.4} style={{ padding }}>
            {description}
          </TableCell>
          <TableCell flex={0.8} style={{ padding }}>
            {moment(dateTime).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell flex={0.9} style={{ padding }}>
            {active &&
              active.userActive && <EyeIcon text={active.activePersonName} />}
          </TableCell>
          <TableCell flex={0.18} color={'#e96868'}>
            {tag && <i className="fa fa-sticky-note" aria-hidden="true" />}
          </TableCell>

          <TransitionCell
            className={
              bulkActionsActive
                ? 'bulkSuspendCheckBoxActive'
                : 'bulkSuspendCheckBoxfade'
            }
            onClick={e => e.stopPropagation()}
            flex={0.7}
            show={isCellHidden}
          >
            <RenderCheckbox
              style={{ color: depositRowSelected ? 'white' : '' }}
              checked={depositRowSelected}
              onCheckFunction={() =>
                handleSelectedBulkDepositClick({ depositId, title })
              }
            />
          </TransitionCell>
        </TableRow>
        {isExpandedRow && (
          <>
            <ReleaseFiatDepositsForm
              initialValues={{
                depositIds: [depositId],
                comment: '',
                status: '',
              }}
              form={'ReleaseFiat'}
              onCancel={this.toggleCancel}
              rawData={deposit}
              expandAddNote={this.props.addNoteFormShown}
              expandRawData={this.props.expandRawData}
              showAddNote={() => this.props.toggleAddNotesForm(depositId)}
              showRawData={() => this.props.showRawData(depositId)}
              comments={{
                depositComments: this.state.comments,
                status: this.state.commentsStatus,
              }}
              removeActiveUser={this.removeActiveUser}
              showActiveUser={this.showActiveUser}
            />
            {this.props.addNoteFormShown && this.renderAddNoteForm()}
          </>
        )}
      </Fragment>
    )
  }
}

export default UnresolvedFiatDepositRow
