import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionButton } from 'components/atoms/Table/styles'
import { fundFromFile } from 'redux/fund'

class FundFromFile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formShown: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    return (
      <Fragment>
        <ActionButton onClick={this.props.fundFromFile}>Fund From File</ActionButton>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fundFromFile,
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(FundFromFile)
