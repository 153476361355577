import getAxios from 'helpers/axios'
import { throwGenericSubmissionError } from 'helpers/utils'
import { toast } from 'react-toastify'
import { fetchAccountDeposits } from 'redux/deposits/actions'

export const submitAccountsDeposits = async (values, dispatch) => {
  const { accountId, status, comment = '', depositId } = values
  let res = null
  try {
    if (status.value === 'CREDIT') {
      res = await getAxios().post('/fiat/review/credit', {
        depositId,
        accountId,
        comment,
      })
      res.data.statusCode !== 202 ? toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 }) : toast('Deposit Successfully Initiated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      res = await getAxios().post('/fiat/deposit/review/update', {
        depositId,
        updateType: status.value,
        comment,
      })
      toast('Deposit status updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
    dispatch(fetchAccountDeposits({
      id: accountId,
      limit: 100,
      skip: 0,
      fetchMore: false,
    }))
    return {
      depositId,
      level: status.value,
      description: status.label,
    }
  } catch (e) {
    toast('Server error', { type: toast.TYPE.ERROR, autoClose: 3500 })
    throwGenericSubmissionError({
      status: e.response.status || 400,
      message: e.response.data.message || 'Server error. Contact administrator to get help',
    })
  }
  return res
}