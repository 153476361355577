import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uploadFiatCsv } from 'redux/fund-fiat'
import UploadCsv from 'forms/UploadCsv/index'
import { FormError } from 'components/atoms/Form'
import ErrorBoundary from 'helpers/ErrorBoundary'
import LoadingBlock from '../../components/molecules/LoadingBlock'
import { getFiatConfig } from "../../redux/fiat/selectors";

class FundFromFiatCsv extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formShown: false,
    }

    this.toggleForm = this.toggleForm.bind(this)
  }

  toggleForm() {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const {
      fundFiat: { loading, success, error },
      fiatConfig,
    } = this.props

    if (fiatConfig.loading) return <LoadingBlock />

    return (
      <ErrorBoundary message="From Fiat CSV">
        <UploadCsv loading={loading} success={success} config={fiatConfig} />
        {error && <FormError error={error} />}
      </ErrorBoundary>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadFiatCsv,
    },
    dispatch,
  )

const mapStateToProps = state => ({
  fundFiat: state.fundFiat,
  fiatConfig: getFiatConfig(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundFromFiatCsv)
