import React, { FC, useEffect, useRef } from 'react'
import { Flexbox } from 'components/layout'
import { Spacer } from 'components/atoms/Generic'
import { connectStateAndDispatch } from 'store'
import { SingleAccountInfo } from 'interfaces/singleAccount'
import { updateSearchedRiskProfileState } from 'redux/riskBasedProfiles/actions'
import { RiskScoringFormOptions } from 'interfaces/riskProfile'

import { DetailsBlockWithTitle } from 'components/atoms/Details'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  restoredRiskProfileEddDocument,
  createRiskProfile,
  updateRiskProfile,
  fetchRiskProfileInfo,
  fetchRiskScoringFormOptions,
  fetchAccountCorporateInformation,
} from 'redux/accounts/actions'
import { Title } from 'components/atoms/Title'

import {
  AccountCorporate,
  CorporateNaturalEntity,
  CorporateRiskProfile,
  IndividualRiskProfile,
  RiskProfileEddDocument,
} from '../../interfaces/riskProfile'
import CorporateRiskProfilePage from './CorporateRiskProfile/CorporateRiskProfilePage'
import { getCustomerType } from 'redux/selectors/type'
import {
  checkIfAccountIsCorporate,
  showRiskScoreAlertColor,
} from './EddReports/RiskProfilePdf/helpers'

interface RiskProfileProps {
  accountInfo: SingleAccountInfo
  individualRiskProfileInfo: IndividualRiskProfile
  corporateRiskProfile: CorporateRiskProfile
  eddDocuments: RiskProfileEddDocument[]
  removedEddDocuments: RiskProfileEddDocument[]
  riskScoringFormOptions: RiskScoringFormOptions
  customerTypeInfo: {
    status: string
    data: {
      accountId: number
      type: string
      updatedByAuthProviderId: string
      comment: string
      insertedAt: string
      updatedAt: string
      keyRelianceAgreementStatus: boolean
    }
    error: string
  }
  accountCorporate: AccountCorporate
  accountCorporateNaturalEntities: CorporateNaturalEntity[]
  isFetchingRiskProfileInfo: boolean
  isFetchingRiskScoringOptions: boolean
  isFetchingCorporateForAccount: boolean
  match: any
  history: any
  restoredRiskProfileEddDocument: (
    document: RiskProfileEddDocument,
  ) => Promise<void>
  fetchRiskProfileInfo: (accountId: number) => Promise<void>
  fetchAccountCorporateInformation: (accountId: number) => Promise<void>
  fetchRiskScoringFormOptions: () => Promise<void>
  updateSearchedRiskProfileState: (accountId: number) => Promise<void>
}

const CorporateRiskProfileDisplay: FC<RiskProfileProps> = (props) => {
  const {
    accountCorporate,
    accountCorporateNaturalEntities,
    corporateRiskProfile,
    customerTypeInfo,
    riskScoringFormOptions,
    isFetchingRiskScoringOptions,
    isFetchingCorporateForAccount,
    updateSearchedRiskProfileState,
  } = props
  const { riskScore: corporateRiskScore } = corporateRiskProfile
  const { accountId } = props.match.params
  const { corporateScoringOptions, ...individualScoringOptions } =
    riskScoringFormOptions
  if (customerTypeInfo.status !== 'done')
    return <LoadingBlock message="Fetching customer type..." />

  const isCorporateAccount = checkIfAccountIsCorporate(customerTypeInfo)

  const handleFetchData = async () => {
    await props.fetchRiskScoringFormOptions()
    if (isCorporateAccount) {
      await props.fetchAccountCorporateInformation(accountId)
    }
    await props.fetchRiskProfileInfo(accountId)
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div>
      <DetailsBlockWithTitle title="Risk Profile">
        <Flexbox direction="column">
          {isCorporateAccount && (
            <Title
              style={{
                ...showRiskScoreAlertColor(corporateRiskScore),
              }}
              type="h4"
              text={`Corporate risk score ${corporateRiskScore || '--'}`}
            />
          )}
        </Flexbox>
        {isFetchingCorporateForAccount || isFetchingRiskScoringOptions ? (
          <LoadingBlock message="Loading please wait..." />
        ) : (
          <>
            {isCorporateAccount ? (
              <CorporateRiskProfilePage
                accountCorporate={accountCorporate}
                accountCorporateNaturalEntities={
                  accountCorporateNaturalEntities
                }
                accountId={accountId}
                corporateRiskProfile={corporateRiskProfile}
                corporateScoringOptions={corporateScoringOptions}
                createRiskProfile={createRiskProfile}
                history={props.history}
                isCorporateAccount={isCorporateAccount}
                isFetchingCorporateForAccount={isFetchingCorporateForAccount}
                isFetchingRiskScoringOptions={isFetchingRiskScoringOptions}
                updateRiskProfile={updateRiskProfile}
                updateSearchedRiskProfileState={updateSearchedRiskProfileState}
              />
            ) : null}
          </>
        )}
      </DetailsBlockWithTitle>
    </div>
  )
}
const action = {
  fetchRiskProfileInfo,
  restoredRiskProfileEddDocument,
  updateSearchedRiskProfileState,
  fetchRiskScoringFormOptions,
  fetchAccountCorporateInformation,
}

export default connectStateAndDispatch(
  (state) => ({
    individualRiskProfileInfo: state.accounts.byId.riskProfile.info,
    corporateRiskProfile: state.accounts.byId.riskProfile.corporateInfo,
    accountCorporate: state.accounts.byId.riskProfile.accountCorporate,
    accountCorporateNaturalEntities:
      state.accounts.byId.riskProfile.accountCorporateNaturalEntities,
    isFetchingCorporateForAccount:
      state.accounts.byId.riskProfile.isFetchingCorporateForAccount,
    riskScoringFormOptions:
      state.accounts.byId.riskProfile.riskScoringFormOptions,
    isFetchingRiskScoringOptions:
      state.accounts.byId.riskProfile.isFetchingRiskScoringOptions,
    isFetchingRiskProfileInfo:
      state.accounts.byId.riskProfile.isFetchingRiskProfileInfo,
    eddDocuments: state.accounts.byId.riskProfile.eddDocuments,
    removedEddDocuments: state.accounts.byId.riskProfile.removedEddDocuments,
    accountInfo: state.accounts.byId.info,
    customerTypeInfo: getCustomerType(state),
  }),
  action,
  CorporateRiskProfileDisplay,
)
