import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import heic2any from 'heic2any'
import { StyledImage } from './Styles'
import LoadingBlock from '../LoadingBlock/index'
import ErrorToast from '../ErrorToast/index'

const HeicToImage = ({ file }) => {
  const [imageUrl, setImageUrl] = useState('')
  const [isConvertingToImage, setIsConvertingToImage] = useState(false)

  function convertHeicToImage(fileData) {
    try {
      setIsConvertingToImage(true)
      heic2any({ blob: fileData, toType: 'image/png', quality: 1 }).then(
        newImage => {
          newImage.name = `${fileData.name.split('.')[0]}.png`
          const url = URL.createObjectURL(newImage)
          setImageUrl(url)
          setIsConvertingToImage(false)
        },
      )
    } catch (error) {
      setIsConvertingToImage(false)
      ErrorToast('Error occurred when previewing HEIC file')
    }
  }
  useEffect(() => {
    convertHeicToImage(file)
    return () => {
      setImageUrl('')
    }
  }, [])

  return (
    <>
      {isConvertingToImage ? (
        <LoadingBlock message="Previewing HEIC file..." />
      ) : (
        <StyledImage style={{ width: '100%' }} src={imageUrl} alt="file" />
      )}
    </>
  )
}

HeicToImage.propTypes = {}

export default HeicToImage
