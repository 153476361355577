import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_CURRENCIES_PAIRS_START = 'FETCH_CURRENCIES_PAIRS_START'
export const FETCH_CURRENCIES_PAIRS_SUCCESS = 'FETCH_CURRENCIES_PAIRS_SUCCESS'
export const FETCH_CURRENCIES_PAIRS_ERROR = 'FETCH_CURRENCIES_PAIRS_ERROR'

export const FETCH_CURRENCY_PAIRS_START = 'FETCH_CURRENCY_PAIRS_START'
export const FETCH_CURRENCY_PAIRS_SUCCESS = 'FETCH_CURRENCY_PAIRS_SUCCESS'
export const FETCH_CURRENCY_PAIRS_ERROR = 'FETCH_CURRENCY_PAIRS_ERROR'

export const UPDATE_CURRENCY_LIMIT_START = 'UPDATE_CURRRENCY_LIMIT_START'
export const UPDATE_CURRENCY_LIMIT_SUCCESS = 'UPDATE_CURRRENCY_LIMIT_SUCCESS'
export const UPDATE_CURRENCY_LIMIT_ERROR = 'UPDATE_CURRRENCY_LIMIT_ERROR'

export const UPDATE_CURRENCY_PAIR_ORDER_TYPES =
  'UPDATE_CURRENCY_PAIR_ORDER_TYPES'

export const UPDATE_CURRENCY_EXCHANGE_START = 'UPDATE_CURRRENCY_EXCHANGE_START'
export const UPDATE_CURRENCY_EXCHANGE_SUCCESS =
  'UPDATE_CURRRENCY_EXCHANGE_SUCCESS'
export const UPDATE_CURRENCY_EXCHANGE_ERROR = 'UPDATE_CURRRENCY_EXCHANGE_ERROR'

export const UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_START =
  'UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_START'
export const UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_SUCCESS =
  'UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_SUCCESS'
export const UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_ERROR =
  'UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_ERROR'

export const TOGGLE_CURRENCY_PAIR_MARGINING_START =
  'TOGGLE_CURRENCY_PAIR_MARGINING_START'
export const TOGGLE_CURRENCY_PAIR_MARGINING_SUCCESS =
  'TOGGLE_CURRENCY_PAIR_MARGINING_SUCCESS'
export const TOGGLE_CURRENCY_PAIR_MARGINING_ERROR =
  'TOGGLE_CURRENCY_PAIR_MARGINING_ERROR'

export const FETCH_POST_ONLY_MODE_DATA_START = 'FETCH_POST_ONLY_MODE_DATA_START'
export const FETCH_POST_ONLY_MODE_DATA_SUCCESS =
  'FETCH_POST_ONLY_MODE_DATA_SUCCESS'
export const FETCH_POST_ONLY_MODE_DATA_ERROR = 'FETCH_POST_ONLY_MODE_DATA_ERROR'

export const TOGGLE_POST_ONLY_MODE_START = 'TOGGLE_POST_ONLY_MODE_START'
export const TOGGLE_POST_ONLY_MODE_SUCCESS = 'TOGGLE_POST_ONLY_MODE_SUCCESS'
export const TOGGLE_POST_ONLY_MODE_ERROR = 'TOGGLE_POST_ONLY_MODE_ERROR'

export const CHECK_POST_ONLY_MODE_STATUS_START =
  'CHECK_POST_ONLY_MODE_STATUS_START'
export const CHECK_POST_ONLY_MODE_STATUS_SUCCESS =
  'CHECK_POST_ONLY_MODE_STATUS_SUCCESS'
export const CHECK_POST_ONLY_MODE_STATUS_ERROR =
  'CHECK_POST_ONLY_MODE_STATUS_ERROR'

export const FETCH_SPOT_CURRENCY_PAIRS_START = 'FETCH_SPOT_CURRENCY_PAIRS_START'
export const FETCH_SPOT_CURRENCY_PAIRS_SUCCESS =
  'FETCH_SPOT_CURRENCY_PAIRS_SUCCESS'
export const FETCH_SPOT_CURRENCY_PAIRS_ERROR = 'FETCH_SPOT_CURRENCY_PAIRS_ERROR'

export const FETCH_PREP_CURRENCY_PAIRS_START = 'FETCH_PREP_CURRENCY_PAIRS_START'
export const FETCH_PREP_CURRENCY_PAIRS_SUCCESS =
  'FETCH_PREP_CURRENCY_PAIRS_SUCCESS'
export const FETCH_PREP_CURRENCY_PAIRS_ERROR = 'FETCH_PREP_CURRENCY_PAIRS_ERROR'

export const updateCurrencyPairActivationStatus =
  (activationData) => async (dispatch) => {
    dispatch({
      type: UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_START,
    })
    try {
      const { currencyPairSymbol, activationStatus } = activationData
      const res = await getAxios().put('/update/currency/pairs', {
        currencyPairSymbol,
        active: activationStatus,
      })
      if (res.data.statusCode === 202) {
        const { data } = res.data
        dispatch({
          type: UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_SUCCESS,
          payload: data,
        })
        toast(`Successfuly updated currency pair ${currencyPairSymbol}`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        })
      }
    } catch (error) {
      toast('Currency Pair activation status update failed', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
      dispatch({
        type: UPDATE_CURRENCY_PAIR_ACTIVATION_STATUS_ERROR,
      })
    }
  }

export const toggleMarginingOnCurrencyPair =
  (marginingData) => async (dispatch) => {
    dispatch({
      type: TOGGLE_CURRENCY_PAIR_MARGINING_START,
    })
    try {
      const res = await getAxios().put(
        '/update/currency/pairs/margining',
        marginingData,
      )
      const { data } = res.data
      dispatch({
        type: TOGGLE_CURRENCY_PAIR_MARGINING_SUCCESS,
        payload: {
          data,
        },
      })

      toast(`Currency margining status updated ${data.symbol}`, {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })
    } catch (error) {
      ErrorToast(error, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
      dispatch({
        type: TOGGLE_CURRENCY_PAIR_MARGINING_ERROR,
      })
    }
  }

export const updateExchangeForCurrencyPair =
  (exchangePairData) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CURRENCY_EXCHANGE_START,
      })
      const res = await getAxios().put(
        '/update/currency/pairs/exchange',
        exchangePairData,
      )
      const { data } = res
      dispatch({
        type: UPDATE_CURRENCY_EXCHANGE_SUCCESS,
        payload: {
          symbol: data.symbol,
          exchange: data.newExchange,
        },
      })
      toast(
        `Currency pair ${data.symbol} exchange updated to ${data.newExchange}`,
        {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        },
      )
    } catch (error) {
      ErrorToast(error, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
      dispatch({
        type: UPDATE_CURRENCY_EXCHANGE_ERROR,
      })
    }
  }

export const fetchCurrenciesPairs = () => async (dispatch) => {
  let res = null
  try {
    dispatch({
      type: FETCH_CURRENCIES_PAIRS_START,
    })
    res = await getAxios().get('/admin/currency/pairs')
    const { data } = res
    dispatch({
      type: FETCH_CURRENCIES_PAIRS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_CURRENCIES_PAIRS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const getAvailableSpotCurrencyPairs = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SPOT_CURRENCY_PAIRS_START,
    })
    const { data } = await getAxios().get('/admin/currency-pairs')
    dispatch({
      type: FETCH_SPOT_CURRENCY_PAIRS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_SPOT_CURRENCY_PAIRS_ERROR,
    })
  }
}

export const getAvailableFuturesCurrencyPairs = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_PREP_CURRENCY_PAIRS_START,
    })
    const { data } = await getAxios().get('/admin/currency-pairs')
    dispatch({
      type: FETCH_PREP_CURRENCY_PAIRS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_PREP_CURRENCY_PAIRS_ERROR,
    })
  }
}

export const fetchPostOnlyModeData = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_POST_ONLY_MODE_DATA_START,
    })
    const { data } = await getAxios().get('/order-types-post-only-mode')

    dispatch({
      type: FETCH_POST_ONLY_MODE_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_POST_ONLY_MODE_DATA_ERROR,
    })
  }
}

export const togglePostOnlyMode =
  (currentCurrencyPairOrderTypesData, isInPostOnlyMode) => async (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_POST_ONLY_MODE_START,
      })
      const { data } = await getAxios().post('/order-types-post-only-mode', {
        data: currentCurrencyPairOrderTypesData,
        refreshToken: localStorage.getItem('admin_refresh_token'),
      })

      dispatch({
        type: TOGGLE_POST_ONLY_MODE_SUCCESS,
        payload: {
          updatedCurrencyPairsOrderTypesData: data.updatedOrderTypesForPairs,
          previousCurrencyPairOrderTypesDataStored:
            data.storedReferenceOfCurrencyPairOrderTypes,
          isInPostOnlyMode: data.isInPostOnlyMode,
        },
      })
      toast(
        `Successfully gone ${
          data.isInPostOnlyMode ? 'into' : 'out of'
        } post only mode. ${data.isInPostOnlyMode ? 'Restored settings' : ''}`,
        {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        },
      )
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: TOGGLE_POST_ONLY_MODE_ERROR,
      })
    }
  }

export const fetchCurrencyPairs = () => async (dispatch) => {
  let res = null
  try {
    dispatch({
      type: FETCH_CURRENCY_PAIRS_START,
    })
    res = await getAxios().get('/admin/currency-pairs')
    const { data } = res
    dispatch({
      type: FETCH_CURRENCY_PAIRS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    console.log('here on currency pairs')
    dispatch({
      type: FETCH_CURRENCY_PAIRS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateCurrencyPairLimits = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CURRENCY_LIMIT_START,
    })
    dispatch({
      type: UPDATE_CURRENCY_LIMIT_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_CURRENCY_LIMIT_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateCurrencyPairOrderTypes =
  (orderTypeData) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_CURRENCY_PAIR_ORDER_TYPES,
        payload: orderTypeData,
      })
    } catch (error) {
      ErrorToast(error)
    }
  }
