import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ShowStatus } from 'components/atoms/Generic/index'
import moment from 'moment'

import {
  setHistoricalFiatFilters,
  clearHistoricalDeposits,
} from 'redux/fiat/actions'
import FormDepositDateRange from 'forms/DateRange/index'
import IconLoading from 'components/atoms/IconLoading'
import { Actions, TablePlaceholder } from 'components/atoms/Table/styles'
import { renderInput as RenderInput } from 'forms/fields'

import Row from './row'
import { Headers } from './headers'
import { Flexbox } from 'components/layout'
import useHistoricalDeposits from './useHistoricalDeposits'

const handleHistoricalDateFilterSubmit = async (values, dispatch) => {
  const { from, to } = values
  await dispatch(setHistoricalFiatFilters({ from, to }))
}

function HistoricalDepositsContainer(props) {
  const { setHistoricalFiatFilters, clearHistoricalDeposits, historicalDeposits } = props

  const {
    filterData,
    filter,
    searching,
    handleSearch,
    fetchMore,
    toggleSelectedRow,
    useStyles,
    expandedRow,
  } = useHistoricalDeposits(setHistoricalFiatFilters, clearHistoricalDeposits,
    historicalDeposits)

  const classes = useStyles()
  const ref = useRef()

  const { data, status } = props.historicalDeposits

  const returnEndMessage = deposits => {
    if (
      deposits.data &&
      deposits.data.length === 0 &&
      deposits.status === 'done'
    ) {
      return <ShowStatus>No Deposits found</ShowStatus>
    }
    if (deposits.status !== 'loading') {
      return <ShowStatus>No more deposits to show</ShowStatus>
    }
    if (!deposits.hasMoreRecords) {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait
          </ShowStatus>
        </TablePlaceholder>
      )
    }
  }

  const {
    historicalFilters: { from, to },
  } = props

  return (
    <div>
      <Flexbox direction={'row'} >
        <FormDepositDateRange
          initialValues={{
            from: from
              ? moment(from).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
            to: to
              ? moment(to).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
          }}
          onSubmit={handleHistoricalDateFilterSubmit}
        />
        <Actions justify="flex-end" margin="10px 0">
          <RenderInput
            value={filter}
            marginRight={0}
            onChange={(event) => handleSearch(event.target.value)}
            meta={{}}
            label={'Filter'}
          />
        </Actions>
      </Flexbox>
      <div ref={ref} className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <InfiniteScroll
              dataLength={data ? data.length : 0}
              next={fetchMore}
              useWindow={false}
              hasMore={props.historicalDeposits.hasMoreRecords}
              loader={
                status === 'loading' ? (
                  <TablePlaceholder>
                    <ShowStatus>
                      <IconLoading />
                      Loading...
                    </ShowStatus>
                  </TablePlaceholder>
                ) : (
                  <></>
                )
              }
              endMessage={returnEndMessage(props.historicalDeposits)}
              height={700}
            >

              <Table
                className={classes.table}
                aria-label="sticky table"
                stickyHeader={true}
              >
                <Headers />
                {!searching && (
                  <TableBody id="test">
                    {filterData(filter, data).map((deposit, i) => (
                      <Row key={i}
                        deposit={deposit}
                        expandedRow={expandedRow}
                        toggleSelectedRow={toggleSelectedRow}
                      />

                    ))}
                  </TableBody>
                )}
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  historicalDeposits: state.fiat.historicalDeposits,
  historicalFilters: state.fiat.historicalFilters,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setHistoricalFiatFilters,
      clearHistoricalDeposits,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricalDepositsContainer)
