import React from 'react'
import { List } from '@material-ui/core'

import { NavItem } from './interfaces'
import { NestedNavMenuItem } from './NestedNavMenuItem'

import { Collapse } from '../styles'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/compliance/sanctions',
    listItemText: 'Sanctions',
    selectedPathName: '/compliance/sanctions',
    routeAccess: 'compliance',
  },
  {
    route: '/compliance/risk-profiles/management/risk-scoring/individual',
    listItemText: 'Individual Risk Profile Management',
    selectedPathName:
      '/compliance/risk-profiles/management/risk-scoring/individual',
    routeAccess: 'compliance',
  },
  {
    route: '/compliance/risk-profiles/management/risk-scoring/corporate',
    listItemText: 'Corporate Risk Profile Management',
    selectedPathName:
      '/compliance/risk-profiles/management/risk-scoring/corporate',
    routeAccess: 'compliance',
  },
]

const NestedComplianceNavigationItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) => {
  const flagOn = useFeatureFlagCheck('riskBasedApproach')
  return pathname.includes('/compliance') ? (
    <Collapse
      in={selectedPathName.includes('compliance')}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        <>
          {nestedNavigationItems
            .filter(
              (navItem) =>
                !(
                  navItem.selectedPathName === 'compliance/risk-profiles' &&
                  !flagOn
                ),
            )
            .map((nestedNavItem, index) => (
              <NestedNavMenuItem
                key={index}
                handleListItemClick={handleListItemClick}
                navItemData={nestedNavItem}
                pathname={pathname}
              />
            ))}
        </>
      </List>
    </Collapse>
  ) : null
}

export default NestedComplianceNavigationItems
