import styled from 'styled-components'
import {
  TableRow,
  Typography
} from '@material-ui/core'
import { BLUE, VALR_BLUE_GRAY } from 'theme/colors'


export const StyledExtraDataProperty = styled.span`
  font-weight: bold;
`

export const StyledTableRow = styled(TableRow)`
cursor: pointer;
height: ${({ height }) => height};
background-color: ${({ backgroundColor }) => backgroundColor};
:hover {
  background-color: ${VALR_BLUE_GRAY};
}
`

export const StyledDetailsHeading = styled(Typography)`
color: ${({ color }) => color || BLUE};
margin-top: 18px;
`
