import {
  FETCH_KYC_PENDING_LIST_PROCESS,
  FETCH_KYC_PENDING_LIST_SUCCESS,
  FETCH_KYC_PENDING_LIST_ERROR,
} from './actions'

const initialState = {
  status: 'pristine',
  list: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KYC_PENDING_LIST_PROCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_KYC_PENDING_LIST_SUCCESS: {
      const { payload } = action
      return {
        status: 'done',
        list: payload,
      }
    }
    case FETCH_KYC_PENDING_LIST_ERROR: {
      const { error } = action.payload
      return {
        status: 'error',
        error,
      }
    }
    default:
      return state
  }
}
