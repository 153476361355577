import React from 'react'

import { Flexbox } from 'components/layout'
import { FormTitle } from 'components/atoms/Form'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Block } from './styles'

const TwilioCountryRow = (props) => {
  const {
    type,
    item,
    item: {
      from,
      to,
      status,
      errorCode,
      errorMessage,
      sid,
      dateCreated
    },
    setHighlightedSid,
    isHighlighted,
  } = props

  const titleCase = str => {
    let spacedCamel = str
      .replace(/\W+/g, ' ')
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    spacedCamel = spacedCamel.toLowerCase()
    spacedCamel =
      spacedCamel.substring(0, 1).toUpperCase() +
      spacedCamel.substring(1, spacedCamel.length)
    return spacedCamel
  }

  const keys = Object.keys(item)

  return (
    <>
      <TableRow  onClick={() => setHighlightedSid(sid)} style={{ backgroundColor: isHighlighted && '#4569eb', color: isHighlighted && '#FFFFFF' }}>
        <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>{from}</TableCell>
        <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>{to}</TableCell>
        <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>
          {status}
        </TableCell>
        <TableCell width={`${type === 'SMS' ? '15%' : '25%'}`}>
        {moment(dateCreated).format('YYYY-MMM-DD HH:mm')}
        </TableCell>
        {type === 'SMS' && errorCode && (
          <>
            <TableCell width={'15%'}>
              {errorCode}
            </TableCell>
            <TableCell width={'15%'}>
              {errorMessage}
            </TableCell>
          </>
        )}
      </TableRow>
      {isHighlighted && (
        <TableCell colSpan={6}>
        <Block>
          <FormTitle>Raw Data</FormTitle>
          <Block>
            {keys.map(key => (
              <Flexbox direction={'row'} justify={'flex-start'} key={key.sid} marginBottom={10}>
                <div style={{ minWidth: '200px' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    {titleCase(key)}
                  </span>
                </div>
                {typeof item[key] !== 'object' && <div style={{ overflow: 'hidden', overflowWrap: 'break-word' }}>{item[key]}</div>}
              </Flexbox>
            ))}
          </Block>
        </Block>
        </TableCell>
      )}
    </>
  )
}

export default TwilioCountryRow
