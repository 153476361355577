import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchMarginRequirements, fetchInterestAuctionConfig, fetchLiquidationSettings } from 'redux/marginSettings/actions'
import { ConfigStyle } from './styles'

class MarginConfig extends Component {
  componentDidMount() {
    this.props.fetchMarginRequirements()
    this.props.fetchInterestAuctionConfig()
    this.props.fetchLiquidationSettings()
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }

  render() {
    const { status, data } = this.props.marginRequirements
    const { interestAuctionStatus, interestAuctionData } = this.props.interestAuctionConfig
    const { liquidationSettingsStatus, liquidationSettingsData } = this.props.liquidationSettings
    return (
      <Fragment>
        <h3>Margin Requirements</h3>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && Object.entries(data).map(([key, value]) => {
          return <ConfigStyle key={key} >
            {this.removeCamelCasing(key)}: {value}
          </ConfigStyle>
        })}
        <h3>Interest Auction Config</h3>
        {interestAuctionStatus === 'loading' && <LoadingBlock />}
        {interestAuctionStatus === 'done' && Object.entries(interestAuctionData).map(([key, value]) => {
          return <>
            {typeof (value) === 'boolean' ?
              <>
                <ConfigStyle>
                  {this.removeCamelCasing(key)}: {value.toString()}
                </ConfigStyle>
              </>
              :
              <>
                <ConfigStyle>
                  {this.removeCamelCasing(key)}: {value}
                </ConfigStyle>
              </>
            }
          </>
        })}
        <h3>Liqudation Settings</h3>
        {liquidationSettingsStatus === 'loading' && <LoadingBlock />}
        {liquidationSettingsStatus === 'done' && Object.entries(liquidationSettingsData).map(([key, value]) => {
          return <ConfigStyle key={key} >
            {this.removeCamelCasing(key)}: {value}
          </ConfigStyle>
        })}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  marginRequirements: state.marginSettings.marginRequirements,
  interestAuctionConfig: state.marginSettings.interestAuctionConfig,
  liquidationSettings: state.marginSettings.liquidationSettings,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMarginRequirements,
      fetchInterestAuctionConfig,
      fetchLiquidationSettings,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarginConfig)
