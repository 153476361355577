import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { updateCryptoWithdrawals } from 'redux/crypto/actions'

export const submitCryptoWithdrawalReview = async (values, dispatch) => {
  const withdrawalId = values.withdrawalId
  const payload = {
    withdrawalId,
    status: values.status.value,
    comment: values.comment,
  }

  try {
    await getAxios().post('/crypto/withdrawals/review/update', payload)
    await dispatch(updateCryptoWithdrawals(withdrawalId))
    toast(`Crypto withdrawal ${withdrawalId} ${values.statu.value}ED`, {
      type: toast.TYPE.SUCCESS,
      autoClose: 2500,
    })
  } catch (err) {
    toast('Review update failed, ' + err.response.data.message, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
