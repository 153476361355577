import getAxios from 'helpers/axios'

const SET_TRANSFER_BUSY = 'fund/SET_TRANSFER_BUSY'
const TRANSFED = 'fund/TRANSFED'
const RESET_TRANSFER = 'fund/RESET_TRANSFER'

const initialState = {
  fromAccountId: '',
  toAccountId: '',
  amount: '',
  comment: '',
  currencySymbol: { value: 'Select', label: 'Select' },
  reasonId: { value: 'Select', label: 'Select' },
  busy: false,
  transfered: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSFED: {
      return { ...state, transfered: true }
    }
    case RESET_TRANSFER: {
      return {...initialState}
    }
    case SET_TRANSFER_BUSY: {
      return { ...state, busy: action.value }
    }
    default: {
      return state
    }
  }
}

export const transferAction = () => {
  return { type: TRANSFED }
}

export const clearForm = () => {
  return { type: RESET_TRANSFER }
}

export const Transfer = (
  amount,
  comment,
  currencySymbol,
  fromAccountId,
  reasonId,
  toAccountId,
) => async dispatch => {
  const res = await getAxios().post('/fund/internal-transfer', {
    fromAccountId,
    toAccountId,
    amount,
    currencySymbol,
    reasonId,
    additionalInfo: comment,
  })

  if (res.status === 201) {
    dispatch(transferAction())
    dispatch(clearForm())
  }
}
