import React, { useState } from 'react'

import { Details, DetailsBlockWithTitle } from '../../../../components/atoms/Details'
import { BASE } from '../../../../theme/base-units'
import { ActionButton } from '../../../../components/atoms/Table/styles'
import InitiateAllowExchangeControlChange from '../../../../forms/InitiateAllowExchangeControlChange'

enum State {
  Withdrawals = 1,
  Deposits = 2
}

const ExchangeControl = ({ accountId ,accountFiatCurrency}) => {
  const [state, setState] = useState<State | undefined>()

  const DetailsAny = (Details as any)

  const formTitle = state === State.Withdrawals
    ? 'Allow crypto withdrawals (add address book entry)'
    : 'Allow crypto deposits (generate receive address)'

  return (
    <DetailsAny marginBottom={`${BASE * 3}px`} direction={'column'}>
      <DetailsBlockWithTitle title="Exchange control">
        <ActionButton variant="contained" color="primary" onClick={() => setState(State.Withdrawals)}>Allow Crypto
          Withdrawals</ActionButton>
        <ActionButton variant="contained" color="primary" onClick={() => setState(State.Deposits)}>Allow Crypto
          Deposits</ActionButton>
        {(state === State.Withdrawals || state === State.Deposits) && (
          <InitiateAllowExchangeControlChange
            title={formTitle}
            initialValues={{
              accountId,
              action: state === State.Withdrawals ? 'ALLOW_ADD_TO_ADDRESS_BOOK' : 'ALLOW_GENERATE_RECEIVE_ADDRESS',
            }}
            onSubmitSuccess={() => setState(undefined)}
            onCancel={() => setState(undefined)}
            form="InitiateExchangeControlChange"
          />
        )}
      </DetailsBlockWithTitle>
    </DetailsAny>
  )
}

export default ExchangeControl
