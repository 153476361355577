import { fetchKycLevel, fetchKycInfo } from 'redux/kyc/actions'
import { fetchAccountAddress } from 'redux/address/actions'
import { fetchActivationStatus } from 'redux/activationStatus/actions'
import { fetchCustomerType } from 'redux/customerType/actions'
import { fetchWatchlistCheckById } from 'redux/singleAccountSanctions/actions'
import { fetchAccountRiskStatus } from 'redux/marginStatus/actions'
import { fetchAccountFraudInformation } from 'redux/accounts/actions'
import { fetchUserComments } from 'redux/userComments/actions'

export const UI_SET_ACTIVE_ID = 'ui/SET_ACTIVE_ID'

// TODO: Rename this to what it actually does and possibly redo the logic and how this is done
export const fetchExtraAccountInformation = accountId => dispatch => {
  dispatch(fetchUserComments(accountId))
  dispatch(fetchKycLevel(accountId))
  dispatch(fetchAccountAddress(accountId))
  dispatch(fetchActivationStatus(accountId))
  dispatch(fetchKycInfo(accountId))
  dispatch(fetchCustomerType(accountId))
  dispatch(fetchWatchlistCheckById(accountId))
  dispatch(fetchAccountRiskStatus(accountId))
  dispatch(fetchAccountFraudInformation(accountId))
  dispatch({
    type: UI_SET_ACTIVE_ID,
    payload: {
      id: accountId,
    },
  })
}
