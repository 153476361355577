export const formatRecommendationDisplayList = (
  recommendation: string,
): string[] => {
  const recommendationData = recommendation.split(/\n+/)
  return recommendationData
}

export const getDesignator = (actionedBy) => {
  let designation = 'Chief Operating Officer'
  if (actionedBy.includes('coby')) {
    designation = 'Anti-Money Laundering Compliance Officer'
  }
  if (actionedBy.includes('zak')) {
    designation = 'Chief Compliance Officer'
  }
  if (actionedBy.includes('nathan')) {
    designation = 'Legal Manager'
  }
  if (actionedBy.includes('neo')) {
    designation = 'Chief Executive Officer'
  }
  return designation
}

export const showRiskScoreAlertColor = (riskScoreValue: number | null) => {
  let color: string = '',
    textShadow: string = ''

  if (riskScoreValue === null) {
    return {
      color,
      textShadow,
    }
  }
  if (riskScoreValue <= 1.9) {
    color = 'green'
  }
  if (riskScoreValue > 1.9 && riskScoreValue <= 2.5) {
    color = 'yellow'
    textShadow = '1px 1px 5px black'
  }
  if (riskScoreValue > 2.5 && riskScoreValue <= 2.9) {
    color = 'orange'
  }
  if (riskScoreValue > 2.9) {
    color = 'red'
  }
  return { color, textShadow }
}

export const checkIfAccountIsCorporate = (customerTypeInfo) => {
  let isCorporateAccount = false
  if (customerTypeInfo) {
    if (customerTypeInfo.data.type === 'CORPORATE') {
      isCorporateAccount = true
    }
  }
  return isCorporateAccount
}
