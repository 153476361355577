import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchKYCPendingList } from 'redux/kyc/actions'
import { getKYCPendingList } from 'redux/selectors/kyc'
import PendingView from './PendingView'

const mapStateToProps = state => ({
  pending: getKYCPendingList(state),
})

const mapDispatchToProps = dipatch =>
  bindActionCreators(
    {
      fetchList: fetchKYCPendingList,
    },
    dipatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingView)
