export const COMPATITION_DETAILS_PROCCESS = 'COMPATITION_DETAILS_PROCCESS'
export const COMPATITION_DETAILS_SUCCESS = 'COMPATITION_DETAILS_SUCCESS'
export const COMPATITION_DETAILS_ERROR = 'COMPATITION_DETAILS_ERROR'
export const COMPATITION_DETAILS_BY_ID_PROCCESS =
  'COMPATITION_DETAILS_BY_ID_PROCCESS'
export const COMPATITION_DETAILS_BY_ID_SUCCESS =
  'COMPATITION_DETAILS_BY_ID_SUCCESS'
export const COMPATITION_DETAILS_BY_ID_ERROR = 'COMPATITION_DETAILS_BY_ID_ERROR'
export const PAST_COMPETITITONS_PROCCESS = 'PAST_COMPETITITONS_PROCCESS'
export const PAST_COMPETITITONS_SUCCESS = 'PAST_COMPETITITONS_SUCCESS'
export const PAST_COMPETITITONS_ERROR = 'PAST_COMPETITITONS_ERROR'
export const PAST_COMPETITITONS_BY_ID_PROCCESS =
  'PAST_COMPETITITONS_BY_ID_PROCCESS'
export const PAST_COMPETITITONS_BY_ID_SUCCESS =
  ' PAST_COMPETITITONS_BY_ID_SUCCESS'
export const PAST_COMPETITITONS_BY_ID_ERROR = ' PAST_COMPETITITONS_BY_ID_ERROR'
export const COMPETITION_LOADING_UPDATE = 'COMPETITION_LOADING_UPDATE'

export const REJECT_INTERNAL_TRANSFER_BATCH_START =
  'REJECT_INTERNAL_TRANSFER_BATCH_START'
export const REJECT_INTERNAL_TRANSFER_BATCH_SUCCESS =
  'REJECT_INTERNAL_TRANSFER_BATCH_SUCCESS'
export const REJECT_INTERNAL_TRANSFER_BATCH_ERROR =
  'REJECT_INTERNAL_TRANSFER_BATCH_ERROR'

const initialState = {
  status: 'pristine',
  isRejectingInternalTransferBatch: false,
  data: [],
  activeWinnersById: {
    status: 'pristine',
    data: [],
  },
  pastCompetitions: {
    status: 'pristine',
    data: [],
  },
  pastWinnersById: {
    status: 'pristine',
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPATITION_DETAILS_PROCCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }

    case COMPATITION_DETAILS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case COMPATITION_DETAILS_ERROR: {
      return {
        ...state,
        data: [],
      }
    }

    case COMPATITION_DETAILS_BY_ID_PROCCESS: {
      return {
        ...state,
        activeWinnersById: {
          status: 'loading',
          data: [],
        },
      }
    }

    case COMPATITION_DETAILS_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        activeWinnersById: {
          status: 'done',
          data,
        },
      }
    }

    case COMPATITION_DETAILS_BY_ID_ERROR: {
      return {
        ...state,
        activeWinnersById: {
          status: 'error',
          data: [],
        },
      }
    }

    case PAST_COMPETITITONS_PROCCESS: {
      return {
        ...state,
        pastCompetitions: {
          status: 'loading',
          data: [],
        },
      }
    }

    case PAST_COMPETITITONS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        pastCompetitions: {
          status: 'done',
          data,
        },
      }
    }

    case PAST_COMPETITITONS_ERROR: {
      const { data } = action.payload
      return {
        ...state,
        pastCompetitions: {
          status: 'error',
          data: [],
        },
      }
    }

    case PAST_COMPETITITONS_BY_ID_PROCCESS: {
      return {
        ...state,
        pastWinnersById: {
          status: 'loading',
          data: [],
        },
      }
    }

    case PAST_COMPETITITONS_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        pastWinnersById: {
          status: 'done',
          data,
        },
      }
    }

    case PAST_COMPETITITONS_BY_ID_ERROR: {
      return {
        ...state,
        pastWinnersById: {
          status: 'error',
          data: [],
        },
      }
    }

    case COMPETITION_LOADING_UPDATE: {
      const {
        payload: { id },
      } = action
      const newData = state.data.map(competition => {
        if (competition.id === id) {
          competition.loading = true
        }
        return competition
      })
      return {
        ...state,
        data: newData,
      }
    }

    case REJECT_INTERNAL_TRANSFER_BATCH_START: {
      return {
        ...state,
        isRejectingInternalTransferBatch: true,
      }
    }

    case REJECT_INTERNAL_TRANSFER_BATCH_SUCCESS: {
      const updatedInternalTransferBatches = state.data.filter(
        internalTransferBatch => internalTransferBatch.id !== action.payload,
      )
      return {
        ...state,
        data: updatedInternalTransferBatches,
        isRejectingInternalTransferBatch: false,
      }
    }

    case REJECT_INTERNAL_TRANSFER_BATCH_ERROR: {
      return {
        ...state,
        isRejectingInternalTransferBatch: false,
      }
    }
    default:
      return state
  }
}
