import React, { Fragment, Component } from 'react'
import {
  hasAccountEditorRole,
  hasNameSurnameChangeRole,
} from 'helpers/roleBasedAccess'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import InitiateNameAndSurnameChangeForm from 'forms/InitiateNameAndSurnameChange'
import auth from 'services/Auth'

class InitiateNameAndSurnameChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const { id, oldName, oldSurname } = this.props
    const notAuthorized = !hasNameSurnameChangeRole()
    return (
      <Fragment>
        <Fragment>
          {!formShown && (
            <MuiButton
              disabled={notAuthorized}
              onClick={this.toggleForm}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
            >
              Change Name/Surname
            </MuiButton>
          )}
        </Fragment>
        {formShown && (
          <InitiateNameAndSurnameChangeForm
            initialValues={{
              accountId: id,
              type: 'NAME_SURNAME_CHANGE',
              newName: oldName,
              newSurname: oldSurname,
              oldName,
              oldSurname,
              reason: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
            }}
            form={'ChangeNameAndSurname'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}
export default InitiateNameAndSurnameChange
