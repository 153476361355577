import React, { FC, Fragment, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import socket from 'redux/socket/index'
import { connect } from 'react-redux'
import { StyledTableRow } from 'components/atoms/CustomTable/Styles'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { bindActionCreators } from 'redux'
import {
  fetchMobileCampaigns,
  toggleCreateMarketingCampaignForm,
  retrySendMobileNotificationsToUsers,
  updateMobileNotificationCampaign,
  sendMobileNotificationsToUsers,
} from 'redux/mobileNotifications/actions'
import { MobileNotificationsInitialState } from 'redux/mobileNotifications'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import SimpleModal from 'components/organisms/Modal'
import { Spacer } from 'components/atoms/Generic'
import { MobileNotificationStatuses } from 'interfaces/mobileNotifications'
import { CheckCircle } from '@material-ui/icons'
import CircularSpinner from 'components/atoms/Spinners'
import MobileNotificationsMessageForm from './MobileNotificationsMessageForm'
import { disconnectSocket } from '../../../../../redux/fiat/actions'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'
import ErrorToast from 'components/molecules/ErrorToast'
import { getIdsFromCsv } from './consts'
import { renderPlural } from 'helpers/utils'
import MobileCampaign from './MobileCampaign'

interface MobileNotificationsProps {
  fetchMobileCampaigns: () => Promise<void>
  toggleCreateMarketingCampaignForm: (value: boolean) => Promise<void>
  retrySendMobileNotificationsToUsers: (campaignId: number) => Promise<void>
  updateMobileNotificationCampaign: (campaign: any) => Promise<void>
  sendMobileNotificationsToUsers: (campaign: any) => Promise<void>
  mobileNotifications: MobileNotificationsInitialState
}
interface MarketingCampaign {
  userIds: string
  scheduledDateTime?: {
    realDateTime: string
  }
  mobilePageUrl: string
  campaignName: string
  messageTitle: string
  message: string
  notificationType: 'SMS' | 'PUSH'
}

const MobileNotifications: FC<MobileNotificationsProps> = (props) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [mobileMessagingCampaignToSubmit, setMobileMessagingCampaignToSubmit] =
    useState<MarketingCampaign | null>(null)
  const { mobileNotifications } = props

  const { fetchingMobileNotifications, marketingMessagingCampaignFormOpen } =
    mobileNotifications

  const checkIfCanResubmit = (
    status: MobileNotificationStatuses,
    campaignId: number,
  ) => {
    let action = <Fragment />
    switch (status) {
      case 'COMPLETED':
        action = <CheckCircle style={{ color: 'green' }} />
        break
      case 'FAILED':
        action = (
          <MuiButton
            onClick={() =>
              props.retrySendMobileNotificationsToUsers(campaignId)
            }
          >
            Retry
          </MuiButton>
        )
        break

      default:
        action = <CircularSpinner size={20} color="" />
        break
    }
    return action
  }

  const idsFromCsv = getIdsFromCsv(
    (mobileMessagingCampaignToSubmit &&
      mobileMessagingCampaignToSubmit.userIds) ||
      '',
  ).accountIds

  const handleProceedSendMobileNotificationsToUsers = async () => {
    if (mobileMessagingCampaignToSubmit) {
      await props.sendMobileNotificationsToUsers({
        ...mobileMessagingCampaignToSubmit,
        userIds: idsFromCsv,
      })
      props.toggleCreateMarketingCampaignForm(false)
    } else {
      ErrorToast(
        'Something is wrong with the mobile messaging data. Please try again.',
      )
    }
    setMobileMessagingCampaignToSubmit(null)
    setShowConfirmationModal(false)
  }

  useEffect(() => {
    socket.connect()
    socket.on('UPDATE_MOBILE_NOTIFICATION_CAMPAIGN', (socketData) => {
      props.updateMobileNotificationCampaign(socketData)
    })
    props.fetchMobileCampaigns()

    return () => {
      props.toggleCreateMarketingCampaignForm(false)
      disconnectSocket()
    }
  }, [])

  return (
    <>
      <Spacer margin="20px 0px">
        <MuiButton
          onClick={() => props.toggleCreateMarketingCampaignForm(true)}
        >
          Create New Messaging Campaign
        </MuiButton>
      </Spacer>
      {fetchingMobileNotifications === 'pending' && <LoadingBlock />}
      {fetchingMobileNotifications === 'done' &&
        props.mobileNotifications.mobileCampaigns.length === 0 && (
          <TablePlaceholder>No Mobile Campaigns</TablePlaceholder>
        )}
      {props.mobileNotifications.mobileCampaigns.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Users</TableCell>
              <TableCell>Users Sent To</TableCell>
              <TableCell>Batches</TableCell>
              <TableCell>Notification Type</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {props.mobileNotifications.mobileCampaigns.map((campaign) => (
            <MobileCampaign
              key={campaign.id}
              {...campaign}
              canResubmit={checkIfCanResubmit(campaign.status, campaign.id)}
            />
          ))}
        </Table>
      )}
      <SimpleModal
        open={marketingMessagingCampaignFormOpen}
        handleClose={() => props.toggleCreateMarketingCampaignForm(false)}
        buttonsHidden
        width="60%"
      >
        <MobileNotificationsMessageForm
          sendToAllUsers={(values: MarketingCampaign) => {
            setMobileMessagingCampaignToSubmit({
              ...values,
              userIds: '',
            })
            setShowConfirmationModal(true)
          }}
          onSubmit={(values: MarketingCampaign) => {
            setMobileMessagingCampaignToSubmit({
              ...values,
              notificationType: values.notificationType || 'PUSH',
            })
            setShowConfirmationModal(true)
          }}
          handleClose={() => props.toggleCreateMarketingCampaignForm(false)}
        />
      </SimpleModal>
      <ConfirmationDialog
        // @ts-ignore
        disabled={
          props.mobileNotifications.sendingMobileNotifications === 'sending'
        }
        title={`Send mobile notifications to users.`}
        // @ts-ignore
        message={
          <div>
            <p>
              Are you sure you want to send mobile notifications to{' '}
              {idsFromCsv.length || ''}{' '}
              {idsFromCsv.length
                ? `user${renderPlural(idsFromCsv.length)}`
                : 'all users'}
              ?
            </p>
            <p>
              <strong>Title: </strong>{' '}
              {mobileMessagingCampaignToSubmit?.messageTitle}
            </p>
            <p>
              <strong>Type: </strong>{' '}
              {mobileMessagingCampaignToSubmit?.notificationType}
            </p>
            <p>
              <strong>Message: </strong>{' '}
              {mobileMessagingCampaignToSubmit?.message}
            </p>
            {mobileMessagingCampaignToSubmit?.scheduledDateTime ? (
              <p>
                <strong>Scheduled date time: </strong>{' '}
                {new Date(
                  mobileMessagingCampaignToSubmit?.scheduledDateTime
                    .realDateTime,
                ).toLocaleString()}
              </p>
            ) : (
              <p>
                No scheduled date and time was selected so this will run
                immediately.
              </p>
            )}
            {mobileMessagingCampaignToSubmit?.mobilePageUrl && (
              <p>
                <strong>Mobile page url: </strong>{' '}
                {mobileMessagingCampaignToSubmit?.mobilePageUrl}
              </p>
            )}
          </div>
        }
        open={showConfirmationModal}
        proceed={handleProceedSendMobileNotificationsToUsers}
        handleClose={() => setShowConfirmationModal(false)}
        button={{
          confirm: {
            text: 'Proceed',
          },
          decline: {
            text: 'Cancel',
          },
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  mobileNotifications: state.mobileNotifications,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMobileCampaigns,
      toggleCreateMarketingCampaignForm,
      retrySendMobileNotificationsToUsers,
      updateMobileNotificationCampaign,
      sendMobileNotificationsToUsers,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(MobileNotifications)
