import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchAccountLogs } from 'redux/accountLogs/actions'
import { FlexRow } from 'components/layout/index'
import ElasticsearchForm from 'forms/Elasticsearch/DatetimeFilter'
import ErrorBoundary from 'helpers/ErrorBoundary'
import LogEventView from './logEventView'
import ApplicationHttpView from './applicationHttpView'

class Logs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShow: true,
      title: '',
      skip: 0,
      limit: 100,
    }
  }

  componentDidMount() {
    const { accountId } = this.props.match.params
    this.props.fetchAccountLogs({
      id: accountId,
      skip: this.state.skip,
      limit: this.state.limit,
    })
  }

  toggleForm = () => {
    const { formShow } = this.state
    this.setState({
      formShow: !formShow,
    })
  }

  sortData = data => {
    var filtered =
      data &&
      data.filter(val => {
        var ignoreOutgoing = val.message.textPayload.match('OUTGOING') === null
        return ignoreOutgoing
      })
    return (
      filtered &&
      filtered.sort(
        (a, b) => new Date(b['@timestamp']) - new Date(a['@timestamp']),
      )
    )
  }

  render() {
    const {
      logs: {
        data,
        data: { httpLogs, eventLogs },
        status,
      },
    } = this.props.accountLogs
    const { accountId } = this.props.match.params
    return (
      <ErrorBoundary message="Logs">
        <Fragment>
          <FlexRow align={'flex-end'}>
            <ElasticsearchForm
              initialValues={{
                id: accountId,
              }}
              fetchAccountLogs={this.props.fetchAccountLogs}
            />
          </FlexRow>
          <LogEventView
            status={status}
            data={data && this.sortData(eventLogs)}
            title={'Logevent'}
            accountId={accountId}
          />
          <ApplicationHttpView
            status={status}
            data={data && this.sortData(httpLogs)}
            title={'Application-http'}
            accountId={accountId}
          />
          {status === 'loading' && <LoadingBlock />}
        </Fragment>
      </ErrorBoundary>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountLogs,
    },
    dispatch,
  )

const mapStateToProps = state => {
  return {
    accountLogs: state.accountLogs,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Logs)
