import React, { useState } from 'react'

import { TableBody, Table } from 'components/atoms/Table/styles'
import { connectStateAndDispatch } from 'store'
import {
  removeSubmittedItem as removeFundsSubmittedItem,
} from 'redux/funds/actions'
import { Headers } from './headers'
import Row from './row'

const FundsTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(
          fundsInfo =>
            <Row
              key={fundsInfo.id}
              dualAuthId={fundsInfo.id}
              fundsInfo={fundsInfo}
              isSelected={selectedRow === fundsInfo.id}
              toggleSelected={toggleSelectedRow}
              removeSubmittedItem={props.removeFundsSubmittedItem}
            />
        )}
      </TableBody>
    </Table>
  )
}

const actions = {
  removeFundsSubmittedItem,
}

export default connectStateAndDispatch(null, actions, FundsTable)
