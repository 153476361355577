import { FontColor, WelcomeLogo } from 'components/atoms/Generic'
import { FullscreenCentered } from 'components/layout'
import React from 'react'
import { PANEL_BLUE, WHITE } from 'theme/colors'

const ValrAdminScreen = ({
  message,
  title,
}: {
  message: string
  title: string
}) => (
  <FullscreenCentered style={{ textAlign: 'center' }} bgColor={PANEL_BLUE}>
    <FontColor color={WHITE}>
      <h1>{title}</h1>
      <WelcomeLogo useTheme={false} text="VALR" />
      <h3>{message}</h3>
    </FontColor>
  </FullscreenCentered>
)

export default ValrAdminScreen
