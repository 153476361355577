import React from 'react'
import { SidebarItem } from './styles'
import { handleCopyToClipBoard } from '../../../helpers/utils'

const DEVELOPER_MODE_KEY = 'developerMode'

export const CopyKeyCloakToken = () => {
  const onClick = (e) => {
    const accessToken = localStorage.getItem('admin_access_token')
    if (accessToken) {
      const textToCopy = `${e.altKey ? 'Bearer ' : ''}${accessToken}`
      handleCopyToClipBoard(textToCopy, false)
    }
  }

  if (localStorage.getItem(DEVELOPER_MODE_KEY)) {
    return (
      <SidebarItem onClick={onClick}>
        <i className={'fas fa-copy'}/>
        Copy token
      </SidebarItem>
    )
  }

  return null
}
