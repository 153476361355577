import { ContentContainer } from 'components/atoms/Generic'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useKycNavigation } from 'Hooks/Kyc/useKycNavigation'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import PendingList from './PendingList'
import Parachute from './Parachute'
import VeriffSessions from './VeriffSessions'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

const Kyc = (props) => {
  const {
    location: { pathname },
  } = props
  const pendingKycFlagOn = useFeatureFlagCheck('kycPendingList')
  const parachuteFlagOn = useFeatureFlagCheck('parachute')

  const { routes, currentPage, handleRouteChange } = useKycNavigation(pathname)
  return (
    <div>
      <TopNavigationBar
        value={currentPage}
        onChange={handleRouteChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          to={routes[0]}
          component={ForwardedNavLink}
          label="Veriff Sessions"
        />
        {pendingKycFlagOn && (
          <NavigationTab
            value={routes[1]}
            to={routes[1]}
            component={ForwardedNavLink}
            label="KYC Pending List"
          />
        )}
        {parachuteFlagOn && (
          <NavigationTab
            value={routes[2]}
            to={routes[2]}
            component={ForwardedNavLink}
            label="Parachute"
          />
        )}
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route exact path={routes[0]} component={VeriffSessions} />
          {pendingKycFlagOn && (
            <Route exact path={routes[1]} component={PendingList} />
          )}
          {parachuteFlagOn && (
            <Route exact path={routes[2]} component={Parachute} />
          )}
        </Switch>
      </ContentContainer>
    </div>
  )
}

export default Kyc
