import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { styled } from '@material-ui/core/styles'
import ErrorBoundary from 'helpers/ErrorBoundary'

import { TablePlaceholder } from 'components/atoms/Table/styles'
import { getAccountDetails } from 'redux/selectors/details'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import { Link } from './link'

class SubAccounts extends Component {
  render() {
    const Container = styled(TableContainer)({
      maxHeight: 1400,
    })
    const {
      details: { status, primaryAccountSubAccounts },
    } = this.props
    return (
      <ErrorBoundary message="Sub Accounts">
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && (
          <>
            {primaryAccountSubAccounts &&
            primaryAccountSubAccounts.length === 0 ? (
              <TablePlaceholder>{'No Subaccounts'}</TablePlaceholder>
            ) : (
              <Paper>
                <Container>
                  <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <TableHead align="right">
                      <TableRow>
                        <TableCell> Account Id</TableCell>
                        <TableCell>Subaccount Name</TableCell>
                        <TableCell>Margin Enabled</TableCell>
                        <TableCell>Futures Enabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {primaryAccountSubAccounts
                        .sort((a, b) => a.accountId - b.accountId)
                        .map((sub) => {
                          return (
                            <TableRow key={sub.accountId}>
                              <TableCell>
                                {<Link id={sub.accountId} />}
                              </TableCell>
                              <TableCell>{sub.label}</TableCell>
                              <TableCell>
                                {sub.accountStatus.marginEnabled.toString()}
                              </TableCell>
                              <TableCell>
                                {sub.accountStatus.futuresEnabled.toString()}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </Container>
              </Paper>
            )}
          </>
        )}
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  details: getAccountDetails(state),
})

export default connect(mapStateToProps, null)(SubAccounts)
