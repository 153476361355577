import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { ReduxAction } from 'interfaces/global'
import { Dispatch } from 'redux'
import { PEP_SCREENING_CHECK_STATUS, PepScreeningResult } from './interfaces'
import { toast } from 'react-toastify'

export type PEPScreeningAction =
  | 'FETCH_PEP_SCREENING_RESULTS_START'
  | 'FETCH_PEP_SCREENING_RESULTS_SUCCESS'
  | 'FETCH_PEP_SCREENING_RESULTS_ERROR'
  | 'CHECK_PEP_SCREENING_RESULTS_START'
  | 'CHECK_PEP_SCREENING_RESULTS_SUCCESS'
  | 'CHECK_PEP_SCREENING_RESULTS_ERROR'

export const fetchPEPScreeningResults =
  () =>
  async (
    dispatch: Dispatch<ReduxAction<PEPScreeningAction, PepScreeningResult[]>>,
  ) => {
    try {
      dispatch({
        type: 'FETCH_PEP_SCREENING_RESULTS_START',
      })
      const { data } = await getAxios().get('/pep-screening')
      dispatch({ type: 'FETCH_PEP_SCREENING_RESULTS_SUCCESS', payload: data })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'FETCH_PEP_SCREENING_RESULTS_ERROR' })
    }
  }

export const checkPEPScreeningResult =
  (
    pepScreeningId: number,
    checkStatus: PEP_SCREENING_CHECK_STATUS,
    comment: string,
  ) =>
  async (
    dispatch: Dispatch<ReduxAction<PEPScreeningAction, PepScreeningResult[]>>,
  ) => {
    try {
      dispatch({
        type: 'CHECK_PEP_SCREENING_RESULTS_START',
      })
      const { data } = await getAxios().post('/pep-screening/check-status', {
        pepScreeningId,
        checkStatus,
        comment,
      })
      dispatch({ type: 'CHECK_PEP_SCREENING_RESULTS_SUCCESS', payload: data })
      toast('Updated check status for PEP screening result.', {
        type: toast.TYPE.SUCCESS,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: 'CHECK_PEP_SCREENING_RESULTS_ERROR' })
    }
  }
