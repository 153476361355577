import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import IconLoading from 'components/atoms/IconLoading'
import {
  TablePlaceholder,
  NotesGrid,
} from 'components/atoms/Table/styles'
import moment from 'moment'
import {
  FormLikeContainer,
} from 'components/atoms/Form'
import { NotesStyle } from './Styles'

class WithdrawalNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      prevDepositId: '',
    }
  }

  showWithdrawalNotes = (data) => data.map((res, i) => {
    const { date, notes, createdBy } = res
    return (
      <NotesGrid key={`#${i++}`}>
        <NotesStyle >
          {moment(date).format('YYYY-MMM-DD HH:mm')}
        </NotesStyle>
        <NotesStyle > {notes} </NotesStyle>
        <NotesStyle > {createdBy} </NotesStyle>
      </NotesGrid>
    )
  })

  render() {
    const {
      comments: { withdrawalComments, status },
    } = this.props
    return (
      <FormLikeContainer>
        {status === 'loading' && (
          <TablePlaceholder>
            <IconLoading />
            <span>Loading, please wait</span>
          </TablePlaceholder>)}
        {status === 'done' && withdrawalComments.length === 0 &&
          <TablePlaceholder >{'No notes yet for this withdrawal'}</TablePlaceholder>
        }
        {status === 'done' && withdrawalComments.length > 0 &&
          <NotesGrid>
            <NotesStyle >Date</NotesStyle>
            <NotesStyle >Notes</NotesStyle>
            <NotesStyle >Created By</NotesStyle>
          </NotesGrid>
        }
        {status === 'done' && withdrawalComments.length > 0 && this.showWithdrawalNotes(withdrawalComments)}
      </FormLikeContainer>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetForm: () => reset('withdrawalNotesForm'),
    },
    dispatch,
  )
export default connect(
  null, mapDispatchToProps
)(WithdrawalNotes)
