import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { BLUE, VALR_BLUE_GRAY } from 'theme/colors'

export const StyledErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  > svg {
    height: 50px;
    width: 50px;
  }
`

export const StyledSanctionCorporateContainer = styled.div`
  max-height: 400px;
  overflow: scroll;
`
export const StyledSanctionCorporateTable = styled(Table)``
export const StyledSanctionCorporateHead = styled(TableHead)`
  background-color: #dde7ff;
  position: sticky;
  top: 0px;
`

export const StyledSanctionCorporateRow = styled(TableRow)`
  :hover {
    cursor: pointer;
  }
`
export const StyledSanctionCorporateCollapseCell = styled(TableCell)`
  padding: 0px;
`
export const StyledEntityMatchedToWrapper = styled.div`
  padding: 12px;
  background-color: ${({ theme }) =>
    theme.corporateSanctions.matchedToDataBackground};
  border-radius: 8px;
  margin-bottom: 8px;
`
export const StyledEntityMatchedHeading = styled.h2`
  color: ${BLUE};
`

export const StyledMatchedToSanctionsListWrapper = styled.div`
  max-height: 350px;
  overflow: scroll;
  border: 1px solid ${VALR_BLUE_GRAY};
  border-radius: 8px;
  padding: 8px;
`
export const StyledMatchedToSanctionsListHeading = styled.div`
  position: sticky;
  top: -8px;
  background-color: ${({ theme }) =>
    theme.corporateSanctions.matchedToSanctionsHeading};
  padding: 12px;
`

export const StyledViewReasonsButton = styled.div`
  display: flex;
  align-items: center;
`
