import React, { Component, Fragment } from 'react'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'

import ActiveCompatition from './ActiveCompetitions'
import PastCompatitions from './PastCompetitions'

class CompetitionDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showActiveCompetitionUsers: [],
      showForm: true,
      currentTab: 'pending',
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ currentTab: newValue })
  }

  render() {
    const { currentTab } = this.state
    return (
      <Fragment>
        <TopNavigationBar
          value={currentTab}
          onChange={this.handleChange}
          variant="fullWidth"
        >
          <NavigationTab label='Pending Transfers' value="pending" />
          <NavigationTab label='Processed Transfers' value="processed" />
        </TopNavigationBar>
        {currentTab === 'pending' &&
          <ActiveCompatition
          />
        }
        {currentTab === 'processed' &&
          <PastCompatitions />
        }
      </Fragment>
    )
  }
}

export default (CompetitionDetails)
