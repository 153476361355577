import React, { FC } from 'react'
import { Text, View } from '@react-pdf/renderer'

import { styles } from './Styles'

import { EddReportApproval } from '../../../../interfaces/riskProfile'
import { getDesignator } from './helpers'

interface ApprovalsSectionProps {
  eddReportApprovals: EddReportApproval[]
}
const ApprovalsSection: FC<ApprovalsSectionProps> = ({
  eddReportApprovals,
}) => (
  <View style={styles.table}>
    <View style={styles.tableRow}>
      <Text style={styles.approvalsTableCellHeading}>Designation</Text>
      <Text style={styles.approvalsTableCellHeading}>Approval</Text>
      <Text style={styles.approvalsTableCellHeading}>Date</Text>
    </View>
    {eddReportApprovals.map((approval) => {
      const createdAt = approval.createdAt
        ? new Date(approval.createdAt).toString()
        : '--'
      return (
        <View key={approval.actionedBy} style={styles.tableRow}>
          <Text style={styles.approvalsTableCell}>
            {getDesignator(approval.actionedBy)}
          </Text>
          <Text style={styles.approvalsTableCell}>{approval.actionedBy}</Text>
          <Text style={styles.approvalsTableCell}>{createdAt}</Text>
        </View>
      )
    })}
  </View>
)

export default ApprovalsSection
