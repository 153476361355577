import React, { useEffect, useMemo, useState } from 'react'
import DepositFundingInfo from 'components/organisms/DepositFundingInfo/index'
import { Header, Spacer } from 'components/atoms/Generic/index'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchDepositByStatus,
  clearFundingDepositByStatus,
  fetchFundingEventForReview,
} from 'redux/fundingEvents/actions'
import { getFiatConfig } from '../../redux/fiat/selectors'
import ErrorBoundary from '../../helpers/ErrorBoundary'
import SearchDepositsByStatus from 'components/organisms/DepositFundingInfo/DepositFundingFilter/index'
import DepositsByStatus from 'components/organisms/DepositFundingInfo/DepositFundingFilter/DepositsByStatus'
import styled from 'styled-components'
import { Flexbox } from 'components/layout'
import { Button } from 'components/atoms/Form'

import { Title } from 'components/atoms/Table/styles'

const FundingInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FundingInfo = ({
  fundingInfo,
  currencies,
  refresh,
  clearFundingDepositByStatus,
  fundingEventsByStatus,
  fetchDepositByStatus,
}) => {
  const [currency, setCurrency] = useState<string>('USD')
  const [selectedOption, setSelectedOption] = useState('')

  const handleChange = (value) => {
    try {
      clearFundingDepositByStatus()
      setSelectedOption(value)
      fetchDepositByStatus(currency, value)
    } catch (error) {
      console.log('error', error)
    }
  }

  const clearFilter = () => {
    setSelectedOption('')
  }

  const loading = useMemo(
    () => fundingInfo?.status === 'loading',
    [fundingInfo],
  )

  useEffect(() => {
    refresh(currency)
  }, [currency])

  const handleClick = (e) => {
    setCurrency(e)
  }

  return (
    <ErrorBoundary message="Funding Info">
      <Flexbox justify={'flex-start'}>
        <Title>Currencies :</Title>
      </Flexbox>
      <FundingInfoContainer>
        <Flexbox>
          {currencies.map((c) => {
            return (
              <Spacer margin="3px 12px 0px 0px">
                <Button
                  onClick={() => {
                    handleClick(c.shortName)
                  }}
                  disabled={currency === c.shortName || loading}
                  style={{
                    backgroundColor: currency === c.shortName ? 'green' : '',
                  }}
                >
                  {c.longName}
                </Button>
              </Spacer>
            )
          })}
        </Flexbox>
        <SearchDepositsByStatus
          currency={currency}
          currencySelection={currency}
          handleChange={handleChange}
          clearFilter={clearFilter}
          selectedOption={selectedOption}
        />
      </FundingInfoContainer>
      {selectedOption && (
        <DepositsByStatus
          selectedOption={selectedOption}
          currency={currency}
          fundingEventsByStatus={fundingEventsByStatus}
        />
      )}
      <Header title={'Funding Info'} />
      <DepositFundingInfo currency={currency} />
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  fundingInfo: state.fundingEvents.fundingInfo,
  currencies: getFiatConfig(state).currencies,
  fundingEventsByStatus: state.fundingEvents.fundingEventsByStatus,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      refresh: fetchFundingEventForReview,
      fetchDepositByStatus,
      clearFundingDepositByStatus,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(FundingInfo)
