import { makeStyles } from '@material-ui/core/styles'

const descendingComparator = (a, b, orderBy) => {
  let valueA
  let valueB
  {
    b[orderBy] === undefined ? (valueB = -1) : (valueB = b[orderBy])
  }
  {
    a[orderBy] === undefined ? (valueA = -1) : (valueA = a[orderBy])
  }
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const searchAccountTableSort = (array, order, orderBy) => {
  return array.sort((a, b) => {
    const aVal = a.info[orderBy]
    const bVal = b.info[orderBy]
    if (!isNaN(aVal)) {
      a.info[orderBy] = parseFloat(aVal)
    }
    if (!isNaN(bVal)) {
      b.info[orderBy] = parseFloat(bVal)
    }
    const ordered = getComparator(order, orderBy)(a.info, b.info)
    if (ordered !== 0) return ordered
  })
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))
