import { Collapse, TableCell, TableRow } from '@material-ui/core'
import React, { Dispatch, FC, Fragment, SetStateAction } from 'react'
import { StyledExpandTableCell, StyledExpandedSectionContainer } from './Styles'
import { JsonBlock } from 'components/atoms/Details'
import { OpenFuturesPosition } from 'interfaces/openFuturesPositions'

interface IOpenFuturesPosition {
  openFuturesPosition: OpenFuturesPosition
  setExpandedItem: Dispatch<SetStateAction<number | null>>
  expandedItem: number | null
}

const OpenFuturesPositionRow: FC<IOpenFuturesPosition> = ({
  openFuturesPosition,
  setExpandedItem,
  expandedItem,
}) => {
  return (
    <Fragment key={openFuturesPosition.id}>
      <TableRow
        onClick={() =>
          setExpandedItem((prev) =>
            prev === openFuturesPosition.id ? null : openFuturesPosition.id,
          )
        }
      >
        <TableCell>{openFuturesPosition.pair}</TableCell>
        <TableCell>{String(openFuturesPosition.side)}</TableCell>
        <TableCell>{openFuturesPosition.quantity}</TableCell>
        <TableCell>{openFuturesPosition.realisedPnl}</TableCell>
        <TableCell>{openFuturesPosition.unrealisedPnl}</TableCell>
        <TableCell>{openFuturesPosition.totalSessionEntryQuantity}</TableCell>
        <TableCell>{openFuturesPosition.createdAt}</TableCell>
        <TableCell>
          {new Date(openFuturesPosition.updatedAt).toISOString()}
        </TableCell>
      </TableRow>
      <TableRow className="no-hover">
        <StyledExpandTableCell colSpan={10}>
          <Collapse
            in={expandedItem === openFuturesPosition.id}
            timeout="auto"
            unmountOnExit
          >
            <StyledExpandedSectionContainer>
              <JsonBlock src={openFuturesPosition} title={'Raw Data'} />
            </StyledExpandedSectionContainer>
          </Collapse>
        </StyledExpandTableCell>
      </TableRow>
    </Fragment>
  )
}

export default OpenFuturesPositionRow
