import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components/atoms/IconButton/index'
import LinearLoaderWithValueLabel from 'components/atoms/Loaders/LinearProgressWithLabel/index'
import CircularSpinner from 'components/atoms/Spinners/index'
import { convertToString } from 'helpers/utils'
import HeicToImage from 'components/molecules/FilesUploader/HeicToImage'
import {
  StyledDocumentCard,
  StyledDocumentCardHeader,
  StyledDocumentCardImage,
  StyledDocumentCardProperty,
  StyledDocumentOuter,
} from './Styles'
import {
  StyledUploadErrorMessage,
  StyledUploadSuccessMessage,
} from '../../../components/Styles'
import { FILE_TYPES } from '../../../const/Entities'

const DocumentCard = ({ index, document, handleRemoveDocument }) => {
  if (!document.file) return <p>Something went wrong with this file content</p>
  const { file, metaData } = document
  const {
    documentType,
    uploadState: { progress, uploadSuccessMessage, uploadErrorMessage },
  } = metaData
  const fileData = URL.createObjectURL(file)

  const showLoader = uploadProgress => {
    if (uploadSuccessMessage) return ''
    switch (uploadProgress) {
      case 100:
        return <CircularSpinner color="#13ab18" />

      default:
        return <LinearLoaderWithValueLabel progress={uploadProgress} />
    }
  }

  function previewSelectedPdfFile(src) {
    return <embed src={`${src}#toolbar=0`} width="100%" height="350" />
  }

  const renderImage = useCallback(
    fileType => {
      if (fileType === FILE_TYPES.HEIC) return <HeicToImage file={file} />
      if (file.type === FILE_TYPES.DOCX) return <p>{file.name} selected</p>
      if (file.type === FILE_TYPES.PDF) return previewSelectedPdfFile(fileData)
      return <StyledDocumentCardImage src={fileData} alt="file" />
    },
    [file],
  )

  return (
    <StyledDocumentOuter>
      <span>{index + 1}.</span>
      <StyledDocumentCard>
        <StyledDocumentCardHeader>
          <IconButton
            width="50px"
            height="50px"
            icon={<span>x</span>}
            type="button"
            onClick={() => handleRemoveDocument(index)}
          />
        </StyledDocumentCardHeader>
        {renderImage(file.type)}
        <div style={{ padding: 12 }}>
          <p>
            <StyledDocumentCardProperty>Type:</StyledDocumentCardProperty>{' '}
            {convertToString(documentType, '_')}
          </p>
          <p>
            <StyledDocumentCardProperty>Name:</StyledDocumentCardProperty>{' '}
            {file.name}
          </p>
          <StyledUploadErrorMessage>
            {uploadErrorMessage}
          </StyledUploadErrorMessage>
          {showLoader(progress)}
          <StyledUploadSuccessMessage>
            {uploadSuccessMessage}
          </StyledUploadSuccessMessage>
        </div>
      </StyledDocumentCard>
    </StyledDocumentOuter>
  )
}

DocumentCard.propTypes = {
  index: PropTypes.number,
  document: PropTypes.object,
  handleRemoveDocument: PropTypes.func,
}

export default DocumentCard
