import React, { Component } from 'react'
import { InitiateDualAuthOverride } from 'components/atoms/Details/index'
import auth from 'services/Auth'
import SimpleModal from 'components/organisms/Modal'
import InitialWithdrawalDualAuthOverrideForm from './add-comment'
import MuiButton from 'components/atoms/Buttons/MuiButton'

class InitiateWithdrawalDualAuthOverride extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    return (
      <>
        {!formShown && <MuiButton style={{ marginRight: '1em' }}  variant='contained' onClick={this.toggleForm}>
        Unsuspend Withdrawals
        </MuiButton >
        }
        <SimpleModal title='Unsuspend Withdrawals' buttonsHidden handleClose={this.toggleForm} open={formShown}>
          {formShown && <InitialWithdrawalDualAuthOverrideForm
            initialValues={{
              accountId: this.props.id,
              comment: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
              actionType: 'ACCOUNT_SECURITY',
            }}
            form={`InitiateWithdrawalDualAuthOverrideForm+${this.props.id}`}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />}
        </SimpleModal>
      </>
    )
  }
}

export default InitiateWithdrawalDualAuthOverride
