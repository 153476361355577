import { Collapse, TableCell, TableRow } from '@material-ui/core'
import {
  StyledTableExpandedTableCell,
  StyledTableRowWithPointerHover,
} from 'global/StyledComponents/Table'
import { AccountPendingMultiAuth } from 'interfaces/accountPendingMultiAuths'
import React, { Dispatch, FC, Fragment, SetStateAction } from 'react'

import RawData from 'components/atoms/RawDataDualAuth'

interface AccountPendingMultiAuthRowProps {
  pendingMultiAuth: AccountPendingMultiAuth
  isExpandedRow: boolean
  setExpandedRow: Dispatch<SetStateAction<string>>
}

const AccountPendingMultiAuthRow: FC<AccountPendingMultiAuthRowProps> = ({
  pendingMultiAuth,
  isExpandedRow,
  setExpandedRow,
}) => (
  <Fragment>
    <StyledTableRowWithPointerHover
      isexpanded={isExpandedRow}
      onClick={() =>
        setExpandedRow(
          prev =>
            prev === pendingMultiAuth.identifier
              ? ''
              : pendingMultiAuth.identifier,
        )
      }
    >
      <TableCell>{pendingMultiAuth.status}</TableCell>
      <TableCell>{pendingMultiAuth.type}</TableCell>
      <TableCell>{pendingMultiAuth.amount}</TableCell>
      <TableCell>{pendingMultiAuth.currency}</TableCell>
      <TableCell>{pendingMultiAuth.initiatorEmailAddress}</TableCell>
      <TableCell>{pendingMultiAuth.expiresAt}</TableCell>
      <TableCell>{pendingMultiAuth.createdAt}</TableCell>
    </StyledTableRowWithPointerHover>
    <TableRow className="no-hover">
      <StyledTableExpandedTableCell isexpanded={isExpandedRow} colSpan={8}>
        <Collapse in={isExpandedRow} timeout="auto" unmountOnExit>
          <RawData data={pendingMultiAuth.data} />
        </Collapse>
      </StyledTableExpandedTableCell>
    </TableRow>
  </Fragment>
)

export default AccountPendingMultiAuthRow
