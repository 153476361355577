import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
export const FETCH_ALL_MFAS_SUCCESS = 'FETCH_ALL_MFAS_SUCCESS'
export const FETCH_ALL_MFAS_ERROR = 'FETCH_ALL_MFAS_ERROR'
export const FETCH_ALL_MFAS_LOADING = 'FETCH_ALL_MFAS_LOADING'

export const getAllMfas = (accountId) => async dispatch => {
  dispatch({
    type: FETCH_ALL_MFAS_LOADING,
    payload: {
      data:[],
      id: accountId
    }
  })
  var res = null
  try {
    res = await getAxios().get(`/admin/all-mfas/${accountId}`)
    const { data } = res
    dispatch({
      type: FETCH_ALL_MFAS_SUCCESS,
      payload: {
        data,
        id: accountId
      }
    })
    return res
  } catch (error) {
    toast(`${error.response.data.error.message}`, { type: toast.TYPE.ERROR, autoClose: 10000 })
    dispatch({
      type: FETCH_ALL_MFAS_ERROR,
      payload: {
        error,
      },
    })
  }
}