import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { GetAccountByKycSession } from './GetAccountByKycSession'

const Kyc = () => (
  <Switch>
    <Route path={'/kyc/:sessionId'} component={GetAccountByKycSession} />
  </Switch>
)

export default Kyc
