import { Input } from 'components/atoms/Form/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import React, { Component } from 'react'
import {
  ActionButton,
} from 'components/atoms/Table/styles'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core'

export default class UpdateIntrestAuction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      intrestAuctionState: [],
      open: false,
    }
  }

  componentDidMount() {
    const { data } = this.props
    const applicationNewState =
      Object.keys(data).length > 0 &&
      Object.entries(data).map(([key, value]) => ({ key, value }))
    this.setState({ intrestAuctionState: applicationNewState })
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props
    if (prevProps.data.length !== data.length) {
      const applicationNewState =
        Object.keys(data).length > 0 &&
        Object.entries(data).map(([key, value]) => {
          data.configChanged = true
          return { key, value }
        })
      this.setState({ intrestAuctionState: applicationNewState })
    }
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  updateCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.toUpperCase()
    if (string === 'interestFeeAccountId') {
      return fixedCamelCasing.split(' ').slice(1).join('_')
    }
    return fixedCamelCasing.split(' ').join('_')
  }

  checkValue = (key, value) => {
    if (key === 'auctionEnabled') {
      return value.toString()
    }
    return value
  }

  saveApplication = async () => {
    this.state.intrestAuctionState.forEach(async applicationChange => {
      if (applicationChange.configChanged) {
        try {
          const key = this.updateCamelCasing(applicationChange.key)
          const value = this.checkValue(applicationChange.key, applicationChange.value)
          await getAxios().put('/admin/margin-settings/update-interest-auction-config', {
            key, value,
          })
          toast('Info successfully updated', {
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          })
        } catch (error) {
          toast(`${error} Info has not been updated`, {
            type: toast.TYPE.ERROR,
            autoClose: 4500,
          })
        }
      }
    })
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }


  updateRequirements = async () => {
    this.setState({ open: true })
  }

  onChange = (value, key) => {
    const newApplicationState = this.state.intrestAuctionState.map(newState => {
      if (newState.key === key) {
        newState.value = value
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ intrestAuctionState: newApplicationState })
  }

  handleProceedConfirmation = async () => {
    this.saveApplication()
    this.setState({ open: false })
  }

  render() {
    const { open } = this.state
    return (
      <>
        <ActionButton
          style={{ marginLeft: '90%', marginBottom: '20%', width: '8%' }}
          onClick={this.updateRequirements}
        >
          Update
        </ActionButton>
        <ConfirmationDialog
          title={'Margin requirements'}
          message={'Are you sure you want to procced?'}
          open={open}
          proceed={this.handleProceedConfirmation}
          handleClose={() => {
            this.closeDialog()
          }}
          button={{
            confirm: {
              text: 'Proceed',
            },
            decline: {
              text: 'Cancel',
            },
          }}
        >
          <>
            {this.state.intrestAuctionState && this.state.intrestAuctionState.map((intrestAuction) =>
              <>
                {intrestAuction.key !== 'auctionEnabled' && intrestAuction.key !== 'auctionIntervalHours' &&
                  <Input
                    label={this.removeCamelCasing(intrestAuction.key)}
                    variant="outlined"
                    style={{ marginLeft: '8px', marginTop: '8px' }}
                    type={intrestAuction.key !== 'auctionEnabled' && 'number'}
                    value={intrestAuction.value}
                    disabled={intrestAuction.key === 'auctionIntervalHours'}
                    onChange={event => this.onChange(event.target.value, intrestAuction.key, intrestAuction.value)} />}
                {intrestAuction.key === 'auctionEnabled' &&
                  <>
                    <h5>{this.removeCamelCasing(intrestAuction.key)}</h5>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender1"
                      value={intrestAuction.value.toString()}
                      onChange={event => this.onChange(event.target.value, intrestAuction.key, intrestAuction.value)}
                    >
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="false" />
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="true" />
                    </RadioGroup>


                  </>}

              </>)}
          </>
        </ConfirmationDialog>
      </>
    )
  }
}
