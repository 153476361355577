import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { removeUserFromCompetitionList } from 'redux/displayList/actions'
import { clearCompetitionList } from 'redux/updateList/actions'
import { FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS } from '../SearchAccountEmails/submit'

export const updateUserCompetitionListForm = async (values, dispatch) => {
  try {
    values.length > 0 &&
      (await Promise.all(
        values.map(async data => {
          const { id, deleteId } = data
          await getAxios().delete(
            `/sendgrid/remove-from-list/${id}/${deleteId}`,
          )
          dispatch(removeUserFromCompetitionList(id))
        }),
      ))
    dispatch(clearCompetitionList())
    toast('Marketing Lists updated', {
      type: toast.TYPE.SUCCESS,
      autoClose: 1500,
    })
  } catch (e) {
    toast(`${e.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}

export const updateGlobalList = async (
  addToGlobalList,
  email,
  dispatch,
  toggleForm,
) => {
  try {
    if (addToGlobalList) {
      await getAxios().post(`/account/global-unsubscribes/add/${email}`)
    } else {
      await getAxios().delete(`/account/global-unsubscribes/remove/${email}`)
    }
    const results = await getAxios().get(`/accounts/emails-activity/${email}`)
    dispatch({
      type: FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS,
      data: results && results.data,
      currentEmail: email,
    })
    toggleForm()
    toast(
      `User Globally ${
        addToGlobalList ? 'Subcribed' : 'Unsubscribed'
      } from marketing list`,
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      },
    )
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
