import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { FundingInfoAndEventsRowData } from './interfaces'

import { BASE } from '../../../theme/base-units'
import { Flexbox } from '../../layout'
import ReviewView from '../ReviewView'
import RawData from '../../atoms/RawDataDualAuth'
import { InfoLabel } from '../../atoms/Details'
import { BlueLinkButton } from '../../atoms/Form'

const ReviewsContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  margin: ${BASE * 2}px 0;
`

interface IFundingExtraInfoProps {
  data: FundingInfoAndEventsRowData
  defaultExpanded?: boolean
}

export const FundingExtraInfo = ({ data, defaultExpanded = false }: IFundingExtraInfoProps) => {
  const { review, ...rowDataForDisplay } = data
  const [expandRawData, showRawData] = useState(defaultExpanded)

  const toggleRawData = useCallback(() => { showRawData(!expandRawData) }, [showRawData, expandRawData])

  return (
    <Flexbox
      direction={'column'}
      align={'flex-start'}
      justify={'space-around'}
    >
      { review != null && <ReviewsContainer>
        <InfoLabel style={{ marginBottom: BASE * 2 }}>Review summary:</InfoLabel>
        <ReviewView rawData={review} flags={review.flags} audits={review.audits} />
      </ReviewsContainer>}

      <Flexbox
        style={{ color: 'white' }}
        direction={'row'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <BlueLinkButton
          marginRight={'50px'}
          marginBottom={'20px'}
          onClick={toggleRawData} color={'primary'} variant={'contained'}>
          {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
        </BlueLinkButton>
      </Flexbox>

      {expandRawData && (
        <Flexbox
          marginBottom={10}
          marginTop={10}
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <InfoLabel style={{ marginBottom: BASE * 2 }}>Raw Data:</InfoLabel>
          <RawData data={rowDataForDisplay} />
        </Flexbox>
      )}
    </Flexbox>
  )
}
