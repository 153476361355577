export const KEYCLOAK_ACTIVITY_PROCESS = 'KEYCLOAK_ACTIVITY_PROCESS'
export const KEYCLOAK_ACTIVITY_SUCCESS = 'KEYCLOAK_ACTIVITY_SUCCESS'
export const KEYCLOAK_ACTIVITY_ERROR = 'KEYCLOAK_ACTIVITY_ERROR'
export const CLEAR_KEYCLOAK_ACTIVITY = 'CLEAR_KEYCLOAK_ACTIVITY'

const initialState = {
  status: 'pristine',
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYCLOAK_ACTIVITY_PROCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }

    case KEYCLOAK_ACTIVITY_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case KEYCLOAK_ACTIVITY_ERROR: {
      return {
        ...state,
        status: 'done',
        data: [],
      }
    }
    case CLEAR_KEYCLOAK_ACTIVITY: {
      return {
        ...state,
        status: 'pristine',
        data: [],
      }
    }

    default:
      return state
  }
}
