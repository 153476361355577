import React, { Component, Fragment } from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import { InputLabel } from 'components/atoms/Form/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'

class RollBackFailedSends extends Component {
  render() {
    const {
      setOverRideAlreadySentAndOverRideChecked,
      setShowConfirmationDialog,
      showConfirmationDialog,
      overrideSends,
    } = this.props
    return (
      <Fragment>
        <Checkbox
          onChange={e => {
            if (overrideSends) return setOverRideAlreadySentAndOverRideChecked(false)
            const { checked } = e.target
            setShowConfirmationDialog(checked)
          }}
          color="primary"
          checked={overrideSends}
        />
        <InputLabel>Override already sent and mark as failed</InputLabel>
        <ConfirmationDialog
          disabled={''}
          title={'Accounts sends confirmation'}
          message={'Are you sure you want to proceed?'}
          open={showConfirmationDialog}
          proceed={() => {
            setOverRideAlreadySentAndOverRideChecked(true)
            setShowConfirmationDialog(false)
          }}
          handleClose={() => {
            setShowConfirmationDialog(false)
          }}
          onClose={() => setShowConfirmationDialog(false)}
          button={{
            confirm: {
              text: 'Proceed',
            },
            decline: {
              text: 'Cancel',
            },
          }}
        />
      </Fragment>
    )
  }
}
export default RollBackFailedSends
