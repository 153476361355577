import { toast } from 'react-toastify'
import {
  captureAddressInformation,
  updateAccountAddressInformation,
} from 'redux/corporate/actions'

export const submit = async (
  values,
  dispatch,
  formActionType,
  closeFormCallBack,
) => {
  // maybe we need to dispatch an action that will re-fetch addressess
  const { accountId } = values
  try {
    if (formActionType === 'corporateAddressEdit') {
      await dispatch(
        updateAccountAddressInformation(accountId, values, closeFormCallBack),
      )
    }
    if (formActionType === 'address') {
      await dispatch(captureAddressInformation(values, closeFormCallBack))
    }
  } catch (error) {
    toast(`${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
