import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_WITHDRAWAL_RESTRICTIONS_PROCESS = 'FETCH_WITHDRAWAL_RESTRICTIONS_PROCESS'
export const FETCH_WITHDRAWAL_RESTRICTIONS_SUCCESS = 'FETCH_WITHDRAWAL_RESTRICTIONS_SUCCESS'
export const FETCH_WITHDRAWAL_RESTRICTIONS_ERROR = 'FETCH_WITHDRAWAL_RESTRICTIONS_ERROR'

export const FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_PROCESS = 'FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_PROCESS'
export const FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_SUCCESS = 'FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_SUCCESS'
export const FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_ERROR = 'FETCH__DELETED_WITHDRAWAL_RESTRICTIONS_ERROR'

export const FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_PROCESS = 'FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_PROCESS'
export const FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_SUCCESS = 'FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_SUCCESS'
export const FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_ERROR = 'FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_ERROR'

export const fetchAddWithdrawalRestrictions = (type) => async dispatch => {
  dispatch({
    type: FETCH_WITHDRAWAL_RESTRICTIONS_PROCESS,
  })
  try {
    const res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const { data } = res
    dispatch({
      type: FETCH_WITHDRAWAL_RESTRICTIONS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WITHDRAWAL_RESTRICTIONS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchUpdateWithdrawalRestrictions = (type) => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const { data } = res
    dispatch({
      type: FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_ERROR,
    })
  }
}

export const fetchDeleteWithdrawalRestrictions = (type) => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const { data } = res
    dispatch({
      type: FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_ERROR,
    })
  }
}



