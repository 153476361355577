import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.link.text};
  display: block;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  &:hover {
    text-decoration: none;
  }
  &.active {
    color: red;
  }
`
export const Link = props => {
  const { id, color, children } = props
  return (
    <div>
      <StyledLink color={color} href={`/accounts/byId/${id}/balances`} target="_blank">
        {children || id}
      </StyledLink>
    </div>
  )
}