import React, { Component, Fragment } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import RawData from 'components/atoms/RawDataDualAuth/index'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'

class Row extends Component {
  render() {
    const {
      deposit: {
        depositId, source, amount, description, effectiveDate, insertedAt,
      },
      toggleSelectedRow,
      expandedRow,
    } = this.props
    const formatedAmount = new BigNumber(amount).toFormat(2)
    return <Fragment>
      <TableRow
        onClick={() => toggleSelectedRow(depositId)}
      >
        <TableCell >
          {effectiveDate}
        </TableCell>
        <TableCell >
          {moment(insertedAt).format('YYYY-MMM-DD HH:mm:ss')}
        </TableCell>
        <TableCell >
          {description}
        </TableCell>
        <TableCell >
          {formatedAmount}
        </TableCell>
        <TableCell >
          {depositId}
        </TableCell>
        <TableCell >
          {source}
        </TableCell>
      </TableRow>
      <TableRow className="no-hover">
        {expandedRow === depositId && (
          <TableCell colSpan={12}>
            <RawData
              data={this.props.deposit}
              title={'Raw Data'}
            />
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  }
}

export default Row
