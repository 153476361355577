import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as moment from 'moment'
import Button from '@material-ui/core/Button'
import getAxios from 'helpers/axios'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { toast } from 'react-toastify'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  fetchCompetitionDetails,
  fetchCompetitionDetailsById,
  rejectInternalTransferBatch,
  updateLoadingStatus,
} from 'redux/competitionDetails/actions'
import { InputGroup } from 'components/atoms/Form/index'
import { TablePlaceholder } from 'components/atoms/Table/styles'

function ActiveCompatition(props) {
  const [showForm, setShowForm] = React.useState(false)
  const [currentTab, setCurrentTab] = React.useState('list')
  const [open, setOpen] = React.useState(true)
  const [scroll, setScroll] = React.useState('paper')
  const [latestOpenedCompetitionId, setLatestOpenedCompetitionId] =
    React.useState('')

  const handleClose = () => {
    setOpen(false)
  }
  const styles = (theme) => ({
    dialogCustomizedWidth: {
      'max-width': '90%',
    },
  })

  const showCompetition = (id) => {
    setShowForm(true)
    setOpen(true)
    setLatestOpenedCompetitionId(id)
    props.fetchCompetitionDetailsById(id)
  }

  React.useEffect(() => {
    props.fetchCompetitionDetails()
  }, [])

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const close = () => {
    setOpen(false)
    setShowForm(false)
  }

  const confirmed = async (data) => {
    const arrayToUse = data
      .filter((dat) => dat.status === 'Pending Approval')
      .sort((a, b) => a.internalId - b.internalId)
      .map((item) => {
        item.newDate = moment(new Date()).format('DD-MMMM-YYYY')
        return item
      })

    props.updateLoadingStatus(
      arrayToUse.length
        ? arrayToUse[0].competitionId
        : latestOpenedCompetitionId,
    )

    try {
      await getAxios().put('/competition/approval-status', {
        id: arrayToUse.length
          ? arrayToUse[0].competitionId
          : latestOpenedCompetitionId,
        status: true,
      })
      const res = await getAxios().post(
        '/approve/winner',
        arrayToUse.length ? arrayToUse : { id: latestOpenedCompetitionId },
      )
      if (res.status === 206) {
        toast('Competition Partially Approved', {
          type: toast.TYPE.WARN,
          autoClose: 2000,
        })
      } else {
        toast('Competition Successfully Approved', {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        })
      }
    } catch (error) {
      toast(`${error.response.data.message} `, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    } finally {
      props.fetchCompetitionDetails()
      close()
    }
  }

  const handleRejectInternalBatchTransfers = async () => {
    await props.rejectInternalTransferBatch(latestOpenedCompetitionId)
    close()
  }

  const { status, data, activeWinnersById, isRejectingInternalTransferBatch } =
    props.competition

  const isLoading = React.useMemo(() => {
    const currentCompetition =
      activeWinnersById.data.length &&
      data.find((item) => item.id === activeWinnersById.data[0].competitionId)

    return activeWinnersById.data.length && currentCompetition
      ? currentCompetition.loading
      : false
  }, [data, activeWinnersById])

  return (
    <Fragment>
      {status !== 'done' && <LoadingBlock />}
      {status === 'done' && data.length === 0 && (
        <TablePlaceholder>No active competitions</TablePlaceholder>
      )}
      {status === 'done' && data.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>List name</TableCell>
              <TableCell colSpan={2}>Sendgrid template id</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => a.id < b.id)
              .map((competition) => (
                <>
                  <TableRow
                    key={competition.competitionId}
                    style={{ border: '4px solid silver' }}
                  >
                    <TableCell colSpan={2} style={{ padding: '10px' }}>
                      {`${competition.competition_name}
                        ${moment(competition.created_at).format(
                          'YYYY-MMM-DD HH:mm:ss',
                        )}
                        ${
                          competition.approvedcount > 0
                            ? `Partially approved:${competition.approvedcount}/${competition.totalcount}`
                            : ''
                        }`}
                    </TableCell>
                    <TableCell colSpan={2} style={{ padding: '10px' }}>
                      {competition.template_id || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ padding: '10px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => showCompetition(competition.id)}
                      >
                        Review
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ height: '10px' }} />
                </>
              ))}
          </TableBody>
        </Table>
      ) : (
        ''
      )}

      {showForm && open && (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
              style: {
                margin: '40px auto 0',
                width: '95%',
                maxWidth: '800px',
              },
            }}
          >
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {
                      'Make sure you have enough funds in the FROM ACCOUNT to transfer for the entire batch'
                    }{' '}
                  </p>
                }
                <Table stickyHeader aria-label="sticky table">
                  <TableHead align="right" style={{ minWidth: '170' }}>
                    <TableRow>
                      <TableCell>Counter</TableCell>
                      <TableCell>From Account Id</TableCell>
                      <TableCell>To Account Id</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeWinnersById.status !== 'done' && (
                      <TableCell colSpan={7}>
                        <LoadingBlock />
                      </TableCell>
                    )}
                    {activeWinnersById.status === 'done' &&
                      activeWinnersById.data
                        .sort((a, b) => a.internalId - b.internalId)
                        .map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.from_account_id}</TableCell>
                            <TableCell>{row.to_account_id}</TableCell>
                            <TableCell>{row.amount}</TableCell>
                            <TableCell>{row.currency}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.error || 'N/A'}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <InputGroup justify={'flex-end'} style={{ marginTop: '2%' }}>
                {isLoading ? (
                  <LoadingBlock
                    width="auto"
                    margin="0 2% 0 0"
                    message="Loading..."
                  />
                ) : (
                  <Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: '80px',
                        marginRight: '2%',
                      }}
                      onClick={() => confirmed(activeWinnersById.data)}
                      disabled={
                        !data.some(
                          (competition) =>
                            competition &&
                            activeWinnersById.data.length &&
                            competition.id ===
                              activeWinnersById.data[0].competitionId,
                        )
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        width: '80px',
                        marginRight: '2%',
                      }}
                      onClick={handleRejectInternalBatchTransfers}
                      disabled={isRejectingInternalTransferBatch}
                    >
                      Reject
                      {isRejectingInternalTransferBatch ? 'ing' : ''}
                    </Button>
                  </Fragment>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ width: '80px' }}
                  onClick={() => close()}
                >
                  Cancel
                </Button>
              </InputGroup>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  competition: state.competition,
  pendingInfo: state.pendingInternalTransfers,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCompetitionDetails,
      fetchCompetitionDetailsById,
      updateLoadingStatus,
      rejectInternalTransferBatch,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCompatition)
