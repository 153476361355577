import {
  CLEAR_VALR_PAY_TRANSACTIONS_LIST,
  FETCH_VALR_PAY_ERROR,
  FETCH_VALR_PAY_START,
  FETCH_VALR_PAY_SUCCESS,
} from './actions'

const initialState = {
  isLoading: false,
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALR_PAY_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case FETCH_VALR_PAY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      }
    }

    case FETCH_VALR_PAY_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case CLEAR_VALR_PAY_TRANSACTIONS_LIST: {
      return initialState
    }
    default:
      return state
  }
}
