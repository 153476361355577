import React from 'react'
import './Styles.css'
import { RenderCheckbox } from 'forms/fields'
import { TransitionCell } from '../Styles'
import { TableCell, TableHead, TableRow } from '@material-ui/core'

export const Headers = props => {
  const {
    expandItem,
    bulkActionsActive,
    isCellHidden,
    toggleSelectAll,
    isAllDepositsSelected,
  } = props

  return (
    <TableHead onClick={expandItem}>
      <TableRow>
        <TableCell >Source</TableCell>
        <TableCell >
          Amount
        </TableCell>
        <TableCell >Currency</TableCell>
        <TableCell >Reference</TableCell>
        <TableCell>Bank Date</TableCell>
        <TableCell>Inserted At</TableCell>
        <TableCell >Viewer</TableCell>
        <TableCell >Tag</TableCell>
        <TransitionCell
          className={bulkActionsActive ? 'suspendActive' : 'suspendFade'}
          show={isCellHidden}
        >
          <TableCell>
            <RenderCheckbox
              onCheckFunction={() =>
                toggleSelectAll()
              }
              checked={isAllDepositsSelected}
              label={'Select All'}
            />
          </TableCell>
        </TransitionCell>
      </TableRow>
    </TableHead>
  )
}
