import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import IconLoading from 'components/atoms/IconLoading'
import { BASE } from 'theme/base-units'
import { renderInput } from 'forms/fields'
import { isNumber, required } from 'forms/fieldValidators'
import {
  InputGroup,
  TransparentFormContainer,
} from 'components/atoms/Form/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'

let Form = props => {
  const { submitting, invalid, handleSubmit, onSubmit, isRunning, disableSubmit } = props

  return (
    <TransparentFormContainer width='100%' onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Sync in batches of'}
          name={'batchSize'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'15%'}
          flex={0.4}
          component={renderInput}
          validate={[isNumber, required]}
          placeholder={'Sync in batches of'}
        />
        <Field
          label={'Max no. accounts to sync'}
          name={'maxSize'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'15%'}
          flex={0.4}
          component={renderInput}
          validate={[isNumber, required]}
          placeholder={'Number of accounts to sync'}
        />
        <MuiButton style={{ marginTop: '1%', marginLeft: `${BASE * 2}px` }} type="submit" main disabled={submitting || invalid || isRunning || disableSubmit}>
          {isRunning && <IconLoading />}
          Sync Accounts to SendGrid
        </MuiButton>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'DynamicFilterFormGlobalSync',
  onSubmit: (values, dispatch, props) => {
    props.triggerMarketingContactsUpdate(values.batchSize, values.maxSize)
  },
  validate: values => {
    const errors = {}
    if (!values.batchSize || !values.maxSize) {
      errors._error = 'Both values required'
    }
    return errors
  },
  initialValues: {
    batchSize: 1000,
    maxSize: 20000,
  },
  enableReinitialize: true,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
