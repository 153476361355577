import {
  FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS,
  FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_ERROR,
  UPDATE_NOTES_FOR_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS,
} from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_UNASSIGNED_BLOCKCHAIN_RECEIVES_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    case UPDATE_NOTES_FOR_UNASSIGNED_BLOCKCHAIN_RECEIVES_SUCCESS: {
      const { id } = action.payload
      const updatedReceivesData =
        state.data &&
        state.data.map(recives => {
          if (id === recives.id) {
            recives.tag = true
          }
          return recives
        })
      return {
        ...state,
        data: updatedReceivesData,
        status: 'done',
      }
    }

    default:
      return state
  }
}
