import { kycLevelInfo } from 'helpers/fileHelpers'
import {
  FETCH_ACCOUNT_KYC_LEVEL_PROCESS,
  FETCH_ACCOUNT_KYC_LEVEL_SUCCESS,
  FETCH_ACCOUNT_KYC_LEVEL_ERROR,
  UPDATE_KYC_LEVEL_SUCCESS,
} from './actions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_KYC_LEVEL_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          status: 'loading',
          info: {},
        },
      }
    }
    case FETCH_ACCOUNT_KYC_LEVEL_SUCCESS: {
      const { id, ...info } = action.payload
      return {
        ...state,
        [id]: {
          status: 'done',
          info,
        },
      }
    }
    case UPDATE_KYC_LEVEL_SUCCESS: {
      const { id, newKycLevelId } = action.payload
      const updatedKycInfo = kycLevelInfo(newKycLevelId)
      const updatedState = {
        ...state,
        [id]: {
          ...state[id],
          info: {
            level: newKycLevelId,
            ...updatedKycInfo,
          },
        },
      }
      return updatedState
    }

    case FETCH_ACCOUNT_KYC_LEVEL_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        [id]: {
          status: 'error',
          info: {},
          error,
        },
      }
    }

    default:
      return state
  }
}
