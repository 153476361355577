import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'

export const FETCH_MANUAL_KYC_ATTEMPTS_PROCCESS =
  'FETCH_MANUAL_KYC_ATTEMPTS_PROCCESS'
export const FETCH_MANUAL_KYC_ATTEMPTS_SUCCESS =
  'FETCH_MANUAL_KYC_ATTEMPTS_SUCCESS'
export const FETCH_MANUAL_KYC_ATTEMPTS_ERROR = 'FETCH_MANUAL_KYC_ATTEMPTS_ERROR'

export const fetchManualAttempts = (id) => async (dispatch) => {
  dispatch({ type: FETCH_MANUAL_KYC_ATTEMPTS_PROCCESS })
  let res = null
  try {
    res = await getAxios().get(`/accounts/${id}/manual-kyc-attempts`)

    const { data } = res
    dispatch({
      type: FETCH_MANUAL_KYC_ATTEMPTS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_MANUAL_KYC_ATTEMPTS_ERROR,
      payload: {
        error,
      },
    })
  }
}
