interface BroadCastChannelProps {
  channelName: string
}
export class BroadCastChannelService {
  private broadcastChannel: BroadcastChannel

  constructor(props: BroadCastChannelProps) {
    this.broadcastChannel = this.init(props.channelName)
  }

  private init(channelName: string) {
    return new BroadcastChannel(channelName)
  }

  listen(cb: (ev: MessageEvent<any>) => void) {
    this.broadcastChannel.onmessage = (ev: MessageEvent<any>) => {
      cb(ev)
    }
  }

  broadcastMessageToAllOpenClients(data: any) {
    this.broadcastChannel.postMessage(data)
  }

  disconnect() {
    this.broadcastChannel.onmessage = null
  }
}
