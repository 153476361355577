
import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = () => (
  <TableHeader>
    <TableCell width={'7%'}>Account Id</TableCell>
    <TableCell width={'9%'}>First Name</TableCell>
    <TableCell width={'9%'}>Last Name</TableCell>
    <TableCell width={'8%'} marginRight={'1%'} align={'right'}>
      Amount
    </TableCell>
    <TableCell width={'7%'}>Currency</TableCell>
    <TableCell width={'20%'}>Reference</TableCell>
    <TableCell width={'9%'}>Bank Date</TableCell>
    <TableCell width={'9%'}>Inserted At</TableCell>
    <TableCell width={'12%'}>Reason</TableCell>
    <TableCell width={'10%'}/>

  </TableHeader>
)
