import React, { Fragment, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, maxLength256 } from 'forms/fieldValidators'
import { FormContainer, Button, FormError } from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput } from '../fields'
import { submit } from './submit'
import { Flexbox } from 'components/layout/index'
import { COMMENT_MESSAGES } from 'helpers/const'
import { EditableDropdown } from 'components/organisms/EditableDropdown/index'

let Form = props => {
  const { submitting, error, invalid, formData, form, change } = props

  const {
    handleSubmit,
    onCancel,
    onSubmit,
    showActiveUser,
    removeActiveUser,
  } = props

  const formValues = formData[form] && formData[form].values

  const { status, comment, selectedValue } = formValues || {status: '', comment: '', selectedValue: ''}

  const options = useMemo(() => {
   return !status ? 
    [{value: '', label: 'Please select an action type'}] 
    : COMMENT_MESSAGES.parachute[status.value]
  }, [status])

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  useEffect(() => {
    change('comment', '')
  }, [status])


  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Flexbox align={'flex-start'} justify={'space-between'} direction={'row'}>
        <Field
          label={'Action'}
          name={'status'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'40%'}
          flex={0.4}
          component={renderDropdown}
          options={[
            { value: 'ACCEPT', label: 'Approve' },
            { value: 'REJECT', label: 'Reject' },
          ]}
        />
        <EditableDropdown
            condition={comment}
            label={'Comment (required)'}
            name={'comment'}
            selectFieldDropDownName={'selectedValue'}
            width={'50%'}
            validate={[required]}
            options={[...options]}
            justify={'space-around'}
            margin={`0px ${BASE * 2}px 26px 0px`}
            selectedValue={selectedValue ? selectedValue.value : ''}
            comment={comment || ''}
            change={change}
          />


        <div style={{ width: '286px', marginTop: '16px' }}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button padding={'16px 41px'} onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </div>
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)
const ReduxForm = connect((state) => ({formData: state.form}))(Form)

export default ReduxForm
