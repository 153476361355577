import { StakingProps } from 'containers/Accounts/SingleAccount/interfaces'
import { StakingAction } from './actions'


type loadingStates = 'pristine' | 'loading' | 'done'
export interface AccountStakingInitialState {
  status: loadingStates
  data: StakingProps[],
}

const initialState: AccountStakingInitialState = {
  status: 'pristine',
  data: [],
}

  interface Action {
    type: StakingAction
    payload: StakingProps[]
  }

export default (
  state = initialState,
  action: Action,
): AccountStakingInitialState => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_STAKING_START': {
      return {
        ...state,
        status: 'loading',
      }
    }
    case 'FETCH_ACCOUNT_STAKING_SUCCESS': {
      return {
        ...state,
        status: 'done',
        data: action.payload,
      }
    }
    case 'FETCH_ACCOUNT_STAKING_ERROR': {
      return {
        ...state,
        status: 'pristine',
      }
    }
    case 'CLEAR_ACCOUNT_STAKING': {
      return initialState
    }
    default:
      return state
  }
}
