import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { ContentContainer } from 'components/atoms/Generic'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import React, { FC, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

import RenderLoanBookInfo from 'containers/Leverage/LoansBook'

import Futures from './Futures'
import MarginPlatformSummary from './MarginPlatformSummary'

type LeverageRoutes =
  | '/leverage/loans-book'
  | '/leverage/margin'
  | '/leverage/futures'

interface LeverageNavigationItem {
  route: LeverageRoutes
  label: string
  Component: (props: Route) => React.ReactNode
}

const LeverageNavigation: LeverageNavigationItem[] = [
  {
    route: '/leverage/loans-book',
    label: 'Loans Book',
    Component: props => <RenderLoanBookInfo {...props} />,
  },
  {
    route: '/leverage/margin',
    label: 'Margin',
    Component: props => <MarginPlatformSummary {...props} />,
  },
  {
    route: '/leverage/futures',
    label: 'Futures',
    Component: props => <Futures {...props} />,
  },
]

const routes = ['/leverage/loans-book', '/leverage/margin', '/leverage/futures']

const Leverage: FC<Route> = props => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }
  const {
    location: { pathname },
  } = props

  useEffect(
    () => {
      setCurrentPage(`/leverage/${pathname.split('/')[2]}`)

      return () => {}
    },
    [pathname],
  )

  return (
    <>
      <h1>Leverage</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        {LeverageNavigation.map(nav => {
          const { route, label } = nav

          return (
            <NavigationTab
              key={label}
              value={route}
              label={label}
              component={ForwardedNavLink}
              to={route}
            />
          )
        })}
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          {LeverageNavigation.map(nav => {
            const { route, Component } = nav

            return <Route key={route} path={route} component={Component} />
          })}
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Leverage
