import { LEGAL_ENTITY_TYPES } from 'helpers/const'
import React from 'react'
import { Field } from 'redux-form'
import { BASE } from 'theme/base-units'

import { required } from 'forms/fieldValidators'
import { renderDropDownField, renderInput } from 'forms/fields'
import { InputGroup } from 'components/atoms/Form/index'

import { StyledEntityFormLeft } from '../Styles'

const LegalEntityFormLeft = ({ hasEntityType }) => (
  <StyledEntityFormLeft>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Company Name'}
        name={'name'}
        width={'100%'}
        validate={[required]}
        component={renderInput}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Registration Number'}
        name={'registrationNumber'}
        width={'100%'}
        margin={`0 ${BASE * 2}px 0 0`}
        validate={[required]}
        type="number"
        component={renderInput}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Legal Entity Type'}
        name={'entityType'}
        width={'100%'}
        disabled={!!hasEntityType}
        margin={`0 ${BASE * 2}px 0 0`}
        validate={[required]}
        showNoneLabel={false}
        options={[
          { label: LEGAL_ENTITY_TYPES.PTY, value: LEGAL_ENTITY_TYPES.PTY },
          { label: LEGAL_ENTITY_TYPES.TRUST, value: LEGAL_ENTITY_TYPES.TRUST },
          { label: LEGAL_ENTITY_TYPES.OTHER, value: LEGAL_ENTITY_TYPES.OTHER },
        ]}
        component={renderDropDownField}
        fullWidth
      />
    </InputGroup>
  </StyledEntityFormLeft>
)

export default LegalEntityFormLeft
