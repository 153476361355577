import styled from 'styled-components'
import { BASE } from 'theme/base-units'

export const Container = styled.div`
  width: ${({ width = '100%' }) => width};
  padding: ${({ padding = BASE * 2 }) => `${padding}px 0`};
  background-color: ${({ theme }) => theme.loadingBlock};
  margin: ${({ margin = '0' }) => margin};
  display: flex;
  align-items: center;
  justify-content: center;
`
