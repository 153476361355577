import React, { Fragment, Component } from 'react'
import SetConfirmationForm from 'forms/SetConfirmations'

class SetConfirmations extends Component {

  render() {
    const { id } = this.props
    return (
      <Fragment>
        <SetConfirmationForm initialValues={{
          accountId: id, currency: '', belowThresholdConfirmationCount: '', threshold: '', aboveThresholdConfirmationCount: '',
        }}
          form={'SetConfirmationForm'}
          onSubmitSuccess={(value, dispatch, props) => {
            props.reset()
          }}
        />
      </Fragment>
    )
  }
}
export default SetConfirmations
