import React, { Component, Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from 'components/atoms/Table/styles'
import SendgridListsTableRow from './sendgridListsRows'

class RenderMarketingContactSendgridListTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const { list } = this.props
    const sortedList = [...list.sort((x, y) => y.id - x.id)]
    return (
      <Fragment>
        {list.length > 0 ? (
          <Table>
            <TableHeader>
              <TableCell flex={0.5} width={'30%'}>Sendgrid ID</TableCell>
              <TableCell flex={0.65} width={'40%'}>Name</TableCell>
              <TableCell flex={0.65} width={'30%'}>Contact Count</TableCell>
            </TableHeader>
            <TableBody>
              {sortedList && sortedList.map((data, idOnList) => {
                let uniqueId = idOnList++
                return (
                    <SendgridListsTableRow
                      key={uniqueId}
                      data={data}
                      uniqueId={uniqueId}
                    />
                )
              })}
            </TableBody>
          </Table>
        ) : (
          <center>
            <b>No Sendgrid lists to show</b>
          </center>
        )}
      </Fragment>
    )
  }
}

export default RenderMarketingContactSendgridListTable