import { AvailableCurrencyPairsList } from 'interfaces/futures'
import { useEffect, useState } from 'react'

export const useConditionalOrders = (
    getAvailableCurrencyPairs: () => Promise<void>,
    availableCurrencyPairsList:AvailableCurrencyPairsList,
    resetAvailableCurrencyPairs: () => Promise<void>,
    resetConditionalOrders: () => Promise<void>,
) => {

  const [expandRowId, setExpandedItem] = useState('')

  const handleFetchAvailableCurrencyPairs = () => {
    if (availableCurrencyPairsList.length === 0) {
      getAvailableCurrencyPairs()
    }
  }

  useEffect(
    () => () => {
      resetAvailableCurrencyPairs()
     resetConditionalOrders()
    },
    [],
  )

  return {
    expandRowId,
    setExpandedItem,
    handleFetchAvailableCurrencyPairs,
  }
}
