import React, { Component, Fragment } from 'react'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import FormUsdDepositRelease from 'forms/UsdDepositRelease/index'
import auth from 'services/Auth'
import { Link } from '../AccountIdLink/clickableId'
import socket from 'redux/socket/index'
import EyeIcon from 'components/atoms/EyeIcon/index'
import { VALR_BLUE_GRAY } from 'theme/colors'

class Row extends Component {
  removeActiveUser = async () => {
    const depositId = this.props.data.id
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const removeUser = { depositId, activeUserQue }
    socket.emit('remove user', removeUser)
  }

  showActiveuser = async () => {
    const depositId = this.props.data.id
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const activeUser = { depositId, activeUserQue }
    socket.emit('add user', activeUser)
  }

  render() {
    const {
      data,
      toggleForm,
      formShown,
      expandRawData,
      showRawData,
      setHighlightedId,
      isHighlighted,
    } = this.props
    const shading = isHighlighted ? 'white' : ''
    const padding = '16px 16px 16px 0px'
    const {
      id,
      accountId,
      amount,
      createdAt,
      currency,
      status,
      reviewItemId,
      active,
    } = data
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && VALR_BLUE_GRAY,
          }}
          onClick={() => {
            toggleForm(reviewItemId)
            setHighlightedId(reviewItemId)
          }}
        >
          <TableCell flex={0.6} style={{ padding: '16px 16px' }}>
            {accountId ? <Link id={accountId} /> : '--'}
          </TableCell>
          <TableCell flex={0.3}>{currency || '--'}</TableCell>

          <TableCell flex={0.6} align={'right'}>
            {amount}
          </TableCell>
          <TableCell flex={0.6} style={{ marginLeft: '12%' }}>
            {status}
          </TableCell>
          <TableCell flex={0.6}>{createdAt}</TableCell>
          <TableCell flex={0.6} style={{ padding }}>
            {active &&
              active.userActive && <EyeIcon text={active.activePersonName} />}
          </TableCell>
        </TableRow>
        {formShown && (
          <FormUsdDepositRelease
            initialValues={{
              depositIds: id,
              comment: '',
            }}
            expandRawData={expandRawData}
            showRawData={() => showRawData(reviewItemId)}
            removeActiveUser={this.removeActiveUser}
            showActiveUser={this.showActiveuser}
            form={`UsdDepositReviewForm+${reviewItemId}`}
            rawData={this.props.data}
            onCancel={this.props.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}

export default Row
