import React, { FC, useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { RiskProfileDualAuth } from 'interfaces/riskProfile'
import { RiskAccountsDualAuthForm } from 'forms/RiskProfileDualAuth'
import { riskLevelCalculator } from 'components/atoms/RiskProfiles'
import moment from 'moment'
import { CheckCircle, Person } from '@material-ui/icons'
import { reset } from 'redux-form'
import { connectStateAndDispatch } from 'store'

import { Details, JsonBlock } from 'components/atoms/Details'
import { Link } from 'components/atoms/Table/rows/clickableId'
import { Title } from 'components/atoms/Title'

import { StyledReviewer, StyledReviewersContainer } from '../Styles'
import authService from 'services/Auth'
import SimpleModal from 'components/organisms/Modal'
import MuiButton from 'components/atoms/Buttons/MuiButton'

interface AccountsProfileDualAuthProps {
  accountsdualAuth: RiskProfileDualAuth
  toggleSelectedRow: (value: string) => void
  isSelected: boolean
  isMultiAuth?: boolean
  reset: (value: string) => void
  isCorporateRiskProfileDualAuth: boolean
}
const RiskAccountsProfilesDualAuthRow: FC<AccountsProfileDualAuthProps> = (
  props,
) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const toggleConfirmationDialog = () =>
    setShowConfirmationDialog((prevState) => !prevState)
  const {
    accountsdualAuth,
    toggleSelectedRow,
    isSelected,
    isMultiAuth,
    isCorporateRiskProfileDualAuth,
  } = props
  const {
    id,
    accountId,
    approvals,
    data: { recommendationStatus },
    name,
    createdAt,
    riskScore,
    updatedAt,
    approvers,
    reviewers,
    commentId,
    initiator,
    multiAuth,
  } = accountsdualAuth
  const currentAdminUserEmail = authService.getEmailFromToken()

  const alreadyActionedByUser =
    approvers.find((approver) => approver.actionedBy === currentAdminUserEmail)
      ?.action === 'APPROVED'

  const isInitiator = initiator === currentAdminUserEmail

  const isReviewer = reviewers.includes(currentAdminUserEmail)
  const handleTooltipText = () => {
    if (alreadyActionedByUser)
      return 'You have already actioned this dual auth.'
    if (!isReviewer && multiAuth)
      return 'Not authorized to review this dual auth.'
    return ''
  }
  return (
    <>
      <TableRow
        onClick={() =>
          toggleSelectedRow(
            `${id}-${
              isCorporateRiskProfileDualAuth ? 'corporate' : 'individual'
            }`,
          )
        }
      >
        <TableCell>{accountId ? <Link id={accountId} /> : '--'}</TableCell>
        <TableCell>{name}</TableCell>
        {!isCorporateRiskProfileDualAuth && (
          <TableCell>{accountsdualAuth.surname}</TableCell>
        )}
        <TableCell>{`${approvers.length}/${approvals}`}</TableCell>
        <TableCell>{recommendationStatus}</TableCell>
        <TableCell>{riskLevelCalculator(riskScore)}</TableCell>
        <TableCell>{moment(createdAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
        <TableCell>{moment(updatedAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
        <TableCell>
          <MuiButton
            onClick={(e) => {
              e.stopPropagation()
              toggleConfirmationDialog()
            }}
            toolTipTextSize=".8em"
            showToolTip
            tooltipTitle={handleTooltipText()}
            disabled={(!isReviewer && multiAuth) || alreadyActionedByUser}
          >
            Action Dual Auth
          </MuiButton>
        </TableCell>
      </TableRow>
      {isSelected && (
        <>
          <TableCell colSpan={9}>
            {isMultiAuth && (
              <StyledReviewersContainer>
                <Title type="h3" text={'Reviewers'} />
                {reviewers.map((reviewer) => (
                  <StyledReviewer key={reviewer}>
                    <Person color="primary" />
                    <p>{reviewer}</p>
                    {approvers.find(
                      (approver) => approver.actionedBy === reviewer,
                    ) && <CheckCircle style={{ color: 'lightgreen' }} />}
                  </StyledReviewer>
                ))}
              </StyledReviewersContainer>
            )}
            <Details>
              <JsonBlock src={accountsdualAuth} title={'Raw Data'} />
            </Details>
          </TableCell>
        </>
      )}
      <SimpleModal
        width="50%"
        title={'Action dual auth'}
        message={
          isInitiator
            ? 'You are the initiator and can not approve this dual auth.'
            : 'Approve or decline dual auth. Note when approving you can add reviewers which will need to be approved.'
        }
        open={showConfirmationDialog}
        handleClose={toggleConfirmationDialog}
        buttonsHidden
      >
        <RiskAccountsDualAuthForm
          form={
            isMultiAuth
              ? 'risk_profiles_multi_auth_Form'
              : 'risk_profiles_dual_auth_Form'
          }
          initialValues={{
            dualAuthId: id,
            action: '',
            listOfReviewers: [],
            accountId,
            isCorporateRiskProfileDualAuth,
            commentId,
          }}
          isInitiator={isInitiator}
          isMultiAuth={isMultiAuth}
          cancel={toggleConfirmationDialog}
          onSubmitSuccess={toggleConfirmationDialog}
        />
      </SimpleModal>
    </>
  )
}

export default connectStateAndDispatch(
  null,
  {
    reset,
  },
  RiskAccountsProfilesDualAuthRow,
)
