import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAccountBalances } from 'redux/selectors/balances'
import BigNumber from 'bignumber.js'
import { fetchBalances, fetchAllAccountBalances } from 'redux/balances/actions'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import {
  Actions,
  TablePlaceholder,
  TitleContainer,
} from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import { getAccountDetails } from 'redux/selectors/details'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import ErrorBoundary from 'helpers/ErrorBoundary'
import AllBalances from './AllAccountsBalances'
import { DownloadAccountBalancesForm } from './Statements'
import { Button } from 'components/atoms/Form'

function createData(
  currency,
  total,
  borrowCollateralReserved,
  borrowedAmount,
  lendReserved,
  zarTotal,
  reserved,
  available,
  shortName,
) {
  return {
    currency,
    total,
    borrowCollateralReserved,
    borrowedAmount,
    lendReserved,
    zarTotal,
    reserved,
    available,
    shortName,
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'currency',
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'assets',
    disablePadding: false,
    label: 'Assets',
  },
  {
    id: 'borrowedAmount',
    disablePadding: false,
    label: 'Debt',
  },
  {
    id: 'equity',
    disablePadding: false,
    label: 'Equity',
  },

  {
    id: 'available',
    disablePadding: false,
    label: 'Available',
  },
  {
    id: 'reserved',
    disablePadding: false,
    label: 'Trade Reserved',
  },
  {
    id: 'borrowCollateralReserved',
    disablePadding: false,
    label: 'Collateral Reserved',
  },
  {
    id: 'lent',
    disablePadding: false,
    label: 'Lent',
  },
  {
    id: 'zarTotal',
    disablePadding: false,
    label: 'Total in Rands',
  },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountBalances(props) {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [modalOpen, setModalOpen] = useState(false)

  const toggleExportModal = () => {
    setModalOpen(!modalOpen)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const formatZar = (zar) =>
    zar &&
    `R ${parseFloat(zar)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`

  React.useEffect(() => {
    const { accountId } = props.match.params
    props.fetchBalances(accountId)
    props.fetchAllAccountBalances(accountId)
  }, [])

  const {
    balances: { data },
    details: { status, accountType },
    match: {
      params: { accountId },
    },
  } = props
  const { balances } = data
  const list = balances || data
  const rows =
    list.length > 0 &&
    list.map((balance) => {
      const { longName, shortName } = balance.currency
      const {
        zarTotal,
        total,
        reserved,
        available,
        borrowCollateralReserved,
        borrowedAmount,
        lendReserved,
      } = balance
      return createData(
        longName,
        total,
        borrowCollateralReserved,
        borrowedAmount,
        lendReserved,
        parseFloat(zarTotal),
        reserved,
        available,
        shortName,
      )
    })

  const addTotalAmount = (deposit) => {
    const total = deposit
      .map((d) => d.zarTotal)
      .reduce((accumulator, value) => {
        const checkedCurrentValue = value
          ? parseFloat(
              typeof value === 'string' ? value.split(',').join('') : value,
            )
          : 0
        return accumulator + checkedCurrentValue
      }, 0)

    return `R${new BigNumber(total).toFormat(2)}`
  }

  const addAllAssets = (a, b, c, d) =>
    Number(a) + Number(b) + Number(c) + Number(d)

  return (
    <ErrorBoundary message="Account Balances">
      {props.balances.status === 'loading' && (
        <TablePlaceholder>
          <IconLoading />
          <span>Loading, please wait</span>
        </TablePlaceholder>
      )}
      {props.balances.status === 'done' && (
        <Actions margin="0px 0px 12px 0px" justify="flex-end">
          <Button onClick={toggleExportModal}>Export Balance Statement</Button>
          <DownloadAccountBalancesForm
            modalOpen={modalOpen}
            accountId={accountId}
            toggleExportModal={toggleExportModal}
            balances={rows}
          />
        </Actions>
      )}
      {props.balances.status === 'done' &&
        list.length === 0 &&
        accountType === 'SUB_ACCOUNT' && (
          <TablePlaceholder>This Account has no balances</TablePlaceholder>
        )}
      {props.balances.status === 'done' &&
        list.length > 0 &&
        accountType === 'SUB_ACCOUNT' && (
          <>
            <TitleContainer>
              {`Total ZAR balance: ${addTotalAmount(rows)}`}
            </TitleContainer>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {list && list.length === 0 && (
                        <TableCell colSpan={6}>
                          <TablePlaceholder>
                            {"Account doesn't have any balances"}
                          </TablePlaceholder>
                        </TableCell>
                      )}
                      {stableSort(rows, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.name}
                            >
                              <TableCell id={labelId} scope="row">
                                {row.currency}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? `${formatZar(
                                      addAllAssets(
                                        row.available,
                                        row.reserved,
                                        row.borrowCollateralReserved,
                                        row.lendReserved,
                                      ),
                                    )}`
                                  : `${addAllAssets(
                                      row.available,
                                      row.reserved,
                                      row.borrowCollateralReserved,
                                      row.lendReserved,
                                    )} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? `${formatZar(row.borrowedAmount)}`
                                  : `${row.borrowedAmount} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? `${formatZar(row.total)}`
                                  : `${row.total} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? `${formatZar(row.available)}`
                                  : `${row.available} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? ` ${formatZar(row.reserved)}`
                                  : `${row.reserved} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? ` ${formatZar(
                                      row.borrowCollateralReserved,
                                    )}`
                                  : `${row.borrowCollateralReserved} ${row.shortName}`}
                              </TableCell>
                              <TableCell>
                                {row.shortName === 'ZAR'
                                  ? ` ${formatZar(row.lendReserved)}`
                                  : `${row.lendReserved} ${row.shortName}`}
                              </TableCell>
                              <TableCell>{formatZar(row.zarTotal)}</TableCell>
                            </TableRow>
                          )
                        },
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        )}
      {status === 'done' && accountType === 'PRIMARY' && (
        <>
          <AllBalances />
        </>
      )}
    </ErrorBoundary>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBalances,
      fetchAllAccountBalances,
    },
    dispatch,
  )

const mapStateToProps = (state) => ({
  balances: getAccountBalances(state),
  allBalances: state.balances.allBalances,
  details: getAccountDetails(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountBalances)
