import { TableCell } from '@material-ui/core'
import styled from 'styled-components'

export const StyledExpandedSectionContainer = styled.div`
  padding: 16px;
`

export const StyledExpandTableCell = styled(TableCell)`
  padding: 0px;
`
