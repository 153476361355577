import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import { addUserActionComment } from 'redux/userComments/actions'
import ErrorToast from 'components/molecules/ErrorToast'

import config from '../../config'
import { updateCustomerType } from '../../redux/customerType/actions'
import { getBooleanFromValue } from '../../helpers/utils'

export const submitNewCustomerType = async (values, dispatch) => {
  try {
    const { customerType, comment = '', id } = values
    const res = await getAxios().post('/change/customer-type', {
      accountId: id,
      type: customerType.value,
      comment,
    })
    if (getBooleanFromValue(config.changeCustomerTypeByDualAuth)) {
      const {
        data: { dualAuthId },
      } = res.data
      values.dualAuthId = dualAuthId
      values.dualAuthStatus = 'pending'
      toast('Customer type change initiated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    } else {
      dispatch(
        updateCustomerType({
          id,
          type: customerType.value,
          comment,
        }),
      )
      toast('Customer type updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
    await addUserActionComment(values)
  } catch (e) {
    ErrorToast(e)
  }
}
