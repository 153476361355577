import React from 'react'
import './Styles.css'
import Select from '@material-ui/core/Select'
import { RenderCheckbox } from 'forms/fields'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { UNRESOLVED_FIAT_DEPOSIT_TITLES } from 'helpers/const'
import { hasExchangeFiatBulkEditorRole } from 'helpers/roleBasedAccess'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { TransitionCell } from '../Styles'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { showSendEmailActionButton } from 'components/atoms/ExpandingItem/helpers'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    height: 70,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export const Headers = (props) => {
  const {
    expandItem,
    bulkActionsActive,
    isCellHidden,
    toggleSelectAll,
    clearSelectedDeposits,
    title,
    handleActionTypeChange,
    actionType,
    isAllDepositsSelected,
    highlightedId,
  } = props

  const isFallBackDepositsTable =
    title === UNRESOLVED_FIAT_DEPOSIT_TITLES.FALL_BACK_IMPORT

  const classes = useStyles()

  const showSelectActionTypes = () => {
    const actions = ['SUSPEND', 'REJECT', 'SEND_EMAIL']
    if (hasExchangeFiatBulkEditorRole()) {
      actions.unshift('APPROVE')
    }
    return actions.map((action) => (
      <MenuItem key={action} value={action}>
        {action}
      </MenuItem>
    ))
  }

  return (
    <TableHead onClick={expandItem}>
      <TableRow>
        <TableCell>Account Id</TableCell>
        <TableCell>First Name</TableCell>
        <TableCell>Last Name</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Currency</TableCell>
        <TableCell>Reference</TableCell>
        <TableCell>Bank Date</TableCell>
        <TableCell>Inserted At</TableCell>
        <TableCell colSpan={1}>Viewer</TableCell>
        <TableCell colSpan={1}>Tag</TableCell>
        {showSendEmailActionButton(title) && <TableCell>Send Mail</TableCell>}
        {isFallBackDepositsTable && bulkActionsActive && (
          <TransitionCell
            className={bulkActionsActive ? 'suspendActive' : 'suspendFade'}
            show={isCellHidden}
            flex={2}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Action</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={actionType}
                onChange={({ target: { value } }) => {
                  handleActionTypeChange(value)
                  clearSelectedDeposits()
                }}
              >
                {showSelectActionTypes()}
              </Select>
            </FormControl>
          </TransitionCell>
        )}
        <TransitionCell
          className={bulkActionsActive ? 'suspendActive' : 'suspendFade'}
          show={isCellHidden}
        >
          <TableCell>
            <RenderCheckbox
              onCheckFunction={() => {
                if (isFallBackDepositsTable && !actionType) {
                  return ErrorToast(
                    'Please select an action type before selecting deposits',
                  )
                }
                toggleSelectAll()
              }}
              checked={isAllDepositsSelected}
              label={'Select All'}
            />
          </TableCell>
        </TransitionCell>
      </TableRow>
    </TableHead>
  )
}
