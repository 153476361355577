import { toast } from 'react-toastify'
import { updateInternalTransfers } from 'redux/pendingInternalTransfers/actions'

export const submitDepositReview = async (values, dispatch) => {
  const {
    status,
  } = values
  const internalTransfersData = [values.transfer]
  const action = status.value
  try {
    await dispatch(updateInternalTransfers(internalTransfersData, action))
  } catch (e) {
    toast(`${e} `, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
