import {
  FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_SUCCESS,
  FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_ERROR,
  FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_PROCESS,
  APPROVE_CREDIT_CARD_ATTEMPT,
  APPROVE_CREDIT_CARD_ATTEMPT_ERROR,
  REJECT_CREDIT_CARD_ATTEMPT,
  REJECT_CREDIT_CARD_ATTEMPT_ERROR,
} from './actions'

const initialState = {
  creditCardDepositReview: {
    status: 'pristine',
    data: [],
  },

}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_PROCESS: {
      return {
        ...state,
        creditCardDepositReview: {
          status: 'loading',
        },
      }
    }

    case FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        creditCardDepositReview: {
          status: 'done',
          data,
        },
      }
    }
    case APPROVE_CREDIT_CARD_ATTEMPT: {
      const { approvedDeposit } = action.payload
      const filteredArray = state.creditCardDepositReview.data.filter(item => item.identifier !== approvedDeposit)
      return {
        ...state,
        creditCardDepositReview: {
          status: 'done',
          data: filteredArray,
        },
      }
    }

    case APPROVE_CREDIT_CARD_ATTEMPT_ERROR: {
      return {
        ...state,
        creditCardDepositReview: {
          status: 'error',
          data: [],
        },
      }
    }

    case REJECT_CREDIT_CARD_ATTEMPT: {
      const { rejectedDeposit } = action.payload
      const filteredArray = state.creditCardDepositReview.data.filter(item => item.identifier !== rejectedDeposit)
      return {
        ...state,
        creditCardDepositReview: {
          status: 'done',
          data: filteredArray,
        },
      }
    }

    case REJECT_CREDIT_CARD_ATTEMPT_ERROR: {
      return {
        ...state,
        creditCardDepositReview: {
          status: 'error',
          data: [],
        },
      }
    }


    case FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_ERROR: {
      return {
        ...state,
        wiredDepositReview: {
          status: 'error',
          data: [],
        },
      }
    }
    default:
      return state
  }
}
