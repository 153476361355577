import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { ContentContainer } from 'components/atoms/Generic'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import OrderBooks from 'components/organisms/OrderBooks'
import StopOrders from 'components/organisms/StopOrders'
import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'

const Spot = (props) => {
  const routes = [
    '/exchange/trading/spot',
    '/exchange/trading/spot/stop-orders',
  ]

  const tabs = ['open-orders', 'stop-orders']

  const [currentPage, setCurrentPage] = useState(tabs[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const checkPath = (path) => {
    tabs.forEach((tab) => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  const memoizedOrderBooks = useCallback(
    (props) => <OrderBooks type={'Spot'} {...props} />,
    [currentPage],
  )
  const memoizedStopOrders = useCallback(
    (props) => <OrderBooks type={'Spot'} {...props} />,
    [currentPage],
  )

  useEffect(() => {
    checkPath(pathname)
  }, [pathname])

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'open-orders'}
          label="Open Orders"
          component={ForwardedNavLink}
          to={routes[0]}
        />
        <NavigationTab
          value={'stop-orders'}
          label="Stop Orders"
          component={ForwardedNavLink}
          to={routes[1]}
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={memoizedOrderBooks} exact />
          <Route path={routes[1]} component={memoizedStopOrders} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Spot
