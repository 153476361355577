import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StyledChip } from './styles'

/**
 * Insert text at cursor position.
 * @param {string} label is the text displayed in the label
 * @param {string} chipType will have to be added to the handleColor function
 * @param {string} variant can be "outlined" | "filled"
 * @param {string} size "small" | "medium"
 * @param {number} width custom chip sizing
 * @param {number} height custom chip sizing
 */

export default class Chip extends Component {
  handleColor(chipType) {
    let color = ''
    switch (chipType) {
      case 'external':
        color = '#00d404'
        break
      case 'internal':
        color = '#ff9d00'
        break
      default:
        break
    }
    return color
  }

  render() {
    const {
      chipType,
      icon,
      size,
      label,
      variant,
      width,
      height,
    } = this.props
    return (
      <StyledChip
        icon={icon}
        size={size}
        width={width}
        label={label}
        height={height}
        color={this.handleColor(chipType)}
        variant={variant}
      />
    )
  }
}

Chip.propTypes = {
  text: PropTypes.string,
  chipType: PropTypes.oneOf([
    'external',
    'internal',
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  width: PropTypes.number,
  height: PropTypes.number,
}

Chip.defaultProps = {
  variant: 'outlined',
  text: 'Text goes here',
}
