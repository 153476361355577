import React, { FC, memo, useMemo } from 'react'
import { KeycloakGroup } from '../interfaces'
import KeycloakAdminGroup from './KeycloakAdminGroup'
import { StyledSubGroupsSection } from '../Styles'
interface ISubgroups {
  subgroups: KeycloakGroup[]
  fetchingMembersForGroupName: string
  handleFetchMembersForGroup: (group: KeycloakGroup) => Promise<void>
}
const Subgroups: FC<ISubgroups> = memo(
  ({ subgroups, handleFetchMembersForGroup, fetchingMembersForGroupName }) => {
    const memoizedSubGroups = useMemo(
      () => subgroups.sort((g) => (g.subGroupCount > 0 ? -1 : 1)),
      [subgroups],
    )
    return (
      <StyledSubGroupsSection>
        <h3>Parent groups: </h3>
        {memoizedSubGroups.map((group) => (
          <KeycloakAdminGroup
            isFetchingMembers={fetchingMembersForGroupName === group.name}
            handleFetchMembersForGroup={handleFetchMembersForGroup}
            key={group.id}
            group={group}
          />
        ))}
      </StyledSubGroupsSection>
    )
  },
)

export default Subgroups
