import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { requiredDropdownValue } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { initiateCheck, fetchWatchlistCheckById } from 'redux/singleAccountSanctions/actions'
import { renderDropdown } from '../fields'

const Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return (
    <FormContainer style={ { width: '50%' } } onSubmit={ handleSubmit(onSubmit) }>
      <FormTitle>Initiate Check</FormTitle>
      <InputGroup align={ 'flex-start' } direction={ 'column' }>
        <Field
          name={ 'sanctionConfigOptions' }
          label={ 'Select' }
          width={ '100%' }
          validate={ [requiredDropdownValue] }
          component={ renderDropdown }
          options={ [
            { label: 'Run Local Sanction', value: 'run_local_sanctions' },
            { label: 'Run Onfido Sanction', value: 'run_onfido_sanctions' },
            { label: 'Run Both', value: 'run_local_and_onfido_sanctions' },
          ] }
        />
      </InputGroup>
      { error && <FormError error={ error } /> }
      <InputGroup justify={ 'flex-end' }>
        <Button type="submit" disabled={ submitting || invalid } margin='0 16px 0 0'>
          Initiate
        </Button>
        <Button onClick={ onCancel } color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

const InitiateSanctionsChecks = reduxForm({
  form: 'InitiateSanctionsChecks',
  onSubmit: initiateCheck,
  onSubmitSuccess: (result, dispatch, props) => {
    props.onCancel()
    dispatch(fetchWatchlistCheckById(props.values.accountId))
  },
})(Form)

export default InitiateSanctionsChecks
