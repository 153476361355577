import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ShowStatus } from 'components/atoms/Generic/index'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import ErrorBoundary from 'helpers/ErrorBoundary'

import {
  TablePlaceholder,
  TitleContainer,
  Actions,
  ActionLabel,
  ActionInput,
} from 'components/atoms/Table/styles'
import { fetchPendingDualAuth } from 'redux/dual-auth/actions'
import SendsRow from 'components/organisms/SendRow'
import IconLoading from 'components/atoms/IconLoading'
import { fetchAccountSends, clearAccountSendsList } from 'redux/sends/actions'
import { getAccountSends } from 'redux/selectors/sends'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'address',
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'confirmations',
    disablePadding: false,
    label: 'Confirmations',
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'feeAmount',
    disablePadding: false,
    label: 'Fee Amount',
  },
  {
    id: 'createdAt',
    disablePadding: false,
    label: 'Created At',
  },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, sortItems } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={'left'}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountSends(props) {
  const { accountId } = props.match.params
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [filter, setFilter] = React.useState('')
  const [highlightedId, setHighlightedId] = React.useState('')
  const [skip, setSkip] = React.useState(0)
  const [limit, setLimit] = React.useState(100)
  const { sends } = props

  const checkIfIsPending = (withdrawal, dualAuth) => {
    const pendingValue = dualAuth.data.some(value => {
      if (value.actionType === 'BLOCKCHAIN_SEND_REVERSAL') {
        return value.sendId === withdrawal.withdrawalId
      }
    })
    return pendingValue
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const ref = useRef()

  useEffect(() => {
    props.fetchPendingDualAuth()
    props.fetchAccountSends({ id: accountId, skip, limit })
    return () => props.clearAccountSendsList()
  }, [])

  const fetchMoreData = async () => {
    const { limit: sendsLimit } = props.sends
    if (props.sends.hasMoreRecords) {
      setSkip(skip + sendsLimit)
    }
    await props.fetchAccountSends({
      fetchMoreData: true,
      id: accountId,
      skip: skip + sendsLimit,
      limit: sendsLimit,
    })
  }

  const filterData = data => {
    if (!filter) return data
    const filteredData = data.filter(item => {
      const { status, sendAddress, confirmations, amount, feeAmount } = item
      const statusFilter = status.toLowerCase().includes(filter)
      const sendAddressFilter = sendAddress.toLowerCase().includes(filter)
      const amountFilter = amount && amount.includes(filter)
      const confirmationsFilter = confirmations.toString().includes(filter)
      const feeAmountFilter = feeAmount && feeAmount.includes(filter)

      return (
        statusFilter ||
        sendAddressFilter ||
        amountFilter ||
        confirmationsFilter ||
        feeAmountFilter
      )
    })
    return filteredData
  }

  const sendsData = stableSort(
    filterData((sends && sends.data) || []),
    getComparator(order, orderBy),
  )

  const returnEndMessage = sendsList => {
    if (
      sendsList.data &&
      sendsList.data.length === 0 &&
      sendsList.status === 'done'
    ) {
      return (
        <TablePlaceholder>
          <ShowStatus>This account has no transactions</ShowStatus>
        </TablePlaceholder>
      )
    }

    if (sendsList.status === 'loading') {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait...
          </ShowStatus>
        </TablePlaceholder>
      )
    }
    if (!sends.hasMoreRecords) {
      return (
        <TablePlaceholder>
          <ShowStatus>No more transactions to show</ShowStatus>
        </TablePlaceholder>
      )
    }
  }

  const { reviews } = props
  return (
    <ErrorBoundary message="Account Sends">
      <>
        <TitleContainer ref={ref}>
          <Actions justify="flex-end">
            <ActionLabel>Filter:</ActionLabel>
            <ActionInput
              marginRight={0}
              onChange={event => setFilter(event.target.value.toLowerCase())}
            />
          </Actions>
        </TitleContainer>
        {sends && (
          <>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <InfiniteScroll
                    scrollableTarget={'test'}
                    dataLength={sends.data ? sends.data.length : 0}
                    next={fetchMoreData}
                    hasMore={sends.hasMoreRecords}
                    useWindow={false}
                    loader={
                      sends.status === 'loading' ? (
                        <TablePlaceholder>
                          <ShowStatus>
                            <IconLoading />
                            Loading...
                          </ShowStatus>
                        </TablePlaceholder>
                      ) : (
                        <></>
                      )
                    }
                    onScroll={() => {}}
                    height={600}
                    endMessage={returnEndMessage(sends)}
                  >
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={sends.data && sends.data.length}
                      />
                      <TableBody>
                        <>
                          {sendsData.map(tx => {
                            const review =
                              reviews.byId[tx.reviewItemId] &&
                              reviews.byId[tx.reviewItemId].data
                            return (
                              <SendsRow
                                fetchPendingDualAuth={
                                  props.fetchPendingDualAuth
                                }
                                setHighlightedId={setHighlightedId}
                                isHighlighted={highlightedId === tx.uniqueId}
                                tx={tx}
                                key={tx.uniqueId}
                                rawData={{ ...tx, review }}
                                dualAuthData={props.dualAuth}
                                checkIfIsPending={checkIfIsPending}
                              />
                            )
                          })}
                        </>
                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Paper>
            </div>
          </>
        )}
      </>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps.match.params
  return {
    sends: getAccountSends(state)(accountId),
    reviews: state.reviews,
    dualAuth: state.pendingDualAuth,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountSends,
      fetchPendingDualAuth,
      clearAccountSendsList,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSends)
