import { Button } from '@material-ui/core'
import { FormContainer, InputGroup } from 'components/atoms/Form'
import { Flexbox } from 'components/layout'
import { connect } from 'react-redux'
import { renderInput } from 'forms/fields'
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { saveMarginRequirementsSettings } from 'redux/marginSettings/actions'
import { required } from 'forms/fieldValidators'

const SaveMarginRequirementsForm = ({
  onCancel, onSubmit, handleSubmit, submitting, invalid,
  dirty,
}) => (
  <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <InputGroup width={'100%'} align="flex-start" justify="space-between">
      <InputGroup justify={'space-between'} direction={'column'}>
        <Field
          width="95%"
          margin="8px"
          label={'Leverage Multiple'}
          name={'leverageMultiple'}
          component={renderInput}
          validate={[required]}
        />
        <Field
          width="95%"
          margin="8px"
          label={'Maintenance Margin Multiple'}
          name={'maintenanceMarginMultiple'}
          component={renderInput}
          validate={[required]}
        />
        <Field
          width="95%"
          margin="8px"
          label={'Auto Close Margin Multiple'}
          name={'autoCloseMarginMultiple'}
          component={renderInput}
          validate={[required]}
        />
        <Field
          width="95%"
          margin="8px"
          label={'Reference Currency'}
          name={'referenceCurrency'}
          component={renderInput}
          validate={[required]}
        />
      </InputGroup>
      <InputGroup justify="space-between" direction="column">
        <Field
          width="95%"
          margin="8px"
          label={'Initial Margin Fraction'}
          name={'initialMarginFraction'}
          component={renderInput}
          disabled
          validate={[required]}
        />
        <Field
          width="95%"
          margin="8px"
          label={'Maintenance Margin Fraction'}
          name={'maintenanceMarginFraction'}
          component={renderInput}
          disabled
          validate={[required]}
        />
        <Field
          width="95%"
          margin="8px"
          label={'Auto Close MarginFraction'}
          name={'autoCloseMarginFraction'}
          component={renderInput}
          disabled
          validate={[required]}
        />
      </InputGroup>
    </InputGroup>
    <Flexbox justify="flex-end">
      <Flexbox justify="space-around">
        <Button type="submit" disabled={submitting || invalid || !dirty} style={{ marginRight: '15px' }} padding='9px 40px'>
                    Sav{submitting ? 'ing' : 'e'}
        </Button>
        <Button disabled={submitting} type="button" onClick={onCancel} color='secondary'>
                    Close
        </Button>
      </Flexbox>
    </Flexbox>
  </FormContainer>
)

export default connect()(reduxForm({
  form: 'marginRequirementsForm',
  enableReinitialize: true,
  onSubmit: (values, dispatch) => dispatch(saveMarginRequirementsSettings(values)),
})(SaveMarginRequirementsForm))
