import React, { FC, Fragment } from 'react'
import WithdrawalRestrictionRow from './row'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LoadingBlock from 'components/molecules/LoadingBlock'

interface WithdrawalRestrictionsTableProps {
  withdrawalRestrictionsData: any
  currencies: any
  accountId: string
  status: string
}

const WithdrawalRestrictionsTable: FC<WithdrawalRestrictionsTableProps> = ({
  withdrawalRestrictionsData,
  currencies,
  accountId,
  status,
}) => (
  <Fragment>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell>Currency</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Created At</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      {status === 'loading' && (
        <TableRow>
          <TableCell colSpan={4}>
            <LoadingBlock />
          </TableCell>
        </TableRow>
      )}
      {status === 'done' && withdrawalRestrictionsData.length === 0 && (
        <TableRow>
          <TableCell colSpan={4}>
            {'This account has no withdrawal restrictions'}
          </TableCell>
        </TableRow>
      )}
      {status === 'done' &&
        withdrawalRestrictionsData.length > 0 &&
        withdrawalRestrictionsData.map((restriction, i) => (
          <WithdrawalRestrictionRow
            key={i}
            withdrawalRestriction={restriction}
            currencies={currencies}
            accountId={accountId}
          />
        ))}
    </Table>
  </Fragment>
)

export default WithdrawalRestrictionsTable
