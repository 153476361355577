import ErrorToast from 'components/molecules/ErrorToast'
import {
  KeycloakGroup,
  KeycloakMemberWithRoles,
} from 'containers/Keycloak/interfaces'
import getAxios from 'helpers/axios'
import { useCallback, useEffect, useState } from 'react'

export function useKeycloakAdminGroups(
  fetchKeycloakAdminGroupsWithSubGroups: () => Promise<void>,
) {
  const defaultMembersInGroupModalState: {
    groupName: string
    members: KeycloakMemberWithRoles[]
    isOpen: boolean
    fetchingMembersForGroupName: string
    isFetchingUserRoles: string
  } = {
    groupName: '',
    members: [],
    isOpen: false,
    fetchingMembersForGroupName: '',
    isFetchingUserRoles: '',
  }
  const [membersInGroupModalState, setMembersInGroupModalState] = useState(
    defaultMembersInGroupModalState,
  )

  const handleFetchMembersForGroup = useCallback(
    async (group: KeycloakGroup) => {
      try {
        setMembersInGroupModalState((prev) => ({
          ...prev,
          isOpen: true,
          fetchingMembersForGroupName: group.name,
        }))
        const { data } = await getAxios().get(`keycloak/${group.id}/members`)

        setMembersInGroupModalState((prev) => ({
          ...prev,
          groupName: group.name,
          members: data,
          fetchingMembersForGroupName: '',
        }))
      } catch (error) {
        ErrorToast('Error fetching members.')
        setMembersInGroupModalState((prev) => ({
          ...prev,
          isOpen: false,
          fetchingMembersForGroupName: '',
        }))
      }
    },
    [],
  )

  const handleFetchUserRoles = async (memberId: string) => {
    try {
      setMembersInGroupModalState((prev) => ({
        ...prev,
        isFetchingUserRoles: memberId,
      }))
      const { data } = await getAxios().get(`keycloak/${memberId}/roles`)
      setMembersInGroupModalState((prev) => {
        const updatedState = { ...prev }
        const indexOfMember = updatedState.members.findIndex(
          (m) => m.id === memberId,
        )
        if (indexOfMember > -1) {
          updatedState.members[indexOfMember].roles = [
            ...new Set(data),
          ] as string[]
        }
        updatedState.isFetchingUserRoles = ''
        return updatedState
      })
    } catch (error) {
      ErrorToast('Error fetching user roles.')
      setMembersInGroupModalState((prev) => ({
        ...prev,
        isFetchingUserRoles: '',
      }))
    }
  }

  const handleCloseMembersGroupModal = () =>
    setMembersInGroupModalState(defaultMembersInGroupModalState)

  useEffect(() => {
    fetchKeycloakAdminGroupsWithSubGroups()
    return () => {}
  }, [])
  return {
    membersInGroupModalState,
    handleFetchMembersForGroup,
    handleCloseMembersGroupModal,
    handleFetchUserRoles,
  }
}

export default useKeycloakAdminGroups
