type DocumentLabel = 'LETTER OF AUTHORITY DOCUMENT' | 'SOURCE OF FUNDS DOCUMENT' | 'OTHER SUPPORTING DOCUMENT' | 'ONBOARDING QUESTIONNAIRE'
type DocumentType = 'edd-letter_of_authority_document' | 'edd-source_of_funds_document' | 'edd-other_supporting_document' | 'edd-onboarding_questionnaire_document'

export interface RISK_PROFILE_EDD_DOCUMENT_TYPE {
    documentLabel: DocumentLabel
    documentType: DocumentType
}

export const RISK_PROFILE_EDD_DOCUMENT_TYPES: RISK_PROFILE_EDD_DOCUMENT_TYPE[] = [
  {
    documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
    documentType: 'edd-letter_of_authority_document',
  },
  {
    documentLabel: 'SOURCE OF FUNDS DOCUMENT',
    documentType: 'edd-source_of_funds_document',
  },
  {
    documentLabel: 'OTHER SUPPORTING DOCUMENT',
    documentType: 'edd-other_supporting_document',
  },
  {
    documentLabel: 'ONBOARDING QUESTIONNAIRE',
    documentType: 'edd-onboarding_questionnaire_document',
  },
]

export type AllowedRiskProfileEddDocumentType = 'image/*' | '.pdf' | 'image/heic'

export const ALLOWED_RISK_PROFILE_EDD_DOCUMENT_TYPES: AllowedRiskProfileEddDocumentType[] = ['image/*', 'image/heic', '.pdf']
