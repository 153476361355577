import React, { useRef, useState } from 'react'
import './styles.css'
import { Button } from 'components/atoms/Form/index'
import ImageIcon from '@material-ui/icons/Image'
import { FILE_TYPES } from 'containers/Accounts/SingleAccount/CorporateAccount/const/Entities'
import {
  StyledFileUploaderContent,
  StyledFileUploaderWrapper,
  StyledImage,
} from './Styles'
import HeicToImage from './HeicToImage'

const UPLOAD_ERROR_TYPES = {
  invalidFileType: 'File type not allowed',
  fileSizeTooLarge: 'File too large max file size allowed is',
}

function FileUploader({
  acceptedFileTypes = ['image/*'],
  showUploadButton = true,
  disabled,
  uploadButtonText = 'Upload Document',
  input: { onChange } = { onChange: () => {} },
  handleChange = () => {},
  handleUpload = () => {},
  //   Styles
  height,
  width,
  manual = false,
  clearFile,
}) {
  const [file, setFile] = useState('')
  const fileInputRef = useRef()
  // MB
  const FILE_SIZE_LIMIT_MB = 20
  const bytesToMegaBytes = (bytes) => bytes / 1024 / 1024
  const [uploadError, setUploadError] = useState('')

  function previewSelectedPdfFile(src) {
    return <embed src={`${src}#toolbar=0`} width="250" height="350" />
  }

  function renderFileView() {
    if (!file) return ''
    if (file.type === FILE_TYPES.HEIC) return <HeicToImage file={file} />
    if (file.type === FILE_TYPES.DOCX) return <p>{file.name} selected</p>
    const src = URL.createObjectURL(file)
    if (file.type === FILE_TYPES.PDF) return previewSelectedPdfFile(src)
    return <StyledImage style={{ width: '100%' }} src={src} alt="file" />
  }

  const checkFileSize = (fileSize) => {
    return bytesToMegaBytes(fileSize) > FILE_SIZE_LIMIT_MB
  }

  const handleSelectedFile = (fileData) => {
    if (!fileData) return ''
    if (checkFileSize(fileData.size)) {
      return setUploadError(
        `${UPLOAD_ERROR_TYPES.fileSizeTooLarge} ${FILE_SIZE_LIMIT_MB}MB`,
      )
    }
    setFile(fileData)
    if (!manual) {
      return onChange(fileData)
    }
    return handleChange(fileData)
  }
  const checkFileTypesAndSize = (
    allowedFileTypes = ['image'],
    fileType,
    fileSize,
  ) => {
    let fileCheckSuccess = true
    if (!fileType || !fileSize) {
      fileCheckSuccess = false
      return setUploadError(`${UPLOAD_ERROR_TYPES.invalidFileType}`)
    }

    allowedFileTypes.forEach((type) => {
      if (!fileType.includes(type)) {
        fileCheckSuccess = false
        return setUploadError(
          `${UPLOAD_ERROR_TYPES.invalidFileType} ${fileType}`,
        )
      }
      if (checkFileSize(fileSize)) {
        fileCheckSuccess = false
        return setUploadError(
          `${UPLOAD_ERROR_TYPES.fileSizeTooLarge} ${FILE_SIZE_LIMIT_MB}MB`,
        )
      }
      setUploadError('')
    })
    return fileCheckSuccess
  }

  const drop = (e) => {
    e.preventDefault()
    if (e.dataTransfer) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i]
        if (item.kind === 'file') {
          const fileItem = item.getAsFile()
          if (fileItem) {
            const allowed = checkFileTypesAndSize(
              ['image'],
              fileItem.type,
              fileItem.size,
            )
            if (allowed) setFile(fileItem)
          }
        }
      }
    }
  }

  return (
    <>
      <StyledFileUploaderWrapper
        id="abc"
        onDragEnter={(e) => {
          e.preventDefault()
        }}
        onDragStart={(e) => {
          e.preventDefault()
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={drop}
      >
        <StyledFileUploaderContent>
          {!file && <p>Click here or drag and drop files to upload</p>}
          {!file && (
            <ImageIcon
              style={{
                opacity: 0.1,
                height: height || 300,
                width: width || 300,
              }}
            />
          )}
          {!file && <Button>Upload file</Button>}
          {file && file.name && renderFileView()}
          {uploadError && <p>{uploadError}</p>}
          <input
            ref={fileInputRef}
            aria-hidden="true"
            disabled={disabled}
            accept={acceptedFileTypes}
            type="file"
            onChange={({ target: { files } }) => handleSelectedFile(files[0])}
          />
        </StyledFileUploaderContent>
      </StyledFileUploaderWrapper>
      {file && file.name && (
        <p>File Name: {file.name || 'No name for this file'}</p>
      )}
      {showUploadButton && file && (
        <Button
          style={{ marginTop: 20 }}
          onClick={async () => {
            await handleUpload(file)
            if (clearFile) {
              setFile('')
              fileInputRef.current.value = ''
            }
          }}
        >
          {uploadButtonText}
        </Button>
      )}
    </>
  )
}

export default FileUploader
