import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAccountsWithMarginSummary } from 'redux/margin/actions'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableContainer from '@material-ui/core/TableContainer'
import { styled } from '@material-ui/core/styles'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { EnhancedTableHead } from './tableHead'
import { getComparator } from 'helpers/tables'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from 'helpers/tables'
import { Actions, TitleContainer } from 'components/atoms/Table/styles'
import { renderInput as RenderInput } from 'forms/fields'

class AccountsWithMarginInfoSummary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedRow: '',
      order: 'desc',
      orderBy: 'leverageMultiple',
      filter: '',
    }
  }

  componentDidMount() {
    this.props.fetchAccountsWithMarginSummary()
  }

  toggleSelectedRow = (id) => {
    if (id !== this.state.expandedRow) {
      this.setState({ expandedRow: id })
    } else {
      this.setState({ expandedRow: '' })
    }
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  tableSort = (array, order, orderBy) => {
    return array.sort((a, b) => {
      a.marginFraction.accountId = a.accountId
      b.marginFraction.accountId = b.accountId
      const aVal = a.marginFraction[orderBy]
      const bVal = b.marginFraction[orderBy]
      if (!isNaN(aVal)) {
        a.marginFraction[orderBy] = parseFloat(aVal)
      }
      if (!isNaN(bVal)) {
        b.marginFraction[orderBy] = parseFloat(bVal)
      }

      const ordered = getComparator(order, orderBy)(
        a.marginFraction,
        b.marginFraction,
      )
      if (ordered !== 0) return ordered
    })
  }

  filterData = (data) =>
    data.filter((item) => {
      const { filter } = this.state
      const { accountId } = item
      if (accountId.toString().toLowerCase().includes(filter.toLowerCase())) {
        item.marginFraction.accountLeverageHealth = Math.round(item.marginFraction.marginFraction / item.marginFraction.maintenanceMarginFraction * 100)
        return item
      }
    })

  handleFilter = (value) => {
    this.setState({ filter: value })
  }

  render() {
    const Container = styled(TableContainer)({
      maxHeight: 1400,
    })

    const {
      accountsWithMarginSummary: { status, data },
    } = this.props.margin
    const classes = withStyles(useStyles)
    const { filter, order, orderBy, expandedRow } = this.state
    return (
      <Fragment>
        <h3>Platform Summary</h3>
        {status === 'done' && data.length > 0 && (
          <TitleContainer>
            <Actions justify="flex-end" margin="10px 0">
              <RenderInput
                value={filter}
                marginRight={0}
                onChange={(event) => this.handleFilter(event.target.value)}
                meta={{}}
                label={'Search account by #'}
              />
            </Actions>
          </TitleContainer>
        )}
        <Paper>
          <Container>
            <Table
              className={classes.table}
              aria-label="sticky table"
              stickyHeader={true}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {status === 'loading' && (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <LoadingBlock />
                    </TableCell>
                  </TableRow>
                )}
                {status === 'done' &&
                  data &&
                  this.tableSort(this.filterData(data), order, orderBy).map(
                    (marginInfo, index) => {
                      const {
                        collateralisedMarginFraction,
                        totalBorrowedInReference,
                        referenceCurrency,
                        marginFraction,
                        leverageMultiple,
                        collateralisedBalancesInReference,
                        autoCloseMarginFraction,
                        accountLeverageHealth,
                      } = marginInfo.marginFraction
                      return (
                        <Fragment key={index}>
                          <TableRow
                            onClick={() => this.toggleSelectedRow(index)}
                          >
                            <TableCell>{marginInfo.accountId}</TableCell>
                            <TableCell>{totalBorrowedInReference}</TableCell>
                            <TableCell>{referenceCurrency}</TableCell>
                            <TableCell>{leverageMultiple}</TableCell>
                            <TableCell>{marginFraction}</TableCell>
                            <TableCell>
                              {collateralisedBalancesInReference}
                            </TableCell>
                            <TableCell>{autoCloseMarginFraction}</TableCell>
                            <TableCell>
                              {collateralisedMarginFraction}
                            </TableCell>
                            <TableCell>
                              {`${accountLeverageHealth}%`}
                            </TableCell>
                          </TableRow>
                          {expandedRow === index && (
                            <TableCell colSpan={8}>
                              <Details>
                                <DetailsBlockWithTitle title={'Raw Data'}>
                                  <RawData
                                    data={marginInfo}
                                    title={'Raw Data'}
                                  />
                                </DetailsBlockWithTitle>
                              </Details>
                            </TableCell>
                          )}
                        </Fragment>
                      )
                    },
                  )}
              </TableBody>
            </Table>
          </Container>
        </Paper>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  margin: state.margin,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountsWithMarginSummary,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsWithMarginInfoSummary)
