import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_REVIEW_PROCESS = 'review/FETCH_REVIEW_PROCESS'
export const FETCH_REVIEW_SUCCESS = 'review/FETCH_REVIEW_SUCCESS'
export const FETCH_REVIEW_ERROR = 'review/FETCH_REVIEW_ERROR'

export const fetchReviewItem = reviewId => async dispatch => {
  dispatch({
    type: FETCH_REVIEW_PROCESS,
    payload: {
      id: reviewId,
    },
  })
  let res = null
  try {
    res = await getAxios().get(`/admin/review/${reviewId}`)
    const { data } = res
    dispatch({
      type: FETCH_REVIEW_SUCCESS,
      payload: {
        id: reviewId,
        data: data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_REVIEW_ERROR,
      payload: {
        error: error.response.data,
      }
    })
  }
}
