import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { TransparentFormContainer, InputGroup } from 'components/atoms/Form'
import { renderCalenderDate, renderInput } from 'forms/fields'
import { isValidEmail } from 'forms/fieldValidators'

import { submitFilter } from './submit'

let Form = props => {
  const { submitting, invalid, handleSubmit, onSubmit } = props

  return (
    <TransparentFormContainer width="100%" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Account ID'}
          name={'id'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'12%'}
          flex={0.4}
          component={renderInput}
          placeholder={'Account-id'}
        />
        <Field
          label={'Admin Email'}
          name={'email'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'20%'}
          flex={0.4}
          component={renderInput}
          validate={[isValidEmail]}
          placeholder={'example@valr.com'}
        />
        <Field
          label={'User ID (Keycloak)'}
          name={'user'}
          margin={`0 ${BASE * 2}px 0 0`}
          width={'20%'}
          flex={0.4}
          component={renderInput}
          placeholder={'7379cc7c-b6b6-4ecf-ac53-9fc353487b86'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'Start time'}
          name={'from'}
          width={'40%'}
          flex={0.4}
          marginRight={BASE * 2}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
        />
        <Field
          style={{ marginTop: '0.6%' }}
          label={'End time'}
          name={'to'}
          width={'40%'}
          flex={0.4}
          marginRight={BASE * 2}
          component={renderCalenderDate}
          placeholder={'Date & Time'}
        />
        <MuiButton
          style={{ marginTop: '1%' }}
          type="submit"
          main="true"
          disabled={submitting || invalid}
        >
          Submit Filter
        </MuiButton>
      </InputGroup>
    </TransparentFormContainer>
  )
}

Form = reduxForm({
  form: 'historyLogs',
  validate: values => {
    const errors = {}
    if (
      !values.id &&
      !values.user &&
      !values.email &&
      !values.from &&
      !values.to
    ) {
      errors._error = 'At least one field shall be specified'
    }
    return errors
  },
  onSubmit: submitFilter,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
