import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  fetchInterestAuctionConfig,
} from 'redux/marginSettings/actions'
import { ConfigStyle } from './styles'
import UpdateLiqudationEngineSettings from './UpdateIntrestAuction'

class IntrestAuction extends Component {
  componentDidMount() {
    this.props.fetchInterestAuctionConfig()
  }

  removeCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
    return fixedCamelCasing
  }

  updateCamelCasing = (string) => {
    const fixCamelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
    const fixedCamelCasing = fixCamelCasing.toUpperCase()
    if (string === 'interestFeeAccountId') {
      return fixedCamelCasing.split(' ').slice(1).join('_')
    }
    return fixedCamelCasing.split(' ').join('_')
  }

  render() {
    const { interestAuctionStatus, interestAuctionData } = this.props.interestAuctionConfig
    return (
      <Fragment>
        <h3>Margin Requirements</h3>
        {interestAuctionStatus === 'loading' && <LoadingBlock />}
        {interestAuctionStatus === 'done' &&
          <>
            {interestAuctionStatus === 'done' && Object.entries(interestAuctionData).map(([key, value]) =>

              <>
                {typeof (value) === 'boolean' ?
                  <>
                    <ConfigStyle>
                      {this.removeCamelCasing(key)}: {value.toString()}
                    </ConfigStyle>
                  </>
                  :
                  <>
                    <ConfigStyle>
                      {this.removeCamelCasing(key)}: {value}
                    </ConfigStyle>
                  </>
                }
              </>)}
            {interestAuctionStatus === 'done' && <UpdateLiqudationEngineSettings
              data={interestAuctionData}
            />}
          </>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  interestAuctionConfig: state.marginSettings.interestAuctionConfig,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInterestAuctionConfig,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntrestAuction)
