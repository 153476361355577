import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_KEYCLOAK_USER_PROCESS = 'FETCH_KEYCLOAK_USER_PROCESS'
export const FETCH_KEYCLOAK_USER_SUCCESS = 'FETCH_KEYCLOAK_USER_SUCCESS'
export const FETCH_KEYCLOAK_USER_ERROR = 'FETCH_KEYCLOAK_USER_ERROR'

export const ADD_KEYCLOAK_USER_PROCESS = 'ADD_KEYCLOAK_USER_PROCESS'
export const ADD_KEYCLOAK_USER_SUCCESS = 'ADD_KEYCLOAK_USER_SUCCESS'
export const ADD_KEYCLOAK_USER_ERROR = 'ADD_KEYCLOAK_USER_ERROR'

export const FETCH_KEYCLOAK_USER_BY_EMAIL_PROCESS =
  'FETCH_KEYCLOAK_USER_BY_EMAIL_PROCESS'
export const FETCH_KEYCLOAK_USER_BY_EMAIL_SUCCESS =
  'FETCH_KEYCLOAK_USER_BY_EMAIL_SUCCESS'
export const FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR =
  'FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR'
export const RESET_USER = 'RESET_USER'

export const UNLINK_KYC_SOCIAL_ACCOUNTS_START =
  'UNLINK_KYC_SOCIAL_ACCOUNTS_START'
export const UNLINK_KYC_SOCIAL_ACCOUNTS_SUCCESS =
  'UNLINK_KYC_SOCIAL_ACCOUNTS_SUCCESS'
export const UNLINK_KYC_SOCIAL_ACCOUNTS_ERROR =
  'UNLINK_KYC_SOCIAL_ACCOUNTS_ERROR'

export const CHECK_KYC_SOCIAL_DUAL_AUTHS_START =
  'CHECK_KYC_SOCIAL_DUAL_AUTHS_START'
export const CHECK_KYC_SOCIAL_DUAL_AUTHS_SUCCESS =
  'CHECK_KYC_SOCIAL_DUAL_AUTHS_SUCCESS'
export const CHECK_KYC_SOCIAL_DUAL_AUTHS_ERROR =
  'CHECK_KYC_SOCIAL_DUAL_AUTHS_ERROR'

// export const FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS'
// export const FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS'
// export const FETCH_OTHER_AUTHO_ACCOUNTS_ERROR = 'auth0/FETCH_OTHER_AUTHO_ACCOUNTS_ERROR'
// export const LINK_AUTH0_ACCOUNTS_PROCESS = 'auth0/LINK_AUTH0_ACCOUNT_PROCESS'
// export const LINK_AUTH0_ACCOUNTS_SUCCESS = 'auth0/LINK_AUTH0_ACCOUNT_SUCCESS'
// export const LINK_AUTH0_ACCOUNTS_ERROR = 'auth0/LINK_AUTH0_ACCOUNT_ERROR'
// export const UNLINK_AUTH0_ACCOUNTS_PROCESS = 'auth0/UNLINK_AUTH0_ACCOUNT_PROCESS'
// export const UNLINK_AUTH0_ACCOUNTS_ERROR = 'auth0/UNLINK_AUTH0_ACCOUNT_ERROR'

export const checkUnlinkKycSocialDualAuthExists =
  (accountId, keycloakUserId) => async (dispatch) => {
    try {
      dispatch({ type: CHECK_KYC_SOCIAL_DUAL_AUTHS_START, payload: accountId })

      const {
        data: { pendingFerryAuths },
      } = await getAxios().get(
        '/ferryman/pendingferryauths/delink_keycloak_social_accounts',
      )
      const exists = pendingFerryAuths.some(
        ({ payload }) => payload.keycloakUserId === keycloakUserId,
      )
      dispatch({
        type: CHECK_KYC_SOCIAL_DUAL_AUTHS_SUCCESS,
        payload: {
          accountId,
          exists,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: CHECK_KYC_SOCIAL_DUAL_AUTHS_ERROR, payload: accountId })
    }
  }

export const fetchKeycloakUser = (id, keycloakId) => async (dispatch) => {
  dispatch({
    type: FETCH_KEYCLOAK_USER_PROCESS,
    payload: { id },
  })
  let res = null
  try {
    res = await getAxios().get(`/account/${keycloakId}/keycloak`)
    const { data } = res
    dispatch(checkUnlinkKycSocialDualAuthExists(id, keycloakId))
    dispatch({ type: FETCH_KEYCLOAK_USER_SUCCESS, payload: { id, data } })
  } catch (error) {
    dispatch({
      type: FETCH_KEYCLOAK_USER_ERROR,
      payload: {
        id,
        error: error && error.response ? error.response.data : error.message,
      },
    })
  }
}
export const fetchKeycloakUserByEmail = (email, realm) => async (dispatch) => {
  dispatch({
    type: FETCH_KEYCLOAK_USER_BY_EMAIL_PROCESS,
    payload: { email, realm },
  })

  try {
    // TODO: this gives us an array so I would think we would rather structure the
    // features reducer to accept a list of users instead of the current approach for future
    const userByEmailResponse = await getAxios().get(
      `/account/keycloak/by-email/${email}`,
      {
        params: { realm },
      },
    )
    const { data } = userByEmailResponse
    if (data && data.length > 0) {
      const user = data.find((d) => d.email === email)
      const keyCloakUserGroupsResponse = await getAxios().get(
        `/account/keycloak/user/groups/${user.id}`,
      )
      const groups = keyCloakUserGroupsResponse.data
      dispatch({
        type: FETCH_KEYCLOAK_USER_BY_EMAIL_SUCCESS,
        payload: { email, realm, data: { user, groups } },
      })
    } else {
      dispatch({
        type: FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR,
        payload: {
          email,
          realm,
          error: data.error
            ? data.error
            : `Keycloak user for email ${email} not found`,
        },
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_KEYCLOAK_USER_BY_EMAIL_ERROR,
      payload: {
        email,
        error: error && error.response ? error.response.data : error.message,
      },
    })
  }
}

export const resetUserInfo = () => (dispatch) => {
  dispatch({
    type: RESET_USER,
  })
}

export const getKeycloakInfoByEmail = async (email) => {
  const emailAddress = encodeURIComponent(email)
  try {
    const res = await getAxios().get(
      `/account/keycloak/by-email/${emailAddress}`,
    )
    const data = res.data
    if (data && data.length > 0) {
      const keycloakUser = data.filter(
        (keycloakUser) => keycloakUser.email === email,
      )
      return keycloakUser[0]
    }
  } catch (error) {
    ErrorToast(error)
    return error
  }
}

export const checkIfKeycloakInfoExist = async (newEmail) => {
  const keyCloakInfo = await getKeycloakInfoByEmail(newEmail)

  const isExistingInKeycloak = keyCloakInfo !== undefined
  return { isExistingInKeycloak, keyCloakInfo }
}

export const checkIfIsKeycloakSocial = async (keycloak) => {
  const isSocial =
    keycloak.info &&
    keycloak.info.federatedIdentities &&
    keycloak.info.federatedIdentities.length

  return isSocial
}

// export const fetchOtherAuth0Accounts = (id, user) => async dispatch => {
//   dispatch({ type: FETCH_OTHER_AUTHO_ACCOUNTS_PROCESS, payload: { id } })
//   let res = null
//   try {
//     const { email, email_verified, user_id } = user
//     res = await getAxios().get(`/account/auth0/other/${user_id}?email=${email}&email_verified=${email_verified}`)
//     const { data } = res
//     dispatch({
//       type: FETCH_OTHER_AUTHO_ACCOUNTS_SUCCESS,
//       payload: { id, data },
//     })
//   } catch (error) {
//     dispatch({
//       type: FETCH_OTHER_AUTHO_ACCOUNTS_ERROR,
//       payload: {
//         id,
//         error: error.response.data,
//       },
//     })
//   }
// }

// export const linkAuth0Accounts = (id, index, mainId, targetId) => async dispatch => {
//   dispatch({
//     type: LINK_AUTH0_ACCOUNTS_PROCESS,
//     payload: { id, index },
//   })
//   let res = null
//   try {
//     await getAxios().post(`/account/auth0/link`, { mainId, targetId: targetId })
//     dispatch({ type: LINK_AUTH0_ACCOUNTS_SUCCESS, payload: { id, index } })
//     // TODO: Fix updating auth0 user info in a different way
//     res = await getAxios().get(`/account/auth0/${mainId}`)
//     const { data } = res
//     dispatch({ type: FETCH_AUTH0_USER_SUCCESS, payload: { id, data } })
//   } catch (error) {
//     dispatch({
//       type: LINK_AUTH0_ACCOUNTS_ERROR,
//       payload: {
//         id,
//         index,
//         error: error.response.data,
//       },
//     })
//   }
// }

// export const unlinkAuth0Accounts = (id, index, mainId, targetProvider, targetId) => async dispatch => {
//   dispatch({ type: UNLINK_AUTH0_ACCOUNTS_PROCESS, payload: { id, index } })
//   let res = null
//   try {
//     await getAxios().post(`/account/auth0/unlink`, { mainId, targetProvider, targetId })
//     res = await getAxios().get(`/account/auth0/${mainId}`)
//     const { data } = res
//     dispatch({ type: FETCH_AUTH0_USER_SUCCESS, payload: { id, data } })
//   } catch (error) {
//     dispatch({
//       type: UNLINK_AUTH0_ACCOUNTS_ERROR,
//       payload: {
//         id,
//         index,
//         error: error.response.data,
//       },
//     })
//   }
// }

// export const checkIfAuthInfoExist = async newEmail => {
//   const infoForNewEmail = await getAuth0InfoByEmail(newEmail)
//   var isExisting = infoForNewEmail !== undefined
//   if (isExisting) {
//     toast('Email entered already exists', {
//       type: toast.TYPE.ERROR,
//       autoClose: 5000,
//     })
//   }
//   return isExisting
// }

// export const checkIfIsSocial = async auth0 => {
//   var isSocial = auth0.info && auth0.info.identities[0].isSocial
//   if (isSocial) {
//     toast('Cannot change email - this account is social', {
//       type: toast.TYPE.ERROR,
//       autoClose: 5000,
//     })
//   }
//   return isSocial
// }

// const getAuth0InfoByEmail = async email => {
//   var emailAddress = encodeURIComponent(email)
//   try {
//     const res = await getAxios().get(`/account/auth0/by-email/${emailAddress}`)
//     const data = JSON.parse(res.data)
//     return data[0]
//   } catch (error) {
//     toast(error.response.data, { type: toast.TYPE.ERROR, autoClose: 5000 })
//     return error
//   }
// }

export const unlinkKeycloakSocialAccounts =
  (accountId, keycloakUserId) => async (dispatch) => {
    try {
      dispatch({ type: UNLINK_KYC_SOCIAL_ACCOUNTS_START, payload: accountId })

      await getAxios().post('/ferryman/requestferryauth', {
        serviceType: 'admin',
        requestType: 'delink_keycloak_social_accounts',
        payload: {
          realm: 'valr', //TODO: do we want to delink internal user SSOs? if so then er need an option to pick the realm
          keycloakUserId,
        },
      })
      dispatch({ type: UNLINK_KYC_SOCIAL_ACCOUNTS_SUCCESS, payload: accountId })
      toast(`Ferry Auth To Unlink Account ${keycloakUserId} Successful`, {
        type: toast.TYPE.SUCCESS,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: UNLINK_KYC_SOCIAL_ACCOUNTS_ERROR, payload: accountId })
    }
  }
