import React from 'react'
import PropTypes from 'prop-types'
import { Actions } from 'components/atoms/Table/styles'
import Button from '@material-ui/core/Button'
import { renderInput as RenderInput } from 'forms/fields'
import AddIcon from '@material-ui/icons/Add'

const CurrencyHeaderActions = ({
  formShown,
  toggleForm,
  handleFilter,
  filter,
}) => (
  <Actions>
    <Actions justify="flex-start" margin="10px 0">
      {!formShown && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={toggleForm}
        >
          Add Currency
        </Button>
      )}
    </Actions>
    <Actions justify="flex-end" margin="10px 0">
      <RenderInput
        value={filter}
        marginRight={0}
        onChange={event => handleFilter(event.target.value)}
        meta={{}}
        label={'Filter'}
      />
    </Actions>
  </Actions>
)

CurrencyHeaderActions.propTypes = {
  formShown: PropTypes.bool,
  toggleForm: PropTypes.func,
  handleFilter: PropTypes.func,
  filter: PropTypes.string,
}

export default CurrencyHeaderActions
