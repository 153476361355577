import React, { Fragment, Component } from 'react'
import UnblockAccountForm from 'forms/UnblockAccount'
import auth from 'services/Auth'
import MuiButton from 'components/atoms/Buttons/MuiButton'
class UnblockAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const { id } = this.props
    return (
      <Fragment>
        {!formShown && (
          <MuiButton style={{ marginRight: '1em' }} onClick={this.toggleForm}>Unblock Account</MuiButton>
        )}
        {formShown && (
          <UnblockAccountForm
            initialValues={{
              id,
              comment: '',
              actionType: 'ACCOUNT_UNBLOCK',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
            }}
            form={'Unblock account'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}

export default UnblockAccount
