import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_SENDGRID_EMAILS_FOR_USER_PROCESS =
  'FETCH_SENDGRID_EMAILS_FOR_USER_PROCESS'
export const FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS =
  'FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS'
export const FETCH_SENDGRID_EMAILS_FOR_USER_ERROR =
  'FETCH_SENDGRID_EMAILS_FOR_USER_ERROR'

export const submitFilter = async (values, dispatch) => {
  const { email } = values

  const limit = 1000
  dispatch({
    type: FETCH_SENDGRID_EMAILS_FOR_USER_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/accounts/emails-activity/${email}`, {
      params: { limit },
    })
    dispatch({
      type: FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS,
      data: res && res.data,
      currentEmail: email,
    })
  } catch (error) {
    dispatch({
      type: FETCH_SENDGRID_EMAILS_FOR_USER_ERROR,
      error: error.response.data || error,
    })
  }
}

export const getMsgDetails = async msgId => {
  try {
    var res = await getAxios().get(`/sendgrid/message/details/${msgId}`)
    return res.data
  } catch (e) {
    toast(e.response.error, { type: toast.TYPE.ERROR, autoClose: 2500 })
    return undefined
  }
}
