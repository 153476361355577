import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Flexbox } from 'components/layout/index'

import { ActionButton, Container } from 'components/atoms/Table/styles'
import { InputGroup } from 'components/atoms/Form/index'
import RawData from 'components/atoms/RawDataDualAuth/index'

import { getSelectedBankAccount } from '../../redux/fiat/selectors'

let Form = props => {
  const {
    showActiveUser,
    removeActiveUser,
    markAsFailed,
    toggleRawData,
    withdraw,
    showRawData,
    withdrawalId,
    refineRawData,
  } = props

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(
    () => () => {
      removeActiveUser()
    },
    [],
  )

  return (
    <Container>
      <Flexbox direction={'row'} align={'flex-start'}>
        <ActionButton onClick={() => toggleRawData(withdrawalId)}>
          {showRawData ? 'Hide Raw Data' : 'Show Raw Data'}
        </ActionButton>
        <InputGroup justify={'flex-end'} direction={'row'} width={'100%'}>
          <ActionButton onClick={() => markAsFailed(withdraw, false)}>
            Reverse funds
          </ActionButton>
          <ActionButton onClick={() => markAsFailed(withdraw, true)}>
            Reverse funds and return fee
          </ActionButton>
        </InputGroup>
      </Flexbox>
      {showRawData && <RawData data={refineRawData(withdraw)} />}
    </Container>
  )
}

Form = reduxForm()(Form)

const mapStateToProps = state => ({
  country: getSelectedBankAccount(state).country,
})

const ReduxForm = connect(
  mapStateToProps,
  null,
)(Form)
export default ReduxForm
