import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Title } from 'components/atoms/Title'
import { DetailsBlockWithTitle } from 'components/atoms/Details'

import {
  FraudRiskActionButton,
  FraudRiskButtonActionType,
  CorporateActionButton,
  CorporateButtonActionType,
  ComplianceButtonActionType,
  ComplianceActionButton,
} from 'interfaces/Fraud'
import React, { FC } from 'react'
import { StyledFraudButton } from './Styles'

const fraudRiskButtons: FraudRiskActionButton[] = [
  { action: 'MONITOR', text: 'Monitor' },
  { action: 'BRF_REPORTED', text: 'Brf Reported' },
  { action: 'SCAM_WITHDRAWAL', text: 'Scam Withdrawal' },
]

const corporateRiskButtons: CorporateActionButton[] = [
  { action: 'KRA', text: 'KRA' },
  { action: 'SOLE_PROPORATITY', text: 'Sole Proporatity' },
  { action: 'P2P_TRADER', text: 'P2P Trader' },
]

const complianceRiskButtons: ComplianceActionButton[] = [
  { action: 'PEP', text: 'PEP' },
  { action: 'ADVERSE_MEDIA', text: 'Adverse Media' },
  { action: 'ACCOUNT_TERMINATED', text: 'Account Terminated' },
]

interface FraudRiskActions {
  handleAccountFraudModalState: (data: {
    actionType:
      | FraudRiskButtonActionType
      | ComplianceButtonActionType
      | CorporateButtonActionType
    isOpen: boolean
  }) => void
  fraudAlerts: FraudRiskButtonActionType
}

const Actions: FC<FraudRiskActions> = ({
  handleAccountFraudModalState,
  fraudAlerts,
}) => (
  <>
    <DetailsBlockWithTitle
      margin="0px 0px 10px 0px"
      type="h4"
      title={'Fraud'}
      flow={'column'}
    >
      {fraudRiskButtons.map((btn) => {
        const buttonText = fraudAlerts.includes(btn.action)
          ? `remove ${btn.text} tag`
          : `apply ${btn.text} tag`
        return (
          <StyledFraudButton>
            <MuiButton
              key={btn.action}
              color={fraudAlerts.includes(btn.action) ? 'primary' : 'secondary'}
              onClick={() =>
                handleAccountFraudModalState({
                  actionType: btn.action,
                  isOpen: true,
                })
              }
            >
              {buttonText}
            </MuiButton>
          </StyledFraudButton>
        )
      })}
    </DetailsBlockWithTitle>

    <DetailsBlockWithTitle
      margin="0px 0px 10px 0px"
      type="h4"
      title={'Corporate'}
      flow={'column'}
    >
      {corporateRiskButtons.map((btn) => {
        const buttonText = fraudAlerts.includes(btn.action)
          ? `remove ${btn.text} tag`
          : `apply ${btn.text} tag`
        return (
          <StyledFraudButton>
            <MuiButton
              key={btn.action}
              color={fraudAlerts.includes(btn.action) ? 'primary' : 'secondary'}
              onClick={() =>
                handleAccountFraudModalState({
                  actionType: btn.action,
                  isOpen: true,
                })
              }
            >
              {buttonText}
            </MuiButton>
          </StyledFraudButton>
        )
      })}
    </DetailsBlockWithTitle>

    <DetailsBlockWithTitle
      margin="0px 0px 10px 0px"
      type="h4"
      title={'Compliance'}
      flow={'column'}
    >
      {complianceRiskButtons.map((btn) => {
        const buttonText = fraudAlerts.includes(btn.action)
          ? `remove ${btn.text} tag`
          : `apply ${btn.text} tag`
        return (
          <StyledFraudButton>
            <MuiButton
              key={btn.action}
              color={fraudAlerts.includes(btn.action) ? 'primary' : 'secondary'}
              onClick={() =>
                handleAccountFraudModalState({
                  actionType: btn.action,
                  isOpen: true,
                })
              }
            >
              {buttonText}
            </MuiButton>
          </StyledFraudButton>
        )
      })}
    </DetailsBlockWithTitle>
  </>
)

export default Actions
