import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { addUserActionComment } from 'redux/userComments/actions'
import { fetchKycLevel } from '../../redux/kyc/actions'

export const submitKycAddressOverride = async (values, dispatch) => {
  const { comment = '', id, reason, sendNotifications = false, } = values
  const payload = {
    id,
    comment,
    reason: reason.value,
    sendNotifications,
  }

  try {
    await getAxios().put('/account/poa/override', payload)
    await addUserActionComment(values)
    toast('Submitted request to update Proof of Address status.', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(fetchKycLevel(id))
  } catch (e) {
    ErrorToast(e?.message || 'Error when updating proof of address status')
  }
}
