import React from 'react'
import { List } from '@material-ui/core'

import { NavItem } from './interfaces'
import { NestedNavMenuItem } from './NestedNavMenuItem'

import { Collapse } from '../styles'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/dynamic/application',
    listItemText: 'Application',
    selectedPathName: '/dynamic/application',
    routeAccess: 'config',
  },
  {
    route: '/dynamic/rules',
    listItemText: 'Rules',
    selectedPathName: '/dynamic/rules',
    routeAccess: 'config',
  },
  {
    route: '/dynamic/currency',
    listItemText: 'Currency',
    selectedPathName: '/dynamic/currency',
    routeAccess: 'config',
  },
]

const NestedApplicationConfigItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) =>
  pathname.includes('/dynamic') ? (
    <Collapse
      in={pathname.includes(selectedPathName)}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        {nestedNavigationItems.map((nestedNavItem, index) => (
          <NestedNavMenuItem
            key={index}
            handleListItemClick={handleListItemClick}
            navItemData={nestedNavItem}
            pathname={pathname}
          />
        ))}
      </List>
    </Collapse>
  ) : null

export default NestedApplicationConfigItems
