import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = (props) => {
  const { expandItem } = props
  return (
    <TableHeader onClick={expandItem}>
      <TableCell flex={0.6}>Account Id</TableCell>
      <TableCell flex={0.6}>First Name</TableCell>
      <TableCell flex={0.6}>Last Name</TableCell>
      <TableCell flex={0.6} align={'right'}>
        Amount
      </TableCell>
      <TableCell flex={0.7}>Currency</TableCell>
      <TableCell flex={2.4}>Reference</TableCell>
      <TableCell flex={0.7}>Bank Date</TableCell>
      <TableCell flex={0.9}>Inserted At</TableCell>
      <TableCell flex={0.9}/>
      <TableCell flex={0.18}/>
    </TableHeader>
  )
}
