import { DetailsBlockWithTitle, InfoBlock } from 'components/atoms/Details'
import { ContentContainer, Spacer } from 'components/atoms/Generic'
import { FlexRow, Flexbox } from 'components/layout'
import React, { FC, useEffect, useRef, useState } from 'react'
import { RiskProfileForm } from './RiskProfileForm'
import { camelCaseToText } from 'helpers/utils'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import RiskProfileEddDocumentsSection from './RiskProfileEddDocumentsSection'
import RestoreDocumentsModal from 'components/organisms/DocumentViewers/RestoreDocumentsModal'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import ErrorToast from 'components/molecules/ErrorToast'
import {
  IndividualRiskProfile,
  RiskProfileEddDocument,
} from '../../../interfaces/riskProfile'
import { RiskScoreOption } from 'interfaces/riskProfile'
import { Title } from 'components/atoms/Title'
import { SingleAccountInfo } from 'interfaces/singleAccount'
import LoadingBlock from 'components/molecules/LoadingBlock'

interface IIndividualRiskProfilePage {
  individualRiskProfileInfo: IndividualRiskProfile
  accountInfo: SingleAccountInfo
  accountId: number
  eddDocuments: RiskProfileEddDocument[]
  removedEddDocuments: RiskProfileEddDocument[]
  isFetchingRiskScoringOptions: boolean
  individualScoringOptions: {
    countryOfResidence: RiskScoreOption[]
    occupationStatus: RiskScoreOption[]
    sourceOfFunds: RiskScoreOption[]
    sourceOfWealth: RiskScoreOption[]
    purposeOfAccount: RiskScoreOption[]
    pepStatus: RiskScoreOption[]
    suspiciousTransactions: RiskScoreOption[]
    ipLocation: RiskScoreOption[]
  }
  history: any
  createRiskProfile: (values: any, dispatch: any) => Promise<void>
  updateRiskProfile: (values: any, dispatch: any) => Promise<void>
  restoredRiskProfileEddDocument: (
    document: RiskProfileEddDocument,
  ) => Promise<void>
  updateSearchedRiskProfileState: (accountId: number) => Promise<void>
  fetchingAccountInfoForRba: boolean
}

const IndividualRiskProfilePage: FC<IIndividualRiskProfilePage> = ({
  individualRiskProfileInfo,
  accountInfo,
  fetchingAccountInfoForRba,
  accountId,
  isFetchingRiskScoringOptions,
  individualScoringOptions,
  history,
  eddDocuments,
  removedEddDocuments,
  createRiskProfile,
  updateRiskProfile,
  updateSearchedRiskProfileState,
  restoredRiskProfileEddDocument,
}) => {
  const [showRiskProfileForm, setShowRiskProfileForm] = useState(false)
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false)
  const [isRestoringDocument, setIsRestoringDocument] = useState(false)
  const [hasDiff, setHasDiff] = useState<boolean>(false)
  const [valuesNotMatching, setValuesNotMatching] = useState<string[]>([])
  const toggleRiskProfileForm = () => setShowRiskProfileForm((prev) => !prev)
  const toggleRestoreModal = () => setIsRestoreModalOpen((prev) => !prev)

  const handleRestoreDocument = async (documentId: number) => {
    setIsRestoringDocument(true)
    try {
      const { data } = await getAxios().post(
        `/risk-based-approach/profile/${accountId}/edd-documents/${documentId}/restore`,
      )
      await restoredRiskProfileEddDocument(data)
      toast('Document restored successfully', { type: toast.TYPE.SUCCESS })
    } catch (error) {
      ErrorToast(error)
    }
    setIsRestoringDocument(false)
  }
  const { riskScore, productSelection, ...restOfIndividualRiskProfile } =
    individualRiskProfileInfo
  const individualRiskProfileInitialValues = {
    ...restOfIndividualRiskProfile,
    name: accountInfo.name || restOfIndividualRiskProfile.name,
    surname: accountInfo.surname || restOfIndividualRiskProfile.surname,
    countryOfResidence:
      individualScoringOptions.countryOfResidence.find(
        (opt) => opt.countryCode === accountInfo.residentialCountry,
      )?.value || restOfIndividualRiskProfile.countryOfResidence,
    productSelection: productSelection ? productSelection.split(', ') : [],
    purposeOfAccount:
      accountInfo.purposeOfAccount ||
      restOfIndividualRiskProfile.purposeOfAccount,
    occupationStatus:
      accountInfo.occupationStatus ||
      restOfIndividualRiskProfile.occupationStatus,
    sourceOfFunds:
      accountInfo.sourceOfFunds || restOfIndividualRiskProfile.sourceOfFunds,
    accountId,
  }

  const checkResidentialCountryDiff = (
    riskProfileCountryOfResidence: string,
    accountResidentialCountryCode: string,
  ) => {
    return (
      individualScoringOptions.countryOfResidence.find(
        (opt) => opt.countryCode === accountResidentialCountryCode,
      )?.value !== riskProfileCountryOfResidence
    )
  }

  const handleOutdatedValuesCheck = (mainObj: IndividualRiskProfile) => {
    let outdated = false
    const mainObjPurposeOfAccount = mainObj.purposeOfAccount
    let isOutdatedPurpose = mainObjPurposeOfAccount
      ? individualScoringOptions.purposeOfAccount.findIndex(
          (opt: RiskScoreOption) => opt.value === mainObjPurposeOfAccount,
        ) === -1
      : false
    const mainObjOccupationStatus = mainObj.occupationStatus
    let isOutdatedOccupationStatus = mainObjOccupationStatus
      ? individualScoringOptions.occupationStatus.findIndex(
          (opt: RiskScoreOption) => opt.value === mainObjOccupationStatus,
        ) === -1
      : false
    setValuesNotMatching((prev) => {
      const updated = [...prev]
      if (isOutdatedPurpose) {
        if (updated.findIndex((v) => v.includes(mainObjPurposeOfAccount)) > -1)
          return updated
        const outDatedValue = `Purpose of account ${
          !mainObjPurposeOfAccount
            ? 'should be empty'
            : `is outdated for value ${mainObjPurposeOfAccount}`
        }`
        updated.push(outDatedValue)
        outdated = true
      }
      if (isOutdatedOccupationStatus) {
        if (updated.findIndex((v) => v.includes(mainObjOccupationStatus)) > -1)
          return updated
        const outDatedValue = `Occupation status ${
          !mainObjOccupationStatus
            ? 'should be empty'
            : `is outdated for value ${mainObjOccupationStatus}`
        }`
        updated.push(outDatedValue)
        outdated = true
      }
      return updated
    })
    setHasDiff(outdated)
    return outdated
  }

  const diffCheckRiskProfileAgainstAccountProfile = (
    mainObj: IndividualRiskProfile,
    comparatorObj: SingleAccountInfo,
  ) => {
    try {
      let foundDiff = false
      if (!individualRiskProfileInfo.id) return
      const mainObjKeys = Object.keys(mainObj)
      const comparatorObjKeys = Object.keys(comparatorObj)
      const keys = mainObjKeys.filter(
        (mk) => comparatorObjKeys.findIndex((ck) => ck === mk) > -1,
      )

      const isOutdated = handleOutdatedValuesCheck(mainObj)

      if (isOutdated) return isOutdated

      keys.forEach((key) => {
        if (!mainObj[key] && !comparatorObj[key]) return hasDiff
        const isSame = mainObj[key] === comparatorObj[key]
        if (isSame) return hasDiff
        foundDiff = true
        setValuesNotMatching((prev) => {
          const updatedValues: string[] = [...prev]
          const alreadyListed =
            updatedValues.findIndex((v) => v.includes(key)) > -1
          if (!alreadyListed) {
            const mismatchingValue = `Risk profile value ${key}: ${`"${mainObj[key]}"`} does not match account value: "${
              comparatorObj[key] || 'empty'
            }". Please update ${key} to ${comparatorObj[key]}`
            updatedValues.push(mismatchingValue)
          }
          return updatedValues
        })
      })
      const hasCountryDiff = checkResidentialCountryDiff(
        mainObj.countryOfResidence,
        comparatorObj.residentialCountry,
      )
      if (hasCountryDiff) {
        foundDiff = true
        setValuesNotMatching((prev) => {
          const updatedValues: string[] = [...prev]
          const alreadyListed =
            updatedValues.findIndex((v) =>
              v.includes(comparatorObj.residentialCountry),
            ) > -1
          if (!alreadyListed) {
            const mismatchingValue = `Risk profile value country of residence: ${mainObj.countryOfResidence} does not match account profile please make sure the value is set to ${comparatorObj.residentialCountry} and click save.`
            updatedValues.push(mismatchingValue)
          }
          return updatedValues
        })
      }
      if (!foundDiff) setValuesNotMatching([])
      setHasDiff(foundDiff)
    } catch (error) {
      ErrorToast(
        'Error when checking difference between values for risk profile',
      )
    }
  }

  const individualRiskProfileExists = individualRiskProfileInfo.id
  useEffect(() => {
    diffCheckRiskProfileAgainstAccountProfile(
      individualRiskProfileInfo,
      accountInfo,
    )
  }, [individualRiskProfileInfo, accountInfo])
  if (fetchingAccountInfoForRba)
    return <LoadingBlock message="Updating account information..." />

  return (
    <ContentContainer style={{ width: '100%' }}>
      {hasDiff && individualRiskProfileExists ? (
        <>
          <Title
            color="red"
            type="h4"
            text="Risk profile is outdated please update!"
          />
          {valuesNotMatching.map((v, i) => (
            <p key={i}>{v}</p>
          ))}
        </>
      ) : null}
      <DetailsBlockWithTitle title="Individual Risk Profile">
        <Flexbox align="flex-start" direction="column">
          {showRiskProfileForm ? (
            <RiskProfileForm
              exists={!!individualRiskProfileInfo.id}
              onSubmit={
                !individualRiskProfileInfo.id
                  ? createRiskProfile
                  : updateRiskProfile
              }
              initialValues={individualRiskProfileInitialValues}
              isFetchingOptions={isFetchingRiskScoringOptions}
              riskScoringFormOptions={individualScoringOptions}
              onCancel={toggleRiskProfileForm}
              hasDiff={hasDiff}
              accountId={accountId}
            />
          ) : (
            <FlexRow>
              {Object.keys(individualRiskProfileInfo).map((key) => (
                <div style={{ width: '25%', marginBottom: 8 }} key={key}>
                  <InfoBlock
                    infoLabelMarginBottom={8}
                    label={camelCaseToText(key)}
                    value={individualRiskProfileInfo[key] || '--'}
                  />
                </div>
              ))}
            </FlexRow>
          )}
          <FlexRow>
            <Spacer margin="0px 12px 0px 0px">
              {!showRiskProfileForm && (
                <MuiButton onClick={toggleRiskProfileForm}>
                  {individualRiskProfileInfo.id ? 'update' : 'create'} risk
                  profile
                </MuiButton>
              )}
            </Spacer>
            <Spacer margin="0px 12px 0px 0px">
              <MuiButton
                onClick={() =>
                  history.push(
                    `/accounts/byId/${accountId}/account-compliance/risk-profile/individual/edd-report`,
                  )
                }
              >
                go to individuals edd report
              </MuiButton>
            </Spacer>
            <Spacer margin="0px 12px 0px 0px">
              <MuiButton
                onClick={async () => {
                  await updateSearchedRiskProfileState(accountId)
                  history.push(
                    '/compliance/risk-profiles/management/risk-scoring/individual',
                  )
                }}
              >
                go to individual risk profile management
              </MuiButton>
            </Spacer>
          </FlexRow>
          <>
            <RiskProfileEddDocumentsSection
              accountId={accountId}
              eddDocuments={eddDocuments}
            />
            {removedEddDocuments.length > 0 && (
              <Spacer margin="0px 12px 0px 0px">
                <MuiButton onClick={toggleRestoreModal}>
                  restore documents
                </MuiButton>
              </Spacer>
            )}
          </>
        </Flexbox>
      </DetailsBlockWithTitle>
      <RestoreDocumentsModal
        isOpen={isRestoreModalOpen}
        removedDocuments={removedEddDocuments}
        toggleRestoringDocumentsModal={toggleRestoreModal}
        canRestoreDocument={true}
        restoreDocument={handleRestoreDocument}
        getDocumentBufferData={async (documentId: number) =>
          getAxios().get(
            `/risk-based-approach/profile/${accountId}/edd-documents/${documentId}`,
          )
        }
        restoringDocument={isRestoringDocument}
      />
    </ContentContainer>
  )
}

export default IndividualRiskProfilePage
