import React from 'react'
import { TableHead } from '@material-ui/core'

import Modal from '../../components/organisms/Modal'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '../../components/atoms/Table/styles'

function splitValueAndConvertBoolean(value) {
  return value
    ?.split(',')
    ?.map(v => (v === 'true' ? 'Yes' : v === 'false' ? 'No' : v))
}

export function ConfirmModal({
  open,
  handleClose,
  proceed,
  initialData,
  dataToConfirm,
}) {
  if (!initialData?.length || !dataToConfirm?.length) {
    return null
  }

  return (
    <Modal
      width="50%"
      button={{ confirm: { text: 'Save' }, decline: { text: 'Cancel' } }}
      declineButtonColor="secondary"
      open={open}
      handleClose={handleClose}
      proceed={proceed}
    >
      <p> The following changes will be made:</p>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="40%">
              <strong>Name</strong>
            </TableCell>
            <TableCell width="30%">
              <strong>Old</strong>
            </TableCell>
            <TableCell width="30%">
              <strong>New</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToConfirm.map(({ envName, value }) => (
            <TableRow key={`row-${envName}`}>
              <TableCell width="40%">{envName.replaceAll('_', ' ')}</TableCell>
              <TableCell width="30%" color="red">
                {splitValueAndConvertBoolean(
                  initialData.find(_ => _.name === envName)?.value,
                ).map(v => (
                  <span key={`${envName}-${value}-new`}>
                    {v}
                    <br />
                  </span>
                ))}
              </TableCell>
              <TableCell width="30%" color="green">
                {splitValueAndConvertBoolean(value).map(v => (
                  <span key={`${envName}-${value}-new`}>
                    {v}
                    <br />
                  </span>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Modal>
  )
}
