import getAxios from 'helpers/axios'
import { Dispatch } from 'react'

import ErrorToast from 'components/molecules/ErrorToast'
import { ConditionalOrder } from 'interfaces/conditionalOrders'

export type ConditionalOrdersActions =
  | 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_START'
  | 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_SUCCESS'
  | 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_ERROR'
  | 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_START'
  | 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_SUCCESS'
  | 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_ERROR'
  | 'RESET_CONDITIONAL_ORDERS'

interface ConditionalOrdersDispatch {
  type: ConditionalOrdersActions
  payload?: ConditionalOrder[]
}

export const fetchAccountConditionalOrders = (id:number) => async (dispatch: Dispatch<ConditionalOrdersDispatch>,) => {
  try {
    dispatch({
      type: 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_START',
    })
    const { data }: {data: ConditionalOrder[]} = await getAxios().get(`conditional-orders/${id}`)
    dispatch({
      type: 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_ACCOUNT_CONDITIONAL_ORDERS_ERROR',
    })
  }
}

export const fetchConditionalOrders = (currencyPair: string) => async (
  dispatch: Dispatch<ConditionalOrdersDispatch>,
) => {
  try {
    dispatch({
      type: 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_START',
    })
    const { data }: { data: ConditionalOrder[] } = await getAxios().get(
      `/conditional-orders/?currencyPair=${currencyPair}`,
    )
    dispatch({
      type: 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_SUCCESS',
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_CURRENCY_PAIR_CONDITIONAL_ORDERS_ERROR',
    })
  }
}

export const resetConditionalOrders = () => async (
  dispatch: Dispatch<ConditionalOrdersDispatch>,
) => {
    dispatch({
      type: 'RESET_CONDITIONAL_ORDERS',
    })
}
