import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Actions,
  Table,
  TableBody,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import * as moment from 'moment'
import IconLoading from 'components/atoms/IconLoading'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchDepositByStatus } from 'redux/fiat/actions'
import { Header, ShowStatus } from 'components/atoms/Generic/index'
import { Headers } from './headers'
import Row from './row'
import { renderInput as RenderInput } from 'forms/fields'

class DepositByStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skip: 0,
      searching: false,
      filter: '',
    }
  }

  fetchMoreData = async () => {
    const { skip, limit, hasMoreRecords } = this.props.depositByStatus
    if (hasMoreRecords !== false) {
      this.setState({ skip: skip + limit })
    }
    await this.props.fetchDepositByStatus({
      status: this.props.selectedOption,
      skip: this.state.skip,
      limit,
    })
  }

  checkIfIsPending = (deposit) => {
    const hasPendingAuthorisation =
      deposit.statuses[0].status === 'PENDING_AUTHORISATION'
    return hasPendingAuthorisation
  }

  returnEndMessage = (depositInfo) => {
    if (
      depositInfo.data &&
      depositInfo.data.length === 0 &&
      depositInfo.status === 'done'
    ) {
      return <ShowStatus>This status has no deposits</ShowStatus>
    }
    if (depositInfo.status !== 'loading') {
      return <ShowStatus>No more deposits to show</ShowStatus>
    }
    if (depositInfo.status === 'loading') {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait
          </ShowStatus>
        </TablePlaceholder>
      )
    }
  }

  filterData = (value = '', data) => {
    const filteredData =
      value.length === 0
        ? data
        : data.filter((item) => {
            const {
              amount,
              bankReceivedAt,
              depositId,
              description,
              insertedAt,
              source,
            } = item
            if (
              source.toLowerCase().includes(value.toLowerCase()) ||
              depositId
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              description
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              amount.toString().toLowerCase().includes(value.toLowerCase()) ||
              bankReceivedAt
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              insertedAt.toString().toLowerCase().includes(value.toLowerCase())
            ) {
              return item
            }
          })

    return filteredData
  }

  debounce = _.debounce((q) => this.setState({ searching: false }), 500)

  handleSearch = (value) => {
    this.setState({
      filter: value,
      searching: true,
    })
    this.debounce(value)
  }

  renderDeposits(data) {
    const { filter } = this.state
    return this.filterData(filter, data).map((deposit, i) => {
      deposit.dateTime = moment(deposit.dateTime).format('YYYY-MMM-DD HH:mm:ss')
      deposit.insertedAt = moment(deposit.insertedAt).format(
        'YYYY-MMM-DD HH:mm:ss',
      )
      return (
        <Row
          key={i}
          deposit={deposit}
          depositId={deposit.depositId}
          isPending={this.checkIfIsPending(deposit)}
          selectedOption={this.props.selectedOption}
        />
      )
    })
  }

  renderTable() {
    const { data, hasMoreRecords } = this.props.depositByStatus
    return (
      <>
        <Header
          title={'Filter Results'}
          style={{ marginBottom: '10px', height: '190px' }}
        />
        <Actions justify="flex-end" margin="0px 0 15px 0">
          <RenderInput
            value={this.statefilter}
            marginRight={0}
            onChange={(event) => this.handleSearch(event.target.value)}
            meta={{}}
            label={'Filter'}
          />
        </Actions>
        <Headers />
        <Table>
          {!this.state.searching && (
            <TableBody>
              <InfiniteScroll
                dataLength={data ? data.length : 0}
                next={this.fetchMoreData}
                hasMore={hasMoreRecords}
                useWindow={false}
                loader={
                  <TablePlaceholder>
                    <ShowStatus>
                      <IconLoading />
                      Loading...
                    </ShowStatus>
                  </TablePlaceholder>
                }
                height={600}
                endMessage={this.returnEndMessage(this.props.depositByStatus)}
              >
                {this.renderDeposits(data)}
              </InfiniteScroll>
            </TableBody>
          )}
        </Table>
      </>
    )
  }

  render() {
    return <div>{this.renderTable()}</div>
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDepositByStatus,
    },
    dispatch,
  )

export default connect(null, mapDispatchToProps)(DepositByStatus)
