import {
  FETCH_ACCOUNT_RISK_SETTINGS_PROCESS,
  FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_RISK_SETTINGS_ERROR,
  UPDATE_ACCOUNT_STATUS_SUCCESS,
} from './actions'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_RISK_SETTINGS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          status: 'loading',
        },
      }
    }

    case FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS: {
      const {
        id, inLiquidation, marginEnabled, futuresEnabled,
      } = action.payload
      return {
        ...state,
        [id]: {
          status: 'done',
          inLiquidation,
          marginEnabled,
          futuresEnabled,
        },
      }
    }

    case UPDATE_ACCOUNT_STATUS_SUCCESS: {
      const { id, updatedStatus } = action.payload
      const {
        inLiquidation,
        marginEnabled,
      } = state[id]
      return {
        ...state,
        [id]: {
          status: 'done',
          inLiquidation,
          marginEnabled,
          futuresEnabled: updatedStatus,
        },
      }
    }

    case FETCH_ACCOUNT_RISK_SETTINGS_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        [id]: {
          ...state[id],
          status: 'error',
          data: [],
          error,
        },
      }
    }
    default:
      return state
  }
}
