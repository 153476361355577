import React, { useState } from 'react'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import {
  removeSubmittedIgnoredDualAuth,
} from 'redux/ignoredDeposits/actions'
import { connectStateAndDispatch } from 'store'
import { Headers } from './headers'
import Row from './row'

const RemoveIgnoredStatusTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(removeIgnoredDualAuth => {
          removeIgnoredDualAuth.createdAt = moment(
            removeIgnoredDualAuth.createdAt,
          ).format('YYYY-MMM-DD HH:mm:ss')
          return (
            <Row
              key={removeIgnoredDualAuth.id}
              dualAuthId={removeIgnoredDualAuth.id}
              isSelected={selectedRow === removeIgnoredDualAuth.id}
              removeIgnoredDualAuth={removeIgnoredDualAuth}
              activeUser={removeIgnoredDualAuth.active}
              toggleSelected={toggleSelectedRow}
              removeSubmittedItem={props.removeSubmittedIgnoredDualAuth}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}

const actions = {
  removeSubmittedIgnoredDualAuth,
}

export default connectStateAndDispatch(null, actions, RemoveIgnoredStatusTable)
