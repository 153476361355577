import { Input } from 'components/atoms/Form/index'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import React, { Component } from 'react'

export default class CurrencyTableModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      borrowWeight: 0,
    }
  }

  componentDidMount() {
    this.setState({
      borrowWeight: this.props.modalData.borrowWeight || 0,
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.modalData.borrowWeight !== this.props.modalData.borrowWeight
    ) {
      this.setState({ borrowWeight: this.props.modalData.borrowWeight })
    }
  }

  componentWillUnmount() {
    this.setState({
      borrowWeight: 0,
    })
  }

  handleProceedConfirmation = async () => {
    if (this.props.modalData.symbol) {
      await this.props.allowBorrowOnCurrency({
        ...this.props.modalData,
        borrowWeight: this.state.borrowWeight,
      })
      await this.props.closeDialog()
    }
  }

  render() {
    const {
      isUpdatingCurrency,
      closeDialog,
      allowBorrowModalOpen,
      modalData,
    } = this.props
    const { borrowWeight } = this.state
    return (
      <ConfirmationDialog
        disabled={isUpdatingCurrency}
        title={`${modalData.allowBorrow ? 'Allow' : 'Cancel'} Borrowing for ${
          modalData.symbol
        }`}
        message={`Are you sure you want to ${
          modalData.allowBorrow ? 'enable' : 'disable'
        } borrowing for ${modalData.symbol}?`}
        open={allowBorrowModalOpen}
        proceed={this.handleProceedConfirmation}
        handleClose={() => {
          closeDialog()
          this.setState({
            borrowWeight: 0,
          })
        }}
        button={{
          confirm: {
            text: 'Proceed',
          },
          decline: {
            text: 'Cancel',
          },
        }}
      >
        {modalData.allowBorrow && (
          <>
            <Input
              label="Borrow Weight"
              variant="outlined"
              placeholder="Borrow Weight"
              type="number"
              value={borrowWeight}
              inputProps={{
                step: '.0.1',
              }}
              onChange={({ target: { value } }) => {
                if (value > 1 || value < 0)
                  return ErrorToast(
                    'Borrow weight value can only be between 0 to 1',
                  )
                return this.setState({
                  borrowWeight: value < 0 ? 0 : parseFloat(value),
                })
              }}
            />
          </>
        )}
      </ConfirmationDialog>
    )
  }
}
