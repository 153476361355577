import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import { addUserActionComment } from 'redux/userComments/actions'

export const makeRequestToUpdateUserEmail = async (values, keycloakId) => {
  try {
    const { id, comment, email } = values
    const res = await getAxios().put('/change/email', {
      accountId: id,
      reason: comment,
      newEmail: email,
      keycloakId,
    })
    if (res.data.statusCode === 202) {
      const { data: { dualAuthId } } = res.data
      values.dualAuthId = dualAuthId
      await addUserActionComment(values)
      toast('Email change status updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
  }
  } catch (error) {
    ErrorToast(error)
  }
}
