import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'
import * as moment from 'moment'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import { Link } from '../../AccountIdLink/clickableId'
import { ActiveDualAuthUserQue } from '../helpers'

class DualAuthRow extends Component {
  toggleForm = () => {
    this.props.toggleSelected(this.props.dualAuth.id)
  }

  removeActiveUser = async () => {
    const { id } = this.props.dualAuth
    const inactiveDualAuthUser = new ActiveDualAuthUserQue({ id, dualAuthType: 'DEFAULT_EVENT', userActive: false })
    socket.emit('REMOVE_DUAL_AUTH_USER', inactiveDualAuthUser)
  }

  showActiveUser = async () => {
    const { id } = this.props.dualAuth
    const activeDualAuthUser = new ActiveDualAuthUserQue({ id, dualAuthType: 'DEFAULT_EVENT', userActive: true })
    socket.emit('ADD_DUAL_AUTH_USER', activeDualAuthUser)
  }

  render() {
    const {
      dualAuth,
      isSelected,
    } = this.props
    const {
      id,
      accountId,
      reason,
      deposit: { dateTime },
      active,
    } = dualAuth

    const formattedAmount = new BigNumber(dualAuth.deposit.amount).toFormat(2)
    const shading = isSelected ? 'white' : ''
    return (
      <Fragment>
        <TableRow
          active={isSelected}
          onClick={() => {
            this.toggleForm()
          }}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'7%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'9%'}>
            {dualAuth.deposit.accountBasicInfoForDisplay.name}
          </TableCell>
          <TableCell width={'9%'}>
            {dualAuth.deposit.accountBasicInfoForDisplay.surname}
          </TableCell>
          <TableCell width={'8%'} marginRight={'1%'} align={'right'}>
            {formattedAmount}
          </TableCell>
          <TableCell width={'7%'}>
            {dualAuth.deposit.currency}
          </TableCell>
          <TableCell width={'20%'}>{dualAuth.deposit.description}</TableCell>
          <TableCell width={'9%'}>{moment(dateTime).format('YYYY-MMM-DD')}</TableCell>
          <TableCell width={'9%'}>{moment(dualAuth.deposit.insertedAt).format('YYYY-MMM-DD HH:mm:ss')}</TableCell>
          <TableCell width={'12%'}>{reason}</TableCell>
          <TableCell
            width={'10%'}
          >
            {
              active && active.userActive &&
              <div className="tooltip">
                <i style={{ color: '#B9BBBD' }} className="fas fa-eye" >
                </i>
                <span className="tooltiptext">{active.activePersonName}</span>
              </div>
            }
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              accountId,
              dualAuthStatus: 'rejected',
              actionType: 'DEPOSITS',
              flag: false,
              data: { date: new Date(), createdBy: auth.decodeNameToken() }
            }}
            addComment
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.removeActiveUser}
            showActiveUser={this.showActiveUser}
            onCancel={this.toggleForm}
            rawData={dualAuth}
            active={active}
          />
        )}
      </Fragment>
    )
  }
}

export default DualAuthRow
