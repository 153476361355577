import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import { addUserActionComment } from 'redux/userComments/actions'

export const FETCH_IGNORED_DEPOSITS_PROCESS = 'FETCH_IGNORED_DEPOSITS_PROCESS'
export const FETCH_IGNORED_DEPOSITS_SUCCESS = 'FETCH_IGNORED_DEPOSITS_SUCCESS'
export const FETCH_IGNORED_DEPOSITS_ERROR = 'FETCH_IGNORED_DEPOSITS_ERROR'

export const SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_START = 'SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_START'
export const SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_SUCCESS = 'SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_SUCCESS'
export const SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_ERROR = 'SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_ERROR'

export const SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_START = 'SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_START'
export const SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_SUCCESS = 'SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_SUCCESS'
export const SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_ERROR = 'SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_ERROR'

export const UPDATE_REMOVAL_IGNORED_STATUS_DUAL_AUTH_ROW_USER_SUCCESS = 'UPDATE_REMOVAL_IGNORED_STATUS_DUAL_AUTH_ROW_USER_SUCCESS'
export const REMOVE_SUBMITTED_IGNORED_DUAL_AUTH = 'pendingDualAuth/REMOVE_SUBMITTED_IGNORED_DUAL_AUTH'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const fetchIgnoredDeposits = (type) => async dispatch => {
  dispatch({
    type: FETCH_IGNORED_DEPOSITS_PROCESS,
  })
  try {
    let formattedData = []
    const { data } = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    formattedData = data.map(item => {
      item.data = JSON.parse(item.data)
      item.createdAt = convertUnixToDate(item.createdAt)
      return item
    })
    dispatch({
      type: FETCH_IGNORED_DEPOSITS_SUCCESS,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_IGNORED_DEPOSITS_ERROR,
    })
  }
}

export const submitDualAuthRemoveIgnoredStatus = async (values, dispatch) => {
  try {
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_START,
    })
    const action = 'REMOVE_FIAT_DEPOSIT_IGNORED_STATUS'
    const { depositId, comment, accountId } = values
    const res = await getAxios().post('dualauth/initiate/deposits/remove-status', {
      depositId, comment, action, accountId,
    })
    if (res.status === 202) {
      toast('Removal of Fiat Deposit Ignored Status initiated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      toast(`Failed to remove the status \n ${res.data.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_SUCCESS,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const submitDualAuthAddressBook = async (values, dispatch) => {
  try {
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_START,
    })

    const res = await getAxios().post('dualauth/initiate/address-book', values)
    const { dualAuthId } = res.data
    await addUserActionComment({ ...values, dualAuthId })
    toast('Dual Auth For Address Withdrawal Book initiated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_SUCCESS,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: SUBMIT_DUAL_AUTH_REMOVE_ADDRESS_BOOK_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateIgnoredDepositDualAuthRowUserSocketEvent = newInfo => async dispatch => {
  try {
    dispatch({
      type: UPDATE_REMOVAL_IGNORED_STATUS_DUAL_AUTH_ROW_USER_SUCCESS,
      payload: {
        data: newInfo,
      },
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const removeSubmittedIgnoredDualAuth = (id) => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_IGNORED_DUAL_AUTH,
    payload: {
      id,
    },
  })
}
