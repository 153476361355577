import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_CREDIT_CARD_DEPOSITS_BY_ID_PROCESS =
  'pendingDualAuth/FETCH_CREDIT_CARD_DEPOSITS_BY_ID_PROCESS'
export const FETCH_CREDIT_CARD_DEPOSITS_BY_ID_SUCCESS =
  'pendingInternalTransfers/FETCH_CREDIT_CARD_DEPOSITS_BY_ID_SUCCESS'
export const FETCH_CREDIT_CARD_DEPOSITS_BY_ID_ERROR =
  'pendingInternalTransfers/FETCH_CREDIT_CARD_DEPOSITS_BY_ID_ERROR'

export const CLEAR_CREDIT_CARD_DEPOSITS = 'CLEAR_CREDIT_CARD_DEPOSITS'

export const fetchCreditCardDepositsById =
  ({ id, skip, limit }) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CREDIT_CARD_DEPOSITS_BY_ID_PROCESS,
      payload: {
        id,
      },
    })

    try {
      const res = await getAxios().get(
        `/credit-card/deposits/${id}/${skip}/${limit}`,
      )
      const { data } = res
      dispatch({
        type: FETCH_CREDIT_CARD_DEPOSITS_BY_ID_SUCCESS,
        payload: {
          data,
          id,
          skip,
          limit,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: FETCH_CREDIT_CARD_DEPOSITS_BY_ID_ERROR,
        payload: {
          error: error.response,
        },
      })
    }
  }

export const clearCreditCardDeposits = () => (dispatch) => {
  dispatch({
    type: CLEAR_CREDIT_CARD_DEPOSITS,
  })
}
