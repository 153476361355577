import React, { useEffect, useState } from 'react'
import * as moment from 'moment'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import Row from './row'
import { Headers } from './headers'

import {
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core'

export const UnknownReferencesTable = props => {
  const {
    formShown,
    expandItem,
    data,
    showRawData,
    expandRawData,
    addNoteFormShown,
    bulkActionsActive,
    title,
    handleSelectedBulkDepositClick,
    selectedBulkDeposits,
    toggleSelectAll,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    tablePaginationCount,
    isAllDepositsSelected,
  } = props
  const { checkIfIsPending, toggleForm, toggleAddNotesForm } = props
  const [highlightedId, setHighlightedId] = useState('')
  const [isCellHidden, setIsCellHidden] = useState(true)
  const hasDeposits = data.length > 0

  useEffect(
    () => {
      if (bulkActionsActive) {
        return setIsCellHidden(false)
      }

      setTimeout(() => {
        setIsCellHidden(true)
      }, 200)
    },
    [bulkActionsActive, isCellHidden],
  )

  return (
    <>
      {!hasDeposits && (
        <NoTableResultsMessage messageType={NO_RESULT_MESSAGE.DEPOSITS.type} />
      )}
      {hasDeposits && (
        <>
          <Headers
            isCellHidden={isCellHidden}
            bulkActionsActive={bulkActionsActive}
            expandItem={expandItem}
            toggleSelectAll={toggleSelectAll}
            isAllDepositsSelected={isAllDepositsSelected}
          />
          <>
            {data.map(deposit => {
              deposit.dateTime = moment(deposit.dateTime).format('YYYY-MMM-DD')
              deposit.insertedAt = moment(deposit.insertedAt).format(
                'YYYY-MMM-DD HH:mm:ss',
              )
              return (
                <Row
                  title={title}
                  isCellHidden={isCellHidden}
                  selectedBulkDeposits={selectedBulkDeposits}
                  handleSelectedBulkDepositClick={
                    handleSelectedBulkDepositClick
                  }
                  bulkActionsActive={bulkActionsActive}
                  key={deposit.depositId}
                  deposit={deposit}
                  depositId={deposit.depositId}
                  isPending={checkIfIsPending(deposit)}
                  toggleForm={() => toggleForm(deposit.depositId)}
                  formShown={formShown === deposit.depositId}
                  showRawData={showRawData}
                  toggleAddNotesForm={toggleAddNotesForm}
                  expandRawData={expandRawData}
                  addNoteFormShown={addNoteFormShown === deposit.depositId}
                  isHighlighted={highlightedId === deposit.depositId}
                  setHighlightedId={setHighlightedId}
                />
              )
            })}
            <TableRow style={{ backgroundColor: 'transparent' }} className="no-hover">
              <TableCell colSpan={12}>
                <TablePagination
                  component="div"
                  count={tablePaginationCount}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </>
        </>
      )}
    </>
  )
}
