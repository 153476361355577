import styled from 'styled-components'
import { DetailsBlock as DB, Details as D } from 'components/atoms/Details'

export const Details = styled(D)<{
  marginBottom?: string,
  marginLeft?: string,
  direction?: string,
}>``

export const DetailsBlock = styled(DB)<{ flow?: string }>``
