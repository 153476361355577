import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import AccountsSends from './AccountsSends'
import AccountPendingMultiAuths from './AccountPendingMultiAuths'

const routes = [
  '/accounts/byId/:accountId/transactions/withdrawals',
  '/accounts/byId/:accountId/transactions/withdrawals/pending-multi-auths',
]
const WithdrawalsNav = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    handleChange(
      null,
      pathname.includes('pending-multi-auths') ? routes[1] : routes[0],
    )

    return () => {}
  }, [])

  return (
    <>
      <Fragment>
        <TopNavigationBar
          value={currentPage}
          onChange={handleChange}
          variant="fullWidth"
        >
          <NavigationTab
            value={routes[0]}
            label="SENDS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/transactions/withdrawals`}
          />
          <NavigationTab
            value={routes[1]}
            label="pending multi auths"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/transactions/withdrawals/pending-multi-auths`}
          />
        </TopNavigationBar>
        <ContentContainer>
          <Switch>
            <Route path={routes[0]} component={AccountsSends} exact />
            <Route path={routes[1]} component={AccountPendingMultiAuths} />
          </Switch>
        </ContentContainer>
      </Fragment>
    </>
  )
}

export default WithdrawalsNav
