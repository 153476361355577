import React, { Fragment } from 'react'
import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
  JsonBlock,
} from '../../../components/atoms/Details'
import { BASE } from 'theme/base-units'
import IconLoading from 'components/atoms/IconLoading'

const AddressInfo = ({ address }) => (
  <Details marginBottom={`${BASE * 3}px`} direction={"column"}>
    <DetailsBlockWithTitle title="address">
      {address.status === 'loading' && <IconLoading />}
      {address.status === 'done' && (
        <Fragment>
          <InfoBlock label="Address1" value={address.data.addressLine1} />
          <InfoBlock label="Address2" value={address.data.addressLine2} />
          <InfoBlock label="City" value={address.data.city} />
          <InfoBlock label="Country" value={address.data.country} />
          <InfoBlock label="Postal Code" value={address.data.postalCode} />
          <InfoBlock label="Region" value={address.data.region} />
          <InfoBlock label="Suburb" value={address.data.suburb} />
        </Fragment>
      )}
      {address.status === 'error' && (
        <InfoBlock
          label="Fetching Error"
          value={`Code ${address.error.errorCode}: ${address.error.message}`}
        />
      )}
      {address.status === 'done' && <JsonBlock src={address.data} title={'Raw Data'} />}
    </DetailsBlockWithTitle>
  </Details>
)
export default AddressInfo
