import { all } from 'redux-saga/effects'
import availableAccountActionsSagas from 'redux/requested-account-actions/sagas'

import fiatConfigSagas from 'redux/fiat/sagas'

import { appInitSaga } from './appInit'

export default function* rootSaga() {
  return yield all([
    appInitSaga(),
    fiatConfigSagas(),
    availableAccountActionsSagas(),
  ])
}
