import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import { RenderCheckbox } from 'forms/fields'

export const Headers = (props) => {
  const {
    isBulkActionsActive,
    deposits,
    selectedFundingInfo,
    toggleSelectAll,
  } = props
  return (
    <>
      <TableCell>Account Id</TableCell>
      <TableCell>First Name</TableCell>
      <TableCell>Last Name</TableCell>
      <TableCell>Currency</TableCell>
      <TableCell>Amount</TableCell>
      <TableCell>Fee Amount</TableCell>
      <TableCell>Description</TableCell>
      <TableCell>Created At</TableCell>
      {isBulkActionsActive && (
        <TableCell>
          <RenderCheckbox
            onCheckFunction={() => toggleSelectAll(deposits)}
            checked={selectedFundingInfo.length === deposits.length}
            label={'Select All'}
          />
        </TableCell>
      )}
    </>
  )
}
