import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { maxLength256, required } from 'forms/fieldValidators'
import { renderDropDownField, renderInput } from 'forms/fields'
import {
  Button,
  FormContainer,
  FormError,
  InputGroup,
} from 'components/atoms/Form/index'
import { BASE } from 'theme/base-units'
import { ActionsWrapper } from './Styles'
import { Spacer } from 'components/atoms/Generic'

let Form = (props) => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'space-between'} direction={'column'}>
        <Field
          label={'Select email template (required)'}
          name={'reason'}
          width={'100%'}
          validate={[required]}
          component={renderDropDownField}
          options={[
            { value: 'dt-rv', label: 'Deposit Threshold' },
            {
              value: 'dt-ec',
              label: 'Deposits for review - Elderly client',
            },
            { value: 'dt-pd', label: 'Potential duplicate' },
          ]}
          marginBottom={26}
          labelWidth={'150px'}
        />
      </InputGroup>
      <ActionsWrapper>
        <Button
          margin={'0px 8px 0px 0px'}
          padding={'16px 41px'}
          onClick={onCancel}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Proceed
        </Button>
      </ActionsWrapper>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm()(Form)
const SendZendeskEmailForm = connect()(Form)

export default SendZendeskEmailForm
