import getAxios from 'helpers/axios'

export const FETCH_PLATFORM_SUMMARY_PROCESS = 'FETCH_PLATFORM_SUMMARY_PROCESS'
export const FETCH_PLATFORM_SUMMARY_SUCCESS = 'FETCH_PLATFORM_SUMMARY_SUCCESS'
export const FETCH_PLATFORM_SUMMARY_ERROR = 'FETCH_PLATFORM_SUMMARY_ERROR'

export const FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_PROCESS =
  'FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_PROCESS'
export const FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_SUCCESS =
  'FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_SUCCESS'
export const FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_ERROR =
  'FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_ERROR'

export const FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_PROCESS =
  'FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_PROCESS'
export const FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_SUCCESS =
  'FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_SUCCESS'
export const FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_ERROR =
  'FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_ERROR'

export const fetchMarginPlatformSummary = () => async dispatch => {
  dispatch({
    type: FETCH_PLATFORM_SUMMARY_PROCESS,
  })

  try {
    const res = await getAxios().get('/platform/summary')
    dispatch({
      type: FETCH_PLATFORM_SUMMARY_SUCCESS,
      payload: {
        data: res.data,
      },
    })
  } catch (error) {
    let mappedError = {}
    if (
      error.response.data.error &&
      error.response.data.statusCode &&
      error.response.data.error.message &&
      error.response.data.error.code
    ) {
      mappedError = {
        statusCode: error.response.data.statusCode,
        message: error.response.data.error.message,
        errorCode: error.response.data.error.code,
      }
      dispatch({
        type: FETCH_PLATFORM_SUMMARY_ERROR,
        payload: {
          error: mappedError,
        },
      })
    } else if (error.response.data.statusCode === 500) {
      dispatch({
        type: FETCH_PLATFORM_SUMMARY_ERROR,
        payload: {
          error: `No summary info found status code ${error.response.data.statusCode
            }`,
        },
      })
    }
  }
}

export const fetchAccountsWithMarginSummary = () => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_PROCESS,
  })
  try {
    const { data } = await getAxios().get('/accounts/with/margin/summary')
    dispatch({
      type: FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.error.message,
      errorCode: error.response.data.error.code,
    }
    dispatch({
      type: FETCH_ACCOUNTS_WITH_MARGIN_SUMMARY_ERROR,
      payload: {
        error: mappedError,
      },
    })
  }
}

export const fetchAccountsWithFullMarginInfo = () => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_PROCESS,
  })

  try {
    const res = await getAxios().get('/accounts/with/full/margin/info')
    dispatch({
      type: FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_SUCCESS,
      payload: {
        data: res.data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.error.message,
      errorCode: error.response.data.error.code,
    }
    dispatch({
      type: FETCH_ACCOUNTS_WITH_FULL_MARGIN_INFO_ERROR,
      payload: {
        error: mappedError,
      },
    })
  }
}
