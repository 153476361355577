import React from 'react'
import { List } from '@material-ui/core'

import { NestedNavMenuItem } from './NestedNavMenuItem'
import { NavItem } from './interfaces'

import { Collapse } from '../styles'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/exchange/deposits',
    listItemText: 'Deposits',
    selectedPathName: '/exchange/deposits',
    routeAccess: 'exchange',
  },
  {
    route: '/exchange/fiat-withdrawals',
    listItemText: 'Withdrawals',
    selectedPathName: '/exchange/fiat-withdrawals',
    routeAccess: 'exchange',
  },
  {
    route: '/exchange/trading',
    listItemText: 'Trading',
    selectedPathName: '/exchange/trading',
    routeAccess: 'exchange',
  },
]

const NestedExchangeNavigationItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) =>
  pathname.includes('/exchange') ? (
    <Collapse
      in={pathname.includes(selectedPathName)}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        <>
          {nestedNavigationItems.map((nestedNavItem, index) => (
            <NestedNavMenuItem
              key={index}
              handleListItemClick={handleListItemClick}
              navItemData={nestedNavItem}
              pathname={pathname}
            />
          ))}
        </>
      </List>
    </Collapse>
  ) : null

export default NestedExchangeNavigationItems
