import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'
import { Withdrawals } from 'containers/Exchange/views'

export const FETCH_WIRED_DEPOSITS_UNDER_REVIEW_PROCESS =
  'pendingDualAuth/FETCH_WIRED_DEPOSITS_UNDER_REVIEW_PROCESS'
export const FETCH_WIRED_DEPOSITS_UNDER_REVIEW_SUCCESS =
  'pendingInternalTransfers/FETCH_WIRED_DEPOSITS_UNDER_REVIEW_SUCCESS'
export const FETCH_WIRED_DEPOSITS_UNDER_REVIEW_ERROR =
  'pendingInternalTransfers/FETCH_WIRED_DEPOSITS_UNDER_REVIEW_ERROR'

export const FETCH_WIRED_DEPOSITS_PENDING_RELEASE_PROCESS =
  'pendingDualAuth/FETCH_WIRED_DEPOSITS_PENDING_RELEASE_PROCESS'
export const FETCH_WIRED_DEPOSITS_PENDING_RELEASE_SUCCESS =
  'pendingInternalTransfers/FETCH_WIRED_DEPOSITS_PENDING_RELEASE_SUCCESS'
export const FETCH_WIRED_DEPOSITS_PENDING_RELEASE_ERROR =
  'pendingInternalTransfers/FETCH_WIRED_DEPOSITS_PENDING_RELEASE_ERROR'

export const FETCH_WIRED_WITHDRAWALS_REVIEW_PROCESS =
  'pendingDualAuth/FETCH_WIRED_WITHDRAWALS_REVIEW_PROCESS'
export const FETCH_WIRED_WITHDRAWALS_REVIEW_SUCCESS =
  'pendingInternalTransfers/FETCH_WIRED_WITHDRAWALS_REVIEW_SUCCESS'
export const FETCH_WIRED_WITHDRAWALS_REVIEW_ERROR =
  'pendingInternalTransfers/FETCH_WIRED_WITHDRAWALS_REVIEW_ERROR'

export const UPDATE_WIRED_DEPOSITS_SUCCESS = 'UPDATE_WIRED_DEPOSITS_SUCCESS'
export const UPDATE_WIRED_DEPOSITS_ERROR = 'UPDATE_WIRED_DEPOSITS_ERROR'
export const UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_SUCCESS ='UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_SUCCESS'
export const UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_ERROR='UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_ERROR'
export const UPDATE_WIRED_WITHDRAWALS_SUCCESS='pendingInternalTransfers/UPDATE_WIRED_WITHDRAWALS_SUCCESS'
export const UPDATE_WIRED_WITHDRAWALS_ERROR='UPDATE_WIRED_WITHDRAWALS_ERROR'

export const fetchWiredDepositUnderReview = () => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_WIRED_DEPOSITS_UNDER_REVIEW_PROCESS,
  })
  try {
    res = await getAxios().get('/wired/deposits/review/update')
    const { data } = res
    var sortedResults = data.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    )
    dispatch({
      type: FETCH_WIRED_DEPOSITS_UNDER_REVIEW_SUCCESS,
      payload: {
        data: sortedResults,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WIRED_DEPOSITS_UNDER_REVIEW_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateWiredDeposits = deposits => async dispatch => {
  try {
    dispatch({
      type: UPDATE_WIRED_DEPOSITS_SUCCESS,
      payload: {
        data: deposits,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_WIRED_DEPOSITS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateWiredDepositsPendingRelease = deposits => async dispatch => {
  try {
    dispatch({
      type: UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_SUCCESS,
      payload: {
        data: deposits,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_WIRED_DEPOSITS_PENDNG_RELEASE_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchWiredDepositPendingRelease = () => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_WIRED_DEPOSITS_PENDING_RELEASE_PROCESS,
  })
  try {
    res = await getAxios().get('/wired/deposits/pending-release')
    const { data } = res
    var sortedResults = data.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    )
    dispatch({
      type: FETCH_WIRED_DEPOSITS_PENDING_RELEASE_SUCCESS,
      payload: {
        data: sortedResults,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WIRED_DEPOSITS_PENDING_RELEASE_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchWiredWithrawalReview = () => async dispatch => {
  let res = null
  dispatch({
    type: FETCH_WIRED_WITHDRAWALS_REVIEW_PROCESS,
  })
  try {
    res = await getAxios().get('/wired/withdrawal/review')
    const { data } = res
    var sortedResults = data.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    )
    dispatch({
      type: FETCH_WIRED_WITHDRAWALS_REVIEW_SUCCESS,
      payload: {
        data: sortedResults,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WIRED_WITHDRAWALS_REVIEW_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateWiredWithdrawals = withdrawals => async dispatch => {
  try {
    dispatch({
      type: UPDATE_WIRED_WITHDRAWALS_SUCCESS,
      payload: {
        data: withdrawals,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_WIRED_WITHDRAWALS_ERROR,
      payload: {
        error,
      },
    })
  }
}
