import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown } from '../fields'
import { submit } from './submit'
import IconLoading from '../../components/atoms/IconLoading'
import { fetchAvailableAccountActionsStart } from '../../redux/requested-account-actions/actions'

export const titleCase = (s) =>
  s.toLowerCase().replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ` ${d.toUpperCase()}`)

let Form = ({
  submitting, error, invalid, handleSubmit, onCancel, onSubmit, availableActions, fetchAvailableAccountActions,
}) => {
  const { data, loading } = availableActions
  const options = useMemo(() => data.map(o => ({ value: o, label: titleCase(o) })), [data])

  useEffect(() => {
    fetchAvailableAccountActions()
  }, [])

  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(async values => {
          await onSubmit(values)
        })}
      >
        <FormTitle>Add Requested Account Action</FormTitle>
        <InputGroup align={'flex-start'} direction={'column'}>
          {loading && <IconLoading />}
          {!loading && <Field
            label={'Action'}
            name={'requestedAction'}
            width={'100%'}
            validate={[required]}
            component={renderDropdown}
            options={options}
            fullWidth
          />}
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const mapDispatchToProps = (dispatch) => ({
  fetchAvailableAccountActions() {
    dispatch(fetchAvailableAccountActionsStart())
  },
})

const mapStateToProps = (state) => ({
  availableActions: state.requestedAccountActions.availableActions,
})

const ReduxForm = connect(mapStateToProps, mapDispatchToProps)(Form)

export default ReduxForm
