import styled, { keyframes } from 'styled-components'
import { BLUE } from 'theme/colors'

const spinningSearch = keyframes`
   0% { transform: rotate(0deg) translateX(80px) translateY(20px) rotate(0deg); }
  100% { transform: rotate(-360deg) translateX(80px) translateY(20px) rotate(360deg); }
`

export const StyledNoContentPage = styled.div`
  text-align: center;
  padding: 12px;
  color: ${BLUE};
  background-color: aliceblue;
  border-radius: 12px;
  height: ${({ height }) => height};

  .findPageIcon {
    font-size: ${({ height }) => height || '350'}px;
    color: 'grey';
    opacity: 0.2;
    margin-right: ${({ height }) => (height ? '0px' : '-220')}px;
    margin-bottom: 18px;
  }

  .searchIcon {
    animation: 5s infinite ${spinningSearch} ease-in-out;
    animation-timing-function: linear;
    transform-origin: 80% 40%;
    font-size: 250px;
  }
`

export const StyledMessage = styled.h4`
  margin-top: ${({ messageSpacing }) => messageSpacing || 80}px;
  width: ${({ width }) => width}px;
`
