import { FontColor } from 'components/atoms/Generic'
import { FullscreenCentered } from 'components/layout'
import { ROLES, checkRole } from 'helpers/roleBasedAccess'
import React, { createContext, useEffect, useState } from 'react'
import { BASE } from 'theme/base-units'
import { PANEL_BLUE, WHITE } from 'theme/colors'
import ValrAdminScreen from 'containers/Home/ValrAdminScreen'

import authService from 'services/Auth'
import IconLoading from 'components/atoms/IconLoading'

const AuthContext = createContext<{
  handleAuthentication: () => void
  handleLogout: () => void
  isInitializing: boolean
  isLoggingOut: boolean
}>({
  handleAuthentication: () => {},
  handleLogout: () => {},
  isInitializing: true,
  isLoggingOut: false,
})

export function useAuth() {
  return React.useContext(AuthContext)
}

const InitializingDisplay = () => (
  <FullscreenCentered bgColor={WHITE}>
    <FontColor color={PANEL_BLUE}>
      <IconLoading size={BASE * 5} />
      <p>Initializing...</p>
    </FontColor>
  </FullscreenCentered>
)

export const AuthContextProvider = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true)
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const handleAuthInit = async () => {
    setIsInitializing(true)
    await authService.init()
    setIsInitializing(false)
  }

  const handleLogout = async () => {
    setIsLoggingOut(true)
    await authService.logout()
    setIsLoggingOut(false)
  }

  useEffect(() => {
    handleAuthInit()
  }, [])

  if (isLoggingOut) {
    return <ValrAdminScreen title="Good bye" message="Logging out" />
  }

  return (
    <AuthContext.Provider
      value={{
        handleAuthentication: authService.handleAuthentication,
        handleLogout,
        isInitializing,
        isLoggingOut,
      }}
    >
      {isInitializing ? <InitializingDisplay /> : children}
    </AuthContext.Provider>
  )
}
