import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { NavLink, Switch, Route } from 'react-router-dom'
import { ContentContainer, Spacer } from 'components/atoms/Generic/index'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  fetchCorporateSanctionInformation,
  clearFoundSanctionedCorporate,
} from 'redux/sanctionedCorporate/actions'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import {
  fetchAccountCorporateInformation,
  fetchAccountDocumentsInformation,
  clearCorporateState,
} from 'redux/corporate/actions'
import {
  getEntitiesForCorporate,
  clearCorporateEntitiesState,
} from 'redux/corporateEntities/actions'
import IconButton from 'components/atoms/IconButton/index'
import ErrorToast from 'components/molecules/ErrorToast/index'

import CorporateDocuments from './pages/CorporateDocuments'
import CorporateAccountForm from './forms/CorporateForm'
import CorporateGeneralInfo from './pages/CorporateGeneralInfo'
import { StyledCorporateActions } from './pages/Styles'

import SanctionedCorporateInformation from '../SanctionedCorporateInformation/SanctionedCorporateInformation'

const CorporateAccount = props => {
  const { accountId } = props.match.params
  const { corporateAccountData, isLoading } = props
  const {
    location: { pathname },
  } = props

  const {
    isCheckingCorporateEditorRole,
    canEditCorporateInformation,
  } = corporateAccountData

  const routes = [
    `/accounts/byId/${accountId}/corporate`,
    `/accounts/byId/${accountId}/corporate/documents`,
  ]
  const [currentPage, setCurrentPage] = useState(routes[0])
  const [isEditingCorporate, setIsEditingCorporate] = useState(false)
  const [corporateModalView, setCorporateModalView] = useState(false)
  const { isUpdating, ...corporateFormData } = corporateAccountData

  const isFormActive =
    isEditingCorporate || corporateModalView || !corporateFormData.createdAt

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const handleCorporateAction = (isEditing, isViewing) => {
    setIsEditingCorporate(isEditing)
    setCorporateModalView(isViewing)
  }
  const memoizedGeneralInfo = useCallback(
    () => <CorporateGeneralInfo accountId={accountId} />,
    [],
  )

  const memoizedCorporateDocuments = useCallback(
    () => <CorporateDocuments accountId={accountId} />,
    [],
  )

  const handleClearCorporateState = () => {
    props.clearCorporateState()
    props.clearCorporateEntitiesState()
    props.clearFoundSanctionedCorporate()
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
    props.fetchAccountCorporateInformation(accountId)
    props.fetchCorporateSanctionInformation(accountId)
    return () => {
      handleClearCorporateState()
    }
  }, [])

  useEffect(
    () => {
      try {
        if (!isLoading && corporateAccountData.type) {
          props.fetchAccountDocumentsInformation(
            accountId,
            corporateAccountData.type,
          )
          if (corporateAccountData.id) {
            props.getEntitiesForCorporate(corporateAccountData.id)
          }
        }
      } catch (error) {
        ErrorToast(error)
      }
    },
    [
      corporateAccountData.createdAt,
      corporateAccountData.type,
      corporateAccountData.otherType,
    ],
  )

  return (
    <div style={{ overflow: 'hidden' }}>
      {(isLoading || isCheckingCorporateEditorRole) && <LoadingBlock />}
      <Fragment>
        {!isLoading &&
          !isCheckingCorporateEditorRole &&
          isFormActive && (
            <CorporateAccountForm
              isUpdatingCorporate={isUpdating}
              initialValues={corporateFormData}
              formType={corporateFormData.createdAt ? 'edit' : 'create'}
              form={'corporateForm'}
              corporateModalView={corporateModalView}
              isEditingCorporate={isEditingCorporate}
              accountId={accountId}
              closeEditingState={() => setIsEditingCorporate(false)}
            />
          )}
        {!isLoading &&
          !isCheckingCorporateEditorRole &&
          corporateAccountData.createdAt && (
            <Fragment>
              <SanctionedCorporateInformation
                showErrorIcon
                accountId={accountId}
              />
              <StyledCorporateActions>
                <h3>{corporateAccountData.name}</h3>
                <Spacer margin="8px">
                  <IconButton
                    onClick={() =>
                      handleCorporateAction(!isEditingCorporate, false)
                    }
                    disabled={
                      !canEditCorporateInformation ||
                      isCheckingCorporateEditorRole
                    }
                    icon={isEditingCorporate ? <CancelIcon /> : <EditIcon />}
                  />
                </Spacer>
                <IconButton
                  icon={
                    corporateModalView ? (
                      <CancelIcon />
                    ) : (
                      <VisibilityIcon style={{ color: '#13ab18' }} />
                    )
                  }
                  onClick={() => {
                    handleCorporateAction(false, !corporateModalView)
                  }}
                />
              </StyledCorporateActions>
              <TopNavigationBar
                value={currentPage}
                onChange={handleChange}
                variant="fullWidth"
              >
                <NavigationTab
                  value={routes[0]}
                  label="General Info"
                  component={ForwardedNavLink}
                  to={routes[0]}
                  exact
                />
                <NavigationTab
                  value={routes[1]}
                  label={corporateAccountData.type}
                  component={ForwardedNavLink}
                  to={routes[1]}
                  exact
                />
              </TopNavigationBar>
              <ContentContainer>
                <Switch>
                  <Route
                    path={routes[0]}
                    component={memoizedGeneralInfo}
                    exact
                  />
                  <Route
                    path={routes[1]}
                    component={memoizedCorporateDocuments}
                    exact
                  />
                </Switch>
              </ContentContainer>
            </Fragment>
          )}
      </Fragment>
    </div>
  )
}

CorporateAccount.propTypes = {
  corporateAccountData: PropTypes.shape({
    id: PropTypes.number,
    account_id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    purpose: PropTypes.string,
    nature: PropTypes.string,
    createdAt: PropTypes.string,
    isLoading: PropTypes.bool,
    corporateAddressDetails: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
    }),
  }),
  isLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  const corporateAccountData = state.corporate
  const { accountType } = state

  return {
    corporateAccountData,
    isLoading: corporateAccountData.isLoading,
    isUpdating: corporateAccountData.isUpdating,
    accountType,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountCorporateInformation,
      fetchAccountDocumentsInformation,
      fetchCorporateSanctionInformation,
      getEntitiesForCorporate,
      clearCorporateState,
      clearCorporateEntitiesState,
      clearFoundSanctionedCorporate,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateAccount)
