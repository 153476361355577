import {
  AccountProfileSetttingsActions,
} from './actions'

type loadingStates = 'pristine' | 'loading' | 'done'
export interface AccountProfileSettingsInitialState {
  status: loadingStates
  canMarginTradeFiat: any,
}

const initialState: AccountProfileSettingsInitialState = {
  status: 'pristine',
  canMarginTradeFiat: {},
}

interface Action {
  type: AccountProfileSetttingsActions,
  payload: any,
}

export default (state = initialState, action:Action):AccountProfileSettingsInitialState => {
  switch (action.type) {
    case 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_PROCESS': {
      return {
        ...state,
        status: 'loading',
      }
    }

    case 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_SUCCESS': {
      const { canMarginTradeFiat } = action.payload
      return {
        ...state,
        status: 'done',
        canMarginTradeFiat,
      }
    }

    case 'FETCH_ACCOUNT_MARGIN_TRADE_FIAT_STATUS_ERROR': {
      return {
        ...state,
        status: 'loading',
      }
    }

    case 'UPDATE_ACCOUNT_TRADE_FIAT_STATUS_SUCCESS': {
      const { newStatus } = action.payload
      return {
        ...state,
        status: 'done',
        canMarginTradeFiat: newStatus,
      }
    }

    default:
      return state
  }
}
