import {
  FETCH_NAME_AND_SURNAME_SUCCESS,
  FETCH_NAME_AND_SURNAME_SUCCESS_ERROR,
  FETCH_NAME_AND_SURNAME_PROCESS,
  FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS,
  FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS_ERROR,
  FETCH_NAME_AND_SURNAME_BY_ID_PROCESS,
  REMOVE_SUBMITTED_ITEM,
  UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_SUCCESS,
  UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_START,
  UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_ERROR,
} from './actions'

const initialState = {
  nameAndSurnameStatus: 'pristine',
  nameAndSurnameData: [],
  nameAndSurnameDataById: {},
  nameAndSurnameDataByIdLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NAME_AND_SURNAME_PROCESS: {
      return {
        ...state,
        nameAndSurnameStatus: 'loading',
      }
    }

    case FETCH_NAME_AND_SURNAME_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        nameAndSurnameStatus: 'done',
        nameAndSurnameData: data,
      }
    }

    case FETCH_NAME_AND_SURNAME_SUCCESS_ERROR: {
      return {
        ...state,
        nameAndSurnameData: [],
      }
    }

    case UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_START: {
      return {
        ...state,
        nameAndSurnameStatus: 'loading',
      }
    }

    case UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.nameAndSurnameData &&
        state.nameAndSurnameData.map((nameSurnameChangeDualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (
                newUserOn.id === nameSurnameChangeDualAuth.id &&
                newUserOn.userActive
              ) {
                nameSurnameChangeDualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === nameSurnameChangeDualAuth.id &&
                      userOnLine.userActive,
                  ).userName,
                }
              } else {
                nameSurnameChangeDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            nameSurnameChangeDualAuth.active = {
              userActive: false,
              activePersonName: '',
            }
          }
          return nameSurnameChangeDualAuth
        })

      return {
        ...state,
        nameAndSurnameData: updatedDualData,
        nameAndSurnameStatus: 'done',
      }
    }

    case UPDATE_NAME_AND_SURNAME_DUAL_AUTH_USER_ROW_ERROR: {
      return {
        ...state,
        nameAndSurnameStatus: 'pristine',
      }
    }

    case REMOVE_SUBMITTED_ITEM: {
      const filteredArray = state.nameAndSurnameData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        nameAndSurnameData: filteredArray,
      }
    }

    case FETCH_NAME_AND_SURNAME_BY_ID_PROCESS: {
      return {
        ...state,
        nameAndSurnameDataById: {},
        nameAndSurnameDataByIdLoading: true,
      }
    }

    case FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        nameAndSurnameDataByIdLoading: false,
        nameAndSurnameDataById: data,
      }
    }

    case FETCH_NAME_AND_SURNAME_BY_ID_SUCCESS_ERROR: {
      return {
        ...state,
        nameAndSurnameDataById: {},
        nameAndSurnameDataByIdLoading: false,
      }
    }
    default:
      return state
  }
}
