import React from "react";
import AddKeycloakRoleForm from "../../../forms/AddKeycloakRole";
import FerryAuths from "../../FerryAuths";

const ManageKeycloakRoles = props => {
  return (
    <>
      <h1>Manage Keycloak Roles</h1>
      <AddKeycloakRoleForm/>
      <FerryAuths ferryAuthRequestType={'create_keycloak_role_for_group'}/>
    </>
  )
}
export default ManageKeycloakRoles
