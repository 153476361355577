import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  camelCaseToText,
  capitalizeFirstLetter,
  flattenObject,
} from 'helpers/utils'

import Row from './Row'
/**
 * Insert text at cursor position.
 * @param {array} headings headings and values follow the sequence of the headings
 * @param {array} rows the data for your tables values
 * @param {array} customColumnHeadings will be any additional headings you want to show
 * @param {array{nodes{TableCell}}} customColumnValues will be any additional values you want to show with the corresponding
 *  headings you must provide a table cell for correct formatting
 * @param {array{node{TableCell}}} customColumnValues will be any additional values you want to show with the corresponding headings
 * you must provide a table cell for correct formatting
 * @param {array} positions structure the positioning of your headings and values e.g ['center'] heading[0] & value[0] = center
 */

export default function CustomTable({
  headings = ['name', 'age'],
  rows = [{ name: 'kevin', age: 30 }, { name: 'meghan', age: 30 }],
  customColumnHeadings = [],
  customColumnValues = [],
  positions = [],
  detailsHeading = 'Details',
  customRows = [],
}) {
  const updatedFlattenedRows = rows.map(row => flattenObject(row))
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headings.map((heading, headingIndex) => (
              <TableCell key={headingIndex} align={positions[headingIndex]}>
                {capitalizeFirstLetter(camelCaseToText(heading))}
              </TableCell>
            ))}
            {customColumnHeadings.map((cell, cellIndex) => (
              <Fragment key={cellIndex}>{cell}</Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {customRows}
          {customRows.length === 0 &&
            updatedFlattenedRows.map((row, index) => (
              <Row
                key={index}
                row={row}
                headings={headings}
                detailsHeading={detailsHeading}
                positions={positions}
                customColumnHeadings={customColumnHeadings}
                customColumnValues={customColumnValues}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
