import {
  APPROVAL_STATUS_TYPES,
  SanctionedCorporate,
} from 'interfaces/sanctions'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import ErrorToast from 'components/molecules/ErrorToast'
import { DispatchAction } from 'interfaces'

export type CorporateSanctionActionTypes =
  | 'FETCH_CORPORATE_SANCTION_RESULTS_START'
  | 'FETCH_CORPORATE_SANCTION_RESULTS_SUCCESS'
  | 'FETCH_CORPORATE_SANCTION_RESULTS_ERROR'
  | 'ACKNOWLEDGE_CORPORATE_SANCTION_START'
  | 'ACKNOWLEDGE_CORPORATE_SANCTION_SUCCESS'
  | 'ACKNOWLEDGE_CORPORATE_SANCTION_ERROR'
  | 'CLEAR_CORPORATE_SANCTION_DATA'

interface AcknowledgeCorporateSanctionResponseData {
  data: SanctionedCorporate
}
export interface AcknowledgeCorporateSanctionParams {
  accountId: string | number
  checkInformation?: {
    comment: string
    approvalStatus: APPROVAL_STATUS_TYPES
  }
}

export const fetchCorporateSanctionInformation =
  (accountId: string | number) =>
  async (dispatch: DispatchAction<CorporateSanctionActionTypes>) => {
    try {
      dispatch({
        type: 'FETCH_CORPORATE_SANCTION_RESULTS_START',
      })

      const { data } = await getAxios().get(
        `/accounts/sanctions/corporate/scan/${accountId}`,
      )

      dispatch({
        type: 'FETCH_CORPORATE_SANCTION_RESULTS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_CORPORATE_SANCTION_RESULTS_ERROR',
      })
    }
  }

export const acknowledgeCorporateSanction =
  ({ accountId, checkInformation }: AcknowledgeCorporateSanctionParams) =>
  async (dispatch: DispatchAction<CorporateSanctionActionTypes>) => {
    try {
      dispatch({
        type: 'ACKNOWLEDGE_CORPORATE_SANCTION_START',
      })

      const { data }: AcknowledgeCorporateSanctionResponseData =
        await getAxios().post(
          `/accounts/sanctions/corporate/editor/acknowledge/${accountId}`,
          {
            checkInformation,
          },
        )

      dispatch({
        type: 'ACKNOWLEDGE_CORPORATE_SANCTION_SUCCESS',
        payload: data,
      })
      toast('Successfully Acknowledged Sanction', {
        type: toast.TYPE.SUCCESS,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'ACKNOWLEDGE_CORPORATE_SANCTION_ERROR',
      })
    }
  }

export const clearFoundSanctionedCorporate =
  () => async (dispatch: DispatchAction<CorporateSanctionActionTypes>) => {
    dispatch({
      type: 'CLEAR_CORPORATE_SANCTION_DATA',
    })
  }
