import { all, takeLatest, put } from 'redux-saga/effects'
import { fetchFiatConfigStart } from 'redux/fiat/actions'
import { fetchAvailableAccountActionsStart } from 'redux/requested-account-actions/actions'

function* init() {
  yield put(fetchFiatConfigStart())
  yield put(fetchAvailableAccountActionsStart())
}

export function* appInitSaga() {
  return yield all([
    takeLatest('USER_AUTHENTICATED', init),
  ])
}
