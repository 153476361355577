import styled, { css } from 'styled-components'
import {
  ListItem as MUIListItem,
  ListItemIcon as MUIListItemIcon,
  Collapse as MUICollapse,
} from '@material-ui/core'
import { BASE } from 'theme/base-units'
import {
  BLACK, BLUE_GRAY, GRAY_2, WHITE,
} from 'theme/colors'

interface StyledCurrentUserWelcomeProps {
  theme: string
}

export const ListItem = styled(MUIListItem)<any>`
  background-color: ${({ selected = false, theme }) =>
    selected
      ? `${theme.navigationTabs.selected.background} !important`
      : 'transparent'};
`

export const ListItemIcon = styled(MUIListItemIcon)`
  color: ${WHITE};
`

export const Collapse = styled(MUICollapse)`
  background-color: rgba(0, 0, 0, 0.2);
`

export const CurrentUserBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledWelcome = styled.div`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 80%;
      flex-direction: column;
`

export const StyledUserMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 20px;
  z-index: 100;
  width: 400px;
  padding: 20px 0 20px 20px;
`

export const StyledCurrentUserWelcome = styled.div<
  StyledCurrentUserWelcomeProps
>`
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 2px;
  border-radius: 20px;
  cursor: pointer;

  ${props =>
    props.theme === 'darkTheme'
      ? css`
          background-color: rgba(255, 255, 255, 0.01);
          border: 1px solid ${GRAY_2};
          color: ${WHITE};
        `
      : css`
          background-color: ${BLUE_GRAY};
          border: 1px solid ${GRAY_2};
          color: ${BLACK};
        `};
`

export const StyledCurrentUserWelcomeText = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const HoverConnector = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 9px ${BASE / 2}px;
  border-radius: ${BASE * 2}px;
`

export const StyledUserMenuWrapper = styled.div`
  position: relative;
`

export const StyledCardContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  > div {
    height: 100px;
    width: 100px;
    margin: 20px;
  }
`
