import React, {useState} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form'
import {
  Button,
  FormContainer,
  FormTitle,
} from 'components/atoms/Form'
import {requiredDropdownValue} from 'forms/fieldValidators'
import {bindActionCreators} from 'redux'
import IconLoading from 'components/atoms/IconLoading'
import {renderDropDownField} from '../fields'
import AlertDialog from './confirmation'
import {getKeycloakGroupsOptionsDataDFS} from '../../helpers/utils'
import {submitKeycloakGroupMembership} from "./submitKeycloakGroupMembership"
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab"

function getGroupName(groups, id) {
  return groups.find((group) => group.value === id)?.label
}

const KeycloakGroupMembership = props => {
  const [action, setAction] = useState('ADD')
  const [formShown, setFormShown] = useState(false)
  const toggleForm = () => setFormShown(prevState => !prevState)

  const {
    keycloakAdminGroups,
    keycloakUser,
  } = props

  const handleActionChange = (event, newAction) => {
    if (newAction) {
      props.reset('keycloakGroupAddOrRemoveUser')
      setAction(newAction)
    }
  }
  const adminKeycloakGroups = getKeycloakGroupsOptionsDataDFS(keycloakAdminGroups.data)
  const foundUser = keycloakUser && keycloakUser.info
  const userKeycloakGroups = keycloakUser && keycloakUser.info && keycloakUser.info.groups.map((group) => {
    return {
      value: group.id,
      label: group.name
    }
  }) || []

  const availableGroups = adminKeycloakGroups.filter((adminGroup) => {
      return userKeycloakGroups.find((userGroup) =>
        userGroup.label === adminGroup.label
      ) === undefined
    }
  )

  return (
    <FormContainer>
      <FormTitle>Keycloak Group Add/Remove User</FormTitle>
      <ToggleButtonGroup
        color="primary"
        value={action}
        exclusive
        onChange={handleActionChange}
        aria-label="Platform"
      >
        <ToggleButton value="ADD">Add</ToggleButton>
        <ToggleButton value="REMOVE">Remove</ToggleButton>
      </ToggleButtonGroup>
      <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
        <p>{foundUser.user.id}</p>
        <p>{foundUser.user.firstName}</p>
        <p>{foundUser.user.lastName}</p>
        <p>{foundUser.user.email}</p>

        {action === 'REMOVE' && (
          <Field
            name={'keycloakGroupRemoveFromUser'}
            showNoneLabel={false}
            component={renderDropDownField}
            disabled={!userKeycloakGroups || userKeycloakGroups.length === 0}
            label={(!userKeycloakGroups || userKeycloakGroups.length === 0) ? <IconLoading/> : 'Current Admin Groups'}
            width="25%"
            validate={[requiredDropdownValue]}
            options={userKeycloakGroups}
          />
        )}
        {action === 'ADD' && (
          <Field
            name={'keycloakGroupAddToUser'}
            showNoneLabel={false}
            component={renderDropDownField}
            disabled={!availableGroups || availableGroups.length === 0}
            label={(!availableGroups || availableGroups.length === 0) ? <IconLoading/> : 'Available Admin Groups'}
            width="25%"
            validate={[requiredDropdownValue]}
            options={availableGroups}
          />
        )}

        <AlertDialog
          open={formShown}
          handleClose={toggleForm}
          onSubmit={() => {
            props.submitKeycloakGroupAddOrRemoveUser({
              keycloakGroupId: props.formValues.keycloakGroupAddToUser || props.formValues.keycloakGroupRemoveFromUser,
              keycloakUserId: foundUser.user.id,
              requestType: action === 'ADD' ? 'add_keycloak_user_to_group' : 'remove_keycloak_user_from_group'
            })
          }}
          message={`Are you sure that you want to ${action === 'ADD' ? 'add ' : 'remove'} ${foundUser.user.email} ${action === 'ADD' ? ' to ' : ' from '} ${getGroupName(action === 'ADD' ? availableGroups : adminKeycloakGroups, props.formValues?.keycloakGroupAddToUser || props.formValues?.keycloakGroupRemoveFromUser)}`}
        />
        <Button margin='10px' onClick={toggleForm} variant="contained"
                disabled={!(props.formValues && props.formValues?.keycloakGroupAddToUser || props.formValues && props?.formValues.keycloakGroupRemoveFromUser)}>
          {action === 'ADD' ? 'Add' : 'Remove'}
        </Button>
      </div>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'keycloakGroupAddOrRemoveUser',
  initialValues: {
    user: {},
    formValues: {}
  },
})(KeycloakGroupMembership)

const mapStateToProps = state => ({
  formValues: state.form && (state.form.keycloakGroupAddOrRemoveUser && state.form.keycloakGroupAddOrRemoveUser.values),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitKeycloakGroupAddOrRemoveUser: submitKeycloakGroupMembership,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduxForm)
