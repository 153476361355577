import React, { Component } from 'react'
import { TicketsGrid } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import Chip from 'components/atoms/Chip/Chip'

class ShowTicketsCommentsRow extends Component {
  returnVariation(isPublic) {
    const normal = isPublic ? 'External' : 'Internal'
    const lowerCase = isPublic ? 'external' : 'internal'
    return { lowerCase, normal }
  }

  render() {
    const {
      comments: { created_at, body, author_id, public: isPublic },
      users,
    } = this.props

    return (
      <div>
        <TicketsGrid isPublic={this.returnVariation(isPublic).lowerCase}>
          <p> {moment(created_at).format('YYYY-MMM-DD HH:mm:ss')}</p>
          <p>{body}</p>
          <p>
            {users &&
              users.length > 0 &&
              users.map(user => (author_id === user.id ? user.name : ''))}
          </p>
          <Chip
            label={this.returnVariation(isPublic).normal}
            chipType={this.returnVariation(isPublic).lowerCase}
          />
        </TicketsGrid>
      </div>
    )
  }
}
export default ShowTicketsCommentsRow
