import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Headers } from './headers'
import Row from './row'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'

class CreditCardDepositsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      formShown: {
        showForm: false,
        id: '',
      },
      expandRawData: {
        expand: false,
        id: '',
      },
      addNoteFormShown: '',
      highlightedId: '',
    }
  }

  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  showRawData = id => {
    if (id !== this.state.expandRawData.id) {
      this.setState({ expandRawData: { id, expand: true } })
    } else {
      this.setState({ expandRawData: { id: '', expand: false } })
    }
  }

  toggleForm = id => {
    if (id !== this.state.formShown.id) {
      this.setState({
        formShown: { id, showForm: true },
        expandRawData: { id: '', expand: false },
        addNoteFormShown: '',
      })
    } else {
      this.setState({
        formShown: { id: '', showForm: false },
        expandRawData: { id: '', expand: false },
        addNoteFormShown: '',
      })
    }
  }

  addTotalAmount = deposit => {
    const total = deposit.map(d => d.amount).reduce((accumulator, value) => {
      const checkedCurrentValue = value
        ? parseFloat(
            typeof value === 'string' ? value.split(',').join('') : value,
          )
        : 0
      return accumulator + checkedCurrentValue
    }, 0)
    return `(R${new BigNumber(total).toFormat(2)})`
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  render() {
    const { status, data } = this.props.deposits
    const hasDeposits = data && data.length > 0
    return (
      <Fragment>
        <ExpandingItem
          title={`DEPOSITS UNDER REVIEW`}
          itemsAmount={(data && data.length) || 0}
          totalAmount={this.addTotalAmount(data) || '(R0)'}
          expanded={this.state.expanded}
          onExpand={this.toggleView}
          opensMultiple
          expandedView={
            <>
              {!hasDeposits && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.DEPOSITS.type}
                />
              )}
              {hasDeposits && (
                <Table>
                  <Headers />
                  <TableBody>
                    {status !== 'done' && <LoadingBlock />}
                    {status === 'done' &&
                      data.map((deposit, i) => {
                        deposit.createdAt = moment(deposit.createdAt).format(
                          'YYYY-MMM-DD HH:mm:ss',
                        )
                        return (
                          <Row
                            key={i}
                            data={deposit}
                            toggleForm={this.toggleForm}
                            formShown={this.state.formShown}
                            setHighlightedId={this.setHighlightedId}
                            isHighlighted={
                              this.state.highlightedId === deposit.reviewItemId
                            }
                            expandRawData={this.state.expandRawData.expand}
                            showRawData={this.showRawData}
                          />
                        )
                      })}
                  </TableBody>
                </Table>
              )}
            </>
          }
        />
      </Fragment>
    )
  }
}

export default CreditCardDepositsContainer
