import React, { Component, Fragment } from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { DetailsBlockWithTitle } from 'components/atoms/Details/index'
import RawDataDualAuth from 'components/atoms/RawDataDualAuth/index'
import * as moment from 'moment'

class LogTableRow extends Component {
  constructor(props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.state = {
      expanded: false,
    }
  }

  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render() {
    const { accountId, logEvent, logMessage } = this.props.log
    const { expanded } = this.state
    return (
      <Fragment>
        <TableRow onClick={this.toggleView} hover>
          <TableCell style={{ fontSize: '14px' }} width={'8%'}>{accountId}</TableCell>
          <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }} width={'20%'}>{logEvent}</TableCell>
          <TableCell
            width={'60%'}
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              fontSize: '14px',
            }}
          >{logMessage}</TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'12%'}>
            {moment(this.props.log['@timestamp']).format(
              'YYYY-MMM-DD HH:mm:ss',
            )}
          </TableCell>
        </TableRow>
        {expanded && (
          <TableCell colSpan={4}>
            <DetailsBlockWithTitle title={'Raw Data'}>
              <RawDataDualAuth data={this.props.log} />
            </DetailsBlockWithTitle>
          </TableCell>
        )}
      </Fragment>
    )
  }
}

export default LogTableRow
