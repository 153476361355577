import styled from 'styled-components'

export const FullWidthBlock = styled.div`
  width: 100%;
  padding-top: 10px;
`

export const ExpansionPanelInner = styled.div`
  padding: 0 0 20px 0;
`
