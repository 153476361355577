import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_APPLICATION_CONFIG_PROCESS =
  'balances/ FETCH_APPLICATION_CONFIG_PROCESS'
export const FETCH_APPLICATION_CONFIG_SUCCESS =
  'balances/FETCH_APPLICATION_CONFIG_SUCCESS'
export const FETCH_APPLICATION_CONFIG_ERROR =
  'balances/FETCH_APPLICATION_CONFIG_ERROR'

export const fetchConfig = (system, currency) => async (dispatch) => {
  dispatch({
    type: FETCH_APPLICATION_CONFIG_PROCESS,
  })
  let res = null
  try {
    const params = currency ? `/${currency}` : ''
    res = await getAxios().get(`/application/config/${system}${params}`)
    const { data } = res
    const compare = (a, b) => {
      const typeA = a.type.toUpperCase()
      const typeB = b.type.toUpperCase()
      let comparison = 0
      if (typeA > typeB) {
        comparison = 1
      } else if (typeA < typeB) {
        comparison = -1
      }
      return comparison
    }
    const sortedData = data.sort(compare)
    dispatch({
      type: FETCH_APPLICATION_CONFIG_SUCCESS,
      payload: {
        data: sortedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_APPLICATION_CONFIG_ERROR,
      payload: {
        error,
      },
    })
  }
}
