import { useEffect, useState } from 'react'

type KycRoutes =
  | '/accounts/kyc'
  | '/accounts/kyc/pending-list'
  | '/accounts/kyc/parachute'

const routes: KycRoutes[] = [
  '/accounts/kyc',
  '/accounts/kyc/pending-list',
  '/accounts/kyc/parachute',
]

export const useKycNavigation = (pathname: string) => {
  const [currentPage, setCurrentPage] = useState(routes[0])

  const handleRouteChange = (e, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    const route = routes.find(rt => pathname.includes(rt))
    if (route) {
      setCurrentPage(route)
    }
  }, [])

  return { routes, currentPage, handleRouteChange }
}
