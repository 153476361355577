import { Header } from 'components/atoms/Generic'
import React, { FC, useState } from 'react'
import getAxios from 'helpers/axios'
import { MultiDocumentViewer } from 'components/organisms/DocumentViewers/MultiDocumentViewer'
import { connectStateAndDispatch } from 'store'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { removeRiskProfileEddDocument } from 'redux/accounts/actions'

import RiskProfileEddDocumentItem from './RiskProfileEddDocumentItem'

import { RiskProfileEddDocument } from '../../../interfaces/riskProfile'
import { RISK_PROFILE_EDD_DOCUMENT_TYPES } from '../../../../CorporateAccount/const/RiskProfile'

interface RiskProfileEddDocumentsSectionProps {
  accountId: string
  eddDocuments: RiskProfileEddDocument[]
  removeRiskProfileEddDocument: (documentId: number) => Promise<void>
}

const RiskProfileEddDocumentsSection: FC<
  RiskProfileEddDocumentsSectionProps
> = (props) => {
  const { accountId, eddDocuments, removeRiskProfileEddDocument } = props
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(false)
  const [isDeletingDocument, setIsDeletingDocument] = useState(false)
  const [documentsBeingViewed, setDocumentsBeingViewed] = useState([])

  const handleViewDocuments = async (documentType: string) => {
    try {
      setIsFetchingDocuments(true)
      const { data } = await getAxios().get(
        `/risk-based-approach/profile/edd-documents/${accountId}/${documentType}`,
      )

      setDocumentsBeingViewed(data)
    } catch (error) {
      ErrorToast(error)
    }
    setIsFetchingDocuments(false)
  }

  const removeDocument = async (documentId: number) => {
    setIsDeletingDocument(true)
    await getAxios().delete(
      `/risk-based-approach/profile/${accountId}/edd-documents/${documentId}`,
    )
    await removeRiskProfileEddDocument(documentId)
    setIsDeletingDocument(false)
    toast('Document removed successfully', {
      type: toast.TYPE.SUCCESS,
    })
  }

  return (
    <>
      <Header title={'EDD Documents'} />
      {RISK_PROFILE_EDD_DOCUMENT_TYPES.map((type, i) => {
        const documentsByType = eddDocuments.filter(
          (document) => document.documentType === type.documentType,
        )

        return (
          <RiskProfileEddDocumentItem
            key={type.documentType}
            documentsByType={documentsByType}
            accountId={accountId}
            handleViewDocuments={handleViewDocuments}
            type={type}
          />
        )
      })}

      <MultiDocumentViewer
        isOpen={isFetchingDocuments || documentsBeingViewed.length > 0}
        handleCloseDocumentViewer={() => setDocumentsBeingViewed([])}
        documentsDataBeingViewed={documentsBeingViewed}
        isLoadingDocuments={isFetchingDocuments}
        isDeletingDocument={isDeletingDocument}
        removeDocument={removeDocument}
        setDocumentsDataBeingViewed={setDocumentsBeingViewed}
        setIsDeletingDocument={setIsDeletingDocument}
      />
    </>
  )
}
const actions = { removeRiskProfileEddDocument }
export default connectStateAndDispatch(
  null,
  actions,
  RiskProfileEddDocumentsSection,
)
