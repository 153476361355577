import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import FiatWithdrawals from './FiatWithdrawals'
import UsdWithdrawals from './UsdWithdrawals'
import CryptoWithdrawals from './CryptoWithdrawals'

const routes = [
  '/exchange/fiat-withdrawals',
  '/exchange/fiat-withdrawals/crypto-withdrawals',
  '/exchange/fiat-withdrawals/usdc',
]

const RenderAllWithdrawals = props => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(
    () => {
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
    },
    [pathname],
  )
  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="FIAT WITHDRAWALS"
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label="CRYPTO WITHDRAWALS"
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
        <NavigationTab
          value={routes[2]}
          label="USD WITHDRAWALS"
          component={ForwardedNavLink}
          to={routes[2]}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={FiatWithdrawals} exact />
          <Route path={routes[1]} component={CryptoWithdrawals} exact />
          <Route path={routes[2]} component={UsdWithdrawals} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}
export default RenderAllWithdrawals
