import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useStyles } from 'helpers/tables'

const headCells = [
    {
        id: 'accountId',
        disablePadding: false,
        label: 'Account Id',
    },
    {
        id: 'totalBorrowedInReference',
        disablePadding: false,
        label: 'Borrowed In Reference',
    },
    {
        id: 'referenceCurrency',
        disablePadding: false,
        label: 'Reference Currency',
    },
    {
        id: 'leverageMultiple',
        disablePadding: false,
        label: 'Leverage Multiple',
    },
    {
        id: 'marginFraction',
        disablePadding: false,
        label: 'Margin Fraction',
    },
    {
        id: 'collateralisedBalancesInReference',
        disablePadding: false,
        label: 'Collateralised Balances In Reference',
    },
    {
        id: 'autoCloseMarginFraction',
        disablePadding: false,
        label: 'Auto Close Margin Fraction',
    },
    {
        id: 'collateralisedMarginFraction',
        disablePadding: false,
        label: 'Collateralised Margin Fraction',
    },
    {
        id: 'accountLeverageHealth',
        disablePadding: false,
        label: 'Account Leverage Health',
    },
]


export const EnhancedTableHead = (props) => {
    const classes = useStyles(props)
    const {
        order, orderBy, onRequestSort,
    } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
}


