import React, { Dispatch, FC, useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { FlexRow, Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'
import { camelCaseToText } from 'helpers/utils'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { RiskScoreOption, RiskScoringFormOptions } from 'interfaces/riskProfile'

import { renderDropDownField, renderInput } from 'forms/fields'
import { FormTitle, InputGroup } from 'components/atoms/Form'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { required } from 'forms/fieldValidators'
import { Title } from 'components/atoms/Title'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'

import { RiskProfileFormContainer } from '../../Styles'

import { RiskProfileInitialValues } from '../../../interfaces/riskProfile'
import { MultipleSelect } from 'components/atoms/MultipleSelect'
import { refreshAccountDetailsForRBA } from 'redux/accounts/actions'

interface FormProps {
  initialValues: RiskProfileInitialValues
  handleSubmit: (
    cb: (values: any, dispatch: Dispatch<any>) => Promise<void>,
  ) => Promise<void>
  onSubmit: () => Promise<void>
  onCancel: () => void
  submitting: boolean
  invalid: boolean
  exists: boolean
  dirty: boolean
  submitSucceeded: boolean
  isFetchingOptions: boolean
  hasDiff: boolean
  riskScoringFormOptions: RiskScoringFormOptions
  reset: (name: string) => void
}

const Form: FC<FormProps> = (props) => {
  const [showExitModal, setShowExitModal] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const inputMargin = `0 12px ${BASE * 2}px 0`

  const handleGoBackClick = () => {
    if (!isSaved) return setShowExitModal(true)
    props.onCancel()
  }

  useEffect(() => {
    if (
      (props.exists && !props.dirty && !props.hasDiff) ||
      (props.submitSucceeded && !props.dirty && !props.hasDiff)
    ) {
      setIsSaved(true)
    } else {
      setIsSaved(false)
    }
  }, [props.dirty, props.submitSucceeded])

  return (
    <RiskProfileFormContainer onSubmit={props.handleSubmit(props.onSubmit)}>
      <Flexbox marginBottom={24} justify="space-between">
        <FormTitle>Risk Profile Information</FormTitle>
        <FlexRow>
          {!isSaved && <Title type="h4" text="You have unsaved changes" />}
          <IconButton onClick={handleGoBackClick}>
            <ArrowBack />
          </IconButton>
        </FlexRow>
      </Flexbox>
      {props.isFetchingOptions ? (
        <LoadingBlock message="Fetching risk scoring options..." />
      ) : (
        <InputGroup
          // @ts-ignore
          justify={'flex-start'}
          style={{ flexWrap: 'wrap' }}
        >
          <Field
            width="20%"
            label={'Name'}
            name={'name'}
            component={renderInput}
            validate={[required]}
            disabled
            margin={inputMargin}
          />
          <Field
            width="20%"
            label={'Surname'}
            name={'surname'}
            disabled
            validate={[required]}
            component={renderInput}
            margin={inputMargin}
          />
          {Object.keys(props.riskScoringFormOptions).map((key) =>
            key === 'productSelection' ? (
              <Field
                width="300px"
                key={key}
                label={camelCaseToText(key)}
                name={key}
                fullWidth={false}
                component={MultipleSelect}
                options={props.riskScoringFormOptions[key].map(
                  (option: RiskScoreOption) => option.value,
                )}
                margin={inputMargin}
              />
            ) : (
              <Field
                width="20%"
                key={key}
                label={camelCaseToText(key)}
                name={key}
                disabled={key === 'countryOfResidence'}
                component={renderDropDownField}
                options={props.riskScoringFormOptions[key].map(
                  (option: RiskScoreOption) => ({
                    value: option.value,
                    label: option.value,
                  }),
                )}
                margin={inputMargin}
              />
            ),
          )}
        </InputGroup>
      )}
      <Flexbox justify={'flex-end'} direction={'row'}>
        <MuiButton onClick={handleGoBackClick}>go back</MuiButton>
        <Spacer margin="0px 12px">
          <MuiButton
            type="submit"
            disabled={props.submitting || props.invalid || isSaved}
          >
            sav
            {props.submitting ? 'ing' : 'e'}
          </MuiButton>
        </Spacer>
      </Flexbox>
      <ConfirmationDialog
        open={showExitModal}
        proceed={() => {
          setShowExitModal(false)
          props.onCancel()
        }}
        title="You have unsaved changes are you sure you want to proceed?"
        message=""
        handleClose={() => setShowExitModal(false)}
      />
    </RiskProfileFormContainer>
  )
}

export const RiskProfileForm = reduxForm({
  form: 'riskProfileForm',
  initialValues: {
    sourceOfFunds: '',
    occupationStatus: '',
    purposeOfAccount: '',
    pepStatus: '',
    suspiciousTransactions: '',
    sourceOfWealth: '',
    ipLocation: '',
    productSelection: '',
    countryOfResidence: '',
    name: '',
    surname: '',
  },
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch(refreshAccountDetailsForRBA(props.accountId))
    props.onCancel()
  },
  destroyOnUnmount: true,
  enableReinitialize: true,
})(Form)
