import React, { Fragment, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { COMMENT_MESSAGES } from 'helpers/const'
import { EditableDropdown } from 'components/organisms/EditableDropdown/index'
import { Field, reduxForm } from 'redux-form'
import { required, requiredSelectDropdownValue } from 'forms/fieldValidators'
import {
  FormContainer,
  BlueLinkButton,
  Button,
  InputGroup,
  FormError,
  InputContainer,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { InfoLabel } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import { renderDropdown, renderInput } from '../fields'
import { submit } from './submit'


let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit,
    form,
    formData,
    change,
    onCancel,
    onSubmit,
    rawData,
    showRawData,
    expandRawData,
  } = props
  const formValues = formData[form] && formData[form].values
  const { status, comment, selectedValue } = formValues || {
    status: '',
    comment: '',
    selectedValue: '',
  }

  const options = useMemo(
    () => {
      return !status
        ? [{ value: '', label: 'Please select an action type' }]
        : COMMENT_MESSAGES.creditCard[status.value]
    },
    [status],
  )

  useEffect(
    () => {
      change('comment', '')
    },
    [status],
  )
  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputContainer style={{ marginTop: '0px' }}>
          <InputGroup
            justify={'space-between'}
            align={'baseline'}
            direction={'row'}
          >
            <Field
              label={'Action'}
              name={'status'}
              margin={`0 ${BASE * 2}px 0 0`}
              flex={'0.3'}
              width="20%"
              type="deposits"
              component={renderDropdown}
              validate={[requiredSelectDropdownValue]}
              options={[
                { value: 'APPROVE', label: 'Approve' },
                { value: 'DECLINE', label: 'Reject' },
              ]}
            />
            {(status.value === undefined || status.value === 'APPROVE') && (
              <EditableDropdown
                condition={comment}
                label={'Comment (required)'}
                name={'comment'}
                selectFieldDropDownName={'selectedValue'}
                width={'50%'}
                validate={[required]}
                options={[...options]}
                justify={'space-around'}
                margin={`0px ${BASE * 2}px 26px 0px`}
                selectedValue={selectedValue ? selectedValue.value : ''}
                comment={comment || ''}
                change={change}
              />
            )}
            {status.value === 'DECLINE' && (
              <Field
                label={'Comment (required)'}
                name={'comment'}
                width={'100%'}
                validate={[required]}
                component={renderInput}
                justify={'space-around'}
                margin={`0 ${BASE * 2}px 0 0`}
                marginTop={26}
              />
            )}
            <Flexbox
              marginRight={BASE * 2}
              marginTop={BASE * 1.5}
              justify={'center'}
              direction={'row'}
            >
              <Button
                type="submit"
                disabled={submitting || invalid}
                margin={`0 ${BASE * 2}px 0 0`}
              >
                Submit
              </Button>
              <Button onClick={onCancel} color="secondary">
                Cancel
              </Button>
            </Flexbox>
          </InputGroup>
        </InputContainer>

        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showRawData}
            >
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)

const mapStateToProps = state => ({
  formData: state.form,
})

const ReduxForm = connect(mapStateToProps)(Form)
export default ReduxForm
