import getAxios from 'helpers/axios'
import { throwGenericSubmissionError } from 'helpers/utils'
import { toast } from 'react-toastify'
import {
  fetchDepositReview,
  removeFromSuspendedDeposit,
} from 'redux/fiat/actions'

export const submit = async (values, dispatch) => {
  const {
    accountId,
    status,
    comment: commentData,
    zendeskCode = '',
    depositId,
    deposit,
  } = values

  const comment = commentData.split(' ').reduce((acc, curr) => {
    if (curr === 'Zendesk' && zendeskCode) {
      acc = `${acc}${curr} # ${zendeskCode}`
    } else {
      acc = `${acc} ${curr}`
    }
    return acc
  }, '')
  let res = null
  try {
    if (status.value === 'APPROVE') {
      res = await getAxios().post('/fiat/review/credit', {
        depositId,
        accountId,
        comment,
      })

      await dispatch(fetchDepositReview()).then(() => {
        res.data.statusCode !== 202
          ? toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 })
          : toast('Deposit Successfully Initiated', {
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          })
      })
    } else if (status.value === 'IGNORE') {
      res = await getAxios().post('/fiat/deposit/review/update', {
        depositId,
        updateType: status.value,
        comment,
      })
      await dispatch(fetchDepositReview()).then(() => {
        toast('Deposit status updated', {
          type: toast.TYPE.SUCCESS,
          autoClose: 4500,
        })
      })
    } else if (status.value === 'SUSPEND') {
      const description = 'DEPOSIT'
      res = await getAxios().post('/suspense/type', {
        description,
        depositId,
      })
      await dispatch(removeFromSuspendedDeposit(depositId, description)).then(() => {
        toast('Deposit status updated', {
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        })
      })
    }
    return {
      depositId,
      level: status.value,
      description: status.label,
    }
  } catch (e) {
    e.response.data !== undefined
      ? toast(`${e.response.data.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 3500,
      })
      : toast('Server error', { type: toast.TYPE.ERROR, autoClose: 3500 })
    throwGenericSubmissionError({
      status: e.response.status || 400,
      message:
        e.response.data.message ||
        'Server error. Contact administrator to get help',
    })
  }
  return res
}
