import { all, call, put, takeLatest } from 'redux-saga/effects'
import { checkPathAccess, configAllowedRoles } from 'helpers/roleBasedAccess'

import {
  fetchFiatConfigError,
  fetchFiatConfigSuccess,
  FETCH_FIAT_CONFIG_START,
} from './actions'

import ErrorToast from '../../components/molecules/ErrorToast'
import getAxios from '../../helpers/axios'

async function fetchRemoteFiatConfig() {
  const { data } = await getAxios().get('/fiat/config')
  return data
}

export function* fetchFiatConfig() {
  try {
    const data = yield call(fetchRemoteFiatConfig)
    yield put(fetchFiatConfigSuccess(data))
  } catch (error) {
    if (!error.response || error.response.status !== 501) {
      // Ignore not implemented error for now
      ErrorToast(error)
    }
    yield put(fetchFiatConfigError({ error: error.response }))
  }
}

function* fiatConfigSagas() {
  return yield all([takeLatest(FETCH_FIAT_CONFIG_START, fetchFiatConfig)])
}

export default fiatConfigSagas
