import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRiskProfileDualAuth } from 'redux/riskProfilesDulAuth/actions'
import { ContentContainer } from 'components/atoms/Generic'

import ExpandingItem from 'components/atoms/ExpandingItem'

import RiskAccountsDualAuthProfilesTable from './RiskAccountsDualAuthProfilesTable'
import CircularSpinner from 'components/atoms/Spinners'

const RiskAccountProfilesDualAuth = (props) => {
  const {
    riskProfilesDulAuth: { status, individualRiskProfileDualAuths },
  } = props
  const [expandedRowId, setSelectedRow] = useState('')
  const [dualAuthExpanded, setDualAuthExpanded] = useState(false)
  const [multiAuthExpanded, setMultiAuthExpanded] = useState(false)

  const toggleSelectedRow = (id: string) =>
    setSelectedRow((prev) => (prev === id ? '' : id))

  const setDualAuthExpand = () => {
    setDualAuthExpanded((prev) => !prev)
  }

  const setMultiAuthExpand = () => {
    setMultiAuthExpanded((prev) => !prev)
  }

  useEffect(() => {
    props.fetchRiskProfileDualAuth()
  }, [])

  return (
    <Fragment>
      <ContentContainer>
        <ExpandingItem
          title={'Dual Auths'}
          expanded={dualAuthExpanded}
          loadingData={status === 'pending'}
          itemsAmount={
            individualRiskProfileDualAuths &&
            individualRiskProfileDualAuths.pending.length
          }
          onExpand={setDualAuthExpand}
          opensMultiple
          expandedView={
            <>
              <RiskAccountsDualAuthProfilesTable
                accountsProfilesDualAuthData={
                  individualRiskProfileDualAuths.pending
                }
                status={status}
                toggleSelectedRow={toggleSelectedRow}
                expandedRowId={expandedRowId}
              />
            </>
          }
        />
        <ExpandingItem
          title={'Multi Auths'}
          loadingData={status === 'pending'}
          itemsAmount={
            individualRiskProfileDualAuths &&
            individualRiskProfileDualAuths.pendingMultiAuth.length
          }
          expanded={multiAuthExpanded}
          onExpand={setMultiAuthExpand}
          opensMultiple
          expandedView={
            <>
              <RiskAccountsDualAuthProfilesTable
                isMultiAuth
                accountsProfilesDualAuthData={
                  individualRiskProfileDualAuths.pendingMultiAuth
                }
                status={status}
                toggleSelectedRow={toggleSelectedRow}
                expandedRowId={expandedRowId}
              />
            </>
          }
        />
      </ContentContainer>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  riskProfilesDulAuth: state.riskProfilesDulAuth,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRiskProfileDualAuth,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RiskAccountProfilesDualAuth)
