import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import IconLoading from 'components/atoms/IconLoading'
import {
  TablePlaceholder,
  NotesGrid,
} from 'components/atoms/Table/styles'
import moment from 'moment'
import {
  FormLikeContainer,
} from 'components/atoms/Form'

class Notes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      prevDepositId: '',
    }
  }

  showDepositsNotes = (data) => {
    return data.map((res, i) => {
      const { date, notes, createdBy } = res
      return (
        <NotesGrid key={`#${i++}`}>
          <p style={{ minWidth: '15%', wordBreak: 'break-all' }} >
            {moment(date).format('YYYY-MMM-DD HH:mm')}
          </p>
          <p  style={{ wordBreak: 'break-all' }}> {notes} </p>
          <p style={{ margin: '5%', wordBreak: 'break-all' }}> {createdBy} </p>
        </NotesGrid>
      )
    })
  }

  render() {
    const {
      comments: { depositComments, status },
    } = this.props
    return (
      <FormLikeContainer>
        {status === 'loading' && (
          <TablePlaceholder>
            <IconLoading />
            <span>Loading, please wait</span>
          </TablePlaceholder>)}
        {status === 'done' && depositComments.length === 0 &&
          <TablePlaceholder >{'No notes yet for this deposit'}</TablePlaceholder>
        }
        {status === 'done' && depositComments.length > 0 &&
          <NotesGrid>
            <p style={{ minWidth: '15%', wordBreak: 'break-all' }}>Date</p>
            <p style={{  wordBreak: 'break-all' }} >Notes</p>
            <p style={{ margin: '5%', wordBreak: 'break-all' }}>Created By</p>
          </NotesGrid>
        }
        {status === 'done' && depositComments.length > 0 && this.showDepositsNotes(depositComments)}
      </FormLikeContainer>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetForm: () => reset('depositCommentsForm'),
    },
    dispatch,
  )
export default connect(
  null, mapDispatchToProps
)(Notes)
