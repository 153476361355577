import React from 'react'
import styled, { keyframes } from 'styled-components'
import {
  PANEL_BLUE,
  BLACK,
  GRAY_0,
  GRAY_1,
  WHITE,
  GREEN,
  GRAY_3,
  BLUE,
  DEFAULT_THEME,
  VALR_BLUE_GRAY,
} from 'theme/colors'
import { BASE } from 'theme/base-units'
import { Flexbox, FlexboxRow } from 'components/layout'
import { Button } from '@material-ui/core'

import { ICON_DROP_DOWN } from 'theme/images'

export const Title = styled.h1`
  margin: 0;
  font-size: ${3 * BASE}px;
  font-weight: 100;
  text-transform: uppercase;
  flex: 0 0 auto;
  margin-right: ${({ marginRight = '0' }) => marginRight};
`

export const TitleContainer = styled.div`
  display: flex;
  border-bottom: ${({ borderBottomWidth = '2px' }) =>
    `${borderBottomWidth} solid ${PANEL_BLUE}`};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${BASE}px 0;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ marginBottom = BASE }) => `${marginBottom}px`};
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noMargin = false }) => (noMargin ? 0 : `${BASE * 2}px`)};
  width: ${({ width = '100%' }) => width};
`

export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.table.header.background};
  color: ${({ theme }) => theme.table.header.text};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  margin-bottom: 2px;
  align-items: center;
`

export const TicketsGrid = styled.div`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  display: grid;
  align-items: center;
  grid-template-columns: 12% 60% 15% 10%;
  font-size: 1em;
  p:nth-child(4n) {
    text-align: center;
  }
  ${({ isPublic }) => {
    if (isPublic === 'internal') {
      return {
        backgroundColor: 'rgba(255, 230, 0, 0.151)',
      }
    }
  }};
`

export const NotesGrid = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  font-size: 1em;
`

export const CommentsGrid = styled.div`
  display: grid;
  grid-template-columns: 12% 25% 40% 22%;
  font-size: 1em;
`

export const RawDataGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  color: ${({ theme }) => theme.rawData.text};
`

export const TableBody = styled.div`
  overflow: hidden;
`

export const TableCell = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ mustwrap }) => (mustwrap ? 'wrap' : 'nowrap')};
  flex: ${({ flex = '0 0 auto' }) => flex};
  padding-right: ${({ columnRender = false }) =>
    columnRender ? 0 : `${BASE * 2}px`};
  width: ${({ width = '100%' }) => width};
  margin-right: ${({ marginRight = '0' }) => marginRight};
  max-width: ${({ maxWidth = 'auto' }) => maxWidth};
  cursor: inherit;
  font-weight: ${({ bold = false }) => (bold ? '600' : '400')};
  justify-content: ${({ align = 'left' }) =>
    align === 'left' ? 'flex-start' : 'flex-end'};
  text-align: ${({ align }) => align};
  &:nth-last-child(2) {
    padding-right: 0;
  }
  font-size: ${({ fontSize = 'auto' }) => fontSize};
  margin-left: ${({ marginLeft = '0' }) => marginLeft};
`

export const StatusDisplay = styled.div`
  color: ${({ color = 'black' }) => color};
  font-weight: bold;
`

const SortArrow = styled.div`
  background-image: url(${ICON_DROP_DOWN});
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  margin-left: ${BASE}px;
  transform: ${({ reverse = false }) =>
    reverse ? 'rotate(-90deg)' : 'rotate(90deg)'};
`

const HeadCellContainer = styled(TableCell)`
  display: flex;
  justify-content: ${({ justify = 'flex-end' }) => justify};
  :hover {
    cursor: pointer;
  }
`

export const HeadCell = (props) => {
  const { children, active, reverse } = props
  return (
    <HeadCellContainer {...props}>
      {children}
      {active && <SortArrow reverse={reverse} />}
    </HeadCellContainer>
  )
}

export const blinkOnceEven = keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
     background-color: #eaf0f2;
  }
`

export const blinkOnceOdd = keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
      background-color: #eaf0f2;
  }
`

const blinkOnce = ({ index = 0 }) => keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
      background-color: ${index % 2 === 0 ? '#dce7ea' : '#eaf0f2'};
  }
`

export const TableRow = styled.div`
  display: flex;
  width: ${({ width = '100%' }) => width};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  color: ${({ theme, active }) => (active ? WHITE : theme.table.row.text)};
  font-size: 90%;
  background-color: ${({ theme, index = 0, active = false }) =>
    active
      ? `${theme.table.row.highlighted}`
      : index % 2 === 0
        ? theme.table.row.even
        : theme.table.row.odd};
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
  cursor: pointer;
  &:nth-child(2n) {
    animation: ${({ isNewItem = false }) =>
    isNewItem && `${blinkOnceEven} 1s linear 0s 1`};
  }
  &:hover {
    background-color: ${({ theme }) => theme.table.row.hover};
  }
  animation: ${({ isNewItem = false, index = 0 }) =>
    isNewItem && `${blinkOnce(index)} 1s linear 0s 1`};
`

export const TableRowForTotal = styled.div`
  display: flex;
  width: ${({ width = '100%' }) => width};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  color: ${BLACK};
  background-color: ${({ bgColor = '#bfc9d4' }) => bgColor};
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
  cursor: pointer;
  &:nth-child(2n) {
    background-color: #dce7ea;
    animation: ${({ isNewItem = false }) =>
    isNewItem && `${blinkOnceEven} 1s linear 0s 1`};
  }
  &:hover {
    background-color: ${({ colorTheme = DEFAULT_THEME }) =>
    colorTheme.color.hover};
  }
  animation: ${({ isNewItem = false, index = 0 }) =>
    isNewItem && `${blinkOnce(index)} 1s linear 0s 1`};
`

export const TableRowExpanded = styled.div`
  background-color: ${WHITE};
  padding: ${BASE * 3}px;
  margin-bottom: ${BASE * 2}px;
  border: 1px solid #d9e7ec;
  border-top: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
`

export const ExpandingContainer = styled.div`
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
`

export const Actions = styled(Flexbox)`
  width: ${({ width = '100%' }) => width};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  margin: ${({ margin = '0' }) => margin};
`

export const ActionLabel = styled.label`
  margin-right: ${BASE}px;
  color: ${GRAY_3};
`

export const ActionButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary',
})`
  padding: ${1 * BASE}px ${BASE * 1}px;
  margin-right: ${BASE / 2}px;
  margin-left: ${({ ml }) => `${ml}`};
  margin-bottom: ${({ mb }) => `${mb}`};
  width: ${({ width }) => `${width}`};
`

const ActionInputContainer = styled.div`
  position: relative;
  margin-right: ${({ marginRight = BASE }) => `${marginRight}px`};
  display: flex;
  align-items: center;
`
export const ActionInputElement = styled.input`
  padding: ${({ padding = `${BASE}px` }) => padding};
  box-sizing: border-box;
  width: ${({ width = '100%' }) => width};
  padding-right: ${({ actionView }) => (actionView ? `${BASE * 4}px` : '0')};
`

export const ActionInput = (props) => (
  <ActionInputContainer {...props}>
    <ActionInputElement {...props} />
    {props.actionView && props.actionView(props)}
  </ActionInputContainer>
)

export const ActionInputIcon = styled.i`
  position: absolute;
  right: ${BASE}px;
  font-size: ${BASE * 2.5}px;
  color: ${GRAY_1};
  cursor: pointer;
  &:hover {
    color: ${BLUE};
  }
`

export const ActionForm = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const TransactionContainer = styled.div`
  padding: ${BASE * 2}px ${BASE * 2}px ${BASE}px;
  background-color: ${GRAY_0};
  margin-bottom: ${BASE}px;
  border: 1px solid ${GRAY_1};
`

export const TablePlaceholder = styled.div`
  width: 100%;
  padding: ${BASE * 2}px 0;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.table.placeholder.background};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(margin) => (margin ? `${margin}` : '0px')};
`

export const ActionDivider = styled.div`
  width: 1px;
  height: 100%;
  min-height: ${BASE * 4}px;
  margin: 0 ${BASE * 1.5}px;
  background-color: ${GRAY_1};
`

export const Divider = styled.div`
  width: ${({ width = '20%' }) => width};
  margin: ${({ margin = '1%' }) => margin};
`

export const Select = styled.select`
  border: 2px solid silver;
  height: 40px;
  width: 100%;
  background-color: white;
  font-size: 1.2em;
  border-radius: 3px;
`

export const SelectActionButton = styled.button`
  flex: 0 0 auto;
  background-color: ${({ color = selectButtonColors.DEFAULT }) => color};
  border: 2px solid ${({ color = selectButtonColors.DEFAULT }) => color};
  color: ${({ color = selectButtonColors.DEFAULT }) =>
    color === selectButtonColors.DEFAULT ? 'black' : 'white'};
  font-weight: ${({ color = selectButtonColors.DEFAULT }) =>
    color === selectButtonColors.DEFAULT ? '100' : '900'};
  margin-right: ${({ marginRight = `${BASE}px` }) => marginRight};
  padding: ${({ padding = `${BASE}px ${BASE * 2}px` }) => padding};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`
export const selectButtonColors = {
  GREEN: '#32B877',
  ORANGE: '#EB8D54',
  DEFAULT: '#DDDDDD',
}

export const Container = styled.div`
  width: ${({ width = '100%' }) => width};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  margin: ${BASE * 2}px 0;
  border: 1px solid ${GRAY_1};
  justify-content: ${({ justify = 'flex-start' }) => justify};
`
export const FLAG_COLORS = {
  RED: '#e96868',
  GREEN: '#02cdb7',
  TRANSPARENT: 'transparent',
}

export const FlagToggle = styled.i`
  color: ${({ color = FLAG_COLORS.GREEN }) => color};

  & a {
    margin-left: 1em;
    text-decoration: none;
    color: ${BLUE};
    &:hover {
      color: red;
    }
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.link.text};
  display: block;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    color: red;
  }
`
