import React from 'react'
import styled from 'styled-components'
import config from '../../../config'

const StyledLink = styled.a`
  color: ${({ color = 'black' }) => color};
  display: block;
  width: 100%;
  font-family: Helvetica, Arial, sans-serif;
  &:hover {
    text-decoration: none;
  }
  &.active {
    color: red;
  }
`
export const ZendeskLink = (props) => {

    const { id, color, email } = props
    const url = config.zendeskSupportUrl + '/agent/search/1?q=' + email

    return (
        <div>
            <StyledLink color={color} href={url} target="_blank">
                {id}
            </StyledLink>
        </div>
    )
}