import React from 'react'
import { Field } from 'redux-form'
import { BASE } from 'theme/base-units'

import { InputGroup } from 'components/atoms/Form/index'
import { MultipleSelect } from 'components/atoms/MultipleSelect'
import {
  renderDropDownField,
  renderInput,
  renderUncontrolledCheckbox,
} from 'forms/fields'
import { required } from 'forms/fieldValidators'

import { NATURAL_ENTITY_ROLES } from '../../const/Entities'
import { StyledEntityFormLeft } from '../Styles'
import { COUNTRY_OPTIONS } from '../../const/Corporate'

const EntityFormLeft = () => (
  <StyledEntityFormLeft>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'First Name'}
        name={'firstName'}
        width={'100%'}
        validate={[required]}
        component={renderInput}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Last Name'}
        name={'lastName'}
        width={'100%'}
        margin={`0 ${BASE * 2}px 0 0`}
        validate={[required]}
        component={renderInput}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'ID Number'}
        name={'idNumber'}
        width={'100%'}
        margin={`0 ${BASE * 2}px 0 0`}
        validate={[required]}
        component={renderInput}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Roles'}
        name={'roles'}
        width={'100%'}
        margin={`0 ${BASE * 2}px 0 0`}
        options={NATURAL_ENTITY_ROLES.sort()}
        showInputWithCheckBox
        component={MultipleSelect}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Nationality'}
        name={'nationality'}
        width={'100%'}
        margin={`0 ${BASE * 2}px 0 0`}
        options={COUNTRY_OPTIONS}
        component={renderDropDownField}
        fullWidth
      />
    </InputGroup>
    <InputGroup align={'flex-start'} direction={'column'}>
      <Field
        label={'Is this entity a PEP?'}
        name={'isPep'}
        width={'100%'}
        component={renderUncontrolledCheckbox}
        fullWidth
      />
    </InputGroup>
  </StyledEntityFormLeft>
)

export default EntityFormLeft
