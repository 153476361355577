import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sortBy } from 'helpers/utils'
import CreditCardRow from 'components/organisms/CreditCardRow'
import { getAccountCreditCardDeposits } from 'redux/selectors/creditCard'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import {
  fetchCreditCardDepositsById,
  clearCreditCardDeposits,
} from 'redux/creditCardDepositsById/actions'
import { makeStyles } from '@material-ui/core/styles'
import BigNumber from 'bignumber.js'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import {
  TitleContainer,
  Actions,
  ActionLabel,
  ActionInput,
  ActionInputIcon,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import { getComparator, tableSort } from 'helpers/tables'
import ErrorBoundary from 'helpers/ErrorBoundary'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { EnhancedTableHead } from './tableCell'
import { TableRow } from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountCreditCardDeposits(props) {
  const { accountId } = props.match.params
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [filter, setFilter] = React.useState('')
  const [sortField, setSortField] = React.useState('createdAt')
  const [sortReverse, setSortReverse] = React.useState('true')
  const [renderData, setRenderData] = React.useState([])
  const [expandRawData, updateRawData] = React.useState('')
  const [addNoteFormShown, updateAddNoteFormshown] = React.useState('')
  const [comments, setComments] = React.useState([])
  const [commentsStatus, updatecommentsStatus] = React.useState('persist')
  const [highlightedId, updateHighlightedId] = React.useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const ref = useRef()

  const updateComments = async (depositId) => {
    updatecommentsStatus('loading')
    const data = await fetchDepositComments(depositId)
    setComments(data)
    updatecommentsStatus('done')
  }

  const setHighlightedId = (id) => {
    updateHighlightedId(id === highlightedId ? null : id)
  }

  const showRawData = (id) => {
    if (id !== expandRawData) {
      updateRawData(id)
    } else {
      updateRawData('')
    }
  }

  const toggleAddNotesForm = (id) => {
    if (id !== addNoteFormShown) {
      updateAddNoteFormshown(id)
    } else {
      updateAddNoteFormshown('')
    }
  }

  const toggleForm = (id) => {
    updateAddNoteFormshown('')
    updateComments(id)
    updateRawData('')
  }

  const sortDataPure = (data, field, reverse) => {
    switch (field) {
      case 'status':
        return data.sort(sortBy('status', reverse, (tx) => tx.status))
      case 'amount':
        return data.sort(sortBy('amount', reverse, (tx) => tx.amount))
      case 'feeAmount':
        return data.sort(sortBy('feeAmount', reverse, (tx) => tx.confirmations))
      case 'identifier':
        return data.sort(
          sortBy('identifier', reverse, (tx) => tx.feeAmount || 0),
        )
      case 'createdAt':
        return data.sort(
          sortBy('createdAt', reverse, (tx) => tx.meta.timestampValue),
        )
      default:
        return data
    }
  }

  const filterData = (dataToFilter) => {
    const accountData = props.creditCard
    const createParsedValue = () => {
      let filterArray = dataToFilter.split(',').join('')
      filterArray = filterArray.split('.')
      if (Number(filterArray[filterArray.length - 1]) === 0) {
        filterArray.splice(-1, 1)
      }
      return filterArray.join('.').trim()
    }
    const modifiedFilterValue = createParsedValue()
    let filteredData = []
    if (accountData.status === 'done') {
      filteredData =
        modifiedFilterValue.length === 0
          ? accountData.data
          : accountData.data.filter(
              (item) =>
                item.meta.searchString.indexOf(
                  modifiedFilterValue.toLowerCase(),
                ) > -1,
            )
    }
    const dataToRender = sortDataPure(filteredData, sortField, sortReverse)
    setFilter(dataToFilter)
    setRenderData(dataToRender)
  }

  const addTotalAmount = (deposit) => {
    const total = deposit
      .map((d) => d.status === 'PAYMENT_ALLOCATED' && d.creditedAmount)
      .reduce((accumulator, value) => {
        const checkedCurrentValue = value
          ? parseFloat(
              typeof value === 'string' ? value.split(',').join('') : value,
            )
          : 0
        return accumulator + checkedCurrentValue
      }, 0)
    return `R${new BigNumber(total).toFormat(2)}`
  }

  const handleChangePage = (event, newPage) => {
    const currentPageHasNoData =
      tableSort(renderData, getComparator(order, orderBy)).slice(
        newPage * rowsPerPage,
        rowsPerPage * (newPage + 1),
      ).length < rowsPerPage
    setPage(newPage)
    if (newPage > page && currentPageHasNoData) {
      props.fetchCreditCardDepositsById({
        id: accountId,
        skip: renderData.length,
        limit: rowsPerPage,
      })
    }
  }

  const handleChangeRowsPerPage = (event) => {
    const currentPageHasNoData =
      tableSort(renderData, getComparator(order, orderBy)).slice(
        page * event.target.value,
        event.target.value * (page + 1),
      ).length < event.target.value
    setRowsPerPage(parseInt(event.target.value, 10))
    if (rowsPerPage < event.target.value && currentPageHasNoData) {
      props.fetchCreditCardDepositsById({
        id: accountId,
        skip: 0,
        limit: event.target.value,
      })
    }
    setPage(0)
  }

  const renderDeposits = (txList) =>
    txList.length === 0
      ? tableSort(txList, getComparator(order, orderBy))
          .slice(page * rowsPerPage, rowsPerPage * (page + 1))
          .map((tx, i) => (
            <CreditCardRow
              tx={tx}
              key={i}
              accountId={accountId}
              showRawData={showRawData}
              txList={txList}
              addNoteFormShown={addNoteFormShown === tx.identifier}
              toggleAddNotesForm={toggleAddNotesForm}
              expandItem={() => toggleForm(tx.identifier)}
              expandRawData={expandRawData === tx.identifier}
              updateComments={updateComments}
              comments={comments}
              fetchMore={false}
              commentsStatus={commentsStatus}
              setHighlightedId={setHighlightedId}
              isHighlighted={highlightedId === tx.identifier}
            />
          ))
      : tableSort(txList, getComparator(order, orderBy))
          .slice(page * rowsPerPage, rowsPerPage * (page + 1))
          .map((tx, i) => (
            <CreditCardRow
              tx={tx}
              key={i}
              accountId={accountId}
              showRawData={showRawData}
              txList={txList}
              expandItem={() => toggleForm(tx.identifier)}
              addNoteFormShown={addNoteFormShown === tx.identifier}
              toggleAddNotesForm={toggleAddNotesForm}
              toggleForm={toggleForm}
              expandRawData={expandRawData === tx.identifier}
              updateComments={updateComments}
              comments={comments}
              fetchMore={false}
              commentsStatus={commentsStatus}
              setHighlightedId={setHighlightedId}
              isHighlighted={highlightedId === tx.identifier}
            />
          ))

  React.useEffect(() => {
    props.fetchCreditCardDepositsById({
      id: accountId,
      skip: 0,
      limit: rowsPerPage,
      fetchMore: false,
    })
    return () => props.clearCreditCardDeposits()
  }, [])

  React.useEffect(() => {
    if (ref.current) {
      filterData(filter)
    }
  }, [props.creditCard, ref.current])
  if (!props.creditCard)
    return <LoadingBlock message="Loading credit card deposits..." />
  return (
    <ErrorBoundary message="Credit Card Account Deposits">
      <>
        <TitleContainer>
          <>
            <Actions justify="flex-start">
              {`Total ZAR deposits: ${addTotalAmount(props.creditCard.data)}`}
            </Actions>
            <Actions justify="flex-end">
              <ActionLabel>Filter:</ActionLabel>
              <ActionInput
                value={filter}
                marginRight={0}
                onChange={(event) => filterData(event.target.value)}
                actionView={() =>
                  filter.length > 0 && (
                    <ActionInputIcon
                      title={'Clear Filter'}
                      className={'far fa-times-circle'}
                      onClick={() => filterData('')}
                    />
                  )
                }
              />
            </Actions>
          </>
        </TitleContainer>
        <div ref={ref} className={classes.root}>
          {props.creditCard.status === 'loading' ? (
            <LoadingBlock
              message={`Loading ${
                page > 0 ? 'more' : ''
              } credit card deposits...`}
            />
          ) : (
            <Paper className={classes.paper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  stickyHeader={true}
                  aria-label="sticky table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={props.creditCard.data.length}
                  />
                  <TableBody id="testing">
                    {
                      <>
                        {renderData && renderData.length === 0 && (
                          <TableRow className="no-hover">
                            <TableCell colSpan={6}>
                              <TablePlaceholder>
                                {'This account has no deposits'}
                              </TablePlaceholder>
                            </TableCell>
                          </TableRow>
                        )}
                        {renderData &&
                          renderData.length > 0 &&
                          renderDeposits(renderData)}
                      </>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={renderData.length + 1}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps.match.params
  return {
    creditCard: getAccountCreditCardDeposits(state)(accountId),
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCreditCardDepositsById,
      clearCreditCardDeposits,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountCreditCardDeposits)
