import getAxios from 'helpers/axios'
import {
  FETCH_PENDING_FERRY_AUTHS_ERROR,
  FETCH_PENDING_FERRY_AUTHS_PROCESS,
  FETCH_PENDING_FERRY_AUTHS_SUCCESS
} from "./index";
import {toast} from "react-toastify";

export const fetchPendingFerryAuths = (ferryAuthRequestType) => async dispatch => {
  dispatch({
    type: FETCH_PENDING_FERRY_AUTHS_PROCESS
  })
  try {
    const res = await getAxios().get(`/ferryman/pendingferryauths/${ferryAuthRequestType}`)
    const data = res.data.pendingFerryAuths
    dispatch({type: FETCH_PENDING_FERRY_AUTHS_SUCCESS, payload: {data}})

  } catch (error) {
    dispatch({
      type: FETCH_PENDING_FERRY_AUTHS_ERROR,
    })
    toast(`${error && error.response ? error.response.data : error.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
