import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { toast } from 'react-toastify'
import BigNumber from 'bignumber.js'

import FormPendingInternalTransfers from 'forms/PendingInternalTransfers'
import { fetchFromAndToAccountDetails } from 'redux/pendingInternalTransfers/actions'
import { RenderCheckbox } from 'forms/fields'
import LoadingBlock from 'components/molecules/LoadingBlock'

import { Link } from '../AccountIdLink/clickableId'
import { TransitionCell } from '../Styles'

class PendingInternalTransferRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      expandRawData: '',
      rawData: {},
      clientInfoStatus: 'loading',
      isFetchingFromAndToDetails: false,
    }
  }

  toggleForm = () => {
    this.setState({ formShown: !this.state.formShown })
  }

  showRawData = (id) => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  fetchFromAndToDetails = async () => {
    const { transfer } = this.props
    const { fromAccountId, toAccountId } = transfer
    try {
      const info = await fetchFromAndToAccountDetails(
        fromAccountId,
        toAccountId,
      )
      const accountsDetails = {
        fromName: info[0].sensitiveInfo.name,
        fromSurname: info[0].sensitiveInfo.surname,
        toName: info[1].sensitiveInfo.name,
        toSurname: info[1].sensitiveInfo.surname,
      }
      this.setState({
        clientInfoStatus: 'done',
        rawData: { ...accountsDetails, ...transfer },
      })
    } catch (error) {
      toast('Could not get client details', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
      this.setState({ clientInfoStatus: 'error', rawData: undefined })
    }
  }

  toggleFetchingFromAndToDetailsLoading = () =>
    this.setState((prev) => ({
      isFetchingFromAndToDetails: !prev.isFetchingFromAndToDetails,
    }))

  handleClick = async () => {
    const { transfer, setHighlightedId } = this.props
    const { id } = transfer
    this.toggleForm()
    setHighlightedId(id)
    if (!this.state.formShown) {
      this.toggleFetchingFromAndToDetailsLoading()
      await this.fetchFromAndToDetails()
      this.toggleFetchingFromAndToDetailsLoading()
    }
  }

  render() {
    const { formShown } = this.state
    const {
      transfer: {
        additionalInfo,
        id,
        fromAccountId,
        toAccountId,
        reason,
        currency,
        amount,
        internalComment,
        isBatchTransfer,
      },
      toggleSelected,
      isSelected,
      isHighlighted,
      bulkActionsActive,
      showBulkAction,
    } = this.props
    const shading = isSelected || isHighlighted ? 'white' : ''
    const transferDataToSubmit = {
      id,
      fromAccountId,
      toAccountId,
      reasonId: reason.id,
      currencyCode: currency.shortName,
      amount,
      internalCommentId: internalComment.id,
    }
    const formattedAmount = new BigNumber(amount).toFormat(2)
    return (
      <Fragment>
        <TableRow
          className={isSelected || isHighlighted ? 'active' : ''}
          onClick={this.handleClick}
          style={{
            color: shading,
          }}
        >
          <TableCell>{id}</TableCell>
          <TableCell>
            <Link color={shading} id={fromAccountId} />
          </TableCell>
          <TableCell>
            <Link color={shading} id={toAccountId} />
          </TableCell>
          <TableCell>{reason.description}</TableCell>
          <TableCell>{currency.shortName}</TableCell>
          <TableCell>
            {currency.shortName === 'ZAR' ||
            currency.shortName === 'USDC' ||
            currency.shortName === 'USDT'
              ? formattedAmount
              : amount}
          </TableCell>
          <TableCell>{internalComment.comment || '--'}</TableCell>
          <TableCell>{additionalInfo || '--'}</TableCell>
          {showBulkAction && (
            <TableCell>
              <TransitionCell
                className={
                  bulkActionsActive
                    ? 'bulkSuspendCheckBoxActive'
                    : 'bulkSuspendCheckBoxfade'
                }
                onClick={(e) => e.stopPropagation()}
                flex={0.7}
              >
                <RenderCheckbox
                  style={{ color: isSelected ? 'white' : '' }}
                  checked={isSelected}
                  disabled={isBatchTransfer}
                  onCheckFunction={() => toggleSelected(transferDataToSubmit)}
                />
              </TransitionCell>
            </TableCell>
          )}
        </TableRow>
        {this.state.isFetchingFromAndToDetails && (
          <TableCell colSpan={8}>
            <LoadingBlock />
          </TableCell>
        )}
        {!this.state.isFetchingFromAndToDetails && formShown && (
          <TableCell colSpan={8}>
            <FormPendingInternalTransfers
              initialValues={{
                transfer: transferDataToSubmit,
              }}
              expandRawData={this.state.expandRawData}
              showRawData={() => this.showRawData(id)}
              form={`PendingInternalTransferForm+${id}`}
              rawData={this.state.rawData || this.props.transfer}
              clientInfoStatus={this.state.clientInfoStatus}
              onCancel={this.toggleForm}
              onSubmitSuccess={this.toggleForm}
            />
          </TableCell>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  release: state.releaseinternalTransfers,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFromAndToAccountDetails,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingInternalTransferRow)
