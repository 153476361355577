import React, { FC } from 'react'
import { Title } from 'components/atoms/Title'
import { renderPlural } from 'helpers/utils'
import { FoundSanctionNaturalEntity } from 'interfaces/sanctions'
import FoundEntitiesTable from './FoundEntitiesTable'

const headings = [
  { heading: 'Entity ID', key: 'id' },
  { heading: 'Entity Name Match', key: 'entityNameMatch' },
  { heading: 'Entity Surname Match', key: 'entitySurnameMatch' },
  { heading: 'Type', key: 'type' },
]

interface FoundNaturalEntitySanctionsProps {
  foundNaturalEntities: FoundSanctionNaturalEntity[]
}

const FoundNaturalEntitySanctions: FC<FoundNaturalEntitySanctionsProps> = ({
  foundNaturalEntities,
}) => (
  <>
    <Title
      type={'h3'}
      text={`Found ${
        foundNaturalEntities.length
      } Natural Entity Sanction Item${renderPlural(
        foundNaturalEntities.length,
      )}`}
    />
    {foundNaturalEntities.length > 0 ? (
      <FoundEntitiesTable entities={foundNaturalEntities} headings={headings} />
    ) : (
      <p>Natural Entities for this corporate are in the clear</p>
    )}
  </>
)

export default FoundNaturalEntitySanctions
