import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS'
export const FETCH_CURRENCIES_ERROR = 'FETCH_CURRENCIES_ERROR'

export const ALLOW_BORROW_ON_CURRENCY_START = 'ALLOW_BORROW_ON_CURRENCY_START'
export const ALLOW_BORROW_ON_CURRENCY_SUCCESS =
  'ALLOW_BORROW_ON_CURRENCY_SUCCESS'
export const ALLOW_BORROW_ON_CURRENCY_ERROR = 'ALLOW_BORROW_ON_CURRENCY_ERROR'

export const UPDATE_CURRENCY_BORROW_WEIGHT_START =
  'UPDATE_CURRENCY_BORROW_WEIGHT_START'
export const UPDATE_CURRENCY_BORROW_WEIGHT_SUCCESS =
  'UPDATE_CURRENCY_BORROW_WEIGHT_SUCCESS'
export const UPDATE_CURRENCY_BORROW_WEIGHT_ERROR =
  'UPDATE_CURRENCY_BORROW_WEIGHT_ERROR'

export const UPDATE_CURRENCY_ENABLED_STATUS_SUCCESS =
  'UPDATE_CURRENCY_ENABLED_STATUS_SUCCESS'

export const fetchCurrencies = () => async dispatch => {
  let res = null
  try {
    res = await getAxios().get('/admin/currency/currencies/finance')
    const { data } = res
    dispatch({
      type: FETCH_CURRENCIES_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    toast('Could not get currencies', {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    dispatch({
      type: FETCH_CURRENCIES_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const allowBorrowOnCurrency = borrowData => async dispatch => {
  dispatch({
    type: ALLOW_BORROW_ON_CURRENCY_START,
  })
  try {
    const res = await getAxios().put('/update/currency/borrow', borrowData)
    const { data } = res.data
    dispatch({
      type: ALLOW_BORROW_ON_CURRENCY_SUCCESS,
      payload: data,
    })
    toast(`Successfully updated borrowing for ${borrowData.symbol}`, {
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    })
  } catch (error) {
    dispatch({
      type: ALLOW_BORROW_ON_CURRENCY_ERROR,
    })
    toast(error.message, { type: toast.TYPE.ERROR, autoClose: 2000 })
  }
}

export const updateCurrencyBorrowWeight = updatedBorrowWeightData => async dispatch => {
  dispatch({
    type: UPDATE_CURRENCY_BORROW_WEIGHT_START,
  })
  try {
    const res = await getAxios().put(
      '/update/currency/borrow',
      updatedBorrowWeightData,
    )
    const { data } = res.data

    dispatch({
      type: UPDATE_CURRENCY_BORROW_WEIGHT_SUCCESS,
      payload: data,
    })

    toast(
      `Successfully updated borrowing for ${updatedBorrowWeightData.symbol}`,
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      },
    )
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_CURRENCY_BORROW_WEIGHT_ERROR,
    })
  }
}

export const updateCurrencyEnabledStatus = (
  currencySymbol,
  active,
) => async dispatch => {
  const activationStatus = !active

  try {
    const res = await getAxios().put('/update/currency/active', {
      symbol: currencySymbol,
      isActive: activationStatus,
    })
    if (res.data.statusCode === 202) {
      toast('Currency activation status updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      })

      dispatch({
        type: UPDATE_CURRENCY_ENABLED_STATUS_SUCCESS,
        payload: res.data.data,
      })
    }
  } catch (error) {
    ErrorToast(error)
  }
}
