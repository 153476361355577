import React, { useEffect, useState } from 'react'
import { TransparentFormContainer } from 'components/atoms/Form'
import { FlexRow, Flexbox } from 'components/layout'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Spacer } from 'components/atoms/Generic'
import IconButton from 'components/atoms/IconButton'
import SendIcon from '@material-ui/icons/Send'
import {
  renderCalenderDateTime,
  renderDropDownField,
  renderFileInput,
  renderInput,
} from 'forms/fields'
import { isValidValrUrl, required } from 'forms/fieldValidators'
import { Title } from 'components/atoms/Title'
import {
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import CircularSpinner from 'components/atoms/Spinners'
import { BLUE } from 'theme/colors'
import {
  StyledCsvAccountIdsData,
  StyledCsvDataTable,
  StyledOptionalText,
} from './Styles'
import { StyledLink } from 'components/atoms/Styles'
import { CancelOutlined } from '@material-ui/icons'
import { getIdsFromCsv } from './consts'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

const MobileNotificationsMessageForm = (props) => {
  const { submitting, invalid, handleSubmit, formValues } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const mobileNotificationSmsOn = useFeatureFlagCheck('mobileNotificationSms')
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
  }

  const csvInfo = getIdsFromCsv((formValues && formValues.userIds) || [])

  useEffect(() => {
    setPage(0)
  }, [formValues && formValues.userIds])

  return (
    <TransparentFormContainer
      //@ts-ignore
      width="100%"
      onSubmit={handleSubmit}
    >
      <Flexbox justify="space-between">
        <Title type="h3" text="Send Mobile Notifications" />
        <IconButton
          disabled={false}
          height={120}
          width={120}
          type="button"
          onClick={props.handleClose}
          icon={<CancelOutlined fontSize="large" />}
        />
      </Flexbox>
      {csvInfo.accountIds.length === 0 && (
        <Spacer margin="12px 0px">
          <StyledLink
            href={
              'https://docs.google.com/spreadsheets/d/14O3BM91q2urBC6GWOLPEAc2sLLAmJa_q3dbztEap2FI/edit?usp=sharing'
            }
            target={'_blank'}
          >
            CSV Example
          </StyledLink>
        </Spacer>
      )}
      <Flexbox align="center" justify="flex-start">
        <Field
          label={csvInfo.accountIds.length === 0 ? 'Upload Csv' : 'Change Csv'}
          name={'userIds'}
          component={renderFileInput}
          showFileName
          accept={'.csv'}
          width="50%"
        />
      </Flexbox>
      <Title type="h4" text={csvInfo.message} />
      {csvInfo.accountIds.length > 0 && (
        <>
          <Title type="h3" text="List Of Account IDS" />
          <Spacer margin="0px 0px 18px 0px">
            <StyledCsvDataTable>
              <TableHead>
                <TableRow>
                  <TableCell>Account ID's</TableCell>
                </TableRow>
              </TableHead>
              <StyledCsvAccountIdsData>
                {csvInfo.accountIds
                  .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                  .map((accId) => (
                    <TableBody key={accId}>
                      <TableRow>
                        <TableCell>{accId}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </StyledCsvAccountIdsData>
            </StyledCsvDataTable>
            <TablePagination
              component="div"
              count={csvInfo.accountIds.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Spacer>
        </>
      )}
      <Flexbox marginBottom={20} align="flex-end" justify="flex-start" wrap>
        {mobileNotificationSmsOn && (
          <Flexbox marginRight={12} align="flex-start" direction="column">
            <Field
              width={'220px'}
              name="notificationType"
              options={[
                { value: 'SMS', label: 'SMS' },
                { value: 'PUSH', label: 'PUSH' },
              ]}
              showNoneLabel={false}
              component={renderDropDownField}
              validate={[required]}
              label={'Select notification type'}
            />
          </Flexbox>
        )}
        <Tooltip
          PopperProps={{ style: { marginBottom: -20 } }}
          placement="top"
          title="Select the date and time you will like this campaign to run"
        >
          <Flexbox
            marginBottom={-18}
            marginRight={12}
            align="flex-start"
            direction="column"
          >
            <StyledOptionalText>(optional)</StyledOptionalText>
            <Field
              label={'Scheduled Date Time'}
              name={'scheduledDateTime'}
              width={'100%'}
              disablePast
              component={renderCalenderDateTime}
              placeholder={'Date & Time'}
            />
          </Flexbox>
        </Tooltip>
        {formValues && formValues.notificationType === 'SMS' ? null : (
          <Tooltip
            placement="top"
            title="The mobile page which this should open to."
          >
            <Flexbox align="flex-start" direction="column">
              <StyledOptionalText>(optional)</StyledOptionalText>
              <Field
                label={'Mobile page url'}
                name={'mobilePageUrl'}
                width={'300px'}
                component={renderInput}
                validate={isValidValrUrl}
                placeholder={'Mobile page url'}
              />
            </Flexbox>
          </Tooltip>
        )}
      </Flexbox>
      <Flexbox marginBottom={12} align="center" justify="flex-start">
        <Flexbox align="flex-start" width="30%" direction="column">
          <Field
            label={'Campaign Name'}
            name={'campaignName'}
            width={'100%'}
            component={renderInput}
            validate={[required]}
            placeholder={'Campaign Name'}
          />
        </Flexbox>
        <Spacer margin="0px 0px 0px 8px" />
        <Field
          label={'Message Title'}
          name={'messageTitle'}
          width={'30%'}
          component={renderInput}
          validate={[required]}
          placeholder={'Message Title'}
        />
        <Spacer margin="0px 0px 0px 8px" />
        <Field
          label={'Message'}
          name={'message'}
          multiline
          width={'30%'}
          component={renderInput}
          validate={[required]}
          placeholder={'Message'}
        />
      </Flexbox>
      {submitting ? (
        <Spacer margin="8px 0px 0px 0px">
          <CircularSpinner color={BLUE} />
        </Spacer>
      ) : (
        <>
          {csvInfo.error ? (
            <Spacer margin="0px 8px">
              <span style={{ color: 'red' }}>{csvInfo.error}</span>
            </Spacer>
          ) : (
            <FlexRow>
              <Tooltip title="Upload a csv with account ids to enable this button.">
                <div>
                  <MuiButton
                    disabled={
                      invalid ||
                      submitting ||
                      csvInfo.error ||
                      csvInfo.accountIds.length === 0
                    }
                    type={'submit'}
                  >
                    Send to csv users
                  </MuiButton>
                </div>
              </Tooltip>
              <Spacer margin="0px 8px 0px 0px" />
              {formValues && formValues.notificationType === 'SMS' ? null : (
                <MuiButton
                  disabled={
                    invalid || submitting || csvInfo.accountIds.length > 0
                  }
                  onClick={() =>
                    props.sendToAllUsers({
                      ...props.formValues,
                      notificationType: 'PUSH',
                    })
                  }
                  type={'button'}
                >
                  Send to all users
                </MuiButton>
              )}
            </FlexRow>
          )}
        </>
      )}
    </TransparentFormContainer>
  )
}

const Form = reduxForm({
  form: 'mobileNotifications',
  // onSubmit: sendMobileNotificationsToUsers,
  enableReinitialize: true,
})(MobileNotificationsMessageForm)

const mapStateToProps = (state) => {
  if (
    state.form &&
    state.form.mobileNotifications &&
    state.form.mobileNotifications.values
  ) {
    return {
      formValues: state.form.mobileNotifications.values || {},
    }
  }
  return {}
}

export default connect(mapStateToProps, null)(Form)
