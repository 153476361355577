import React, { Fragment, useState } from 'react'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import auth from 'services/Auth'
import FormComplianceComments from 'forms/ComplianceComments/index'
import {
  fetchComplianceComments,
  flagComplianceComment,
} from 'redux/complianceComments/actions'
import { makeStyles } from '@material-ui/core/styles'
import ErrorBoundary from 'helpers/ErrorBoundary'
import CustomAccordion from 'components/organisms/Accordion'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details/index'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import {
  FlagToggle,
  FLAG_COLORS,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import RawData from 'components/atoms/RawDataDualAuth'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { ComplianceData } from './interfeces'
import { StyledNoCommentsMessage } from './Styles'

function createData(
  {
    date, createdBy, comments, flag, commentId,
  }:
 { date: ComplianceData['date']; createdBy: ComplianceData['createdBy'];
  comments: ComplianceData['comments']; flag: ComplianceData['flag']; commentId: ComplianceData['commentId'] },
) {
  return {
    date,
    createdBy,
    comments,
    flag,
    commentId,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const ComplianceCommentsTable = (
  props,
) => {
  const classes = useStyles()
  const [expandedRow, setExpandedRow] = useState('')

  const toggleRow = id => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  React.useEffect(() => {
    props.fetchComplianceComments(props.match.params.accountId)
  }, [])

  const {
    complianceComments: { data, status },
    resetForm,
  } = props
  const commentsRows =
  data &&
  data.length > 0 &&
  data.map((results, i) => {
    const {
      date,
      createdBy,
      comment,
      flag,
      commentId,
    } = results
    return createData(
      {
        date, createdBy, comments: comment, flag, commentId,
      },
    )
  })
  const hasAccountComments = commentsRows.length > 0
  return (

    <>
      <ErrorBoundary message="Form Comments">
        <FormComplianceComments
          initialValues={{
            accountId: props.match.params.accountId,
            data: { date: new Date(), createdBy: auth.decodeNameToken() },
            flag: false,
          }}
          onCancel={resetForm}
          onSubmitSuccess={(value, dispatch) => {
            dispatch(fetchComplianceComments(props.match.params.accountId))
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary message="Compliance Comments">
        {status === 'loading' && (
          <TablePlaceholder>
            <LoadingBlock />
          </TablePlaceholder>
        )}
        {status === 'done' && (
          <CustomAccordion title="Compliance Comments">
            {hasAccountComments && (
              <div className={classes.root} >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead >
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Comments</TableCell>
                      <TableCell>Flag Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commentsRows.length === 0 && (
                      <TableCell colSpan={6}>
                        <TablePlaceholder>{'No comments'}</TablePlaceholder>
                      </TableCell>
                    )}
                    {commentsRows.length > 0 &&
              commentsRows.map((row, index) => {
                const { flag } = row
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell
                        onClick={() => toggleRow(row.commentId)}
                        id={labelId}
                        scope="row"
                      >
                        {moment(row.date).format('YYYY-MMM-DD HH:mm')}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.createdBy}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.comments}
                      </TableCell>
                      <TableCell>
                        <FlagToggle
                          color={!flag ? FLAG_COLORS.GREEN : FLAG_COLORS.RED}
                          className={'fa fa-flag'}
                        >
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              props.flagComplianceComment({
                                flagStatus: !flag,
                                commentId: row.commentId,
                              })
                            }
                          >
                            {!flag ? 'Flag comment' : 'Unflag comment'}
                          </a>
                        </FlagToggle>
                      </TableCell>
                    </TableRow>
                    {expandedRow === row.commentId && (
                      <TableCell colSpan={6}>
                        <Details>
                          <DetailsBlockWithTitle title={'Raw Data'}>
                            <RawData data={row} title={'Raw Data'} />
                          </DetailsBlockWithTitle>
                        </Details>
                      </TableCell>
                    )}
                  </Fragment>
                )
              })}
                  </TableBody>
                </Table>
              </div>
            )}
            {!hasAccountComments && (
              <StyledNoCommentsMessage>
                No Compliance Comments
              </StyledNoCommentsMessage>
            )}
          </CustomAccordion>

        )}
      </ErrorBoundary>
    </>
  )
}

const mapStateToProps = state => ({
  complianceComments: state.complianceComments,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchComplianceComments,
      flagComplianceComment,
      resetForm: () => reset('ComplianceCommentsForm'),
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplianceCommentsTable)
