import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast/index'
import { fetchAuth0User } from 'redux/auth0/actions'
import { fetchKeycloakUser } from 'redux/keycloak/actions'

export const FETCH_ACCOUNT_DETAILS_PROCESS =
  'account/FETCH_ACCOUNT_DETAILS_PROCESS'
export const FETCH_ACCOUNT_DETAILS_SUCCESS =
  'account/FETCH_ACCOUNT_DETAILS_SUCCESS'
export const FETCH_ACCOUNT_DETAILS_ERROR = 'account/FETCH_ACCOUNT_DETAILS_ERROR'

export const FETCH_ACCOUNT_DETAILS_RBA_PROCESS =
  'account/FETCH_ACCOUNT_DETAILS_RBA_PROCESS'
export const FETCH_ACCOUNT_DETAILS_RBA_SUCCESS =
  'account/FETCH_ACCOUNT_DETAILS_RBA_SUCCESS'
export const FETCH_ACCOUNT_DETAILS_RBA_ERROR =
  'account/FETCH_ACCOUNT_DETAILS_RBA_ERROR'

export const SEARCH_ACCOUNTS_PROCESS = 'accounts/SEARCH_ACCOUNTS_PROCESS'
export const SEARCH_ACCOUNTS_SUCCESS = 'accounts/SEARCH_ACCOUNTS_SUCCESS'
export const SEARCH_ACCOUNTS_ERROR = 'accounts/SEARCH_ACCOUNTS_ERROR'

export const FETCH_ACCOUNT_FRAUD_INFORMATION_START =
  'accounts/FETCH_ACCOUNT_FRAUD_INFORMATION_START'
export const FETCH_ACCOUNT_FRAUD_INFORMATION_SUCCESS =
  'accounts/FETCH_ACCOUNT_FRAUD_INFORMATION_SUCCESS'
export const FETCH_ACCOUNT_FRAUD_INFORMATION_ERROR =
  'accounts/FETCH_ACCOUNT_FRAUD_INFORMATION_ERROR'

export const UPDATE_ACCOUNT_FRAUD_INFORMATION_START =
  'accounts/UPDATE_ACCOUNT_FRAUD_INFORMATION_START'
export const UPDATE_ACCOUNT_FRAUD_INFORMATION_SUCCESS =
  'accounts/UPDATE_ACCOUNT_FRAUD_INFORMATION_SUCCESS'
export const UPDATE_ACCOUNT_FRAUD_INFORMATION_ERROR =
  'accounts/UPDATE_ACCOUNT_FRAUD_INFORMATION_ERROR'

export const HANDLE_ACCOUNT_FRAUD_MODAL_STATE =
  'accounts/HANDLE_ACCOUNT_FRAUD_MODAL_STATE'

export const FETCH_ACCOUNT_RISK_PROFILE_START =
  'account/FETCH_ACCOUNT_RISK_PROFILE_START'
export const FETCH_ACCOUNT_RISK_PROFILE_SUCCESS =
  'account/FETCH_ACCOUNT_RISK_PROFILE_SUCCESS'
export const FETCH_ACCOUNT_RISK_PROFILE_ERROR =
  'account/FETCH_ACCOUNT_RISK_PROFILE_ERROR'

export const FETCH_CORPORATE_FOR_ACCOUNT_START =
  'account/FETCH_CORPORATE_FOR_ACCOUNT_START'
export const FETCH_CORPORATE_FOR_ACCOUNT_SUCCESS =
  'account/FETCH_CORPORATE_FOR_ACCOUNT_SUCCESS'
export const FETCH_CORPORATE_FOR_ACCOUNT_ERROR =
  'account/FETCH_CORPORATE_FOR_ACCOUNT_ERROR'

export const FETCH_RISK_SCORING_OPTIONS_START =
  'account/FETCH_RISK_SCORING_OPTIONS_START'
export const FETCH_RISK_SCORING_OPTIONS_SUCCESS =
  'account/FETCH_RISK_SCORING_OPTIONS_SUCCESS'
export const FETCH_RISK_SCORING_OPTIONS_ERROR =
  'account/FETCH_RISK_SCORING_OPTIONS_ERROR'

export const UPDATE_RISK_PROFILE_START = 'account/UPDATE_RISK_PROFILE_START'
export const UPDATE_RISK_PROFILE_SUCCESS = 'account/UPDATE_RISK_PROFILE_SUCCESS'
export const UPDATE_CORPORATE_RISK_PROFILE_SUCCESS =
  'account/UPDATE_CORPORATE_RISK_PROFILE_SUCCESS'
export const UPDATE_RISK_PROFILE_ERROR = 'account/UPDATE_RISK_PROFILE_ERROR'

export const FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_START =
  'account/FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_START'
export const FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_SUCCESS =
  'account/FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_SUCCESS'
export const FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_ERROR =
  'account/FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_ERROR'

export const FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_START =
  'account/FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_START'
export const FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS =
  'account/FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS'
export const FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_ERROR =
  'account/FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_ERROR'

export const UPDATE_RISK_PROFILE_EDD_REPORT_SUCCESS =
  'account/UPDATE_RISK_PROFILE_EDD_REPORT_SUCCESS'

export const UPDATE_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS =
  'account/UPDATE_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS'

export const FETCH_RISK_PROFILE_EDD_DOCUMENTS_START =
  'account/FETCH_RISK_PROFILE_EDD_DOCUMENTS_START'
export const FETCH_RISK_PROFILE_EDD_DOCUMENTS_SUCCESS =
  'account/FETCH_RISK_PROFILE_EDD_DOCUMENTS_SUCCESS'
export const FETCH_RISK_PROFILE_EDD_DOCUMENTS_ERROR =
  'account/FETCH_RISK_PROFILE_EDD_DOCUMENTS_ERROR'

export const UPDATE_VALR_STAFF_ACCOUNT_STATUS_START =
  'account/UPDATE_VALR_STAFF_ACCOUNT_STATUS_START'
export const UPDATE_VALR_STAFF_ACCOUNT_STATUS_SUCCESS =
  'account/UPDATE_VALR_STAFF_ACCOUNT_STATUS_SUCCESS'
export const UPDATE_VALR_STAFF_ACCOUNT_STATUS_ERROR =
  'account/UPDATE_VALR_STAFF_ACCOUNT_STATUS_ERROR'

export const updateValrStaffAccountStatus =
  ({ accountId, status }) =>
  async (dispatch) => {
    try {
      dispatch({ type: UPDATE_VALR_STAFF_ACCOUNT_STATUS_START })
      await getAxios().post(
        `/account/${accountId}/details/valr-staff-account-status`,
        {
          status,
        },
      )
      dispatch({
        type: UPDATE_VALR_STAFF_ACCOUNT_STATUS_SUCCESS,
        payload: status,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: UPDATE_VALR_STAFF_ACCOUNT_STATUS_ERROR })
    }
  }

export const massageAccountData = (accounts) =>
  accounts.map((acc) => ({
    info: {
      ...acc.sensitiveInfo,
      ...acc,
      depositReference: acc.depositReferenceForDisplay || '',
    },
    identifier: acc.accountIdentifier,
  }))

export const fetchAccountDetails = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_DETAILS_PROCESS,
    payload: {
      id,
    },
  })

  try {
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const {
      accountIdentifier,
      sensitiveInfo,
      depositReference,
      authenticationProvider,
    } = res.data
    dispatch({
      type: FETCH_ACCOUNT_DETAILS_SUCCESS,
      payload: {
        id,
        info: sensitiveInfo,
        identifier: accountIdentifier,
        reference: depositReference,
        ...res.data,
      },
    })
    if (authenticationProvider) {
      if (authenticationProvider.toLowerCase() === 'auth0') {
        dispatch(fetchAuth0User(id, accountIdentifier))
      } else if (authenticationProvider.toLowerCase() === 'keycloak') {
        dispatch(fetchKeycloakUser(id, accountIdentifier))
      }
    }
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_DETAILS_ERROR,
      payload: {
        id,
        error: 'Error occurred when fetching account',
      },
    })
  }
}

export const refreshAccountDetailsForRBA = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_DETAILS_RBA_PROCESS,
    payload: {
      id,
    },
  })

  try {
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const {
      accountIdentifier,
      sensitiveInfo,
      depositReference,
      authenticationProvider,
    } = res.data
    dispatch({
      type: FETCH_ACCOUNT_DETAILS_RBA_SUCCESS,
      payload: {
        id,
        info: sensitiveInfo,
        identifier: accountIdentifier,
        reference: depositReference,
        ...res.data,
      },
    })
    if (authenticationProvider) {
      if (authenticationProvider.toLowerCase() === 'auth0') {
        dispatch(fetchAuth0User(id, accountIdentifier))
      } else if (authenticationProvider.toLowerCase() === 'keycloak') {
        dispatch(fetchKeycloakUser(id, accountIdentifier))
      }
    }
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_DETAILS_RBA_ERROR,
      payload: {
        id,
        error: 'Error occurred when fetching account',
      },
    })
  }
}

export const fetchTwilioData = (id, number) => async (dispatch) => {
  dispatch({
    type: 'FETCH_ACCOUNT_TWILIO',
    payload: {
      id,
    },
  })
  try {
    const sms = await getAxios().post('/twilio/sms-list', { number })
    const voice = await getAxios().post('/twilio/voice-list', { number })
    const response = { sms: sms.data, voice: voice.data }
    dispatch({
      type: 'FETCH_ACCOUNT_TWILIO_SUCCESS',
      payload: {
        data: response,
        id,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: 'FETCH_TWILIO_ERROR',
      payload: {
        error,
      },
    })
  }
}

export const clearAccountDetails = () => async (dispatch) => {
  dispatch({
    type: 'CLEAR_ACCOUNT_DETAILS',
  })
}

export const handleAccountFraudModalState =
  ({ isOpen, actionType }) =>
  async (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNT_FRAUD_MODAL_STATE,
      payload: {
        actionType,
        isOpen,
      },
    })
  }

export const fetchAccountFraudInformation = (accountId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ACCOUNT_FRAUD_INFORMATION_START,
    })
    const { data } = await getAxios().get(
      `/risk-account/${accountId}/fraud-risk-alerts`,
    )
    dispatch({
      type: FETCH_ACCOUNT_FRAUD_INFORMATION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_FRAUD_INFORMATION_ERROR,
    })
  }
}

export const updateAccountFraudStatuses = (values) => async (dispatch) => {
  try {
    const { isActiveAlert, accountId, comment } = values
    dispatch({
      type: UPDATE_ACCOUNT_FRAUD_INFORMATION_START,
    })
    const { data } = await getAxios().post('/risk-account/fraud-risk-alerts', {
      values,
      comment: `${isActiveAlert ? 'Removed alert' : 'Added alert'}: ${comment}`,
    })
    dispatch({
      type: UPDATE_ACCOUNT_FRAUD_INFORMATION_SUCCESS,
      payload: data,
    })
    dispatch(handleAccountFraudModalState({ isOpen: false, actionType: '' }))
    toast(`Fraud alert for account ${accountId} submitted`)
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_ACCOUNT_FRAUD_INFORMATION_ERROR,
    })
  }
}

export const fetchRiskScoringFormOptions = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_RISK_SCORING_OPTIONS_START,
    })
    const { data } = await getAxios().get(
      '/risk-based-approach/profile/scoring-levels',
    )
    dispatch({
      type: FETCH_RISK_SCORING_OPTIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_RISK_SCORING_OPTIONS_ERROR,
    })
  }
}

export const fetchRiskProfileInfo = (accountId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_START,
    })
    const { data } = await getAxios().get(
      `/risk-based-approach/profile/${accountId}`,
    )
    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_ERROR,
    })
  }
}

export const fetchAccountCorporateInformation =
  (accountId) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_CORPORATE_FOR_ACCOUNT_START,
      })
      const {
        data: { corporateData, corporateAddressDetails },
      } = await getAxios().get(`/corporates/${accountId}/info`)
      if (!corporateData.id) {
        toast(
          'This account is a corporate but the corporate data has not been captured yet.',
        )
        return dispatch({
          type: FETCH_CORPORATE_FOR_ACCOUNT_ERROR,
        })
      }
      const {
        data: { naturalEntities },
      } = await getAxios().get(`/corporates/${corporateData.id}/entities`)
      dispatch({
        type: FETCH_CORPORATE_FOR_ACCOUNT_SUCCESS,
        payload: {
          accountCorporate: {
            createdAt: corporateData.createdAt,
            corporateId: corporateData.id,
            name: corporateData.name,
            purposeOfAccount: corporateData.purpose,
            countryOfRegistration: corporateData.countryOfRegistration,
            natureOfBusiness: corporateData.nature,
            sourceOfFunds: corporateData.sourceOfFunds,
            companyRegistrationNumber:
              corporateData.corporateRegistrationNumber,
            corporateAddressDetails,
            type: corporateData.type,
            sourceOfWealth: corporateData.sourceOfWealth,
            productSelection: corporateData.productSelection,
          },
          accountCorporateNaturalEntities: naturalEntities,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: FETCH_CORPORATE_FOR_ACCOUNT_ERROR,
      })
    }
  }

export const createRiskProfile = async (values, dispatch) => {
  try {
    dispatch({
      type: UPDATE_RISK_PROFILE_START,
    })
    const { data } = await getAxios().post(
      '/risk-based-approach/profile',
      values,
    )
    if (values.isCorporateAccount) {
      dispatch({
        type: UPDATE_CORPORATE_RISK_PROFILE_SUCCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: UPDATE_RISK_PROFILE_SUCCESS,
        payload: data,
      })
    }
    toast(
      `${
        values.isCorporateAccount ? 'Corporate' : 'Individual'
      }Risk profile saved.`,
      {
        type: toast.TYPE.SUCCESS,
      },
    )
  } catch (error) {
    ErrorToast(error)

    dispatch({
      type: UPDATE_RISK_PROFILE_ERROR,
    })
  }
}

export const updateRiskProfile = async (values, dispatch) => {
  try {
    dispatch({
      type: UPDATE_RISK_PROFILE_START,
    })
    const { data } = await getAxios().put(
      '/risk-based-approach/profile',
      values,
    )
    if (values.isCorporateAccount) {
      dispatch({
        type: UPDATE_CORPORATE_RISK_PROFILE_SUCCESS,
        payload: data,
      })
    } else {
      dispatch({
        type: UPDATE_RISK_PROFILE_SUCCESS,
        payload: data,
      })
    }
    toast(
      `${
        values.isCorporateAccount ? 'Corporate' : 'Individual'
      }Risk profile saved.`,
      {
        type: toast.TYPE.SUCCESS,
      },
    )
  } catch (error) {
    ErrorToast(error)

    dispatch({
      type: UPDATE_RISK_PROFILE_ERROR,
    })
  }
}

export const fetchRiskProfileEddReport = (accountId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_START,
    })
    const { data: eddReport } = await getAxios().get(
      `/risk-based-approach/profile/edd-report/${accountId}`,
    )

    const { data: eddReportApprovals } = await getAxios().get(
      `/risk-based-approach/dual-auth/approvers/${accountId}`,
    )
    const { data: dualAuthHistory } = await getAxios().get(
      `/risk-based-approach/dual-auth/history/${accountId}`,
    )

    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_SUCCESS,
      payload: { eddReport, eddReportApprovals, dualAuthHistory },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_ERROR,
    })
  }
}

export const updateRiskProfileEddReport = async (values, dispatch) => {
  try {
    const { data } = await getAxios().post(
      '/risk-based-approach/profile/edd-report',
      values,
    )

    const { data: eddReportApprovals } = await getAxios().get(
      `/risk-based-approach/dual-auth/approvers/${data.accountId}`,
    )

    dispatch({
      type: UPDATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
      payload: { data, eddReportApprovals },
    })
    toast('Edd report saved', {
      type: toast.TYPE.SUCCESS,
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const updateCorporateRiskProfileEddReport = async (values, dispatch) => {
  try {
    const { data } = await getAxios().post(
      '/risk-based-approach/profile/edd-report/corporate',
      values,
    )

    const { data: corporateEddReportApprovals } = await getAxios().get(
      `/risk-based-approach/dual-auth/approvers/corporate/${data.accountId}`,
    )

    dispatch({
      type: UPDATE_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
      payload: { data, corporateEddReportApprovals },
    })
    toast('Edd report saved', {
      type: toast.TYPE.SUCCESS,
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const fetchCorporateRiskProfileEddReport =
  (accountId) => async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_START,
      })
      const { data: eddReport } = await getAxios().get(
        `/risk-based-approach/profile/edd-report/corporate/${accountId}`,
      )
      const { data: corporateEddReportApprovals } = await getAxios().get(
        `/risk-based-approach/dual-auth/approvers/corporate/${accountId}`,
      )
      const { data: corporateDualAuthHistory } = await getAxios().get(
        `/risk-based-approach/corporate/dual-auth/history/${accountId}`,
      )
      dispatch({
        type: FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
        payload: {
          eddReport,
          corporateEddReportApprovals,
          corporateDualAuthHistory,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_ERROR,
      })
    }
  }

export const addNewRiskProfileEddDocument = (document) => async (dispatch) => {
  dispatch({
    type: 'ADD_RISK_PROFILE_EDD_DOCUMENT',
    payload: document,
  })
}

export const removeRiskProfileEddDocument =
  (documentId) => async (dispatch) => {
    dispatch({
      type: 'REMOVE_RISK_PROFILE_EDD_DOCUMENT',
      payload: documentId,
    })
  }
export const restoredRiskProfileEddDocument =
  (documentId) => async (dispatch) => {
    dispatch({
      type: 'RESTORE_RISK_PROFILE_EDD_DOCUMENT',
      payload: documentId,
    })
  }
