import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { connectStateAndDispatch } from 'store'

import { fetchRiskProfileInfo } from 'redux/accounts/actions'

import ComplianceInfo from './components/ComplianceInfo'
import CorporateRiskProfileInfo from './components/CorporateRiskProfileInfo'

import ComplianceCommentsTable from '../Comments/ComplianceComments'
import { getCustomerType } from 'redux/selectors/type'


const ComplianceDetails = props => {
  const routes = [
    '/accounts/byId/:accountId/account-compliance/risk-profile',
    '/accounts/byId/:accountId/account-compliance/corporate-risk-profile',
    '/accounts/byId/:accountId/account-compliance/comments',
  ]
  const tabs = ['risk-profile', 'corporate-risk-profile', 'comments']

  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
    customerTypeInfo: { data },
    accountType,
  } = props
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const checkPath = path => {
    tabs.forEach(tab => {
      if (path.includes(tab)) {
        handleChange(null, tab)
      }
    })
  }

  useEffect(
    () => {
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
    },
    [pathname],
  )

  useEffect(
    () => {
      checkPath(pathname)
    },
    [pathname],
  )

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={'risk-profile'}
          label="Risk Profile"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId
            }/account-compliance/risk-profile`}
          exact
        />
        {data.type === 'CORPORATE' && accountType === 'PRIMARY' && <NavigationTab
          value={'corporate-risk-profile'}
          label="Corporate Risk Profile"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId
            }/account-compliance/corporate-risk-profile`}
          exact
        />
        }
        <NavigationTab
          value={'comments'}
          label="COMPLIANCE COMMENTS"
          component={ForwardedNavLink}
          to={`/accounts/byId/${props.match.params.accountId
            }/account-compliance/comments`}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={ComplianceInfo} exact />
          {data.type === 'CORPORATE' && accountType === 'PRIMARY' && <Route path={routes[1]} component={CorporateRiskProfileInfo} exact />}
          <Route path={routes[2]} component={ComplianceCommentsTable} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}

const actions = { fetchRiskProfileInfo }

export default connectStateAndDispatch((state) => ({
  customerTypeInfo: getCustomerType(state),
  accountType: state.accounts.byId.accountType,
}), actions, ComplianceDetails)
