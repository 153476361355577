import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  FlagToggle,
  FLAG_COLORS,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import moment from 'moment'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details/index'
import RawData from 'components/atoms/RawDataDualAuth/index'

const InternalTransferComments = ({
  internalTransferComments,
  flagComment,
  paperStyle,
}) => {
  const [expandedRow, setExpandedRow] = useState('')

  const toggleSelectedRow = id => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  const toggleRow = id => {
    toggleSelectedRow(id)
  }
  return (
    <Paper className={paperStyle}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead align="right">
            <TableRow>
              <TableCell> Date</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell> Comments</TableCell>
              <TableCell>Action Type</TableCell>
              <TableCell>Transfer Status</TableCell>
              <TableCell>Flag Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {internalTransferComments === 0 && (
              <TableCell colSpan={6}>
                <TablePlaceholder>{'No comments'}</TablePlaceholder>
              </TableCell>
            )}
            {internalTransferComments.map(
              (internalTransferCommentData, index) => {
                const {
                  id,
                  createdAt,
                  createdBy,
                  comment,
                  actionType,
                  transferStatus,
                  transferId,
                  flag,
                } = internalTransferCommentData
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <Fragment key={transferId}>
                    <TableRow>
                      <TableCell
                        onClick={() => toggleRow(transferId)}
                        id={labelId}
                        scope="row"
                      >
                        {moment(createdAt).format('YYYY-MMM-DD HH:mm')}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(transferId)}>
                        {createdBy}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(transferId)}>
                        {comment}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(transferId)}>
                        {actionType}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(transferId)}>
                        {transferStatus || 'No status'}
                      </TableCell>
                      <TableCell>
                        <FlagToggle
                          color={!flag ? FLAG_COLORS.GREEN : FLAG_COLORS.RED}
                          className={'fa fa-flag'}
                        >
                          <a
                            style={{ cursor: 'pointer' }}
                            hover
                            onClick={() =>
                              flagComment({
                                flagStatus: !flag,
                                commentId: id,
                              })
                            }
                          >
                            {!flag ? 'Flag comment' : 'Unflag comment'}
                          </a>
                        </FlagToggle>
                      </TableCell>
                    </TableRow>
                    {expandedRow === transferId && (
                      <TableCell colSpan={6}>
                        <Details>
                          <DetailsBlockWithTitle title={'Raw Data'}>
                            <RawData
                              data={internalTransferCommentData}
                              title={'Raw Data'}
                            />
                          </DetailsBlockWithTitle>
                        </Details>
                      </TableCell>
                    )}
                  </Fragment>
                )
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

InternalTransferComments.propTypes = {
  internalTransferComments: PropTypes.array,
  flagComment: PropTypes.func,
  paperStyle: PropTypes.object,
}

export default InternalTransferComments
