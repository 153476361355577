import styled from 'styled-components'
import { BLUISH_GREY } from 'theme/colors'

export const StyledGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  margin-top: 8px;
  border-radius: 8px;
  font-weight: 600;
  background-color: ${({ theme }) =>
    theme === 'lightTheme' ? '#91a4d6' : BLUISH_GREY};
  color: white;
`
export const StyledAccordionAction = styled.div`
  position: absolute;
  top: 22px;
  right: 55px;
`
export const StyledSubGroupsSection = styled.div`
  width: 100%;
`

export const StyledKeycloakAccordionButtonContainer = styled.div`
  text-align: right;
  margin-top: 12px;
`
export const StyledUserRole = styled.div`
  margin-top: 12px;
`

export const StyledUserRolesContainer = styled.div`
  height: 300px;
  overflow: scroll;
  width: 100%;
`
