import React from 'react'
import { Document, Page, View, Text, Image } from '@react-pdf/renderer'
import { styles } from './Styles'
import { convertTimestampToDate, formatDate, splitRowsIntoPages } from 'helpers/utils'
import {
  VALR_LETTERHEAD_BASE64,
  VALR_SMALL_DISCLAIMER,
  VALR_TRANSACTION_STATEMENT_DISCLAIMER,
} from './const'
import { EmptyStatementTable, Footer } from './Shared'
import {
  TransactionStatementProps,
  TransactionStatementTableProps,
} from 'interfaces/downloadStatements'

const TransactionStatementTable: React.FC<TransactionStatementTableProps> = ({
  rows,
}) => (
  <View style={styles.tableContainer}>
    <View style={[styles.tableRow, styles.txTableHeader]}>
      <Text style={styles.txTableCell}>Transaction Date</Text>
      <Text style={styles.txTableCell}>Transaction Type</Text>
      <Text style={styles.txTableCell}>Debit</Text>
      <Text style={styles.txTableCell}>Credit</Text>
      <Text style={styles.txTableCell}>Fee</Text>
    </View>
    {rows.length === 0 && <EmptyStatementTable type="transactions" />}
    {rows.map((tx, index) => (
      <View key={index} style={styles.tableRow}>
        <Text
          style={index % 2 === 0 ? styles.txTableCell : styles.txDarkTableCell}
        >
          {convertTimestampToDate(tx.transactionDate)}
        </Text>
        <Text
          style={index % 2 === 0 ? styles.txTableCell : styles.txDarkTableCell}
        >
          {tx.transactionType}
        </Text>
        <Text
          style={index % 2 === 0 ? styles.txTableCell : styles.txDarkTableCell}
        >
          {tx.debitValue && `${tx.debitValue} ${tx.debitCurrency}`}
        </Text>
        <Text
          style={index % 2 === 0 ? styles.txTableCell : styles.txDarkTableCell}
        >
          {tx.creditValue && `${tx.creditValue} ${tx.creditCurrency}`}
        </Text>
        <Text
          style={index % 2 === 0 ? styles.txTableCell : styles.txDarkTableCell}
        >
          {tx.feeValue && tx.feeCurrency && `${tx.feeValue} ${tx.feeCurrency}`}
        </Text>
      </View>
    ))}
  </View>
)

const TransactionStatement: React.FC<TransactionStatementProps> = ({
  customerDetails,
  payload,
  from,
  to,
}) => {
  const date = `${formatDate(from)} to ${formatDate(to)}`
  const { firstPageRows, subsequentPagesData } = splitRowsIntoPages(payload)

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={VALR_LETTERHEAD_BASE64} style={styles.letterhead} />
        <View>
          <Text style={styles.statementTitle}>
            Account Transaction Statement
          </Text>
        </View>
        <View>
          <Text style={styles.accountName}>{customerDetails}</Text>
        </View>
        <View>
          <Text style={styles.smallDisclaimer}>{VALR_SMALL_DISCLAIMER}</Text>
        </View>
        <View style={styles.right}>
          <Text style={styles.date}>Statement Period: {date}</Text>
        </View>
        <View>
          <Text style={styles.statementType}>Transaction History</Text>
        </View>
        <View>
          <TransactionStatementTable rows={firstPageRows} />
        </View>
        <View style={styles.footer} fixed>
          <Footer
            date={date}
            disclaimer={VALR_TRANSACTION_STATEMENT_DISCLAIMER}
          />
        </View>
      </Page>
      {subsequentPagesData.length > 0 &&
        subsequentPagesData.map((pageRows, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page}>
            <View>
              <TransactionStatementTable rows={pageRows} />
            </View>
            <View style={styles.footer} fixed>
              <Footer
                date={date}
                disclaimer={VALR_TRANSACTION_STATEMENT_DISCLAIMER}
              />
            </View>
          </Page>
        ))}
    </Document>
  )
}

export default TransactionStatement
