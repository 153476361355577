import styled from 'styled-components'

export const Container = styled.span`
  cursor: ${({ cursor = 'initial' }) => cursor};
  margin-right: ${({ marginRight = 0 }) => marginRight};
  display: ${({ display = 'initial' }) => display};
  opacity: ${({ opacity = 1 }) => opacity};
`

export const ConfigStyle = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
`
export const ListStyle = styled.div`
  display: grid;
  grid-template-columns: 40% 10%;
`

export const MapStyle = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 50%;
`

export const MapInner = styled.div`
  display: flex;
  gap: 10px;
`

export const InputStyle = styled.div`
  display: grid;
  grid-template-columns: 20% 5%;
  align-items: flex-end;
  grid-column-gap: 12px;
  margin: 18px 0px;
`
