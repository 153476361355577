import getAxios from 'helpers/axios'

export const DISPLAY_USER_TICKETS_PROCESS = ' DISPLAY_USER_TICKETS_PROCESS'
export const DISPLAY_USER_TICKETS_SUCCESS = 'DISPLAY_USER_TICKETS_SUCCESS'
export const DISPLAY_USER_TICKETS_ERROR = 'DISPLAY_USER_TICKETS_ERROR'

export const UserTicketsComments = (ticketId) => async dispatch => {
  dispatch({
    type: DISPLAY_USER_TICKETS_PROCESS,
    payload: {
      ticketId,
    },
  })
  let res = null
  try {
    res = await getAxios().get(`/show-ticket/${ticketId}`)
    const { data } = res
    dispatch({
      type: DISPLAY_USER_TICKETS_SUCCESS,
      payload: {
        ticketId,
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: DISPLAY_USER_TICKETS_ERROR,
      payload: {
        ticketId,
        error,
      },
    })
  }
}
