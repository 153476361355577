import React, { Component, Fragment } from 'react'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({ formShown: !this.state.formShown })
  }

  render() {
    const {
      logs: {
        email, jwtSubject, method, url, accountId, date,
      },
    } = this.props

    const { formShown } = this.state
    return (
      <Fragment >
        {method === 'POST' || method === 'PUT' ? <TableRow onClick={this.toggleForm}>
          <TableCell width={'14%'}>{email}</TableCell>
          <TableCell width={'21%'}>{jwtSubject}</TableCell>
          <TableCell width={'8%'}>{accountId}</TableCell>
          <TableCell width={'8%'}>{method}</TableCell>
          <TableCell width={'34%'}>{url}</TableCell>
          <TableCell width={'15%'}> {moment(date).format('YYYY-MMM-DD HH:mm:ss')}</TableCell>
        </TableRow> :
          <TableRow >
            <TableCell width={'14%'}>{email}</TableCell>
            <TableCell width={'21%'}>{jwtSubject}</TableCell>
            <TableCell width={'8%'}>{accountId}</TableCell>
            <TableCell width={'8%'}>{method}</TableCell>
            <TableCell width={'34%'}>{url}</TableCell>
            <TableCell width={'15%'}> {moment(date).format('YYYY-MMM-DD HH:mm:ss')}</TableCell>
          </TableRow>
        }
        {formShown &&
          <Details>
            <DetailsBlockWithTitle title="Raw Data">
              <RawData data={this.props.logs} />
            </DetailsBlockWithTitle>
          </Details>
        }
      </Fragment>

    )
  }
}
export default Row
