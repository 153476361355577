import React, { Component, Fragment } from 'react'
import { Link } from '../AccountIdLink/clickableId'
import { StyledExpandedSectionContainer, StyledExpandTableCell } from 'containers/Accounts/SingleAccount/AccountOpenOrders/Styles'
import { Collapse } from '@material-ui/core'
import { JsonBlock } from 'components/atoms/Details'
import { TableCell, TableRow } from '@material-ui/core'

class Row extends Component {
  render() {
    const {
      orderBook: {
        accountId, currencyPair, id, positionAtPrice, price, quantity, side,
      },
      handleRowClick,
      expandedItem,
    } = this.props

    return (
      <Fragment>
        <TableRow
          onClick={() =>
            handleRowClick(id)
          }
        >
          <TableCell><Link id={accountId} /></TableCell>
          <TableCell>{currencyPair}</TableCell>
          <TableCell>{positionAtPrice}</TableCell>
          <TableCell >{price}</TableCell>
          <TableCell >{quantity}</TableCell>
        </TableRow>
        <TableRow className="no-hover">
          <StyledExpandTableCell colSpan={6}>
            <Collapse
              in={expandedItem === id}
              timeout="auto"
              unmountOnExit
            >
              <StyledExpandedSectionContainer>
                <JsonBlock src={this.props.orderBook} title={'Raw Data'} />
              </StyledExpandedSectionContainer>
            </Collapse>
          </StyledExpandTableCell>
        </TableRow>
      </Fragment>
    )
  }
}

export default Row
