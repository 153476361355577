import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { TablePagination } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'

import ReviewFiatWithdrawalsRow from './row'

import { getSymbolFromCurrency } from '../../../helpers/currency'
import { getSelectedBankAccount } from '../../../redux/fiat/selectors'

class ReviewFiatWithdrawals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedId: -1,
      showRawData: '',
      highlightedId: '',
      page: 0,
      rowsPerPage: 50,
      expandRawData: '',
      addNoteFormShown: '',
    }
  }

  toggleSelected = id => {
    if (this.state.selectedId === id) {
      this.setState({ selectedId: -1 })
    } else {
      this.setState({ selectedId: id })
    }
  }

  toggleRawData = id => {
    if (this.state.showRawData === id) {
      this.setState({ showRawData: '' })
    } else {
      this.setState({ showRawData: id })
    }
  }

  showRawData = id => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleAddNotesForm = id => {
    if (id !== this.state.addNoteFormShown) {
      this.setState({ addNoteFormShown: id })
    } else {
      this.setState({ addNoteFormShown: '' })
    }
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  setRowsPerPage = rowsPerPage => {
    this.setState({
      rowsPerPage,
    })
  }

  countAllWithdrawals = () => {
    const {
      withdrawalsUnderReview: { data },
    } = this.props
    const counter = data
      ? data.reduce((accum, value) => accum + Number(value.amount), 0)
      : 0
    return `${new BigNumber(counter).toFormat(2)}`
  }

  setPage = page => {
    this.setState({
      page,
    })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage)
  }

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10))
    this.setPage(0)
  }

  render() {
    const { name, setExpand, expanded } = this.props
    const {
      withdrawalsUnderReview: { status, data },
      reviews,
      currency,
    } = this.props
    const { page, rowsPerPage } = this.state
    const hasWithdrawals = data && data.length > 0
    return (
      <ExpandingItem
        title={`${name}`}
        itemsAmount={(data && data.length) || 0}
        totalAmount={`(${getSymbolFromCurrency(
          currency,
        )}${this.countAllWithdrawals() || 0})`}
        expanded={expanded}
        onExpand={setExpand}
        expandedView={
          <>
            {status === 'loading' && <LoadingBlock />}
            {status === 'done' &&
              data &&
              data.length === 0 && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.WITHDRAWALS.type}
                />
              )}
            {hasWithdrawals && (
              <Table>
                <TableHeader>
                  <TableCell flex={0.5}>Account Id</TableCell>
                  <TableCell flex={1.2}>Bank Account Holder</TableCell>
                  <TableCell flex={1.2}>Bank</TableCell>
                  <TableCell flex={1}>Name</TableCell>
                  <TableCell flex={1}>Surname</TableCell>
                  <TableCell flex={1}>Fast Withdrawal</TableCell>
                  <TableCell flex={1}> Amount</TableCell>
                  <TableCell flex={0.8}>Currency</TableCell>
                  <TableCell flex={1}>Date</TableCell>
                  <TableCell flex={0.5} />
                  <TableCell flex={0.18} />
                </TableHeader>
                <TableBody>
                  {status === 'done' &&
                    data &&
                    data
                      .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                      .map(withdraw => {
                        withdraw.updatedAt = moment(withdraw.updatedAt).format(
                          'YYYY-MMM-DD HH:mm:ss',
                        )
                        return (
                          <ReviewFiatWithdrawalsRow
                            title={name}
                            key={withdraw.withdrawalId}
                            withdraw={withdraw}
                            toggleSelected={this.toggleSelected}
                            setHighlightedId={this.setHighlightedId}
                            toggleRawData={this.toggleRawData}
                            toggleAddNotesForm={this.toggleAddNotesForm}
                            addNoteFormShown={this.state.addNoteFormShown}
                            expandRawData={this.state.expandRawData}
                            showRawData={this.showRawData}
                            showForm={
                              this.state.selectedId === withdraw.withdrawalId
                            }
                            review={
                              reviews.byId[withdraw.reviewItemId] &&
                              reviews.byId[withdraw.reviewItemId].data
                            }
                            isHighlighted={
                              this.state.highlightedId === withdraw.withdrawalId
                            }
                          />
                        )
                      })}
                  <TablePagination
                    component="div"
                    count={data.length}
                    page={page}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableBody>
              </Table>
            )}
          </>
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  reviews: state.reviews,
  accounts: state.accounts.byId,
  currency: getSelectedBankAccount(state).currency,
})

export default connect(mapStateToProps)(ReviewFiatWithdrawals)
