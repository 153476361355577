import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import tradeFees from './tradeFees/reducer'
import fund from './fund'
import accounts from './accounts'
import transactions from './transactions'
import balances from './balances'
import kyc from './kyc'
import address from './address'
import status from './activationStatus'
import sends from './sends'
import ui from './ui'
import auth0 from './auth0'
import keycloak from './keycloak'
import fiat from './fiat'
import circle from './circle'
import deposits from './deposits'
import fundFiat from './fund-fiat'
import releaseFiat from './release-fiat'
import customerType from './customerType'
import watchlistCheck from './singleAccountSanctions'
import reviews from './review'
import pendingInternalTransfers from './pendingInternalTransfers'
import currencies from './currencies'
import corporate from './corporate/index'
import corporateEntities from './corporateEntities'
import getAllMfas from './getAllMfas'
import pendingDualAuth from './dual-auth'
import currencyPairs from './currencyPairs'
import orderTypesForCurrencyPairs from './orderTypesForCurrencyPairs'
import potentialUserInSanctions from './sanctions'
import userComments from './userComments'
import crypto from './crypto'
import recentSendgridEmailByUser from './accountEmailHistory'
import asksAndBids from './getAsksAndBids'
import depositsComments from './depositsComments'
import allUserTickets from './getUserTicketsByEmail'
import displayTickets from './userTicketsComments'
import unallocatedBlockchainReceives from './unallocatedBlockchainReceives'
import allRequests from './allRequest'
import accountLogs from './accountLogs'
import elasticsearchQuery from './elasticsearchQueryLogs'
import accountConfirmations from './accountConfirmations'
import applicationConfig from './applicationConfig'
import routing from './routing'
import stopOrders from './getStopOrders'
import funds from './funds'
import dualAuthByType from './nameAndSurnameChange'
import identityChange from './identityNumberChange'
import rebatesAndReferrals from './rebateAndReferral'
import twilio from './twilio'
import marketingContactListRunResults from './marketing-contact-updates'
import recentActivity from './RecentActivity'
import keycloakActivity from './keycloak-activity'
import keycloakGroups from './keycloak-groups'
import competition from './competitionDetails'
import kycAttempts from './manualKycUploads'
import manualKycAttempts from './manualKycAttempts'
import listData from './displayList'
import updatedList from './updateList'
import manualKycById from './manualKycById'
import pendingFerryAuths from './ferry-auths'
import creditCard from './creditCard'
import creditCardById from './creditCardDepositsById'
import loansBook from './loansBook'
import marginStatus from './marginStatus'
import margin from './margin'
import marginSettings from './marginSettings'
import valrPay from './valrPay'
import withdrawalLimit from './withdrawal-limit'
import virtualAccount from './virtual-account'
import sanctionedCorporate from './sanctionedCorporate'
import ignoredStatusDeposits from './ignoredDeposits'
import mobileNotifications from './mobileNotifications'
import veriffSessions from './veriffSessions'
import fundingEvents from './fundingEvents'
import addressBook from './address-book'
import accountPendingMultiAuths from './accountPendingMultuAuths'
import staking from './staking'
import fiatWithdrawalsDashboardStats from './fiatWithdrawalsDashboardStats'
import complianceComments from './complianceComments'
import requestedAccountActions from './requested-account-actions'
import futures from './futures'
import accountProfileSettings from './accountProfileSettings'
import riskBasedProfiles from './riskBasedProfiles'
import riskProfilesDulAuth from './riskProfilesDulAuth'
import trading from './trading'
import withdrawalRestrictions from './withdrawalRestrictions'
import dualAuthRestrictions from './withdrawalRestrictionsDualAuth'
import conditionalOrders from './conditionalOrders'
import pepScreening from './pepScreening'

export const appReducer = combineReducers({
  accounts,
  balances,
  kyc,
  address,
  status,
  form,
  fund,
  tradeFees,
  transactions,
  sends,
  ui,
  auth0,
  keycloak,
  fiat,
  circle,
  crypto,
  corporate,
  corporateEntities,
  deposits,
  fundFiat,
  releaseFiat,
  customerType,
  reviews,
  pendingInternalTransfers,
  currencies,
  getAllMfas,
  pendingDualAuth,
  currencyPairs,
  orderTypesForCurrencyPairs,
  potentialUserInSanctions,
  recentSendgridEmailByUser,
  userComments,
  asksAndBids,
  allUserTickets,
  displayTickets,
  unallocatedBlockchainReceives,
  watchlistCheck,
  depositsComments,
  allRequests,
  accountLogs,
  elasticsearchQuery,
  accountConfirmations,
  applicationConfig,
  routing,
  stopOrders,
  funds,
  dualAuthByType,
  identityChange,
  rebatesAndReferrals,
  twilio,
  marketingContactListRunResults,
  recentActivity,
  keycloakActivity,
  keycloakGroups,
  pendingFerryAuths,
  competition,
  kycAttempts,
  manualKycAttempts,
  listData,
  updatedList,
  manualKycById,
  creditCard,
  creditCardById,
  loansBook,
  marginStatus,
  margin,
  marginSettings,
  valrPay,
  withdrawalLimit,
  sanctionedCorporate,
  ignoredStatusDeposits,
  virtualAccount,
  mobileNotifications,
  veriffSessions,
  fundingEvents,
  addressBook,
  accountPendingMultiAuths,
  staking,
  fiatWithdrawalsDashboardStats,
  complianceComments,
  requestedAccountActions,
  futures,
  accountProfileSettings,
  riskBasedProfiles,
  riskProfilesDulAuth,
  trading,
  withdrawalRestrictions,
  dualAuthRestrictions,
  conditionalOrders,
  pepScreening,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['routing'],
}

export default () => persistReducer(persistConfig, appReducer)
