import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import auth from 'services/Auth'
import { Field, reduxForm } from 'redux-form'
import { required, requiredSelectDropdownValue } from 'forms/fieldValidators'
import {
  FormContainer,
  BlueLinkButton,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { InfoLabel } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import { COMMENT_MESSAGES } from 'helpers/const'
import {
  constructArrayWithInitials,
  returnInitials,
  returnStatusTypeComments,
} from 'helpers/utils'
import { EditableDropdown } from 'components/organisms/EditableDropdown/index'
import { renderDropdown } from '../fields'
import { submit } from './submit'

let Form = props => {
  const { submitting, error, invalid, anyTouched, change } = props
  const {
    handleSubmit,
    statusType,
    comment,
    selectedValue,
    onCancel,
    onSubmit,
    rawData,
    showRawData,
    expandRawData,
    showAddNote,
    expandAddNote,
    depositId,
    comments,
    isInDualAuth,
    showActiveUser,
    removeActiveUser,
  } = props

  const initials = returnInitials(auth.decodeFulNameFromToken())

  const commentsForForm = COMMENT_MESSAGES.EXCHANGE.USD_WITHDRAWALS_UNDER_REVIEW

  const commentsForStatusType = returnStatusTypeComments(
    commentsForForm,
    statusType || '',
  )

  const options = constructArrayWithInitials(
    commentsForStatusType,
    initials,
    statusType || '',
  )

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  React.useEffect(
    () => {
      change('comment', '')
      change('selectedValue', '')
    },
    [statusType],
  )

  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputGroup
          justify={'space-between'}
          align={'baseline'}
          direction={'row'}
        >
          <Field
            label={'Action'}
            name={'status'}
            margin={`0 ${BASE * 2}px 0 0`}
            flex={'0.3'}
            width="20%"
            type="deposits"
            component={renderDropdown}
            validate={[requiredSelectDropdownValue]}
            options={[
              { value: 'APPROVE', label: 'Approve' },
              { value: 'DECLINE', label: 'Reject' },
            ]}
          />
          <EditableDropdown
            condition={comment}
            label={'Comment (required)'}
            name={'comment'}
            selectFieldDropDownName={'selectedValue'}
            width={'100%'}
            validate={[required]}
            options={[...options]}
            justify={'space-around'}
            margin={`0px ${BASE * 2}px 26px 0px`}
            selectedValue={selectedValue ? selectedValue.value : ''}
            comment={comment || ''}
            change={change}
          />

          <Flexbox
            marginRight={BASE * 2}
            marginTop={BASE * 1.5}
            justify={'center'}
            direction={'row'}
          >
            <Button
              type="submit"
              disabled={submitting || invalid}
              margin={`0 ${BASE * 2}px 0 0`}
            >
              Submit
            </Button>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
          </Flexbox>
        </InputGroup>
        {/* </InputContainer> */}
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showRawData}
            >
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({
  onSubmit: submit,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Form)

const mapStateToProps = (state, ownProps) => {
  const suspendedForm = state.form[ownProps.form]
  if (!suspendedForm) {
    return {
      statusType: '',
    }
  }
  if (suspendedForm.values) {
    const {
      values: { status, comment, selectedValue },
    } = suspendedForm
    return {
      statusType: status ? status.value : '',
      comment: comment ? comment : '',
      selectedValue: selectedValue ? selectedValue : '',
    }
  }
  return { statusType: '' }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
