import React, { Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { pure } from 'recompose'
import { Logo } from 'components/atoms/Generic'
import { Main, Content } from 'components/layout'
import List from '@material-ui/core/List'
import 'react-toastify/dist/ReactToastify.css'
import {
  hasAccessToRoute,
  checkRole,
  ROLES,
  checkPathAccess,
  hasAccessToAtLeastOneRoute,
  accountsAllowedRoles,
} from 'helpers/roleBasedAccess'
import { getTheme } from 'theme/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { useAuth } from 'context/auth'
import { themeBroadcastService } from 'broadcastServices/themBroadcastService'
import { bindActionCreators } from 'redux'

import {
  Sidebar,
  Settings,
  MainPart,
  LogoutMenuItem,
} from 'components/atoms/Sidebar/styles'
import config from 'config'
import { clearAccountDetails } from 'redux/accounts/actions'
import authService from 'services/Auth'

import { CurrentUserBlock } from './styles'
import UserMenu from './UserMenu'
import ValrAdminScreen from './ValrAdminScreen'
import ValrAdminWelcome from './ValrAdminWelcome'
import SideTabNavigationMenuItem from './SideTabNavigationMenuItem'
import { sideBarNavigationItems } from './Navigation/sideBarNavigationItems'
import { navigationRoutes } from './Navigation/navigationRoutes'

import { CopyKeyCloakToken } from '../../components/atoms/Sidebar/CopyKeyCloakToken'
import SearchById from '../../components/organisms/SearchById/SearchById'

const RestrictedRoute = ({ pathAccess, ...rest }) => {
  if (!hasAccessToRoute(pathAccess)) return null
  return <Route {...rest} />
}

const Home = ({ auth, history, redirectOnRefresh, clearAccountDetails }) => {
  // Check if they should login whenever the home page updates
  authService.handleShouldLogin()
  const theme = localStorage.getItem('adminTheme')
  const token = localStorage.getItem('admin_access_token')
  const [currentTheme, setCurrentTheme] = useState(theme || 'lightTheme')
  const { handleLogout } = useAuth()

  const handleThemeChange = themeType => {
    localStorage.setItem('adminTheme', themeType)
    setCurrentTheme(themeType)
  }
  const toggleTheme = themeType => {
    themeBroadcastService.broadcastMessageToAllOpenClients({
      theme: themeType,
    })
    handleThemeChange(themeType)
  }
  const {
    location: { pathname },
  } = history

  const handleListItemClick = (event, route) => {
    event.preventDefault()
    if (config.isProduction && route === '/fund/individual') {
      return history.push('/fund/from-fiat-csv')
    }
    history.push(route)
  }

  useEffect(() => {
    if (
      !theme &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      localStorage.setItem('adminTheme', 'darkTheme')
      setCurrentTheme('darkTheme')
    }
  }, [])

  useEffect(() => {
    themeBroadcastService.listen(ev => {
      if (ev.data.theme) {
        handleThemeChange(ev.data.theme)
      }
    })

    return () => {
      themeBroadcastService.disconnect()
    }
  }, [])

  const hasAdminAccess = checkRole('management:user')
  // TODO: Implement authorization on compliance tab
  // const hasAccess = hasAccessToAtLeastOneRoute()
  const hasAccess = true

  if (!hasAdminAccess && token) {
    return (
      <ValrAdminScreen
        title="Welcome to"
        message="You don't have permissions to access the app yet. Please contact support to get you started."
      />
    )
  }

  return (
    <SCThemeProvider theme={getTheme(currentTheme)}>
      <ThemeProvider theme={getTheme(currentTheme)}>
        <Main>
          {!auth.isAuthenticated() ||
            (!hasAccess && <Redirect to="/welcome" />)}
          <ToastContainer autoClose={3000} />
          <Fragment>
            <Sidebar>
              <MainPart>
                <Logo to="/welcome" />
                {checkPathAccess(accountsAllowedRoles) && (
                  <SearchById
                    clearAccountDetails={clearAccountDetails}
                    history={history}
                  />
                )}
                <List orientation="vertical" variant="scrollable">
                  {sideBarNavigationItems.map((navItem, index) => (
                    <SideTabNavigationMenuItem
                      key={index}
                      handleListItemClick={handleListItemClick}
                      pathname={pathname}
                      navItemData={navItem}
                    />
                  ))}
                </List>
              </MainPart>
              <Settings>
                <CopyKeyCloakToken />
                <LogoutMenuItem label="Logout" onClick={handleLogout} />
              </Settings>
            </Sidebar>
            <Content>
              <CurrentUserBlock>
                <UserMenu toggleTheme={toggleTheme} />
              </CurrentUserBlock>
              {!hasAccess && (
                <ValrAdminWelcome message="You don`t seem to have any roles yet. Please contact support." />
              )}
              <Switch>
                {navigationRoutes.map(({ path, pathAccess, Component }, i) => (
                  <RestrictedRoute
                    key={i}
                    path={path}
                    pathAccess={pathAccess}
                    component={Component}
                  />
                ))}
                <Route
                  path="/welcome"
                  component={() => (
                    <ValrAdminWelcome message="Get started by clicking one of the navigation items on the left hand panel." />
                  )}
                />
                <Redirect to={redirectOnRefresh || '/welcome'} />
              </Switch>
            </Content>
          </Fragment>
        </Main>
      </ThemeProvider>
    </SCThemeProvider>
  )
}

const mapStateToProps = state => ({
  redirectOnRefresh: state.routing.redirectOnRefresh,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearAccountDetails,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(pure(withRouter(Home)))
