import React, { Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormLikeContainer, FormTitle, Button, InputGroup } from 'components/atoms/Form'
import { unlinkAuth0Accounts } from 'redux/auth0/actions'
import IconLoading from 'components/atoms/IconLoading/index';
import { 
  Table,
  TableHeader, 
  TableCell,
  TableBody,
  TableRow
} from 'components/atoms/Table/styles';
import { getAuth0Info } from 'redux/selectors/auth0'

class LinkedAccounts extends Component {
  constructor(props) {
    super(props)
    this.unlinkAccounts = this.unlinkAccounts.bind(this)
  }

  unlinkAccounts(index, auth0Id, provider) {
    const { user, accountId } = this.props
    this.props.unlinkAuth0Accounts(accountId, index, user.info.user_id, provider, auth0Id)
  }

  renderTable(list) {
    return (
      <Table>
        <TableHeader>
          <TableCell width={'20%'}>User ID</TableCell>
          <TableCell width={'10%'}>Provider</TableCell>
          <TableCell width={'10%'}>First Name</TableCell>
          <TableCell width={'10%'}>Last Name</TableCell>
          <TableCell flex={1} align={'flex-end'}></TableCell>
        </TableHeader>
        <TableBody>
          {list.map((account, index) => {
            const {
              provider,
              user_id,
              profileData: { given_name, family_name },
              status,
            } = account
            return (
              <TableRow key={index}>
                <TableCell width={'20%'}>{user_id}</TableCell>
                <TableCell width={'10%'}>{provider}</TableCell>
                <TableCell width={'10%'}>{given_name}</TableCell>
                <TableCell width={'10%'}>{family_name}</TableCell>
                <TableCell flex={1} align={'flex-end'}>
                  {status === 'loading' && <IconLoading />}
                  {status !== 'loading' &&
                    <InputGroup justify={'flex-end'}>
                      <Button onClick={() => {this.unlinkAccounts(index + 1, user_id, provider)}}>Unlink</Button>
                    </InputGroup>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  render() {
    const { user: { info }, onCancel } = this.props
    const list = info.identities.filter(identity => info.user_id !== `${identity.provider}|${identity.user_id}`)
    return (
      <FormLikeContainer>
        <FormTitle>Linked Accounts</FormTitle>
        <div>{this.renderTable(list)}</div>      
        <InputGroup justify={'flex-end'}>
          <Button onClick={onCancel}>Close</Button>
        </InputGroup>
      </FormLikeContainer>
    )
  }
}

const mapStateToProps = state => ({
  user: getAuth0Info(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unlinkAuth0Accounts,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedAccounts)