import React from 'react'
import { Document, Page, View, Text, Image } from '@react-pdf/renderer'
import {
  VALR_BALANCE_STATEMENT_DISCLAIMER,
  VALR_LETTERHEAD_BASE64,
} from './const'
import { formatDate, splitRowsIntoPages } from 'helpers/utils'
import { styles } from './Styles'
import { EmptyStatementTable, Footer } from './Shared'

interface AccountStatementProps {
  payload: any
  customerDetails: string
}

interface AccountBalanceTableProps {
  rows: { asset: string; value: string; approxValueInZAR: string }[]
}

const date = formatDate(Date.now())

const AccountBalanceTable: React.FC<AccountBalanceTableProps> = ({ rows }) => (
  <View style={styles.tableContainer}>
    <View style={[styles.tableRow, styles.balTableHeader]}>
      <Text style={styles.balTableCell}>Asset</Text>
      <Text style={styles.balTableCell}>Total Value</Text>
      <Text style={styles.balTableCell}>Approx Value in ZAR</Text>
    </View>
    {rows.length === 0 && <EmptyStatementTable type="balances" />}
    {rows.map((balance, index) => (
      <View key={index} style={styles.tableRow}>
        <Text style={index % 2 === 0 ? styles.balTableCell : styles.balDarkTableCell}>{balance.asset}</Text>
        <Text style={index % 2 === 0 ? styles.balTableCell : styles.balDarkTableCell}>{balance.value}</Text>
        <Text style={index % 2 === 0 ? styles.balTableCell : styles.balDarkTableCell}>{balance.approxValueInZAR}</Text>
      </View>
    ))}
  </View>
)

const AccountStatement: React.FC<AccountStatementProps> = ({
  customerDetails,
  payload,
}) => {
  const { firstPageRows, subsequentPagesData } = splitRowsIntoPages(
    payload,
    16,
    27,
  )

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={VALR_LETTERHEAD_BASE64} style={styles.letterhead} />
        <View>
          <Text style={styles.statementTitle}>Account Balance Statement</Text>
        </View>
        <View>
          <Text style={styles.accountName}>{customerDetails}</Text>
        </View>
        <View style={styles.right}>
          <Text style={styles.date}>Statement Date: {date}</Text>
        </View>
        <View>
          <Text style={styles.statementType}>Account Balance</Text>
        </View>
        <View>
          <AccountBalanceTable rows={firstPageRows} />
        </View>
        <View style={styles.footer} fixed>
          <Footer date={date} disclaimer={VALR_BALANCE_STATEMENT_DISCLAIMER} />
        </View>
      </Page>
      {subsequentPagesData.map((pageRows, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.page}>
          <View>
            <AccountBalanceTable rows={pageRows} />
          </View>
          <View style={styles.footer} fixed>
            <Footer
              date={date}
              disclaimer={VALR_BALANCE_STATEMENT_DISCLAIMER}
            />
          </View>
        </Page>
      ))}
    </Document>
  )
}

export default AccountStatement
