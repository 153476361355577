import { InputGroup, InputLabel } from 'components/atoms/Form'
import React from 'react'

const SelectOuter = ({ children, label }) => (
    <InputGroup
      direction={'column'}
      align={'flex-start'}
      width={'100%'}
      style={{ marginTop: '7px' }}
    >
      <InputLabel>{label}:</InputLabel>
      {children}
    </InputGroup>
  )

  export default SelectOuter