import getAxios from 'helpers/axios'

export const FETCH_STOP_ORDERS_START = 'FETCH_STOP_ORDERS_START'
export const FETCH_STOP_ORDERS_SUCCESS = 'FETCH_STOP_ORDERS_SUCCESS'
export const FETCH_STOP_ORDERS_ERROR = 'FETCH_STOP_ORDERS_ERROR'

export const fetchStopOrders = (currencyPairs) => async dispatch => {

  dispatch({ type: FETCH_STOP_ORDERS_START })
  try {
    const res = await getAxios().get(`/stoporders?currencyPairs=${currencyPairs}`)
    const { data } = res
    const asksAndBids = !res.data.length ? [] : Object.keys(data).map(key => data[key]).flat()

    dispatch({
      type: FETCH_STOP_ORDERS_SUCCESS,
      payload: {
        data: asksAndBids,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_STOP_ORDERS_ERROR,
      payload: {
        error,
      },
    })
  }
}
