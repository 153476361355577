import styled from 'styled-components'

export const CheckboxTextSize = styled.div`
  font-size: 20px;
`

export const Input = styled.input`
  position: relative;
  width: 20px;
  height:  20px;
`

export const FlexboxColumn = styled.div`
  display: flex;
  flex-direction: column;
`
