import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  fetchCryptoWithdrawalsUnderReview,
  updateCryptoWithrawal,
} from 'redux/crypto/actions'
import ReviewCryptoWithdrawalsContainer from 'components/organisms/ReviewCryptoWithdrawals'
import { TitleContainer } from 'components/atoms/Table/styles'
import socket from 'redux/socket/index'
import auth from 'services/Auth'

class CryptoWithdrawals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: '',
      withdrawalsCount: 0,
    }
  }

  componentDidMount() {
    this.props.fetchCryptoWithdrawalsUnderReview()
    socket.on('busy withdrawals', data => {
      this.props.updateCryptoWithrawal(data)
    })

    socket.on('withdrawal status updated', data => {
      this.props.updateCryptoWithrawal(data)
    })

    socket.on('onMount status updated', data => {
      this.props.updateCryptoWithrawal(data)
    })
  }

  componentDidUpdate(prevProps) {
    const { cryptoWithdrawalsUnderReview } = this.props
    const {
      cryptoWithdrawalsUnderReview: prevCryptoWithdrawalsUnderReview,
    } = prevProps

    if (
      cryptoWithdrawalsUnderReview.data !==
      prevCryptoWithdrawalsUnderReview.data
    ) {
      const count = cryptoWithdrawalsUnderReview.data
        ? cryptoWithdrawalsUnderReview.data.length
        : 0
      this.setState({ withdrawalsCount: count })
    }
  }

  removeActiveUser = async withdrawalId => {
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  showActiveuser = async withdrawalId => {
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  setExpand = panel => {
    this.setState({ expanded: panel })
  }

  render() {
    const { cryptoWithdrawalsUnderReview } = this.props
    const { expanded, withdrawalsCount } = this.state
    return (
      <Fragment>
        <TitleContainer>
          {`Total withdrawals: ${withdrawalsCount}`}
        </TitleContainer>
        <Fragment>
          <ReviewCryptoWithdrawalsContainer
            name="CRYPTO WITHDRAWALS UNDER REVIEW"
            withdrawalsUnderReview={cryptoWithdrawalsUnderReview}
            expanded={expanded}
            setExpand={this.setExpand}
          />
        </Fragment>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  cryptoWithdrawalsUnderReview: state.crypto.withdrawalsUnderReview,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCryptoWithdrawalsUnderReview,
      updateCryptoWithrawal,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CryptoWithdrawals)
