import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  Button, FormContainer, FormTitle, InputGroup,
} from 'components/atoms/Form'
import { submit } from './submit'

let Form = props => {
  const { submitting } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)} width={10}>
      <FormTitle>Enable Voice OTP</FormTitle>
      <InputGroup justify={'flex-start'}>
        <Button type="submit" disabled={submitting} margin='0 16px 0 0'>Enable</Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
