import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import socket from 'redux/socket/index'
import { updateFiat } from 'redux/fiat/actions'
import * as moment from 'moment'
import { Table, TableBody } from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Button, InputGroup } from 'components/atoms/Form/index'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { Headers } from './headers'
import Row from './row'

class PendingUsdDepositsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      formShown: '',
      expandRawData: '',
      addNoteFormShown: '',
      highlightedId: '',
    }
    this.toggleView = this.toggleView.bind(this)
  }

  toggleView() {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  showRawData = id => {
    if (id !== this.state.expandRawData) {
      this.setState({ expandRawData: id })
    } else {
      this.setState({ expandRawData: '' })
    }
  }

  toggleForm = id => {
    if (id !== this.state.formShown) {
      this.setState({ formShown: id, expandRawData: '', addNoteFormShown: '' })
    } else {
      this.setState({ formShown: '', expandRawData: '', addNoteFormShown: '' })
    }
  }

  addTotalAmount = deposit => {
    const total = deposit.map(d => d.amount).reduce((accumulator, value) => {
      const checkedCurrentValue = value
        ? parseFloat(
          typeof value === 'string' ? value.split(',').join('') : value,
        )
        : 0
      return accumulator + checkedCurrentValue
    }, 0)
    return `${new BigNumber(total).toFormat(2)} USDC`
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  render() {
    const { status, data } = this.props.deposits
    const hasDeposits = data && data.length > 0
    return (
      <Fragment>
        <ExpandingItem
          title={`DEPOSITS PENDING RELEASE  (${(data && data.length) || 0})`}
          itemsAmount={this.addTotalAmount(data) || 0}
          expanded={this.state.expanded}
          onExpand={this.toggleView}
          opensMultiple
          expandedView={
            <>
              {status !== 'done' && <LoadingBlock />}
              {status === 'done' && !hasDeposits && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.DEPOSITS.type}
                />
              )}
              {status === 'done' && hasDeposits && (
                <Table>
                  <Headers />
                  <TableBody>
                    {data.map(deposit => {
                      deposit.createdAt = moment(deposit.createdAt).format(
                        'YYYY-MMM-DD HH:mm:ss',
                      )
                      return (
                        <Row
                          key={deposit.id}
                          data={deposit}
                          toggleForm={this.toggleForm}
                          formShown={
                            this.state.formShown === deposit.reviewItemId
                          }
                          setHighlightedId={this.setHighlightedId}
                          isHighlighted={
                            this.state.highlightedId === deposit.reviewItemId
                          }
                          expandRawData={this.state.expandRawData}
                          showRawData={this.showRawData}
                        />
                      )
                    })}
                  </TableBody>
                </Table>
              )}
            </>
          }
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  state,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFiat,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingUsdDepositsContainer)
