export const riskLevelCalculator = (score: number) => {
  if (!score) return '--'
  let riskLevel = '--'
  if (score <= 1.9) {
    riskLevel = 'LOW'
  }
  if (score > 1.9 && score <= 2.5) {
    riskLevel = 'MEDIUM'
  }
  if (score > 2.5 && score <= 2.9) {
    riskLevel = 'HIGH'
  }
  if (score > 2.9) {
    riskLevel = 'SEVERE'
  }
  return riskLevel
}
