import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const SET_TRADE_FEES = 'tradeFees/SET_TRADE_FEES'
export const LOADING = 'tradeFees/loading'

export const UPDATE_CURRENCY_PAIR_TRADE_FEES = 'UPDATE_CURRENCY_PAIR_TRADE_FEES'
export const UPDATE_DEFAULT_TRADE_FEES = 'UPDATE_DEFAULT_TRADE_FEES'

export const getTradeFees = () => async dispatch => {
    dispatch({ type: LOADING })
    try {
        const res = await getAxios().get('/tradefees')
        dispatch({ type: SET_TRADE_FEES, value: res.data })
    } catch (e) {
        ErrorToast(e)
        dispatch({ type: SET_TRADE_FEES, value: {} })
    }
}

export const setTradeFees = fees => async dispatch => {
    dispatch({ type: LOADING })

    setTimeout(() => {
        dispatch(getTradeFees())
    }, 500)

    try {
        await getAxios().post(`/tradefees`, fees)
    } catch (e) {
        ErrorToast(e)
    }
}

export const updateCurrencyPairTradeFees = (updatedData) => async dispatch => {
    dispatch({
        type: UPDATE_CURRENCY_PAIR_TRADE_FEES,
        payload: updatedData
    })
}

export const updateDefaultTradeFees = (updatedData) => async dispatch => {
    dispatch({
        type: UPDATE_DEFAULT_TRADE_FEES,
        payload: updatedData
    })
}