import { FETCH_STOP_ORDERS_START, FETCH_STOP_ORDERS_SUCCESS, FETCH_STOP_ORDERS_ERROR } from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOP_ORDERS_START: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_STOP_ORDERS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_STOP_ORDERS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    default:
      return state
  }
}