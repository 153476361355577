export const NATURAL_ENTITY_ROLES = [
  'Director',
  'Shareholder',
  'Ubo',
  'Trader',
  'Beneficiary',
  'Founder',
  'Trustee',
  'Other',
  'Member',
]
export const FILE_TYPES = {
  IMG: 'image',
  PDF: 'application/pdf',
  DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  HEIC: 'image/heic',
}
