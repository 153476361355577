import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { FiatWithdrawalsDashboardStats } from 'interfaces/fiatWithdrawalsDashboardStats'
import { Dispatch } from 'redux'

export type FiatWithdrawalsDashboardStatsActionTypes =
  | 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_START'
  | 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_SUCCESS'
  | 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_ERROR'

export const fetchFiatWithdrawalsDashboardStats =
  () =>
  async (
    dispatch: Dispatch<{
      type: FiatWithdrawalsDashboardStatsActionTypes
      payload?: FiatWithdrawalsDashboardStats
    }>,
  ) => {
    try {
      dispatch({
        type: 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_START',
      })
      const { data } = await getAxios().get('/fiat/dashboard/stats')
      dispatch({
        type: 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_ERROR',
      })
    }
  }
