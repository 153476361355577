import React, { Dispatch, FC, useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { FlexRow, Flexbox } from 'components/layout'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'
import { camelCaseToText } from 'helpers/utils'

import { DatePicker, renderInput } from 'forms/fields'
import { FormTitle } from 'components/atoms/Form'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'

import { RiskProfileFormContainer } from '../../Styles'

import { IndividualEddReportInitialValues } from '../../../interfaces/riskProfile'

interface FormProps {
  initialValues: IndividualEddReportInitialValues
  handleSubmit: (
    cb: (values: any, dispatch: Dispatch<any>) => Promise<void>,
  ) => Promise<void>
  onSubmit: () => Promise<void>
  onCancel: () => void
  reset: () => void

  setIsSaved: Dispatch<boolean>
  setShowExitModal: Dispatch<boolean>
  submitting: boolean
  invalid: boolean
  submitSucceeded: boolean
  exists: boolean
  dirty: boolean
  pristine: boolean
  isSaved: boolean
  showExitModal: boolean
  hasDiff: boolean
  status?: string
}

const Form: FC<FormProps> = (props) => {
  const disableField = (key: keyof IndividualEddReportInitialValues) =>
    key === 'fullName' ||
    // TODO: we will automate this some time
    // key === 'dateOfBirth' ||
    key === 'phoneNumber' ||
    key === 'emailAddress' ||
    key === 'occupationStatus' ||
    key === 'sourceOfFunds' ||
    key === 'purposeOfAccount' ||
    key === 'pepStatus' ||
    key === 'ipLocation' ||
    key === 'passportOrIdNumber' ||
    (key === 'recommendation' && (!props.status || props.status === 'DECLINED'))

  useEffect(() => {
    if (
      (props.exists && !props.dirty && !props.hasDiff) ||
      (props.submitSucceeded && !props.dirty)
    ) {
      props.setIsSaved(true)
    } else {
      props.setIsSaved(false)
    }
    return () => {
      props.setIsSaved(true)
      props.setShowExitModal(false)
    }
  }, [props.dirty, props.submitSucceeded])
  return (
    <RiskProfileFormContainer onSubmit={props.handleSubmit(props.onSubmit)}>
      <FormTitle>Risk Profile EDD Report Information</FormTitle>
      <FlexRow style={{ textAlign: 'center' }}>
        {Object.keys(props.initialValues)
          .filter((key: keyof IndividualEddReportInitialValues) => {
            if (key === 'accountId') {
              return false
            } else if (key === 'status') {
              return false
            } else if (key === 'type') {
              return false
            } else if (key === 'commentId') {
              return false
            } else if (key === 'commentCreatedAt') {
              return false
            } else if (key === 'createdBy') {
              return false
            }
            return true
          })
          .map((key: keyof IndividualEddReportInitialValues) =>
            key === 'dateOfBirth' || key === 'accountOpenedDate' ? (
              <div key={key} style={{ width: '25%' }}>
                <Field
                  style={{ width: '90%', marginBottom: 18, marginTop: 0 }}
                  label={camelCaseToText(key)}
                  name={key}
                  disabled={disableField(key)}
                  component={DatePicker}
                  margin={`0px 2px ${BASE * 2}px 0px`}
                />
              </div>
            ) : (
              <div key={key} style={{ width: '25%' }}>
                <Field
                  width="90%"
                  label={camelCaseToText(key)}
                  name={key}
                  disabled={disableField(key)}
                  multiline
                  component={renderInput}
                  margin={`0px 2px ${BASE * 2}px 0px`}
                />
              </div>
            ),
          )}
      </FlexRow>

      <Flexbox justify={'flex-end'} direction={'row'}>
        <MuiButton
          onClick={() => {
            if (!props.isSaved) {
              props.setShowExitModal(true)
            } else {
              props.onCancel()
            }
          }}
        >
          go back
        </MuiButton>
        <Spacer margin="0px 12px">
          <MuiButton
            type="submit"
            disabled={props.submitting || props.invalid || props.isSaved}
          >
            sav
            {props.submitting ? 'ing' : 'e'}
          </MuiButton>
        </Spacer>
      </Flexbox>
      <ConfirmationDialog
        open={props.showExitModal}
        proceed={() => props.onCancel()}
        title="You have unsaved changes are you sure you want to proceed?"
        message=""
        handleClose={() => props.setShowExitModal(false)}
      />
    </RiskProfileFormContainer>
  )
}

export const RiskProfileEddReportForm = reduxForm({
  form: 'riskProfileEddReportForm',
  onSubmit: (v) => {
    console.log({ v })
  },
  enableReinitialize: true,
})(Form)
