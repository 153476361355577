import { createSelector } from 'reselect'

import { getActiveAccount } from './ui'

export const getCheck = state => state.watchlistCheck.byId

export const getAccountWatchlistCheck = createSelector(
  [getCheck, getActiveAccount],
  (watchlistCheck, id) => {
    if (!id || !watchlistCheck[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }

    return watchlistCheck[id]
  },
)
