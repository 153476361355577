import React from 'react'
import PropTypes from 'prop-types'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import { Input } from 'components/atoms/Form/index'
import ErrorToast from 'components/molecules/ErrorToast/index'

const CurrencyBorrowWeightModal = props => {
  const {
    isOpen,
    updateCurrencyBorrowWeight,
    currencyShortName,
    setEditBorrowWeightModal,
    borrowWeight,
    modalButtonDisabled,
    handleCloseEditBorrowWeightModal,
  } = props
  return (
    <ConfirmationDialog
      open={isOpen}
      title={`Borrow Weight For ${currencyShortName}`}
      message={`Are you sure you want to change the borrow weight for ${currencyShortName}?`}
      proceed={updateCurrencyBorrowWeight}
      handleClose={handleCloseEditBorrowWeightModal}
      disabled={modalButtonDisabled}
      button={{
        confirm: {
          text: 'Proceed',
        },
        decline: {
          text: 'Cancel',
        },
      }}
    >
      <Input
        label="Borrow Weight"
        variant="outlined"
        placeholder="Borrow Weight"
        type="number"
        value={borrowWeight}
        inputProps={{
          step: '.01',
        }}
        onChange={({ target: { value } }) => {
          if (value > 1 || value < 0) return ErrorToast('Borrow weight value can only be between 0 to 1')
          return setEditBorrowWeightModal({
            isOpen: true,
            borrowWeight: value < 0 ? 0 : parseFloat(value),
          })
        }}
      />
    </ConfirmationDialog>
  )
}

CurrencyBorrowWeightModal.propTypes = {}

export default CurrencyBorrowWeightModal
