import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchMarginPlatformSummary } from 'redux/margin/actions'

import { ConfigStyle } from './styles'

class MarginPlatformSummary extends Component {
  componentDidMount() {
    this.props.fetchMarginPlatformSummary()
  }

  render() {
    const {
      PlatformSummary: { status, data, error },
    } = this.props.margin
    if (error) {
      this.props.history.push('/accounts/search')
    }
    return (
      <Fragment>
        <h3>Platform Summary</h3>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' &&
          Object.entries(data).map(([key, value]) => {
            const fixCamelCasing = key.replace(/([A-Z])/g, '$1')
            const fixedCamelCasing =
              fixCamelCasing.charAt(0).toUpperCase() + fixCamelCasing.slice(1)
            return (
              <ConfigStyle key={key}>
                {fixedCamelCasing}:{value}
              </ConfigStyle>
            )
          })}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  margin: state.margin,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMarginPlatformSummary,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarginPlatformSummary)
