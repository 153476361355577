import getAxios from 'helpers/axios'
import { flatten } from 'lodash/array'
import { convertUnixToDate } from 'helpers/fileHelpers'

import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_PENDING_DUAL_AUTH_PROCESS =
  'pendingDualAuth/FETCH_PENDING_DUAL_AUTH_PROCESS'
export const FETCH_PENDING_DUAL_AUTH =
  'pendingInternalTransfers/FETCH_PENDING_DUAL_AUTH'
export const FETCH_PENDING_DUAL_AUTH_ERROR =
  'pendingInternalTransfers/FETCH_PENDING_DUAL_AUTH_ERROR'

export const APPROVE_DUAL_AUTH = 'pendingInternalTransfers/APPROVE_DUAL_AUTH'
export const APPROVE_DUAL_AUTH_ERROR =
  'pendingInternalTransfers/APPROVE_DUAL_AUTH_ERROR'

export const REJECT_DUAL_AUTH = 'pendingInternalTransfers/REJECT_DUAL_AUTH'
export const REJECT_DUAL_AUTH_ERROR =
  'pendingInternalTransfers/REJECT_DUAL_AUTH_ERROR'

export const UPDATE_TIMER = 'pendingInternalTransfers/UPDATE_TIMER'

export const UPDATE_DUAL_AUTH_ROW_USER_START = 'UPDATE_DUAL_AUTH_ROW_USER_START'
export const UPDATE_DUAL_AUTH_ROW_USER_SUCCESS =
  'UPDATE_DUAL_AUTH_ROW_USER_SUCCESS'
export const UPDATE_DUAL_AUTH_ROW_USER_ERROR = 'UPDATE_DUAL_AUTH_ROW_USER_ERROR'

const mapPendingDualAuth = (pendingDualAuth) => {
  switch (pendingDualAuth.actionType) {
    case 'FIAT_DEPOSIT_CREDIT': {
      return {
        ...pendingDualAuth,
        status: pendingDualAuth.deposit.statuses
          .sort((a, b) => new Date(a.insertedAt) < new Date(b.insertedAt))
          .map((s) => `${s.status}`)
          .join(', '),
      }
    }
    case 'ALLOW_ADD_TO_ADDRESS_BOOK':
    case 'ALLOW_GENERATE_RECEIVE_ADDRESS': {
      const { data, createdAt } = pendingDualAuth
      return {
        ...pendingDualAuth,
        data: JSON.parse(data),
        createdAt: convertUnixToDate(createdAt),
      }
    }
    default:
      return pendingDualAuth
  }
}

export const fetchExchangeControlDualAuths = async () => {
  const types = ['ALLOW_ADD_TO_ADDRESS_BOOK', 'ALLOW_GENERATE_RECEIVE_ADDRESS']
  const data = await Promise.all(
    types.map(async (type) => {
      const res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
      return res.data
    }),
  )
  return flatten(data).map(mapPendingDualAuth)
}

export const fetchKycAddressDualAuths = async () => {
  const res = await getAxios().get(
    `/pending/dual-auth/by-type/KYC_ADDRESS_OVERRIDE`,
  )
  return res.data.map((d) => ({
    ...d,
    createdAt: convertUnixToDate(d.createdAt),
  }))
}

const mapData = (list) =>
  list.map((item) => {
    const { data } = item
    const altInfo = data && JSON.parse(data)
    if (altInfo && altInfo.expiryTime) {
      item.createdAt = convertUnixToDate(item.createdAt)
      altInfo.expiryTime = convertUnixToDate(altInfo.expiryTime)
      item.data = altInfo
    }
    return item
  })

export const fetchAccountSecurityOverride = async () => {
  let res = null
  const type = 'ACCOUNT_SECURITY_CHANGE_BLOCK_OVERRIDE'
  res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
  return mapData(res.data)
}

export const fetchPendingDualAuth = () => async (dispatch) => {
  let res = null
  dispatch({
    type: FETCH_PENDING_DUAL_AUTH_PROCESS,
  })
  try {
    res = await getAxios().get('/pending/dual-auth')
    const { data } = res
    const flatArr = data.map(mapPendingDualAuth)
    const securityOverrideDualAuths = await fetchAccountSecurityOverride()
    const exchangeControlDualAuths = await fetchExchangeControlDualAuths()
    const kycAddressDualAuths = await fetchKycAddressDualAuths()
    const results = [
      ...flatArr,
      ...securityOverrideDualAuths,
      ...exchangeControlDualAuths,
      ...kycAddressDualAuths,
    ]
    const sortedResults = results.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    )
    dispatch({
      type: FETCH_PENDING_DUAL_AUTH,
      payload: {
        data: sortedResults,
      },
    })
    return results
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_PENDING_DUAL_AUTH_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateDualAuthRowUserSocketEvent = (auth) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DUAL_AUTH_ROW_USER_START,
    })
    dispatch({
      type: UPDATE_DUAL_AUTH_ROW_USER_SUCCESS,
      payload: {
        data: auth,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_DUAL_AUTH_ROW_USER_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const approveDualAuth = (approveDualAuthId) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_DUAL_AUTH,
      payload: {
        approveDualAuthId,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: APPROVE_DUAL_AUTH_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const rejectDualAuth = (rejectDualAuthId) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_DUAL_AUTH,
      payload: {
        rejectDualAuthId,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: REJECT_DUAL_AUTH_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchPendingDualAuthByType = async (req) => {
  let res = null
  try {
    res = await getAxios().get(`/generic/${encodeURIComponent(req.path)}`)
    const { data } = res
    return data
  } catch (error) {
    ErrorToast(error)
  }
}

export const updateTimer = (flag, callback) => async (dispatch) => {
  let timer = null

  if (flag) {
    timer = setInterval(callback, 30000)
  }
  dispatch({
    type: UPDATE_TIMER,
    payload: {
      flag,
      timer,
    },
  })
}
