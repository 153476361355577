import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import FormPendingDualAuthorisation from 'forms/DualAuth/index'

import auth from 'services/Auth'
import { Link } from '../../AccountIdLink/clickableId'
import dualAuthTableRowEventsEmitter from '../DualAuthEventsEmitter'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitted: false,
    }
  }

  toggleForm = () => {
    this.props.toggleSelected(this.props.fundsInfo.id)
  }

  handleSubmitSuccess = () => {
    this.props.removeSubmittedItem(this.props.fundsInfo.id)
    this.toggleForm()
  }

  render() {
    const {
      fundsInfo,
      isSelected,
      rowLoading,
    } = this.props
    const {
      data: {
        accountId, internalReference, value, currencySymbol,
      },
      id,
      createdAt,
      active,
    } = fundsInfo
    const shading = isSelected ? 'white' : ''
    let formattedAmount
    if (currencySymbol === 'ZAR') {
      formattedAmount = new BigNumber(value).toFormat(2)
    } else {
      formattedAmount = value
    }

    return (
      <Fragment>
        <TableRow
          onClick={() => this.toggleForm()}
          active={isSelected}
          style={{
            color: shading,
          }}
        >
          <TableCell width={'10%'}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell width={'10%'} marginRight={'1%'} align={'right'}>
            {formattedAmount}
          </TableCell>
          <TableCell width={'20%'}>{currencySymbol}</TableCell>
          <TableCell width={'25%'}>{internalReference}</TableCell>
          <TableCell width={'25%'}>{createdAt}</TableCell>
          <TableCell width={'10%'}>
            {active &&
              active.userActive && (
                <div className="tooltip">
                  <i style={{ color: '#B9BBBD' }} className="fas fa-eye" />
                  <span className="tooltiptext">{active.activePersonName}</span>
                </div>
              )}
          </TableCell>
        </TableRow>
        {isSelected && (
          <FormPendingDualAuthorisation
            initialValues={{
              id,
              accountId,
              dualAuthStatus: 'rejected',
              actionType: 'FUNDS',
              flag: false,
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
            }}
            addComment
            form={`PendingDualAuthorisationForm-${id}`}
            removeActiveUser={this.props.removeActiveUser}
            showActiveUser={this.props.showActiveUser}
            onCancel={this.toggleForm}
            onSubmitSuccess={this.handleSubmitSuccess}
            rawData={fundsInfo}
            loading={rowLoading}
            active={active}
            isSubmitted={this.state.isSubmitted}
          />
        )}
      </Fragment>
    )
  }
}

export default dualAuthTableRowEventsEmitter(Row, 'FUNDS_EVENT')
