import getAxios from 'helpers/axios'
import { AccountPendingMultiAuth } from 'interfaces/accountPendingMultiAuths'
import { Dispatch } from 'redux'

export type AccountPendingMultiAuthsAction =
  | 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_START'
  | 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_SUCCESS'
  | 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_ERROR'
  | 'CLEAR_ACCOUNT_PENDING_MULTI_AUTHS'

interface FetchAccountPendingMultiAuthsDispatch {
  type: AccountPendingMultiAuthsAction
  payload?: AccountPendingMultiAuth[]
}
export const fetchAccountPendingMultiAuthsById =
  (id: string) =>
  async (dispatch: Dispatch<FetchAccountPendingMultiAuthsDispatch>) => {
    try {
      dispatch({
        type: 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_START',
      })
      const { data } = await getAxios().get(
        `account/${id}/account-pending-multi-auths`,
      )
      dispatch({
        type: 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_SUCCESS',
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: 'FETCH_ACCOUNT_PENDING_MULTI_AUTHS_ERROR',
      })
    }
  }

export const clearAccountPendingMultiAuthsState =
  () => async (dispatch: Dispatch<FetchAccountPendingMultiAuthsDispatch>) => {
    dispatch({
      type: 'CLEAR_ACCOUNT_PENDING_MULTI_AUTHS',
    })
  }
