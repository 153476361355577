import {
  UPDATE_FUNDS_DUAL_AUTH_ROW_USER_ERROR,
  UPDATE_FUNDS_DUAL_AUTH_ROW_USER_START,
  UPDATE_FUNDS_DUAL_AUTH_ROW_USER_SUCCESS,
} from './actions'

export const FUND_ACCOUNT_PROCESS = 'FUND_ACCOUNT_PROCESS'
export const FUND_ACCOUNT_SUCCESS = 'FUND_ACCOUNT_SUCCESS'
export const FUND_ACCOUNT_SUCCESS_ERROR = 'FUND_ACCOUNT_SUCCESS_ERROR'
export const REMOVE_SUBMITTED_ITEM = 'REMOVE_SUBMITTED_ITEM'

const initialState = {
  fetchFundsStatus: 'pristine',
  fetchFundsData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FUND_ACCOUNT_PROCESS: {
      return {
        ...state,
        fetchFundsStatus: 'loading',
      }
    }

    case FUND_ACCOUNT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        fetchFundsStatus: 'done',
        fetchFundsData: data,
      }
    }

    case FUND_ACCOUNT_SUCCESS_ERROR: {
      return {
        ...state,
        fetchFundsData: [],
      }
    }

    case UPDATE_FUNDS_DUAL_AUTH_ROW_USER_START: {
      return {
        ...state,
        fetchFundsStatus: 'pending',
      }
    }

    case UPDATE_FUNDS_DUAL_AUTH_ROW_USER_SUCCESS: {
      const { data } = action.payload

      const updatedFundsData =
        state.fetchFundsData &&
        state.fetchFundsData.map((fundsDualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (
                newUserOn.id === fundsDualAuth.id &&
                newUserOn.userActive === true
              ) {
                fundsDualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === fundsDualAuth.id &&
                      userOnLine.userActive,
                  ).userName,
                }
              } else {
                fundsDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            fundsDualAuth.active = { userActive: false, activePersonName: '' }
          }
          return fundsDualAuth
        })

      return {
        ...state,
        fetchFundsStatus: 'done',
        fetchFundsData: updatedFundsData,
      }
    }

    case UPDATE_FUNDS_DUAL_AUTH_ROW_USER_ERROR: {
      return {
        ...state,
        fetchFundsStatus: 'pristine',
      }
    }

    case REMOVE_SUBMITTED_ITEM: {
      const filteredArray = state.fetchFundsData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        fetchFundsData: filteredArray,
      }
    }

    default:
      return state
  }
}
