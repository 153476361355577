import React, { Component, Fragment } from 'react'
import {
  Details,
  DetailsBlockWithTitle,
  DetailsBlock,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import { BASE } from 'theme/base-units'
import { ActionButton } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import { oppositeColor } from 'helpers/utils'

class KeycloakInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      otherAccountsShown: false,
      linkedAccountsShown: false,
    }

    this.toggleOtherAccountsView = this.toggleOtherAccountsView.bind(this)
    this.toggleLinkedAccountsView = this.toggleLinkedAccountsView.bind(this)
  }

  toggleOtherAccountsView() {
    this.setState({
      otherAccountsShown: !this.state.otherAccountsShown,
    })
  }

  toggleLinkedAccountsView() {
    this.setState({
      linkedAccountsShown: !this.state.linkedAccountsShown,
    })
  }

  render() {
    const { data, id } = this.props
    const { status, info } = data
    const { otherAccountsShown, linkedAccountsShown } = this.state
    return (
      <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
        <DetailsBlockWithTitle title={'Keycloak Info'} flow={'column'}>
          {!info && status !== 'loading' && <p>No Keycloak Info</p>}
          {status === 'loading' && <IconLoading />}
          {status === 'error' && (
            <InfoBlock
              label={'Fetching Error'}
              value={`Code ${info.error.errorCode}: ${info.error.message}`}
            />
          )}
          {status === 'done' && (
            <Fragment>
              <DetailsBlock flow={'row'}>
                <InfoBlock label={'User Id'} value={info.id || '--'} />
                <InfoBlock label={'User Name'} value={info.username || '--'} />
                <InfoBlock
                  label={'Provider'}
                  value={
                    (info.identities && info.identities[0].provider) || '--'
                  }
                />
                <InfoBlock label={'Email'} value={info.email || '--'} />
                <InfoBlock
                  label={'Created At'}
                  value={
                    moment(info.createdTimestamp).format(
                      'YYYY-MMM-DD HH:mm:ss',
                    ) || '--'
                  }
                />
                {/* <InfoBlock label={'Last Login'} value={moment(info.last_login).format('YYYY-MMM-DD HH:mm:ss') || '--'} /> */}
                <InfoBlock
                  label={'Social Accounts'}
                  value={
                    (info.federatedIdentities &&
                      info.federatedIdentities.length) ||
                    '--'
                  }
                />
                <InfoBlock
                  label={'Status'}
                  color={oppositeColor(info.enabled)}
                  value={
                    (status === 'done' && !info.enabled
                      ? 'blocked'
                      : 'not blocked') || '--'
                  }
                />
              </DetailsBlock>
              <DetailsBlock flow={'column'}>
                <h3>Social accounts</h3>
                {info.federatedIdentities && info.federatedIdentities.length ? (
                  info.federatedIdentities.map(identity => {
                    return (
                      <DetailsBlock key={identity.userId}>
                        <InfoBlock
                          label="provider"
                          value={identity.identityProvider}
                        />
                        <InfoBlock label="user id" value={identity.userId} />
                        <InfoBlock label="username" value={identity.userName} />
                      </DetailsBlock>
                    )
                  })
                ) : (
                  <p style={{ fontWeight: 'bold', marginTop: 0 }}>N/A</p>
                )}
              </DetailsBlock>
              <JsonBlock src={info} title={'Raw Data'} />
            </Fragment>
          )}
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}

export default KeycloakInfo

//{identityProvider: "facebook", userId: "10157426223277321", userName: "tbohnen.jnr@gmail.com"}
//1: {identityProvider: "google", userId: "117455017103552605275", userName: "tbohnen.jnr@gmail.com"}
