import * as moment from 'moment'

import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const UPDATE_DUAL_AUTH_BY_TYPE_SUCCESS= 'UPDATE_DUAL_AUTH_BY_TYPE_SUCCESS'
export const UPDATE_DUAL_AUTH_BY_TYPE_ERROR='UPDATE_DUAL_AUTH_BY_TYPE_ERROR'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

 const fetchDualAuthByType = (type) => async dispatch => {
  let res = null

  dispatch({
    type: `${type}_PROCESS`,
  })
  try {
    res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const {
      data,
    } = res

    const formattedData = data.map(item => {
      item.data = JSON.parse(item.data)
      item.createdAt = convertUnixToDate(item.createdAt)
      return item
    })

    dispatch({
      type: `${type}_SUCCESS`,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: `${type}_SUCCESS_ERROR`,
      payload: {
        error: error.response,
      },
    })
  }
}

export default fetchDualAuthByType
