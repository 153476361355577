import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_ALL_DEPOSITS_COMMENTS_PROCESS = 'FETCH_ALL_DEPOSITS_COMMENTS_PROCESS'
export const FETCH_ALL_DEPOSITS_COMMENTS_SUCCESS = 'FETCH_ALL_DEPOSITS_COMMENTS_SUCCESS'
export const FETCH_ALL_DEPOSITS_COMMENTS_ERROR = 'FETCH_ALL_DEPOSITS_COMMENTS_ERROR'

export const fetchDepositComments = async (id) => {
  let res = null
  try {
    res = await getAxios().get(`/deposit/comments/${id}`)
    const data = res.data.sort((a, b) => new Date(b.date) - new Date(a.date))
    return data
  } catch (error) {
    ErrorToast(error)
    return error.response
  }
}
