import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MarketingContactRunsView from './MarketingContactsView'
import { fetchMarketingContactRunsList } from 'redux/marketing-contact-updates/actions'

const mapStateToProps = state => ({
  marketingContactListRunResults: state.marketingContactListRunResults.marketingContactListRunResults,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMarketingContactRunsList,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarketingContactRunsView)
