import React, { Component } from 'react'
import * as moment from 'moment'
import { RawDataGrid } from 'components/atoms/Table/styles'
import { Flexbox } from 'components/layout/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { StyledRawDataKey, StyledRawDataValue } from './Styles'

class RawData extends Component {
  flattenObject = object => {
    var toReturn = {}
    for (var i in object) {
      if (!object.hasOwnProperty(i)) continue
      if (typeof object[i] == 'object' && object[i] !== null) {
        var flatObject = this.flattenObject(object[i])
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue
          toReturn[x] = flatObject[x]
        }
      } else if (typeof object[i] === 'boolean') {
        toReturn[i] = object[i].toString()
      } else {
        toReturn[i] = object[i]
      }
    }

    return toReturn
  }

  titleCase = str => {
    let spacedCamel = str
      .replace(/\W+/g, ' ')
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
    spacedCamel = spacedCamel.toLowerCase()
    spacedCamel =
      spacedCamel.substring(0, 1).toUpperCase() +
      spacedCamel.substring(1, spacedCamel.length)
    return spacedCamel
  }

  momentFormat = dateTime => {
    return moment(dateTime).format('YYYY-MMM-DD HH:mm:ss')
  }

  render() {
    const data = this.flattenObject(this.props.data)
    const keys = Object.keys(data)
    const { loading } = this.props
    if (loading) {
      return (
        <LoadingBlock />
      )
    }

    return (
      <div>
        <RawDataGrid>
          {keys.map(key => (
            <Flexbox direction={'row'} justify={'flex-start'} key={key}>
              <div style={{ width: '200px' }}>
                <StyledRawDataKey>
                  {this.titleCase(key)}
                </StyledRawDataKey>
              </div>
              {key === 'insertedAt' ||
                key === 'createdAt' ||
                key === 'updatedAt' ||
                key === 'time' ||
                key === 'confirmedAt' ? (
                <StyledRawDataValue>{this.momentFormat(data[key])}</StyledRawDataValue>
              ) : (
                <StyledRawDataValue>{data[key]}</StyledRawDataValue>
              )}
            </Flexbox>
          ))}
        </RawDataGrid>
      </div>
    )
  }
}


export default RawData
