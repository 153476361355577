import keycloak from 'keycloak-js'

import { clearRefreshRedirect } from 'redux/routing/actions'

import config from '../config'
import { disconnectSocket } from '../redux/fiat/actions'
import store, { history } from '../store'

const jsonwebtoken = require('jsonwebtoken')

class Keycloak {
  constructor(onSession) {
    this.onSession = onSession
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.refreshToken = this.refreshToken.bind(this)
    this.updateToken = this.updateToken.bind(this)
    this.init = this.init.bind(this)
    this.keycloak = new keycloak(config.keycloak)
  }

  async init() {
    try {
      console.log('Init for keycloak...')
      this.keycloak.onTokenExpired = this.refreshToken
      this.keycloak.onAuthRefreshSuccess = this.updateToken
      const authenticated = await this.keycloak.init({
        onLoad: 'check-sso',
        promiseType: 'native',
        redirectUri: config.keycloak.callbackUrl,
      })

      console.log(authenticated ? 'authenticated' : 'not authenticated')
      if (!authenticated || !this.onSession) {
        return
      }

      this.onSession({ token: this.keycloak.token })
    } catch (e) {
      this.logout()
      console.error(e)
    }
  }

  async login() {
    this.keycloak.login()
  }

  async handleAuthentication() {
    try {
      console.log('auth:', 'handling authentication')
      if (this.hasExpired()) {
        console.log('auth:', 'logging out due to expired token')
        this.logout()
      }

      if (!this.keycloak || !localStorage.getItem('admin_access_token')) {
        console.log('auth:', 'not authenticated. attempting to log in')
        return this.login()
      }

      console.log('auth user:', 'user is logged in')

      // dispatch an action to trigger application init logic
      store.dispatch({
        type: 'USER_AUTHENTICATED',
      })

      localStorage.setItem('admin_id_token', this.keycloak.idToken)
      localStorage.setItem('admin_refresh_token', this.keycloak.refreshToken)
      localStorage.setItem('admin_access_token', this.keycloak.token)
      localStorage.setItem(
        'admin_token_expires_at',
        this.keycloak.tokenParsed.exp * 1000,
      )
      if (this.onSession) {
        await this.onSession({ token: this.keycloak.token })
      }
      history.replace('/')
    } catch (error) {
      this.logout()
      console.log(error)
    }
  }

  async logout() {
    disconnectSocket()
    console.log('auth: removing tokens...')
    localStorage.removeItem('admin_access_token')
    localStorage.removeItem('admin_id_token')
    localStorage.removeItem('admin_refresh_token')
    localStorage.removeItem('admin_token_expires_at')
    console.log('auth: clearing local state...')
    console.log('auth: initiating logout...')
    await this.keycloak.logout({
      redirectUri: `${config.logoutCallbackUri}`,
    })
    store.dispatch(clearRefreshRedirect())
  }

  handleShouldLogin() {
    if (
      !(this.keycloak && this.keycloak.authenticated) ||
      !localStorage.getItem('admin_access_token')
    ) {
      alert('Session expired. Please login.')
      return this.logout()
    }
    if (this.hasExpired()) {
      return this.logout()
    }
  }

  isAuthenticated() {
    return this.keycloak && this.keycloak.authenticated
  }

  hasExpired() {
    const accessToken = localStorage.getItem('admin_access_token')
    if (!accessToken) {
      console.log('auth:', 'no admin access token detected')
      return true
    }

    const decodedString = jsonwebtoken.decode(accessToken)
    if (!decodedString) return true
    const expiresAtString = decodedString.exp * 1000
    const expiresAt = JSON.parse(expiresAtString)

    const isExpired = new Date().getTime() >= expiresAt
    if (isExpired) {
      console.log('auth:', 'token has expired')
      alert('Your Session has expired')
    }
    return isExpired
  }

  refreshToken() {
    console.log('auth: refreshing token')
    this.keycloak
      .updateToken(10)
      .then(refreshed => {
        if (refreshed) {
          console.log('auth: successfully refreshed token')
        } else {
          console.log('auth: not refreshing token, still valid')
        }
      })
      .catch(console.error)
  }

  updateToken() {
    localStorage.setItem('admin_id_token', this.keycloak.idToken)
    localStorage.setItem('admin_refresh_token', this.keycloak.refreshToken)
    localStorage.setItem('admin_access_token', this.keycloak.token)
    if (this.onSession) {
      this.onSession({ token: this.keycloak.token })
    }
  }

  decodeToken = () => {
    const accessToken = localStorage.getItem('admin_access_token')
    const decoded = jsonwebtoken.decode(accessToken)
    return decoded.sub
  }

  getEmailFromToken = () => {
    const accessToken = localStorage.getItem('admin_access_token')
    const decoded = jsonwebtoken.decode(accessToken)
    return (decoded && decoded.email) || ''
  }

  decodeNameToken = () => {
    const accessToken = localStorage.getItem('admin_access_token')
    const decoded = jsonwebtoken.decode(accessToken)
    return (decoded && decoded.given_name) || ''
  }

  decodeFulNameFromToken = () => {
    const accessToken = localStorage.getItem('admin_access_token')
    const decoded = jsonwebtoken.decode(accessToken)
    return decoded.name
  }
}

const authService = new Keycloak(({ token }) => {
  localStorage.setItem('admin_access_token', token)
})

export default authService
