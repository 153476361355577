import { hasAccessToRoute } from 'helpers/roleBasedAccess'
import React, { FC } from 'react'
import { ListItemText } from '@material-ui/core'

import { SideTabNavigationMenuItemProps } from './interfaces'

import { ListItem } from '../styles'

export const NestedNavMenuItem: FC<SideTabNavigationMenuItemProps> = ({ navItemData, handleListItemClick, pathname }) => {
  const {
    routeAccess, route, selectedPathName, listItemText,
  } = navItemData
  return hasAccessToRoute(routeAccess) ? <ListItem
    button
    selected={pathname.includes(selectedPathName)}
    onClick={(event) => handleListItemClick(event, route)}
    component='a'
    href={route}
  >
    <ListItemText primary={listItemText} />
  </ListItem> : null
}
