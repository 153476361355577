import { TIME_OUT_TRANSITION } from 'helpers/const'
import { capitalizeFirstLetter } from 'helpers/utils'
import React, { useEffect, useState } from 'react'
import {
  SwitcherContainer,
  Dot,
  Label,
  StyledSwitcherWrapper,
  StyledSwitcherTitle,
} from './styles'
import MuiSwitcherWithTitle from '../MuiSwitcherWithTitle'

const Switcher = React.memo(
  ({ onClick, type, title, active, height, width, ...props }: any) => {
    const switchType = type && type.split('/')
    let switchLabel = {
      on: 'on',
      off: 'off',
    }
    if (
      (switchType && switchType.length < 0) ||
      (switchType && switchType.length < 2) ||
      (switchType && switchType.length > 2)
    ) {
      console.error(
        "Please make sure to only have two options seperated by a '/' e.g 'on/off' ",
      )
    } else if (switchType && switchType.length === 2) {
      switchLabel = {
        on: capitalizeFirstLetter(switchType[0] || 'on'),
        off: capitalizeFirstLetter(switchType[1] || 'off'),
      }
    }

    return (
      <StyledSwitcherWrapper width={width} show={props.show}>
        <MuiSwitcherWithTitle
          checked={active}
          onChange={onClick}
          text={title}
        />
      </StyledSwitcherWrapper>
    )
  },
)

export default Switcher
