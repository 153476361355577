import { SanctionedCorporate } from 'interfaces/sanctions'
import { Reducer } from 'redux'
import { CorporateSanctionActionTypes } from './actions'

interface InitialState extends SanctionedCorporate {
  isLoading: boolean
  isUpdating: boolean
}

interface Action {
  type: CorporateSanctionActionTypes
  payload?: SanctionedCorporate
}

const initialState: InitialState = {
  id: '',
  account_id: '',
  approval_status: '',
  check_comment: '',
  created_at: '',
  modified_at: '',
  isLoading: false,
  isUpdating: false,
  name: '',
  reason: {
    corporateNameMatch: '',
    foundEntities: [],
    matchedToSanctionsItems: [],
  },
  status: '',
}

const reducer: Reducer<InitialState, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_CORPORATE_SANCTION_RESULTS_START':
      return {
        ...state,
        isLoading: true,
      }
    case 'FETCH_CORPORATE_SANCTION_RESULTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case 'FETCH_CORPORATE_SANCTION_RESULTS_ERROR':
      return {
        ...state,
        isLoading: false,
      }
    case 'ACKNOWLEDGE_CORPORATE_SANCTION_START':
      return {
        ...state,
        isUpdating: true,
      }
    case 'ACKNOWLEDGE_CORPORATE_SANCTION_SUCCESS':
      return {
        ...state,
        isUpdating: false,
        ...action.payload,
      }
    case 'ACKNOWLEDGE_CORPORATE_SANCTION_ERROR':
      return {
        ...state,
        isUpdating: false,
      }
    case 'CLEAR_CORPORATE_SANCTION_DATA':
      return initialState

    default:
      return state
  }
}

export default reducer
