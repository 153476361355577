import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionButton } from 'components/atoms/Table/styles'
import {
  fetchMarginRequirements,
} from 'redux/marginSettings/actions'
import SaveMarginRequirementsForm from './SaveMarginRequirementsForm'
import SimpleModal from '../Modal'

class UpdateMarginRequirements extends Component {
  constructor(props) {
    super(props)
    this.state = {
      marginRequirements: {},
      open: false,
    }
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  updateRequirements = async () => {
    this.setState({ open: true })
  }

  render() {
    const { open } = this.state
    const { data: marginRequirements } = this.props
    const formattedMarginRequirements =
      Object.keys(marginRequirements).length > 0 &&
      Object.entries(marginRequirements).reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
      }, {
        leverageMultiple: '',
        initialMarginFraction: '',
        maintenanceMarginMultiple: '',
        maintenanceMarginFraction: '',
        autoCloseMarginMultiple: '',
        autoCloseMarginFraction: '',
        referenceCurrency: '',
      })


    return (
      <>
        <ActionButton
          ml={'90%'}
          width={'8%'}
          onClick={this.updateRequirements}
        >
          Update
        </ActionButton>
        <SimpleModal width={'50%'} title="Margin Requirements" handleClose={this.closeDialog} open={open} buttonsHidden >
          <SaveMarginRequirementsForm
            onSubmitSuccess={this.closeDialog}
            onCancel={this.closeDialog}
            initialValues={{
              leverageMultiple: formattedMarginRequirements.leverageMultiple,
              initialMarginFraction: formattedMarginRequirements.initialMarginFraction,
              maintenanceMarginMultiple: formattedMarginRequirements.maintenanceMarginMultiple,
              maintenanceMarginFraction: formattedMarginRequirements.maintenanceMarginFraction,
              autoCloseMarginMultiple: formattedMarginRequirements.autoCloseMarginMultiple,
              autoCloseMarginFraction: formattedMarginRequirements.autoCloseMarginFraction,
              referenceCurrency: formattedMarginRequirements.referenceCurrency,
            }} />
        </SimpleModal>
      </>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchMarginRequirements,
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(UpdateMarginRequirements)
