import React, { Fragment, Component } from 'react'
import AddressBookForm from 'forms/RemoveWithdrawalAddressBook/index'
import SimpleModal from 'components/organisms/Modal'
import auth from 'services/Auth'
import MuiButton from 'components/atoms/Buttons/MuiButton'

class RemoveWithdrawalAddressBook extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState(state => ({
      ...state,
      formShown: !state.formShown,
    }))
  }

  render() {
    const { formShown } = this.state
    const { accountId } = this.props
    return (
      <Fragment>
        <MuiButton style={{ marginRight: '1em' }} onClick={this.toggleForm} >Remove Withdrawal To Address Book Only</MuiButton>
        {formShown &&
          <SimpleModal title='Remove Withdrawal To Address Book Only' buttonsHidden handleClose={this.toggleForm} open={formShown}>
            <AddressBookForm
              initialValues={{
                accountId,
                comment: '',
                data: { date: new Date(), createdBy: auth.decodeNameToken() },
                actionType: 'DISABLE_ADDRESS_BOOK_ONLY_WITHDRAWALS',
                dualAuthStatus: 'pending',
              }}
              form={'remove-withdrawal-address-book'}
              onSubmitSuccess={this.toggleForm}
              onCancel={this.toggleForm}
            />
          </SimpleModal>
        }
      </Fragment>
    )
  }
}

export default RemoveWithdrawalAddressBook
