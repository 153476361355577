import React, { FC } from 'react'
import { AVAILABLE_FEATURE_FLAGS } from 'types/featureFlags'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

interface FeatureFlagBoundaryProps {
  flag: AVAILABLE_FEATURE_FLAGS
  render?: React.ReactElement
  children: React.ReactElement
  hideMessage?: boolean
}

const FeatureFlagBoundary: FC<FeatureFlagBoundaryProps> = ({
  flag,
  render,
  children,
  hideMessage = false,
}) => {
  const flagIsOn = useFeatureFlagCheck(flag)
  if (!flagIsOn) {
    if (render) return <>{render}</>
    return hideMessage ? null : <h3>Feature currently under maintenance!</h3>
  }

  return <>{children}</>
}

export default FeatureFlagBoundary
