import React, { useEffect } from 'react'
import { renderDropdown, renderInput } from 'forms/fields'
import { Field } from 'redux-form'

export const EditableDropdown = ({
  condition,
  name,
  selectFieldDropDownName,
  label,
  validate = [],
  options,
  justify = 'space-around',
  margin = '0px ${BASE * 2}px 0px 0px',
  width = '100%',
  selectedValue,
  comment,
  change,
}) => {
  useEffect(() => {
    if (selectedValue && selectedValue) {
      change(name, selectedValue ? selectedValue : '')
    }
  }, [selectedValue])

  useEffect(() => {
    change(selectFieldDropDownName, '')
  }, [comment])
  return (
    <>
      {condition && (
        <Field
          label={label}
          name={name}
          width={width}
          validate={validate}
          component={renderInput}
          justify={justify}
          margin={margin}
        />
      )}

      {!condition && (
        <Field
          label={label}
          name={selectFieldDropDownName}
          width={width}
          validate={validate}
          component={renderDropdown}
          options={options}
          justify={justify}
          margin={margin}
        />
      )}
    </>
  )
}
