import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'components/atoms/IconButton/index'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  LEGAL_ENTITY_TYPES,
  LEGAL_ENTITY_DOCUMENT_TYPES,
  LEGAL_TRUST_ENTITY_DOCUMENT_TYPES,
  LEGAL_OTHER_ENTITY_DOCUMENT_TYPES,
} from 'helpers/const'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import {
  deactivateLegalEntityForCorporate,
  handleUploadEntityDocumentWithProgress,
  removeEntityDocument,
} from 'redux/corporateEntities/actions'
import { connect } from 'react-redux'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import {
  StyledCorporateUserTableData,
  StyledCorporateUserTableDataActions,
} from '../../Styles'
import EntityDocumentSection from '../EntityDocumentSection'
import DocumentsViewer from '../../DocumentsViewer'

const LegalEntity = props => {
  const {
    entity,
    handleEditEntity,
    handleUploadEntityDocumentWithProgress,
    deactivateLegalEntityForCorporate,
    isCheckingCorporateEditorRole,
    canEditCorporateInformation,
    accountId,
  } = props
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)
  const [viewDocument, setViewDocument] = useState(false)
  const [documentsDataBeingViewed, setDocumentsDataBeingViewed] = useState([])
  const [showDeactivateEntityModal, setShowDeactivateEntityModal] = useState(
    false,
  )
  const [isDeactivatingEntity, setIsDeactivatingEntity] = useState(false)
  const unmounted = useRef(false)

  const actionDisabled =
    !canEditCorporateInformation || isCheckingCorporateEditorRole

  const handleCloseDocumentViewer = () => {
    setViewDocument(false)
    setDocumentsDataBeingViewed([])
  }

  const clearDocumentViewer = () => {
    setDocumentsDataBeingViewed([])
    setIsLoadingDocuments(false)
    setViewDocument(false)
  }

  const handleViewDocuments = async (entityId, documentType) => {
    try {
      setIsLoadingDocuments(true)
      setViewDocument(true)
      const documentsTypeToView = entity.documents.filter(
        document => document.documentType === documentType,
      )
      const documentsInfo = await Promise.all(
        documentsTypeToView.map(async document => {
          const {
            data: { file, fileType, documentName },
          } = await getAxios().get(
            `/corporates/document/${entityId}/${document.id}`,
          )
          return {
            id: document.id,
            file,
            documentType,
            createdAt: document.createdAt,
            documentName,
            mimeType: fileType,
            ...(document.customDocumentType && {
              customDocumentType: document.customDocumentType,
            }),
          }
        }),
      )
      setDocumentsDataBeingViewed(documentsInfo)
      setIsLoadingDocuments(false)
    } catch (error) {
      toast(
        `${error.response.data.message ||
        'An error occurred when fetching the document. If the problem persists please contact support'}`,
        { type: toast.TYPE.ERROR, autoClose: 3000 },
      )
      clearDocumentViewer()
    }
  }

  const handleDeactivateEntityForCorporate = async (entityId, accountId) => {
    setIsDeactivatingEntity(true)
    await deactivateLegalEntityForCorporate(entityId, accountId)
    if (unmounted.current) return
    setShowDeactivateEntityModal(false)
    setIsDeactivatingEntity(false)
    setDocumentsDataBeingViewed([])
  }

  const showEntityDocumentType = documentType => {
    const entityDocuments =
      entity.documents &&
      entity.documents.find(doc => doc.documentType.includes(documentType))
    return (
      <EntityDocumentSection
        entityType={entity.entityType}
        canEditCorporateInformation={canEditCorporateInformation}
        handleUploadEntityDocumentWithProgress={
          handleUploadEntityDocumentWithProgress
        }
        handleViewDocuments={handleViewDocuments}
        isCheckingCorporateEditorRole={isCheckingCorporateEditorRole}
        key={documentType}
        accountId={accountId}
        documentType={documentType}
        documents={entityDocuments || []}
        entityId={entity.id}
      />
    )
  }

  const documentTypesMap = {
    [LEGAL_ENTITY_TYPES.PTY]: Object.values(LEGAL_ENTITY_DOCUMENT_TYPES),
    [LEGAL_ENTITY_TYPES.TRUST]: Object.values(
      LEGAL_TRUST_ENTITY_DOCUMENT_TYPES,
    ),
    [LEGAL_ENTITY_TYPES.OTHER]: Object.values(
      LEGAL_OTHER_ENTITY_DOCUMENT_TYPES,
    ),
  }

  const documentTypes = documentTypesMap[entity.entityType]

  useEffect(() => () => {
    unmounted.current = true
  }, [])

  return (
    <tr>
      <StyledCorporateUserTableData>{entity.name}</StyledCorporateUserTableData>
      <StyledCorporateUserTableData>
        {entity.registrationNumber}
      </StyledCorporateUserTableData>
      {documentTypes.map(documentType => {
        return showEntityDocumentType(documentType)
      })}
      <StyledCorporateUserTableDataActions>
        <IconButton
          disabled={actionDisabled}
          onClick={() => handleEditEntity(entity)}
          icon={<EditIcon />}
        />
        <IconButton
          disabled={
            !canEditCorporateInformation ||
            isCheckingCorporateEditorRole ||
            isDeactivatingEntity
          }
          onClick={() => setShowDeactivateEntityModal(true)}
          icon={<DeleteIcon />}
        />
      </StyledCorporateUserTableDataActions>
      <ConfirmationDialog
        title="Remove entity from corporate"
        open={showDeactivateEntityModal}
        proceed={() => handleDeactivateEntityForCorporate(entity.id, accountId)}
        message={`Are you sure you want to remove the LEGAL ${entity.entityType
          } entity named ${entity.name.toUpperCase()}?`}
        disabled={isDeactivatingEntity}
        handleClose={() => setShowDeactivateEntityModal(false)}
      />
      {!entity.linkedAccountId && (
        <DocumentsViewer
          entityId={entity.id}
          isLoadingDocuments={isLoadingDocuments}
          isOpen={viewDocument}
          documentsDataBeingViewed={documentsDataBeingViewed}
          handleCloseDocumentViewer={handleCloseDocumentViewer}
          accountId={accountId}
          removeDocument={props.removeEntityDocument}
          setDocumentsDataBeingViewed={setDocumentsDataBeingViewed}
        />
      )}
    </tr>
  )
}

LegalEntity.propTypes = {
  entity: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      registrationNumber: PropTypes.number,
      entityType: PropTypes.oneOf([LEGAL_ENTITY_TYPES.PTY]),
      documents: PropTypes.array,
      uploadState: PropTypes.shape({
        ptyRegistrationDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
      }),
    }),
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      registrationNumber: PropTypes.number,
      entityType: PropTypes.oneOf([LEGAL_ENTITY_TYPES.TRUST]),
      letterOfAuthorityDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      registrationDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      uploadState: PropTypes.shape({
        trustRegistrationDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
        trustLetterOfAuthorityDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
      }),
    }),
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      registrationNumber: PropTypes.number,
      entityType: PropTypes.oneOf([LEGAL_ENTITY_TYPES.OTHER]),
      letterOfAuthorityDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      registrationDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      otherDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      uploadState: PropTypes.shape({
        otherRegistrationDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
        otherLetterOfAuthorityDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
        otherDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
      }),
    }),
  ]),
  handleEditEntity: PropTypes.func,
  entityDataOrder: PropTypes.arrayOf(PropTypes.string),
  handleUploadEntityDocumentWithProgress: PropTypes.func,
}

const mapStateToProps = state => {
  const {
    canEditCorporateInformation,
    isCheckingCorporateEditorRole,
  } = state.corporate
  return {
    canEditCorporateInformation,
    isCheckingCorporateEditorRole,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleUploadEntityDocumentWithProgress,
      deactivateLegalEntityForCorporate,
      removeEntityDocument,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(LegalEntity)
