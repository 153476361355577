import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchCorporateSanctionInformation,
  clearFoundSanctionedCorporate,
} from 'redux/sanctionedCorporate/actions'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { fetchMarginTradeFiatStatus } from 'redux/accountProfileSettings/actions'

import { fetchAccountDetails } from 'redux/accounts/actions'
import { fetchKycLevel, fetchKycInfo } from 'redux/kyc/actions'
import { fetchAccountAddress } from 'redux/address/actions'
import { fetchActivationStatus } from 'redux/activationStatus/actions'
import { fetchCustomerType } from 'redux/customerType/actions'
import { fetchWatchlistCheckById } from 'redux/singleAccountSanctions/actions'
import { fetchAccountRiskStatus } from 'redux/marginStatus/actions'
import { getActivationStatus } from 'redux/selectors/status'
import { getAccountAddress } from 'redux/selectors/address'
import { getAccountKYC, getAccountKYCInfo } from 'redux/selectors/kyc'
import { getAccountDetails } from 'redux/selectors/details'
import { getAuth0Info } from 'redux/selectors/auth0'
import { getKeycloakInfo } from 'redux/selectors/keycloak'
import { getCustomerType } from 'redux/selectors/type'
import { getAccountWatchlistCheck } from 'redux/selectors/sanctionsWatchlist'
import { getAccountMarginStatus } from 'redux/selectors/marginStatus'
import { Splitter, ContentDiv } from 'components/atoms/Details/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import KYCLevelView from './RenderKYCLevel'
import AddressView from './RenderAddress'
import DetailsView from './RenderDetails'
import StatusView from './RenderStatus'
import KYCInfo from './RenderKYCInfo'
import Auth0Info from './RenderAuth0Info/Info'
import KeycloakInfo from './RenderKeycloakInfo/Info'
import RebatesAndReferrals from './RebatesAndReferrals'
import RenderParachute from './ParachuteDetails/RenderParachuteDocuments'
import VeriffAccountInformation, {
  VeriffKycType,
} from './VeriffInfo/VeriffAccountInformation'

import { clearVirtualAccount } from '../../../redux/virtual-account/actions'
import { getFiatConfig } from '../../../redux/fiat/selectors'
import { checkRole } from 'helpers/roleBasedAccess'

class AccountDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { accountId: -1 }
  }

  componentDidMount() {
    const {
      match: {
        params: { accountId },
      },
    } = this.props

    this.props.fetchCorporateSanctionInformation(accountId)
    this.props.fetchMarginTradeFiatStatus(accountId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.accountId != this.state.accountId) {
      this.setState({ accountId: -1 })
    }
  }

  componentWillUnmount() {
    this.props.clearFoundSanctionedCorporate()
  }

  render() {
    const {
      details: {
        info,
        status,
        reference,
        cellphoneMarkedForReUse,
        depositReferences,
        settings,
        keyRelianceAgreementType,
        isValrStaffAccount,
      },
      match: {
        params: { accountId },
      },
      kyc,
      kycInfo,
      address,
      activationStatus,
      auth0Info,
      keycloakInfo,
      customerType,
      fetchAccountDetails,
      currencies,
      sanctionedCorporate,
      accountActivationStatus,
      individualRiskProfileInfo,
      corporateRiskProfile,
    } = this.props

    const hasSuperUserRoleAndIsStaffAccount = isValrStaffAccount
      ? checkRole('management:internal:account:editor')
      : true

    const corporateAccountSanctioned =
      (sanctionedCorporate !== null &&
        sanctionedCorporate.status === 'unchecked') ||
      sanctionedCorporate.approval_status === 'declined'
    const { status: kycLoading } = kyc
    if (kycLoading !== 'done' && kycLoading !== 'error') return <LoadingBlock />
    if (info !== undefined) {
      return (
        <ErrorBoundary message="Account Details">
          <DetailsView
            info={info}
            settings={settings}
            reference={reference}
            status={status}
            customerTypeInfo={customerType}
            id={accountId}
            auth0={auth0Info}
            keycloakInfo={keycloakInfo}
            cellphoneMarkedForReUse={cellphoneMarkedForReUse}
            fetchAccountDetails={fetchAccountDetails}
            depositReferences={depositReferences}
            currencies={currencies}
            corporateAccountSanctioned={corporateAccountSanctioned}
            accountActivationStatus={accountActivationStatus}
            hasSuperUserRoleAndIsStaffAccount={
              hasSuperUserRoleAndIsStaffAccount
            }
            individualRiskProfileInfo={individualRiskProfileInfo}
            corporateRiskProfile={corporateRiskProfile}
            keyRelianceAgreementType={keyRelianceAgreementType}
          />
          <Splitter>
            <ContentDiv width={25}>
              <KYCLevelView kyc={kyc} id={accountId} />
            </ContentDiv>
            <ContentDiv width={50}>
              {kycInfo.status === 'done' && kycInfo.submitted && (
                <KYCInfo kyc={kyc} data={kycInfo} />
              )}
              <VeriffAccountInformation
                accountId={accountId}
                kycType={VeriffKycType.IDENTITY}
              />
            </ContentDiv>
            <ContentDiv width={25}>
              <StatusView activationStatus={activationStatus} id={accountId} />
            </ContentDiv>
          </Splitter>
          <RenderParachute id={accountId} />
          {auth0Info.status === 'done' && auth0Info.info && (
            <Auth0Info data={auth0Info} id={accountId} />
          )}
          {keycloakInfo.status === 'done' && keycloakInfo.info && (
            <KeycloakInfo data={keycloakInfo} id={accountId} />
          )}
          <RebatesAndReferrals accountId={accountId} />
          <AddressView address={address} />
        </ErrorBoundary>
      )
    }
    return <p>Account not found</p>
  }
}
const mapStateToProps = (state) => ({
  activationStatus: getActivationStatus(state),
  address: getAccountAddress(state),
  details: getAccountDetails(state),
  kyc: getAccountKYC(state),
  kycInfo: getAccountKYCInfo(state),
  auth0Info: getAuth0Info(state),
  keycloakInfo: getKeycloakInfo(state),
  accountId: state.ui.activeId,
  customerType: getCustomerType(state),
  watchlistCheck: getAccountWatchlistCheck(state),
  accountActivationStatus: getAccountMarginStatus(state),
  currencies: getFiatConfig(state).currencies,
  sanctionedCorporate: state.sanctionedCorporate,
  accountProfileSettings: state.accountProfileSettings,
  individualRiskProfileInfo: state.accounts.byId.riskProfile.info,
  corporateRiskProfile: state.accounts.byId.riskProfile.corporateInfo,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchKycLevel,
      fetchKycInfo,
      fetchAccountAddress,
      fetchAccountDetails,
      fetchActivationStatus,
      fetchCustomerType,
      fetchAccountRiskStatus,
      fetchMarginTradeFiatStatus,
      fetchWatchlistCheckById,
      fetchCorporateSanctionInformation,
      clearFoundSanctionedCorporate,
      clearVirtualAccount,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
