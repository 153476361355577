import {
  FETCH_SENDGRID_EMAILS_FOR_USER_PROCESS,
  FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS,
  FETCH_SENDGRID_EMAILS_FOR_USER_ERROR,
} from '../../forms/SearchAccountEmails/submit'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SENDGRID_EMAILS_FOR_USER_PROCESS: {
      return {
        ...state,
        data: [],
        status: 'loading',
      }
    }

    case FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS: {
      const { data, currentEmail } = action
      return {
        ...state,
        data,
        currentEmail,
        status: 'done',
      }
    }

    case FETCH_SENDGRID_EMAILS_FOR_USER_ERROR: {
      const { error } = action
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    default:
      return state
  }
}
