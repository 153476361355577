export const FETCH_PENDING_FERRY_AUTHS_PROCESS = 'FETCH_PENDING_FERRY_AUTHS_PROCESS'
export const FETCH_PENDING_FERRY_AUTHS_SUCCESS = 'FETCH_PENDING_FERRY_AUTHS_SUCCESS'
export const FETCH_PENDING_FERRY_AUTHS_ERROR = 'FETCH_PENDING_FERRY_AUTHS_ERROR'

const initialState = {
  status: 'pristine',
  loading: false,
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_FERRY_AUTHS_PROCESS: {
      return {
        ...state,
        status: 'loading',
        loading: true,
      }
    }

    case FETCH_PENDING_FERRY_AUTHS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        loading: false,
        status: 'done',
      }
    }

    case FETCH_PENDING_FERRY_AUTHS_ERROR: {
      return {
        ...state,
        data: [],
        status: 'done',
        loading: false,
      }
    }

    default:
      return state
  }
}
