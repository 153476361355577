import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import ErrorToast from 'components/molecules/ErrorToast'
import { addUserActionComment } from 'redux/userComments/actions'

export const submit = async values => {
  let res = null
  values.comment = values.reason
  values.actionType = values.type
  try {
    res = await getAxios().post('/change/identity-number', {
      values,
    })

    if (res.data.statusCode === 202) {
      const {
        data: { dualAuthId },
      } = res.data
      addUserActionComment({ ...values, dualAuthId })
      toast('Identity number status updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    } else {
      toast(`Failed to update identity change status. \n ${res.data.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
  } catch (error) {
    ErrorToast(error)
  }
}
