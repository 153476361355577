import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import LoansBook from './LoansBook'
import ViewLoansForCurrency from './ViewAllLoansForCurrency'

const routes = ['/leverage/loans-book', '/leverage/loans-book/full']

const RenderLoanBookInfo = props => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(
    () => {
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
    },
    [pathname],
  )
  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="LOANS BOOK SUMMARY"
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label="LOANS BOOK FULL"
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={LoansBook} exact />
          <Route path={routes[1]} component={ViewLoansForCurrency} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}
export default RenderLoanBookInfo
