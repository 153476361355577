import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, maxLength256 } from 'forms/fieldValidators'
import {
  FormContainer,
  Button,
  InputGroup,
  FormError,
  BlueLinkButton,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { renderDropdown, renderInput } from '../fields'
import { submitAccountsDeposits } from './submit'
import Notes from 'components/atoms/Notes'
import { InfoLabel } from 'components/atoms/Details/index'
import { Flexbox } from 'components/layout/index'
import { Spacer } from 'components/atoms/Generic'

let Form = (props) => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    depositId,
    expandRawData,
    showAddNote,
    showRawData,
    expandAddNote,
    comments,
  } = props
  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <Flexbox
          align={'flex-start'}
          justify={'space-between'}
          direction={'row'}
        >
          <Field
            label={'Action'}
            name={'status'}
            margin={`0 ${BASE * 2}px 0 0`}
            width={'40%'}
            flex={0.4}
            component={renderDropdown}
            options={[
              { value: 'CREDIT', label: 'Approve' },
              { value: 'IGNORE', label: 'Reject' },
            ]}
          />
          <Field
            label={'Comment (required)'}
            name={'comment'}
            width={'60%'}
            margin={`0 ${BASE * 2}px 0 0`}
            validate={[required, maxLength256]}
            component={renderInput}
            marginBottom={26}
            labelWidth={'150px'}
          />

          <div style={{ width: '286px', marginTop: '16px' }}>
            <Button
              type="submit"
              disabled={submitting || invalid}
              margin={`0 ${BASE * 2}px 0 0`}
            >
              Submit
            </Button>
            <Button padding={'16px 41px'} onClick={onCancel} color="secondary">
              Cancel
            </Button>
          </div>
        </Flexbox>
        <Spacer margin="12px 0px" />
        <DetailsBlockWithTitle title="Notes">
          <Notes depositId={depositId} comments={comments} />
        </DetailsBlockWithTitle>
        <Spacer margin="8px 0px" />
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showRawData}
            >
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
            <BlueLinkButton
              marginRight={'50px'}
              marginBottom={'20px'}
              onClick={showAddNote}
            >
              {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </>
  )
}

Form = reduxForm({ onSubmit: submitAccountsDeposits })(Form)
const ReduxForm = connect()(Form)

export default ReduxForm
