import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getAuth0Users = state => state.auth0.byId

export const getAuth0Info = createSelector(
  [getAuth0Users, getActiveAccount],
  (users, id) => {
    if (!id || !users[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }
    return users[id]
  },
)

export const otherAuth0Accounts = state => state.auth0.otherAccountsById

export const getOtherAuth0Accounts = createSelector(
  [otherAuth0Accounts, getActiveAccount],
  (accounts, id) => {
    if (!id || !accounts[id]) {
      return {
        status: 'pristine',
        data: [],
      }
    }
    return accounts[id]
  },
)
