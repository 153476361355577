import { Flexbox } from 'components/layout'
import styled from 'styled-components'
import { BASE } from 'theme/base-units'
import { VALR_BLUE_GRAY } from 'theme/colors'

export const RiskProfileFormContainer = styled('form')<any>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  margin: ${BASE * 2}px 0;
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`

export const RiskProfilePdfContainer = styled.div`
  padding: 20px 100px;
`

export const RiskProfileEddDocumentItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  width: 100%;
  align-items: center;
`
export const RiskProfileGridItem = styled.div`
  text-align: left;
`
export const RiskProfileUploaderContainer = styled.div`
  text-align: left;
`

export const CorporateRiskProfileEntityData = styled.span`
  margin-right: 8px;
`

export const StyledCorporateRiskProfileEntityCard = styled(Flexbox)`
  background-color: ${VALR_BLUE_GRAY};
  border-radius: 33px;
  color: white;
  height: 150px;
  width: 200px;
  overflow: scroll;
`
