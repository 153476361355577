import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  required,
  maxLength256,
} from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderInput } from '../fields'
import { submit } from './submit'

let Form = props => {
  const { submitting, error, invalid, title } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <>
      <FormContainer
        onSubmit={handleSubmit(async values => {
          await onSubmit(values)
        })}
      >
        <FormTitle>{title}</FormTitle>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Count (required)'}
            name={'count'}
            width={'100%'}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Reason (required)'}
            name={'reason'}
            width={'100%'}
            validate={[required, maxLength256]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Button
            type="submit"
            disabled={submitting || invalid}
            margin={`0 ${BASE * 2}px 0 0`}
          >
            Submit
          </Button>
          <Button onClick={onCancel} color="secondary">
            Cancel
          </Button>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
