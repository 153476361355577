import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import {
  formatFieldValueWithGroupSeparators,
  removeGroupSeparators,
} from 'helpers/currency'

import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
} from 'components/atoms/Form'
import { renderDropdown, renderDropDownField, renderInput } from 'forms/fields'
import {
  required,
  isNumber,
  noSpacesAllowed,
  requiredDropdownValue,
} from 'forms/fieldValidators'

import { submitTransfer } from './submit'

const Form = (props) => {
  const { submitting, invalid } = props
  const { handleSubmit, onSubmit, currencies, onCancel } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Enter Details</FormTitle>
      <InputGroup align={'flex-start'}>
        <Field
          label={'From (account id)'}
          name={'fromAccountId'}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          margin={`0 ${BASE * 2}px 0 0`}
          fullWidth
        />
        <Field
          label={'To (account id)'}
          name={'toAccountId'}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'}>
        <Field
          format={(value) => formatFieldValueWithGroupSeparators(value)}
          normalize={(value) => removeGroupSeparators(value)}
          label={'Amount'}
          name={'amount'}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          margin={`0 ${BASE * 2}px 0 0`}
          fullWidth
        />
        <Field
          label={'Currency Symbol'}
          name={'currencySymbol'}
          component={renderDropdown}
          options={[...currencies]}
          validate={[requiredDropdownValue]}
          type="currencies"
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'}>
        <Field
          label={'Reason ID'}
          name={'reasonId'}
          component={renderDropDownField}
          options={[
            { value: 1, label: 'COMPETITION' },
            { value: 2, label: 'OTHER' },
          ]}
          showNoneLabel={false}
          fullWidth
          margin={`0 ${BASE * 2}px 0 0`}
        />
        <Field
          label={`Comment on clients transaction history`}
          name={'comment'}
          component={renderInput}
          validate={[required]}
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'} width="49%">
        <Field
          label={'Internal Comment'}
          name={'internalComment'}
          component={renderInput}
          validate={[required]}
          fullWidth
        />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button type="button" onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

const PendingInternalTransferForm = connect(null)(Form)

const ReduxForm = reduxForm({
  form: 'InternalTransfer',
  initialValues: {
    comment: '',
    toAccountId: '',
    fromAccountId: '',
    amount: '',
    reasonId: 2,
  },
  onSubmit: submitTransfer,
  validate: (values) => {
    const errors = {}
    if (!values.confirmation) {
      errors.confirmation = 'Check this to confirm'
    }
    return errors
  },
  enableReinitialize: true,
})(PendingInternalTransferForm)

export default ReduxForm
