import { TablePagination } from '@material-ui/core'
import React, { Fragment, useState, useEffect, FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchCorporateRiskBasedProfiles,
  fetchMoreCorporateRiskBasedProfiles,
  initializeCorporateRiskProfileReview,
} from 'redux/riskBasedProfiles/actions'
import {
  CorporateRiskProfileWithDualAuthStatus,
  RecommendationStatusTypes,
  RiskLevelTypes,
} from 'interfaces/riskProfile'
import { RiskBasedProfilesState } from 'redux/riskBasedProfiles'

import RiskAccountsProfilesTable from '../RiskAccountsProfilesTable'
import getAxios from 'helpers/axios'

interface IRiskAccountProfiles {

  fetchCorporateRiskBasedProfiles: (
    from: number,
    to: number,
    type: RecommendationStatusTypes,
    riskLevelToSearch: RiskLevelTypes,
  ) => Promise<void>
  fetchMoreCorporateRiskBasedProfiles: (
    from: number,
    to: number,
    type: RecommendationStatusTypes,
    riskLevelToSearch: RiskLevelTypes,
  ) => Promise<void>
  initializeCorporateRiskProfileReview: (id: number) => Promise<void>
  riskAccountProfiles: RiskBasedProfilesState
  type: RecommendationStatusTypes
  riskLevelToSearch: RiskLevelTypes
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
}

const CorporateRiskAccountProfiles: FC<IRiskAccountProfiles> = (props) => {
  const [expandRowId, setSelectedRow] = useState(-1)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [numberOfRecords,setNumberOfRecords] = useState(0)
  const riskProfileType = 'CORPORATE'

  const toggleSelectedRow = (id: number) =>
    setSelectedRow((prev) => (prev === id ? -1 : id))

    const {
    corporateRiskBasedProfileManagement: {
      newRiskProfiles,
      pendingRiskProfiles,
      monitorRiskProfiles,
      blockedRiskProfiles,
      terminatedRiskProfiles,
    },
    status,
  } = props.riskAccountProfiles
  const showDataByType = (type, page) => {
    let data: CorporateRiskProfileWithDualAuthStatus[] = []
    switch (type) {
      case 'NEW':
        data = newRiskProfiles
        break
      case 'PENDING':
        data = pendingRiskProfiles
        break
      case 'MONITOR':
        data = monitorRiskProfiles
        break
      case 'BLOCK':
        data = blockedRiskProfiles
        break
      case 'TERMINATE':
        data = terminatedRiskProfiles
        break
      default:
        break
    }
    return data && data.slice(page * rowsPerPage, rowsPerPage * (page + 1))
  }

  const getAmountOfItems = (type) => {
    let data: CorporateRiskProfileWithDualAuthStatus[] = []
    switch (type) {
      case 'NEW':
        data = newRiskProfiles
        break
      case 'PENDING':
        data = pendingRiskProfiles
        break
      case 'MONITOR':
        data = monitorRiskProfiles
        break
      case 'BLOCK':
        data = blockedRiskProfiles
        break

      case 'TERMINATE':
        data = terminatedRiskProfiles
        break
      default:
        break
    }
    return data.length
  }

  const handleChangePage = async(e, newPage) => {
    const amountOfItemsOnPage = showDataByType(props.type, newPage).length
    if (rowsPerPage === amountOfItemsOnPage) return setPage(newPage)
    const previousItemsAmount = getAmountOfItems(props.type)
    setPage(newPage)
    props.fetchMoreCorporateRiskBasedProfiles(
      previousItemsAmount,
      previousItemsAmount + rowsPerPage,
      props.type,
      props.riskLevelToSearch,
    )
    const { data } = await getAxios().get(
      `risk-based-approach/profiles/all?type=${props.type}&level=${props.riskLevelToSearch}&riskProfileType=${riskProfileType}`,
    )
    setNumberOfRecords(data)
  }

  const handleChangeRowsPerPage = async(e) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
    const { data } = await getAxios().get(
      `risk-based-approach/profiles/all?type=${props.type}&level=${props.riskLevelToSearch}&riskProfileType=${riskProfileType}`,
    )
    setNumberOfRecords(data)
    if (rowsPerPage < e.target.value) {
      props.fetchCorporateRiskBasedProfiles(
        0,
        e.target.value,
        props.type,
        props.riskLevelToSearch,
      )
    }
  }

 const getAllProfiles = async (type:RecommendationStatusTypes,riskLevelToSearch:RiskLevelTypes, riskProfileType?: 'CORPORATE' | 'INDIVIDUAL')=>{
  const { data } = await getAxios().get(
    `risk-based-approach/profiles/all?type=${type}&level=${riskLevelToSearch}&riskProfileType=${riskProfileType}`,
  )
  setNumberOfRecords(data)
  }

  useEffect(() => {
    props.fetchCorporateRiskBasedProfiles(
      0,
      rowsPerPage,
      props.type,
      props.riskLevelToSearch,
    )
    getAllProfiles(props.type,props.riskLevelToSearch,riskProfileType)
  }, [])
  
  return (
    <Fragment>
      <RiskAccountsProfilesTable
        accountsProfilesData={showDataByType(props.type, page)}
        toggleSelectedRow={toggleSelectedRow}
        expandRowId={expandRowId}
        status={status}
        type={props.type}
        initializeRiskProfileReview={props.initializeCorporateRiskProfileReview}
        riskProfileType="CORPORATE"
        riskLevelToSearch={props.riskLevelToSearch}
        getAllProfiles={getAllProfiles}
      />
      <TablePagination
        component="div"
        count={numberOfRecords}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  riskAccountProfiles: state.riskBasedProfiles,
  state,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCorporateRiskBasedProfiles,
      fetchMoreCorporateRiskBasedProfiles,
      initializeCorporateRiskProfileReview,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateRiskAccountProfiles)
