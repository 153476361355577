import React, { FC, Fragment, useState } from 'react'
import {
  getAvailableCurrencyPairs,
  resetAvailableCurrencyPairs,
} from 'redux/futures/actions'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import Select from 'react-select'
import { FuturesInitialState } from 'redux/futures'
import {
  AvailableCurrencyPairsListItem,
  CurrencyPair,
} from 'interfaces/futures'
import { Spacer } from 'components/atoms/Generic'

import LoadingBlock from 'components/molecules/LoadingBlock'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import ConditionalOrdersTable from './components/ConditionalOrderTable'
import { fetchConditionalOrders ,resetConditionalOrders} from 'redux/conditionalOrders/actions'
import { useConditionalOrders } from 'containers/Hooks/ConditionalOrders/useConditionalOrders'
import { ConditionalOrder } from 'interfaces/conditionalOrders'

interface ConditionalOrdersProps {
  futures: FuturesInitialState
  getAvailableCurrencyPairs: () => Promise<void>
  fetchConditionalOrders: (currencyPair: CurrencyPair) => Promise<void>
  resetConditionalOrders : () => Promise<void>
  resetAvailableCurrencyPairs: () => Promise<void>
  conditionalOrders: {
    fetchingConditionalOrders:  'pristine' | 'pending' | 'done'
    data: ConditionalOrder[]
  }
  toggleSelectedRow: React.Dispatch<React.SetStateAction<number | null>>
  setExpandedItem: React.Dispatch<React.SetStateAction<string>>
  expandedItem: number

}
const ConditionalOrdersView: FC<ConditionalOrdersProps> = props => {
  const {
    availableCurrencyPairsList,
    fetchingCurrencyPairs,
  } = props.futures

 const { conditionalOrders,
  resetAvailableCurrencyPairs,
  resetConditionalOrders,
  getAvailableCurrencyPairs,
  fetchConditionalOrders
} = props

  const {
    expandRowId,
    setExpandedItem,
    handleFetchAvailableCurrencyPairs,
  } = useConditionalOrders(
  getAvailableCurrencyPairs,
  availableCurrencyPairsList,
  resetAvailableCurrencyPairs,
  resetConditionalOrders,
   )

   return (
    <div>
      <Select
        onFocus={handleFetchAvailableCurrencyPairs}
        options={
          fetchingCurrencyPairs
            ? [
                {
                  label: <LoadingBlock message="Fetching Currency Pairs" />,
                  value: '',
                },
              ]
            : availableCurrencyPairsList || []
        }
        placeholder={'Select currency pair'}
        onChange={(currencyPairItem: AvailableCurrencyPairsListItem) =>
       fetchConditionalOrders(currencyPairItem.value)
        }
      />

{
conditionalOrders.fetchingConditionalOrders === 'pending'?(
     <Spacer margin="12px 0px">
     <LoadingBlock message="Fetching conditional orders..." />
   </Spacer>
):
(
  <Fragment>
  {conditionalOrders.data.length> 0 ?(
      <ConditionalOrdersTable
            expandRowId={expandRowId}
            conditionalOrders={conditionalOrders}
            setExpandedItem={setExpandedItem}
          />
): (
  <Fragment>
  {conditionalOrders.fetchingConditionalOrders === 'done' && (
    <Spacer margin="12px 0px">
      <TablePlaceholder>
        No conditional orders found.
      </TablePlaceholder>
    </Spacer>
  )}
  {conditionalOrders.fetchingConditionalOrders === 'pristine' && (
    <Spacer margin="12px 0px">
      <TablePlaceholder>
      No conditional orders to show. please select an option from the list above.
      </TablePlaceholder>
    </Spacer>
  )}
</Fragment>
   )}
  </Fragment>
)}
    </div>
  )
}

const actions = {
  getAvailableCurrencyPairs,
  fetchConditionalOrders,
  resetAvailableCurrencyPairs,
  resetConditionalOrders,
}

export default connectStateAndDispatch(
  mapStateToProps(state => ({
    futures: state.futures,
    conditionalOrders:state.conditionalOrders,
  })),
  actions,
  ConditionalOrdersView,
)
