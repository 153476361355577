import React, { FC, useEffect, useRef } from 'react'
import { Flexbox } from 'components/layout'
import { Spacer } from 'components/atoms/Generic'
import { connectStateAndDispatch } from 'store'
import { SingleAccountInfo } from 'interfaces/singleAccount'
import { updateSearchedRiskProfileState } from 'redux/riskBasedProfiles/actions'
import { RiskScoringFormOptions } from 'interfaces/riskProfile'

import { DetailsBlockWithTitle } from 'components/atoms/Details'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  restoredRiskProfileEddDocument,
  createRiskProfile,
  updateRiskProfile,
  fetchRiskProfileInfo,
  fetchRiskScoringFormOptions,
  fetchAccountCorporateInformation,
} from 'redux/accounts/actions'
import { Title } from 'components/atoms/Title'

import {
  AccountCorporate,
  CorporateNaturalEntity,
  CorporateRiskProfile,
  IndividualRiskProfile,
  RiskProfileEddDocument,
} from '../../interfaces/riskProfile'
import IndividualRiskProfilePage from './IndividualRiskProfile/IndividualRiskProfilePage'
import { getCustomerType } from 'redux/selectors/type'
import { showRiskScoreAlertColor } from './EddReports/RiskProfilePdf/helpers'

interface RiskProfileProps {
  accountInfo: SingleAccountInfo
  individualRiskProfileInfo: IndividualRiskProfile
  corporateRiskProfile: CorporateRiskProfile
  eddDocuments: RiskProfileEddDocument[]
  removedEddDocuments: RiskProfileEddDocument[]
  riskScoringFormOptions: RiskScoringFormOptions
  customerTypeInfo: {
    status: string
    data: {
      accountId: number
      type: string
      updatedByAuthProviderId: string
      comment: string
      insertedAt: string
      updatedAt: string
      keyRelianceAgreementStatus: boolean
    }
    error: string
  }
  accountCorporate: AccountCorporate
  accountCorporateNaturalEntities: CorporateNaturalEntity[]
  isFetchingRiskProfileInfo: boolean
  isFetchingRiskScoringOptions: boolean
  isFetchingCorporateForAccount: boolean
  match: any
  history: any
  restoredRiskProfileEddDocument: (
    document: RiskProfileEddDocument,
  ) => Promise<void>
  fetchRiskProfileInfo: (accountId: number) => Promise<void>
  fetchAccountCorporateInformation: (accountId: number) => Promise<void>
  fetchRiskScoringFormOptions: () => Promise<void>
  updateSearchedRiskProfileState: (accountId: number) => Promise<void>
  fetchingAccountInfoForRba: boolean
}

const RiskProfile: FC<RiskProfileProps> = (props) => {
  const {
    accountInfo,
    fetchingAccountInfoForRba,
    individualRiskProfileInfo,
    removedEddDocuments,
    customerTypeInfo,
    riskScoringFormOptions,
    isFetchingRiskProfileInfo,
    isFetchingRiskScoringOptions,
    restoredRiskProfileEddDocument,
    updateSearchedRiskProfileState,
  } = props
  const { riskScore } = individualRiskProfileInfo
  const { accountId } = props.match.params
  const { corporateScoringOptions, ...individualScoringOptions } =
    riskScoringFormOptions
  if (customerTypeInfo.status !== 'done')
    return <LoadingBlock message="Fetching customer type..." />

  const handleFetchData = async () => {
    await props.fetchRiskScoringFormOptions()
    await props.fetchRiskProfileInfo(accountId)
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div>
      <DetailsBlockWithTitle title="Risk Profile">
        <Flexbox direction="column">
          <Title
            style={{
              ...showRiskScoreAlertColor(riskScore),
            }}
            type="h4"
            text={`Individual risk score ${riskScore || '--'}`}
          />
        </Flexbox>
        {isFetchingRiskProfileInfo || isFetchingRiskScoringOptions ? (
          <LoadingBlock message="Loading please wait..." />
        ) : (
          <>
            <IndividualRiskProfilePage
              accountId={accountId}
              accountInfo={accountInfo}
              createRiskProfile={createRiskProfile}
              eddDocuments={props.eddDocuments}
              history={props.history}
              individualRiskProfileInfo={individualRiskProfileInfo}
              individualScoringOptions={individualScoringOptions}
              isFetchingRiskScoringOptions={isFetchingRiskScoringOptions}
              removedEddDocuments={removedEddDocuments}
              updateRiskProfile={updateRiskProfile}
              updateSearchedRiskProfileState={updateSearchedRiskProfileState}
              restoredRiskProfileEddDocument={restoredRiskProfileEddDocument}
              fetchingAccountInfoForRba={fetchingAccountInfoForRba}
            />
          </>
        )}
      </DetailsBlockWithTitle>
    </div>
  )
}
const action = {
  fetchRiskProfileInfo,
  restoredRiskProfileEddDocument,
  updateSearchedRiskProfileState,
  fetchRiskScoringFormOptions,
  fetchAccountCorporateInformation,
}

export default connectStateAndDispatch(
  (state) => ({
    individualRiskProfileInfo: state.accounts.byId.riskProfile.info,
    riskScoringFormOptions:
      state.accounts.byId.riskProfile.riskScoringFormOptions,
    isFetchingRiskScoringOptions:
      state.accounts.byId.riskProfile.isFetchingRiskScoringOptions,
    isFetchingRiskProfileInfo:
      state.accounts.byId.riskProfile.isFetchingRiskProfileInfo,
    eddDocuments: state.accounts.byId.riskProfile.eddDocuments,
    removedEddDocuments: state.accounts.byId.riskProfile.removedEddDocuments,
    accountInfo: state.accounts.byId.info,
    fetchingAccountInfoForRba: state.accounts.byId.fetchingAccountInfoForRba,
    customerTypeInfo: getCustomerType(state),
  }),
  action,
  RiskProfile,
)
