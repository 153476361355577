import React, { Fragment, useState } from 'react'
import BigNumber from 'bignumber.js'
import { VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'

import { TableRow, TableCell } from 'components/atoms/Table/styles'
import {
  InputGroup,
  InputContainer,
  Input,
  InputLabel,
} from 'components/atoms/Form'
import { RenderCheckbox } from 'forms/fields'
import FiatUnreslovedWithrawals from 'forms/UnreslovedWithdrawals/index'
import auth from 'services/Auth'
import socket from 'redux/socket/index'
import EyeIcon from 'components/atoms/EyeIcon/index'

import { Link } from '../AccountIdLink/clickableId'

const UnresolvedFiatWithdrawalsRow = (props) => {
  const [sensitiveInfoData, setSensitiveInfoData] = useState({
    name: '',
    surname: '',
    isFetchingSensitiveInfo: false,
  })

  const getProfileInfo = async (id) => {
    setSensitiveInfoData((prev) => ({ ...prev, isFetchingSensitiveInfo: true }))
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const { sensitiveInfo } = res.data
    setSensitiveInfoData((prev) => ({ ...prev, ...sensitiveInfo }))
    setSensitiveInfoData((prev) => ({
      ...prev,
      isFetchingSensitiveInfo: false,
    }))
  }
  const removeActiveUser = () => {
    const { withdrawalId } = props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const removeUser = { withdrawalId, activeUserQue }
    socket.emit('remove withdrawalUser user', removeUser)
  }

  const showActiveuser = () => {
    const { withdrawalId } = props.withdraw
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, withdrawalId }
    const activeUser = { withdrawalId, activeUserQue }
    socket.emit('add withdrawal user', activeUser)
  }

  const refineRawData = (data) => {
    const {
      bank,
      name,
      surname,
      withdrawalId,
      branchCode,
      accountType,
      currency,
    } = data
    const { rtcParticipant, defaultBranchCode } = bank
    return {
      name,
      surname,
      withdrawalId,
      branchCode,
      accountType,
      defaultBranchCode,
      rtcParticipant,
      currency,
    }
  }
  const { isHighlighted, setHighlightedId } = props
  const {
    onWithdrawCheck,
    checked,
    fee,
    onFeeChange,
    expanded,
    expandItem,
    markAsFailed,
  } = props
  const { withdraw, showRawData, toggleRawData } = props
  const {
    withdrawalId,
    active,
    accountId,
    amount,
    bank,
    accountHolder,
    updatedAt,
    currency,
  } = withdraw
  const formattedAmount = new BigNumber(amount).toFormat(2)
  const padding = checked ? '45px 16px' : '16px 16px'
  const shading = isHighlighted ? 'white' : ''

  return (
    <Fragment>
      <TableRow
        style={{
          padding: '0px',
          color: shading,
          backgroundColor: (checked || isHighlighted) && VALR_BLUE_GRAY,
        }}
        onClick={() => {
          getProfileInfo(accountId)
          setHighlightedId(withdrawalId)
        }}
      >
        <TableCell style={{ padding }} flex={0.45}>
          <Link color={shading} id={accountId} />
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={1.2}
        >
          {accountHolder}
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={1.2}
        >
          {bank.displayName}{' '}
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={1}
          align={'right'}
        >
          {formattedAmount}
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={0.8}
          align={'right'}
        >
          {currency.shortName}
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={1}
          align={'right'}
        >
          {updatedAt}
        </TableCell>
        <TableCell style={{ padding: '16px' }} flex={0.7} align={'right'}>
          <RenderCheckbox
            style={{ color: checked ? 'white' : '' }}
            checked={checked}
            width={'20%'}
            height={'20%'}
            onCheckFunction={() => onWithdrawCheck(props.withdraw)}
          />
          {checked && (
            <InputGroup direction={'column'} align={'flex-start'}>
              <InputLabel>Fee:</InputLabel>
              <InputContainer>
                <Input
                  type={'text'}
                  flex={'auto'}
                  width={'100%'}
                  defaultValue={fee}
                  onChange={(e) => onFeeChange(withdrawalId, e.target.value)}
                />
              </InputContainer>
            </InputGroup>
          )}
        </TableCell>
        <TableCell
          style={{ padding }}
          onClick={() => expandItem(withdrawalId)}
          flex={0.5}
        >
          {active && active.userActive && (
            <EyeIcon text={active.activePersonName} />
          )}
        </TableCell>
      </TableRow>
      {expanded.indexOf(withdrawalId) !== -1 && (
        <>
          <FiatUnreslovedWithrawals
            showRawData={showRawData}
            withdrawalId={withdrawalId}
            withdraw={{ ...withdraw, ...sensitiveInfoData }}
            toggleRawData={toggleRawData}
            form={`fiatUnreslovedWithdrawals+${withdrawalId}`}
            showActiveUser={showActiveuser}
            refineRawData={refineRawData}
            removeActiveUser={removeActiveUser}
            markAsFailed={markAsFailed}
          />
        </>
      )}
    </Fragment>
  )
}

export default UnresolvedFiatWithdrawalsRow
