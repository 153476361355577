export const RECENT_ACTIVITY_PROCESS = 'RECENT_ACTIVITY_PROCESS'
export const RECENT_ACTIVITY_SUCCESS = 'RECENT_ACTIVITY_SUCCESS'
export const RECENT_ACTIVITY_ERROR = 'RECENT_ACTIVITY_ERROR'

const initialState = {
  status: 'pristine',
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECENT_ACTIVITY_PROCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }

    case RECENT_ACTIVITY_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case RECENT_ACTIVITY_ERROR: {
      return {
        ...state,
        status: 'pristine',
        data: [],
      }
    }

    default:
      return state
  }
}
