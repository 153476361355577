import React, { Component, Fragment } from 'react'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import FormCreditCard from 'forms/CreditCard/index'
import { Link } from '../AccountIdLink/clickableId'

class Row extends Component {
  render() {
    const {
      data,
      toggleForm,
      formShown,
      expandRawData,
      showRawData,
      setHighlightedId,
      isHighlighted,
    } = this.props
    const shading = isHighlighted ? 'white' : ''
    const { accountId, amount, createdAt, identifier, reviewItemId } = data
    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && '#4569eb',
          }}
          onClick={() => {
            toggleForm(reviewItemId)
            setHighlightedId(reviewItemId)
          }}
        >
          <TableCell flex={0.6} style={{ padding: '16px 16px' }}>
            {accountId ? <Link id={accountId} /> : '--'}
          </TableCell>
          <TableCell flex={0.6}>{amount}</TableCell>

          <TableCell flex={0.6}>{createdAt}</TableCell>
        </TableRow>
        {formShown.id === reviewItemId && formShown.showForm && (
          <FormCreditCard
            initialValues={{
              accountId,
              comment: '',
              status: '',
              identifier,
            }}
            expandRawData={expandRawData}
            showRawData={() => showRawData(reviewItemId)}
            form={`CreditCard+${reviewItemId}`}
            rawData={this.props.data}
            onCancel={this.props.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}

export default Row
