import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify'
import { FlexboxColumn } from 'components/layout'
import { BASE } from 'theme/base-units'

import './Styles.css'
import { hasApplicationConfigEditor } from 'helpers/roleBasedAccess'
import ErrorBoundary from 'helpers/ErrorBoundary'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { fetchConfig } from 'redux/applicationConfig/actions'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import DynamicConfig from './DynamicConfig'
import {
  calculateChanges,
  getChangedConfigValue,
  getConfigValue,
  saveConfig,
} from './helpers'
import { ConfirmModal } from './ConfirmModal'

class DynamicApplication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applicationState: [],
      confirm: false,
      changes: [],
    }
  }

  componentDidMount() {
    this.props.fetchConfig('application')
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props.applicationConfig
    if (prevProps.applicationConfig.data.length !== data.length) {
      const applicationNewState = data.map((application) => {
        const value = getConfigValue(application.type, application.value)
        return { envName: application.name, value, type: application.type }
      })
      this.setState({ applicationState: applicationNewState })
    }
  }

  addToList = (key) => {
    const { value } = window[`${key}Ref`].current
    if (!value) {
      toast('Please enter a value')
    } else {
      const newItemAdded = this.state.applicationState.map((newState) => {
        if (newState.envName === key) {
          newState.value.push(value.toUpperCase())
          newState.configChanged = true
        }
        return newState
      })
      this.setState({ applicationState: newItemAdded })
      window[`${key}Ref`].current.value = ''
    }
  }

  addToMap = (key) => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const value = window[`${key}Ref`].current.value.toUpperCase().split(':')
    const newKey = value[0]
    if (!newKey) {
      toast('Please enter a value')
    } else {
      const updatedApplicationState = this.state.applicationState.map(
        (newState) => {
          if (newState.envName === key) {
            const found = newState.value.find((v) => v[newKey] != null)
            if (found) {
              toast.error(`Key already exists. ${newKey} : ${found[newKey]}`)
              return newState
            }
            newState.value.push({ [newKey]: value[1] || '' })
            newState.configChanged = true
          }
          return newState
        },
      )
      this.setState({ applicationState: updatedApplicationState })
      window[`${key}Ref`].current.value = ''
    }
  }

  // rename the key name
  onChange = (type, name, value, mappedKey) => {
    const newApplicationState = this.state.applicationState.map((newState) => {
      if (newState.envName === name) {
        newState.value = getChangedConfigValue(
          type,
          value,
          newState.value,
          mappedKey,
        )
        newState.configChanged = true
      }
      return newState
    })
    this.setState({ applicationState: newApplicationState })
  }

  handleRemove = (index, name) => {
    const hasEditorRights = hasApplicationConfigEditor()
    if (!hasEditorRights) {
      return
    }
    const updatedApplicationState = this.state.applicationState.map(
      (newState) => {
        if (newState.envName === name) {
          newState.value = newState.value.filter((todo, i) => index !== i)
          newState.configChanged = true
        }
        return newState
      },
    )
    this.setState({ applicationState: updatedApplicationState })
  }

  confirmChanges = async () => {
    this.setState({ confirm: true })
    const changes = calculateChanges(this.state.applicationState)
    this.setState({ changes })
  }

  save = async () => {
    const system = 'application'
    await saveConfig(this.state.changes, system)
    this.props.fetchConfig(system)
  }

  renderButton() {
    return (
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <MuiButton
          onClick={this.confirmChanges}
          disabled={!this.state.applicationState?.some((_) => _.configChanged)}
        >
          Review and save
        </MuiButton>
      </div>
    )
  }

  render() {
    const { status } = this.props.applicationConfig
    if (status === 'error') {
      return (
        <TablePlaceholder>
          An error occurred. Please contact support.
        </TablePlaceholder>
      )
    }
    return (
      <div>
        {status === 'loading' && <LoadingBlock />}
        {status === 'done' && (
          <ErrorBoundary message="Application config">
            <FlexboxColumn align="space-between" marginBottom={BASE * 3}>
              {this.renderButton()}
              <DynamicConfig
                items={this.state.applicationState}
                onChange={this.onChange}
                onListAdd={this.addToList}
                onRemove={this.handleRemove}
                onMapAdd={this.addToMap}
              />
              {this.renderButton()}
            </FlexboxColumn>
            <ConfirmModal
              open={this.state.confirm}
              handleClose={() => this.setState({ confirm: false })}
              proceed={this.save}
              dataToConfirm={this.state.changes}
              initialData={this.props.applicationConfig.data}
            />
          </ErrorBoundary>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  applicationConfig: state.applicationConfig.application,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchConfig,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(DynamicApplication)
