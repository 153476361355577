import getAxios from 'helpers/axios'

export const FETCH_ASKS_AND_BIDS_PROCESS = 'FETCH_ASKS_AND_BIDS_PROCESS'
export const FETCH_ASKS_AND_BIDS_SUCCESS = 'FETCH_ASKS_AND_BIDS_SUCCESS'
export const FETCH_ASKS_AND_BIDS_ERROR = 'FETCH_ASKS_AND_BIDS_ERROR'

export const fetchAsksAndBids = (currencyPairs) => async dispatch => {
  dispatch({
    type: FETCH_ASKS_AND_BIDS_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/asks-and-bids?currencyPairs=${currencyPairs}`)
    const { data } = res
    const asksAndBids = Object.keys(data).map(key => data[key]).flat()
    dispatch({
      type: FETCH_ASKS_AND_BIDS_SUCCESS,
      payload: {
        data: asksAndBids,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_ASKS_AND_BIDS_ERROR,
      payload: {
        error,
      },
    })
  }
}
