import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import { IconButton, OutlinedInput, makeStyles } from '@material-ui/core'
import { connectStateAndDispatch } from 'store'
import {
  clearSearchedRiskProfile,
  initializeCorporateRiskProfileReview,
  searchRiskProfileByAccountId,
  updateSearchedRiskProfileAccountId,
} from 'redux/riskBasedProfiles/actions'
import { FlexRow } from 'components/layout'
import { ArrowBack } from '@material-ui/icons'
import _ from 'lodash'
import {
  CorporateRiskProfileWithDualAuthStatus,
  RecommendationStatusTypes,
  RiskLevelTypes,
} from 'interfaces/riskProfile'

import CorporateRiskAccountProfiles from './CorporateRiskAccountProfiles'

import SearchedRiskProfile from '../SearchedRiskProfile'
import MuiSelect from 'components/atoms/MuiSelect'

const routes = [
  '/compliance/risk-profiles/management/risk-scoring/corporate',
  '/compliance/risk-profiles/management/risk-scoring/corporate/pending',
  '/compliance/risk-profiles/management/risk-scoring/corporate/monitor',
  '/compliance/risk-profiles/management/risk-scoring/corporate/block',
  '/compliance/risk-profiles/management/risk-scoring/corporate/terminate',
]

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

interface ICorporateProfileManagementNav {
  searchedRiskProfile: {
    isSearching: boolean
    searchedRiskProfileAccountId: number | null
    data: CorporateRiskProfileWithDualAuthStatus | null
  }
  searchRiskProfileByAccountId: (
    accountId: number,
    type?: 'CORPORATE' | 'INDIVIDUAL',
  ) => Promise<void>
  updateSearchedRiskProfileAccountId: (accountId: number) => Promise<void>
  clearSearchedRiskProfile: () => Promise<void>
  initializeCorporateRiskProfileReview: (accountId: number) => Promise<void>
  fetchIndividualRiskBasedProfiles: () => Promise<void>
  fetchMoreRiskBasedProfiles: () => Promise<void>
  getAllProfiles: (
    type: RecommendationStatusTypes,
    riskLevelTypes: RiskLevelTypes,
    riskProfileType?: 'CORPORATE' | 'INDIVIDUAL',
  ) => Promise<void>
}

const CorporateRiskManagementNav: FC<ICorporateProfileManagementNav> = (
  props,
) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const [riskLevelToFetch, setRiskLevelToFetch] = useState<
    RiskLevelTypes | 'All'
  >('All')
  const classes = useStyles()
  const riskLevels: RiskLevelTypes[] = [
    'All',
    'Low',
    'Medium',
    'High',
    'Severe',
  ]
  const debouncedSearch = useMemo(
    () =>
      _.debounce(
        (accId: number) =>
          props.searchRiskProfileByAccountId(accId, 'CORPORATE'),
        1000,
      ),
    [],
  )

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  const handleRiskProfileSearch = useCallback((e) => {
    props.updateSearchedRiskProfileAccountId(Number(e.target.value))
    debouncedSearch(Number(e.target.value))
  }, [])

  const MemoizedRoutes = useMemo(() => {
    return (
      <ContentContainer>
        <Switch>
          <Route
            path={routes[0]}
            testing="Helloooo there"
            component={(props) => {
              return (
                <CorporateRiskAccountProfiles
                  riskLevelToSearch={riskLevelToFetch}
                  type={'NEW'}
                  {...props}
                />
              )
            }}
            exact
          />
          <Route
            path={routes[1]}
            component={(props) => {
              return (
                <CorporateRiskAccountProfiles
                  riskLevelToSearch={riskLevelToFetch}
                  type={'PENDING'}
                  {...props}
                />
              )
            }}
          />
          <Route
            path={routes[2]}
            component={(props) => {
              return (
                <CorporateRiskAccountProfiles
                  riskLevelToSearch={riskLevelToFetch}
                  type={'MONITOR'}
                  {...props}
                />
              )
            }}
          />
          <Route
            path={routes[3]}
            component={(props) => {
              return (
                <CorporateRiskAccountProfiles
                  riskLevelToSearch={riskLevelToFetch}
                  type={'BLOCK'}
                  {...props}
                />
              )
            }}
          />
          <Route
            path={routes[4]}
            component={(props) => {
              return (
                <CorporateRiskAccountProfiles
                  riskLevelToSearch={riskLevelToFetch}
                  type={'TERMINATE'}
                  {...props}
                />
              )
            }}
          />
        </Switch>
      </ContentContainer>
    )
  }, [riskLevelToFetch])

  useEffect(() => {
    if (props.searchedRiskProfile.searchedRiskProfileAccountId) {
      props.searchRiskProfileByAccountId(
        props.searchedRiskProfile.searchedRiskProfileAccountId,
      )
    }
    return () => {
      props.clearSearchedRiskProfile()
    }
  }, [])

  useEffect(() => {
    setCurrentPage(window.location.pathname)

    return () => {}
  }, [window.location.pathname])

  return (
    <>
      <FlexRow>
        <OutlinedInput
          className={classes.input}
          type="number"
          placeholder="Search for account #"
          value={props.searchedRiskProfile.searchedRiskProfileAccountId || ''}
          onChange={handleRiskProfileSearch}
        />
        <MuiSelect
          handleChange={(e) =>
            setRiskLevelToFetch(e.target.value as RiskLevelTypes)
          }
          label="Risk Level"
          options={riskLevels}
          value={riskLevelToFetch}
        />
        <IconButton onClick={() => props.clearSearchedRiskProfile()}>
          <ArrowBack />
        </IconButton>
      </FlexRow>

      {props.searchedRiskProfile.searchedRiskProfileAccountId ? (
        <SearchedRiskProfile
          isSearching={props.searchedRiskProfile.isSearching}
          searchedRiskProfileAccountId={
            props.searchedRiskProfile.searchedRiskProfileAccountId
          }
          foundRiskProfile={
            props.searchedRiskProfile
              .data as CorporateRiskProfileWithDualAuthStatus
          }
          initializeRiskProfileReview={
            props.initializeCorporateRiskProfileReview
          }
          riskProfileType="CORPORATE"
          riskLevelToSearch={riskLevelToFetch}
        />
      ) : (
        <Fragment>
          <TopNavigationBar
            value={currentPage}
            onChange={handleChange}
            variant="fullWidth"
          >
            <NavigationTab
              value={routes[0]}
              label="NEW"
              component={ForwardedNavLink}
              to={routes[0]}
            />
            <NavigationTab
              value={routes[1]}
              label="PENDING"
              component={ForwardedNavLink}
              to={routes[1]}
            />
            <NavigationTab
              value={routes[2]}
              label="MONITOR"
              component={ForwardedNavLink}
              to={routes[2]}
            />
            <NavigationTab
              value={routes[3]}
              label="BLOCK"
              component={ForwardedNavLink}
              to={routes[3]}
            />
            <NavigationTab
              value={routes[4]}
              label="TERMINATE"
              component={ForwardedNavLink}
              to={routes[4]}
            />
          </TopNavigationBar>
          {MemoizedRoutes}
        </Fragment>
      )}
    </>
  )
}

export default connectStateAndDispatch(
  (state) => ({
    searchedRiskProfile: state.riskBasedProfiles.searchedRiskProfile,
  }),
  {
    searchRiskProfileByAccountId,
    clearSearchedRiskProfile,
    updateSearchedRiskProfileAccountId,
    initializeCorporateRiskProfileReview,
  },
  CorporateRiskManagementNav,
)
