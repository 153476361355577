import {
  FETCH_PENDING_INTERNAL_TRANSFERS_LOADING,
  FETCH_PENDING_INTERNAL_TRANSFERS_SUCCESS,
  FETCH_PENDING_INTERNAL_TRANSFERS_ERROR,
  UPDATE_BULK_TRANSFER_DATA_SUCCESS,
  UPDATE_BULK_TRANSFER_DATA_ERROR,
} from './actions'

const initialState = {
  status: 'pristine',
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PENDING_INTERNAL_TRANSFERS_LOADING: {
      return {
        ...state,
        status: 'loading',
        data: [],
      }
    }
    case FETCH_PENDING_INTERNAL_TRANSFERS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        data,
      }
    }

    case FETCH_PENDING_INTERNAL_TRANSFERS_ERROR: {
      return {
        ...state,
        status: 'error',
        data: [],
      }
    }

    case UPDATE_BULK_TRANSFER_DATA_SUCCESS: {
      const bulkInternalTransfersData = action.payload
      const updatedInternalTransfersData = [...state.data]
      bulkInternalTransfersData.forEach(transferData => {
        const indexOfInternalTransferToUpdate = updatedInternalTransfersData.findIndex(
          transfer => transfer.id === transferData.data.id,
        )
        updatedInternalTransfersData.splice(indexOfInternalTransferToUpdate, 1)
      })
      return {
        ...state,
        data: updatedInternalTransfersData,
        status: 'done',
      }
    }

    case UPDATE_BULK_TRANSFER_DATA_ERROR: {
      return {
        ...state,
        status: 'pristine',
      }
    }

    default:
      return state
  }
}
