import React from 'react'
import PropTypes from 'prop-types'
import { ContentContainer } from 'components/atoms/Generic/index'

import { TablePlaceholder } from 'components/atoms/Table/styles'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import Entity from './Entity/Entity'

import { StyledCorporateUserTable } from '../../pages/Styles'
import CorporateEntityTableHeadings from '../CorporateEntityTableHeadings'

const NaturalCorporateEntities = ({
  entityType,
  entities,
  handleEditEntity,
  isFetchingEntities,
  accountId,
}) => {
  const headings = [
    'First Name',
    'Last Name',
    'ID Number',
    'Roles',
    'Nationality',
    'Is PEP',
    'Linked Account ID',
    'ID Document',
    'Proof Of Residence',
    'Actions',
  ]

  const hasEntities = entities.length > 0

  return (
    <ContentContainer bgcolor={'0px solid transparent'}>
      {isFetchingEntities && <LoadingBlock />}
      {!hasEntities &&
        !isFetchingEntities && (
          <TablePlaceholder>{'No natural entities exist yet'}</TablePlaceholder>
        )}
      {hasEntities &&
        !isFetchingEntities && (
          <StyledCorporateUserTable>
            <CorporateEntityTableHeadings headings={headings} />
            <tbody>
              {entities.map((entity, index) => (
                <Entity
                  accountId={accountId}
                  handleEditEntity={handleEditEntity}
                  key={`${entity.idNumber}${index}`}
                  entity={entity}
                  entityType={entityType}
                />
              ))}
            </tbody>
          </StyledCorporateUserTable>
        )}
    </ContentContainer>
  )
}

NaturalCorporateEntities.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      idNumber: PropTypes.string,
      roles: PropTypes.arrayOf(
        PropTypes.oneOf(['Director', 'Trader', 'Shareholder', 'Ubo']),
      ),
      linkedAccountId: PropTypes.number,
      idDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      proofOfResidenceDocument: PropTypes.shape({
        fileName: PropTypes.string,
        uploadDate: PropTypes.string,
      }),
      uploadState: PropTypes.shape({
        idDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
        proofOfResidenceDocumentUploadState: PropTypes.shape({
          progress: PropTypes.number,
          uploadErrorMessage: PropTypes.string,
        }),
      }),
    }),
  ),
  entityType: PropTypes.string,
  handleEditEntity: PropTypes.func,
  isFetchingEntities: PropTypes.bool,
}

export default NaturalCorporateEntities
