import React from 'react'
import { connect } from 'react-redux'

import { Field, reduxForm } from 'redux-form'
import { required, requiredSelectDropdownValue, maxLength128 } from 'forms/fieldValidators'
import { FormContainer, FormTitle, Button, InputGroup, FormError } from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput, renderUncontrolledCheckbox } from '../fields'
import { submitKycAddressOverride } from './submit'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Override POA Status</FormTitle>
      <p>This will mark Proof of Address as complete.</p>

      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Reason (required)'}
          name={'reason'}
          width={'100%'}
          component={renderDropdown}
          validate={[requiredSelectDropdownValue]}
          options={[
            { value: 'GENERAL', label: 'General' },
            { value: 'FUTURES', label: 'Futures' },
          ]}
        />
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required, maxLength128]}
          component={renderInput}
        />
      </InputGroup>
      <InputGroup justify={'flex-start'} direction={'row'}>
        <Field
          label="Send notifications"
          name="sendNotifications"
          width={'100%'}
          component={renderUncontrolledCheckbox}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({
  form: 'KycAddressOverrideForm',
  onSubmit: submitKycAddressOverride,
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
