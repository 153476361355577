import {
  FETCH_APPLICATION_CONFIG_PROCESS,
  FETCH_APPLICATION_CONFIG_SUCCESS,
  FETCH_APPLICATION_CONFIG_ERROR,
  FETCH_ADMIN_CONFIG_PROCESS,
  FETCH_ADMIN_CONFIG_SUCCESS,
  FETCH_ADMIN_CONFIG_ERROR,
  UPDATE_ADMIN_CONFIG_PROCESS,
  UPDATE_ADMIN_CONFIG_SUCCESS,
  UPDATE_ADMIN_CONFIG_ERROR,
} from './actions'

const initialState = {
  application: {
    status: 'pristine',
    data: [],
  },
  setConfig: {
    status: 'pristine',
    data: [],
  },
  adminConfig: {
    loading: false,
    isUpdating: false,
    data: {
      latestAccountId: '',
    },
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPLICATION_CONFIG_PROCESS: {
      return {
        ...state,
        application: {
          status: 'loading',
          data: [],
        },
      }
    }
    case FETCH_APPLICATION_CONFIG_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        application: {
          status: 'done',
          data,
        },
      }
    }
    case FETCH_APPLICATION_CONFIG_ERROR: {
      return {
        ...state,
        application: {
          ...state.application,
          status: 'error',
        },
      }
    }

    default:
      return state
  }
}
