import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import { NO_RESULT_MESSAGE } from 'helpers/const'

import {
  Table,
  TableBody,
  SelectActionButton,
  selectButtonColors,
} from 'components/atoms/Table/styles'
import { Button, InputGroup } from 'components/atoms/Form'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { saveSelectedWithdrawals } from 'redux/fiat/actions'
import ExpandingItem from 'components/atoms/ExpandingItem/index'
import { BankSources } from 'redux/fiat/constants'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { RenderCheckbox } from 'forms/fields'

import PendingFiatWithdrawsRow from './row'
import PendingWithdrawalsPreviewer from './preview'
import { MainScreenTableHeaders } from './table-headers'

import { getSymbolFromCurrency } from '../../../helpers/currency'
import DownloadBankInstructions from '../DownloadBankInstructions'
import { getSelectedBankAccount } from '../../../redux/fiat/selectors'

const ACTION_TYPES = {
  PROCESS: 'PROCESS',
  REVERSE: 'REVERSE',
}

class PendingFiatWithdraws extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: { data: [] },
      onReview: false,
      formShown: '',
      showRawData: '',
      highlightedId: '',
      banks: ['FNB/RMB', 'Standard Bank', 'Other'],
      otherSelectedWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedFnbWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedStbWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedFastWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      withdrawalActionType: '',
    }
  }

  selectAll = () => {
    const { data } = this.props.pendingWithdraws
    const selected = {
      data: data.map(withdraw => ({
        id: withdraw.withdrawalId,
        reverseFee: false,
      })),
    }
    this.setState({ selected }, () => this.getSelectedValuesForEachBank())
  }

  clearAll = () => {
    this.setState({
      selected: { data: [] },
      otherSelectedWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedFnbWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedStbWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
      selectedFastWithdrawals: { color: selectButtonColors.DEFAULT, data: [] },
    })
  }

  selectByBank = bank => {
    const {
      pendingWithdraws: { data },
    } = this.props
    this.cancelInversion(bank)
    return data.filter(withdrawal => {
      const name = withdrawal.bank.displayName
      if (bank === 'Other' && name !== 'FNB/RMB' && name !== 'Standard Bank') {
        this.selectRow(withdrawal.withdrawalId)
      } else if (bank !== 'Other' && name === bank) {
        this.selectRow(withdrawal.withdrawalId)
        return withdrawal
      }
    })
  }

  selectRow = id => {
    const selected = [...this.state.selected.data]
    const index = selected.findIndex(
      pendingWithdrawal => pendingWithdrawal.id === id,
    )
    if (index < 0) {
      const selectedWitdrawal = {
        id,
        reverseFee: false,
      }
      selected.push(selectedWitdrawal)
    } else {
      selected.splice(index, 1)
    }

    this.setState(
      {
        selected: { data: selected },
      },
      () => this.getSelectedValuesForEachBank(),
    )
  }

  updateSelectedReversefee = id => {
    const selected = [...this.state.selected.data]
    const index = selected.findIndex(
      pendingWithdrawal => pendingWithdrawal.id === id,
    )

    selected[index].reverseFee = !selected[index].reverseFee

    this.setState({
      selected: { data: selected },
    })
  }

  toggleForm = id => {
    const formId = this.state.formShown
    if (formId !== id) {
      this.setState({ formShown: id })
    } else {
      this.setState({ formShown: '' })
    }
  }

  toggleRawData = id => {
    const { showRawData } = this.state
    if (showRawData !== id) {
      this.setState({ showRawData: id })
    } else {
      this.setState({ showRawData: '' })
    }
  }

  toggleReview = () => {
    this.setState({ onReview: !this.state.onReview })
  }

  clearSelectedState = () => {
    this.setState(
      {
        selected: {
          data: [],
        },
        withdrawalActionType: ACTION_TYPES.REVERSE,
      },
      () => this.getSelectedValuesForEachBank(),
    )
    this.toggleReview()
  }

  getSelectedIdsForBank = object =>
    object
      .map(withdrawal => withdrawal.withdrawalId)
      .filter(
        element =>
          this.state.selected.data.findIndex(w => w.id === element) > -1,
      )

  cancelInversion = bank => {
    const {
      pendingWithdraws: {
        byBank: { allOtherWithdrawals, allFnbWithdrawals, allStbWithdrawals },
      },
    } = this.props
    let withdrawsBybank = []
    const isOrange = color => color === selectButtonColors.ORANGE
    if (
      bank === 'FNB/RMB' &&
      isOrange(this.state.selectedFnbWithdrawals.color)
    ) {
      withdrawsBybank = allFnbWithdrawals
    } else if (
      bank === 'Standard Bank' &&
      isOrange(this.state.selectedStbWithdrawals.color)
    ) {
      withdrawsBybank = allStbWithdrawals
    } else if (
      bank === 'Other' &&
      isOrange(this.state.otherSelectedWithdrawals.color)
    ) {
      withdrawsBybank = allOtherWithdrawals
    }

    return this.getSelectedIdsForBank(withdrawsBybank).forEach(element => {
      this.selectRow(element)
    })
  }

  getSelectedValuesForEachBank = () => {
    const {
      pendingWithdraws: { byBank, data },
    } = this.props
    const otherBanksCollection = this.getSelectedIdsForBank(
      byBank.allOtherWithdrawals,
    )
    const fnbCollection = this.getSelectedIdsForBank(byBank.allFnbWithdrawals)
    const stbCollection = this.getSelectedIdsForBank(byBank.allStbWithdrawals)
    const fastWithdrawalsCollection =
      data && data.filter(w => w.isFast === true)
    const fastWithdrawals = this.getSelectedIdsForBank(
      fastWithdrawalsCollection,
    )

    this.setState({
      otherSelectedWithdrawals: {
        color: this.returnValidColor(
          otherBanksCollection,
          byBank.allOtherWithdrawals,
        ),
        data: otherBanksCollection,
      },
      selectedFnbWithdrawals: {
        color: this.returnValidColor(fnbCollection, byBank.allFnbWithdrawals),
        data: fnbCollection,
      },
      selectedStbWithdrawals: {
        color: this.returnValidColor(stbCollection, byBank.allStbWithdrawals),
        data: stbCollection,
      },
      selectedFastWithdrawals: {
        color: this.returnValidColor(
          fastWithdrawals,
          fastWithdrawalsCollection,
        ),
        data: fastWithdrawals,
      },
    })
  }

  returnValidColor = (a, b) => {
    if (a.length === b.length && a.length > 0 && b.length > 0) {
      return selectButtonColors.GREEN
    }
    if (a.length > 0 && a.length < b.length) {
      return selectButtonColors.ORANGE
    }
    if (a.length === 0) {
      return selectButtonColors.DEFAULT
    }
  }

  changeColor = bank =>
    bank === 'FNB/RMB'
      ? this.state.selectedFnbWithdrawals.color
      : bank === 'Standard Bank'
        ? this.state.selectedStbWithdrawals.color
        : bank === 'Other'
          ? this.state.otherSelectedWithdrawals.color
          : selectButtonColors.DEFAULT

  filterFastWithdraws = () => {
    const { data } = this.props.pendingWithdraws
    if (
      this.state.selectedFastWithdrawals.color === selectButtonColors.ORANGE
    ) {
      this.state.selectedFastWithdrawals.data.map(id => this.selectRow(id))
    }
    const fastWithdrawals = data
      .filter(withdrawal => withdrawal.isFast === true)
      .map(w => w.withdrawalId)
    this.setState({ selectedFastWithdrawals: { data: fastWithdrawals } })
    return fastWithdrawals.map(id => this.selectRow(id))
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  countAllWithdrawals = () => {
    const {
      pendingWithdraws: { data },
    } = this.props

    const counter = data
      ? data.reduce((accum, value) => accum + Number(value.amount), 0)
      : 0

    return `${new BigNumber(counter).toFormat(2)}`
  }

  render() {
    const {
      name,
      pendingWithdraws: { status, data },
      reviews,
      accounts,
      expanded,
      setExpand,
      readyForDownload,
      bankAccount,
    } = this.props
    const { currency, country } = bankAccount
    const { selected, selectedFastWithdrawals } = this.state
    const hasWithdrawals = data && data.length > 0
    // TODO: This is really bad so I am removing this
    // var postToStore = saveSelectedWithdrawals(this.state.selected.data)
    const isZambian = country === BankSources.STANBIC_ZM.country
    const showReverseFee = !isZambian
      ? true
      : this.state.withdrawalActionType === ACTION_TYPES.REVERSE

    return (
      <ExpandingItem
        title={`${name}`}
        itemsAmount={(data && data.length) || 0}
        decoration={`${getSymbolFromCurrency(
          currency,
        )}${this.countAllWithdrawals()}`}
        expanded={expanded}
        onExpand={setExpand}
        expandedView={
          <>
            {status === 'loading' && <LoadingBlock />}
            {status === 'done' &&
              data &&
              data.length === 0 && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.WITHDRAWALS.type}
                />
              )}
            {status === 'done' &&
              hasWithdrawals && (
                <div>
                  {this.state.onReview === false ? (
                    <Fragment>
                      <InputGroup justify={'flex-start'}>
                        {this.state.banks.map(option => (
                          <SelectActionButton
                            key={option}
                            onClick={() => this.selectByBank(option)}
                            color={this.changeColor(option)}
                          >
                            {option}
                          </SelectActionButton>
                        ))}
                        <SelectActionButton
                          color={selectedFastWithdrawals.color}
                          onClick={() => {
                            this.filterFastWithdraws()
                          }}
                        >
                          Fast Withdrawals
                        </SelectActionButton>
                        <SelectActionButton onClick={this.selectAll}>
                          Select All
                        </SelectActionButton>
                        <SelectActionButton onClick={this.clearAll}>
                          Clear All
                        </SelectActionButton>
                        {country === BankSources.STANBIC_ZM.country && (
                          <>
                            <div style={{ display: 'flex' }}>
                              <p>Process</p>
                              <RenderCheckbox
                                onCheckFunction={() => {
                                  this.setState({
                                    withdrawalActionType: ACTION_TYPES.PROCESS,
                                  })
                                }}
                                checked={
                                  this.state.withdrawalActionType ===
                                  ACTION_TYPES.PROCESS
                                }
                              />
                            </div>
                            <div style={{ display: 'flex' }}>
                              <p>Reverse</p>
                              <RenderCheckbox
                                onCheckFunction={() => {
                                  this.setState({
                                    withdrawalActionType: ACTION_TYPES.REVERSE,
                                  })
                                }}
                                checked={
                                  this.state.withdrawalActionType ===
                                  ACTION_TYPES.REVERSE
                                }
                              />
                            </div>
                          </>
                        )}
                        <InputGroup justify={'flex-end'}>
                          Selected Items: {selected.data.length}
                        </InputGroup>
                      </InputGroup>
                      <Table>
                        <MainScreenTableHeaders
                          showReverseFee={showReverseFee}
                        />
                        <TableBody>
                          {status === 'done' &&
                            data.map(withdrawal => {
                              const review =
                                reviews.byId[withdrawal.reviewItemId]
                              const { withdrawalId } = withdrawal
                              const selecteWithdrawalIndex = selected.data.findIndex(
                                selectedWithdrawal =>
                                  selectedWithdrawal.id === withdrawalId,
                              )

                              return (
                                <PendingFiatWithdrawsRow
                                  key={withdrawalId}
                                  withdrawal={withdrawal}
                                  review={review && review.data}
                                  selectRow={() => this.selectRow(withdrawalId)}
                                  updateSelectedReversefee={
                                    this.updateSelectedReversefee
                                  }
                                  isZambian={isZambian}
                                  isSelected={selecteWithdrawalIndex !== -1}
                                  showReverseFee={showReverseFee}
                                  withdrawalActionType={
                                    this.state.withdrawalActionType
                                  }
                                  reverseFee={
                                    (selected.data[selecteWithdrawalIndex] &&
                                      selected.data[selecteWithdrawalIndex]
                                        .reverseFee) ||
                                    false
                                  }
                                  toggleForm={this.toggleForm}
                                  formShown={this.state.formShown}
                                  toggleRawData={this.toggleRawData}
                                  showRawData={
                                    this.state.showRawData === withdrawalId
                                  }
                                  isHighlighted={
                                    this.state.highlightedId === withdrawalId
                                  }
                                  setHighlightedId={this.setHighlightedId}
                                />
                              )
                            })}
                        </TableBody>
                        {status === 'done' && (
                          <InputGroup justify={'flex-end'}>
                            <Button
                              disabled={selected.data.length === 0}
                              onClick={this.toggleReview}
                            >
                              Review
                            </Button>
                          </InputGroup>
                        )}
                        {status === 'done' &&
                          readyForDownload && (
                            <InputGroup style={{ textAlign: 'center' }}>
                              <DownloadBankInstructions
                                withdrawals={readyForDownload}
                                bankAccount={bankAccount}
                              />
                            </InputGroup>
                          )}
                      </Table>
                    </Fragment>
                  ) : (
                    <PendingWithdrawalsPreviewer
                      selectedPendingFiatWithDrawals={selected.data}
                      clearSelectedState={this.clearSelectedState}
                      cancel={this.toggleReview}
                      showReverseFee={showReverseFee}
                    />
                  )}
                </div>
              )}
          </>
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  reviews: state.reviews,
  readyForDownload: state.fiat.selectedWithdrawals.readyForDownload,
  accounts: state.accounts.byId,
  bankAccount: getSelectedBankAccount(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveSelectedWithdrawals,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingFiatWithdraws)
