import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'
import {
  FlagToggle,
  FLAG_COLORS,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import moment from 'moment'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details/index'
import RawData from 'components/atoms/RawDataDualAuth/index'

const AccountComments = ({ commentsRows, flagComment, paperStyle }) => {
  const [expandedRow, setExpandedRow] = useState('')
  const toggleSelectedRow = (id) => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  const toggleRow = (id) => {
    toggleSelectedRow(id)
  }
  return (
    <Paper className={paperStyle}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead align="right">
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Comments</TableCell>
              <TableCell>Action Type</TableCell>
              <TableCell>Dual Auth Status</TableCell>
              <TableCell>Rejected dual auth Reason</TableCell>
              <TableCell>Flag Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commentsRows.length === 0 && (
              <TableCell colSpan={6}>
                <TablePlaceholder>{'No comments'}</TablePlaceholder>
              </TableCell>
            )}
            {commentsRows.length > 0 &&
              commentsRows.map((row, index) => {
                const { flag } = row
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <Fragment key={index}>
                    <TableRow>
                      <TableCell
                        onClick={() => toggleRow(row.commentId)}
                        id={labelId}
                        scope="row"
                      >
                        {moment(row.date).format('YYYY-MMM-DD HH:mm')}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.createdBy}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.comments}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.actionType}
                      </TableCell>
                      <TableCell onClick={() => toggleRow(row.commentId)}>
                        {row.dualAuthStatus}
                      </TableCell>
                      <TableCell>
                        {row.actionType === 'DEPOSITS' ||
                        row.actionType ===
                          'BLOCKCHAIN_RECEIVES_AND_CONFIRMATION' ||
                        row.actionType === 'BLOCKCHAIN_SENDS_AND_REVERSAL' ||
                        row.actionType === 'FUNDS' ||
                        row.actionType === 'TOTP_REMOVAL'
                          ? row.comments
                          : row.rejectionReason}
                      </TableCell>
                      <TableCell>
                        <FlagToggle
                          color={!flag ? FLAG_COLORS.GREEN : FLAG_COLORS.RED}
                          className={'fa fa-flag'}
                        >
                          <a
                            style={{ cursor: 'pointer' }}
                            hover
                            onClick={() =>
                              flagComment({
                                flagStatus: !flag,
                                commentId: row.commentId,
                              })
                            }
                          >
                            {!flag ? 'Flag comment' : 'Unflag comment'}
                          </a>
                        </FlagToggle>
                      </TableCell>
                    </TableRow>
                    {expandedRow === row.commentId && (
                      <TableCell colSpan={6}>
                        <Details>
                          <DetailsBlockWithTitle title={'Raw Data'}>
                            <RawData data={row} title={'Raw Data'} />
                          </DetailsBlockWithTitle>
                        </Details>
                      </TableCell>
                    )}
                  </Fragment>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

AccountComments.propTypes = {
  commentsRows: PropTypes.array,
  flagComment: PropTypes.func,
  paperStyle: PropTypes.object,
}

export default AccountComments
