import {
  FETCH_ACCOUNT_DEPOSITS_PROCESS,
  FETCH_ACCOUNT_DEPOSITS_SUCCESS,
  FETCH_ACCOUNT_DEPOSITS_ERROR,
  CLEAR_ACCOUNT_DEPOSITS,
} from './actions'

const initialState = {
  byId: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_DEPOSITS_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            status: 'loading',
          },
        },
      }
    }

    case FETCH_ACCOUNT_DEPOSITS_SUCCESS: {
      const { id, data, hasMoreRecords, skip, limit, fetchMore } = action.payload
      const existingData = state.byId[id] && state.byId[id].data ? state.byId[id].data : []
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data: fetchMore ? [...existingData, ...data] : data,
            skip,
            limit,
            hasMoreRecords,
          },
        },
      }
    }
    case FETCH_ACCOUNT_DEPOSITS_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            data: [],
            error,
          },
        },
      }
    }

    case CLEAR_ACCOUNT_DEPOSITS: {
      return initialState
    }

    default:
      return state
  }
}
