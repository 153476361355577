import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'
import { renderInput, renderRadioGroup } from 'forms/fields'

let Form = (props) => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit, fullName } = props
  return (
    <>
      <FormContainer onSubmit={handleSubmit(onSubmit)} width={'40vw'}>
        <FormTitle>Check PEP result for: {fullName}</FormTitle>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            label={'Enter Comment'}
            name={'comment'}
            width={'100%'}
            validate={[required]}
            component={renderInput}
            fullWidth
          />
        </InputGroup>
        <InputGroup align={'flex-start'} direction={'column'}>
          <Field
            name={'checkStatus'}
            width={'100%'}
            validate={[required]}
            component={renderRadioGroup}
            defaultValue="positive"
            fullWidth
            options={[
              { value: 'positive', label: 'Positive' },
              { value: 'false-positive', label: 'False positive' },
            ]}
          />
        </InputGroup>
        {error && <FormError error={error} />}
        <InputGroup justify={'flex-end'}>
          <Spacer margin="0px 8px">
            <MuiButton type="submit" disabled={submitting || invalid}>
              Submit
            </MuiButton>
          </Spacer>
          <MuiButton onClick={onCancel} color="secondary">
            Cancel
          </MuiButton>
        </InputGroup>
      </FormContainer>
    </>
  )
}

Form = reduxForm({
  initialValues: {
    comment: '',
    checkStatus: 'positive',
  },
})(Form)

export const PEPScreeningForm = connect()(Form)
