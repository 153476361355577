import getAxios from 'helpers/axios'
import { Dispatch } from 'redux'
import { FundingInfoAndEventsRowData } from 'components/organisms/DepositFundingInfo/interfaces'

import ErrorToast from 'components/molecules/ErrorToast'

import { FundingEventReviewData } from './index'

export type ActionTypes =
  | 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_START'
  | 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_SUCCESS'
  | 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_ERROR'
  | 'FETCH_FUNDING_EVENTS_FOR_REVIEW_START'
  | 'FETCH_FUNDING_EVENTS_FOR_REVIEW_SUCCESS'
  | 'FETCH_FUNDING_EVENTS_FOR_REVIEW_ERROR'
  | 'UPDATE_FUNDING_EVENTS'
  | 'UPDATE_FUNDING_EVENTS_ERROR'
  | 'CLEAR_FUNDING_REVIEW'
  | 'FETCH_FUNDING_REVIEW_SUCCESS'
  | 'FETCH_FUNDING_DEPOSIT_BY_STATUS_PROCESS'
  | 'FETCH_FUNDING_DEPOSIT_BY_STATUS_SUCCESS'
  | 'FETCH_FUNDING_DEPOSIT_BY_STATUS_ERROR'
  | 'RESET_FUNDING_DEPOSIT_BY_STATUS'

export interface FundingEventsAction {
  type: ActionTypes
  payload?: { data: FundingInfoAndEventsRowData }
}

export interface UpdateFundingEventsAction {
  type: ActionTypes
  payload?: { values: FundingInfoAndEventsRowData }
}

export const fetchAccountFundingEvent =
  (id: string | number) => async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({
      type: 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_START',
    })
    try {
      const { data } = await getAxios().get(`/funding/events/${id}`)
      dispatch({
        type: 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_SUCCESS',
        payload: { data },
      })
    } catch (error) {
      dispatch({
        type: 'FETCH_FUNDING_EVENTS_FOR_ACCOUNT_ERROR',
      })
    }
  }

export const fetchFundingEventForReview =
  (currency: string) => async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({
      type: 'FETCH_FUNDING_EVENTS_FOR_REVIEW_START',
    })
    try {
      const { data } = await getAxios().get(
        `/funding/available/events/${currency}`,
      )
      dispatch({
        type: 'FETCH_FUNDING_EVENTS_FOR_REVIEW_SUCCESS',
        payload: {
          data:data.filter((deposit:FundingInfoAndEventsRowData)=>!deposit.suspended),
          suspendedDeposits:data.filter((deposit:FundingInfoAndEventsRowData)=>deposit.suspended)
        },
      })
    } catch (error) {
      dispatch({
        type: 'FETCH_FUNDING_EVENTS_FOR_REVIEW_ERROR',
      })
    }
  }

export const updateFundingEvents =
  (values: FundingInfoAndEventsRowData) => async (dispatch: Dispatch<UpdateFundingEventsAction>) => {
    try {
      dispatch({
        type: 'UPDATE_FUNDING_EVENTS',
        payload: {
          values,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'UPDATE_FUNDING_EVENTS_ERROR',
      })
    }
  }

export const fetchFundingReview =
  (fundingEvent: FundingEventReviewData) =>
  async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({ type: 'CLEAR_FUNDING_REVIEW' })
    try {
      const { data } = await getAxios().get(
        `/funding/screen/${fundingEvent.identifier}/preview/${fundingEvent.accountId}`,
      )
      dispatch({
        type: 'FETCH_FUNDING_REVIEW_SUCCESS',
        payload: { data: { ...data, fundingEvent } },
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

export const clearFundingReview =
  () => async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({ type: 'CLEAR_FUNDING_REVIEW' })
  }

export const fetchDepositByStatus =
  (currency: string, status: string) =>
  async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({
      type: 'FETCH_FUNDING_DEPOSIT_BY_STATUS_PROCESS',
    })
    try {
      const res = await getAxios().get(
        `/funding/${currency}/${status}/deposits-by-status`,
      )
      const { data } = res
      dispatch({
        type: 'FETCH_FUNDING_DEPOSIT_BY_STATUS_SUCCESS',
        payload: { data },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: 'FETCH_FUNDING_DEPOSIT_BY_STATUS_ERROR',
      })
    }
  }

export const clearFundingDepositByStatus =
  () => async (dispatch: Dispatch<FundingEventsAction>) => {
    dispatch({
      type: 'RESET_FUNDING_DEPOSIT_BY_STATUS',
    })
  }
