import React, { Component, Fragment } from 'react'
import ExpandingItem from 'components/atoms/ExpandingItem'
import ApplicationHttpRow from './applicationHttpRow'

import InfiniteScroll from 'react-infinite-scroll-component'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'

class LogsMainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      skip: 0,
      limit: 100,
    }
  }

  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  mapDataToTbl = logsList => {
    if (logsList && logsList.length) {
      return logsList.map((log, i) => <ApplicationHttpRow key={i++} log={log} />)
    }
    return null
  }

  fetchMore = () => {
    const id = this.props.match.params.accountId
    const { fetchLogs, title } = this.props
    const { skip, limit } = this.state
    fetchLogs({ id, type: title, limit, skip: skip + limit })
  }

  render() {
    const { title, data, status } = this.props
    const { expanded } = this.state
    return (
      <ExpandingItem
        title={title}
        itemsAmount={data && data.length || 0}
        expanded={expanded}
        onExpand={this.toggleView}
        opensMultiple
        expandedView={(
          status === 'done' && (
            <Fragment>
              <Paper >
                <TableContainer>
                  <InfiniteScroll
                    dataLength={data && data.length}
                    next={this.fetchMore}
                    useWindow={false}
                    height={500}
                  >
                    <Table stickyHeader={true} aria-label="sticky table">
                      <TableHead>
                        <TableCell width={'10%'}>AccountId</TableCell>
                        <TableCell width={'10%'}>IP</TableCell>
                        <TableCell width={'10%'}>Country For</TableCell>
                        <TableCell width={'12%'}>Device Id</TableCell>
                        <TableCell width={'11%'}>Request Method</TableCell>
                        <TableCell width={'30%'}>Request Url</TableCell>
                        <TableCell width={'12%'}>Timestamp</TableCell>
                      </TableHead>
                      <TableBody>{this.mapDataToTbl(data)}</TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Paper>
            </Fragment>
          )
        )
        }
      />
    )
  }
}

export default LogsMainContainer
