import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'
import moment from 'moment'

export const FETCH_ACCOUNT_TRANSACTIONS_PROCESS =
  'transactions/FETCH_ACCOUNT_TRANSACTIONS_PROCESS'
export const FETCH_ACCOUNT_TRANSACTIONS_SUCCESS =
  'transactions/FETCH_ACCOUNT_TRANSACTIONS_SUCCESS'
export const FETCH_ACCOUNT_TRANSACTIONS_ERROR =
  'transactions/FETCH_ACCOUNT_TRANSACTIONS_ERROR'

export const DOWNLOAD_ACCOUNT_TRANSACTIONS_PROCESS =
  'transactions/DOWNLOAD_ACCOUNT_TRANSACTIONS_PROCESS'
export const DOWNLOAD_ACCOUNT_TRANSACTIONS_SUCCESS =
  'transactions/DOWNLOAD_ACCOUNT_TRANSACTIONS_SUCCESS'
export const DOWNLOAD_ACCOUNT_TRANSACTIONS_ERROR =
  'transactions/DOWNLOAD_ACCOUNT_TRANSACTIONS_ERROR'
export const RESET_TRANSACTIONS_LIST = 'transactions/RESET_TRANSACTIONS_LIST'

export const FETCH_ACCOUNT_PENDING_BLOCKCHAIN_RECEIVES_ERROR =
  'transactions/FETCH_ACCOUNT_PENDING_BLOCKCHAIN_RECEIVES_ERROR'
export const FETCH_BLOCKCHAIN_RECEIVES_SUCCESS =
  'transactions/ FETCH_BLOCKCHAIN_RECEIVES_SUCCESS'
export const FETCH_BLOCKCHAIN_RECEIVES_PROCESS =
  'transactions/FETCH_BLOCKCHAIN_RECEIVES_PROCESS'
export const FETCH_ACCOUNT_TRANSACTIONS_FOR_PRINT_SUCCESS =
  'transaction/FETCH_ACCOUNT_TRANSACTIONS_FOR_PRINT_SUCCESS'
export const FETCH_ACCOUNT_TRANSACTIONS_FOR_PRINT_ERROR =
  'transaction/FETCH_ACCOUNT_TRANSACTIONS_FOR_PRINT_ERROR'

const massageData = arr =>
  arr.map(tx => {
    const date = new Date(tx.eventAt)
    tx.timestampValues = {
      timestamp: date.toGMTString(),
      timestampValue: date.getTime(),
    }
    return tx
  })

export const fetchTransactions = ({
  id,
  skip = 0,
  limit = 100,
  fromDate,
  toDate,
}) => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNT_TRANSACTIONS_PROCESS,
    payload: { id, fromDate, toDate },
  })
  let res = null
  try {
    res = await getAxios().get(
      `/account/transactionhistory/${id}/${skip}/${limit}?fromDate=${fromDate}&toDate=${toDate}`,
    )

    const { data } = res
    dispatch({
      type: FETCH_ACCOUNT_TRANSACTIONS_SUCCESS,
      payload: {
        id,
        data: massageData(data),
        skip,
        limit,
        hasMoreRecords: data.length !== 0,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_TRANSACTIONS_ERROR,
      payload: {
        id,
        error,
      },
    })
  }
}

export const fetchBlockChainReceives = id => async dispatch => {
  dispatch({
    type: FETCH_BLOCKCHAIN_RECEIVES_PROCESS,
    payload: {
      id,
    },
  })
  let pendingReceives = null
  try {
    const receives = await getAxios().get(`/pending/blockchain/receives/${id}`)
    pendingReceives = receives.data
    dispatch({
      type: FETCH_BLOCKCHAIN_RECEIVES_SUCCESS,
      payload: {
        id,
        data: pendingReceives,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ACCOUNT_PENDING_BLOCKCHAIN_RECEIVES_ERROR,
      payload: {
        id,
        error,
      },
    })
  }
}

export const clearTransactions = ({ id }) => dispatch => {
  dispatch({
    type: RESET_TRANSACTIONS_LIST,
  })
}

export const downloadTransactionStatements = ({
  accountId,
  from,
  to,
}) => async dispatch => {
  dispatch({
    type: DOWNLOAD_ACCOUNT_TRANSACTIONS_PROCESS,
    payload: {
      accountId,
    },
  })

  try {
    const res = await getAxios().get(
      `/account/transactionhistory/statementdownload/${accountId}/${from}/${to}`,
    )
    dispatch({
      type: DOWNLOAD_ACCOUNT_TRANSACTIONS_SUCCESS,
      payload: {
        accountId,
      },
    })
    return res.data
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: DOWNLOAD_ACCOUNT_TRANSACTIONS_ERROR,
      payload: {
        accountId,
      },
    })
  }
}
