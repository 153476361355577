import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { VeriffSession } from 'interfaces/veriffSessions'
import { Dispatch } from 'react'

export type VeriffSessionsActionTypes =
  | 'FETCH_VERIFF_SESSIONS_START'
  | 'FETCH_VERIFF_SESSIONS_SUCCESS'
  | 'FETCH_VERIFF_SESSIONS_ERROR'

export interface FetchVeriffSessionsDispatch {
  type: VeriffSessionsActionTypes
  payload?: VeriffSession
}

export interface VeriffSessionsResponse {
  data: VeriffSession
}

export const fetchVeriffAccountSessionsBySourceId = (
  sourceId: string,
) => async (dispatch: Dispatch<FetchVeriffSessionsDispatch>) => {
  try {
    dispatch({ type: 'FETCH_VERIFF_SESSIONS_START' })

    const { data }: VeriffSessionsResponse = await getAxios().get(
      `/accounts/kyc/veriff-sessions/${sourceId}`,
    )

    dispatch({ type: 'FETCH_VERIFF_SESSIONS_SUCCESS', payload: data })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: 'FETCH_VERIFF_SESSIONS_ERROR' })
  }
}
