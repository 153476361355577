import React from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import config from '../../../config'

const NavigateToZendesk = props => {
  const url = config.zendeskSupportUrl + '/agent/search/1?q=' + props.email
  return (
    <MuiButton href={url} target={'_blank'} variant='contained'>
      Go to Zendesk
    </MuiButton>
  )
}

export default NavigateToZendesk
