import React, { useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAccountDeposits } from 'redux/selectors/deposits'
import {
  fetchAccountDeposits,
  clearAccountDepositsList,
} from 'redux/deposits/actions'
import IconLoading from 'components/atoms/IconLoading'
import DepositRow from 'components/organisms/DepositRow'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import {
  TablePlaceholder,
  TitleContainer,
  Actions,
  ActionLabel,
  ActionInput,
} from 'components/atoms/Table/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ShowStatus } from 'components/atoms/Generic/index'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { addTotalAmount } from './accountTotals'

function descendingComparator(a, b, orderBy) {
  const newA = a[orderBy]
  const newB = b[orderBy]
  if (typeof newA === 'object' && orderBy === 'statuses') {
    if (newB[0].status < newA[0].status) {
      return -1
    }
    if (newB[0].status > newA[0].status) {
      return 1
    }
    return 0
  }
  let valueA
  let valueB
  {
    b[orderBy] === undefined ? (valueB = -1) : (valueB = b[orderBy])
  }
  {
    a[orderBy] === undefined ? (valueA = -1) : (valueA = a[orderBy])
  }
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'source',
    disablePadding: false,
    label: 'Source',
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'currency',
    disablePadding: false,
    label: 'Currency',
  },
  {
    id: 'statuses',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Reference',
  },
  {
    id: 'dateTime',
    disablePadding: false,
    label: 'Date',
  },
  { id: '', disablePadding: false, label: '' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountDeposits(props) {
  const { accountId } = props.match.params
  const { deposits } = props

  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [skip, setSkip] = React.useState(0)
  const [limit, setLimit] = React.useState(100)
  const [filter, setFilter] = React.useState('')
  const [expandRawData, updateRawData] = React.useState('')
  const [addNoteFormShown, updateAddNoteFormshown] = React.useState('')
  const [comments, setComments] = React.useState([])
  const [commentsStatus, updatecommentsStatus] = React.useState('persist')
  const [highlightedId, updateHighlightedId] = React.useState('')
  const [hideIgnoredDeposits, setHideIgnoredDeposits] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const ref = useRef()

  React.useEffect(() => {
    props.fetchAccountDeposits({
      id: accountId,
      limit,
      skip,
      fetchMore: false,
    })
    return () => props.clearAccountDepositsList()
  }, [])

  const showRawData = (id) => {
    if (id !== expandRawData) {
      updateRawData(id)
    } else {
      updateRawData('')
    }
  }

  const toggleAddNotesForm = (id) => {
    if (id !== addNoteFormShown) {
      updateAddNoteFormshown(id)
    } else {
      updateAddNoteFormshown('')
    }
  }

  const updateComments = async (depositId) => {
    updatecommentsStatus('loading')
    const data = await fetchDepositComments(depositId)
    setComments(data)
    updatecommentsStatus('done')
  }

  const toggleForm = (id) => {
    updateAddNoteFormshown('')
    updateComments(id)
    updateRawData('')
  }

  const fetchMoreDeposits = () => {
    const { limit: depositLimit } = props.deposits
    if (props.deposits.hasMoreRecords !== false) {
      setSkip(skip + depositLimit)
    }
    props.fetchAccountDeposits({
      id: accountId,
      skip: skip + depositLimit,
      limit: depositLimit,
      fetchMore: true,
    })
  }

  const filterData = (data) => {
    let dataToFilter
    if (hideIgnoredDeposits) {
      dataToFilter = data.filter((dat) => dat.statuses[0].status !== 'IGNORED')
    } else {
      dataToFilter = data
    }
    if (!filter) return dataToFilter

    const filteredData = dataToFilter.filter((item) => {
      const { description, status, insertedAt, currency, amount } = item

      const descriptionFilter = description.toLowerCase().includes(filter)
      const statusFilter = status.toLowerCase().includes(filter)
      const currencyFilter = currency.toLowerCase().includes(filter)
      const dateFilter = insertedAt.toLowerCase().includes(filter)
      const amountFilter = amount.toString().toLowerCase().includes(filter)
      return (
        descriptionFilter ||
        statusFilter ||
        currencyFilter ||
        dateFilter ||
        amountFilter
      )
    })
    return filteredData
  }

  const depositsData = stableSort(
    filterData((deposits && deposits.data) || []),
    getComparator(order, orderBy),
  )

  const setHighlightedId = (id) => {
    updateHighlightedId(id === highlightedId ? null : id)
  }

  const returnEndMessage = (txList) => {
    if (txList.data && txList.data.length === 0 && txList.status === 'done') {
      return <ShowStatus>This account has no deposits</ShowStatus>
    }
    if (txList.status === 'loading') {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait...
          </ShowStatus>
        </TablePlaceholder>
      )
    }
    if (!deposits.hasMoreRecords) {
      return (
        <TablePlaceholder>
          <ShowStatus>No more deposits to show</ShowStatus>
        </TablePlaceholder>
      )
    }
  }
  const depositsTotal = useMemo(
    () => addTotalAmount((deposits && deposits.data) || []),
    [deposits],
  )
  return (
    <ErrorBoundary message="Accounts Deposits">
      <TitleContainer>
        <>
          <Actions justify="flex-start">
            {`Total deposits: ${depositsTotal}`}
          </Actions>
          <Actions justify="flex-end">
            <input
              style={{ width: '20px', height: '22px' }}
              type="checkbox"
              onChange={(event) => setHideIgnoredDeposits(event.target.checked)}
            />
            <label>Hide Ignored Status</label>
          </Actions>
          <Actions justify="center">
            <ActionLabel>Filter:</ActionLabel>
            <ActionInput
              marginRight={0}
              onChange={(event) => setFilter(event.target.value.toLowerCase())}
            />
          </Actions>
        </>
      </TitleContainer>
      <>
        <div ref={ref} className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <InfiniteScroll
                scrollableTarget={'test'}
                dataLength={deposits.data ? deposits.data.length : 0}
                next={fetchMoreDeposits}
                hasMore={deposits.hasMoreRecords}
                useWindow={false}
                loader={
                  deposits.status === 'loading' ? (
                    <TablePlaceholder>
                      <ShowStatus>
                        <IconLoading />
                        Loading...
                      </ShowStatus>
                    </TablePlaceholder>
                  ) : (
                    <></>
                  )
                }
                onScroll={() => {}}
                height={600}
                endMessage={returnEndMessage(deposits)}
              >
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={(deposits.data && deposits.data.length) || 0}
                  />
                  <TableBody id="test">
                    {
                      <>
                        {depositsData &&
                          depositsData.length > 0 &&
                          depositsData.map((tx, i) => (
                            <DepositRow
                              tx={tx}
                              key={i}
                              deposits={deposits}
                              accountId={accountId}
                              showRawData={showRawData}
                              txList={depositsData}
                              addNoteFormShown={
                                addNoteFormShown === tx.depositId
                              }
                              toggleAddNotesForm={toggleAddNotesForm}
                              expandItem={() => toggleForm(tx.depositId)}
                              expandRawData={expandRawData === tx.depositId}
                              updateComments={updateComments}
                              comments={comments}
                              skip={skip}
                              limit={limit}
                              fetchMore={false}
                              commentsStatus={commentsStatus}
                              setHighlightedId={setHighlightedId}
                              isHighlighted={highlightedId === tx.depositId}
                            />
                          ))}
                      </>
                    }
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </TableContainer>
          </Paper>
        </div>
      </>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  deposits: getAccountDeposits(state),
  allDepositsComments: state.depositsComments,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountDeposits,
      clearAccountDepositsList,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountDeposits)
