import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const updateRequestedAction = async values => {
  try {
    const { primaryAccountId, action, state } = values
    const res = await getAxios().put('/account/requested-actions', {
      accountId: primaryAccountId,
      action,
      state,
    })
    if (res.status === 204) {
      toast('Updated requested action for account', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
  } catch (error) {
    ErrorToast(error)
  }
}
