import React, { useCallback, useState } from 'react'
import { Button } from '@material-ui/core'
import moment from 'moment'
import {
  RequestedAccountActionsData,
  RequestedAccountActionState,
} from '../../../../redux/requested-account-actions'
import { updateRequestedAction } from './update'
import { TablePlaceholder } from '../../../../components/atoms/Table/styles'
import CustomTable from '../../../../components/atoms/CustomTable'
import Row from '../../../../components/atoms/CustomTable/Row'
import { titleCase } from '../../../../forms/AddRequestedAccountAction'
import { ExpansionPanelInner } from './styles'

const getStateColour = (state: RequestedAccountActionState) => {
  switch (state) {
    case RequestedAccountActionState.REQUESTED:
      return 'orange'
    case RequestedAccountActionState.CANCELLED:
      return 'red'
    case RequestedAccountActionState.COMPLETED:
      return 'green'
    default:
      return 'orange'
  }
}

const StateItem = ({ state }: { state: RequestedAccountActionState }) =>
  <div style={{ color: getStateColour(state) }}>
    {state}
  </div>

interface IUpdateRequestButtonProps {
  hidden: boolean,
  color?: 'primary' | 'secondary' | 'default'
  onClick: () => void
  children: React.ReactNode
}

const UpdateRequestButton = ({
  hidden, onClick, color, children,
}: IUpdateRequestButtonProps) => {
  if (hidden) return null

  return (
    <Button
      variant="contained"
      color={color ?? 'primary'}
      onClick={onClick}
      style={{ marginRight: '10px' }}>
      {children}
    </Button>
  )
}

interface IRequestedAccountActionsItems {
  status: 'pristine' | 'loading' | 'done' | 'error',
  data: RequestedAccountActionsData[] | null,
  refresh: () => void
}

const RequestedActionItems = ({ data, status, refresh }: IRequestedAccountActionsItems) => {
  if (status === 'loading') return null
  const [expandedRowId, setExpandedRowId] = useState('')
  const headings = ['action', 'state', 'initiator', 'lastUpdated']

  const handleUpdateRequest = useCallback(async (state: RequestedAccountActionState, requestedAction: RequestedAccountActionsData) => {
    const { primaryAccountId, action } = requestedAction
    await updateRequestedAction({ state, primaryAccountId, action })
    refresh()
  }, [refresh])

  if (status === 'error' || data == null) {
    return (
      <TablePlaceholder>
        <span>Unable to fetch requested account actions</span>
      </TablePlaceholder>
    )
  }

  if (data.length <= 0) {
    return (
      <TablePlaceholder>
        <span>Account has no requested actions</span>
      </TablePlaceholder>
    )
  }

  return (
    <CustomTable
      // @ts-ignore
      headings={headings}
      customRows={data.map((requestedAction, index) => (
        <Row
          onExpand={setExpandedRowId}
          expandedRowId={expandedRowId}
          key={index}
          row={{
            ...requestedAction,
            action: titleCase(requestedAction.action),
            state: <StateItem state={requestedAction.state}/>,
            lastUpdated: moment(requestedAction.createdAt).format('YYYY/MM/DD HH:mm'),
            id: index,
          }}
          headings={headings}
          detailsHeading={'Manual override'}
          height='50px'
          customExpandData={
            <ExpansionPanelInner>
              <h3>{requestedAction.action}</h3>
              <UpdateRequestButton
                hidden={requestedAction.state === RequestedAccountActionState.REQUESTED}
                color={'default'}
                onClick={() => handleUpdateRequest(RequestedAccountActionState.REQUESTED, requestedAction)}>
                RESET REQUEST
              </UpdateRequestButton>
              <UpdateRequestButton
                hidden={RequestedAccountActionState.isTerminal(requestedAction.state)}
                color={'secondary'}
                onClick={() => handleUpdateRequest(RequestedAccountActionState.CANCELLED, requestedAction)}>
                CANCEL REQUEST
              </UpdateRequestButton>
              <UpdateRequestButton
                hidden={RequestedAccountActionState.isTerminal(requestedAction.state)}
                onClick={() => handleUpdateRequest(RequestedAccountActionState.COMPLETED, requestedAction)}>
                COMPLETE REQUEST
              </UpdateRequestButton>
            </ExpansionPanelInner>
          }
        />
      ))}
      showRawData={false}
    />
  )
}

export default RequestedActionItems
