import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from 'components/atoms/Form'
import { Actions, Title } from 'components/atoms/Table/styles'
import { SubmitCurrencyPairs } from 'forms/OrderTypes/submit'
import {
  fetchCurrenciesPairs,
  fetchPostOnlyModeData,
  togglePostOnlyMode,
} from 'redux/currencyPairs/actions'
import { resetUpdatedOrderTypesList } from 'redux/orderTypesForCurrencyPairs/actions'
import LoadingBlock from 'components/molecules/LoadingBlock/index'

import { StyledOrderTypesHeader } from './Styles'
import SimpleModal from '../Modal'
import OrderTypesTable from './OrderTypesTable'
import { FlexRow } from 'components/layout'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'

class OrderTypes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isUpdatingCurrencyPairsOrderTypes: false,
      postOnlyModeModalOpen: false,
    }
    this.togglePostOnlyModeModal = this.togglePostOnlyModeModal.bind(this)
    this.handleProceed = this.handleProceed.bind(this)
  }

  componentDidMount() {
    this.props.fetchCurrenciesPairs()
    this.props.fetchPostOnlyModeData()
  }
  componentWillUnmount() {
    this.props.resetUpdatedOrderTypesList()
  }

  mapCurrencyPairs = (currencyPairs) =>
    currencyPairs.map((currencyPair) => ({
      value: currencyPair.currencyPairSymbol,
      label: currencyPair.currencyPairSymbol,
    }))

  setPage = (page) => {
    this.setState({
      page,
    })
  }

  handleUpdateSearchedCurrencyPair = (value, action) => {
    if (action.action !== 'input-blur' && action.action !== 'menu-close') {
      this.setState({
        searchedCurrencyPair: value,
      })
    }
  }

  setRowsPerPage = (rowsPerPage) => {
    this.setState({
      rowsPerPage,
    })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage)
  }

  handleChangeRowsPerPage = (event) => {
    this.setRowsPerPage(parseInt(event.target.value, 10))
    this.setPage(0)
  }

  toggleUpdatingCurrencyPairsOrderTypes = (loading) => {
    this.setState({
      isUpdatingCurrencyPairsOrderTypes: loading,
    })
  }

  handleSubmitUpdatedOrderTypesForCurrencyPairs = async () => {
    this.toggleUpdatingCurrencyPairsOrderTypes(true)
    await SubmitCurrencyPairs(this.props.orderTypesDataToUpdate)
    this.props.resetUpdatedOrderTypesList()
    this.toggleUpdatingCurrencyPairsOrderTypes(false)
  }

  togglePostOnlyModeModal() {
    this.setState((prev) => ({
      ...prev,
      postOnlyModeModalOpen: !prev.postOnlyModeModalOpen,
    }))
  }

  async handleProceed() {
    await this.props.togglePostOnlyMode(
      this.props.currencyPairs.data,
      this.props.postOnlyModeData.isInPostOnlyMode,
    )
    this.togglePostOnlyModeModal()
  }

  // This is to just make sure all the data looks the same when comparing previous stored data as we only store
  // the valr pairs and order types to restore not everything
  // So we just have to map the data and return the stored data where needed for ui purposes only
  buildPreviousDataWithStoredData() {
    return this.props.currencyPairs.data.map((pair) => {
      const storedData =
        this.props.postOnlyModeData.previousCurrencyPairOrderTypesDataStored.find(
          (storedCurrencyPair) =>
            storedCurrencyPair.currencyPairSymbol === pair.currencyPairSymbol,
        )
      if (storedData) {
        return storedData
      }
      return pair
    })
  }

  render() {
    const { currencyPairs, orderTypesDataToUpdate } = this.props
    const { isUpdatingCurrencyPairsOrderTypes } = this.state
    const {
      isInPostOnlyMode,
      isUpdatingPostOnlyMode,
      isFetchingPostOnlyModeData,
      previousCurrencyPairOrderTypesDataStored,
    } = this.props.postOnlyModeData

    const isLoading =
      currencyPairs.status !== 'done' || isFetchingPostOnlyModeData
    return (
      <Fragment>
        {isLoading && (
          <LoadingBlock message="Loading currency pairs order types" />
        )}
        {!isLoading && (
          <>
            <StyledOrderTypesHeader>
              <Title>Edit Currency Pairs Order Types</Title>
              <Actions margin="20px 0px" justify={'space-between'}>
                <Button onClick={this.togglePostOnlyModeModal}>
                  Turn {isInPostOnlyMode ? 'off' : 'on'} post only mode
                </Button>
                <Spacer margin="0px 8px" />
                <Button
                  onClick={this.handleSubmitUpdatedOrderTypesForCurrencyPairs}
                  main="true"
                  disabled={
                    orderTypesDataToUpdate.length === 0 ||
                    isUpdatingCurrencyPairsOrderTypes
                  }
                  type="button"
                >
                  Submit
                  {isUpdatingCurrencyPairsOrderTypes ? 'ing' : ''}
                </Button>
              </Actions>
            </StyledOrderTypesHeader>
            <br />
            <OrderTypesTable
              data={currencyPairs.data}
              options={this.mapCurrencyPairs(currencyPairs.data)}
              status={currencyPairs.status}
            />
          </>
        )}
        <SimpleModal
          title={`Go ${isInPostOnlyMode ? 'out of' : 'in to'} post only mode ?`}
          disabled={isUpdatingPostOnlyMode}
          open={this.state.postOnlyModeModalOpen}
          handleClose={this.togglePostOnlyModeModal}
          message={`Are you sure you want to go ${
            isInPostOnlyMode ? 'out of' : 'in to'
          } post only mode ?`}
          buttonsHidden
        >
          <div style={{ width: '60vw' }}>
            {isInPostOnlyMode ? (
              <OrderTypesTable
                data={this.buildPreviousDataWithStoredData()}
                options={this.mapCurrencyPairs(
                  previousCurrencyPairOrderTypesDataStored,
                )}
                status={currencyPairs.status}
                isModalTable
                isInPostOnlyMode={isInPostOnlyMode}
                isUpdatingPostOnlyMode={isUpdatingPostOnlyMode}
              />
            ) : (
              <OrderTypesTable
                data={currencyPairs.data}
                options={this.mapCurrencyPairs(currencyPairs.data)}
                status={currencyPairs.status}
                isModalTable
                isInPostOnlyMode={isInPostOnlyMode}
                isUpdatingPostOnlyMode={isUpdatingPostOnlyMode}
              />
            )}
            <FlexRow>
              <MuiButton
                color="secondary"
                disabled={isUpdatingPostOnlyMode}
                onClick={this.togglePostOnlyModeModal}
                type="button"
              >
                Cancel
              </MuiButton>
              <Spacer margin="0px 8px" />
              <MuiButton
                disabled={isUpdatingPostOnlyMode}
                onClick={this.handleProceed}
                type="button"
              >
                Go {isInPostOnlyMode ? 'out of post' : 'into'} post only mode
              </MuiButton>
            </FlexRow>
          </div>
        </SimpleModal>
      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  currencyPairs: state.currencyPairs,
  orderTypesDataToUpdate:
    state.orderTypesForCurrencyPairs.orderTypesDataToUpdate,
  postOnlyModeData: state.currencyPairs.postOnlyModeData,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCurrenciesPairs,
      resetUpdatedOrderTypesList,
      fetchPostOnlyModeData,
      togglePostOnlyMode,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(OrderTypes)
