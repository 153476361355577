import {
  FETCH_HISTORY_LOGS_PROCESS,
  FETCH_HISTORY_LOGS_SUCCESS,
  FETCH_HISTORY_LOGS_ERROR,
  RESET_HISTORY_LOGS,
} from './actions'

const initialState = {
  logs: {
    status: 'pristine',
    error: null,
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY_LOGS_PROCESS: {
      return {
        ...state,
        logs: {
          ...state.logs,
          error: null,
          status: 'loading',
        },
      }
    }

    case FETCH_HISTORY_LOGS_SUCCESS: {
      const { data, hasMoreRecords, skip, limit } = action.payload
      const existingData = state.logs.data ? state.logs.data : []
      return {
        ...state,
        logs: {
          error: null,
          status: 'done',
          data: [...existingData, ...data],
          hasMoreRecords,
          skip,
          limit,
        },
      }
    }

    case FETCH_HISTORY_LOGS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        logs: {
          ...state.logs,
          error,
          status: 'error',
        },
      }
    }

    case RESET_HISTORY_LOGS: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}
