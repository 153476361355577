import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { reset } from 'redux-form'
import { fetchDepositReview } from 'redux/fiat/actions'

export const submit = async (values, dispatch) => {
  const { depositId, data, notes } = values
  data.notes = notes

  try {
    await getAxios().post('/deposit/comments', {
      depositId, data,
    })
    toast('Comment successfully added', {
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    })
    dispatch(reset(`depositCommentsForm+${depositId}`))
    dispatch(fetchDepositReview())
  } catch (e) {
    toast(`Failed to add a comment,|| ${e.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 4000,
    })
  }
}
