import React, { FC, Fragment, useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import {
  RecommendationStatusTypes,
  RiskLevelTypes,
  RiskProfileWithDualAuthStatus,
} from 'interfaces/riskProfile'
import { RiskScoreForm } from 'forms/UpdateRiskScore'
import { riskLevelCalculator } from 'components/atoms/RiskProfiles'
import moment from 'moment'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { Details, JsonBlock } from 'components/atoms/Details'
import { Link } from 'components/atoms/Table/rows/clickableId'
import { Title } from 'components/atoms/Title'
import CircularSpinner from 'components/atoms/Spinners'
import SimpleModal from 'components/organisms/Modal'
import getAxios from 'helpers/axios'

interface IIndividualRiskProfileRow {
  accountsProfilesData: RiskProfileWithDualAuthStatus
  toggleSelectedRow: (value: number) => void
  isSelected: boolean
  initializeRiskProfileReview: () => Promise<void>
  type: RecommendationStatusTypes
  riskLevelToSearch: RiskLevelTypes
  riskProfileType?: 'CORPORATE' | 'INDIVIDUAL'
  getAllProfiles?: (
    type: RecommendationStatusTypes,
    riskLevelTypes: RiskLevelTypes,
    riskProfileType?: 'CORPORATE' | 'INDIVIDUAL',
  ) => Promise<void> | null
}
const RiskAccountsProfilesRow: FC<IIndividualRiskProfileRow> = (props) => {
  const {
    accountsProfilesData,
    toggleSelectedRow,
    isSelected,
    initializeRiskProfileReview,
    type,
    riskLevelToSearch,
    riskProfileType,
    getAllProfiles,
  } = props

  const {
    id,
    accountId,
    recommendationStatus,
    riskScore,
    dualAuthStatus,
    name,
    surname,
    createdAt,
    updatedAt,
  } = accountsProfilesData
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const toggleConfirmationDialog = () =>
    setShowConfirmationDialog((prevState) => !prevState)

  const handleInitialize = async () => {
    setIsBusy(true)
    await initializeRiskProfileReview()
    setIsBusy(false)
    if (getAllProfiles) {
      await getAllProfiles(type, riskLevelToSearch, riskProfileType)
    }
  }
  return (
    <Fragment>
      <TableRow onClick={() => toggleSelectedRow(id)}>
        <TableCell>{accountId ? <Link id={accountId} /> : '--'}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{surname}</TableCell>
        <TableCell>{recommendationStatus}</TableCell>
        <TableCell>{dualAuthStatus || '--'}</TableCell>
        <TableCell>{riskScore || '--'}</TableCell>
        <TableCell>{riskLevelCalculator(riskScore)}</TableCell>
        <TableCell>{moment(createdAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
        <TableCell>{moment(updatedAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
        <TableCell>
          {dualAuthStatus === 'PENDING' ? (
            <Title margin="0px" type="h3" text="Pending dual auth" />
          ) : (
            <>
              {isBusy ? (
                <CircularSpinner size={22} />
              ) : (
                <MuiButton
                  disabled={isBusy}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (recommendationStatus === 'NEW') {
                      return handleInitialize()
                    }
                    toggleConfirmationDialog()
                  }}
                >
                  {recommendationStatus === 'NEW'
                    ? 'initialize'
                    : 'change status'}
                </MuiButton>
              )}
            </>
          )}
        </TableCell>
      </TableRow>
      {isSelected && (
        <Fragment>
          <TableCell colSpan={9}>
            <Details>
              <JsonBlock src={accountsProfilesData} title={'Raw Data'} />
            </Details>
          </TableCell>
        </Fragment>
      )}
      <SimpleModal
        title={'Update recommendation status'}
        message={`You are about to update the recommendation status. Please select the status and enter a comment.`}
        open={showConfirmationDialog}
        handleClose={toggleConfirmationDialog}
        disabled={isBusy}
        buttonsHidden
      >
        <RiskScoreForm
          initialValues={{
            accountId,
            name,
            surname,
            type,
            riskScore,
          }}
          recommendationStatus={recommendationStatus}
          form={'risk_score_Form'}
          onCancel={toggleConfirmationDialog}
          toggleForm={toggleConfirmationDialog}
          onSubmitSuccess={toggleConfirmationDialog}
        />
      </SimpleModal>
    </Fragment>
  )
}

export default RiskAccountsProfilesRow
