import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import getAxios from 'helpers/axios'
import { Spacer } from 'components/atoms/Generic/index'
import { renderPlural } from 'helpers/utils'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import IconButton from 'components/atoms/IconButton/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import {
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'

import EntityFormLeft from './EntityFormLeft'
import EntityFormRight from './EntityFormRight'
import Documents from './EntitiyFormDocuments.js/Documents'
import LegalEntityFormLeft from './LegalEntityFormLeft'

import { StyledEntityFormContent, StyledEntityFormWrapper } from '../Styles'

const FormTypes = {
  EditEntityForm: 'Edit Entity',
  AddEntityForm: 'Add Entity',
}

let scrollEvent

let Form = props => {
  const {
    submitting,
    error,
    invalid,
    pristine,
    form,
    // To update form data use change
    change,
    formValues,
    // corporateAccountData,
    accountId,
  } = props
  const { handleSubmit, onCancel, onSubmit, entitiesCollectionName } = props
  const [documents, setDocuments] = useState([])
  const [documentType, setDocumentType] = useState('')
  const [otherDocumentTypeName, setOtherDocumentTypeName] = useState('')

  const [
    removeDocumentConfirmationDialog,
    setRemoveDocumentConfirmationDialog,
  ] = useState({ open: false, indexOfItemToRemove: -1 })
  const [idDocumentUploadState, setIdDocumentUploadState] = useState({
    progress: 0,
    uploadErrorMessage: '',
    uploadSuccessMessage: '',
    documentType: '',
  })

  const isLinkedEntity =
    formValues && formValues.initial && !!formValues.initial.linkedAccountId

  const checkFormTypeActionButtonText = () => {
    const text = FormTypes[form]
    return text
  }

  const updateDocuments = updatedDocumentsData => {
    change('files', updatedDocumentsData)
    setDocuments(updatedDocumentsData)
    setDocumentType('')
  }

  const handleAddDocument = async (type, file) => {
    const documentData = {
      file,
      metaData: {
        documentType: type,
        fileName: file.name,
        uploadState: {
          progress: 0,
          uploadSuccessMessage: '',
          uploadErrorMessage: '',
        },
      },
    }

    const updatedDocumentsData = [...documents, documentData]
    return updateDocuments(updatedDocumentsData)
  }

  const handleRemoveDocument = index => {
    setRemoveDocumentConfirmationDialog({
      open: true,
      indexOfItemToRemove: index,
    })
  }

  const handleProceedRemoveDocument = index => {
    const updatedDocumentsData = [...documents]
    updatedDocumentsData.splice(index, 1)
    change('files', updatedDocumentsData)
    setDocuments(updatedDocumentsData)
    setRemoveDocumentConfirmationDialog({
      open: false,
      indexOfItemToRemove: -1,
    })
  }

  const handleDocumentsUploadState = (fileName, uploadState) => {
    const indexOfDocument = documents.findIndex(
      doc => doc.metaData.fileName === fileName,
    )
    if (indexOfDocument >= 0) {
      const updatedDocuments = [...documents]
      updatedDocuments[indexOfDocument].metaData.uploadState = uploadState
      setDocuments(updatedDocuments)
    }
  }

  const handleFileUploadingProcess = async (files, entityId, entityType) => {
    const uploads = files.map(async file => {
      try {
        const formData = new FormData()
        formData.append('selectedFile', file.file)
        // change to post
        const { data } = await getAxios().put(
          `corporates/editor/${accountId}/${false}/entities/${entityId}/${entityType}/upload/${
            file.metaData.documentType
          }`,
          formData,
          {
            onUploadProgress: uploadingData => {
              // Set the progress value to show the progress bar
              const progress = Math.round(
                (100 * uploadingData.loaded) / uploadingData.total,
              )
              handleDocumentsUploadState(file.metaData.fileName, {
                progress,
                uploadSuccessMessage: '',
                uploadErrorMessage: '',
              })
            },
          },
        )

        const uploadSuccessData = {
          progress: 100,
          uploadSuccessMessage: 'Document uploaded successfully',
          uploadErrorMessage: '',
        }
        handleDocumentsUploadState(file.metaData.fileName, uploadSuccessData)

        return data
      } catch (e) {
        const errorMessage =
          e.response && e.response.data && e.response.data.message
        const uploadErrorData = {
          progress: 0,
          uploadErrorMessage:
            errorMessage ||
            'Error occurred when uploading document please contact support if this problem persists',
          uploadSuccessMessage: '',
        }
        handleDocumentsUploadState(file.metaData.fileName, uploadErrorData)
        return e.response.data
      }
    })

    const uploadPromises = await Promise.all(uploads)
    const uploadErrorOccurred = {
      status: false,
      failedUploadsCount: 0,
    }

    uploadPromises.forEach(eachUpload => {
      eachUpload.forEach(upload => {
        if (!upload.createdAt) {
          uploadErrorOccurred.status = true
          uploadErrorOccurred.failedUploadsCount += 1
        }
      })
    })
    if (uploadErrorOccurred.status) {
      throw new Error(
        `There seems to be an error when uploading files, ${
          uploadErrorOccurred.failedUploadsCount
        } upload${renderPlural(
          uploadErrorOccurred.failedUploadsCount,
        )} failed please check your internet connection or try again. If this problem persists please contact support. If you wish proceed with updating the other content remove the files`,
      )
    }
    return uploadPromises
  }

  useEffect(
    () => {
      const formContainer = document.getElementById('formContainer')
      if (formContainer) {
        formContainer.removeEventListener('scroll', scrollEvent)
        scrollEvent = formContainer.addEventListener('scroll', () => {
          const dropDownButton = document.getElementById('contentBelow')
          const actionsElement = document.getElementById('actions')

          let rect

          if (actionsElement) {
            rect = actionsElement.getBoundingClientRect()

            const { top } = rect

            if (dropDownButton) {
              if (top < 800) {
                dropDownButton.style.display = 'none'
              } else {
                dropDownButton.style.display = 'block'
              }
            }
          }
        })
      }
      return () => {}
    },
    [documents],
  )

  return (
    <StyledEntityFormWrapper>
      <FormContainer
        id="formContainer"
        style={{ height: 600, overflow: 'scroll' }}
        onSubmit={handleSubmit(async values => {
          const uploadFilesCallBack = (files, entityId) =>
            handleFileUploadingProcess(files, entityId, values.entityType)

          await onSubmit(values, uploadFilesCallBack)
        })}
      >
        <FormTitle>Entity Details</FormTitle>
        <StyledEntityFormContent>
          {entitiesCollectionName === 'NATURAL' && <EntityFormLeft />}
          {entitiesCollectionName === 'LEGAL' && (
            <LegalEntityFormLeft
              hasEntityType={
                formValues &&
                formValues.initial &&
                formValues.initial.entityType &&
                !!formValues.initial.entityType
              }
            />
          )}
          {!isLinkedEntity && (
            <Spacer margin="0px 0px 0px 8px">
              <EntityFormRight
                showNaturalDocumentOptions={
                  entitiesCollectionName === 'NATURAL'
                }
                entityType={
                  formValues &&
                  formValues.values &&
                  formValues.values.entityType &&
                  formValues.values.entityType
                }
                documentType={documentType}
                setDocumentType={setDocumentType}
                otherDocumentTypeName={otherDocumentTypeName}
                setOtherDocumentTypeName={setOtherDocumentTypeName}
                handleAddDocument={handleAddDocument}
              />
            </Spacer>
          )}
        </StyledEntityFormContent>
        {documents.length > 0 && (
          <div
            id="contentBelow"
            style={{ position: 'absolute', right: 8, bottom: 15 }}
          >
            <IconButton
              onClick={() => {
                const doc = document.getElementById('bottom')
                doc.scrollIntoView({ behavior: 'smooth' })
              }}
              icon={<ArrowDropDownCircleIcon />}
            />
          </div>
        )}
        {!isLinkedEntity && (
          <Fragment>
            <Documents
              documents={documents}
              handleRemoveDocument={handleRemoveDocument}
              idDocumentUploadState={idDocumentUploadState}
            />
          </Fragment>
        )}
        {error && <FormError error={error} />}
        <InputGroup id="actions" justify={'flex-end'}>
          <Spacer margin="0px 16px 0px 0px">
            <MuiButton
              type="submit"
              disabled={submitting || invalid || pristine}
            >
              {checkFormTypeActionButtonText()}
            </MuiButton>
          </Spacer>
          <MuiButton onClick={onCancel} color="secondary">
            Cancel
          </MuiButton>
          <div id="bottom" />
        </InputGroup>
      </FormContainer>
      <ConfirmationDialog
        open={removeDocumentConfirmationDialog.open}
        title="Remove document"
        message="Are you sure you want to remove current file?"
        proceed={() =>
          handleProceedRemoveDocument(
            removeDocumentConfirmationDialog.indexOfItemToRemove,
          )
        }
        handleClose={() =>
          setRemoveDocumentConfirmationDialog({
            open: false,
            indexOfItemToRemove: -1,
          })
        }
      />
    </StyledEntityFormWrapper>
  )
}

Form = reduxForm()(Form)

const mapStateToProps = (state, ownProps) => ({
  formValues: state.form[ownProps.form],
  corporateAccountData: state.corporate,
})

const EntityForm = connect(mapStateToProps)(Form)

export default EntityForm
