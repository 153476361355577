import { useEffect, useState } from 'react'

export const useAccountConditionalOrders = (
  fetchAccountConditionalOrders: (accountId: string) => Promise<void>,
  accountId: string,
) => {
  const [expandedItem, setExpandedItem] = useState('')
  
  useEffect(() => {
    fetchAccountConditionalOrders(accountId)
  }, [])

  return {
    expandedItem,
    setExpandedItem,
  }
}
