import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { addUserActionComment } from 'redux/userComments/actions'

export const submit = async values => {
  try {
    const res = await getAxios().post('/admin/withdrawal/limit', {
      values,
    })

    const { dualAuthId } = res.data
    await addUserActionComment({
      ...values,
      dualAuthId,
    })
    toast('Dual Auth For Withdrawal Limit Initiated', {
      type: toast.TYPE.SUCCESS,
      autoClose: 1500,
    })
  } catch (error) {
    ErrorToast(error)
  }
}
