import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS } from '../SearchAccountEmails/submit'

export const submitRemovedEmail = async (email, dispatch) => {
  const { bouncedEmail } = email
  let res = null
  try {
    res = await getAxios().delete(
      `/account/remove/user-from-bounces-list/${bouncedEmail}`,
    )
    toast(res.data.message, { type: toast.TYPE.SUCCESS, autoClose: 2500 })
    const results = await getAxios().get(`/accounts/emails-activity/${bouncedEmail}`)
    dispatch({
      type: FETCH_SENDGRID_EMAILS_FOR_USER_SUCCESS,
      data: results && results.data,
      currentEmail: bouncedEmail,
    })
    return res.data
  } catch (error) {
    toast(error.response === undefined ? `Something went wrong,${error}` : error.response.data.message, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
