import React, { useEffect, useMemo } from 'react'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchCurrencies } from 'redux/currencies/actions'
import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderSwitch, renderInput, renderDropdown } from 'forms/fields'
import {
  required,
  isNumber,
  noSpacesAllowed,
  moreThanZero,
  requiredSelectDropdownValue,
  requiredDropdownValue,
  maxLength256,
} from 'forms/fieldValidators'
import { submitTransaction } from './submit'

const Form = props => {
  const { submitting, invalid, currencies } = props
  const { handleSubmit, onCancel, onSubmit } = props

  const currenciesData = useMemo(
    () =>
      currencies.data.map(property => {
        const option = { value: property.shortName, label: property.shortName }
        return option
      }),
    [currencies.data],
  )

  useEffect(
    () => {
      if (!currencies.data.length) props.fetchCurrencies()
    },
    [currencies.data],
  )

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Enter Details</FormTitle>
      <InputGroup align={'flex-start'}>
        <Field
          label={'Account ID'}
          name={'accountId'}
          margin={`0 ${BASE * 2}px 0 0`}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed, moreThanZero]}
          fullWidth
        />
        <Field
          label={'Transaction Reference'}
          name={'transactionReference'}
          component={renderInput}
          validate={[required, maxLength256]}
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'}>
        <Field
          label={'Amount'}
          name={'amount'}
          margin={`0 ${BASE * 2}px 0 0`}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed, moreThanZero]}
          fullWidth
        />
        <Field
          label={'Currency Symbol:'}
          name={'currencySymbol'}
          component={renderDropdown}
          width={'50%'}
          options={[...currenciesData]}
          type="currencies"
          validate={[requiredDropdownValue]}
          fullWidth
        />
      </InputGroup>
      <InputGroup justify={'flex-start'}>
        <Field
          label={'Do you want to process this?'}
          formatLabel={false}
          name={'confirmation'}
          type={'yes/no'}
          component={renderSwitch}
        />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'fundIndividual',
  onSubmit: submitTransaction,
  validate: values => {
    const errors = {}
    if (!values.confirmation) {
      errors.confirmation = 'Check this to confirm'
    }
    return errors
  },
})(Form)

const mapStateToProps = state => ({
  currencies: state.currencies,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrencies,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduxForm)
