import React, { Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import AccountDeposits from './AccountsDeposits'
import CreditCardAccountDeposits from './CreditCard/CreditCardAccountDeposits'
import FundingEvents from './FundingEvents'

const routes = [
  '/accounts/byId/:accountId/transactions/deposits',
  '/accounts/byId/:accountId/transactions/deposits/credit-card',
  '/accounts/byId/:accountId/transactions/deposits/account-funding',
]
const DepositsNav = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    const currentPath = pathname.split('/')[pathname.split('/').length - 1]
    const path = routes.find((r) => r.includes(currentPath))
    if (path) {
      setCurrentPage(path)
    }
  }, [pathname])

  return (
    <>
      <Fragment>
        <TopNavigationBar
          value={currentPage}
          onChange={handleChange}
          variant="fullWidth"
        >
          <NavigationTab
            value={routes[0]}
            label="BANK DEPOSITS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/transactions/deposits`}
          />
          <NavigationTab
            value={routes[1]}
            label="CREDIT CARD DEPOSITS"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/transactions/deposits/credit-card`}
          />
          <NavigationTab
            value={routes[2]}
            label="FUNDING"
            component={ForwardedNavLink}
            to={`/accounts/byId/${props.match.params.accountId}/transactions/deposits/account-funding`}
          />
        </TopNavigationBar>
        <ContentContainer>
          <Switch>
            <Route path={routes[0]} component={AccountDeposits} exact />
            <Route path={routes[1]} component={CreditCardAccountDeposits} />
            <Route path={routes[2]} component={FundingEvents} />
          </Switch>
        </ContentContainer>
      </Fragment>
    </>
  )
}

export default DepositsNav
