import { FETCH_REFERRAL_LEVEL_PROCCESS, FETCH_REFERRAL_LEVEL_SUCCESS, FETCH_REFERRAL_LEVEL_ERROR } from './actions'

const initialState = {
  data: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REFERRAL_LEVEL_PROCCESS: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case FETCH_REFERRAL_LEVEL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        status: 'done',
      }
    }

    case FETCH_REFERRAL_LEVEL_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        status: 'error',
      }
    }

    default:
      return state
  }
}
