import React from 'react'
import { List } from '@material-ui/core'

import { NavItem } from './interfaces'
import { NestedNavMenuItem } from './NestedNavMenuItem'

import { Collapse } from '../styles'

const nestedNavigationItems: NavItem[] = [
  {
    route: '/leverage/loans-book',
    listItemText: 'Loans Book',
    selectedPathName: '/leverage/loans-book',
    routeAccess: 'leverage',
  },
  {
    route: '/leverage/margin',
    listItemText: 'Margin',
    selectedPathName: '/leverage/margin',
    routeAccess: 'leverage',
  },
  {
    route: '/leverage/futures',
    listItemText: 'Futures',
    selectedPathName: '/leverage/futures',
    routeAccess: 'leverage',
  },
]

const NestedLeverageNavigationItems = ({
  selectedPathName,
  pathname,
  handleListItemClick,
}) =>
  pathname.includes('/leverage') ? (
    <Collapse
      in={pathname.includes(selectedPathName)}
      timeout="auto"
      unmountOnExit
    >
      <List disablePadding>
        <>
          {nestedNavigationItems.map((nestedNavItem, index) => (
            <NestedNavMenuItem
              key={index}
              handleListItemClick={handleListItemClick}
              navItemData={nestedNavItem}
              pathname={pathname}
            />
          ))}
        </>
      </List>
    </Collapse>
  ) : null

export default NestedLeverageNavigationItems
