import React, { Component, Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import {
  TitleContainer,
  Title,
} from 'components/atoms/Table/styles'
import {
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import {
  Details,
} from 'components/atoms/Details'
import { fetchAccountConfirmations } from 'redux/accountConfirmations/actions'
import ErrorBoundary from 'helpers/ErrorBoundary'
import SetConfirmations from './setConfirmations'

function createData(currency, aboveThresholdConfirmationCount, belowThresholdConfirmationCount, threshold, createdAt) {
  return {
    currency, aboveThresholdConfirmationCount, belowThresholdConfirmationCount, threshold, createdAt
  }
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
const headCells = [
  {
    id: 'currency', disablePadding: false, label: 'Currency',
  },
  {
    id: ' aboveThresholdConfirmationCount', disablePadding: false, label: ' Above Threshold Confirmation Count',
  },
  {
    id: 'belowThresholdConfirmation', disablePadding: false, label: 'Below Threshold Confirmation',
  },
  {
    id: 'threshold', disablePadding: false, label: 'Threshold',
  },
  {
    id: 'createdAt', disablePadding: false, label: 'Created At',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountConfirmations(props) {
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  React.useEffect(() => {
    const { accountId } = props.match.params
    props.fetchAccountConfirmations(accountId)
  }, [])

  const { accountConfirmations: { status, data } } = props

  const rows = data.map((results, i) => {
    const { currency, aboveThresholdConfirmationCount, belowThresholdConfirmationCount, threshold, createdAt } = results
    return createData(
      currency,
      aboveThresholdConfirmationCount,
      belowThresholdConfirmationCount,
      threshold,
      createdAt,
    )
  })

  return (
    <ErrorBoundary message="Account Confirmation">
      <TitleContainer>
        <Title>Set Account Confirmation</Title>
      </TitleContainer>
      <Details marginTop={`30px`} marginBottom={`30px`} direction={'column'}>
        <SetConfirmations id={props.match.params.accountId} />
      </Details>
      {status === 'loading' && (
        <TablePlaceholder>
          <IconLoading />
          <span>Loading, please wait</span>
        </TablePlaceholder>
      )}
      {status === 'done' && data.length !== 0 &&
        <div>

          <TitleContainer>
            <Title> Account Confirmation Details</Title>
          </TitleContainer>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>

                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>

                    {stableSort(rows, getComparator(order, orderBy))
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`
                        return (
                          <>
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.name}
                              aria-label="collapsible table"
                            >
                              <TableCell id={labelId} >{row.currency}</TableCell>
                              <TableCell  >{row.aboveThresholdConfirmationCount}</TableCell>
                              <TableCell  >{row.belowThresholdConfirmationCount}</TableCell>
                              <TableCell  >{row.threshold}</TableCell>
                              <TableCell  >  {moment(row.createdAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
                            </TableRow>
                          </>

                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>

      }
    </ErrorBoundary>
  )

}

const mapStateToProps = state => ({
  accountConfirmations: state.accountConfirmations,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountConfirmations,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountConfirmations)
