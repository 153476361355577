import React, { Component, Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from 'components/atoms/Table/styles'
import RunsTableRow from './runsRows'

class RenderMarketingContactRunsTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const { list } = this.props
    const sortedList = [...list.sort((x, y) => y.id - x.id)]
    return (
      <Fragment>
        {list.length > 0 ? (
          <Table>
            <TableHeader>
              <TableCell flex={0.5} width={'10%'}>Id</TableCell>
              <TableCell flex={0.65} width={'20%'}>Latest Account ID</TableCell>
              <TableCell flex={0.65} width={'20%'}>List Name</TableCell>
              <TableCell flex={0.65} width={'15%'}>Contacts In Batch</TableCell>
              <TableCell flex={1} width={'20%'}>SendGrid Job ID</TableCell>
              <TableCell flex={1} width={'15%'}>Created At</TableCell>
            </TableHeader>
            <TableBody>
              {sortedList && sortedList.map((data, idOnList) => {
                let uniqueId = idOnList++
                return (
                    <RunsTableRow
                      key={uniqueId}
                      data={data}
                      uniqueId={uniqueId}
                    />
                )
              })}
            </TableBody>
          </Table>
        ) : (
          <center>
            <b>No marketing contact list runs to show</b>
          </center>
        )}
      </Fragment>
    )
  }
}

export default RenderMarketingContactRunsTable