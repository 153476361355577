import React from 'react'
import Button from '@material-ui/core/Button'

const CurrencyPairActions = React.memo(
  ({
    active,
    updateLimit,
    handleUpdateCurrencyPair,
    handleUpdateCurrencyPairLimits,
    isUpdatingCurrencyPair,
  }: any) => (
    <>
      <Button
        disabled={isUpdatingCurrencyPair}
        style={{ marginRight: '10px', width: '90px' }}
        variant="contained"
        color={active ? 'secondary' : 'primary'}
        onClick={handleUpdateCurrencyPair}
      >
        {active ? 'Disable' : 'Enable'}
      </Button>
      {!updateLimit && (
        <Button
          style={{ width: '90px', marginRight: '10px' }}
          variant="contained"
          color="primary"
          onClick={handleUpdateCurrencyPairLimits}
        >
          Edit
        </Button>
      )}
    </>
  ),
)

export default CurrencyPairActions
