import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
} from 'components/atoms/Form'
import { renderSwitch } from 'forms/fields'

import { submitAccountMarginTradeFiatStatus } from './submit'

let Form = props => {
  const { submitting, invalid, pristine } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Update Margin Fiat Trade Status</FormTitle>
      <InputGroup align={'flex-start'} direction={'rows'}>
        <Field
          label={'Active'}
          name={'canMarginTradeFiat'}
          marginRight={BASE * 2}
          component={renderSwitch}
        />
        <hr />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          disabled={submitting || invalid || pristine}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submitAccountMarginTradeFiatStatus })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
