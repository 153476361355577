import React, { Component } from 'react'
import * as moment from 'moment'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHeader,
  TablePlaceholder,
} from 'components/atoms/Table/styles'

class UnsubscribesGroups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emails: [],
      loading: true,
    }
  }

  render() {
    const {
      data: {
        unsubscribes
      },
    } = this.props.src
    return (
      <Table key={'groups'}>
        <TableHeader>
          <TableCell flex={1}>Email</TableCell>
          <TableCell flex={1}>Group ID</TableCell>
          <TableCell flex={1}>Group Name</TableCell>
          <TableCell flex={1}>Created At</TableCell>
        </TableHeader>
        <TableBody>
          {unsubscribes && unsubscribes.map((value) => {
            return (
              <TableRow key={value['group_id']}>
                <TableCell flex={1}>{value['email']}</TableCell>
                <TableCell flex={1}>{value['group_id']}</TableCell>
                <TableCell flex={1}>{value['group_name']}</TableCell>
                  <TableCell flex={1}>
                    {moment.unix(value['created_at']).format('YYYY-MMM-DD HH:mm:ss')}
                  </TableCell>
                </TableRow>
              )
            })}
          {(unsubscribes === undefined || unsubscribes.length === 0) && (
            <TablePlaceholder>No Unsubscribes found</TablePlaceholder>
          )}
        </TableBody>
      </Table>
    )
  }
}

export default UnsubscribesGroups
