import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_POTENTIAL_USERS_IN_SANCTIONS_PROCESS =
  'accounts/FETCH_POTENTIAL_USERS_IN_SANCTIONS_PROCESS'
export const FETCH_POTENTIAL_USERS_IN_SANCTIONS_SUCCESS =
  'accounts/FETCH_POTENTIAL_USERS_IN_SANCTIONS_SUCCESS'
export const FETCH_POTENTIAL_USERS_IN_SANCTIONS_ERROR =
  'accounts/FETCH_POTENTIAL_USERS_IN_SANCTIONS_ERROR'

export const FETCH_CORPORATE_SANCTIONS_START =
  'FETCH_CORPORATE_SANCTIONS_START'
export const FETCH_CORPORATE_SANCTIONS_SUCCESS =
  'FETCH_CORPORATE_SANCTIONS_SUCCESS'
export const FETCH_CORPORATE_SANCTIONS_ERROR =
  'FETCH_CORPORATE_SANCTIONS_ERROR'

export const FETCH_HIDDEN_SANCTIONS_START = 'FETCH_HIDDEN_SANCTIONS_START'
export const FETCH_HIDDEN_SANCTIONS_SUCCESS = 'FETCH_HIDDEN_SANCTIONS_SUCCESS'
export const FETCH_HIDDEN_SANCTIONS_ERROR = 'FETCH_HIDDEN_SANCTIONS_ERROR'

export const UPDATE_SANCTION_DATA_START = 'UPDATE_SANCTION_DATA_START'
export const UPDATE_SANCTION_DATA_SUCCESS = 'UPDATE_SANCTION_DATA_SUCCESS'
export const UPDATE_SANCTION_DATA_ERROR = 'UPDATE_SANCTION_DATA_ERROR'

export const UPDATE_BULK_SANCTION_DATA_START = 'UPDATE_BULK_SANCTION_DATA_START'
export const UPDATE_BULK_SANCTION_DATA_SUCCESS = 'UPDATE_BULK_SANCTION_DATA_SUCCESS'
export const UPDATE_BULK_SANCTION_DATA_ERROR = 'UPDATE_BULK_SANCTION_DATA_ERROR'

export const CHECK_LOCAL_SANCTIONS_PROCESS_START = 'CHECK_LOCAL_SANCTIONS_PROCESS_START'
export const CHECK_LOCAL_SANCTIONS_PROCESS_SUCCESS = 'CHECK_LOCAL_SANCTIONS_PROCESS_SUCCESS'
export const CHECK_LOCAL_SANCTIONS_PROCESS_ERROR = 'CHECK_LOCAL_SANCTIONS_PROCESS_ERROR'

export const CHECK_CORPORATE_SANCTIONS_PROCESS_START = 'CHECK_CORPORATE_SANCTIONS_PROCESS_START'
export const CHECK_CORPORATE_SANCTIONS_PROCESS_SUCCESS = 'CHECK_CORPORATE_SANCTIONS_PROCESS_SUCCESS'
export const CHECK_CORPORATE_SANCTIONS_PROCESS_ERROR = 'CHECK_CORPORATE_SANCTIONS_PROCESS_ERROR'

export const TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS = 'TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS'
export const TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS = 'TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS'

export const CHECK_OUT_CORPORATE_SANCTION_START = 'CHECK_OUT_CORPORATE_SANCTION_START'
export const CHECK_OUT_CORPORATE_SANCTION_SUCCESS = 'CHECK_OUT_CORPORATE_SANCTION_SUCCESS'
export const CHECK_OUT_CORPORATE_SANCTION_ERROR = 'CHECK_OUT_CORPORATE_SANCTION_ERROR'

export const UPDATE_CORPORATE_SANCTIONS_LIST = 'UPDATE_CORPORATE_SANCTIONS_LIST'

export const FETCH_SANCTIONS_RESET_PROCESS = 'FETCH_SANCTIONS_RESET_PROCESS'
export const FETCH_SANCTIONS_RESET_SUCCESS = 'FETCH_SANCTIONS_RESET_SUCCESS'
export const FETCH_SANCTIONS_RESET_ERROR = 'FETCH_SANCTIONS_RESET_ERROR'

export const UPDATE_SANCTIONS_RESET_SUCCESS = 'UPDATE_SANCTIONS_RESET_SUCCESS'
export const UPDATE_SANCTIONS_RESET_ERROR = 'UPDATE_SANCTIONS_RESET_ERROR'

export const removeFromHiddenSanctionsList = ({
  hidden,
  id,
  search_type,
}) => async dispatch => {
  dispatch({
    type: UPDATE_SANCTION_DATA_START,
  })

  try {
    const data = await getAxios().post(`/sanctions/${id}/delete`, {
      hidden,
      search_type,
    })

    const payloadData = { ...data, searchType: search_type }

    dispatch({
      type: UPDATE_SANCTION_DATA_SUCCESS,
      payload: payloadData,
    })

    toast(`Successfully ${hidden ? 'removed' : 'restored'} sanction`, {
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    })
  } catch (error) {
    console.error(error.response)

    toast('Error occurred when removing/restoring sanction', {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })

    dispatch({
      type: UPDATE_SANCTION_DATA_ERROR,
    })
  }
}

export const fetchPotentialUsersInSanctionsList = hidden => async dispatch => {
  dispatch({
    type: FETCH_POTENTIAL_USERS_IN_SANCTIONS_PROCESS,
  })
  try {
    const res = await getAxios().get(`/accounts/sanctions?hidden=${hidden}`)
    const { data } = res
    dispatch({
      type: FETCH_POTENTIAL_USERS_IN_SANCTIONS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_POTENTIAL_USERS_IN_SANCTIONS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchSanctionsResetStatus = () => async dispatch => {
  dispatch({
    type: FETCH_SANCTIONS_RESET_PROCESS,
  })
  try {
    const res = await getAxios().get('/reset-sanctions-status')
    const { data } = res
    dispatch({
      type: FETCH_SANCTIONS_RESET_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    toast('Error occurred when fetching resetting status', {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    dispatch({
      type: FETCH_SANCTIONS_RESET_ERROR,
    })
  }
}

export const UpdateSanctionsResetStatus = (newStatus) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_SANCTIONS_RESET_SUCCESS,
      payload: {
        data: newStatus,
      },
    })
  } catch (error) {
    toast('Error occurred when resetting sanctions', {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    dispatch({
      type: UPDATE_SANCTIONS_RESET_ERROR,
    })
  }
}

export const toggleSanctionCheck = (sanctionData, checkInformation) => async dispatch => {
  dispatch({
    type: UPDATE_SANCTION_DATA_START,
  })
  try {
    const res = await getAxios().post(`/account/acknowledge/check/${false}`, {
      sanctionData,
      checkInformation,
    })

    const payloadData = { ...res, searchType: sanctionData.search_type }

    dispatch({
      type: UPDATE_SANCTION_DATA_SUCCESS,
      payload: payloadData,
    })

    toast('Request Completed', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
  } catch (error) {
    dispatch({
      type: UPDATE_SANCTION_DATA_ERROR,
    })

    ErrorToast(error)
  }
}

export const bulkSanctionCheck = (sanctionData, checkInformation) => async dispatch => {
  dispatch({
    type: UPDATE_BULK_SANCTION_DATA_START,
  })
  try {
    const res = await getAxios().post(`/account/acknowledge/check/${true}`, {
      sanctionData,
      checkInformation,
    })

    const payloadData = res.data
      .map((updatedSanction) => ({
        ...updatedSanction,
        searchType: sanctionData.find((sanction) => sanction.id === updatedSanction.id).search_type,
      }))

    dispatch({
      type: UPDATE_BULK_SANCTION_DATA_SUCCESS,
      payload: payloadData,
    })

    toast('Request Completed', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
  } catch (error) {
    dispatch({
      type: UPDATE_BULK_SANCTION_DATA_ERROR,
    })

    ErrorToast(error)
  }
}

export const checkLocalSanctionsProcessStatus = () => async dispatch => {
  try {
    dispatch({
      type: CHECK_LOCAL_SANCTIONS_PROCESS_START,
    })

    const { data: { running } } = await getAxios().get('/local-sanctions-process-status')

    dispatch({
      type: CHECK_LOCAL_SANCTIONS_PROCESS_SUCCESS,
      payload: running,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: CHECK_LOCAL_SANCTIONS_PROCESS_ERROR,
    })
  }
}

export const triggerLocalSanctionsScan = () => async dispatch => {
  try {
    dispatch({
      type: TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS,
      payload: true,
    })
    getAxios().post('/trigger/local/checks', {
      start: true,
    })
    toast('Local Sanction search started', {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: TOGGLE_LOCAL_SANCTIONS_PROCESS_STATUS,
      payload: false,
    })
  }
}

export const checkCorporateSanctionsProcessStatus = () => async dispatch => {
  try {
    dispatch({
      type: CHECK_CORPORATE_SANCTIONS_PROCESS_START,
    })

    const { data: { running } } = await getAxios().get('/accounts/sanctions/corporate/process/status')

    dispatch({
      type: CHECK_CORPORATE_SANCTIONS_PROCESS_SUCCESS,
      payload: running,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: CHECK_CORPORATE_SANCTIONS_PROCESS_ERROR,
    })
  }
}

export const fetchCorporateSanctions = hidden => async dispatch => {
  dispatch({
    type: FETCH_CORPORATE_SANCTIONS_START,
  })
  try {
    const { data } = await getAxios().get('/accounts/sanctions/corporate')

    dispatch({
      type: FETCH_CORPORATE_SANCTIONS_SUCCESS,
      payload: {
        data: data.filter((s) => s.status === 'unchecked'),
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_CORPORATE_SANCTIONS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const triggerCorporateSanctionScan = () => async dispatch => {
  try {
    toast('Corporate sanction search started', {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
    dispatch({
      type: TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS,
      payload: true,
    })
    const { data } = await getAxios().get('/accounts/sanctions/corporate/scan')

    dispatch({
      type: UPDATE_CORPORATE_SANCTIONS_LIST,
      payload: data,
    })
    dispatch({
      type: TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS,
      payload: false,
    })
    toast('Corporate sanction search completed', {
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: TOGGLE_CORPORATE_SANCTIONS_PROCESS_STATUS,
      payload: false,
    })
  }
}

export const toggleCorporateSanctionCheck = (accountId, checkInformation) => async dispatch => {
  try {
    dispatch({
      type: CHECK_OUT_CORPORATE_SANCTION_START,
    })

    const { data } = await getAxios().post(
      `/accounts/sanctions/corporate/editor/acknowledge/${accountId}`,
      {
        checkInformation,
      },
    )
    // const checkedSanctionData = await runCheckCorpSanction(, checkInformation)

    dispatch({
      type: CHECK_OUT_CORPORATE_SANCTION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: CHECK_OUT_CORPORATE_SANCTION_ERROR,
    })
  }
}

const runCheckCorpSanction = async (corpSanctionData, checkInformation) => new Promise((resolve, reject) => setTimeout(() => {
  try {
    const updatedData = { ...corpSanctionData }
    updatedData.status = updatedData.status === 'checked' ? 'unchecked' : 'checked'
    if (corpSanctionData.status === 'checked') {
      updatedData.checkComment = checkInformation.checkComment
      updatedData.approvalStatus = checkInformation.approvalStatus
    } else {
      updatedData.checkComment = null
      updatedData.approvalStatus = null
    }

    resolve(updatedData)
  } catch (error) {
    reject(error)
  }
}, 1000))

const dummyArray = [
  {
    id: 5,
    accountId: 1,
    name: 'Corporate Name',
    status: 'unchecked',
    reason: {
      corporateName: 'Some name',
      foundEntities: [
        {
          id: 'ENTITY_ID1',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
        {
          id: 'ENTITY_ID2',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
      ],
    },
    createdAt: new Date().toLocaleDateString(),
    modifiedAt: new Date().toLocaleDateString(),
    acknowledged: 'unacknowledged',
  },
  {
    id: 2,
    accountId: 1,
    name: 'Corporate Name',
    status: 'unchecked',
    reason: {
      corporateName: 'Some name',
      foundEntities: [
        {
          id: 'ENTITY_ID1',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
        {
          id: 'ENTITY_ID2',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
      ],
    },
    createdAt: new Date().toLocaleDateString(),
    modifiedAt: new Date().toLocaleDateString(),
    acknowledged: 'unacknowledged',
  },
  {
    id: 3,
    accountId: 1,
    name: 'Corporate Name',
    status: 'unchecked',
    reason: {
      corporateName: 'Some name',
      foundEntities: [
        {
          id: 'ENTITY_ID1',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
        {
          id: 'ENTITY_ID2',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
      ],
    },
    createdAt: new Date().toLocaleDateString(),
    modifiedAt: new Date().toLocaleDateString(),
    acknowledged: 'unacknowledged',
  },
  {
    id: 4,
    accountId: 1,
    name: 'Corporate Name',
    status: 'unchecked',
    reason: {
      corporateName: 'Some name',
      foundEntities: [
        {
          id: 'ENTITY_ID1',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
        {
          id: 'ENTITY_ID2',
          entityNameMatch: 'Entity Name if entity name was found',
          entitySurnameMatch: 'Entity Surname if entity surname was found',
          type: 'legal',
        },
      ],
    },
    createdAt: new Date().toLocaleDateString(),
    modifiedAt: new Date().toLocaleDateString(),
    acknowledged: 'unacknowledged',
  },
]

const mockFetchCorpSanctionsStatus = async () => new Promise((res, rej) => setTimeout(() => res(Math.round(Math.random() * 1) > 0), 1000))
