import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_PENDING_INTERNAL_TRANSFERS_LOADING =
  'pendingInternalTransfers/FETCH_PENDING_INTERNAL_TRANSFERS_LOADING'
export const FETCH_PENDING_INTERNAL_TRANSFERS_SUCCESS =
  'pendingInternalTransfers/FETCH_PENDING_INTERNAL_TRANSFERS_SUCCESS'
export const FETCH_PENDING_INTERNAL_TRANSFERS_ERROR =
  'pendingInternalTransfers/FETCH_PENDING_INTERNAL_TRANSFERS_ERROR'

export const UPDATE_BULK_TRANSFER_DATA_SUCCESS =
  'UPDATE_BULK_TRANSFER_DATA_SUCCESS'
export const UPDATE_BULK_TRANSFER_DATA_ERROR = 'UPDATE_BULK_TRANSFER_DATA_ERROR'

export const fetchPendingTransfers = () => async (dispatch) => {
  dispatch({ type: FETCH_PENDING_INTERNAL_TRANSFERS_LOADING })
  let res = null
  try {
    res = await getAxios().get('/fund/pending-internal-transfers')
    const { data } = res
    data.sort((a, b) => a.id - b.id)
    dispatch({
      type: FETCH_PENDING_INTERNAL_TRANSFERS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    toast(
      `${error.response.status} || ${error.response.data.error.message}` ||
        error.response.data.message,
      { type: toast.TYPE.ERROR, autoClose: 2000 },
    )
    dispatch({
      type: FETCH_PENDING_INTERNAL_TRANSFERS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateInternalTransfers =
  (internalTransfersData, action) => async (dispatch) => {
    let res
    try {
      if (action === 'CREDIT') {
        res = await getAxios().put('/approve/internal/transfers', {
          internalTransfersData,
        })
      } else {
        res = await getAxios().post('/cancel/pending/internal-transfers', {
          internalTransfersData,
        })
      }
      if (res.data.resolvedInternalTransfersData.length > 0) {
        dispatch({
          type: UPDATE_BULK_TRANSFER_DATA_SUCCESS,
          payload: res.data.resolvedInternalTransfersData,
        })
        toast('Request Completed', {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        })
      } else if (res.data.unresolvedInternalTransfersData.length > 0) {
        res.data.unresolvedInternalTransfersData.map((transfer) => {
          toast(`${transfer.message}`, {
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          })
        })
      }
    } catch (error) {
      dispatch({
        type: UPDATE_BULK_TRANSFER_DATA_ERROR,
      })
      ErrorToast(error)
    }
  }

export const fetchFromAndToAccountDetails = async (fromAccId, toAccId) => {
  try {
    const fromAcc = await getAxios().get(`/account/${fromAccId}/profileinfo`)
    const toAcc =
      fromAcc && (await getAxios().get(`/account/${toAccId}/profileinfo`))
    const result = fromAcc && toAcc && [fromAcc.data, toAcc.data]
    return result
  } catch (error) {
    return error
  }
}
