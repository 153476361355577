import {
  FETCH_ALL_DEPOSITS_COMMENTS_PROCESS,
  FETCH_ALL_DEPOSITS_COMMENTS_SUCCESS,
  FETCH_ALL_DEPOSITS_COMMENTS_ERROR,
} from './actions'

const initialState = {
  depositComments: [],
  status: 'pristine',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_DEPOSITS_COMMENTS_PROCESS: {
      return {
        ...state,
        status: 'loading',
        allDepositsComments: [],
      }
    }

    case FETCH_ALL_DEPOSITS_COMMENTS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        status: 'done',
        allDepositsComments: data,
      }
    }

    case FETCH_ALL_DEPOSITS_COMMENTS_ERROR: {
      return {
        ...state,
        depositReview: {
          status: 'error',
          allDepositsComments: [],
        },
      }
    }
    default:
      return state
  }
}
