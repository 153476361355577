import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'
import { toast } from 'react-toastify'
import { SubmissionError } from 'redux-form'
import config from '../../config'
import {
  addUserActionComment,
  updateDualAuthStatus,
} from '../userComments/actions'
import { getBooleanFromValue } from '../../helpers/utils'

export const FETCH_CUSTOMER_TYPE_PROCESS = 'FETCH_CUSTOMER_TYPE_PROCESS'
export const FETCH_CUSTOMER_TYPE_SUCCESS = 'FETCH_CUSTOMER_TYPE_SUCCESS'
export const FETCH_CUSTOMER_TYPE_ERROR = 'FETCH_CUSTOMER_TYPE_ERROR'

export const FETCH_CUSTOMER_TYPE_CHANGE_PROCESS =
  'FETCH_CUSTOMER_TYPE_CHANGE_PROCESS'
export const FETCH_CUSTOMER_TYPE_CHANGE_SUCCESS =
  'FETCH_CUSTOMER_TYPE_CHANGE_SUCCESS'
export const FETCH_CUSTOMER_TYPE_CHANGE_ERROR =
  'FETCH_CUSTOMER_TYPE_CHANGE_ERROR'

export const CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_SUCCESS =
  'CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_SUCCESS'
export const CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_ERROR =
  'CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_ERROR'

export const UPDATE_CUSTOMER_TYPE_CHANGE_SUCCESS =
  'UPDATE_CUSTOMER_TYPE_CHANGE_SUCCESS'
export const UPDATE_CUSTOMER_TYPE_CHANGE_ERROR =
  'UPDATE_CUSTOMER_TYPE_CHANGE_ERROR'
export const UPDATE_CUSTOMER_TYPE_CHANGE_PENDING =
  'UPDATE_CUSTOMER_TYPE_CHANGE_PENDING'

export const REMOVE_CUSTOMER_TYPE_CHANGE_DUAL_AUTH =
  'REMOVE_CUSTOMER_TYPE_CHANGE_DUAL_AUTH'

export const UPDATE_KEY_RELIANCE_AGREEMENT_STATUS =
  'UPDATE_KEY_RELIANCE_AGREEMENT_STATUS'

const convertUnixToDate = (date) => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const fetchCustomerTypeChange = (type) => async (dispatch) => {
  dispatch({
    type: FETCH_CUSTOMER_TYPE_CHANGE_PROCESS,
  })
  try {
    let formattedData = []
    if (getBooleanFromValue(config.changeCustomerTypeByDualAuth)) {
      const { data } = await getAxios().get(
        `/pending/dual-auth/by-type/${type}`,
      )
      formattedData = data.map((item) => {
        item.data = JSON.parse(item.data)
        item.createdAt = convertUnixToDate(item.createdAt)
        return item
      })
    }
    dispatch({
      type: FETCH_CUSTOMER_TYPE_CHANGE_SUCCESS,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_CUSTOMER_TYPE_CHANGE_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateCustomerTypeChangeDualAuthSocketEvent =
  (newInfo) => async (dispatch) => {
    try {
      dispatch({
        type: CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_SUCCESS,
        payload: {
          data: newInfo,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_ERROR,
        payload: {
          error,
        },
      })
    }
  }

export const fetchCustomerType = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_CUSTOMER_TYPE_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/account-type`)
    const { data } = res

    dispatch({
      type: FETCH_CUSTOMER_TYPE_SUCCESS,
      payload: {
        id,
        data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.message,
    }
    dispatch({
      type: FETCH_CUSTOMER_TYPE_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}

export const updateCustomerType = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER_TYPE_CHANGE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_CUSTOMER_TYPE_CHANGE_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const removeCustomerTypeChangeDualAuth = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_CUSTOMER_TYPE_CHANGE_DUAL_AUTH,
    payload: {
      id,
    },
  })
}

export const submitCustomerTypeChangeDualAuth = async (
  values,
  dispatch,
  addComment,
) => {
  const {
    status: { value: dualAuthStatus },
    reason,
    id,
  } = values
  if (!dualAuthStatus) {
    toast('No action status provided', {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
  let log = ''
  try {
    const res = await getAxios().put(`/dual-auth/${dualAuthStatus}`, {
      id,
    })

    if (res.data.statusCode !== 202) {
      throw new Error(res.data.message)
    } else if (res.data.statusCode === 202) {
      if (dualAuthStatus === 'approve' || dualAuthStatus === 'reject') {
        dispatch(removeCustomerTypeChangeDualAuth(id))
        log =
          dualAuthStatus === 'approve'
            ? 'Approved Dual Auth'
            : 'Rejected Dual Auth.'
      }

      if (addComment && dualAuthStatus === 'reject') {
        await addUserActionComment({ ...values, dualAuthId: id })
      } else if (!addComment) {
        await updateDualAuthStatus({ ...values, dualAuthId: id, reason })
      }
      toast(log, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    }
  } catch (error) {
    toast(`${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    throw new SubmissionError({ error })
  }
}

export const updateKeyRelianceAgreementStatus =
  (accountId, status = true) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_KEY_RELIANCE_AGREEMENT_STATUS,
      payload: { accountId, status },
    })
  }
