import { createSelector } from 'reselect'
import { formValueSelector } from 'redux-form';
const selector = formValueSelector('DownloadTransactionsForm');
import moment from 'moment';

export const getTransactions = state => state.transactions.byId

export const getAccountTransactions = createSelector(
  [getTransactions],
  (transactions) => (accountId) => transactions[accountId]
)

export const getTransactionDownloadStatus = createSelector(
  [getTransactions],
  (transactions) => (accountId) => {
    return transactions[accountId]?.downloadStatus || 'pristine'
  }
)

export const getFromAndToDates = (state) => {
  const from = selector(state, 'from');
  const to = selector(state, 'to');
  return { from, to };
}

export const getAvailableTransactions = createSelector(
  [getTransactions],
  (transactions) => (accountId, state) => {
    const { from, to } = getFromAndToDates(state);
    if (!transactions[accountId]?.data || !from || !to) return [];

    const fromDate = moment(from);
    const toDate = moment(to);

    return transactions[accountId]?.data.filter(tx => {
      const dateObj = moment(tx.eventAt);
      if (fromDate.isSame(toDate, 'day')) {
        return dateObj.isSame(fromDate, 'day');
      }
      return dateObj.isBetween(fromDate, toDate, 'day', '[]');
    });
  }
);