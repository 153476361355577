import getAxios from 'helpers/axios'

import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_USER_COMMENTS_PROCESS = 'FETCH_USER_COMMENTS_PROCESS'
export const FETCH_USER_COMMENTS_SUCCESS = 'FETCH_USER_COMMENTS_SUCCESS'
export const FETCH_USER_COMMENTS_ERROR = 'FETCH_USER_COMMENTS_ERROR'

export const UPDATE_USER_COMMENTS_PROCESS = 'UPDATE_USER_COMMENTS_PROCESS'
export const UPDATE_USER_COMMENTS_SUCCESS = 'UPDATE_USER_COMMENTS_SUCCESS'
export const UPDATE_USER_COMMENTS_ERROR = 'UPDATE_USER_COMMENTS_ERROR'

export const UPDATE_INTERNAL_TRANSFER_COMMENTS_PROCESS =
  'UPDATE_INTERNAL_TRANSFER_COMMENTS_PROCESS'
export const UPDATE_INTERNAL_TRANSFER_COMMENTS_SUCCESS =
  'UPDATE_INTERNAL_TRANSFER_COMMENTS_SUCCESS'
export const UPDATE_INTERNAL_TRANSFER_COMMENTS_ERROR =
  'UPDATE_INTERNAL_TRANSFER_COMMENTS_ERROR'

export const fetchUserComments = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_USER_COMMENTS_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/comments`)
    const comments = res.data.comments.sort(
      (a, b) => new Date(b.date) - new Date(a.date),
    )
    const internalTransferComments = res.data.internalTransferComments.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    )
    dispatch({
      type: FETCH_USER_COMMENTS_SUCCESS,
      payload: {
        id,
        comments,
        internalTransferComments,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_USER_COMMENTS_ERROR,
      payload: {
        id,
        error,
      },
    })
  }
}

export const flagComment = (object) => async (dispatch) => {
  try {
    // TODO: Just rather give back the object of data that was updated instead of the entire array of comments everytime
    const { data } = await getAxios().put(
      '/account/comment/flag/userComment',
      object,
    )
    dispatch({
      type: UPDATE_USER_COMMENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const flagInternalTransferComment =
  (updateFlagStatusData) => async (dispatch) => {
    try {
      const { data } = await getAxios().put(
        '/account/comment/flag/internalTransfer',
        updateFlagStatusData,
      )

      dispatch({
        type: UPDATE_INTERNAL_TRANSFER_COMMENTS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

export const addUserActionComment = async (values) => {
  const {
    data,
    comment,
    flag,
    action,
    dualAuthStatus,
    dualAuthId,
    reason,
    oldName,
    oldSurname,
  } = values
  data.comment = comment || reason
  data.oldName = oldName
  data.oldSurname = oldSurname
  const accountId = values.accountId || values.id
  const actionType =
    action || values.actionType || values.type || 'NORMAL_COMMENT'

  try {
    await getAxios().post('/account/comments', {
      dualAuthId,
      accountId,
      data,
      flag,
      actionType,
      dualAuthStatus,
    })
  } catch (e) {
    ErrorToast(e)
  }
}

export const addParachuteUserActionComment = async (values) => {
  const {
    data,
    comment,
    flag,
    status: { value },
  } = values
  data.comment = comment
  const accountId = values.accountId || values.id
  const actionType = 'PARACHUTE_KYC_OVERRIDE'

  try {
    await getAxios().post('/account/comments', {
      accountId,
      data,
      flag,
      actionType,
      dualAuthStatus: value,
    })
  } catch (e) {
    ErrorToast(e)
  }
}

export const updateDualAuthStatus = async (object) => {
  const {
    accountId,
    actionType,
    status: { value },
    dualAuthId,
    reason,
  } = object
  let dualAuthStatus = ''
  const comment = reason || 'N/A'
  switch (value) {
    case 'approve':
      dualAuthStatus = 'Approved'
      break
    case 'reject':
      dualAuthStatus = 'Rejected'
      break

    default:
      dualAuthStatus = value
      break
  }
  try {
    await getAxios().put('/account/comment/changeDualAuthStatus', {
      accountId,
      actionType,
      dualAuthStatus,
      dualAuthId,
      comment,
    })
  } catch (error) {
    ErrorToast(error)
  }
}
