import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

export const FETCH_USER_TICKETS_PROCESS = 'FETCH_USER_TICKETS_PROCESS'
export const FETCH_USER_TICKETS_SUCCESS = 'FETCH_USER_TICKETS_SUCCESS'
export const FETCH_USER_TICKETS_ERROR = 'FETCH_USER_TICKETS_ERROR'

export const showTicket = async (ticketId) => {
  const res = await getAxios().get(`/show-ticket/${ticketId}`)
  return res.data
}

export const fetchUserTicketsByEmail = (email) => async dispatch => {
  dispatch({
    type: FETCH_USER_TICKETS_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/all-tickets/${email}`)
    const { data } = res
    const { results } = data

    if(results !== undefined) {
      dispatch({
        type: FETCH_USER_TICKETS_SUCCESS,
        payload: {
          data: results,
        },
      })
    } else {
      toast(data.message, { type: toast.TYPE.ERROR, autoClose: 2000 })
      dispatch({
        type: FETCH_USER_TICKETS_ERROR,
        payload: {
          error: data.message,
        },
      })
    }
  } catch(error) {
    dispatch({
      type: FETCH_USER_TICKETS_ERROR,
      payload: {
        error,
      },
    })
  }
}
