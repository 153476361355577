import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'

export const FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_PROCESS =
  'pendingDualAuth/FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_PROCESS'
export const FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_SUCCESS =
  'pendingInternalTransfers/FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_SUCCESS'
export const FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_ERROR =
  'pendingInternalTransfers/FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_ERROR'

export const APPROVE_CREDIT_CARD_ATTEMPT = 'APPROVE_CREDIT_CARD_ATTEMPT'
export const APPROVE_CREDIT_CARD_ATTEMPT_ERROR =
  'APPROVE_CREDIT_CARD_ATTEMPT_ERROR'
export const REJECT_CREDIT_CARD_ATTEMPT = 'REJECT_CREDIT_CARD_ATTEMPT'
export const REJECT_CREDIT_CARD_ATTEMPT_ERROR =
  'REJECT_CREDIT_CARD_ATTEMPT_ERROR'

export const fetchCreditCardDepositUnderReview = () => async dispatch => {
  dispatch({
    type: FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_PROCESS,
  })
  try {
    const res = await getAxios().get('/credit-card/deposits/review')
    const { data } = res
    dispatch({
      type: FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_CREDIT_CARD_DEPOSITS_UNDER_REVIEW_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const approveDeposit = approvedDeposit => async dispatch => {
  try {
    dispatch({
      type: APPROVE_CREDIT_CARD_ATTEMPT,
      payload: {
        approvedDeposit,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: APPROVE_CREDIT_CARD_ATTEMPT_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const rejectDeposit = rejectedDeposit => async dispatch => {
  try {
    dispatch({
      type: REJECT_CREDIT_CARD_ATTEMPT,
      payload: {
        rejectedDeposit,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: REJECT_CREDIT_CARD_ATTEMPT_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}
