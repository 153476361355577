import React from 'react'
import PropTypes from 'prop-types'
import FileUploader from 'components/molecules/FileUploader/index'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  LEGAL_ENTITY_DOCUMENT_TYPES,
  LEGAL_TRUST_ENTITY_DOCUMENT_TYPES,
  NATURAL_ENTITY_DOCUMENT_TYPES,
  LEGAL_OTHER_ENTITY_DOCUMENT_TYPES,
} from 'helpers/const'
import { StyledEntityFormRight } from '../Styles'
import { ACCEPTED_FILE_TYPES } from '../../const/CorporateDocuments'
import { InputGroup } from 'components/atoms/Form'
import { Field } from 'redux-form'
import { BASE } from 'theme/base-units'
import { renderInput } from 'forms/fields'
import { required } from 'forms/fieldValidators'

const EntityFormRight = ({
  documentType,
  setDocumentType,
  otherDocumentTypeName,
  setOtherDocumentTypeName,
  handleAddDocument,
  entityType,
  showNaturalDocumentOptions = false,
}) => {
  const formattedOtherDocumentTypeName = otherDocumentTypeName => {
    return `${
      LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER
    }:${otherDocumentTypeName.split(' ').join('_')}`
  }

  const renderEntityDocumentSelect = () => {
    if (showNaturalDocumentOptions) {
      return (
        <Select
          style={{ width: '100%' }}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={documentType}
          onChange={({ target: { value } }) => setDocumentType(value)}
          label="Document Type"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={NATURAL_ENTITY_DOCUMENT_TYPES.ID}>
            ID Document
          </MenuItem>
          <MenuItem value={NATURAL_ENTITY_DOCUMENT_TYPES.PROOF_OF_RESIDENCE}>
            Proof Of Residence
          </MenuItem>
        </Select>
      )
    }
    switch (entityType) {
      case 'PTY':
        return (
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={documentType}
            onChange={({ target: { value } }) => setDocumentType(value)}
            label="Document Type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={LEGAL_ENTITY_DOCUMENT_TYPES.REGISTRATION}>
              Registration Document
            </MenuItem>
          </Select>
        )

      case 'TRUST':
        return (
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={documentType}
            onChange={({ target: { value } }) => setDocumentType(value)}
            label="Document Type"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={LEGAL_TRUST_ENTITY_DOCUMENT_TYPES.REGISTRATION}>
              Registration Document
            </MenuItem>
            <MenuItem
              value={LEGAL_TRUST_ENTITY_DOCUMENT_TYPES.LETTER_OF_AUTHORITY}
            >
              Letter Of Authority
            </MenuItem>
          </Select>
        )

      case 'OTHER':
        return (
          <>
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={documentType}
              onChange={({ target: { value } }) => {
                setDocumentType(value)
              }}
              label="Document Type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.REGISTRATION}>
                Registration Document
              </MenuItem>
              <MenuItem
                value={LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.LETTER_OF_AUTHORITY}
              >
                Letter Of Authority
              </MenuItem>
              <MenuItem value={LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER}>
                Other document
              </MenuItem>
            </Select>
            {documentType === LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER && (
              <InputGroup align={'flex-start'} direction={'column'}>
                <Field
                  label={'Specify Document Type'}
                  name={'otherDocumentTypeName'}
                  width={'100%'}
                  margin={`${BASE}px 0 0 0`}
                  validate={[required]}
                  component={renderInput}
                  onChange={({ target: { value } }) => {
                    setOtherDocumentTypeName(value)
                  }}
                  fullWidth
                />
              </InputGroup>
            )}
          </>
        )

      default:
        return (
          <Select
            style={{ width: '100%' }}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={documentType}
            onChange={({ target: { value } }) => setDocumentType(value)}
            label="Document Type"
          >
            <MenuItem value="">
              <em>Please select a legal entity type to see options</em>
            </MenuItem>
          </Select>
        )
    }
  }
  return (
    <StyledEntityFormRight>
      <FormControl
        variant="outlined"
        style={{ width: '100%', marginBottom: 16 }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Document Type
        </InputLabel>
        {renderEntityDocumentSelect()}
      </FormControl>
      {documentType && (
        <FileUploader
          acceptedFileTypes={ACCEPTED_FILE_TYPES.ENTITY}
          clearFile={false}
          manual
          handleUpload={file => {
            const updatedDocumentType =
              documentType === LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER
                ? formattedOtherDocumentTypeName(otherDocumentTypeName)
                : documentType
            handleAddDocument(updatedDocumentType, file)
          }}
          height={100}
          uploadButtonText="UPLOAD DOCUMENT"
        />
      )}
    </StyledEntityFormRight>
  )
}

EntityFormRight.propTypes = {
  documentType: PropTypes.string,
  setDocumentType: PropTypes.func,
  handleAddDocument: PropTypes.func,
}

export default EntityFormRight
