import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { TIME_OUT_TRANSITION } from 'helpers/const'
import { VALR_BLUE_GRAY } from 'theme/colors'

import { Link } from 'containers/Accounts/SingleAccount/link'

import { showSwitchButton } from './helpers'
import { StyledAccordionContent, StyledAccordionSummaryInner } from './styles'
import { MemoizedExpandItemActions } from './ExpandItemActions'
import CircularSpinner from '../Spinners'
import { FlexRow } from 'components/layout'
import { Spacer } from '../Generic'

const useStyles = makeStyles({
  root: {
    display: 'block',
  },
  accordionBody: {
    display: 'block',
  },
  accordionContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
})

const ExpandingItem = (props) => {
  const {
    title,
    titleType,
    totalAmount,
    itemsAmount,
    expanded,
    expandedView,
    decoration,
    adornment,
    onExpand,
    opensMultiple,
    timeout = 500,
    link,
    applyHeight,
    bulkActionsActive,
    setBulkActionsActive,
    initiateBulkActionUpdate,
    selectedBulkDeposits,
    actionType,
  } = props
  const classes = useStyles(props)
  const [isButtonHidden, setIsButtonHidden] = useState(true)

  const handleChange = (panel) => (event, isExpanded) => {
    if (onExpand) onExpand(isExpanded ? panel : false)
  }

  const handleBulkSuspendClick = () => {
    if (setBulkActionsActive && showSwitchButton(titleType)) {
      setBulkActionsActive()
    }
  }
  const renderAccordionSummaryTitleSection = () => {
    if (props.loadingData) {
      return (
        <FlexRow padding="0px">
          {title}
          <Spacer margin="0px 8px" />
          <CircularSpinner size={20} />
        </FlexRow>
      )
    }

    if (itemsAmount === undefined) return title
    return `${title} (${itemsAmount}) ${totalAmount || ''} ${
      decoration ? `(${decoration})` : ''
    }`
  }

  useEffect(() => {
    if (bulkActionsActive) {
      return setIsButtonHidden(false)
    }

    setTimeout(() => {
      setIsButtonHidden(true)
    }, TIME_OUT_TRANSITION)
  }, [bulkActionsActive, isButtonHidden])

  return (
    <Accordion
      expanded={(opensMultiple && expanded) || expanded === `${title}-panel`}
      onChange={handleChange(`${title}-panel`)}
      TransitionProps={{ timeout }}
      key={`${title}-panel`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-panel-content`}
        id={`${title}-panel`}
        classes={{ content: classes.accordionContent }}
      >
        {link ? (
          <div style={{ display: 'flex' }}>
            <Link id={link.id}>{link.label}</Link>
            <span style={{ marginLeft: '10px', fontWeight: '500' }}>
              {title}
            </span>
          </div>
        ) : (
          <StyledAccordionSummaryInner>
            {renderAccordionSummaryTitleSection()}
            {itemsAmount > 0 && (
              <MemoizedExpandItemActions
                actionType={actionType}
                titleType={titleType}
                bulkActionsActive={bulkActionsActive}
                selectedBulkDeposits={selectedBulkDeposits}
                isButtonHidden={isButtonHidden}
                initiateBulkActionUpdate={initiateBulkActionUpdate}
                opensMultiple={opensMultiple}
                expanded={expanded}
                handleBulkSuspendClick={handleBulkSuspendClick}
              />
            )}
          </StyledAccordionSummaryInner>
        )}

        {adornment && (
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={(e) => e.stopPropagation()}
          >
            {adornment}
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails
        className={applyHeight ? classes.accordionBody : classes.root}
      >
        {expanded && (
          <StyledAccordionContent>{expandedView}</StyledAccordionContent>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

ExpandingItem.propTypes = {
  expandedView: PropTypes.object.isRequired,
  title: PropTypes.string,
  itemsAmount: PropTypes.any,
  totalAmount: PropTypes.any,
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  opensMultiple: PropTypes.bool,
  decoration: PropTypes.any,
  loadingData: PropTypes.bool,
}

export default memo(ExpandingItem)
