import ErrorBoundary from 'helpers/ErrorBoundary'
import React, { Component } from 'react'
import AddCurrencyPair from './currencyPairsTable'

class AddCurrencyPairs extends Component {
  render() {
    return (
      <ErrorBoundary message="Finance Dynamic Currencies Currency Pairs">
        <AddCurrencyPair />
      </ErrorBoundary>
    )
  }
}

export default AddCurrencyPairs
