import styled from 'styled-components'
import { GRAY_3 } from 'theme/colors'

export const StyledCorporateUserTableHeading = styled.th`
  border: 1px solid grey;
  padding: 8px;
`
export const StyledCorporateUserTableData = styled.td`
  border: 1px solid grey;
  padding: 8px;
`
export const StyledCorporateUserTableDataActions = styled.td`
  border: 1px solid grey;
  padding: 8px;
  text-align: center;
`

export const StyledCorporateAddressActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const StyledCorporateAddress = styled.div`
  width: 300px;
  margin-left: 8px;
`

export const StyledLinkEntityWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const StyledOrWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8;
`

export const StyledDash = styled.div`
  border: 1px solid grey;
  width: 150px;
`

export const StyledUploadErrorMessage = styled.span`
  color: red;
  font-size: 8px;
  width: 80px;
`

export const StyledUploadSuccessMessage = styled.span`
  color: green;
  font-size: 8px;
  width: 80px;
`
export const StyledDocumentIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledUnderlinedAddressHeading = styled.p`
  border-bottom: 1px solid black;
  font-size: medium;
  font-weight: 800;
`

export const StyledUploadTableDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledDocumentsActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0px;
`

export const StyledPdfIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledOverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(222, 225, 227, 0.9);
  padding: 8px 40px;
  border: 1px solid ${GRAY_3};
  border-radius: 4px;
  color: black;
  font-size: 18px;
  text-align: center;
`

export const PdfViewActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > div {
    text-align: center;
  }
`

export const StyledAddEntityFormWrapper = styled.div`
  color: ${({ theme }) => theme.container.text};
`
