import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormSuspendedDeposit from 'forms/DepositReview'
import auth from 'services/Auth'
import { fetchDepositReview } from 'redux/fiat/actions'
import BigNumber from 'bignumber.js'
import * as moment from 'moment'
import FormDepositsComments from 'forms/DepositsComments/index'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import './Styles.css'
import socket from 'redux/socket/index'
import { TransitionCell } from '../Styles'
import { RenderCheckbox } from 'forms/fields'
import EyeIcon from 'components/atoms/EyeIcon/index'
import { TableCell, TableRow } from '@material-ui/core'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      commentsStatus: 'persist',
      activeUser: '',
    }
  }

  updateComments = async depositId => {
    this.setState({ commentsStatus: 'loading' })
    const data = await fetchDepositComments(depositId)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  refineRawData = rawData => {
    const { source, amount, dateTime } = rawData
    rawData.source = source.replace(/\W\d*/g, ' ').trim()
    rawData.dateTime = moment(dateTime).format('YYYY-MMM-DD')
    var formattedAmount = new BigNumber(amount).toFormat(2)
    rawData.amount = formattedAmount !== 'NaN' ? formattedAmount : amount
    return rawData
  }

  removeActiveUser = async () => {
    const depositId = this.props.deposit.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const removeUser = { depositId, activeUserQue }
    socket.emit('remove user', removeUser)
  }

  showActiveuser = async () => {
    const depositId = this.props.deposit.depositId
    const name = auth.decodeNameToken()
    const activeUserQue = { userName: name, depositId: depositId }
    const activeUser = { depositId, activeUserQue }
    socket.emit('add user', activeUser)
  }

  renderAddNoteForm = () => {
    const {
      resetForm,
      deposit: { depositId },
    } = this.props
    return (
      <FormDepositsComments
        initialValues={{
          depositId,
          data: {
            date: new Date(),
            createdBy: auth.decodeNameToken(),
            depositsId: depositId,
          },
        }}
        form={`depositCommentsForm+${depositId}`}
        depositData={this.props.deposit}
        onCancel={resetForm}
        onSubmitSuccess={(value, dispatch) => {
          this.updateComments(depositId)
        }}
      />
    )
  }

  render() {
    const {
      deposit,
      isPending,
      formShown,
      bulkActionsActive,
      title,
      handleSelectedBulkDepositClick,
      selectedBulkDeposits,
      isCellHidden,
    } = this.props
    const {
      depositId,
      accountId,
      source,
      amount,
      depositsDescription,
      dateTime,
      insertedAt,
      tag,
      active,
      currency,
    } = deposit
    const { setHighlightedId, isHighlighted } = this.props
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const rawData = this.refineRawData(this.props.deposit)
    var shading =
      isPending && isHighlighted
        ? '#d2d3d6'
        : isPending
          ? '#8c8c8c'
          : !isPending && isHighlighted
            ? 'white'
            : ''

    const depositRowSelected = !!selectedBulkDeposits.find(
      deposit => deposit.depositId === depositId,
    )

    return (
      <Fragment>
        <TableRow
          className={isHighlighted || depositRowSelected ? 'active' : ''}
          style={{
            color: shading,
            fontWeight: '600',
          }}
          onClick={() => {
            this.updateComments(depositId)
            this.props.toggleForm(depositId)
            setHighlightedId(depositId)
          }}
        >
          <TableCell style={{ color: shading }}>
            {source.replace(/\W\d*/g, ' ').trim()}
          </TableCell>
          <TableCell style={{ color: shading }} >
            {formattedAmount !== 'NaN' ? formattedAmount : amount}
          </TableCell>
          <TableCell style={{ color: shading }} >{currency}</TableCell>
          <TableCell style={{ color: shading }} >{depositsDescription}</TableCell>
          <TableCell style={{ color: shading }}>{dateTime}</TableCell>
          <TableCell style={{ color: shading }}>{insertedAt}</TableCell>
          <TableCell style={{ color: shading }}>
            {active &&
              active.userActive && <EyeIcon text={active.activePersonName} />}
          </TableCell>
          <TableCell style={{ color: shading }}>
            {tag && <i className="fa fa-sticky-note" aria-hidden="true" />}
          </TableCell>
          <TransitionCell
            className={
              bulkActionsActive
                ? 'bulkSuspendCheckBoxActive'
                : 'bulkSuspendCheckBoxfade'
            }
            onClick={e => e.stopPropagation()}
            show={isCellHidden}
          >
            <TableCell style={{ color: shading }}>
              <RenderCheckbox
                style={{ color: depositRowSelected ? 'white' : '' }}
                checked={depositRowSelected}
                disabled={isPending}
                onCheckFunction={() =>
                  handleSelectedBulkDepositClick({ depositId, title })
                }
              />
            </TableCell>
          </TransitionCell>
        </TableRow>
        {formShown &&
          (
            <TableRow className="no-hover">
              <TableCell colSpan={12}>
                <FormSuspendedDeposit
                  initialValues={{
                    depositId,
                    accountId: accountId || '',
                    comment: '',
                  }}
                  title={this.props.title}
                  depositId={depositId}
                  expandRawData={this.props.expandRawData === depositId}
                  expandAddNote={this.props.addNoteFormShown === depositId}
                  showRawData={() => this.props.showRawData(depositId)}
                  form={`SuspendedDepositsForm+${depositId}`}
                  removeActiveUser={this.removeActiveUser}
                  showActiveUser={this.showActiveuser}
                  rawData={{ ...rawData }}
                  onCancel={this.props.toggleForm}
                  onSubmitSuccess={this.props.toggleForm}
                  showAddNote={() => this.props.toggleAddNotesForm(depositId)}
                  isInDualAuth={isPending}
                  comments={{
                    depositComments: this.state.comments,
                    status: this.state.commentsStatus,
                  }}
                />
              </TableCell>
            </TableRow>
          )}
        {this.props.addNoteFormShown && this.renderAddNoteForm()}
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDepositReview,
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(Row)
