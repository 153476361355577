import React, { Fragment, useEffect, useState } from 'react'
import IconButton from 'components/atoms/IconButton/index'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DescriptionIcon from '@material-ui/icons/Description'
import FilesUploader from 'components/molecules/FilesUploader/index'
import BasicModal from 'components/organisms/Modal/index'
import { convertToString } from 'helpers/utils'
import CircularStatic from 'components/atoms/Loaders/CircularProgressWithLabel/index'
import CircularSpinner from 'components/atoms/Spinners/index'
import {
  StyledCorporateUserTableData,
  StyledDocumentIcon,
  StyledUploadErrorMessage,
} from '../Styles'
import { ACCEPTED_FILE_TYPES } from '../../const/CorporateDocuments'
import { NATURAL_ENTITY_DOCUMENT_TYPES } from 'helpers/const'

const EntityDocumentSection = ({
  isLinkedAccount,
  canEditCorporateInformation,
  isCheckingCorporateEditorRole,
  handleUploadEntityDocumentWithProgress,
  handleViewDocuments,
  entityId,
  accountId,
  entityType,
  documentType,
  documents,
}) => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  const handleCloseUploadModal = () => {
    setUploadModalOpen(false)
  }

  const showLoader = progress => {
    switch (progress) {
      case 100:
        return <CircularSpinner color="#13ab18" />

      default:
        return <CircularStatic progress={progress} />
    }
  }

  return (
    <StyledCorporateUserTableData style={{ textAlign: 'center' }}>
      <StyledDocumentIcon>
        {(isLinkedAccount && documentType !== NATURAL_ENTITY_DOCUMENT_TYPES.PROOF_OF_RESIDENCE) ? (
          <DescriptionIcon style={{ color: 'grey', opacity: 0.6 }} />
        ) : (
          <Fragment>
            {uploadProgress === 0 && (
              <>
                {documents.length === 0 ? (
                  <IconButton
                    icon={<CloudUploadIcon />}
                    disabled={
                      !canEditCorporateInformation ||
                      isCheckingCorporateEditorRole
                    }
                    onClick={() => {
                      setUploadModalOpen(true)
                    }}
                  />
                ) : (
                  <IconButton
                    icon={<VisibilityIcon style={{ color: '#13ab18' }} />}
                    onClick={() => {
                      handleViewDocuments(entityId, documentType)
                    }}
                  />
                )}
              </>
            )}
            <StyledUploadErrorMessage>
              {uploadErrorMessage}
            </StyledUploadErrorMessage>
            {uploadProgress > 0 && showLoader(uploadProgress)}
          </Fragment>
        )}
      </StyledDocumentIcon>

      <BasicModal
        handleClose={handleCloseUploadModal}
        buttonsHidden
        open={uploadModalOpen}
        title={`${convertToString(documentType || '', '_')} Upload`}
        message=""
      >
        <div style={{ width: 400 }}>
          <FilesUploader
            acceptedFileTypes={ACCEPTED_FILE_TYPES.ENTITY}
            clearFile={false}
            manual
            handleUpload={files => {
              files.map(file => {
                handleCloseUploadModal()
                handleUploadEntityDocumentWithProgress(
                  documentType,
                  entityId,
                  accountId,
                  entityType,
                  file,
                  setUploadProgress,
                  setUploadErrorMessage,
                )
              })
            }}
            height={100}
            uploadButtonText="ADD MORE"
          />
        </div>
      </BasicModal>
    </StyledCorporateUserTableData>
  )
}

export default EntityDocumentSection
