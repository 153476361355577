
import React from 'react'
import { Table, TableCell, TableHeader } from 'components/atoms/Table/styles'
export const Headers = (props) => {
    const { expandItem } = props
    return (
        <TableHeader onClick={expandItem}>
            <TableCell flex={0.6}>Account Id</TableCell>
            <TableCell flex={0.6}>Currency</TableCell>
            <TableCell flex={0.4} marginRight={'1%'} align={'right'}>
                Amount
            </TableCell>
            <TableCell flex={0.6}style={{marginLeft:'12%'}} >Status</TableCell>
            <TableCell flex={0.6}>Created At</TableCell>
            <TableCell flex={0.9}/>
        </TableHeader>
    )
}
