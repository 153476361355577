// @ts-nocheck
import getAxios from 'helpers/axios'
import { Dispatch } from 'redux'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'

export const FETCH_ADDRESS_BOOK_START = 'FETCH_ADDRESS_BOOK_START'
export const FETCH_ADDRESS_BOOK_SUCCESS = 'FETCH_ADDRESS_BOOK_SUCCESS'
export const FETCH_ADDRESS_BOOK_ERROR = 'FETCH_ADDRESS_BOOK_ERROR'

export const UPDATE_ADDRESS_BOOK_DUAL_AUTH_ROW_USER_SUCCESS =
  'UPDATE_ADDRESS_BOOK_DUAL_AUTH_ROW_USER_SUCCESS'
export const REMOVE_SUBMITTED_WITHDRAWAL_ADDRESS_BOOK_DUAL_AUTH =
  'REMOVE_SUBMITTED_WITHDRAWAL_ADDRESS_BOOK_DUAL_AUTH'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const fetchAddressBook = type => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_ADDRESS_BOOK_START,
  })
  try {
    let formattedData = []
    const { data } = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    formattedData = data.map(item => {
      item.data = JSON.parse(item.data)
      item.createdAt = convertUnixToDate(item.createdAt)
      return item
    })
    dispatch({
      type: FETCH_ADDRESS_BOOK_SUCCESS,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_ADDRESS_BOOK_ERROR,
    })
  }
}

export const updateAddressBookDualAuthRowUserSocketEvent = newInfo => async dispatch => {
  try {
    dispatch({
      type: UPDATE_ADDRESS_BOOK_DUAL_AUTH_ROW_USER_SUCCESS,
      payload: {
        data: newInfo,
      },
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const removeSubmittedAddressBookDualAuth = id => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_WITHDRAWAL_ADDRESS_BOOK_DUAL_AUTH,
    payload: {
      id,
    },
  })
}
