import {
  SEARCH_ACCOUNTS_PROCESS,
  SEARCH_ACCOUNTS_SUCCESS,
  SEARCH_ACCOUNTS_ERROR,
  FETCH_ACCOUNT_DETAILS_PROCESS,
  FETCH_ACCOUNT_DETAILS_SUCCESS,
  FETCH_ACCOUNT_DETAILS_ERROR,
  HANDLE_ACCOUNT_FRAUD_MODAL_STATE,
  FETCH_ACCOUNT_FRAUD_INFORMATION_START,
  FETCH_ACCOUNT_FRAUD_INFORMATION_SUCCESS,
  FETCH_ACCOUNT_FRAUD_INFORMATION_ERROR,
  UPDATE_ACCOUNT_FRAUD_INFORMATION_START,
  UPDATE_ACCOUNT_FRAUD_INFORMATION_SUCCESS,
  UPDATE_ACCOUNT_FRAUD_INFORMATION_ERROR,
  FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_START,
  FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_SUCCESS,
  FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_ERROR,
  FETCH_ACCOUNT_RISK_PROFILE_START,
  FETCH_ACCOUNT_RISK_PROFILE_SUCCESS,
  FETCH_ACCOUNT_RISK_PROFILE_ERROR,
  UPDATE_RISK_PROFILE_START,
  UPDATE_RISK_PROFILE_SUCCESS,
  UPDATE_RISK_PROFILE_ERROR,
  UPDATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
  UPDATE_CORPORATE_RISK_PROFILE_SUCCESS,
  UPDATE_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
  FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_START,
  FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS,
  FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_ERROR,
  FETCH_RISK_SCORING_OPTIONS_START,
  FETCH_RISK_SCORING_OPTIONS_SUCCESS,
  FETCH_RISK_SCORING_OPTIONS_ERROR,
  FETCH_CORPORATE_FOR_ACCOUNT_START,
  FETCH_CORPORATE_FOR_ACCOUNT_SUCCESS,
  FETCH_CORPORATE_FOR_ACCOUNT_ERROR,
  UPDATE_VALR_STAFF_ACCOUNT_STATUS_START,
  UPDATE_VALR_STAFF_ACCOUNT_STATUS_SUCCESS,
  UPDATE_VALR_STAFF_ACCOUNT_STATUS_ERROR,
  FETCH_ACCOUNT_DETAILS_RBA_SUCCESS,
  FETCH_ACCOUNT_DETAILS_RBA_ERROR,
  FETCH_ACCOUNT_DETAILS_RBA_PROCESS,
} from './actions'

export const defaultAccountState = {
  accountType: null,
  info: null,
  error: null,
  status: 'pristine',
  fetchingAccountInfoForRba: false,
  isFetchingTwilio: false,
  fraud: {
    modal: {
      isOpen: false,
      actionType: '',
    },
    isFetchingFraudInformation: false,
    isUpdatingFraudInformation: false,
    alerts: [],
  },
  riskProfile: {
    info: {
      id: null,
      name: '',
      surname: '',
      accountId: null,
      sourceOfFunds: '',
      occupationStatus: '',
      purposeOfAccount: '',
      pepStatus: '',
      suspiciousTransactions: '',
      sourceOfWealth: '',
      ipLocation: '',
      productSelection: '',
      countryOfResidence: '',
      recommendationStatus: '',
      riskScore: null,
    },
    corporateInfo: {
      id: null,
      accountId: null,
      corporateId: null,
      countryOfRegistration: '',
      documentsStatus: '',
      name: '',
      natureOfBusiness: '',
      purposeOfAccount: '',
      riskScore: null,
      recommendationStatus: '',
      sourceOfFunds: '',
      createdAt: '',
      sourceOfWealth: '',
    },
    corporateEddReport: {
      id: null,
      accountId: null,
      corporateId: null,
      name: '',
      countryOfRegistration: '',
      documentsStatus: '',
      natureOfBusiness: '',
      purposeOfAccount: '',
      riskScore: null,
      recommendationStatus: '',
      sourceOfFunds: '',
      createdAt: '',
      accountOpenedDate: '',
      companyRegistrationNumber: '',
      registeredOfficeAddress: '',
      phoneNumber: '',
      emailAddress: '',
      website: '',
      typeOfCorporate: '',
      shareHolders: '',
      ultimateBeneficialOwner: '',
      directors: '',
      describedNatureOfBusinessEmploymentGeneratingFunds: '',
      originOfFunds: '',
      estimatedNetWorth: '',
      estimatedAnnualRevenue: '',
      transactionHistory: '',
      totalFiatDeposits: '',
      totalFiatWithdrawals: '',
      totalCryptoSends: '',
      totalCryptoReceives: '',
      ipLogs: '',
      high_risk_country: '',
      affiliated: '',
      criminal_history: '',
      pep_directors: '',
      prevention_measures: '',
    },
    eddReport: {
      id: null,
      accountId: null,
      fullName: '',
      accountOpenedDate: '',
      dateOfBirth: '',
      nationality: '',
      passportOrIdNumber: '',
      residentialAddress: '',
      phoneNumber: '',
      emailAddress: '',
      occupationStatus: '',
      employer: '',
      politicalExposure: '',
      criminalHistory: '',
      sourceOfFund: '',
      natureOfBusiness: '',
      estimatedAnnualIncome: '',
      purposeOfAccount: '',
      transactionHistoryInternal: '',
      transactionHistoryExternal: '',
      totalFiatDeposits: '',
      totalFiatWithdrawals: '',
      totalCryptoReceives: '',
      totalCryptoSends: '',
      offChainTransactions: '',
      ipLogs: '',
      pepStatus: '',
      highRiskCountry: '',
      affiliated: '',
      createdAt: '',
      updatedAt: '',
    },
    riskScoringFormOptions: {
      countryOfResidence: [],
      occupationStatus: [],
      sourceOfFunds: [],
      sourceOfWealth: [],
      purposeOfAccount: [],
      pepStatus: [],
      suspiciousTransactions: [],
      ipLocation: [],
      corporateScoringOptions: {
        countryOfRegistration: [],
        sourceOfFunds: [],
        purposeOfAccount: [],
        natureOfBusiness: [],
        documentsStatus: [],
        sourceOfWealth: [],
      },
    },
    accountCorporate: {
      corporateId: null,
      name: '',
      purposeOfAccount: '',
      countryOfRegistration: '',
      natureOfBusiness: '',
      sourceOfFunds: '',
      companyRegistrationNumber: '',
      corporateAddressDetails: [],
      type: '',
      sourceOfWealth: '',
    },
    accountCorporateNaturalEntities: [],
    eddDocuments: [],
    removedEddDocuments: [],
    eddReportApprovals: [],
    corporateEddReportApprovals: [],
    dualAuthHistory: [],
    corporateDualAuthHistory: [],
    isFetchingRiskProfileInfo: false,
    isFetchingRiskProfileEddReport: false,
    isUpdatingRiskProfileInfo: false,
    isUpdatingRiskProfileEddReport: false,
    isFetchingRiskScoringOptions: false,
    isFetchingCorporateForAccount: false,
    isValrStaffAccount: false,
    isUpdatingValrStaffAccountStatus: false,
  },
}

const initialState = {
  byId: defaultAccountState,
  byQuery: {},
  foundSearchedAccounts: [],
  accountCorporatesResults: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACCOUNTS_PROCESS: {
      const { query } = action.payload
      return {
        ...state,
        byQuery: {
          ...state.byQuery,
          [query]: {
            status: 'loading',
          },
        },
      }
    }
    case 'FETCH_ACCOUNT_TWILIO': {
      return {
        ...state,
        byId: {
          ...state.byId,
          isFetchingTwilio: true,
        },
      }
    }
    case SEARCH_ACCOUNTS_SUCCESS: {
      const { query, data, accountCorporatesResults } = action.payload

      return {
        ...state,
        foundSearchedAccounts: data,
        byQuery: {
          ...state.byQuery,
          [query]: {
            status: 'done',
            data,
          },
        },
        accountCorporatesResults,
      }
    }
    case 'FETCH_ACCOUNT_TWILIO_SUCCESS': {
      const { data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          ...data,
          isFetchingTwilio: false,
        },
      }
    }

    case 'FETCH_TWILIO_ERROR': {
      return {
        ...state,
        byId: {
          ...state.byId,
          isFetchingTwilio: false,
        },
      }
    }

    case SEARCH_ACCOUNTS_ERROR: {
      return {
        ...state,
        byQuery: {
          status: 'pristine',
        },
      }
    }

    case FETCH_ACCOUNT_DETAILS_PROCESS: {
      return {
        ...state,
        byId: {
          ...defaultAccountState,
          status: 'loading',
        },
      }
    }
    case FETCH_ACCOUNT_DETAILS_RBA_PROCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          fetchingAccountInfoForRba: true,
        },
      }
    }
    case FETCH_ACCOUNT_DETAILS_RBA_SUCCESS:
    case FETCH_ACCOUNT_DETAILS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload,
          status: 'done',
          fetchingAccountInfoForRba: false,
          error: null,
        },
      }
    }

    case FETCH_ACCOUNT_DETAILS_RBA_ERROR:
    case FETCH_ACCOUNT_DETAILS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          status: 'error',
          fetchingAccountInfoForRba: false,
          error,
        },
      }
    }

    case FETCH_ACCOUNT_FRAUD_INFORMATION_START: {
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isFetchingFraudInformation: true,
          },
        },
      }
    }

    case FETCH_ACCOUNT_FRAUD_INFORMATION_SUCCESS: {
      const alerts = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isFetchingFraudInformation: false,
            alerts,
          },
        },
      }
    }

    case FETCH_ACCOUNT_FRAUD_INFORMATION_ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isFetchingFraudInformation: false,
          },
        },
      }
    }

    case UPDATE_ACCOUNT_FRAUD_INFORMATION_START: {
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isUpdatingFraudInformation: true,
          },
        },
      }
    }

    case UPDATE_ACCOUNT_FRAUD_INFORMATION_SUCCESS: {
      const alerts = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isUpdatingFraudInformation: false,
            alerts,
          },
        },
      }
    }

    case UPDATE_ACCOUNT_FRAUD_INFORMATION_ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            isUpdatingFraudInformation: false,
          },
        },
      }
    }

    case HANDLE_ACCOUNT_FRAUD_MODAL_STATE: {
      const { isOpen, actionType } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          fraud: {
            ...state.byId.fraud,
            modal: {
              isOpen,
              actionType,
            },
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_START: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileInfo: true,
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_SUCCESS: {
      const { byId } = state
      const { riskProfileEddDocuments, removedEddDocuments } = action.payload
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            info:
              action.payload.riskProfile ||
              defaultAccountState.riskProfile.info,
            eddDocuments: riskProfileEddDocuments,
            removedEddDocuments,
            isFetchingRiskProfileInfo: false,
            corporateInfo:
              action.payload.corporateRiskProfile ||
              defaultAccountState.riskProfile.corporateInfo,
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_ERROR: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileInfo: false,
          },
        },
      }
    }

    case FETCH_RISK_SCORING_OPTIONS_START: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            isFetchingRiskScoringOptions: true,
          },
        },
      }
    }

    case FETCH_RISK_SCORING_OPTIONS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            riskScoringFormOptions: action.payload,
            isFetchingRiskScoringOptions: false,
          },
        },
      }
    }
    case FETCH_RISK_SCORING_OPTIONS_ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            isFetchingRiskScoringOptions: false,
          },
        },
      }
    }

    case UPDATE_RISK_PROFILE_START: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isUpdatingRiskProfileInfo: true,
          },
        },
      }
    }

    case UPDATE_RISK_PROFILE_SUCCESS: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            info: action.payload,
            isUpdatingRiskProfileInfo: false,
          },
        },
      }
    }

    case UPDATE_CORPORATE_RISK_PROFILE_SUCCESS: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            corporateInfo: action.payload,
            isUpdatingRiskProfileInfo: false,
          },
        },
      }
    }

    case UPDATE_RISK_PROFILE_ERROR: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isUpdatingRiskProfileInfo: false,
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_START: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileEddReport: true,
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_SUCCESS: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            eddReport:
              action.payload.eddReport ||
              defaultAccountState.riskProfile.eddReport,
            eddReportApprovals:
              action.payload.eddReportApprovals ||
              defaultAccountState.riskProfile.eddReportApprovals,
            dualAuthHistory:
              action.payload.dualAuthHistory ||
              defaultAccountState.riskProfile.dualAuthHistory,
            isFetchingRiskProfileEddReport: false,
          },
        },
      }
    }

    case FETCH_ACCOUNT_RISK_PROFILE_EDD_REPORT_ERROR: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileEddReport: false,
          },
        },
      }
    }
    case FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_START: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileEddReport: true,
          },
        },
      }
    }

    case FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            corporateEddReport: {
              ...byId.riskProfile.corporateEddReport,
              ...action.payload.eddReport,
            },
            corporateEddReportApprovals:
              action.payload.corporateEddReportApprovals ||
              defaultAccountState.riskProfile.corporateEddReportApprovals,
            corporateDualAuthHistory:
              action.payload.corporateDualAuthHistory ||
              defaultAccountState.riskProfile.corporateDualAuthHistory,
            isFetchingRiskProfileEddReport: false,
          },
        },
      }
    }

    case FETCH_ACCOUNT_CORPORATE_RISK_PROFILE_EDD_REPORT_ERROR: {
      const { byId } = state
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            isFetchingRiskProfileEddReport: false,
          },
        },
      }
    }

    case UPDATE_RISK_PROFILE_EDD_REPORT_SUCCESS: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            eddReport: action.payload.data,
            eddReportApprovals: action.payload.eddReportApprovals,
            isUpdatingRiskProfileEddReport: false,
          },
        },
      }
    }
    case UPDATE_CORPORATE_RISK_PROFILE_EDD_REPORT_SUCCESS: {
      const { byId } = state

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            corporateEddReport: action.payload.data,
            corporateEddReportApprovals:
              action.payload.corporateEddReportApprovals,
            isUpdatingRiskProfileEddReport: false,
          },
        },
      }
    }

    case FETCH_CORPORATE_FOR_ACCOUNT_START: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            isFetchingCorporateForAccount: true,
          },
        },
      }
    }

    case FETCH_CORPORATE_FOR_ACCOUNT_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            accountCorporate: action.payload.accountCorporate,
            accountCorporateNaturalEntities:
              action.payload.accountCorporateNaturalEntities,
            isFetchingCorporateForAccount: false,
          },
        },
      }
    }

    case FETCH_CORPORATE_FOR_ACCOUNT_ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          riskProfile: {
            ...state.byId.riskProfile,
            isFetchingCorporateForAccount: false,
          },
        },
      }
    }

    case 'ADD_RISK_PROFILE_EDD_DOCUMENT': {
      const { byId } = state
      const updatedEddDocuments = [...byId.riskProfile.eddDocuments]
      updatedEddDocuments.push(action.payload)
      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            eddDocuments: updatedEddDocuments,
          },
        },
      }
    }

    case 'REMOVE_RISK_PROFILE_EDD_DOCUMENT': {
      const { byId } = state
      const updatedRemovedEddDocuments = [
        ...byId.riskProfile.removedEddDocuments,
      ]
      const removedImage = byId.riskProfile.eddDocuments.find(
        (document) => document.id === action.payload,
      )
      if (removedImage) {
        updatedRemovedEddDocuments.push(removedImage)
      }
      const updatedEddDocuments = byId.riskProfile.eddDocuments.filter(
        (document) => document.id !== action.payload,
      )

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            eddDocuments: updatedEddDocuments,
            removedEddDocuments: updatedRemovedEddDocuments,
          },
        },
      }
    }

    case 'RESTORE_RISK_PROFILE_EDD_DOCUMENT': {
      const { byId } = state
      const updatedEddDocuments = [...byId.riskProfile.eddDocuments]
      if (action.payload) {
        updatedEddDocuments.push(action.payload)
      }
      const removedEddDocuments = byId.riskProfile.removedEddDocuments.filter(
        (document) => document.id !== action.payload.id,
      )

      return {
        ...state,
        byId: {
          ...byId,
          riskProfile: {
            ...byId.riskProfile,
            eddDocuments: updatedEddDocuments,
            removedEddDocuments,
          },
        },
      }
    }

    case UPDATE_VALR_STAFF_ACCOUNT_STATUS_START: {
      return {
        ...state,
        byId: {
          ...state.byId,
          isUpdatingValrStaffAccountStatus: true,
        },
      }
    }
    case UPDATE_VALR_STAFF_ACCOUNT_STATUS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          isValrStaffAccount: action.payload,
          isUpdatingValrStaffAccountStatus: false,
        },
      }
    }
    case UPDATE_VALR_STAFF_ACCOUNT_STATUS_ERROR: {
      return {
        ...state,
        byId: {
          ...state.byId,
          isUpdatingValrStaffAccountStatus: false,
        },
      }
    }

    case 'CLEAR_ACCOUNT_DETAILS': {
      return {
        ...state,
        byId: defaultAccountState,
      }
    }

    default:
      return state
  }
}
