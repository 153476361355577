import { SubmissionError } from 'redux-form'
import { RED, GREEN } from 'theme/colors'
import { at } from 'lodash'
import countries from 'i18n-iso-countries'
import { toast } from 'react-toastify'
import {
  COMMENT_MESSAGES,
  EXCHANGE_COMMENT_TYPES,
  SUPPORT_TEAM_ALIASES,
} from './const'
import BigNumber from 'bignumber.js'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export const capitalizeFirstLetter = str =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const formatText = str =>
  str
    .split('_')
    .map(word => capitalizeFirstLetter(word))
    .join(' ')
    .split(/(?=[A-Z])/)
    .join(' ')

export const throwGenericSubmissionError = error => {
  // const { error, message } = error.response.data
  throw new SubmissionError({
    _error: {
      message: `${error.status} - ${error.message}`,
      error,
    },
  })
}

export const formatError = error =>
  `${error.response.status} || ${error.response.statusText}`

export const urlContains = str => document.URL.indexOf(str) >= 0

export const oppositeColor = (
  value,
  colorPair = { positive: GREEN, negative: RED },
) => (Boolean(value) === true ? colorPair.positive : colorPair.negative)

export const resolve = (path, obj = {}, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export const camelCaseToText = (str) => str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())

export const getJwt = () => localStorage.getItem('admin_access_token')

export const buildQuery = queryObject => {
  const keys = Object.keys(queryObject)
  return keys
    .map(key => {
      if (!key) return ''
      let value = queryObject[key].trim()
      if (value === undefined || value === '') {
        return ''
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return ''
        }
        value = value.map(item => encodeURIComponent(item)).join(',')
      } else {
        value = encodeURIComponent(value)
      }

      return `${encodeURIComponent(key)}=${value}`
    })
    .filter(has => !!has)
    .join('&')
}

export const sortBy = function (field, reverse, primer) {
  const key = primer
    ? function (x) {
      return primer(x)
    }
    : function (x) {
      return x[field]
    }

  const revValue = !reverse ? 1 : -1

  return function (a, b) {
    const keyA: any = key(a)
    const keyB: any = key(b)
    return revValue * (Number(keyA > keyB) - Number(keyB > keyA))
  }
}

export const encodeToHex = str => Buffer.from(str).toString('hex')
export const decodeFromHex = hex => Buffer.from(hex, 'hex').toString()

export const makeDate = ({ day, year, month }) => `${year}-${month}-${day}`

export const formatDate = (dateInput: Date | string | number): string => {
  let date: Date;
  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === "number") {
    date = new Date(dateInput);
  } else if (typeof dateInput === "string") {
    date = new Date(dateInput);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string format");
    }
  } else {
    throw new Error("Invalid date input type");
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear().toString();

  return `${day} ${month} ${year}`;
}

export const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp);

  const day = date.getUTCDate();
  const month = date.toLocaleString("default", { month: "short", timeZone: "UTC" });
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
};

export const formatNumber = (input: string | number, maxDigits: number = 12): string => {
  let num = new BigNumber(input);
  if (input === "" || isNaN(num.toNumber())) {
    return "";
  }

  let numStr = num.toFixed();

  if (numStr.length > maxDigits) {
    const isNegative = num.isNegative();
    const parts = num.absoluteValue().toFixed().split('.');
    let truncatedStr = "";

    if (parts[0].length >= maxDigits) {
      truncatedStr = parts[0].substring(0, maxDigits);
    } else {
      truncatedStr = parts[0] + '.' + parts[1].substring(0, maxDigits - parts[0].length - 1);
    }

    numStr = isNegative ? '-' + truncatedStr : truncatedStr;
  }

  return numStr;
};

export const splitRowsIntoPages = (
  payload: any[],
  rowsPerPageFirst?: number,
  rowsPerPageSubsequent?: number
) => {
  // If rowsPerPageFirst and rowsPerPageSubsequent are provided, use fixed pagination
  if (rowsPerPageFirst !== undefined && rowsPerPageSubsequent !== undefined) {
    const firstPageRows = payload.slice(0, rowsPerPageFirst);
    const remainingRows = payload.slice(rowsPerPageFirst);

    const numberOfSubsequentPages = Math.ceil(remainingRows.length / rowsPerPageSubsequent);
    const subsequentPagesData = Array.from(
      { length: numberOfSubsequentPages },
      (_, i) => {
        return remainingRows.slice(
          i * rowsPerPageSubsequent,
          (i + 1) * rowsPerPageSubsequent
        );
      }
    );

    return { firstPageRows, subsequentPagesData };
  }

  // If rowsPerPageFirst and rowsPerPageSubsequent are not provided, use dynamic pagination
  const linesPerPageFirst = 23;
  const linesPerPageSubsequent = 41;

  const pages: any[][] = [];
  let currentPage: any[] = [];
  let currentLineCount = 0;
  let isFirstPage = true;

  payload.forEach(item => {
    let lineCount = 1;
    if (item && typeof item.transactionType === 'string') {
      const transactionType = item.transactionType;
      const words = transactionType.split(' ');
  
      lineCount = words.reduce((acc, word, index) => {
          const isNotFirstWord = index > 0;
          const wordLength = word.length + (isNotFirstWord ? 1 : 0);
          acc.currentLineLength += wordLength;
  
          if (acc.currentLineLength > 24) {
              acc.lineCount++;
              acc.currentLineLength = word.length;
          }
          return acc;
      }, { lineCount: 0, currentLineLength: 0 }).lineCount + 1;
  }  

    if (isFirstPage && (currentLineCount + lineCount) > linesPerPageFirst) {
      pages.push([...currentPage]);
      currentPage = [];
      currentLineCount = 0;
      isFirstPage = false;
    } else if (!isFirstPage && (currentLineCount + lineCount) > linesPerPageSubsequent) {
      pages.push([...currentPage]);
      currentPage = [];
      currentLineCount = 0;
    }

    currentPage.push(item);
    currentLineCount += lineCount;
  });

  if (currentPage.length > 0) {
    pages.push([...currentPage]);
  }

  const firstPageRows = pages.length > 0 ? pages[0] : [];
  const subsequentPagesData = pages.slice(1);

  return { firstPageRows, subsequentPagesData };
};

export const makeSearchString = (item, fieldList) =>
  at(item, fieldList)
    .join('')
    .toLowerCase()

export const getValuesFromSearch = search =>
  search
    .slice(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce((acc, pair) => {
      const [field, value] = pair
      acc[field] = decodeURIComponent(value)
      return acc
    }, {})

export const isArraySame = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  return (
    JSON.stringify(arr1.sort((a, b) => a - b)) ===
    JSON.stringify(arr2.sort((a, b) => a - b))
  )
}

export const isVerifiedAccount = accountDescription =>
  !(
    accountDescription &&
    accountDescription.toLowerCase().includes('fully-verified')
  )

export const renderPlural = arrayLength => (arrayLength > 1 ? 's' : '')

export const returnValueOnly = (value, determiningFactor) =>
  determiningFactor ? value : ''

export const flattenObject = object => {
  let toReturn = {}
  toReturn = Object.keys(object).reduce((acc, currKey) => {
    const keyToPersist = currKey
    const value = object[currKey]
    if (typeof value === 'object' && value !== null) {
      const flatObj = flattenObject(object[currKey])
      acc = {
        ...acc,
        ...Object.keys(flatObj).reduce((acc2, key) => {
          if (parseInt(key) > -1) return acc2
          if (key === 'id' && flatObj !== null) {
            acc2[`${keyToPersist} ${key}`] = flatObj[key]
          } else if (flatObj !== null) acc2[key] = flatObj[key]
          return acc2
        }, {}),
      }
    } else if (typeof value === 'boolean') {
      acc[currKey] = object[currKey].toString()
    } else {
      if (parseInt(currKey) > -1) return acc
      acc[currKey] = object[currKey]
    }
    return acc
  }, {})

  return toReturn
}

export const returnInitials = fullName => {
  const foundAlias = SUPPORT_TEAM_ALIASES.find(
    ({ alias }) => alias === fullName,
  )

  if (foundAlias) {
    return foundAlias.initials
  }

  let initialsFromName = ''

  initialsFromName =
    fullName &&
    fullName.split(' ').reduce((acc, curr) => {
      acc = `${acc}${curr.slice(0, 1)}`
      return acc
    }, '')

  return `${initialsFromName}`.toUpperCase()
}

export const returnStatusTypeComments = (comments, statusType) => {
  let data = []
  switch (statusType) {
    // Used for depositsReview & withDrawlDepositsUnderReview
    case EXCHANGE_COMMENT_TYPES.approve:
      data = comments[EXCHANGE_COMMENT_TYPES.approve]
      break

    // Used for depositsReview
    case EXCHANGE_COMMENT_TYPES.suspend:
      data = comments[EXCHANGE_COMMENT_TYPES.suspend]
      break
    case EXCHANGE_COMMENT_TYPES.ignore:
      data = comments[EXCHANGE_COMMENT_TYPES.ignore]
      break

    // Used for withDrawlDepositsUnderReview
    case EXCHANGE_COMMENT_TYPES.decline:
      data = comments[EXCHANGE_COMMENT_TYPES.decline]
      break

    default:
      break
  }
  return data
}

export interface ICommentMessages {
  [x: string]: any[]
}

export const getCommentsForExchangeForm = title => {
  const tableName = title.split(' ').join('_')
  let commentsForForm: ICommentMessages = {}
  switch (tableName) {
    case 'FIAT_DEPOSITS_FOR_REVIEW':
    case 'FIAT_POTENTIAL_DUPLICATES':
    case 'FIAT_WITHDRAWALS_UNDER_REVIEW':
    case 'USD_DEPOSITS_UNDER_REVIEW':
    case 'USD_WITHDRAWALS_UNDER_REVIEW':
    case 'FIAT_FALL_BACK_IMPORT':
    case 'FIAT_SUSPENDED_WITHDRAWALS':
    case 'FIAT_UNKNOWN_REFERENCES':
      commentsForForm = COMMENT_MESSAGES.EXCHANGE[tableName]
      break

    default:
      commentsForForm = COMMENT_MESSAGES.EXCHANGE.FIAT_DEPOSITS_FOR_REVIEW
      break
  }
  return commentsForForm
}

export const constructArrayWithInitials = (
  array: any[] = [],
  initials,
  statusType,
) => {
  const defaultComment = [{ value: '', label: 'Please select an action type' }]
  if (!statusType) return defaultComment

  if (array.length === 0) return defaultComment

  const updatedArray = array.map(answer => {
    const updatedAnswer = {
      value: `${answer.value} - ${initials}`,
      label: `${answer.value} - ${initials}`,
    }
    return updatedAnswer
  })

  return updatedArray
}

export const convertToString = (str = '', split = ' ') => {
  const string = str.split(split).reduce((acc, curr, index) => {
    if (index > 0) {
      acc += ` ${curr}`
    } else {
      acc += curr
    }
    return acc.toLocaleLowerCase()
  }, '')
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const viewPdf = (fileData, fileType) => {
  const blob = new Blob([new Uint8Array(fileData)], {
    type: fileType,
  })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.href = url
  link.target = '_blank'
  link.click()
  window.URL.revokeObjectURL(url)
  link.remove()
}

export const checkIfFallBackDepositCanBeSelected = deposit => {
  if (deposit.blockedAccount) return false
  let canSelect = true
  const acceptedStatuses = ['ACCOUNT_IDENTIFIED', 'FALLBACK_IMPORT', 'AUTO_BUY']
  deposit.statuses.forEach(({ status }) => {
    if (!acceptedStatuses.includes(status)) canSelect = false
  })
  return canSelect
}

export const checkAmountDepositsThatCanBeSelectedLength = (
  deposits,
  isFallBackDepositsApprove,
  checkIsPendingCb,
) => {
  let amountOfDepositsThatCanBeSelected = 0
  deposits.forEach(deposit => {
    if (!checkIsPendingCb(deposit)) {
      if (isFallBackDepositsApprove) {
        if (checkIfFallBackDepositCanBeSelected(deposit)) {
          amountOfDepositsThatCanBeSelected += 1
        }
      } else {
        amountOfDepositsThatCanBeSelected += 1
      }
    }
  })

  return amountOfDepositsThatCanBeSelected
}

export const getCountryOptions = () =>
  Object.values(countries.getNames('en', { select: 'official' })).map(
    country => ({
      value: countries.getAlpha3Code(country, 'en'),
      label: country,
    }),
  )

export const handleCopyToClipBoard = (value, showValueInToast = true) => {
  navigator.clipboard.writeText(value)
  const toastText = `${showValueInToast ? `${value} copied` : 'Copied'} to clipboard`
  toast(toastText, {
    type: toast.TYPE.SUCCESS,
    autoClose: 1500,
  })
}

export const handleScroll = (elementId, timeOutRef, time = 320, behavior = 'smooth') => {
  if (timeOutRef) {
    clearTimeout(timeOutRef)
    timeOutRef = null
  } else {
    const doc = document.getElementById(elementId)
    timeOutRef = setTimeout(() => {
      doc?.scrollIntoView({ behavior } as any)
    }, time)
  }
}

export const fixCamelCasing = (string) => {
  const camelCasing = string.replace(/([a-z](?=[A-Z]))/g, '$1 ')
  const fixedCamelCasing = camelCasing.charAt(0).toUpperCase() + camelCasing.slice(1)
  return fixedCamelCasing
}

export const getKeycloakGroupsOptionsDataBFS = (dataToArrange) => {
  if(!dataToArrange) return []
  const options: { value: string, label: string }[] = []
  let currentDataToTraverse = dataToArrange
  let count = 0
  while (count < currentDataToTraverse.length) {
    options.push(
      { value: currentDataToTraverse[count].id, label: currentDataToTraverse[count].path }
    )
    if (currentDataToTraverse[count].subGroups.length > 0) {
      currentDataToTraverse = currentDataToTraverse.concat(currentDataToTraverse[count].subGroups)
    }
    count += 1
  }
  return options
}

export const getKeycloakGroupsOptionsDataDFS = (dataToArrange: any[]) => {
  if(!dataToArrange) return []
  let options: { value: string, label: string }[] = []
  for (let i = 0; i < dataToArrange.length; i++) {
    options.push({ value: dataToArrange[i].id, label: dataToArrange[i].name })
    options = options.concat(getKeycloakGroupsOptionsDataDFS(dataToArrange[i].subGroups))
  }
  return options.sort((a: any, b: any) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  })
}

export const getKeycloakGroupsAndRolesDataDFS = (dataToArrange: any[]) => {
  if(!dataToArrange) return []
  let groups: {id: string,  name: string, roles: string[] }[] = []
  for (let i = 0; i < dataToArrange.length; i++) {
    groups.push({id: dataToArrange[i].id, name: dataToArrange[i].name, roles: dataToArrange[i].roles })
    groups = groups.concat(getKeycloakGroupsAndRolesDataDFS(dataToArrange[i].subGroups))
  }
  return groups.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
}

export const getBooleanFromValue = (value) => (typeof value === 'string' ? value.toLowerCase() === 'true' : value)
