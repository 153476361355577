import { makeSearchString } from 'helpers/utils'
import getAxios from 'helpers/axios'
import { fetchReviewItem } from 'redux/review/actions'

export const FETCH_ACCOUNT_SENDS_PROCESS = 'account/FETCH_ACCOUNT_SENDS_PROCESS'
export const FETCH_ACCOUNT_SENDS_SUCCESS = 'account/FETCH_ACCOUNT_SENDS_SUCCESS'
export const FETCH_ACCOUNT_SENDS_ERROR = 'account/FETCH_ACCOUNT_SENDS_ERROR'

export const FETCH_MORE_ACCOUNT_SENDS_PROCESS = 'FETCH_MORE_ACCOUNT_SENDS_PROCESS'
export const FETCH_MORE_ACCOUNT_SENDS_SUCCESS = 'FETCH_MORE_ACCOUNT_SENDS_SUCCESS'
export const FETCH_MORE_ACCOUNT_SENDS_ERROR = 'FETCH_MORE_ACCOUNT_SENDS_ERROR'

export const CLEAR_ACCOUNT_SENDS = 'CLEAR_ACCOUNT_SENDS'

const SEARCH_FIELDS = [
  'amount',
  'feeAmount',
  'sendAddress',
  'status',
  'createdAt',
  'uniqueId',
  'currency.symbol',
  'currency.longName',
]

export const fetchAccountSends = ({
  id,
  skip = 0,
  limit = 100,
  fetchMoreData = false,
}) => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNT_SENDS_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(
      `/account/sendhistory/${id}/${skip}/${limit}`,
    )

    const { data } = res

    dispatch({
      type: FETCH_ACCOUNT_SENDS_SUCCESS,
      payload: {
        fetchMoreData,
        id,
        hasMoreRecords: !(data.length === 0),
        skip,
        limit,
        data: data.map(tx => {
          if (tx.reviewItemId !== undefined) {
            dispatch(fetchReviewItem(tx.reviewItemId))
          }

          tx.meta = {
            ...tx.meta,
            searchString: makeSearchString(tx, SEARCH_FIELDS),
            timestampValue: new Date(tx.createdAt).getTime(),
          }
          return tx
        }),
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_ACCOUNT_SENDS_ERROR,
      payload: {
        id,
        error: 'Error occurred when fetching sends',
      },
    })
  }
}

export const clearAccountSendsList = () => dispatch => {
  dispatch({
    type: CLEAR_ACCOUNT_SENDS,
  })
}
