import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchAsksAndBids } from 'redux/getAsksAndBids/actions'
import { Flexbox } from 'components/layout'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import ErrorBoundary from 'helpers/ErrorBoundary'
import { useStyles } from 'helpers/tables'

import Row from './row'
import { withStyles } from '@material-ui/core/styles'

import {
  Title,
  Actions,
  ActionLabel,
  ActionInput,
  ActionInputIcon,
} from 'components/atoms/Table/styles'

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  Paper,
} from '@material-ui/core'

import {
  getAvailableSpotCurrencyPairs,
  getAvailableFuturesCurrencyPairs,
} from 'redux/currencyPairs/actions'

import { Checkbox } from '@material-ui/core'

import TextField from '@material-ui/core/TextField'

import { ShowStatus, Spacer } from 'components/atoms/Generic'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { EnhancedTableHead } from './tableHead'
import { getComparator } from 'helpers/tables'

class OrderBooks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      filter: '',
      renderData: [],
      selectedItems: [],
      expandedItem: '',
      submitted: false,
      orderBy: 'price',
      bidsOrder: 'desc',
      asksOrder: 'asc',
    }
  }

  componentDidMount() {
    if (this.props.type === 'Spot') {
      this.props.getAvailableSpotCurrencyPairs()
    } else {
      this.props.getAvailableFuturesCurrencyPairs()
    }
  }
  componentDidUpdate(prevProps) {
    const { asksAndBids } = this.props
    if (prevProps.asksAndBids !== asksAndBids) {
      this.filterData(this.state.filter)
    }
  }

  handleRowClick = (id) => {
    if (id !== this.state.expandedItem) {
      this.setState({ expandedItem: id })
    } else {
      this.setState({ expandedItem: '' })
    }
  }

  filterData = (value) => {
    const {
      asksAndBids: { data },
    } = this.props
    this.setState({
      filter: value,
    })
    const filteredData =
      value.length === 0
        ? data
        : data.filter((item) => {
            const { accountId, currencyPair, price, quantity, side } = item
            if (accountId !== undefined) {
              if (
                accountId
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                currencyPair
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                price.toString().toLowerCase().includes(value.toLowerCase()) ||
                quantity
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                side.toLowerCase().includes(value.toLowerCase())
              ) {
                return item
              }
            }
          })
    this.setState({
      renderData: filteredData,
    })
  }

  mapCurrencyPairs = (currencyPairs) =>
    currencyPairs.map((currencyPair) => ({
      value: currencyPair.currencyPairSymbol,
      label: currencyPair.currencyPairSymbol,
    }))

  setExpand = (panel) => {
    this.setState({
      expanded: panel,
    })
  }

  handleRequestSort = (event, property) => {
    const { orderBy, bidsOrder } = this.state
    const isAsc = orderBy === property && bidsOrder === 'asc'
    this.setState({ bidsOrder: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  handleAskRequestSort = (event, property) => {
    const { orderBy, asksOrder } = this.state
    const isAsc = orderBy === property && asksOrder === 'desc'
    this.setState({ asksOrder: isAsc ? 'asc' : 'desc', orderBy: property })
  }

  tableSort = (array, order, orderBy) => {
    return array.sort((a, b) => {
      const aVal = a[orderBy]
      const bVal = b[orderBy]
      if (!isNaN(aVal)) {
        a[orderBy] = parseFloat(aVal)
      }
      if (!isNaN(bVal)) {
        b[orderBy] = parseFloat(bVal)
      }
      const ordered = getComparator(order, orderBy)(a, b)
      if (ordered !== 0) return ordered
    })
  }

  render() {
    const {
      currencyPairs: {
        availableSpotCurrencyPairsList,
        fetchingSpotCurrencyPairs,
        availableFuturesCurrencyPairsList,
        fetchingFuturesCurrencyPairs,
      },
      asksAndBids: { data, status },
      type,
    } = this.props

    const { filter, renderData, asksOrder, bidsOrder, orderBy } = this.state
    const classes = withStyles(useStyles)

    const submit = () => {
      this.props.fetchAsksAndBids(this.state.selectedItems)
      this.setState({ submitted: true })
    }

    const handleAutoCompleteChange = (_, newValues) => {
      const selectedItems = newValues.map((value) => value.value)
      this.setState({ selectedItems, submitted: false })
      if (newValues.length === 0) {
        this.setState({
          renderData: [],
        })
      }
    }

    const renderMessage = () => {
      const {
        asksAndBids: { data, status },
      } = this.props
      const { renderData, filter, selectedItems, submitted } = this.state
      if (selectedItems.length <= 0) {
        return (
          <ShowStatus>Select currency pairs to fetch asks and bids</ShowStatus>
        )
      }
      if (status === 'loading') {
        return <LoadingBlock />
      }
      if (status === 'done' && !renderData.length && filter && data.length) {
        return <ShowStatus>No bids or asks due to filter results</ShowStatus>
      }
      if (status === 'done' && !renderData.length && submitted) {
        return <ShowStatus>No bids or Ask to show </ShowStatus>
      }
      if (status === 'done' && !renderData.length && !submitted) {
        return (
          <ShowStatus>Press submit to search for the selected pairs</ShowStatus>
        )
      }
    }

    const handleOptionsDisplay = () => {
      if (type === 'Spot') return availableSpotCurrencyPairsList || []
      return availableFuturesCurrencyPairsList || []
    }

    return (
      <ErrorBoundary message="Order Books">
        <Title style={{ padding: '10px' }}>Currency Pairs</Title>
        <Flexbox direction={'row'} justify={'flex-start'}>
          <Autocomplete
            id="combo-box-demo"
            multiple
            options={handleOptionsDisplay()}
            loading={
              type === 'Spot'
                ? fetchingSpotCurrencyPairs
                : fetchingFuturesCurrencyPairs
            }
            getOptionLabel={(option) => option.label}
            style={{ width: 350 }}
            disableCloseOnSelect
            onChange={handleAutoCompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select currency Pair"
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Checkbox checked={option.selected} name={props.label} />
                <label>{props.label}</label>
              </li>
            )}
          />
          <Spacer margin="0px 0px 0px 16px" />
          <MuiButton
            disabled={this.state.selectedItems.length <= 0}
            onClick={submit}
          >
            Submit
          </MuiButton>
          <Actions justify="flex-end" margin="10px 0">
            <ActionLabel>Filter:</ActionLabel>
            <ActionInput
              value={filter}
              marginRight={0}
              onChange={(event) => this.filterData(event.target.value)}
              actionView={() =>
                filter.length > 0 && (
                  <ActionInputIcon
                    title={'Clear Filter'}
                    className={'far fa-times-circle'}
                    onClick={() => this.filterData('')}
                  />
                )
              }
            />
          </Actions>
        </Flexbox>
        <Flexbox
          width={'100%'}
          direction={'row'}
          justify={'space-between'}
          align={'flex-start'}
        >
          <Flexbox width={'50%'} direction={'column'}>
            <Title>Bids</Title>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="sticky table"
                stickyHeader={true}
              >
                <EnhancedTableHead
                  order={bidsOrder}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                {renderData.length <= 0 && (
                  <TableCell colSpan={7}>{renderMessage()} </TableCell>
                )}
                <TableBody>
                  {status === 'done' &&
                    this.tableSort(renderData, bidsOrder, orderBy).map(
                      (orderBook) => {
                        if (orderBook.side === 'buy') {
                          return (
                            <Row
                              expandedItem={this.state.expandedItem}
                              handleRowClick={this.handleRowClick}
                              key={orderBook.id}
                              orderBook={orderBook}
                            />
                          )
                        }
                      },
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Flexbox>
          <Spacer margin="0px 0px 0px 8px" />
          <Flexbox width={'50%'} direction={'column'}>
            <Title>Asks</Title>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                aria-label="sticky table"
                stickyHeader={true}
              >
                <EnhancedTableHead
                  order={asksOrder}
                  orderBy={orderBy}
                  onRequestSort={this.handleAskRequestSort}
                />
                {renderData.length <= 0 && (
                  <TableCell colSpan={7}>{renderMessage()} </TableCell>
                )}
                <TableBody>
                  {status === 'done' &&
                    this.tableSort(renderData, asksOrder, orderBy).map(
                      (orderBook) => {
                        if (orderBook.side === 'sell') {
                          return (
                            <Row
                              expandedItem={this.state.expandedItem}
                              handleRowClick={this.handleRowClick}
                              key={orderBook.id}
                              orderBook={orderBook}
                            />
                          )
                        }
                      },
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Flexbox>
        </Flexbox>
      </ErrorBoundary>
    )
  }
}
const mapStateToProps = (state) => ({
  currencyPairs: state.currencyPairs,
  asksAndBids: state.asksAndBids,
  futures: state.futures,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAsksAndBids,
      getAvailableSpotCurrencyPairs,
      getAvailableFuturesCurrencyPairs,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(OrderBooks)
