import BigNumber from 'bignumber.js'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import { updateCurrencyPairTradeFees, updateDefaultTradeFees } from 'redux/tradeFees/actions'

export const submitUpdatedFees = async (formValues, dispatch) => {
  try {
    const { confirmation, isDefaultTradeFeesForm, ...fees } = formValues

    if (isDefaultTradeFeesForm) {
      const defaultTradeFees = {
        makerFee: new BigNumber(fees.makerFee).dividedBy(100),
        takerFee: new BigNumber(fees.takerFee).dividedBy(100),
        valrInstantOrderFee: new BigNumber(fees.valrInstantOrderFee).dividedBy(100),
        bittrexInstantOrderFee: new BigNumber(fees.bittrexInstantOrderFee).dividedBy(100),
        bittrexFee: new BigNumber(fees.bittrexFee).dividedBy(100),
      }
      await getAxios().post('/tradefees', defaultTradeFees)
      return await dispatch(updateDefaultTradeFees(fees))
    }
    const currencyPairTradeFees = {
      currencyPairSymbol: fees.currencyPairSymbol,
      makerFee: new BigNumber(fees.makerFee).dividedBy(100),
      takerFee: new BigNumber(fees.takerFee).dividedBy(100),
      valrInstantOrderFee: new BigNumber(fees.valrInstantOrderFee).dividedBy(100),
      foreignExchangeInstantOrderFee: new BigNumber(fees.foreignExchangeInstantOrderFee).dividedBy(100),
      foreignExchangeFee: new BigNumber(fees.foreignExchangeFee).dividedBy(100),
    }
    await getAxios().put('/tradefees/currency-pair', currencyPairTradeFees)
    await dispatch(updateCurrencyPairTradeFees(fees))
  } catch (e) {
    ErrorToast(e)
  }
  return true
}
