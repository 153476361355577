import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableHead } from '@material-ui/core'

import ConditionalOrdersRow from './ConditionalOrderRow'
import { ConditionalOrder } from 'interfaces/conditionalOrders'

interface IConditionalOrders {
  conditionalOrders: {
    data: ConditionalOrder[]
  }
  setExpandedItem: React.Dispatch<React.SetStateAction<string>>
  expandRowId: string
}

const ConditionalOrderTable: FC<IConditionalOrders> = (props) => {
  const { expandRowId, setExpandedItem, conditionalOrders } = props

  const handleOrderClick = (positionId: string) => {
    setExpandedItem((prev) => (prev === positionId ? '' : positionId))
  }

  return (
    <Table>
      <TableHead>
        <TableCell>Account Id </TableCell>
        <TableCell>Currency Pair</TableCell>
        <TableCell>Trigger Price </TableCell>
        <TableCell>Order Price</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Updated At</TableCell>
      </TableHead>
      <TableBody>
        {conditionalOrders.data.map((o) => (
          <ConditionalOrdersRow
            key={o.orderId}
            expandRowId={expandRowId}
            handleOrderClick={handleOrderClick}
            conditionalOrder={o}
          />
        ))}
      </TableBody>
    </Table>
  )
}
export default ConditionalOrderTable
