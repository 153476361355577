export const documentTypes = {
  PTY: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  CC: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  EDD: [
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'edd-letter_of_authority_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS DOCUMENT',
      documentType: 'edd-source_of_funds_document',
    },
    {
      documentLabel: 'OTHER SUPPORTING DOCUMENT',
      documentType: 'edd-other_supporting_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'edd-onboarding_questionnaire_document',
    },

  ],

  TRUST: [
    {
      documentLabel: 'TRUST DEED DOCUMENT',
      documentType: 'trust_deed_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORISED TRADERS DOCUMENT',
      documentType: 'letter_of_authorised_traders_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],

  STOKVEL: [
    {
      documentLabel: 'STOKVEL CONSTITUTION DOCUMENT',
      documentType: 'stokvel_constitution_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],

  NON_PROFIT_COMPANIES: [
    {
      documentLabel:
        'PROOF OF INCORPORATION,MEMORANDUM AND ASSOCIATION DOCUMENT',
      documentType: 'proof_of_incorporation_memorandum_association_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],

  PARTNERSHIPS: [
    {
      documentLabel: 'PROOF OF PARTNERSHIP AGREEMENT DOCUMENT',
      documentType: 'proof_of_partnership_agreement_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],

  FOREIGN_COMPANIES: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  BODY_CORPORATE: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  CHURCH: [
    {
      documentLabel: 'CHURCH CONSTITUTION DOCUMENT',
      documentType: 'church_constitution_document',
    },
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  CLUB: [
    {
      documentLabel: 'CLUB CONSTITUTION DOCUMENT',
      documentType: 'club_constitution_document',
    },
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  NON_PROFIT_ORGANISATION: [
    {
      documentLabel: 'CONSTITUTION DOCUMENT',
      documentType: 'constitution_document',
    },
    {
      documentLabel:
        'PROOF OF INCORPORATION,MEMORANDUM AND ASSOCIATION DOCUMENT',
      documentType: 'proof_of_incorporation_memorandum_association_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  SOLE_PROPRIETORSHIP: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  INTERMEDIARY_FSP: [
    {
      documentLabel: 'AML RMCP DOCUMENT',
      documentType: 'aml_rmcp_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'FSL REGISTRATION LICENSE ',
      documentType: 'fsp_registration_license',
    },
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  INTERMEDIARY_NON_FSP: [
    {
      documentLabel: 'AML RMCP DOCUMENT',
      documentType: 'aml_rmcp_document',
    },
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  ESTATE_LATE: [
    {
      documentLabel: 'LETTER CONFIRMING EXECUTORSHIP DOCUMENT',
      documentType: 'letter_confirming_the_executorship_document',
    },
    {
      documentLabel: 'DEATH CERTIFICATE DOCUMENT',
      documentType: 'death_certificate_document',
    },
    {
      documentLabel: 'CERTIFIED DECEASED CLIENT ID',
      documentType: 'certified_deceased_client_id_document',
    },
    {
      documentLabel: 'CERTIFIED EXECUTOR ID DOCUMENT',
      documentType: 'certified_executor_id_document',
    },
    {
      documentLabel: 'LAST WILL AND TESTIMANT DOCUMENT',
      documentType: 'last_will_and_testimant_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  PUBLICLY_TRADE_COMPANY: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
  OTHER: [
    {
      documentLabel: 'COMPANY REGISTRATION DOCUMENT',
      documentType: 'company_registration_document',
    },
    {
      documentLabel: 'FIC PROOF OF REGISTRATION DOCUMENT',
      documentType: 'fic_proof_of_registration_document',
    },
    {
      documentLabel: 'PROOF OF PHYSICAL ADDRESS DOCUMENT',
      documentType: 'proof_of_physical_address_document',
    },
    {
      documentLabel: 'PROOF OF BANK ACCOUNT DOCUMENT',
      documentType: 'proof_of_bank_account_document',
    },
    {
      documentLabel: 'PROOF OF INCOME TAX NUMBER DOCUMENT',
      documentType: 'proof_of_income_tax_number_document',
    },
    {
      documentLabel: 'PROOF OF VAT NUMBER DOCUMENT',
      documentType: 'proof_of_vat_number_document',
    },
    {
      documentLabel: 'LETTER OF AUTHORITY DOCUMENT',
      documentType: 'letter_of_authority_document',
    },
    {
      documentLabel: 'PROOF OF AUTHORIZED TRADERS DOCUMENT',
      documentType: 'proof_of_authorized_traders_document',
    },
    {
      documentLabel: 'ANTI MONEY LAUNDERING AND COMPLIANCE DOCUMENT',
      documentType: 'anti_money_laundering_and_compliance_document',
    },
    {
      documentLabel: 'KEY RELIANCE AGREEMENT DOCUMENT',
      documentType: 'key_reliance_agreement_document',
    },
    {
      documentLabel: 'ONBOARDING QUESTIONNAIRE',
      documentType: 'onboarding_questionnaire_document',
    },
    {
      documentLabel: 'ADDITIONAL DOCUMENTS',
      documentType: 'additional_documents_document',
    },
    {
      documentLabel: 'SOURCE OF FUNDS',
      documentType: 'source_of_funds_document',
    },
    {
      documentLabel: 'SOURCE OF WEALTH',
      documentType: 'source_of_wealth_document',
    },
    {
      documentLabel: 'RISK ACCEPTANCE ',
      documentType: 'risk_acceptance_document',
    },
  ],
}

export const ACCEPTED_FILE_TYPES = {
  ENTITY: ['image/*', '.pdf', 'image/heic'],
  CORPORATE: ['image/*'],
}
