import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import * as moment from 'moment'

export const FETCH_WITHDRAWAL_LIMIT_PROCESS = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_PROCESS'
export const FETCH_WITHDRAWAL_LIMIT_SUCCESS = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_SUCCESS'
export const FETCH_WITHDRAWAL_LIMIT_ERROR = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_ERROR'

export const FETCH_WITHDRAWAL_LIMIT_BY_ID_PROCESS = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_BY_ID_PROCESS'
export const FETCH_WITHDRAWAL_LIMIT_BY_ID_SUCCESS = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_BY_ID_SUCCESS'
export const FETCH_WITHDRAWAL_LIMIT_BY_ID_ERROR = 'pendingDualAuth/FETCH_WITHDRAWAL_LIMIT_BY_ID_ERROR'
export const REMOVE_SUBMITTED_ITEM = 'pendingDualAuth/REMOVE_SUBMITTED_ITEM'

export const UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_START = 'UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_START'
export const UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_SUCCESS = 'UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_SUCCESS'
export const UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_ERROR = 'UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_ERROR'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const fetchWithdrawalLimit = (type) => async dispatch => {

  let res = null
  dispatch({
    type: FETCH_WITHDRAWAL_LIMIT_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/by-type/${type}`)
    const { data } = res
    const formattedData = data.map(item => {
      item.data = JSON.parse(item.data)
      item.createdAt = convertUnixToDate(item.createdAt)
      return item
    })
    dispatch({
      type: FETCH_WITHDRAWAL_LIMIT_SUCCESS,
      payload: {
        data: formattedData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WITHDRAWAL_LIMIT_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}
export const updateWithdrawalLimitDualAuthUserRowSocketEvent = newInfo => async dispatch => {
  try {
    dispatch({
      type: UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_START,
    })
    dispatch({
      type: UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_SUCCESS,
      payload: {
        data: newInfo,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_WITHDRAWAL_LIMIT_DUAL_AUTH_USER_ROW_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchWithdrawalLimitById = (id) => async dispatch => {
  let res = null

  dispatch({
    type: FETCH_WITHDRAWAL_LIMIT_BY_ID_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/single/${id}`)
    const { data } = res
    data.data = JSON.parse(data.data)
    data.createdAt = convertUnixToDate(data.createdAt)
    dispatch({
      type: FETCH_WITHDRAWAL_LIMIT_BY_ID_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_WITHDRAWAL_LIMIT_BY_ID_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const removeSubmittedWithdrawalItem = (id) => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_ITEM,
    payload: {
      id,
    },
  })
}
