import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { FormContainer, FormTitle, Button, InputGroup } from 'components/atoms/Form'
import { FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS } from 'redux/marginStatus/actions'
import { BASE } from 'theme/base-units'
import { renderSwitch } from 'forms/fields'
import { submitAccountMarginStatus } from './submit'

const Form = props => {
  const { submitting, invalid, pristine } = props
  const { handleSubmit, onCancel } = props
  return (
    <FormContainer onSubmit={handleSubmit(submitAccountMarginStatus)}>
      <FormTitle>Update Margin Status</FormTitle>
      <InputGroup align={'flex-start'} direction={'rows'}>
        <Field
          label={'Active'}
          name={'marginEnabled'}
          marginRight={BASE * 2}
          component={renderSwitch}
        />
        <hr />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid || pristine} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'change_margin_status',
  onSubmitSuccess: (result, dispatch, props) => {
    dispatch({
      type: FETCH_ACCOUNT_RISK_SETTINGS_SUCCESS,
      payload: result,
    })
    props.onCancel()
  },
})(Form)

export default ReduxForm
