import React from 'react'
import { StyledIconButton } from './Styles'

function IconButton({ icon, type, onClick = () => { }, width, height, disabled }) {
  return (
    <StyledIconButton
      width={width}
      height={height}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </StyledIconButton>
  )
}

IconButton.propTypes = {}

export default IconButton
