import styled from 'styled-components'
import TableContainer from '@material-ui/core/TableContainer'

export const ConfigStyle = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Container = styled(TableContainer)({
  maxHeight: 1400,
})

export const StyledExchangeTableData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
