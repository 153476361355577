import { toast } from 'react-toastify'
import { fetchHistoryLogs, clearHistoryLogs } from 'redux/allRequest/actions'

export const submitFilter = async (values, dispatch) => {
  let newValues
  if (values.from !== undefined || values.to !== undefined) {
    const from = values.from.realDate
    const to = values.to.realDate
    const id = values.id
    const skip = values.skip
    const limit = values.limit
    const user = values.user
    const email = values.email
    newValues = { id, from, to, skip, limit, user, email }
  } else {
    const id = values.id
    const skip = values.skip
    const limit = values.limit
    const user = values.user
    const email = values.email
    newValues = { id, skip, limit, user, email }
  }

  try {
    dispatch(clearHistoryLogs())
    dispatch(fetchHistoryLogs(newValues))
  } catch (e) {
    toast(`${e} `, { type: toast.TYPE.ERROR, autoClose: 2000 })
  }
}
