import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getDeposits = state => state.deposits.byId

export const getAccountDeposits = createSelector([getDeposits, getActiveAccount], (deposits, id) => {
  if (!id || !deposits[id]) {
    return {
      status: 'pristine',
      data: [],
    }
  }

  return deposits[id]
})
