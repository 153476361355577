import React, { Component} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormLikeContainer, FormTitle, Button, InputGroup } from 'components/atoms/Form'
import { fetchOtherAuth0Accounts, linkAuth0Accounts } from 'redux/auth0/actions'
import { getOtherAuth0Accounts } from 'redux/selectors/auth0'
import IconLoading from 'components/atoms/IconLoading/index';
import { InfoBlock } from 'components/atoms/Details/index';
import { 
  Table,
  TableHeader, 
  TableCell,
  TableBody,
  TableRow
} from 'components/atoms/Table/styles';
import { JsonBlock } from 'components/atoms/Details'
import { getAuth0Info } from 'redux/selectors/auth0'

class OtherAccounts extends Component {
  constructor(props) {
    super(props)
    this.linkAccounts = this.linkAccounts.bind(this)
  }

  componentDidMount() {
    const { user, accountId } = this.props
    this.props.fetchOtherAuth0Accounts(accountId, user)
  }

  linkAccounts(index, auth0Id) {
    const { user, accountId } = this.props
    this.props.linkAuth0Accounts(accountId, index, user.user_id, auth0Id)
  }

  renderTable(list) {
    return (
      <Table>
        <TableHeader>
          <TableCell width={'20%'}>User ID</TableCell>
          <TableCell width={'10%'}>Provider</TableCell>
          <TableCell width={'10%'}>First Name</TableCell>
          <TableCell width={'10%'}>Last Name</TableCell>
          <TableCell width={'30%'}>Created At</TableCell>
          <TableCell flex={1} align={'flex-end'}></TableCell>
        </TableHeader>
        <TableBody>
          {list.map((account, index) => {
            const {
              created_at,
              identities,
              family_name,
              given_name,
              user_id,
              status
            } = account
            return (
              <TableRow key={index}>
                <TableCell width={'20%'}>{identities[0].user_id}</TableCell>
                <TableCell width={'10%'}>{identities[0].provider}</TableCell>
                <TableCell width={'10%'}>{given_name}</TableCell>
                <TableCell width={'10%'}>{family_name}</TableCell>
                <TableCell width={'30%'}>{created_at}</TableCell>
                <TableCell flex={1} align={'flex-end'}>
                  {status === 'loading' && <IconLoading />}
                  {status !== 'loading' &&
                    <InputGroup justify={'flex-end'}>
                      <Button onClick={() => {this.linkAccounts(index, user_id)}}>Link</Button>
                    </InputGroup>
                  }
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  render() {
    const { accounts, onCancel } = this.props
    return (
      <FormLikeContainer>
        <FormTitle>Other Accounts</FormTitle>
        {accounts.status === 'loading' && <IconLoading />}
        {accounts.status === 'error' && (
          <InfoBlock
            label="Fetching Error"
            value={`Code ${accounts.error.errorCode}: ${accounts.error.message}`}
          />
        )}
        {accounts.status === 'done' && <div>{this.renderTable(accounts.data)}</div>}
        <JsonBlock src={accounts.data} title={'Raw Data'} />
        <InputGroup justify={'flex-end'}>
          <Button onClick={onCancel}>Close</Button>
        </InputGroup>
      </FormLikeContainer>
    )
  }
}

const mapStateToProps = state => ({
  accounts: getOtherAuth0Accounts(state),
  info: getAuth0Info(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOtherAuth0Accounts,
      linkAuth0Accounts,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OtherAccounts)