import React from 'react'
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
} from 'components/atoms/Table/styles'
import FlagRow from './flagRow'

function ReviewStatuses({ depositId, statuses, closeOuterForm, accountId }) {
  const flags = statuses && (statuses.flags || statuses)
  return (
    flags.length > 0 && (
      <Table>
        <TableHeader>
          <TableCell width={'20%'}>Status</TableCell>
          <TableCell flex={1}>Additional Info</TableCell>
          <TableCell width={'18%'} >Inserted At</TableCell>
          <TableCell width={'20%'} />
        </TableHeader>
        <TableBody>
          {flags.map((flag, index) => {
            return (
              <FlagRow
                key={index}
                flag={flag}
                depositId={depositId}
                closeOuterForm={closeOuterForm}
                accountId={accountId}
              />
            )
          })}
        </TableBody>
      </Table>
    )
  )
}

export default ReviewStatuses
