import getAxios from 'helpers/axios'
import JsFileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { buildQuery, renderPlural, returnValueOnly } from 'helpers/utils'
import * as moment from 'moment'
import { FIAT_WITHDRAWALS_ACTIONS } from 'helpers/const'

import ErrorToast from 'components/molecules/ErrorToast'
import { fetchReviewItem } from 'redux/review/actions'
import auth from 'services/Auth'
import socket from 'redux/socket/index'

import { downloadBankInstructions } from '../../components/organisms/DownloadBankInstructions'

export const FETCH_FIAT_DEPOSIT_REVIEW_PROCESS =
  'fiat/FETCH_FIAT_DEPOSIT_REVIEW_PROCESS'
export const FETCH_FIAT_DEPOSIT_REVIEW_SUCCESS =
  'fiat/FETCH_FIAT_DEPOSIT_REVIEW_SUCCESS'
export const FETCH_FIAT_DEPOSIT_REVIEW_ERROR =
  'fiat/FETCH_FIAT_DEPOSIT_REVIEW_ERROR'

export const UPDATE_DEPOSIT_ZENDESK_MAIL_SENT_SUCCESS =
  'fiat/UPDATE_DEPOSIT_ZENDESK_MAIL_SENT_SUCCESS'

export const FETCH_FIAT_UNRESOLVED_WITHDRAWS_PROCESS =
  'fiat/FETCH_FIAT_UNRESOLVED_WITHDRAWS_PROCESS'
export const FETCH_FIAT_UNRESOLVED_WITHDRAWS_SUCCESS =
  'fiat/FETCH_FIAT_UNRESOLVED_WITHDRAWS_SUCCESS'
export const FETCH_FIAT_UNRESOLVED_WITHDRAWS_ERROR =
  'fiat/FETCH_FIAT_UNRESOLVED_WITHDRAWS_ERROR'

export const FETCH_FIAT_DEPOSIT_STATUSES_PROCESS =
  'fiat/FETCH_FIAT_DEPOSIT_STATUSES_PROCESS'
export const FETCH_FIAT_DEPOSIT_STATUSES_SUCCESS =
  'fiat/FETCH_FIAT_DEPOSIT_STATUSES_SUCCESS'
export const FETCH_FIAT_DEPOSIT_STATUSES_ERROR =
  'fiat/FETCH_FIAT_DEPOSIT_STATUSES_ERROR'

export const FETCH_FIAT_DEPOSIT_BY_STATUS_PROCESS =
  'fiat/FETCH_FIAT_DEPOSIT_BY_STATUS_PROCESS'
export const FETCH_FIAT_DEPOSIT_BY_STATUS_SUCCESS =
  'fiat/FETCH_FIAT_DEPOSIT_BY_STATUS_SUCCESS'
export const FETCH_FIAT_DEPOSIT_BY_STATUS_ERROR =
  'fiat/FETCH_FIAT_DEPOSIT_BY_STATUS_ERROR'

export const RESOLVE_FIAT_WITHDRAWS_PROCESS =
  'fiat/RESOLVE_FIAT_WITHDRAWS_PROCESS'
export const RESOLVE_FIAT_WITHDRAWS_SUCCESS =
  'fiat/RESOLVE_FIAT_WITHDRAWS_SUCCESS'
export const RESOLVE_FIAT_WITHDRAWS_ERROR = 'fiat/RESOLVE_FIAT_WITHDRAWS_ERROR'

export const FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_PROCESS =
  'fiat/FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_PROCESS'
export const FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_SUCCESS =
  'fiat/FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_SUCCESS'
export const FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_ERROR =
  'fiat/FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_ERROR'

export const UPDATE_NOTES_FOR_WITHDRAWALS_UNDER_REVIEW_SUCCESS =
  'fiat/UPDATE_NOTES_FOR_WITHDRAWALS_UNDER_REVIEW_SUCCESS'
export const UPDATE_NOTES_FOR_SUSPENDED_WITHDRAWALS_SUCCESS =
  'fiat/UPDATE_NOTES_FOR_SUSPENDED_WITHDRAWALS_SUCCESS'

export const FETCH_FIAT_PENDING_WITHDRAWS_PROCESS =
  'fiat/FETCH_FIAT_PENDING_WITHDRAWS_PROCESS'
export const FETCH_FIAT_PENDING_WITHDRAWS_SUCCESS =
  'fiat/FETCH_FIAT_PENDING_WITHDRAWS_SUCCESS'
export const FETCH_FIAT_PENDING_WITHDRAWS_ERROR =
  'fiat/FETCH_FIAT_PENDING_WITHDRAWS_ERROR'

export const DOWNLOAD_PENDING_WITHDRAWS_PROCESS =
  'fiat/DOWNLOAD_PENDING_WITHDRAWS_PROCESS'
export const DOWNLOAD_PENDING_WITHDRAWS_SUCCESS =
  'fiat/DOWNLOAD_PENDING_WITHDRAWS_SUCCESS'
export const DOWNLOAD_PENDING_WITHDRAWS_ERROR =
  'fiat/DOWNLOAD_PENDING_WITHDRAWS_ERROR'

export const FETCH_NEW_FIAT_DEPOSITS_PROCESS =
  'fiat/FETCH_NEW_FIAT_DEPOSITS_PROCESS'
export const FETCH_NEW_FIAT_DEPOSITS_SUCCESS =
  'fiat/FETCH_NEW_FIAT_DEPOSITS_SUCCESS'
export const FETCH_NEW_FIAT_DEPOSITS_ERROR =
  'fiat/FETCH_NEW_FIAT_DEPOSITS_ERROR'

export const PENDING_AUTHORISATION_UNDER_DEPOSITS =
  'fiat/PENDING_AUTHORISATION_UNDER_DEPOSITS'
export const SAVE_CHECKED_WITHDRAWALS = 'fiat/SAVE_CHECKED_WITHDRAWALS'
export const TOTAL_AMOUNT_OF_CHECKED_WITHDRAWALS =
  'fiat/TOTAL_AMOUNT_OF_CHECKED_WITHDRAWALS'
export const RESET_FIAT_DEPOSIT_BY_STATUS = 'fiat/RESET_FIAT_DEPOSIT_BY_STATUS'
export const FETCH_SUSPENDED_DEPOSIT_PROCESS = 'FETCH_SUSPENDED_DEPOSIT_PROCESS'
export const FETCH_SUSPENDED_DEPOSIT_SUCCESS =
  ' FETCH_SUSPENDED_DEPOSIT_SUCCESS'
export const FETCH_SUSPENDED_DEPOSIT_ERROR = 'FETCH_SUSPENDED_DEPOSIT_ERROR'

export const FETCH_SUSPENDED_WITHDRWALS_PROCESS =
  'FETCH_SUSPENDED_WITHDRWALS_PROCESS'
export const FETCH_SUSPENDED_WITHDRWALS_SUCCESS =
  ' FETCH_SUSPENDED_WITHDRWALS_SUCCESS'
export const FETCH_SUSPENDED_WITHDRWALS_ERROR =
  'FETCH_SUSPENDED_WITHDRWALS_ERROR'

export const MOVE_FIAT_WITHDRAWAL_TO_PENDING_START =
  'fiat/MOVE_FIAT_WITHDRAWAL_TO_PENDING_START'
export const MOVE_FIAT_WITHDRAWAL_TO_PENDING_SUCCESS =
  'fiat/MOVE_FIAT_WITHDRAWAL_TO_PENDING_SUCCESS'
export const MOVE_FIAT_WITHDRAWAL_TO_PENDING_ERROR =
  'fiat/MOVE_FIAT_WITHDRAWAL_TO_PENDING_ERROR'

export const REMOVE_SUSPENDED_DEPOSIT_PROCESS =
  'REMOVE_SUSPENDED_DEPOSIT_PROCESS'
export const REMOVE_SUSPENDED_DEPOSIT_SUCCESS =
  ' REMOVE_SUSPENDED_DEPOSIT_SUCCESS'
export const REMOVE_SUSPENDED_DEPOSIT_ERROR = 'REMOVE_SUSPENDED_DEPOSIT_ERROR'

export const UPDATE_SUSPENDED_DEPOSIT = 'UPDATE_SUSPENDED_DEPOSIT'
export const UPDATE_SUSPENDED_DEPOSIT_ERROR = 'UPDATE_SUSPENDED_DEPOSIT_ERROR'

export const APPROVE_SUSPENDED_DEPOSIT = 'APPROVE_SUSPENDED_DEPOSIT'
export const APPROVE_SUSPENDED_DEPOSIT_ERROR = 'APPROVE_SUSPENDED_DEPOSIT_ERROR'

export const REJECT_SUSPENDED_DEPOSIT = 'REJECT_SUSPENDED_DEPOSIT'
export const REJECT_SUSPENDED_DEPOSIT_ERROR = 'REJECT_SUSPENDED_DEPOSIT_ERROR'

export const UPDATE_DEPOSIT_SUCCESS = 'UPDATE_DEPOSIT_SUCCESS'
export const UPDATE_WITHDRAWAL_SUCCESS = 'UPDATE_WITHDRAWAL_SUCCESS'
export const UPDATE_WITHDRAWAL_ERROR = 'UPDATE_WITHDRAWAL_ERROR'

export const UPDATE_UNRESLOVED_WITHDRAWAL_SUCCESS =
  'UPDATE_UNRESLOVED_WITHDRAWAL_SUCCESS'
export const UPDATE_UNRESLOVED_WITHDRAWAL_ERROR =
  'UPDATE_UNRESLOVED_WITHDRAWAL_ERROR'

export const BULK_SUSPEND_DEPOSITS_START = 'BULK_SUSPEND_DEPOSITS_START'
export const BULK_SUSPEND_DEPOSITS_SUCCESS = 'BULK_SUSPEND_DEPOSITS_SUCCESS'
export const BULK_SUSPEND_DEPOSITS_ERROR = 'BULK_SUSPEND_DEPOSITS_ERROR'

export const BULK_ACTION_DEPOSITS_START = 'BULK_ACTION_DEPOSITS_START'
export const BULK_ACTION_DEPOSITS_SUCCESS = 'BULK_ACTION_DEPOSITS_SUCCESS'
export const BULK_ACTION_DEPOSITS_ERROR = 'BULK_ACTION_DEPOSITS_ERROR'

export const REVERSE_PENDING_FIAT_WITHDRAWALS_START =
  'REVERSE_PENDING_FIAT_WITHDRAWALS_START'
export const REVERSE_PENDING_FIAT_WITHDRAWALS_SUCCESS =
  'REVERSE_PENDING_FIAT_WITHDRAWALS_SUCCESS'
export const REVERSE_PENDING_FIAT_WITHDRAWALS_ERROR =
  'REVERSE_PENDING_FIAT_WITHDRAWALS_ERROR'

export const CHECKOUT_PENDING_FIAT_WITHDRAWALS_START =
  'CHECKOUT_PENDING_FIAT_WITHDRAWALS_START'
export const CHECKOUT_PENDING_FIAT_WITHDRAWALS_SUCCESS =
  'CHECKOUT_PENDING_FIAT_WITHDRAWALS_SUCCESS'
export const CHECKOUT_PENDING_FIAT_WITHDRAWALS_ERROR =
  'CHECKOUT_PENDING_FIAT_WITHDRAWALS_ERROR'

export const SET_FILTERS_START = 'SET_FILTERS_START'
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS'
export const SET_FILTERS_ERROR = 'SET_FILTERS_ERROR'
export const REMOVE_RELEASED_FIAT_DIPOSITS = 'REMOVE_RELEASED_FIAT_DIPOSITS'
export const REMOVE_RELEASED_FIAT_DIPOSITS_ERROR =
  'REMOVE_RELEASED_FIAT_DIPOSITS_ERROR'

export const SET_HISTORICAL_DEPOSIT_FILTERS_START =
  'SET_HISTORICAL_DEPOSIT_FILTERS_START'
export const SET_HISTORICAL_DEPOSIT_FILTERS_SUCCESS =
  'SET_HISTORICAL_DEPOSIT_FILTERS_SUCCESS'
export const SET_HISTORICAL_DEPOSIT_FILTERS_ERROR =
  'SET_HISTORICAL_DEPOSIT_FILTERS_ERROR'

export const UPDATE_NEW_FIAT_DEPOSIT_SUCCESS = 'UPDATE_NEW_FIAT_DEPOSIT_SUCCESS'
export const UPDATE_NEW_FIAT_DEPOSIT_ERROR = 'UPDATE_NEW_FIAT_DEPOSIT_ERROR'
export const FETCH_HISTORICAL_FIAT_DEPOSITS_PROCESS =
  'FETCH_HISTORICAL_FIAT_DEPOSITS_PROCESS'
export const FETCH_HISTORICAL_FIAT_DEPOSITS_SUCCESS =
  'FETCH_HISTORICAL_FIAT_DEPOSITS_SUCCESS'
export const FETCH_HISTORICAL_FIAT_DEPOSITS_ERROR =
  'FETCH_HISTORICAL_FIAT_DEPOSITS_ERROR'

export const FETCH_FIAT_CONFIG_START = 'FETCH_FIAT_CONFIG_START'
export const FETCH_FIAT_CONFIG_SUCCESS = 'FETCH_FIAT_CONFIG_SUCCESS'
export const FETCH_FIAT_CONFIG_ERROR = 'FETCH_FIAT_CONFIG_ERROR'

export const SET_FIAT_FILTER_WITHOUT_REFRESH = 'SET_FIAT_FILTER_WITHOUT_REFRESH'

export const CLEAR_HISTORICAL_DEPOSITS_LIST = 'CLEAR_HISTORICAL_DEPOSITS_LIST'

const mapStatuses = (status) => ({
  ...status,
  status: status.statuses
    .sort((a, b) => new Date(a.insertedAt) < new Date(b.insertedAt))
    .map((s) => `${s.status}`)
    .join(', '),
})

export const getPendingAuthorisation = (deposits) => async (dispatch) => {
  const pendingAuth = deposits
    .map((deposit) => {
      if (deposit.statuses[0].status === 'PENDING_AUTHORISATION') {
        return deposit
      }
    })
    .filter((value) => value != null)
  dispatch({
    type: PENDING_AUTHORISATION_UNDER_DEPOSITS,
    payload: pendingAuth,
  })
}

export const fetchSuspendedDeposits = (deposits) => async (dispatch) => {
  dispatch({
    type: FETCH_SUSPENDED_DEPOSIT_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().post('/suspended/deposits', deposits)
    const data = res.data.sort((a, b) => new Date(b.date) - new Date(a.date))
    dispatch({
      type: FETCH_SUSPENDED_DEPOSIT_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_SUSPENDED_DEPOSIT_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchDepositReview = (values) => async (dispatch, getState) => {
  try {
    const { from, to, currency, ...rest } = getState().fiat.filters
    const twoWeeksLater = moment().add(14, 'days').format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD')
    const query = buildQuery({
      from: from || oneWeekAgo,
      to: to || twoWeeksLater,
      ...rest,
      ...values,
      currency,
    })

    dispatch({
      type: FETCH_FIAT_DEPOSIT_REVIEW_PROCESS,
    })
    let res = null
    res = await getAxios().get(`/fiat/deposit/review/${query}`)
    const { data } = res

    const refinedData = data
      .map((value) => {
        value.statuses = value.statuses.sort(
          (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt),
        )
        return value
      })
      .sort((a, b) => new Date(b.insertedAt) - new Date(a.insertedAt))
    const mappedData = refinedData.map(mapStatuses)
    dispatch(getPendingAuthorisation(refinedData))
    const suspendedDeposits = dispatch(fetchSuspendedDeposits(mappedData))
    dispatch({
      type: FETCH_FIAT_DEPOSIT_REVIEW_SUCCESS,
      payload: {
        data: mappedData,
        query,
      },
    })
    return suspendedDeposits
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_FIAT_DEPOSIT_REVIEW_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateDepositZendeskEmailSent = (values) => async (dispatch) => {
  try {
    const { reason, depositId, requesterEmail, userName } = values
    const {
      data: { depositZendeskEmailSent, tag },
    } = await getAxios().post('/send-zendesk-email', {
      ticketData: {
        ticket: {
          subject: 'Your VALR deposit',
          comment: {
            body: reason,
          },
          requester: {
            email: requesterEmail, // The email address of the requester
          },
        },
      },
      userName,
      depositId,
    })
    dispatch({
      type: UPDATE_DEPOSIT_ZENDESK_MAIL_SENT_SUCCESS,
      payload: {
        depositZendeskEmailSent,
        depositId,
        tag,
      },
    })
    toast('Sent email')
  } catch (error) {
    ErrorToast(error)
  }
}

export const bulkUpdateDeposits = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BULK_ACTION_DEPOSITS_START,
    })
    if (data.bulkActionType === 'SEND_EMAIL') {
      await Promise.all(
        data.selectedBulkDeposits.map(async (deposit) => {
          await dispatch(
            updateDepositZendeskEmailSent({
              ...data.formData,
              ...deposit,
            }),
          )
        }),
      )
      dispatch({
        type: BULK_ACTION_DEPOSITS_SUCCESS,
      })
      toast('Bulk process complete', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      })
    } else {
      const { hasAllData } = getState().fiat.depositReview

      const query = hasAllData ? {} : undefined

      const {
        selectedBulkDeposits,
        formData: { comment },
        bulkActionType,
      } = data

      const itemsLength = selectedBulkDeposits.length

      const body = {
        depositsToPerformBulkActionOn: selectedBulkDeposits,
        comment,
        query,
      }

      const lowerCasedBulkActionType = bulkActionType.toLowerCase()

      toast(
        `Processing bulk ${lowerCasedBulkActionType} of ${itemsLength} deposit${renderPlural(
          itemsLength,
        )}.`,
        {
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        },
      )

      // QueryType can be either reject or approve
      const queryType = lowerCasedBulkActionType

      const res = await getAxios().post(
        `/fiat/deposit/review/bulk/${queryType}`,
        body,
      )

      if (res.status === 200) {
        await dispatch(fetchDepositReview(query)).then(() => {
          dispatch({
            type: BULK_ACTION_DEPOSITS_SUCCESS,
          })
          toast(res.data.message, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          })
        })
      }
    }
  } catch (error) {
    const {
      error: { message, statusCode },
      query,
    } = error.response.data
    await dispatch(fetchDepositReview(query)).then(() => {
      toast(`${statusCode} || ${message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      })
    })
  }
}

export const removeSuspendedDeposits =
  (depositId, description) => async (dispatch) => {
    try {
      const res = await getAxios().post('/suspense/type', {
        description,
        depositId,
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

export const removeFromSuspendedDeposit =
  (removeDeposit, description) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_SUSPENDED_DEPOSIT,
        payload: {
          removeDeposit,
          description,
        },
      })
    } catch (error) {
      ErrorToast(error)
      dispatch({
        type: UPDATE_SUSPENDED_DEPOSIT_ERROR,
        payload: {
          error: error.response,
        },
      })
    }
  }

export const suspendDeposits = (data, description) => async (dispatch) => {
  dispatch({
    type: BULK_SUSPEND_DEPOSITS_START,
  })
  try {
    const updatedData = data.map((deposit) => {
      deposit.description = description
      return deposit
    })
    const response = await getAxios().post(
      `/suspense/type?bulkUpdate=${true}`,
      updatedData,
    )
    const { data: updatedDeposits } = response

    if (response.status === 201 || response.status === 202) {
      const updatedDepositsCount = updatedDeposits.length
      updatedDeposits.map(async ({ depositId }) => {
        dispatch(removeFromSuspendedDeposit(depositId, description))
      })
      toast(
        `${description.toLowerCase()} ${returnValueOnly(
          updatedDepositsCount,
          updatedDeposits.length > 1,
        )} ${returnValueOnly(
          'all',
          updatedDepositsCount > 1,
        )} deposit${renderPlural(updatedDepositsCount)} successfully`,
        {
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        },
      )
    }
  } catch (error) {
    ErrorToast(error)
  }
}

export const FETCH_NOTES_TAG_PROCESS = 'FETCH_NOTES_TAG_ PROCESS'
export const FETCH_NOTES_TAG_SUCCESS = 'FETCH_NOTES_TAG_SUCCESS'
export const FETCH_NOTES_TAG_ERROR = 'FETCH_NOTES_TAG_ERROR'

export const updateFiat = (deposits) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_DEPOSIT_SUCCESS,
      payload: {
        data: deposits,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_SUSPENDED_DEPOSIT_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateNewFiatDeposits = (deposits) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_NEW_FIAT_DEPOSIT_SUCCESS,
      payload: {
        data: deposits,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_NEW_FIAT_DEPOSIT_ERROR,
    })
  }
}

export const rejectSuspendedDeposit = (rejectedId) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_SUSPENDED_DEPOSIT,
      payload: {
        rejectedId,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: REJECT_SUSPENDED_DEPOSIT_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const approveSuspendedDeposit = (removeDeposit) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_SUSPENDED_DEPOSIT,
      payload: {
        removeDeposit,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: APPROVE_SUSPENDED_DEPOSIT,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchNewDeposits = () => async (dispatch, getState) => {
  const { from, to, currency, ...rest } = getState().fiat.filters
  const twoWeeksLater = moment().add(14, 'days').format('YYYY-MM-DD')
  const oneWeekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const query = buildQuery({
    from: from || oneWeekAgo,
    to: to || twoWeeksLater,
    ...rest,
    currency,
  })

  dispatch({
    type: FETCH_NEW_FIAT_DEPOSITS_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/fiat/deposit/pending/${query}`)
    const { data } = res
    const mappedDeposits = data.map(mapStatuses)
    dispatch({
      type: FETCH_NEW_FIAT_DEPOSITS_SUCCESS,
      payload: {
        data: mappedDeposits,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_NEW_FIAT_DEPOSITS_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const removeReleasedFiatDeposits = (depositsIds) => (dispatch) => {
  try {
    dispatch({
      type: REMOVE_RELEASED_FIAT_DIPOSITS,
      payload: {
        depositsIds,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: REMOVE_RELEASED_FIAT_DIPOSITS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchDepositStatuses = () => async (dispatch) => {
  dispatch({
    type: FETCH_FIAT_DEPOSIT_STATUSES_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get('/fiat/deposits/statuses')
    const { data } = res
    dispatch({
      type: FETCH_FIAT_DEPOSIT_STATUSES_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_FIAT_DEPOSIT_STATUSES_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const fetchDepositByStatus =
  ({ status, skip, limit }) =>
    async (dispatch, getState) => {
      dispatch({
        type: FETCH_FIAT_DEPOSIT_BY_STATUS_PROCESS,
      })
      let res = null
      const { currency } = getState().fiat.filters
      try {
        res = await getAxios().get(
          `/fiat/deposits/by/status/${status}&${skip}&${limit}&${currency}`,
        )
        const { data } = res
        const refinedData = data
          .map((value) => {
            value.statuses = value.statuses.sort(
              (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt),
            )
            return value
          })
          .sort((a, b) => new Date(b.insertedAt) - new Date(a.insertedAt))
        const mappedData = refinedData.map(mapStatuses)
        dispatch({
          type: FETCH_FIAT_DEPOSIT_BY_STATUS_SUCCESS,
          payload: {
            data: mappedData,
            skip,
            limit,
            hasMoreRecords: data.length !== 0,
          },
        })
      } catch (error) {
        ErrorToast(error)
        dispatch({
          type: FETCH_FIAT_DEPOSIT_BY_STATUS_ERROR,
          payload: {
            error: error.response,
          },
        })
      }
    }

export const fetchHistoricalDeposits = () => async (dispatch, getState) => {
  const { from, to, skip, limit, fetchMoreData } =
    getState().fiat.historicalFilters

  const twoWeeksLater = moment().add(14, 'days').format('YYYY-MM-DD')
  const oneWeekAgo = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const query = buildQuery({
    from: from || oneWeekAgo,
    to: to || twoWeeksLater,
  })

  dispatch({
    type: FETCH_HISTORICAL_FIAT_DEPOSITS_PROCESS,
  })
  try {
    const { data } = await getAxios().get(
      `/fiat/deposits/${skip}/${limit}/${query}`,
    )
    const refinedData = data.sort(
      (a, b) => new Date(b.insertedAt) - new Date(a.insertedAt),
    )
    dispatch({
      type: FETCH_HISTORICAL_FIAT_DEPOSITS_SUCCESS,
      payload: {
        data: refinedData,
        skip,
        limit,
        fetchMoreData,
        hasMoreRecords: !(data.length === 0),
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_HISTORICAL_FIAT_DEPOSITS_ERROR,
    })
  }
}

export const clearDepositByStatus = () => (dispatch) => {
  dispatch({
    type: RESET_FIAT_DEPOSIT_BY_STATUS,
  })
}

export const disconnectSocket = () => {
  const name = auth.decodeNameToken()
  socket.removeAllListeners()
  socket.emit('remove on unmount', name)
  socket.disconnect()
}

export const dateFormatter = (data) => {
  const refinedData = data.sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  )
  return refinedData
}

export const fetchUnresolvedWithdraws =
  (country = '') =>
    async (dispatch) => {
      dispatch({
        type: FETCH_FIAT_UNRESOLVED_WITHDRAWS_PROCESS,
      })
      let res = null
      try {
        res = await getAxios().get(`/fiat/withdrawals/unresolved/${country}`)
        const { data } = res
        dispatch({
          type: FETCH_FIAT_UNRESOLVED_WITHDRAWS_SUCCESS,
          payload: {
            data: dateFormatter(data),
          },
        })
      } catch (error) {
        ErrorToast(error)
        dispatch({
          type: FETCH_FIAT_UNRESOLVED_WITHDRAWS_ERROR,
          payload: {
            error: error.response,
          },
        })
      }
    }

export const fetchSuspendedWithdrawals = (data) => async (dispatch) => {
  dispatch({
    type: FETCH_SUSPENDED_WITHDRWALS_PROCESS,
  })
  try {
    dispatch({
      type: FETCH_SUSPENDED_WITHDRWALS_SUCCESS,
      payload: {
        data: dateFormatter(data),
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_SUSPENDED_WITHDRWALS_ERROR,
    })
  }
}

export const fetchWithdrawalsUnderReview =
  (country = '') =>
    async (dispatch) => {
      dispatch({
        type: FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_PROCESS,
      })
      let res = null
      const suspendedWithdrawals = []
      try {
        res = await getAxios().get(`/fiat/withdrawals/review/${country}`)
        const { data } = res
        data.map((withdrawals) => {
          if (withdrawals.suspended) {
            suspendedWithdrawals.push(withdrawals)
          }
        })
        dispatch(fetchSuspendedWithdrawals(suspendedWithdrawals))
        dispatch({
          type: FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
          payload: {
            data: dateFormatter(data),
          },
        })
        data.forEach((withdrawal) => {
          const { reviewItemId: reviewId } = withdrawal
          dispatch(fetchReviewItem(reviewId))
        })
      } catch (error) {
        ErrorToast(error)
        dispatch({
          type: FETCH_FIAT_WITHDRAWALS_UNDER_REVIEW_ERROR,
          payload: {
            error: error.response.data,
          },
        })
      }
    }

export const updateNotesForWithdrawalsUnderReview =
  (withdrawalId) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_NOTES_FOR_WITHDRAWALS_UNDER_REVIEW_SUCCESS,
        payload: {
          withdrawalId,
        },
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

export const updateNotesForSuspendedWithdrawals =
  (withdrawalId) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_NOTES_FOR_SUSPENDED_WITHDRAWALS_SUCCESS,
        payload: {
          withdrawalId,
        },
      })
    } catch (error) {
      ErrorToast(error)
    }
  }

export const moveFiatWithdrawalToPending =
  (withdrawalId) => async (dispatch, getState) => {
    dispatch({
      type: MOVE_FIAT_WITHDRAWAL_TO_PENDING_START,
    })
    const reviewWithdrawals = [...getState().fiat.withdrawalsUnderReview.data]
    const withdrawalToMoveToPending = reviewWithdrawals.find(
      (withdrawal) => withdrawal.withdrawalId === withdrawalId,
    )
    if (withdrawalToMoveToPending) {
      withdrawalToMoveToPending.status = 'PAYMENT_PENDING'
      try {
        dispatch({
          type: MOVE_FIAT_WITHDRAWAL_TO_PENDING_SUCCESS,
          payload: {
            data: withdrawalToMoveToPending,
          },
        })
      } catch (error) {
        ErrorToast(error)
        dispatch({
          type: MOVE_FIAT_WITHDRAWAL_TO_PENDING_ERROR,
        })
      }
    }
  }

// TODO: FIX this spelling error
export const updateWithrawal = (withdrawals) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_WITHDRAWAL_SUCCESS,
      payload: {
        data: withdrawals,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_WITHDRAWAL_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const updateUnresolvedWithrawal = (withdrawals) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_UNRESLOVED_WITHDRAWAL_SUCCESS,
      payload: {
        data: withdrawals,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_UNRESLOVED_WITHDRAWAL_ERROR,
      payload: {
        error,
      },
    })
  }
}

const separateByBanks = (data) => {
  const allFnbWithdrawals = data.filter(
    (withdraw) => withdraw.bank.displayName === 'FNB/RMB',
  )
  const allStbWithdrawals = data.filter(
    (withdraw) => withdraw.bank.displayName === 'Standard Bank',
  )
  const allOtherWithdrawals = data.filter(
    (withdraw) =>
      withdraw.bank.displayName !== 'Standard Bank' &&
      withdraw.bank.displayName !== 'FNB/RMB',
  )
  return { allFnbWithdrawals, allStbWithdrawals, allOtherWithdrawals }
}

export const fetchPendingWithdraws =
  (country = '') =>
    async (dispatch) => {
      dispatch({
        type: FETCH_FIAT_PENDING_WITHDRAWS_PROCESS,
      })
      let res = null
      try {
        res = await getAxios().get(`/fiat/withdrawals/pending/${country}`)
        const { data } = res
        dispatch({
          type: FETCH_FIAT_PENDING_WITHDRAWS_SUCCESS,
          payload: {
            data: dateFormatter(data),
            byBank: separateByBanks(data),
          },
        })

        data.forEach((withdrawal) => {
          const { reviewItemId: reviewId } = withdrawal
          if (reviewId) dispatch(fetchReviewItem(reviewId))
        })
      } catch (error) {
        ErrorToast(error)
        dispatch({
          type: FETCH_FIAT_PENDING_WITHDRAWS_ERROR,
          payload: {
            error: error.response.data,
          },
        })
      }
    }

export const resolveFiatWithdraws = (withdraws) => async (dispatch) => {
  dispatch({ type: RESOLVE_FIAT_WITHDRAWS_PROCESS })

  try {
    await getAxios().post('/fiat/resolve/withdrawals', withdraws)
    dispatch({ type: RESOLVE_FIAT_WITHDRAWS_SUCCESS, payload: withdraws })
    toast('Resolved withdrawals', { type: toast.TYPE.SUCCESS })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: RESOLVE_FIAT_WITHDRAWS_ERROR, error })
  }
}

export const downloadPendingWithdrawals =
  (selectedWithdrawals) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_PENDING_WITHDRAWS_PROCESS })

    try {
      const response = await getAxios().post(
        '/fiat/download/pending_withdraws',
        selectedWithdrawals,
      )
      const download =
        typeof response.data === 'string' || response.data instanceof String
          ? JSON.parse(response.data)
          : response.data
      JsFileDownload(download.data, download.fileName)
      dispatch({ type: DOWNLOAD_PENDING_WITHDRAWS_SUCCESS, payload: download })
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: DOWNLOAD_PENDING_WITHDRAWS_ERROR, error })
    }
  }

export const reversePendingFiatWithdrawals =
  (pendingFiatWithdrawals) => async (dispatch) => {
    dispatch({ type: REVERSE_PENDING_FIAT_WITHDRAWALS_START })
    const checkoutBy = `${auth.decodeNameToken()} ${FIAT_WITHDRAWALS_ACTIONS.reverse
      }`

    const payload = {
      pendingFiatWithdrawals,
      checkoutBy,
    }
    try {
      const response = await getAxios().post(
        '/fiat/reverse/pending_withdraws',
        payload,
      )

      if (response.status === 200) {
        const { data } = response
        dispatch({
          type: REVERSE_PENDING_FIAT_WITHDRAWALS_SUCCESS,
          payload: data,
        })
        toast(`Updated ${data.length} withdrawal${renderPlural(data.length)}`, {
          type: toast.TYPE.SUCCESS,
          autoClose: 3500,
        })
      }
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: REVERSE_PENDING_FIAT_WITHDRAWALS_ERROR })
    }
  }

export const checkoutPendingFiatWithdrawals =
  (pendingFiatWithdrawalIds, bankAccount) => async (dispatch) => {
    dispatch({ type: CHECKOUT_PENDING_FIAT_WITHDRAWALS_START })
    const checkoutBy = `${auth.decodeNameToken()} ${FIAT_WITHDRAWALS_ACTIONS.checkout
      }`

    const payload = {
      ids: pendingFiatWithdrawalIds,
      checkoutBy,
    }

    try {
      const response = await getAxios().post(
        '/fiat/withdrawals/checkout',
        payload,
      )

      if (response.status === 200) {
        const { data } = response
        dispatch({
          type: CHECKOUT_PENDING_FIAT_WITHDRAWALS_SUCCESS,
          payload: data,
        })
        dispatch(fetchUnresolvedWithdraws(bankAccount.country))
        toast(
          `Checked out ${data.length} withdrawal${renderPlural(
            data.length,
          )}. Downloading bank instruction file.`,
          {
            type: toast.TYPE.SUCCESS,
            autoClose: 3500,
          },
        )
        downloadBankInstructions(bankAccount, data)
      }
    } catch (error) {
      ErrorToast(error)
      dispatch({ type: CHECKOUT_PENDING_FIAT_WITHDRAWALS_ERROR })
    }
  }

export const clearDownloadStatus = () => async (dispatch) => {
  dispatch({ type: DOWNLOAD_PENDING_WITHDRAWS_PROCESS })
  dispatch({
    type: FETCH_FIAT_PENDING_WITHDRAWS_PROCESS,
  })
  dispatch(fetchPendingWithdraws())
  dispatch(fetchUnresolvedWithdraws())
}

export const saveSelectedWithdrawals =
  (checkedWithdrawals) => async (dispatch) => {
    dispatch({ type: SAVE_CHECKED_WITHDRAWALS, payload: checkedWithdrawals })
  }

export const setFiatFilters = (newFilters) => async (dispatch) => {
  try {
    dispatch({ type: SET_FILTERS_START, payload: newFilters })
    // Refresh all deposits
    await dispatch(fetchDepositReview())
    await dispatch(fetchNewDeposits())
    dispatch({ type: SET_FILTERS_SUCCESS })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: SET_FILTERS_ERROR })
  }
}

export const setHistoricalFiatFilters = (newFilters) => async (dispatch) => {
  try {
    dispatch({
      type: SET_HISTORICAL_DEPOSIT_FILTERS_START,
      payload: newFilters,
    })
    await dispatch(fetchHistoricalDeposits())
    dispatch({ type: SET_HISTORICAL_DEPOSIT_FILTERS_SUCCESS })
  } catch (error) {
    dispatch({ type: SET_HISTORICAL_DEPOSIT_FILTERS_ERROR })
  }
}

export const setBankAccountFilter = (newBankAccount) => (dispatch) => {
  dispatch({
    type: SET_FIAT_FILTER_WITHOUT_REFRESH,
    payload: { bankAccount: newBankAccount },
  })
}

export const setFiatCurrencyFilter = (newCurrency) => (dispatch) => {
  dispatch({
    type: SET_FIAT_FILTER_WITHOUT_REFRESH,
    payload: { currency: newCurrency },
  })
}

export const getTotalAmountOfWithdrawsSelected =
  (allWithdraws, list) => async (dispatch) => {
    let totalAmount = 0
    for (const withdraw of allWithdraws) {
      if (list.indexOf(withdraw.withdrawalId) > -1) {
        totalAmount += parseFloat(withdraw.amount)
      }
    }

    dispatch({
      type: TOTAL_AMOUNT_OF_CHECKED_WITHDRAWALS,
      payload: totalAmount.toFixed(2),
    })
  }

export const markAsFailed = (withdraw, reverseFee) => async (dispatch) => {
  const payload = []
  dispatch({ type: RESOLVE_FIAT_WITHDRAWS_PROCESS })
  const markWithdrawalAsFailed = {
    ...withdraw,
    status: 'FAILED',
    reverseFee,
  }
  try {
    payload.push(markWithdrawalAsFailed)
    await getAxios().post('/fiat/resolve/withdrawals', payload)
    const feedback = reverseFee
      ? 'Successfully marked as failed with Reverse Fee'
      : 'Successfully marked as failed with no Reverse Fee'

    toast(feedback, { type: toast.TYPE.SUCCESS, autoClose: 3500 })

    dispatch({ type: RESOLVE_FIAT_WITHDRAWS_SUCCESS, payload })
  } catch (error) {
    ErrorToast(error)
    dispatch({ type: RESOLVE_FIAT_WITHDRAWS_ERROR, error })
  }
}

export const fetchFiatConfigStart = () => ({
  type: FETCH_FIAT_CONFIG_START,
})

export const fetchFiatConfigSuccess = (config) => ({
  type: FETCH_FIAT_CONFIG_SUCCESS,
  payload: config,
})

export const fetchFiatConfigError = (error) => ({
  type: FETCH_FIAT_CONFIG_ERROR,
  payload: error,
})

export const clearHistoricalDeposits = () => (dispatch) => {
  dispatch({
    type: CLEAR_HISTORICAL_DEPOSITS_LIST,
  })
}
