import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

import { fetchCurrencies } from 'redux/currencies/actions'
import { fetchCurrencyPairs } from 'redux/currencyPairs/actions'

export const submit = async (values, dispatch) => {
  try {
    const request = {
      path: '/admin/currencypairs',
      body: {
        symbol: values.symbol.toUpperCase(),
        baseCurrencySymbol: values.baseCurrencySymbol.value.toUpperCase(),
        quoteCurrencySymbol: values.quoteCurrencySymbol.value.toUpperCase(),
        shortName: values.shortName.toUpperCase(),
        exchange: values.exchange.value.toUpperCase(),
        active: values.active,
        minBaseAmount: values.minBaseAmount,
        maxBaseAmount: values.maxBaseAmount,
        minQuoteAmount: values.minQuoteAmount,
        maxQuoteAmount: values.maxQuoteAmount,
        tickSize: values.tickSize,
        baseDecimalPlaces: values.baseDecimalPlaces,
        currencyPairType: values.currencyPairType.value,
      },
    }
    const res = await getAxios().post('/generic', request)
    if (res.data.success) {
      toast('Currency Pair Successful added', { type: toast.TYPE.SUCCESS, autoClose: 3000 })
    } else {
      toast(res.data.error.message, { type: toast.TYPE.ERROR, autoClose: 3000 })
    }
    dispatch(values.formClose)
    dispatch(fetchCurrencies())
    dispatch(fetchCurrencyPairs())
  } catch (error) {
    toast(`Currency Pair not added, ${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2500,
    })
  }
}
