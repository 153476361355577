import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import { toast } from 'react-toastify'
import { UpdateAccountRiskStatus } from 'redux/marginStatus/actions'

export const submitAccountFuturesStatus = async (values, dispatch) => {
  const { fieldName, id, futuresEnabled } = values
  let res = null
  try {
    res = await getAxios().put('/account/risk-controls', {
      accountId: id,
      accountStatusFieldName: fieldName,
      value: futuresEnabled,
    })
    toast('Futures status updated', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(UpdateAccountRiskStatus(id, futuresEnabled))
  } catch (e) {
    ErrorToast(e)
  }
  return res
}
