import getAxios from 'helpers/axios'
import { SubmissionError } from 'redux-form'
import { toast } from 'react-toastify'
import { fetchWiredDepositUnderReview } from 'redux/circle/actions'

export const submit = async (values, dispatch) => {
  const { accountId, status, comment, depositId } = values
  let res = null
  try {
    res = await getAxios().post('/update/review/deposits', {
      accountId: accountId,
      depositId: depositId,
      status: status.value,
      comment: comment,
    })
    res.status !== 200
      ? toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 })
      : toast('Deposits Status Updated', {
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        })
    dispatch(fetchWiredDepositUnderReview())
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
    throw new SubmissionError({ error })
  }
  return res
}
