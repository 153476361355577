import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const MainScreenTableHeaders = ({ showReverseFee }) => (
  <TableHeader>
    <TableCell flex={0.5}>Account Id</TableCell>
    <TableCell flex={1.2}>Bank Account Holder</TableCell>
    <TableCell flex={1.2}>Bank</TableCell>
    <TableCell flex={1}>Fast Withdrawal</TableCell>
    <TableCell flex={1.1} align={'right'}>
      {' '}
      Amount
    </TableCell>
    <TableCell flex={0.9} align={'right'}>
      Currency
    </TableCell>
    {showReverseFee &&
      <TableCell flex={0.9} align={'right'}>
        Reverse Fee
      </TableCell>
    }
    <TableCell flex={1} align={'right'}>
      Date
    </TableCell>
    <TableCell flex={0.4} align={'right'}>
      Select
    </TableCell>
  </TableHeader>
)

export const PreviewTableHeaders = ({ showReverseFee }) => (
  <TableHeader>
    <TableCell flex={1}>Id</TableCell>
    <TableCell flex={0.8}>Account Id</TableCell>
    <TableCell flex={1}>Account Holder</TableCell>
    <TableCell flex={0.8}>Bank</TableCell>
    <TableCell flex={1}>Fast Withdrawal</TableCell>
    <TableCell flex={1} align={'right'}>
      Amount
    </TableCell>
    <TableCell flex={0.8} align={'right'}>
      Currency
    </TableCell>
    {showReverseFee &&
      <TableCell flex={0.9} align={'right'}>
        Reverse Fee
      </TableCell>
    }
  </TableHeader>
)
