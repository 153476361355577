
import { toast } from 'react-toastify'
import { LOADING, SET_TRADE_FEES, UPDATE_CURRENCY_PAIR_TRADE_FEES, UPDATE_DEFAULT_TRADE_FEES } from './actions'

const initialState = { makerFee: 'N/A', takerFee: 'N/A', loading: false, feesPerCurrencyPair: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return { ...state, loading: true }
    }

    case SET_TRADE_FEES: {
      return { ...state, ...action.value, loading: false }
    }

    case UPDATE_CURRENCY_PAIR_TRADE_FEES: {
      const updatedFeesPerCurrencyPair = [...state.feesPerCurrencyPair]
      const indexOfItem = updatedFeesPerCurrencyPair.findIndex((tradeFees) => tradeFees.currencyPairSymbol === action.payload.currencyPairSymbol)
      if (indexOfItem !== -1) {
        updatedFeesPerCurrencyPair[indexOfItem] = action.payload
        toast('Updated Trade Fees Per Currency Pair', { type: toast.TYPE.SUCCESS, autoClose: 2000 })
      } else {
        updatedFeesPerCurrencyPair.push(action.payload)
        toast('Added New Trade Fees Per Currency Pair', { type: toast.TYPE.SUCCESS, autoClose: 2000 })
      }
      return {
        ...state,
        feesPerCurrencyPair: updatedFeesPerCurrencyPair,
      }
    }
    case UPDATE_DEFAULT_TRADE_FEES: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state
    }
  }
}


