import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { TableCell, TableRow } from 'components/atoms/Table/styles'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { updateCountryPermissions } from 'redux/twilio/actions'

const TwilioCountryRow = (props) => {
  const {
    country: { name, isoCode, lowRiskNumbersEnabled },
    updateCountryPermissions: updatePermissions,
    hasEditorRights,
    loading,
  } = props

  const [isThisChanged, setIsThisChanged] = useState(false)

  const handleChange = (event) => {
    setIsThisChanged(true)
    updatePermissions(isoCode, event.target.checked)
  }

  useEffect(() => {
    if (loading === false) setIsThisChanged(false)
  }, [loading])

  return (
    <>
      <TableRow>
        <TableCell width={'55%'}>{name}</TableCell>
        <TableCell width={'20%'}>
          {isoCode}
        </TableCell>
        <TableCell width={'25%'}>
          {loading && isThisChanged ? <LoadingBlock bgColor='transparent' message='Loading'/> :
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={lowRiskNumbersEnabled}
                  onChange={handleChange}
                  name="Enabled"
                  disabled={!hasEditorRights}
                />}
              label={lowRiskNumbersEnabled ? 'Enabled' : 'Disabled'}
              style={{ marginLeft: 0 }}
            />
          }
        </TableCell>
      </TableRow>
    </>
  )
}
const mapStateToProps = state => ({
  loading: state.twilio.updateLoading,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCountryPermissions,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TwilioCountryRow)
