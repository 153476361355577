import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  TablePlaceholder,
  Actions,
  TitleContainer,
} from 'components/atoms/Table/styles'
import { renderInput as RenderInput } from 'forms/fields'

import ValrPayTransactionRow from 'components/atoms/Table/rows/ValrPayTransactionRow'
import { fetchAccountValrPayTransactions, clearValrPayTransactionsList } from 'redux/valrPay/actions'

import IconLoading from 'components/atoms/IconLoading'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { getComparator, tableSort } from 'helpers/tables'
import { EnhancedTableHead } from './tableCell'
import TablePagination from '@material-ui/core/TablePagination'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountValrPayTransactions(props) {
  const { accountId } = props.match.params
  const classes = useStyles()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('meta')
  const [filter, setFilter] = useState('')
  const [highlightedId, updateHighlightedId] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const filterData = (data) => data.filter(item => {
    const {
      type,
      status,
      amount,
      timestamp,
      currency,
    } = item
    if (
      status
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      timestamp
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase()) ||
      type.toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
      ||
      currency.toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
      ||
      amount
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
    ) {
      return item
    }
  })

  React.useEffect(() => {
    props.fetchAccountValrPayTransactions(accountId)

    return () => props.clearValrPayTransactionsList()
  }, [])

  const setHighlightedId = id => {
    updateHighlightedId(id === highlightedId ? null : id)
  }

  const handleFilter = value => {
    setFilter(value)
  }

  const { valrPayTransactions: { isLoading, data } } = props
  return (
    <div>
      {!isLoading && data.length > 0 &&
        <TitleContainer >
          <Actions justify="flex-end" margin="10px 0">
            <RenderInput
              value={filter}
              marginRight={0}
              onChange={event => handleFilter(event.target.value)}
              meta={{}}
              label={'Filter'}
            />
          </Actions>
        </TitleContainer>
      }
      <>
        {isLoading && <TablePlaceholder>
          <IconLoading />
          <span>Loading, please wait</span>
        </TablePlaceholder>}
        {!isLoading && data.length === 0 && <TablePlaceholder>{'This account does not have valr pay transactions'}</TablePlaceholder>}
        {!isLoading && data.length > 0 && (
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="sticky table"
                  stickyHeader={true}
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody id="test">
                    <>
                      {data && data.length > 0 && tableSort(filterData(data), getComparator(order, orderBy))
                        .slice(page * rowsPerPage, rowsPerPage * (page + 1)).map((row, i) => (
                          <ValrPayTransactionRow
                            tx={row}
                            accountId={accountId}
                            key={i}
                            setHighlightedId={() => setHighlightedId(i)}
                            isHighlighted={highlightedId === i}
                          />
                        ))}
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <TablePagination
              component="div"
              count={data.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        )}
      </>
    </div>

  )
}

const mapStateToProps = state => ({
  valrPayTransactions: state.valrPay,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountValrPayTransactions,
      clearValrPayTransactionsList,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountValrPayTransactions)
