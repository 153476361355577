import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { Color } from '@material-ui/lab'

import { Spacer } from 'components/atoms/Generic'
import { Fade } from '@material-ui/core'

import { Button } from 'components/atoms/Form/index'

import {
  ModalActions,
  ModalContent,
  ModalContentText,
  ModalTitle,
} from './Styles'

/**
 * Insert Types here with descriptions.
 *
 * @param {string} title is the heading for the Modal
 * @param {function} proceed is the function given to perform the action
 * @param {function} handleClose is the function given to close the Modal
 * @param {string} message is the message for the Modal
 * @param {object} button is an object that has 2 props { confirm: { text: @param {string} }, decline: { text: @param {string} }}
 * @param {boolean} buttonsHidden will hide the action buttons if true if you wish to pass differen buttons as children if needed
 * @param {node} children what ever you will like to show below the message you provided/(body of the Modal)
 * @public
 */

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8,
    color: theme.palette.primary.main,
    overflow: 'scroll',
    maxHeight: '80%',
  },
}))

interface SimpleModalProps {
  children: React.ReactNode
  open: boolean
  handleClose?: () => void
  title?: string
  message?: string | React.ReactElement
  button?: {
    decline: {
      text: string
    }
    confirm: {
      text: string
    }
  }
  declineButtonColor?: 'primary' | 'secondary' | 'default'
  confirmButtonColor?: 'primary' | 'secondary' | 'default'
  proceed?: () => void
  buttonsHidden?: boolean
  disabled?: boolean
  width?: string
  height?: string
}

const SimpleModal: FC<SimpleModalProps> = ({
  children,
  open,
  handleClose = () => {},
  title,
  message,
  button = {
    decline: {
      text: '',
    },
    confirm: {
      text: '',
    },
  },
  declineButtonColor = 'secondary',
  confirmButtonColor = 'primary',
  proceed = () => {},
  buttonsHidden,
  disabled,
  width,
  height,
}) => {
  const classes = useStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={{ width }} className={classes.paper}>
          <ModalTitle id="transition-modal-title">{title}</ModalTitle>
          <ModalContent>
            <ModalContentText id="transition-modal-description">
              {message}
            </ModalContentText>
            {children}
          </ModalContent>
          {!buttonsHidden && (
            <ModalActions>
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleClose}
                color={declineButtonColor}
              >
                {button.decline.text || 'No'}
              </Button>
              <Spacer margin="0px 8px" />
              <Button
                variant="contained"
                onClick={() => {
                  proceed()
                  handleClose()
                }}
                color={confirmButtonColor}
                autoFocus
                disabled={disabled}
              >
                {button.confirm.text || 'Yes'}
              </Button>
            </ModalActions>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default SimpleModal
