import BigNumber from 'bignumber.js'
import { CURRENCY_SHORT_NAMES } from 'enums/currency'
import { CURRENCY_TYPES } from 'types/currency'
import { CURRENCY_MAP } from './const'

export const getSymbolFromCurrency = currencyCode => {
  if (typeof currencyCode !== 'string') {
    return ''
  }

  const code = currencyCode.toUpperCase()

  if (!Object.prototype.hasOwnProperty.call(CURRENCY_MAP, code)) {
    return currencyCode
  }

  return CURRENCY_MAP[code]
}

export const removeGroupSeparators = value => {
  return value.replaceAll(',', '')
}

export const addGroupSeparatos = amount => {
  let bigNumValue = amount
  if (typeof amount === 'number') return new BigNumber(amount).toFormat(2)
  if (amount.includes(',')) {
    bigNumValue = removeGroupSeparators(amount)
  }
  if (amount.includes('.')) {
    return (
      new BigNumber(amount.split('.')[0]).toFormat() +
      '.' +
      amount.split('.')[1]
    )
  }

  return new BigNumber(bigNumValue).toFormat()
}

export const formatFieldValueWithGroupSeparators = value => {
  if (value && !isNaN(parseInt(removeGroupSeparators(value)))) {
    return addGroupSeparatos(value)
  }
  return value
}

export const addCurrencySymbol = (amount, currencyShortname) => {
  const formattedAmount = addGroupSeparatos(amount)
  const currencySymbol = getSymbolFromCurrency(currencyShortname) || 'R'
  if (formattedAmount === 'NaN' || formattedAmount.includes('Infinity'))
    return `${currencySymbol}0.00`

  return `${currencySymbol}${formattedAmount}`
}

export const formatZar = (zar: string) =>
  zar &&
  `R ${parseFloat(zar)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`

export const formatCurrency = (shortName: CURRENCY_TYPES, value: string) => {
  let currency = ''
  switch (shortName) {
    case CURRENCY_SHORT_NAMES.ZAR:
      currency = formatZar(value)
      break
    case CURRENCY_SHORT_NAMES.BTC:
      currency = `${new BigNumber(value)
        .decimalPlaces(8, BigNumber.ROUND_DOWN)
        .toFormat()} ${shortName}`
      break

    default:
      currency = `${new BigNumber(value).toFormat()} ${shortName}`
      break
  }
  return currency
}
