import React from 'react'
import { WHITE } from 'theme/colors'
import { TableCell } from '@material-ui/core'
import { StyledTableRowWithPointerHover } from 'global/StyledComponents/Table'

import FormSuspendedDeposits from 'forms/SuspendedFundingDeposits/index'
import { Link } from 'components/organisms/AccountIdLink/clickableId'
import { FundingExtraInfo } from '../FundingExtraInfo'

const SuspendedFundingRow = (props) => {
  const {
    data,
    toggleForm,
    formShown,
    isHighlighted,
    isSelected,
    isBulkActionsActive,
  } = props
  const {
    accountId,
    amount,
    createdAt,
    currency,
    status,
    identifier,
    feeAmount,
    description,
    basicInfoForDisplay,
  } = data
  return (
    <>
      <StyledTableRowWithPointerHover
        isexpanded={isHighlighted}
        isselected={isSelected}
        onClick={() => {
          toggleForm(identifier)
        }}
      >
        <TableCell>
          {accountId ? (
            <Link color={isSelected ? WHITE : ''} id={accountId} />
          ) : (
            '--'
          )}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.name : '--'}{' '}
        </TableCell>
        <TableCell>
          {basicInfoForDisplay ? basicInfoForDisplay.surname : '--'}{' '}
        </TableCell>
        <TableCell>{currency || '--'}</TableCell>
        <TableCell>{amount}</TableCell>
        <TableCell>{feeAmount}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{createdAt}</TableCell>
      </StyledTableRowWithPointerHover>
      {formShown.id === identifier && formShown.showForm && (
        <TableCell colSpan={isBulkActionsActive ? 9 : 9}>
          <FormSuspendedDeposits
            initialValues={{
              identifier,
              comment: '',
              currentStatus: status,
              currency,
              accountId,
            }}
            form={`Suspended deposit form+${identifier}`}
            onCancel={props.toggleForm}
            extra={<FundingExtraInfo data={data} />}
          />
        </TableCell>
      )}
    </>
  )
}

export default SuspendedFundingRow
