import { FontColor } from 'components/atoms/Generic'
import { FullscreenCentered } from 'components/layout'
import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PANEL_BLUE, WHITE } from 'theme/colors'

import PrivateRoute from 'components/molecules/PrivateRouite'
import auth from 'services/Auth'

import Callback from '../Callback'
import Home from '../Home'
import Login from '../Login'

const LoadingFallback = () => (
  <FullscreenCentered bgColor={PANEL_BLUE}>
    <FontColor color={WHITE}>
      <p>Loading...</p>
    </FontColor>
  </FullscreenCentered>
)

const App = () => (
  <Suspense fallback={<LoadingFallback />}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route
        path="/callback"
        render={routeProps => <Callback routeProps={routeProps} auth={auth} />}
      />
      <PrivateRoute path="/" auth={auth} component={Home} />
    </Switch>
  </Suspense>
)

export default App
