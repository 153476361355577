import styled, { keyframes } from 'styled-components'
import { GRAY_2, PANEL_BLUE, WHITE } from 'theme/colors'

const fadeInSwitcherWrapper = keyframes`
  0% {
    margin-right: -120px;
    height: auto;
  }
  100% {
    margin-right: 0px;
    height: auto;
  }
`

const fadeOutSwitcherWrapper = keyframes`
  0% {
    margin-right: 0px;
  }
  
  100% {
    margin-right: -120px;
  }
`

export const StyledSwitcherWrapper = styled.div<{
  show?: boolean
  width: string | number
}>`
  flex-direction: column;
  text-align: center;
  height: 0px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  height: 0px;
  animation: 0.5s
    ${({ show }) => (show ? fadeInSwitcherWrapper : fadeOutSwitcherWrapper)}
    forwards;
  transition: opacity 0.5s;
  align-items: center;
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
`

export const StyledSwitcherTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
`

export const SwitcherContainer = styled.div<{
  fontSize?: string
  active?: boolean
  activeColorBg?: string
  inactiveColorBg?: string
  height?: string
  width?: string
}>`
  font-size: ${({ fontSize = '17' }) => `${parseInt(fontSize, 10) + 1}px`};
  background-color: ${({
    active,
    activeColorBg = PANEL_BLUE,
    inactiveColorBg = GRAY_2,
  }) => (active ? activeColorBg : inactiveColorBg)};
  color: ${WHITE};
  border-radius: 2em;
  cursor: pointer;
  position: relative;
  padding: 2px;
  user-select: none;
  box-sizing: content-box;
  background-clip: content-box;
  height: ${({ height }) => height || 'calc(1.5em + 6px)'};
  width: ${({ width }) => width || '100%'};
  text-align: center;
`

export const Dot = styled.div<{
  active?: boolean
  height?: string
  width?: string
}>`
  position: absolute;
  left: ${(props) =>
    props.active
      ? `calc(100% - (${props.height ? props.height : '1.5em'} + 6px))`
      : '6px'};
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 100%;
  height: ${({ height }) => height || '1.5em'};
  width: ${({ height }) => height || '1.5em'};
  transition:
    background-color 0.4s,
    left 0.4s;
  box-sizing: border-box;
`

export const Label = styled.div<{ active?: boolean }>`
  position: absolute;
  left: ${({ active }) => (active ? '40%' : '60%')};
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  flex: 1;
  padding-bottom: 0.05em;
`
