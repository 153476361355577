import styled from 'styled-components'

export const StyledMultiBadge = styled.div`
  padding: 2px 8px;
  border-radius: 33px;
  background-color: ${({ background }) => background};
  color: white;
  margin-right: 8px;
  font-size: 12px;
`
