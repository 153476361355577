import getAxios from 'helpers/axios'

export const FETCH_ACCOUNT_ADDRESS_PROCESS =
  'account/FETCH_ACCOUNT_ADDRESS_PROCESS'
export const FETCH_ACCOUNT_ADDRESS_SUCCESS =
  'account/FETCH_ACCOUNT_ADDRESS_SUCCESS'
export const FETCH_ACCOUNT_ADDRESS_ERROR = 'account/FETCH_ACCOUNT_ADDRESS_ERROR'

export const fetchAccountAddress = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_ADDRESS_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/address`)
    const { data } = res
    dispatch({
      type: FETCH_ACCOUNT_ADDRESS_SUCCESS,
      payload: {
        id,
        data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.error.message,
      errorCode: error.response.data.error.code,
    }
    dispatch({
      type: FETCH_ACCOUNT_ADDRESS_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}
