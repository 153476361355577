import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  TopNavigationBar,
  NavigationTab,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { checkRole, hasCompetitionRole, ROLES } from 'helpers/roleBasedAccess'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import Config from 'config'

import {
  FundIndividual,
  FundFromFiatCsv,
  InitiateTransfer,
  InternalTransfers,
  Competition,
} from './views'

const routes = [
  '/funds/individual',
  '/funds/from-fiat-csv',
  '/funds/internal-transfer',
  '/funds/pending-internal-transfer',
  '/funds/batch-transfers',
]

const Fund = (props) => {
  const [currentPage, setCurrentPage] = useState(
    Config.isProduction ? routes[1] : routes[0],
  )
  const {
    location: { pathname },
  } = props
  const hasCompetitionRights = hasCompetitionRole()
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
  }, [pathname])

  return (
    <>
      <h1>Funding</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        {!Config.isProduction && (
          <NavigationTab
            value={routes[0]}
            label="Individual"
            component={ForwardedNavLink}
            to={routes[0]}
            exact
          />
        )}
        {checkRole(ROLES.depositUploadRole) && (
          <NavigationTab
            value={routes[1]}
            label="From Fiat Csv"
            component={ForwardedNavLink}
            to={routes[1]}
          />
        )}
        <NavigationTab
          value={routes[2]}
          label="Initiate Internal Transfer"
          component={ForwardedNavLink}
          to={routes[2]}
        />
        <NavigationTab
          value={routes[3]}
          label="Pending Internal Transfers"
          component={ForwardedNavLink}
          to={routes[3]}
        />
        {hasCompetitionRights && (
          <NavigationTab
            value={routes[4]}
            label="Batch Transfers"
            component={ForwardedNavLink}
            to={routes[4]}
          />
        )}
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          {!Config.isProduction && (
            <Route path={routes[0]} exact component={FundIndividual} />
          )}
          <Route path={routes[1]} exact component={FundFromFiatCsv} />
          <Route path={routes[2]} exact component={InitiateTransfer} />
          <Route path={routes[3]} exact component={InternalTransfers} />
          {hasCompetitionRights && (
            <Route path={routes[4]} exact component={Competition} />
          )}
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Fund
