import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { getSymbolFromCurrency } from 'helpers/currency'

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from 'components/atoms/Table/styles'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'
import { getSelectedBankAccount } from 'redux/fiat/selectors'

import SuspendedWithdrawalsRow from './row'

function SuspendedWithdrawals(props) {
  const [selectedId, setSelectedId] = useState(-1)
  const [highlightedId, updateHighlightedId] = useState('')
  const [expandRawData, setExpandRawData] = useState('')
  const [addNoteFormShown, setAddNoteFormShown] = useState('')

  const countAllWithdrawals = () => {
    const {
      suspendedWithdrawals: { data },
    } = props
    const counter = data
      ? data.reduce((accum, value) => accum + Number(value.amount), 0)
      : 0
    return `${new BigNumber(counter).toFormat(2)}`
  }

  const toggleSelected = id => setSelectedId(prev => (prev === id ? '' : id))
  const toggleAddNotesForm = id =>
    setAddNoteFormShown(prev => (prev === id ? '' : id))
  const showRawData = id => setExpandRawData(prev => (prev === id ? '' : id))
  const setHighlightedId = id => {
    updateHighlightedId(id)
  }

  const { name, setExpand, expanded } = props
  const {
    suspendedWithdrawals: { status, data },
    reviews,
    currency,
  } = props
  const hasWithdrawals = data && data.length > 0
  return (
    <>
      <ExpandingItem
        title={`${name}`}
        itemsAmount={(data && data.length) || 0}
        totalAmount={`(${getSymbolFromCurrency(
          currency,
        )}${countAllWithdrawals() || 0})`}
        expanded={expanded}
        onExpand={setExpand}
        expandedView={
          <>
            {status === 'loading' && <LoadingBlock />}
            {status === 'done' &&
              !hasWithdrawals && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.WITHDRAWALS.type}
                />
              )}
            {hasWithdrawals && (
              <Table>
                <TableHeader>
                  <TableCell flex={0.5}>Account Id</TableCell>
                  <TableCell flex={1.2}>Bank Account Holder</TableCell>
                  <TableCell flex={1.2}>Bank</TableCell>
                  <TableCell flex={1}>Fast Withdrawal</TableCell>
                  <TableCell flex={1} align={'right'}>
                    {' '}
                    Amount
                  </TableCell>
                  <TableCell flex={0.8} align={'right'}>
                    Currency
                  </TableCell>
                  <TableCell flex={1} align={'right'}>
                    Date
                  </TableCell>
                  <TableCell flex={0.5} />
                  <TableCell flex={0.18} />
                </TableHeader>
                <TableBody>
                  {status === 'done' &&
                    data &&
                    data.map(withdraw => {
                      withdraw.updatedAt = moment(withdraw.updatedAt).format(
                        'YYYY-MMM-DD HH:mm:ss',
                      )
                      return (
                        <SuspendedWithdrawalsRow
                          title={name}
                          key={withdraw.withdrawalId}
                          withdrawal={withdraw}
                          toggleSelected={toggleSelected}
                          setHighlightedId={setHighlightedId}
                          showForm={selectedId === withdraw.withdrawalId}
                          review={
                            reviews.byId[withdraw.reviewItemId] &&
                            reviews.byId[withdraw.reviewItemId].data
                          }
                          isHighlighted={
                            highlightedId === withdraw.withdrawalId
                          }
                          toggleAddNotesForm={toggleAddNotesForm}
                          addNoteFormShown={addNoteFormShown}
                          expandRawData={expandRawData}
                          showRawData={showRawData}
                        />
                      )
                    })}
                </TableBody>
              </Table>
            )}
          </>
        }
      />
    </>
  )
}

const mapStateToProps = state => ({
  reviews: state.reviews,
  accounts: state.accounts.byId,
  currency: getSelectedBankAccount(state).currency,
})

export default connect(mapStateToProps)(SuspendedWithdrawals)
