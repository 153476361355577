import React, { Component, Fragment } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { DetailsBlockWithTitle } from 'components/atoms/Details/index'
import RawDataDualAuth from 'components/atoms/RawDataDualAuth/index'
import * as moment from 'moment'

class LogTableRow extends Component {
  constructor(props) {
    super(props)
    this.toggleView = this.toggleView.bind(this)
    this.state = {
      expanded: false,
    }
  }
  toggleView = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  shortenUrl = (url) => {
    var splitted = url.split('/')
    var strUrl = ''
    for (var i = 0; i < splitted.length; i++) {
      if (i > 2) {
        strUrl += '/' + splitted[i]
      }
    }
    return strUrl
  }

  render() {
    const { accountId, forwardedForIp, requestMethod, url, message: { textPayload } } = this.props.log
    const { reqUrl, cfCountryFor, deviceHash, mobileDeviceId } = this.props.log
    const { expanded } = this.state
    return (
      <Fragment>
        <TableRow onClick={this.toggleView} hover>
          <TableCell style={{ fontSize: '14px' }} width={'10%'}>
            {accountId}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'10%'}>
            {forwardedForIp || 'N/A'}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'10%'}>
            {cfCountryFor || 'N/A'}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'12%'}>
            {(mobileDeviceId && mobileDeviceId.match('=UNKNOWN') !== null ? deviceHash : mobileDeviceId) || (textPayload.match('V1API') && 'v1')}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'11%'}>
            {requestMethod || 'N/A'}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'30%'}>
            {this.shortenUrl(url || reqUrl)}
          </TableCell>
          <TableCell style={{ fontSize: '14px' }} width={'12%'}>
            {moment(this.props.log['@timestamp']).format(
              'YYYY-MMM-DD HH:mm:ss',
            )}
          </TableCell>
        </TableRow>
        {expanded && (
          <TableCell colSpan={7}>
            <DetailsBlockWithTitle title={'Raw Data'}>
              <RawDataDualAuth data={this.props.log} />
            </DetailsBlockWithTitle>
          </TableCell>
        )}
      </Fragment>
    )
  }
}

export default LogTableRow
