import { WelcomeLogo } from 'components/atoms/Generic'
import React from 'react'

import { StyledWelcome } from './styles'

const ValrAdminWelcome = ({ message }: { message: string }) => (
  <StyledWelcome>
    <h1>Welcome To</h1>
    <WelcomeLogo text="VALR" />
    <h3>{message}</h3>
  </StyledWelcome>
)

export default ValrAdminWelcome
