import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form';
import FormSearchQuery from 'forms/SearchQuery/index'
import InfiniteScroll from 'react-infinite-scroll-component'
import { fetchHistoryLogs, clearHistoryLogs } from 'redux/allRequest/actions'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import IconLoading from 'components/atoms/IconLoading'
import { ShowStatus } from 'components/atoms/Generic/index'
import Row from './row'

const formSelector = formValueSelector('historyLogs')

class AllRequestLogger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      skip: 0,
      limit: 100,
    }
  }

  mapData = logsList => {
    return logsList.map((log, i) => {
      return <Row key={i} logs={log} />
    })
  }

  fetchMore = async () => {
    const { skip, limit } = this.props.logsDetails
    if (this.props.logsDetails.hasMoreRecords !== false) {
      this.setState({ skip: skip + limit })
    }
    this.props.values.skip = this.state.skip
    this.props.values.limit = limit
    let newValues;
    if (this.props.values.from !== undefined || this.props.values.to !== undefined) {
      const from = this.props.values.from.realDate
      const to = this.props.values.to.realDate
      const id = this.props.values.id
      const skip = this.props.values.skip
      const limit = this.props.values.limit
      const user = this.props.values.user
      const email = this.props.values.email
      newValues = { id, from, to, skip, limit, user, email }
    } else {
      const id = this.props.values.id
      const skip = this.props.values.skip
      const limit = this.props.values.limit
      const user = this.props.values.user
      const email = this.props.values.email
      newValues = { id, skip, limit, user, email }
    }
    await this.props.fetchHistoryLogs(newValues)
  }

  componentWillUnmount() {
    this.props.clearHistoryLogs()
  }

  returnEndMessage = txList => {
    if (txList.data && txList.data.length === 0 && txList.status === 'done') {
      return <ShowStatus>No history logs found</ShowStatus>
    } if (txList.status !== 'loading') {
      return <ShowStatus>No more logs to show</ShowStatus>
    }
    if (txList.status === 'loading') {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait
          </ShowStatus>
        </TablePlaceholder>
      )
    }
  }

  render() {
    const { status, data } = this.props.logsDetails
    const { skip, limit } = this.state
    return (
      <div>
        <FormSearchQuery
          initialValues={{
            skip,
            limit,
          }}
          form={'historyLogs'}
        />
        {status !== 'pristine' &&
          <Table>
            <TableHeader >
              <TableCell width={'14%'}>Admin Email</TableCell>
              <TableCell width={'21%'}>Keycloak ID</TableCell>
              <TableCell width={'8%'}>Account ID</TableCell>
              <TableCell width={'8%'}>Method</TableCell>
              <TableCell width={'34%'}>Request Url</TableCell>
              <TableCell width={'15%'}>Date</TableCell>
            </TableHeader>
            <TableBody>
              <InfiniteScroll
                dataLength={data ? data.length : 0}
                next={this.fetchMore}
                useWindow={false}
                hasMore={this.props.logsDetails.hasMoreRecords}
                loader={
                  <TablePlaceholder>
                    <ShowStatus>
                      <IconLoading />
                      Loading...
                    </ShowStatus>
                  </TablePlaceholder>

                }
                endMessage={this.returnEndMessage(this.props.logsDetails)}
                height={700}
              >
                {this.mapData(data)}
              </InfiniteScroll>
            </TableBody>
          </Table>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  logsDetails: state.allRequests.logs,

  values: {
    user: formSelector(state, 'user'),
    email: formSelector(state, 'email'),
    from: formSelector(state, 'from'),
    to: formSelector(state, 'to'),
    id: formSelector(state, 'id'),
  },
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchHistoryLogs,
      clearHistoryLogs,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllRequestLogger)
