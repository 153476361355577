import React from 'react'

import { StyledTitle } from './Styles'

export const Title = ({
  type,
  text = 'Enter a heading',
  margin = '',
  color = '',
  ...rest
}) => {
  const renderHeading = () => {
    let title = ''
    switch (type) {
      case 'h2':
        title = <h1 style={{ margin, color }}>{text}</h1>
        break
      case 'h3':
        title = <h3 style={{ margin, color }}>{text}</h3>
        break
      case 'h4':
        title = <h4 style={{ margin, color }}>{text}</h4>
        break
      case 'h5':
        title = <h5 style={{ margin, color }}>{text}</h5>
        break
      case 'h6':
        title = <h6 style={{ margin, color }}>{text}</h6>
        break
      default:
        title = <h1 style={{ margin, color }}>{text}</h1>
        break
    }
    return title
  }
  return <StyledTitle {...rest}>{renderHeading()}</StyledTitle>
}
