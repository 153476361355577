import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  FormContainer,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { required, requiredDropdownValue } from 'forms/fieldValidators'
import { bindActionCreators } from 'redux'
import {
  getKeycloakGroupsAndRolesDataDFS,
  getKeycloakGroupsOptionsDataDFS,
} from 'helpers/utils'
import IconLoading from 'components/atoms/IconLoading'
import { renderDropDownField, renderInput } from '../fields'
import { submitAddRoleToGroup } from './submitAddRoleToGroup'
import AlertDialog from './confirmation'
import { fetchKeycloakAdminGroups } from '../../redux/keycloak-groups/actions'
import List from '@material-ui/core/List'
import { ListItem } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { checkRole, ROLES } from '../../helpers/roleBasedAccess'
import MuiButton from '../../components/atoms/Buttons/MuiButton'
import { Actions } from '../../components/atoms/Table/styles'

const AddKeycloakRoleForm = (props) => {
  const [formShown, setFormShown] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const toggleForm = () => setFormShown((prevState) => !prevState)

  const {
    submitting,
    pristine,
    valid,
    role,
    keycloakGroups,
    isFetchingKeycloakGroups,
  } = props

  useEffect(() => {
    props.fetchKeycloakAdminGroups()
  }, [])

  const keyCloakGroupOptions = getKeycloakGroupsOptionsDataDFS(
    keycloakGroups.data,
  )
  const keyCloakGroupsAndRoles = getKeycloakGroupsAndRolesDataDFS(
    keycloakGroups.data,
  )

  const hasRole = checkRole(ROLES.createKeycloakRole)

  return (
    <>
      <FormContainer>
        <FormTitle>Add Keycloak Role</FormTitle>
        <div style={{ height: 100 }}>
          <InputGroup
            style={{
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              marginTop: 20,
            }}
          >
            <Field
              name={'role'}
              component={renderInput}
              inputValue={role}
              label="Role"
              width="45%"
              validate={[required]}
            />
            <Field
              name={'group'}
              showNoneLabel={false}
              disabled={isFetchingKeycloakGroups}
              component={renderDropDownField}
              label={
                isFetchingKeycloakGroups ? <IconLoading /> : 'Keycloak Group'
              }
              width="45%"
              validate={[requiredDropdownValue]}
              options={keyCloakGroupOptions}
              onChange={(event, newSelectedGroupId) => {
                if (
                  newSelectedGroupId &&
                  newSelectedGroupId !== selectedGroup
                ) {
                  setSelectedGroup(
                    keyCloakGroupsAndRoles.find(
                      (group) => group.id === newSelectedGroupId,
                    ),
                  )
                } else {
                  setSelectedGroup({})
                }
              }}
            />
          </InputGroup>
        </div>
        <InputGroup justify={'flex-end'}>
          <AlertDialog
            open={formShown}
            handleClose={toggleForm}
            onSubmit={() =>
              props.submitAddRoleToGroup({
                role: props.formValues.role,
                group: keyCloakGroupOptions.find(
                  (g) => g.value === props.formValues.group,
                ),
              })
            }
            values={!valid || pristine || submitting}
            role={props.formValues?.role}
            group={selectedGroup?.name}
            alreadyExists={selectedGroup.roles?.includes(
              props.formValues?.role,
            )}
          />
          <Actions justify="flex-end">
            <MuiButton
              onClick={toggleForm}
              variant="contained"
              disabled={!hasRole || !props.dirty}
              showToolTip={!hasRole}
              tooltipTitle={
                !hasRole ? 'You are not authorized to perform this action' : ''
              }
            >
              Submit
            </MuiButton>
          </Actions>
        </InputGroup>
      </FormContainer>
      {selectedGroup?.name !== undefined && (
        <>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Roles for {selectedGroup.name}
          </Typography>
          <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
            <List dense={true} orientation="vertical" variant="scrollable">
              {selectedGroup.roles?.map((role, i) => {
                const selected = i % 2 === 0
                return (
                  <ListItem selected={selected}>
                    <ListItemText primary={role} />
                  </ListItem>
                )
              })}
            </List>
          </Paper>
        </>
      )}
    </>
  )
}

const ReduxForm = reduxForm({
  form: 'addKeycloakRole',
  initialValues: { role: '', group: '' },
})(AddKeycloakRoleForm)

const mapStateToProps = (state) => ({
  keycloakGroups: state.keycloakGroups,
  isFetchingKeycloakGroups: state.keycloakGroups.loading,
  formValues:
    state.form &&
    state.form.addKeycloakRole &&
    state.form.addKeycloakRole.values,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchKeycloakAdminGroups,
      submitAddRoleToGroup,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm)
