import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import { required } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { submitDualAuthRemoveIgnoredStatus } from 'redux/ignoredDeposits/actions'

import { renderInput } from '../fields'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle style={{ marginRight: '120px' }}>
        Remove Ignored Status
      </FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment'}
          name={'comment'}
          width={'100%'}
          validate={[required]}
          component={renderInput}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          main="true"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({ onSubmit: submitDualAuthRemoveIgnoredStatus })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
