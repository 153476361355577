import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchManualAttemptsById } from 'redux/manualKycById/actions'
import { DetailsBlockWithTitle } from 'components/atoms/Details'
import ExpandingItem from 'components/atoms/ExpandingItem'
import { ListStyle, EnlargedDocumentImage } from './styles'
import Modal from '@material-ui/core/Modal'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import ParachuteDocument from './ParachuteDocument'

function RenderParachute(props) {
  const [isOpen, setIsOpen] = useState('')
  const [expanded, setExpanded] = useState(false)
  const { status, data } = props.manualKycById

  const toggleView = () => {
    setExpanded(!expanded)
  }

  const handleShowDialog = url => {
    setIsOpen(url)
  }

  useEffect(() => {
    props.fetchManualAttemptsById(props.id)
  }, [])

  return (
    <Fragment>
      <DetailsBlockWithTitle
        title="Manual UPloaded Kyc documents"
        flow={'column'}
        marginBottom={'20px'}
      />
      <ExpandingItem
        title={`MANUAL UPLOADED KYC DOCUMENTS`}
        itemsAmount={status === 'done' ? data.length : 'loading'}
        expanded={expanded}
        onExpand={toggleView}
        opensMultiple
        setColor={true}
        expandedView={
          <div>
            {status === 'loading' && <LoadingBlock />}
            {status === 'done' && data.length === 0 && (
              <TablePlaceholder>
                <span>Account has no documents</span>
              </TablePlaceholder>
            )}
            {status === 'done' && (
              <ListStyle>
                {data.map((imageData, index) => {
                  const blob = new Blob([new Uint8Array(imageData.data.data)], {
                    type: `image/${imageData.fileExtension}`,
                  })

                  const url = window.URL.createObjectURL(blob)
                  return (
                    <Fragment key={index}>
                      <ParachuteDocument
                        type={imageData.type.split('_').join(' ')}
                        url={url}
                        identifier={imageData.identifier}
                        isOpen={isOpen}
                        handleShowDialog={handleShowDialog}
                      />
                      <>
                        <Modal
                          disablePortal
                          id="uniqueId"
                          open={isOpen === imageData.identifier}
                          onClose={() => handleShowDialog('')}
                        >
                          <EnlargedDocumentImage>
                            <img
                              className="image"
                              src={url}
                              onContextMenu={e =>
                                e.preventDefault()
                              }
                              draggable={false}
                              onClick={() => handleShowDialog('')}
                              alt="no image"
                            />
                          </EnlargedDocumentImage>
                        </Modal>
                      </>
                    </Fragment>
                  )
                })}
              </ListStyle>
            )}
          </div>
        }
      />
    </Fragment>
  )
}

const mapStateToProps = state => ({
  manualKycById: state.manualKycById,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchManualAttemptsById,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(RenderParachute)
