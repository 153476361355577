import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderTextArea } from 'forms/fields'
import { required, maxLength256 } from 'forms/fieldValidators'
import { submit } from './submit'
import { Flexbox } from 'components/layout/index'
import { Spacer } from 'components/atoms/Generic'

let Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props
  return <FormContainer onSubmit={handleSubmit(onSubmit)}>
    <FormTitle>Add Notes</FormTitle>
    <InputGroup justify={'flex-start'} direction={'row'}>
      <Flexbox marginRight={BASE * 2} width={'90%'} justify={'flex-start'} direction={'column'}>
        <Field label={'notes'} name={'notes'} component={renderTextArea} validate={[required, maxLength256]} rows={1} />
      </Flexbox>
      <Flexbox marginRight={BASE * 2} marginTop={BASE * 1.5} justify={'center'} direction={'row'}>
        <Button type="submit" disabled={submitting || invalid} style={{ marginLeft: '15px' }} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Spacer margin="0px 12px 0px 0px" />
        <Button type="button" onClick={onCancel} color='secondary'>
          Clear
        </Button>
      </Flexbox>
    </InputGroup>
  </FormContainer>
}

Form = reduxForm({
  form: 'CommentsForm',
  onSubmit: submit,
  onSubmitSuccess: (values, dispatch, props) => props.toggleForm(),
})(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
