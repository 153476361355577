import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  Button,
  FormContainer,
  FormTitle,
  InputGroup,
} from 'components/atoms/Form'
import { required, requiredDropdownValue } from 'forms/fieldValidators'
import { bindActionCreators } from 'redux'
import IconLoading from 'components/atoms/IconLoading'
import { renderInput, renderDropDownField } from '../fields'
import { submitAddKeycloakGroup } from './submitAddKeycloakGroup'
import AlertDialog from './confirmation'
import { fetchKeycloakAdminGroups } from '../../redux/keycloak-groups/actions'
import { getKeycloakGroupsOptionsDataDFS } from '../../helpers/utils'
import { checkRole, ROLES } from '../../helpers/roleBasedAccess'
import { Actions } from '../../components/atoms/Table/styles'
import MuiButton from '../../components/atoms/Buttons/MuiButton'

const AddKeycloakGroupForm = (props) => {
  const [formShown, setFormShown] = useState(false)
  const toggleForm = () => setFormShown((prevState) => !prevState)

  const {
    submitting,
    pristine,
    valid,
    group,
    keycloakGroups,
    isFetchingKeycloakGroups,
  } = props

  useEffect(() => {
    props.fetchKeycloakAdminGroups()
  }, [])

  const adminKeycloakGroups = getKeycloakGroupsOptionsDataDFS(
    keycloakGroups.data,
  )
  const hasRole = checkRole(ROLES.createKeycloakGroup)

  return (
    <FormContainer>
      <FormTitle>Add Keycloak Group</FormTitle>
      <div style={{ height: 100 }}>
        <InputGroup
          style={{
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            marginTop: 20,
          }}
        >
          <Field
            name={'group'}
            component={renderInput}
            inputValue={group}
            label="Group"
            width="45%"
            validate={[required]}
          />
          <Field
            name={'parent'}
            showNoneLabel={false}
            component={renderDropDownField}
            disabled={isFetchingKeycloakGroups}
            label={
              isFetchingKeycloakGroups ? (
                <IconLoading />
              ) : (
                'Keycloak Parent Group'
              )
            }
            width="45%"
            validate={[requiredDropdownValue]}
            options={adminKeycloakGroups}
          />
        </InputGroup>
      </div>

      <InputGroup justify-content={'flex-end'}>
        <AlertDialog
          open={formShown}
          handleClose={toggleForm}
          onSubmit={() => {
            props.submitAddKeycloakGroup({
              group: props.formValues.group,
              parentGroup: adminKeycloakGroups.find(
                (g) => g.value === props.formValues.parent,
              ),
            })
          }}
          group={props.formValues?.group}
          parentGroup={
            adminKeycloakGroups.find(
              (g) => g.value === props.formValues?.parent,
            )?.label
          }
          values={!valid || pristine || submitting}
        />
        <Actions justify="flex-end">
          <MuiButton
            onClick={toggleForm}
            variant="contained"
            disabled={!hasRole || !props.dirty}
            showToolTip={!hasRole}
            tooltipTitle={
              !hasRole ? 'You are not authorized to perform this action' : ''
            }
          >
            Submit
          </MuiButton>
        </Actions>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'addKeycloakRole',
  initialValues: { role: '', group: '' },
})(AddKeycloakGroupForm)

const mapStateToProps = (state) => ({
  keycloakGroups: state.keycloakGroups,
  isFetchingKeycloakGroups: state.keycloakGroups.loading,
  formValues:
    state.form &&
    state.form.addKeycloakRole &&
    state.form.addKeycloakRole.values,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchKeycloakAdminGroups,
      submitAddKeycloakGroup,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ReduxForm)
