import { CLEAR_COMPETITION_LIST, MODIFIED_LIST } from './actions'

const initialState = {
  status: 'pristine',
  updatedList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MODIFIED_LIST: {
      const { data } = action.payload
      const modifiedData = [...state.updatedList]
      const existingListIndex = modifiedData.findIndex(ot => ot.id === data.id)
      if (existingListIndex > -1) {
        modifiedData.splice(existingListIndex, 1)
      } else {
        modifiedData.push(data)
      }

      return {
        ...state,
        status: 'done',
        updatedList: modifiedData,
      }
    }

    case CLEAR_COMPETITION_LIST: {
      return {
        ...state,
        updatedList: [],
      }
    }

    default:
      return state
  }
}
