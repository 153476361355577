import { makeSearchString } from 'helpers/utils'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast/index'

export const FETCH_ACCOUNT_DEPOSITS_PROCESS = 'account/FETCH_ACCOUNT_DEPOSITS_PROCESS'
export const FETCH_ACCOUNT_DEPOSITS_SUCCESS = 'account/FETCH_ACCOUNT_DEPOSITS_SUCCESS'
export const FETCH_ACCOUNT_DEPOSITS_ERROR = 'account/FETCH_ACCOUNT_DEPOSITS_ERROR'

export const CLEAR_ACCOUNT_DEPOSITS = 'CLEAR_ACCOUNT_DEPOSITS'

const SEARCH_FIELDS = [
  'status',
  'amount',
  'depositId',
  'description',
  'source',
  'dateTime',
]

const mapStatuses = status => {
  return {
    ...status,
    status: status.statuses
      .sort((a, b) => new Date(a.insertedAt) < new Date(b.insertedAt))
      .map(s => `${s.status}`)
      .join(', '),
  }
}

export const fetchAccountDeposits = ({ id, skip, limit, fetchMore }) => async dispatch => {
  dispatch({
    type: FETCH_ACCOUNT_DEPOSITS_PROCESS,
    payload: {
      id,
    },
  })

  try {
    const res = await getAxios().get(`/fiat/deposithistory/${id}/${skip}/${limit}`)
    const { data } = res


    const refinedData = data.map(mapStatuses)
    dispatch({
      type: FETCH_ACCOUNT_DEPOSITS_SUCCESS,
      payload: {
        fetchMore,
        id,
        data: refinedData.map(tx => {
          tx.meta = {
            ...tx.meta,
            searchString: makeSearchString(tx, SEARCH_FIELDS),
            timestampValue: new Date(tx.dateTime).getTime(),
          }
          tx.statuses = tx.statuses.sort((a, b) => { return new Date(b.insertedAt) - new Date(a.insertedAt) })
          return tx
        }),
        skip,
        limit,
        hasMoreRecords: !(data.length === 0),
      },
    })
  } catch (error) {
    ErrorToast(error)
    let mappedError = {}
    if (error.response) {
      mappedError = {
        statusCode: error.response.data.statusCode,
        message: error.response.data.message,
        errorCode: error.response.data.code,
      }
    } else {
      mappedError = {
        statusCode: -1,
        message: error.message,
        errorCode: -1
      }
    }
    dispatch({
      type: FETCH_ACCOUNT_DEPOSITS_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}

export const clearAccountDepositsList = () => dispatch => {
  dispatch({
    type: CLEAR_ACCOUNT_DEPOSITS,
  })
}
