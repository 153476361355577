import getAxios from 'helpers/axios'

export const FETCH_LOGS_PROCESS = 'account/FETCH_LOGS_PROCESS'
export const FETCH_LOGS_SUCCESS = 'account/FETCH_LOGS_SUCCESS'
export const FETCH_LOGS_ERROR = 'account/FETCH_LOGS_ERROR'

export const fetchAccountLogs = values => async dispatch => {
  try {
    dispatch({
      type: FETCH_LOGS_PROCESS,
    })
    const res = await getAxios().post(`/account/logs`, values)
    const { data } = res
    dispatch({
      type: FETCH_LOGS_SUCCESS,
      payload: { data },
    })
    return data
  } catch (error) {
    dispatch({
      type: FETCH_LOGS_ERROR,
      payload: { error },
    })
  }
}
