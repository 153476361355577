import React from 'react'
import styled from 'styled-components'
import { pure } from 'recompose'
import { PANEL_BLUE, WHITE } from 'theme/colors'
import { BASE } from 'theme/base-units'

export const Sidebar = styled.div`
  background: ${({ theme }) => theme.sidebarNavigation.background};
  color: ${WHITE};
  flex: 0.35;
  max-width: ${32 * BASE}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: auto;
`

export const MainPart = styled.div`
  width: 100%;
`
export const Settings = styled.div`
  width: 100%;
`

export const SidebarItem = styled.div`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: ${BASE * 2}px;
  font-weight: bold;
  font-size: ${BASE * 2}px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  & i {
    margin-right: ${BASE}px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }
`

export const LogoutMenuItem = pure(({ label, onClick }) => (
  <SidebarItem onClick={onClick}>
    <i className={'fas fa-sign-out-alt'} />
    {label}
  </SidebarItem>
))
