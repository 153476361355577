import React, { Fragment, Component } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasIdentityNumberChangeRole } from 'helpers/roleBasedAccess'

import InitiateIdentityChangeForm from 'forms/InitiateIdentityNumberChange'
import auth from 'services/Auth'

class InitiateIdentityChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
    }
  }

  toggleForm = () => {
    this.setState({
      formShown: !this.state.formShown,
    })
  }

  render() {
    const { formShown } = this.state
    const { id, identityNumber } = this.props
    const notAuthorized = !hasIdentityNumberChangeRole()
    return (
      <Fragment>
        <Fragment>
          {!formShown && (
            <MuiButton
              onClick={this.toggleForm}
              disabled={notAuthorized}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
            >
              {identityNumber
                ? 'Change Identity Number'
                : 'Add Identity Number'}
            </MuiButton>
          )}
        </Fragment>
        {formShown && (
          <InitiateIdentityChangeForm
            initialValues={{
              accountId: id,
              type: 'IDENTITY_NUMBER_CHANGE',
              newIdentityNumber: '',
              reason: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
            }}
            form={'ChangeIdentityNumber'}
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Fragment>
    )
  }
}
export default InitiateIdentityChange
