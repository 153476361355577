import { DetailsBlockWithTitle, InfoBlock } from 'components/atoms/Details'
import { ContentContainer, Spacer } from 'components/atoms/Generic'
import { FlexRow, Flexbox } from 'components/layout'
import React, { FC, useEffect, useState } from 'react'
import { CorporateRiskProfileForm } from './CorporateRiskProfileForm'
import { camelCaseToText } from 'helpers/utils'
import { Title } from 'components/atoms/Title'
import CorporateRiskProfileEntity from './CorporateRiskProfileEntity'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  AccountCorporate,
  CorporateNaturalEntity,
  CorporateRiskProfile,
} from '../../../interfaces/riskProfile'
import { CorporateScoringOptions } from 'interfaces/riskProfile'
import ErrorToast from 'components/molecules/ErrorToast'

const trimToLowerCaseAndRemoveSpaces = (str: string | string[]) => {
  let stringValue = ''
  if (Array.isArray(str)) {
    stringValue = str.join(',')
  } else if (typeof stringValue === 'string') {
    stringValue = str
    if (!stringValue) return ''
    stringValue = stringValue.replace(/\s/g, '').toLowerCase()
  }
  return stringValue
}

interface ICorporateRiskProfilePage {
  isFetchingCorporateForAccount: boolean
  accountCorporate: AccountCorporate
  corporateRiskProfile: CorporateRiskProfile
  accountCorporateNaturalEntities: CorporateNaturalEntity[]
  corporateScoringOptions: CorporateScoringOptions
  accountId: number
  isCorporateAccount: boolean
  isFetchingRiskScoringOptions: boolean
  history: any
  createRiskProfile: (values: any, dispatch: any) => Promise<void>
  updateRiskProfile: (values: any, dispatch: any) => Promise<void>
  updateSearchedRiskProfileState: (accountId: number) => Promise<void>
}

const CorporateRiskProfilePage: FC<ICorporateRiskProfilePage> = ({
  isFetchingCorporateForAccount,
  accountCorporate,
  corporateRiskProfile,
  createRiskProfile,
  updateRiskProfile,
  accountCorporateNaturalEntities,
  corporateScoringOptions,
  accountId,
  isCorporateAccount,
  isFetchingRiskScoringOptions,
  history,
  updateSearchedRiskProfileState,
}) => {
  const [showCorporateRiskProfileForm, setShowCorporateRiskProfileForm] =
    useState(false)
  const [valuesNotMatching, setValuesNotMatching] = useState<string[]>([])
  const [hasDiff, setHasDiff] = useState<Boolean>(false)
  const toggleCorporateRiskProfileForm = () =>
    setShowCorporateRiskProfileForm((prev) => !prev)
  const { riskScore: corporateRiskScore, ...restOfCorporateRiskProfile } =
    corporateRiskProfile
  const corporateRiskProfileInitialValues = {
    ...restOfCorporateRiskProfile,
    name: accountCorporate.name || restOfCorporateRiskProfile.name,
    accountCorporateNaturalEntities,
    corporateId:
      accountCorporate.corporateId || restOfCorporateRiskProfile.corporateId,
    countryOfRegistration:
      accountCorporate.countryOfRegistration ||
      restOfCorporateRiskProfile.countryOfRegistration,
    purposeOfAccount:
      corporateScoringOptions.purposeOfAccount.find(
        (poa) =>
          accountCorporate.purposeOfAccount &&
          trimToLowerCaseAndRemoveSpaces(poa.value).includes(
            trimToLowerCaseAndRemoveSpaces(accountCorporate.purposeOfAccount),
          ),
      )?.value || restOfCorporateRiskProfile.purposeOfAccount,
    natureOfBusiness:
      corporateScoringOptions.natureOfBusiness.find(
        (nb) =>
          accountCorporate.natureOfBusiness &&
          trimToLowerCaseAndRemoveSpaces(nb.value).includes(
            trimToLowerCaseAndRemoveSpaces(accountCorporate.natureOfBusiness),
          ),
      )?.value || restOfCorporateRiskProfile.natureOfBusiness,
    ipLogs: restOfCorporateRiskProfile.ipLogs,
    suspiciousTransactions: restOfCorporateRiskProfile.suspiciousTransactions,
    accountId,
    isCorporateAccount,
    sourceOfWealth:
      corporateScoringOptions.sourceOfWealth.find(
        (sw) =>
          accountCorporate.sourceOfWealth &&
          trimToLowerCaseAndRemoveSpaces(sw.value).includes(
            trimToLowerCaseAndRemoveSpaces(accountCorporate.sourceOfWealth),
          ),
      )?.value || restOfCorporateRiskProfile.sourceOfWealth,
    sourceOfFunds:
      corporateScoringOptions.sourceOfFunds.find(
        (sf) =>
          accountCorporate.sourceOfFunds &&
          trimToLowerCaseAndRemoveSpaces(sf.value).includes(
            trimToLowerCaseAndRemoveSpaces(accountCorporate.sourceOfFunds),
          ),
      )?.value || restOfCorporateRiskProfile.sourceOfFunds,
    productSelection: accountCorporate.productSelection || [],
  }

  const diffCheckCorpRiskProfileAgainstAccountProfile = (
    riskProfileObject: { [key: string]: any },
    accountCorporateObject: { [key: string]: any },
  ) => {
    try {
      let foundDiff = false
      if (!corporateRiskProfile.id) return
      setHasDiff(false)
      const mainObjKeys = Object.keys(riskProfileObject)
      const comparatorObjKeys = Object.keys(accountCorporateObject)
      const keys = mainObjKeys.filter(
        (mk) => comparatorObjKeys.findIndex((ck) => ck === mk) > -1,
      )
      keys.forEach((key) => {
        try {
          const riskProfileValue = riskProfileObject[key]
          const accountCorporateValue = accountCorporateObject[key]
          const hasOnValueAsArray =
            Array.isArray(riskProfileValue) ||
            Array.isArray(accountCorporateValue)
          let isSame = false
          if (
            Array.isArray(riskProfileValue) &&
            Array.isArray(accountCorporateValue)
          ) {
            let valuesMatching = true
            riskProfileValue.forEach((value) => {
              if (!accountCorporateValue.includes(value)) {
                valuesMatching = false
              }
            })
            accountCorporateValue.forEach((value) => {
              if (!riskProfileValue.includes(value)) {
                valuesMatching = false
              }
            })
            if (valuesMatching) {
              isSame = true
            }
          } else if (hasOnValueAsArray) {
            if (riskProfileValue && !accountCorporateValue) {
              isSame = false
            }
            if (!riskProfileValue && accountCorporateValue) {
              isSame = false
            }
            if (riskProfileValue && accountCorporateValue) {
              if (
                Array.isArray(riskProfileValue) &&
                typeof accountCorporateValue === 'string'
              ) {
                riskProfileValue.forEach((value) => {
                  if (
                    trimToLowerCaseAndRemoveSpaces(
                      accountCorporateValue,
                    ).includes(trimToLowerCaseAndRemoveSpaces(value))
                  ) {
                    isSame = true
                  } else {
                    isSame = false
                  }
                })
              } else if (
                Array.isArray(accountCorporateValue) &&
                typeof riskProfileValue === 'string'
              ) {
                accountCorporateValue.forEach((value) => {
                  if (
                    trimToLowerCaseAndRemoveSpaces(riskProfileValue).includes(
                      trimToLowerCaseAndRemoveSpaces(value),
                    )
                  ) {
                    isSame = true
                  } else {
                    isSame = false
                  }
                })
              }
            }
          } else {
            const mainObjKeyToLowerCase = riskProfileValue
              ? riskProfileValue.toLowerCase().replaceAll(' ', '')
              : ''
            const comparatorObjKeyToLowerCase = accountCorporateValue
              ? accountCorporateValue.toLowerCase().replaceAll(' ', '')
              : ''

            if (
              mainObjKeyToLowerCase.includes('other') &&
              comparatorObjKeyToLowerCase.includes('other')
            ) {
              isSame = true
            } else {
              isSame = mainObjKeyToLowerCase === comparatorObjKeyToLowerCase
            }
          }

          if (isSame) return
          foundDiff = true
          setValuesNotMatching((prev) => {
            const updatedValues: string[] = [...prev]
            const alreadyListed =
              updatedValues.findIndex((v) => v.includes(key)) > -1
            if (alreadyListed) return updatedValues
            const emptyValueToAdd = `Corporate Risk profile value ${key}: ${`"${riskProfileValue}"`} does not match account value: "${
              accountCorporateValue || 'empty'
            }". Please update ${key} to ${accountCorporateValue || 'none'}`
            updatedValues.push(emptyValueToAdd)
            return updatedValues
          })
        } catch (error) {
          throw error
        }
      })
      if (!foundDiff) {
        setValuesNotMatching([])
      }
      setHasDiff(foundDiff)
    } catch (error) {
      console.log(error)
      ErrorToast(
        'Error occurred when checking difference between corporates risk profile and the account corporate information.',
      )
    }
  }

  const {
    id: _,
    corporateId,
    ...corporateRiskProfileDataToCheckDiff
  } = corporateRiskProfile

  const riskProfileExists = corporateRiskProfile.id
  useEffect(() => {
    diffCheckCorpRiskProfileAgainstAccountProfile(
      corporateRiskProfileDataToCheckDiff,
      accountCorporate,
    )

    return () => {}
  }, [corporateRiskProfile, accountCorporate, accountCorporateNaturalEntities])
  return !isFetchingCorporateForAccount ? (
    <ContentContainer style={{ width: '100%' }}>
      {hasDiff && riskProfileExists ? (
        <>
          <Title
            color="red"
            type="h4"
            text="Corporate risk profile is outdated please update!"
          />
          {valuesNotMatching.map((v, i) => (
            <p key={i}>{v}</p>
          ))}
        </>
      ) : null}
      <DetailsBlockWithTitle title="Corporate Risk Profile">
        <Flexbox width="100%" align="flex-start" direction="column">
          {showCorporateRiskProfileForm && accountCorporate ? (
            <CorporateRiskProfileForm
              exists={!!corporateRiskProfile.id}
              onSubmit={
                !corporateRiskProfile.id ? createRiskProfile : updateRiskProfile
              }
              initialValues={corporateRiskProfileInitialValues}
              isFetchingOptions={isFetchingRiskScoringOptions}
              riskScoringFormOptions={corporateScoringOptions}
              onCancel={toggleCorporateRiskProfileForm}
              hasDiff={hasDiff}
            />
          ) : (
            <FlexRow>
              {Object.keys(corporateRiskProfile).map((key) => (
                <div style={{ width: '25%', marginBottom: 8 }} key={key}>
                  <InfoBlock
                    infoLabelMarginBottom={8}
                    label={camelCaseToText(key)}
                    value={corporateRiskProfile[key] || '--'}
                  />
                </div>
              ))}
            </FlexRow>
          )}
          <Title type="h4" text="Account Entities" />
          {accountCorporateNaturalEntities.length > 0 ? (
            <Flexbox marginBottom={12} align="flex-start" direction="row">
              {accountCorporateNaturalEntities.map((entity) => (
                <CorporateRiskProfileEntity key={entity.id} entity={entity} />
              ))}
            </Flexbox>
          ) : (
            <Title type="h4" text="No entities for this corporate." />
          )}
          <FlexRow>
            <Spacer margin="0px 12px 0px 0px">
              {!showCorporateRiskProfileForm && (
                <MuiButton onClick={toggleCorporateRiskProfileForm}>
                  {corporateRiskProfile.id ? 'update' : 'create'} risk profile
                </MuiButton>
              )}
            </Spacer>
            <Spacer margin="0px 12px 0px 0px">
              <MuiButton
                onClick={() =>
                  history.push(
                    `/accounts/byId/${accountId}/account-compliance/risk-profile/corporate/edd-report`,
                  )
                }
              >
                go to corporates edd report
              </MuiButton>
            </Spacer>
            <Spacer margin="0px 12px 0px 0px">
              <MuiButton
                onClick={async () => {
                  await updateSearchedRiskProfileState(accountId)
                  history.push(
                    '/compliance/risk-profiles/management/risk-scoring/corporate',
                  )
                }}
              >
                go to corporate risk profile management
              </MuiButton>
            </Spacer>
          </FlexRow>
        </Flexbox>
      </DetailsBlockWithTitle>
    </ContentContainer>
  ) : (
    <LoadingBlock message="Fetching account corporate information" />
  )
}

export default CorporateRiskProfilePage
