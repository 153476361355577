import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TablePlaceholder, Title } from 'components/atoms/Table/styles'
import Select from 'react-select'
import { fetchCurrenciesEnabledBorrow } from 'redux/loansBook/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import CustomTable from 'components/atoms/CustomTable/index'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { Spacer } from 'components/atoms/Generic/index'

const ViewLoansForCurrency = ({ isLoading, fetchCurrencies, currencyList }) => {
  const [
    selectedCurrencyLoanBookData,
    setSelectedCurrencyLoanBookData,
  ] = useState([])
  const [
    isFetchingCurrencyLoansBookData,
    setIsFetchingCurrencyLoansBookData,
  ] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState('')

  const handleChange = async ({ value }) => {
    setIsFetchingCurrencyLoansBookData(true)
    try {
      setSelectedCurrency(value)
      const { data } = await getAxios().get(
        `/admin/currency/loans/book/${value}/full`,
      )
      setSelectedCurrencyLoanBookData(data)
    } catch (error) {
      setSelectedCurrency('')
      toast('An error occurred fetching loans book for the selected currency', {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
    setIsFetchingCurrencyLoansBookData(false)
  }

  useEffect(
    () => () => {
      setSelectedCurrencyLoanBookData([])
    },
    [],
  )

  return (
    <Fragment>
      <Title>Loans Book Full</Title>
      <Spacer margin="16px" />
      <Select
        onFocus={fetchCurrencies}
        options={
          isLoading
            ? [
                {
                  label: <LoadingBlock message="Fetching Currencies" />,
                  value: '',
                },
              ]
            : currencyList || []
        }
        placeholder={'Select currency loans book'}
        onChange={handleChange}
      />
      <Spacer margin="16px 0px">
        {selectedCurrency && <Title>Loans book for {selectedCurrency}</Title>}
      </Spacer>
      {isFetchingCurrencyLoansBookData && (
        <LoadingBlock message={`Fetching loans book for ${selectedCurrency}`} />
      )}
      {!isFetchingCurrencyLoansBookData &&
      selectedCurrencyLoanBookData.length > 0 ? (
        <CustomTable
          headings={[
            'accountId',
            'loanId',
            'currencySymbol',
            'hourlyRate',
            'totalAmount',
            'usedAmount',
            'createdAt',
          ]}
          rows={selectedCurrencyLoanBookData}
        />
      ) : (
        <>
          {selectedCurrency &&
            !isFetchingCurrencyLoansBookData && (
              <TablePlaceholder>
                No loans book data for {selectedCurrency}
              </TablePlaceholder>
            )}
        </>
      )}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  isLoading: state.loansBook.isLoading,
  currencyList: state.loansBook.currencyList,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCurrencies: fetchCurrenciesEnabledBorrow,
    },
    dispatch,
  )

ViewLoansForCurrency.propTypes = {
  isLoading: PropTypes.bool,
  fetchCurrencies: PropTypes.func,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewLoansForCurrency)
