import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { connect } from 'react-redux'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import DynamicApplication from './DynamicApplication'
import DynamicRules from './DynamicRules'
import DynamicCurrency from './DynamicCurrency'

import LoadingBlock from '../../components/molecules/LoadingBlock'

const routes = ['/dynamic/application', '/dynamic/rules', '/dynamic/currency']

const DynamicApplicationConfig = (props) => {
  const {
    location: { pathname },
    loading,
  } = props
  const [currentPage, setCurrentPage] = useState(routes[0])

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
  }, [pathname])

  if (loading) return <LoadingBlock />

  return (
    <>
      <h1>Configuration</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="Application"
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label="Rules"
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
        <NavigationTab
          value={routes[2]}
          label="Currency"
          component={ForwardedNavLink}
          to={routes[2]}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={DynamicApplication} />
          <Route path={routes[1]} component={DynamicRules} />
          <Route path={routes[2]} component={DynamicCurrency} />
        </Switch>
      </ContentContainer>
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: state.fiat.config.loading,
})

export default connect(mapStateToProps, null)(DynamicApplicationConfig)
