import { TablePlaceholder } from 'components/atoms/Table/styles'
import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { fetchKeycloakAdminGroupsWithSubGroups } from 'redux/keycloak-groups/actions'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import { KeycloakGroup } from '../interfaces'
import KeycloakAdminGroup from './KeycloakAdminGroup'
import CustomAccordion from 'components/organisms/Accordion'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import {
  StyledAccordionAction,
  StyledKeycloakAccordionButtonContainer,
  StyledUserRole,
  StyledUserRolesContainer,
} from '../Styles'
import getAxios from 'helpers/axios'
import ErrorToast from 'components/molecules/ErrorToast'
import useKeycloakAdminGroups from 'Hooks/Keycloak/useKeycloakAdminGroups'
import LoadingBlock from 'components/molecules/LoadingBlock'
import Subgroups from './Subgroups'
import SimpleModal from 'components/organisms/Modal'
import { JsonBlock } from 'components/atoms/Details'

interface IAdminGroupsWithSubGroupsProps {
  adminKeycloakGroups: {
    loading: boolean
    data: KeycloakGroup
  }
  fetchKeycloakAdminGroupsWithSubGroups: () => Promise<void>
}

const KeycloakAdminGroupsWithSubGroups: FC<IAdminGroupsWithSubGroupsProps> = (
  props,
) => {
  const {
    handleCloseMembersGroupModal,
    handleFetchMembersForGroup,
    handleFetchUserRoles,
    membersInGroupModalState,
  } = useKeycloakAdminGroups(props.fetchKeycloakAdminGroupsWithSubGroups)
  if (props.adminKeycloakGroups.loading) return <LoadingBlock margin="12px" />
  if (!props.adminKeycloakGroups.data)
    return <TablePlaceholder>No admin Keycloak groups</TablePlaceholder>

  return (
    <div>
      <h1>Keycloak Groups</h1>
      <CustomAccordion
        title={props.adminKeycloakGroups.data.name}
        extraHeader={
          <MuiButton
            disabled={
              membersInGroupModalState.fetchingMembersForGroupName ===
              props.adminKeycloakGroups.data.name
            }
            onClick={(e) => {
              e.stopPropagation()
              handleFetchMembersForGroup(props.adminKeycloakGroups.data)
            }}
          >
            View Users
          </MuiButton>
        }
      >
        <Subgroups
          fetchingMembersForGroupName={
            membersInGroupModalState.fetchingMembersForGroupName
          }
          handleFetchMembersForGroup={handleFetchMembersForGroup}
          subgroups={props.adminKeycloakGroups.data.subGroups}
        />
      </CustomAccordion>
      <SimpleModal
        buttonsHidden
        handleClose={() => handleCloseMembersGroupModal()}
        width="50%"
        open={membersInGroupModalState.isOpen}
        title={
          membersInGroupModalState.fetchingMembersForGroupName
            ? ''
            : `${membersInGroupModalState.groupName} Group`
        }
      >
        {membersInGroupModalState.fetchingMembersForGroupName ? (
          <LoadingBlock />
        ) : (
          <div>
            {membersInGroupModalState.members
              .filter((m) => m.email)
              .map((m) => (
                <CustomAccordion
                  key={m.id}
                  onClick={
                    m.roles ? () => {} : () => handleFetchUserRoles(m.id)
                  }
                  title={m.email}
                >
                  {membersInGroupModalState.isFetchingUserRoles === m.id ? (
                    <LoadingBlock message="Fetching user roles..." />
                  ) : (
                    <StyledUserRolesContainer>
                      {m.roles &&
                        m.roles.map((role: string) => (
                          <StyledUserRole key={role}>{role}</StyledUserRole>
                        ))}
                    </StyledUserRolesContainer>
                  )}
                </CustomAccordion>
              ))}
          </div>
        )}
        <StyledKeycloakAccordionButtonContainer>
          <MuiButton onClick={handleCloseMembersGroupModal}>Close</MuiButton>
        </StyledKeycloakAccordionButtonContainer>
      </SimpleModal>
    </div>
  )
}

const actions = {
  fetchKeycloakAdminGroupsWithSubGroups,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    adminKeycloakGroups: state.keycloakGroups.adminKeycloakGroups,
  })),
  actions,
  KeycloakAdminGroupsWithSubGroups,
)
