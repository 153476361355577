import ErrorToast from 'components/molecules/ErrorToast/index'
import { ComplianceData } from 'containers/Accounts/SingleAccount/Comments/interfeces'
import { Reducer } from 'redux'

import {
  ActionTypes,
} from './actions'

type loadingStates = 'pristine' | 'loading' | 'done' | 'error'

interface ComplianceInitialState {
    status: loadingStates
    data: ComplianceData[]
}

interface Action {
  type:ActionTypes
  payload?: any
  }

const initialState: ComplianceInitialState = {
  status: 'pristine',
  data: [],
}

const reducer: Reducer<ComplianceInitialState, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FETCH_COMPLIANCE_COMMENTS_PROCESS': {
      return {
        ...state,
        status: 'loading',
        data: [],
      }
    }

    case 'FETCH_COMPLIANCE_COMMENTS_SUCCESS': {
      const { comments } = action.payload

      return {
        ...state,
        status: 'done',
        data: comments,
      }
    }

    case 'UPDATE_COMPLIANCE_COMMENTS_SUCCESS': {
      const { id, flag } = action.payload
      const updatedData = [...state.data]
      const indexOfCommentFlagStatusToUpdate = updatedData.findIndex(
        commentFlagStatusToUpdate => commentFlagStatusToUpdate.commentId === id,
      )
      if (indexOfCommentFlagStatusToUpdate === -1) {
        ErrorToast(
          'Update was successfull but an error occurred when updating ui please refresh screen to see updates',
        )
      }
      updatedData[indexOfCommentFlagStatusToUpdate].flag = flag
      return {
        ...state,
        data: updatedData,
      }
    }

    case 'FETCH_COMPLIANCE_COMMENTS_ERROR': {
      return {
        ...state,
        status: 'pristine',
      }
    }
    default:
      return state
  }
}

export default reducer
