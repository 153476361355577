import { createSelector } from 'reselect'

import { getActiveAccount } from './ui'

export const getKYCLevels = state => state.kyc.level
export const getAccountKYC = createSelector(
  [getKYCLevels, getActiveAccount],
  (kyc, id) => {
    if (!id || !kyc[id]) {
      return {
        status: 'pristine',
        info: {
          description: '',
        },
      }
    }

    return kyc[id]
  },
)

export const getKYCInfo = state => state.kyc.info
export const getAccountKYCInfo = createSelector(
  [getKYCInfo, getActiveAccount],
  (kyc, id) => {
    if (!id || !kyc[id]) {
      return {
        status: 'pristine',
        info: {
          description: '',
        },
      }
    }

    return kyc[id]
  },
)

export const getKYCPendingList = state => state.kyc.pending
