import {
  FETCH_CUSTOMER_TYPE_PROCESS,
  FETCH_CUSTOMER_TYPE_SUCCESS,
  FETCH_CUSTOMER_TYPE_ERROR,
  FETCH_CUSTOMER_TYPE_CHANGE_PROCESS,
  FETCH_CUSTOMER_TYPE_CHANGE_SUCCESS,
  FETCH_CUSTOMER_TYPE_CHANGE_ERROR,
  UPDATE_CUSTOMER_TYPE_CHANGE_SUCCESS,
  UPDATE_CUSTOMER_TYPE_CHANGE_ERROR,
  CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_SUCCESS,
  CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_ERROR,
  UPDATE_CUSTOMER_TYPE_CHANGE_PENDING,
  REMOVE_CUSTOMER_TYPE_CHANGE_DUAL_AUTH,
  UPDATE_KEY_RELIANCE_AGREEMENT_STATUS,
} from './actions'

const initialState = {
  byId: {},
  customerTypeData: [],
  customerTypeStatus: 'pristine',
  error: '',
  customerTypeChangeLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TYPE_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'loading',
            data: [],
          },
        },
      }
    }

    case FETCH_CUSTOMER_TYPE_ERROR: {
      const { id, error } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'error',
            data: [],
            error,
          },
        },
      }
    }

    case FETCH_CUSTOMER_TYPE_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data,
            error: null,
          },
        },
      }
    }
    // maybe rename??
    case FETCH_CUSTOMER_TYPE_CHANGE_PROCESS: {
      return {
        ...state,
        customerTypeStatus: 'loading',
        customerTypeData: [],
        error: '',
        customerTypeChangeLoading: true,
      }
    }
    case FETCH_CUSTOMER_TYPE_CHANGE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        customerTypeStatus: 'done',
        customerTypeData: data,
        error: '',
        customerTypeChangeLoading: false,
      }
    }

    case FETCH_CUSTOMER_TYPE_CHANGE_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        customerTypeChangeLoading: false,
      }
    }

    case CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.customerTypeData &&
        state.customerTypeData.map((dualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (newUserOn.id === dualAuth.id && newUserOn.userActive) {
                dualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === dualAuth.id && userOnLine.userActive,
                  ).userName,
                }
              } else {
                dualAuth.active = { userActive: false, activePersonName: '' }
              }
            })
          } else {
            dualAuth.active = { userActive: false, activePersonName: '' }
          }
          return dualAuth
        })

      return {
        ...state,
        customerTypeData: updatedDualData,
        customerTypeDataStatus: 'done',
      }
    }

    case CUSTOMER_TYPE_CHANGE_DUAL_AUTH_ROW_USER_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        error,
        customerTypeDataStatus: 'pristine',
      }
    }

    case UPDATE_CUSTOMER_TYPE_CHANGE_PENDING: {
      return {
        ...state,
        customerTypeStatus: 'loading',
      }
    }

    case UPDATE_CUSTOMER_TYPE_CHANGE_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: {
            status: 'done',
            data,
            error: null,
          },
        },
      }
    }

    case UPDATE_CUSTOMER_TYPE_CHANGE_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        customerTypeStatus: 'done',
        error,
      }
    }
    case REMOVE_CUSTOMER_TYPE_CHANGE_DUAL_AUTH: {
      const updatedData = state.customerTypeData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        customerTypeData: updatedData,
      }
    }

    case UPDATE_KEY_RELIANCE_AGREEMENT_STATUS: {
      const { accountId, status } = action.payload
      const updatedData = {
        ...state.byId[accountId],
        data: {
          ...state.byId[accountId].data,
          keyRelianceAgreementStatus: status,
        },
      }

      return {
        ...state,
        byId: {
          [accountId]: updatedData,
        },
      }
    }

    default:
      return state
  }
}
