import ErrorToast from 'components/molecules/ErrorToast'
import getAxios from 'helpers/axios'

export const FETCH_ACCOUNT_KYC_LEVEL_PROCESS =
  'account/FETCH_ACCOUNT_KYC_LEVEL_PROCESS'
export const FETCH_ACCOUNT_KYC_LEVEL_SUCCESS =
  'account/FETCH_ACCOUNT_KYC_LEVEL_SUCCESS'
export const FETCH_ACCOUNT_KYC_LEVEL_ERROR =
  'account/FETCH_ACCOUNT_KYC_LEVEL_ERROR'

export const FETCH_ACCOUNT_KYC_INFO_PROCESS =
  'account/FETCH_ACCOUNT_KYC_INFO_PROCESS'
export const FETCH_ACCOUNT_KYC_INFO_SUCCESS =
  'account/FETCH_ACCOUNT_KYC_INFO_SUCCESS'
export const FETCH_ACCOUNT_KYC_INFO_ERROR =
  'account/FETCH_ACCOUNT_KYC_INFO_ERROR'

export const FETCH_KYC_PENDING_LIST_PROCESS =
  'accounts/FETCH_KYC_PENDING_LIST_PROCESS'
export const FETCH_KYC_PENDING_LIST_SUCCESS =
  'accounts/FETCH_KYC_PENDING_LIST_SUCCESS'
export const FETCH_KYC_PENDING_LIST_ERROR =
  'accounts/FETCH_KYC_PENDING_LIST_ERROR'

export const UPDATE_KYC_LEVEL_SUCCESS = 'accounts/UPDATE_KYC_LEVEL_SUCCESS'

export const fetchKycInfo = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_KYC_INFO_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/kyc`)
    dispatch({
      type: FETCH_ACCOUNT_KYC_INFO_SUCCESS,
      payload: {
        id,
        ...res.data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message: error.response.data.error.message,
      errorCode: error.response.data.error.code,
    }
    dispatch({
      type: FETCH_ACCOUNT_KYC_INFO_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}

export const fetchKycLevel = (id) => async (dispatch) => {
  dispatch({
    type: FETCH_ACCOUNT_KYC_LEVEL_PROCESS,
    payload: {
      id,
    },
  })
  try {
    const res = await getAxios().get(`/account/${id}/kyclevel`)
    dispatch({
      type: FETCH_ACCOUNT_KYC_LEVEL_SUCCESS,
      payload: {
        id,
        ...res.data,
      },
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message:
        (error.response.data.error && error.response.data.error.message) ||
        error.response.data.message,
      errorCode:
        (error.response.data.error && error.response.data.error.code) ||
        error.response.data.code,
    }
    dispatch({
      type: FETCH_ACCOUNT_KYC_LEVEL_ERROR,
      payload: {
        id,
        error: mappedError,
      },
    })
  }
}

export const updateKycLevel = (newKycLevel) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_KYC_LEVEL_SUCCESS,
      payload: newKycLevel,
    })
  } catch (error) {
    ErrorToast(error)
  }
}

export const fetchKYCPendingList = () => async (dispatch) => {
  dispatch({
    type: FETCH_KYC_PENDING_LIST_PROCESS,
    payload: {},
  })
  try {
    const res = await getAxios().get('/kyc/pending')
    const mappedData = res.data.map((account) => ({
      id: account.accountId,
      level: account.kycLevel,
      status: account.checkStatus,
      timestamp: account.checkCreatedAt,
      checkCount: account.applicantCheckCount,
      profile: account.profileInfo,
    }))
    dispatch({
      type: FETCH_KYC_PENDING_LIST_SUCCESS,
      payload: mappedData,
    })
  } catch (error) {
    const mappedError = {
      statusCode: error.response.data.statusCode,
      message:
        (error.response.data.error && error.response.data.error.message) ||
        error.response.data.message,
      errorCode:
        (error.response.data.error && error.response.data.error.code) ||
        error.response.data.code,
    }
    dispatch({
      type: FETCH_KYC_PENDING_LIST_ERROR,
      payload: {
        error: mappedError,
      },
    })
  }
}
