import React from 'react'

import { StyledLink } from '../styles'

export const Link = props => {
  const { id, color } = props
  return (
    <div>
      <StyledLink color={color} href={`/accounts/byId/${id}/details`} target="_blank">
        {id}
      </StyledLink>
    </div>
  )
}
