import React, { useState } from 'react'

import { Header } from 'components/atoms/Generic/index'
import TablePagination from '@material-ui/core/TablePagination'
import { Headers } from './headers'
import DepositByStatusRow from './DepositByStatusRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { TablePlaceholder } from 'components/atoms/Table/styles'

const DepositsByStatus = (props) => {
  const { selectedOption, fundingEventsByStatus } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
  }

  const { data, status } = fundingEventsByStatus
  const tablePaginationCount = data.length
  const fundingDeposits = data.slice(
    page * rowsPerPage,
    rowsPerPage * (page + 1),
  )
  return (
    <div>
      <Header title={'Filter Results'} />
      <Table>
        <TableHead>
          <TableRow>
            <Headers />
          </TableRow>
        </TableHead>
        <TableBody>
          {status !== 'done' && (
            <TableRow>
              <TableCell colSpan={8}>
                <LoadingBlock />
              </TableCell>
            </TableRow>
          )}
          {status === 'done' && fundingDeposits.length === 0 && (
            <TableCell colSpan={8}>
              <TablePlaceholder>No Deposits</TablePlaceholder>
            </TableCell>
          )}
          {fundingDeposits &&
            fundingDeposits.map((deposit, i) => {
              return (
                <DepositByStatusRow
                  key={i}
                  data={deposit}
                  depositId={deposit.depositId}
                  selectedOption={selectedOption}
                />
              )
            })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={tablePaginationCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default DepositsByStatus
