import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { required, maxLength256, requiredDropdownValue } from 'forms/fieldValidators'
import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'
import { renderDropdown, renderInput } from '../fields'
import { submitNewCustomerType } from './submit'
import { BASE } from 'theme/base-units'

const Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit, corporateAccountSanctioned } = props
  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Change Customer Type</FormTitle>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Customer type'}
          name={'customerType'}
          validate={[requiredDropdownValue]}
          component={renderDropdown}
          fullWidth
          corporateAccountSanctioned={corporateAccountSanctioned}
          options={[
            { value: 'INDIVIDUAL', label: 'INDIVIDUAL' },
            { value: 'TRUSTED_CUSTOMER', label: 'TRUSTED_CUSTOMER' },
            { value: 'CORPORATE', label: 'CORPORATE' },
          ]}
        />
      </InputGroup>
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required, maxLength256]}
          component={renderInput}
          fullWidth
        />
      </InputGroup>
      {error && <FormError error={error} />}
      <InputGroup justify={'flex-end'}>
        <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'changeCustomerType',
  onSubmit: submitNewCustomerType,
})(Form)

export default ReduxForm
