import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'

import { required } from 'forms/fieldValidators'
import {
  Button,
  FormContainer,
  FormError,
  InputGroup,
} from 'components/atoms/Form'

import { submitFraudRiskReport } from './submit'

import { renderInput } from '../fields'

let Form = props => {
  const { submitting, error, invalid } = props
  const { handleSubmit, onCancel, onSubmit } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {/* @ts-ignore */}
      <InputGroup align={'flex-start'} direction={'column'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required]}
          component={renderInput}
        />
      </InputGroup>
      {error && <FormError error={error} />}
      {/* @ts-ignore */}
      <InputGroup justify={'flex-end'}>
        <Button
          margin={`0 ${BASE * 2}px 0 0`}
          type="submit"
          disabled={submitting || invalid}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
      </InputGroup>
    </FormContainer>
  )
}

Form = reduxForm({
  form: 'fraudRiskForm',
  onSubmit: submitFraudRiskReport,
  destroyOnUnmount: true,
})(Form)
export const FraudRiskForm = connect()(Form)
