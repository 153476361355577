import moment from 'moment'
import { toast } from 'react-toastify'

export const saveFile = (prefix, blob) => {
  const uniqTime = moment().format('DD-MM-YY-HH:mm:ss')
  const filename = `${prefix}_${uniqTime}`

  const link = document.createElement('a')
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', filename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    toast('File downloaded', { type: toast.TYPE.SUCCESS, autoClose: 2000 })
  }
}

export const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}

export const kycLevelInfo = (kycLevel) => {
  if (kycLevel === 1) {
    return { description: 'Semi-verified', status: 'NOT_SUBMITTED' }
  } if (kycLevel === 2) {
    return { description: 'full-verified', status: 'SUBMITTED' }
  }
}
