import React, { Fragment, useEffect, useState } from 'react'
import {
  Actions,
} from 'components/atoms/Table/styles'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import RenderMarketingContactRunsTable from './runsTable'
import RenderMarketingContactSendgridListTable from './sendgridListsTable'
import InputFormGlobalSync from './FormGlobalSync'
import InputFormListSync from './FormListSync'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'

const MarketingContactRunsView = (props) => {

  const [marketingContactListRunResults, setMarketingContactListRuns] = useState(props.marketingContactListRunResults)
  const [isRunningGlobalSync, setIsRunningGlobalSync] = useState(false)
  const [isRunningListSync, setIsRunningListSync] = useState(false)

  const [currentTab, setCurrentTab] = useState('lists')

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  // Sets up component
  useEffect(() => {
    let isMounted = true
    const initComponent = async () => {
      props.fetchMarketingContactRunsList()
      checkIsRunning(isMounted)
    }
    initComponent()
    return () => isMounted = false // Prevent memory leak
  }, [])

  // Checks if the sync is still in progress
  const checkIsRunning = async (isMounted) => {
    try {
      let isRunningResponse = await getAxios().get(`/marketing-contacts-update-is-running`)
      if (isMounted) {
        setIsRunningGlobalSync(isRunningResponse.data.runningGlobalSync)
        setIsRunningListSync(isRunningResponse.data.runningListSync)
      }
    } catch (error) {
      const resMessage =
        error.response.status == 401
          ? `Could not get current sync status. \n401 - ${error.response.statusText}`
          : `Could not get current sync status.${error.response.data.message}`
      toast(resMessage, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      })
    }
  }

  // Updates the list of records if it changes
  useEffect(() => {
    setMarketingContactListRuns(props.marketingContactListRunResults)
  }, [props.marketingContactListRunResults])

  // Sets up an interval to poll the status of the run and clears it once the run is done
  useEffect(() => {
    let isMounted = true
    const intervalId = setInterval(() => {  // assign interval to a variable to clear it.
      checkIsRunning(isMounted)
    }, 16000) // Rechecks every 16 seconds (backend checks the SendGrid job every 30 seconds)
    if (!isRunningGlobalSync && !isRunningListSync) {
      props.fetchMarketingContactRunsList()
      clearInterval(intervalId)
    }
    return () => {
      isMounted = false // Prevent memory leaks
      clearInterval(intervalId) // Prevent memory leaks
    }
  }, [isRunningGlobalSync, isRunningListSync])

  const triggerMarketingContactsUpdate = async (batchSize = 1000, maxSize = 20000, userIds, listName) => {
    try {
      await getAxios().post(`/trigger/marketing-contacts-update`, {
        batchSize,
        maxSize,
        userIds,
        listName,
      })
      if (userIds && listName) {
        setIsRunningListSync(true)
      } else {
        setIsRunningGlobalSync(true)
      }
      toast('Syncing of Accounts initiated, this can take a while, please check back later.', {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      })
    } catch (error) {
      const resMessage =
        error.response.status == 401
          ? `Could not start-up marketing contact list update. \n401 - ${error.response.statusText}`
          : `Could not start-up marketing contact list update.${error.response.data.message}`
      toast(resMessage, {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      })
    }
  }

  return (
    <Fragment>
      <Actions margin={'0 0 20px 0'} justify={'flex-end'}>
        <InputFormGlobalSync
          isRunning={isRunningGlobalSync}
          disableSubmit={isRunningListSync}
          triggerMarketingContactsUpdate={triggerMarketingContactsUpdate}
        />
      </Actions>
      <Actions justify={'flex-end'}>
        <InputFormListSync
          isRunning={isRunningListSync}
          disableSubmit={isRunningGlobalSync}
          triggerMarketingContactsUpdate={triggerMarketingContactsUpdate}
        />
      </Actions>
      {marketingContactListRunResults.status === 'loading' && <LoadingBlock />}
      {marketingContactListRunResults.status === 'done' && (
        <>
          <TopNavigationBar
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
          >
            <NavigationTab label='Sendgrid Lists' value="lists" />
            <NavigationTab label='Sendgrid Runs' value="runs" />
          </TopNavigationBar>
          {/* <ContentContainer> */}
          {currentTab === 'lists' &&
            <RenderMarketingContactSendgridListTable
              list={[...(marketingContactListRunResults.data.sendgridLists)]}
            />
          }
          {currentTab === 'runs' &&
            <RenderMarketingContactRunsTable
              list={[...(marketingContactListRunResults.data.runs)]}
            />
          }
        </>
      )}
    </Fragment>
  )
}

export default MarketingContactRunsView
