import { Theme } from 'interfaces/theme'

import { ERROR_COLOR, GRAY_2, VALR_BLUE_GRAY, WHITE } from './colors'

export const generateThemeOverrides = (theme: Theme) => ({
  MuiButton: {
    text: {
      fontSize: '16px',
      height: 32,
      padding: '0 5px',
    },
  },
  MuiInputBase: {
    root: {
      '& input:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 100px ${theme.input.backgroundColor} inset`,
        WebkitTextFillColor: theme.input.textColor,
      },
      '&.MuiInputBase-root .MuiInputBase-input': {
        backgroundColor: theme.input.backgroundColor,
        borderRadius: '6px',
        color: theme.input.textColor,
      },
      '&.MuiOutlinedInput-multiline': {
        background: theme.input.backgroundColor,
      },
      '&.MuiAutocomplete-inputRoot': {
        backgroundColor: theme.input.backgroundColor,
      },
      '&.MuiAutocomplete-inputRoot .MuiChip-root': {
        color: theme.input.textColor,
        backgroundColor: theme.chip.background,
      },
    },
  },
  MuiPaper: {
    root: {
      color: theme.muiListItem.text,
      '&.MuiAutocomplete-paper': {
        backgroundColor: theme.autoComplete.background, // Set the background color for the box
        '& .MuiAutocomplete-noOptions': {
          color: theme.autoComplete.color,
        },
        '&.MuiAutocomplete-loading': {
          color: theme.autoComplete.color,
        },
      },
    },
  },
  MuiAutocomplete: {
    listbox: {
      color: theme.muiListItem.text,
      backgroundColor: theme.muiCardContent.background,
    },
  },
  MuiOutlinedInput: {
    root: {
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.input.borderColor,
      },
      '&.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline':
        {
          borderColor: ERROR_COLOR,
        },
      '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.input.borderColor,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: theme.formLabel.color,
      '&.Mui-label-filled': {
        color: theme.formLabel.filled,
      },
      '&.MuiInputLabel-shrink': {
        color: theme.formLabel.inputShrink,
      },
      '&.MuiFormLabel-root.Mui-error': {
        color: ERROR_COLOR,
      },
    },
  },
  MuiButtonBase: {
    root: {
      '&.MuiPickersDay-day': {
        color: theme.datePicker.text,
      },
      '&.MuiPickersDay-daySelected': {
        color: theme.datePicker.selected,
      },
      '&.MuiButtonBase-root.Mui-disabled .MuiSvgIcon-root': {
        color: GRAY_2,
      },
      '&.MuiButtonBase-root .MuiSvgIcon-root': {
        color: theme.icon.color,
      },
      '&.MuiButtonBase-root .MuiButton-label .MuiButton-startIcon .MuiSvgIcon-root':
        {
          color: theme.button.textColor,
        },
      '&.MuiButtonBase-root.Mui-disabled .MuiButton-label': {
        color: GRAY_2,
      },
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: '#e1e1e1',
      },
      '&.MuiButton-containedPrimary:hover': {
        backgroundColor: theme.button.hoverColor,
      },
      '&.MuiButton-containedPrimary': {
        backgroundColor: theme.button.backgroundColor,
        color: theme.button.textColor,
      },
      '&.MuiCheckbox-colorPrimary.Mui-checked': {
        color: theme.checkBox.checked,
      },
      '&.MuiAccordionSummary-root': {
        color: theme.muiAccordion.color,
        '&.Mui-expanded': {
          backgroundColor: theme.muiAccordion.expandedBackgroundColor,
          color: theme.muiAccordion.expandedText,
        },
      },
    },
  },
  MuiTableSortLabel: {
    root: {
      '&$active': {
        color: theme.button.textColor,
        '&& $icon': {
          color: theme.icon.color,
        },
      },
    },
  },
  MuiSvgIcon: {
    root: {
      '&.MuiTableSortLabel-icon': {
        color: 'red',
      },
    },
  },
  MuiListItem: {
    root: {
      '&.MuiListItem-button:hover': {
        backgroundColor: VALR_BLUE_GRAY,
      },
    },
  },
  MuiMenuItem: {
    root: {
      color: theme.muiListItem.text,
      '&.Mui-selected': {
        backgroundColor: VALR_BLUE_GRAY,
        color: WHITE,
      },
    },
  },
  MuiSwitch: {
    root: {
      '&.MuiSwitch-root .MuiSwitch-track': {
        backgroundColor: theme.muiSwitch.backgroundColor,
      },
    },
  },
  MuiRadio: {
    colorPrimary: {
      '&$checked': {
        color: theme.radio.color, // change the color here
      },
    },
  },
  MuiTable: {
    root: {
      color: theme.muiTable.color,
      borderCollapse: 'separate',
      borderSpacing: '0 2px',
    },
  },
  MuiTableCell: {
    root: {
      '&.MuiTableCell-head': {
        color: theme.muiTable.color,
        backgroundColor: theme.muiTable.tableHead.backgroundColor,
      },
      '&.MuiTableCell-root': {
        color: theme.muiTable.color,
        border: 'none',
      },
    },
  },
  MuiTableRow: {
    root: {
      backgroundColor: theme.muiTable.tableRow.backgroundColor,
      lineHeight: '4px',
      '&.MuiTableRow-root:hover': {
        backgroundColor: theme.muiTable.tableRow.hoverColor,
        cursor: 'pointer',
      },
      '&.MuiTableRow-root.Mui-selected': {
        backgroundColor: theme.muiTable.tableRow.hoverColor,
      },
      '&.active': {
        backgroundColor: theme.muiTable.tableRow.hoverColor,
      },
      '&.light-hover:hover': {
        backgroundColor: theme.muiTable.tableRow.lightHoverColor,
      },
      '&.no-hover:hover': {
        backgroundColor: theme.muiTable.tableRow.backgroundColor,
        cursor: 'auto',
      },
      '&.MuiTableRow-head': {
        backgroundColor: theme.muiTable.tableHead.backgroundColor,
      },
      '&.MuiTableRow-head:hover': {
        backgroundColor: theme.muiTable.tableHead.backgroundColor,
      },
    },
  },
  MuiFormGroup: {
    root: {
      '&.MuiFormGroup-root': {
        color: theme.formGroup.text,
      },
    },
  },
  MuiTablePagination: {
    root: {
      '&.MuiTablePagination-root': {
        color: theme.muiTablePagination.text,
      },
    },
  },
  MuiCardContent: {
    root: {
      backgroundColor: theme.muiCardContent.background,
      color: theme.muiCardContent.text,
    },
  },
  MuiTypography: {
    root: {
      color: theme.muiTypography.color,
      '&.MuiPickersCalendarHeader-dayLabel': {
        color: theme.datePicker.text,
      },
      '&.MuiDialogContentText-root': {
        color: theme.muiTypography.color,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.muiDivider.color,
      margin: '0 4px',
    },
    vertical: {
      marginLeft: '8px',
    },
  },
  MuiAccordion: {
    root: {
      borderBottom: `1px solid ${theme.muiAccordion.borderBottomColor}`,
    },
  },
})
