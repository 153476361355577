import getAxios from 'helpers/axios'
import { reset } from 'redux-form'
import { toast } from 'react-toastify'

export const submit = async (values, dispatch) => {
  const { accountId, data, comment, flag, action, dualAuthStatus } = values
  data.comment = comment
  var actionType = action || 'NORMAL_COMMENT'
  try {
    await getAxios().post('/account/comments', {
      accountId,
      data,
      flag,
      actionType,
      dualAuthStatus,
    })
    toast('Comment successfully added', {
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
    })
    dispatch(reset('CommentsForm'))
  } catch (e) {
    toast(`Failed to add a comment,|| ${e.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
