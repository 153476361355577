import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import UnallocatedBlockChainReceives from 'components/organisms/UnAllocatedBlockChainReceives/index'

import FiatDeposits from './FiatDeposits'
import UsdDeposits from './UsdDeposits'
import CreditCardDeposits from './CreditCardDeposits'
import HistoricalDeposits from './HistoricalDeposits'
import FundingInfo from './Funding'
import useFeatureFlagCheck from 'Hooks/FeatureFlags/useFeatureFlag'

const routes = [
  '/exchange/deposits',
  '/exchange/deposits/historical-deposits',
  '/exchange/deposits/usdc',
  '/exchange/deposits/credit-card',
  '/exchange/deposits/unallocated-blockchain-receives',
  '/exchange/deposits/funding',
]

const RenderAllDeposits = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
    depositReview,
    unassignedBlockChain,
    wiredDepositReview,
    wiredDepositPendingReview,
    creditCard,
    fundingInfo,
  } = props
  const flagOn = useFeatureFlagCheck('usdcDeposits')

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    console.log({ pathname })
    if (routes.includes(pathname)) {
      setCurrentPage(pathname)
    }
  }, [pathname])
  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label={`BANK DEPOSITS (${depositReview.data.length})`}
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label={'HISTORICAL DEPOSITS'}
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
        {flagOn && (
          <NavigationTab
            value={routes[2]}
            label={`USDC DEPOSITS (${wiredDepositReview.data.length +
              wiredDepositPendingReview.data.length
              })`}
            component={ForwardedNavLink}
            to={routes[2]}
            exact
          />
        )}
        <NavigationTab
          value={routes[3]}
          label={`CREDIT CARD DEPOSITS (${creditCard.data ? creditCard.data.length : 0
            })`}
          component={ForwardedNavLink}
          to={routes[3]}
          exact
        />
        <NavigationTab
          value={routes[4]}
          label={`Unallocated Blockchain Receives(${unassignedBlockChain.data.length})`}
          component={ForwardedNavLink}
          to={routes[4]}
          exact
        />
        <NavigationTab
          value={routes[5]}
          label={`Funding(${fundingInfo.data.length + fundingInfo.suspendedDeposits.length})`}
          component={ForwardedNavLink}
          to={routes[5]}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={FiatDeposits} exact />
          <Route path={routes[1]} component={HistoricalDeposits} exact />
          {flagOn && <Route path={routes[2]} component={UsdDeposits} exact />}
          <Route path={routes[3]} component={CreditCardDeposits} exact />
          <Route
            path={routes[4]}
            component={UnallocatedBlockChainReceives}
            exact
          />
          <Route path={routes[5]} component={FundingInfo} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}

const mapStateToProps = (state) => ({
  depositReview: state.fiat.suspendedDeposits,
  withdrawalsUnderReview: state.fiat.withdrawalsUnderReview,
  wiredDepositReview: state.circle.wiredDepositReview,
  wiredDepositPendingReview: state.circle.wiredDepositPendingReview,
  wiredWithdrawalReview: state.circle.updateWithdrawalReview,
  creditCard: state.creditCard.creditCardDepositReview,
  unassignedBlockChain: state.unallocatedBlockchainReceives,
  fundingInfo: state.fundingEvents.fundingInfo,
})

export default connect(mapStateToProps, null)(RenderAllDeposits)
