import getAxios from 'helpers/axios'
import { throwGenericSubmissionError } from 'helpers/utils'
import { toast } from 'react-toastify'
import {
  fetchWithdrawalsUnderReview,
  moveFiatWithdrawalToPending,
} from 'redux/fiat/actions'

export const submitFiatWithdrawalReview = async (values, dispatch) => {
  const {
    status, comment, withdrawalId, accountId, reverseFee, country,
  } = values
  let res = null
  try {
    if (status.value !== 'SUSPEND') {
      res = await getAxios().post('/fiat/withdrawals/review', {
        status: status.value,
        withdrawalId,
        accountId,
        comment,
        reverseFee,
      })
    }

    if (status.value === 'APPROVE') {
      dispatch(moveFiatWithdrawalToPending(withdrawalId))
    } else if (status.value === 'SUSPEND') {
      const description = 'WITHDRAWALS'
      await getAxios().post('/suspense/type', {
        description,
        withdrawalId,
      })
    }
    toast('Withdrawal review', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(fetchWithdrawalsUnderReview(country))
    return {
      withdrawalId,
      level: status.value,
      description: status.label,
    }
  } catch (e) {
    toast('Server error', { type: toast.TYPE.ERROR, autoClose: 1500 })
    throwGenericSubmissionError({
      status: e.response.status || 400,
      message:
        e.response.data.message ||
        'Server error. Contact administrator to get help',
    })
  }
  return res
}
