import styled from 'styled-components'
import { Chip } from '@material-ui/core'

export const StyledChip = styled(Chip)`
${({ width, height, color }) => {
    const styledObject = {}
    if (width) styledObject.width = `${width}px`
    if (height) styledObject.height = `${height}px`
    if (color) {
      styledObject.color = color
      styledObject.borderColor = color
    }
    return styledObject
  }}
  :hover {
      background-color: ${({ color }) => color};
      color: #ffff;
  }
`
