import React from 'react'
import { connectStateAndDispatch } from 'store'
import SimpleModal from 'components/organisms/Modal'
import { FraudRiskForm } from 'forms/FraudRisk/FraudRiskForm'
import { FraudRiskButtonActionType } from 'interfaces/Fraud'

import { handleAccountFraudModalState } from 'redux/accounts/actions'
import LoadingBlock from 'components/molecules/LoadingBlock'

import Actions from './Actions'

const Fraud = ({ fraud, handleAccountFraudModalState, accountId }) => {
  const handleCloseModal = () =>
    handleAccountFraudModalState({ actionType: '', isOpen: false })
  if (fraud.isFetchingFraudInformation) {
    return <LoadingBlock message="Fetching account fraud alerts" />
  }
  const handleActionText = (type: FraudRiskButtonActionType) => {
    if (type === 'MONITOR') {
      return `${type.replace('_', ' ').toLowerCase()}ing`
    }
    return type.replace('_', ' ').toLowerCase()
  }
  const isActiveAlert = fraud.alerts.includes(fraud.modal.actionType)

  return (
    <>
      <Actions
        fraudAlerts={fraud.alerts}
        handleAccountFraudModalState={handleAccountFraudModalState}
      />
      <SimpleModal
        buttonsHidden
        width="40%"
        title={`${isActiveAlert ? 'Remove' : 'Submit'}  ${handleActionText(
          fraud.modal.actionType,
        )} for account.`}
        handleClose={handleCloseModal}
        open={fraud.modal.isOpen}
      >
        <FraudRiskForm
          onCancel={handleCloseModal}
          initialValues={{
            accountId,
            comment: '',
            actionType: fraud.modal.actionType,
            isActiveAlert,
          }}
        />
      </SimpleModal>
    </>
  )
}
const actions = {
  handleAccountFraudModalState,
}

export default connectStateAndDispatch(
  (state) => ({
    fraud: state.accounts.byId.fraud,
    accountId: state.accounts.byId.accountId,
  }),
  actions,
  Fraud,
)
