import React, { FC, useState } from 'react'
import { Collapse, TableBody, TableCell, TableRow } from '@material-ui/core'
import {
  FoundSanctionLegalEntity,
  FoundSanctionNaturalEntity,
} from 'interfaces/sanctions'
import {
  StyledSanctionCorporateCollapseCell,
  StyledSanctionCorporateContainer,
  StyledSanctionCorporateHead,
  StyledSanctionCorporateRow,
  StyledSanctionCorporateTable,
} from '../styles'
import MatchedToData from './MatchedToData'

interface HeadingData {
  heading: string
  key: string
}

interface FoundEntitiesTableProps {
  entities: FoundSanctionNaturalEntity[] | FoundSanctionLegalEntity[]
  headings: HeadingData[]
  tableType?: 'LEGAL' | 'NATURAL'
}

const FoundEntitiesTable: FC<FoundEntitiesTableProps> = ({
  entities,
  headings,
  tableType = 'NATURAL',
}) => {
  const listOrder = headings.map(({ key }) => key)
  const [isExpanded, setIsExpanded] = useState()
  return (
    <StyledSanctionCorporateContainer>
      <StyledSanctionCorporateTable>
        <StyledSanctionCorporateHead>
          <TableRow>
            {headings.map(({ heading }) => (
              <TableCell key={heading}>{heading}</TableCell>
            ))}
            {tableType === 'LEGAL' && (
              <>
                <TableCell>Sanctions List</TableCell>
                <TableCell>First Name</TableCell>
              </>
            )}
          </TableRow>
        </StyledSanctionCorporateHead>
        <TableBody>
          {entities.map((entity, index) => (
            <>
              <StyledSanctionCorporateRow
                onClick={() =>
                  setIsExpanded(prev => (prev === index ? null : index))
                }
                key={index}
              >
                {listOrder.map(key => (
                  <TableCell key={key}>{entity[key]}</TableCell>
                ))}
                {tableType === 'LEGAL' && (
                  <>
                    <TableCell>{entity.matchedTo.sanctionsList}</TableCell>
                    <TableCell>{entity.matchedTo.firstName}</TableCell>
                  </>
                )}
              </StyledSanctionCorporateRow>
              <TableRow>
                <StyledSanctionCorporateCollapseCell colSpan={4}>
                  <Collapse
                    in={isExpanded === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <MatchedToData matchedTo={entity.matchedTo} />
                  </Collapse>
                </StyledSanctionCorporateCollapseCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </StyledSanctionCorporateTable>
    </StyledSanctionCorporateContainer>
  )
}

export default FoundEntitiesTable
