import React, { Fragment, Component } from 'react'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasCountryChangeRole } from 'helpers/roleBasedAccess'

import { Details } from 'components/atoms/Details'
import { ActionButton } from 'components/atoms/Table/styles'
import InitiateCountryOfResidenceChangeForm from 'forms/InitiateCountryOfResidenceChange'
import auth from 'services/Auth'

class InitiateCountryOfResidenceChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formShown: false,
      updateUserData: {},
      countryOfResidenceChangeProcessActive: false,
    }
  }

  toggleForm = async () => {
    this.setState({ formShown: !this.state.formShown })
  }

  handleCloseModal = () => {
    this.setState({
      modalData: {
        ...this.initialModalState,
      },
    })
  }

  render() {
    const { formShown, countryOfResidenceChangeProcessActive } = this.state
    const { id } = this.props
    const notAuthorized = !hasCountryChangeRole()
    return (
      <Details>
        <Fragment>
          {!formShown && (
            <MuiButton
              disabled={notAuthorized || countryOfResidenceChangeProcessActive}
              onClick={this.toggleForm}
              showToolTip={notAuthorized}
              tooltipTitle={
                notAuthorized
                  ? 'You are not authorized to perform this action'
                  : ''
              }
            >
              {countryOfResidenceChangeProcessActive
                ? 'updating...'
                : 'Change Country'}
            </MuiButton>
          )}
        </Fragment>
        {formShown && (
          <InitiateCountryOfResidenceChangeForm
            initialValues={{
              id,
              comment: '',
              data: { date: new Date(), createdBy: auth.decodeNameToken() },
              dualAuthStatus: 'pending',
              actionType: 'RESIDENTIAL_COUNTRY_CHANGE',
            }}
            countryOfResidenceChangeProcessActive={status =>
              this.setState({
                countryOfResidenceChangeProcessActive: status,
              })
            }
            form="ChangeCountryOfResidence"
            onSubmitSuccess={this.toggleForm}
            onCancel={this.toggleForm}
          />
        )}
      </Details>
    )
  }
}
export default InitiateCountryOfResidenceChange
