import { TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { VALR_BLUE_GRAY } from 'theme/colors'

export const StyledSearchedAccountsRow = styled(TableRow)`
  :hover {
    cursor: pointer;
    background-color: ${VALR_BLUE_GRAY};
  }
`
