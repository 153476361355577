import getAxios from 'helpers/axios'
import * as moment from 'moment'

import ErrorToast from 'components/molecules/ErrorToast'

import {
  REMOVE_SUBMITTED_ITEM,
  UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_START,
  IDENTITY_NUMBER_CHANGE_BY_ID_PROCESS,
  IDENTITY_NUMBER_CHANGE_BY_ID_SUCCESS,
  IDENTITY_NUMBER_CHANGE_BY_ID_ERROR,
  UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_SUCCESS,
  UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_ERROR,
} from './index'

const convertUnixToDate = date => {
  const day = moment(date.seconds * 1000 || date)
  return day.format('YYYY-MMM-DD HH:mm:ss')
}
export const removeSubmittedItem = id => async dispatch => {
  dispatch({
    type: REMOVE_SUBMITTED_ITEM,
    payload: {
      id,
    },
  })
}

export const fetchIdentityNumberChangeDualAuthRawData = id => async dispatch => {
  let res = null
  dispatch({
    type: IDENTITY_NUMBER_CHANGE_BY_ID_PROCESS,
  })

  try {
    res = await getAxios().get(`/pending/dual-auth/single/${id}`)
    const { data } = res
    data.data = JSON.parse(data.data)
    data.createdAt = convertUnixToDate(data.createdAt)
    dispatch({
      type: IDENTITY_NUMBER_CHANGE_BY_ID_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: IDENTITY_NUMBER_CHANGE_BY_ID_ERROR,
      payload: {
        error: error.response,
      },
    })
  }
}

export const updateIdNumberDualAuthRowUserSocketEvent = auth => async dispatch => {
  try {
    dispatch({
      type: UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_START,
    })
    dispatch({
      type: UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_SUCCESS,
      payload: {
        data: auth,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: UPDATE_IDENTITY_NUMBER_CHANGE_DUAL_AUTH_USER_ROW_ERROR,
      payload: {
        error,
      },
    })
  }
}
