import React, { FC, useEffect, useState } from 'react'
import getAxios from 'helpers/axios'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Flexbox } from 'components/layout'
import { CorporateDocument } from 'interfaces/Corporates'
import { Typography } from '@material-ui/core'

import LoadingBlock from 'components/molecules/LoadingBlock'
import ErrorToast from 'components/molecules/ErrorToast'
import VisibilityIcon from '@material-ui/icons/Visibility'
import CloseIcon from '@material-ui/icons/Close'
import { FILE_TYPES } from '../const/Entities'
import {
  StyledDocumentContentWrapper,
  StyledImageWrapper,
} from '../pages/Styles'
import { PdfViewActionContainer, StyledDocumentsActionsContainer } from 'components/organisms/DocumentViewers/Styles'
import { viewPdf } from 'helpers/utils'
import IconButton from 'components/atoms/IconButton'
import { Title } from 'components/atoms/Title'

interface Document {
  document: CorporateDocument
  canEditCorporateInformation: boolean
  restoringCorporateDocument: boolean
  restoreDocument: () => Promise<void>
  handleCloseDocumentViewer: () => void
}

const DocumentView: FC<Document> = ({
  document,
  canEditCorporateInformation,
  restoringCorporateDocument,
  restoreDocument,
  handleCloseDocumentViewer,
}) => {
  const [isFetchingDocument, setIsFetchingDocument] = useState(false)
  const [documentState, setDocumentState] = useState({
    fileData: null,
    fileType: null,
    url: '',
    isPdf: false
  });

  function previewSelectedPdfFile(src) {
    return <embed src={src} width="100%" height="350" />
  }

  const handleGetDocument = async () => {
    setIsFetchingDocument(true)
    try {
      const { data } = await getAxios().get(
        `/corporates/document/${document.fileName}`,
      )
      if (data.file && data.file.data) {
        const type = data.fileType
        const blob = new Blob([new Uint8Array(data.file.data)], {
          type,
        })
        setDocumentState({
          fileType: type,
          isPdf: type === FILE_TYPES.PDF,
          fileData: data.file.data,
          url: URL.createObjectURL(blob)
        })
      }
    } catch (error) {
      ErrorToast(error)
    }
    setIsFetchingDocument(false)
  }

  useEffect(() => {
    handleGetDocument()
  }, [])
  return (
    <StyledDocumentContentWrapper>
      {isFetchingDocument ? (
        <LoadingBlock message="Fetching Document..." />
      ) : (
        <StyledImageWrapper>
          <StyledDocumentsActionsContainer>
            <Title type="h3" text={document.fileName.split(']}')[1]} />
            {/* @ts-ignore */}
            <IconButton
              onClick={handleCloseDocumentViewer}
              icon={<CloseIcon />}
            />
          </StyledDocumentsActionsContainer>
          {documentState.url && (
            <>
              {!documentState.isPdf && (
                <img src={documentState.url} alt="img" />
              )}
              {documentState.isPdf &&
                previewSelectedPdfFile(documentState.url)}
              {documentState.isPdf && (
                <PdfViewActionContainer>
                  <div>
                    {/* @ts-ignore */}
                    <IconButton
                      icon={<VisibilityIcon style={{ color: '#13ab18' }} />}
                      onClick={() => {
                        viewPdf(
                          documentState.fileData,
                          documentState.fileType,
                        )
                      }}
                    />
                    <Typography variant="body1">View PDF</Typography>
                  </div>
                </PdfViewActionContainer>
              )}
              <Flexbox width="100%" direction="row" justify="space-between">
                <div>
                  <p>{document.documentType.split('_').join(' ')}</p>
                  <p>{document.createdAt}</p>
                </div>
                <MuiButton
                  disabled={
                    !canEditCorporateInformation || restoringCorporateDocument
                  }
                  onClick={restoreDocument}
                >
                  Restor
                  {restoringCorporateDocument ? 'ing' : 'e'} document
                </MuiButton>
              </Flexbox>
            </>
          )}
        </StyledImageWrapper>
      )}
    </StyledDocumentContentWrapper>
  )
}

export default DocumentView
