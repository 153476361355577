import React, { Fragment, createRef } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import {
  ConfigStyle,
  InputStyle,
  ListStyle,
  MapInner,
  MapStyle,
} from './styles'

import {
  ActionButton,
  Title,
  TitleContainer,
} from '../../components/atoms/Table/styles'
import { InputGroup } from '../../components/atoms/Form'
import { renderInput as RenderInput } from '../../forms/fields'
import { BASE } from '../../theme/base-units'
import { hasApplicationConfigEditor } from '../../helpers/roleBasedAccess'
import { number } from 'prop-types'

const DynamicConfigItem = ({
  item,
  onChange,
  onMapAdd,
  onListAdd,
  onRemove,
}) => {
  const hasEditorRights = hasApplicationConfigEditor()

  const envNameToDisplay = item.envName.split('_').join(' ')
  const showExample = () => {
    let exampleText = ''
    switch (item.type) {
      case 'MAPPED_STRING_TO_STRING':
        exampleText = 'COUNTRY:CURRENCY (ZAF:ZAR)'
        break
      case 'MAPPED_CURRENCY_BIG_DECIMAL':
        exampleText = 'CURRENCY:VALUE (ZAR:1)'
        break
      case 'MAPPED_CURRENCY_PAIR_BIG_DECIMAL':
        exampleText = 'PAIR:VALUE (ETHUSDCPERP:1)'
        break
      case 'MAPPED_INT_BIG_DECIMAL':
        exampleText = 'INT:VALUE (1:100.0)'
        break
      default:
        if (item.value) {
          if (typeof item.value === 'string') {
            const number = Number(item.value)
            if (!isNaN(number)) {
              const isDecimal = number.toString().includes('.')
              exampleText = isDecimal ? number : '100'
            } else {
              exampleText = item.value
            }
          } else if (item.value[0]) {
            exampleText = item.value[0]
          }
        }
    }
    if (!exampleText) return ''
    return `e.g (${truncateString(exampleText, 32)})`
  }

  switch (item.type) {
    case 'BOOLEAN':
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
          </TitleContainer>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={item.value.toString()}
            onChange={(event) =>
              onChange(item.type, item.envName, event.target.value)
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Yes"
              disabled={!hasEditorRights}
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="No"
              disabled={!hasEditorRights}
            />
          </RadioGroup>
        </>
      )
    case 'LIST':
      window[`${item.envName}Ref`] = createRef()
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
            {showExample()}
          </TitleContainer>
          {item.value.map((t, i) => (
            <ListStyle key={i}>
              <span> {t}</span>
              <ConfigStyle>
                <span
                  style={{ marginBottom: '8%', textAlign: 'right' }}
                  className="close-icon"
                  onClick={() => onRemove(i, item.envName)}
                  disabled={!hasEditorRights}
                />
                <span
                  style={{ marginBottom: '8%' }}
                  className="remove-text"
                  onClick={() => onRemove(i, item.envName)}
                  disabled={!hasEditorRights}
                >
                  Remove
                </span>
              </ConfigStyle>
            </ListStyle>
          ))}
          <InputStyle>
            <RenderInput
              placeholder={`Enter value ${showExample()}`}
              meta={{}}
              inputRef={window[`${item.envName}Ref`]}
              style={{ margin: `0 ${BASE * 2}px 0 0` }}
              disabled={!hasEditorRights}
            />
            <ActionButton
              type="button"
              onClick={() => onListAdd(item.envName)}
              disabled={!hasEditorRights}
            >
              Add
            </ActionButton>
          </InputStyle>
        </>
      )
    case 'MAPPED_STRING_TO_STRING':
    case 'MAPPED_CURRENCY_BIG_DECIMAL':
    case 'MAPPED_CURRENCY_PAIR_BIG_DECIMAL':
    case 'MAPPED_INT_BIG_DECIMAL':
      window[`${item.envName}Ref`] = createRef()
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
            {showExample(item)}
          </TitleContainer>
          {item.value.map((listItem, i) =>
            Object.keys(listItem).map((key, c) => (
              <Fragment key={item.envName + c}>
                <MapStyle>
                  {key}
                  <MapInner>
                    <span
                      style={{ marginBottom: '8%', textAlign: 'right' }}
                      className="close-icon"
                      onClick={() => onRemove(i, item.envName)}
                      disabled={!hasEditorRights}
                    />
                    <span
                      style={{ marginBottom: '8%' }}
                      className="remove-text"
                      onClick={() => onRemove(i, item.envName)}
                      disabled={!hasEditorRights}
                    >
                      Remove
                    </span>
                  </MapInner>
                  <InputGroup justify={'flex-start'}>
                    <RenderInput
                      meta={{}}
                      width={'100%'}
                      onChange={(event) =>
                        onChange(
                          item.type,
                          item.envName,
                          event.target.value,
                          key,
                        )
                      }
                      value={listItem[key]}
                      name={key}
                      disabled={!hasEditorRights}
                    />
                  </InputGroup>
                </MapStyle>
              </Fragment>
            )),
          )}
          <InputStyle>
            <RenderInput
              placeholder={`Enter value ${showExample()}`}
              meta={{}}
              width={'100%'}
              inputRef={window[`${item.envName}Ref`]}
              disabled={!hasEditorRights}
            />
            <ActionButton
              type="button"
              onClick={() => onMapAdd(item.envName)}
              disabled={!hasEditorRights}
            >
              Add
            </ActionButton>
          </InputStyle>
        </>
      )
    case 'MAPPED_ACCOUNT_TYPE_BIG_DECIMAL':
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
          </TitleContainer>
          {item.value.map((listItem) =>
            Object.keys(listItem).map((key) => (
              <ConfigStyle key={item.envName + key}>
                {key}
                <InputGroup justify={'flex-start'}>
                  <RenderInput
                    meta={{}}
                    style={{ margin: `0 ${BASE * 2}px 0 0` }}
                    onChange={(event) =>
                      onChange(item.type, item.envName, event.target.value, key)
                    }
                    value={Object.values(listItem)}
                    name={key}
                    disabled={!hasEditorRights}
                  />
                </InputGroup>
              </ConfigStyle>
            )),
          )}
        </>
      )
    case 'BIG_DECIMAL':
    case 'INT':
    case 'STRING':
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
            {showExample()}
          </TitleContainer>
          <InputGroup justify={'flex-start'}>
            <RenderInput
              disabled={!hasEditorRights}
              meta={{}}
              style={{ margin: `0 ${BASE * 2}px 0 0` }}
              value={item.value}
              onChange={(event) =>
                onChange(item.type, item.envName, event.target.value)
              }
            />
          </InputGroup>
        </>
      )
    default:
      return (
        <>
          <TitleContainer>
            <Title>{envNameToDisplay}</Title>
          </TitleContainer>
          <p>Unknown config type</p>
        </>
      )
  }
}

const DynamicConfig = ({ items, ...props }) => {
  if (!items.length || items.length <= 0) return null

  return (
    <>
      {items.map((item, i) => (
        <DynamicConfigItem item={item} key={i} {...props} />
      ))}
    </>
  )
}

const truncateString = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str
  } else {
    if (typeof str === number)
      return str.toString().slice(0, maxLength - 3) + '...'
    if (typeof str === 'string') return str.slice(0, maxLength - 3) + '...'
  }
}

export default DynamicConfig
