import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { fetchFundingReview, updateFundingEvents } from 'redux/fundingEvents/actions'

import ErrorToast from '../../components/molecules/ErrorToast'

export const isPendingManualAllocation = (status) => ['PENDING_MANUAL_ALLOCATION', 'MANUAL_ALLOCATION_DUAL_AUTH_REJECTED'].includes(status)

export const submitFundingUpdate = async (values, onComplete) => {
  const {
    accountId, status: statusObj, comment, identifier, currentStatus,
  } = values

  const { value: status } = statusObj

  try {
    if (status === 'SUSPEND') {
      const description = 'FUNDING'
      await getAxios().post('/suspense/type', {
        description,
        identifier,
      })
      toast('Deposit status updated on suspend', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    } else {
      await getAxios().post('/funding/update/funding/deposits', {
        accountId,
        identifier,
        status,
        comment,
        currentStatus,
      })
      toast('Deposits Status Updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
    }
    onComplete(identifier)

  } catch (error) {
    ErrorToast(error)
  }
}

export const submit = async (values, dispatch) => {
  const { currentStatus, status, currency } = values
  if (isPendingManualAllocation(currentStatus) && status?.value === 'APPROVE') {
    return fetchFundingReview(values)(dispatch)
  }

  return submitFundingUpdate(values, (identifier) => dispatch(updateFundingEvents(values)))
}
