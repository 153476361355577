import { Switch } from '@material-ui/core'
import React, { FC } from 'react'
import {
  StyledMuiSwitcherTitle,
  StyledMuiSwitcherWithTitleWrapper,
} from './styles'

interface MuiSwitcherWithTitleProps {
  checked: boolean
  text: string
  onChange: () => void
}

const MuiSwitcherWithTitle: FC<MuiSwitcherWithTitleProps> = ({
  checked,
  text,
  onChange,
}) => (
  <StyledMuiSwitcherWithTitleWrapper>
    <StyledMuiSwitcherTitle>{text}</StyledMuiSwitcherTitle>
    <Switch
      size="medium"
      color="primary"
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
  </StyledMuiSwitcherWithTitleWrapper>
)

export default MuiSwitcherWithTitle
