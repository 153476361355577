import React, { useEffect, useRef, useState } from 'react'
import FileUploader from 'components/molecules/FileUploader'
import getAxios from 'helpers/axios'
import CircularStatic from 'components/atoms/Loaders/CircularProgressWithLabel'
import { toast } from 'react-toastify'
import { TitleContainer } from 'components/atoms/Table/styles'
import { checkRole, complianceEditor } from 'helpers/roleBasedAccess'
import ErrorToast from 'components/molecules/ErrorToast'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock'

const ComplianceAdminConfig = () => {
  const [uploadProgress, setUploadProgress] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [csvView, setCsvView] = useState<any>(null)
  const [fetchingCsvFileData, setFetchingCsvFileData] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value))
    setPage(0)
  }

  const formatLineDataWithQuotes = (line: string) => {
    let lineData = line.split(',')
    lineData = lineData.reduce(
      (
        acc: { inQuotes: boolean; current: string[]; result: string[] },
        curr: string,
      ) => {
        if (acc.inQuotes) {
          acc.current.push(curr)
          if (curr.includes('"')) {
            acc.result.push(acc.current.join(','))
            acc.current = []
            acc.inQuotes = false
          }
        } else {
          if (curr.includes('"')) {
            acc.current.push(curr)
            acc.inQuotes = true
          } else {
            acc.result.push(curr)
          }
        }
        return acc
      },
      { inQuotes: false, current: [], result: [] },
    ).result
    return lineData
  }

  const handleFetchCurrentSanctionsFileData = async () => {
    try {
      setFetchingCsvFileData(true)
      const { data } = await getAxios().get('/sanctions-csv/file-data')
      if (!data.fileData) return setFetchingCsvFileData(false)
      const lines = data.fileData.split('\n')
      const headings = lines[0].split(',')
      lines.splice(0, 1)
      const restOfTableData = lines.map((line: string) =>
        formatLineDataWithQuotes(line),
      )
      setCsvView({
        headings,
        restOfTableData,
      })
    } catch (error) {
      ErrorToast(error)
    }
    setFetchingCsvFileData(false)
  }

  useEffect(() => {
    handleFetchCurrentSanctionsFileData()
    return () => {}
  }, [])

  if (fetchingCsvFileData)
    return <LoadingBlock message="Fetching csv data..." />

  return (
    <div>
      <h3>Admin configuration settings</h3>
      {checkRole(complianceEditor) && (
        <>
          <TitleContainer>Upload sanctions consolidated csv</TitleContainer>

          <>
            <CircularStatic progress={uploadProgress} />{' '}
            {isUploading ? (
              <LoadingBlock message="Uploading csv file data..." />
            ) : (
              <FileUploader
                acceptedFileTypes={['.csv']}
                // @ts-ignore
                handleChange={(file) => {
                  const fileReader = new FileReader()
                  fileReader.readAsText(file)
                  fileReader.onloadend = (e) => {
                    const content = fileReader.result
                    if (content && typeof content === 'string') {
                      const lines = content.split('\n')
                      const headings = lines[0].split(',')
                      lines.splice(0, 1)
                      const restOfTableData = lines.map((line) =>
                        formatLineDataWithQuotes(line),
                      )
                      setCsvView({
                        headings,
                        restOfTableData,
                        isNewFileToBeUploaded: true,
                      })
                    }
                  }
                }}
                clearFile
                manual
                // @ts-ignore
                handleUpload={async (file) => {
                  try {
                    if (!checkRole(complianceEditor))
                      return ErrorToast(
                        'You are not authorized to perform this action.',
                      )
                    setIsUploading(true)
                    if (timeoutRef.current) {
                      clearTimeout(timeoutRef.current)
                    }
                    const formData = new FormData()
                    formData.append('selectedFile', file)

                    await getAxios().post('/sanctions-csv/upload', formData, {
                      onUploadProgress: (uploadingData) => {
                        if (uploadingData && uploadingData.total) {
                          const progress = Math.round(
                            (100 * uploadingData.loaded) / uploadingData.total,
                          )
                          setUploadProgress(progress)
                        }
                      },
                    })

                    timeoutRef.current = setTimeout(() => {
                      setUploadProgress(0)
                    }, 1000)
                    toast('Consolidated csv uploaded successfully.', {
                      type: toast.TYPE.SUCCESS,
                      autoClose: 1500,
                    })
                    setCsvView(null)
                    handleFetchCurrentSanctionsFileData()
                  } catch (error) {
                    ErrorToast(error)
                    setUploadProgress(0)
                  }
                  setIsUploading(false)
                }}
                height={100}
                uploadButtonText="UPLOAD SANCTION CSV"
              />
            )}
          </>
          {csvView && csvView.restOfTableData.length && (
            <>
              <h4>
                {csvView.isNewFileToBeUploaded
                  ? 'New sanctions csv file to upload'
                  : 'Sanctions consolidated csv'}
              </h4>
              <div className="sanctions-csv-data">
                <Table>
                  <TableHead>
                    <TableRow>
                      {csvView?.headings.map((h) => <TableCell>{h}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvView?.restOfTableData
                      ?.slice(page * rowsPerPage, rowsPerPage * (page + 1))
                      .map((section) => (
                        <TableRow>
                          {section.map((td) => (
                            <TableCell colSpan={0.1}>{td}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                count={csvView?.restOfTableData?.length || 0}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ComplianceAdminConfig
