import {
  ContentContainer,
  Header,
  Spacer,
} from 'components/atoms/Generic/index'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BasicModal from 'components/organisms/Modal/index'
import {
  LEGAL_ENTITY_TYPES,
  LEGAL_OTHER_ENTITY_DOCUMENT_TYPES,
} from 'helpers/const'
import { bindActionCreators } from 'redux'
import { fetchCorporateSanctionInformation } from 'redux/sanctionedCorporate/actions'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import { TablePlaceholder } from 'components/atoms/Table/styles'
import {
  createNaturalEntityRequest,
  updateNaturalEntityRequest,
  updateLinkedNaturalEntity,
  createLegalEntityRequest,
  updateLegalEntityRequest,
  getEntitiesForCorporate,
  linkEntityAndAddToNaturalEntities,
} from 'redux/corporateEntities/actions'
import { removeCorporateAddress } from 'redux/corporate/actions'
import ErrorToast from 'components/molecules/ErrorToast/index'

import { StyledEditEntityForm } from './Styles'
import { handleViewDocuments } from './DocumentsSection'

import CorporateAddress from '../components/CorporateAddress'
import NaturalCorporateEntities from '../components/CorporateEntities/NaturalCorporateEntities'
import AddEntityPage from '../components/CorporateEntities/Entity/AddEntityPage'
import CorporateAddressForm from '../forms/CorporateAddress/CorporateAddressForm'
import EntityForm from '../forms/EntityForm/index'
import LegalCorporateEntities from '../components/CorporateEntities/LegalCorporateEntities'
import { StyledCorporateAddressesWrapper } from '../forms/Styles'

const CorporateGeneralInfo = props => {
  const {
    corporateAccountData: {
      corporateAddressData: { data },
      isCheckingCorporateEditorRole,
      canEditCorporateInformation,
    },
    accountId,
  } = props

  const [entitiesCollectionName, setEntitiesCollectionName] = useState('')
  const [entityData, setEntityData] = useState({
    firsName: '',
    lastName: '',
    idNumber: '',
    roles: [],
    accountIdLinked: '',
    files: [],
    entityType: '',
    nationality: '',
    isPep: false,
  })
  const [showModal, setShowModal] = useState({
    type: '',
    open: false,
    title: '',
  })
  const [corporateAddressDetails, setCorporateAddressDetails] = useState({
    addressType: '',
    street: '',
    area: '',
    country: '',
    code: '',
  })
  const corporateAccountId = props.corporateAccountData.id
  const exposeCustomDocumentTypeFromOtherDocument = legalOtherEntities =>
    legalOtherEntities.map(entity => {
      const modifiedDocuments = entity.documents.map(document => {
        if (
          !document.documentType.includes(
            LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER,
          )
        ) {
          return document
        }
        const { documentType } = document
        const customDocumentType = documentType.split(':')[1]
        const modifiedDocumentType = LEGAL_OTHER_ENTITY_DOCUMENT_TYPES.OTHER
        return {
          ...document,
          customDocumentType,
          documentType: modifiedDocumentType,
        }
      })

      return { ...entity, documents: modifiedDocuments }
    })

  const closeModal = () => {
    setShowModal({ type: '', open: false, title: '' })
    setCorporateAddressDetails({
      addressType: '',
      street: '',
      area: '',
      country: '',
      code: '',
    })
    setEntityData({
      firsName: '',
      lastName: '',
      idNumber: '',
      roles: [],
      accountIdLinked: '',
      files: [],
      entityType: '',
      nationality: '',
      isPep: false,
    })
  }

  const handleEditCorporateAddress = address => {
    setCorporateAddressDetails(address)
    setShowModal({
      type: 'corporateAddressEdit',
      open: true,
      title: 'Edit corporate address',
    })
  }

  const handleEditEntity = (entity, entityType) => {
    const collectionName = entityType === 'NATURAL' ? entityType : 'LEGAL'
    setEntitiesCollectionName(collectionName)
    if (entity.roles === null) entity.roles = []
    setEntityData({
      ...entity,
      entityType,
      corporateAccountId: props.corporateAccountData.id,
      accountId: props.corporateAccountData.account_id,
      formType: 'EDIT',
      files: [],
    })
    setShowModal({ type: 'entity-edit', open: true, title: 'Edit Entity' })
  }

  const handleAddEntityClick = type => {
    if (type === 'NATURAL') {
      setShowModal({
        type: 'natural-entity-create',
        open: true,
        title: 'Create New Natural Entity',
      })
    }
    if (type === 'LEGAL') {
      setEntityData({
        name: '',
        registrationNumber: '',
        entityType: '',
        corporateAccountId: props.corporateAccountData.id,
        accountId: props.corporateAccountData.account_id,
        files: [],
      })
      setShowModal({
        type: 'legal-entity-create',
        open: true,
        title: 'Create New Legal Entity',
      })
    }

    setEntitiesCollectionName(type)
  }

  const onSubmit = async (values, uploadEntityFilesCallBack) => {
    if (!canEditCorporateInformation) {
      return ErrorToast('You are not authorized!')
    }
    const { entityType = '' } = values
    const isUpdatingExistingEntity = values.formType === 'EDIT'
    const isLegalEntityRequest =
      (entityType && entityType === LEGAL_ENTITY_TYPES.PTY) ||
      (entityType && entityType === LEGAL_ENTITY_TYPES.TRUST) ||
      (entityType && entityType === LEGAL_ENTITY_TYPES.OTHER)
    if (!isLegalEntityRequest) {
      if (isUpdatingExistingEntity) {
        if (values.linkedAccountId) {
          await props.updateLinkedNaturalEntity(values, accountId, closeModal)
        } else {
          await props.updateNaturalEntityRequest(
            values,
            uploadEntityFilesCallBack,
            accountId,
            closeModal,
          )
        }
      } else {
        await props.createNaturalEntityRequest(
          values,
          uploadEntityFilesCallBack,
          corporateAccountId,
          accountId,
          closeModal,
        )
      }
    }
    if (isLegalEntityRequest) {
      if (isUpdatingExistingEntity) {
        await props.updateLegalEntityRequest(
          values,
          uploadEntityFilesCallBack,
          accountId,
          closeModal,
        )
      } else {
        await props.createLegalEntityRequest(
          values,
          uploadEntityFilesCallBack,
          corporateAccountId,
          accountId,
          closeModal,
        )
      }
    }
    await props.fetchCorporateSanctionInformation(accountId)
  }

  return (
    <div>
      <Header bg="light" rounded title={'Natural Entities'} />
      <NaturalCorporateEntities
        accountId={accountId}
        entityType="NATURAL"
        corporateAccountId={props.corporateAccountData.id}
        handleEditEntity={data => handleEditEntity(data, 'NATURAL')}
        entities={props.naturalEntities}
        isFetchingEntities={props.isFetchingEntitiesForCorporate}
      />
      <Spacer margin="16px" />
      <MuiButton
        disabled={!canEditCorporateInformation || isCheckingCorporateEditorRole}
        onClick={() => handleAddEntityClick('NATURAL')}
      >
        Add Natural Entity
      </MuiButton>
      <Spacer margin="16px" />
      <Header bg="light" rounded={true} title={'Legal Entities'} />
      <ContentContainer bgcolor={'0px solid transparent'}>
        <h3>Pty Entities</h3>
        <LegalCorporateEntities
          accountId={accountId}
          entityType={LEGAL_ENTITY_TYPES.PTY}
          handleEditEntity={data =>
            handleEditEntity(data, LEGAL_ENTITY_TYPES.PTY)
          }
          entities={props.legalPtyEntities}
          isFetchingEntities={props.isFetchingEntitiesForCorporate}
        />
      </ContentContainer>
      <Spacer margin="8px 0px" />
      <ContentContainer bgcolor={'0px solid transparent'}>
        <h3>Trust Entities</h3>
        <LegalCorporateEntities
          accountId={accountId}
          entityType={LEGAL_ENTITY_TYPES.TRUST}
          handleEditEntity={data =>
            handleEditEntity(data, LEGAL_ENTITY_TYPES.TRUST)
          }
          entities={props.legalTrustEntities}
          isFetchingEntities={props.isFetchingEntitiesForCorporate}
        />
      </ContentContainer>
      <Spacer margin="8px 0px" />
      <ContentContainer bgcolor={'0px solid transparent'}>
        <h3>Other Legal Entities</h3>
        <LegalCorporateEntities
          accountId={accountId}
          entityType={LEGAL_ENTITY_TYPES.OTHER}
          handleEditEntity={data =>
            handleEditEntity(data, LEGAL_ENTITY_TYPES.OTHER)
          }
          entities={exposeCustomDocumentTypeFromOtherDocument(
            props.legalOtherEntities,
          )}
          isFetchingEntities={props.isFetchingEntitiesForCorporate}
        />
      </ContentContainer>
      <Spacer margin="16px" />
      <Spacer margin="16px" />
      <MuiButton
        disabled={!canEditCorporateInformation || isCheckingCorporateEditorRole}
        onClick={() => handleAddEntityClick('LEGAL')}
      >
        Add Legal Entity
      </MuiButton>
      <Header bg="light" rounded={true} title={'Corporate Address(s)'} />

      <ContentContainer bgcolor={'0px solid transparent'}>
        <StyledCorporateAddressesWrapper>
          {data.length === 0 && (
            <TablePlaceholder>
              {'This corporate has no registered address'}
            </TablePlaceholder>
          )}
          {data.map((address, index) => (
            <CorporateAddress
              canEditCorporateInformation={canEditCorporateInformation}
              handleEditCorporateAddress={handleEditCorporateAddress}
              deleteCorporateAddress={props.removeCorporateAddress}
              key={address.id}
              address={address}
              accountId={accountId}
            />
          ))}
        </StyledCorporateAddressesWrapper>
      </ContentContainer>
      <Spacer margin="16px" />
      <MuiButton
        disabled={!canEditCorporateInformation || isCheckingCorporateEditorRole}
        onClick={() => {
          setShowModal({
            type: 'address',
            open: true,
            title: 'Add Corporate Address',
          })
        }}
      >
        Add Address
      </MuiButton>
      <BasicModal
        handleClose={closeModal}
        buttonsHidden
        open={showModal.open}
        title={showModal.title}
        message=""
      >
        {(showModal.type === 'legal-entity-create' ||
          showModal.type === 'entity-edit') && (
          <StyledEditEntityForm>
            <EntityForm
              accountId={accountId}
              initialValues={{
                ...entityData,
              }}
              onSubmit={onSubmit}
              entitiesCollectionName={entitiesCollectionName}
              form={
                !showModal.type === 'entity-edit' ||
                showModal.type === 'legal-entity-create'
                  ? 'AddEntityForm'
                  : 'EditEntityForm'
              }
              onCancel={() => closeModal(false)}
            />
          </StyledEditEntityForm>
        )}
        {showModal.type === 'natural-entity-create' && (
          <AddEntityPage
            linkEntityAndAddToNaturalEntities={
              props.linkEntityAndAddToNaturalEntities
            }
            fetchCorporateSanctionInformation={
              props.fetchCorporateSanctionInformation
            }
            onSubmit={onSubmit}
            corporateAccountId={props.corporateAccountData.id}
            accountId={accountId}
            entitiesCollectionName={entitiesCollectionName}
            closeModal={closeModal}
          />
        )}
        {(showModal.type === 'address' ||
          showModal.type === 'corporateAddressEdit') && (
          <div style={{ width: 400 }}>
            <CorporateAddressForm
              formType={showModal.type}
              initialValues={{
                accountId,
                ...corporateAddressDetails,
              }}
              formActionType={showModal.type}
              form="AddCorporateAddressForm"
              onCancel={closeModal}
            />
          </div>
        )}
      </BasicModal>
    </div>
  )
}

CorporateGeneralInfo.propTypes = {
  corporateAccountData: PropTypes.shape({
    id: PropTypes.number,
    account_id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    purpose: PropTypes.string,
    nature_of_business: PropTypes.string,
    created_at: PropTypes.string,
    isLoading: PropTypes.bool,
    corporateAddressDetails: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.array,
    }),
  }),
  isLoading: PropTypes.bool,
  naturalEntities: PropTypes.array,
  legalPtyEntities: PropTypes.array,
  legalTrustEntities: PropTypes.array,
  legalOtherEntities: PropTypes.array,
  isFetchingEntitiesForCorporate: PropTypes.bool,
  isUpdatingEntity: PropTypes.bool,
}

const mapStateToProps = state => {
  const corporateAccountData = state.corporate
  const corporateEntitiesData = state.corporateEntities
  return {
    corporateAccountData,
    isLoading: corporateAccountData.isLoading,
    naturalEntities: corporateEntitiesData.naturalEntities,
    legalPtyEntities: corporateEntitiesData.legalPtyEntities,
    legalTrustEntities: corporateEntitiesData.legalTrustEntities,
    legalOtherEntities: corporateEntitiesData.legalOtherEntities,
    isFetchingEntitiesForCorporate:
      corporateEntitiesData.isFetchingEntitiesForCorporate,
    isUpdatingEntity: corporateEntitiesData.isUpdatingEntity,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createNaturalEntityRequest,
      updateNaturalEntityRequest,
      updateLinkedNaturalEntity,
      createLegalEntityRequest,
      updateLegalEntityRequest,
      getEntitiesForCorporate,
      linkEntityAndAddToNaturalEntities,
      removeCorporateAddress,
      fetchCorporateSanctionInformation,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateGeneralInfo)
