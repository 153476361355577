import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: {
    paddingBottom: 100,
  },
  letterhead: {
    margin: '15px',
  },
  statementTitle: {
    fontWeight: 'black',
    fontSize: 16,
    marginLeft: '15px',
  },
  statementDetails: {
    fontWeight: 800,
    fontSize: 10,
    margin: '20px 15px',
  },
  accountName: {
    fontSize: 12,
    margin: '15px 15px 0 15px',
  },
  right: {
    textAlign: 'right',
    fontSize: 10,
    margin: '15px',
    textDecoration: 'underline',
  },
  date: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  statementType: {
    color: 'white',
    backgroundColor: '#4871ec',
    margin: '0 15px',
    padding: '2px 5px',
    fontSize: 12,
    fontWeight: 800,
  },
  smallDisclaimer: {
    fontSize: 10,
    margin: '15px',
    marginBottom: '5px',
  },
  tableContainer: {
    margin: '15px',
    borderTop: '1 solid #ccc',
    borderLeft: '1 solid #ccc',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1 solid #ccc',
  },
  balTableCell: {
    flex: 1,
    padding: '6px',
    borderRight: '1 solid #ccc',
    textAlign: 'left',
    fontSize: 10,
  },
  balDarkTableCell: {
    flex: 1,
    padding: '6px',
    borderRight: '1 solid #ccc',
    textAlign: 'left',
    fontSize: 10,
    backgroundColor: '#eee',
  },
  txTableCell: {
    flex: 1,
    padding: '2px',
    borderRight: '1 solid #ccc',
    textAlign: 'left',
    fontSize: 10,
  },
  txDarkTableCell: {
    flex: 1,
    padding: '2px',
    borderRight: '1 solid #ccc',
    textAlign: 'left',
    backgroundColor: '#f1f1f1',
    fontSize: 10,
  },
  balTableHeader: {
    fontSize: 14,
    backgroundColor: '#e6e6e6',
    fontWeight: 900,
  },
  txTableHeader: {
    fontSize: 12,
    backgroundColor: '#e6e6e6',
    fontWeight: 900,
  },
  placeHolder: {
    flex: 1,
    flexDirection: 'row',
    padding: '8px',
    textAlign: 'center',
    fontSize: 12,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    borderTop: '1px solid #ddd',
    paddingTop: 5,
    fontSize: 8,
  },
  disclaimer: {
    marginLeft: 45,
    marginRight: 45,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 80,
    right: 20,
    fontSize: 8,
  },
})
