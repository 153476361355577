import React from 'react'
import {
  Details, InfoBlock, DetailsBlockWithTitle,
} from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { RED_COLOR_PAIR, GREEN_COLOR_PAIR } from 'theme/colors'
import { oppositeColor } from 'helpers/utils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as moment from 'moment'
import RenderStatuses from './index'

const ExpandedView = props => {
  const { tx } = props
  const debitValue = tx.type === 'DEBIT' ? `${tx.amount} ${tx.currency}` : '--'
  const creditValue = tx.type === 'CREDIT' ? `${tx.amount} ${tx.currency}` : '--'
  return (
    <TableRow className='no-hover'>
      <TableCell colSpan={7}>
        <Details>
          <DetailsBlockWithTitle title="Transaction Info">
            <InfoBlock
              label="Debited"
              value={debitValue}
              style={{ color: oppositeColor(tx.debitValue, RED_COLOR_PAIR), fontWeight: 'bold' }}
            />
            <InfoBlock
              label="Credited"
              value={creditValue}
              style={{ color: oppositeColor(tx.debitValue, GREEN_COLOR_PAIR), fontWeight: 'bold' }}
            />
            <InfoBlock label="Timestamp" value={moment(tx.timestamp).format('YYYY-MMM-DD HH:mm:ss')} />
          </DetailsBlockWithTitle>
        </Details>
        <Details>
          <DetailsBlockWithTitle title="Statuses">
            <RenderStatuses statuses={tx.statuses} />
          </DetailsBlockWithTitle>
        </Details>
        <DetailsBlockWithTitle title="Raw Data">
          <RawData data={tx} title={'Raw Data'} />
        </DetailsBlockWithTitle>
      </TableCell>
    </TableRow>
  )
}

export default ExpandedView
