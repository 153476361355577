import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import {
  FETCH_KEYCLOAK_ADMIN_GROUPS_ERROR,
  FETCH_KEYCLOAK_ADMIN_GROUPS_PROCESS,
  FETCH_KEYCLOAK_ADMIN_GROUPS_SUCCESS,
  KEYCLOAK_GROUPS_ERROR,
  KEYCLOAK_GROUPS_PROCESS,
  KEYCLOAK_GROUPS_SUCCESS,
} from './index'

export const fetchKeycloakAdminGroups = () => async (dispatch) => {
  dispatch({
    type: KEYCLOAK_GROUPS_PROCESS,
  })
  let res = null
  try {
    res = await getAxios().get(`/keycloak/admingroups`)
    const { data } = res
    dispatch({ type: KEYCLOAK_GROUPS_SUCCESS, payload: { data } })
  } catch (error) {
    dispatch({
      type: KEYCLOAK_GROUPS_ERROR,
    })
    toast(`${error && error.response ? error.response.data : error.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
export const fetchKeycloakAdminGroupsWithSubGroups = () => async (dispatch) => {
  dispatch({
    type: FETCH_KEYCLOAK_ADMIN_GROUPS_PROCESS,
  })
  try {
    const { data } = await getAxios().get(`/keycloak/groups-with-subgroups`)
    dispatch({ type: FETCH_KEYCLOAK_ADMIN_GROUPS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: FETCH_KEYCLOAK_ADMIN_GROUPS_ERROR,
    })
    toast(`Error fetching keycloak groups with subgroups.`, {
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    })
  }
}
