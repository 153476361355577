import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
  FormContainer,
  FormTitle,
  Button,
  InputGroup,
  Percentage,
} from 'components/atoms/Form/index'
import { required, isNumber, noSpacesAllowed } from 'forms/fieldValidators'
import { BASE } from 'theme/base-units'
import { renderSwitch, renderInput } from 'forms/fields'
import { submitUpdatedFees } from './submit'

const Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onCancel, onSubmit, question } = props
  const { title, isDefaultTradeFeesForm } = props
  return (
    <FormContainer borderRadius={8} onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>{title}</FormTitle>
      <InputGroup align={'flex-start'}>
        <Field
          label={'Maker Fee %'}
          name={'makerFee'}
          margin={`0px ${BASE * 2}px ${BASE * 2}px 0px`}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          endAdornment={<Percentage />}
          fullWidth
        />
        <Field
          label={'Taker Fee %'}
          name={'takerFee'}
          margin={`0px 0px ${BASE * 2}px 0`}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          endAdornment={<Percentage />}
          fullWidth
        />
      </InputGroup>
      <InputGroup align={'flex-start'}>
        <Field
          label={'Valr Instant Order Fee %'}
          name={'valrInstantOrderFee'}
          margin={`0px ${BASE * 2}px ${BASE * 2}px 0px`}
          component={renderInput}
          validate={[required, isNumber, noSpacesAllowed]}
          endAdornment={<Percentage />}
          fullWidth
        />
        {!isDefaultTradeFeesForm ?
          <Field
            label={'Foreign Exchange Instant Order Fee %'}
            name={'foreignExchangeInstantOrderFee'}
            margin={`0px 0px ${BASE * 2}px 0px`}
            component={renderInput}
            validate={[required, isNumber, noSpacesAllowed]}
            endAdornment={<Percentage />}
            fullWidth
          /> :
          <Field
            label={'Bittrex Instant Order Fee %'}
            name={'bittrexInstantOrderFee'}
            margin={`0px 0px ${BASE * 2}px 0px`}
            component={renderInput}
            validate={[required, isNumber, noSpacesAllowed]}
            endAdornment={<Percentage />}
            fullWidth
          />
        }
      </InputGroup>
      <InputGroup style={{
        width: '50%',
      }} align={'flex-start'}>
        {!isDefaultTradeFeesForm ?
          <Field
            label={'Foreign Exchange Fee %'}
            name={'foreignExchangeFee'}
            margin={'0px 8px 0px  0px'}
            component={renderInput}
            validate={[required, isNumber, noSpacesAllowed]}
            endAdornment={<Percentage />}
            fullWidth
          /> :
          <Field
            label={'Bittrex Fee %'}
            name={'bittrexFee'}
            margin={'0px 8px 0px 0px'}
            component={renderInput}
            validate={[required, isNumber, noSpacesAllowed]}
            endAdornment={<Percentage />}
            fullWidth
          />
        }
      </InputGroup>
      <InputGroup>
        <Field
          label={question}
          formatLabel={false}
          name={'confirmation'}
          type={'yes/no'}
          component={renderSwitch}
        />
      </InputGroup>
      <InputGroup justify={'flex-end'}>
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Submit
        </Button>
        <Button onClick={onCancel} color='secondary'>{isDefaultTradeFeesForm ? 'Cancel' : 'Close'}</Button>
      </InputGroup>
    </FormContainer>
  )
}

const ReduxForm = reduxForm({
  form: 'tradeFees',
  onSubmit: submitUpdatedFees,
  enableReinitialize: true,
  validate: values => {
    const errors = {}
    if (!values.confirmation) {
      errors.confirmation = 'Check this to confirm'
    }
    return errors
  },
})(Form)

const FormUpdateTradeFees = connect()(ReduxForm)

export default FormUpdateTradeFees
