import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ExpandingItem from '../../../../components/atoms/ExpandingItem'
import { ActionButton } from '../../../../components/atoms/Table/styles'
import { Details, DetailsBlockWithTitle } from '../../../../components/atoms/Details'
import { BASE } from '../../../../theme/base-units'
import { FullWidthBlock } from './styles'
import AddRequestedAccountAction from '../../../../forms/AddRequestedAccountAction'
import {
  clearRequestedAccountActions,
  fetchRequestedAccountActions,
} from '../../../../redux/requested-account-actions/actions'
import {
  RequestedAccountActionsState,
} from '../../../../redux/requested-account-actions'
import RequestedActionItems from './RequestedActionItems'

enum State {
  View = 1,
  Add = 2
}

interface IRequestedActionsProps {
  accountId: number,
  requestedAccountActions: RequestedAccountActionsState,
  fetchRequestedAccountActions: (accountId: number) => void,
  clearRequestedAccountActions: () => void,
}

const RequestedAccountActions = ({
  accountId,
  requestedAccountActions,
  fetchRequestedAccountActions: fetchActions,
  clearRequestedAccountActions: clearActions,
}: IRequestedActionsProps) => {
  const { data, status } = requestedAccountActions

  const DetailsAny = (Details as any)

  const [expanded, setExpanded] = useState(false)
  const [state, setState] = useState<State>(State.View)

  const toggleView = () => {
    setExpanded(!expanded)
  }

  const toggleForm = () => {
    setState(State.View)
  }
  const refreshAccountActions = () => {
    setState(State.View)
    fetchActions(accountId)
  }

  useEffect(() => {
    fetchActions(accountId)

    return () => {
      clearActions()
    }
  }, [])

  return (
    <DetailsAny marginBottom={`${BASE * 3}px`} direction={'column'}>
      <DetailsBlockWithTitle title="REQUESTED ACCOUNT ACTIONS">
        { state === State.Add && (
          <FullWidthBlock>
            <AddRequestedAccountAction
              initialValues={{ accountId }}
              form="AddRequestedAccountAction"
              onSubmitSuccess={refreshAccountActions}
              onCancel={toggleForm} />
          </FullWidthBlock>
        )}
        { state === State.View && (
          <>
            <FullWidthBlock>
              <ExpandingItem
                title={'REQUESTED ACCOUNT ACTIONS'}
                itemsAmount={status === 'loading' ? 'loading' : (data?.length || 0)}
                expanded={expanded}
                onExpand={toggleView}
                opensMultiple
                // @ts-ignore
                setColor={true}
                expandedView={<RequestedActionItems data={data} status={status} refresh={() => fetchActions(accountId)} />}/>
            </FullWidthBlock>
            <FullWidthBlock>
              <ActionButton variant="contained" color="primary" onClick={() => setState(State.Add)}>ADD REQUEST</ActionButton>
            </FullWidthBlock>
          </>
        )}
      </DetailsBlockWithTitle>
    </DetailsAny>)
}

const getRequestedAccountActions = (state): RequestedAccountActionsState => state.requestedAccountActions

const mapStateToProps = (state) => ({
  requestedAccountActions: getRequestedAccountActions(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRequestedAccountActions,
      clearRequestedAccountActions,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestedAccountActions)
