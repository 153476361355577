import React, { FC, useState, useMemo } from 'react'

import { connectStateAndDispatch, mapStateToProps } from 'store'
import Select from '@material-ui/core/Select'
import styled from 'styled-components'
import FilterListIcon from '@material-ui/icons/FilterList'
import { WHITE } from 'theme/colors'
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from 'components/atoms/Form'
import {
  fetchDepositByStatus,
  clearFundingDepositByStatus,
} from 'redux/fundingEvents/actions'
import SelectOuter from 'components/organisms/SearchForDepositByStatus/SelectOuter'
import { fundingStatuses } from './const'

import { ButtonContainer, DepositByStatusLayout } from './Styles'

interface DepositByStatusProps {
  fetchDepositByStatus: (currency: string, status: string) => Promise<void>
  depositByStatus: any
  clearFundingDepositByStatus: () => Promise<void>
  currency: string
  handleChange: any
  clearFilter: any
  selectedOption: string
}

const SearchDepositsByStatus: FC<DepositByStatusProps> = (props) => {
  const { handleChange, clearFilter, selectedOption } = props
  const [showSelect, setShowSelect] = useState(false)

  const showSelected = () => {
    setShowSelect(true)
  }

  const clear = () => {
    setShowSelect(false)
    clearFilter()
  }

  const currencySelection = useMemo(
    () =>
      fundingStatuses.map((c, i) => (
        <MenuItem key={i} value={c}>
          {c}
        </MenuItem>
      )),
    [fundingStatuses],
  )

  return (
    <>
      <DepositByStatusLayout>
        {showSelect ? (
          <>
            <SelectOuter label={'Filter'}>
              <Select
                value={selectedOption}
                id="status-select"
                style={{ width: '100%', maxWidth: '200px' }}
                onChange={({ target: { value } }) => {
                  handleChange(value)
                }}
              >
                {currencySelection}
              </Select>
            </SelectOuter>
            <ButtonContainer>
              <Button
                style={{ marginLeft: '8px' }}
                onClick={clear}
                color="secondary"
              >
                Cancel
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <ButtonContainer>
            <Button
              onClick={showSelected}
              startIcon={<FilterListIcon style={{ color: WHITE }} />}
            >
              Filter
            </Button>
          </ButtonContainer>
        )}
      </DepositByStatusLayout>
    </>
  )
}

const actions = {
  fetchDepositByStatus,
  clearFundingDepositByStatus,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    depositByStatus: state.fundingEvents.fundingEventsByStatus,
  })),
  actions,
  SearchDepositsByStatus,
)
