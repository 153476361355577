import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, Collapse, Box } from '@material-ui/core'
import { formatText, handleScroll } from 'helpers/utils'

import {
  StyledDetailsHeading,
  StyledExtraDataProperty,
  StyledTableRow,
} from './Styles'

/**
 * Insert text at cursor position.
 * @param {object} row Object of the row data
 * @param {array} headings headings and values follow the sequence of the headings
 * @param {array} customColumnHeadings will be any additional headings you want to show
 * @param {array{nodes{TableCell}}} customColumnValues will be any additional values you want to show with the corresponding
 *  headings you must provide a table cell for correct formatting
 * @param {string} detailsHeading A heading/title for the expanded row
 * @param {array} positions structure the positioning of your headings and values e.g ['center', 'left'] heading[0] & value[0] = center and heading[1] & value[1] = left
 * @param {boolean} showRawData To handle showing the raw data component of the row data
 * @param {node} customExpandData Is the custom data you wan to display which is a react node(some JSX) that you want to pass as a prop
 * @param {string} height the height of the expanded row data if needed to be customized
 * The Below is used to toggle one rows expanded value at a time and if you want to have multiple rows expanded you can leave this out and the default
 * isRowExpanded state will handle this for us
 * @param {string|number} expandedRowId a string or number to reference the expanded row
 * @param {function} onExpand A call back function that will set the expanded id to the row id
 */

const Row = ({
  row = {},
  headings = [],
  customColumnValues = [],
  detailsHeading = '',
  positions = [],
  showRawData,
  customExpandData = null,
  height = '',
  // If we want to handle individual rows expanded only one at a time we will need to use this
  // Other wise to use multiple we can leave this out and the isRowExpanded will handle everything for us
  expandedRowId,
  onExpand = () => {},
}) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false)
  const timeOutScroll = useRef(null)

  useEffect(
    () => {
      if (expandedRowId !== row.id && isRowExpanded) {
        setIsRowExpanded(false)
      }
    },
    [expandedRowId],
  )

  return (
    <>
      <StyledTableRow
        id="outerRow"
        onClick={() => {
          setIsRowExpanded(!isRowExpanded)
          onExpand(row.id)
          handleScroll('bottom', timeOutScroll.current, 320, 'smooth')
        }}
        key={row}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        height={height}
        className={isRowExpanded ? 'active' : ''}
      >
        {headings.map((heading, headingIndex) => (
          <TableCell
            id="cell"
            key={headingIndex}
            align={positions[headingIndex]}
          >
            {row[heading]}
          </TableCell>
        ))}
        {customColumnValues.map((cell, cellIndex) => (
          <Fragment key={cellIndex}>{cell(row)}</Fragment>
        ))}
      </StyledTableRow>

      <TableRow className="no-hover">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
            <StyledDetailsHeading variant="h6" gutterBottom component="div">
              {detailsHeading}
            </StyledDetailsHeading>
            {showRawData ? (
              <Box sx={{ margin: 1 }}>
                {Object.keys(row).map(
                  (key, i) =>
                    row[key] !== null && (
                      <p key={i}>
                        <StyledExtraDataProperty>
                          {formatText(key)}:{' '}
                        </StyledExtraDataProperty>
                        {row[key]}
                      </p>
                    ),
                )}
              </Box>
            ) : (
              <Box>{customExpandData}</Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  row: PropTypes.object,
  headings: PropTypes.array,
  customColumnValues: PropTypes.array,
  positions: PropTypes.array,
  detailsHeading: PropTypes.string,
  customExpandData: PropTypes.node,
  height: PropTypes.string,
  expandedRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onExpand: PropTypes.func,
}

export default Row
