import React, { FC, useEffect, useState } from 'react'
import getAxios from 'helpers/axios'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Flexbox } from 'components/layout'
import { AxiosResponse } from 'axios'
import LoadingBlock from 'components/molecules/LoadingBlock'
import ErrorToast from 'components/molecules/ErrorToast'
import {
  StyledDocumentContentWrapper,
  StyledImageWrapper,
} from 'containers/Accounts/SingleAccount/CorporateAccount/pages/Styles'
import { RemovedDocument } from './interfaces'
import { StyledDocumentsActionsContainer } from 'containers/Accounts/SingleAccount/CorporateAccount/components/Styles'
import { Title } from 'components/atoms/Title'
import IconButton from 'components/atoms/IconButton'
import CloseIcon from '@material-ui/icons/Close'

interface Document {
  document: RemovedDocument
  canRestoreDocument: boolean
  restoringDocument: boolean
  getDocumentBufferData: (documentId: number) => Promise<AxiosResponse>
  restoreDocument: () => Promise<void>
  handleCloseDocumentViewer: () => void;
}

export const RestoringDocumentView: FC<Document> = ({
  document,
  canRestoreDocument,
  restoringDocument,
  getDocumentBufferData,
  restoreDocument,
  handleCloseDocumentViewer,
}) => {
  const [isFetchingDocument, setIsFetchingDocument] = useState(false)

  const [url, setUrl] = useState('')
  let blob: Blob

  function previewSelectedPdfFile(src) {
    return <embed src={src} width="100%" height="350" />
  }

  const handleGetDocument = async () => {
    setIsFetchingDocument(true)
    try {
      const { data } = await getDocumentBufferData(document.id)

      if (data.file && data.file.data) {
        const type = data.file.fileType || 'img'
        blob = new Blob([new Uint8Array(data.file.data)], {
          type,
        })
        setUrl(URL.createObjectURL(blob))
      }
    } catch (error) {
      ErrorToast(error)
    }
    setIsFetchingDocument(false)
  }

  useEffect(() => {
    handleGetDocument()
  }, [])
  return (
    <StyledDocumentContentWrapper>
      {isFetchingDocument ? (
        <LoadingBlock message="Fetching Document..." />
      ) : (
        <StyledImageWrapper>
          <StyledDocumentsActionsContainer>
              <Title type="h3" text={document.fileName.split(']}')[1]} />
              {/* @ts-ignore */}
              <IconButton
                onClick={handleCloseDocumentViewer}
                icon={<CloseIcon />}
              />
            </StyledDocumentsActionsContainer>
          {url && (
            <>
              <img src={url} alt="img" />
              {document.documentType === 'application/pdf' &&
                previewSelectedPdfFile(url)}
              <Flexbox width="100%" direction="row" justify="space-between">
                <div>
                  <p>{document.createdAt}</p>
                </div>
                <MuiButton
                  disabled={!canRestoreDocument || restoringDocument}
                  onClick={restoreDocument}
                >
                  Restor
                  {restoringDocument ? 'ing' : 'e'} document
                </MuiButton>
              </Flexbox>
            </>
          )}
        </StyledImageWrapper>
      )}
    </StyledDocumentContentWrapper>
  )
}
