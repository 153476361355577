import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic/index'
import SanctionsPage from 'containers/Accounts/AccountLists/Sanctions'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import RiskProfileManagement from 'containers/Compliance/RiskProfileManagement'
import CorporateProfileManagement from './RiskProfileManagement/Corporate'
import PEPScreening from './pepScreening'
import ComplianceAdminConfig from './ComplianceAdminConfig'

const routes = [
  '/compliance/sanctions',
  '/compliance/pep-screening',
  '/compliance/risk-profiles/management/risk-scoring/individual',
  '/compliance/risk-profiles/management/risk-scoring/corporate',
  '/compliance/admin/config',
]

const ComplianceView = (props) => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }
  useEffect(() => {
    let pathname = routes[0]
    if (window.location.pathname.includes('pep-screening')) {
      pathname = routes[1]
    }
    if (window.location.pathname.includes('individual')) {
      pathname = routes[2]
    }
    if (window.location.pathname.includes('corporate')) {
      pathname = routes[3]
    }

    if (window.location.pathname.includes('config')) {
      pathname = routes[4]
    }
    handleChange(null, pathname)

    return () => {}
  }, [])

  return (
    <>
      <h1>Compliance</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          to={routes[0]}
          component={ForwardedNavLink}
          label="Sanctions"
        />
        <NavigationTab
          value={routes[1]}
          to={routes[1]}
          component={ForwardedNavLink}
          label="PEP screening"
        />

        <NavigationTab
          value={`${routes[2]}`}
          to={`${routes[2]}`}
          component={ForwardedNavLink}
          label="Individual Risk Profile Management"
        />
        <NavigationTab
          value={`${routes[3]}`}
          to={`${routes[3]}`}
          component={ForwardedNavLink}
          label="Corporate Risk Profile Management"
        />
        <NavigationTab
          value={`${routes[4]}`}
          to={`${routes[4]}`}
          component={ForwardedNavLink}
          label="Admin Compliance Config"
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={SanctionsPage} />
          <Route path={routes[1]} component={PEPScreening} />
          <Route path={routes[2]} component={RiskProfileManagement} />
          <Route path={routes[3]} component={CorporateProfileManagement} />
          <Route path={routes[4]} component={ComplianceAdminConfig} />
        </Switch>
      </ContentContainer>
    </>
  )
}

export default ComplianceView
