import React, { ChangeEvent, useState } from 'react'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ROLES, checkRole } from 'helpers/roleBasedAccess'
import MarketingContacts from './MarketingContacts'
import MobileNotifications from './MobileNotifications/MobileNotifications'

type TabTypes = 'marketing-contacts' | 'mobile-notifications'

const Marketing = () => {
  const [currentTab, setCurrentTab] = useState<TabTypes>('mobile-notifications')
  const handleChange = (e: ChangeEvent, value: TabTypes) => {
    setCurrentTab(value)
  }
  const hasRole = checkRole(ROLES.marketingMobileMessages)
  return (
    <div>
      {hasRole ? (
        <>
          <TopNavigationBar
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
          >
            <NavigationTab
              label="Mobile Notifications"
              value={'mobile-notifications'}
            />
            <NavigationTab label="Sendgrid" value={'marketing-contacts'} />
          </TopNavigationBar>
          {currentTab === 'marketing-contacts' ? (
            <MarketingContacts />
          ) : (
            <MobileNotifications />
          )}
        </>
      ) : (
        <MarketingContacts />
      )}
    </div>
  )
}

export default Marketing
