import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as moment from 'moment'
import BigNumber from 'bignumber.js'
import { NO_RESULT_MESSAGE } from 'helpers/const'
import { TablePagination } from '@material-ui/core'
import { renderPlural } from 'helpers/utils'

import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
} from 'components/atoms/Table/styles'
import { Button, InputGroup } from 'components/atoms/Form'
import ExpandingItem from 'components/atoms/ExpandingItem'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { resolveFiatWithdraws, markAsFailed } from 'redux/fiat/actions'
import NoTableResultsMessage from 'components/molecules/NoTableResultsMessage/index'

import UnresolvedFiatWithdrawalsRow from './row'

import { getSymbolFromCurrency } from '../../../helpers/currency'
import DownloadBankInstructions from '../DownloadBankInstructions'
import { getSelectedBankAccount } from '../../../redux/fiat/selectors'
import { ConfirmationDialog } from '../ConfirmationDialog'

const findWithAttr = (array, attr, value) => {
  let ret = -1
  array.forEach((obj, index) => {
    if (obj[attr] === value) {
      ret = index
    }
  })
  return ret
}

class UnresolvedFiatWithdrawals extends Component {
  constructor(props) {
    super(props)
    this.withdrawResolvedChecked = this.withdrawResolvedChecked.bind(this)
    this.resolveCheckedWithdraws = this.resolveCheckedWithdraws.bind(this)
    this.onFeeChange = this.onFeeChange.bind(this)

    this.state = {
      selectedFiatWithdrawalsToResolve: [],
      expandedItem: '',
      showRawData: '',
      highlightedId: '',
      page: 0,
      rowsPerPage: 50,
      resolveFiatWithdrawsModalOpen: false,
    }
  }

  async resolveCheckedWithdraws() {
    await this.props.resolveFiatWithdraws(
      this.state.selectedFiatWithdrawalsToResolve,
    )
    this.setState({
      selectedFiatWithdrawalsToResolve: [],
    })
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  withdrawResolvedChecked(withdraw) {
    const { withdrawalId, accountId } = withdraw
    this.setState(prev => {
      const updatedWithdrawalsToResolve = [
        ...this.state.selectedFiatWithdrawalsToResolve,
      ]
      const index = findWithAttr(
        updatedWithdrawalsToResolve,
        'withdrawalId',
        withdrawalId,
      )
      if (index > -1) {
        updatedWithdrawalsToResolve.splice(index, 1)
        this.setState({ highlightedId: '' })
      } else {
        updatedWithdrawalsToResolve.push({
          status: 'SUCCESS_NO_FEES',
          accountId,
          withdrawalId,
        })
      }
      return {
        ...prev,
        selectedFiatWithdrawalsToResolve: updatedWithdrawalsToResolve,
      }
    })
  }

  expandItem = id => {
    const expanded = this.state.expandedItem
    if (expanded !== id) {
      this.setState({ expandedItem: id })
    } else {
      this.setState({ expandedItem: '' })
    }
  }

  toggleRawData = id => {
    const { showRawData } = this.state
    if (showRawData !== id) {
      this.setState({ showRawData: id })
    } else {
      this.setState({ showRawData: '' })
    }
  }

  countAllWithdrawals = () => {
    const {
      unresolvedWithdraws: { data },
    } = this.props

    const counter = data
      ? data.reduce((accum, value) => accum + Number(value.amount), 0)
      : 0

    return `${new BigNumber(counter).toFormat(2)}`
  }

  getSelectedWithdrawals = data =>
    data
      ? data.filter(
        withdrawal =>
          !!this.state.selectedFiatWithdrawalsToResolve.find(
            resolvedWithdrawal =>
              resolvedWithdrawal.withdrawalId === withdrawal.withdrawalId,
          ),
      )
      : []

  setRowsPerPage = rowsPerPage => {
    this.setState({
      rowsPerPage,
    })
  }

  setPage = page => {
    this.setState({
      page,
    })
  }

  toggleModal = () => {
    this.setState(prev => ({
      ...prev,
      resolveFiatWithdrawsModalOpen: !prev.resolveFiatWithdrawsModalOpen,
    }))
  }

  onFeeChange(id, amount) {
    this.setState(prev => {
      const updatedSelectedWithdrawalsToResolve = [
        ...prev.selectedFiatWithdrawalsToResolve,
      ]
      const index = findWithAttr(
        updatedSelectedWithdrawalsToResolve,
        'withdrawalId',
        id,
      )
      if (index > -1) {
        updatedSelectedWithdrawalsToResolve[index] = {
          ...updatedSelectedWithdrawalsToResolve[index],
          status: amount > 0 ? 'SUCCESS' : 'SUCCESS_NO_FEES',
          fee: amount > 0 ? amount : undefined,
        }
      }
      return {
        ...prev,
        selectedFiatWithdrawalsToResolve: updatedSelectedWithdrawalsToResolve,
      }
    })
  }

  handleChangePage = (event, newPage) => {
    this.setPage(newPage)
  }

  handleChangeRowsPerPage = event => {
    this.setRowsPerPage(parseInt(event.target.value, 10))
    this.setPage(0)
  }

  render() {
    const { selectedFiatWithdrawalsToResolve, page, rowsPerPage } = this.state
    const {
      name,
      unresolvedWithdraws: { status, data },
      activeUser,
      setExpand,
      expanded,
      unresolvedWithdraws,
      markAsFailed,
      bankAccount: { currency, canCheckout },
    } = this.props
    const hasWithdrawals = data && data.length > 0
    return (
      <>
        <ExpandingItem
          title={`${name}`}
          itemsAmount={(data && data.length) || 0}
          decoration={`${getSymbolFromCurrency(
            currency,
          )}${this.countAllWithdrawals()}`}
          expanded={expanded}
          onExpand={setExpand}
          expandedView={
            <>
              {status === 'loading' && <LoadingBlock />}
              {status === 'done' &&
                !hasWithdrawals && (
                <NoTableResultsMessage
                  messageType={NO_RESULT_MESSAGE.WITHDRAWALS.type}
                />
              )}
              {status === 'done' &&
                hasWithdrawals && (
                <Table>
                  <TableHeader>
                    <TableCell flex={0.45}>Account Id</TableCell>
                    <TableCell flex={1.2}>Bank Account Holder</TableCell>
                    <TableCell flex={1.2}>Bank</TableCell>
                    <TableCell flex={1} align={'right'}>
                        Amount
                    </TableCell>
                    <TableCell flex={0.8} align={'right'}>
                        Currency
                    </TableCell>
                    <TableCell flex={1} align={'right'}>
                        Date
                    </TableCell>
                    <TableCell
                      flex={0.7}
                      align={'right'}
                      paddingRight={'10px'}
                    >
                        Resolved
                    </TableCell>
                    <TableCell flex={0.5} />
                  </TableHeader>
                  <TableBody>
                    {data
                      .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                      .map(withdraw => {
                        const index = findWithAttr(
                          selectedFiatWithdrawalsToResolve,
                          'withdrawalId',
                          withdraw.withdrawalId,
                        )
                        const fee =
                            index > -1
                              ? selectedFiatWithdrawalsToResolve[index].fee ===
                                undefined
                                ? 0
                                : selectedFiatWithdrawalsToResolve[index].fee
                              : 0
                        withdraw.updatedAt = moment(
                          withdraw.updatedAt,
                        ).format('YYYY-MMM-DD HH:mm:ss')
                        return (
                          <UnresolvedFiatWithdrawalsRow
                            key={withdraw.withdrawalId}
                            withdraw={withdraw}
                            checked={index > -1}
                            onWithdrawCheck={this.withdrawResolvedChecked}
                            fee={fee}
                            onFeeChange={this.onFeeChange}
                            expandItem={this.expandItem}
                            expanded={this.state.expandedItem}
                            markAsFailed={markAsFailed}
                            activeUser={activeUser}
                            showRawData={
                              this.state.showRawData === withdraw.withdrawalId
                            }
                            toggleRawData={this.toggleRawData}
                            isHighlighted={
                              this.state.highlightedId ===
                                withdraw.withdrawalId
                            }
                            setHighlightedId={this.setHighlightedId}
                          />
                        )
                      })}
                    <TablePagination
                      component="div"
                      count={data.length}
                      page={page}
                      onChangePage={this.handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableBody>
                  {status === 'done' && (
                    <InputGroup justify={'flex-end'}>
                      {!unresolvedWithdraws.loading &&
                          canCheckout && (
                        <DownloadBankInstructions
                          withdrawals={this.getSelectedWithdrawals(data)}
                          bankAccount={this.props.bankAccount}
                        />
                      )}
                      {!unresolvedWithdraws.loading && (
                        <Button
                          onClick={this.toggleModal}
                          type="button"
                          main="true"
                          disabled={
                            this.state.selectedFiatWithdrawalsToResolve
                              .length < 1 || this.props.resolvingWithdrawals
                          }
                        >
                            Resolve
                        </Button>
                      )}
                      {unresolvedWithdraws.loading && <LoadingBlock />}
                    </InputGroup>
                  )}
                </Table>
              )}
            </>
          }
        />
        <ConfirmationDialog
          title={'Resolve Withdrawals'}
          message={`You are about to resolve ${
            this.state.selectedFiatWithdrawalsToResolve.length
          } withdrawal${renderPlural(
            this.state.selectedFiatWithdrawalsToResolve.length,
          )}. Would you like to proceed?`}
          open={this.state.resolveFiatWithdrawsModalOpen}
          proceed={async () => {
            await this.resolveCheckedWithdraws()
            this.toggleModal()
          }}
          handleClose={this.toggleModal}
          disabled={
            this.props.resolvingWithdrawals ||
            this.state.selectedFiatWithdrawalsToResolve.length < 1
          }
          button={{
            confirm: {
              text: `Proceed${this.props.resolvingWithdrawals ? 'ing' : ''}`,
            },
            decline: {
              text: 'Cancel',
            },
          }}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  unresolvedWithdraws: state.fiat.unresolvedWithdraws,
  resolvingWithdrawals: state.fiat.resolveWithdraw.loading,
  bankAccount: getSelectedBankAccount(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resolveFiatWithdraws,
      markAsFailed,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnresolvedFiatWithdrawals)
