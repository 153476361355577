export const DEFAULT_RESCAN_DEPTH = 10
export const DEFAULT_RESCAN_TIMEOUT = 1000 * 60 * 5 // 5 minutes
export const TABLE_MAX_PAGES = 3
export const TABLE_ITEMS_PER_PAGE = 10

export const EXCHANGE_DEPOSIT_TYPES = {
  depositForReview: 'DEPOSITS FOR REVIEW',
  potentialDuplicates: 'POTENTIAL DUPLICATES',
  unknownReferences: 'UNKNOWN REFERENCES',
  blackListed: 'BLACKLISTED',
  fallBackImport: 'FALL BACK IMPORT',
  suspended: 'SUSPENDED',
  rejectedDualAuth: 'REJECTED DUAL AUTH',
  newDeposits: 'NEW DEPOSITS',
  sanctions: 'SANCTIONS',
}

export const EXCHANGE_STATUS_TYPES = {
  depositForReview: 'DEPOSITS FOR REVIEW',
  potentialDuplicates: 'POTENTIAL DUPLICATES',
  unknownReferences: 'UNKNOWN REFERENCE',
  blackListed: 'BLACKLISTED',
  fallBackImport: 'FALL BACK IMPORT',
  suspended: 'SUSPENDED',
  rejectedDualAuth: 'REJECTED DUAL AUTH',
  newDeposits: 'NEW DEPOSITS',
}

export const TIME_OUT_TRANSITION = 200

const buildOptionObjectForSelect = (option) => ({
  value: option,
  label: option,
})

const DEFAULT_SUSPEND_COMMENTS = [
  buildOptionObjectForSelect('3rd Party deposit'),
  buildOptionObjectForSelect('Unclaimed deposit'),
  buildOptionObjectForSelect('Potential scam'),
]

export const COMMENT_MESSAGES = {
  parachute: {
    REJECT: [
      buildOptionObjectForSelect('No selfie'),
      buildOptionObjectForSelect('Only one side of the ID document'),
      buildOptionObjectForSelect('Documents not clear'),
    ],
    ACCEPT: [buildOptionObjectForSelect('Profile clear')],
  },
  EXCHANGE: {
    FIAT_DEPOSITS_FOR_REVIEW: {
      APPROVE: [
        buildOptionObjectForSelect('Profile clear'),
        buildOptionObjectForSelect('Zendesk'),
        buildOptionObjectForSelect('Trusted client'),
        buildOptionObjectForSelect('Corporate Account'),
      ],
      SUSPEND: DEFAULT_SUSPEND_COMMENTS,
      IGNORE: [
        buildOptionObjectForSelect('Recall request approved'),
        buildOptionObjectForSelect('Failed withdrawal, funds already reversed'),
      ],
    },
    FIAT_POTENTIAL_DUPLICATES: {
      APPROVE: [
        buildOptionObjectForSelect('Profile clear'),
        buildOptionObjectForSelect('Zendesk'),
        buildOptionObjectForSelect('Real deposit - Reflects in bank'),
        buildOptionObjectForSelect('Identified based on reference'),
        buildOptionObjectForSelect('Identified based on phone number'),
        buildOptionObjectForSelect('Trusted Client'),
        buildOptionObjectForSelect('Identified based on referral code'),
        buildOptionObjectForSelect('Identified based on email'),
        buildOptionObjectForSelect(
          "Failed withdrawal.Return funds to client's account",
        ),
      ],
      SUSPEND: DEFAULT_SUSPEND_COMMENTS,
      IGNORE: [
        buildOptionObjectForSelect('Recall request approved'),
        buildOptionObjectForSelect('Failed withdrawal, funds already reversed'),
        buildOptionObjectForSelect('Fallback Import'),
      ],
    },
    FIAT_UNKNOWN_REFERENCES: {
      APPROVE: [
        buildOptionObjectForSelect('Profile clear'),
        buildOptionObjectForSelect('Zendesk'),
        buildOptionObjectForSelect('Identified based on reference'),
        buildOptionObjectForSelect('Identified based on phone number'),
        buildOptionObjectForSelect('Trusted  Client'),
        buildOptionObjectForSelect('Identified based on referral code'),
        buildOptionObjectForSelect('Identified based on email'),
        buildOptionObjectForSelect(
          "Failed withdrawal. Return funds to client's account",
        ),
      ],
      SUSPEND: DEFAULT_SUSPEND_COMMENTS,
      IGNORE: [
        buildOptionObjectForSelect('Recall request approved'),
        buildOptionObjectForSelect('Failed withdrawal, funds already reversed'),
        buildOptionObjectForSelect('Fallback import'),
        buildOptionObjectForSelect('Credit card settlement'),
      ],
    },
    FIAT_FALL_BACK_IMPORT: {
      APPROVE: [
        buildOptionObjectForSelect('Profile clear'),
        buildOptionObjectForSelect('Zendesk'),
        buildOptionObjectForSelect('Trusted client'),
      ],
      SUSPEND: DEFAULT_SUSPEND_COMMENTS,
      IGNORE: [
        buildOptionObjectForSelect('Recall request approved'),
        buildOptionObjectForSelect('Failed withdrawal, funds already reversed'),
        buildOptionObjectForSelect('Potential scam'),
      ],
    },
    FIAT_WITHDRAWALS_UNDER_REVIEW: {
      APPROVE: [buildOptionObjectForSelect('Profile clear')],
      SUSPEND: [],
      DECLINE: [
        buildOptionObjectForSelect('3rd party bank account'),
        buildOptionObjectForSelect('Waiting for credit card statement'),
      ],
    },
    FIAT_SUSPENDED_WITHDRAWALS: {
      APPROVE: [buildOptionObjectForSelect('Profile clear')],
      SUSPEND: [],
      DECLINE: [
        buildOptionObjectForSelect('3rd party bank account'),
        buildOptionObjectForSelect('Waiting for credit card statement'),
      ],
    },
    USD_DEPOSITS_UNDER_REVIEW: [buildOptionObjectForSelect('Profile clear')],
    USD_WITHDRAWALS_UNDER_REVIEW: {
      APPROVE: [buildOptionObjectForSelect('Profile clear')],
      SUSPEND: [],
      DECLINE: [buildOptionObjectForSelect('3rd party bank account')],
    },
    creditCard: {
      ACCEPT: [{ value: 'Profile clear', label: 'Profile clear' }],
    },
  },
}

export const EXCHANGE_COMMENT_TYPES = {
  approve: 'APPROVE',
  ignore: 'IGNORE',
  suspend: 'SUSPEND',
  decline: 'DECLINE',
}

export const SUPPORT_TEAM_ALIASES = [
  // Gianluca Sacco George Clarke
  // Driena Massyn Petro Myburg
  // Bonolo Thomas Dumi Nkosi
  // Karabo Nemakonde Zak Hewitt
  // Thapelo Rampolokeng Randy Kevlar
  { alias: 'George Clarke', initials: 'GS' },
  { alias: 'Petro Myburg', initials: 'DM' },
  { alias: 'Dumi Nkosi', initials: 'BT' },
  { alias: 'Zak Hewitt', initials: 'KN' },
  { alias: 'Randy Kevlar', initials: 'TR' },
]
export const FIAT_WITHDRAWALS_ACTIONS = {
  reverse: 'reverse',
  checkout: 'manual checkout',
}

export const BORROW_WEIGHT = 0.5

export const UPDATE_CURRENCY_TYPES = {
  active: 'active',
  borrow: 'borrow',
}

export const NATURAL_ENTITY_DOCUMENT_TYPES = {
  ID: 'id_document',
  PROOF_OF_RESIDENCE: 'proof_of_residence_document',
}
export const LEGAL_ENTITY_DOCUMENT_TYPES = {
  REGISTRATION: 'registration_document',
}

export const LEGAL_TRUST_ENTITY_DOCUMENT_TYPES = {
  LETTER_OF_AUTHORITY: 'letter_of_authority_document',
  REGISTRATION: 'registration_document',
}

export const LEGAL_OTHER_ENTITY_DOCUMENT_TYPES = {
  LETTER_OF_AUTHORITY: 'letter_of_authority_document',
  REGISTRATION: 'registration_document',
  OTHER: 'other_document',
}

export const LEGAL_ENTITY_TYPES = {
  PTY: 'PTY',
  TRUST: 'TRUST',
  OTHER: 'OTHER',
}

export const ENTITY_TYPES = {
  PTY: 'legalPtyEntities',
  NATURAL: 'naturalEntities',
  TRUST: 'legalTrustEntities',
  OTHER: 'legalOtherEntities',
}

export const ACCOUNT_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE',
  TRUSTED_CUSTOMER: 'TRUSTED_CUSTOMER',
}

export const UNRESOLVED_FIAT_DEPOSIT_TITLES = {
  DEPOSITS_FOR_REVIEW: 'DEPOSITS FOR REVIEW',
  POTENTIAL_DUPLICATES: 'POTENTIAL DUPLICATES',
  UNKNOWN_REFERENCES: 'UNKNOWN REFERENCES',
  BLACKLISTED: 'BLACKLISTED',
  FALL_BACK_IMPORT: 'FALL BACK IMPORT',
  SUSPENDED: 'SUSPENDED',
  REJECTED_DUAL_AUTH: 'REJECTED DUAL AUTH',
}

export const DEPOSIT_STATUSES = {
  AUTHORISATION_REJECTED: 'AUTHORISATION_REJECTED',
  POTENTIAL_DUPLICATE: 'POTENTIAL_DUPLICATE',
  BLACKLISTED: 'BLACKLISTED',
  UNKNOWN_REFERENCE: 'UNKNOWN_REFERENCE',
  FALLBACK_IMPORT: 'FALLBACK_IMPORT',
  INVALID_DESCRIPTION: 'INVALID_DESCRIPTION',
}

export const NO_RESULT_MESSAGE = {
  DEPOSITS: {
    type: 'DEPOSITS',
    message: 'No deposits to show.',
  },
  WITHDRAWALS: {
    type: 'WITHDRAWALS',
    message: 'No withdrawals to show.',
  },
  SUSPENDED_DEPOSITS: {
    type: 'SUSPENDED_DEPOSITS',
    message: 'No suspended deposits to show.',
  },
}

export const CURRENCY_MAP = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNH: '¥',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYI: 'UYI',
  UYU: '$U',
  UYW: 'UYW',
  UZS: 'лв',
  VEF: 'Bs',
  VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  XSU: 'Sucre',
  XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  ZWD: 'Z$',
  ZWL: '$',
}

type TRANSACTION_HISTORY_FILTER_LABELS =
  // SIMPLE
  | 'SIMPLE BUY'
  | 'SIMPLE SWAP BUY'
  | 'SIMPLE SELL'
  | 'SIMPLE SWAP SELL'
  | 'SIMPLE SWAP REVERSAL'
  // LIMIT
  | 'LIMIT BUY'
  | 'LIMIT SELL'
  // LIQUIDATION
  | 'LIQUIDATION LIMIT BUY'
  | 'LIQUIDATION LIMIT SELL'
  | 'LIQUIDATION LIMIT SELL'
  | 'LIQUIDATION TAKEOVER ASSET TRANSFER'
  | 'LIQUIDATION TAKEOVER DEBT TRANSFER'
  // AUTO
  | 'AUTO BUY'
  | 'AUTO SELL'
  // MARKET
  | 'MARKET BUY'
  | 'MARKET SELL'
  // PAYMENT
  | 'PAYMENT SENT'
  | 'PAYMENT RECEIVED'
  | 'PAYMENT REVERSED'
  | 'PAYMENT REWARD'
  | 'PAYMENT FINALIZED'
  // REFERRAL
  | 'REFERRAL REBATE'
  | 'REFERRAL REWARD'
  // REWARD
  | 'REWARDS'
  // TRANSFERS
  | 'TRANSFERS'
  // WITHDRAW
  | 'WITHDRAW'
  | 'FIAT WITHDRAWAL REVERSAL'
  | 'FIAT WITHDRAWAL'
  // DEPOSIT
  | 'DEPOSITS'
  | 'FIAT DEPOSIT'
  // FUND
  | 'FUND'
  // BLOCKCHAIN
  | 'BLOCKCHAIN RECEIVES'
  | 'BLOCKCHAIN SENDS'
  // PLATFORM
  | 'PLATFORM FEE RECEIVED'
  // MAKER
  | 'MAKER FEE PAYED'
  | 'MAKER REWARD'
  // OTHER
  | 'REBATES'
  // RECEIVE
  | 'RECEIVE'
  // SEND
  | 'SEND'
  // PROMOTIONAL
  | 'PROMOTIONAL'
  // OFF CHAIN
  | 'OFF CHAIN WITHDRAWAL'
  | 'OFF CHAIN BLOCKCHAIN WITHDRAW'
  | 'OFF CHAIN DEPOSIT'
  | 'OFF CHAIN BLOCKCHAIN DEPOSIT'
  // WIRE
  | 'WIRE DEPOSIT'
  | 'WIRE WITHDRAWAL'
  | 'WIRE WITHDRAWAL FEE'
  // CREDIT CARD
  | 'CREDIT CARD DEPOSIT'
  // SPOT
  | 'SPOT BORROW AMOUNT'
  | 'SPOT BORROW INTEREST CHARGE'
  | 'SPOT LEND INTEREST PAYMENT'
  | 'SPOT LEND PLATFORM INTEREST PAYMENT'
  // ACCOUNT
  | 'ACCOUNT FUNDING'
  | 'ACCOUNT FUNDING FEE'
  // FUTURES
  | 'FUTURES FUNDING PAID'
  | 'FUTURES FUNDING EARNED'
  | 'FUTURES PNL PROFIT'
  | 'FUTURES PNL LOSS'
  | 'FUTURES TRADE FEE'
  | 'FUTURES PNL HOLDING TRANSFER'
  | 'FUTURES FUNDING ROUNDING REMAINDER'

type TRANSACTION_HISTORY_FILTER_TYPES =
  // SIMPLE
  | 'simple_buy'
  | 'simple_swap_buy'
  | 'simple_sell'
  | 'simple_swap_sell'
  | 'simple_swap_reversal'
  // LIMIT
  | 'limit_buy'
  | 'limit_sell'
  // LIQUIDATION
  | 'liquidation_limit_buy'
  | 'liquidation_limit_sell'
  | 'liquidation_takeover_asset_transfer'
  | 'liquidation_takeover_debt_transfer'
  // AUTO
  | 'auto_buy'
  | 'auto_sell'
  // MARKET
  | 'market_buy'
  | 'market_sell'
  // PAYMENT
  | 'payment_sent'
  | 'payment_received'
  | 'payment_reversed'
  | 'payment_reward'
  | 'payment_finalized'
  // REFERRAL
  | 'referral_rebate'
  | 'referral_reward'
  // REWARD
  | 'reward'
  // TRANSFERS
  | 'transfer'
  // WITHDRAW
  | 'withdraw'
  | 'fiat_withdrawal_reversal'
  | 'fiat_withdrawal'
  // DEPOSIT
  | 'deposit'
  | 'fiat_deposit'
  // FUND
  | 'fund'
  // BLOCKCHAIN
  | 'blockchain_receive'
  | 'blockchain_send'
  // PLATFORM
  | 'platform_fee_received'
  // MAKER
  | 'maker_fee_payed'
  | 'maker_reward'
  // OTHER
  | 'rebate'
  // RECEIVE
  | 'receive'
  // SEND
  | 'send'
  // PROMOTIONAL
  | 'promotional'
  // OFF CHAIN
  | 'off_chain_withdrawal'
  | 'off_chain_blockchain_withdraw'
  | 'off_chain_deposit'
  | 'off_chain_blockchain_deposit'
  // WIRE
  | 'wire_deposit'
  | 'wire_withdrawal'
  // CREDIT CARD
  | 'credit_card_deposit'
  // SPOT
  | 'spot_borrow_amount'
  | 'spot_borrow_interest_charge'
  | 'spot_lend_interest_payment'
  | 'spot_lend_platform_interest_payment'
  // ACCOUNT
  | 'account_funding'
  | 'account_funding_fee'
  // Futures
  | 'futures_funding_paid'
  | 'futures_funding_earned'
  | 'futures_pnl_profit'
  | 'futures_pnl_loss'
  | 'futures_trade_fee'
  | 'futures_pnl_holding_transfer'
  | 'futures_funding_rounding_remainder'

type TRANSACTION_CHECKBOXES = {
  label: TRANSACTION_HISTORY_FILTER_LABELS
  type: TRANSACTION_HISTORY_FILTER_TYPES
}[]

export const TRANSACTION_HISTORY_FILTERS: TRANSACTION_CHECKBOXES = [
  {
    label: 'SIMPLE SWAP BUY',
    type: 'simple_swap_buy',
  },
  {
    label: 'SIMPLE BUY',
    type: 'simple_buy',
  },
  {
    label: 'SIMPLE SWAP SELL',
    type: 'simple_swap_sell',
  },
  {
    label: 'SIMPLE SELL',
    type: 'simple_sell',
  },
  {
    label: 'SIMPLE SWAP REVERSAL',
    type: 'simple_swap_reversal',
  },
  {
    label: 'LIMIT BUY',
    type: 'limit_buy',
  },
  {
    label: 'LIMIT SELL',
    type: 'limit_sell',
  },
  {
    label: 'LIQUIDATION LIMIT BUY',
    type: 'liquidation_limit_buy',
  },
  {
    label: 'LIQUIDATION LIMIT SELL',
    type: 'liquidation_limit_sell',
  },
  {
    label: 'LIQUIDATION TAKEOVER ASSET TRANSFER',
    type: 'liquidation_takeover_asset_transfer',
  },
  {
    label: 'LIQUIDATION TAKEOVER DEBT TRANSFER',
    type: 'liquidation_takeover_debt_transfer',
  },
  {
    label: 'AUTO SELL',
    type: 'auto_sell',
  },
  {
    label: 'AUTO BUY',
    type: 'auto_buy',
  },
  {
    label: 'ACCOUNT FUNDING',
    type: 'account_funding',
  },
  {
    label: 'MARKET BUY',
    type: 'market_buy',
  },
  {
    label: 'MARKET SELL',
    type: 'market_sell',
  },
  {
    label: 'MAKER FEE PAYED',
    type: 'maker_fee_payed',
  },
  {
    label: 'MAKER REWARD',
    type: 'maker_reward',
  },
  {
    label: 'PAYMENT SENT',
    type: 'payment_sent',
  },
  {
    label: 'PAYMENT RECEIVED',
    type: 'payment_received',
  },
  {
    label: 'PAYMENT REVERSED',
    type: 'payment_reversed',
  },
  {
    label: 'PAYMENT FINALIZED',
    type: 'payment_finalized',
  },
  {
    label: 'PAYMENT REWARD',
    type: 'payment_reward',
  },
  {
    label: 'REFERRAL REBATE',
    type: 'referral_rebate',
  },
  {
    label: 'REWARDS',
    type: 'reward',
  },
  {
    label: 'REBATES',
    type: 'rebate',
  },
  {
    label: 'RECEIVE',
    type: 'receive',
  },
  {
    label: 'REFERRAL REWARD',
    type: 'referral_reward',
  },
  {
    label: 'TRANSFERS',
    type: 'transfer',
  },
  {
    label: 'DEPOSITS',
    type: 'deposit',
  },
  {
    label: 'FIAT DEPOSIT',
    type: 'fiat_deposit',
  },
  {
    label: 'FUND',
    type: 'fund',
  },
  {
    label: 'BLOCKCHAIN RECEIVES',
    type: 'blockchain_receive',
  },
  {
    label: 'BLOCKCHAIN SENDS',
    type: 'blockchain_send',
  },
  {
    label: 'PLATFORM FEE RECEIVED',
    type: 'platform_fee_received',
  },
  {
    label: 'PROMOTIONAL',
    type: 'promotional',
  },
  {
    label: 'CREDIT CARD DEPOSIT',
    type: 'credit_card_deposit',
  },
  {
    label: 'OFF CHAIN DEPOSIT',
    type: 'off_chain_deposit',
  },
  {
    label: 'OFF CHAIN WITHDRAWAL',
    type: 'off_chain_withdrawal',
  },
  {
    label: 'OFF CHAIN BLOCKCHAIN WITHDRAW',
    type: 'off_chain_blockchain_withdraw',
  },
  {
    label: 'OFF CHAIN BLOCKCHAIN DEPOSIT',
    type: 'off_chain_blockchain_deposit',
  },
  {
    label: 'SEND',
    type: 'send',
  },
  {
    label: 'SPOT BORROW AMOUNT',
    type: 'spot_borrow_amount',
  },
  {
    label: 'SPOT BORROW INTEREST CHARGE',
    type: 'spot_borrow_interest_charge',
  },
  {
    label: 'SPOT LEND INTEREST PAYMENT',
    type: 'spot_lend_interest_payment',
  },
  {
    label: 'SPOT LEND PLATFORM INTEREST PAYMENT',
    type: 'spot_lend_platform_interest_payment',
  },
  {
    label: 'WIRE DEPOSIT',
    type: 'wire_deposit',
  },
  {
    label: 'WIRE WITHDRAWAL',
    type: 'wire_withdrawal',
  },
  {
    label: 'WITHDRAW',
    type: 'withdraw',
  },
  {
    label: 'FIAT WITHDRAWAL REVERSAL',
    type: 'fiat_withdrawal_reversal',
  },
  {
    label: 'FIAT WITHDRAWAL',
    type: 'fiat_withdrawal',
  },
  {
    label: 'FUTURES FUNDING PAID',
    type: 'futures_funding_paid',
  },
  {
    label: 'FUTURES FUNDING EARNED',
    type: 'futures_funding_earned',
  },
  {
    label: 'FUTURES PNL PROFIT',
    type: 'futures_pnl_profit',
  },
  {
    label: 'FUTURES PNL LOSS',
    type: 'futures_pnl_loss',
  },
  {
    label: 'FUTURES TRADE FEE',
    type: 'futures_trade_fee',
  },
  {
    label: 'FUTURES PNL HOLDING TRANSFER',
    type: 'futures_pnl_holding_transfer',
  },
  {
    label: 'FUTURES FUNDING ROUNDING REMAINDER',
    type: 'futures_funding_rounding_remainder',
  },
]

interface TRANSACTION_HISTORY_FILTER_CHECKBOX {
  label: TRANSACTION_HISTORY_FILTER_LABELS
  type: TRANSACTION_HISTORY_FILTER_TYPES
}

export const TRANSACTION_HISTORY_FILTERS_OPTIONS_SORTED: TRANSACTION_HISTORY_FILTER_CHECKBOX[] =
  TRANSACTION_HISTORY_FILTERS.sort((a, b) => (a.label > b.label ? 1 : -1))

export const TRANSACTION_HISTORY_FILTERS_CHECKBOX_OPTIONS =
  TRANSACTION_HISTORY_FILTERS.map(
    (filter: TRANSACTION_HISTORY_FILTER_CHECKBOX) => filter.label,
  )
