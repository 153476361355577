import React, { useState } from 'react'

import { DetailsBlockWithTitle } from 'components/atoms/Details'
import FormUpdateTradeFeesPerCurrencyPair from 'forms/UpdateTradeFees'
import { TablePlaceholder } from 'components/atoms/Table/styles'

import { StyledFeesPerCurrencyPairWrapper } from '../Styles'
import CustomTable from '../../../components/atoms/CustomTable'
import Row from '../../../components/atoms/CustomTable/Row'

const FeesPerCurrencyPair = ({ feesPerCurrencyPair }) => {
  const [expandedRowId, setExpandedRowId] = useState('')
  if (feesPerCurrencyPair.length === 0) {
    return (
      <TablePlaceholder margin="0px 24px 0px 0px">
        No Active Trade Fees For Currency Pairs
      </TablePlaceholder>
    )
  }

  const headings = [
    'currencyPairSymbol',
    'makerFee',
    'takerFee',
    'valrInstantOrderFee',
    'foreignExchangeInstantOrderFee',
    'foreignExchangeFee',
  ]
  return (
    <StyledFeesPerCurrencyPairWrapper>
      <DetailsBlockWithTitle title="Trade Fees Per Currency Pair">
        <CustomTable
          headings={headings}
          customRows={feesPerCurrencyPair.sort((a, b) => (a.currencyPairSymbol > b.currencyPairSymbol ? 1 : -1)).map((tradeFees, index) => (
            <Row
              onExpand={setExpandedRowId}
              expandedRowId={expandedRowId}
              key={index}
              row={{ ...tradeFees, id: index }}
              headings={headings}
              detailsHeading={`${tradeFees.currencyPairSymbol}`}
              height="50px"
              customExpandData={
                <FormUpdateTradeFeesPerCurrencyPair
                  onCancel={() => setExpandedRowId('')}
                  form={`${index}${tradeFees.currencyPairSymbol}form}`}
                  title={`Update ${tradeFees.currencyPairSymbol
                    } Trade Fees (0.03% = 3bps)`}
                  initialValues={{
                    ...tradeFees,
                    confirmation: false,
                    isDefaultTradeFeesForm: false,
                  }}
                  onSubmitSuccess={() => setExpandedRowId('')}
                  question={`Do you want to update fees for ${tradeFees.currencyPairSymbol
                    }?`}
                  isDefaultTradeFeesForm={false}
                />
              }
            />
          ))}
          showRawData={false}
        />
      </DetailsBlockWithTitle>
    </StyledFeesPerCurrencyPairWrapper>
  )
}

export default FeesPerCurrencyPair
