import React, { Component, Fragment } from 'react'
import {
  Details,
  DetailsBlockWithTitle,
  DetailsBlock,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import { BASE } from 'theme/base-units'
import { ActionButton } from 'components/atoms/Table/styles'
import * as moment from 'moment'
import { oppositeColor } from 'helpers/utils'
import OtherAccounts from './OtherAccounts'
import LinkedAccounts from './LinkedAccounts'

class Auth0Info extends Component {
  constructor(props) {
    super(props)

    this.state = {
      otherAccountsShown: false,
      linkedAccountsShown: false,
    }

    this.toggleOtherAccountsView = this.toggleOtherAccountsView.bind(this)
    this.toggleLinkedAccountsView = this.toggleLinkedAccountsView.bind(this)
  }

  toggleOtherAccountsView() {
    this.setState({
      otherAccountsShown: !this.state.otherAccountsShown,
    })
  }

  toggleLinkedAccountsView() {
    this.setState({
      linkedAccountsShown: !this.state.linkedAccountsShown,
    })
  }

  render() {
    const { data, id } = this.props
    const { status, info } = data
    const { otherAccountsShown, linkedAccountsShown } = this.state
    return (
      <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
        <DetailsBlockWithTitle title={'Auth0 Info'} flow={'column'}>
          {!info && status !== 'loading' && <p>No Auth Info</p>}
          {status === 'loading' && <IconLoading />}
          {status === 'error' && (
            <InfoBlock
              label={'Fetching Error'}
              value={`Code ${info.error.errorCode}: ${info.error.message}`}
            />
          )}
          {status === 'auth0Error' && (
            <InfoBlock label={'Fetching Error'} value={`${data.data}`} />
          )}
          {status === 'done' && (
            <Fragment>
              <DetailsBlock flow={'row'}>
                <InfoBlock
                  label={'User Id'}
                  value={
                    (info.identities && info.identities[0].user_id) || '--'
                  }
                />
                <InfoBlock
                  label={'Provider'}
                  value={
                    (info.identities && info.identities[0].provider) || '--'
                  }
                />
                <InfoBlock label={'Email'} value={info.email || '--'} />
                <InfoBlock
                  label={'Created At'}
                  value={
                    moment(info.created_at).format('YYYY-MMM-DD HH:mm:ss') ||
                    '--'
                  }
                />
                <InfoBlock
                  label={'Last Login'}
                  value={
                    moment(info.last_login).format('YYYY-MMM-DD HH:mm:ss') ||
                    '--'
                  }
                />
                <InfoBlock
                  label={'Linked Accounts'}
                  value={
                    (info.identities && info.identities.length - 1) || '--'
                  }
                />
                <InfoBlock
                  label={'Status'}
                  color={oppositeColor(!info.blocked)}
                  value={
                    (status === 'done' && info.blocked
                      ? 'blocked'
                      : 'not blocked') || '--'
                  }
                />
              </DetailsBlock>
              <JsonBlock src={info} title={'Raw Data'} />
              <DetailsBlock flow={'row'}>
                {!otherAccountsShown && (
                  <ActionButton onClick={this.toggleOtherAccountsView}>
                    View Other Accounts
                  </ActionButton>
                )}
                {!linkedAccountsShown && (
                  <ActionButton onClick={this.toggleLinkedAccountsView}>
                    View Linked Accounts
                  </ActionButton>
                )}
              </DetailsBlock>
            </Fragment>
          )}
          {otherAccountsShown &&
            status === 'done' && (
              <OtherAccounts
                user={info}
                accountId={id}
                onCancel={this.toggleOtherAccountsView}
              />
            )}
          {linkedAccountsShown &&
            status === 'done' && (
              <LinkedAccounts
                accountId={id}
                onCancel={this.toggleLinkedAccountsView}
              />
            )}
        </DetailsBlockWithTitle>
      </Details>
    )
  }
}

export default Auth0Info
