import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  TopNavigationBar,
  NavigationTab,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import DynamicCurrencies from 'components/organisms/DynamicCurrencies/index'
import PlatformBalances from 'containers/Exchange/PlatformBalances'
import TradeFees from 'containers/TradeFees'
import AccountBalanceSummary from 'containers/Exchange/AccountBalanceSummary'

const routes = [
  '/finance/summary-balances',
  '/finance/balances',
  '/finance/fees',
  '/finance/currencies',
]

const Finance = props => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(
    () => {
      if (pathname.includes('currency-pairs')) return setCurrentPage(routes[3])
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
    },
    [pathname],
  )
  return (
    <>
      <h1>Finance</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="Summary Balances"
          component={ForwardedNavLink}
          to={routes[0]}
        />
        <NavigationTab
          value={routes[1]}
          label="Platform Balances"
          component={ForwardedNavLink}
          to={routes[1]}
        />

        <NavigationTab
          value={routes[2]}
          label="Fees"
          component={ForwardedNavLink}
          to={routes[2]}
        />
        <NavigationTab
          value={routes[3]}
          label="Dynamic Currencies"
          component={ForwardedNavLink}
          to={routes[3]}
        />
      </TopNavigationBar>

      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={AccountBalanceSummary} exact />
          <Route path={routes[1]} component={PlatformBalances} />
          <Route path={routes[2]} component={TradeFees} />
          <Route path={routes[3]} component={DynamicCurrencies} />
        </Switch>
      </ContentContainer>
    </>
  )
}

export default Finance
