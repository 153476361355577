import { createSelector } from 'reselect'
import { getActiveAccount } from './ui'

export const getAddress = state => state.address.byId

export const getAccountAddress = createSelector([getAddress, getActiveAccount], (address, id) => {
  if (!id || !address[id]) {
    return {
      status: 'pristine',
      data: [],
    }
  }

  return address[id]
})
