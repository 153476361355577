import ErrorToast from 'components/molecules/ErrorToast/index'
import getAxios from 'helpers/axios'

export const FETCH_REFERRAL_LEVEL_PROCCESS = 'FETCH_REFERRAL_LEVEL_PROCCESS'
export const FETCH_REFERRAL_LEVEL_SUCCESS = 'FETCH_REFERRAL_LEVEL_SUCCESS'
export const FETCH_REFERRAL_LEVEL_ERROR = 'FETCH_REFERRAL_LEVEL_ERROR'

export const fetchRebatesAndReferrals = (req) => async dispatch => {
  dispatch({ type: FETCH_REFERRAL_LEVEL_PROCCESS })
  let res = null
  try {
    res = await getAxios().get(`/generic/${encodeURIComponent(req.path)}`)
    const { data } = res
    const rebateData=data.body
    dispatch({
      type: FETCH_REFERRAL_LEVEL_SUCCESS,
      payload: {
        data:rebateData,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_REFERRAL_LEVEL_ERROR,
      payload: {
        error,
      },
    })
  }
}
