import React, { Fragment } from 'react'
import {
  Details,
  DetailsBlockWithTitle,
  DetailsBlock,
  InfoBlock,
  JsonBlock,
} from 'components/atoms/Details'
import IconLoading from 'components/atoms/IconLoading'
import { ActionButton, Actions } from 'components/atoms/Table/styles'
import { BASE } from 'theme/base-units'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import * as moment from 'moment'
import {
  ContentContainer,
  Spacer,
  StyledAnchor,
} from 'components/atoms/Generic/index'
import { Title } from 'components/atoms/Title/index'
import NoData from 'components/molecules/NoData/index'
import { Button } from 'components/atoms/Form/index'

const allowKycRetry = async id => {
  try {
    const res = await getAxios().post(`/account/${id}/kyc/retry`)
    toast(res.data.message, { type: toast.TYPE.SUCCESS, autoClose: 3000 })
  } catch (e) {
    toast(e.response.data.message, { type: toast.TYPE.ERROR, autoClose: 3000 })
  }
}

const renderOnfidoChecks = info => {
  const { checkStatus, applicantCheckCount, checkCreatedAt, checkResult } = info
  const formatedCheckCreatedAt = checkCreatedAt ? moment(checkCreatedAt).format(
    'YYYY-MMM-DD HH:mm:ss',
  ) : '--'
  return (
    <Fragment>
      <InfoBlock label={'Check Status'} value={checkStatus || '--'} />
      <InfoBlock
        label={'Check Created'}
        value={formatedCheckCreatedAt || '--'}
      />
      <InfoBlock label={'Check Count'} value={applicantCheckCount || '--'} />
      <InfoBlock label={'Check Result'} value={checkResult || '--'} />
    </Fragment>
  )
}

const renderOnfidoButton = info => {
  const { checkData, checkStatus } = info
  var onfidoResults = checkStatus === 'complete' && JSON.parse(checkData)
  return (
    <>
      {!onfidoResults &&
        !onfidoResults.results_uri && <Button disabled>View in Onfido</Button>}
      {onfidoResults &&
        onfidoResults.results_uri && (
          <StyledAnchor
            href={`${onfidoResults && onfidoResults.results_uri
              ? onfidoResults.results_uri
              : ''
              }`}
            target="_blank"
          >
            <ActionButton>View in Onfido</ActionButton>
          </StyledAnchor>
        )}
    </>
  )
}

const KYCInfo = props => {
  const { status, info, submitted } = props.data
  const { description } = props.kyc.info
  const { applicantCheckCount } = info
  const { errorCode, message } = status === 'error' && info.error

  return (
    <Details direction={'column'} marginBottom={`${BASE * 3}px`}>
      <DetailsBlockWithTitle title={'Verification Info'} flow={'row'} />
      <ContentContainer bgcolor={'0px solid transparent'}>
        <Title type="h3" text="Onfido account information" />
        <DetailsBlock flow={'row'}>
          {status === 'loading' && <IconLoading />}
          {status === 'error' && (
            <InfoBlock
              label={'Fetching Error'}
              value={`Code ${errorCode}: ${message}`}
            />
          )}
          {submitted &&
            status === 'done' && (
              <Fragment>
                {renderOnfidoChecks(info)}
                <Actions justify={'space-between'}>
                  {renderOnfidoButton(info)}
                  {applicantCheckCount > 0 &&
                    description === 'Semi-verified' && (
                      <ActionButton
                        onClick={() => allowKycRetry(info.accountId)}
                      >
                        Allow KYC Retry
                      </ActionButton>
                    )}
                </Actions>
              </Fragment>
            )}
          {!submitted && (
            <NoData
              height={100}
              messageSpacing={1}
              hasMagnifier={false}
              headingType={'h4'}
              headingText={'Not submitted'}
              message={'This user has not submitted Onfido information'}
            />
          )}
        </DetailsBlock>
        <Spacer margin="10px 0px" />
        <DetailsBlock>
          <JsonBlock overflow={'scroll'} src={info} title={'Raw Data'} />
        </DetailsBlock>
      </ContentContainer>
    </Details>
  )
}

export default KYCInfo
