import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getValuesFromSearch } from 'helpers/utils'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import { withStyles } from '@material-ui/core/styles'

import { ContentContainer, Spacer } from 'components/atoms/Generic'
import { renderInput as RenderInput } from 'forms/fields'
import { EnhancedTableHead } from './tableHead'
import SearchForm from 'forms/SearchAccount/index'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  Actions,
  StyledLink,
  TablePlaceholder,
} from 'components/atoms/Table/styles'
import { Title } from 'components/atoms/Title'
import TablePagination from '@material-ui/core/TablePagination'
import Paper from '@material-ui/core/Paper'
import { styled } from '@material-ui/core/styles'
import { StyledSearchedAccountsRow } from '../AccountLists/Styles'
import { useStyles, searchAccountTableSort } from 'helpers/tables'

class SearchAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      filter: '',
      page: 0,
      rowsPerPage: 50,
      order: 'desc',
      orderBy: 'accountId',
    }
  }

  handleChangePage = (e, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: parseInt(e.target.value), page: 0 })
  }

  navigateToAccount = (accountId) => {
    this.props.history.push(`/accounts/byId/${accountId}/details`)
  }

  handleFilter = (value) => {
    this.setState({
      filter: value,
    })
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc'
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property })
  }

  filterData = (data) =>
    data.filter((item) => {
      const {
        info: { name, surname },
      } = item
      if (
        name
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase()) ||
        surname
          .toString()
          .toLowerCase()
          .includes(this.state.filter.toLowerCase())
      ) {
        return item
      }
    })

  renderAccounts() {
    const { accountCorporatesResults, foundSearchedAccounts } =
      this.props.accounts
    const tablePaginationCount = foundSearchedAccounts.length
    const { rowsPerPage, page } = this.state
    const Container = styled(TableContainer)({
      maxHeight: 1400,
    })
    const classes = withStyles(useStyles)
    return (
      <Fragment>
        <Actions justify="flex-end" margin="10px 0">
          <RenderInput
            value={this.state.filter}
            marginRight={0}
            onChange={(event) => this.handleFilter(event.target.value)}
            meta={{}}
            label={'Filter Name or Surname'}
          />
        </Actions>
        {foundSearchedAccounts.length === 0 ? (
          <TablePlaceholder>No accounts found</TablePlaceholder>
        ) : (
          <>
            <Paper>
              <Container>
                <Table
                  className={classes.table}
                  aria-label="sticky table"
                  stickyHeader={true}
                >
                  <EnhancedTableHead
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {searchAccountTableSort(this.filterData(foundSearchedAccounts), this.state.order, this.state.orderBy)
                      .slice(page * rowsPerPage, rowsPerPage * (page + 1))
                      .map((item) => {
                        const { info, reference } = item
                        const {
                          accountId,
                          name,
                          surname,
                          cellNumber,
                          email,
                          identityNumber,
                          corporateName,
                          kycLevel,
                          isAddressVerified,
                        } = info
                        return (
                          <StyledSearchedAccountsRow
                            onClick={() => this.navigateToAccount(accountId)}
                            key={email}
                          >
                            <TableCell>{accountId}</TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>{surname}</TableCell>
                            <TableCell>{cellNumber}</TableCell>
                            <TableCell>{email}</TableCell>
                            <TableCell>
                              {info.depositReference.reference ||
                                reference ||
                                '--'}
                            </TableCell>
                            <TableCell>{identityNumber || '--'}</TableCell>
                            <TableCell>{kycLevel || '--'}</TableCell>
                            <TableCell>
                              {isAddressVerified}
                            </TableCell>
                            <TableCell>{corporateName}</TableCell>
                          </StyledSearchedAccountsRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </Container>
            </Paper>
            <TablePagination
              component="div"
              count={tablePaginationCount}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </>
        )}
        {accountCorporatesResults.length === 0 ? (
          <TablePlaceholder>No Corporate Results found</TablePlaceholder>
        ) : (
          <Spacer margin="12px 0px">
            <ContentContainer bgcolor="transparent">
              <Title
                type="h3"
                text="Corporate Accounts Linked To Searched Account"
              />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Corporate Name</TableCell>
                    <TableCell>Linked Entity ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accountCorporatesResults.map((corp) => (
                    <StyledSearchedAccountsRow
                      onClick={() => this.navigateToAccount(corp.account_id)}
                      key={corp.id}
                    >
                      <TableCell>
                        <StyledLink>{corp.account_id}</StyledLink>
                      </TableCell>
                      <TableCell>{corp.name}</TableCell>
                      <TableCell>
                        {corp.linked_account_id ? (
                          <MuiButton
                            onClick={(e) => {
                              e.stopPropagation()
                              this.navigateToAccount(corp.linked_account_id)
                            }}
                          >
                            {corp.linked_account_id}
                          </MuiButton>
                        ) : (
                          '--'
                        )}
                      </TableCell>
                    </StyledSearchedAccountsRow>
                  ))}
                </TableBody>
              </Table>
            </ContentContainer>
          </Spacer>
        )}
      </Fragment>
    )
  }

  renderContent() {
    const {
      location: { search },
    } = this.props
    const { accounts } = this.props
    const index = search.slice(1)
    const data = accounts.byQuery[index]
    if (data) {
      const { status } = data
      switch (status) {
        case 'done':
          return this.renderAccounts(data)
        case 'loading':
          return <LoadingBlock />
        default:
          return null
      }
    }
    return null
  }

  render() {
    const {
      history,
      location: { search },
    } = this.props
    const urlValues = getValuesFromSearch(search)
    return (
      <Fragment>
        <SearchForm history={history} initialValues={urlValues} />
        {this.renderContent()}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  accounts: state.accounts,
})

export default connect(mapStateToProps)(SearchAccount)
