import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { removeReleasedFiatDeposits } from 'redux/fiat/actions'

const RELEASE_FIAT_DEPOSITS = 'releaseFiat/RELEASE_FIAT_DEPOSITS'
const RELEASE_FIAT_DEPOSITS_FAILED = 'releaseFiat/RELEASE_FIAT_DEPOSITS_FAILED'
const LOADING = 'releaseFiat/loading'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING: {
      return { ...state, loading: true, success: false }
    }

    case RELEASE_FIAT_DEPOSITS: {
      return { ...state, loading: false, success: true }
    }

    case RELEASE_FIAT_DEPOSITS_FAILED: {
      return {
        ...state, error: action.error, loading: false, success: false,
      }
    }

    default:
      return state
  }
}

export const releaseFiatDeposits = (data) => async dispatch => {
  const { selectedBulkDeposits, formData: { comment } } = data
  const depositIds = selectedBulkDeposits.map(deposit => deposit.depositId)
  dispatch({ type: LOADING })
  try {
    await getAxios().post('/fiat/deposit/review/release', { depositIds, comment })
    dispatch({ type: RELEASE_FIAT_DEPOSITS })
    toast('Deposits Released', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    dispatch(removeReleasedFiatDeposits(depositIds))
  } catch (e) {
    dispatch({ type: RELEASE_FIAT_DEPOSITS_FAILED, error: e.response.data })
  }
}
