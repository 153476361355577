import React from 'react'
import { PANEL_BLUE } from 'theme/colors'
import IconLoading from 'components/atoms/IconLoading'
import { FontColor } from 'components/atoms/Generic'
import { Container } from './styles'

const LoadingBlock = props => (
  <Container {...props}>
    <IconLoading />
    <FontColor>
      <span>{props.message || 'Loading data, please wait...'}</span>
    </FontColor>
  </Container>
)

export const UploadingBlock = props => (
  <Container {...props}>
    <IconLoading />
    <FontColor>
      <span>Uploading data, please wait...</span>
    </FontColor>
  </Container>
)

export default LoadingBlock
