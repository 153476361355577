

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { fetchUserTicketsByEmail } from 'redux/getUserTicketsByEmail/actions'
import IconLoading from 'components/atoms/IconLoading'
import TableCell from '@material-ui/core/TableCell'
import TicketsRow from './ticketsRow'

class Tickets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedId: -1,
      highlightedId: ''
    }
  }

  toggleSelectedRow = id => {
    if (this.state.selectedId === id) {
      this.setState({ selectedId: -1 })
    } else {
      this.setState({ selectedId: id })
    }
  }

  setHighlightedId = id => {
    this.setState({ highlightedId: id })
  }

  componentDidMount() {
    this.props.fetchUserTicketsByEmail(this.props.email)
  }

  render() {
    const {
      usersTickets: { status, data }
    } = this.props
    return (
      <Fragment>
        {status === 'loading' && (
          <TableCell colSpan={12}>
            <TablePlaceholder>
              <IconLoading />
              <span>Loading, please wait</span>
            </TablePlaceholder>
          </TableCell>
        )}
        {status === 'done' && data.length === 0 &&
          <TableCell colSpan={12}>
            <TablePlaceholder>
              <span>No tickets for this user</span>
            </TablePlaceholder>
          </TableCell>
        }
        {status === 'done' && data.length > 0 &&
          data.map((ticket) => (
            <TicketsRow
              key={ticket.id}
              ticket={ticket}
              toggleSelected={this.toggleSelectedRow}
              showForm={this.state.selectedId === ticket.id}
              setHighlightedId={this.setHighlightedId}
              isHighlighted={this.state.highlightedId === ticket.id}
              email={this.props.email}
            />
          ))
        }

      </Fragment >
    )
  }
}


const mapStateToProps = state => ({
  usersTickets: state.allUserTickets,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserTicketsByEmail,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tickets)
