import { Table, TableBody, TableCell, TableHead } from '@material-ui/core'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { match } from 'interfaces'
import React, { FC, useEffect, useState } from 'react'
import { fetchAccountOpenFuturesPositions } from 'redux/trading/actions'
import { connectStateAndDispatch, mapStateToProps } from 'store'
import OpenFuturesPositionRow from './OpenFuturesPositionRow'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import { OpenFuturesPosition } from 'interfaces/openFuturesPositions'
interface IOpenFuturesPositions {
  fetchAccountOpenFuturesPositions: (
    accountId: string,
  ) => Promise<OpenFuturesPosition[]>
  match: match<{ accountId: string }>
  openFuturesPositions: {
    loading: boolean
    // TODO: fix type for futures positions list
    data: OpenFuturesPosition[]
  }
}
const OpenFuturesPositions: FC<IOpenFuturesPositions> = (props) => {
  const [expandedItem, setExpandedItem] = useState<number | null>(null)
  useEffect(() => {
    props.fetchAccountOpenFuturesPositions(props.match.params.accountId)

    return () => {}
  }, [])
  if (props.openFuturesPositions.loading)
    return <LoadingBlock message="Fetching account open futures positions..." />
  if (props.openFuturesPositions.data.length === 0)
    return <TablePlaceholder>No open futures positions found.</TablePlaceholder>
  return (
    <Table>
      <TableHead>
        <TableCell>Pair</TableCell>
        <TableCell>Side</TableCell>
        <TableCell>Quantity</TableCell>
        <TableCell>Realized P/L</TableCell>
        <TableCell>UnRealized P/L</TableCell>
        <TableCell>Total Session Entry Quantity</TableCell>
        <TableCell>Created At</TableCell>
        <TableCell>Updated At</TableCell>
      </TableHead>
      <TableBody>
        {props.openFuturesPositions.data.map((o) => (
          <OpenFuturesPositionRow
            key={o.id}
            expandedItem={expandedItem}
            setExpandedItem={setExpandedItem}
            openFuturesPosition={o}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const actions = {
  fetchAccountOpenFuturesPositions,
}

export default connectStateAndDispatch(
  mapStateToProps((state) => ({
    openFuturesPositions: state.trading.openFuturesPositions,
  })),
  actions,
  OpenFuturesPositions,
)
