import { renderPlural } from 'helpers/utils'

const CONFIRMATION_TYPES = {
  deposit: 'deposit',
  suspend: 'suspend',
  reject: 'reject',
  temporary: 'temporary',
  bulkDepositToggle: 'bulkDepositToggle',
  closeAccordion: 'closeAccordion',
}
export class Dialog {
  constructor(dialogTitle, message, type) {
    return {
      title: dialogTitle,
      isOpen: true,
      message,
      type,
    }
  }
}
