
import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = () => {
    return (
        <TableHeader >
            <TableCell width={'15%'}>Account Id</TableCell>
            <TableCell width={'15%'}>Source</TableCell>
            <TableCell align={'right'} width={'15%'} style={{ marginRight: "5%" }}  >Amount</TableCell>
            <TableCell width={'20%'}>Reference</TableCell>
            <TableCell width={'15%'} >Date</TableCell>
            <TableCell width={'15%'} >Inserted At</TableCell>
            <TableCell width={'1%'} />
        </TableHeader>
    )
}
