import React, { Component, Fragment } from 'react'
import OrderTypes from 'components/organisms/OrderTypes'
import ErrorBoundary from 'helpers/ErrorBoundary'
class RenderOrderTypes extends Component {
  render() {
    return (
      <ErrorBoundary message="Order Types">
        <OrderTypes />
      </ErrorBoundary>
    )
  }
}
export default RenderOrderTypes
