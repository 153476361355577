import React, { FC, useState } from 'react'
import {
  StyledOrderTypesTable,
  StyledSearchWrapper,
  StyledSelectOuter,
} from './Styles'
import IconButton from 'components/atoms/IconButton'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import OrderTypesCheckBox from 'forms/OrderTypes/orderTypesCheckBox'
import TablePagination from '@material-ui/core/TablePagination'
import { Title } from 'components/atoms/Title'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  DARK_BLUE,
  DARK_BLUE_GREY,
  DARK_MIDNIGHT_BLUE,
  VALR_BLUE_GRAY,
} from 'theme/colors'

const allAvailableOrderTypes = [
  'LIMIT_POST_ONLY',
  'LIMIT',
  'MARKET',
  'SIMPLE',
  'STOP_LOSS_LIMIT',
  'TAKE_PROFIT_LIMIT',
  'LIMIT_REDUCE_ONLY',
  'MARKET_REDUCE_ONLY',
]

type ORDER_TYPE =
  | 'LIMIT_POST_ONLY'
  | 'LIMIT'
  | 'MARKET'
  | 'SIMPLE'
  | 'STOP_LOSS_LIMIT'
  | 'TAKE_PROFIT_LIMIT'
  | 'LIMIT_REDUCE_ONLY'
  | 'MARKET_REDUCE_ONLY'

interface CurrencyPairWithOrderTypes {
  currencyPairSymbol: string
  orderTypes: ORDER_TYPE[]
}
interface OrderTypesTable {
  data: CurrencyPairWithOrderTypes[]
  status: string
  options: { label: string; value: string }[]
  isModalTable: boolean
  isInPostOnlyMode: boolean
  isUpdatingPostOnlyMode: boolean
}
const OrderTypesTable: FC<OrderTypesTable> = ({
  status,
  options,
  data,
  isModalTable,
  isInPostOnlyMode,
  isUpdatingPostOnlyMode,
}) => {
  const [searchedCurrencyPairs, setSearchedCurrencyPairs] = useState<string[]>(
    [],
  )

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  if (data && !data.length) return <p>Error fetching order types</p>
  const paginationItemsCount = data.filter((pair) =>
    searchedCurrencyPairs.length > 0
      ? searchedCurrencyPairs.includes(pair.currencyPairSymbol)
      : true,
  )
  const theme = localStorage.getItem('adminTheme') as 'lightTheme' | 'darkTheme'

  const handleAutoCompleteChange = (_, newValues) => {
    const selectedCurrencyPairs = newValues.map((value) => value.value)
    setSearchedCurrencyPairs(selectedCurrencyPairs)
    setPage(0)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const showPostOnlyModeLoader = () => {
    if (isUpdatingPostOnlyMode && isInPostOnlyMode) {
      return (
        <LoadingBlock
          message={
            'Restoring back to previous saved setting and going out of post only mode.'
          }
        />
      )
    }
    if (isUpdatingPostOnlyMode && !isInPostOnlyMode) {
      return (
        <LoadingBlock
          message={'Saving current settings and going into post only mode.'}
        />
      )
    }
  }
  const useStyles = makeStyles({
    headerRoot: {},
    tableCellRoot: {
      textAlign: 'left',
    },
    tableWidth: {
      width: 200,
      backgroundColor: 'white !important',
      textAlign: 'left',
    },
  })

  const classes = useStyles()
  const cellStyles = (isHeader: boolean) => {
    let backgroundColor = theme === 'lightTheme' ? 'white' : DARK_MIDNIGHT_BLUE
    if (isHeader) {
      backgroundColor = theme === 'lightTheme' ? '#f3f6f8' : '#2f385b'
    }
    return {
      backgroundColor,
    }
  }
  return (
    <>
      {isModalTable && (
        <Title
          type="h4"
          text={
            !isInPostOnlyMode
              ? 'Current settings that will be stored.'
              : 'Settings that will be restored.'
          }
        />
      )}
      {showPostOnlyModeLoader()}
      <TableContainer
        style={{
          maxHeight: 800,
        }}
        component={Paper}
      >
        <Table stickyHeader>
          <TableHead className={classes.headerRoot}>
            <TableRow className={classes.headerRoot}>
              <TableCell style={cellStyles(true)}>
                <br />
                {status === 'done' ? (
                  <StyledSearchWrapper>
                    <StyledSelectOuter>
                      <Autocomplete
                        id="combo-box-demo"
                        multiple
                        options={options}
                        value={options.filter((opt) =>
                          searchedCurrencyPairs.includes(opt.value),
                        )}
                        getOptionLabel={(option) => option.label}
                        disableCloseOnSelect
                        onChange={handleAutoCompleteChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Select Currency Pair"
                            placeholder="Select Currency Pair"
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Checkbox
                              checked={option.selected}
                              name={props.label}
                            />
                            <label>{props.label}</label>
                          </li>
                        )}
                      />
                    </StyledSelectOuter>
                    {/* @ts-ignore */}
                    <IconButton
                      icon={<RotateLeftIcon />}
                      onClick={() => setSearchedCurrencyPairs([])}
                    />
                  </StyledSearchWrapper>
                ) : (
                  <p />
                )}
                <br />
              </TableCell>
              {allAvailableOrderTypes.map((orderType) => (
                <TableCell
                  style={{ width: 200, ...cellStyles(true) }}
                  key={orderType}
                >
                  {orderType}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((pair) =>
                searchedCurrencyPairs.length > 0
                  ? searchedCurrencyPairs.includes(pair.currencyPairSymbol)
                  : true,
              )
              .slice(page * rowsPerPage, rowsPerPage * (page + 1))
              .map((pair, i) => (
                <OrderTypesCheckBox
                  cellStyles={cellStyles(false)}
                  disabled={isModalTable}
                  orderTypesLength={pair.orderTypes.length}
                  key={i}
                  pair={pair}
                  classes={classes}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* @ts-ignore */}
      <TablePagination
        component="div"
        count={paginationItemsCount.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default OrderTypesTable
