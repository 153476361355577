import React, { Component, Fragment } from 'react'
import BigNumber from 'bignumber.js'
import * as moment from 'moment'
import { Flexbox } from 'components/layout/index'
import { GREEN_COLOR_PAIR, VALR_BLUE_GRAY } from 'theme/colors'
import getAxios from 'helpers/axios'

import { TableCell, TableRow } from 'components/atoms/Table/styles'
import {
  FormContainer,
  Button,
  InputGroup,
  BlueLinkButton,
} from 'components/atoms/Form'
import ReviewView from 'components/organisms/ReviewView'
import { RenderCheckbox } from 'forms/fields'
import RawData from 'components/atoms/RawDataDualAuth/index'
import ErrorToast from 'components/molecules/ErrorToast/index'
import IconLoading from 'components/atoms/IconLoading'

import { Link } from '../AccountIdLink/clickableId'

class ReviewFiatWithdrawalsRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetchingSensitiveInfo: false,
      sensitiveInfo: {
        name: '',
        surname: '',
      },
    }
  }

  getProfileInfo = async (id) => {
    this.setState({ isFetchingSensitiveInfo: true })
    const res = await getAxios().get(`/account/${id}/profileinfo`)
    const { sensitiveInfo } = res.data
    this.setState((prev) => ({
      ...prev,
      sensitiveInfo,
    }))
    this.setState({ isFetchingSensitiveInfo: false })
  }

  refineRawData = (data) => {
    const {
      bank,
      name,
      surname,
      withdrawalId,
      branchCode,
      accountType,
      currency,
    } = data
    const { rtcParticipant, defaultBranchCode } = bank
    const { symbol, shortName, longName } = currency
    return {
      name,
      surname,
      withdrawalId,
      branchCode,
      accountType,
      defaultBranchCode,
      rtcParticipant,
      shortName,
      symbol,
      longName,
    }
  }

  render() {
    const {
      withdrawal: {
        withdrawalId,
        accountId,
        amount,
        bank,
        accountHolder,
        isFast,
        updatedAt,
        currency,
      },
      withdrawal,
      review,
      selectRow,
      updateSelectedReversefee,
      isSelected,
      toggleForm,
      formShown,
      toggleRawData,
      showRawData,
      setHighlightedId,
      isHighlighted,
      reverseFee,
      showReverseFee,
      withdrawalActionType,
      isZambian,
    } = this.props
    const { sensitiveInfo, isFetchingSensitiveInfo } = this.state
    const formattedAmount = new BigNumber(amount).toFormat(2)
    const padding = '16px 16px 16px 0px'
    const shading = isHighlighted ? 'white' : ''

    return (
      <Fragment>
        <TableRow
          style={{
            padding: '0px',
            color: shading,
            backgroundColor: isHighlighted && VALR_BLUE_GRAY,
          }}
          onClick={() => {
            if (!sensitiveInfo.name) {
              this.getProfileInfo(accountId)
            }
            // TODO: find out why this fetchAccountDetails is used here everytime we click the row we should rather have this fire off once on mount
            setHighlightedId(withdrawalId)
          }}
        >
          <TableCell style={{ padding: '16px 16px' }} flex={0.5}>
            <Link color={shading} id={accountId} />
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleForm(withdrawalId)}
            flex={1.2}
          >
            {accountHolder}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleForm(withdrawalId)}
            flex={1.2}
          >
            {bank.displayName}
          </TableCell>
          <TableCell
            style={{ padding }}
            color={isFast ? GREEN_COLOR_PAIR : 'black'}
            onClick={() => toggleForm(withdrawalId)}
            flex={1}
          >
            {isFast ? 'Yes' : 'No'}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleForm(withdrawalId)}
            align={'right'}
            flex={1.1}
          >
            {formattedAmount}
          </TableCell>
          <TableCell
            style={{ padding }}
            onClick={() => toggleForm(withdrawalId)}
            align={'right'}
            flex={0.9}
          >
            {currency.shortName}
          </TableCell>
          {showReverseFee && (
            <TableCell
              style={{ padding, marginLeft: '40px' }}
              align={'center'}
              flex={0.9}
            >
              {isSelected ? (
                <RenderCheckbox
                  style={{ color: 'white', marginLeft: '40px' }}
                  onCheckFunction={() => {
                    updateSelectedReversefee(withdrawalId)
                  }}
                  checked={reverseFee}
                />
              ) : (
                JSON.stringify(reverseFee)
              )}
            </TableCell>
          )}
          <TableCell
            style={{ padding }}
            onClick={() => toggleForm(withdrawalId)}
            align={'right'}
            flex={1}
          >
            {moment(updatedAt).format('YYYY-MMM-DD HH:mm:ss')}
          </TableCell>
          <TableCell style={{ padding }} flex={0.4} align={'right'}>
            <RenderCheckbox
              style={{ color: isSelected ? 'white' : '' }}
              onCheckFunction={() => {
                if (isZambian && !withdrawalActionType) {
                  return ErrorToast(
                    'Please select one of the actions above Process/Reverse',
                  )
                }
                selectRow(withdrawalId, bank.code)
              }}
              checked={isSelected}
            />
          </TableCell>
        </TableRow>
        {formShown === withdrawalId && (
          <FormContainer>
            <ReviewView
              flags={review && review.flags}
              audits={review && review.audits}
            />
            <Flexbox align={'flex-start'} direction={'column'}>
              <BlueLinkButton
                marginRight={'50px'}
                marginBottom={'20px'}
                onClick={() => toggleRawData(withdrawalId)}
              >
                {showRawData ? 'Hide Raw Data' : 'Show Raw Data'}
              </BlueLinkButton>
              {isFetchingSensitiveInfo && <IconLoading />}
              {!isFetchingSensitiveInfo && showRawData && (
                <RawData
                  data={this.refineRawData({
                    ...withdrawal,
                    ...sensitiveInfo,
                  })}
                />
              )}
            </Flexbox>
            <InputGroup justify={'flex-end'}>
              <Button onClick={() => toggleForm(withdrawalId)}>Close</Button>
            </InputGroup>
          </FormContainer>
        )}
      </Fragment>
    )
  }
}

export default ReviewFiatWithdrawalsRow
