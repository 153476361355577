import { FiatWithdrawalsDashboardStats } from 'interfaces/fiatWithdrawalsDashboardStats'
import { FiatWithdrawalsDashboardStatsActionTypes } from './actions'

export interface FiatWithdrawalsDashboardStatsInitialState {
  status: 'idle' | 'pending' | 'done'
  data: FiatWithdrawalsDashboardStats
}

const initialState: FiatWithdrawalsDashboardStatsInitialState = {
  status: 'idle',
  data: {
    stats: [],
    healthZStats: {
      futurePublicHolidaysReady: false,
      standardBankConnected: false,
    },
  },
}

interface Action {
  type: FiatWithdrawalsDashboardStatsActionTypes
  payload?: FiatWithdrawalsDashboardStats
}
export default (
  state = initialState,
  action: Action,
): FiatWithdrawalsDashboardStatsInitialState => {
  switch (action.type) {
    case 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_START':
      return {
        ...state,
        status: 'pending',
      }

    case 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_SUCCESS': {
      if (action.payload) {
        return {
          ...state,
          status: 'done',
          data: action.payload,
        }
      }
      return state
    }
    case 'FETCH_FIAT_WITHDRAWALS_DASHBOARD_STATS_ERROR':
      return {
        ...state,
        status: 'idle',
      }

    default:
      return state
  }
}
