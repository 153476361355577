// eslint-disable-next-line import/no-cycle
import {
  FETCH_MARGIN_REQUIREMENTS_PROCESS,
  FETCH_MARGIN_REQUIREMENTS_SUCCESS,
  FETCH_MARGIN_REQUIREMENTS_ERROR,
  FETCH_INTEREST_AUCTION_CONFIG_PROCESS,
  FETCH_INTEREST_AUCTION_CONFIG_SUCCESS,
  FETCH_INTEREST_AUCTION_CONFIG_ERROR,
  FETCH_LIQUIDATION_SETTINGS_PROCESS,
  FETCH_LIQUIDATION_SETTINGS_SUCCESS,
  FETCH_LIQUIDATION_SETTINGS_ERROR,
  SAVE_MARGIN_REQUIREMENTS_START,
  SAVE_MARGIN_REQUIREMENTS_SUCCESS,
  SAVE_MARGIN_REQUIREMENTS_ERROR,
} from './actions'

const initialState = {
  marginRequirements: {
    status: 'pristine',
    isSavingMarginRequirements: false,
    error: '',
    data: {},
  },
  interestAuctionConfig: {
    interestAuctionStatus: 'pristine',
    error: '',
    interestAuctionData: [],
  },
  liquidationSettings: {
    liquidationSettingsStatus: 'pristine',
    error: '',
    liquidationSettingsData: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MARGIN_REQUIREMENTS_PROCESS: {
      return {
        ...state,
        marginRequirements: {
          ...state.marginRequirements,
          status: 'loading',
          error: '',
          data: {},
        },
      }
    }

    case FETCH_MARGIN_REQUIREMENTS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        marginRequirements: {
          ...state.marginRequirements,
          status: 'done',
          error: '',
          data,
        },
      }
    }

    case FETCH_MARGIN_REQUIREMENTS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        marginRequirements: {
          ...state.marginRequirements,
          error,
          status: 'error',
          data: {},
        },
      }
    }
    case SAVE_MARGIN_REQUIREMENTS_START: {
      return {
        ...state,
        marginRequirements: {
          ...state.marginRequirements,
          isSavingMarginRequirements: true,
        },
      }
    }

    case SAVE_MARGIN_REQUIREMENTS_SUCCESS: {
      return {
        ...state,
        marginRequirements: {
          ...state.marginRequirements,
          data: { ...state.marginRequirements.data, ...action.payload },
          isSavingMarginRequirements: false,
        },
      }
    }

    case SAVE_MARGIN_REQUIREMENTS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        marginRequirements: {
          ...state.PlatformSummary,
          isSavingMarginRequirements: false,
          error,
          status: 'error',
        },
      }
    }

    case FETCH_INTEREST_AUCTION_CONFIG_PROCESS: {
      return {
        ...state,
        interestAuctionConfig: {
          ...state.interestAuctionConfig,
          interestAuctionStatus: 'loading',
          error: '',
          interestAuctionData: [],
        },
      }
    }

    case FETCH_INTEREST_AUCTION_CONFIG_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        interestAuctionConfig: {
          ...state.interestAuctionConfig,
          interestAuctionStatus: 'done',
          error: '',
          interestAuctionData: data,
        },
      }
    }

    case FETCH_INTEREST_AUCTION_CONFIG_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        interestAuctionConfig: {
          ...state.interestAuctionConfig,
          error,
          interestAuctionStatus: 'error',
        },
      }
    }

    case FETCH_LIQUIDATION_SETTINGS_PROCESS: {
      return {
        ...state,
        liquidationSettings: {
          ...state.liquidationSettings,
          liquidationSettingsStatus: 'loading',
          error: '',
          liquidationSettingsData: [],
        },
      }
    }

    case FETCH_LIQUIDATION_SETTINGS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        liquidationSettings: {
          ...state.liquidationSettings,
          liquidationSettingsStatus: 'done',
          error: '',
          liquidationSettingsData: data,
        },
      }
    }

    case FETCH_LIQUIDATION_SETTINGS_ERROR: {
      const { error } = action.payload
      return {
        ...state,
        liquidationSettings: {
          ...state.liquidationSettings,
          error,
          liquidationSettingsStatus: 'error',
        },
      }
    }
    default:
      return state
  }
}
