import getAxios from 'helpers/axios'

export const FETCH_CURRENCY_ENABLED_BORROW_START =
  'FETCH_CURRENCY_ENABLED_BORROW_START'
export const FETCH_CURRENCY_ENABLED_BORROW_SUCCESS =
  'FETCH_CURRENCY_ENABLED_BORROW_SUCCESS'
export const FETCH_CURRENCY_ENABLED_BORROW_ERROR =
  'FETCH_CURRENCY_ENABLED_BORROW_ERROR'

export const fetchCurrenciesEnabledBorrow = () => async dispatch => {
  dispatch({
    type: FETCH_CURRENCY_ENABLED_BORROW_START,
  })
  try {
    const res = await getAxios().get('/admin/currency/currencies/loansBook')
    const { data } = res

    dispatch({
      type: FETCH_CURRENCY_ENABLED_BORROW_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_CURRENCY_ENABLED_BORROW_ERROR,
    })
  }
}
