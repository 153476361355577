import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'
import {
  TopNavigationBar,
  NavigationTab,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic/index'
import { fetchExtraAccountInformation } from 'redux/ui/actions'
import getAxios from 'helpers/axios'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'
import {
  clearAccountPendingMultiAuthsState,
  fetchAccountPendingMultiAuthsById,
} from 'redux/accountPendingMultuAuths/actions'

import { fetchUserComments } from 'redux/userComments/actions'
import { getAccountDetails } from 'redux/selectors/details'
import { FLAG_COLORS } from 'components/atoms/Table/styles'
import { getCustomerType } from 'redux/selectors/type'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  fetchAccountDetails,
  clearAccountDetails,
  fetchRiskProfileInfo,
} from 'redux/accounts/actions'

import RenderAccountBalances from './VeiwAccountBalances'
import Totp from './MultipleFactor'
import RecentActivity from './RecentActivity/RecentActivity'
import UserTickets from './UserTickets'
import Logs from './Logs'
import AccountConfirmations from './AccountConfirmation'
import SubAccounts from './SubAccounts'
import ProfileHeader from './ProfileHeader'
import RenderTransactionalHistory from './ViewTransactionalHistory'
import CorporateAccount from './CorporateAccount/index'
import Comments from './Comments/Comments'
import ComplianceDetails from './Compliance/index'
import RenderAccountDetails from './ViewAccountDetails'
import RiskProfileEddReports from './Compliance/components/RiskProfiles/EddReports/RiskProfileEddReports'
import TradingNav from './TradingNav'

import { ROLES, checkRole } from 'helpers/roleBasedAccess'
class SingleAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: '0',
      corporateExists: false,
    }
  }

  componentDidMount() {
    this.handleFetchAccountData()
    this.checkLocation()
    // TODO: get this function built
    // this.props.runCorporateSanctionScan()
  }

  componentDidUpdate(prevProps) {
    const { accountId } = this.props.match.params
    const mustFetchMoreAccountInfo =
      this.props.details.status === 'done' &&
      this.props.details &&
      prevProps.details

    if (mustFetchMoreAccountInfo) {
      if (prevProps.details.accountId !== this.props.details.accountId) {
        this.props.fetchExtraAccountInformation(accountId)
        this.checkIfCorporateExists()
      }
    }
    if (prevProps.match.params.accountId !== accountId) {
      this.handleFetchAccountData()
      const {
        location: { pathname },
      } = this.props
      const {
        location: { pathname: prevPathname },
      } = prevProps
      if (pathname !== prevPathname) this.checkLocation()
    }
  }

  componentWillUnmount() {
    this.props.clearAccountPendingMultiAuthsState()
    this.props.clearAccountDetails()
  }

  checkPathName = (pathname) => {
    if (pathname.includes('creditCard')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('deposits')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('sends')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('accountTransactions')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('valrPay')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('funding')) {
      return this.handleChange(null, 'transactions')
    }
    if (pathname.includes('corporate/documents')) {
      return this.handleChange(null, 'corporate')
    }
    if (pathname.includes('balances')) {
      return this.handleChange(null, 'balances')
    }
    if (pathname.includes('comments')) {
      return this.handleChange(null, 'comments')
    }
    if (pathname.includes('details')) {
      return this.handleChange(null, 'details')
    }
    if (pathname.includes('compliance')) {
      return this.handleChange(null, 'compliance')
    }
    if (pathname.includes('trading')) {
      return this.handleChange(null, 'trading')
    }
    this.handleChange(null, '0')
  }

  checkIfCorporateExists = async () => {
    const {
      data: { corporateExists },
    } = await getAxios().get(
      `/corporates/${this.props.match.params.accountId}/exists`,
    )
    this.setState({ corporateExists })
  }

  checkLocation = () => {
    const {
      location: { pathname },
    } = this.props
    const pathnameArray = pathname.split('/')
    if (pathnameArray.length === 5) {
      if (pathnameArray[5] !== this.state.currentPage) {
        this.handleChange(null, pathnameArray[4])
      }
    } else {
      this.checkPathName(pathname)
    }
  }

  returnBGColorForCommentsNav = () => {
    const {
      userComments: { status, data },
    } = this.props

    const indexOfFlagged =
      status === 'done' && data.findIndex((value) => value.flag === true)
    if (status === 'loading') {
      return FLAG_COLORS.TRANSPARENT
    }
    if (status === 'done' && data.length === 0) {
      return FLAG_COLORS.TRANSPARENT
    }
    if (status === 'done' && data.length > 0 && indexOfFlagged === -1) {
      return FLAG_COLORS.GREEN
    }
    if (status === 'done' && indexOfFlagged > -1) {
      return FLAG_COLORS.RED
    }
  }

  returnBGColorForSubAccountNav = () => {
    const {
      details: { status, primaryAccountSubAccounts = [] },
    } = this.props
    if (status === 'loading') {
      return FLAG_COLORS.TRANSPARENT
    }
    if (status === 'done' && primaryAccountSubAccounts.length === 0) {
      return FLAG_COLORS.TRANSPARENT
    }
    if (status === 'done' && primaryAccountSubAccounts.length > 0) {
      return FLAG_COLORS.GREEN
    }
  }

  returnBGColorForCorporateNav = () => {
    const {
      accountType: {
        data: { type },
        status,
      },
    } = this.props
    const { corporateExists } = this.state
    const isCorporateAccount = type === 'CORPORATE'
    if (status === 'loading') return FLAG_COLORS.TRANSPARENT
    if (status === 'done' && corporateExists && isCorporateAccount) {
      return FLAG_COLORS.GREEN
    }
    if (status === 'done' && corporateExists && !isCorporateAccount) {
      return FLAG_COLORS.RED
    }
    return FLAG_COLORS.TRANSPARENT
  }

  returnBGColorForPendingMultiAuthsNav = () => {
    if (this.props.hasPendingMultiAuths) return FLAG_COLORS.RED
    return FLAG_COLORS.TRANSPARENT
  }

  async handleFetchAccountData() {
    const { accountId } = this.props.match.params
    await this.props.fetchAccountDetails(accountId)
    await this.props.fetchRiskProfileInfo(accountId)
    if (this.props.accountPendingMultiAuthsFeatureActive) {
      this.props.fetchAccountPendingMultiAuthsById(accountId)
    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      currentPage: newValue,
    })
  }

  render() {
    const { accountId } = this.props.match.params
    const commentsColor = this.returnBGColorForCommentsNav()
    const subAccountsColor = this.returnBGColorForSubAccountNav()
    const {
      details: { accountType, info, status, isValrStaffAccount },
    } = this.props

    const blue = '#4569eb'
    const lightBlue = '#3F445F'

    if (status === 'loading' || status === 'pristine') {
      return <LoadingBlock />
    }

    if (!info && (status === 'done' || status === 'error')) {
      return <p>Account not found</p>
    }
    const hasSuperUserRoleAndIsStaffAccount = isValrStaffAccount
      ? checkRole('management:internal:account:editor')
      : true
    return (
      <Fragment>
        {status !== 'loading' && <ProfileHeader accountId={accountId} />}
        <TopNavigationBar
          value={this.state.currentPage}
          onChange={this.handleChange}
          variant="scrollable"
          scrollButtons="off"
          style={{
            backgroundColor: accountType === 'SUB_ACCOUNT' && `${lightBlue}`,
          }}
        >
          <NavigationTab
            value="details"
            label="Details"
            to={`/accounts/byId/${accountId}/details`}
            component={ForwardedNavLink}
          />
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="balances"
              label="Balances"
              to={`/accounts/byId/${accountId}/balances`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="transactions"
              label="Transactions"
              exact
              to={`/accounts/byId/${accountId}/transactions`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="trading"
              label="Trading"
              exact
              to={`/accounts/byId/${accountId}/trading`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="comments"
              label="Comments"
              to={`/accounts/byId/${accountId}/comments`}
              bgcolor={commentsColor}
              color={
                accountType === 'SUB_ACCOUNT' && commentsColor !== 'transparent'
                  ? 'black'
                  : 'white'
              }
              component={ForwardedNavLink}
            />
          )}

          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="mfa"
              label="MFA"
              to={`/accounts/byId/${accountId}/mfa`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="recentactivity"
              label="Recent Activity"
              to={`/accounts/byId/${accountId}/recent-activity`}
              component={ForwardedNavLink}
            />
          )}

          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="subaccounts"
              label="Subaccounts"
              to={`/accounts/byId/${accountId}/sub-accounts`}
              color={accountType === 'SUB_ACCOUNT' ? 'black' : 'white'}
              bgcolor={subAccountsColor}
              component={ForwardedNavLink}
            />
          )}

          {hasSuperUserRoleAndIsStaffAccount && accountType !== 'SUB_ACCOUNT' && (
            <NavigationTab
              value={'corporate'}
              label="Corporate"
              to={`/accounts/byId/${accountId}/corporate`}
              bgcolor={this.returnBGColorForCorporateNav()}
              color={
                accountType === 'CORPORATE' && commentsColor !== 'transparent'
                  ? 'black'
                  : 'white'
              }
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="confirmations"
              label="Confirmations"
              to={`/accounts/byId/${accountId}/confirmations`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="tickets"
              label="Tickets"
              to={`/accounts/byId/${accountId}/tickets`}
              component={ForwardedNavLink}
            />
          )}
          {hasSuperUserRoleAndIsStaffAccount && (
            <NavigationTab
              value="logs"
              label="Logs"
              to={`/accounts/byId/${accountId}/logs`}
              component={ForwardedNavLink}
            />
          )}
          {
            checkRole(ROLES.complianceEditor) &&
            hasSuperUserRoleAndIsStaffAccount && (
              <NavigationTab
                value="compliance"
                label="Compliance"
                to={`/accounts/byId/${accountId}/account-compliance/risk-profile`}
                component={ForwardedNavLink}
              />
            )}
        </TopNavigationBar>
        <ContentContainer
          bgcolor={
            accountType === 'SUB_ACCOUNT'
              ? `4px solid ${lightBlue}`
              : `4px solid ${blue}`
          }
        >
          {hasSuperUserRoleAndIsStaffAccount && (
            <Switch>
              <Route
                path={'/accounts/byId/:accountId/details'}
                component={RenderAccountDetails}
              />
              <Route
                path={'/accounts/byId/:accountId/balances'}
                component={RenderAccountBalances}
              />
              <Route
                path={'/accounts/byId/:accountId/transactions'}
                accountId={accountId}
                component={RenderTransactionalHistory}
              />
              <Route
                path={'/accounts/byId/:accountId/trading'}
                accountId={accountId}
                component={TradingNav}
              />
              <Route
                path={'/accounts/byId/:accountId/comments'}
                component={Comments}
              />
              <Route
                path={'/accounts/byId/:accountId/sub-accounts'}
                component={SubAccounts}
              />
              {accountType !== 'SUB_ACCOUNT' &&
                <Route
                  path={'/accounts/byId/:accountId/corporate'}
                  component={CorporateAccount}
                />
              }
              <Route
                path={'/accounts/byId/:accountId/confirmations'}
                component={AccountConfirmations}
              />
              <Route path={'/accounts/byId/:accountId/mfa'} component={Totp} />
              <Route
                path={'/accounts/byId/:accountId/recent-activity'}
                component={RecentActivity}
              />
              <Route
                path={'/accounts/byId/:accountId/tickets'}
                component={UserTickets}
              />
              <Route path={'/accounts/byId/:accountId/logs'} component={Logs} />

              {checkRole(ROLES.complianceEditor) && (
                <Switch>
                  <Route
                    path={
                      '/accounts/byId/:accountId/account-compliance/risk-profile/individual/edd-report'
                    }
                    component={RiskProfileEddReports}
                  />
                  <Route
                    path={
                      '/accounts/byId/:accountId/account-compliance/risk-profile/corporate/edd-report'
                    }
                    component={RiskProfileEddReports}
                  />
                  {
                    checkRole(ROLES.complianceEditor) &&
                    hasSuperUserRoleAndIsStaffAccount && (
                      <Route
                        path={'/accounts/byId/:accountId/account-compliance'}
                        component={ComplianceDetails}
                      />
                    )}
                </Switch>
              )}
            </Switch>
          )}
          {!hasSuperUserRoleAndIsStaffAccount && (
            <Route
              path={'/accounts/byId/:accountId/details'}
              component={RenderAccountDetails}
            />
          )}
        </ContentContainer>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  userComments: state.userComments,
  details: getAccountDetails(state),
  accountType: getCustomerType(state),
  corporateAccountData: state.corporate,
  hasPendingMultiAuths: state.accountPendingMultiAuths.data.length > 0,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchExtraAccountInformation,
      fetchUserComments,
      fetchAccountPendingMultiAuthsById,
      clearAccountPendingMultiAuthsState,
      fetchAccountDetails,
      clearAccountDetails,
      fetchRiskProfileInfo,
      // TODO: Pass this to props
      // runCorporateSanctionScan,
    },
    dispatch,
  )
export default connect(mapStateToProps, mapDispatchToProps)(SingleAccount)
