import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel as MUIInputLabel,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ImageIcon from '@material-ui/icons/Image'

import './Style.css'
import MuiButton from 'components/atoms/Buttons/MuiButton'

import {
  RadioButton,
  RadioGroup,
  Dropdown,
  Input,
  InputContainer,
  InputError,
  InputGroup,
  InputLabel,
} from 'components/atoms/Form/index'
import EmailSwitcher from 'components/organisms/EmailSwitcher'
import FileUploader from 'components/molecules/FileUploader'
import {
  StyledFileUploaderContent,
  StyledFileUploaderWrapper,
} from 'components/molecules/FileUploader/Styles'

export const renderInput = (props) => {
  const {
    input,
    label,
    type,
    placeholder,
    disabled,
    meta: { touched, error },
    onKeyPress,
    onChange,
    margin,
    fullWidth,
    width,
    size,
    inputValue,
    value,
    inputRef,
    endAdornment,
    multiline,
    ...rest
  } = props

  const inputProps = inputValue ? { ...input, value: inputValue } : input
  useEffect(() => {
    if (inputValue) input.onChange(inputValue)
  }, [inputValue])
  return (
    <Input
      InputProps={{
        ...inputProps,
        endAdornment,
      }}
      multiline={multiline}
      {...rest}
      disabled={disabled}
      error={touched && !!error}
      label={label || placeholder}
      placeholder={placeholder}
      // This would always be overridden, keep if needed
      // variant={type || 'standard'}
      helperText={touched ? error : ''}
      onKeyPress={onKeyPress}
      marginspacing={margin}
      fullWidth={fullWidth}
      width={width}
      size={size}
      value={value}
      onChange={onChange}
      variant="outlined"
      inputRef={inputRef}
    />
  )
}

export const renderSearch = (props) => {
  const {
    input,
    label,
    type,
    placeholder,
    disabled,
    meta: { touched, error },
    onKeyPress,
    onChange,
    margin,
    fullWidth,
    width,
    size,
    inputValue,
  } = props

  const inputProps = inputValue ? { ...input, value: inputValue } : input
  useEffect(() => {
    if (inputValue) input.onChange(inputValue)
  }, [inputValue])
  return (
    <Input
      inputProps={inputProps}
      disabled={disabled}
      error={touched && error}
      label={label || placeholder}
      placeholder={placeholder}
      variant={type || 'standard'}
      helperText={touched ? error : ''}
      onKeyPress={onKeyPress}
      margin={margin}
      fullWidth={fullWidth}
      width={width}
      size={size}
      onChange={onChange}
    />
  )
}

export const RenderCheckbox = (props) => {
  const { onCheckFunction, checked, label, disabled, ...rest } = props
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          checked={checked}
          onChange={onCheckFunction}
          color="primary"
        />
      }
      label={label || ''}
      disabled={disabled || ''}
    />
  )
}

export const renderUncontrolledCheckbox = (props) => {
  const { input, label, disabled, ...rest } = props

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          checked={input.value ? true : false}
          onChange={input.onChange}
          color="primary"
        />
      }
      label={label || ''}
      disabled={disabled || ''}
    />
  )
}

export const renderRadioGroup = ({ options, input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
  >
    {options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value || ''}
        control={<RadioButton color="primary" />}
        label={option.label}
      />
    ))}
  </RadioGroup>
)

export const renderFileUploaderInput = ({
  allowedFileTypes,
  input,
  iconWidth,
  iconHeight,
  buttonText = 'Upload file',
  ...rest
}) => {
  const [file, setFile] = useState(null)
  const [uploadError, setUploadError] = useState('')

  const checkFileType = (allowedFileTypes, fileType) => {
    let fileCheckSuccess = true
    if (!fileType) {
      fileCheckSuccess = false
      return setUploadError('Invalid file')
    }

    allowedFileTypes.forEach((type) => {
      if (!fileType.includes(type)) {
        fileCheckSuccess = false
        return setUploadError(`Invalid file ${fileType}`)
      }
      setUploadError('')
    })
    return fileCheckSuccess
  }

  const drop = (e) => {
    e.preventDefault()
    if (e.dataTransfer) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i]
        if (item.kind === 'file') {
          const fileItem = item.getAsFile()
          if (fileItem) {
            const allowed = checkFileType(allowedFileTypes, fileItem.type)
            if (allowed) setFile(fileItem)
          }
        }
      }
    }
  }
  useEffect(() => {
    if (input.value) return
    setFile(null)
  }, [input])

  return (
    <>
      <StyledFileUploaderWrapper
        id="abc"
        onDragEnter={(e) => {
          e.preventDefault()
        }}
        onDragStart={(e) => {
          e.preventDefault()
        }}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        onDrop={drop}
      >
        <StyledFileUploaderContent>
          {!file && <p>Click here or drag and drop files to upload</p>}
          {!file && (
            <ImageIcon
              style={{
                opacity: 0.1,
                height: iconHeight || 300,
                width: iconWidth || 300,
              }}
            />
          )}
          {!file && <MuiButton>{buttonText}</MuiButton>}
          {file && file.name && file.name}
          {uploadError && <p>{uploadError}</p>}
          <input
            aria-hidden="true"
            {...rest}
            accept={allowedFileTypes}
            type="file"
            onChange={(e) => {
              const selectedFile = e.target.files[0]
              let fileReader
              if (selectedFile) {
                fileReader = new FileReader()
                fileReader.readAsText(selectedFile)
                fileReader.onloadend = (e) => {
                  const content = fileReader.result
                  input.onChange(content)
                  setFile(selectedFile)
                }
              }
            }}
            onClick={(e) => {
              e.target.value = null
              input.onChange(null)
            }}
          />
        </StyledFileUploaderContent>
      </StyledFileUploaderWrapper>
    </>
  )
}

export const renderFileInput = (props) => {
  const {
    input,
    label,
    labelWidth,
    title,
    placeholder,
    align,
    disabled,
    bgColor,
    accept,
    meta: { touched, error },
    showFileName = false,
    noFileMessage = '',
    width,
  } = props

  const [fileData, setFileData] = useState(null)
  const inputRef = useRef()

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }
  useEffect(() => {
    if (input.value) return
    setFileData(null)
  }, [input])

  return (
    <>
      <MuiButton onClick={handleClick}>{label || 'Upload file'}</MuiButton>
      <InputContainer width={width}>
        <Input
          inputProps={{ accept }}
          inputRef={inputRef}
          error={touched && error}
          type="file"
          placeholder={placeholder}
          display="hidden"
          style={{ display: 'none' }}
          flex={'auto'}
          width={'100%'}
          bgcolor={bgColor}
          textalign={align}
          disabled={disabled}
          title={title}
          accept={accept}
          onChange={(e) => {
            const file = e.target.files[0]
            let fileReader
            if (file) {
              fileReader = new FileReader()
              fileReader.readAsText(file)
              fileReader.onloadend = (e) => {
                const content = fileReader.result
                input.onChange(content)
                setFileData(file)
              }
            } else {
              input.onChange(null)
            }
          }}
          onClick={(e) => {
            e.target.value = null
            input.onChange(null)
          }}
        />
        {props.endDecoration && props.endDecoration()}
        {showFileName && (
          <div style={{ marginLeft: 8 }}>
            {fileData?.name || `No File Selected ${noFileMessage}`}
          </div>
        )}
      </InputContainer>
      {touched && error && (
        <InputError paddingLeft={parseInt(labelWidth, 10) + 20}>
          {error}
        </InputError>
      )}
    </>
  )
}

export const renderCompetitionFileInput = (props) => {
  const {
    input,
    label,
    labelWidth,
    title,
    placeholder,
    align,
    disabled,
    width,
    bgColor,
    accept,
    meta: { touched, error },
    marginRight,
  } = props

  return (
    <InputGroup
      direction={'column'}
      align={'flex-start'}
      width={width}
      marginRight={marginRight}
    >
      {label && (
        <InputLabel width={labelWidth} minWidth={labelWidth}>
          {label}:
        </InputLabel>
      )}
      <InputContainer>
        <Input
          id="competition-file-input"
          error={touched && error}
          type="file"
          placeholder={placeholder}
          flex={'auto'}
          width={'100%'}
          bgColor={bgColor}
          textAlign={align}
          disabled={disabled}
          title={title}
          accept={accept}
          onChange={(e) => {
            const file = e.target.files[0]
            let fileReader
            if (file) {
              fileReader = new FileReader()
              fileReader.onloadend = (e) => {
                const content = fileReader.result
                input.onChange(content)
              }
              fileReader.readAsText(file)
            } else {
              input.onChange(null)
            }
          }}
          onClick={(e) => {
            e.target.value = null
            input.onChange(null)
          }}
        />
        {props.endDecoration && props.endDecoration()}
      </InputContainer>
      {touched && error && (
        <InputError paddingLeft={parseInt(labelWidth, 10) + 20}>
          {error}
        </InputError>
      )}
    </InputGroup>
  )
}

export const renderVerticalField = (props) => {
  const {
    input,
    label,
    labelWidth,
    type,
    placeholder,
    autofocus,
    align,
    disabled,
    width,
    meta: { touched, error },
  } = props
  return (
    <InputGroup
      width={width}
      direction={'column'}
      align={'flex-start'}
      marginBottom={16}
    >
      <InputGroup
        direction={'column'}
        align={'flex-start'}
        justify={'space-between'}
        width={'100%'}
      >
        {label && (
          <InputLabel width={labelWidth} minWidth={labelWidth} marginBottom={8}>
            {label}:
          </InputLabel>
        )}

        <Input
          {...input}
          error={touched && error}
          type={type}
          placeholder={placeholder}
          autofocus={autofocus}
          flex={'auto'}
          width={'100%'}
          textAlign={align}
          disabled={disabled}
        />
      </InputGroup>
      {touched && error && <InputError>{error}</InputError>}
    </InputGroup>
  )
}

export const errorField = (props) => {
  const {
    meta: { error },
  } = props
  return <div> {error && <InputError>{error}</InputError>}</div>
}

export const renderTextArea = (props) => {
  const {
    labelWidth,
    label,
    input,
    meta: { visited, error, warning },
    rows,
    marginRight,
    // marginTop,
    maxLength,
    ...rest
  } = props
  return (
    <InputGroup
      // marginTop={marginTop || 0}
      direction={'column'}
      align={'flex-start'}
      width={'100%'}
      {...rest}
    >
      <InputLabel width={labelWidth}>{label}:</InputLabel>
      <TextareaAutosize
        {...input}
        rowsMax={3}
        aria-label="maximum height"
        marginLeft={'20%'}
        style={{
          width: '100%',
          borderRadius: '6px',
          verticalAlign: 'baseline',
          padding: '10px',
        }}
        maxLength={maxLength}
      />
      {visited && (error || warning) && (
        <InputError paddingLeft={parseInt(labelWidth, 10) + 20}>
          {error || warning}
        </InputError>
      )}
    </InputGroup>
  )
}

export const renderSendEmail = (props) => {
  const {
    label,
    formatLabel,
    labelWidth,
    fontSize,
    type,
    input: { onChange, value },
  } = props

  const suffix = label.slice(-1) === '?' ? '' : ':'
  return (
    <InputGroup
      justify={'flex-start'}
      align={'flex-start'}
      direction={'column'}
    >
      <InputLabel
        formatLabel={formatLabel}
        width={labelWidth}
      >{`${label}${suffix}`}</InputLabel>
      <EmailSwitcher
        type={type}
        active={value}
        fontSize={fontSize || 14}
        onClick={onChange.bind(null, !value)}
      />
    </InputGroup>
  )
}

export const renderSwitch = (props) => {
  const {
    label,
    input: { onChange, value },
  } = props

  const handleChange = (event) => {
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      value={value}
      control={
        <Switch checked={value} onChange={handleChange} color="primary" />
      }
      label={label}
      labelPlacement="top"
    />
  )
}

export const renderDropdown = (props) => {
  const {
    options,
    label,
    depositOnChange,
    input: { onFocus, value: inputValue, onChange },
    meta: { error: metaError, visited },
    error,
    width,
    fullWidth,
    value,
    margin,
    type,
    corporateAccountSanctioned,
    kycLevel,
    disabled,
  } = props

  const [stateValue, setStateValue] = React.useState('')
  const [isBlurred, setIsBlurred] = React.useState(false)

  const handleChange = (event) => {
    const { value } = event.target
    onChange({ value })
    setStateValue({ value })
  }

  useEffect(() => {
    setStateValue(inputValue)
  }, [inputValue])

  const useStyles = makeStyles({
    menuPaper: {
      maxHeight: 200,
    },
  })

  const filteredOptions = useMemo(() => {
    if (options && options.length) {
      const onlyItems = options.splice(1)

      if (type === 'currencies') {
        const importantItemsToReturn = []
        const secondaryItemsToReturn = []
        onlyItems.forEach((item) => {
          if (
            item.label === 'ZAR' ||
            item.label === 'BTC' ||
            item.label === 'ETH' ||
            item.label === 'XRP'
          ) {
            importantItemsToReturn.push(item)
          } else {
            secondaryItemsToReturn.push(item)
          }
        })
        const sortedArray = secondaryItemsToReturn.sort((a, b) => {
          if (a.label < b.label) return -1
          return 1
        })
        return [options[0], ...importantItemsToReturn, ...sortedArray]
      }
      if (type === 'deposits') {
        const sortedArray = onlyItems.sort((a, b) => {
          if (a.label > b.label) return -1
          return 1
        })
        return [options[0], ...sortedArray]
      }
      const sortedArray = onlyItems.sort((a, b) => {
        if (a.label < b.label) return -1
        return 1
      })
      return [options[0], ...sortedArray]
    }
    return []
  }, [options, type])

  const handleBlur = () => {
    setIsBlurred(true)
  }

  const classes = useStyles()

  return (
    <Dropdown
      error={(isBlurred && visited && metaError) || error}
      width={width}
      fullWidth={fullWidth}
      margin={margin}
      variant="outlined"
    >
      <MUIInputLabel>{label}</MUIInputLabel>
      <Select
        onChange={
          depositOnChange !== undefined ? depositOnChange : handleChange
        }
        onClose={handleBlur}
        disabled={disabled}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onFocus={onFocus || (() => console.log('Focused'))}
        value={
          stateValue !== undefined ? stateValue.value || stateValue : value
        }
        label={label}
      >
        {filteredOptions.length &&
          filteredOptions.map((option) => {
            const disableCorporateSelection =
              option.value === 'CORPORATE' && corporateAccountSanctioned
            const disableKycSelection = option.value === kycLevel
            if (disableCorporateSelection) {
              return (
                <Tooltip
                  key={option.value}
                  arrow
                  placement="right-start"
                  title="This accounts corporate account has been sanctioned and needs to be reviewed!"
                >
                  <div
                    onClick={(e) => e.preventDefault()}
                    className="not-allowed-select-wrapper"
                  >
                    <MenuItem
                      disabled
                      key={`${option.value}-${option.label}`}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  </div>
                </Tooltip>
              )
            }
            if (disableKycSelection) {
              return (
                <Tooltip
                  key={option.value}
                  arrow
                  placement="right-start"
                  title="Can not change to the same kyc level"
                >
                  <div
                    onClick={(e) => e.preventDefault()}
                    className="not-allowed-select-wrapper"
                  >
                    <MenuItem
                      disabled
                      key={`${option.value}-${option.label}`}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  </div>
                </Tooltip>
              )
            }
            return (
              <MenuItem
                key={`${option.value}-${option.label}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            )
          })}
      </Select>
      {isBlurred && ((visited && metaError) || error) && (
        <FormHelperText>{(visited && metaError) || error}</FormHelperText>
      )}
    </Dropdown>
  )
}

export const renderDropDownField = (formValues) => {
  const {
    input: { value: inputValue, onChange },
    label,
    meta,
    options,
    error,
    width,
    fullWidth,
    margin,
    disabled,
    showNoneLabel = true,
  } = formValues
  const { error: metaError, invalid, valid } = meta
  const [touched, setTouched] = React.useState(false)
  const useStyles = makeStyles({
    menuPaper: {
      maxHeight: 200,
    },
  })
  const classes = useStyles()

  return (
    <Dropdown
      error={(invalid && !valid && metaError && touched) || error}
      width={width}
      fullWidth={fullWidth}
      margin={margin}
      variant="outlined"
      value={inputValue}
      disabled={disabled}
    >
      <MUIInputLabel>{label}</MUIInputLabel>
      <Select
        style={{ width: '100%', cursor: 'pointer' }}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label={label}
        onChange={onChange}
        value={inputValue}
        onBlur={() => setTouched(true)}
      >
        {showNoneLabel && (
          <MenuItem style={{ cursor: 'pointer' }} value="">
            <em>None</em>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem
            disabled={option.disabled}
            key={index}
            style={{ cursor: 'pointer' }}
            value={option.value}
          >
            {option.label}{' '}
            {inputValue !== option.value && option.disabledHelperText
              ? option.disabledHelperText
              : ''}
          </MenuItem>
        ))}
      </Select>
      {((invalid && !valid && metaError && touched) || error) && (
        <FormHelperText>{metaError || error}</FormHelperText>
      )}
    </Dropdown>
  )
}

export const RenderSimpleCheckbox = (props) => {
  const {
    options,
    label,
    labelWidth,
    width,
    flex,
    input: { onChange, value },
    transform,
    marginTop,
    marginLeft,
    marginRight,
    ...rest
  } = props

  return (
    <div
      flex={flex}
      direction={'column'}
      align={'flex-start'}
      width={width}
      marginRight={marginRight}
    >
      <label width={'inherit'}>Reverse Fee:</label>
      <Checkbox
        transform={transform}
        color="primary"
        marginTop={marginTop}
        marginLeft={marginLeft}
        flex={flex}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

export const DatePicker = (props) => {
  const { input, label, ...rest } = props
  const handleDateChange = (date) => {
    input.onChange(date)
  }

  const useStyles = makeStyles({
    input: {
      padding: 8,
      backgroundColor: 'transparent',
    },
  })

  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        {...input}
        {...rest}
        disableToolbar
        value={input.value || null}
        variant="inline"
        format="YYYY-MMM-DD"
        margin="normal"
        id="date-picker-inline"
        label={label}
        inputProps={{
          className: classes.input,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        onChange={handleDateChange}
      />
    </MuiPickersUtilsProvider>
  )
}

export const renderDatetime = (props) => {
  const {
    label,
    labelWidth,
    placeholder,
    top,
    input: { onChange, value },
    meta: { touched, error },
    ...rest
  } = props

  return (
    <InputGroup
      direction={'column'}
      align={'flex-start'}
      width={'100%'}
      style={{ marginTop: '7px' }}
    >
      <InputLabel width={labelWidth}>{label}:</InputLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          {...rest}
          props={props}
          value={value || null}
          id="date-picker-inline"
          placeholder={placeholder}
          top={top}
          margin="normal"
          format="YYYY-MMM-DD "
          onChange={(v) => onChange(v.format('YYYY-MM-DD'))}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      {touched && error && <InputError>{error}</InputError>}
    </InputGroup>
  )
}

export const renderCalenderDate = (props) => {
  const {
    options,
    label,
    labelWidth,
    placeholder,
    top,
    input: { onChange },
    meta: { touched, error },
    ...rest
  } = props
  const twoWeeks = 1000 * 60 * 60 * 24 * 14
  const twoWeeksTime = new Date(new Date().getTime() - twoWeeks)

  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedEndDate, setSelectedEndDate] = React.useState(null)

  const handleDateChange = (date) => {
    const realDate = date._d
    setSelectedDate(date._d)
    onChange({ realDate })
  }

  const handleEndDateChange = (date) => {
    const realDate = date._d
    setSelectedEndDate(date._d)
    onChange({ realDate })
  }

  return (
    <InputGroup
      direction={'column'}
      align={'flex-start'}
      width={'100%'}
      {...rest}
      style={{ marginTop: '7px' }}
    >
      <InputLabel width={labelWidth}>{label}:</InputLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          props={props}
          id="date-picker-inline"
          placeholder={placeholder}
          top={top}
          margin="normal"
          value={label === 'Start date' ? selectedDate : selectedEndDate}
          format="YYYY-MMM-DD "
          onChange={
            label === 'Start date' ? handleDateChange : handleEndDateChange
          }
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      {touched && error && <InputError>{error}</InputError>}
    </InputGroup>
  )
}
const useDatePickerStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 10,
      },
      '& .MuiInputBase-input': {
        padding: 18,
        paddingLeft: 8,
      },
    },
  },
})
export const renderCalenderDateTime = (props) => {
  const {
    options,
    label,
    labelWidth,
    placeholder,
    top,
    disablePast,
    input: { onChange },
    meta: { touched, error },
    ...rest
  } = props
  const classes = useDatePickerStyles()
  const [selectedDateTime, setSelectedDateTime] = React.useState(null)

  const handleDateTimeChange = (dateTime) => {
    const realDateTime = dateTime._d
    setSelectedDateTime(dateTime._d)
    onChange({ realDateTime })
  }

  return (
    <InputGroup
      direction={'column'}
      align={'flex-start'}
      width={'100%'}
      {...rest}
      style={{ marginTop: '7px' }}
    >
      <InputLabel width={labelWidth}>{label}:</InputLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDateTimePicker
          disablePast={disablePast}
          props={props}
          id="datetime-picker-inline"
          placeholder={placeholder}
          top={top}
          margin="normal"
          value={label === 'Start date' ? selectedDateTime : selectedDateTime}
          format="YYYY-MMM-DD HH:mm"
          onChange={handleDateTimeChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          className={classes.root}
        />
      </MuiPickersUtilsProvider>
      {touched && error && <InputError>{error}</InputError>}
    </InputGroup>
  )
}
