import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import { Button, FormResendVerification } from 'components/atoms/Form'

import { submit } from './submit'

let Form = props => {
  const { submitting, invalid } = props
  const { handleSubmit, onSubmit } = props
  return (
    <FormResendVerification
      style={{ backgroundColor: 'transparent' }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Button type="submit" main="true" disabled={submitting || invalid}>
        Resend Verification
      </Button>
    </FormResendVerification>
  )
}

Form = reduxForm({ form: 'resendVerification', onSubmit: submit })(Form)

const ReduxForm = connect()(Form)

export default ReduxForm
