import React, { FC, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  clearAccountPendingMultiAuthsState,
  fetchAccountPendingMultiAuthsById,
} from 'redux/accountPendingMultuAuths/actions'
import { RouteComponentProps } from 'react-router-dom'
import { AccountPendingMultiAuthsInitialState } from 'redux/accountPendingMultuAuths'
import ErrorBoundary from 'helpers/ErrorBoundary'
import LoadingBlock from 'components/molecules/LoadingBlock'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import AccountPendingMultiAuthRow from './AccountPendingMultiAuthRow'

interface AccountPendingMultiAuthsProps {
  accountPendingMultiAuths: AccountPendingMultiAuthsInitialState
  fetchAccountPendingMultiAuthsById: (id: string) => void
  clearAccountPendingMultiAuthsState: () => void
  match: RouteComponentProps<{ accountId: string }>
}

const AccountPendingMultiAuths: FC<AccountPendingMultiAuthsProps> = (props) => {
  const [expandedRow, setExpandedRow] = useState('')

  if (props.accountPendingMultiAuths.fetchingAccountPendingMultiAuths)
    return <LoadingBlock message="Loading Account Pending Multi Auths" />
  return (
    <ErrorBoundary message="Account Pending Multi Auths">
      {props.accountPendingMultiAuths.data.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Initiator Email</TableCell>
              <TableCell>Expires At</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.accountPendingMultiAuths.data.map((pendingMultiAuth) => (
              <AccountPendingMultiAuthRow
                key={pendingMultiAuth.identifier}
                pendingMultiAuth={pendingMultiAuth}
                isExpandedRow={expandedRow === pendingMultiAuth.identifier}
                setExpandedRow={setExpandedRow}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <TablePlaceholder>
          This Account Has No Pending Multi Auths.
        </TablePlaceholder>
      )}
    </ErrorBoundary>
  )
}

const mapStateToProps = (state) => ({
  accountPendingMultiAuths: state.accountPendingMultiAuths,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountPendingMultiAuthsById,
      clearAccountPendingMultiAuthsState,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPendingMultiAuths)
