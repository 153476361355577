import {
  FETCH_ACCOUNT_BALANCES_PROCESS,
  FETCH_ACCOUNT_BALANCES_SUCCESS,
  FETCH_ACCOUNT_BALANCES_ERROR,
  FETCH_ALL_ACCOUNT_BALANCES_PROCESS,
  FETCH_ALL_ACCOUNT_BALANCES_SUCCESS,
  FETCH_ALL_ACCOUNT_BALANCES_ERROR,
  FETCH_PLATFORM_BALANCES_PROCESS,
  FETCH_PLATFORM_BALANCES_SUCCESS,
  FETCH_PLATFORM_BALANCES_ERROR,
  FETCH_ACCOUNT_BALANCE_SUMMARY_PROCESS,
  FETCH_ACCOUNT_BALANCE_SUMMARY_SUCCESS,
  FETCH_ACCOUNT_BALANCE_SUMMARY_ERROR,
} from './actions'

const initialState = {
  byId: {},
  platform: {
    status: 'pristine',
    data: [],
  },
  allBalances: {
    allBalancesStatus: 'pristine',
    allBalancesData: [],
  },
  summary: {
    status: 'pristine',
    data: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_BALANCES_PROCESS: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'loading',
            data: [],
          },
        },
      }
    }
    case FETCH_ACCOUNT_BALANCES_SUCCESS: {
      const { id, data } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data,
          },
        },
      }
    }
    case FETCH_ACCOUNT_BALANCES_ERROR: {
      const { id } = action.payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            status: 'done',
            data: [],
          },
        },
      }
    }

    case FETCH_ALL_ACCOUNT_BALANCES_PROCESS: {
      return {
        ...state,
        allBalances: {
          allBalancesStatus: 'loading',
          allBalancesData: [],
        }
      }
    }
    case FETCH_ALL_ACCOUNT_BALANCES_SUCCESS: {
      const {  data } = action.payload
      return {
        ...state,
        allBalances: {
          allBalancesStatus: 'done',
          allBalancesData: data,
        },
      }
    }
    case FETCH_ALL_ACCOUNT_BALANCES_ERROR: {
      const { id } = action.payload
      return {
        ...state,
        allBalances: {
          allBalancesStatus: 'error',
          allBalancesData: []
        }
      }
    }

    case FETCH_PLATFORM_BALANCES_PROCESS: {
      return {
        ...state,
        platform: {
          status: 'loading',
          data: [],
        },
      }
    }

    case FETCH_PLATFORM_BALANCES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        platform: {
          status: 'done',
          data,
        },
      }
    }

    case FETCH_PLATFORM_BALANCES_ERROR: {
      return {
        ...state,
        platform: {
          status: 'done',
          data: [],
        },
      }
    }

    case FETCH_ACCOUNT_BALANCE_SUMMARY_PROCESS: {
      return {
        ...state,
        summary: {
          status: 'loading',
          data: []
        },
      }
    }

    case FETCH_ACCOUNT_BALANCE_SUMMARY_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        summary: {
          status: 'done',
          data
        }
      }
    }

    case FETCH_ACCOUNT_BALANCE_SUMMARY_ERROR: {
      return {
        ...state,
        summary: {
          status: 'done',
          data: []
        }
      }
    }

    default:
      return state
  }
}
