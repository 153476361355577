import {
  FETCH_WITHDRAWAL_RESTRICTIONS_PROCESS,
  FETCH_WITHDRAWAL_RESTRICTIONS_SUCCESS,
  FETCH_WITHDRAWAL_RESTRICTIONS_ERROR,

  FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_PROCESS,
  FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_SUCCESS,
  FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_ERROR,

  FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_PROCESS,
  FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_ERROR,
  FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_SUCCESS
} from './actions'

const initialState = {
  withdrawalRestrictionsStatus: 'pristine',
  withdrawalRestrictionsData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WITHDRAWAL_RESTRICTIONS_PROCESS: {
      return {
        ...state,
        withdrawalRestrictionsStatus: 'loading',
      }
    }

    case FETCH_WITHDRAWAL_RESTRICTIONS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        withdrawalRestrictionsStatus: 'done',
        error: '',
        withdrawalRestrictionsData: data,
      }
    }

    case FETCH_WITHDRAWAL_RESTRICTIONS_ERROR: {
      return {
        ...state,
        status: 'pristine',
      }
    }

    case FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_PROCESS: {
      return {
        ...state,
        withdrawalRistrictionsStatus: 'loading',
      }
    }

    case FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_SUCCESS: {
      const updatedState = {
        ...state,
      }
      const data = updatedState.withdrawalRestrictionsData.concat(action.payload.data)
      return {
        ...state,
        withdrawalRestrictionsStatus: 'done',
        withdrawalRestrictionsData: data,
      }
    }


    case FETCH_DELETED_WITHDRAWAL_RESTRICTIONS_ERROR: {
      return {
        ...state,
        withdrawalRestrictionsStatus: 'pristine',
      }
    }

    case FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_PROCESS: {
      return {
        ...state,
        withdrawalRestrictionsStatus: 'loading',
      }
    }


    case FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_SUCCESS: {
      const updatedState = {
        ...state,
      }
      const data = updatedState.withdrawalRestrictionsData.concat(action.payload.data)
      return {
        ...state,
        withdrawalRestrictionsStatus: 'done',
        withdrawalRestrictionsData: data,
      }
    }

    case FETCH_UPDATED_WITHDRAWAL_RESTRICTIONS_ERROR: {
      return {
        ...state,
        withdrawalRestrictionsStatus: 'loading',
      }
    }



    default:
      return state
  }
}
