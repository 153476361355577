import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'

import ExpandingItem from 'components/atoms/ExpandingItem'
import {
  fetchKeycloakActivity,
  clearKeycloakActivity,
} from 'redux/keycloak-activity/actions'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth/index'
import { TablePlaceholder } from 'components/atoms/Table/styles'
import FormKeycloak from 'forms/KeycloakDateRange/index'
import IconLoading from 'components/atoms/IconLoading/index'

import { GridStyle } from './styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
}))

function createData(
  time,
  clientId,
  details,
  userId,
  sessionId,
  ipAddress,
  type,
) {
  return {
    time,
    clientId,
    details,
    userId,
    sessionId,
    ipAddress,
    type,
  }
}

const KeycloakActivity = (props) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState('')
  const [expandedRow, setExpandedRow] = React.useState('')
  const {
    keycloakInfo,
    keycloakActivity: { data, status },
  } = props
  const isLoadingKeycloakActivity = status === 'loading'

  const rows = data.map((results, i) => {
    const { time, clientId, details, userId, sessionId, ipAddress, type } =
      results
    return createData(
      time,
      clientId,
      details,
      userId,
      sessionId,
      ipAddress,
      type,
    )
  })

  const setingExpand = (panel) => {
    setExpanded(panel)
  }

  const handleChange = (panel) => {
    setingExpand(panel)
  }

  const toggleSelectedRow = (id) => {
    if (id !== expandedRow) {
      setExpandedRow(id)
    } else {
      setExpandedRow('')
    }
  }

  const toggleRow = (id) => {
    toggleSelectedRow(id)
  }

  useEffect(() => {
    if (keycloakInfo.status === 'done') {
      const accId = { id: keycloakInfo.info.id }
      props.fetchKeycloakActivity(accId)
    }

    return props.clearKeycloakActivity()
  }, [keycloakInfo])

  return (
    <>
      <ExpandingItem
        title={'Keycloak Events'}
        expanded={expanded}
        onExpand={handleChange}
        expandedView={
          <>
            <GridStyle>
              <FormKeycloak
                initialValues={{
                  from: null,
                  to: null,
                }}
              />
            </GridStyle>
            {isLoadingKeycloakActivity && (
              <TablePlaceholder>
                <IconLoading />
                <span> Keycloak Info Loading, please wait</span>
              </TablePlaceholder>
            )}
            {!isLoadingKeycloakActivity && (
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      aria-label="collapsible table"
                    >
                      <TableCell>Time</TableCell>
                      <TableCell>Client Id</TableCell>
                      <TableCell>Grant Type</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>User Id</TableCell>
                      <TableCell>Session Id</TableCell>
                      <TableCell>Ip Address</TableCell>
                      <TableCell>Type</TableCell>
                      <TableBody>
                        {status === 'loading' && (
                          <TableCell colSpan={8}>
                            <TablePlaceholder>
                              <IconLoading />
                              <span>Loading, please wait</span>
                            </TablePlaceholder>
                          </TableCell>
                        )}
                        {status !== 'loading' && data.length === 0 && (
                          <TableCell colSpan={8}>
                            <TablePlaceholder>{'No events'}</TablePlaceholder>
                          </TableCell>
                        )}
                        {rows.map((row, index) => {
                          const { flag } = row
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <Fragment key={index}>
                              <TableRow>
                                <TableCell
                                  onClick={() => toggleRow(row.time)}
                                  id={labelId}
                                  scope="row"
                                >
                                  {moment(row.time).format('YYYY-MMM-DD HH:mm')}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.clientId}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.details.grant_type || 'N/A'}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.details.username || 'N/A'}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.userId}
                                </TableCell>

                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.sessionId}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.ipAddress}
                                </TableCell>
                                <TableCell onClick={() => toggleRow(row.time)}>
                                  {row.type}
                                </TableCell>
                              </TableRow>
                              {expandedRow === row.time && (
                                <TableCell colSpan={8}>
                                  <Details>
                                    <DetailsBlockWithTitle title={'Raw Data'}>
                                      <RawData data={row} title={'Raw Data'} />
                                    </DetailsBlockWithTitle>
                                  </Details>
                                </TableCell>
                              )}
                            </Fragment>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            )}
          </>
        }
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  state,
  keycloakActivity: state.keycloakActivity,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchKeycloakActivity,
      clearKeycloakActivity,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(KeycloakActivity)
