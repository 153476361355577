import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required, isNumber, moreThanZero, requiredDropdownValue } from 'forms/fieldValidators'
import {
  FormContainer,
  BlueLinkButton,
  Button,
  FormError,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import { renderDropdown, renderInput } from '../fields'
import { submit } from './submit'
import { Details, DetailsBlockWithTitle } from 'components/atoms/Details'
import DepositStatusView from 'components/organisms/ReviewStatuses'
import { hasDepositEditorRole } from 'helpers/roleBasedAccess'
import RawData from 'components/atoms/RawDataDualAuth'
import { Flexbox } from 'components/layout/index'
import { InfoLabel } from 'components/atoms/Details/index'
import Notes from 'components/atoms/Notes'

let renderAccountIdField = accountId => {
  var hasRole = hasDepositEditorRole()
  return (
    <Field
      label={'Account Id (required)'}
      name={'accountId'}
      flex={0.3}
      value={accountId || ''}
      validate={[required, isNumber, moreThanZero]}
      disabled={!hasRole}
      component={renderInput}
      margin={`0 ${BASE * 2}px 0 0`}
      justify={'space-around'}
      marginTop={26}
      labelWidth={'150px'}
      width='300px'
    />
  )
}

let Form = props => {
  const { submitting, error, invalid, anyTouched } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,
    showRawData,
    expandRawData,
    showAddNote,
    expandAddNote,
    depositId,
    statusType,
    comments,
    isInDualAuth,
    showActiveUser,
    removeActiveUser
  } = props

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  return (
    <Fragment>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        {!isInDualAuth && (
          <Flexbox
            align={'flex-start'}
            justify={'space-between'}
            direction={'row'}
          >
            <Field
              label={'Action'}
              name={'status'}
              margin={`0 ${BASE * 2}px 0 0`}
              width={'20%'}
              flex={0.4}
              validate={[requiredDropdownValue]}
              component={renderDropdown}
              options={[
                { value: 'APPROVE', label: 'Approve' },
                { value: 'IGNORE', label: 'Reject' },
              ]}
            />
            {rawData.status.match('UNKNOWN_REFERENCE') !== null && statusType !== 'IGNORE' &&
              renderAccountIdField(rawData.accountId, anyTouched)}
            <Field
              label={'Comment (required)'}
              name={'comment'}
              width={'60%'}
              margin={`0 ${BASE * 2}px 26px 0`}
              validate={[required]}
              component={renderInput}
              labelWidth={'150px'}
            />
            <div style={{ width: '286px', marginTop: '16px' }}>
              <Button type="submit" disabled={submitting || invalid} margin={`0 ${BASE * 2}px 0 0`}>
                Submit
              </Button>
              <Button padding={'16px 41px'} onClick={onCancel} color='secondary'>Cancel</Button>
            </div>
          </Flexbox>
        )}
        <Details>
          <DetailsBlockWithTitle title="Statuses">
            <DepositStatusView statuses={rawData.statuses} />
          </DetailsBlockWithTitle>
        </Details>
        <Details>
          <DetailsBlockWithTitle title="Notes">
            <Notes depositId={depositId} comments={comments} />
          </DetailsBlockWithTitle>
        </Details>
        <Flexbox
          direction={'column'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <Flexbox
            style={{ color: 'white' }}
            direction={'row'}
            align={'flex-start'}
            justify={'space-around'}
          >
            <BlueLinkButton marginRight={'50px'} marginBottom={'20px'} onClick={showRawData}>
              {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
            </BlueLinkButton>
            <BlueLinkButton marginRight={'50px'} marginBottom={'20px'} onClick={showAddNote}>
              {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
            </BlueLinkButton>
          </Flexbox>
          {expandRawData && (
            <Fragment>
              <Flexbox
                marginBottom={'10'}
                marginTop={'10'}
                direction={'column'}
                align={'flex-start'}
                justify={'space-around'}
              >
                <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
                <RawData data={rawData} />
              </Flexbox>
            </Fragment>
          )}
        </Flexbox>

        {error && <FormError error={error} />}
      </FormContainer>
    </Fragment>
  )
}

Form = reduxForm({ onSubmit: submit })(Form)

const mapStateToProps = (state, ownProps) => {
  const creditAccountForm = state.form[ownProps.form]

  if (creditAccountForm === undefined) {
    return {
      statusType: '',
    }
  }
  const {
    values: { status },
  } = creditAccountForm
  return {
    statusType: status.value,
  }
}

const ReduxForm = connect(mapStateToProps)(Form)

export default ReduxForm
