import * as React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

export default function CircularSpinner({ color = '', size = 40 }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={size} style={{ color }} />
    </Box>
  )
}
