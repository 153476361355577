import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {TablePlaceholder} from '../../../components/atoms/Table/styles'
import {fetchKeycloakAdminGroups} from '../../../redux/keycloak-groups/actions'
import LoadingBlock from '../../../components/molecules/LoadingBlock'
import GetKeycloakUser from '../../../forms/GetKeycloakUser'
import {resetUserInfo} from '../../../redux/keycloak/actions'
import FerryAuths from '../../FerryAuths'
import {ContentContainer} from "../../../components/atoms/Generic";
import KeycloakGroupMembershipForm from "../../../forms/KeycloakGroupMembershipForm";

const KeycloakGroupMembership = props => {
  const {
    isFetchingKeycloakUser,
    keycloakGroups,
    keycloakUser,
  } = props

  useEffect(() => {
    props.fetchKeycloakAdminGroups()
    return () => {
      props.resetUserInfo()
    }
  }, [])

  const foundUser = keycloakUser && keycloakUser.info

  return (
    <>
      <h1>Keycloak</h1>
      <GetKeycloakUser/>
      <ContentContainer bgcolor={'0px solid transparent'}>
        {isFetchingKeycloakUser && <LoadingBlock/>}
        {!isFetchingKeycloakUser && !foundUser && !!keycloakUser.status && (
          <TablePlaceholder>{'User does not exist'}</TablePlaceholder>
        )}
        {foundUser && !isFetchingKeycloakUser && (
          <KeycloakGroupMembershipForm keycloakAdminGroups={keycloakGroups} keycloakUser={keycloakUser}/>
        )}
        {!isFetchingKeycloakUser && !foundUser && !keycloakUser.status && (
          <TablePlaceholder>{'Enter email to search for user'}</TablePlaceholder>
        )}
      </ContentContainer>
      <FerryAuths ferryAuthRequestType={'add_keycloak_user_to_group'}/>
    </>)
}

const mapStateToProps = state => ({
  keycloakGroups: state.keycloakGroups,
  keycloakUser: state.keycloak.byEmail,
  isFetchingKeycloakGroups: state.loading,
  isFetchingKeycloakUser: state.keycloak.byEmail.status === 'loading',
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchKeycloakAdminGroups,
      resetUserInfo,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeycloakGroupMembership)
