import {
  FETCH_IGNORED_DEPOSITS_PROCESS,
  FETCH_IGNORED_DEPOSITS_SUCCESS,
  FETCH_IGNORED_DEPOSITS_ERROR,
  SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_START,
  SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_ERROR,
  SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_SUCCESS,
  UPDATE_REMOVAL_IGNORED_STATUS_DUAL_AUTH_ROW_USER_SUCCESS,
  REMOVE_SUBMITTED_IGNORED_DUAL_AUTH,
} from './actions'

const initialState = {
  byId: {},
  ignoredDeposits: [],
  ignoredDepositsStatus: 'pristine',
  isRemovingIgnoredStatus: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IGNORED_DEPOSITS_PROCESS: {
      return {
        ...state,
        ignoredDepositsStatus: 'loading',
        ignoredDeposits: [],
      }
    }
    case FETCH_IGNORED_DEPOSITS_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        ignoredDepositsStatus: 'done',
        ignoredDeposits: data,
      }
    }

    case FETCH_IGNORED_DEPOSITS_ERROR: {
      return {
        ...state,
      }
    }

    case SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_START: {
      return {
        ...state,
        isRemovingIgnoredStatus: true,
      }
    }
    case SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_SUCCESS: {
      return {
        ...state,
        isRemovingIgnoredStatus: false,
      }
    }

    case UPDATE_REMOVAL_IGNORED_STATUS_DUAL_AUTH_ROW_USER_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.ignoredDeposits &&
        state.ignoredDeposits.map(ignoredDepositDualAuth => {
          if (data && data.length > 0) {
            data.forEach(newAuth => {
              if (
                newAuth.id === ignoredDepositDualAuth.id &&
                data[0].userActive
              ) {
                ignoredDepositDualAuth.active = {
                  userActive: true,
                  activePersonName: newAuth.userName,
                }
              } else {
                ignoredDepositDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            ignoredDepositDualAuth.active = {
              userActive: false,
              activePersonName: '',
            }
          }
          return ignoredDepositDualAuth
        })
      return {
        ...state,
        ignoredDeposits: updatedDualData,
      }
    }

    case REMOVE_SUBMITTED_IGNORED_DUAL_AUTH: {
      const filteredArray = state.ignoredDeposits.filter(
        item => item.id !== action.payload.id,
      )
      return {
        ...state,
        ignoredDeposits: filteredArray,
      }
    }

    case SUBMIT_DUAL_AUTH_REMOVE_IGNORE_STATUS_ERROR: {
      return {
        ...state,
        isRemovingIgnoredStatus: false,
      }
    }
    default:
      return state
  }
}
