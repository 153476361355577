import React from 'react'

import { TableCell, TableHeader } from 'components/atoms/Table/styles'

export const Headers = (props) => {
  const { expandItem } = props
  return (
    <TableHeader onClick={expandItem}>
      <TableCell width={'10%'}>Account Id</TableCell>
      <TableCell width={'10%'} marginRight={'1%'} align={'right'}>Amount</TableCell>
      <TableCell width={'20%'}>Currency Symbol</TableCell>
      <TableCell width={'25%'}>Internal Reference</TableCell>
      <TableCell width={'25%'}>Created At</TableCell>
      <TableCell width={'10%'}/>

    </TableHeader>
  )
}
