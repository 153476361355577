// @ts-nocheck
import {
  FETCH_ADDRESS_BOOK_START,
  FETCH_ADDRESS_BOOK_SUCCESS,
  FETCH_ADDRESS_BOOK_ERROR,
  UPDATE_ADDRESS_BOOK_DUAL_AUTH_ROW_USER_SUCCESS,
  REMOVE_SUBMITTED_WITHDRAWAL_ADDRESS_BOOK_DUAL_AUTH,
} from './actions'

const initialState = {
  addressBookStatus: 'pristine',
  addressBookData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESS_BOOK_START: {
      return {
        ...state,
        addressBookStatus: 'loading',
        addressBookData: [],
      }
    }

    case FETCH_ADDRESS_BOOK_ERROR: {
      return {
        ...state,
        addressBookStatus: 'error',
        addressBookData: [],
      }
    }

    case FETCH_ADDRESS_BOOK_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        addressBookStatus: 'done',
        addressBookData: data,
      }
    }

    case UPDATE_ADDRESS_BOOK_DUAL_AUTH_ROW_USER_SUCCESS: {
      const { data } = action.payload
      const updatedDualData =
        state.addressBookData &&
        state.addressBookData.map((addressBookDualAuth) => {
          if (data && data.length > 0) {
            data.forEach((newUserOn) => {
              if (
                newUserOn.id === addressBookDualAuth.id &&
                newUserOn.userActive
              ) {
                addressBookDualAuth.active = {
                  userActive: true,
                  activePersonName: data.find(
                    (userOnLine) =>
                      userOnLine.id === addressBookDualAuth.id &&
                      userOnLine.userActive,
                  ).userName,
                }
              } else {
                addressBookDualAuth.active = {
                  userActive: false,
                  activePersonName: '',
                }
              }
            })
          } else {
            addressBookDualAuth.active = {
              userActive: false,
              activePersonName: '',
            }
          }
          return addressBookDualAuth
        })
      return {
        ...state,
        addressBookData: updatedDualData,
      }
    }

    case REMOVE_SUBMITTED_WITHDRAWAL_ADDRESS_BOOK_DUAL_AUTH: {
      const filteredArray = state.addressBookData.filter(
        (item) => item.id !== action.payload.id,
      )
      return {
        ...state,
        addressBookData: filteredArray,
      }
    }
    default:
      return state
  }
}
