import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { WHITE, DARK_BLUE, BLUE } from 'theme/colors'
import { BASE } from 'theme/base-units'
import { Tabs, Tab, TabProps } from '@material-ui/core'

import { ForwardedNavLink } from '../ForwardedNavlink'

interface NavigationTabProps {
  bgcolor?: string
  color?: string
  component?: NavLink
  to?: string
}

export const MenuItemContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: ${({ active = false }) =>
    active ? DARK_BLUE : 'transparent'};
  &:hover {
    background-color: ${DARK_BLUE};
    &:before {
      content: '';
      display: block;
      width: ${BASE / 2}px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: WHITE;
      bottom: 0;
    }
  }

  &:before {
    content: '';
    display: block;
    width: ${BASE / 2}px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ active = false }) =>
    active ? WHITE : 'transparent'};
    bottom: 0;
  }
`

const activeClassName = 'menu-item_active'

export const MainContainer = styled.div``

export const MainItem = styled(ForwardedNavLink).attrs({
  activeClassName,
})`
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  color: ${WHITE};
  font-size: ${2.5 * BASE}px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  padding: ${BASE * 3}px 0;
  padding-left: ${BASE * 4}px;
  & .fas {
    margin-right: ${BASE * 1.5}px;
  }
`

const getMenuItemSize = () => `${BASE * 2}px`

export const SubItem = styled(ForwardedNavLink).attrs({
  activeClassName,
})`
  &:before {
    content: '';
    display: block;
    width: ${BASE}px;
    height: ${BASE}px;
    border-radius: 50%;
    margin-right: ${BASE}px;
    background-color: transparent;
    position: relative;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${getMenuItemSize()};
  margin-left: ${({ level = 0 }: any) => `${(level + 3) * BASE}px`};
  text-decoration: none;
  padding: ${BASE}px;
  color: ${WHITE};
  position: relative;
  opacity: 0.6;
  &.${activeClassName} {
    opacity: 1;
    :before {
      background-color: ${WHITE};
    }
  }
`

export const TopNavigationBar = styled(Tabs)<{ bgcolor?: string }>`
  background-color: ${({ theme }) => theme.navigationTabs.background};
  .MuiTabs-indicator {
    background-color: ${WHITE};
  }
`

export const NavigationTab = styled(Tab)<NavigationTabProps & TabProps>`
  background-color: ${({ bgcolor = 'transparent' }) => bgcolor};
  color: ${({ color = WHITE }) => color};
  opacity: ${({ bgcolor }) => (bgcolor ? '1' : '0.5')};
  width: auto;
  min-width: min-content;
  :hover {
    background-color: #3550b2;
  }
`
