import React from 'react'
import { TableCell, TableHeader } from 'components/atoms/Table/styles'
import { RenderCheckbox } from 'forms/fields'
import { TransitionCell } from '../Styles'

export const Headers = props => {
  const {
    bulkActionsActive, isCellHidden, data, getAllSelectedDeposits, toggleSelectAll, selected, title,
  } = props
  const allDepositIds = data && getAllSelectedDeposits(data, title)
  return (
    <TableHeader style={{ overflow: 'hidden' }} >
      <TableCell flex={0.5}>Account Id</TableCell>
      <TableCell flex={0.6}>Source</TableCell>
      <TableCell flex={0.6} align={'left'}>
        Amount
      </TableCell>
      <TableCell flex={0.8}>Currency</TableCell>
      <TableCell flex={2.4}>Reference</TableCell>
      <TableCell flex={0.8}>Bank Date</TableCell>
      <TableCell flex={0.9} />
      <TableCell flex={0.18} />
      <TransitionCell
        className={bulkActionsActive ? 'suspendActive' : 'suspendFade'}
        show={isCellHidden}
        flex={0.7}
      >
        <RenderCheckbox
          onCheckFunction={() => toggleSelectAll(allDepositIds)}
          checked={selected.length === allDepositIds.length && allDepositIds.length > 0}
          label={'Select All'}
        />
      </TransitionCell>
    </TableHeader>
  )
}
