import React, { Component, Fragment } from 'react'
import { TableRow, TableCell } from 'components/atoms/Table/styles'
import auth from 'services/Auth'
import FormDepositReviewByStatus from 'forms/DepositByStatus/index'
import BigNumber from 'bignumber.js'
import RawData from 'components/atoms/RawDataDualAuth/index'
import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
} from 'components/atoms/Details'
import ReviewStatuses from 'components/organisms/ReviewStatuses'
import { fetchDepositComments } from 'redux/depositsComments/actions'
import FormDepositsComments from 'forms/DepositsComments/index'
import { Link } from '../AccountIdLink/clickableId'

class Row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      commentsStatus: 'persist',
      formShown: false,
    }
    this.toggleForm = this.toggleForm.bind(this)
  }

  updateComments = async (depositId) => {
    this.setState({ commentsStatus: 'loading' })
    const data = await fetchDepositComments(depositId)
    this.setState({ comments: data, commentsStatus: 'done' })
  }

  refineRawData = rawData => {
    const { source } = rawData
    rawData.source = source.replace(/\W\d*/g, ' ').trim()
    return rawData
  }

  toggleForm() {
    this.setState({ formShown: !this.state.formShown })
    this.updateComments(this.props.deposit.depositId)
  }

  renderAddNoteForm = () => {
    const {
      resetForm,
      deposit: { depositId },
      toggleAddNotesForm,
    } = this.props
    return (
      <FormDepositsComments
        initialValues={{
          depositId,
          data: {
            date: new Date(),
            createdBy: auth.decodeNameToken(),
            depositsId: depositId,
          },
        }}
        form={'depositCommentsForm'}
        depositData={this.props.deposit}
        onCancel={resetForm}
        toggleForm={() => {
          toggleAddNotesForm(depositId)
          this.updateComments(depositId)
        }}
      />
    )
  }

  creditDeposits = deposit =>
    deposit.statuses.findIndex(
      element =>
        element.status === 'ACCOUNT_CREDITED' ||
        element.status === 'SUBMITTED_FOR_CREDITING' ||
        element.status === 'IGNORED' ||
        element.status === 'EXCLUDED',
    ) !== -1

  render() {
    const {
      deposit: {
        depositId,
        accountId,
        source,
        amount,
        description,
        dateTime,
        insertedAt,
        tag,
      },
      isPending,
    } = this.props
    const { formShown } = this.state
    const formatedAmount = new BigNumber(amount).toFormat(2)
    return (
      <Fragment>
        <TableRow
          style={{ color: isPending ? '#A8A8A8' : '' }}
          onClick={this.toggleForm}
        >
          <TableCell width={'15%'}>
            <Link id={accountId} />
          </TableCell>
          <TableCell width={'15%'}>{source}</TableCell>
          <TableCell
            style={{ marginRight: '5%' }}
            align={'right'}
            width={'15%'}
          >
            {formatedAmount}
          </TableCell>
          <TableCell width={'20%'}>{description}</TableCell>
          <TableCell width={'15%'}>{dateTime}</TableCell>
          <TableCell width={'15%'}>{insertedAt}</TableCell>
          <TableCell width={'1%'} align={'center'} color={'#e96868'}>
            {tag && (
              <i
                className="fa fa-sticky-note"
                aria-hidden="true"
              />
            )}
          </TableCell>
        </TableRow>
        {formShown && (
          <Details>
            <DetailsBlockWithTitle title={'Details'}>
              <InfoBlock label={'Id'} value={depositId} />
              <InfoBlock label={'Amount'} value={amount} />
              <InfoBlock label={'Source'} value={source} />
              <InfoBlock label={'Reference'} value={description} />
              <InfoBlock label={'Timestamp'} value={insertedAt} />
            </DetailsBlockWithTitle>
          </Details>
        )}
        {formShown &&
          !this.creditDeposits(this.props.deposit)

        }
        {formShown &&
          !this.creditDeposits(this.props.deposit) && (
            <FormDepositReviewByStatus
              initialValues={{
                depositId,
                accountId: accountId || '',
                comment: '',
                status: '',
                selectedOption: this.props.selectedOption,
              }}
              depositId={depositId}
              expandRawData={this.props.expandRawData}
              expandAddNote={this.props.addNoteFormShown}
              showRawData={() => this.props.showRawData(depositId)}
              form={`depositReviewByStatusForm+${depositId}`}
              rawData={this.refineRawData(this.props.deposit)}
              onCancel={this.props.toggleForm}
              showAddNote={() => this.props.toggleAddNotesForm(depositId)}
              isInDualAuth={isPending}
              comments={{ depositComments: this.state.comments, status: this.state.commentsStatus }}
            />)}
        {this.props.addNoteFormShown && this.renderAddNoteForm()}
        {formShown &&
          this.creditDeposits(this.props.deposit) && (
            <div>
              <Details>
                <DetailsBlockWithTitle title={'Raw Data'}>
                  <RawData data={this.props.deposit} title={'Raw Data'} />
                </DetailsBlockWithTitle>
              </Details>
              <Details>
                <DetailsBlockWithTitle title="Status">
                  <ReviewStatuses
                    depositId={depositId}
                    statuses={this.props.deposit.statuses}
                    closeOuterForm={this.props.toggleForm}
                    accountId={accountId}
                  />
                </DetailsBlockWithTitle>
              </Details>
            </div>
          )}
      </Fragment>
    )
  }
}

export default Row
