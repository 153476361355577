import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FilterListIcon from '@material-ui/icons/FilterList'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { WHITE } from 'theme/colors'
import { Flexbox } from 'components/layout'
import moment from 'moment'

import {
  fetchDepositStatuses,
  fetchDepositByStatus,
  clearDepositByStatus,
  fetchDepositReview,
  fetchNewDeposits,
  setFiatFilters,
  updateNewFiatDeposits,
  updateFiat,
} from 'redux/fiat/actions'
import FormDepositDateRange from 'forms/DateRange/index'
import socket from 'redux/socket/index'

import { Col, Grid, Row } from './styles'
import DepositByStatus from './depositByStatus'
import SelectOuter from './SelectOuter'

import { getFiatConfig } from '../../../redux/fiat/selectors'
import { Spacer } from 'components/atoms/Generic'

const Item = ({ hidden, children, ...props }) => {
  if (hidden) return null
  return (
    <Col justifyContent="flex-start" alignItems="flex-end" {...props}>
      {children}
    </Col>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  margin-bottom: 10px;
  justify-items: flex-end;
  align-content: flex-end;
  align-items: center;
  padding-top: 4px;
`

class SearchDepositByStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: 'Select Filter',
      searching: false,
    }
  }

  componentDidMount() {
    this.props.fetchDepositStatuses()
    // Default to ZAR on mount
    this.props.setFiatFilters({
      currency: 'ZAR',
    })
    const busyDeposits = socket.on('busy deposits', (data) => {
      this.props.updateFiat(data)
    })
    const updatedOnMount = socket.on('status updated', (data) => {
      this.props.updateFiat(data)
    })

    const updateDepositOnMount = socket.on(
      'status updated on unmount',
      (data) => {
        this.props.updateFiat(data)
      },
    )
    const depositStatus = socket.on('deposit status updated', (data) => {
      this.props.updateFiat(data)
    })
  }

  getStatuses = (statuses) =>
    statuses
      .sort((a, b) => (a.type > b.type ? 1 : -1))
      .map((status) => (
        <MenuItem key={status.type} value={status.type}>
          {status.type}
        </MenuItem>
      ))

  handleChange = async (e) => {
    this.props.clearDepositByStatus()
    this.setState({ selectedOption: e })
    await this.props.fetchDepositByStatus({
      status: e,
      skip: 0,
      limit: 100,
    })
  }

  clear = () => {
    this.setState({ selectedOption: 'Select Filter', searching: false })
  }

  refresh = () => {
    this.props.fetchDepositReview()
    this.props.fetchNewDeposits()
  }

  showCurrencies = () =>
    this.props.currencies.map((currency) => (
      <MenuItem
        key={currency.shortName}
        value={currency.shortName}
        disabled={this.props.isLoading}
      >
        {currency.longName}
      </MenuItem>
    ))

  changeCurrency = (newCurrency) => {
    this.props.setFiatFilters({ currency: newCurrency })
  }

  showSelect = () => {
    this.setState({ searching: true })
  }

  render() {
    const {
      depositStatuses: { status, statuses },
      depositByStatus,
      filters,
      setFiatFilters,
    } = this.props
    const { currency, from, to } = filters
    const { selectedOption, searching } = this.state
    return (
      <>
        <Flexbox direction="row" align="flex-end">
          <Item hidden={status !== 'done'} size={2}>
            {!searching && (
              <SelectOuter label={'Currencies'}>
                <Flexbox>
                  {this.props.currencies.map((c) => {
                    return (
                      <Spacer margin="3px 12px 0px 0px">
                        <MuiButton
                          onClick={() => {
                            this.changeCurrency(c.shortName)
                          }}
                          disabled={this.props.isLoading}
                          style={{
                            backgroundColor:
                              currency === c.shortName ? 'green' : '',
                          }}
                        >
                          {c.longName}
                        </MuiButton>
                      </Spacer>
                    )
                  })}
                  <Spacer margin="3px 12px 0px 0px">
                    <MuiButton
                      onClick={this.showSelect}
                      startIcon={<FilterListIcon style={{ color: WHITE }} />}
                    >
                      Filter
                    </MuiButton>
                  </Spacer>
                </Flexbox>
              </SelectOuter>
            )}
          </Item>
        </Flexbox>
        <Flexbox>
          <Item hidden={status !== 'done' || !searching} size={2}>
            <SelectOuter label={'Filter'}>
              <Select
                value={selectedOption}
                id="status-select"
                style={{ width: '90%' }}
                onChange={({ target: { value } }) => {
                  this.handleChange(value)
                }}
              >
                {this.getStatuses(statuses)}
              </Select>
            </SelectOuter>
          </Item>
          <Item size={1} hidden={status !== 'done' || !searching}>
            <ButtonContainer>
              <MuiButton onClick={this.clear} color="secondary">
                Cancel
              </MuiButton>
            </ButtonContainer>
          </Item>
        </Flexbox>
        <Flexbox>
          <Item hidden={status !== 'done' || searching} size={4}>
            <FormDepositDateRange
              initialValues={{
                from: from
                  ? moment(from).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD'),
                to: to
                  ? moment(to).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD'),
              }}
              onSubmit={async (values) => setFiatFilters(values)}
            />
          </Item>
        </Flexbox>
        <Item
          hidden={
            status !== 'done' ||
            !searching ||
            selectedOption === 'Select Filter'
          }
          style={{ width: '100%' }}
        >
          <DepositByStatus
            selectedOption={selectedOption}
            depositByStatus={depositByStatus}
          />
        </Item>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  depositStatuses: state.fiat.depositStatuses,
  depositByStatus: state.fiat.depositsByStatus,
  filters: state.fiat.filters,
  currencies: getFiatConfig(state).currencies,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDepositStatuses,
      fetchDepositByStatus,
      clearDepositByStatus,
      fetchDepositReview,
      fetchNewDeposits,
      updateFiat,
      setFiatFilters,
      updateNewFiatDeposits,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchDepositByStatus)
