import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import { rejectDeposit, approveDeposit } from 'redux/creditCard/actions'

const STATUSVALUES = {
  accept: 'APPROVE',
  reject: 'DECLINE',
}

const checkStatusValue = status => status === STATUSVALUES.accept

export const submit = async (values, dispatch) => {
  let log
  const { accountId, identifier, status, comment } = values
  const payload = {
    accountId,
    identifier,
    status: status.value,
    comment,
  }
  try {
    const res = await getAxios().post(
      '/credit-card/update/deposits/review',
      payload,
    )
    if (res.data.statusCode === 200) {
      if (checkStatusValue(status.value)) {
        log = 'Deposit successfully Approved '
        dispatch(approveDeposit(identifier))
      } else {
        log = 'Deposit rejected'
        dispatch(rejectDeposit(identifier))
      }
      toast(log, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      toast(res.data.message, { type: toast.TYPE.ERROR, autoClose: 4500 })
    }
  } catch (error) {
    toast(`${error.response.data.message}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
