import moment from 'moment'
import { toast } from 'react-toastify'

export const autoDownloadFile = (blob: Blob, statementType: string) => {
  const timestamp = moment().format('DDMMYYYYHHmm')
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `VALR_${statementType}_${timestamp}.pdf`)
  document.body.appendChild(link)
  link.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(link)
  toast(`${statementType} downloaded successfully`, {
    type: toast.TYPE.SUCCESS,
    autoClose: 2000,
  })
}