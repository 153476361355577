import React, { useState } from 'react'

import { Table, TableBody } from 'components/atoms/Table/styles'
import { Headers } from './headers'
import Row from './row'

const WithdrawalRestrictionsTable = props => {
  const [selectedRow, setSelectedRow] = useState('')
  const toggleSelectedRow = (uniqueId) => setSelectedRow((prev) => prev === uniqueId ? '' : uniqueId)
  const {
    expandItem,
    data,
  } = props
  return (
    <Table>
      <Headers expandItem={expandItem} />
      <TableBody>
        {data.map(restrictions => (
          <Row
            key={restrictions.id}
            dualAuthId={restrictions.id}
            withdrawalRestrictionInfo={restrictions}
            isSelected={selectedRow === restrictions.id}
            toggleSelected={toggleSelectedRow}
          />
        ))}
      </TableBody>
    </Table>
  )
}


export default WithdrawalRestrictionsTable
