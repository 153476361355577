import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { DetailsBlockWithTitle, InfoBlock } from 'components/atoms/Details'
import { bindActionCreators } from 'redux'
import { fetchFiatWithdrawalsDashboardStats } from 'redux/fiatWithdrawalsDashboardStats/actions'
import { FiatWithdrawalsDashboardStatsInitialState } from 'redux/fiatWithdrawalsDashboardStats'
import StatusIconIndicator from 'components/atoms/StatusIconIndicator'
import LoadingBlock from 'components/molecules/LoadingBlock'
import { StyledFiatWithdrawalsDashboardStats } from './Styles'

interface FiatWithdrawalsDashboardStatsProps {
  fiatWithdrawalsDashboardStats: FiatWithdrawalsDashboardStatsInitialState
  fetchFiatWithdrawalsDashboardStats: () => void
}

const FiatWithdrawalsDashboardStats: FC<FiatWithdrawalsDashboardStatsProps> = (
  props,
) => {
  const { fiatWithdrawalsDashboardStats } = props
  const convertDateDisplay = (date: string) => {
    const parsed = date.split('[')
    return new Date(parsed[0]).toDateString() + `[${parsed[1]}`
  }

  useEffect(() => {
    props.fetchFiatWithdrawalsDashboardStats()
  }, [])

  if (props.fiatWithdrawalsDashboardStats.status === 'pending')
    return <LoadingBlock message="Fetching Fiat Withdrawals Dashboard Stats" />

  return (
    <StyledFiatWithdrawalsDashboardStats>
      {fiatWithdrawalsDashboardStats.data.stats.map((stat) => (
        <DetailsBlockWithTitle
          key={stat.currencyCode}
          title={`${stat.currencyCode} Fiat Withdrawals`}
        >
          <InfoBlock label={'Currency Code'} value={stat.currencyCode} />
          <InfoBlock
            label={'Last submitted withdrawal timestamp'}
            value={convertDateDisplay(stat.lastSubmittedWithdrawalTimestamp)}
          />
        </DetailsBlockWithTitle>
      ))}
      <DetailsBlockWithTitle title="Health Z">
        <InfoBlock
          align="center"
          label="Future Public Holidays Ready Status"
          value={
            <StatusIconIndicator
              status={
                fiatWithdrawalsDashboardStats.data.healthZStats
                  .standardBankConnected
              }
              height={40}
              width={40}
            />
          }
        />
        <InfoBlock
          align="center"
          label="Standard Bank Connected Status"
          value={
            <StatusIconIndicator
              status={
                fiatWithdrawalsDashboardStats.data.healthZStats
                  .standardBankConnected
              }
              height={40}
              width={40}
            />
          }
        />
      </DetailsBlockWithTitle>
    </StyledFiatWithdrawalsDashboardStats>
  )
}
const mapStateToProps = (state) => ({
  fiatWithdrawalsDashboardStats: state.fiatWithdrawalsDashboardStats,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFiatWithdrawalsDashboardStats,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiatWithdrawalsDashboardStats)
