import ErrorToast from 'components/molecules/ErrorToast/index'
import { toast } from 'react-toastify'
import getAxios from 'helpers/axios'

export const FETCH_MARGIN_REQUIREMENTS_PROCESS = 'FETCH_MARGIN_REQUIREMENTS_PROCESS'
export const FETCH_MARGIN_REQUIREMENTS_SUCCESS = 'FETCH_MARGIN_REQUIREMENTS_SUCCESS'
export const FETCH_MARGIN_REQUIREMENTS_ERROR = 'FETCH_MARGIN_REQUIREMENTS_ERROR'

export const SAVE_MARGIN_REQUIREMENTS_START = 'SAVE_MARGIN_REQUIREMENTS_START'
export const SAVE_MARGIN_REQUIREMENTS_SUCCESS = 'SAVE_MARGIN_REQUIREMENTS_SUCCESS'
export const SAVE_MARGIN_REQUIREMENTS_ERROR = 'SAVE_MARGIN_REQUIREMENTS_ERROR'

export const FETCH_INTEREST_AUCTION_CONFIG_PROCESS = 'FETCH_INTEREST_AUCTION_CONFIG_PROCESS'
export const FETCH_INTEREST_AUCTION_CONFIG_SUCCESS = 'FETCH_INTEREST_AUCTION_CONFIG_SUCCESS'
export const FETCH_INTEREST_AUCTION_CONFIG_ERROR = 'FETCH_INTEREST_AUCTION_CONFIG_ERROR'

export const FETCH_LIQUIDATION_SETTINGS_PROCESS = 'FETCH_LIQUIDATION_SETTINGS_PROCESS'
export const FETCH_LIQUIDATION_SETTINGS_SUCCESS = 'FETCH_LIQUIDATION_SETTINGS_SUCCESS'
export const FETCH_LIQUIDATION_SETTINGS_ERROR = 'FETCH_LIQUIDATION_SETTINGS_ERROR'

export const fetchMarginRequirements = () => async dispatch => {
  dispatch({
    type: FETCH_MARGIN_REQUIREMENTS_PROCESS,
  })

  try {
    const { data } = await getAxios().get('/admin/margin-settings/margin-requirements')
    dispatch({
      type: FETCH_MARGIN_REQUIREMENTS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_MARGIN_REQUIREMENTS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchInterestAuctionConfig = () => async dispatch => {
  dispatch({
    type: FETCH_INTEREST_AUCTION_CONFIG_PROCESS,
  })

  try {
    const { data } = await getAxios().get('/admin/margin-settings/interest-auction-config')
    dispatch({
      type: FETCH_INTEREST_AUCTION_CONFIG_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_INTEREST_AUCTION_CONFIG_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const fetchLiquidationSettings = () => async dispatch => {
  dispatch({
    type: FETCH_LIQUIDATION_SETTINGS_PROCESS,
  })

  try {
    const { data } = await getAxios().get('/admin/margin-settings/liquidation-engine-settings')
    dispatch({
      type: FETCH_LIQUIDATION_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    ErrorToast(error)
    dispatch({
      type: FETCH_LIQUIDATION_SETTINGS_ERROR,
      payload: {
        error,
      },
    })
  }
}

export const saveMarginRequirementsSettings = (marginRequirements) => async dispatch => {
  try {
    dispatch({
      type: SAVE_MARGIN_REQUIREMENTS_START,
    })
    const {
      autoCloseMarginMultiple,
      leverageMultiple,
      maintenanceMarginMultiple,
      referenceCurrency,
    } = marginRequirements

    if (referenceCurrency === 'zar' || referenceCurrency === 'ZAR' ||
     referenceCurrency === 'R' || referenceCurrency === 'r' || referenceCurrency === 'USDC' || referenceCurrency === 'usdc') {
      const { data } = await getAxios().put(
        '/admin/margin-settings/update-margin-requirements',
        {
          autoCloseMarginMultiple,
          leverageMultiple,
          maintenanceMarginMultiple,
          referenceCurrency,
        },
      )
      toast('Info successfully updated', {
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      })
      dispatch({
        type: SAVE_MARGIN_REQUIREMENTS_SUCCESS,
        payload: data,
      })
    } else {
      toast('Currency reference update not supported', {
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      })
    }
  } catch (error) {
    ErrorToast(error, {
      type: toast.TYPE.ERROR,
      autoClose: 4500,
    })
    dispatch({
      type: SAVE_MARGIN_REQUIREMENTS_ERROR,
      error: error.message || 'Error occurred when saving margin requirements',
    })
  }
}
