import { Text } from '@react-pdf/renderer'
import React, { FC, Fragment } from 'react'
import { styles } from './Styles'

interface FooterProps {
  date: string
  disclaimer: string
}
interface EmptyStatementTableProps {
  type: string
}

export const Footer: FC<FooterProps> = ({ date, disclaimer }) => (
  <Fragment>
    <Text>Statement Date: {date} | Contact Us: support@valr.com</Text>
    <Text style={styles.disclaimer}>{disclaimer}</Text>
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} of ${totalPages}`
      }
    />
  </Fragment>
)

export const EmptyStatementTable: FC<EmptyStatementTableProps> = ({
  type = 'transactions',
}) => (
  <Fragment>
    <Text style={styles.placeHolder}>No {type} available on statement date.</Text>
  </Fragment>
)
