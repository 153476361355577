import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NavLink, Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic/index'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import socket from 'redux/socket/index'
import { disconnectSocket } from 'redux/fiat/actions'

import SearchAccount from '../SearchAccount/SearchAccount'
import EmailStatus from './SearchEmailHistoryByEmail'
import AllRequestLogger from './AllRequest'
import Logs from './HttpLogs'
import Kyc from './Kyc'
import SpamList from './RemoveSpamList'

const routes = [
  '/accounts/search',
  '/accounts/kyc/',
  '/accounts/email-activity',
  '/accounts/requests',
  '/accounts/http-logs',
  '/accounts/spam-list',
]

const AccountsView = props => {
  const {
    location: { pathname },
  } = props
  const [currentPage, setCurrentPage] = useState(routes[0])

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', disconnectSocket)
    socket.connect()
  }, [])

  useEffect(
    () => () => {
      disconnectSocket()
      window.removeEventListener('beforeunload', disconnectSocket)
    },
    [],
  )

  useEffect(
    () => {
      const route = routes.find(rt => pathname.includes(rt))
      if (route) {
        setCurrentPage(route)
      }
    },
    [pathname],
  )

  return (
    <>
      <h1>Accounts</h1>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          to={routes[0]}
          component={ForwardedNavLink}
          label="Search"
        />
        <NavigationTab
          value={routes[1]}
          to={routes[1]}
          component={ForwardedNavLink}
          label="KYC"
        />
        <NavigationTab
          value={routes[2]}
          to={routes[2]}
          component={ForwardedNavLink}
          label="Email Activity"
        />
        <NavigationTab
          value={routes[3]}
          to={routes[3]}
          component={ForwardedNavLink}
          label="Admin History"
        />
        <NavigationTab
          value={routes[4]}
          to={routes[4]}
          component={ForwardedNavLink}
          label="Logs"
        />
        <NavigationTab
          value={routes[5]}
          to={routes[5]}
          component={ForwardedNavLink}
          label="Spam List"
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={SearchAccount} />
          <Route path={routes[1]} component={Kyc} />
          <Route path={routes[2]} component={EmailStatus} />
          <Route path={routes[3]} component={AllRequestLogger} />
          <Route path={routes[4]} component={Logs} />
          <Route path={routes[5]} component={SpamList} />
        </Switch>
      </ContentContainer>
    </>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      disconnectSocket,
    },
    dispatch,
  )

export default connect(
  null,
  mapDispatchToProps,
)(AccountsView)
