import React, { useState } from 'react'
import RemoveIcon from '@material-ui/icons/Remove'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AddBoxIcon from '@material-ui/icons/AddBox'
import BasicModal from 'components/organisms/Modal/index'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { Spacer } from 'components/atoms/Generic'

import IconButton from 'components/atoms/IconButton/index'
import CircularStatic from 'components/atoms/Loaders/CircularProgressWithLabel/index'
import CircularSpinner from 'components/atoms/Spinners/index'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog/index'
import FilesUploader from 'components/molecules/FilesUploader'

import { handleViewDocuments } from './DocumentsSection'
import { StyledCorporateTypes } from './Styles'

import { ACCEPTED_FILE_TYPES } from '../const/CorporateDocuments'
import { RadioButton } from 'components/atoms/Form'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'

const CorporateDocumentsSection = (props) => {
  const {
    type,
    uploadCorporateDocument,
    removeCorporateDocuments,
    corporateType,
    corporateId,
    setIsLoadingDocument,
    setViewDocument,
    setDocumentsDataBeingViewed,
    canEditCorporateInformation,
    accountId,
    document,
  } = props

  const showEmbeddedPdf = false
  const [uploadDocument, setUploadDocument] = useState('')
  const [removeUploadedDocumentType, setRemoveUploadedDocumentType] =
    useState('')
  const [keyRelianceAgreementType, setKeyRelianceAgreementType] =
    useState('light')
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false)
  const [progress, setProgress] = useState(0)
  const [isRemovingDocumentType, setIsRemovingDocumentType] = useState(false)
  const [
    removeDocumentConfirmationDialog,
    setRemoveDocumentConfirmationDialog,
  ] = useState({ open: false, indexOfItemToRemove: -1 })

  const hasDocument = !!document && document.documentType

  const addDocumentType = (docType) => {
    setUploadDocument(docType)
  }
  const showLoader = (progress) => {
    switch (progress) {
      case 100:
        return <CircularSpinner color="#13ab18" />

      default:
        return <CircularStatic progress={progress} />
    }
  }

  const removeDocuments = (docType) => {
    setRemoveUploadedDocumentType(docType)
    setRemoveDocumentConfirmationDialog({
      open: true,
      indexOfItemToRemove: 0,
    })
    setProgress(0)
  }

  const handleRemoveDocument = async (documentType, accountId) => {
    setIsRemovingDocumentType(true)
    setRemoveDocumentConfirmationDialog({
      open: false,
      indexOfItemToRemove: -1,
    })
    await removeCorporateDocuments(documentType, accountId)

    setIsRemovingDocumentType(false)
  }

  const handleUpload = async (files) => {
    setIsUploadingDocuments(true)
    let completed = 0
    for (const file of files) {
      const documentData = {
        file,
        metaData: {
          documentType: type.documentType,
          uploadDate: Date.now(),
          fileName: file.name,
        },
        corporateId,
        corporateType,
      }
      await uploadCorporateDocument(documentData, () => {}, accountId)
      completed += 1
      setProgress((completed / files.length) * 100)
    }
    if (uploadDocument === 'key_reliance_agreement_document') {
      await getAxios().post('/key-reliance-agreement-type', {
        type: keyRelianceAgreementType,
        accountId,
      })
      toast('Key reliance agreement type updated.', {
        type: toast.TYPE.SUCCESS,
      })
    }
    setProgress(0)
    setUploadDocument('')
    setIsUploadingDocuments(false)
  }
  return (
    <StyledCorporateTypes>
      <li style={{ listStyle: 'none' }}>
        <label>
          <input type="checkbox" onChange={() => {}} checked={hasDocument} />
          {type.documentLabel}
        </label>
      </li>

      {hasDocument && progress === 0 ? (
        <IconButton
          icon={<VisibilityIcon />}
          disabled={isRemovingDocumentType}
          width="50px"
          onClick={() =>
            handleViewDocuments(
              document,
              corporateId,
              setIsLoadingDocument,
              setViewDocument,
              setDocumentsDataBeingViewed,
              showEmbeddedPdf,
            )
          }
        />
      ) : (
        <>
          {progress === 0 ? (
            <a />
          ) : (
            <div style={{ width: '50px' }}>{showLoader(progress)}</div>
          )}
        </>
      )}
      <Spacer>
        <MuiButton
          margin="3px 0"
          disabled={!canEditCorporateInformation || isRemovingDocumentType}
          onClick={() => addDocumentType(type.documentType)}
          startIcon={<AddBoxIcon />}
        >
          UPLOAD
        </MuiButton>
      </Spacer>
      <Spacer>
        {hasDocument && (
          <MuiButton
            margin="0 0 0 10px"
            color="secondary"
            disabled={
              !canEditCorporateInformation ||
              isRemovingDocumentType ||
              isUploadingDocuments
            }
            onClick={() => removeDocuments(document.documentType)}
            startIcon={<RemoveIcon />}
          >
            REMOVE ALL
          </MuiButton>
        )}
      </Spacer>
      <BasicModal
        buttonsHidden
        open={uploadDocument === type.documentType}
        handleClose={() => setUploadDocument('')}
      >
        <div style={{ width: 400 }}>
          {uploadDocument === 'key_reliance_agreement_document' && (
            <FormControl disabled={isUploadingDocuments} component="fieldset">
              <FormLabel component="label">
                Key reliance agreement document type
              </FormLabel>
              <RadioGroup
                defaultValue="light"
                aria-label="gender"
                name="kra-radios"
                value={keyRelianceAgreementType}
                onChange={(e) => setKeyRelianceAgreementType(e.target.value)}
              >
                <FormControlLabel
                  value="light"
                  control={<Radio color="primary" />}
                  label="Light"
                />
                <FormControlLabel
                  value="heavy"
                  control={<Radio color="primary" />}
                  label="Heavy"
                />
              </RadioGroup>
            </FormControl>
          )}
          <Spacer margin="0px 0px 12px 0px" />
          <FilesUploader
            isUploading={isUploadingDocuments}
            clearFile={false}
            acceptedFileTypes={ACCEPTED_FILE_TYPES.ENTITY}
            manual
            handleUpload={handleUpload}
            height={100}
            uploadButtonText="ADD MORE"
          />
        </div>
      </BasicModal>

      {removeUploadedDocumentType === type.documentType && (
        <>
          <ConfirmationDialog
            disabled={isRemovingDocumentType}
            open={removeDocumentConfirmationDialog.open}
            title="Remove document"
            message={`Are you sure you want to remove ${type.documentType}?`}
            proceed={() => handleRemoveDocument(type.documentType, accountId)}
            handleClose={() =>
              setRemoveDocumentConfirmationDialog({
                open: false,
                indexOfItemToRemove: -1,
              })
            }
          />
        </>
      )}
    </StyledCorporateTypes>
  )
}

export default CorporateDocumentsSection
