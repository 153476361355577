import getAxios from 'helpers/axios'
import { toast } from 'react-toastify'
import {
  approveKycAttempt,
  rejectKycAttempt,
} from 'redux/manualKycUploads/actions'
import { addParachuteUserActionComment } from 'redux/userComments/actions'

const STATUSVALUES = {
  accept: 'ACCEPT',
  reject: 'REJECT',
}

const checkStatusValue = status => status === STATUSVALUES.accept

export const submit = async (values, dispatch) => {
  const { status, id, comment } = values
  let log
  try {
   const res = await getAxios().post(`/manual-kyc/attempts`, {
      id,
      comment,
      status: status.value,
    })
    if (res.data.statusCode === 202) {
      if (checkStatusValue(status.value)) {
        log = 'Approved KYC manual docs'
        dispatch(approveKycAttempt(id))
      } else {
        log = 'Rejected KYC manual docs.'
        dispatch(rejectKycAttempt(id))
      }
      addParachuteUserActionComment(values)
      toast(log, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
    } else {
      toast(`${res.data.body.message}`, {
        type: toast.TYPE.ERROR,
        autoClose: 2000,
      })
    }
  } catch (error) {
    toast(`${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}
