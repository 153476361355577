import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  NavigationTab,
  TopNavigationBar,
} from 'components/atoms/MenuItem/styles'
import { ContentContainer } from 'components/atoms/Generic'
import { ForwardedNavLink } from 'components/atoms/ForwardedNavlink'

import PlatformSummary from 'components/organisms/Margin/PlatformSummaryInfo'
import AccountsWithMarginInfoSummary from 'components/organisms/Margin/AccountsWithMarginSummaryInfo'
import AccountsWithFullMarginInfo from 'components/organisms/Margin/AccountsWithFullMarginInfo'
import MarginConfig from 'components/organisms/Margin/MarginConfig'
import MarginRequirements from 'components/organisms/Margin/MarginRequirements'
import IntrestAuction from 'components/organisms/Margin/IntrestAuction'
import LiquidationSettings from 'components/organisms/Margin/LiquidationSettings'

const routes = [
  '/leverage/margin',
  '/leverage/margin/accounts/info/summary',
  '/leverage/margin/accounts/full/info',
  '/leverage/margin/requirements',
  '/leverage/margin/interest-auction',
  '/leverage/margin/liquidation-settings',
]

const MarginPlatformSummary = props => {
  const [currentPage, setCurrentPage] = useState(routes[0])
  const {
    location: { pathname },
  } = props

  function handleChange(event, newValue) {
    setCurrentPage(newValue)
  }

  useEffect(
    () => {
      if (routes.includes(pathname)) {
        setCurrentPage(pathname)
      }
    },
    [pathname],
  )

  return (
    <>
      <TopNavigationBar
        value={currentPage}
        onChange={handleChange}
        variant="fullWidth"
      >
        <NavigationTab
          value={routes[0]}
          label="MarginPlatform Summary"
          component={ForwardedNavLink}
          to={routes[0]}
          exact
        />
        <NavigationTab
          value={routes[1]}
          label="Accounts With Margin Info Summary"
          component={ForwardedNavLink}
          to={routes[1]}
          exact
        />
        <NavigationTab
          value={routes[2]}
          label="Accounts With Full Margin Info"
          component={ForwardedNavLink}
          to={routes[2]}
          exact
        />
        <NavigationTab
          value={routes[3]}
          label="Margin Requirements"
          component={ForwardedNavLink}
          to={routes[3]}
          exact
        />
        <NavigationTab
          value={routes[4]}
          label="Interest Auction"
          component={ForwardedNavLink}
          to={routes[4]}
          exact
        />
        <NavigationTab
          value={routes[5]}
          label="Liquidation Settings"
          component={ForwardedNavLink}
          to={routes[5]}
          exact
        />
      </TopNavigationBar>
      <ContentContainer>
        <Switch>
          <Route path={routes[0]} component={PlatformSummary} exact />
          <Route
            path={routes[1]}
            component={AccountsWithMarginInfoSummary}
            exact
          />
          <Route
            path={routes[2]}
            component={AccountsWithFullMarginInfo}
            exact
          />
          <Route path={routes[3]} component={MarginRequirements} exact />
          <Route path={routes[4]} component={IntrestAuction} exact />
          <Route path={routes[5]} component={LiquidationSettings} exact />
        </Switch>
      </ContentContainer>
    </>
  )
}
export default MarginPlatformSummary
