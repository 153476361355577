import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { required } from 'forms/fieldValidators'
import {
  FormContainer,
  Button,
  InputGroup,
  FormError,
  BlueLinkButton,
} from 'components/atoms/Form'
import { BASE } from 'theme/base-units'
import {
  Details,
  DetailsBlockWithTitle,
  InfoLabel,
} from 'components/atoms/Details'
import Notes from 'components/atoms/Notes'
import { Flexbox } from 'components/layout/index'

import RawData from 'components/atoms/RawDataDualAuth/index'
import { submit } from './submit'
import { renderInput } from '../fields'

let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit, onCancel, onSubmit, rawData, expandAddNote,
    depositId, comments, showAddNote, expandRawData, showRawData, showActiveUser, removeActiveUser,
  } = props

  React.useEffect(() => {
    showActiveUser()
  }, [])

  React.useEffect(() => {
    return () => {
      removeActiveUser()
    }
  }, [])

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <InputGroup justify={'space-between'} align={'baseline'} direction={'row'}>
        <Field
          label={'Comment (required)'}
          name={'comment'}
          width={'100%'}
          validate={[required]}
          component={renderInput}
          justify={'space-around'}
          margin={`0 ${BASE * 2}px 0 0`}
          marginTop={26}
        />
        <Button
          type="submit"
          disabled={submitting || invalid}
          margin={`0 ${BASE * 2}px 0 0`}
        >
          Release
        </Button>
        <Button onClick={onCancel} color='secondary'>Cancel</Button>
      </InputGroup>
      <Details>
        <DetailsBlockWithTitle title="Notes">
          <Notes depositId={depositId} comments={comments} />
        </DetailsBlockWithTitle>
      </Details>
      <Flexbox
        direction={'column'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <Flexbox
          style={{ color: 'white' }}
          direction={'row'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showRawData}
          >
            {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
          </BlueLinkButton>
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showAddNote}
          >
            {expandAddNote ? 'Hide Notes Form' : 'Show Notes Form'}
          </BlueLinkButton>
        </Flexbox>
        {expandRawData && (
          <Fragment>
            <Flexbox
              marginBottom={'10'}
              marginTop={'10'}
              direction={'column'}
              align={'flex-start'}
              justify={'space-around'}
            >
              <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
              <RawData data={rawData} />
            </Flexbox>
          </Fragment>
        )}
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}
Form = reduxForm({ onSubmit: submit })(Form)


const ReduxForm = connect()(Form)

export default ReduxForm
