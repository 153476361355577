import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { makeStyles } from '@material-ui/core/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ShowStatus, Spacer } from 'components/atoms/Generic/index'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import ErrorBoundary from 'helpers/ErrorBoundary'
import {
  TRANSACTION_HISTORY_FILTERS,
  TRANSACTION_HISTORY_FILTERS_OPTIONS_SORTED,
  TRANSACTION_HISTORY_FILTERS_CHECKBOX_OPTIONS,
} from 'helpers/const'
import { Input } from '@material-ui/core'
import { BASE } from 'theme/base-units'
import { DateTimePicker } from '@material-ui/pickers'
import Switcher from 'components/atoms/Switcher'
import { Flexbox, FlexboxColumn } from 'components/layout'
import moment from 'moment'

import DateRange from 'forms/DateRange/index'
import { renderDatetime } from 'forms/fields'
import { Button } from 'components/atoms/Form'
import { TransactionFiltersMultipleSelect } from 'components/atoms/MultipleSelect'
import { getAccountTransactions } from 'redux/selectors/transactions'
import IconLoading from 'components/atoms/IconLoading'
import {
  fetchTransactions,
  fetchBlockChainReceives,
  clearTransactions,
} from 'redux/transactions/actions'
import TransactionRow from 'components/atoms/Table/rows/TransactionRow'
import {
  TablePlaceholder,
  Actions,
  ActionLabel,
  ActionInput,
  TitleContainer,
} from 'components/atoms/Table/styles'
import { DownloadTransactionsForm } from './Statements'

import {
  StyledTransActionHistoryFilter,
  StyledTransActionHistorySelectWrapper,
} from './Styles'

function descendingComparator(a, b, orderBy) {
  const newA = a[orderBy]
  const newB = b[orderBy]
  if (
    typeof newA === 'object' &&
    (orderBy === 'transactionType' || orderBy === 'timeStampValues')
  ) {
    if (Object.values(newB)[1] < Object.values(newA)[1]) {
      return -1
    }
    if (Object.values(newB)[1] > Object.values(newA)[1]) {
      return 1
    }
    return 0
  }
  let valueA
  let valueB
  {
    b[orderBy] === undefined ? (valueB = -1) : (valueB = b[orderBy])
  }
  {
    a[orderBy] === undefined ? (valueA = -1) : (valueA = a[orderBy])
  }
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1] || 0
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  {
    id: 'transactionType',
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'debitValue',
    disablePadding: false,
    label: 'Debited',
  },
  {
    id: 'creditValue',
    disablePadding: false,
    label: 'Credited',
  },
  {
    id: 'feeValue',
    disablePadding: false,
    label: 'Fee',
  },
  {
    id: 'timeStampValues',
    disablePadding: false,
    label: 'Time Stamp',
  },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

function AccountTransactions(props) {
  const { accountId } = props.match.params
  const { clearTransactions } = props
  const classes = useStyles()
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('meta')
  const [filter, setFilter] = useState('')
  const [highlightedId, updateHighlightedId] = useState('')
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(100)
  const [
    currentSelectedTransactionFilters,
    setCurrentSelectedTransactionFilters,
  ] = useState(TRANSACTION_HISTORY_FILTERS_CHECKBOX_OPTIONS)
  const [modalOpen, setModalOpen] = useState(false)

  const toggleExportModal = () => {
    setModalOpen(!modalOpen)
  }
  const { transactions } = props

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleHistoricalDateFilterSubmit = (values, dispatch) => {
    const { from, to } = values
    dispatch(
      props.fetchTransactions({
        id: accountId,
        skip,
        limit,
        fromDate: from,
        toDate: to,
      }),
    )
  }

  React.useEffect(() => {
    props.fetchTransactions({ id: accountId, skip, limit })
    props.fetchBlockChainReceives(accountId)
  }, [])

  React.useEffect(
    () => () => {
      clearTransactions({ accountId })
    },
    [clearTransactions, accountId],
  )

  const checkIfTransactionShouldBeDisplayed = useCallback(
    type => {
      let selectableOption = false
      // if the type is this we ned to then allow it to be selected
      if (type === 'INTERNAL_TRANSFER') {
        selectableOption = true
      } else {
        selectableOption = TRANSACTION_HISTORY_FILTERS_OPTIONS_SORTED.find(
          option => option.type === type.toLowerCase(),
        )
      }

      if (!selectableOption) return true
      let isSelected = false

      // This is responsible for checking if the option is currently
      // selected or not when the type is this
      if (type === 'INTERNAL_TRANSFER') {
        isSelected = currentSelectedTransactionFilters.find(
          filterOption => filterOption === 'TRANSFERS',
        )
      } else {
        isSelected = currentSelectedTransactionFilters.find(
          filterOption => filterOption === selectableOption.label,
        )
      }

      return isSelected
    },
    [currentSelectedTransactionFilters],
  )

  const filterData = useCallback(
    data => {
      const filterTransactionsByTransactionType = data.filter(
        ({ transactionType }) =>
          checkIfTransactionShouldBeDisplayed(transactionType.type),
      )
      if (!filter) return filterTransactionsByTransactionType
      const filteredData = filterTransactionsByTransactionType.filter(item => {
        const {
          transactionType: { description },
          creditCurrency,
          debitCurrency,
          creditValue,
          debitValue,
        } = item
        const descriptionFilter = description.toLowerCase().includes(filter)
        const creditCurrencyFilter =
          creditCurrency &&
          creditCurrency.shortName.toLowerCase().includes(filter)
        const creditValueFilter = creditValue && creditValue.includes(filter)
        const debitCurrencyFilter =
          debitCurrency &&
          debitCurrency.shortName.toLowerCase().includes(filter)
        const debitValueFilter = debitValue && debitValue.includes(filter)
        return (
          descriptionFilter ||
          creditCurrencyFilter ||
          creditValueFilter ||
          debitCurrencyFilter ||
          debitValueFilter
        )
      })
      return filteredData
    },
    [filter, currentSelectedTransactionFilters],
  )

  const transactionsData = useMemo(
    () =>
      stableSort(
        filterData((transactions && transactions.data) || []),
        getComparator(order, orderBy),
      ),
    [transactions, filter, currentSelectedTransactionFilters, order, orderBy],
  )
  const { transactionHistoryDateFilters } = props
  const { fromDate: from, toDate: to } = transactionHistoryDateFilters

  const fetchMoreData = async () => {
    const { limit: transactionsLimit } = props.transactions
    if (from || to) return
    if (props.transactions.hasMoreRecords !== false) {
      setSkip(skip + transactionsLimit)
    }
    await props.fetchTransactions({
      id: accountId,
      transactionsLimit,
      skip: skip + transactionsLimit,
    })
  }
  const returnEndMessage = txList => {
    if (txList.data && txList.data.length === 0) {
      return <ShowStatus>This account has no transactions</ShowStatus>
    }
    if (
      txList.transactionsStatus === 'loading' ||
      txList.bloackhainReceivesStatus === 'loading'
    ) {
      return (
        <TablePlaceholder>
          <ShowStatus>
            <IconLoading />
            Loading, please wait
          </ShowStatus>
        </TablePlaceholder>
      )
    }
    if (!transactions.hasMoreRecords) {
      return (
        <TablePlaceholder>
          <ShowStatus>No more transactions to show</ShowStatus>
        </TablePlaceholder>
      )
    }
  }

  const setHighlightedId = id => {
    updateHighlightedId(id === highlightedId ? null : id)
  }

  const renderBlockchainReceives = useCallback(
    txList =>
      txList && txList !== undefined && txList.length ? (
        stableSort(txList, getComparator(order, orderBy)).map((tx, i) => (
          <TransactionRow
            tx={tx}
            key={i}
            setHighlightedId={() => setHighlightedId(tx.id)}
            isHighlighted={highlightedId === tx.id}
          />
        ))
      ) : (
        <></>
      ),
    [order, orderBy, highlightedId],
  )

  const handleTransactionFilterOptionClick = label => {
    setCurrentSelectedTransactionFilters(prevState => {
      const index = prevState.indexOf(label)
      if (index > -1) {
        return prevState.filter(item => item !== label)
      }
      return [...prevState, label]
    })
  }

  const selectAllOptions = deselect => {
    setCurrentSelectedTransactionFilters(
      deselect ? [] : [...TRANSACTION_HISTORY_FILTERS_CHECKBOX_OPTIONS],
    )
  }

  return (
    <ErrorBoundary message="Account Transactions">
      <Actions justify="flex-end">
        <Button onClick={toggleExportModal}>
          Export Transaction Statement
        </Button>
        <DownloadTransactionsForm
          modalOpen={modalOpen}
          accountId={accountId}
          toggleExportModal={toggleExportModal}
        />
      </Actions>
      <Actions margin="20px 0px">
        <Actions width="70%">
          <DateRange
            initialValues={{
              from: from
                ? moment(from).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
              to: to
                ? moment(to).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            }}
            onSubmit={handleHistoricalDateFilterSubmit}
            filters={{ from, to }}
          />
        </Actions>
        <StyledTransActionHistorySelectWrapper>
          <TransactionFiltersMultipleSelect
            options={TRANSACTION_HISTORY_FILTERS_CHECKBOX_OPTIONS}
            currentSelectedOptions={currentSelectedTransactionFilters}
            onMenuItemClick={value => handleTransactionFilterOptionClick(value)}
            label="Transaction History Filters"
            selectAllOptions={selectAllOptions}
          />
        </StyledTransActionHistorySelectWrapper>
        <StyledTransActionHistoryFilter>
          <ActionLabel>Filter:</ActionLabel>
          <ActionInput
            marginRight={0}
            onChange={event => setFilter(event.target.value.toLowerCase())}
          />
        </StyledTransActionHistoryFilter>
      </Actions>
      <>
        {transactions && (
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>
                <InfiniteScroll
                  scrollableTarget={'test'}
                  dataLength={transactions.data ? transactions.data.length : 0}
                  next={fetchMoreData}
                  hasMore={transactions.hasMoreRecords}
                  useWindow={false}
                  loader={
                    transactions.transactionsStatus === 'loading' ||
                    transactions.bloackhainReceivesStatus === 'loading' ? (
                      <TablePlaceholder>
                        <ShowStatus>
                          <IconLoading />
                          Loading...
                        </ShowStatus>
                      </TablePlaceholder>
                    ) : (
                      <></>
                    )
                  }
                  onScroll={() => {}}
                  height={600}
                  endMessage={returnEndMessage(transactions)}
                >
                  <Table
                    className={classes.table}
                    aria-label="sticky table"
                    stickyHeader={true}
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody id="test">
                      <>
                        {transactions.pendingBlockChainReceives &&
                          renderBlockchainReceives(
                            transactions.pendingBlockChainReceives,
                          )}
                        {transactionsData &&
                          transactionsData.length > 0 &&
                          transactionsData.map((tx, i) => (
                            <TransactionRow
                              tx={tx}
                              key={i}
                              setHighlightedId={() => setHighlightedId(i)}
                              isHighlighted={highlightedId === i}
                            />
                          ))}
                      </>
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            </Paper>
          </div>
        )}
      </>
    </ErrorBoundary>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps.match.params
  return {
    transactions: getAccountTransactions(state)(accountId),
    transactionHistoryDateFilters:
      state.transactions.transactionHistoryDateFilters,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTransactions,
      clearTransactions,
      fetchBlockChainReceives,
    },
    dispatch,
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountTransactions)
