import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { TablePlaceholder, TicketsGrid } from 'components/atoms/Table/styles'
import { UserTicketsComments } from 'redux/userTicketsComments/actions'
import IconLoading from 'components/atoms/IconLoading'
import TableCell from '@material-ui/core/TableCell'
import ShowTicketsCommentsRow from './showTicketsCommentsRow'

class ShowTicketsComments extends Component {
  componentDidMount() {
    const { ticketId } = this.props
    this.props.UserTicketsComments(ticketId)
  }

  render() {
    const {
      displayTickets: { userTickets, userTicketsStatus },
    } = this.props
    return (
      <div>
        <div>
          <TicketsGrid>
            <p>Created At</p>
            <p>Comment</p>
            <p>Commenter</p>
            <p>Comment Type</p>
          </TicketsGrid>
        </div>
        {userTicketsStatus === 'loading' && (
          <div>
            <TablePlaceholder>
              <IconLoading />
              <span>Loading, please wait</span>
            </TablePlaceholder>
          </div>
        )}
        {userTicketsStatus === 'done' &&
          userTickets.comments.map(comment => (
            <ShowTicketsCommentsRow
              key={comment.id}
              comments={comment}
              users={userTickets.users}
            />
          ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  displayTickets: state.displayTickets,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserTicketsComments,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowTicketsComments)
