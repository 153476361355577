import { Collapse, TableCell, TableRow } from '@material-ui/core'
import { convertUnixToDate } from 'helpers/fileHelpers'
import React, { FC, Fragment, SetStateAction ,Dispatch} from 'react'
import { StyledExpandTableCell, StyledExpandedSectionContainer } from './Styles'
import { JsonBlock } from 'components/atoms/Details'
import { ConditionalOrder } from 'interfaces/conditionalOrders'

interface IConditionalOrderRow {
  handleOrderClick: React.Dispatch<React.SetStateAction<string>>
  conditionalOrder: ConditionalOrder
  expandedItem: string
}

const ConditionalOrderRow: FC<IConditionalOrderRow> = ({
  expandedItem,
  handleOrderClick,
  conditionalOrder,
}) => {
  return (
    <Fragment>
      <TableRow
        onClick={() =>
          handleOrderClick(conditionalOrder.positionId)
        }
      >
        <TableCell>{conditionalOrder.accountId}</TableCell>
        <TableCell>{conditionalOrder.currencyPair}</TableCell>
        <TableCell>{conditionalOrder.quantity|| ''}</TableCell>
        <TableCell>{conditionalOrder.triggerOrderSide}</TableCell>
        <TableCell>{conditionalOrder.triggerType}</TableCell>
        <TableCell>{conditionalOrder.triggerOrderType}</TableCell>
        <TableCell>{String(conditionalOrder.orderId)}</TableCell>
        <TableCell>{convertUnixToDate(conditionalOrder.updatedAt)}</TableCell>
      </TableRow>
      <TableRow className="no-hover">
        <StyledExpandTableCell colSpan={10}>
          <Collapse
            in={expandedItem === conditionalOrder.positionId}
            timeout="auto"
            unmountOnExit
          >
            <StyledExpandedSectionContainer>
              <JsonBlock src={conditionalOrder} title={'Raw Data'} />
            </StyledExpandedSectionContainer>
          </Collapse>
        </StyledExpandTableCell>
      </TableRow>
    </Fragment>
  )
}

export default ConditionalOrderRow
