import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BasicModal from 'components/organisms/Modal/index'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from 'components/atoms/IconButton/index'
import { convertToString, viewPdf } from 'helpers/utils'
import { Title } from 'components/atoms/Title'
import { ConfirmationDialog } from 'components/organisms/ConfirmationDialog'
import { Typography } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { connect } from 'react-redux'
import { FILE_TYPES } from '../const/Entities'
import { PdfViewActionContainer, StyledDocumentsActionsContainer } from './Styles'

const DocumentsViewer = ({
  isOpen,
  documentsDataBeingViewed,
  isLoadingDocuments,
  handleCloseDocumentViewer,
  accountId,
  removeDocument,
  setDocumentsDataBeingViewed,
  isLoadingCompanyDocument,
  isUpdatingEntity,
  entityId,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false)

  let blob = []
  let url = ''
  const isPdf =
    documentsDataBeingViewed[currentImageIndex] &&
    documentsDataBeingViewed[currentImageIndex].mimeType === FILE_TYPES.PDF

  if (
    documentsDataBeingViewed.length > 0 &&
    documentsDataBeingViewed[currentImageIndex].file &&
    documentsDataBeingViewed[currentImageIndex].mimeType
  ) {
    blob = new Blob(
      [new Uint8Array(documentsDataBeingViewed[currentImageIndex].file.data)],
      {
        type: documentsDataBeingViewed[currentImageIndex].mimeType,
      },
    )
    url = URL.createObjectURL(blob)
  }

  const handleDeleteDocument = async () => {
    setShowDeleteDocumentModal(false)
    await removeDocument(
      documentsDataBeingViewed[currentImageIndex].id,
      accountId,
      entityId,
    )
    const updatedDocumentsDataBeingViewed = documentsDataBeingViewed.filter(
      doc => doc.id !== documentsDataBeingViewed[currentImageIndex].id,
    )
    if (updatedDocumentsDataBeingViewed.length === 0) {
      return handleCloseDocumentViewer()
    }
    setCurrentImageIndex(currentImageIndex - 1 < 0 ? 0 : currentImageIndex - 1)
    setDocumentsDataBeingViewed(updatedDocumentsDataBeingViewed)
  }

  const getModalTitle = () => {
    if (documentsDataBeingViewed.length === 0) return ''
    const docType = documentsDataBeingViewed[currentImageIndex]
      .customDocumentType
      ? documentsDataBeingViewed[currentImageIndex].customDocumentType
      : documentsDataBeingViewed[currentImageIndex].documentType
    const documentType = convertToString(docType, '_')
    const documentCount = ` [ ${currentImageIndex + 1} of ${documentsDataBeingViewed.length
    } ]`
    return `${documentType}${documentCount}`
  }

  const handleViewNextDocument = () => {
    if (currentImageIndex < documentsDataBeingViewed.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1)
    }
  }
  const handleViewPrevDocument = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1)
    }
  }

  function previewSelectedPdfFile(src) {
    return <embed src={src} width="100%" height="350" />
  }

  useEffect(() => {
      setCurrentImageIndex(0)
    }, [isLoadingDocuments])

  return (
    <Fragment>
      <BasicModal
        handleClose={handleCloseDocumentViewer}
        buttonsHidden
        open={isOpen}
        message={
          !isLoadingDocuments && (
            <StyledDocumentsActionsContainer>
              <Title type="h3" text={getModalTitle()} />
              <IconButton
                onClick={handleCloseDocumentViewer}
                icon={<CloseIcon />}
              />
            </StyledDocumentsActionsContainer>
          )
        }
      >
        <div style={{ width: 500 }}>
          {isLoadingDocuments && (
            <LoadingBlock message="Fetching Document..." />
          )}
          {!isLoadingDocuments && !isPdf && (
              <>
                <img
                  style={{ height: 500, width: 500, objectFit: 'contain' }}
                  src={url}
                  alt="img"
                />
                {(isLoadingCompanyDocument || isUpdatingEntity) && (
                  <LoadingBlock message="Deleting Document..." />
                )}
              </>
            )}
          {!isLoadingDocuments && isPdf && previewSelectedPdfFile(url)}
          {isPdf && (
            <PdfViewActionContainer>
              <div>
                <IconButton
                  icon={<VisibilityIcon style={{ color: '#13ab18' }} />}
                  onClick={() => {
                    viewPdf(
                      documentsDataBeingViewed[currentImageIndex].file.data,
                      documentsDataBeingViewed[currentImageIndex].mimeType,
                    )
                  }}
                />
                <Typography variant="body1">View PDF</Typography>
              </div>
            </PdfViewActionContainer>
          )}
        </div>
        {!isLoadingDocuments && (
          <StyledDocumentsActionsContainer>
            <div>
              {documentsDataBeingViewed.length > 0 && (
                <>
                  <Typography
                    component="div"
                    variant="overline"
                    style={{ maxWidth: 400, wordWrap: 'break-word' }}
                  >
                    {documentsDataBeingViewed[currentImageIndex].documentName}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="primary"
                    style={{ maxWidth: 400, wordWrap: 'break-word' }}
                  >
                    {
                      documentsDataBeingViewed[
                        currentImageIndex
                      ].createdAt.split('(')[0]
                    }
                  </Typography>
                </>
              )}
            </div>
            <div>
              <IconButton
                onClick={() => setShowDeleteDocumentModal(true)}
                disabled={isUpdatingEntity || isLoadingCompanyDocument}
                icon={<DeleteIcon />}
              />
              <IconButton
                onClick={handleViewPrevDocument}
                icon={<ArrowBackIcon />}
                disabled={currentImageIndex === 0 || isUpdatingEntity || isLoadingCompanyDocument}
              />
              <IconButton
                onClick={handleViewNextDocument}
                icon={<ArrowForwardIcon />}
                disabled={
                  currentImageIndex === documentsDataBeingViewed.length - 1
                  || isUpdatingEntity
                  || isLoadingCompanyDocument
                }
              />
            </div>
          </StyledDocumentsActionsContainer>
        )}
      </BasicModal>
      <ConfirmationDialog
        title="Delete document"
        open={showDeleteDocumentModal}
        proceed={() => handleDeleteDocument()}
        message={`Are you sure you want to delete ${documentsDataBeingViewed[
          currentImageIndex
        ] && documentsDataBeingViewed[currentImageIndex].documentName}?`}
        handleClose={() => setShowDeleteDocumentModal(false)}
      />
    </Fragment>
  )
}

DocumentsViewer.propTypes = {
  isOpen: PropTypes.bool,
  documentsDataBeingViewed: PropTypes.any,
  isLoadingDocuments: PropTypes.bool,
  handleCloseDocumentViewer: PropTypes.func,
}

const mapStateToProps = state => {
  const { isLoadingCompanyDocument } = state.corporate
  const { isUpdatingEntity } = state.corporateEntities
  return {
    isLoadingCompanyDocument,
    isUpdatingEntity,
  }
}

export default connect(mapStateToProps)(DocumentsViewer)
