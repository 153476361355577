import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import { VALR_BLUE_GRAY, BLUE, DARK_MODE_PRIMARY } from 'theme/colors'
import { OutlinedInput } from '@material-ui/core'
import { CheckCircleOutline } from '@material-ui/icons'
import {
  Dropdown,
  StyledAutocomplete,
  StyledMuiInputLabel,
  StyledOption,
} from './Styles'
import { Input } from '../Form'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const useStyles = makeStyles({
  paper: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
    marginTop: 8,
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: `${VALR_BLUE_GRAY}`,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${BLUE}`,
      borderRadius: '8px',
    },
  },
  showHighlightedInputBackground: {
    backgroundColor: `${DARK_MODE_PRIMARY} !important`,
  },
})

function getStyles(option, selectedOptions, theme) {
  return {
    fontWeight:
      selectedOptions.indexOf(option) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export const MultipleSelect = ({
  options,
  label,
  input: { onFocus, value: inputValue = [], onChange } = {
    onFocus: () => { },
    value: (inputValue = []),
    onChange: () => { },
  },
  showInputWithCheckBox = false,
  onMenuItemClick,
  currentSelectedOptions = [],
  showHighlightedBackground,
  width,
  fullWidth = true,
  ...rest
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  const theme = useTheme()
  const classes = useStyles()

  if (options.length === 0) {
    console.error(
      'Please provide options to the Multiple Select e.g ["Option 1, Option 2]"',
    )
    return (
      <p>
        Please provide options to the component Multiple Select e.g [Option 1,
        Option 2]
      </p>
    )
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    // On autofill we get a stringified value.
    const option = typeof value === 'string' ? value.split(',') : value
    setSelectedOptions(option)
    if (onChange) {
      onChange(option)
    }
  }

  useEffect(() => {
    setSelectedOptions(inputValue || [])
  }, [])

  return (
    <Dropdown {...rest} variant="outlined" fullWidth={fullWidth}>
      <StyledMuiInputLabel id="demo-multiple-name-label">
        {label}
      </StyledMuiInputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={selectedOptions}
        input={
          <OutlinedInput
            style={{ width }}
            id="select-multiple-chip"
            label={label}
            notched
          />
        }
        onChange={handleChange}
        renderValue={(selected) =>
          selected.length > 0 ? selected.join(', ') : selected.join('')
        }
        MenuProps={{
          classes: { paper: classes.paper },
          getContentAnchorEl: null,
        }}
        inputProps={{
          classes: {
            root: showHighlightedBackground
              ? classes.showHighlightedInputBackground
              : '',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            style={getStyles(option, inputValue, theme)}
            key={option}
            value={option}
            onClick={() => {
              if (onMenuItemClick) {
                onMenuItemClick(option)
              }
            }}
          >
            {showInputWithCheckBox && (
              <>
                <Checkbox
                  color="primary"
                  checked={selectedOptions.indexOf(option) > -1}
                />
                <ListItemText primary={option} />
              </>
            )}
            {!showInputWithCheckBox && <>{option}</>}
          </MenuItem>
        ))}
      </Select>
    </Dropdown>
  )
}

export const TransactionFiltersMultipleSelect = ({
  options,
  currentSelectedOptions,
  label,
  onMenuItemClick,
  selectAllOptions,
}) => {
  const allSelected = options.length === currentSelectedOptions.length
  const selectAllText = allSelected ? 'DESELECT ALL' : 'SELECT ALL'
  return (
    <StyledAutocomplete
      multiple
      id="multiple-select"
      options={[selectAllText, ...options]}
      value={currentSelectedOptions}
      onChange={(_, newValue, reason, details) => {
        if (newValue.includes(selectAllText)) {
          if (allSelected) {
            selectAllOptions(true)
          } else {
            selectAllOptions(false)
          }
        } else {
          const clickedOption = details?.option
          if (clickedOption && onMenuItemClick) {
            onMenuItemClick(clickedOption)
          }
        }
      }}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      disableClearable
      renderInput={(params) => (
        <Input
          {...params}
          variant="outlined"
          label={label}
          placeholder={`${currentSelectedOptions.length} SELECTED`}
        />
      )}
      renderTags={() => ' '}
      renderOption={(option, { inputValue }) => {
        const isSelected = currentSelectedOptions.includes(option)
        return (
          <StyledOption>
            {option}
            {isSelected && <CheckCircleOutline />}
          </StyledOption>
        )
      }}
    />
  )
}
