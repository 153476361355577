import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchRiskProfileDualAuth } from 'redux/riskProfilesDulAuth/actions'
import { ContentContainer, Spacer } from 'components/atoms/Generic'

import ExpandingItem from 'components/atoms/ExpandingItem'

import RiskAccountsDualAuthProfilesTable from './RiskAccountsDualAuthProfilesTable'

const CorporateRiskAccountProfilesDualAuth = (props) => {
  const {
    riskProfilesDulAuth: { status, corporateRiskProfileDualAuths },
  } = props
  const [expandedRowId, setSelectedRow] = useState('')
  const [dualAuthExpanded, setDualAuthExpanded] = useState(false)
  const [multiAuthExpanded, setMultiAuthExpanded] = useState(false)

  const toggleSelectedRow = (id: string) =>
    setSelectedRow((prev) => (prev === id ? '' : id))

  useEffect(() => {
    props.fetchRiskProfileDualAuth()
  }, [])

  const setDualAuthExpand = () => {
    setDualAuthExpanded((prev) => !prev)
  }

  const setMultiAuthExpand = () => {
    setMultiAuthExpanded((prev) => !prev)
  }

  return (
    <Fragment>
      <ContentContainer>
        <ExpandingItem
          title={`Corporate Dual Auths`}
          loadingData={status === 'pending'}
          expanded={dualAuthExpanded}
          itemsAmount={
            corporateRiskProfileDualAuths &&
            corporateRiskProfileDualAuths.pending.length
          }
          onExpand={setDualAuthExpand}
          opensMultiple
          expandedView={
            <>
              <RiskAccountsDualAuthProfilesTable
                accountsProfilesDualAuthData={
                  corporateRiskProfileDualAuths.pending
                }
                status={status}
                toggleSelectedRow={toggleSelectedRow}
                expandedRowId={expandedRowId}
                isCorporateRiskProfileDualAuth
              />
            </>
          }
        />
        <ExpandingItem
          title={`Corporate Multi Auths`}
          loadingData={status === 'pending'}
          itemsAmount={
            corporateRiskProfileDualAuths &&
            corporateRiskProfileDualAuths.pendingMultiAuth.length
          }
          expanded={multiAuthExpanded}
          onExpand={setMultiAuthExpand}
          opensMultiple
          expandedView={
            <>
              <RiskAccountsDualAuthProfilesTable
                isMultiAuth
                accountsProfilesDualAuthData={
                  corporateRiskProfileDualAuths.pendingMultiAuth
                }
                status={status}
                toggleSelectedRow={toggleSelectedRow}
                expandedRowId={expandedRowId}
                isCorporateRiskProfileDualAuth
              />
            </>
          }
        />
      </ContentContainer>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  riskProfilesDulAuth: state.riskProfilesDulAuth,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRiskProfileDualAuth,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateRiskAccountProfilesDualAuth)
