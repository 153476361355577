import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { BASE } from 'theme/base-units'
import { Flexbox } from 'components/layout/index'

import {
  FormContainer,
  FormTitle,
  BlueLinkButton,
  Button,
  InputGroup,
  FormError,
} from 'components/atoms/Form'
import { InfoLabel } from 'components/atoms/Details'
import RawData from 'components/atoms/RawDataDualAuth'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import { Title } from 'components/atoms/Title'

import { submitDepositReview } from './submit'

import { renderDropdown } from '../fields'

let Form = props => {
  const { submitting, error, invalid } = props
  const {
    handleSubmit,
    onCancel,
    onSubmit,
    rawData,

    expandRawData,
    showRawData,
    clientInfoStatus,
  } = props

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormTitle>Review Pending Transfers </FormTitle>
      <Flexbox
        direction={'column'}
        align={'flex-start'}
        justify={'space-around'}
      >
        <Flexbox
          style={{ color: 'white' }}
          direction={'row'}
          align={'flex-start'}
          justify={'space-around'}
        >
          <BlueLinkButton
            marginRight={'50px'}
            marginBottom={'20px'}
            onClick={showRawData}
          >
            {expandRawData ? 'Hide Raw Data' : 'Show Raw Data'}
          </BlueLinkButton>
        </Flexbox>
        {expandRawData && (
          <Fragment>
            <Flexbox
              marginBottom={'10'}
              marginTop={'10'}
              direction={'column'}
              align={'flex-start'}
              justify={'space-around'}
            >
              <InfoLabel marginBottom={BASE * 2}>Raw Data:</InfoLabel>
              <RawData data={rawData} />
              {clientInfoStatus === 'loading' && <LoadingBlock />}
            </Flexbox>
          </Fragment>
        )}
      </Flexbox>
      <Flexbox align={'flex-start'} justify={'space-between'} direction={'row'}>
        {rawData.isBatchTransfer ? (
          <Title
            type="h3"
            text="This internal transfer is a batch transfer and can not be approved here"
          />
        ) : (
          <Fragment>
            <Field
              label={'Action'}
              name={'status'}
              margin={`0 ${BASE * 2}px 0 0`}
              width={'20%'}
              flex={0.4}
              disabled={rawData.isBatchTransfer}
              component={renderDropdown}
              options={[
                { value: 'CREDIT', label: 'Approve' },
                { value: 'IGNORE', label: 'Reject' },
              ]}
            />
            <InputGroup justify={'flex-end'}>
              <div style={{ width: '286px', marginTop: '16px' }}>
                <Button
                  type="submit"
                  disabled={submitting || invalid || rawData.isBatchTransfer}
                  margin={`0 ${BASE * 2}px 0 0`}
                >
                  Submit
                </Button>
                <Button
                  padding={'16px 41px'}
                  onClick={onCancel}
                  color="secondary"
                >
                  Cancel
                </Button>
              </div>
            </InputGroup>
          </Fragment>
        )}
      </Flexbox>
      {error && <FormError error={error} />}
    </FormContainer>
  )
}

Form = reduxForm({
  onSubmit: submitDepositReview,
  onSubmitSuccess: (values, dispatch, props) => props.toggleForm(),
})(Form)
const ReduxForm = connect()(Form)

export default ReduxForm
