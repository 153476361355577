import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getVirtualAccount } from 'redux/selectors/virtualAccounts'
import {
  clearVirtualAccount,
  fetchVirtualAccount,
} from 'redux/virtual-account/actions'
import { BASE } from 'theme/base-units'
import MuiButton from 'components/atoms/Buttons/MuiButton'
import { hasManagementUserRole } from 'helpers/roleBasedAccess'

import {
  Details,
  DetailsBlockWithTitle,
  InfoBlock,
} from '../../../components/atoms/Details'
import IconLoading from '../../../components/atoms/IconLoading'
import CreateVirtualAccount from '../../../forms/CreateVirtualAccount'
import UpdateVirtualAccount from '../../../forms/UpdateVirtualAccount'

const VirtualAccountInner = ({ virtualAccount, accountId }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [errors, setErrors] = useState([])
  const hasVirtualAccount = useMemo(
    () => virtualAccount.status === 'done' && virtualAccount.data,
    [virtualAccount],
  )
  const notAuthorized = !hasManagementUserRole()
  const handleResponse = useCallback(
    response => {
      setErrors([])
      if (response == null) return
      if (response.errors && response.errors.length > 0) {
        setErrors(response.errors)
      } else setFormOpen(false)
    },
    [setErrors, setFormOpen],
  )

  const errorMessages = useMemo(
    () => errors.map(e => `${e.field}: ${e.message}`),
    [errors],
  )

  const handleClose = useCallback(
    () => {
      setErrors([])
      setFormOpen(false)
    },
    [setErrors, setFormOpen],
  )

  if (virtualAccount.status === 'loading') return <IconLoading />

  if (formOpen && hasVirtualAccount) {
    return (
      <UpdateVirtualAccount
        initialValues={{
          accountId,
        }}
        form="UpdateVirtualAccountForm"
        onCancel={handleClose}
        onSubmitSuccess={handleResponse}
        errors={errorMessages}
      />
    )
  }

  if (formOpen && !hasVirtualAccount) {
    return (
      <CreateVirtualAccount
        initialValues={{
          accountId,
        }}
        form="CreateVirtualAccountForm"
        onCancel={handleClose}
        onSubmitSuccess={handleResponse}
        errors={errorMessages}
      />
    )
  }

  if (hasVirtualAccount) {
    return (
      <>
        <InfoBlock
          label="Bank Account ID"
          value={virtualAccount.data.bankAccountId}
        />
        <InfoBlock
          label="VAN Account Number"
          value={virtualAccount.data.accountNumber}
        />
        <InfoBlock label="VAN IFSC" value={virtualAccount.data.ifsc} />
        <MuiButton
          onClick={() => setFormOpen(true)}
          disabled={notAuthorized}
          showToolTip={notAuthorized}
          tooltipTitle={
            notAuthorized ? 'You are not authorized to perform this action' : ''
          }
        >
          Change Linked Bank Account
        </MuiButton>
      </>
    )
  }

  return (
    <MuiButton
      onClick={() => setFormOpen(true)}
      disabled={notAuthorized}
      showToolTip={notAuthorized}
      tooltipTitle={
        notAuthorized ? 'You are not authorized to perform this action' : ''
      }
    >
      Add Virtual Account
    </MuiButton>
  )
}

const VirtualAccount = props => {
  const { accountId, virtualAccount } = props

  useEffect(() => {
    props.fetchVirtualAccount(accountId)

    return () => {
      props.clearVirtualAccount()
    }
  }, [])

  return (
    <Details marginBottom={`${BASE * 3}px`} direction={'column'}>
      <DetailsBlockWithTitle title="virtual account">
        <VirtualAccountInner
          virtualAccount={virtualAccount}
          accountId={accountId}
        />
      </DetailsBlockWithTitle>
    </Details>
  )
}

const mapStateToProps = state => ({
  virtualAccount: getVirtualAccount(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchVirtualAccount,
      clearVirtualAccount,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VirtualAccount)
